import _ from 'lodash';
import React from 'react';
import { formatItem } from "../../../common/dateFormatting";
import { uniqueKey } from '../../../common/keys';
import PendingData from '../../../components/alerts/pendings/PendingData';
import { gEnums } from '../../../enums/globalEnums';

export const getSwiperContentInit = async (state, gscProps, handleSetSwiperContent, fn_getContent) => {

  const {
    desktopMode,
    formatting,
    projectOptions,
    groupType,
  } = state

  const { swipeContentType, tabIndex, currentSwipeItems, additionalItems, profileItems, showTabCount } = gscProps

  let id;
  let _swiperTabs;
  let _swiperItems;

  switch (swipeContentType) {

    case gEnums.swipeContentTypes.normalItems:
      id = 'tabContainerNormal'
      _swiperTabs = swipeTabs_groupItem(currentSwipeItems)
      _swiperItems = pageContentInitState(tabIndex, currentSwipeItems)
      break;

    case gEnums.swipeContentTypes.pageAreaContent:
      id = 'tabContainer'
      _swiperTabs = swipeTabs_pageItems(currentSwipeItems, profileItems, additionalItems, showTabCount, desktopMode)
      _swiperItems = pageContentInitState(tabIndex, currentSwipeItems, profileItems, additionalItems, fn_getContent)
      break;

    case gEnums.swipeContentTypes.groupedItems:
      id = 'tabGroupContainer'
      _swiperTabs = groupedSwipeTabs(currentSwipeItems, formatting, projectOptions, showTabCount)
      _swiperItems = groupInitState(tabIndex, currentSwipeItems, groupType, fn_getContent)
      break;

    default:
    //nothing
  }

  if (_swiperTabs.length === 0) {
    console.log('sts', swipeContentType)
  }

  const swiperContent = { tabContainerId: id, swiperTabs: _swiperTabs, swiperItems: _swiperItems }
  handleSetSwiperContent(swiperContent, gscProps)
}

/**
 * Gets the htcProps and then triggers 
 * @param {object} state 
 * @param {object} swiper_handlers 
 * @param {number} index 
 */
export const handleSwipeChange = (state, swiper_handlers, index, fn_getContent) => {

  const {
    tabView,
    swiperContent,
    groupDataProps,
    swipeContentType,
    view,
    _viewItem,
  } = state


  // note > swiperItems: {content, loaded}
  const { swiperItems, tabContainerId } = swiperContent ? swiperContent : {}

  let htcProps;
  switch (swipeContentType) {
    case gEnums.swipeContentTypes.normalItems:
      htcProps = { fn_getContent, swiper_handlers, uiSwipeItem: swipeItem_pageItem, swiperItems, tabView, tabContainerId, index, localStorageKey: _viewItem }
      break;
    case gEnums.swipeContentTypes.pageAreaContent:
      htcProps = { fn_getContent, swiper_handlers, uiSwipeItem: swipeItem_pageItem, swiperItems, tabView, tabContainerId, index, localStorageKey: _viewItem }
      break;
    case gEnums.swipeContentTypes.groupedItems:
      htcProps = { fn_getContent, swiper_handlers, groupDataProps, uiSwipeItem: swipeItem_groupItem, swiperItems, tabView, tabContainerId, index, localStorageKey: _viewItem }
      break;
    default:
    //nothing
  }

  if (htcProps) {
    handleTabChange(htcProps)
  } else {
    console.log('swipeContentType is not set')
  }
}

export const pageContentInitState = (initTabIndex, viewItems, additionalItems, profileItems, fn_getContent) => {
  const allItems = {}
  getPageItem(allItems, viewItems, initTabIndex, fn_getContent)
  if (additionalItems && Object.keys(additionalItems).length > 0) { getPageItem(allItems, additionalItems, initTabIndex, fn_getContent) }
  if (profileItems && Object.keys(profileItems).length > 0) { getPageItem(allItems, profileItems, initTabIndex, fn_getContent) }
  return allItems
}

/** returns a swipeitem for a pageItem
 * @returns loaded
 * @returns content
 */
export const swipeItem_pageItem = (swipeItems, key, isInit, leavePending, index, fn_getContent) => {
  if (isInit) {
    if (leavePending) {
      return { loaded: false, content: <PendingData key={uniqueKey('dp', key)} text={key + ' Data'} /> }
    } else {
      return fn_getContent(key, index)
    }
  } else {
    if (swipeItems[key].loaded) {
      return { loaded: true, content: swipeItems[key].content }
    } else {
      return fn_getContent(key, index)
    }
  }
}
/** returns a swipeitem for a groupItem
 * @returns loaded
 * @returns content (from SemUi)
 */
export const swipeItem_groupItem = (swipeItems, key, isInit, leavePending, fn_getContent) => {
  if (isInit) {
    if (leavePending) {
      return { loaded: false, key, content: <PendingData key={uniqueKey('dp', key)} keyy={key} text={key + ' Data'} /> }
    } else {
      return fn_getContent(key)
    }
  } else {
    if (swipeItems[key].loaded) {
      return { loaded: true, key, content: swipeItems[key].content }
    } else {
      return fn_getContent(key)
    }
  }
}

/**
 * Returns a group of item. Each item contains `content`, `key` and 'loaded'
 * @param {number} initTabIndex 
 * @param {object} groupDataProps 
 * @param {string} groupType 
 * @returns items
 */
export const groupInitState = (initTabIndex, groupDataProps, groupType, fn_getContent) => {

  const { groups } = groupDataProps
  const items = {}
  const firstGroup = groups ? groups[initTabIndex] : null

  if (groups) {
    groups.forEach((group) => {
      let item = null
      if (firstGroup && group.key === firstGroup.key) {
        item = swipeItem_groupItem(group.data, group.key, true, false, fn_getContent)
      } else {
        let leavePending = true
        switch (groupType) {
          case gEnums.groupTypes.swipeableGroup:
          case gEnums.groupTypes.segment:
            leavePending = false
            break;
          default:
          // nothing
        }
        item = swipeItem_groupItem(group.data, group.key, true, leavePending, fn_getContent)
      }
      items[group.key] = item
    })
  }

  return items

}

const swipeTabs_groupItem = (items, sts) => {
  const lists = []
  Object.keys(items).forEach((key, index) => {
    const item = items[key]
    const { caption, adminOnly, userOnly } = item
    if (sts) {
      sts.push({ key, caption: caption, adminOnly, userOnly })
    } else {
      lists.push({ key, caption: caption, adminOnly, userOnly })
    }
  })
  return lists
}

const swipeTabs_pageItems = (allowedViewItems, profileItems, additionalItems, showTabCount, desktopMode) => {
  const swipe_tabs = []
  addSwipeTabs(swipe_tabs, allowedViewItems, showTabCount, desktopMode)
  if (additionalItems && Object.keys(additionalItems).length > 0) { swipeTabs_groupItem(additionalItems, swipe_tabs) }
  if (profileItems && Object.keys(profileItems).length > 0) { swipeTabs_groupItem(profileItems, swipe_tabs) }
  return swipe_tabs
}

/**
 * Returns the tabs for the group
 * @param {object} groupDataProps 
 * @param {object} formatting 
 * @param {boolean} showTabCount 
 * @returns 
 */
const groupedSwipeTabs = (groupDataProps, formatting, projectOptions, showTabCount) => {

  const { timeZone, groupDatesByTimeZone } = projectOptions ? projectOptions : {}
  const { groups, groupByProp } = groupDataProps ? groupDataProps : {}
  const sts = []

  groups.forEach(group => {
    const { key, namedKey, data } = group
    const _key = namedKey ? namedKey : key
    let caption = _key ? _key : group
    if (groupByProp.toLowerCase().indexOf('date') >= 0 && groupByProp.toLowerCase().endsWith('date')) {
      if (formatting && formatting.dateFormat) { caption = formatItem(formatting.dateFormat, caption, groupDatesByTimeZone && timeZone ? timeZone : null) }
    }
    const _dataCount = data ? Object.keys(data).length : 0
    sts.push({ key: key, caption, showTabItemCount: showTabCount, dataCount: _dataCount })
  })

  return sts
}

export const getTabView = (tabContainerId, tabItemPrefix) => {
  return {
    midPoint: 0,
    itemWidths: [],
    itemWidth: [],
    set: function (tabCount) {
      const tabElement = document.getElementById(tabContainerId)
      if (tabElement) {
        let viewWidth = tabElement.offsetWidth
        this.midPoint = (viewWidth / 2)
        let tw = 0
        for (var i = 0; i < tabCount; i++) {
          let docElem = document.getElementById(tabItemPrefix + '_' + i)
          if (docElem) {
            let thisWidth = document.getElementById(tabItemPrefix + '_' + i).offsetWidth
            tw += thisWidth
            this.itemWidth.push(thisWidth)
            this.itemWidths.push(tw)
          }
        }
      }
    }
  }
}

/**
 * handles the tab change of menu item
 * @param {object} props (swiper_handlers, uiSwipeItem, swiperItems, tabView, tabContainerId, index, localStorageKey)
 */
const handleTabChange = (props) => {

  // uiSwipeItem is a function!!! 
  const { fn_getContent, swiper_handlers, swiperItems, tabView, tabContainerId, index, localStorageKey } = props

  // eslint-disable-next-line
  const { handleSetTabIndex } = swiper_handlers
  const indexKey = Object.keys(swiperItems)[index]

  if (tabContainerId === 'tabGroupContainer') {
    localStorageKey && localStorage.setItem('tab-container-' + localStorageKey, index)
  } else {
    localStorageKey && localStorage.setItem('tab-' + localStorageKey, index)
  }

  if (!swiperItems) {
    handleSetTabIndex(index)
    setHorizontalScroll(tabView, tabContainerId, index)
  } else {
    if (swiperItems[indexKey] && swiperItems[indexKey].loaded) {
      handleSetTabIndex(index)
      setHorizontalScroll(tabView, tabContainerId, index)
    } else {
      swiperItems[indexKey] = fn_getContent(indexKey, index)
      handleSetTabIndex(index)
      setHorizontalScroll(tabView, tabContainerId, index)
    }
  }
}

/**
 * 
 * @param {string} keyy 
 * @param {object} content 
 * @param {boolean} adminOnly 
 * @param {boolean} userOnly 
 * @returns an object with the `caption` and `content` for the `swipe item`
 */
export const swipePropNew = (keyy, content, adminOnly, userOnly) => {
  return {
    key: keyy,
    caption: _.startCase(keyy),
    content: content,
    adminOnly,
    userOnly
  }
}

export const setHorizontalScroll = (tabView, tabContainerId, tabIndex) => {
  const tabElement = document.getElementById(tabContainerId)
  if (tabElement) {
    if (tabIndex >= 0) {
      let leftWidths = tabView.itemWidths[tabIndex]
      let leftScroll = leftWidths - (tabView.itemWidth[tabIndex] + (tabView.midPoint - (tabView.itemWidth[tabIndex] / 2)))
      tabElement.scrollLeft = leftScroll
    } else {
      tabElement.scrollLeft = 0
    }
  }
}

/**
 * Adds an `item` to the `allItems` array
 * If the `item` already contains content, it will add the item. 
 * If the `item` does NOT have content, it will get it using the `fn_getContent`
 * @param {array} allItems 
 * @param {object} items 
 * @param {number} initTabIndex 
 * @param {function} fn_getContent 
 */
const getPageItem = (allItems, items, initTabIndex, fn_getContent) => {

  const firstKey = items && Object.values(items)[initTabIndex] ? Object.values(items)[initTabIndex].key : null

  Object.keys(items).forEach((key, index) => {
    const item = items[key]
    let _item = null
    if (item.content) {
      _item = {
        loaded: true,
        content: item.content
      }
    } else {
      if (key === firstKey) {
        _item = swipeItem_pageItem(items, key, true, false, index, fn_getContent)
      } else {
        _item = swipeItem_pageItem(items, key, true, true, index, fn_getContent)
      }
    }
    allItems[key] = _item
  })

}

/** Adds the props for the swipe tabs into the swipe_tabs array 
 * @note if the viewPermissionType === allowToAppUserAdmin, adminOnly will be set to true indicatating the only the user will be allowed to see this
*/
const addSwipeTabs = (swipe_tabs, items, showTabCount, desktopMode) => {
  Object.keys(items).forEach((key, index) => {
    const viewItem = items[key]
    switch (viewItem.viewPermissionType) {
      case gEnums.viewPermissionTypes.allow:
      case gEnums.viewPermissionTypes.allowToAppUserAdmin:
        const adminOnly = viewItem.viewPermissionType === gEnums.viewPermissionTypes.allowToAppUserAdmin
        const { display, desktopDisplay } = viewItem
        const _display = desktopMode && desktopDisplay ? desktopDisplay : display
        const { caption, altCaption } = _display ? _display : {}
        swipe_tabs.push({ key, caption: caption ? caption : altCaption, showTabItemCount: index === 0 ? false : showTabCount, adminOnly })
        break;
      default:
      // nothing
    }
  })
}

// https://hackernoon.com/simplifying-responsive-layouts-with-react-hooks-19db73893a7a
// https://react-swipeable-views.com/api/api/
// https://www.npmjs.com/package/react-swipeable-tabs
// https://codesandbox.io/react-hooks