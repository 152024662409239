import _ from 'lodash';
import React from 'react';
import { Placeholder, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';

const SegPending = (props) => {
  const { lineCount } = props
  return <Segment basic>
    <Placeholder>
      {_.times(lineCount / 2, (ppIndex) => <Placeholder.Paragraph key={uniqueKey('spnd', 'l', ppIndex)}>
        {_.times(lineCount, (plIndex) => <Placeholder.Line key={uniqueKey('spnd', '2', plIndex)} />)}
      </Placeholder.Paragraph>)}
    </Placeholder>
  </Segment>
}

export default SegPending