import { gEnums } from '../enums/globalEnums';
import { getAppProfileData } from '../firestoreData/helpers/appProfileData';
import { updateNotifications } from '../firestoreData/notifications/notificationsData';
import { fsfn_sendEmail, fsfn_sendNotificationByData, fsfn_sendSms, _allowNotificationSend } from './fbNotifications';

/** Checks for past notification and sends them */
export const checkScheduledNotifications = (props) => {
  const {
    action,
    appNotifications,
    aps_viewItems,
    appUserAccess,
    aps_appUserSettings,
    logging,
    notifications,
    page_fns,
    pathViews,
    useNotificationTopics,
  } = props


  const { appUserCollection, appUserViewTypeProp } = aps_appUserSettings ? aps_appUserSettings : {}
  const { pushActiveToSelf, notificationDeliveryType } = appNotifications ? appNotifications : {}

  const seconds_now = Math.floor(Date.now() / 1000);

  if (notifications) {
    // this will send notifications if 
    // active
    // sendDate is after now

    // loop the notifications
    Object.keys(notifications).forEach(key => {

      const note = notifications[key]
      const { autoSend, sent, active, sendDateAndTimeUtc, notificationDeliveryType: notificationDeliveryType_note, notificationScheduleType } = note ? note : {}

      const _notificationDeliveryType = notificationDeliveryType_note ? notificationDeliveryType_note : notificationDeliveryType

      if (autoSend) {

        switch (notificationScheduleType) {

          case gEnums.notificationScheduleTypes.scheduled:

            if (!sent && active && sendDateAndTimeUtc) {

              const noteDate = sendDateAndTimeUtc
              const allowSend = seconds_now >= noteDate.seconds

              if (allowSend) {

                switch (_notificationDeliveryType) {

                  case gEnums.notificationDeliveryTypes.inApp:
                    const _note = { ...note }
                    _note.sent = true
                    _note.active = true
                    updateNotifications(pathViews, page_fns, _note, _note)
                    break;

                  case gEnums.notificationDeliveryTypes.email:
                    const nd = {
                      email: {
                        recipient: 'philipaokeeffe@gmail.com',
                        sender: 'support@meetingevolution.net',
                        subject: note.title,
                        body: note.body,
                      }
                    }
                    fsfn_sendEmail(nd)
                    break;

                  case gEnums.notificationDeliveryTypes.push:
                    if (pushActiveToSelf) {
                      fsfn_sendNotificationByData(note, pathViews, appUserAccess.fcmTokens).then(res => {
                        const { success, result } = res ? res : {}
                        if (success) { console.log('result', result) }
                        action.callback && action.callback()
                      })
                    } else {
                      sendScheduledPushNotifications({ appUserCollection, appUserViewTypeProp, aps_viewItems, pathViews, notificationData: note, useNotificationTopics })
                    }
                    break;

                  case gEnums.notificationDeliveryTypes.sms:
                    const data = {
                      to: '+14126002081',
                      text: note.body,
                    }
                    fsfn_sendSms(data, logging)
                    break;


                  default:
                    break;
                }
              } else {
                console.error("note not send", note)
              }
            }
            break;
          default:
            break;
        }
      }
    })
  }
}

export const getSendNotificationProps = (activeNote, appUserAccess, callback, notificationData, page_fns, pathViews, appProfileData, useNotificationTopics) => {
  return { activeNote, appUserAccess, callback, notificationData, page_fns, pathViews, appProfileData, useNotificationTopics }
}

/**
 * Gets the client profile data and then sends the notification
 * @param {object} props 
 */
export const sendScheduledPushNotifications = (props) => {
  const { pathViews, page_fns, appUserCollection, appUserCollections, appUserViewTypeProp, aps_viewItems, notificationData, useNotificationTopics } = props
  const np = getSendNotificationProps(null, null, null, notificationData, page_fns, pathViews, null, useNotificationTopics)
  getAppProfileData({ pathViews, page_fns, appUserCollection, appUserCollections, appUserViewTypeProp, aps_viewItems, callback_send: sendTheScheduledNotification, notificationData, useNotificationTopics, np })
}

export const sendTheScheduledNotification = (props) => {
  const {
    // appUserAccess,
    callback,
    notificationData,
    pathViews,
    appProfileData,
    // useNotificationTopics,
  } = props

  const { selectedTopicGroups } = notificationData
  // if (useNotificationTopics && selectedTopicGroups &&  appProfileData && appProfileData[topicOriginGroup] && appProfileData[topicOriginGroup][topicOriginGroupKey]) {
  //   if (_allowNotificationSend) {
  //     sendNotification_topic(notificationData, appUserAccess, paps_state).then(res => {
  //       callback && callback()
  //     })
  //   } else {
  //     console.log('sendTheNotification - notificationTopic', notificationData)
  //   }
  // } else {
  if (appProfileData && selectedTopicGroups && Object.keys(selectedTopicGroups).length > 0) {
    let fcms = [];
    Object.keys(selectedTopicGroups).forEach(key => {
      const selectedTopicGroup = selectedTopicGroups[key]
      const { groupKey, itemKey } = selectedTopicGroup
      const topicGroup = groupKey && itemKey ? appProfileData[groupKey][itemKey] : appProfileData[groupKey]
      if (topicGroup) {
        appendTopicGroupFcms(fcms, topicGroup)
      }
    })
    if (fcms) {
      if (_allowNotificationSend) {
        fsfn_sendNotificationByData(notificationData, pathViews, fcms, callback)
      } else {
        console.log('fcms', fcms)
      }
    }
  }
}
// }

/** Returns the fcm tokes for each item in the topicGroup */
const appendTopicGroupFcms = (fcms, topicGroup) => {
  const { targets } = topicGroup
  if (targets) {
    Object.keys(targets).forEach(key => {
      const target = targets[key]
      const { fcmTokens } = target
      if (fcmTokens) {
        fcmTokens.forEach(fcmToken => {
          if (!fcms.includes(fcmToken)) {
            fcms.push(fcmToken)
          }
        })
      }
    })
  } else {
    Object.keys(topicGroup).forEach(key => {
      const target = topicGroup[key]
      const { fcmTokens } = target
      if (fcmTokens) {
        fcmTokens.forEach(fcmToken => {
          if (!fcms.includes(fcmToken)) {
            fcms.push(fcmToken)
          }
        })
      }
    })
  }
}
