import React, { createContext, useContext, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { UiItemContext } from '../../../cnr/contexts/UiItemContext'
import { dataModificationTypes } from '../../../viewSettings/enums/itemActionTypes'
import FullPageWrapper from '../../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper'
import UiAddEdit from '../dataModifications/UiAddEdit'

export const QrScanContext = createContext();

const QrScan = (props) => {

  const { qrAdd } = props ? props : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ? uiItemContext : {}

  const [scannedData, setScannedData] = useState()

  const handleError = err => console.error(err)

  const handleScan = (data) => {
    if (data) {
      try {
        const _scannedData = JSON.parse(data)
        _scannedData.qrKey = 1234
        setScannedData(_scannedData)
      } catch (error) {
        setScannedData({ qrKey: 5678 })
      }
    }
  }

  const qrReader = () => <QrReader
    delay={300}
    onError={handleError}
    onResult={handleScan}
    style={{ width: '100%' }}
    constraints={{ facingMode: { ideal: "environment" } }}
    className="test"
  />

  const content = () => {
    if (scannedData) {
      return <QrScanContext.Provider value={{ qrScan_state: { scannedData: scannedData } }} >
        <UiAddEdit dataModificationType={dataModificationTypes.add} ignoreItemStatus={true} />
      </QrScanContext.Provider >
    } else {
      return qrReader(128)
    }
  }

  const wrapper = () => <Wrapper
    content={content()}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={item_handlers.handleShowItemSidebar}
    topperCaption={'QR Code'}
  />

  return fullPageWrapper()
}

export default QrScan