import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { getAppUserLinks } from '../auth/accessPermissions';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { g_cns } from '../common/cns';
import { appIconTypes } from '../enums/appIconTypes';
import { gEnums } from '../enums/globalEnums';
import GenericIcon from '../genericControls/GenericIcon';
import { itemActionTypes } from '../viewSettings/enums/itemActionTypes';

let _iconSize = 'large'
const _allowSettingsPopup = false

/**
 * 
 * @param {object} props (forArray, componentContexts)
 * @returns a popup icon element that will appear at the bottom right of 
 * uiItem IF there are valid actions associated with the item
 */
const UiItemPopupIcon = (props) => {

  const { forArray, componentContexts, viewItem } = props

  // componentContexts
  const { dataContext, pageAreaContext } = componentContexts

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, handlers, settings } = parentContext ? parentContext : {}
  const { appAccess_state, appUser_state, baseSettings_state, page_state, paps_state, videoConference_state } = states
  // const { page_handlers } = handlers ? handlers : {}
  const { appUser_fns } = fns ? fns : {}

  // homeSettingsContext 
  const { homeSettings } = settings ? settings : {}
  const { global } = homeSettings ? homeSettings : {}
  const { settingsConsole: settingsConsole_home_global } = global ? global : {}
  const { dataManagementSettings } = settingsConsole_home_global ? settingsConsole_home_global : {}

  // const { settingsConsole } = baseSettings_state ? baseSettings_state : {}

  // authContext  
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { isAdminOrSuper, appUserSessionKey } = appUserAccess ? appUserAccess : {}

  // pageContext
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { imageMapping: imageMapping_global, dataOptions } = aps_global ? aps_global : {}
  const { appUserCollection, appUserLinks } = aps_appUserSettings ? aps_appUserSettings : {}
  const { links } = appUserLinks ? appUserLinks : {}
  const { reduceSingleItemMenus } = dataOptions ? dataOptions : {}

  // papsContext 
  const { view, viewKey, viewPathKey } = paps_state ? paps_state : {}

  // videoConferenceContext 
  const { meetingRooms } = videoConference_state ? videoConference_state : {}

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.actionButton] : {}

  if (forArray) { _iconSize = 'small' }

  // pageAreaContext 
  const { pageArea_state } = pageAreaContext
  const { pageItemsShown } = pageArea_state ? pageArea_state : {}

  // dataContext 
  const { data_state } = dataContext ? dataContext : {}
  let { uivi } = data_state ? data_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state, item_handlers } = uiItemContext ? uiItemContext : {}
  const { modifyActionType, vld, singleDataItem, viewItemIsView } = item_state ? item_state : {}
  const { handleShowItemSidebar, handleModifyMapping, handleSettingsSidebar } = item_handlers ? item_handlers : {}

  const itemData = vld ? vld[Object.keys(vld)[0]] : {}

  const meetingInProgress = meetingRooms && meetingRooms[viewPathKey] && meetingRooms[viewPathKey].meetingInProgress ? true : false

  let _viewItem;

  if (viewItem) {
    _viewItem = viewItem
    uivi = viewItem.key
  } else {
    _viewItem = pageItemsShown ? pageItemsShown[uivi] : {}
  }

  // const imageMapping_viewItem = imageMapping_global ? _.find(imageMapping_global, { collectionName: uivi }) : {}
  // const { showImageMap: showImageMap_vi, useImageMapIcon } = imageMapping_viewItem ? imageMapping_viewItem : {}

  // const _showImageMap = singleDataItem && showImageMap_vi && useImageMapIcon && isAdminOrSuper ? true : false

  const { accessRequests } = appAccess_state ? appAccess_state : {}

  const [allowItemPopup, setAllowItemPopup] = useState({ allow: false, count: 0 })
  const [singleItem, setSingleItem] = useState()

  const getSingleItem = (actionItems) => {
    let _singleItem;
    if (actionItems) {
      Object.keys(actionItems).forEach(actionItemKey => {
        const actionItem = actionItems[actionItemKey]
        Object.keys(actionItem).forEach(itemKey => {
          _singleItem = {
            actionItemKey,
            itemKey
          }
        })
      })
    }
    return _singleItem
  }

  useEffect(() => {
    const actionItems = []
    if (itemData && links && appUserSessionKey) {
      getAppUserLinks(actionItems, appUserCollection, links, itemData, viewKey, _viewItem, itemData.id)
    }

    const _actionItems = appUser_fns.validateAccess_uiAction({ viewItem: _viewItem, singleDataItem, viewItemIsView, pageSettings, itemData, accessRequests, view, viewKey, dataManagementSettings })

    let aip = false
    let totalCount = 0

    if (Object.keys(_actionItems.appUserDirect).length > 0) {
      aip = true
      totalCount += Object.keys(_actionItems.appUserDirect).length
    }

    if (Object.keys(_actionItems.dataManagement).length > 0) {
      aip = true
      totalCount += Object.keys(_actionItems.dataManagement).length
    }

    if (Object.keys(_actionItems.dataModification).length > 0) {
      aip = true
      totalCount += Object.keys(_actionItems.dataModification).length
    }

    if (!aip && actionItems.length > 0) { aip = true }

    if (totalCount) {
      setSingleItem(getSingleItem(_actionItems))
    }

    // page_handlers.handlePageOptions({ allow: aip, count: totalCount, _actionItems })

    setAllowItemPopup({ allow: aip, count: totalCount })

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess, uivi]);

  const handleSingleClick = () => {
    const { dataModifications } = _viewItem ? _viewItem : {}
    const { add } = dataModifications ? dataModifications : {}
    const { appFormType } = add ? add : {}
    const at = { modifyActionType: singleItem.itemKey, appFormType }
    item_handlers.handleModifyAction(at)
  }

  const optionButtons = {
    showSave: true,
    showCancel: true
  }

  // turn off save and cancel
  switch (modifyActionType) {
    case itemActionTypes.add:
    case itemActionTypes.appUserInfo:
    case itemActionTypes.createGlobalData:
    case itemActionTypes.createNotification:
    case itemActionTypes.dataLinking:
    case itemActionTypes.dataActions:
    case itemActionTypes.delete:
    case itemActionTypes.createFilter:
    case itemActionTypes.createTopic:
    case itemActionTypes.edit:
    case itemActionTypes.googleSheetLink:
    case itemActionTypes.googleSheetUpdate:
    case itemActionTypes.imageMapping:
    case itemActionTypes.imageUpload:
    case itemActionTypes.openDatabase:
    case itemActionTypes.rating:
    case itemActionTypes.subAction:
    case itemActionTypes.viewAppUserAuthorizations:
      optionButtons.showSave = false
      optionButtons.showCancel = false
      break;

    default:
    // nothing
  }

  // key, iconName, onClick, clickOptions, opts, corner
  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const itemPopup = () => {
    if (reduceSingleItemMenus && allowItemPopup.count === 1) {
      return <span className={g_cns.item_popup + ' ' + uivi}>
        {gi('item-icon-ask', 'add', handleSingleClick, null, { circular: true, size: _iconSize, color: 'blue', inverted: true, sac: styleAndClass })}
      </span>
    } else {
      return <span className={g_cns.item_popup + ' ' + uivi}>
        {gi('item-icon-ask', appIconTypes.ellipsisVertical, handleShowItemSidebar, null, { circular: true, size: _iconSize ? _iconSize : null, color: 'blue', inverted: true, sac: styleAndClass })}
      </span>
    }
  }

  const settingsPopup = () => <span className={g_cns.item_settings}>
    {gi('item-icon-settings', appIconTypes.cog, handleSettingsSidebar, null, { circular: true, size: _iconSize, color: 'green', inverted: true })}
  </span>

  const meetingPopup = () => <span className={g_cns.item_meeting}>
    {gi('item-icon-meeting', appIconTypes.videos, handleShowItemSidebar, null, { circular: true, size: _iconSize, color: 'green', inverted: true })}
  </span>

  const mappingPopup = () => <span className={g_cns.item_mapping}>
    {gi('item-icon-mapping', appIconTypes.mapping, handleModifyMapping, null, { circular: true, size: _iconSize, color: 'blue', inverted: true })}
  </span>

  if (allowItemPopup.allow) {
    return <React.Fragment>
      {/* {_showImageMap && mappingPopup()} */}
      {_allowSettingsPopup && settingsPopup()}
      {allowItemPopup.allow && itemPopup()}
      {meetingInProgress && meetingPopup()}
    </React.Fragment>
  } else {
    return <div></div>
  }
}

export default UiItemPopupIcon