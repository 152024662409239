import React, { useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { gEnums } from '../../enums/globalEnums';
import MenuItems from './MenuItems';

/** Menu of navigation items */
const NavMenu = (props) => {

  const { items, handleClick, navOnly, sac, sob, sib } = props

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { page_state, } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appUser_fns } = fns

  // pageContext 
  const { desktop, sideMenu, appAccess } = aps_global ? aps_global : {}
  const { navigationAlignment } = desktop ? desktop : {}
  const { iconsLeft } = sideMenu ? sideMenu : {}
  const { appAccessType } = appAccess ? appAccess : {}

  let isPublicSite = false

  switch (appAccessType) {
    case gEnums.siteAuthorizationTypes.public:
    case gEnums.siteAuthorizationTypes.passcode:
      isPublicSite = true
      break;
    default:
    // nothing
  }

  const alignRight = navigationAlignment === gEnums.captionAlignmentTypes.right
  const allowSidebarView = appUser_fns.validateAccess_sidebar(isPublicSite)

  const menuItems = () => {
    const nmiProps = { items, alignRight, handleClick }
    return <MenuItems {...nmiProps} />
  }

  const navMenu = () => {
    const sty = sac ? sac.style : {}
    const cn = !sac ? 'inh' : ''
    const sAndC = { className: cn, style: sty }
    if (iconsLeft) { sAndC.className += ' icons-left' }

    return <Menu borderless vertical={!navOnly ? true : false} fluid {...sAndC} inverted>
      {(allowSidebarView === gEnums.viewPermissionTypes.allow) && items && menuItems()}
      <Menu.Menu position='right'>
        {navOnly && sob && sob}
        {navOnly && sib && sib}
      </Menu.Menu>
    </Menu >
  }

  return navMenu()
}

export default NavMenu