import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import { getItemName } from '../common/convert'
import { updateArrayInclusion } from '../common/sorting'
import { appIconTypes } from '../enums/appIconTypes'
import UiSaveButtons from '../components/buttons/UiSaveButtons'
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper'

export const SelectList = (props) => {

  const { items, arrayValues, handleArrayValue, noModal } = props ? props : {}

  const [selectedArrayValues, setSelectedArrayValues] = useState()
  const [selectedItems, setSelectedItems] = useState()

  useEffect(() => {
    if (items) {
      const _items = _.sortBy(items, 'name')
      _items.forEach(item => {
        if (arrayValues && arrayValues.includes(item.key)) {
          item.selected = true
        }
      })
      setSelectedItems(_items)
    }
    setSelectedArrayValues(arrayValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleOk = () => handleArrayValue(selectedArrayValues)

  const handleCheck = (key, checked) => {
    const _selectedArrayValues = [...selectedArrayValues]
    const _selectedItems = [...selectedItems]
    if (_selectedItems) {
      const si = _.find(_selectedItems, { key: key })
      if (si) {
        si.selected = !checked
      }
      setSelectedItems(_selectedItems)
      updateArrayInclusion(_selectedArrayValues, key)
      setSelectedArrayValues(_selectedArrayValues)
    }
    // setSelected(key)
    // handleListSelect(data.name)
  }

  const checkField = (item) => {
    const _name = getItemName(item)
    const { key, selected } = item
    return <Form.Field>
      <Checkbox
        label={_name}
        name={_name}
        checked={selected}
        onClick={(e) => { handleCheck(key, selected) }}
      />
    </Form.Field>
  }

  const checkFields = () => selectedItems.map(item => checkField(item))

  const form = () => <Form inverted={noModal}>
    {selectedItems && checkFields()}
  </Form>

  const wrapper = () => <Wrapper
    content={form()}
    footer={<UiSaveButtons save={{ oc: handleOk, caption: 'OK', icon: appIconTypes.check }} />}
    wrapperType={wrapperTypes.paddedFooter}
  />

  return wrapper()

}
