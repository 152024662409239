import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Card, Icon } from 'semantic-ui-react'
import { uniqueKey } from '../../../common/keys'
import { appIconTypes } from '../../../enums/appIconTypes'
import { getThemeColorDirect } from '../../../styles/formatting'

const _indicatorValues_true = [true, 'yes', 'Yes']
const _indicatorValues_false = [false, 'no', 'No']
const _indicatorValues = [..._indicatorValues_false, ..._indicatorValues_true]

const CardIndicator = (props) => {

  const { itemData, indicator, cardKey, aps_global, staticViews, ind } = props
  const { indicatorPropIcon, indicatorProp, indicatorSize, showIndicatorValue, indicatorIconColor, indicatorBackgroundColor, getIndicatorColorFromPropCollection } = indicator ? indicator : {}
  const _color_bc = getThemeColorDirect(aps_global, indicatorBackgroundColor)

  const [cardIndicator, setCardIndicator] = useState()

  useEffect(() => {
    if (indicatorProp && itemData[indicatorProp]) {
      const _value = itemData[indicatorProp]
      if (_indicatorValues.includes(_value) && _indicatorValues_true.includes(_value)) {
        let _indicatorValue;
        let _indicatorColor;
        if (itemData && showIndicatorValue && indicatorProp) {
          const _svIndicator = staticViews ? staticViews[indicatorProp] : null
          if (_svIndicator) {
            if (itemData && itemData[indicatorProp]) {
              const value = itemData[indicatorProp]
              let _svi = _.find(_svIndicator, { name: value })
              if (!_svi) { _svi = _svIndicator[value] }
              if (_svi) {
                if (_svi.name) { _indicatorValue = _svi.name }
                if (_svi.color) { _indicatorColor = _svi.color.toLowerCase() }
              }
            }
          }
        }
        let value = _indicatorValue ? _indicatorValue : _.startCase(indicatorProp)
        const color = _indicatorColor ? _indicatorColor : getThemeColorDirect(aps_global, indicatorIconColor)
        setCardIndicator({ show: true, value: value, color, className: 'card-indic' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [indicatorProp]);

  const indicatorItem = () => <div key={uniqueKey('cp', 'i', cardKey, ind)} >
    <Icon key={uniqueKey('cp', 'i', cardKey, 1)}
      name={indicatorPropIcon ? indicatorPropIcon : appIconTypes.check}
      size={indicatorSize ? indicatorSize : null}
      style={{ color: cardIndicator.color ? cardIndicator.color : 'grey' }}
    />
    {cardIndicator.value}
  </div>

  return cardIndicator ?
    <Card.Content
      key={uniqueKey('cp', 'crd', cardKey, 4)}
      extra
      className={cardIndicator.className}
      style={{ backgroundColor: _color_bc ? _color_bc : 'white' }}
    >
      {indicatorItem()}
    </Card.Content>
    : null

}

export default CardIndicator