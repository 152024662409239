import React from 'react';
import { Form, Icon, Segment } from 'semantic-ui-react';

/**
 * 
 * @param {object} props 
 * @returns A simple form with a Input
 */
const SearchInputForm = (props) => {

  const {
    disabled,
    label,
    onChange,
    onClick,
    value,
    noSeg,
  } = props

  const form = () => <Form>
    <Form.Input
      icon={<Icon name='search' inverted circular link color={'blue'} onClick={onClick} disabled={disabled} />}
      value={value}
      label={noSeg ? null : label}
      onChange={onChange}
    />
  </Form>

  return noSeg ? form() : <Segment basic >
    {form()}
  </Segment>
};

export default SearchInputForm