import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { isMeProd } from '../../../project/fbConfiguration';
import AppNotifications, { appNotificationTypes } from '../../appNotifications/AppNotifications';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { copyCodeToClipboard } from '../../common_web/copy';
import { gEnums } from '../../enums/globalEnums';
import { refreshPageWithCacheClear } from '../../common/actions';

const showVersionNumber = true

const NavSideMenu = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { settings, states, handlers, fns, navigate } = parentContext ? parentContext : {}
  const { page_state, paps_state, storage_state } = states ? states : {}
  const { appSettings_handlers } = handlers ? handlers : {}

  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}

  const { page_fns } = fns ? fns : {}

  const { allowSidebarView, pushSimpleItem, appVersion } = props

  const _appVersion = isMeProd() ? appVersion : appVersion + '*'

  // papsContext 
  const { sharePathFull, pathViews, rootPaths } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { eventOptions, clientOptions, appNotifications, styles, appInstallation, sideMenu } = aps_global ? aps_global : {}
  const { allowNotifications } = appNotifications ? appNotifications : {}
  const { sideMenus } = styles ? styles : {}
  const { iconsLeft } = sideMenu ? sideMenu : {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  const { allowBackToClient, showHelp, showDevice, showLyft, showQrCode, showUserImage, showUber, showWeather, weatherZip, weatherCountryCode, allowUrlLinkSharing, shortenedLink, allowCookieClearing, helpPdf } = _appOptions ? _appOptions : {}

  const { allowAppInstallation, allowManualAppInstallation, showAddToHomeScreenHelp } = appInstallation ? appInstallation : {}

  const { appUrls: homeAppUrls } = homeSettings_global ? homeSettings_global : {}
  const { appUrls: appUrlz } = homeAppUrls ? homeAppUrls : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ? frameworkContext : {}
  const { isMobileDevice } = framework_state ? framework_state : {}

  const [showNotifications, setShowNotifications] = useState()
  const [helpUrl, setHelpUrl] = useState()

  useEffect(() => {
    const { globalFiles } = storage_state ? storage_state : {}
    const { pdf } = globalFiles ? globalFiles : {}
    const _helpPdf = pdf && pdf[helpPdf]
    const { urls } = _helpPdf ? _helpPdf : {}
    const { full } = urls ? urls : {}
    setHelpUrl(full)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const textAreaRef = useRef()
  const shortenedLinkRef = useRef()

  const handleShowHelp = () => window.open(helpUrl, '_blank')
  const handleBackToClient = () => {
    if (rootPaths && rootPaths.clients) {
      const pn = rootPaths.clients.substring(0, rootPaths.clients.length - 1)
      navigate(pn)
    }
  }
  // FrZi8TCLcOSTKr8L2n5s
  const handleShare = () => copyCodeToClipboard(textAreaRef)
  const handleShareShort = () => copyCodeToClipboard(shortenedLinkRef)

  const handleShowAppQrCode = () => framework_handlers.handleShowAppQrCode()
  const handleShowAppQrCodeShort = () => framework_handlers.handleShowAppQrCode(true)

  const handleClearCookies = () => {
    localStorage.clear()
    framework_handlers.handleAppSidebar()
  }

  const handleShowHomeScreenPrompt = () => {
    const addToHomeScreenItem = eventKey ? 'appInstallation-' + eventKey : 'appInstallation'
    window.localStorage.removeItem(addToHomeScreenItem)
    appSettings_handlers.handleShowHomeScreenPrompt()
    framework_handlers.handleAppSidebar()
  }

  const handleShowHomeScreenHelpPrompt = () => {
    appSettings_handlers.handleShowHomeScreenHelp()
    framework_handlers.handleAppSidebar()
  }

  const handleNotifications = () => {
    !showNotifications && framework_handlers.handleAppSidebar()
    setShowNotifications(!showNotifications)
  }

  const handleGetLatest = () => refreshPageWithCacheClear()
  const handleQrCode = () => { pushSimpleItem(null, null, 'qrCode') }

  // const handleNotificationReset = () => {
  //   Notification.requestPermission().then(function (result) {
  //     console.log('result', result)
  //     // setNotificationRequest({ requested: true, requestResult: result })
  //   });
  // }

  // const handleNotifications = () => { pushSimpleItem(null, null, 'notifications') }
  const handleLinkClick = (url) => { window.open(url, '_blank') }
  const hdc = () => { }

  const lyftLink = page_fns.getLink(appUrlz, 'lyft', isMobileDevice)
  const uberLink = page_fns.getLink(appUrlz, 'uber', isMobileDevice)
  const weatherLink = page_fns.getLink(appUrlz, 'weather', isMobileDevice)

  const getItemStyle = () => {
    const miStyle = {
      paddingTop: sideMenus && sideMenus.apply && sideMenus.padding ? sideMenus.padding + 'em' : null,
      paddingBottom: sideMenus && sideMenus.apply && sideMenus.padding ? sideMenus.padding + 'em' : null,
      fontSize: sideMenus && sideMenus.fontSize && sideMenus.fontSize ? sideMenus.fontSize + 'em' : null,
    }
    return miStyle
  }

  const menuIcon = (name, oc, color) => <Icon name={name} color={color} onClick={() => oc && oc()} />

  const linkMenuItem = (caption, url, replace, replaceWith) => {
    const sty = getItemStyle()
    let itemCaption = _.startCase(caption)
    if (replace && replaceWith) {
      url = url.replace(replace, replaceWith)
      if (weatherCountryCode) { url = url.replace('US', weatherCountryCode) }
      itemCaption += ' (' + replaceWith + ')'
    }
    return <Menu.Item style={sty} onClick={() => handleLinkClick(url)}>{itemCaption}</Menu.Item>
  }

  const menuItem = (text, sty, icon, oc) => <Menu.Item style={sty} onClick={() => oc()}>{menuIcon(icon)}{text}</Menu.Item>
  const menuItemIcon = (text, sty, icon, oc, color) => <Menu.Item style={sty} onClick={() => oc && oc()}>{menuIcon(icon, oc, color)}{text}</Menu.Item>

  const navMenu = () => {
    const sty = getItemStyle()
    if (allowSidebarView === gEnums.viewPermissionTypes.allow) {
      let cn = 'inh'
      if (iconsLeft) { cn += ' icons-left' }
      return <Menu borderless vertical fluid className={cn}>
        {showDevice && <Menu.Item style={sty} >{'Device: ' + paps_state.device.name}</Menu.Item>}
        {(showQrCode || showUserImage) && menuItem('QR Code', sty, 'qrcode', handleQrCode)}
        {(allowNotifications) && menuItem('Notifications', sty, 'bell outline', handleNotifications)}
        {(allowUrlLinkSharing) && menuItemIcon('Copy App Link', sty, 'share alternate', handleShare, 'blue')}
        {(allowUrlLinkSharing && shortenedLink) && menuItemIcon('Copy Short Link', sty, 'share alternate', handleShareShort, 'blue')}
        {(allowUrlLinkSharing) && menuItemIcon('Show App Qr Code', sty, 'qrcode', handleShowAppQrCode, 'blue')}
        {(allowUrlLinkSharing && shortenedLink) && menuItemIcon('Show Short Qr Code', sty, 'qrcode', handleShowAppQrCodeShort, 'blue')}

        {showLyft && lyftLink && linkMenuItem('lyft', lyftLink)}
        {showUber && uberLink && linkMenuItem('uber', uberLink)}
        {showWeather && weatherLink && weatherZip && linkMenuItem('weather', weatherLink, 'ZIPCODE', weatherZip)}

        {allowCookieClearing && menuItemIcon('Clear Site Cookies', sty, 'erase', handleClearCookies)}
        {allowAppInstallation && allowManualAppInstallation && menuItem('Add to Home Screen', sty, 'home', handleShowHomeScreenPrompt)}
        {showAddToHomeScreenHelp && menuItem('Add to Home Screen Instructions', sty, 'help', handleShowHomeScreenHelpPrompt)}
        {(showVersionNumber) && menuItemIcon('Version Number: ' + _appVersion, sty, 'refresh', handleGetLatest, 'blue')}
        {(showHelp) && menuItemIcon('Help', sty, 'help', handleShowHelp, 'blue')}
        {(allowBackToClient && eventKey) && menuItemIcon('Client', sty, 'arrow left', handleBackToClient, 'blue')}

        <textarea key={uniqueKey('nsb', 'ta')} ref={textAreaRef} onChange={hdc} value={sharePathFull} style={{ position: 'absolute', left: '-999em' }} />
        <textarea key={uniqueKey('nsb', 'ta')} ref={shortenedLinkRef} onChange={hdc} value={shortenedLink} style={{ position: 'absolute', left: '-999em' }} />

      </Menu>
    }
    return <div></div>
  }

  if (showNotifications) {
    return <AppNotifications appNotificationType={appNotificationTypes.notifications} handleCancel={handleNotifications} />
  } else {
    return navMenu()
  }
}

export default NavSideMenu