import React, { useEffect, useState } from 'react';
import DragDropper, { dragDropperTypes } from '../../dragNDrops/DragDropper';
import { labels } from './labels';

const LabelSort = (props) => {

  const { viewKey, display_propItem, updateArray, allowSort, headerColor, additionalProps, sortData } = props
  const { sortGroupName } = display_propItem ? display_propItem : {}

  const [groups, setGroups] = useState()
  const [ddGroups, setDDGroups] = useState()

  const portalCn = 'portal'

  useEffect(() => {
    const { groups: _groups } = sortData ? sortData : {}
    if (_groups) {
      const _ddGroups = {}
      Object.keys(_groups).forEach((groupKey, index) => {
        const group = _groups[groupKey]
        const lbProps = { items: group.items, viewKey, additionalProps }
        _ddGroups[groupKey] = {
          dataItems: group.items,
          elements: labels(lbProps)
        }
      })
      setDDGroups(_ddGroups)
    }
    setGroups(_groups)
    // eslint-disable-next-line
  }, [sortData && sortData.groups]);


  const handleSorted = (ddGroups) => updateArray(ddGroups)

  const dragDropper = () => <DragDropper
    allowSort={allowSort}
    ddType={dragDropperTypes.label}
    ddGroups={ddGroups}
    display_propItem={display_propItem}
    groupChangeKey={sortGroupName}
    handleSorted={handleSorted}
    headerColor={headerColor}
    isLabelSort={true}
    portalCn={portalCn}
  />

  return groups && ddGroups ? dragDropper() : <div></div>

}

export default LabelSort