import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { AppSettingsContext } from '../../cnr/contexts/AppSettingsContext';
import { thumbSwipeHandlers, thumbSwipeInitialState, thumbSwipeReducer } from '../../cnr/reducers/ThumbSwipeReducer';
import { gEnums } from '../../enums/globalEnums';
import { svgElements, svgTypes } from '../svg/svgElements';

// const dims = { w: 360, h: 540 }

// const usePerc = false

export const ThumbSwipe = (props) => {

  const { imageMapType, url, imageMap_state, imageMap_handlers, handleZoneClick } = props
  const { svgElements: existingElements, currentZone } = imageMap_state ? imageMap_state : {}

  const allowNew = imageMapType === gEnums.imageMapTypes.mapping

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ? appSettingsContext : {}
  const { currentDevice } = appSettings_state ? appSettings_state : {}
  const { msettings } = currentDevice ? currentDevice : {}
  const { h, w } = msettings ? msettings : {}

  let imageStyle = msettings ? { height: h, width: w } : { height: 360, width: 540 }

  // let svgStyle = { verticalAlign: 'top', touchAction: 'none', position: 'relative' }
  // if (msettings) {
  //   svgStyle = { ...svgStyle, ...{ height: h, width: w } }
  // } else {
  //   svgStyle = { ...svgStyle, ...{ height: 360, width: 540 } }
  // }

  // imageStyle = { height: 540, width: 360 }
  // svgStyle = { height: 540, width: 360, verticalAlign: 'top', touchAction: 'none', position: 'relative' }

  // action
  const [thumbSwipe_state, thumbSwipe_dispatch] = useReducer(thumbSwipeReducer, thumbSwipeInitialState({ imageStyle }));
  const thumbSwipe_handlers = thumbSwipeHandlers(thumbSwipe_dispatch, thumbSwipe_state)
  const { currentElem, lastActionElement, done } = thumbSwipe_state
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = thumbSwipe_handlers

  // local state
  const [imageDims, setImageDims] = useState()
  const [_svgElements, set_svgElements] = useState([])
  const [_currentElement, setCurrentElement] = useState([])

  const _swipeDiv = useRef(null);

  useEffect(() => {
    thumbSwipe_handlers.handleDiv(_swipeDiv)
    thumbSwipe_handlers.handleSvgType(svgTypes.rectangle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const se = svgElements(getEps(existingElements), null, null, null, handleZoneClick, imageDims)
    set_svgElements(se)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingElements, imageDims]);

  useEffect(() => {
    const se = svgElements(null, currentElem, null, null, handleZoneClick, imageDims)
    setCurrentElement(se)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElem, imageDims]);

  useEffect(() => {
    const se = svgElements(null, null, currentZone, null, handleZoneClick, imageDims)
    setCurrentElement(se)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZone, imageDims]);

  useEffect(() => {
    if (lastActionElement && done === true) {
      const fixed = 5
      const { width: w_i, height: h_i } = imageDims ? imageDims : { width: 100, height: 100 }
      lastActionElement.parent = {
        width: w_i,
        height: h_i
      }
      const r_w = lastActionElement.w / w_i
      const r_h = lastActionElement.h / h_i
      const r_x = lastActionElement.sx / w_i
      const r_y = lastActionElement.sy / h_i

      lastActionElement.percs = {
        w: (r_w).toFixed(fixed) * 100,
        h: (r_h).toFixed(fixed) * 100,
        sx: (r_x).toFixed(fixed) * 100,
        sy: (r_y).toFixed(fixed) * 100,
      }
      imageMap_handlers.handleNewZone(lastActionElement)
      thumbSwipe_handlers.handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);

  const getEps = (elems) => {
    const eps = {
      elems: [...elems],
    }
    return eps
  }

  const handleLoad = ({ target: img }) => setImageDims({ height: img.offsetHeight, width: img.offsetWidth })

  const svg_touch = (currentOnly) => <svg
    // viewBox="0 0 200 200"
    // viewBox="0 0 100 100"
    ref={_swipeDiv}
    onTouchStart={allowNew ? handleTouchStart : null}
    onTouchMove={allowNew ? handleTouchMove : null}
    onTouchEnd={allowNew ? handleTouchEnd : null}
    onMouseDown={allowNew ? handleTouchStart : null}
    onMouseMove={allowNew ? handleTouchMove : null}
    onMouseUp={allowNew ? handleTouchEnd : null}
  // onLoad={handleLoad}
  >
    {/* {image()} */}
    {!currentOnly && _svgElements}
    {_currentElement}
  </svg>

  // eslint-disable-next-line
  const img = () => <img src={url} onLoad={handleLoad} />

  const svgWrapper = (currentOnly) => <div className="svg-wrapper">
    {img()}
    {svg_touch(currentOnly)}
  </div>

  const canvas_pinch = () => {
    const inits = { scale: 1, x: 0, y: 0 }
    return <TransformWrapper
      initialScale={inits.scale}
      initialPositionX={inits.x}
      initialPositionY={inits.y}>
      <TransformComponent>
        {svgWrapper(true)}
      </TransformComponent>
    </TransformWrapper>
  }

  switch (imageMapType) {
    case gEnums.imageMapTypes.mapping:
    case gEnums.imageMapTypes.zones:
      return svgWrapper()

    case gEnums.imageMapTypes.location:
      return canvas_pinch()

    default:
      return canvas_pinch()
  }

}