import _ from 'lodash';
import React from 'react';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';

const UpdateWrapper = (props) => {

  const { content, caption, handleClose, handleSave, allowSave } = props

  const saveClick = () => handleSave()

  const footer = () => <UiSaveButtons
    save={{ caption: 'Update', oc: saveClick, disabled: !allowSave }}
  ></UiSaveButtons>

  const wrapper = () => <Wrapper
    content={content}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleClose}
    topperCaption={_.startCase(caption)}
  />

  return fullPageWrapper()

}

export default UpdateWrapper