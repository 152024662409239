import React from 'react';
import { Card, Icon, Label } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { formatTimeAmPm } from '../../../common/dateFormatting';
import _ from 'lodash'

const _size = 'tiny'

const TimeLabels = (props) => {
  const { itemData, propGroups, _keyy } = props
  const { schedule: props_schedule } = propGroups ? propGroups : {}
  console.log('props_schedule', props_schedule)
  const { startTime, endTime } = itemData ? itemData : {}
  const st = startTime && props_schedule && _.isArray(props_schedule) && props_schedule.includes('startTime') ? formatTimeAmPm(startTime) : null
  const et = endTime && props_schedule && _.isArray(props_schedule) && props_schedule.includes('endTime') ? formatTimeAmPm(endTime) : null
  return <Card.Content key={uniqueKey('tl', 'c', _keyy)}>
    {st && <Label key={uniqueKey('tl', 1, _keyy)} size={_size}><Icon color={'green'} name='time' />{st}</Label>}
    {et && <Label key={uniqueKey('tl', 2, _keyy)} size={_size}><Icon color={'red'} name='time' />{et}</Label>}
  </Card.Content>
}

export default TimeLabels