import { tr } from 'date-fns/locale';
import _ from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FullPageAlert from '../../components/alerts/FullPageAlert';
import { startTypes } from '../reducers/StartReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { PapsContext } from './PapsContext';
import { StartContext } from './StartContext';

/** 
 * @state clientSettings_state (`clientSettings`)
 * @contexts (`AppSettingsContext`, `HomeSettingsContext`, `PapsContext`, `StartContext`)
 */
export const ClientSettingsContext = createContext();
/** 
 * @state eventSettings_state (`eventSettings`)
 * @functions eventSettings_fns (`getGlobalSetting`, `getGlobalViewItem`, `getGlobalViewItemProps`, `getHomeGlobalSetting`, `mergeViewItemProps`)
 * @contexts (`AppSettingsContext`, `ClientSettingsContext`, `HomeSettingsContext`,`PapsContext`, `StartContext`)
 */
export const EventSettingsContext = createContext();
/** 
 * @state homeSettings_state (`homeSettings`)
 * @contexts (`AppSettingsContext`, `PapsContext`, `StartContext`)
 */
export const HomeSettingsContext = createContext();

const _useAll = false
const _showState = false

/**
 * Returns the home, client and event settings contexts from the database 
 * @param {object} props (ignoreStart)
 * @provides ClientSettingsContext (clientSettings)
 * @provides EventSettingsContext (eventSettings)
 * @provides HomeSettingsContext (homeSettings)
 * @contexts [StartContext, AuthContext, AppSettingContext, PapsProvider] 
 * @returns 
 */
const SettingsDataProvider = (props) => {

  const { ignoreStart } = props ? props : {}

  // startContext
  const startContext = useContext(StartContext);
  const { start_state, start_handlers } = startContext ? startContext : {}
  const { startDocName } = start_state ? start_state : {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ? papsContext : {}
  const { settingsDocName, settingsDocKeys, pathViews } = paps_state ? paps_state : {}


  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state, appSettings_handlers } = appSettingsContext ? appSettingsContext : {}
  const { allAppSettings, appSettings, sandboxOn, appLanding_global } = appSettings_state ? appSettings_state : {}

  const { hceSettings } = appSettings ? appSettings : {}
  const { settingsReady, settingsError } = appSettings ? appSettings : {}

  const isClient = pathViews.clients ? true : false
  let _clientSettings;

  if (hceSettings) {
    _clientSettings = isClient ? hceSettings.client_key : hceSettings.clients
  }

  const homeSettings_state = hceSettings ? { homeSettings: hceSettings.home } : {}
  const clientSettings_state = _clientSettings ? { clientSettings: _clientSettings } : {}
  const eventSettings_state = hceSettings ? { eventSettings: hceSettings.event_key } : {}

  // console.log('hceSettings', hceSettings)
  // console.log('settingsDocKeys', settingsDocKeys)

  const navigate = useNavigate()

  const needSettings = (sdk) => {
    let getSettings;
    if (!allAppSettings) {
      getSettings = true
    } else {
      getSettings = allAppSettings[sdk] ? false : true
    }
    return getSettings
  }

  // if the settingsDocName changes, get the settings
  // the callback is handleAmmendAppSettings or handleAmmendHCEAppSettings
  // RERUNS: settingsDocName, pathViews
  useEffect(
    () => {
      console.log('settingsDocName', settingsDocName)
      if (_useAll) {
        const apsaProps = {
          view: paps_state.view,
          pathViews,
          sandboxOn,
          settingsDocKeys,
        }
        appSettings_handlers.handleGetHceSettings_all(apsaProps)
      } else {
        // loop the settingsDocKeys   
        // the settingsDocKeys is an array container the needed settings keys (home, client, event)    

        const apsPropsBase = {
          view: paps_state.view,
          pathViews,
          sandboxOn,
          settingsDocKeys,
        }

        const settingsToGet = []

        Object.keys(settingsDocKeys).forEach(settingsDocKey => {
          const sdn = settingsDocKeys[settingsDocKey]
          const _needSettings = needSettings(sdn);
          if (_needSettings && settingsDocKey) {
            const apsProps = { settingsDocKey, settingsDocName: sdn }
            settingsToGet.push({ ...apsPropsBase, ...apsProps })
          }
        })

        if (settingsToGet.length > 0) {
          if (start_handlers.handleUpdateStart && (startDocName !== settingsDocName) && !ignoreStart) {
            start_handlers.handleUpdateStart(startTypes.event, settingsDocName)
          }
          // console.log('settingsDocName', settingsDocName, settingsToGet)
          settingsToGet.forEach(stg => {
            appSettings_handlers.handleGetHceSettings(stg)
          })
        } else {
          if (start_handlers.handleUpdateStart && (startDocName !== settingsDocName) && !ignoreStart) {
            start_handlers.handleUpdateStart(startTypes.event, settingsDocName)
          }
          appSettings_handlers.handleAmmendAppSettings(allAppSettings[settingsDocName], pathViews, settingsDocKeys)
          // console.log('allAppSettings[sdk]', settingsDocName, allAppSettings[settingsDocName])
          console.log('--- set existing settings ---', settingsDocName)
        }
      }
    },
    // eslint-disable-next-line
    [settingsDocName]
  )

  // triggers the redirection of the app to the `landingUrl` if useAppLanding and `landingUrl` are set.
  useEffect(
    () => {
      if (appLanding_global && appLanding_global.useAppLanding) {
        const { landingUrl, clientKey, eventKey } = appLanding_global
        if (landingUrl) {
          navigate('/' + landingUrl)
        } else if (clientKey && eventKey) {
          navigate('/clients/' + clientKey + '/events/' + eventKey)
        }
      }
    },
    // eslint-disable-next-line
    [appLanding_global]
  )

  const eventSettings_fns = {
    getHomeGlobalSetting: (key, subKey) => {
      const { global } = hceSettings.home ? hceSettings.home : {}
      const globalItem = global ? global[key] : {}
      if (globalItem) {
        if (subKey) {
          return globalItem ? globalItem[subKey] : null
        } else {
          return globalItem
        }
      } else {
        return null
      }
    },
    getGlobalSetting: (key, subKey) => {
      const { global } = hceSettings.events ? hceSettings.events : {}
      const globalItem = global ? global[key] : {}
      if (globalItem) {
        if (subKey) {
          return globalItem ? globalItem[subKey] : null
        } else {
          return globalItem
        }
      } else {
        return null
      }
    },
    getGlobalViewItem: (key) => {
      const { viewItems } = hceSettings.events ? hceSettings.events : {}
      const viewItem = viewItems ? viewItems[key] : {}
      return viewItem
    },
    getGlobalViewItemProps: (key) => {
      const { viewItems } = hceSettings.events ? hceSettings.events : {}
      const viewItem = viewItems ? viewItems[key] : {}
      const { props } = viewItem ? viewItem : {}
      return props
    },
    mergeViewItemProps: (viewItem) => {
      const { props, key } = viewItem ? viewItem : {}
      const { viewItems } = hceSettings.events ? hceSettings.events : {}
      const viewItem_g = viewItems ? viewItems[key] : {}
      const { props: props_g } = viewItem_g ? viewItem_g : {}
      const np = {}
      if (props_g) {
        Object.keys(props_g).forEach(pKey => {
          if (props[pKey]) {
            const prop_g = props_g[pKey]
            props[pKey] = _.merge(prop_g, props[pKey])
          }
        })
      }
      return np
    }
  }


  if (_showState) {
    console.log(Object.keys(eventSettings_fns).sort())
  }

  // , appSettings_handlers: appSettings_handlers // was in EventSettingsContext > NOT needed
  const providerContext = () => <HomeSettingsContext.Provider value={{ homeSettings_state }}>
    <ClientSettingsContext.Provider value={{ clientSettings_state }}>
      <EventSettingsContext.Provider value={{ eventSettings_fns, eventSettings_state }}>
        {props.children}
      </EventSettingsContext.Provider>
    </ClientSettingsContext.Provider>
  </HomeSettingsContext.Provider>

  if (settingsError) {
    return <FullPageAlert
      title={'Error'}
      message={settingsError.toString()}
    />
  } else {
    return settingsReady ? providerContext() : <div></div>
  }

}

export default SettingsDataProvider
