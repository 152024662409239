import _ from 'lodash';
import React from 'react';

export const createTeeboxes = (teeboxes) => {
 
  const divs = [] 
  const data = {}
  const firstDs = {} 
  let gotD = false

  Object.keys(teeboxes).forEach(key => {
    const teebox = teeboxes[key]
    data[key] = {}
    Object.keys(teebox).forEach(hKey => {
      switch (hKey) {
        case 'rating':
        case 'slope':
          break;
        default:
          data[key][hKey] = teebox[hKey]
          if (!gotD) {
            firstDs[hKey] = { key: hKey, distance: teebox[hKey] } 
          }
      }
    })
    gotD = true
  })
 
  const dds = _.sortBy(firstDs, 'distance').reverse()
  
  divs.push(<div>{'Teebox'}</div>)
  divs.push(<div>{'Rating'}</div>)
  divs.push(<div>{'Slope'}</div>)
    
  Object.keys(teeboxes).forEach(key => {
    const teebox = teeboxes[key] 
    const { rating, slope } = teebox
    divs.push(<div className='gsch'>{_.startCase(key)}</div>)
    divs.push(<div>{rating}</div>)
    divs.push(<div>{slope}</div>)
    dds.forEach(d => {
      divs.push(<div>{d[d.key]}</div>)
    }) 
  })

  let cn = 'golf-holes-container'
  return <div className={cn}>{divs}</div>
}