import React, { useContext, useEffect, useState } from 'react';
import { Icon, Image, Label, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { getAuthIcon } from '../auth/authPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { uniqueKey } from '../common/keys';
import { FullPageContent } from '../components/layout/FullPageContent';
import { allPageTypes } from '../enums/allPageTypes';
import { gEnums } from '../enums/globalEnums';
import { PageItemOptionsMenu } from '../sidebars/PageItemOptionsMenu';
import UiItemSidebar from '../sidebars/UiItemSidebar';
import { getTheme } from '../styles/formatting';
import FullPageWrapper from '../wrappers/FullPageWrapper';

/**
 * 
 * @returns a navigation menu for the app
 */
const PageNavigation = () => {

  const parentContext = useContext(ParentContext);
  const { states, fns, navigate } = parentContext ? parentContext : {}
  const { appUser_state, appSettings_state, page_state, paps_state } = states
  const { pageSettings, pageNav } = page_state ? page_state : {}
  const { navOptions } = pageNav ? pageNav : {}
  const { aps_global, aps_styles, aps_viewItems, aps_views, aps_page, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { events: events_aps } = aps_views ? aps_views : {}
  const { viewItems: viewItems_events } = events_aps ? events_aps : {}
  const { page_fns, storage_fns } = fns

  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { appUserSessionKey, appUserSession, accessLevel } = appUserAccess ? appUserAccess : {}

  // appSettingsContext 
  const { desktopOn } = appSettings_state ? appSettings_state : {}

  // papsContext 
  const { view, pathViews, lastRootPath } = paps_state ? paps_state : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext
  const { desktopMode } = framework_state ? framework_state : {}

  const desktopView = desktopMode || desktopOn ? true : false

  // pageContext 
  const { pushSimple } = page_fns ? page_fns : {}
  const { navigation, themedItems } = aps_global ? aps_global : {}
  const { navigationItems, includeHome, includeSlideout, homeIconImage, useHomeIconImage, showItemActionInNavigation } = navigation ? navigation : {}

  const { appUserCollection, showFavoritesInNavigation, showMyProfileLinkInNavigation, allowChat, showChatInNavigation, allowNotes, showNotesInNavigation } = aps_appUserSettings ? aps_appUserSettings : {}

  const { navigation: navigation_ti } = themedItems ? themedItems : {}
  const { iconSize } = navigation_ti ? navigation_ti : {}

  let { showCaptions } = navigation ? navigation : {}

  const [allowedNavigationItems, setAllowedNavigationItems] = useState()
  const [showFullPage, setShowFullPage] = useState()
  const [showPageItemOptions, setShowPageItemOptions] = useState()

  useEffect(() => {
    if (navigationItems && viewItems_events) {
      const _allowNavigationItems = []
      navigationItems.forEach(navigationItem => {
        if (viewItems_events[navigationItem]) {
          const vie = viewItems_events[navigationItem]
          const { accessManagement } = vie ? vie : {}
          const { accessLevel: accessLevel_nav } = accessManagement ? accessManagement : {}
          if (!accessLevel_nav) {
            _allowNavigationItems.push(navigationItem)
          } else {
            if (accessLevel >= accessLevel_nav) {
              _allowNavigationItems.push(navigationItem)
            }
          }
        }
      })
      setAllowedNavigationItems(_allowNavigationItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowWizard = () => setShowFullPage(!showFullPage)

  const getPagePath = (name) => {
    let pagePath = ''
    let done = false
    const spl = lastRootPath.split('/')
    spl.forEach(sp => {
      if (sp) {
        if (sp === name) {
          pagePath += sp + '/'
          pagePath += pathViews[name]
          done = true
        } else if (!done) {
          pagePath += sp + '/'
        }
      }
    })
    return pagePath
  }

  const pageNavHome = {
    page: null,
    path: null,
    icon: null,
  }
  // let _pageHome;
  // let _pageHomeIcon;
  // let _pagePath;

  if (pathViews) {
    Object.keys(pathViews).forEach(pv => {
      if (pv !== view) {
        if (aps_viewItems && aps_viewItems[pv]) {
          const { pageNavigation } = aps_viewItems[pv]
          const { showPageHome, pageHomeIcon } = pageNavigation ? pageNavigation : {}
          if (showPageHome) {
            pageNavHome.path = getPagePath(pv)
            pageNavHome.page = pv
            pageNavHome.icon = pageHomeIcon
          }
        }
      }
    })
  }

  if (desktopView) { showCaptions = true }

  // from view  
  const { layout: layout_page } = aps_page ? aps_page : {}
  const { layoutType } = layout_page ? layout_page : {}

  const itemTheme = getTheme(aps_global, gEnums.projectStyles.navigation)
  const menuSaC = aps_styles ? aps_styles[gEnums.projectStyles.navigation] : {}

  if (itemTheme) {
    if (itemTheme.size) { menuSaC.className += ' ' + itemTheme.size }
  }

  if (layoutType === 'icons') { return null }

  /**
   * handles the click of the navigation menu item
   * @param {string} item 
   */
  const handleNavClick = (item, isAppUser) => {

    if (isAppUser) {
      pushSimple(item, appUserSessionKey)
    } else {
      switch (item.key) {
        case 'slideout':
          framework_handlers.handleAppSidebar()
          break;

        case allPageTypes.navOptions:
          setShowPageItemOptions(true)
          break;

        case allPageTypes.chat:
        case allPageTypes.conversations:
        case allPageTypes.favorites:
        case allPageTypes.notes:
        case allPageTypes.notifications:
          setShowFullPage(item.key)
          break;

        default:
          if (item.key === pageNavHome.page) {
            navigate(pageNavHome.path)
          } else {
            pushSimple(item)
          }
          break;
      }
    }

  }

  const label = (count) => <Label color='blue' floating style={{ top: '-.3em', marginLeft: '-1.8em' }}>{count}</Label>

  const iconOrImage = (item) => {
    const { icon, iconImage, useIconImage } = item.display ? item.display : {}
    const iconImageUrls = storage_fns.getIconUrl(useIconImage, iconImage)
    const { urls } = iconImageUrls ? iconImageUrls : {}
    const { full, thumbnail } = urls ? urls : {}
    const src = thumbnail ? thumbnail : full
    const { name, color } = icon ? icon : {}
    if (src) {
      return <Image src={src} alt={iconImage} className={'icon-image'} />
    } else {
      if (name) {
        return <Icon size={iconSize ? iconSize : null} name={name.trim()} color={color ? color : null} />
      } else {
        return <Icon size={iconSize ? iconSize : null} name={'file alternate'} />
      }
    }
  }

  const iconLabel = (item, count) => {
    const { icon } = item.display ? item.display : {}
    const { name } = icon ? icon : {}
    return <React.Fragment>
      <Icon name={name} />
      <Label circular attached='top right' color={'green'} size={'tiny'} >
        {count}
      </Label>
    </React.Fragment>
  }

  const menuItem = (item, il, count, isAppUser) => {

    // IMPORTANT: match the key with the view so that the item will be active    
    let lbl = null

    if (item.messageCount && (item.messageCount > 0)) { lbl = label(item.messageCount) }

    return <Menu.Item
      key={uniqueKey('pn', 'mi', item.key, item.index)}
      name={item.key}
      active={item.key === paps_state.viewAs}
      onClick={() => { handleNavClick(item, isAppUser) }}
    >
      {!il && iconOrImage(item)}
      {il && iconLabel(item, count)}
      {showCaptions && lbl}
      {showCaptions && item.display && item.display.caption}
    </Menu.Item>
  }

  /** Return a list of menu items for the page navigation */
  const menuItems = () => {
    let count = 0
    const mis = []

    if (includeSlideout && !desktopView) {
      mis.push(menuItem({ key: 'slideout', display: { icon: { name: 'content' }, caption: 'settings' } }))
      count++
    }

    if (includeHome) {
      mis.push(menuItem({ key: paps_state.landingView, display: { icon: { name: 'home' }, caption: paps_state.appHome, iconImage: homeIconImage, useIconImage: useHomeIconImage } }))
      count++
    }

    if (allowedNavigationItems) {
      allowedNavigationItems.forEach((item, index) => {
        let allow = true
        const vi = aps_viewItems && aps_viewItems[item] ? aps_viewItems[item] : {}
        const { display } = vi ? vi : {}
        const { defaultIcon } = display ? display : {}
        const icon = { name: defaultIcon }
        if (allow) {
          count++
          mis.push(menuItem({ index, key: item, display: { ...display, icon } }))
        }
      })
    }

    if (pageNavHome.page) {
      const vi = aps_viewItems && aps_viewItems[pageNavHome.page] ? aps_viewItems[pageNavHome.page] : {}
      const { display } = vi ? vi : {}
      count++
      mis.push(menuItem({ key: pageNavHome.page, display: { ...display, icon: { name: pageNavHome.icon } } }))
    }

    if (showFavoritesInNavigation) {
      mis.push(menuItem({ key: allPageTypes.favorites, display: { icon: { name: 'star outline' }, caption: 'favorites' } }))
      count++
    }

    if (showMyProfileLinkInNavigation && appUserSessionKey && appUserSession) {
      const icon = getAuthIcon(appUserAccess ? appUserAccess.accessLevel : 0)
      mis.push(menuItem({ key: appUserCollection, display: { icon: { ...icon, color: null }, caption: 'appUser' } }, null, null, true))
      count++
    }

    if (allowNotes && showNotesInNavigation) {
      mis.push(menuItem({ key: allPageTypes.notes, display: { icon: { name: 'edit outline' }, caption: 'Notes' } }))
      count++
    }

    if (allowChat && showChatInNavigation) {
      mis.push(menuItem({ key: allPageTypes.chat, display: { icon: { name: 'conversation' }, caption: 'Chat' } }))
      count++
    }

    if (showItemActionInNavigation && navOptions) {
      mis.push(menuItem({ key: 'navOptions', display: { icon: { name: 'options' }, caption: 'Options' } }))
      count++
    }

    return {
      count: count,
      items: mis
    }
  }

  const fpw_navOptions = () => <FullPageWrapper
    content={<PageItemOptionsMenu />}
    topperCaption={'Options'}
    handleCancel={setShowPageItemOptions}
  ></FullPageWrapper>

  const menu = () => {

    const mis = menuItems()
    const styAndC = Object.assign({}, menuSaC)

    return <Menu
      {...styAndC}
      inverted
      icon={showCaptions ? 'labeled' : null}
      widths={!desktopView && mis.count}
      key={uniqueKey('pn', 'm')} >
      {mis.items}
    </Menu>
  }

  if (!includeSlideout && !includeHome && !allowedNavigationItems) {
    return <Segment basic inverted>
      {'No bottom menus set'}
    </Segment>
  } else {
    if (showFullPage) {
      return <FullPageContent fullPageType={showFullPage} handleCancel={handleShowWizard} />
    } else if (showPageItemOptions) {
      return fpw_navOptions()
    } else {
      return menu()
    }
    // if (showNotes) {
    //   return <UserNotes fullPage={true} handleCancel={handleShowNotes} />
    // } else if (showNotifications) {

    // } else {

    // }
  }

}

export default PageNavigation
