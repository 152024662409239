import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { CreateContext } from '../../../cnr/contexts/CreateContext';
import GoogleSheetsProvider, { GoogleSheetsContext } from '../../../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { googleSheetsTypes } from '../../../cnr/reducers/GoogleSheetsReducer';
import { convertArraysToStrings } from '../../../common/convert';
import { uniqueKey } from '../../../common/keys';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import { EventsViewer } from '../../../components/viewers/EventsViewer';
import { GoogleSheetsViewer } from '../../../components/viewers/GoogleSheetsViewer';
import JsonViewer from '../../../components/viewers/JsonViewer';
import { RegDataViewer } from '../../../components/viewers/RegDataViewer';
import StepWizard from '../../../components/wizard/StepWizard';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';
import { settingFormTypes } from '../../../enums/settingFormTypes';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import SettingsFormEdit from '../../subComponents/SettingsFormEdit';

const stepTypes_normalEvent = {
  eventsViewer: 'eventsViewer',
  projectOptions: 'projectOptions',
  parentDefaults: 'parentDefaults',
  themeColors: 'themeColors',
  googleSheetsDataOptions: 'googleSheetsDataOptions',
  confirmation: 'confirmation',
}

const stepTypes_googleSheetsOnly = {
  googleSheetsData: 'googleSheetsData',
  confirmation: 'confirmation',
}

const stepTypes_google = {
  googleSheetsData: 'googleSheetsData',
  ...stepTypes_normalEvent
}

const stepTypes_googleMerge = {
  googleSheetsData: 'googleSheetsData',
  confirmation: 'confirmation',
}

const stepTypes_reg = {
  registrationData: 'registrationData',
  ...stepTypes_normalEvent
}

const stepTypes_regAndGoogle = {
  registrationData: 'registrationData',
  googleSheetsData: 'googleSheetsData',
  ...stepTypes_normalEvent
}

const stepTypes_previous = {
  googleSheetsData: 'googleSheetsData',
  eventsViewer: 'eventsViewer',
  confirmation: 'confirmation',
}

export const createEventTypes = {
  merge: 'merge',
  previous: 'previous',
  reg: 'reg',
  regAndGoogleSheets: 'regAndGoogleSheets',
}

const CreateEvent = (props) => {

  const { createEventType, addModeType } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { settings } = parentContext ? parentContext : {}
  const { clientSettings } = settings ? settings : {}

  // createContext
  const createContext = useContext(CreateContext)
  const { create_handlers, create_state } = createContext ? createContext : {}
  const { projectResult, updating: updating_cr, updatingCaption: updatingCaption_cr } = create_state ? create_state : {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext);
  const { googleSheets_state, googleSheets_handlers } = googleSheetsContext ? googleSheetsContext : {}
  const {
    previewInfo,
    mergedInfo,
    updating: updating_gs,
    updatingCaption: updatingCaption_gs,
    confirmation,
    projectOptions,
    themeColors,
  } = googleSheets_state ? googleSheets_state : {}

  if (previewInfo && mergedInfo && mergedInfo.projectSettings) {
    previewInfo.projectSettings = mergedInfo.projectSettings
  }

  const _updating = updating_cr ? updating_cr : updating_gs
  const _updatingCaption = updatingCaption_cr ? updatingCaption_cr : updatingCaption_gs

  const _createData = projectResult ? projectResult : previewInfo

  const [regInfo, setRegInfo] = useState({});
  const [appStatus, setAppStatus] = useState({});
  const [dataOptions, setDataOptions] = useState({});
  const [parentDefaults, setParentDefaults] = useState({});
  const [selectedEventKey, setSelectedEventKey] = useState()

  const eventOptions = {
    appStatus,
    parentDefaults,
    projectOptions,
    themeColors,
  }

  useEffect(() => {
    if (_createData) {
      // update global with the eventOptions
      const _previewInfo = { ..._createData }
      if (eventOptions) {
        Object.keys(eventOptions).forEach(key => {
          _previewInfo.projectSettings.global[key] = eventOptions[key]
        })
      }
      switch (createEventType) {
        case createEventTypes.reg:
          create_handlers.handleUpdate_projectResult(_previewInfo)
          break;

        default:
          googleSheets_handlers.handleUpdatePreviewInfo(_previewInfo)
      }
    }

    const { global: global_client } = clientSettings ? clientSettings : {}
    const { themeColors: themeColors_client } = global_client ? global_client : {}
    if (themeColors_client) { googleSheets_handlers.handleThemeColors(themeColors_client) }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [projectOptions, themeColors, appStatus, parentDefaults]);

  const handleProjectOptions = (projectOptions) => googleSheets_handlers.handleProjectOptions(projectOptions)
  const handleThemeColors = (themeColors) => googleSheets_handlers.handleThemeColors(themeColors)
  const handlePreviousPreviewInfo = (previousEventSettings) => googleSheets_handlers.handlePreviousPreviewInfo(previousEventSettings)

  /**
   * creates the event in the database
   */
  const handleCreate = () => {
    switch (createEventType) {
      case createEventTypes.reg:
        create_handlers.handleStartUpdate()
        create_handlers.handleCreate_clientOrEventInDb()
        break;
      default:
        googleSheets_handlers.handleStartUpdate()
        googleSheets_handlers.handleCreateEventFromGs(null, null, addModeType)
    }
  }

  const handleMerge = () => {
    switch (createEventType) {
      case createEventTypes.reg:
        create_handlers.handleStartUpdate()
        create_handlers.handleCreate_clientOrEventInDb()
        break;
      default:
        googleSheets_handlers.handleStartUpdate()
        googleSheets_handlers.handleCreateEventFromGs()
    }
  }

  const handleCreateData = () => {
    switch (createEventType) {
      case createEventTypes.reg:
        // nothing
        break;
      default:
        googleSheets_handlers.handleCreateEventDataFromGs(eventOptions)
    }
  }

  let steps;

  switch (createEventType) {
    case createEventTypes.reg:
      steps = Object.keys(stepTypes_reg)
      break;
    case createEventTypes.regAndGoogleSheets:
      steps = Object.keys(stepTypes_regAndGoogle)
      break;
    case createEventTypes.merge:
      steps = Object.keys(stepTypes_googleMerge)
      break;
    case createEventTypes.previous:
      steps = Object.keys(stepTypes_previous)
      break;
    default:
      switch (addModeType) {
        case gEnums.addModeTypes.googleSheetsOnly:
          steps = Object.keys(stepTypes_googleSheetsOnly)
          break;
        default:
          steps = Object.keys(stepTypes_google)
      }
  }

  // local state  
  const [step, setStep] = useState({ index: 0, name: steps[0] })
  const stepCount = steps.length

  // triggers the handleCreate_clientProjectSettings
  useEffect(() => {
    switch (step.name) {
      case stepTypes_reg.registrationResponse:
        create_handlers.handleFetch_urlFeed(null, regInfo.eventKey)
        break;

      case stepTypes_reg.themeColors:
        if (dataOptions.convertSingleItemArraysToString) {
          const { projectData } = _createData ? _createData : {}
          const { dataCollections } = projectData ? projectData : {}
          if (dataCollections) {
            Object.keys(dataCollections).forEach(key => {
              const dc = dataCollections[key]
              convertArraysToStrings(dc)
            })
          }
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [step]);

  // const handleShowConfirmation = () => { }
  // const handleCancel = () => props.handleCancel()
  // const handleSaveInfo = () => {    console.log('handle save');  }

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes_google.googleSheetsData:
        return previewInfo ? true : false
      case stepTypes_reg.registrationData:
        return projectResult ? true : false
      case stepTypes_previous.eventsViewer:
        return true
      case stepTypes_normalEvent.googleSheetsDataOptions:
        return true
      case stepTypes_normalEvent.projectOptions:
        return true
      case stepTypes_normalEvent.themeColors:
        return true
      case stepTypes_normalEvent.eventStatus:
        return true
      case stepTypes_normalEvent.parentDefaults:
        return true
      case stepTypes_normalEvent.confirmation:
        return true
      default:
        break;
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const settingsForm = (settingsType, formData, updateForm) => <SettingsFormEdit
    key={uniqueKey('ce', settingsType)}
    settingsType={settingsType}
    formData={formData}
    updateForm={updateForm}
    isGlobal={true}
  />

  const _googleData = () => <GoogleSheetsViewer noModal={true} fromCreate={true} />
  const _regData = () => <RegDataViewer noModal={true} fromCreate={true} />
  const _eventsViewer = () => <EventsViewer noModal={true} fromCreate={true} selectedEventKey={selectedEventKey} handleSelectedEvent={setSelectedEventKey} handlePreviewInfo={handlePreviousPreviewInfo} />

  const saveButtons = () => <UiSaveButtons
    preview={{ oc: handleCreateData, caption: 'Create New Event (Data)', color: 'blue', disabled: !_createData, icon: appIconTypes.arrowRight }}
    save={createEventType === createEventTypes.merge
      ?
      { oc: handleMerge, caption: 'Merge Event', color: 'blue', disabled: !_createData, icon: appIconTypes.arrowRight }
      :
      { oc: handleCreate, caption: 'Create New Event', color: 'blue', disabled: !_createData, icon: appIconTypes.arrowRight }
    }
  />

  const save_info = () => <Wrapper
    content={<JsonViewer json={_createData} name={'createInfo'} />}
    footer={saveButtons()}
    wrapperType={wrapperTypes.padded}
  />

  const stepCaption = () => {
    const name = _.startCase(step.name)
    const stepCaption = ' (' + (step.index + 1) + ' of ' + (steps.length) + ')'
    switch (step.name) {
      default:
        return name + stepCaption
    }
  }

  const content = () => {
    switch (step.name) {
      case stepTypes_google.googleSheetsData:
        return _googleData()
      case stepTypes_reg.registrationData:
        return _regData()
      case stepTypes_previous.eventsViewer:
        return _eventsViewer()
      case stepTypes_normalEvent.googleSheetsDataOptions:
        return settingsForm(settingFormTypes.googleSheetsDataOptions, dataOptions, setDataOptions)
      case stepTypes_normalEvent.projectOptions:
        return settingsForm(settingFormTypes.projectOptions, projectOptions, handleProjectOptions)
      case stepTypes_normalEvent.themeColors:
        return settingsForm(settingFormTypes.themeColors, themeColors, handleThemeColors)
      case stepTypes_normalEvent.eventStatus:
        return settingsForm(settingFormTypes.appStatus, appStatus, setAppStatus)
      case stepTypes_normalEvent.parentDefaults:
        return settingsForm(settingFormTypes.parentDefaults, parentDefaults, setParentDefaults)
      case stepTypes_normalEvent.confirmation:
        return save_info()
      default:
        break;
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    darkMode={true}
    noModal={true}
    fullMode={true}
    setStep={setStep}
    step={step}
    stepCaption={stepCaption()}
    steps={steps}
    topperCaption={_.startCase('createEvent')}
    updating={_updating}
    updatingCaption={_updatingCaption}
    confirmation={confirmation}
  ></StepWizard>

  return step ? stepWizard() : <div></div>

}

export const CreateEventWithProvider = (props) => {
  return <GoogleSheetsProvider googleSheetsType={googleSheetsTypes.create}>
    <CreateEvent {...props} />
  </GoogleSheetsProvider>
}