import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { golfEventHandlers, golfEventInitialState, golfEventReducer } from '../reducers/GolfEventReducer';

export const GolfEventContext = createContext();

/**
 * @provides tournamentTeams 
 * @provides golfCourse  
 * @provides holes 
 * @provides golfMatchData  
 * @provides roundsStatus 
 * @provides golfEvent_handlers 
 * @provides golfTeam1
 * @provides golfTeam2
 * @provides golfTeam1Style
 * @provides golfTeam2Style
 */

const GolfEventProvider = (props) => {

  const { directReturn } = props ? props : {}

  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ? parentContext : {}
  const { paps_state } = states
  const { page_fns } = fns

  // papsContext
  const { viewKey } = paps_state ? paps_state : {}

  // pageContext  
  const { pushSimple } = page_fns ? page_fns : {}

  // golfEventReducer
  const init_state = { paps_state, pushSimple, viewKey }
  const [golfEvent_state, golfEvent_dispatch] = useReducer(golfEventReducer, init_state, golfEventInitialState)
  const golfEvent_handlers = golfEventHandlers(golfEvent_dispatch, golfEvent_state)
  const { golfCourses, golfers } = golfEvent_state

  useEffect(() => {
    golfEvent_handlers.handleGet_clientSubData()
    golfEvent_handlers.handleGet_eventSubData()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  if (golfCourses && golfers) {
    if (directReturn) {
      return { golfEvent_handlers, golfEvent_state }
    } else {
      return <GolfEventContext.Provider value={{ golfEvent_handlers, golfEvent_state }}>
        {props.children}
      </GolfEventContext.Provider>
    }
  } else {
    if (directReturn) {
      return { golfEvent_handlers }
    } else {
      return <GolfEventContext.Provider value={{ golfEvent_handlers }}>
        {props.children}
      </GolfEventContext.Provider>
    }
  }
};

export default GolfEventProvider 