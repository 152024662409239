import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Menu, Segment, Tab } from 'semantic-ui-react'
import DataProvider from '../../cnr/contexts/DataContext'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { UiItemContext } from '../../cnr/contexts/UiItemContext'
import { uniqueKey } from '../../common/keys'
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths'
import { fs_get_data } from '../../firestoreData/appData/fsAppData'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import { DataManagementViewer } from './DataManagementViewer'

const _useVerticalMenu = false

/**
 * This is a google sheet like display of the data in the google sheet.
 * @param {object} props (previewInfo, handleActiveTab)
 * @previewInfo (projectData)
 * @projectData (dataCollections)
 * @returns a `Tab` with a `Tab` for each `dataCollection` in `dataCollections` displayed in a `DataManagementViewer`
 */
const AppDataViewer = (props) => {

  const { projectData, handleActiveTab } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, paps_state } = states ? states : {}
  const { pathViews } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_viewItems } = pageSettings ? pageSettings : {}

  // uiItemContext - will not exist if from landing page
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { view: view_item } = item_state ? item_state : {}

  // local state
  const [dataTabs, setDataTabs] = useState()
  const [dataMenuItems, setDataMenuItems] = useState()
  const [activeTabIndex, setActiveTabIndex] = useState()
  const [activeMenuItem, setActiveMenuItem] = useState()
  const [viewItemData, setViewItemData] = useState()
  const [viewItemDatas, setViewItemDatas] = useState({})
  const [viewItem, setViewItem] = useState()

  const callback_appData = (vid) => {
    const _vlds = { ...viewItemDatas }
    setViewItemData(vid)
    _vlds[activeTabIndex] = vid
    setViewItemDatas(_vlds)
  }

  useEffect(() => {
    // if no item_state
    if (aps_viewItems) {
      const _vit = Object.keys(aps_viewItems).sort()
      const _dataTabs = []
      const _dataMenuItems = []
      _vit.forEach(vi => {
        const _vi = aps_viewItems[vi]
        if (view_item) {
          if (view_item === vi) {
            _dataTabs.push(vi)
            _dataMenuItems.push(vi)
          }
        } else {
          if (_vi) {
            _dataTabs.push(vi)
            _dataMenuItems.push(vi)
          }
        }
      })
      setDataTabs(_dataTabs)
      setDataMenuItems(_dataMenuItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aps_viewItems]);

  useEffect(() => {
    if (activeMenuItem) {
      const collectionName = dataMenuItems[activeMenuItem]
      const _refPath = createRefPath_event(pathViews, [collectionName])
      setViewItem(aps_viewItems[collectionName])
      if (!viewItemDatas[activeTabIndex]) {
        fs_get_data({ refPath: _refPath, callback: callback_appData })
      } else {
        setViewItemData(viewItemDatas[activeTabIndex])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuItem]);

  useEffect(() => {
    if (activeTabIndex >= 0) {
      const collectionName = dataTabs[activeTabIndex]
      const _refPath = createRefPath_event(pathViews, [collectionName])
      setViewItem(aps_viewItems[collectionName])
      if (!viewItemDatas[activeTabIndex]) {
        fs_get_data({ refPath: _refPath, callback: callback_appData })
      } else {
        setViewItemData(viewItemDatas[activeTabIndex])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  const handleDataCollectionChange = (dmi) => setActiveMenuItem(dmi)

  const handleTabChange = (e, { activeIndex }) => {
    const _activeTabName = dataTabs[activeIndex]
    setActiveTabIndex(activeIndex)
    handleActiveTab && handleActiveTab({ index: activeIndex, name: _activeTabName })
  }

  /**
   * 
   * @param {object} vi 
   * @returns 
   */
  const tabContent = (vi) => <DataProvider
    uivi={vi}
    forAppViewer={true}>
    <DataManagementViewer
      fromDataViewer={true}
      projectData={projectData}
      uivi={vi}
      viewItem={viewItem}
      viewItemData={viewItemData}
    />
  </DataProvider>

  const pane = (tabName, vi) => {
    return {
      menuItem: tabName,
      render: () => <Tab.Pane attached={false}>{tabContent(vi)}</Tab.Pane>,
    }
  }

  /**
   * 
   * @returns a Menu wrapped in a Segment
   * 
   */
  const dataCollectionMenu = () => {
    const mis = []
    dataMenuItems.forEach(dmi => {
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', dmi)} onClick={() => { handleDataCollectionChange(dmi) }}>{_.startCase(dmi)}</Menu.Item>)
    })
    return <Segment basic><Menu vertical fluid inverted className={'adv-menu'}>{mis}</Menu></Segment>
  }

  /**
   * 
   * @returns an array of tab panes, one for each dataTab
   */
  const tabContents = () => {
    const p = []
    dataTabs.forEach(dt => {
      p.push(pane(_.startCase(dt), dt))
    })
    return p
  }

  const tab = () => <Tab
    activeIndex={activeTabIndex}
    menu={{ color: 'black', inverted: true, className: 'adv-menu', size: 'tiny' }}
    panes={dataTabs && tabContents()}
    onTabChange={handleTabChange}
    className={'gst vit adv-tab'}
  />

  if (_useVerticalMenu) {
    return <Wrapper
      header={dataMenuItems && dataCollectionMenu()}
      content={dataMenuItems && activeMenuItem && tabContent(activeMenuItem)}
      wrapperType={wrapperTypes.splitLR}
    />
  } else {
    return tab()
  }

}

export default AppDataViewer