import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { Icon, Label, Visibility } from 'semantic-ui-react';
import { DataContext } from '../cnr/contexts/DataContext';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { PageAreaContext } from '../cnr/contexts/PageAreaContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { g_cns } from '../common/cns';
import { getCheckInCount } from '../common/convert';
import { uniqueKey } from '../common/keys';
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';
import { UiSwiperWithProvider } from '../components/swipers/UiSwiper';
import { gEnums } from '../enums/globalEnums';
import { _animateTypes } from '../motions/AnimateComponent';
import { PageItemDimmerContext } from '../page/PageItemDimmer';
import UiFilterPopup from '../sidebars/UiFilterPopup';
import UiItemPopupIcon from '../sidebars/UiItemPopupIcon';
import UiItemSidebar from '../sidebars/UiItemSidebar';
import { getThemeColor } from '../styles/formatting';
import ItemWrapper from '../wrappers/ItemWrapper';
import SemUI from './SemUI';
import { fullFilterTypes, UiDataFilters } from './UiDataFilters';
import ErrorBoundary from '../components/errorHandling/ErrorBoundery';

const AlphabetGrid = React.lazy(() => import('../components/layout/AlphabetGrid'));
const AlphabetMenu = React.lazy(() => import('../components/menus/AlphabetMenu'));
const ImageMap = React.lazy(() => import('../components/swipe/ImageMap'));
const SliderHeader = React.lazy(() => import('./SliderHeader'));
const UiDataModifications = React.lazy(() => import('./modification/UiDataModifications'));
const UiFilter = React.lazy(() => import('./UiFilter'));
const UiFilterSidebar = React.lazy(() => import('../sidebars/UiFilterSidebar'));
const UiItemAction = React.lazy(() => import('../pageItem/modification/UiItemAction'));

/** Builds the UiItem (element) for the PageItem 
 * @returns <SemUI />
 * @returns <UiMaskFilter />  
 * @calledFrom <PageItem/>
*/
const UiItem = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ? parentContext : {}
  const { page_state, paps_state, transition_state } = states
  const { page_handlers } = handlers
  const { page_fns } = fns
  const { view, viewKey } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { pushSimple } = page_fns ? page_fns : {}

  const { dataOptions, navigation } = aps_global ? aps_global : {}
  const { fetchExistingItems } = dataOptions ? dataOptions : {}
  const { showItemActionInNavigation } = navigation ? navigation : {}

  // // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ? frameworkContext : {}
  const { uiItems, pageItems, desktopMode } = framework_state ? framework_state : {}

  // transitionContext  
  const { transitions } = transition_state ? transition_state : {}
  const transition_title = transitions ? transitions[_animateTypes.title] : null
  const { showTransition: showTransition_title } = transition_title ? transition_title : {}

  // // pageContext  
  const pageAreaContext = useContext(PageAreaContext)
  const dataContext = useContext(DataContext);

  const pageItemDimmerContext = useContext(PageItemDimmerContext)
  const { pageItemWrapper_handlers } = pageItemDimmerContext ? pageItemDimmerContext : {}

  // dataContext
  const { data_state } = dataContext ? dataContext : {}
  const { viewListData } = data_state ? data_state : {}
  const dataCount = viewListData ? Object.keys(viewListData).length : 0

  // uiItemContext
  const uiItemContext = useContext(UiItemContext)
  const { item_state, item_handlers } = uiItemContext ? uiItemContext : {}
  const { showSingleItemEdit, navigationOptions, allowPopups, viewItem, dataOpts, filterOpts, pageOpts, viewItemOpts, modifyActionType, modifyMappingOpen, vld } = item_state ? item_state : {}
  const { _display, itemWrapType, layout, layoutType } = pageOpts ? pageOpts : {}
  const { showCount } = _display ? _display : {}
  const { isCombinedData, uivi } = dataOpts ? dataOpts : {}
  const { dataModifications, key: key_viewItem } = viewItem ? viewItem : {}
  const { credentialing } = dataModifications ? dataModifications : {}
  const { showCheckedIn } = credentialing ? credentialing : {}

  const [currentItem, setCurrentItem] = useState()

  const {
    alphabetActive,
    alphaDisplayType,
    alphaFilterAfter,
    alphaFilterType,
    caption,
    displayType,
    fetchByAlphabet,
    grouping,
    searchOnPage,
    showFilter,
    showSearch,
  } = viewItemOpts ? viewItemOpts : {}

  const {
    divideIntoGroups,
    filterSidebarOpen,
    filterStatusType,
    groups,
    searchValue,
  } = filterOpts ? filterOpts : {}

  const componentContexts = { dataContext, pageAreaContext }

  const sidebarOn = desktopMode

  // sets up the pageDimmer
  const itemRef = useRef()
  const { current: currentRef } = itemRef ? itemRef : {}

  useEffect(() => {
    pageItemWrapper_handlers && pageItemWrapper_handlers.killPageDimmer()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentRef]);

  useEffect(() => {
    let currentItem;
    if (fetchExistingItems) {
      if (viewKey) {
        if (uiItems && uiItems[uivi] && uiItems[uivi][viewKey]) {
          currentItem = uiItems[uivi][viewKey]
        }
      } else {
        if (pageItems && pageItems[uivi]) {
          currentItem = pageItems[uivi]
        }
      }
    }
    if (currentItem) {
      setCurrentItem(currentItem)
      console.log('EXITING', uivi, viewKey)
    } else {
      // console.log('GET', uivi, viewKey)
      const _uiItem = suspenseContent()
      setCurrentItem(_uiItem)
      if (fetchExistingItems) {
        framework_handlers.handleUiItem({ view, viewKey, uivi, _uiItem })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [vld, viewListData]);

  useEffect(() => {
    page_handlers.handlePageNav(navigationOptions, uiItemContext)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [navigationOptions]);

  let { groupType, groupActive } = grouping ? grouping : {}
  if (!groupType) { groupType = gEnums.groupTypes.swipeableTab }

  let slider = null

  const viewListCount = vld ? Object.keys(vld).length : 0

  if (layoutType === gEnums.layoutTypes.horizontalSliders && viewListCount > 0) {
    if (!slider) {
      slider = {
        horizontalSlider: true,
        showSliderCaption: true,
        sliderCount: layout.viewSliderCount ? layout.viewSliderCount : 10,
        sliderCaption: caption,
        sliderGoToCaption: layout.sliderGoToCaption ? layout.sliderGoToCaption : 'View All'
      }
      if (viewListCount <= 1) { slider.sliderGoToCaption = null }
    }
  }

  const alphaDisplay = () => {
    if (fetchByAlphabet) {
      return <AlphabetMenu />
    } else {
      if (alphabetActive && alphaDisplayType && !groupActive) {
        switch (alphaDisplayType) {
          case gEnums.alphaDisplayTypes.tab:
            return <AlphabetMenu />
          default:
            return null
        }
      }
    }
    return null
  }

  /**
   * 
   * @returns a tabMenu with the alpha characters
   */
  const alphaTabMenu = () => {
    switch (alphaFilterType) {
      case gEnums.alphaFilterTypes.always:
        return alphaDisplay()
      case gEnums.alphaFilterTypes.afterLimit:
        const limit = alphaFilterAfter ? alphaFilterAfter : 100
        if (dataCount > limit) {
          return alphaDisplay()
        }
        break;
      default:
        if (fetchByAlphabet) { return alphaDisplay() }
    }
    return null
  }

  const uiCheckedIn = () => {
    const total = getCheckInCount(viewListData, view, viewKey)
    return <Label attached='bottom left' size={'mini'} color={'grey'} style={{ margin: '1em' }}>
      <Icon name='check' />
      {total + '/' + dataCount}</Label>
  }

  const uiCount = () => <Label attached='bottom left' size={'mini'} color={'grey'} style={{ margin: '1em' }}>
    <Icon name='hashtag' />
    {dataCount}</Label>

  /**
   * 
   * @returns itemCaption, UiFilter, alphaTabMenu, SliderHeader
   */
  const ui_header = () => <React.Fragment>
    <div className={g_cns.item_caption}>{props.caption && itemCaption(props.caption)}</div>
    <div className={g_cns.item_filter}> {(showFilter || (showSearch && searchOnPage)) && <UiFilter />}</div>
    <div className={g_cns.item_alpha}>{alphabetActive && !groupActive && alphaTabMenu()}</div>
    {slider && <SliderHeader viewItem={viewItem} slider={slider} pushSimple={pushSimple} />}
  </React.Fragment>

  const ui_swiper = () => <UiSwiperWithProvider
    swipeContentType={gEnums.swipeContentTypes.groupedItems}
    fn_getContent={getSemUi}
  />

  /**
   * 
   * @param {string} key 
   * @returns The SemUl
   * @description - This is where the swipe items get the data
   */
  const getSemUi = (key) => {
    if (key) {
      item_handlers.handleCurrentGroupKey(key)
      return { loaded: true, key, content: <SemUI key={uniqueKey('sigi', key)} selectedGroupItem={key} /> }
    } else {
      return <SemUI />
    }
  }

  /**
   * 
   * @param {boolean} useSwiper 
   * @returns the content of the ui
   */
  const ui_content = (useSwiper) => {
    let _useSwiper = useSwiper
    if (searchValue) { _useSwiper = false }
    const content = <React.Fragment>
      {filterStatusType !== gEnums.filterStatusTypes.showing && !_useSwiper && getSemUi()}
      {filterStatusType === gEnums.filterStatusTypes.showing && <UiDataFilters fullFilterType={fullFilterTypes.item} />}
      {filterStatusType !== gEnums.filterStatusTypes.showing && _useSwiper && ui_swiper()}
      {showCheckedIn && uiCheckedIn()}
      {showCount && !showCheckedIn && uiCount()}
      <div ref={itemRef}></div>
    </React.Fragment>
    return content
  }

  const ui_mapping = () => <ImageMap imageMapType={gEnums.imageMapTypes.location} />

  /**
   * 
   * @param {boolean} useSwiper 
   * @returns <ItemWrapper with ui_header, UiItemAction and UiItemPopup
   */
  const itemContent = (useSwiper) => {
    return modifyMappingOpen ? ui_mapping() : <ItemWrapper
      header={ui_header()}
      content={modifyActionType ? <UiItemAction /> : ui_content(useSwiper)}
      itemPopup={!modifyActionType && allowPopups && !showItemActionInNavigation && <UiItemPopupIcon componentContexts={componentContexts} />}
      displayType={displayType}
      display={_display}
      useTitleTransition={showTransition_title}
      key_viewItem={key_viewItem}
    ></ItemWrapper>
  }

  const hv = () => {
    const elems = document.querySelectorAll('.desktop-page-nav > .menu > .item')
    for (let elem of elems) { elem.className = 'item' }
    const elem = document.getElementById('appsbi-' + uivi)
    if (elem) { elem.className += " active" }
  }

  const itemCaption = (content) => <Visibility offset={[10, 10]} onTopPassed={hv} once={false} >
    {content}
  </Visibility>

  const mainContent = (useSwiper) => {

    const cid = 'siv-' + uivi
    let containerCn = g_cns.item_container + ' ' + layoutType

    if (allowPopups) { containerCn += ' popup' }
    if (isCombinedData) { containerCn += ' cbn' }
    if (desktopMode && itemWrapType) { containerCn += ' iwt-' + itemWrapType }

    let containerStyle = {}

    if (props.viewItem_app && props.viewItem_app.desktopDisplay && props.viewItem_app.desktopDisplay.backgroundColor) {
      const tc = getThemeColor(aps_global, props.viewItem_app.desktopDisplay.backgroundColor.color)
      containerStyle.backgroundColor = tc
    }

    if (alphabetActive) {
      switch (alphaFilterType) {

        case gEnums.alphaFilterTypes.afterLimit:
        case gEnums.alphaFilterTypes.always:

          switch (alphaDisplayType) {
            case gEnums.alphaDisplayTypes.vertical:
              return <div className={'split-container vertical'}>
                <div className={'split-content'}>
                  <div className={containerCn} id={cid}>
                    {itemContent(useSwiper)}
                  </div>
                </div>
                <div className={'split-menu'}>
                  <AlphabetMenu />
                </div>
              </div>

            case gEnums.alphaDisplayTypes.block:
              return <div className={containerCn} id={cid}>
                {slider && <SliderHeader viewItem={viewItem} slider={slider} pushSimple={pushSimple} />}
                {itemContent(useSwiper)}
                <UiFilterPopup />
                {!modifyActionType && allowPopups && <UiItemPopupIcon componentContexts={componentContexts} />}
              </div>
            default:
            // nothing
          }
          break;
        default:
        // nothing
      }
    }


    return itemContent(useSwiper, containerStyle)

  }

  const getUseSwiper = () => {
    let _useSwiper = false
    // groupType
    if (divideIntoGroups) {
      if (groups) {
        if (groups.length > 1) {
          switch (layoutType) {
            case gEnums.layoutTypes.horizontalSliders:
              break;
            default:
              switch (groupType) {
                case gEnums.groupTypes.swipeableGroup:
                  _useSwiper = false
                  break;
                case gEnums.groupTypes.swipeableTab:
                  _useSwiper = true
                  break;
                default:
                // nothing
              }
          }
        }
      }
    }
    return _useSwiper
  }

  const dataContent = () => {
    try {
      return mainContent(getUseSwiper())
    } catch (error) {
      console.error(error)
      return <div>UiItem Error</div>
    }
  }

  const uiContent = () => {
    if (showSingleItemEdit) {
      return <UiDataModifications modifyActionType={gEnums.dataUpdateTypes.edit} />
    } else {
      switch (layoutType) {
        case gEnums.layoutTypes.horizontalSliders:
          return <div className={g_cns.app_horz_item}>
            {dataContent()}
          </div>
        default:
          if (sidebarOn) {
            return dataContent()
          } else {
            if (filterSidebarOpen) {
              return <UiFilterSidebar
                content={dataContent()}
                sidebarContent={<AlphabetGrid />}
                item_state={item_state}
                item_handlers={item_handlers}
              />
            } else {
              if (modifyActionType || !allowPopups) {
                return dataContent()
              } else {
                return <UiItemSidebar
                  content={dataContent()}
                  componentContexts={componentContexts}
                />
              }
            }
          }
      }
    }
  }

  const suspenseContent = () => <ErrorBoundary origin={'uiItem'}>
    <Suspense fallback={<SuspenseDimmer origin={'Ui Item'} />}>
      {uiContent()}
    </Suspense>
  </ErrorBoundary>

  if (currentItem) {
    return suspenseContent()
  } else {
    return <div></div>
  }


}

export default UiItem