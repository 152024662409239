import _ from 'lodash';
import React from 'react';
import { Grid, Icon, Label, Menu, Sidebar } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { getArrayIndex } from '../../common/sorting';
import { CalMonths } from '../calendar/CalMonths';

export const menuStyles = {
  menuSliderWrapCal: {
    margin: 0,
  },
  menuSliderWrapSeg: {
    margin: 0,
    textAlign: 'center'
  },
  menuSliderWrapSmall: {
    margin: 0,
  },
  statMenuItem: {
    width: '21%',
    fontWeight: 'bold'
  },
}

export const menuWidths = {
  1: {
    full: '100%'
  },
  3: {
    lr: '15%',
    mid: '70%'
  },
  4: {
    lr: '12%',
    mid: '66%'
  },
  6: {
    lr: '12%',
    mid: '21%',
    midPlus: '22%',
    midAll: '64%'
  },
}

const sidebar = (menuItems, visible, isAlpha, setVisible, inverted, type, sidebarType) => {
  // LOOK
  const keyy = sidebarType ? sidebarType + '-' + type : type
  if (isAlpha) {
    return <Sidebar as={Menu} key={uniqueKey(keyy)} onHide={() => setVisible && setVisible(false)} color='blue' vertical inverted={inverted} animation='overlay' direction='top' visible={visible} className={'menu-alphabet-slider ms-' + type}>
      {menuItems}
    </Sidebar>
  } else {
    return <Sidebar as={Menu} key={uniqueKey(keyy)} onHide={() => setVisible && setVisible(false)} color='blue' vertical inverted={inverted} animation='overlay' direction='top' visible={visible} className={'menu-slider-sidebar ms-' + type}  >
      {menuItems}
    </Sidebar>
  }
}

const getCount = (items, item, menuGroups, menuGroupProp) => {
  const x = getArrayIndex(items, item)
  const c = menuGroups ? menuGroups[x] : null
  const g = c && menuGroupProp ? c[menuGroupProp] : null
  return g ? Object.keys(g).length : null
}

export const calSidebar = (sidebarItem, sidebar_handlers, type, isAlpha, sbProps) => <Sidebar key={uniqueKey('ms', 'cs')} color='blue' animation='overlay' direction='top' visible={sidebarItem.show} className={(isAlpha ? 'menu-alphabet-slider' : 'menu-slider-sidebar ms-' + type)} >
  <CalMonths sidebarItem={sidebarItem} sidebar_handlers={sidebar_handlers} type={type} sbProps={sbProps} />
</Sidebar>

export const sidebarBase = (sidebarItem, sidebar_handlers, type, opts) => {

  const keyy = 'sb-' + type + '-'

  const { items, show: isVisible, keyAs } = sidebarItem
  const { handleCloseSidebar, handleGenericItemClick: handleItemClick } = sidebar_handlers

  // isAlpha, menuGroups, menuGroupProp, useStartCase
  const { isAlpha, menuGroups, menuGroupProp, useStartCase, inverted, sidebarType } = opts ? opts : {}
  const mis = []

  const clickType = keyAs ? keyAs : type

  const mi = (index, key, value) => (
    <Menu.Item key={uniqueKey('msmi', keyy, key)} onClick={e => handleItemClick(index, key, clickType)}>{value}</Menu.Item>
  )

  if (items) {
    items.forEach((item, index) => {
      const c = getCount(items, item, menuGroups, menuGroupProp)
      mis.push(mi(index, 'smts-' + item + index, baseGrid(item, 'black', c, useStartCase)))
    })
  }

  return sidebar(mis, isVisible, isAlpha, handleCloseSidebar, inverted, type, sidebarType)
}

export const sidebarGenericStat = (items, handleItemClick, isVisible, type) => {
  const mis = []

  const mi = (index, key, value) => (
    <Menu.Item key={key} onClick={e => handleItemClick(index, key, type)} style={{ padding: 0 }}>{value}</Menu.Item>
  )

  items.forEach((item, index) => {
    mis.push(mi(index, 'smts-' + item, statGenericGrid(item, 'black')))
  })

  return sidebar(mis, isVisible)
}

export const sidebarMenuPositionStat = (positions, handleItemClick, isVisible, action) => {
  const mis = []

  const mi = (index, key, value) => (
    <Menu.Item key={key} onClick={e => handleItemClick((index), key)} style={{ padding: 0 }}>{value}</Menu.Item>
  )

  Object.keys(positions).forEach((key, index) => {
    const position = positions[key]
    mis.push(mi(index, key, statPositionGrid(true, key, position, action, 'black')))
  })

  return sidebar(mis, isVisible)
}

export const menuSliderItemsName = (items, handleItemClick, isVisible) => {
  const mis = []
  Object.keys(items).forEach((key, index) => {
    const item = items[key]
    const name = (item.altName) ? item.altName : item.name
    mis.push(
      <Menu.Item key={key} onClick={e => handleItemClick((index), key)}>
        {name}
      </Menu.Item>
    )
  })

  return sidebar(mis, isVisible)
}

const statPositionGrid = (asGrid, key, position, action, color) => {
  const items = []
  let userIcon = getIcon(position.name)
  let actionIcon = getIcon(action)
  let percIcon = getActionIcon(action)
  if (asGrid) {
    if (position.calcs && position.calcs[action] && position.calcs[action] && position.calcs[action].ta) {
      const calcs = position.calcs[action]
      items.push(<Grid.Column key={uniqueKey('spg', 1)} width={2}><Icon name={actionIcon} /></Grid.Column>)
      items.push(<Grid.Column key={uniqueKey('spg', 2)} width={4}><Icon name={userIcon} />{key}</Grid.Column>)
      items.push(<Grid.Column key={uniqueKey('spg', 3)} width={5}><Icon name={percIcon} />{calcs.perc}</Grid.Column>)
      items.push(<Grid.Column key={uniqueKey('spg', 4)} width={5}>{calcs.ta}</Grid.Column>)
    } else {
      items.push(<Grid.Column key={uniqueKey('spg', 1)} width={2}><Icon name={actionIcon} /></Grid.Column>)
      items.push(<Grid.Column key={uniqueKey('spg', 2)} width={14}><Icon name={userIcon} />{key}</Grid.Column>)
    }
    return <Grid padded columns={4}>
      <Grid.Row style={{ borderBottom: '1px solid darkgrey' }}>
        {items}
      </Grid.Row>
    </Grid>
  } else {
    return statPositionMenu(key, position, action, color)
  }
}

const statGenericGrid = (item, color) => {
  const items = []
  let icon = getIcon(item)
  items.push(<Grid.Column key={uniqueKey('sgg', icon)} width={16}><Icon name={icon} />{item}</Grid.Column>)

  return <Grid padded columns={4}>
    <Grid.Row style={{ borderBottom: '1px solid darkgrey' }}>
      {items}
    </Grid.Row>
  </Grid>
}

const baseGrid = (item, color, c, useStartCase) => {
  const items = []
  if (c) {
    items.push(<div key={uniqueKey('bg', 1)}>{useStartCase ? _.startCase(item) : item}</div>)
    items.push(<Label style={{ marginLeft: '2em' }}>{c}</Label>)
  } else {
    items.push(<div key={uniqueKey('bg', 2)}>{useStartCase ? _.startCase(item) : item}</div>)
  }
  return items
}

const statPositionMenu = (key, position, action, color) => {
  const items = []
  const width = '21%'
  if (position.calcs && position.calcs[action] && position.calcs[action] && position.calcs[action].ta) {
    const calcs = position.calcs[action]
    items.push(<Menu.Item key={uniqueKey('spm', 1)} width={width} name={key} />)
    items.push(<Menu.Item key={uniqueKey('spm', 2)} width={width} name={calcs.perc} />)
    items.push(<Menu.Item key={uniqueKey('spm', 3)} width={width} name={calcs.ta} />)
  } else {
    items.push(<Menu.Item key={uniqueKey('spm', 1)} width={width} name={key} />)
  }
  return items
}

const getActionIcon = (item) => {
  switch (item) {
    case 'attack':
      return 'percentage'
    case 'serve':
      return 'line graph'
    case 'pass':
      return 'line graph'
    default:
      return 'user'
  }
}

const getIcon = (item) => {
  switch (item) {
    case 'zone':
      return 'external'
    case 'stat':
      return 'bar chart'
    case 'pie':
      return 'pie chart'
    case 'attack':
      return 'hand rock'
    case 'serve':
      return 'hand paper'
    case 'pass':
      return 'target'
    case 'All':
      return 'users'
    case 'AR':
      return 'circle notched'
    case 'R1':
    case 'R2':
    case 'R3':
    case 'R4':
    case 'R5':
    case 'R6':
      return 'refresh'
    default:
      return 'user'
  }
}