import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { dateTimeSplits, formatItem, formatTypes } from '../../common/dateFormatting';
import { gEnums } from '../../enums/globalEnums';
import SemCards from './SemCards';

const widths = {
  left: 3,
  right: 13,
  full: 16
}

const SemSchedule = (props) => {

  const { groupType, componentContexts, card_props, groupDataProps } = props
  const { groups } = groupDataProps ? groupDataProps : {}

  if (card_props) {
    card_props.scheduleType = 'list'
  }

  const dateDiv = (df) => {
    switch (groupType) {
      case gEnums.groupTypes.scheduleTop:
        return <div>{df}</div>
      default:
        return <div className={'dater'}>
          <div>{df.day}</div>
          <div>{df.dow}</div>
        </div>
    }
  }

  const gridColumn = (data, df, width) => <Grid.Column width={width}>
    {df && <Segment basic>{df ? dateDiv(df) : '?'}</Segment>}
    <SemCards cardProps={card_props} componentContexts={componentContexts} viewListData={data} />
  </Grid.Column>

  // const stickyColumn = (data, df, width, index) => <Grid.Column width={width}>
  //   <Ref innerRef={contextRef}>
  //     <Segment basic>
  //       <SemCards cardProps={card_props} componentContexts={componentContexts} viewListData={data}   />
  //       <Rail position='right'>
  //         <Sticky context={contextRef}>
  //           <Label>{index}</Label>
  //         </Sticky>
  //       </Rail>
  //     </Segment>
  //   </Ref>
  // </Grid.Column>

  let columns = 2

  switch (groupType) {
    case gEnums.groupTypes.scheduleTop:
      columns = 1
      break;
    default:
    // nothing
  }

  const gridRow = (group, index) => {
    const { key: rowDate, data } = group
    switch (groupType) {
      case gEnums.groupTypes.scheduleTop:
        const _date = new Date(rowDate)
        const _dateFormatted = formatItem(formatTypes.shortDate, _date)
        return <Grid.Row stretched>
          {gridColumn(data, _dateFormatted, widths.full)}
        </Grid.Row>
      default:
        let df = dateTimeSplits(rowDate)
        return <Grid.Row stretched>
          {/* {stickyColumn(data, null, widths.right, index)} */}
          <Grid.Column width={widths.left}>
            <Segment basic>{df ? dateDiv(df) : '?'}</Segment>
          </Grid.Column>
          {gridColumn(data, null, widths.right)}
        </Grid.Row>
    }
  }

  const gridRows = () => groups.map((group, index) => gridRow(group, index))
  const schedule = () => <Grid className={'schedule-grid'} columns={columns} divided>{gridRows()}</Grid>

  return schedule()

}

export default SemSchedule
