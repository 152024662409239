import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import GoogleFormsProvider, { GoogleFormsContext } from '../../cnr/contexts/GoogleFormsContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import { openGoogleForm } from '../../viewSettings/helpers/settingsLinks';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import PendingWait from '../alerts/pendings/PendingWait';
import UiSaveButtons from '../buttons/UiSaveButtons';
import GoogleSheetsTabs from './GoogleSheetsTabs';

export const GoogleFormsViewer = () => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states ? states : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { googleForms } = aps_global ? aps_global : {}
  const { googleFormsId, googleFormsResponseId } = googleForms ? googleForms : {}

  const googleFormsContext = useContext(GoogleFormsContext)
  const { googleForms_state, googleForms_handlers } = googleFormsContext ? googleFormsContext : {}

  const {
    previewInfo
  } = googleForms_state ? googleForms_state : {}


  const [activeTab, handleActiveTab] = useState()

  const handleOpenForm = () => openGoogleForm(googleFormsId)
  const handleUpdateRegistration = () => { console.log('handleUpdateRegistration') }
  const getFormResonses = () => googleForms_handlers.handleGetSheetsFormResponses()

  useEffect(() => {
    if (googleFormsResponseId) {
      getFormResonses()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleFormsResponseId]);

  const tabGs = () => <GoogleSheetsTabs previewInfo={previewInfo} handleActiveTab={handleActiveTab} />

  const tabContent = () => previewInfo ? tabGs() : <PendingWait />

  const bottomButtons = () => {
    const btns = [
      { caption: 'Open Forms', oc: handleOpenForm, icon: 'database', color: iconColorTypes.external },
      { caption: 'Refresh', oc: getFormResonses, icon: 'refresh', color: iconColorTypes.external }
    ]

    return <UiSaveButtons
      others={btns}
      color={'blue'}
      rows={2}
    />
  }

  const saveButtons = () => {
    const btns = [
      { caption: _.startCase('start') + ' (' + _.startCase('TEST') + ')', oc: handleUpdateRegistration, icon: 'arrow right' },
    ]
    return <UiSaveButtons
      others={btns}
      color={'blue'}
      rows={2}
    />
  }

  const footer = () => <div className={'header-flex'}>
    {bottomButtons()}
    {saveButtons()}
  </div>

  const wrapper = () => <Wrapper
    content={tabContent()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ content: 'google-data-container' }}
  />
  return wrapper()
}

export const GoogleFormsViewerWithProvider = (props) => {
  return <GoogleFormsProvider>
    <GoogleFormsViewer {...props} />
  </GoogleFormsProvider>
}