export const getTempSettingsState = (settings_temp, isGlobal) => {

  let { gvvi, page } = settings_temp ? settings_temp : {}

  let _global;
  let _viewItems;
  let _views;

  if (isGlobal) {
    _global = gvvi.global
    _viewItems = gvvi.viewItems
    _views = gvvi.views
  } else {
    _global = page
    _viewItems = page.viewItems
  }

  return { _global, _viewItems, _views }
}

/**
 * 
 * @param {object} settings_temp 
 * @param {boolean} isGlobal 
 * @param {object} _global 
 * @param {object} _viewItems 
 * @param {object} _views 
 */
export const ammendTempSettingsState = (settings_temp, isGlobal, _global, _viewItems, _views) => {

  let { gvvi, page } = settings_temp ? settings_temp : {}

  if (isGlobal) {
    if (_global) { gvvi.global = _global }
    if (_viewItems) { gvvi.viewItems = _viewItems }
    if (_views) { gvvi.views = _views }
  } else {
    if (_global) { page = _global }
    if (_viewItems) { page.viewItems = _viewItems }
  }
}
