import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { where } from "firebase/firestore";
import { gEnums } from '../../enums/globalEnums';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { fs_add_doc, fs_delete_doc } from '../../firestoreData/appData/fsData';
import { fsfn_updateAuthPasswordAndSignin } from '../../functions/fbAuth';
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { signInResponseTypes } from '../../cnr/reducers/SignInReducer';
import { sendEmailVerificationToCurrentUser } from './sendEmailVerification';
import { validateEmail } from '../../validation/validation';

const dispatchResponse = (callback, response) => {
  console.log('dispatchResponse', response)
  const { signInResponseType } = response ? response : {}
  callback(signInResponseData(null, null, null, signInResponseType))
}

/** Sign In using signInWithEmailAndPassword */
export const signInAppUser = async (siProps) => {

  const { hasProfile, creds, signInData, passwordConfirmation } = siProps
  const { email } = signInData ? signInData : {}
  const { password } = creds ? creds : {}

  const authCreds = {
    email: email ? email.toLowerCase().trim() : null,
    password: password ? password.trim() : null,
  }

  // validate the email
  if (!validateEmail(authCreds.email)) {
    return ({ success: false, signInResponseType: signInResponseTypes.invalidEmail, email: authCreds.email });
  }

  // a profile must have been found before continuing
  if (hasProfile) {
    if (passwordConfirmation) {
      runPasswordConfirmation(authCreds, siProps)
    } else {
      signInWithPassword(authCreds, siProps)
    }
  }
}

const signInWithPassword = async (authCreds, siProps) => {

  const auth = getAuth()

  try {
    // attempt to sign in to the app with an email/password combination
    await signInWithEmailAndPassword(auth, authCreds.email.toLowerCase(), authCreds.password);
  } catch (err) {
    emailPasswordFail(authCreds, siProps, err)
  }
}

/**
 * 
 * @param {object} authCreds 
 * @param {object} siProps 
 */
const runPasswordConfirmation = (authCreds, siProps) => {

  const { callback, hasAuth, pathViews, signInData } = siProps
  const data = { email: authCreds.email, password: authCreds.password }
  let resp = { success: true, signInResponseType: signInResponseTypes.signInPending }

  fsfn_updateAuthPasswordAndSignin(data, signInData, pathViews, hasAuth).then(async res => {
    const { success, signInResponse } = res
    if (success === true) {
      resp = { success: true, signInResponseType: signInResponseTypes.signInSuccess }
    } else {
      if (signInResponseTypes[signInResponse]) {
        resp = { success: false, signInResponseType: signInResponse }
      } else {
        resp = { success: false, signInResponseType: signInResponseTypes.unknownError }
      }
    }
  })
  dispatchResponse(callback, resp)
}

/**
 * Handles the failure of the sign in
 * @param {object} authCreds 
 * @param {object} siProps 
 * @param {object} err 
 * @returns 
 */
const emailPasswordFail = (authCreds, siProps, err) => {

  const { callback, appSignIn, allowSignUp, appUserCollection, signInData, directSignIn } = siProps
  const { appUserType } = signInData ? signInData : {}
  const { authGlobalPassword } = appSignIn ? appSignIn : {}

  const allowNonAuth = appUserType === gEnums.appUserTypes.appProfile ? true : false

  let resp = {}

  //  sign in with the email/password FAILED
  const { code, message: errorMessage } = err ? err : {};

  switch (code) {

    case 'auth/too-many-requests':
      resp = { signInResponseType: signInResponseTypes.tooManyAttempts, errorMessage, success: false };
      dispatchResponse(callback, resp);
      break;

    default:
      // if we allow authGlobalPassword, continue
      if (!directSignIn && appUserCollection) {
        if (allowNonAuth && (authGlobalPassword === authCreds.password)) {
          signInGlobal(authCreds, siProps)
        } else {
          dispatchResponse(callback, { success: false, signInResponseType: signInResponseTypes.incorrectPassword });
        }
      } else {
        if (allowSignUp) {
          signUp(authCreds)
        } else {
          const err_2 = { message: 'Log-in Error' };
          return ({ signInResponseType: signInResponseTypes.incorrectPassword, err, success: false });
        }
      }
  }
}

/**
 * Tries to sign in with a global password
 * @param {object} authCreds 
 * @param {object} siProps 
 */
const signInGlobal = (authCreds, siProps) => {

  let resp = {}

  const { callback, hasAuth, appSignIn, pathViews, signInData } = siProps
  const { sendEmailVerification } = appSignIn ? appSignIn : {}

  const data_global = { email: authCreds.email, password: authCreds.password };

  if (data_global.email && data_global.password) {
    if (hasAuth) {
      // if the user already has authentication, and has not already been alerted, alert the user that their password will be changed
      resp = { success: true, signInResponseType: signInResponseTypes.handlePasswordAlert };
    } else {
      fsfn_updateAuthPasswordAndSignin(data_global, signInData, pathViews, hasAuth).then(async (res) => {
        const { success, signInResponse } = res;
        if (success === true) {
          resp = { success: true, signInResponseType: signInResponseTypes.signInSuccess };
          if (sendEmailVerification) { sendEmailVerificationToCurrentUser(); }
        } else {
          if (signInResponseTypes[signInResponse]) {
            resp = { success: false, signInResponseType: signInResponse };
          }
          resp = { success: false, signInResponseType: signInResponseTypes.unknownError };
        }
      }).catch(error => {
        return ({ signInResponseType: signInResponseTypes.signUpError, error, success: false });
      });
    }
  }
  dispatchResponse(callback, resp);
}

/**
 * Creates a new user with an email and password
 * @param {object} authCreds 
 * @returns 
 */
const signUp = async (authCreds) => {

  const auth = getAuth()

  try {
    const resp = await createUserWithEmailAndPassword(
      auth,
      authCreds.email.toLowerCase(),
      authCreds.password
    );

    var user = auth.currentUser;

    fs_get_data({ refPath: ['profiles'], wheres: [where('email', '==', user.email)] }).then(profile => {
      fs_add_doc(['profiles'], profile);
      fs_delete_doc(['profiles', user.email]);
    });

  } catch (err) {
    return ({ signInResponseType: signInResponseTypes.signUpError, err, success: false });
  }
}