import _ from 'lodash';
import { gEnums } from '../../enums/globalEnums';

export const fileViewerTypes = {
  handleCancelModify: 'handleCancelModify',
  handleCommitRemoveFiles: 'handleCommitRemoveFiles',
  handleSelectFile: 'handleSelectFile',
  handleSelectModify: 'handleSelectModify',
  handleSelectSave: 'handleSelectSave',
  handleShowUpload: 'handleShowUpload',
  // handleSetPropStorageFile: 'handleSetPropStorageFile',
}

export const fileViewerReducer = (state, action) => {

  const { type, selectedItems } = action
  const { storage_handlers, storageRootPath, storageType, showUpload, paps_state, fieldProps, fileMode, selectedFiles, storageData, page_fns, allowMultiItemSelect } = state

  switch (type) {
    case fileViewerTypes.handleShowUpload:
      return {
        ...state,
        showUpload: !showUpload,
        storageType: action.props ? action.props.storageType : storageType
      }

    case fileViewerTypes.handleSelectFile:
      return { ...state, selectedFile: action.selectedFile }

    case fileViewerTypes.handleCancelModify:
      return {
        ...state,
        questionProps: null,
        saveReady: false
      }

    case fileViewerTypes.handleCommitRemoveFiles:
      // depending on the fileMode
      const data = Object.assign({}, fieldProps);
      data.value = selectedFiles

      switch (fileMode) {
        case gEnums.fileModes.viewWithSelect:
          data.value = selectedFiles
          break;

        default:
          const removeProps = { storageRootPath, selectedFiles, storageData, paps_state, page_fns }
          storage_handlers.handleRemove_filesFromStorage(removeProps)

          return {
            ...state,
            questionProps: null,
            saveReady: false
          }
      }

      return {
        ...state,
      }

    case fileViewerTypes.handleSelectSave:

      let propStorageFile;
      let propStorageFiles

      if (state.fieldProps) {
        const fps = Object.assign({}, fieldProps);

        if (allowMultiItemSelect) {
          fps.value = Object.keys(selectedItems)
          propStorageFiles = getPropStorageFiles(storageData, fps.value)
        } else {
          fps.value = selectedItems && Object.keys(selectedItems).length > 0 ? Object.keys(selectedItems)[0] : null
          propStorageFile = getPropStorageFile(storageData, fps.value)
        }
        fieldProps.onChange(null, fps)
      }

      return { ...state, selectedFile: null, propStorageFile, propStorageFiles }

    case fileViewerTypes.handleSelectModify:
      return {
        ...state,
        questionProps: {
          check: true,
          header: 'Delete',
          question: 'Are you sure you want to delete this item/items. It cannot be recovered.'
        }
      }
    default:
      return state
  }
}

const getPropStorageFile = (storageData, propValue) => storageData ? storageData[propValue] : null
const getPropStorageFiles = (storageData, propValue) => {
  const psf = []
  if (propValue && _.isArray(propValue)) {
    propValue.forEach(pr => {
      psf.push(storageData[pr])
    })
  }
  return psf
}

export const fileViewerInitialState = (initState) => {

  const { paps_state, page_fns, storage_handlers, storage_state, fileMode, storageType, fieldProps, swipeToSelected, propValue, allowMultiItemSelect } = initState

  // papsContext 
  const { storageRootPath } = paps_state ? paps_state : {}

  // storageContext 
  const { globalFiles } = storage_state ? storage_state : {}

  let storageData = globalFiles && globalFiles[storageType] ? globalFiles[storageType] : {}

  let initStorageFiles = [];
  let propFiles = []

  const propStorageFiles = allowMultiItemSelect ? getPropStorageFiles(storageData, propValue) : null
  const propStorageFile = getPropStorageFile(storageData, propValue)

  switch (fileMode) {
    case gEnums.fileModes.viewWithSelect:
      if (fieldProps && fieldProps.propkey && storageData) {
        if (fieldProps.value && _.isArray(fieldProps.value)) {
          fieldProps.value.forEach(f => {
            initStorageFiles[f] = storageData[f]
          })
          propFiles = fieldProps.value
        }
      }
      break;
    default:
      initStorageFiles = storageData
  }

  return {
    allowMultiItemSelect,
    fieldProps,
    fileMode,
    page_fns,
    paps_state,
    propFiles,
    propStorageFile,
    propStorageFiles,
    saveReady: false,
    selectedFiles: [],
    showUpload: false,
    storage_handlers,
    storageData,
    storageRootPath,
    storageType,
    swipeToSelected,
  }
};

export const fileViewerHandlers = (dispatch, state) => {
  return {
    handleCancelModify: () => { dispatch({ type: fileViewerTypes.handleCancelModify }) },
    handleCommitRemoveFiles: () => { dispatch({ type: fileViewerTypes.handleCommitRemoveFiles }) },
    handleSelectFile: (selectedFile) => { dispatch({ type: fileViewerTypes.handleSelectFile, selectedFile }) },
    handleSelectModify: () => { dispatch({ type: fileViewerTypes.handleSelectModify }) },
    handleSelectSave: (selectedItems) => { dispatch({ type: fileViewerTypes.handleSelectSave, selectedItems }) },
    handleShowUpload: (props) => { dispatch({ type: fileViewerTypes.handleShowUpload, props: props }) },
  }
}