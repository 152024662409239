import _ from 'lodash'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Button } from 'semantic-ui-react'
import Wrapper from '../../wrappers/Wrapper'
import { uniqueKey } from '../../common/keys'
import { DimmerPending } from '../alerts/DimmerWrap'

const SwiperWrapper = (props) => {

  const { swiper } = props ? props : {}
  const { swipeItems, sidebarItems, handleSwiperIndexChange } = swiper ? swiper : {}
  const { selected, keyAs } = sidebarItems ? sidebarItems : {}
  const { index } = selected ? selected : {}

  const [currentIndex, setCurrentIndex] = useState(index ? index : 0)

  const handleButtonClick = (e, item, index) => {
    window.localStorage.setItem('ms_' + keyAs, item.key)
    handleChange(index, item)
  }

  const handleChange = (index, item) => {
    const _item = item ? item : swipeItems[index]
    setCurrentIndex(index)
    handleSwiperIndexChange && handleSwiperIndexChange(index, _item)
  }

  const handleIndexChange = (index, indexLatest, meta) => meta && meta.reason === 'swipe' && handleChange(index)
  const handleGetItemCaption = (item) => _.startCase(item)

  const slides = () => swipeItems && swipeItems.map((item, index) => (item.content))

  const buttons = () => swipeItems && swipeItems.map((item, index) => (
    <Button
      key={uniqueKey('sw', 'btn', index)}
      color={index === currentIndex ? 'blue' : null}
      className={index === currentIndex ? 'btn-sel' : null}
      onClick={e => handleButtonClick(e, item, index)}>
      {item.caption ? item.caption : handleGetItemCaption(item.key)}
    </Button>))

  const swipeableButtons = () => <div className='ms-button-group'>
    <Button.Group className='swipe-tab-container' size='mini'>
      {buttons()}
    </Button.Group>
  </div>

  const swipeableViews = () => <SwipeableViews
    enableMouseEvents
    className='swiper-sub'
    index={currentIndex}
    onChangeIndex={handleIndexChange}
  // disableLazyLoading={true}
  >
    {slides()}
  </SwipeableViews>

  const wrapper = () => <Wrapper
    header={swipeableButtons()}
    content={swipeItems ? swipeableViews() : <DimmerPending />}
    css={{ content: 'killOf' }}
  ></Wrapper>

  return wrapper()

}

export default SwiperWrapper