import React, { useEffect, useRef } from 'react';
import { uniqueKey } from '../common/keys';
import { copyCodeToClipboard } from '../common_web/copy';

const Copy = (props) => {

  const { copyText, forTable } = props

  const textAreaRef = useRef()

  const handleOnChange = () => { }

  useEffect(() => {
    copyToClipboard(copyText)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [copyText]);

  const copyToClipboard = () => copyCodeToClipboard(textAreaRef)

  return <textarea key={uniqueKey('cp', 'ta')}
    ref={textAreaRef}
    value={forTable ? copyText : JSON.stringify(copyText, null, 3)}
    style={{ position: 'absolute', left: '-999em' }}
    onChange={handleOnChange}
  />
}

export default Copy