import { deleteField } from "firebase/firestore"
import _ from 'lodash'
import { fs_get_data } from "paoke-firebase"
import { removeFromObject } from "../../../../../global/common/sorting"
import { copyObj } from "../../../../../global/common_web/copy"
import { createRefPath_event } from "../../../../../global/firestoreData/appData/appRefPaths"
import { fs_set_doc } from "../../../../../global/firestoreData/appData/fsData"
import { geGCGHandicapIndex } from "../../../golfCalcs/courseHandicap"
import { fields_golferTournament } from "../GolfTournamentReducer"

export const fixTournament = (state) => {

  const { pathViews, golfEvent_state } = state
  const { golfers: golfers_event, golfCourses } = golfEvent_state ? golfEvent_state : {}

  const _callBack = (res) => {

    const { golfers, lineups, rounds, teams } = res

    const _golfers = fixGolfers(golfers, golfers_event, golfCourses)
    const _lineups = fixLineups(lineups, golfers_event)
    const _rounds = fixRounds(rounds)
    const _teams = fixTeams(teams)

    const details = {
      golfers: _golfers,
      lineups: _lineups,
      rounds: _rounds,
      teams: _teams,
    }

    const _refPathU = createRefPath_event(pathViews, ['golfTournaments', pathViews.golfTournaments, 'details', 'details'])

    console.log('_refPathU', _refPathU)
    console.log('details', details)
    fs_set_doc(_refPathU, details, true)

  }

  const _refPath = createRefPath_event(pathViews, ['_golfTournaments', 'JSbL0WiQ0XPmuC4MTb73'])
  // console.log('_refPath', _refPath)
  fs_get_data({ refPath: _refPath, callback: _callBack, opts: { returnFirstObject: true } })

}

export const fixGolfers_tournament = (state) => {
  const backupDetails = false
  const { pathViews, _details } = state
  const { golfers } = _details ? _details : {}
  if (golfers) {
    const fixedGolfers = {}
    Object.keys(golfers).forEach((golferKey, index) => {
      const golfer = golfers[golferKey]
      const fixedGolfer = {}
      Object.keys(golfer).forEach(propKey => {
        if (fields_golferTournament.includes(propKey)) {
          fixedGolfer[propKey] = golfer[propKey]
        } else {
          fixedGolfer[propKey] = deleteField()
        }
      })
      fixedGolfers[golferKey] = fixedGolfer
    })
    const _refPathU = createRefPath_event(pathViews, ['golfTournaments', pathViews.golfTournaments, 'details', 'details'])
    const dataToUpdate = { golfers: fixedGolfers }
    console.log('_refPathU', _refPathU)
    console.log('dataToUpdate', dataToUpdate)
    // fs_set_doc(_refPathU, dataToUpdate, true)
  }
  if (_details && backupDetails) {
    const _refPathD = createRefPath_event(pathViews, ['golfTournaments', pathViews.golfTournaments, 'details_backup', 'details'])
    console.log('_refPathD', _refPathD)
    console.log('_details', _details)
    fs_set_doc(_refPathD, _details, true)
  }
}

export const fixGolferHandicaps = (state) => {
  const courseKey = '2xSDTm7EZXmeRTCNhiUM'
  const courseTb = 'black'
  const { golfers, pathViews, golfTournament } = state
  const { golfCourse: golfCourseKey } = golfTournament ? golfTournament : {}
  const _golfCourseKey = golfCourseKey ? golfCourseKey : courseKey
  if (golfers) {
    let _golfers = copyObj(golfers);
    Object.keys(_golfers).forEach(k => {
      const g = _golfers[k]
      const { teebox, courseHandicap, handicapIndex } = g ? g : {}
      const _ch = courseHandicap ? courseHandicap : handicapIndex
      // const _ch = geGCGHandicapIndex(g, '2xSDTm7EZXmeRTCNhiUM', tournamentGolfCourses, g.handicapIndex, 'cranberry')
      // g.courseHandicaps = { [courseKey]: _ch }
      // g.courseTeeboxes = { [courseKey]: courseTb }
      g.courseHandicaps = { [_golfCourseKey]: _ch }
      g.courseTeeboxes = { [_golfCourseKey]: teebox ? teebox : courseTb }
      if (!courseHandicap) { g.noTrueCourseHandicap = true }
      // g.tournamentHandicapIndex = g.handicapIndex
      g._itemKey = deleteField()
      g.courseHandicap = deleteField()
      g._existingKey = deleteField()
      g.key = deleteField()
      g.shortName = deleteField()
      g.teebox = deleteField()
    })
    const _refPathU = createRefPath_event(pathViews, ['golfTournaments', pathViews.golfTournaments, 'details', 'details'])
    const dataToUpdate = { golfers: _golfers }
    console.log('_refPathU', _refPathU)
    console.log('dataToUpdate', dataToUpdate)
    // fs_set_doc(_refPathU, dataToUpdate, true)
  }
}

export const fixGolfers = (golfers, golfers_event, golfCourses) => {
  const _golfers = {}
  if (golfers) {
    Object.keys(golfers).forEach(k => {
      const golfer = golfers[k]
      const gk = getGolferKey(golfer, golfers_event)
      const _golfer = { ...golfer }
      _golfer.handicapIndex = golfer.handicap
      if (!_golfer.handicapIndex) {
        _golfer.handicapIndex = golfer.handicapIndex
      }
      _golfer.courseHandicap = geGCGHandicapIndex(_golfer, '2xSDTm7EZXmeRTCNhiUM', golfCourses, _golfer.handicapIndex, 'cranberry')
      _golfer.teamNumber = _golfer.tournamentTeams === '2' ? 2 : 1
      _golfer.teebox = ['cranberry']
      removeFromObject(_golfer, ['handicap', 'id', 'key', 'tournamentTeams', 'groupNumber', 'position',])

      _golfers[gk] = _golfer
    })
  }
  return _golfers
}

export const fixLineups = (lineups, golfers_event) => {
  const _lineups = { team1: {}, team2: {} }
  if (lineups) {
    Object.keys(lineups).forEach(k => {
      const lineup = lineups[k]
      const { name, tournamentGolfers, tournamentRounds, tournamentTeams } = lineup
      let ln = _.camelCase(name)
      ln = ln.replace('Lineup', '')
      switch (tournamentTeams) {
        case '1':
          if (!_lineups.team1[ln]) { _lineups.team1[ln] = [] }
          break;
        case '2':
          if (!_lineups.team2[ln]) { _lineups.team2[ln] = [] }
          break;
        default:
        // nothing
      }
      if (tournamentGolfers) {
        Object.keys(tournamentGolfers).forEach(tgk => {
          const tournamentGolfer = tournamentGolfers[tgk]
          const tg = { ...tournamentGolfer }
          tg.golferKey = getGolferKey(tg, golfers_event)
          removeFromObject(tg, ['key', 'name'])
          switch (tournamentTeams) {
            case '1':
              _lineups.team1[ln].push(tg)
              break;
            case '2':
              _lineups.team2[ln].push(tg)
              break;
            default:
            // nothing
          }
        })
      }
    })
  }
  return _lineups
}

export const fixRounds = (rounds) => {
  const _rounds = {}
  if (rounds) {
    Object.keys(rounds).forEach(k => {
      switch (k) {
        case 'alternativeShot':
        case 'alternateShot':
        case 'bestBall':
        case 'singles':
          const round = rounds[k]
          let { name, golfersPerTeam, numberOfMatches, roundNumber, startDate, startTime } = round
          if (name === 'alternativeShot') { name = 'alternateShot' }
          _rounds[name] = {
            golfCourse: '2xSDTm7EZXmeRTCNhiUM',
            golfersPerTeam,
            name: name,
            numberOfMatches,
            roundNumber,
            startDate,
            startTime,
            lineUps: {
              team1: name,
              team2: name
            }
          }
          break;
        default:
        // nothing
      }

    })
  }
  return _rounds
}

export const fixTeams = (teams) => {
  const _teams = {}
  if (teams) {
    Object.keys(teams).forEach(k => {
      const team = teams[k]
      const { captain, name, teamNumber } = team
      _teams[teamNumber === 1 ? 'team1' : 'team2'] = {
        captain,
        name,
        teamNumber
      }

    })
  }
  return _teams
}

export const getGolferKey = (tournamentGolfer, golfers_event) => {
  const { name, lastName, firstName } = tournamentGolfer
  let tgn = name ? name : firstName + ' ' + lastName
  let golferKey = _.findKey(golfers_event, function (g) {
    switch (tgn) {
      case 'Pat Callahan':
        tgn = 'Pat Callaghan'
        break;
      default:
      // nothing
    }
    return g.firstName + ' ' + g.lastName === tgn
  })
  if (!golferKey) {
    switch (tgn) {
      case 'Jeff Sr. Thompson':
        golferKey = 'g4GAPanII16TkoNb47gF'
        break;
      default:
      // nothing
    }
  }
  return golferKey
}

export const fixFromMatches = (state) => {
  const _golfCourseKey = 'xMmeLJoluqPAqZihIHje'
  const courseTb = 'black'
  const { matches, pathViews, golfEvent_state } = state
  const { golfers } = golfEvent_state
  const _golfers = {}
  const _lineups = { team1: {}, team2: {} }
  if (matches) {
    Object.keys(matches).forEach(rk => {
      const roundMatches = matches[rk]
      if (_.isObject(roundMatches)) {
        const _roundMatches = _.sortBy(roundMatches, 'matchNumber')
        _roundMatches.forEach(m => {
          if (!_lineups.team1[rk]) { _lineups.team1[rk] = [] }
          if (!_lineups.team2[rk]) { _lineups.team2[rk] = [] }
          const { team1, team2 } = m
          if (team1) {
            Object.keys(team1).forEach(gk => {
              const golfer = team1[gk]
              const _g = golfers[gk]
              const gi = parseFloat(_g.handicapIndex).toFixed(1)
              _golfers[gk] = {
                firstName: _g.firstName,
                lastName: _g.lastName,
                name: _g.firstName + ' ' + _g.lastName,
                teamNumber: 1,
                handicapIndex: _g.handicapIndex,
                tournamentHandicapIndex: _g.handicapIndex,
                noTrueCourseHandicap: true,
                courseHandicaps: { [_golfCourseKey]: gi },
                courseTeeboxes: { [_golfCourseKey]: courseTb }
              }
              _lineups.team1[rk].push({
                golferKey: gk,
                groupNumber: Math.round((golfer.position + 1) / 2),
                position: golfer.position
              })
            })
          }
          if (team2) {
            Object.keys(team2).forEach(gk => {
              const golfer = team2[gk]
              const _g = golfers[gk]
              const gi = parseFloat(_g.handicapIndex).toFixed(1)
              _golfers[gk] = {
                firstName: _g.firstName,
                lastName: _g.lastName,
                name: _g.firstName + ' ' + _g.lastName,
                teamNumber: 2,
                handicapIndex: _g.handicapIndex,
                tournamentHandicapIndex: _g.handicapIndex,
                noTrueCourseHandicap: true,
                courseHandicaps: { [_golfCourseKey]: gi },
                courseTeeboxes: { [_golfCourseKey]: courseTb }
              }
              _lineups.team2[rk].push({
                golferKey: gk,
                groupNumber: Math.round((golfer.position + 1) / 2),
                position: golfer.position
              })
            })
          }
        })
      }

    })
  }
  const _refPathU = createRefPath_event(pathViews, ['golfTournaments', pathViews.golfTournaments, 'details', 'details'])
  const dataToUpdate = { golfers: _golfers }
  console.log('_refPathU', _refPathU)
  console.log('dataToUpdate', dataToUpdate)
  fs_set_doc(_refPathU, dataToUpdate, true)
}

// {
//   "golfers": {
//       "6bMiXrhdyjYwA9WGMwYp": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "9.0",
//           "tournamentHandicapIndex": "9.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "9.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "eba95EZNRlBTM58TWXJ4": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "12.0",
//           "tournamentHandicapIndex": "12.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "12.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "P70cRBsctkopDFVMlkoT": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "21.0",
//           "tournamentHandicapIndex": "21.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "21.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "egjEaHBdPSG9JXnw85xU": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "10.4",
//           "tournamentHandicapIndex": "10.4",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "10.4"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "XPCXiwDu3Yprdt51eTuy": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "3.0",
//           "tournamentHandicapIndex": "3.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "3.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "D2OFQLGBwIhbMqeSpCgs": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "3.1",
//           "tournamentHandicapIndex": "3.1",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "3.1"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "CRlNrXPjCBRRKqboB3pf": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "9.0",
//           "tournamentHandicapIndex": "9.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "9.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "IJmKBSWDlByI2x3ubdNA": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "1.9",
//           "tournamentHandicapIndex": "1.9",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "1.9"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "4rgEXD3iJ01ZL4ex2j4R": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "20.3",
//           "tournamentHandicapIndex": "20.3",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "20.3"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "hPyNUtsrT2zNdk0130Zt": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "12.0",
//           "tournamentHandicapIndex": "12.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "12.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "WjeyORsBgTeYbvEwt92a": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "10.9",
//           "tournamentHandicapIndex": "10.9",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "10.9"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "MHNY7iAy9TqSzQJu7SDS": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "11.8",
//           "tournamentHandicapIndex": "11.8",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "11.8"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "6S5Sd3qnwC08gxkaqYKZ": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "12.0",
//           "tournamentHandicapIndex": "12.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "12.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "tsrX9pHRj9LpDxHDgxx8": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "10.5",
//           "tournamentHandicapIndex": "10.5",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "10.5"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "u3rEQb6gKqRQbfr8JeHI": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "6.8",
//           "tournamentHandicapIndex": "6.8",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "6.8"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "g4GAPanII16TkoNb47gF": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "18.7",
//           "tournamentHandicapIndex": "18.7",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "18.7"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "O5Cf0MqnnfZBBlu0rjFw": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "23.0",
//           "tournamentHandicapIndex": "23.0",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "23.0"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "Q1mn5bLuVCrPh0pT0sca": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "9.8",
//           "tournamentHandicapIndex": "9.8",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "9.8"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "SAEJprZSYEgQ1QKbMgUX": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "20.9",
//           "tournamentHandicapIndex": "20.9",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "20.9"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "adLglIOZ7X5JwuU3pqlz": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "11.1",
//           "tournamentHandicapIndex": "11.1",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "11.1"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "phkX800TR9ZsRf3ytQ5m": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "18.2",
//           "tournamentHandicapIndex": "18.2",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "18.2"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "PkMiGtIONSFURvekyfAU": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "13.3",
//           "tournamentHandicapIndex": "13.3",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "13.3"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "C7HAIDPmh4M6I4SZxqS3": {
//           "name": "undefined undefined",
//           "teamNumber": 1,
//           "handicapIndex": "16.3",
//           "tournamentHandicapIndex": "16.3",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "16.3"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       },
//       "2OKobEuNkM3ssjfTa8DS": {
//           "name": "undefined undefined",
//           "teamNumber": 2,
//           "handicapIndex": "10.2",
//           "tournamentHandicapIndex": "10.2",
//           "noTrueCourseHandicap": true,
//           "courseHandicaps": {
//               "xMmeLJoluqPAqZihIHje": "10.2"
//           },
//           "courseTeeboxes": {
//               "xMmeLJoluqPAqZihIHje": "black"
//           }
//       }
//   }
// }