import _ from 'lodash';
import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';

export const AppCheck = () => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states ? states : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const {
    appUserDataSettings,
    manifest,
    appSignIn
  } = aps_global ? aps_global : {}

  console.log('appSignIn', appSignIn)

  const segContent = (setting) => Object.keys(setting).map(sk => {
    const s = setting[sk]
    if (!_.isObject(s)) {
      return <div>{sk}{': '}{s}</div>
    }
  })


  const segGroup = (groupType, setting) => <Segment.Group className='app-check-container'>
    <Segment>{_.startCase(groupType)}</Segment>
    <Segment>{segContent(setting)}</Segment>
  </Segment.Group>

  const segGroups = () => {
    const groups = []
    groups.push(segGroup('appSignIn', appSignIn))
    groups.push(segGroup('manifest', manifest))
    return groups
  }

  const wrapper = () => <Wrapper
    content={segGroups()}
    wrapperType={wrapperTypes.padded}
  ></Wrapper>

  return wrapper()

}
