import _ from 'lodash';
import { sortObjectRoot } from '../../../common/sorting';
import { gEnums } from '../../../enums/globalEnums';
import { viTypes } from '../../../cnr/reducers/CreateReducer';

/** list of options, excluding the one that are already in the app */
// , views_app
export const selectedOptions = (isGlobal, noData, settings_globalPageItems, aps_viewItems, viewItems_page, firstSw, swipedItems, swipedItem, addModeType) => {

  const opts = []

  const viType = swipedItem ? swipedItem.viType : null

  if (isGlobal) {
    if (settings_globalPageItems) {
      let selList = sortObjectRoot(settings_globalPageItems)
      switch (addModeType) {
        case gEnums.addModeTypes.pageItems:
          selList = sortObjectRoot(viewItems_page)
          break;
        case gEnums.addModeTypes.views:
          selList = sortObjectRoot(aps_viewItems)
          break;
        default:
        // nothing
      }
      Object.keys(selList).forEach(key => {
        if (!aps_viewItems[key]) {
          opts.push({ key: key, value: key, text: _.startCase(key) })
        }
      })
    }
  } else {
    if (viType) {
      switch (viType) {
        case viTypes.views:
          if (aps_viewItems) {
            const gcvis = sortObjectRoot(aps_viewItems)
            Object.keys(gcvis).forEach(key => {
              if (settings_globalPageItems) {
                if (!settings_globalPageItems[key]) {
                  opts.push({ key: key, value: key, text: _.startCase(key) })
                }
              } else {
                opts.push({ key: key, value: key, text: _.startCase(key) })
              }
            })
          } else {
            opts.push({ key: 'none', value: 'none', text: _.startCase('none') })
          }
          break;

        case viTypes.viewItems:
          if (aps_viewItems) {
            const ggvis = sortObjectRoot(aps_viewItems)
            Object.keys(ggvis).forEach(key => {
              if (!viewItems_page[key]) {
                opts.push({ key: key, value: key, text: _.startCase(key) })
              }
            })
          } else {
            opts.push({ key: 'none', value: 'none', text: _.startCase('none') })
          }
          break;

        case viTypes.props:

          const vi_parent = aps_viewItems[swipedItems[1].key]
          const vi_page = viewItems_page[swipedItems[1].key]

          const props_vi = sortObjectRoot(vi_parent.props)
          const props_page = sortObjectRoot(vi_page.props)

          Object.keys(props_vi).forEach(key => {
            if (!props_page[key]) {
              opts.push({ key: key, value: key, text: _.startCase(key) })
            }
          })
          break;

        default:
        // notihng

      }
    }

  }
  return opts
}

export const propOptions = (swipeItemPaths, aps_viewItems, pageVis) => {
  const { vit } = swipeItemPaths ? swipeItemPaths : {}
  const opts = []
  const appVi = aps_viewItems[vit]
  const pageVi = pageVis[vit]
  if (appVi && appVi.props) {
    Object.keys(appVi.props).forEach(key => {
      if (!pageVi) {
        opts.push({ key: key, value: key, text: _.startCase(key) })
      } else {
        if (pageVi && pageVi.props && !pageVi.props[key]) {
          opts.push({ key: key, value: key, text: _.startCase(key) })
        }
      }
    })
  }
  return opts
}