import React, { useContext } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import HelpProvider from '../cnr/contexts/HelpContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { googleModeTypes } from '../cnr/reducers/GoogleSheetsReducer';
import { g_cns } from '../common/cns';
import { mobileDeviceTypes } from '../devices';
import { allPageTypes } from '../enums/allPageTypes';
import { gEnums } from '../enums/globalEnums';
import UIDisplayEditor from '../pageItem/modification/UIDisplayEditor';
import AppSettingsSidebarWithProvider from '../viewSettings/AppSettingsSidebar';
import AppContent from './AppContent';
import SettingsAppBottom from './SettingsAppBottom';
import SettingsAppTopMenu from './SettingsAppTopMenu';

const wrapGap = 12
const wrapBorder = wrapGap / 2

const pageWidths = {
  desktop: {
    sides: 4,
    middle: 8
  },
  mobile: {
    sides: 4,
    middle: 8
  }
}

/** Controls the settings for the app
 * @param appContent  
 * @description Creates a three column grid. 
 * The left column contains the global settings. 
 * The right column contains the view/Wi settings. 
 * The middle column contains the app/mobileContent, which is passed in via props.
 */
const PageFramework = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ? parentContext : {}
  const { appSettings_state, page_state, paps_state, googleSheets_state } = states
  const { appUser_fns } = fns
  const { homeSettings } = settings ? settings : {}
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { settingsOptions: settingsOptions_home } = global_home ? global_home : {}
  const { dataSourceType: dst, showAppViewer } = appSettings_state ? appSettings_state : {}

  // appSettingsContext 
  const { desktopOn, appSettingsOn, globalSettingsOn, pageSettingsOn, settingsLeftOn, settingsRightOn, currentDevice, settingsPreviewOn, dataSourceType } = appSettings_state ? appSettings_state : {}
  const { msettings } = currentDevice ? currentDevice : {}
  const { googleModeType } = googleSheets_state ? googleSheets_state : {}

  // papsContext 
  const { view } = paps_state ? paps_state : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode, desktopMobileOn, isMobileDevice, mediaDeviceType, modalContent, fullMode } = framework_state ? framework_state : {}
  const { appDimensions } = framework_state ? framework_state : {}
  let { width, height } = appDimensions ? appDimensions : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, } = pageSettings ? pageSettings : {}
  const { appDataSource, display: display_global } = aps_global ? aps_global : {}
  const { appDataSourceType } = appDataSource ? appDataSource : {}
  const { useDarkMode } = settingsOptions_home ? settingsOptions_home : {}
  const { fontFamily, useFontFamily } = display_global ? display_global : {}

  const _dataFromGs = appDataSourceType === gEnums.dataSourceTypes.googleSpreadSheet
  const _dataFromGsTemp = dataSourceType === gEnums.dataSourceTypes.googleSpreadSheet

  const _appSettingsOn = appSettingsOn || settingsLeftOn || settingsRightOn || pageSettingsOn || globalSettingsOn ? true : false
  const desktopView = desktopMode || desktopOn ? true : false

  // local
  const authPermissionType = appUser_fns.validate_settingsAuth()
  const authSettingsPermissionType = appUser_fns.validate_trueSettingsAuth()

  const allowSettings = authPermissionType === gEnums.viewPermissionTypes.allow
  const allowTopSettings = authSettingsPermissionType === gEnums.viewPermissionTypes.allow
  const allowPageSettings = authSettingsPermissionType === gEnums.viewPermissionTypes.allow

  const mdsx = []

  if (msettings && msettings.w) { width = msettings.w }
  if (msettings && msettings.h) { height = msettings.h }

  const mds = mobileDeviceTypes()

  mds.forEach(item => {
    const z = { ...item }
    delete z.msettings
    mdsx.push(z)
  })

  const segWrapperStyle = (desktopView) ? { height: '100%', width: '100%' } : { height: height + wrapGap, width: width + wrapGap, border: wrapBorder + 'px solid' }
  const segStyle = (desktopView) ? { height: '100%', width: '100%', border: 0, padding: 0 } : { height: height, width: width, border: 0, padding: 0 }
  if (useFontFamily && fontFamily) { segStyle.fontFamily = fontFamily }

  let showLeftSettings = false

  switch (view) {
    case 'home':
    case 'clients':
    case 'events':
      showLeftSettings = true
      break;
    default:
      break;
  }

  /**
   * 
   * @param {boolean} isGlobal 
   * @returns an 
   */
  const appSettingsSidebar = (isGlobal) => <AppSettingsSidebarWithProvider isGlobal={isGlobal} showLeftSettings={showLeftSettings} />

  const displayEditor = (contents) => {
    return <div className={'display-editor'}>
      {contents}
      <UIDisplayEditor segStyle={segStyle} />
    </div>
  }

  const smartPhoneAppContent = () => {

    const _showEditor = false

    let cn = desktopView ? g_cns.settings_app_desktop_wrap : g_cns.settings_app_mobile_wrap

    if (settingsPreviewOn) {
      cn += ' live global'
    } else {
      cn += ' not-live'
    }

    if (fullMode) { cn += ' fullMode' }

    if (desktopMobileOn) {
      return <AppContent />
    } else {
      if (_showEditor) {
        return displayEditor(<div className={cn} style={segWrapperStyle}>
          <Segment style={segStyle} id={'page-framework'}>
            <AppContent />
            {modalContent && modalContent}
          </Segment>
        </div>)
      } else {
        return <div className={cn}>
          <Segment style={segStyle} id={'page-framework'}>
            <AppContent />
            {modalContent && modalContent}
          </Segment>
        </div>
      }
    }
  }

  const BlankColumn = (w) => <Grid.Column width={w}></Grid.Column>
  // const CurrentColumn = (w) => <Grid.Column width={w}><Segment inverted>TEST</Segment></Grid.Column>

  const SettingsColumn = (isGlobal, w) => <Grid.Column width={w} className={useDarkMode ? 'dark-mode' : ''}>
    {_appSettingsOn && appSettingsSidebar(isGlobal)}
  </Grid.Column>

  /** Column container for either the global or page/view settings. 
   * @description only available for uses with permissions
   */
  const SideColumn = (isGlobal, w) => {
    if (!allowSettings) { return BlankColumn(w) }
    if (!isGlobal) {
      switch (view) {
        case allPageTypes.appProfile:
          return BlankColumn(w)
        default:
        // nothing
      }
    }
    return SettingsColumn(isGlobal, w)
  }

  /**
   * 
   * @returns smartPhoneAppContent
   */
  const appContainer = () => {

    let cn_sa_containter = g_cns.settings_app_container
    let cn_sa_content = g_cns.settings_app_content

    if (desktopView) { cn_sa_content += ' dt-mode' }
    // if (_usePageTransitions) { cn_sa_content += ' ft' }

    if (googleModeType !== googleModeTypes.none) { cn_sa_containter += ' google ' + googleModeType }
    if (_dataFromGsTemp) { cn_sa_containter += ' gds temp' }
    if (_dataFromGs) { cn_sa_containter += ' gds' }

    return <div className={cn_sa_containter} >
      <div className={cn_sa_content} >
        {smartPhoneAppContent()}
      </div>
    </div>
  }

  /** @returns Grid.Column containing the header and the actual smart phone app. Thi appContent is being passed in */
  const AppColumn = (w) => <Grid.Column className={'app'} width={w} >
    {appContainer()}
  </Grid.Column>


  let cnSettings = g_cns.app_settings_container

  /**
   * @returns a grid with 3 columns.
   * @leftColumn - App Settings
   * @centerColumn - App Content
   * @rightColumn - Page Settings
   */
  const gridContent = () => {

    let cngSettings = g_cns.app_settings_grid

    if (desktopMobileOn) { cngSettings += ' dmo' }

    if (mediaDeviceType === gEnums.mediaDeviceTypes.mobile) {
      return <AppContent />
    } else {
      if (desktopMobileOn) {
        if (appSettingsOn) {
          return <Grid inverted={useDarkMode} columns={3} className={cngSettings}>
            {SideColumn(true, pageWidths.desktop.sides)}
            {AppColumn(pageWidths.desktop.middle)}
            {SideColumn(false, pageWidths.desktop.sides)}
          </Grid>
        } else {
          return <Grid inverted={useDarkMode} columns={3} className={cngSettings}>
            {BlankColumn(pageWidths.desktop.sides)}
            {AppColumn(pageWidths.desktop.middle)}
            {BlankColumn(pageWidths.desktop.sides)}
          </Grid>
        }
      } else {
        if (desktopView) {
          if (settingsLeftOn) {
            return <Grid inverted={useDarkMode} columns={2} className={cngSettings}>
              {SideColumn(true, pageWidths.desktop.sides)}
              {AppColumn(12)}
            </Grid>
          } else if (settingsRightOn) {
            return <Grid inverted={useDarkMode} columns={2} className={cngSettings}>
              {AppColumn(12)}
              {SideColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else {
            return <Grid inverted={useDarkMode} columns={3} className={cngSettings}>
              {BlankColumn(2)}
              {AppColumn(12)}
              {BlankColumn(2)}
            </Grid>
          }
        } else {
          // mobile view
          if (appSettingsOn) {
            return <Grid inverted={useDarkMode} columns={3} className={cngSettings} >
              {SideColumn(true, pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {SideColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else if (globalSettingsOn) {
            return <Grid inverted={useDarkMode} columns={3} className={cngSettings} >
              {SideColumn(true, pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {BlankColumn(pageWidths.desktop.sides)}
            </Grid>
          } else if (pageSettingsOn) {
            return <Grid inverted={useDarkMode} columns={3} className={cngSettings} >
              {BlankColumn(pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {SideColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else {
            return <Grid inverted={useDarkMode} columns={3} className={cngSettings} >
              {BlankColumn(pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {BlankColumn(pageWidths.desktop.sides)}
            </Grid>
          }
        }
      }
    }
  }

  const pfContent = () => <div className={cnSettings}>
    {!isMobileDevice && allowTopSettings && <SettingsAppTopMenu />}
    {gridContent()}
    {!isMobileDevice && allowPageSettings && <SettingsAppBottom />}
  </div>

  return <HelpProvider>
    {pfContent()}
  </HelpProvider>

}

export default PageFramework