import React, { useContext, useState } from "react";
import { List, Segment } from "semantic-ui-react";
import { PageContext } from "./global/cnr/contexts/PageContext";
import { uniqueKey } from "./global/common/keys";
import UiSaveButtons from "./global/components/buttons/UiSaveButtons";
import Checker from "./global/components/forms/elements/Checker";
import { renderHtml } from "./global/components/html/renderHtml";
import FullPageWrapper from "./global/wrappers/FullPageWrapper";
import Wrapper, { wrapperTypes } from "./global/wrappers/Wrapper";

export const Disclaimers = (props) => {

  const { appUser_handlers } = props ? props : {}

  // papsContext
  const pageContext = useContext(PageContext)
  const { page_state, homeSettings_global } = pageContext ? pageContext : {}
  const { disclaimers } = homeSettings_global ? homeSettings_global : {}
  const { cookiesDisclaimer, smsDisclaimer, pushDisclaimer, emailDisclaimer } = disclaimers ? disclaimers : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appSignIn } = aps_global ? aps_global : {}
  const { useCookiesDisclaimer, useSmsDisclaimer, usePushDisclaimer, useEmailDisclaimer } = appSignIn ? appSignIn : {}

  const [activeSnC, setActiveSnC] = useState()
  const [disclaimerResponses, setDisclaimerResponses] = useState({ cookiesDisclaimer: true, smsDisclaimer: false, pushDisclaimer: false, emailDisclaimer: false })

  const handleClickYes = () => appUser_handlers.handleDisclaimerResponses(disclaimerResponses)
  // const handleClickNo = () => appUser_handlers.handleDisclaimerResponses(disclaimerResponses)

  const handleCheck = (e, data, x) => {
    const _disclaimerResponses = { ...disclaimerResponses }
    _disclaimerResponses[data.key] = data.checked
    setDisclaimerResponses(_disclaimerResponses)
  }

  const checker = (key, text) => <Checker handleClick={handleCheck} label={text} checked={disclaimerResponses[key]} item={{ key }} />

  const listItem = (disclaimerType, disclaimerContent, checkContent) => <List.Item key={uniqueKey('disc', disclaimerType)}>
    <List.Content>
      <List.Header>{disclaimerType}</List.Header>
      <List.Description as='a'>{disclaimerContent}</List.Description>
    </List.Content>
    <List.Description style={{ paddingTop: '1em' }}>
      {checkContent}
    </List.Description>
  </List.Item>

  const disclaimerList = () => {
    const list = []
    if (useCookiesDisclaimer && cookiesDisclaimer) {
      list.push(listItem('Cookies', renderHtml(cookiesDisclaimer), checker('cookiesDisclaimer', 'I Agree')))
    }
    if (useEmailDisclaimer && emailDisclaimer) {
      list.push(listItem('Email', renderHtml(emailDisclaimer), checker('emailDisclaimer', 'I Agree')))
    }
    if (usePushDisclaimer && pushDisclaimer) {
      list.push(listItem('Push', renderHtml(pushDisclaimer), checker('pushDisclaimer', 'I Agree')))
    }
    if (useSmsDisclaimer && smsDisclaimer) {
      list.push(listItem('SMS', renderHtml(smsDisclaimer), checker('smsDisclaimer', 'I Agree')))
    }
    return <Segment basic>
      <List divided relaxed>
        {list}
      </List>
    </Segment>
  }

  const wrapperContent = () => <div {...activeSnC}>
    {disclaimerList()}
  </div>

  const wrapper = <Wrapper
    content={wrapperContent()}
    footer={<UiSaveButtons
      save={{ oc: handleClickYes, caption: 'OK' }}
    // cancel={{ oc: handleClickNo, caption: 'Cancel' }}
    />}
    wrapperType={wrapperTypes.padded}
  >
  </Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper}
    topperCaption={'Disclaimers'}
  />

  return fpw()
}