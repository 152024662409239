
import _ from 'lodash';
import { createResponse } from '../altFunctions/common';
import { removeEmpties } from '../common/dataAdjust';
import { createResponseTypes } from '../cnr/reducers/CreateReducer';
import { callFunction } from "./fbAll";
import { fsfn_createProject } from './fbCreate';

// https://www.sslshopper.com/ssl-checker.html

const fetchGenericTypes = {
  fetchGeneric: 'fetchGeneric',
}

const fetchFunctionTypes = {
  createEventFromFetch: 'createEventFromFetch',
  fetchData: 'fetchData',
  fetchTableData: 'fetchTableData',
  getFeed: 'getFeed',
  getFeeds: 'getFeeds',
  getGolfCourseInfo: 'getGolfCourseInfo',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(fetchGenericTypes.fetchGeneric, callData)
  return data
}

export const fsfn_createEventFromFetch = async (urlData) => {
  // console.log('response', response)
  // urlData.options = {
  //   "menthod": "GET",
  //   "rejectUnauthorized": false,
  //   "credentials": "include",
  //   headers: { "Content-Type": "application/json" }
  // }   
  urlData.ignoreSLL = true
  const callData = {
    urlData,
    fetchFunctionType: fetchFunctionTypes.createEventFromFetch
  }
  try {
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error('fsfn_createEventFromFetch - error', error)
    return createResponse(false, 'Not Successfull', createResponseTypes.catchError, error)
  }
  // responseFn && responseFn(response_data)
}

/**
 * 
 * @param {object} urlData 
 * @returns response_data {success, result, error}
 * @description This will get the data from a URL. This does NOT create the event/client info.
 * https://securian.events/index.php/attendeeregistration-event-create-mobile-app.html?eventId=
 */
export const fsfn_fetchData = async (urlData) => {
  try {
    urlData.ignoreSLL = true
    const callData = { urlData, fetchFunctionType: fetchFunctionTypes.fetchData, showLogs: true }
    const response_data = await callFsFunction(callData) // OK 
    let { result } = response_data ? response_data : {}
    if (result) { result = removeEmpties(result) }
    return response_data
  } catch (error) {
    console.error('fsfn_fetchData - error', error)
  }
}

/** This will get the data from a URL. This does NOT create the event/client info */
export const fsfn_fetchDataAndCreate = async (urlData, dispatch) => {
  try {
    const callData = {
      urlData,
      fetchFunctionType: fetchFunctionTypes.fetchData
    }
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ? response_data : {}
    if (success) {
      const eventData = { initialData: result }
      return fsfn_createProject(eventData, null, dispatch).then(response_data => {
        console.log('fsfn_fetchDataAndCreate', 'response_data', response_data);
        return response_data
      })
    } else {
      const previewInfo = {
        projectData: null,
        projectSettings: null,
        previewOk: false,
        message: 'No Event Key found'
      }
      return previewInfo
    }
  } catch (error) {
    console.error(error)
  }
}

/** This will get the data from a URL */
export const fsfn_fetchTableData = async (urlData) => {
  try {
    const callData = {
      urlData,
      fetchFunctionType: fetchFunctionTypes.fetchData
    }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/** This will get the data from a URL */
// export const fsfn_getGolfCourseInfo = async (courseId) => {
//   try {
//     const callData = { courseId, fetchFunctionType: fetchFunctionTypes.getGolfCourseInfo }
//     const response_data = await callFsFunction(callData)// OK
//     return response_data
//   } catch (error) {
//     console.error(error)
//   }
// }

/**
 * 
 * @param {string} feedUrl 
 * @param {string} titleSearch 
 * @returns response_data {success, result, error}
 */
export const fsfn_getFeed = async (feedUrl, titleSearch) => {
  try {
    const callData = { fetchFunctionType: fetchFunctionTypes.getFeed, feedUrl, titleSearch }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} rssFeeds_home 
 * @param {*} rssFeeds 
 * @param {*} titleSearch 
 * @param {*} contentSearch 
 * @param {*} logging 
 * @returns srt
 */
export const fsfn_getFeeds = async (rssFeeds_home, rssFeeds, titleSearch, contentSearch, logging) => {
  try {
    const allFeeds = []
    rssFeeds.forEach(rssF => {
      const _rssHome = rssFeeds_home && rssFeeds_home[rssF]
      const { baseUrl } = _rssHome
      if (baseUrl) {
        allFeeds.push({ url: baseUrl })
      }
      // console.log('_rssHome', _rssHome)
      // const rssS = rssF.split('_')
      // const s = rssS[0]
      // const c = rssS[1]
      // if (s) {
      //   if (rssFeeds_home && rssFeeds_home[s] && rssFeeds_home[s].categories) {
      //     const { baseUrl } = rssFeeds_home[s]
      //     if (c) {
      //       const _rssf = _.find(rssFeeds_home[s].categories, { name: c })
      //       if (_rssf && _rssf.categoryPath) {
      //         const fullUrl = baseUrl + _rssf.categoryPath
      //         allFeeds.push(fullUrl)
      //       }
      //     } else {
      //       allFeeds.push(baseUrl)
      //     }
      //   }
      // }
    })

    const callData = { fetchFunctionType: fetchFunctionTypes.getFeeds, rssFeeds: allFeeds, titleSearch, contentSearch, showLogs: logging && logging.allowLogging && logging.logGetFeeds }
    const response_data = await callFsFunction(callData) // OK
    console.log('response_data', response_data)
    const { success, result } = response_data ? response_data : {}
    if (success) {
      const feedResults = {}
      let allItems = []
      result.forEach((res, index) => {
        res.items.forEach(item => {
          const f = rssFeeds[index].split('_')
          item.source = _.startCase(f[0])
          item.sortDate = new Date(item.pubDate)
        })
        feedResults[rssFeeds[index]] = res.items
        allItems = [...allItems, ...res.items]
      })
      const srt = _.orderBy(allItems, ['sortDate'], ['desc'])
      return srt
    } else {
      return null
    }
  } catch (error) {
    console.error(error)
  }
}