import React, { useEffect } from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import FileUploader from "react-firebase-file-uploader";
import ImageEditor from '../imaging/ImageEditor';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';

const useFileButton = false

/**
 * 
 * @param {object} props (imageFile, upload_state, upload_handlers, storageType, storageRef, handleSelectedImageFile)
 * @returns an `Image` (if selected) or an `IconGroup`
 */
const UploadSegButton = (props) => {

  const accept = '*'

  const { imageFile, imageImage, upload_state, upload_handlers, storageType, storageRef, handleSelectedImageFile, size } = props
  const { storageLocationType, selectedFolderFiles, allSelectedImages } = upload_state ? upload_state : {}

  const cn = 'seg-button st-' + storageType + ' slt-' + storageLocationType
  let iconName = 'image outline'

  switch (storageType) {
    case gEnums.storageTypes.pdf:
      iconName = 'file pdf outline'
      break;
    default:
    //nothing 
  }

  useEffect(() => {
    if (selectedFolderFiles && handleSelectedImageFile) {
      handleSelectedImageFile(selectedFolderFiles)
    }
    // return () => window.removeEventListener("transitionend", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedFolderFiles]);


  const handleButtonFileSelect = (f) => upload_handlers.handleImageButtonFileSelect(f, storageType)

  /**
   * 
   * @param {object} e 
   * @returns triggers the `handleImageFileSelect` within `upload_handlers`
   */
  const handleFileSelect = (e) => upload_handlers.handleImageFileSelect(e, storageType)

  /**
   * 
   * @returns the `FileUploader` component
   */
  const fileUploader = () => <FileUploader
    hidden
    accept={accept}
    name="avatar"
    storageRef={storageRef}
    onUploadStart={upload_handlers.handleStartUpdate}
    onUploadError={upload_handlers.handleUploadError}
    onProgress={upload_handlers.handleProgress}
    onChange={handleFileSelect}
    multiple={true}
  />

  const fileInput = () => <input
    type="file"
    onChange={(event) => {
      handleButtonFileSelect(event.target.files[0]);
    }}
  />

  /**
   * 
   * @returns an IconGroup with the `iconName` as the icon and `add` as the small icon.
   */
  const iconGroup = () => <Icon.Group size={size ? size : 'massive'}>
    <Icon name={iconName} />
    <Icon corner name='add' />
  </Icon.Group>

  const imageEditor = () => <ImageEditor
    imageFile={imageFile}
    imageImage={imageImage}
    imageEditProps={{ maxWidth: 240, maxHeight: 240 }}
    allSelectedImages={allSelectedImages}
    selectedFolderFiles={selectedFolderFiles}
    key={uniqueKey('su', 'ii')}
  />

  const handleSend = () => { }

  return <Segment as={'button'} className={cn} onClick={() => { handleSend() }}>
    {imageFile ? imageEditor() : iconGroup()}
    {useFileButton ? fileInput() : fileUploader()}
  </Segment>
}

export default UploadSegButton