import { where } from 'firebase/firestore';
import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from "react";
import { createKeyedObjectFromKey, createKeys, createObject } from '../../../../global/common/creating';
import { formatItem, formatTypes } from "../../../../global/common/dateFormatting";
import { getArrayIndex, sortObj, sortObject } from "../../../../global/common/sorting";
import { HeaderContext } from '../../../../global/cnr/contexts/HeaderContext';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { sortDB } from '../../helpers/league';
import { sportsSeasonHandlers, sportsSeasonInitialState, sportsSeasonReducer } from '../reducers/SportsSeasonReducer';
import { SportsOrganizationContext } from './SportsOrganizationContext';
import { SportsDistrictContext } from './SportsDistrictContext';
import { gEnums } from '../../../../global/enums/globalEnums';

const _useScoreBlock = false
const _useScoreTeamBlock = false
const _startHour = 19
const _usePlayoffBrackets = true

const _sportsButtonProps = {
  color: 'teal',
  icon: 'snowflake outline',
}

export const _sportsFormProps = {
  teamSettings: {
    youTubeLink: 'youTubeLink',
    twitterLink: 'twitterLink',
    teamAccess: 'teamAccess',
  },
  googleLinks: {
    googleSheetsId: 'googleSheetsId',
    rosterSheet: 'rosterSheet',
    scoresSheet: 'scoresSheet',
    sportAccess: 'sportAccess',
  },
  updateSeasonScores: {
    googleSheetsId: 'googleSheetsId',
    scoresSheet: 'scoresSheet',
  }
}

export const SportsSeasonContext = React.createContext()

export default function SportsSeasonProvider(props) {

  const parentContext = useContext(ParentContext)
  const { states, handlers } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state } = states ? states : {}
  const { appUser_handlers } = handlers ? handlers : {}

  // sportsOrganizationContext
  const sportsOrganizationContext = useContext(SportsOrganizationContext)
  const { sportsOrganization_state } = sportsOrganizationContext ? sportsOrganizationContext : {}
  const { districts: districts_org, schools: schools_org, useAppDarkMode } = sportsOrganization_state ? sportsOrganization_state : {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ? sportsDistrictContext : {}
  const { schools: schools_district } = sportsDistrict_state ? sportsDistrict_state : {}

  // appUserContext
  const { appUser } = appUser_state ? appUser_state : {}
  const { uid, email: email_appUser } = appUser ? appUser : {}

  // papsContext 
  const { view, pathViews } = paps_state ? paps_state : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_page, aps_global } = pageSettings ? pageSettings : {}
  const { sportSettings } = aps_global ? aps_global : {}
  const { matchScheduleDisplayType } = sportSettings ? sportSettings : {}
  const _matchScheduleDisplayType = matchScheduleDisplayType ? matchScheduleDisplayType : gEnums.matchScheduleDisplayTypes.selectedDate

  const headerContext = useContext(HeaderContext)
  const { header_handlers } = headerContext ? headerContext : {}

  const { viewItems: viewItems_page } = aps_page ? aps_page : {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ? viewItem : {}
  const { dataParents, useSeasonals } = dataSource ? dataSource : {}

  // organizations/districts/sports
  // organizations/sports

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const sportsMode = {
    organizations: pathViews && pathViews.organizations && !pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    // districtSports: pathViews && pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.teams ? true : false,
    districts: pathViews && pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    matches: pathViews && pathViews.matches ? true : false,
    schools: pathViews && pathViews.schools ? true : false,
    sports: pathViews && pathViews.sports && !pathViews.teams ? true : false,
    teams: pathViews && pathViews.teams ? true : false,
  }

  const init_state = { sportsMode, sportsButtonProps: _sportsButtonProps, useScoreBlock: _useScoreBlock, useScoreTeamBlock: _useScoreTeamBlock, pathViews, useAppDarkMode, viewItem, dataParents, useSeasonals, uid, ownerPath, inverted: false, usePlayoffBrackets: _usePlayoffBrackets, matchScheduleDisplayType: _matchScheduleDisplayType, _startHour, cssOpts: { name: 'ssc-normal' } }
  const [sportsSeason_state, sportsSeason_dispatch] = useReducer(sportsSeasonReducer, init_state, sportsSeasonInitialState)
  const sportsSeason_handlers = sportsSeasonHandlers(sportsSeason_dispatch, sportsSeason_state)
  const { matches_info, teams_info, latestSeason, googleLinks_info, sportsKey } = sportsSeason_state ? sportsSeason_state : {}
  const { teams } = teams_info ? teams_info : {}
  const { matches } = matches_info ? matches_info : {}
  const { gls_scores, gls_teams } = googleLinks_info ? googleLinks_info : {}

  useEffect(() => {
    Object.keys(sportsMode).forEach(smKey => {
      if (sportsMode[smKey]) {
        const _sportsMode = smKey
        const _sportsModeKey = pathViews[smKey]
        sportsSeason_handlers.handleSportsMode(_sportsMode, _sportsModeKey)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathViews.teams]);

  useEffect(() => {
    if (pathViews.sports) {
      sportsSeason_handlers.handleGetSportsSeasonInit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews.sports])

  useEffect(() => {
    if (districts_org) {
      sportsSeason_handlers.handleSetState('organization', districts_org, 'districts_info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [districts_org])

  useEffect(() => {
    if (schools_org) {
      sportsSeason_handlers.handleSetState('organization', schools_org, 'schools_info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [schools_org])

  useEffect(() => {
    if (schools_district) {
      sportsSeason_handlers.handleSetState('district', schools_district, 'schools_info')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [schools_district])

  useEffect(() => {
    if (latestSeason && header_handlers) {
      const _cap = pathViews.sports ? _.startCase(pathViews.sports) + ' ' + latestSeason : null
      header_handlers.handleSportsCaption(_cap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [latestSeason, pathViews])

  useEffect(() => {
    if (email_appUser) {
      if (gls_teams || gls_scores) {
        const itemAccess_teams = gls_teams ? _.pickBy(gls_teams, item => _.includes(item.teamAccess, email_appUser)) : {};
        const itemAccess_sport = gls_scores && gls_scores.sportAccess && gls_scores.sportAccess.includes(email_appUser) ? sportsKey : {}
        if (itemAccess_teams || itemAccess_sport) {
          const iat = itemAccess_teams ? Object.keys(itemAccess_teams) : []
          const ias = itemAccess_sport ? [itemAccess_sport] : []
          const _accessItems = [...iat, ...ias]
          appUser_handlers.handleUpdateAccess(_accessItems)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [gls_teams, gls_scores])

  // const {
  //   allTeams,
  //   matches,
  //   playoffMatches,
  //   playoffTournaments,
  //   rankings, 
  //   sportDistricts,
  //   sportLevels,
  //   teams
  // } = sportsSeason_state

  const getNextMatch = (matches) => {
    const today = new Date();
    const firstAfterToday = _.takeRight(_.filter(_.orderBy(matches, ['startDate'], ['desc']), item => new Date(item.startDate).setHours(_startHour, 0, 0, 0) >= today), 1);
    return firstAfterToday ? firstAfterToday[0] : null
  }

  const getLatestMatches = (matches, latestMatchesCount) => {
    const today = new Date();
    const result = _.takeRight(_.filter(_.orderBy(matches, ['startDate'], ['asc']), item => (item.results && item.results.complete === true) && new Date(item.startDate).setHours(_startHour, 0, 0, 0) < today), latestMatchesCount);
    return result
  }

  const getMissing = () => {
    const { matches } = sportsSeason_state
    if (matches) {
      const missing = _.filter(Object.values(matches), function (m) {
        const today = new Date()
        const yesterday = today.setDate(today.getDate() - 1)
        return (
          new Date(m.startDate) < yesterday
          && !m.results
          // && !m.cancelled
          // && !m.postponed
        );
      })
      if (missing) {
        const m_s = createKeyedObjectFromKey(missing)
        return m_s
      }
    }
  }

  const sportsSeason_fns = {

    getMatchDateGroups: (sortedMatches) => {

      const today = new Date()
      const todayF = formatItem(formatTypes.fullDate, today)

      // let districtMatchDates = _.groupBy(sortedMatches, 'districts')

      let matchDates = _.groupBy(sortedMatches, 'startDate')
      const x = sortObject(matchDates)

      const groups = []

      let groupKeys = Object.keys(x)
      groupKeys = _.sortBy(groupKeys, function (value) { return new Date(value); })

      groupKeys.forEach(gk => {
        groups.push({
          matchDate: gk,
          matches: createKeyedObjectFromKey(x[gk])
        })
      })

      if (groupKeys[todayF]) {
        groups.unshift({
          matchDate: todayF,
          matches: createKeyedObjectFromKey(x[todayF])
        })
      }

      const mz = getMissing()
      if (mz && Object.keys(mz).length > 0) {
        groups.push({
          matchDate: 'Missing',
          matches: mz
        })
      }

      return groups
    },

    getSelectedDateMatches: (matchDateGroups, ms_matchDates, ms_district, ms_level, team_favs, previewMatches) => {

      const { selected: date_selected } = ms_matchDates ? ms_matchDates : {}
      const { item: matchDate } = date_selected ? date_selected : {}

      // eslint-disable-next-line 
      const { selected: district_selected, items: district_items } = ms_district ? ms_district : {}
      const { item: district } = district_selected ? district_selected : {}

      let _district;
      if (districts_org && district) {
        const _d = _.findKey(districts_org, { name: district })
        if (_d) { _district = _d }
      }

      const { selected: level_selected } = ms_level ? ms_level : {}
      const { item: level } = level_selected ? level_selected : {}

      let _allMatches = {}
      if (matchDateGroups) {
        Object.keys(matchDateGroups).forEach(k => {
          const dgms = matchDateGroups[k].matches
          _allMatches = { ..._allMatches, ...dgms }
        })
      }

      const matchDateGroup = _.find(matchDateGroups, { matchDate: matchDate })
      const { matches: dateMatches, matchDate: selectedMatchDate } = matchDateGroup ? matchDateGroup : {}

      let sectionDateMatches;
      let levelDateMatches;

      if (_district) {
        switch (level) {
          case 'All':
            sectionDateMatches = dateMatches
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          case 'Non':
            sectionDateMatches = _.filter(dateMatches, { ls: 'Non-Section' })
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          default:
            sectionDateMatches = _.filter(dateMatches, { districts: _district, levels: level })
            levelDateMatches = _.groupBy(dateMatches, 'levels')
            break;
        }
      } else {
        switch (level) {
          case 'All':
            sectionDateMatches = dateMatches
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          case 'Non':
            sectionDateMatches = _.filter(dateMatches, { ls: 'Non-Section' })
            levelDateMatches = _.groupBy(dateMatches, 'ls')
            break;
          default:
            sectionDateMatches = _.filter(dateMatches, { levels: level })
            levelDateMatches = _.groupBy(dateMatches, 'levels')
            break;
        }
      }

      let favMatches;

      if (team_favs && dateMatches && !previewMatches && matchDate !== 'Missing') {
        Object.keys(team_favs).forEach(pfKey => {
          Object.keys(dateMatches).forEach(key_match => {
            const dm = dateMatches[key_match]
            if (dm.teams) {
              if (dm.teams.includes(pfKey)) {
                if (!favMatches) { favMatches = {} }
                favMatches[key_match] = dm
              }
            }
          })
        })
      }

      return { sectionDateMatches, levelDateMatches, matchDate: selectedMatchDate, favMatches, allMatches: _allMatches }
    },

    getFilteredMatches: (sortedMatched, teamKey, selectedLocation, selectedResult, selectedSection) => {
      let sms = sortedMatched
      if (selectedLocation) { sms = _.filter(sms, function (m) { return m[selectedLocation] && m[selectedLocation].id === teamKey; }) }
      if (selectedResult) { sms = _.filter(sms, function (m) { return m.results && m.results[selectedResult === 'win' ? 'winner' : 'loser'] && m.results[selectedResult === 'win' ? 'winner' : 'loser'].id === teamKey; }) }
      if (selectedSection) { sms = _.filter(sms, function (m) { return selectedSection === 'non' ? m.sections === 'Non' : m.sections !== 'Non'; }) }
      return sms
    },

    getMatchesByDate: (selectedDate) => {
      const f = _.pickBy(matches, function (m) {
        if (m) { return m.startDate === selectedDate }
      })
      if (f) {
        Object.keys(f).forEach(k => { f[k].key = k })
        const fs = _.sortBy(f, 'startDateUTC')
        return fs
      }
    },
    getTeamsAvailableByDate: (selectedDate) => {
      const teams_available = {}
      const f = _.pickBy(matches, function (m) {
        if (m) { return m.startDate === selectedDate }
      })
      if (f) {
        Object.keys(teams).forEach(teamKey => {
          const ta = _.pickBy(f, function (m) {
            if (m) { return m.teams.includes(teamKey) }
          })
          if (Object.keys(ta).length === 0) {
            teams_available[teamKey] = teams[teamKey]
          }
        })
        return teams_available
      }
    },
    getMatchesByTeam: (sortedMatched, teamKey, completedOnly, nextMatchOnly, latestMatchesOnly, latestMatchesCount) => {
      const tms = completedOnly ?
        _.filter(Object.values(sortedMatched), function (m) { return m.teams && m.teams.includes(teamKey) && (m.results && m.results.complete === true); })
        :
        _.filter(Object.values(sortedMatched), function (m) { return m.teams && m.teams.includes(teamKey); })

      if (nextMatchOnly) {
        const nextMatch = getNextMatch(tms)
        return [nextMatch]
      } else if (latestMatchesOnly) {
        const latestMatches = getLatestMatches(tms, latestMatchesCount)
        return latestMatches
      } else {
        const fs = _.sortBy(tms, 'startDateUTC')
        return fs
      }
    },
    getMissingMatchResults: () => {
      const { matches } = sportsSeason_state
      const missing = _.filter(Object.values(matches), function (m) {
        return (
          new Date(m.startDate) < new Date()
          && !m.results
          && !m.cancelled
          && !m.postponed
        );
      })
      if (missing) {
        const m_s = createKeyedObjectFromKey(missing)
        return m_s
      }
    },
    getMatchesByLevel: (sortedMatched, level) => {
      return _.filter(Object.values(sortedMatched), function (m) { return m.levels === level; })
    },
    getMatchesBySection: (sortedMatched, section) => {
      return _.filter(Object.values(sortedMatched), function (m) { return m.sections === section; })
    },
    getMatch: (sortedMatched, matchKey) => {
      return sortedMatched && sortedMatched[matchKey] ? [sortedMatched[matchKey]] : {}
      // return _.filter(Object.values(sortedMatched), function (m) { return m.id === matchKey; })
    },
    getTeamsByLevel: (level) => {
      const st = _.pickBy(teams, function (t) {
        if (t) { return t.levels === level }
      })
      if (st) {
        Object.keys(st).forEach(k => {
          st[k].key = k
        })
        return st
      }
    },
    getTeamsBySection: (level, section) => {
      const st = _.pickBy(teams, function (t) {
        if (t) { return t.levels === level && t.sections === section }
      })
      if (st) {
        Object.keys(st).forEach(k => {
          st[k].key = k
        })
        return st
      }
    },
    getTeamsByMatch: (home, away) => {
      const { sections: hs, levels: hl } = home ? home : {}
      const { sections: as, levels: al } = away ? away : {}
      const st = _.pickBy(teams, function (t) {
        const { levels: lt, sections: st } = t
        if (t) {
          return (
            (lt === hl && st === hs) ||
            (lt === al && st === as)
          )
        }
      })
      if (st) {
        Object.keys(st).forEach(k => {
          st[k].key = k
        })
        return st
      }
    },
    getTeamRpis: (teams, matches) => {

      const rpis = {}

      const getTeamInfo = (teamKey) => {
        const team = teams[teamKey]
        const { name, record } = team
        const { w, l } = record ? record : {}
        const mwp = (w && l) ? (w / (w + l)).toFixed(3) : 0
        const ret = { name, w, l, mwp }
        return ret
      }

      Object.keys(teams).forEach(teamKey => {
        const team = teams[teamKey]
        const { record } = team
        const { w, l } = record ? record : {}
        const mwp = (w && l) ? (w / (w + l)).toFixed(3) : 0
        rpis[teamKey] = { name: teams[teamKey].name, mwp: mwp, opponents: {} }
        const matches_team = sportsSeason_fns.getMatchesByTeam(matches, teamKey, true)
        if (matches_team) {
          matches_team.forEach(match_team => {
            const { away, home } = match_team
            if (away.id === teamKey) { rpis[teamKey].opponents[home.id + 'home'] = getTeamInfo(home.id) }
            if (home.id === teamKey) { rpis[teamKey].opponents[away.id + 'away'] = getTeamInfo(away.id) }
          })
        }
      })

      Object.keys(rpis).forEach(oppKey => {
        const opp = rpis[oppKey]
        const { opponents } = opp
        if (opponents) {
          let ws = 0
          let ls = 0
          Object.keys(opponents).forEach(key => {
            const _opp = opponents[key]
            const { w, l } = _opp
            if (w && l) {
              ws += w
              ls += l
            }
          })
          opp.oppW = ws
          opp.oppL = ls
          opp.omwp = (ws && ls) ? (ws / (ws + ls)).toFixed(3) : 0
          opp.rpi = (opp.mwp * 0.25) + (opp.omwp * 0.50) // + (OOWP * 0.25)
        }
      })
      return rpis
    },
    sortSectionStandings: (teams, playoffTeamsPerSection) => sortDB.sortSectionStandings(teams, playoffTeamsPerSection),
    sortLevelStandings: (teams) => sortDB.sortLevelStandings(teams),
    addTeamInfoToAthletes: (athletes) => {
      const { teams: allTeams } = teams_info ? teams_info : {}
      // adding the parentKey info to each of the data items
      if (athletes) {
        Object.keys(athletes).forEach(key => {
          const athlete = athletes[key]
          const { teams } = athlete ? athlete : {}
          if (allTeams) {
            const t = _.find(allTeams, { id: teams })
            if (t) {
              const { name, levels, sections } = t
              if (levels) { athlete.levels = levels }
              if (sections) { athlete.sections = sections }
              if (name) { athlete.teams = name }
            }
          }
        })
      }
    },

    getMatchAthletes: async (pathViews, collectionName, teamId, callback, teams) => {
      const _team = teams && teams[teamId] ? teams[teamId] : null
      const _getRef = createRefPath_event(pathViews, [collectionName])
      const wheres = [where('parentKeys.teams', '==', teamId)]
      const _teamRoster = await fs_get_data({ refPath: _getRef, wheres, opts: { returnFirstObject: true } })
      if (_teamRoster) {
        const _data = createKeys(_teamRoster['athletes'])
        const athletes = _.sortBy(_data, 'lastName')
        if (athletes && _team) {
          Object.keys(athletes).forEach(key => {
            athletes[key].teams = _team.name
          })
        }
        if (athletes) { callback(athletes) }
      }
    },

    updateTeamBests: (rankings, teams) => {
      if (teams && rankings) {
        Object.keys(teams).forEach(teamKey => {
          const team = teams[teamKey]
          team.weeklyRankings = {
            best: null,
            byWeek: []
          }
          const teamName = team.name
          const rankingsSorted = sortObj(rankings)
          Object.keys(rankingsSorted).forEach(weekKey => {
            const week = rankings[weekKey]
            Object.keys(week).forEach(levelKey => {
              if (team.levels === levelKey) {
                const weekLevelTeams = week[levelKey]
                const teamRank = getArrayIndex(weekLevelTeams, teamName)
                if (teamRank >= 0) {
                  const teamRankThisWeek = teamRank + 1
                  team.weeklyRankings.byWeek.push(teamRankThisWeek)
                  if (team.weeklyRankings.best) {
                    if (teamRankThisWeek < team.weeklyRankings.best) {
                      team.weeklyRankings.best = teamRankThisWeek
                    }
                  } else {
                    team.weeklyRankings.best = teamRankThisWeek
                  }
                } else {
                  team.weeklyRankings.byWeek.push(0)
                }
              }
            })
          })
        })
      }
    }
  }

  return sportsSeason_state ?
    <SportsSeasonContext.Provider
      value={{ sportsSeason_state, sportsSeason_handlers, sportsSeason_fns, appComponent: 'sportsSeason' }}>
      {props.children}
    </SportsSeasonContext.Provider>
    :
    <div></div>
}