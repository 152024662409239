import React from 'react'
import { Icon } from 'semantic-ui-react'
import { uniqueKey } from '../../common/keys'

export const DefaultImageIcon = (props) => {

  const {
    allowAction,
    cn,
    containerRef,
    handleShowBigImage,
    iconName,
    images_viewItem,
    isInit,
    isVisible,
    itemData,
    useLazyImageLoading,
  } = props ? props : {}

  const {
    iconSize,
    showDefaultImage,
    defaultImageIcon,
    getImageFromProp
  } = images_viewItem ? images_viewItem : {}

  let _iconName = iconName ? iconName : 'user'
  let iconColor = 'blue'

  if (useLazyImageLoading && !isVisible) { iconColor = 'grey' }
  if (defaultImageIcon) { _iconName = defaultImageIcon }

  if (showDefaultImage) {
    if (allowAction && !isInit && handleShowBigImage) {
      return <Icon.Group
        size={iconSize ? iconSize : 'huge'}
        key={uniqueKey('crdi', 'i', itemData.id)}
        onClick={() => { allowAction && handleShowBigImage && handleShowBigImage() }}
      >
        <Icon name={_iconName} color={iconColor} />
        <Icon corner name='add' />
      </Icon.Group>
    } else {
      return <div className={cn} key={uniqueKey('crdi', itemData.id)} ref={containerRef} >
        <Icon
          key={uniqueKey('crdi', 'i', itemData.id)}
          name={_iconName} size={iconSize ? iconSize : 'huge'}
          color={iconColor}
          onClick={() => { handleShowBigImage && handleShowBigImage() }}
        />
      </div>
    }
  } else {
    return null
  }
}
