import _ from 'lodash';
import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';

const PendingSimple = () => {

  const lines = (count) => (
    _.times(count, (index) => <Placeholder.Line key={uniqueKey('pnd', 'l', index)} />)
  )

  const phLines = (i) => (
    <Placeholder key={uniqueKey('pnd', 'pl', i)} fluid >
      {lines(2)}
    </Placeholder>
  )

  return <div className={'pending-container'}>
    <div className={'pending-header'}></div>
    <div className={'pending-content'}>
      {_.times(5, (i) => phLines(i))}
    </div>
  </div>
}

export default PendingSimple