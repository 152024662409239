import React from 'react';
import AppSettingsSidebarWithProvider from './AppSettingsSidebar';

export const SettingsSolo = (props) => {

  const { isGlobal } = props
  const showLeftSettings = true

  const viewSettingsSidebar = () => {
    const vssProps = { isGlobal, showLeftSettings, isSolo: true, }
    return <AppSettingsSidebarWithProvider {...vssProps} />
  }

  return viewSettingsSidebar()
}
