import _ from 'lodash'
// import { createMatchScorecards } from './createMatchScorecards'

export const calcMatchStatus = (golfMatch, holes) => {

  const { holeWinners, holeScores, matchNumber } = golfMatch

  const holesScoredCount = holeWinners ? getLastHolePlayed(holeWinners) : 0

  const matchHcps = getMatchLowHcp(golfMatch)

  const holeRemainings = 18 - holesScoredCount

  const matchStatus = {
    dormie: false,
    finalScore: null,
    finished: false,
    tied: false,
    holeRemainings,
    holesScored: holesScoredCount,
    lastHolePlayed: holesScoredCount,
    matchHcps,
    leadingBy: 0,
    leadingTeam: 0,
    matchNumber,
    status: '--',
    winner: null,
    team1: {
      holesWon: 0,
      holesHalved: 0,
      leadingBy: 0,
      leading: true,
      won: false,
      status: '--'
    },
    team2: {
      holesWon: 0,
      holesHalved: 0,
      leadingBy: 0,
      leading: true,
      won: false,
      status: '--'
    }
  }

  const golferScores = {}

  if (holes && holeScores) {

    Object.keys(golfMatch.team1).forEach((key) => {
      golferScores[key] = { total: 0, toPar: 0, totalNet: 0, toParNet: 0, hcp: golfMatch.team1[key].handicap }
    })

    Object.keys(golfMatch.team2).forEach((key) => {
      golferScores[key] = { total: 0, toPar: 0, totalNet: 0, toParNet: 0, hcp: golfMatch.team2[key].handicap }
    })

    // loop the holes
    Object.keys(holes).forEach(hk => {
      const h = holes[hk]
      const { par, holeNumber, handicap } = h
      // see if the score exists
      if (holeScores[holeNumber]) {
        Object.keys(golferScores).forEach(gskey => {
          if (holeScores[holeNumber][gskey]) {
            const score = holeScores[holeNumber][gskey]
            let netScore = score
            if (golferScores[gskey].hcp <= handicap) (netScore--)
            golferScores[gskey].total = golferScores[gskey].total + score
            golferScores[gskey].toPar = golferScores[gskey].toPar + (score - par)
            golferScores[gskey].totalNet = golferScores[gskey].totalNet + netScore
            golferScores[gskey].toParNet = golferScores[gskey].toParNet + (netScore - par)
          }
        })
      }
    })
  }

  if (holeWinners) {

    const hs1 = _.filter(holeWinners, { winningTeam: 1 })
    const hs2 = _.filter(holeWinners, { winningTeam: 2 })

    matchStatus.team1.holesWon = hs1.length
    matchStatus.team2.holesWon = hs2.length

    if (matchStatus.team1.holesWon > matchStatus.team2.holesWon) {
      matchStatus.team1.leadingBy = matchStatus.team1.holesWon - matchStatus.team2.holesWon
      matchStatus.team1.status = (matchStatus.team1.leadingBy) + ' Up'
      matchStatus.team1.leading = true
      matchStatus.leadingTeam = 1
      matchStatus.leadingBy = matchStatus.team1.leadingBy
    } else if (matchStatus.team2.holesWon > matchStatus.team1.holesWon) {
      matchStatus.team2.leadingBy = matchStatus.team2.holesWon - matchStatus.team1.holesWon
      matchStatus.team2.status = (matchStatus.team2.leadingBy) + ' Up'
      matchStatus.team2.leading = true
      matchStatus.leadingTeam = 2
      matchStatus.leadingBy = matchStatus.team2.leadingBy
    } else {
      matchStatus.status = 'Tied'
      matchStatus.team1.status = 'Tied'
      matchStatus.team2.status = 'Tied'
    }
  }

  if (matchStatus.leadingBy > holeRemainings) {
    matchStatus.finished = true
    matchStatus.winner = matchStatus.leadingTeam
    if (holeRemainings === 0) {
      matchStatus.finalScore = matchStatus.leadingBy + ' Up'
    } else {
      matchStatus.finalScore = matchStatus.leadingBy + ' & ' + holeRemainings
    }
  } else if (matchStatus.leadingBy === holeRemainings) {
    if (holeRemainings === 0) {
      matchStatus.finished = true
      matchStatus.finalScore = 'Tied'
    } else {
      matchStatus.dormie = true
      if (matchStatus.leadingTeam === 1) {
        matchStatus.dormieTeam = 1
      } else if (matchStatus.leadingTeam === 2) {
        matchStatus.dormieTeam = 2
      }
    }
  }

  matchStatus.golferScores = golferScores
  matchStatus.holeScores = holeScores
  matchStatus.holeWinners = holeWinners

  return matchStatus

}

const getMatchLowHcp = (golfMatch) => {

  let hcpLowG = {
    lowHandicap: 100,
    lowGolfer: null
  }

  const team1Hcps = getTeamHcp(golfMatch.team1)
  const team2Hcps = getTeamHcp(golfMatch.team2)

  if (team1Hcps.sum < team2Hcps.sum) {
    team2Hcps.actual = Math.ceil((team2Hcps.sum - team1Hcps.sum) * .40)
  } else if (team2Hcps.sum < team1Hcps.sum) {
    team1Hcps.actual = Math.ceil((team1Hcps.sum - team2Hcps.sum) * .40)
  } else {
    team2Hcps.actual = 0
  }

  hcpLowG = getLowHcpG(golfMatch.team1, hcpLowG)
  hcpLowG = getLowHcpG(golfMatch.team2, hcpLowG)

  return ({ team1Handicaps: team1Hcps, team2Handicaps: team2Hcps, lowHandicapG: hcpLowG })

}

export const getLowHcpG = (tg, hcpLowG) => {
  Object.keys(tg).forEach(key => {
    const g = tg[key]
    const h = g.courseHandicap
    if (parseInt(h) < hcpLowG.lowHandicap) {
      hcpLowG.lowHandicap = parseInt(h)
      hcpLowG.lowGolfer = g
    }
  })
  return hcpLowG
}

export const getTeamHcp = (tg) => {
  let hcpSum = 0
  Object.keys(tg).forEach(key => {
    const g = tg[key]
    const { matchHandicap: mh } = g ? g : {}
    hcpSum += parseInt(mh)
  })
  return {
    sum: hcpSum,
    ave: Math.ceil(hcpSum / 2),
    actual: 0,
  }
}

export const getLastHolePlayed = (holeWinners) => {
  const s = holeWinners ? Object.keys(holeWinners).pop() : 0
  return parseInt(s)
}