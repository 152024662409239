import _ from 'lodash';
import React, { useContext } from 'react';
import { CreateContext } from '../../../cnr/contexts/CreateContext';
import { DataModifyContext } from '../../../cnr/contexts/DataModifyContext';
import { SettingParentContext } from '../../../cnr/contexts/SettingParentContext';
import { ViewSettingsComponentContext } from '../../../cnr/contexts/ViewSettingsComponentContext';
import { isCreateReady } from '../../../cnr/reducers/CreateReducer';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import { gEnums } from '../../../enums/globalEnums';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { vsComponentTypes } from '../../enums/vsComponentTypes';
import { createEventTypes, CreateEventWithProvider } from '../addSubs/CreateEvent';
import { InputContent } from '../createSubs/InputContent';
import { selectedOptions } from '../createSubs/selectedOptions';
import { SelectExistingContent } from '../createSubs/SelectExistingContent';
import { fetchButtonProps } from './fetchButtonProps';

const ApiData = React.lazy(() => import('../../../components/api/ApiData'));
const CopyViewItem = React.lazy(() => import('./CopyViewItem'));
const CreateClient = React.lazy(() => import('../addSubs/CreateClient'));
const MapListContent = React.lazy(() => import('../createSubs/MapListContent'));
const NormalContent = React.lazy(() => import('../createSubs/NormalContent'));
const PageFieldList = React.lazy(() => import('./PageFieldList'));
const SelectAdditionalContent = React.lazy(() => import('../createSubs/SelectAdditionalContent'));
const SelectContent = React.lazy(() => import('../createSubs/SelectContent'));
const SettingsViewer = React.lazy(() => import('../../actions/SettingsViewer'));
const SheetsContent = React.lazy(() => import('../createSubs/SheetsContent'));
const SheetsDataContent = React.lazy(() => import('../createSubs/SheetsDataContent'));
const SubEventsContent = React.lazy(() => import('../createSubs/SubEventsContent'));
const UrlContent = React.lazy(() => import('../createSubs/UrlContent'));
const ViewItemPaste = React.lazy(() => import('../../importing/ViewItemPaste'));

/**
 * 
 * @returns a Content item based on the `selectedAddMode` or `_addModeType` from createContext
 */
const AddContent = () => {

  const _amts = gEnums.addModeTypes

  const settingsParentContext = useContext(SettingParentContext)
  const { states } = settingsParentContext ? settingsParentContext : {}
  const { page_state, global_state } = states ? states : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_viewItems, aps_page } = pageSettings ? pageSettings : {}

  let { viewItems: viewItems_page } = aps_page ? aps_page : {}

  if (!viewItems_page) { viewItems_page = {} }

  // createContext
  const createContext = useContext(CreateContext)
  const { create_handlers, create_state, grProps, csProps, _addModeType, selectedAddMode } = createContext ? createContext : {}

  // globalContext 
  const { settings_globalPageItems } = global_state ? global_state : {}

  // viewSettingsComponentContext 
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { swipedItems, isGlobal, swipedItem } = vsc_state ? vsc_state : {}
  const { viType } = swipedItem ? swipedItem : {}

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_state, dataModify_handlers } = dataModifyContext ? dataModifyContext : {}
  const { itemProps: dataItemProps, data_modified, data_modified_ready } = dataModify_state ? dataModify_state : {}

  const {
    createOrigin,
    createType,
    isDirect,
    lockedData,
    useWizard,
    viewItem_page,
    viewItem,
  } = csProps

  const {
    componentContexts,
    createInfo,
    formData,
    jsonError,
    mapList,
    selectedDataItems,
    selectedItems,
    updating,
  } = create_state

  let firstSw;
  if (swipedItems) { firstSw = swipedItems[0] }

  const { handleFetch_url, handleSetMapList } = create_handlers

  const normalProps = dataItemProps ? true : false
  const noData = true

  const amt = selectedAddMode ? selectedAddMode : _addModeType

  const checkReady = () => {
    const { directReady } = isCreateReady(create_state, data_modified_ready, mapList, selectedItems, selectedDataItems)
    return directReady
  }

  /**
   * 
   * @description Handles the adding of content
   */
  const handleAddContent = () => {
    create_handlers.handleStartUpdate()
    create_handlers.handleCommitCreateConfirmation()
  }

  /**
   * Handles the change to any of the props
   * @param {object} formDataInfo 
   */
  const handleLocalPropChange = (formDataInfo) => {
    if (normalProps) {
      dataModify_handlers.handleFormDataChange_dataModify(formDataInfo)
      // dataModify_handlers.handlePropChange(formDataInfo)
    } else {
      create_handlers.handleFormDataChange_create(formDataInfo)
    }
  }

  const hds = { handleFormDataChange_local: handleLocalPropChange }

  const getSubProps = (selectedAddMode) => {
    return {
      addModeType: selectedAddMode ? selectedAddMode : _addModeType,
      buttonInfo: fetchButtonProps({ addModeType: selectedAddMode ? selectedAddMode : _addModeType, createType, create_handlers, create_state }),
      createType,
      formData,
      hds,
      isDirect,
      isGlobal,
      data_modified,
      componentContexts,
      selectedItems,
      swipedItem,
      useWizard,
    }
  }

  const subProps = getSubProps(selectedAddMode)

  /**
   * 
   * @param {object} inputPane This is the form
   * @returns A component with a Form and a SettingsViewer
   */
  const settingsViewer = (inputPane) => <SettingsViewer
    inputPane={inputPane}
    createType={createType}
    createInfo={createInfo}
    jsonError={jsonError}
  />

  /**
   * 
   * @returns the save button that will trigger handleAddContent 
   */
  const footer = () => <UiSaveButtons
    save={{ caption: _.startCase(createType), oc: handleAddContent, disabled: !checkReady(), icon: 'cog' }}
  ></UiSaveButtons>

  const wrapper = (content) => <Wrapper
    content={content}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
  />

  const content = () => {

    switch (amt) {

      case _amts.additionalPageItems:
        return <SelectAdditionalContent selectExistingProps={{ selectedItems, grProps }} />

      case _amts.api:
        return <ApiData viewItem={viewItem} />

      case _amts.select:
        const selectProps = { ...subProps }
        switch (createType) {
          case vsComponentTypes.createClient:
            return <CreateClient />
          default:
            return <SelectContent selectProps={selectProps} />
          // nothing
        }

      case _amts.registrationKey:
        return <CreateEventWithProvider createEventType={createEventTypes.reg} />

      case _amts.registrationKeyAndGoogleSheets:
        return <CreateEventWithProvider createEventType={createEventTypes.regAndGoogleSheets} />

      case _amts.previousEvent:
        return <CreateEventWithProvider createEventType={createEventTypes.previous} />

      case _amts.googleSheets:
      case _amts.googleSheetsOnly:
        const sheetsProps = { ...subProps, createOrigin }
        let sdc;

        switch (createOrigin) {
          case gEnums.createOriginTypes.createDataModify:
          case gEnums.createOriginTypes.dataModify:
            sdc = <SheetsDataContent sheetsProps={sheetsProps} />
            break;
          case gEnums.createOriginTypes.createDataSettings:
            sdc = <SheetsContent sheetsProps={sheetsProps} />
            break;

          case gEnums.createOriginTypes.viewSettings:
            return <CreateEventWithProvider addModeType={amt} />

          case gEnums.createOriginTypes.createSettings:
            sdc = <SheetsDataContent sheetsProps={sheetsProps} />
            break;
          default:
            sdc = <SheetsContent sheetsProps={sheetsProps} />
          // nothing
        }

        return settingsViewer(sdc)

      case _amts.copyExisting:
        return wrapper(<CopyViewItem subProps={subProps} />)

      case _amts.listAdd:
        return wrapper(<PageFieldList subProps={subProps} />)

      case _amts.existingItems:
      case _amts.pageItems:
      case _amts.views:
        // views_app 
        const sos = selectedOptions(isGlobal, noData, settings_globalPageItems, aps_viewItems, viewItems_page, firstSw, swipedItems, swipedItem, amt)
        const selectExistingProps = { selectedOptions: sos, selectedItems, grProps }
        return wrapper(<SelectExistingContent selectExistingProps={selectExistingProps} />)

      case _amts.input:
        const inputProps = { ...subProps }
        switch (createType) {
          default:
            switch (viType) {
              case vsComponentTypes.props:
              case vsComponentTypes.propSections:
              case vsComponentTypes.rssFeeds:
              case vsComponentTypes.viewItems:
              case vsComponentTypes.views:
                inputProps.camelCase = true
                break;
              default:
              // nothing
            }
            return wrapper(<InputContent inputProps={inputProps} />)
        }

      case _amts.list:
        const mapListProps = { ...subProps, handleSetMapList, isNew: true }
        return <MapListContent mapListProps={mapListProps} />

      case _amts.normal:
        const normProps = { viewItem, viewItem_page, ...subProps }
        return <NormalContent normalProps={normProps} lockedData={lockedData} />

      case _amts.pasteImport:
        return <ViewItemPaste viewItem={viewItem_page} />

      case _amts.subEvent:
        const subEventsProps = { ...subProps }
        return <SubEventsContent subEventsProps={subEventsProps} />

      case _amts.url:
        const urlProps = { handleFetch_url, ...subProps }
        return <UrlContent urlProps={urlProps} />

      default:
        return <div>---</div>
    }
  }

  return content()

};

export default AddContent