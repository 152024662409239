import _ from 'lodash';
import React, { createContext, Suspense, useContext, useEffect, useReducer, useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { getAllowedDataModificationKeys, getDataModifications } from '../../auth/actionAccessPermissions';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { getAuthIcon } from '../../auth/authPermissions';
import { DataContext } from '../../cnr/contexts/DataContext';
import { FirestoreContext } from '../../cnr/contexts/FirestoreContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { dataModificationsHandlers, dataModificationsInitialState, dataModificationsReducer } from '../../cnr/reducers/DataModificationsReducer';
import SuspenseDimmer from '../../components/alerts/SuspenseDimmer';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { appIconTypes } from '../../enums/appIconTypes';
import { dataModificationTypes } from '../../viewSettings/enums/itemActionTypes';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { fullFilterTypes, UiDataFilters } from '../UiDataFilters';
import QrScan from './pageItemActions/QrScan';
import ImageGallery from '../../components/imaging/ImageGallery';
import { gEnums } from '../../enums/globalEnums';

const UiAmmend = React.lazy(() => import('./dataManagement/UiAmmend'));
const UiStatus = React.lazy(() => import('./dataManagement/UiStatus'));
const UiUpload = React.lazy(() => import('./dataManagement/UiUpload'));
const UiAddEdit = React.lazy(() => import('./dataModifications/UiAddEdit'));
const UiClipboard = React.lazy(() => import('./dataModifications/UiClipboard'));
const UiCredentialing = React.lazy(() => import('./dataModifications/UiCredentialing'));
const UiDataLinking = React.lazy(() => import('./dataModifications/UiDataLinking'));
const UiSorting = React.lazy(() => import('./dataModifications/UiSorting'));
const UiSubAction = React.lazy(() => import('./dataModifications/UiSubAction'));

export const DataModificationsContext = createContext();

export const dataModificationDisplayTypes = {
  full: 'full',
  direct: 'direct',
  menu: 'menu',
  menuHeader: 'menuHeader'
}

/**
 * This 
 * @param {object} props (direct) 
 * @returns a Menu with the dataModification menuItems
 * @contexts DataModificationsContext, uiItemContext, dataContext
 */
const UiDataModifications = (props) => {

  const { dataModificationDisplayType, modifyActionType } = props ? props : {}

  let trimBySingle;
  let fullPage;

  switch (dataModificationDisplayType) {
    case dataModificationDisplayTypes.direct:
      trimBySingle = true
      fullPage = true
      break;

    case dataModificationDisplayTypes.menuHeader:
      trimBySingle = true
      break;
    default:
      trimBySingle = false
  }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state, eventInfo_state } = states
  const { page_fns } = fns
  const { pageSettings, pageNav } = page_state ? page_state : {}
  const { navOptions, navUiItemContext } = pageNav ? pageNav : {}
  const { pageNavOptions } = navOptions ? navOptions : {}
  const { dataModification: dataModification_pio } = pageNavOptions ? pageNavOptions : {}

  const { aps_global } = pageSettings ? pageSettings : {}
  const { dataOptions, dataRestrictions, dataModifications: dataModifications_global } = aps_global ? aps_global : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const { staticViewKeys } = eventInfo_state ? eventInfo_state : {}
  const { autoUpdateCollectionRelationships, useTimestamps, restrictArrayEdits } = dataOptions ? dataOptions : {}
  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging } = homeSettings_global ? homeSettings_global : {}

  const appUserAccess = getAppUserAccess(appUser)

  // firestoreContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ? frameworkContext : {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ? dataContext : {}
  const { viewListData: vld } = data_state ? data_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);

  const _uiItemContext = navUiItemContext ? navUiItemContext : uiItemContext

  const { item_handlers, item_state } = _uiItemContext ? _uiItemContext : {}
  const { viewItem, singleDataItem } = item_state ? item_state : {}
  const { handleCloseOpenedItem: handleItemClose } = item_handlers ? item_handlers : {}
  const { dataModifications: dataModifications_pageItem, key: key_viewItem } = viewItem ? viewItem : {}

  const _dataModifications_pageItem = dataModifications_pageItem // dataModification_pio ? dataModification_pio : dataModifications_pageItem

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ? dataManagementContext : {}
  const { viewItem_preview, viewDataMode } = dataManagement_state ? dataManagement_state : {}

  const _viewItem = viewItem_preview ? viewItem_preview : viewItem

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers } = firestoreContext ? firestoreContext : {}

  const _initState = {
    appData: vld,
    appUser,
    autoUpdateCollectionRelationships,
    dataRestrictions,
    firestore_handlers,
    framework_handlers,
    handleItemClose,
    item_handlers,
    logging,
    modifyActionType,
    page_fns,
    paps_state,
    restrictArrayEdits,
    staticViewKeys,
    trimBySingle,
    useTimestamps,
    viewDataMode,
    viewItem: _viewItem,
  }

  const [dataModifications_state, dataModificationsDispatch] = useReducer(dataModificationsReducer, dataModificationsInitialState(_initState));
  const dataModifications_handlers = dataModificationsHandlers(dataModificationsDispatch, dataModifications_state)
  const { dataModificationItems, dataModificationType, subModificationType } = dataModifications_state ? dataModifications_state : {}
  const { handleDataModificationSelect } = dataModifications_handlers ? dataModifications_handlers : {}

  const _dataModifications = getDataModifications(_dataModifications_pageItem, dataModifications_global, singleDataItem, appUserAccess, trimBySingle)

  const [activeItem, setActiveItem] = useState()

  // this creates the allowed list of dataModifications 
  useEffect(() => {
    if (_dataModifications) {
      const _dataModificationItems = getAllowedDataModificationKeys(_dataModifications, appUserAccess)
      dataModifications_handlers.handleDataModificationItems(_dataModificationItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleOpenMenuItem = () => setActiveItem(activeItem ? false : true)

  const handleCancel = () => {
    handleItemClose && handleItemClose()
    handleDataModificationSelect && handleDataModificationSelect()
  }

  const menuItem = (item, dmiKey, subKey) => {
    const { accessLevel } = item ? item : {}
    const icon = getAuthIcon(accessLevel, true)
    const cc = subKey ? _.startCase(subKey) : _.startCase(dmiKey)
    const cn = icon ? 'iconed' : null
    return <Menu.Item
      key={subKey ? subKey : dmiKey}
      className={cn}
      onClick={() => { handleDataModificationSelect(dmiKey, subKey) }}
    >
      {icon && <Icon name={icon.name} color={icon.color} ></Icon>}
      {cc}
      <Icon name={appIconTypes.arrowRight} color={'blue'}></Icon>
    </Menu.Item>
  }

  const menuElements = () => {
    const elements = []
    Object.keys(dataModificationItems).forEach(dmiKey => {
      const item = dataModificationItems[dmiKey]
      switch (dmiKey) {
        case 'subAction':
          const { allowAction, allow, subActionTypes } = item ? item : {}
          if ((allowAction || allow) && subActionTypes) {
            subActionTypes.sort()
            subActionTypes.forEach(sat => {
              elements.push(menuItem(item, dmiKey, sat))
            })
          }
          break;
        default:
          elements.push(menuItem(item, dmiKey))
      }
    })
    return elements
  }

  const menu = () => {
    return <Menu vertical fluid className={'menu-slim'}>
      {menuElements()}
    </Menu>
  }

  const menuHeader = () => <Menu.Item className='piom'>
    <Menu.Header onClick={() => handleOpenMenuItem()}>
      <div><Icon name={'cog'}></Icon></div>
      <div>{'Data Modifications'}</div>
      <div><Icon name={'angle right'} color={'blue'}></Icon></div>
    </Menu.Header>
    <Menu.Menu className={activeItem ? '' : 'dis-none'}>
      {menuElements()}
    </Menu.Menu>
  </Menu.Item>

  /**
   * 
   * @returns A Wrapper
   */
  const wrapper = () => <Wrapper
    content={dataModificationItems ? menu() : <div></div>}
    wrapperType={wrapperTypes.paddedHeader}
  />

  /**
   * 
   * @returns a FullPageWrapper
   */
  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={'Data Modifications'}
    topperCaption2={_.startCase(key_viewItem)}
  />

  const content = () => {
    if (dataModificationType) {
      return <DataModificationContent
        dataModificationType={dataModificationType}
        subModificationType={subModificationType}
        singleDataItem={singleDataItem}
        dataModifications={_dataModifications}
        handleCancel={handleCancel}
      />
    } else {
      switch (dataModificationDisplayType) {
        case dataModificationDisplayTypes.menuHeader:
          return dataModificationItems ? menuHeader() : <div></div>
        default:
          if (fullPage) {
            return fullPageWrapper()
          } else {
            return wrapper()
          }
      }
    }
  }

  const contentWithContext = () => <DataModificationsContext.Provider value={{ dataModifications_state, dataModifications_handlers }}>
    <Suspense fallback={<SuspenseDimmer origin={'Ui Data Modification'} />}>
      {content()}
    </Suspense>
  </DataModificationsContext.Provider>

  return contentWithContext()

}

const DataModificationContent = (props) => {

  const { dataModificationType, subModificationType, singleDataItem, dataModifications, handleCancel } = props ? props : {}

  switch (dataModificationType) {

    case dataModificationTypes.activate:
    case dataModificationTypes.delete:
    case dataModificationTypes.modifyList:
      return <UiAmmend singleDataItem={singleDataItem} />

    case dataModificationTypes.status:
      return <UiStatus singleDataItem={singleDataItem} />

    case dataModificationTypes.add:
      const { add } = dataModifications ? dataModifications : {}
      const { allowScan } = add ? add : {}
      if (allowScan) {
        return <QrScan qrAdd={add} />
      } else {
        return <UiAddEdit />
      }

    case dataModificationTypes.edit:
      const { edit } = dataModifications ? dataModifications : {}
      return <UiAddEdit />

    case dataModificationTypes.images:
      return <ImageGallery
        handleCancel={handleCancel}
        storageLocationType={gEnums.storageLocationTypes.pageDirect}
        storageType={gEnums.storageTypes.image}
      />

    case dataModificationTypes.bulkImageUpload:
    case dataModificationTypes.images:
    case dataModificationTypes.pdfs:
      return <UiUpload origin={'UiDataModifications'} showUpload={true} />

    case dataModificationTypes.clipboard:
      return <UiClipboard />

    case dataModificationTypes.connections:
    case dataModificationTypes.dataLinking:
      return <UiDataLinking />

    case dataModificationTypes.credentialing:
      return <UiCredentialing />

    case dataModificationTypes.filters:
      return <UiDataFilters fullFilterType={fullFilterTypes.create} />

    case dataModificationTypes.sorting:
      return <UiSorting />

    case dataModificationTypes.subAction:
      return <UiSubAction subActionType={subModificationType} />

    default:
      return <div></div>
  }
}

export default UiDataModifications