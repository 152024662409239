import { collection, doc, getDoc, getDocs, getFirestore, query } from "firebase/firestore";
import _ from 'lodash';
import { isOdd } from "../../common/filtering";
import { arrayString } from "./fsAppData";

/**
 * Reads the document referred to by this DocumentReference OR Executes the query and returns the results as a QuerySnapshot.
 * @param {*} item 
 * @returns 
 */
export const doc_get_promise = (item) => {
  if (item) {
    if (item.type === 'document') {
      return getDoc(item)
    } else {
      return getDocs(item)
    }
  }
}

/** Returns an update reference (updateDoc) */
/**
 * Gets a DocumentReference instance that refers to the document at the specified absolute path.
 * @param {*} refPath  
 */
export const doc_ref = async (refPath) => {
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const fs = getFirestore()
  return doc(fs, ps)
}

/**
 * Gets are Reference
 * @param {object} fs - the firestore object (optional)
 * @param {any} refPath - the refPath (string or array)
 * @param {array} wheres - array of `where` objects
 * @returns a `get reference` (doc, collection + query)
 */
export const ref_get = (fs, refPath, wheres) => {
  const count = _.isArray(refPath) ? refPath.length : refPath.split('/').length
  const isCollection = isOdd(count) ? true : false
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const _fs = fs ? fs : getFirestore()
  const ref_collectionOrDoc = isCollection ? collection(_fs, ps) : doc(_fs, ps)
  return wheres ? query(ref_collectionOrDoc, ...wheres) : ref_collectionOrDoc
}


