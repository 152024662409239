import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { getFbConfig } from './fbConfiguration';

const config = getFbConfig()
const firebaseApp = initializeApp(config)

const messaging = getMessaging()

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const handleOnMessage = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
}

export default firebaseApp;
