import { last } from 'lodash';
import React from 'react';
import { BrowserRouter as Router, HashRouter, Route, Routes } from 'react-router-dom';
import AppSub, { subAppTypes } from './AppSub';
import './cssMain/AppC.css';
import './cssMain/AppG.css';
import './cssMain/AppVs.css';
import './cssMain/me.css';
import AppSettingsProvider from './global/cnr/contexts/AppSettingsContext';
import AuthProvider from './global/cnr/contexts/AuthContext';
import ComponentsProvider from './global/cnr/contexts/ComponentsContext';
import StartProvider from './global/cnr/contexts/StartContext';
import AppRoutes from './global/page/AppRoutes';
import PageNotFound from './global/page/PageNotFound';
import { getHashPath, getPathViews } from './global/redirection/current';
import { handleOnMessage } from './project/firebase'
import * as dotenv from 'dotenv'

export const useHash = true
export const useIconFolder = false
export const hashmark = '#'
export const consoleStart = false

const useRedirect = true

/** Starting point fo the App
 * @includes StartProvider  
 * @includes AuthProvider  
 * @includes AppSettingsProvider  
 * @includes HashRouter
 * @includes HashRouter
 * @includes Pages
 *  */
function App() {

  let { _hash, _redirect, _pathNameOriginal } = getHashPath()

  if (_hash && _hash.startsWith('#')) { _hash = _hash.substring(1) }

  const pvs = _hash ? getPathViews(_hash) : getPathViews(_hash)
  const lastItem = last((_hash).split('/'))

  const getSubA = () => {
    if (lastItem === subAppTypes.emailSignInConfirmation) {
      return lastItem
    } else if (pvs.willCall || lastItem === subAppTypes.willCall) {
      return subAppTypes.willCall
    } else if (pvs.willCallPhone || lastItem === subAppTypes.willCallPhone) {
      return subAppTypes.willCallPhone
    } else if (pvs.willCallEmail || lastItem === subAppTypes.willCallEmail) {
      return subAppTypes.willCallEmail
    } else if (lastItem === subAppTypes.willCallHome) {
      return lastItem
    }
  }

  handleOnMessage().then(payload => {
    // setShow(true);
    // setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log('handleOnMessage: payload', payload);
  }).catch(err => console.log('failed: ', err));

  const subA = getSubA()

  if (subA) {
    return <AppSub lastItem={subA} />
  } else {
    if (useRedirect && _redirect) {
      return <div className='App' >
        <Router>
          <Routes>
            <Route path={_pathNameOriginal} element={<PageNotFound />} />
            <Route path={'/404'} element={<PageNotFound />} />
          </Routes>
        </Router>
      </div>
    } else {
      return <StartProvider>
        <AuthProvider>
          <AppSettingsProvider>
            <ComponentsProvider>
              <div className='App' >
                {!useHash && <Router><AppRoutes /></Router>}
                {useHash && <HashRouter><AppRoutes /></HashRouter>}
              </div >
            </ComponentsProvider>
          </AppSettingsProvider>
        </AuthProvider>
      </StartProvider>
    }
  }
}

export default App; 