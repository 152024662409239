import _ from 'lodash'
import { createResponse } from '../../../altFunctions/common'
import { fsfn_createProject } from '../../../functions/fbCreate'
import { fsfn_createEventFromFetch, fsfn_fetchData } from '../../../functions/fbFetchFunctions'
import { fsfn_createProjectFromSheets } from '../../../functions/fbSheetsFuntions'
import { createResponseTypes, createTypes } from '../CreateReducer'

export const frts = {
  handleFetch_googleSheet: 'handleFetch_googleSheet',
  handleFetch_url: 'handleFetch_url',
  handleFetch_urlFeed: 'handleFetch_urlFeed',
  handleFetch_urlFeedData: 'handleFetch_urlFeedData',
  handleFetchResponse: 'handleFetchResponse',
  handleCreateProject: 'handleCreateProject',
  handleCreate_eventFromFetch: 'handleCreate_eventFromFetch',
}

const fetchOriginTypes = {
  feed: 'feed',
  googleSheet: 'googleSheet',
  ivs: 'ivs',
  url: 'url',
}

/**
 * Use dispatch({ type: frts... }); to access
 * @param {*} state 
 * @param {*} action 
 * @param {*} opts 
 * @returns 
 */
export const fetchReducers = (state, action, handleCreateResponse, handleResponse_createInfoCreated) => {

  const { googleSheetsImportOptions, paps_state, formData, regUrl, aps_viewItems, createType, logging } = state
  const { response_data, type, mode, clientRegUrl, dispatch } = action
  let { info } = state

  const appArea = createType === createTypes.createEvent ? 'event' : 'client'
  const { pathViews } = paps_state ? paps_state : {}

  const { handleFetchResponse } = fetchHandlers(dispatch)

  switch (type) {
    // ret
    case frts.handleFetch_googleSheet:
      if (!state.updating) {
        const sheetProps = { formData, pathViews }
        const response_data = fsfn_createProjectFromSheets(sheetProps, appArea, googleSheetsImportOptions, logging)
        return handleResponse_createInfoCreated(response_data)
      }
      return { ...state, mode, fetchOrigin: fetchOriginTypes.googleSheet, updating: true, createInfo: null, createError: null }

    // Fetching the URL Feed only. This does not create the project.
    case frts.handleFetch_urlFeed:
      const _eventKey = action.eventKey ? action.eventKey : formData.eventKey
      const urlDataF = { url: regUrl + _eventKey }
      fsfn_fetchData(urlDataF).then(response_data => {
        return handleFetchResponse(response_data)
      })
      return { ...state, mode, fetchOrigin: fetchOriginTypes.url, updating: true, urlData: urlDataF }

    // FETCHING - URL
    case frts.handleFetch_url:
      const urlData = { url: formData.url }
      fsfn_fetchData(urlData).then(response_data => {
        return handleFetchResponse(response_data)
      })
      return { ...state, mode, fetchOrigin: fetchOriginTypes.url, updating: true, urlData: urlDataF }

    // FETCHING - FEED DATA
    case frts.handleFetch_urlFeedData:
      const url = clientRegUrl ? clientRegUrl + info.eventId : regUrl + info.eventId
      const urlDataFF = { url }
      fsfn_fetchData(urlDataFF).then(response_data => {
        return handleFetchResponse(response_data)
      })
      return { ...state, mode, fetchOrigin: fetchOriginTypes.feed, updating: true, urlData: urlDataFF }

    case frts.handleCreate_eventFromFetch:
      const urll = clientRegUrl ? clientRegUrl + info.eventId : regUrl + info.eventId
      const urlDataFFF = { url: urll, pathViews: action.pathViews }
      fsfn_createEventFromFetch(urlDataFFF).then(response_data => {
        return handleCreateResponse(response_data)
      }).catch(err => {
        return handleCreateResponse(createResponse(false, 'Not Successfull', createResponseTypes.catchError, err))
      })
      return { ...state, mode, fetchOrigin: fetchOriginTypes.feed, updating: true, urlData: urlDataFFF }

    // FETCHING - RESPONSE
    case frts.handleFetchResponse:

      const { result: fetch_result, success: fetch_success } = response_data ? response_data : {}

      if (info) {
        info.regUrl = regUrl + info.eventId // info.eventId
      } else {
        info = {
          regUrl: regUrl
        }
      }
      return {
        ...state,
        response_data,
        fetchResult: fetch_result,
        fetchResultIsText: !_.isObject(fetch_result),
        fetchSuccess: fetch_success,
        info,
        creating: false,
        updating: false,
        fetchConfirmation: getCreateConfirmation(fetch_success, type, response_data, state.urlData),
      }

    case frts.handleCreateProject:

      console.log('handleCreateProject')

      const createEventProps = {
        initialData: state.fetchResult,
        appArea,
        settingsGlobal: { viewItems: aps_viewItems },
        fromRegUrl: true,
        googleSheetsId: formData && formData.googleSheetsId,
        eventName: formData && formData.name,
        settingsDocName: pathViews.clients
      }

      // create the project in functions this goes to the server function  
      const xp = fsfn_createProject(createEventProps)

      xp.then(response_data_project => {

        const { result: project_result, success: project_success } = response_data_project ? response_data_project : {}

        handleResponse_createInfoCreated(response_data_project)

        return {
          ...state,
          projectResult: project_result,
          info,
          creating: false,
          updating: false,
          fetchConfirmation: getCreateConfirmation(project_success, type, project_result, state.urlData),
        }
      }).catch(() => {
        return { ...state }
      })
      return { ...state }

    default:
      return { ...state }
  }
}

export const fetchHandlers = (dispatch) => {
  return {
    handleFetch_googleSheet: () => { dispatch({ type: frts.handleFetch_googleSheet, dispatch }) },
    handleFetch_url: () => { dispatch({ type: frts.handleFetch_url, dispatch }) },
    handleFetch_urlFeed: (mode, eventKey) => { dispatch({ type: frts.handleFetch_urlFeed, dispatch, mode, eventKey }) },
    handleFetch_urlFeedData: (mode, clientRegUrl) => { dispatch({ type: frts.handleFetch_urlFeedData, dispatch, mode, clientRegUrl }) },
    handleFetchResponse: (response_data) => { dispatch({ type: frts.handleFetchResponse, dispatch, response_data }) },
    handleCreateProject: () => { dispatch({ type: frts.handleCreateProject, dispatch }) },
  }
}


const getCreateConfirmation = (success, type, result, urlData) => {

  const { message, error } = result ? result : {}

  switch (type) {

    case createResponseTypes.createClientSuccess:
      return {
        title: success ? 'Client created' : 'Client not created',
        success: true,
        content: success ? 'The data and settings for the Client have been created.' : 'The data and settings for the Client have NOT been created.'
      }

    case createResponseTypes.createClientFailed:
      return {
        title: 'Client NOT created',
        success: false,
        content: 'The data and settings for the Client have NOT been created.'
      }

    case createResponseTypes.createEventSuccess:
      return {
        title: success ? 'Event created' : 'Event not created',
        success: true,
        content: success ? 'The data and settings for the event have been created.' : 'The data and settings for the Client have NOT been created.'
      }

    case createResponseTypes.createEventFailed:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'The data and settings for the event have NOT been created.'
      }

    case createResponseTypes.noClient:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'Client not found!'
      }

    case createResponseTypes.noClientMobileKey:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'No client mobile key!'
      }

    case createResponseTypes.invalidMode:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'Invalid mode'
      }

    case createResponseTypes.clientAlreadyExists:
      return {
        title: 'Client NOT created',
        success: false,
        content: 'A Client with the same name already exists.'
      }

    case createResponseTypes.eventAlreadyExists:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'Client was found, but the event has already been created.'
      }

    case createResponseTypes.noUpdateCode:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'All good but the update portion of the code has not been built!'
      }

    case createResponseTypes.handleFetchResponse:
      let content = message
      if (content && error) {
        const { message } = error
        if (message) { content = message }
      }
      return {
        title: 'Fetch Response',
        success: success,
        content: content
      }

    default:
      switch (type) {
        case frts.handleFetchResponse:
          const otherContent = success ? 'Successful' : 'Not Successful'
          let content = message ? message : otherContent
          if (urlData && urlData.url) (content += ' url:' + urlData.url)
          return {
            title: _.startCase('fetchResponse'),
            success: success,
            content: content
          }
        default:
          return {
            title: 'No rvType. Check settings',
            success: false,
            content: _.startCase(type)
          }

      }
  }
}