import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Menu, Segment, Sidebar } from 'semantic-ui-react'
import { SportsSeasonContext, _sportsFormProps } from '../../../../projectSpecific/sports/cnr/contexts/SportsSeasonContext'
import { getPks, seasonalUpdateTypes, updateSeasonalOrGlobal } from '../../../../projectSpecific/sports/cnr/reducers/reducerHelpers/seasonalSportsData'
import { ParentContext } from '../../../cnr/contexts/ParentContext'
import { uniqueKey } from '../../../common/keys'
import UiSaveButtons from '../../../components/buttons/UiSaveButtons'
import { appViewerColors } from '../../../components/viewers/AppDashboard'
import ListAdd from '../../../lists/ListAdd'
import FullPageWrapper from '../../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper'

export const UiDashboardForm = (props) => {

  const { handleCancel, forSportsManagement, sportsDataMode } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state } = states
  const { pathViews, viewKey } = paps_state ? paps_state : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { sportsKey, googleLinks_info, teams_info } = sportsSeason_state ? sportsSeason_state : {}
  const { teams } = teams_info ? teams_info : {}
  const { gls_scores, gls_teams } = googleLinks_info ? googleLinks_info : {}

  const _pks = getPks(pathViews, null, sportsKey, [viewKey])
  delete _pks.teams

  // local state
  const [formData, setFormData] = useState()
  const [allItems, setAllItems] = useState()
  const [showItemOptions, setItemOptions] = useState()
  const [currentItemKey, setCurrentItemKey] = useState('Select')
  const [currentItem, setCurrentItem] = useState()
  const [scoreLinks, setScoreLinks] = useState()
  const [teamLinks, setTeamLinks] = useState()
  const [updating, setUpdating] = useState()

  const { name: teamName } = currentItem ? currentItem : {}

  useEffect(() => {
    const items = { sport: { name: 'Sport', _itemKey: 'sport' } }
    const _allItems = { ...items, ...teams }
    setAllItems(_allItems)
    setScoreLinks(gls_scores)
    setTeamLinks(gls_teams)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (allItems && currentItemKey) {
      setCurrentItem(allItems[currentItemKey])
      const links = currentItemKey === 'sport' ? scoreLinks : teamLinks
      const form_props = _sportsFormProps[sportsDataMode]

      let _formData = {}
      switch (currentItemKey) {
        case 'sport':
          const gls = links ? links : {}
          ammendFormData(_formData, form_props, gls)
          break
        default:
          const glt = links && links[currentItemKey] ? links[currentItemKey] : {}
          ammendFormData(_formData, form_props, glt)
      }
      setFormData(_formData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsDataMode, allItems, currentItemKey]);

  const ammendFormData = (formData, form_props, link) => {
    Object.keys(form_props).forEach(fp => {
      switch (fp) {
        case 'teamAccess':
        case 'teamsAccess':
        case 'teamsAccess':
          formData[fp] = link && link[fp] ? link[fp] : []
          break;
        default:
          formData[fp] = link && link[fp] ? link[fp] : ''
      }
    })
  }

  const handleFormChange = (e, data) => {

    const _formData = { ...formData }
    _formData[data.propname] = data.value
    setFormData(_formData)

    const links = currentItemKey === 'sport' ? scoreLinks : teamLinks
    const _links = { ...links }
    const _link = _links[currentItemKey] ? _links[currentItemKey] : {}

    switch (currentItemKey) {
      case 'sport':
        setScoreLinks(_formData)
        break;

      default:
        _links[currentItemKey] = { ..._link, ..._formData }
        setTeamLinks(_links)
        break;
    }
  }

  const handleSave = () => {

    const cb = (r) => setUpdating()

    setUpdating(true)

    const datasToUpdate = {
      team: {
        collectionName: 'gls_teams',
        dataToUpdate: {
          ['gls_teams']: teamLinks
        }
      },
      sport: {
        collectionName: 'gls_scores',
        dataToUpdate: {
          ['gls_scores']: scoreLinks
        }
      }
    }

    updateSeasonalOrGlobal(seasonalUpdateTypes.googleLinks, pathViews, _pks, datasToUpdate.team.collectionName, null, datasToUpdate.team.dataToUpdate, cb, { ignoreSeason: true })
    updateSeasonalOrGlobal(seasonalUpdateTypes.googleLinks, pathViews, _pks, datasToUpdate.sport.collectionName, null, datasToUpdate.sport.dataToUpdate, cb, { ignoreSeason: true })


  }

  const handleShowItemOptions = () => setItemOptions(!showItemOptions)

  const handleItemSelect = (key) => {
    setItemOptions()
    setCurrentItemKey(key)
  }

  const itemOptionsButton = () => {
    const btns = [
      { caption: teamName ? teamName : _.startCase(currentItemKey), oc: handleShowItemOptions, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.dmv}
      rows={1}
    />
  }

  const header = () => <div className={'header-flex'}>
    {itemOptionsButton()}
  </div>

  const formElements = () => {
    const inputs = []
    if (formData) {
      Object.keys(formData).forEach(fdKey => {
        const value = formData[fdKey]
        switch (fdKey) {
          case 'teamAccess':
          case 'sportAccess':
          case 'teamsAccess':
          case 'scoresAccess':
            const fip = { label: _.startCase(fdKey), value, onChange: handleFormChange, propname: fdKey }
            inputs.push(<ListAdd fip={fip} />)
            break;
          default:
            inputs.push(<Form.Input
              key={uniqueKey('gsl', 'fi', fdKey)}
              value={value}
              label={_.startCase(fdKey)}
              placeholder={fdKey}
              propname={fdKey}
              onChange={handleFormChange}
            />)
        }

      })
    }
    return inputs
  }

  const form = () => <Form>
    {formElements()}
  </Form>

  const footer = () => <UiSaveButtons
    save={{ oc: handleSave }}
  />

  const wrapper = () => <Wrapper
    header={header()}
    content={currentItemKey !== 'Select' ? form() : <div></div>}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
  />

  const optionsMenu = () => {
    const mis = []
    const _items = _.sortBy(allItems, 'name')
    _items.forEach(team => {
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', team._itemKey)} onClick={() => { handleItemSelect(team._itemKey) }}>{_.startCase(team.name)}</Menu.Item>)
    })
    return <Segment basic><Menu vertical fluid inverted className={'av-menu'}>{mis}</Menu></Segment>
  }

  const OptionsSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv'
      onHide={() => handleShowItemOptions()}
    >
      {allItems && optionsMenu()}
    </Sidebar>
  )

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <OptionsSidebar visible={showItemOptions ? true : false} />
    <Sidebar.Pusher dimmed={showItemOptions ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    topperCaption={'Google Link'}
    topperCaption2={teamName}
    handleCancel={handleCancel}
  />

  return forSportsManagement ? sidebar() : fullPageWrapper()

}
