import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { Card, Icon, Label, List, Message, Segment } from 'semantic-ui-react'
import { CredentialingContext } from '../../../cnr/contexts/CredentialingContext'
import { PageDataContext } from '../../../cnr/contexts/PageDataContext'
import { ParentContext } from '../../../cnr/contexts/ParentContext'
import { getItemName } from '../../../common/convert'
import { uniqueKey } from '../../../common/keys'
import UiSaveButtons from '../../../components/buttons/UiSaveButtons'
import { appUserLevels } from '../../../enums/globalEnums'
import FullPageWrapper from '../../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper'
import { DataModificationsContext } from '../UiDataModifications'

const _messageCount = 1000
const _scanDelay = 2000

const UiCredentialing = (props) => {

  const parentContext = useContext(ParentContext);
  const { handlers, fns, states } = parentContext ? parentContext : {}
  const { page_state } = states ? states : {}
  const { appUser_fns } = fns
  const { navigate } = handlers

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}

  const { appCredentialing } = aps_global ? aps_global : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}
  const {
    allowDirectScanValidation,
    allowInOut,
    showEntered,
    showTotal,
    useSplitScreen,
  } = appCredentialing ? appCredentialing : {}

  const pageDataContext = useContext(PageDataContext);
  const { pageData_state } = pageDataContext ? pageDataContext : {}
  const { currentPageData } = pageData_state ? pageData_state : {}
  const _name = currentPageData ? getItemName(currentPageData) : _.startCase(appUserCollection)

  // credentialingContext
  const credentialingContext = useContext(CredentialingContext)
  const { credentialing_state, credentialing_handlers } = credentialingContext ? credentialingContext : {}
  const {
    appUser_scanned,
    appUser_selected,
    checkIns,
    showMessage,
    currentTotals,
    showScanner,
  } = credentialing_state ? credentialing_state : {}

  // dataModificationsContext
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}

  const topperCaption = 'Scan Credentials'
  const topperCaption2 = allowDirectScanValidation ? 'Direct' : 'Validate'

  const allowMods = appUser_fns ? appUser_fns.validateAccess_item(appUserLevels.admin) : false

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        credentialing_handlers.handleHideMessage()
      }, _messageCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [showMessage]);

  useEffect(() => {
    if (allowDirectScanValidation && appUser_scanned) {
      // const { _itemKey } = appUser_scanned
      // if (!checkIns || !checkIns[_itemKey]) {
      credentialing_handlers.handleCheckInAppUser(appUser_scanned)
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUser_scanned]);

  const handleCancel = () => {
    credentialing_handlers.handleCloseScanner()
    if (dataModifications_handlers) {
      dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
    } else {
      props.handleCancel ? props.handleCancel() : navigate(-1)
    }
  }

  const handleError = err => console.error(err)
  const handleShowScanner = () => credentialing_handlers.handleShowScanner()

  const handleScan = (data) => {
    if (data) {
      const _scannedData = JSON.parse(data)
      if (_scannedData[appUserCollection]) {
        const _appUserKey = _scannedData[appUserCollection]
        credentialing_handlers.handleGetAppUser(_appUserKey)
      }
    }
  }

  const handleShowSelectedAppUser = (listItem) => credentialing_handlers.handleShowSelectedAppUser(listItem)

  const handleCheckIn = (e, listItem) => {
    e && e.stopPropagation()
    credentialing_handlers.handleCheckInAppUser(listItem)
  }

  const handleCheckIn_direct = () => credentialing_handlers.handleCheckInAppUser(appUser_selected)

  const message = () => <Message
    style={{ height: '100%' }}
    icon='check circle'
    success
    header='Checked-In'
  />

  /** The reader */
  const qrReader = () => <QrReader
    scanDelay={_scanDelay}
    onError={handleError}
    onResult={handleScan}
    className="qr-reader"
  />

  const credentialTotals = () => <div className={'cred-lbls'}>
    {showEntered && <Label color={'green'}>
      <Icon name='check' />
      {currentTotals ? currentTotals.checkedIn : 0}
    </Label>}
    {showTotal && <Label color={'blue'} >
      <Icon name='hashtag' />
      {currentTotals ? currentTotals.total : 0}
    </Label>}
  </div>

  const credentialList = () => {
    let list = _.orderBy(checkIns, ['name', 'lastName'])
    const listItems = []
    if (list) {
      list.forEach((listItem, index) => {
        const { checkedIn, _itemKey } = listItem
        const name = getItemName(listItem)
        const lblColor = checkedIn ? 'green' : 'red'
        const icon = {
          name: checkedIn ? 'arrow left' : 'arrow right',
          color: checkedIn ? 'red' : 'green',
        }
        listItems.push(<List.Item key={uniqueKey('aul', _itemKey)} onClick={(e) => { handleShowSelectedAppUser(listItem) }}>
          <List.Content floated='right'>
            <Icon name={icon.name} color={icon.color} bordered onClick={(e) => { handleCheckIn(e, listItem) }} />
          </List.Content>
          <List.Content floated='left'>
            <Label color={lblColor} size='tiny'>{index + 1}</Label>
          </List.Content>
          <List.Content>
            <List.Header>{name} </List.Header>
          </List.Content>
        </List.Item>)
      })
    }
    return <Segment basic>
      <List divided relaxed>
        {listItems}
      </List>
    </Segment>
  }

  const credCard = () => {
    const { checkedIn } = appUser_selected
    const _name = getItemName(appUser_selected)
    return <Card fluid style={{ height: '100%' }}>
      <Card.Content>
        <Card.Header>{_name}</Card.Header>
        <Card.Description>
          <Icon name={'check circle'} color={checkedIn ? 'green' : 'grey'} />
          <span>Checked In</span>
        </Card.Description>
      </Card.Content>
    </Card >
  }

  const content = () => {
    if (showScanner) {
      if (useSplitScreen) {
        return <div className={'scan-split'}>
          <div>{qrReader()}</div>
          {credentialList()}
        </div>
      } else {
        return qrReader()
      }

    } else {
      return credentialList()
    }
  }

  const readerContent = () => <div className={'cred-scanner'}>
    {!showMessage && (showEntered || showTotal) && credentialTotals()}
    {showMessage ? message() : content()}
  </div>

  const footer_appUser = () => {
    if (appUser_selected && allowInOut) {
      const { checkedIn } = appUser_selected
      return <UiSaveButtons
        save={{ caption: 'In', oc: handleCheckIn_direct, icon: 'arrow circle up', disabled: checkedIn }}
        preview={{ caption: 'Out', icon: allowInOut ? 'arrow circle down' : 'ban', oc: handleCheckIn_direct, disabled: !checkedIn }}
      />
    }
  }

  /** wrapper for the reader */
  const wrapper_appUser = () => <Wrapper
    content={credCard()}
    footer={footer_appUser()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  /** Wizard for the reader */
  const fpw_appUser = () => <FullPageWrapper
    content={wrapper_appUser()}
    handleCancel={handleShowSelectedAppUser}
    topperCaption={'Selected'}
    isBack={true}
  />

  const footer_reader = () => <UiSaveButtons
    save={{ caption: showScanner ? 'Turn Off' : 'Turn On', oc: handleShowScanner, icon: showScanner ? 'delete' : 'check', color: showScanner ? 'green' : 'grey', disabled: !allowMods }}
  />

  const wrapper_reader = () => <Wrapper
    content={readerContent()}
    footer={footer_reader()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const fpw_normal = () => <FullPageWrapper
    content={wrapper_reader()}
    handleCancel={handleCancel}
    topperCaption={topperCaption}
    topperCaption2={topperCaption2}
  />

  return appUser_selected ? fpw_appUser() : fpw_normal()

}

export default UiCredentialing