import React, { createContext, useContext, useState } from 'react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { startTypes } from '../cnr/reducers/StartReducer';
import { gEnums } from '../enums/globalEnums';
import AnimateComponent, { _animateTypes } from '../motions/AnimateComponent';
import CircleLoader from '../motions/CircleLoader';

export const PageItemDimmerContext = createContext();

const WAIT_INTERVAL = 200

const PageItemDimmer = (props) => {

  const { content } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_styles } = pageSettings ? pageSettings : {}

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.pageItemDimmer] : {}

  const [showPageDimmer, setShowPageDimmer] = useState(true)

  const killPageDimmer = () => {
    const timer = setTimeout(() => setShowPageDimmer(false), WAIT_INTERVAL);
    return () => clearTimeout(timer);
  }

  const pageItemWrapper_handlers = { killPageDimmer }

  return <PageItemDimmerContext.Provider value={{ pageItemWrapper_handlers }}>
    {showPageDimmer && <AnimateComponent animateType={_animateTypes.pageItem} styleAndClass={styleAndClass} showAnimate={showPageDimmer}>
      <CircleLoader startType={startTypes.pageItem} />
    </AnimateComponent>}
    {content}
  </PageItemDimmerContext.Provider>

}

export default PageItemDimmer
