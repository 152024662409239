import { _profileCollectionName } from "../../../firestoreData/profiles/getProfile"
import { ddDivider } from "../../helpers/hddItems"

export const getDataModifyItems = (props) => {

  const {
    appUserAccess,
    gvisKeys,
    hddButtons,
    isList,
    showAccessPermissions,
    showAddAuth,
    vit,
  } = props

  const ddis = []

  if (appUserAccess && appUserAccess.isSuperAdmin) {

    showAddAuth && ddis.push(hddButtons.alarm())

    ddis.push(ddDivider(4))

    if ((gvisKeys.includes(vit) || vit === _profileCollectionName)) {
      ddis.push(hddButtons.imageModify())
    }

    if (showAccessPermissions) {
      ddis.push(hddButtons.modifyAccess())
    }

    if (!isList) {
      ddis.push(ddDivider(5))
      ddis.push(hddButtons.updateProps())
      ddis.push(ddDivider(6))
      ddis.push(hddButtons.delete())
    } else {
      ddis.push(hddButtons.delete())
    }
  }

  return ddis
} 