
export const getFormOptions = (optionProps) => {

  const { itemProp, optionsList } = optionProps
  const { data: data_prop, prop } = itemProp ? itemProp : {}
  const { listType, staticList } = data_prop ? data_prop : {}

  if (listType && optionsList && optionsList[listType]) {
    return optionsList[listType]
  }

  if (staticList && optionsList && optionsList[staticList]) {
    return optionsList[staticList]
  }

  if (prop && optionsList && optionsList[prop]) {
    return optionsList[prop]
  }

  return false

}

