import { responseReducers } from "./reducerHelpers/dispatchProps"

export const _secretDataName = 'secret'

const rts = {
  handleInit: 'handleInit',
  handleLoadData: 'handleLoadData',
  handleLoadPageData: 'handleLoadPageData',
  handleSecretData: 'handleSecretData',
  handleSetFetchData: 'handleSetFetchData',
  handleSetAlphaValue: 'handleSetAlphaValue',
  handleSetAlphabetValue: 'handleSetAlphabetValue',
  handleUpdateData: 'handleUpdateData',
}

const getSecretData = async (dr, propSectionKey) => {
  const ref = dr.collection(propSectionKey).doc(_secretDataName)
  const res = await ref.get()
  return res
}

export const dataReducer = (state, action) => {

  const { combinedListData } = state

  const { dispatch, dataValue, isCombinedData, type, dataResultProps } = action
  const { name, singleDataItem, dataRef, dataId } = dataResultProps ? dataResultProps : {}

  const { handleUpdateData } = dataHandlers(dispatch)

  switch (type) {

    case rts.handleInit:

      return {
        ...state,
        isLoading: true,
        fetched: false,
      }

    case rts.handleLoadPageData:
      return {
        ...state,
        dataRef,
        isLoading: false,
        fetched: true,
        viewListData: dataValue,
        pageData: dataValue
      }

    case rts.handleLoadData:

      const sing = { ...state.singles }
      let cd = { ...combinedListData }
      if (isCombinedData) { cd = { ...cd, ...dataValue } }

      if (singleDataItem && dataValue && Object.keys(dataValue).length === 1) { sing[name] = dataValue[Object.keys(dataValue)[0]] }

      return {
        ...state,
        [name]: dataValue,
        combinedListData: cd,
        dataId: dataId,
        dataName: name,
        dataRef,
        excludedData: action.excludedData,
        fetched: true,
        fsr: action.fsr,
        isLoading: false,
        singleDataItem,
        singles: sing,
        time_stamp: Date.now(),
        viewListData: dataValue,
      }

    case rts.handleSecretData:

      const { dataRef: dr, dataName } = state
      const { secretProps } = action
      const { propSectionKey, itemData } = secretProps ? secretProps : {}
      const { key: itemKey } = itemData ? itemData : {}

      getSecretData(dr, propSectionKey).then(res => {
        const secretItemData = res.data()
        const existingItemData = state[dataName][itemKey]
        const combinedItemData = { ...existingItemData, ...secretItemData }
        handleUpdateData(combinedItemData)
      })

      return { ...state }

    case rts.handleUpdateData:
      const { updateData } = action
      const vld = { ...state.viewListData }
      vld[updateData.key] = updateData
      return {
        ...state,
        [state.dataName]: action.updateData,
        viewListData: vld,
        time_stamp: Date.now()
      }

    case rts.handleSetAlphaValue:
      return {
        ...state,
        alphaValue: action.alphaValue
      }

    case rts.handleSetAlphabetValue:
      return {
        ...state,
        alphabetValue: action.alphabetValue
      }

    case rts.handleSetFetchData:
      return {
        ...state,
        fetchData: action.fetchData
      }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { [name]: dataValue, fetched: true })

    default:
      return { ...state }
  }
}

export const dataInitialState = (initState) => {
  const { diKey, isCombinedData, isSettingData, itemListData, uivi, updateProps, viewItem, pageImages } = initState ? initState : {}
  return {
    // alphabetValue: 65,
    combinedListData: {},
    confirmation: null,
    diKey,
    isCombinedData,
    isSettingData,
    itemListData,
    pageImages,
    singleDataItem: false,
    singles: {},
    uivi,
    updateProps,
    viewItem,
  }
};

export const dataHandlers = (dispatch) => {
  return {
    handleInit: () => { dispatch({ type: rts.handleInit }) },
    handleLoadPageData: (dataValue, name, singleDataItem, dataRef) => { dispatch({ type: rts.handleLoadPageData, dataValue, name, singleDataItem, dataRef }) },
    handleLoadData: (dataValue, dataResultProps, fsr, excludedData) => { dispatch({ type: rts.handleLoadData, dataValue, dataResultProps, fsr, excludedData }) },
    handleSecretData: (secretProps) => { dispatch({ type: rts.handleSecretData, dispatch, secretProps }) },
    handleSetAlphaValue: (alphaValue) => { dispatch({ type: rts.handleSetAlphaValue, alphaValue }) },
    handleSetAlphabetValue: (alphabetValue) => { dispatch({ type: rts.handleSetAlphabetValue, alphabetValue }) },
    handleSetFetchData: (fetchData) => { dispatch({ type: rts.handleSetFetchData, fetchData }) },
    handleUpdateData: (updateData) => { dispatch({ type: rts.handleUpdateData, updateData }) },
  }
}
