import { addDefaultProps, convertDates, convertToLowerCase } from "../../common/dataAdjust";
import { formatTypes } from "../../common/dateFormatting";
import { fsfn_fetchData } from "../../functions/fbFetchFunctions";
import { fsfn_createProject } from "../../functions/fbCreate";
import { createResponseTypes } from "./CreateReducer";
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

const rts = {
  handleGetRegData: 'handleGetRegData',
  handleFetchResult: 'handleFetchResult',
  handleProjectResult: 'handleProjectResult',
  handleUpdateCollectionWithClear: 'handleUpdateCollectionWithClear',
  handleUpdateSettings: 'handleUpdateSettings',
  handleShowCommitRegData: 'handleShowCommitRegData',
  ...grts,
  ...createResponseTypes
}

export const regDataInitialState = (initState) => {
  return { ...initState }
};

export const regDataReducer = (state, action) => {

  const { pathViews, regUrl } = state
  const { type, dispatch } = action

  const { handleFetchResult, handleProjectResult } = regDataHandlers(dispatch)

  switch (type) {

    case rts.handleShowCommitRegData:
      return { ...state, showCommitRegData: !state.showCommitRegData }

    case rts.handleGetRegData:
      if (pathViews.events && regUrl) {
        const urlDataF = { url: regUrl + pathViews.events }
        fsfn_fetchData(urlDataF).then(res => {
          const { result } = res ? res : {}
          handleFetchResult(result)
        })
      } else {
        return { ...state, noRegUrl: true }
      }
      return { ...state }

    case rts.handleFetchResult:
      const createEventProps = {
        initialData: action.fetchData,
        fromRegUrl: true,
        settingsDocName: pathViews.clients
      }

      const xp = fsfn_createProject(createEventProps)

      xp.then(response_data_project => {

        const { result } = response_data_project
        const { projectData, projectSettings } = result ? result : {}
        const { dataCollections } = projectData ? projectData : {}

        if (dataCollections) {
          Object.keys(dataCollections).forEach(dcKey => {
            const appData = dataCollections[dcKey]
            convertDates(appData, formatTypes.shortestDate)
            convertToLowerCase(appData, 'email')
            switch (dcKey) {
              case 'sessions':
                addDefaultProps(appData, 'sessionTypes', ['2'])
                break;
              default:
              // nothing
            }
          })
        }
        handleProjectResult(dataCollections, projectSettings)
      })
      return { ...state, fetchData: action.fetchData }

    case rts.handleProjectResult:
      return { ...state, projectData: action.projectData, projectSettings: action.projectSettings }

    // replace and update thte collections
    case rts.handleUpdateCollectionWithClear:
      const ueuProps = { pathViews, collectionName: action.collectionName, dispatch, updateOnly: true, restrict: false }
      ueuProps.dataList = state.dataCollections(action.collectionName)
      ueuProps.clearCollection = true
      // updateExistingCollection(ueuProps)
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmationClick, questionProps: null, updatingCaption: 'Creating' })

    default:
      return state;
  }
}

export const regDataHandlers = (dispatch) => {
  return {
    handleShowCommitRegData: () => { dispatch({ type: rts.handleShowCommitRegData, dispatch }) },
    handleGetRegData: () => { dispatch({ type: rts.handleGetRegData, dispatch }) },
    handleFetchResult: (fetchData) => { dispatch({ type: rts.handleFetchResult, dispatch, fetchData }) },
    handleProjectResult: (projectData, projectSettings) => { dispatch({ type: rts.handleProjectResult, dispatch, projectData, projectSettings }) },
    handleUpdateCollectionWithClear: (collectionName) => { dispatch({ type: rts.handleUpdateCollectionWithClear, dispatch, collectionName }) },
    handleUpdateSettings: (results) => { dispatch({ type: rts.handleUpdateSettings, dispatch, results }) },
    ...responseHandlers(dispatch)
  }
}
