import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Dropdown, Form, Segment } from 'semantic-ui-react';
import { AppComponentContext } from '../../../cnr/contexts/AppComponentContext';
import { DataContext } from '../../../cnr/contexts/DataContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { dataLinkingHandlers, dataLinkingInitialState, dataLinkingReducer } from '../../../cnr/reducers/DataLinkingReducer';
import { uniqueKey } from '../../../common/keys';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import Checker from '../../../components/forms/elements/Checker';
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer';
import JsonViewer from '../../../components/viewers/JsonViewer';
import { gEnums } from '../../../enums/globalEnums';
import { listListO } from '../../../lists/lists';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { DataModificationsContext } from '../UiDataModifications';
import UIDictaphone from './UIDictaphone';
import UiDataListSelect from './UiDataListSelect';

const UiDataLinking = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { page_fns } = fns
  const { eventInfo_state, paps_state, page_state } = states
  const { staticViews, globals } = eventInfo_state ? eventInfo_state : {}

  // appContext
  const appContext = useContext(AppComponentContext)
  const { appStaticViews } = appContext ? appContext : {}

  // papsContext  
  const { view, viewKey, pathViews } = paps_state ? paps_state : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_page, aps_viewItems } = pageSettings ? pageSettings : {}
  const { viewItems: viewItems_page } = aps_page ? aps_page : {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ? dataContext : {}
  const { viewListData: _uiData } = data_state ? data_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { itemAction_handlers, item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem, linkIndex } = item_state ? item_state : {}
  const { dataModifications } = viewItem ? viewItem : {}
  const { key: viKey } = viewItem ? viewItem : {}

  // dataModificationsContext
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_state, dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}
  const { dataModificationType } = dataModifications_state ? dataModifications_state : {}

  // dataModificationsContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ? dataManagementContext : {}
  const { viewItem: viewItem_dms } = dataManagement_state ? dataManagement_state : {}
  const { key: key_viewItem } = viewItem_dms ? viewItem_dms : {}

  const _linkItems = { left: null, right: null }

  if (dataModifications_state) {
    _linkItems.left = view
    _linkItems.right = viKey
  } else if (dataManagement_state) {
    if (key_viewItem) {
      _linkItems.left = key_viewItem
    }
  }

  const fullMode = dataManagement_state ? true : false

  // linking
  const { connections, dataLinking } = dataModifications ? dataModifications : {}
  const { connectWith } = connections ? connections : {}
  const { pageLinkProps, useGlobalData, globalCollection, forcePreview } = dataLinking ? dataLinking : {}

  // this data 
  const pageListProp = pageLinkProps && linkIndex ? pageLinkProps[linkIndex] : null

  const [selectionOptions, setSelectionOptions] = useState()

  const pageKeys = !dataManagement_state ? {
    left: viKey,
    right: view
  } : null

  // dataLinkingReducer
  const dataLinking_initState = {
    _uiData,
    appStaticViews,
    collectionItems: _linkItems,
    connectWith,
    dataManagementContext,
    dataModifications_handlers,
    dataModifications,
    dataModificationsContext,
    dataModificationType,
    globalCollection,
    globals,
    item_state,
    itemAction_handlers,
    page_fns,
    pageKeys,
    pageListProp,
    paps_state,
    pathViews,
    rightCount: 0,
    staticViews,
    useGlobalData,
    view,
    viewItems_page,
    viewKey,
    viKey,
  }

  const [dataLinking_state, dataLinkingDispatch] = useReducer(dataLinkingReducer, dataLinkingInitialState(dataLinking_initState));
  const dataLinking_handlers = dataLinkingHandlers(dataLinkingDispatch, dataLinking_state)

  const { showPreview, showPaste, pasteValue, collectionItems, linkedItems, linkProps, dataLinkingProps, rightCount, preview, selectedKeyLeft, dataLists, actionItem, updating, confirmation, linkItem } = dataLinking_state ? dataLinking_state : {}

  const rights = selectedKeyLeft && linkedItems.right && linkedItems.right[selectedKeyLeft] ? linkedItems.right[selectedKeyLeft] : []

  const {
    fromPage,
    toPage,
    toKeyCaption,
  } = linkProps ? linkProps : {}

  const {
    dataLinkType,
    pageConstraintType,
  } = linkProps ? dataLinkingProps : {}

  switch (pageConstraintType) {
    case gEnums.pageConstraintTypes.arrayContains:
      linkProps.allowMultiSelect = true
      break;
    default:
    // nothing
  }

  useEffect(() => {
    setSelectionOptions(listListO(aps_viewItems))
    dataLinking_handlers.initializeDataLinking()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set the list of selectable items
  useEffect(() => {
    if (linkItem) {
      dataLinking_handlers.handleLinkTableLinks(linkItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [linkItem]);

  const handleUpdateLinkItems = () => {
    dataLinking_handlers.handleStartUpdate()
    dataLinking_handlers.handleUpdateLinkItems()
  }

  const handleUpdateLinkItem = () => {
    dataLinking_handlers.handleStartUpdate()
    dataLinking_handlers.handleUpdateLinkItems(true)
  }

  const handleUpdate_links = () => {
    // dataLinking_handlers.handleStartUpdate()
    dataLinking_handlers.handleUpdateLinksOnly(true)
  }

  const handleCancel = () => {
    dataManagement_handlers && dataManagement_handlers.handleSelectDataActionOption('options')
    dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
  }

  // handlers for dataLinking_handlers   
  const handleSelectLeft = (e, item) => dataLinking_handlers.handleSelectLeft(item)
  const handleSelectRight = (e, item) => dataLinking_handlers.handleSelectRight(item)
  const handleSelectRights = (e, item, itemChecked) => dataLinking_handlers.handleSelectRight(null, true, itemChecked)
  const handlePaste = (e, { value }) => { dataLinking_handlers.handlePaste(value) }

  const handleQuantity = (e, data) => {
    const { menuListItem, value } = data
    dataLinking_handlers.handleQuantity(menuListItem, value)
  }

  const handleLeftChange = (e, data) => dataLinking_handlers.handleLeftItemChange(data.value)
  const handleRightChange = (e, data) => dataLinking_handlers.handleRightItemChange(data.value)
  const handleLinkChange = (e, data) => dataLinking_handlers.handleLinkItemChange(data.value)

  const listItemWrapper = (list, handleSelect, selectedItems, linkedItems, selectedOnly, checkAllow, allowed, caption, allowCheckAll, count) => {
    const prps = { list, handleSelect, selectedItems, linkedItems, selectedOnly, checkAllow, allowed, caption, allowCheckAll, count }
    const prpsg = { fullMode, selectedKeyLeft, handleSelectRights }
    const _prps = { ...prps, ...prpsg }
    return <UiDataListSelect {..._prps}></UiDataListSelect>
  }

  const headerDd = (key, oc, value, disabled, cn) => <Dropdown
    className={cn}
    placeholder={_.startCase(key)}
    fluid
    selection
    options={selectionOptions ? selectionOptions : []}
    value={value}
    disabled={disabled}
    onChange={oc} />

  const headerSeg = () => !showPreview ? <Segment basic style={{ padding: 0 }}><Checker
    label={'Select All'}
    handle_click={dataLinking_handlers.handleSelectAll}
    item={{ key: 'selectAll' }}
  /></Segment> : null

  const header = (key, oc, value, disabled, cn) => fullMode ? headerDd(key, oc, value, disabled, cn) : headerSeg()

  const footer = () => {
    let saveDisabled = !preview
    if (!forcePreview && actionItem) { saveDisabled = false }
    const btns = []
    btns.push({ oc: dataLinking_handlers.handleShowPreview, disabled: !preview, caption: 'Preview', icon: 'eye', fl: true })
    fullMode && btns.push({ oc: dataLinking_handlers.handleShowPaste, disabled: !preview, icon: 'paste' })
    fullMode && btns.push({ oc: dataLinking_handlers.handleShowSelected, disabled: !preview, icon: 'filter' })
    btns.push({ disabled: saveDisabled, oc: handleUpdateLinkItems, caption: 'Save All', icon: 'save' })
    fullMode && btns.push({ disabled: !selectedKeyLeft, oc: handleUpdateLinkItem, color: 'green', caption: 'Save Selected', icon: 'save' })
    btns.push({ disabled: saveDisabled, oc: handleUpdate_links, caption: 'Update Links', icon: 'save' })

    return <UiSaveButtons others={btns} />
  }

  /**
   * 
   * @param {string} key 
   * @param {object} list 
   * @param {function} onClick 
   * @param {function} hs - handleSelect 
   * @param {object} selectedItems 
   * @param {object} lis 
   * @param {object} value 
   * @param {boolean} disabled 
   * @param {boolean} selectedOnly 
   * @returns 
   */
  const wrapper_single = (key, list, onClick, hs, selectedItems, lis, value, disabled, selectedOnly) => <Wrapper
    header={header(key, onClick, value, disabled)}
    content={listItemWrapper(list, hs, selectedItems, lis, selectedOnly)}
    wrapperType={wrapperTypes.padded}
    confirmation={confirmation}
  />

  const splitRight = (list, hs, selectedItems, lis) => <div className={'split-list'}>
    {listItemWrapper(list, hs, selectedItems, lis, false, true, selectedKeyLeft, 'All', true, list ? list.length : 0)}
    {listItemWrapper(list, hs, selectedItems, lis, true, null, null, 'Selected', false, rightCount)}
  </div>

  const footer_right = (list) => <div>
    <div>{header('link', handleLinkChange, null, null, 'dd-linking')}</div>
    <div><UIDictaphone list={list} handleSelect={handleSelectRight} listenProp={'lastName'} opts={{ dataLinking: true }} /></div>
  </div>

  const wrapper_split = (key, list, onClick, hs, selectedItems, lis, value, disabled, selectedOnly) => <Wrapper
    header={header(key, onClick, value, disabled)}
    content={splitRight(list, hs, selectedItems, lis, selectedOnly)}
    footer={footer_right(list)}
    wrapperType={wrapperTypes.padded}
    confirmation={confirmation}
    css={{ footer: 'footer-right' }}
  />

  const leftRightLists = () => <div className={'split-list three'}>
    {wrapper_single('left', dataLists.left, handleLeftChange, handleSelectLeft, linkedItems.left, linkedItems.right, collectionItems.left, viKey ? true : false)}
    {wrapper_split('right', dataLists.right, handleRightChange, handleSelectRight, rights, null, collectionItems.right)}
  </div>

  const pasteForm = () => <Form>
    <Form.TextArea value={pasteValue} className={'text-area-paste'} key={uniqueKey('dp', 'ta')} onChange={handlePaste} rows={20} placeholder='paste here...' />
  </Form>

  const content = () => {
    if (showPreview) {
      return <JsonViewer vit={'preview'} itemData={preview} fullView={true} />
    } else if (showPaste) {
      return pasteForm()
    } else {
      return fullMode ? leftRightLists() : wrapper_single('list', dataLists.right, null, handleSelectRight, rights)
    }
  }

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={showPreview ? wrapperTypes.paddedHeader : wrapperTypes.paddedFooter}
    updating={updating}
    confirmation={confirmation}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={fullMode ? 'Link Data' : 'Link ' + _.startCase(fromPage)}
    topperCaption2={!fullMode ? 'to ' + _.startCase(toPage) + ' (' + toKeyCaption + ')' : null}
    handleCancel={handleCancel}
    isBack={false}
    fullMode={true}
  />

  return collectionItems && collectionItems.left ? fullPageWrapper() : <div></div>

}

export default UiDataLinking