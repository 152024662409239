import React, { createContext, useContext, useEffect, useState } from 'react';
import { _animateTypes } from '../../motions/AnimateComponent';
import { animationVariants } from '../../motions/animationVariants';
import { HomeSettingsContext } from './SettingsDataContext';

export const TransitionContext = createContext();

const TransitionProvider = (props) => {

  const homeSettingsContext = useContext(HomeSettingsContext)

  const { homeSettings_state } = homeSettingsContext ? homeSettingsContext : {}
  const { homeSettings } = homeSettings_state ? homeSettings_state : {}
  const { global: global_h } = homeSettings ? homeSettings : {}
  const { transitionItems } = global_h ? global_h : {}

  const [transitions, setTransitions] = useState()

  useEffect(() => {
    const _transitions = {}
    if (transitionItems) {
      Object.keys(_animateTypes).forEach(at => {
        const { variants, showPendingDimmer, useTransitions } = animationVariants(at, transitionItems)
        _transitions[at] = { animateType: at, variants, showPendingDimmer, showTransition: useTransitions }
      })
    }
    setTransitions(_transitions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionItems]);

  return transitions ? <TransitionContext.Provider value={{ transition_state: { transitions } }} >
    {props.children}
  </TransitionContext.Provider> : <div></div>

};

export default TransitionProvider 