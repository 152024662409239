import { itemActionTypes } from '../../../viewSettings/enums/itemActionTypes';

/** Generates a preview of the arrays that will be updated */
export const arrayUpdatePreview = (state, aod) => {

  const { actionItem, modifyActionType, linkProps } = state
  const { allowMultiSelect, fromPage, toPage } = linkProps

  const { actionProps } = actionItem
  const { linkData, viewItemKey } = actionProps

  const { _view, _viewItem } = linkData ? linkData : {}
  const { key: viewKey } = _view ? _view : {}
  const { keys: viewItemKeys } = _viewItem ? _viewItem : {}

  let preview;

  if (allowMultiSelect) {
    const viewItems = {}
    viewItemKeys.forEach(vik => {
      viewItems[vik] = { [fromPage]: [viewKey] }
    })

    switch (modifyActionType) {
      case itemActionTypes.connections:
        preview = {
          connections: {
            [fromPage]: {
              [viewKey]: {
                [toPage]: {
                  ...viewItemKeys
                }
              }
            }
          }
        }
        break;

      default:
        preview = {
          [toPage]: viewItems,
          [fromPage]: { [viewKey]: { [toPage]: viewItemKeys } }
        }
    }

  } else {
    preview = {
      [toPage]: { [viewItemKey]: { [fromPage]: [viewKey] } },
      [fromPage]: { [viewKey]: { [toPage]: [viewItemKey] } }
    }
  }

  return preview

}


/** Generates a preview of the arrays that will be updated */
export const arrayLinkingPreview = (state) => {

  const { collectionItems, linkedItems, selectedKeyLeft } = state
  const { right: linkedItems_right } = linkedItems
  const { left: item_left, right: item_right } = collectionItems

  const preview = {
    [item_left]: {},
    [item_right]: {},
  }

  const preview_left = {
    [item_left]: {},
    [item_right]: {},
  }

  Object.keys(linkedItems_right).forEach(leftKey => {

    const items = linkedItems_right[leftKey]

    Object.keys(items).forEach(itemKey => {
      const item = items[itemKey]
      const { selected } = item
      if (!preview[item_left][leftKey]) { preview[item_left][leftKey] = {} }
      if (!preview[item_left][leftKey][item_right]) { preview[item_left][leftKey][item_right] = {} }
      preview[item_left][leftKey][item_right][itemKey] = { selected }

      if (leftKey === selectedKeyLeft) {
        if (!preview_left[item_left][leftKey]) { preview_left[item_left][leftKey] = {} }
        if (!preview_left[item_left][leftKey][item_right]) { preview_left[item_left][leftKey][item_right] = {} }
        preview_left[item_left][leftKey][item_right][itemKey] = { selected }
      }

      if (!preview[item_right][itemKey]) { preview[item_right][itemKey] = {} }
      if (!preview[item_right][itemKey][item_left]) { preview[item_right][itemKey][item_left] = {} }
      preview[item_right][itemKey][item_left][leftKey] = { selected }

    })
  })

  if (preview[item_right]) {
    Object.keys(preview[item_right]).forEach(rightKey => {
      if (preview[item_right][rightKey][item_left] && preview[item_right][rightKey][item_left][selectedKeyLeft]) {
        if (!preview_left[item_right][rightKey]) { preview_left[item_right][rightKey] = {} }
        if (!preview_left[item_right][rightKey][item_left]) { preview_left[item_right][rightKey][item_left] = {} }
        preview_left[item_right][rightKey][item_left] = preview[item_right][rightKey][item_left]
      }
    })
  }

  return { preview, previewLeft: preview_left }

}