import { getAuth, isSignInWithEmailLink } from "firebase/auth";
import React, { useContext, useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';
import { subAppTypes } from '../../AppSub';
import { _emailSignInStorageItem } from '../auth/signInActions/sendSignInLink';
import { AuthContext } from "../cnr/contexts/AuthContext";
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import AppForm from "../components/forms/appForms/AppForm";
import { appFormTypes } from '../enums/appFormTypes';
import { gEnums } from "../enums/globalEnums";
import { getHashPath } from '../redirection/current';
import FullPageWrapper from "../wrappers/FullPageWrapper";
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { validateEmail } from './validation';

/**
 * 
 * @returns a FullPageWrapper with controls to validate an email sign in.
 */
const EmailSignInConfirmation = () => {

  let { _hash } = getHashPath()
  const path = '/' + _hash.replace('/' + subAppTypes.emailSignInConfirmation, '')

  const authContext = useContext(AuthContext)
  const { auth_state, auth_handlers } = authContext ? authContext : {}
  const { emailConfirmationResult } = auth_state ? auth_state : {};
  const { status: emailValidationStatus, redirectTo } = emailConfirmationResult ? emailConfirmationResult : {};

  // retrieved, lists  
  const [isEmailLink, setIsEmailLink] = useState()
  const [formData, setFormData] = useState()
  const [storageConfirmationEmail] = useState(window.localStorage.getItem(_emailSignInStorageItem))
  const [validatedEmail, setValidatedEmail] = useState()
  const [signInResponse, setSignInResponse] = useState()

  const { success, error, data: clientAppUser } = signInResponse ? signInResponse : {}

  const { email: userEmail } = formData ? formData : {}
  const _confirmationEmail = storageConfirmationEmail ? storageConfirmationEmail : userEmail

  // runs when confirmationEmail exists
  useEffect(() => {
    const auth = getAuth()
    // the firebase method to validate if the URL is an email link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      setIsEmailLink(true)
      if (_confirmationEmail) {
        auth_handlers.handleSignInWithValidatedEmail(_confirmationEmail, setSignInResponse)
        // validateAppUserEmail(_confirmationEmail)
      } else {
        // setIsValidSignIn(true)
      }
    } else {
      setIsEmailLink(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (isEmailLink) {
      // removes the _emailSignInStorageItem from storage if it exists
      window.localStorage.removeItem(_emailSignInStorageItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isEmailLink]);

  const handleUpdateParentData = (d) => setFormData(d)
  const handleConfirmEmail = () => auth_handlers.handleSignInWithValidatedEmail(_confirmationEmail, setSignInResponse)
  const handleOpenApp = () => window.location.href = path

  const handleCloseApp = () => {
    // window.location.href = redirectTo;
    // window.open("about:blank", "_self");
    window.close();
  }

  const validConfirmationEmail = validateEmail(userEmail)

  const message = () => {
    let m;
    let negative;
    let info;
    console.log('emailValidationStatus', emailValidationStatus)
    switch (emailValidationStatus) {
      case gEnums.emailValidationTypes.accepted:
        m = 'Your email (' + validatedEmail + ') has been validated.'
        info = true
        break;
      case gEnums.emailValidationTypes.denied:
        m = 'Your email (' + _confirmationEmail + ') has NOT been validated.'
        negative = true
        break;
      default:
        m = 'Your email (' + validatedEmail + ') has been validated.'
        break;
    }
    m += ' You can safely close this window.'
    return <Message negative={negative} info={info} textAlign={'center'}>
      {m}
    </Message>
  }

  const form_email = () => <React.Fragment>
    {message()}
    <AppForm
      appFormType={appFormTypes.signInEmail}
      parentData={formData}
      handleUpdateParentData={handleUpdateParentData}
    />
  </React.Fragment>


  const wrapper_validated = () => <Wrapper
    content={message()}
    footer={storageConfirmationEmail ? <UiSaveButtons save={{ oc: storageConfirmationEmail ? handleCloseApp : handleOpenApp, caption: storageConfirmationEmail ? 'Close' : 'Open App', disabled: !validatedEmail }} /> : null}
    wrapperType={wrapperTypes.padded}
  />

  const wrapper_validation = () => <Wrapper
    content={form_email()}
    footer={<UiSaveButtons save={{ oc: handleConfirmEmail, caption: 'Validate', disabled: !validConfirmationEmail }} />}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={validatedEmail ? wrapper_validated() : wrapper_validated()}
    topperCaption={'Email Validation'}
    fullWebPage={true}
  />

  return fullPageWrapper()
}

export default EmailSignInConfirmation