import React from 'react'
import { Menu } from 'semantic-ui-react'
import { uniqueKey } from '../common/keys'

/** Wraps vert-container or dt-container */

export const H3Wrapper = (props) => {
  const { leftContent, middleContent, rightContent, widths } = props
  return (
    <div className={'hdr-container'}>
      <div key={uniqueKey('wr3', 1)} width={widths ? widths[0] : 2}>{leftContent}</div>
      <div key={uniqueKey('wr3', 2)} width={widths ? widths[1] : 12}>{middleContent}</div>
      <div key={uniqueKey('wr3', 3)} width={widths ? widths[2] : 2}>{rightContent}</div>
    </div>
  )
}

export const H1Topper = (props) => {
  const { content } = props
  return <div className='menu-header-topper-simple'>
    <div>{content}</div>
  </div>
}

export const H2Topper = (props) => {
  const { leftContent, rightContent } = props
  return <div className='menu-header-topper'>
    <div key={uniqueKey('wr2', 1)}>{leftContent}</div>
    <div key={uniqueKey('wr2', 2)}>{rightContent}</div>
  </div>
}

export const H3Topper = (props) => {
  const { leftContent, middleContent, middleContent2, rightContent, style } = props
  const mc = middleContent2 ?
    <div style={{ paddingTop: '.5em', wordBreak: 'break-word' }} >{middleContent2}</div>
    :
    null
  return <div className='menu-header-alt' style={style ? style : null}>
    <div key={uniqueKey('wr3a', 1)}>{leftContent}</div>
    <div key={uniqueKey('wr3a', 2)}>{middleContent}{mc}</div>
    <div key={uniqueKey('wr3a', 3)}>{rightContent}</div>
  </div>
}

export const H3MenuWrapper = (props) => {
  const { leftContent, middleContent, rightContent, widths, color } = props
  return <Menu widths={3} className={'menu-header'} color={color ? color : 'blue'} inverted>
    <Menu.Item style={{ width: widths[0] }}>{leftContent}</Menu.Item>
    <Menu.Item style={{ width: widths[1] }} width={6}>{middleContent}</Menu.Item>
    <Menu.Item style={{ width: widths[2] }} width={3}>{rightContent}</Menu.Item>
  </Menu>
} 