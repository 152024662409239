import { Marker } from 'google-maps-react';
import _ from 'lodash';
import React from 'react';
import { gEnums } from '../../enums/globalEnums';

export const getMapMarker = (itemData) => {

  const marker = (itemData) => (
    <Marker
      name={itemData.name}
      title={itemData.name}
      key={itemData.id}
      position={itemData.mapPosition} />
  )

  return marker(itemData)

}

export const getMapMarkers = (mapListData, onMarkerClick) => {

  const mapMarkers = []

  const marker = (itemData) => (
    <Marker
      onClick={onMarkerClick}
      name={itemData.name}
      title={itemData.name}
      key={itemData.id}
      position={itemData.mapPosition} />
  )

  mapListData.forEach(itemData => (
    mapMarkers.push(marker(itemData))
  ))

  return mapMarkers

}

export const geoCodeAddress = (google, mapDetails, onGeoResults, autoUpdateMapPositions, updateProps, firestore_handlers) => {
  const geocoder = new google.maps.Geocoder();
  if (mapDetails.address && mapDetails.address !== 'undefined') {
    geocoder.geocode({ 'address': mapDetails.address }, function (results, status) {
      if (status === 'OK') {
        const position = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }
        mapDetails.mapPosition = position
        onGeoResults && onGeoResults(mapDetails)
        if (autoUpdateMapPositions && updateProps) {
          const { paps_state, itemData, viewItem } = updateProps
          if (itemData) {
            const { updateFirestoreData } = firestore_handlers ? firestore_handlers : {}
            if (updateFirestoreData) {
              itemData.mapPosition = position
              const udp = {
                id: itemData.id,
                viewKey: itemData.id,
                itemData,
                modifyType: gEnums.dataUpdateTypes.edit,
                dataUpdateType: gEnums.dataUpdateTypes.edit,
                vit: viewItem.key,
              }
              updateFirestoreData(null, paps_state, udp)
            }
          }
        }
      } else {
        mapDetails.error = status
        onGeoResults && onGeoResults(mapDetails)
      }
    });
  }
}

export const geoCodeLatLng = (google, mapDetails, onGeoResults) => {
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ 'location': mapDetails.location }, function (results, status) {
    if (status === 'OK') {
      if (results[0]) {
        const { formatted_address } = results[0]
        mapDetails.mapPosition = {
          lat: mapDetails.location.lat(),
          lng: mapDetails.location.lng()
        }
        mapDetails.address = formatted_address
        mapDetails.name = mapDetails.address
        try {
          const spl = formatted_address.split(',')
          const sspl = spl[2].trim().split(' ')
          mapDetails.street = spl[0]
          mapDetails.city = spl[1]
          mapDetails.state = sspl[0]
          mapDetails.zip = sspl[1]
        } catch (error) {
          // split failed 
        }
        onGeoResults(mapDetails)
      } else {
        mapDetails.error = 'No results found'
        onGeoResults(mapDetails)
      }
    } else {
      mapDetails.error = 'No results found'
      onGeoResults(mapDetails)
    }
  });
}

export const getAddress = (itemData) => {
  let street = ''
  let city = ''
  let state = ''
  let zip = ''
  street = street + itemData.address ? itemData.address : ''
  city = city + (itemData.city ? ' ' + itemData.city : '')
  state = state + (itemData.state ? ', ' + itemData.state : '')
  zip = zip + (itemData.zip ? ' ' + itemData.zip : '')
  return {
    name: itemData.name,
    street: street,
    city: city,
    address: street + city + state + zip,
    mapPosition: itemData.mapPosition
  }
}

export const getMapDetails = (itemData, pr) => {
  if (itemData) {
    let name = itemData.name
    if (itemData.locations && itemData.locations[0]) {
      name = itemData.locations[0]
    }
    if (pr && pr.listOf) {
      if (itemData[pr.listOf]) {
        name = itemData[pr.listOf]
        if (name && _.isArray(name)) {
          name = name[0]
        }
      }
    }
    let itemAddress = getAddress(itemData)
    if (!itemAddress.street && itemData.mapItem) { itemAddress = getAddress(itemData.mapItem) }
    return {
      name: name,
      street: itemAddress.street,
      city: itemAddress.city,
      address: itemAddress.street + itemAddress.city,
      mapPosition: itemData.mapPosition,
      location: name ? name : null
    }
  }
  return {
    name: '',
    street: null,
    city: null,
    address: null,
    mapPosition: null,
    location: null
  }
}

export const findLocations = (viewListData, mapLists, listOfProp) => {
  const listOf = listOfProp.listOf
  if (listOf) {
    listOf.forEach(l => {
      if (mapLists && mapLists[l]) {
        const ml = mapLists[l]
        viewListData.forEach(itemData => {
          if (itemData.locations && itemData.locations[0]) {
            const item = _.find(Object.values(ml), { 'name': itemData.locations[0] })
            if (item && item.mapPosition) {
              itemData.mapPosition = item.mapPosition
            }
          }
        })
      }
    })
  }
}

export const findLocation = (itemData, mapLists, pr) => {
  const listOf = pr.listOf
  if (listOf) {
    if (_.isArray(listOf)) {
      listOf.forEach(l => {
        if (mapLists && mapLists[l]) {
          const ml = mapLists[l]
          if (itemData.locations && itemData.locations[0]) {
            let item = _.find(Object.values(ml), { 'name': itemData.locations[0] })
            if (!item) {
              item = _.find(ml, { 'name': itemData.locations[0] })
            }
            if (item && item.mapPosition) {
              itemData.mapPosition = item.mapPosition
              itemData.mapItem = item
            }
          }
          if (itemData.hotels && itemData.hotels[0]) {
            let item = _.find(Object.values(ml), { 'name': itemData.hotels[0] })
            if (!item) {
              item = _.find(ml, { 'name': itemData.hotels[0] })
            }
            if (item && item.mapPosition) {
              itemData.mapPosition = item.mapPosition
              itemData.mapItem = item
            }
          }
        }
      })
    } else {
      if (mapLists && mapLists[listOf]) {
        const ml = mapLists[listOf]
        if (itemData.locations && itemData.locations[0]) {
          let item = _.find(Object.values(ml), { 'name': itemData.locations[0] })
          if (!item) {
            item = _.find(ml, { 'name': itemData.locations[0] })
          }
          if (item && item.mapPosition) {
            itemData.mapPosition = item.mapPosition
            itemData.mapItem = item
          }
        }
        if (itemData.hotels && itemData.hotels[0]) {
          let item = _.find(Object.values(ml), { 'name': itemData.hotels[0] })
          if (!item) {
            item = _.find(ml, { 'name': itemData.hotels[0] })
          }
          if (item && item.mapPosition) {
            itemData.mapPosition = item.mapPosition
            itemData.mapItem = item
          }
        }
      }
    }
  }
}

export const getLocationFromAddress = (google, address, onGeoResults) => {
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ 'address': address }, function (results, status) {
    if (status === 'OK') {
      const location = {
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng()
      }
      onGeoResults && onGeoResults(location)
    }
  });
}