import _ from 'lodash';
import React from 'react';
import { Icon, Image, Label, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';

export const gridItemIconGroup = (viewItem, segProps, gcProps, hidden) => {
  return {
    viewItem,
    element: <SegIconGroup item={viewItem} segProps={segProps} gcProps={gcProps} defaultSnC={gcProps ? gcProps.defaultSnC : {}} handleIconClick={gcProps && gcProps.handleIconClick} hidden={hidden} />
  }
}

/** Return a Segment with and Icon or Image
 * @segProps caption, cloned, cn, icon, iconImageUrl, cornerIcon
 */
export const SegIconGroup = (props) => {

  const { item, segProps, gcProps, defaultSnC, handleIconClick, hidden } = props ? props : {}
  const { viewPermissionType } = item ? item : {}
  const { textLimit } = gcProps ? gcProps : {}

  const segIcon = () => {
    const { icon, iconImageUrls, cornerIcon } = segProps
    const { urls } = iconImageUrls ? iconImageUrls : {}
    const { full, thumbnail } = urls ? urls : {}
    const src = thumbnail ? thumbnail : full

    let _icon = viewPermissionType === gEnums.viewPermissionTypes.allowToAppUserAdmin ? 'user secret' : icon

    if ((!_icon || _icon === 'file alternate') && appIconTypes[item.key]) {
      _icon = appIconTypes[item.key]
    }

    if (src) {
      return <Image style={{ margin: 'auto' }} vit={item.key} name={_icon} src={src} />
    } else {
      if (cornerIcon) {
        return <Icon.Group size='huge'>
          <Icon name={_icon} />
          <Icon size='small' corner='bottom right' name={cornerIcon} />
        </Icon.Group>
      } else {
        return <Icon key={uniqueKey('sig', item.key)} name={_icon} />
      }
    }
  }

  const segLabel = (notification) => <Label size='tiny' attached='top right' style={notification.color ? { backgroundColor: notification.color } : {}}  >
    {notification.text}
  </Label>

  // title, icon
  const segGroup = () => {

    let { caption, cloned, cn, notification } = segProps
    if (!caption) { caption = _.startCase(item.key) + '*' }

    let cnt = 'grid-text'

    if (caption && caption.length > textLimit) { cnt += ' eight' }

    let itemKey = cloned ? item.itemKey : null
    if (hidden) { cn += ' hid' }

    const seg = () => {
      return <Segment
        style={defaultSnC.style}
        basic
        key={uniqueKey('ssig', item.key)}
        onClick={() => { handleIconClick && handleIconClick(item, itemKey) }}
        className={cn ? cn : null}
      >
        {notification && segLabel(notification)}
        {segIcon(item, segProps)}
        <div key={uniqueKey('sigd', item.key)} className={cnt}>{caption}</div>
      </Segment>
    }

    return seg()
  }

  return segGroup()

}
