import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import { Label, Segment } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import SwiperWrapper from '../../../../global/components/swipers/SwiperWrapper';
import { gEnums } from '../../../../global/enums/globalEnums';
import { getCurrentItem, getStorageItem } from '../../../../global/redirection/current';
import MenuSidebars from '../../../../global/sidebars/MenuSidebars';
import Wrapper from '../../../../global/wrappers/Wrapper';
import SeasonMatchesProvider, { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { CreateSportsContext } from '../../create/CreateSports';
import { getSectionColor } from '../../create/sectionColors';
import MatchFilter from './MatchFilter';
import MatchListSelected from './MatchListSelected';
import { playoffBracketTypes, playoffBracketTypesByCount } from './PlayoffBracket';

const sidebarType = 'matchList'

const MatchSchedule = (props) => {

  const {
    commonMatches,
    componentContexts,
    forBackup,
    forPlayoff,
    latestMatchesCount,
    latestMatchesOnly,
    nextMatchOnly,
    previewMatches,
    teamKey,
  } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { paps_state, appUser_state } = states
  const { page_fns } = fns

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const { profileData } = appUser ? appUser : {}
  const { pageFavs } = profileData ? profileData : {}
  const { teams: team_favs } = pageFavs ? pageFavs : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers, sportsSeason_fns } = sportsSeasonContext ? sportsSeasonContext : {}
  const { sportDistricts, seasonal_info, timestamp, currents, inverted, teams_info, matches_info, districts_info, seasonMatches_info, matchScheduleDisplayType, useAppDarkMode } = sportsSeason_state ? sportsSeason_state : {}
  const { organization: districts_org } = districts_info ? districts_info : {}
  const { sportLevels } = seasonal_info ? seasonal_info : {}
  const { sectionKeys } = teams_info ? teams_info : {}

  const _matches_info = forBackup && seasonMatches_info ? seasonMatches_info : matches_info

  const {
    levelDateGroups,
    matchDateGroups,
    matchDateKeys_count,
    matchDateKeys,
    matchesAll,
    levelLatestDates,
  } = _matches_info ? _matches_info : {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state } = createSportsContext ? createSportsContext : {}
  const { levels_info } = createSports_state ? createSports_state : {}
  const { next } = levels_info ? levels_info : {}

  // componentContexts
  const { uiItemContext } = componentContexts ? componentContexts : {}
  const { pushSimple } = page_fns ? page_fns : {}
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem } = item_state ? item_state : {}

  // papsContext    
  const { pathViews } = paps_state ? paps_state : {}

  // createSportsContext
  const seasonMatchesContext = useContext(SeasonMatchesContext);
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ? seasonMatchesContext : {}

  const {
    dateMatchResults,
    levelDateGroup,
    levelDateKey,
    levelGroupMatches,
    matchFilter,
    noData,
    sortedMatches,
    swiper,
    teamMatches
  } = seasonMatches_state ? seasonMatches_state : {}

  const {
    handleDateMatchResults,
    handleLevelDateGroup,
    handleLevelGroupMatches,
    handleMatchFilter,
    handleSwiper,
    handleTeamMatches,
  } = seasonMatches_handlers ? seasonMatches_handlers : {}

  const scheduleTypes = {
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: teamKey || (pathViews && pathViews.teams) ? true : false,
    common: commonMatches ? true : false
  }

  const _teamKey = teamKey ? teamKey : pathViews.teams

  let _sepBySection = true
  if (scheduleTypes.team || scheduleTypes.match || scheduleTypes.common) { _sepBySection = false }

  const _defaults = {
    ms_matchDates: window.localStorage.getItem('ms_matchDates'),
    ms_districts: window.localStorage.getItem('ms_districts'),
    ms_levels: window.localStorage.getItem('ms_levels'),
  }

  // sidebar
  // const init = { commonMatches, pathViews, previewMatches, nextMatchOnly, latestMatchesOnly, scheduleTypes, matchesInfo: _matches_info, sportsSeason_fns, _teamKey, latestMatchesCount }
  // const [seasonMatches_state, seasonMatches_dispatch] = useReducer(seasonMatchesReducer, seasonMatchesInitialState(init));
  // const seasonMatches_handlers = seasonMatchesHandlers(seasonMatches_dispatch)

  // const {
  //   dateMatchResults,
  //   levelDateGroup,
  //   levelDateKey,
  //   levelGroupMatches,
  //   matchFilter,
  //   noData,
  //   sortedMatches,
  //   swiper,
  //   teamMatches,
  // } = seasonMatches_state ? seasonMatches_state : {}

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers, defaults: _defaults }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, dimmed, contents } = sidebar_state ? sidebar_state : {}
  const { setInit, handleSetContent } = sidebar_handlers ? sidebar_handlers : {}
  const { ms_matchDates, ms_districts, ms_levels } = sidebar_items ? sidebar_items : {}

  const { selectedLocation, selectedResult, selectedSection } = matchFilter ? matchFilter : {}

  const item_matchDates = ms_matchDates && ms_matchDates.selected ? ms_matchDates.selected.item : null
  const item_districts = ms_districts && ms_districts.selected ? ms_districts.selected.item : null
  const item_levels = ms_levels && ms_levels.selected ? ms_levels.selected.item : null

  const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }
  const _initCalendarProps = { sidebarSliderType: sidebarSliderTypes.calendar }

  const _allowMatchFilter = !nextMatchOnly && !latestMatchesOnly && !scheduleTypes.common && scheduleTypes.team

  const handleSwiperIndexChange = (index, item) => {
    const _levelDateGroup = levelDateGroups[item.key]
    let _levelDateKey = getCurrentDateKey(_levelDateGroup)
    if (!_levelDateKey) { _levelDateKey = formatItem(formatTypes.shortestDate, new Date()) }
    handleLevelDateGroup(_levelDateGroup, _levelDateKey)
  }

  const getCurrentDateKey = (ldg) => {

    const ldgKeys = []

    if (ldg) {
      Object.keys(ldg).forEach(ldgKey => {
        ldgKeys.push(ldg[ldgKey].matchDate)
      })
    }

    let dd = false;
    let _dateKey = ldgKeys ? ldgKeys[ldgKeys.length - 1] : null;

    ldgKeys.forEach(dgk => {
      var d1 = new Date(dgk);
      var d2 = new Date();
      var diff = d2.getTime() - d1.getTime();

      if (diff < 0 && !dd) {
        const xxx = ldgKeys.indexOf(dgk)
        const yyy = xxx - 1
        const zzz = ldgKeys[yyy]
        _dateKey = zzz
        dd = true
      }
    })
    return _dateKey
  }

  // update ms_matchDates
  useEffect(() => {
    switch (matchScheduleDisplayType) {
      case gEnums.matchScheduleDisplayTypes.selectedDate:
        if (matchDateKeys) {
          if (!currents) { currents = {} }
          currents.matchDates = getStorageItem('ms_matchDates', matchDateKeys)
          setInit({ smt: sidebarMenuTypes.one, items: matchDateKeys, itemz: matchDateKeys_count, currents, as: 'matchDates', ..._initCalendarProps, showToday: true, showMissing: false })
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [levelLatestDates, matchDateKeys, matchDateKeys_count]);

  // update DISTRICTS
  useEffect(() => {
    if (scheduleTypes.district) {
      const _districts = []
      if (sportDistricts && districts_org) {
        sportDistricts.forEach(sd => {
          if (districts_org[sd]) {
            _districts.push(districts_org[sd].name)
          }
        })
      }
      setInit({ smt: sidebarMenuTypes.two, items: _districts, currents, as: 'districts', ..._initProps })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportDistricts, districts_org]);

  // update LEVELS
  useEffect(() => {
    let _sportLevels = sportLevels
    if (next) {
      let _spl = []
      Object.keys(next).forEach(key => {
        if (next[key].checked) {
          _spl.push(key)
        }
      })
      _sportLevels = _spl
    }
    currents.three = getCurrentItem(_defaults.ms_levels, _sportLevels)
    setInit({ smt: sidebarMenuTypes.three, items: _sportLevels, currents, as: 'levels', ..._initProps })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportLevels]);

  // update the scheduledMatches
  // useEffect(() => {
  //   handleMatchesInit(matchesAll)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps 
  // }, [pathViews, matchesAll]);

  useEffect(() => {
    switch (matchScheduleDisplayType) {
      case gEnums.matchScheduleDisplayTypes.selectedDate:
        if (matchDateGroups) {
          const dmr = sportsSeason_fns.getSelectedDateMatches(matchDateGroups, ms_matchDates, ms_districts, ms_levels, team_favs, previewMatches)
          const { sectionDateMatches } = dmr ? dmr : {}
          const pms = sectionDateMatches ? _.filter(sectionDateMatches, { isPlayoff: true }) : {}
          dmr.playoffDates = pms && Object.keys(pms).length > 0
          handleDateMatchResults(dmr)
        }

      case gEnums.matchScheduleDisplayTypes.allDates:
        if (levelDateGroups && item_levels) {
          const _levelDateGroup = levelDateGroups[item_levels]
          let _levelDateKey = getCurrentDateKey(_levelDateGroup)
          if (!_levelDateKey) { _levelDateKey = formatItem(formatTypes.shortestDate, new Date()) }
          handleLevelDateGroup(_levelDateGroup, _levelDateKey)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matchDateGroups, item_matchDates, item_districts, item_levels]);

  useEffect(() => {
    if (sortedMatches) {
      handleTeamMatches(selectedLocation, selectedResult, selectedSection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sortedMatches, selectedLocation, selectedResult, selectedSection]);

  useEffect(() => {
    if (ms_levels && ms_levels.items && levelDateGroup) {
      const lgms = {}
      ms_levels.items.forEach(key => {
        const _levelDateGroup = levelDateGroups[key]
        lgms[key] = matchSegs(key, _levelDateGroup)
      })
      handleLevelGroupMatches(lgms)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_levels, levelDateGroup]);

  useEffect(() => {
    if (levelGroupMatches) {
      const _swiperItems = []
      Object.keys(levelGroupMatches).forEach((k, index) => {
        _swiperItems.push({
          key: k,
          content: levelGroupMatches[k],
          caption: k,
          index
        })
      })
      handleSwiper({ swipeItems: _swiperItems, sidebarItems: ms_levels, handleSwiperIndexChange })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [levelGroupMatches]);

  const handleMatchClick = (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => {
    if (itemKey) sportsSeason_handlers.handleGoToMatch(pushSimple, { key: 'matches' }, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex)
  }

  const sectionDivs = () => sectionKeys.map(sk => (<div key={uniqueKey('sk', sk)}><Label circular size={'mini'} color={getSectionColor(sk)}></Label><div>{_.startCase(sk)}</div></div>))

  const legend = () => <Segment inverted={useAppDarkMode} className={'legend-schedule'} key={uniqueKey('ls', 'l')}>
    {sectionDivs()}
  </Segment>

  const matchSegs = (itemLevel, ldg) => {

    // const dateSegs = []
    let pools;
    let pbt;

    if (dateMatchResults && dateMatchResults.sectionDateMatches) {
      const count = dateMatchResults.sectionDateMatches.length
      pbt = playoffBracketTypesByCount(count)
      pools = {
        [pbt]: {
          matches: dateMatchResults.sectionDateMatches,
          itemKey: playoffBracketTypes.roundOf16
        }
      }
    }

    const mss = <MatchListSelected
      key={uniqueKey('smp', 'ml', itemLevel)}
      teamKey={teamKey}
      latestMatchesOnly={latestMatchesOnly}
      nextMatchOnly={nextMatchOnly}
      item_levels={itemLevel ? itemLevel : item_levels}
      forPlayoff={forPlayoff} />

    return [mss]
  }

  const matchesWrapper = () => <Wrapper
    header={_allowMatchFilter && <MatchFilter setMatchFilter={handleMatchFilter} />}
    content={matchSegs()}
    updating={false}
    updatingCaption={'Fetching'}
  />

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={matchesWrapper()}
    dimmed={dimmed}
    inverted={inverted}
  />

  if (scheduleTypes.team || scheduleTypes.match || scheduleTypes.common) {
    return sortedMatches ? matchesWrapper() : <div key={uniqueKey('mtl', 'd')} ></div>
  } else {
    switch (matchScheduleDisplayType) {
      case gEnums.matchScheduleDisplayTypes.allDates:
        return swiper ? <SwiperWrapper swType={'ms'} swiper={swiper} noData={noData} key={uniqueKey('sw', 'sm')} /> : <div></div>
      case gEnums.matchScheduleDisplayTypes.selectedDate:
        return menuSidebars()
      default:
        return <div></div>
    }
  }

}

export default MatchSchedule

export const MatchScheduleWithProvider = (props) => {
  return <SeasonMatchesProvider  {...props}  >
    <MatchSchedule {...props} />
  </SeasonMatchesProvider>
}