import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, List } from 'semantic-ui-react';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { SportsSeasonContext } from '../cnr/contexts/SportsSeasonContext';
import { getPks, updateSeasonalOrGlobal } from '../cnr/reducers/reducerHelpers/seasonalSportsData';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';

const BackupSeasons = (props) => {

  const { handleActionPreview, viewItem, handleCancel } = props

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { paps_state } = states ? states : {}
  const { pathViews } = paps_state ? paps_state : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ? sportsSeasonContext : {}
  const { latestSeason, latestSeason_full, sportsKey, seasonMatches_info } = sportsSeason_state ? sportsSeason_state : {}
  const { matches: matches_season } = seasonMatches_info ? seasonMatches_info : {}

  const [currentYear, setCurrentYear] = useState(latestSeason)

  useEffect(() => {
    sportsSeason_handlers.handleGetSeasonMatches(currentYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentYear]);

  const handleButtonClick = () => sportsSeason_handlers.handleBackupSeasonMatches(currentYear, matches_season, handleCancel)

  const handleYearChange = (e, data) => setCurrentYear(data.value)

  const buttons = () => {
    const clickOptions_backup = { clickType: 'seasonSchedule', isBackup: true }
    const btns = []
    btns.push({ oc: handleButtonClick, icon: 'save', caption: 'Backup Season (' + latestSeason + ')', clickOptions: clickOptions_backup })
    return btns

  }

  const matchItem = (m) => {
    const { matchName, startDate, results } = m
    const { scoreDisplayFull } = results ? results : {}
    return <List.Item>
      <List.Content>
        <List.Header >{scoreDisplayFull ? scoreDisplayFull : matchName}</List.Header>
        <List.Description >{startDate}</List.Description>
      </List.Content>
    </List.Item>
  }


  const matchList = () => {
    const mls = []
    const dateGroups = _.groupBy(matches_season, 'startDate')
    Object.keys(dateGroups).forEach(dgKey => {
      const matches = dateGroups[dgKey]
      matches.forEach(m => {
        mls.push(matchItem(m))
      })
    })
    return <List divided relaxed>
      {mls}
    </List>
  }

  const wrapperContent = () => {
    const _buttons = buttons()
    return <Wrapper
      content={matches_season ? matchList() : <div>...</div>}
      footer={_buttons && <UiSaveButtons others={_buttons} />}
      wrapperType={wrapperTypes.padded}
    />
  }

  let startYear = 2003;
  let endYear = 2024;

  const ddOpts = Array.from({ length: endYear - startYear + 1 }, (_, i) => ({
    text: (startYear + i - 1) + '-' + (startYear + i),
    value: startYear + i,
    key: startYear + i,
  }))

  const dd = () => <Dropdown
    placeholder='Year'
    selection
    options={ddOpts}
    value={currentYear}
    onChange={handleYearChange}
  >
  </Dropdown>

  const fpw = () => <FullPageWrapper
    content={wrapperContent()}
    topperCaption={'Backup Seasons'}
    topperCaption2={dd()}
    handleCancel={handleCancel}
  ></FullPageWrapper>


  return fpw()
}

export default BackupSeasons
