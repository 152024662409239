import { createKeys } from '../../common/creating'
import { gEnums } from "../../enums/globalEnums"
import { createPropsFromList } from "../../viewSettings/helpers/settingsHelpers"
import { dispatchConfirmationTypes, grts, responseReducers } from './reducerHelpers/dispatchProps'
import _ from 'lodash'

const rts = {
  handleCalOpen: 'handleCalOpen',
  handleCloseImageUploader: 'handleCloseImageUploader',
  handleCommitNo: 'handleCommitNo',
  handleCommitYes: 'handleCommitYes',
  handleDataLabelAdd: 'handleDataLabelAdd',
  handleDataLabelClick: 'handleDataLabelClick',
  handleDataLabelRemoveClick: 'handleDataLabelRemoveClick',
  handleDelete: 'handleDelete',
  handleDismissBack: 'handleDismissBack',
  handleGetAuth: 'handleGetAuth',
  handleGetDataModifyProps: 'handleGetDataModifyProps',
  handleGoToAccess: 'handleGoToAccess',
  handleHeaderBreadcrumbClick: 'handleHeaderBreadcrumbClick',
  handleMapOpen: 'handleMapOpen',
  handleOpenImageUploader: 'handleOpenImageUploader',
  handleFormDataChange_dataModify: 'handleFormDataChange_dataModify',
  handleSetModifyData: 'handleSetModifyData',
  handleSubPropChange: 'handleSubPropChange',
  handleSwipeChangeIndex: 'handleSwipeChangeIndex',
  handleUpdateAppFirestore: 'handleUpdateAppFirestore',
  handleUpdateInitData: 'handleUpdateInitData',
  handleUpdateSettings: 'handleUpdateSettings',
  handleUploadOpen: 'handleUploadOpen',
  setAddMode: 'setAddMode',
  setDataViewMode: 'setDataViewMode',
  setMapList: 'setMapList',
  setSelectedDataItems: 'setSelectedDataItems',
  ...grts,
}

export const dataModifyReducer = (state, action) => {

  const {
    appUser,
    autoUpdateCollectionRelationships,
    questionProps,
    data_initial,
    dataListData,
    dataViewMode: stateDataViewMode,
    handleUpdateSettings_dataList,
    navigate,
    isList,
    itemProps,
    paps_state,
    selectedIndex,
    staticViewKeys,
    swipedItems,
    firestore_handlers,
    viewItem_props,
    vit,
  } = state

  let { data_modified } = state ? state : {}

  const { key, parentKey, count, formDataInfo, dispatch, type, dataViewMode } = action
  const { data_form, propItemData, subProp } = formDataInfo ? formDataInfo : {}
  let sis = swipedItems ? [...swipedItems] : null

  const { dataConstraints, dataSource } = viewItem_props ? viewItem_props : {}
  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  let swipedItem;
  let newItemData;

  switch (type) {

    // set the modifyProps (from DataModifySwiper)
    case rts.handleGetDataModifyProps:

      const props = { ...state, ...action }
      const { combinedProps: iprs, newItemProps: nips, createFunction } = getDataItemProps(props)
      const dataModifyProps = { itemProps: iprs, newItemProps: nips, createFunction }
      const { itemProps: ip, newItemProps: nip } = dataModifyProps
      if (ip) { createKeys(ip) }
      const _itemProps = action.modifyProps ? action.modifyProps : ip

      if (_itemProps) {
        Object.keys(_itemProps).forEach(key => {
          const ip = _itemProps[key]
          if (ip && ip.data && ip.data.useAsName) {
            ip.propSection = 'name'
            ip.display.caption = 'Name (' + ip.display.caption + ')'
            delete _itemProps['name']
          }
        })
      }
      const _md = getInitDataFromProps(_itemProps, state.modifyType)
      return {
        ...state,
        itemProps: _itemProps,
        newItemProps: nip,
        data_modified: state.data_modified ? state.data_modified : _md
      }

    case rts.handleSetModifyData:
      return { ...state, itemData: action.modifyData, modifyData: action.modifyData }

    // updates the `data_modified` from the form
    case rts.handleFormDataChange_dataModify:

      if (data_modified) {
        swipedItem = sis ? sis[sis.length - 1] : null
        const parentDataItem = getParentDataItem(swipedItems, data_modified, true)

        if (swipedItem && swipedItem.parentKey && parentDataItem && parentDataItem[swipedItem.parentKey] && parentDataItem[swipedItem.parentKey][swipedItem.dKey]) {
          parentDataItem[swipedItem.parentKey][swipedItem.dKey] = data_form
        } else {
          data_modified = data_form && Object.keys(data_form).length > 0 ? data_form : data_modified
        }

        if (itemProps && propItemData) {
          const { propKey, text: itemName } = propItemData
          if (itemProps && itemProps[propKey] && itemProps[propKey].data && itemProps[propKey].data.useAsName) {
            data_modified['name'] = itemName
          }
        }

        return {
          ...state,
          data_modified,
          calOpen: false,
          uploadOpen: false,
          data_modified_ready: itemProps ? isModifyReady(itemProps, data_form) : false
        }
      } else {
        return { ...state }
      }

    case rts.handleSubPropChange:

      const existingData = { ...data_modified }
      if (existingData && existingData[subProp]) {
        existingData[subProp] = data_form
        return {
          ...state,
          data_modified: existingData,
          uploadOpen: false,
          data_modified_ready: itemProps ? isModifyReady(itemProps, existingData) : false
        }
      }
      return { ...state }

    case rts.setSelectedDataItems:
      return {
        ...state,
        selectedDataItems: action.selectedDataItems,
        data_modified_ready: action.selectedDataItems && Object.keys(action.selectedDataItems).length > 0 ? true : false
      }

    case rts.setAddMode:
      return { ...state, addModeType: action.addModeType }

    // goes to firestore_handlers
    case rts.handleUpdateAppFirestore:

      swipedItem = sis[0]

      let od;

      if (isList && data_modified && data_initial) {
        od = { ...data_initial }
        Object.keys(od).forEach(key => {
          const oid = od[key]
          if (oid.key && data_modified[oid.key]) {
            oid.text = data_modified[oid.key]
          }
        })
      }

      if (itemProps) {
        Object.keys(itemProps).forEach(key => {
          if (itemProps[key].data && itemProps[key].data.autoFill && itemProps[key].data.autoFillType) {
            switch (itemProps[key].data.autoFillType) {
              case gEnums.autoFillTypes.appUserId:
                data_modified[key] = appUser.uid
                break;
              case gEnums.autoFillTypes.currentTimestamp:
                data_modified[key] = new Date()
                break;
              default:
              // nothing
            }
          }
        })
      }

      // add any parent keys
      if (_dataConstraints) {
        const { view: pageName, viewKey: pageKey } = paps_state ? paps_state : {}
        if (pageName && pageKey) {
          switch (_dataConstraints.pageConstraintType) {
            case gEnums.pageConstraintTypes.arrayContains:
              data_modified[pageName] = [pageKey]
              break;
            case gEnums.pageConstraintTypes.equalTo:
              data_modified[pageName] = pageKey
              break;
            default:
            // nothing
          }
        }
      }

      const ufProps = {
        autoUpdateCollectionRelationships,
        id: data_modified ? data_modified.id : null,
        itemData: od ? od : data_modified,
        modifyType: state.modifyType,
        dataUpdateType: state.modifyType,
        selectedDataItems: action.selectedDataItems,
        staticViewKeys,
        swipedItem,
        vit: vit,
      }

      firestore_handlers.updateFirestoreData(dispatch, paps_state, ufProps, null, appUser)

      return { ...state }

    case rts.handleUpdateSettings:
      handleUpdateSettings_dataList(dataListData)
      return { ...state }

    case rts.handleUpdateInitData:
      return {
        ...state,
        data_initial: action.initItem,
        data_modified: action.initItem,
      }

    case rts.handleOpenImageUploader:

      swipedItem = { isImage: true }
      swipedItem.caption = 'Image'
      sis.push(swipedItem)

      return {
        ...state,
        swipedItems: sis,
        selectedIndex: selectedIndex + 1
      }

    case rts.handleGoToAccess:

      swipedItem = { isAccess: true }
      swipedItem.caption = 'Access'
      sis.push(swipedItem)

      return {
        ...state,
        swipedItems: sis,
        selectedIndex: selectedIndex + 1
      }

    case rts.handleGetAuth:

      swipedItem = { isAuth: true }
      swipedItem.caption = 'Auth'
      sis.push(swipedItem)

      return {
        ...state,
        swipedItems: sis,
        selectedIndex: selectedIndex + 1
      }

    case rts.handleCloseImageUploader:

      sis.splice(sis.length - 1, 1)
      swipedItem = sis[sis.length - 1]

      return {
        ...state,
        swipedItems: sis,
        selectedIndex: selectedIndex - 1,
      }

    case rts.handleSwipeChangeIndex:

      sis.splice(sis.length - 1, 1)
      swipedItem = sis[sis.length - 1]

      const pd = getParentDataItem(sis, data_modified, true)

      if (swipedItem && pd && pd[swipedItem.parentKey] && pd[swipedItem.parentKey][swipedItem.dKey]) {
        return {
          ...state,
          swipedItems: sis,
          selectedIndex: selectedIndex - 1,
          data_modified: pd[swipedItem.parentKey][swipedItem.dKey]
        }
      } else {
        return {
          ...state,
          swipedItems: sis,
          selectedIndex: selectedIndex - 1,
          data_modified
        }
      }

    case rts.handleHeaderBreadcrumbClick:

      const startNumber = (count || count === 0) ? count + 1 : sis.length - 1
      const deleteCount = (count || count === 0) ? sis.length - (count + 1) : 1
      const newIndex = startNumber - 1
      sis.splice(startNumber, deleteCount)
      swipedItem = sis[sis.length - 1]
      newItemData = getDataItem(swipedItem)

      return {
        ...state,
        swipedItems: sis,
        selectedIndex: newIndex,
        data_modified,
      }

    case rts.handleDataLabelAdd:

      const { propname } = action.fip

      swipedItem = { parentKey: propname, dataUpdateType: gEnums.dataUpdateTypes.add }
      newItemData = getDataItem(sis, data_modified, swipedItem)
      swipedItem.caption = 'New Item'
      sis.push(swipedItem)

      return {
        ...state,
        swipedItems: sis,
        selectedIndex: selectedIndex + 1,
        dataUpdateType: gEnums.dataUpdateTypes.add,
      }

    case rts.handleDataLabelClick:

      swipedItem = { parentKey, dKey: key }
      newItemData = getDataItem(sis, data_modified, swipedItem)
      swipedItem.caption = newItemData.name ? newItemData.name : key
      sis.push(swipedItem)

      return {
        ...state,
        data_modified: newItemData,
        swipedItems: sis,
        selectedIndex: selectedIndex + 1
      }

    case rts.handleDataLabelRemoveClick:
      return {
        ...state
      }

    case rts.handleCalOpen:
      return {
        ...state,
        calOpen: !state.calOpen,
        calProp: action.calProp
      }

    case rts.handleMapOpen:
      return {
        ...state,
        [action.name]: action.value
      }

    case rts.handleUploadOpen:
      return {
        ...state,
        uploadOpen: !state.uploadOpen,
        uploadProp: action.uploadProp
      }


    case rts.handleCommitNo:
      return {
        ...state,
        questionProps: null
      }

    case rts.handleCommitYes:
      if (questionProps) {
        switch (questionProps.type) {
          case 'delete':
            const ufPropsC = {
              id: data_modified.id,
              data_modified,
              modifyType: state.modifyType,
              dataUpdateType: gEnums.dataUpdateTypes.delete,
              vit: vit,
            }
            firestore_handlers.updateFirestoreData(dispatch, paps_state, ufPropsC, null, appUser)
            break;
          default:
          // nothinog
        }
      }

      return {
        ...state,
        questionProps: null
      }

    case rts.handleDelete:
      return {
        ...state,
        questionProps: {
          check: true,
          header: 'Delete',
          question: 'Are you sure you want to delete this item. It cannot be recovered!!!.',
          type: 'delete'
        }
      }

    case rts.setDataViewMode:
      return {
        ...state,
        dataViewMode: stateDataViewMode === dataViewMode ? null : dataViewMode
      }

    case rts.setMapList:
      return {
        ...state,
        dataListData: action.mapList,
        data_modified_ready: true,
      }

    case rts.handleDismissBack:
      navigate(-1)
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { viewSettingsHaveChanged: false, dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterUpdate })

    default:
      return { ...state }
  }
}

export const dataModifyInitialState = (init_state) => {

  const {
    allowProdDataEdit,
    allowImage,
    componentContexts,
    data_initial,
    data_modified,
    dataListData,
    dataUpdateType,
    editableItem,
    isList,
    navigate,
    originalModifyData,
    staticViewKeys,
    states,
    selectedItem,
    selectedKey,
    swipedItems,
    viewItem,
    vit,
  } = init_state

  const { appUser_state, page_state, paps_state } = states

  // componentContexts
  const { firestoreContext, viewSettingsContext } = componentContexts ? componentContexts : {}

  //  authContext 
  const { appUser } = appUser_state ? appUser_state : {}

  //  papsContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appNotifications } = aps_global ? aps_global : {}

  // firestoreContext
  const { firestore_handlers } = firestoreContext

  // viewSettingsContext
  const { viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}
  const { handleUpdateSettings_dataList } = viewSettings_handlers ? viewSettings_handlers : {}

  return {
    allowProdDataEdit,
    allowImage,
    allowUpdates: true,
    appNotifications,
    appUser,
    calProp: null,
    data_initial,
    data_modified,
    dataListData,
    data_modified_ready: false,
    editableItem,
    handleUpdateSettings_dataList,
    navigate,
    isList,
    modifyType: dataUpdateType,
    originalModifyData,
    paps_state,
    selectedIndex: 0,
    selectedKey,
    selectedItem,
    staticViewKeys,
    swipedItems: swipedItems ? swipedItems : [],
    firestore_handlers,
    viewItem,
    vit,
  }
};

export const dataModifyHandlers = (dispatch) => {
  return {
    handleOpenMap: () => { dispatch({ type: rts.handleMapOpen }) },
    handleFormDataChange_dataModify: (formDataInfo) => { dispatch({ type: rts.handleFormDataChange_dataModify, formDataInfo }) },
    handleSubPropChange: (formDataInfo) => { dispatch({ type: rts.handleSubPropChange, formDataInfo }) },
    handleTimeChange: (e, timeProp) => { console.log('timeProp', timeProp) },
    handleUploadOpen: (uploadProp) => { dispatch({ type: rts.handleUploadOpen, uploadProp }) },
    handleHeaderBreadcrumbClick: (count) => { dispatch({ type: rts.handleHeaderBreadcrumbClick, count }) },
    handleDataLabelAdd: (fip) => { dispatch({ type: rts.handleDataLabelAdd, fip }) },
    handleDataLabelClick: (parentKey, key) => { dispatch({ type: rts.handleDataLabelClick, parentKey, key }) },
    handleDataLabelRemoveClick: (e, parentKey, key) => {
      e.stopPropagation()
      dispatch({ type: rts.handleDataLabelRemoveClick, parentKey, key })
    },
    handleSwipeChangeIndex: () => { dispatch({ type: rts.handleSwipeChangeIndex }) },
    handleOpenImageUploader: () => { dispatch({ type: rts.handleOpenImageUploader }) },
    handleGoToAccess: (modifyData) => { dispatch({ type: rts.handleGoToAccess, modifyData }) },
    handleGetAuth: (modifyData) => { dispatch({ type: rts.handleGetAuth, modifyData }) },
    handleCloseImageUploader: () => { dispatch({ type: rts.handleCloseImageUploader }) },
    setAddMode: (addModeType) => { dispatch({ type: rts.setAddMode, addModeType }) },

    // from dataReducer
    handleDismiss: () => { dispatch({ type: rts.handleDismiss }) },
    handleDismissBack: () => { dispatch({ type: rts.handleDismissBack }) },
    handleUpdateAppFirestore: (selectedDataItems) => { dispatch({ type: rts.handleUpdateAppFirestore, dispatch, selectedDataItems }) },
    handleUpdateSettings: () => { dispatch({ type: rts.handleUpdateSettings, dispatch }) },
    handleUpdateInitData: (initItem) => { dispatch({ type: rts.handleUpdateInitData, dispatch, initItem }) },
    handleDelete: () => { dispatch({ type: rts.handleDelete, dispatch }) },
    handleCommitYes: () => { dispatch({ type: rts.handleCommitYes, dispatch }) },
    handleCommitNo: () => { dispatch({ type: rts.handleCommitNo, dispatch }) },
    setDataViewMode: (dataViewMode) => { dispatch({ type: rts.setDataViewMode, dataViewMode }) },
    handleGetDataModifyProps: (appForms, aps_viewItems, viewItem_page, modifyProps) => { dispatch({ type: rts.handleGetDataModifyProps, appForms, aps_viewItems, viewItem_page, modifyProps }) },
    setMapList: (mapList) => { dispatch({ type: rts.setMapList, mapList }) },
    handleSetModifyData: (modifyData) => { dispatch({ type: rts.handleSetModifyData, modifyData }) },
    setSelectedDataItems: (selectedDataItems) => { dispatch({ type: rts.setSelectedDataItems, selectedDataItems }) },
  }
}

const isModifyReady = (itemProps, data_current) => {
  let itemIsReady = true
  Object.keys(itemProps).forEach(key => {
    if (itemIsReady) {
      const itemProp = itemProps[key]
      if (itemProp.data && itemProp.data.required) {
        if (!data_current[key] || data_current[key] === '') {
          itemIsReady = false
        }
      }
    }
  })
  return itemIsReady
}

const getDataItem = (swipedItems, data_modified, swipedItem) => {
  let vdr = getParentDataItem(swipedItems, data_modified)
  const { dKey, parentKey } = swipedItem ? swipedItem : {}
  if (vdr && vdr[parentKey] && vdr[parentKey][dKey]) {
    return vdr[parentKey][dKey]
  }
  return {}
}

const getParentDataItem = (swipedItems, data_modified, parentOnly) => {
  if (swipedItems) {
    let itemD = { ...data_modified }
    let max = Object.keys(swipedItems).length - 1
    if (parentOnly) { max-- }
    Object.keys(swipedItems).forEach((key, index) => {
      if (index <= max) {
        const si = swipedItems[key]
        const { parentKey, dKey } = si
        if (itemD[dKey]) {
          itemD = itemD[dKey]
        } else if (itemD[parentKey]) {
          itemD = itemD[parentKey]
          if (itemD[dKey]) {
            itemD = itemD[dKey]
          }
        }
      }
    })
    return itemD
  }
  return {}
}

const getDataItemProps = (props) => {

  const { appForms, aps_viewItems, data_initial, viewItem: viewItem_app, viewItem_page, vit } = props

  // viewProps - global
  const viProps_app = aps_viewItems && viewItem_app && aps_viewItems[vit] ? aps_viewItems[vit].props : null

  // viProps_page
  const viProps_page = viewItem_page ? viewItem_page.props : null

  let props_viewItem = { ...viProps_app }
  ammendPositions(props_viewItem, viProps_page)

  const fixedProps = appForms ? appForms[vit] : {}
  const newItemProps = {}

  if (!props_viewItem || fixedProps) { props_viewItem = fixedProps }
  if (!props_viewItem && data_initial) { props_viewItem = createPropsFromList(data_initial) }

  const props_fromData = data_initial ? createPropsFromList(data_initial) : {}

  Object.keys(props_fromData).forEach(propKey => {
    if (!props_viewItem[propKey]) {
      newItemProps[propKey] = props_fromData[propKey]
    }
  })

  const combinedProps = { ...props_fromData, ...props_viewItem }

  return { combinedProps, newItemProps }
}


const ammendPositions = (props_viewItem, props_viewItem_page) => {
  Object.keys(props_viewItem).forEach(key => {
    if (props_viewItem_page && props_viewItem_page[key]) {
      if (_.isObject(props_viewItem[key])) {
        props_viewItem[key].position = props_viewItem_page[key].position
      }
    }
  })
}

/**
 * 
 * @param {object} itemProps 
 * @param {string} modifyType 
 * @returns an object container base on the itemProps
 */
const getInitDataFromProps = (itemProps, modifyType) => {
  const _md = {}
  switch (modifyType) {
    case gEnums.dataUpdateTypes.add:
      Object.keys(itemProps).forEach(key => {
        _md[key] = null
      })
      break;
    default:
    // nothing
  }
  return _md
} 