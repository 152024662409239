import { getAuth, onAuthStateChanged } from "firebase/auth";
import { isSupported } from "firebase/messaging";
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { consoleStart } from '../../../App';
import { signUpConfirm } from '../../auth/signInActions/signUpConfirm';
import { authHandlers, authReducer, authReducerInitialState } from '../reducers/AuthReducer';
import { startTypes } from "../reducers/StartReducer";
import { StartContext } from "./StartContext";

const ignoreWait = false
const _showState = false

/**
@state auth_state(`notificationRequestResponse`, `messagingSupported`, `publicVapidKey`, `authValidated`, `loggedIn`, `currentUser`, `noAuth`)
@handlers auth_handlers (`handleMessagingSupported`, `handleBadEmailLink`, `handleSetAuth`, `handleSignOut`, `handleSignedOut`, `handleUpdateNotificationRequest`, `handleSignInWithValidatedEmail`)
@contexts [StartContext]
 */
export const AuthContext = createContext();
/** Top level provider the App
@componentContexts [StartContext]
@provides auth_state, auth_handlers 
@description Returns the Auth object for the App. Will return a `PageDimmer` until the appUser && appUser.profileValidated is set  
*/
const AuthProvider = (props) => {

  // startContext
  const startContext = useContext(StartContext)
  const { start_handlers } = startContext ? startContext : {}
  const { handleUpdateStart } = start_handlers ? start_handlers : {}

  // AuthReducer
  const initState = authReducerInitialState({})
  const [auth_state, auth_dispatch] = useReducer(authReducer, initState);
  const auth_handlers = authHandlers(auth_dispatch, auth_state)
  const { authValidated, notificationRequestResponse } = auth_state;

  if (_showState) {
    console.log(Object.keys(auth_state))
    console.log(Object.keys(auth_handlers))
  }

  const callback = (emailConfirmation) => auth_handlers.handleBadEmailLink(emailConfirmation)

  // runs when `auth_state` changes philipaokeeffe@gmail.com
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      console.log('auth', auth)
      if (!authValidated) {
        handleUpdateStart && handleUpdateStart(startTypes.authorizing)
        if (user) {
          const ua = {
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            loggedIn: true,
            photoURL: user.photoURL,
            providerData: user.providerData,
            senderKey: user.uid,
            uid: user.uid,
          }
          auth_handlers.handleSetAuth(ua)
          consoleStart && console.log('ua', ua)
        } else {
          auth_handlers.handleSetAuth()
          handleUpdateStart && handleUpdateStart(startTypes.finish)
        }
      } else {
        auth_handlers.handleSetAuth()
        handleUpdateStart && handleUpdateStart(startTypes.finish)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  useEffect(() => {
    isSupported().then(res => {
      auth_handlers.handleMessagingSupported(res)
    }).catch(error => {
      console.error('error', error)
      auth_handlers.handleMessagingSupported(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (!notificationRequestResponse) {
      try {
        Notification.requestPermission().then(function (result) {
          auth_handlers.handleUpdateNotificationRequest(result)
        });
      } catch (error) {
        if (error instanceof TypeError) {
          Notification.requestPermission(() => {
            console.log("OK");
          });
        } else {
          // set the `notificationRequestResponse` property
          auth_handlers.handleUpdateNotificationRequest('denied')
        }
      }
    } else {
      auth_handlers.handleUpdateNotificationRequest(notificationRequestResponse)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // check the link for email confirmation
  useEffect(() => {
    signUpConfirm(window.location.href, callback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const provider = () => <AuthContext.Provider
    value={{
      auth_handlers,
      auth_state,
    }} >
    {props.children}
  </AuthContext.Provider >

  return (authValidated || ignoreWait) ? provider() : <div></div>
}

export default AuthProvider
