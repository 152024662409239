import React from 'react';
import { Form } from 'semantic-ui-react';
import { uniqueKey } from '../common/keys';
import { listList } from '../lists/lists';

const PickSelect = (name, items, selected, oc) => {
  const opts = listList(Object.keys(items), true)
  return <Form style={{ marginTop: '1em' }}><Form.Select
    key={uniqueKey('add', 'ps')}
    className='icon hdd'
    fluid
    label={name}
    options={opts}
    onChange={oc}
    selection
    value={selected}
  /></Form>
}

export default PickSelect