import { arrayUnion, where } from "firebase/firestore";
import { fs_get_data } from "../appData/fsAppData";
import { createRefPath, createRefPath_client } from '../appData/appRefPaths';
import { fs_update_doc } from "../appData/fsData";
import { _profileCollectionName } from "./getProfile";

export const updateAppUserFcmTokenToDatabase = (newToken, appUser, pathViews, callback) => {
  const { profileData } = appUser ? appUser : {}
  const { email } = profileData ? profileData : {}
  if (email) { updateProfileToken(newToken, appUser, pathViews, callback) }
}

/** Updates a profile token for the user */
const updateProfileToken = (newToken, appUser, pathViews, callback) => {

  const { profileData } = appUser ? appUser : {}
  const { email, fcmTokens } = profileData ? profileData : {}

  if (newToken && fcmTokens && fcmTokens.includes(newToken)) { return false }

  const allowStandAlone = true
  let isSmartPhone;

  if (window.matchMedia('(display-mode: standalone)').matches) {
    isSmartPhone = true
  } else {
    isSmartPhone = false
  }

  if (email && (isSmartPhone || allowStandAlone)) {

    const _cb = (profile) => {
      if (profile) {
        const data = { fcmTokens: arrayUnion(newToken) }
        fs_update_doc(createRefPath([_profileCollectionName, profile.id]), data).then(res => {
          callback(newToken)
        }).catch(error => {
          console.error(error)
        })
      } else {
        // no profile, check the client
        updateClientProfileToken(newToken, appUser, pathViews)
      }
    }

    // get the profile
    const wheres = [where('email', '==', email)]
    fs_get_data({ refPath: [_profileCollectionName], wheres, opts: { returnFirstObject: true }, callback: _cb })

  }

  const updateClientProfileToken = (newToken, appUser, pathViews) => {

    const { profileData } = appUser ? appUser : {}
    const { email } = profileData ? profileData : {}

    const _getRef = createRefPath_client(pathViews, [_profileCollectionName])

    const _cb = (profile) => {
      if (profile) {
        const _updateRef = createRefPath(profile.id, _getRef)
        const data = { fcmTokens: arrayUnion(newToken) }
        fs_update_doc(_updateRef, data).then(ress => {
          console.log('Client Token Updated - New Token ', email, newToken)
        }).catch(error => {
          console.error(error)
        });
      }
    }

    if (email) {
      const wheres = [where('email', '==', email)]
      fs_get_data({ refPath: _getRef, wheres, opts: { returnFirstObject: true }, callback: _cb })
    }
  }
}