import { deleteField } from "firebase/firestore";
import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "../../../../global/cnr/reducers/reducerHelpers/dispatchProps";
import { getFirstObject } from '../../../../global/common/convert';
import { deleteFromObject, getGroupListData, removeFromObject, removeFromObjects, sumObjectProp } from '../../../../global/common/sorting';
import { createRefPath, createRefPath_event, createRefPath_events } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data, fs_get_data_all } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_set_doc } from '../../../../global/firestoreData/appData/fsData';
import { golfEnums } from "../../enums/golfEnums";
import { calcGolfRounds } from '../../golfCalcs/calcGolfRounds';
import { fixFromMatches, fixGolferHandicaps, fixGolfers_tournament, fixTournament } from "./reducerHelpers/golfFix";
import { ammendGolfers, ammendLineupsWithGolfers, ammendMatchGolfers, getAllMatches, getSeletedTeamGolfers, getTournamentCourses, roundColor } from "./reducerHelpers/golfTournamentHelpers";

const removeFields_golferTournament = ['_itemKey', 'id', 'handicap', 'checked']
const deleteFields_golferTournament = ['_existingKey', '_itemKey', '_authId', 'firstName', 'lastName', 'handicapIndex', 'phoneNumber', 'teebox']
const removeFields_golferMatch = [
  '_existingKey',
  'auth_uid',
  'courseHandicap',
  'courseHandicaps',
  'courseTeeboxes',
  'email',
  'firstName',
  'handicapIndex',
  'handicapSourceType',
  'id',
  'itemKey',
  'key',
  'lastName',
  'name',
  'shortName',
  'tournamentHandicapIndex',
  'tournamentHandicapSourceType',
]

const _golfTournamentCollection = 'golfTournaments'
const _golfTournamenCollection_details = 'details'
const _golfTournamentDocument_details = 'details'
const _golfTournamentDocument_matches = 'matches'

const _allowRoundMatchCreate = true
const _allowRoundMatchUpdate = true
const _allowDetailsUpdate = true

export const fields_golferTournament = ['courseHandicaps', 'courseTeeboxes', 'tournamentHandicapIndex', 'teamNumber', 'noTrueCourseHandicap']

export const rts = {
  // handleGet_tournamentCourses: 'handleGet_tournamentCourses',
  handleChange_golferTeam: 'handleChange_golferTeam',
  handleChange_golferTournament: 'handleChange_golferTournament',
  handleCheck_eventGolfer: 'handleCheck_eventGolfer',
  handleCreate_roundMatches: 'handleCreate_roundMatches',
  handleFix_golfersTournament: 'handleFix_golfersTournament',
  handleFix_golfersTournamentHandicaps: 'handleFix_golfersTournamentHandicaps',
  handleFix_golfTournament: 'handleFix_golfTournament',
  handleFix_golfTournamentFromMatches: 'handleFix_golfTournamentFromMatches',
  handleGet_golfTournamentData: 'handleGet_golfTournamentData',
  handleGetSelectedTeamGolfers: 'handleGetSelectedTeamGolfers',
  handleGoTo_match: 'handleGoTo_match',
  handleGoTo_round: 'handleGoTo_round',
  handleLineup_init: 'handleLineup_init',
  handleLineup_select: 'handleLineup_select',
  handleLineup_sort: 'handleLineup_sort',
  handleLineup_update: 'handleLineup_update',
  handleRound_update: 'handleRound_update',
  handleShow_createRounds: 'handleShow_createRounds',
  handleUpdate_golfCourse: 'handleUpdate_golfCourse',
  handleUpdate_golfMatchHoleWinners: 'handleUpdate_golfMatchHoleWinners',
  handleUpdate_golfMatchScore: 'handleUpdate_golfMatchScore',
  // handleUpdate_lineups: 'handleUpdate_lineups',
  handleUpdate_tournamentGolfer: 'handleUpdate_tournamentGolfer',
  handleUpdate_tournamentGolfersHandicaps: 'handleUpdate_tournamentGolfersHandicaps',
  setGolfData: 'setGolfData',
  setGolfDataDetails: 'setGolfDataDetails',
  setGolfDataMatches: 'setGolfDataMatches',
  setGolfInit: 'setGolfInit',
  setGolfTournament: 'setGolfTournament',
  handleSetTournamentInfo: 'handleSetTournamentInfo',
  updateGolfers: 'updateGolfers',
  updateGolfTournamentDoc: 'updateGolfTournamentDoc',
  ...grts,
}

export const golfTournamentReducer = (state, action) => {

  const { pushSimple, pathViews, golfTournament, golfEvent_state } = state

  const { type, matchUpdate, golfData, viKey, roundKey, matches, dispatch } = action
  const { id: gtid } = golfTournament ? golfTournament : {}

  const _handlers = golfTournamentHandlers(dispatch)

  const { handleUpdate_golfCourse, setGolfDataDetails, setGolfDataMatches, handleSetTournamentInfo } = _handlers

  switch (type) {

    case rts.handleCheck_eventGolfer:
      const { item } = action
      const cg = item._itemKey === state.checkedGolfer ? null : item._itemKey
      return { ...state, checkedGolfer: cg }

    case rts.handleGet_golfTournamentData:
      if (action.existing) {
        return { ...state, ...action.existing }
      } else {
        getGolfTournamentDetails(pathViews.golfTournaments, pathViews, setGolfDataDetails, setGolfDataMatches, handleSetTournamentInfo)
      }
      return { ...state }

    case rts.handleCreate_roundMatches:
      createRoundMatchesInDatabase(gtid, pathViews, matches, roundKey, action.callback)
      return { ...state }

    case rts.handleUpdate_tournamentGolfer:
      const { golfer: _golfer } = action
      const { _itemKey } = _golfer
      if (_itemKey) {
        removeFromObject(_golfer, removeFields_golferTournament)
        deleteFromObject(_golfer, deleteFields_golferTournament)
        const dtu = {
          golfers: {
            [_itemKey]: _golfer
          }
        }
        updateGolfTournamentDetails(gtid, pathViews, { ...dtu }, action.callback)
      }
      return { ...state }

    case rts.handleUpdate_tournamentGolfersHandicaps:
      const _golfers = { ...action.golfers }
      Object.keys(_golfers).forEach(k => {
        const g = _golfers[k]
        removeFromObject(g, removeFields_golferTournament)
        deleteFromObject(g, deleteFields_golferTournament)
      })
      const dtua = {
        golfers: _golfers
      }
      updateGolfTournamentDetails(gtid, pathViews, dtua, action.callback)
      return { ...state }

    case rts.updateGolfers:
      updateGolfTournamentDetails(gtid, pathViews, { 'golfers': action.golfers }, action.callback)
      return { ...state }

    // case rts.handleUpdate_lineups:
    //   console.log('action', 'handleUpdate_lineups')
    //   updateGolfTournamentDetails(gtid, pathViews, { 'lineups': action.lineups }, action.callback)
    //   return { ...state, }

    case rts.handleLineup_update:
      const gtlu = {
        lineups: {
          [action.teamKey]: {
            [action.roundType]: action.lineup
          }
        }
      }
      updateGolfTournamentDetails(gtid, pathViews, gtlu, action.callback)
      return { ...state }

    case rts.handleRound_update:
      const docFieldRound = 'rounds.' + action.roundType + '.golfCourse'
      dispatch({ type: rts.updateSuccess, dispatch })
      updateGolfTournamentDetails(gtid, pathViews, { [docFieldRound]: action.selectedCourse }, action.callback)
      return { ...state }

    case rts.handleChange_golferTournament:
      const { lineups: lineups_changed, golfers: golfer_changed } = changeTournanemtGolfer(action, state)
      updateGolfTournamentDetails(gtid, pathViews, { 'golfers': golfer_changed, 'lineups': lineups_changed }, action.callback)
      return { ...state }

    case rts.setGolfTournament:
      return {
        golfTournaments: action.gt,
        ...state,
      }

    case rts.setGolfInit:
      const a = initGolfTournamentData(state, golfEvent_state)
      const tournamentGolfCourses = getTournamentCourses(state)
      const p = {
        ...state,
        ...a,
        tournamentGolfCourses
      }
      return {
        ...p
      }

    case rts.setGolfDataDetails:

      const { detailsData } = action
      const { mainData: t, details: d, _details: dd } = detailsData ? detailsData : {}
      const { golfers } = d ? d : {}
      if (t && t.golfCourse) {
        fs_get_data_all(pathViews, 'golfCourses', handleUpdate_golfCourse, { docKey: t.golfCourse, clientPath: true })
      }

      let playersPerTeam = 2

      if (golfers) {
        const totalGolfers = Object.keys(golfers).length
        playersPerTeam = totalGolfers / 2
        t.playersPerTeam = playersPerTeam

        Object.keys(golfers).forEach(key => {
          if (golfers[key].teamNumber) {
            golfers[key].teamNumber = parseInt(golfers[key].teamNumber)
            golfers[key].key = key
          }
        })
      }
      return { ...state, details: d, golfTournament: t, _details: dd }

    case rts.setGolfDataMatches:
      return { ...state, matches: action.matches }

    case rts.handleSetTournamentInfo:
      return { ...state, info: action.info }

    case rts.setGolfData:
      return {
        ...state,
        [viKey]: golfData,
      }

    // case rts.handleGet_tournamentCourses:
    //   const tournamentGolfCourses = getTournamentCourses(state)
    //   return { ...state, tournamentGolfCourses }

    case rts.handleUpdate_golfCourse:
      const { item: gc } = getFirstObject(action.golfCourse)
      return { ...state, golfCourse: gc }

    case rts.handleGoTo_round:
      pushSimple({ key: 'tournamentRounds' }, roundKey)
      return { ...state }

    case rts.handleGoTo_match:
      pushSimple({ key: 'golfMatches' }, action.gm._itemKey)
      return { ...state }

    case rts.handleLineup_init:
      const _selectedKeys = []
      const { lineupInfo } = action
      const { teamRoundLineup, golfers_lineup, golfers_team } = lineupInfo ? lineupInfo : {}
      if (teamRoundLineup) {
        teamRoundLineup.forEach(g => {
          _selectedKeys.push(g.golferKey)
        })
        const _golfers = getSeletedTeamGolfers(_selectedKeys, golfers_lineup, golfers_team)
        const _sorted = _golfers ? getGroupListData(_golfers, null, 2, 'group') : {}
        return { ...state, lineupInfo: { selectedKeys: _selectedKeys, golfers: _golfers, sorted: _sorted }, selectedLineupItems: _selectedKeys }
      }
      return { ...state, lineupInfo: {} }

    case rts.handleLineup_select:
      const _lineupInfo = { ...state.lineupInfo }
      _lineupInfo.selectedKeys = action.selectedKeys
      return { ...state, lineupInfo: _lineupInfo }

    case rts.handleLineup_sort:
      const _lineupInfo2 = { ...state.lineupInfo }
      _lineupInfo2.sorted = action.sortData
      return { ...state, lineupInfo: _lineupInfo2 }

    case rts.handleUpdate_golfMatchHoleWinners:
      const { golfMatch: _gm } = matchUpdate
      const _matchData = { ..._gm }
      updateMatchScore(pathViews.golfTournaments, pathViews, _matchData, action.callback)
      return { ...state }

    case rts.handleUpdate_golfMatchScore:

      const { golfMatch, holeNumber, winningTeam, playerScoring } = matchUpdate
      const matchData = { ...golfMatch }

      // create holeWinners if it does not exist
      if (!matchData.holeWinners) { matchData.holeWinners = {} }
      matchData.holeNumber = holeNumber

      // update holeWinners
      switch (winningTeam) {
        case -1:
          removeMatchScore(pathViews.golfTournaments, pathViews, matchData, holeNumber, action.callback)
          break;
        default:
          if (playerScoring) {
            if (!matchData.holeScores) { matchData.holeScores = {} }
            let _playerScoring = {}
            if (playerScoring.team1) { _playerScoring = { ...playerScoring.team1 } }
            if (playerScoring.team2) { _playerScoring = { ..._playerScoring, ...playerScoring.team2 } }
            matchData.holeScores[holeNumber] = _playerScoring
          }
          const count = Object.keys(matchData.holeWinners).length
          matchData.holeWinners[holeNumber] = { winningTeam: winningTeam, order: count }
          updateMatchScore(pathViews.golfTournaments, pathViews, matchData, action.callback)
      }

      return { ...state }

    // case golfTournamentTypes.saveGolfMatches:
    //   const { golfMatches } = action
    //   const ufPropsM = getUfProps('golfMatches', null, golfMatches, gEnums.dataUpdateTypes.updates)
    //   if (allowUpdates) firestore_handlers.updateFirestoreData(dispatch, paps_state, ufPropsM)
    //   return { ...state }



    case rts.handleChange_golferTeam:
      const { tournamentGolfers } = state
      const { player, teamIndex } = action
      const { key: playerKey } = player ? player : {}
      const tgp = tournamentGolfers[playerKey]
      tgp.teamNumber = teamIndex
      return { ...state, tournamentGolfers }

    case rts.updateGolfTournamentDoc:

      const { tournamentGolfers: tg, lineups: tl, tournamentRounds: tr, tournamentTeams: tt, golfMatches: gm, golfTournaments: g_t } = state
      const gte = { golfers: tg, lineups: tl, rounds: tr, teams: tt, matches: gm }
      const gtd = { golfers: null, lineups: null, rounds: null, teams: null, matches: null }

      if (gte.teams) { gtd.teams = gte.teams }
      if (gte.golfers) { gtd.golfers = gte.golfers }
      if (gte.lineups) {
        const _lineups = { 1: {}, 2: {} }
        Object.keys(gte.lineups).forEach(key => {
          const lineup = gte.lineups[key]
          const { tournamentTeams: team, tournamentRounds: lineup_round } = lineup ? lineup : {}
          if (lineup_round && lineup_round[0] && gte.rounds && gte.rounds[lineup_round[0]]) {
            const rt = gte.rounds[lineup_round[0]].roundType
            _lineups[team][rt] = lineup
            _lineups[team][rt].round = rt
            _lineups[team][rt].golfers = lineup.tournamentGolfers
            delete _lineups[team][rt].tournamentRounds
            delete _lineups[team][rt].tournamentGolfers
          }
        })
        gtd.lineups = _lineups
      }
      if (gte.rounds) {
        const _rounds = {}
        Object.keys(gte.rounds).forEach(key => {
          const round = gte.rounds[key]
          const { lineups } = round
          if (lineups) {
            Object.keys(lineups).forEach(tlKey => {
              lineups[tlKey] = round.roundType
            })
          }
          _rounds[round.roundType] = round
        })
        gtd.rounds = _rounds
      }
      if (gte.matches) {
        gtd.matches = gte.matches
        Object.keys(gtd.matches).forEach(matchKey => {
          const match = gtd.matches[matchKey]
          const { tournamentRounds: match_round } = match ? match : {}
          if (match_round && gte.rounds && gte.rounds[match_round]) {
            const rt = gte.rounds[match_round].roundType
            match.round = rt
            delete match.tournamentRounds
          }
        })
      }

      const { id } = g_t

      const _setRef = createRefPath_event(pathViews, [_golfTournamentCollection, id])
      fs_set_doc(_setRef, g_t, true).then(res => {
        const _detailsRef = createRefPath([_golfTournamenCollection_details, _golfTournamentDocument_details], _setRef)
        fs_set_doc(_detailsRef, gtd, true)
      })

      // gtRef.set(g_t, { merge: true }).then(res => {
      //   const gtcRef = gtRef.collection('details')
      //   gtcRef.doc('details').set(gtd, { merge: true })
      // })

      return { ...state }

    case rts.handleFix_golfTournament:
      fixTournament(state)
      return { ...state }

    case rts.handleFix_golfTournamentFromMatches:
      fixFromMatches(state)
      return { ...state }

    case rts.handleFix_golfersTournamentHandicaps:
      fixGolferHandicaps(state)
      return { ...state }

    case rts.handleFix_golfersTournament:
      fixGolfers_tournament(state)
      return { ...state }

    case rts.handleShow_createRounds:
      action.e && action.e.stopPropagation()
      return { ...state, showCreateRounds: !state.showCreateRounds }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const golfTournamentHandlers = (dispatch) => {
  return {
    handleCheck_eventGolfer: (e, item, itemChecked) => { dispatch({ type: rts.handleCheck_eventGolfer, dispatch, e, item, itemChecked }) },
    handleCreate_roundMatches: (matches, roundKey, callback) => { dispatch({ type: rts.handleCreate_roundMatches, dispatch, matches, roundKey, callback }) },
    handleGet_golfTournamentData: (existing) => { dispatch({ type: rts.handleGet_golfTournamentData, dispatch, existing }) },
    // handleGet_tournamentCourses: () => { dispatch({ type: rts.handleGet_tournamentCourses }) },
    handleChange_golferTournament: (existingGolferKey, newGolfer) => { dispatch({ type: rts.handleChange_golferTournament, existingGolferKey, newGolfer }) },
    handleChange_golferTeam: (player, teamIndex) => { dispatch({ type: rts.handleChange_golferTeam, player, teamIndex }) },
    handleFix_golfersTournamentHandicaps: () => { dispatch({ type: rts.handleFix_golfersTournamentHandicaps, dispatch }) },
    handleFix_golfersTournament: () => { dispatch({ type: rts.handleFix_golfersTournament, dispatch }) },
    handleFix_golfTournament: () => { dispatch({ type: rts.handleFix_golfTournament, dispatch }) },
    handleFix_golfTournamentFromMatches: () => { dispatch({ type: rts.handleFix_golfTournamentFromMatches, dispatch }) },
    handleGoTo_match: (gm) => { dispatch({ type: rts.handleGoTo_match, gm }) },
    handleGoTo_round: (roundKey) => { dispatch({ type: rts.handleGoTo_round, roundKey }) },
    handleLineup_init: (lineupInfo) => { dispatch({ type: rts.handleLineup_init, dispatch, lineupInfo }) },
    handleLineup_select: (selectedKeys) => { dispatch({ type: rts.handleLineup_select, dispatch, selectedKeys }) },
    handleLineup_sort: (sortData) => { dispatch({ type: rts.handleLineup_sort, dispatch, sortData }) },
    handleLineup_update: (teamKey, roundType, lineup, callback) => { dispatch({ type: rts.handleLineup_update, dispatch, teamKey, roundType, lineup, callback }) },
    handleRound_update: (roundType, selectedCourse, callback) => { dispatch({ type: rts.handleRound_update, dispatch, roundType, selectedCourse, callback }) },
    handleShow_createRounds: (e) => { dispatch({ type: rts.handleShow_createRounds, dispatch, e }) },
    handleUpdate_golfCourse: (golfCourse) => { dispatch({ type: rts.handleUpdate_golfCourse, golfCourse, dispatch }) },
    handleUpdate_golfMatchScore: (matchUpdate, callback) => { dispatch({ type: rts.handleUpdate_golfMatchScore, matchUpdate, callback, dispatch }) },
    handleUpdate_golfMatchHoleWinners: (matchUpdate, callback) => { dispatch({ type: rts.handleUpdate_golfMatchHoleWinners, matchUpdate, callback, dispatch }) },
    // handleUpdate_lineups: (lineups, callback) => { dispatch({ type: rts.handleUpdate_lineups, dispatch, lineups, callback }) },
    handleUpdate_tournamentGolfer: (golfer) => { dispatch({ type: rts.handleUpdate_tournamentGolfer, golfer, dispatch }) },
    handleUpdate_tournamentGolfersHandicaps: (golfers) => { dispatch({ type: rts.handleUpdate_tournamentGolfersHandicaps, golfers }) },
    setGolfData: (golfData, viKey) => { dispatch({ type: rts.setGolfData, golfData, viKey }) },
    setGolfDataDetails: (detailsData) => { dispatch({ type: rts.setGolfDataDetails, detailsData, dispatch }) },
    setGolfDataMatches: (matches) => { dispatch({ type: rts.setGolfDataMatches, matches, dispatch }) },
    setGolfInit: () => { dispatch({ type: rts.setGolfInit }) },
    setGolfTournament: (gt) => { dispatch({ type: rts.setGolfTournament, dispatch, gt }) },
    handleSetTournamentInfo: (info) => { dispatch({ type: rts.handleSetTournamentInfo, info, dispatch }) },
    updateGolfers: (golfers) => { dispatch({ type: rts.updateGolfers, golfers, dispatch }) },
    updateGolfTournamentDoc: () => { dispatch({ type: rts.updateGolfTournamentDoc, dispatch }) },
    ...responseHandlers(dispatch)
  }
}

export const golfTournamentInitialState = (init_state) => {
  return { ...init_state }
};

const initGolfTournamentData = (state, golfEvent_state) => {

  const { golfTournament, golfCourse, matches, details, tournamentGolfCourses } = state
  const { rounds, teams, golfers: golfers_tournament, lineups } = details

  const golfTournamentMatches = getAllMatches(rounds, matches)

  const { teams: teams_event, golfers: golfers_event, allGolfers, golfCourses } = golfEvent_state ? golfEvent_state : {}

  if (rounds && golfCourses) {
    Object.keys(rounds).forEach(rk => {
      const round = rounds[rk]
      if (round.golfCourse) {
        const rgc = golfCourses[round.golfCourse]
        if (rgc) {
          round.golfCourseName = rgc.name
        }
      }
    })
  }

  const { description } = golfTournament ? golfTournament : {}
  const { holes } = golfCourse ? golfCourse : {}
  let golfCoursePar = holes ? sumObjectProp(holes, 'par') : 72
  const missing = {}

  const _golfers = ammendGolfers(golfers_event, golfers_tournament)

  ammendMatchGolfers(golfTournamentMatches, _golfers, allGolfers, tournamentGolfCourses, rounds, missing)
  ammendLineupsWithGolfers(lineups, _golfers)

  const golfTeam1_event = teams_event ? teams_event.team1 : {}
  const golfTeam2_event = teams_event ? teams_event.team2 : {}

  const golfTeam1 = teams ? _.find(teams, { teamNumber: 1 }) : {}
  const golfTeam2 = teams ? _.find(teams, { teamNumber: 2 }) : {}

  const golfTeam_1 = teams ? _.find(teams, { teamNumber: '1' }) : {}
  const golfTeam_2 = teams ? _.find(teams, { teamNumber: '2' }) : {}

  const _golfTeam1 = golfTeam1 ? golfTeam1 : golfTeam_1
  const _golfTeam2 = golfTeam2 ? golfTeam2 : golfTeam_2

  const golfClasses = {
    active: 'status-active',
    completed: 'status-completed',
    default: 'status-default',
    neutral: 'status-neutral',
    pending: 'status-pending',
  }

  const activeStyle = { backgroundColor: 'green', color: 'white' }
  const completedStyle = { backgroundColor: '#575757', color: 'white' }
  const defaultStyle = { backgroundColor: 'grey', color: 'white' }
  const neutralStyle = { backgroundColor: 'darkGrey', color: 'white' }
  const pendingStyle = { backgroundColor: '#6e6e6e', color: 'white' }
  const removeStyle = { backgroundColor: '#ff9e81' }

  const roundColors = {
    [golfEnums.roundTypes.alternateShot]: roundColor(golfEnums.roundTypes.alternateShot),
    [golfEnums.roundTypes.alternateShot1]: roundColor(golfEnums.roundTypes.alternateShot1),
    [golfEnums.roundTypes.alternateShot2]: roundColor(golfEnums.roundTypes.alternateShot2),

    [golfEnums.roundTypes.bestBall]: roundColor(golfEnums.roundTypes.bestBall),
    [golfEnums.roundTypes.bestBall1]: roundColor(golfEnums.roundTypes.bestBall1),
    [golfEnums.roundTypes.bestBall2]: roundColor(golfEnums.roundTypes.bestBall2),

    [golfEnums.roundTypes.singles]: roundColor(golfEnums.roundTypes.singles),
    [golfEnums.roundTypes.scramble]: roundColor(golfEnums.roundTypes.scramble),
  }

  const golfTeam1Style = {
    backgroundColor: golfTeam1_event && golfTeam1_event.teamColor ? golfTeam1_event.teamColor : 'white',
    color: golfTeam1_event && golfTeam1_event.teamTextColor ? golfTeam1_event.teamTextColor : 'black',
  }

  const golfTeam2Style = {
    backgroundColor: golfTeam2_event && golfTeam2_event.teamColor ? golfTeam2_event.teamColor : 'white',
    color: golfTeam2_event && golfTeam2_event.teamTextColor ? golfTeam2_event.teamTextColor : 'black',
  }

  let roundsStatus = {}

  const ts = {
    team1Leading: 0,
    team1Virtual: 0,
    team1Wins: 0,
    team1Dormies: 0,
    team1Halved: 0,
    team1Square: 0,
    team2Leading: 0,
    team2Virtual: 0,
    team2Wins: 0,
    team2Dormies: 0,
    team2Halved: 0,
    team2Square: 0,
    matchesSquare: 0,
    matchesHalved: 0,
    tournamentComplete: true
  }

  if (rounds) {
    roundsStatus = calcGolfRounds(ts, golfTournament, rounds, matches, holes)
    if (roundsStatus) {
      Object.keys(roundsStatus).forEach(key => {
        const rs = roundsStatus[key]
        if (!rs.roundComplete) {
          ts.tournamentComplete = false
        }
      })
    } else {
      ts.tournamentComplete = false
    }
  } else {
    ts.tournamentComplete = false
  }

  const gts = {
    matchDataIsLocked: golfTournament.dataIsLocked,
    description,
    golfCourse,
    golfCoursePar,
    // golfCourseTeeBox,
    matches,
    golfTournamentMatches,
    golfers: _golfers,
    golfTeam1: _golfTeam1,
    golfClasses: golfClasses,
    golfStyles: {
      activeStyle,
      completedStyle,
      defaultStyle,
      golfTeam1Style,
      golfTeam2Style,
      neutralStyle,
      pendingStyle,
      roundColors,
      removeStyle,
    },
    golfTeam2: _golfTeam2,
    golfTournament,
    holes,
    lineups,
    roundsStatus,
    rounds,
    tournamentStatus: ts,
  }

  return gts
}

/** Get the details */
export const getGolfTournamentDetails = (gtid, pathViews, detailsCallback, matchesCallback, infoCallback) => {

  const _cb_details = (detailsData, cbProps, returnFsr, cbData) => {
    const { mainData } = cbData ? cbData : {}
    const { details } = detailsData ? detailsData : {}
    if (detailsCallback) {
      const _details = details ? global.structuredClone(details) : {}
      detailsCallback({ mainData, details, _details })
    }
  }

  const _cb_matches = (matchesData) => {
    if (matchesCallback) {
      // const { matches } = matchesData
      const matches = matchesData[_golfTournamentDocument_matches]
      matchesCallback(matches)
    } else {
      return {}
    }
  }

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid])

  fs_get_data({ refPath: _basePath, opts: { returnFirstObject: true } }).then(mainData => {

    const _detailsRef = createRefPath([_golfTournamenCollection_details, _golfTournamentDocument_details], _basePath)
    const _matchesRef = createRefPath([_golfTournamenCollection_details, _golfTournamentDocument_matches], _basePath)
    const _infoRef = createRefPath([_golfTournamenCollection_details, 'info'], _basePath)

    fs_get_data({ refPath: _detailsRef, callback: _cb_details, opts: { ignoreId: true, listen: false, cbData: { mainData } } })

    fs_get_data({ refPath: _matchesRef, callback: _cb_matches, opts: { ignoreId: true, listen: true } })

    // fs_get_data({ refPath: _matchesRef }).then(matchesData => {
    //   if (matchesCallback) {
    //     // const { matches } = matchesData
    //     const matches = matchesData[_golfTournamentDocument_matches]
    //     console.log('matches', matches)
    //     matchesCallback(matches)
    //   } else {
    //     return {}
    //   }
    // })

    fs_get_data({ refPath: _infoRef }).then(infoData => {
      if (infoCallback) {
        const { info } = infoData
        infoCallback(info)
      } else {
        return {}
      }
    })
  })
}

/** Updates the details */

/**
 * 
 * @param {string} gtid 
 * @param {object} pathViews 
 * @param {object} dataToUpdate 
 * @param {function} callback 
 */
const updateGolfTournamentDetails = (gtid, pathViews, dataToUpdate, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamenCollection_details, _golfTournamentDocument_details])


  if (_allowDetailsUpdate) {
    fs_set_doc(_basePath, dataToUpdate, true, callback)
  } else {
    callback()
  }
}

/** Updates the match score */

/**
 * 
 * @param {string} gtid 
 * @param {object} pathViews 
 * @param {object} matchData 
 * @param {function} callback 
 */
const updateMatchScore = (gtid, pathViews, matchData, callback) => {

  const { round, holeWinners, holeScores, id } = matchData

  if (holeWinners) {
    Object.keys(holeWinners).forEach(k => {
      if (holeWinners[k].winningTeam === -1) {
        holeWinners[k] = deleteField()
      }
    })
  }

  const dataToUpdate = {
    [round]: {
      [id]: { 'holeWinners': holeWinners }
    }
  }

  if (holeScores) {
    dataToUpdate[round][id].holeScores = holeScores
  }

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamenCollection_details, _golfTournamentDocument_matches])

  // console.log('_basePath', _basePath)
  // console.log('dataToUpdate', dataToUpdate)

  if (_allowRoundMatchUpdate) {
    fs_set_doc(_basePath, dataToUpdate, true, callback)
  }
}

const removeMatchScore = (gtid, pathViews, matchData, holeNumber, callback) => {

  const { round, holeScores, id } = matchData

  const dataToUpdate = {
    [round]: {
      [id]: {
        'holeWinners': {
          [holeNumber]: deleteField()
        },
      }
    }
  }

  if (holeScores) {
    dataToUpdate[round][id].holeScores = holeScores
  }

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamenCollection_details, _golfTournamentDocument_matches])

  if (_allowRoundMatchUpdate) {
    fs_set_doc(_basePath, dataToUpdate, true, callback)
  }

  // ref.set(dataToUpdate, { merge: true }).then(res => {
  //   callback && callback()
  // })
}

/** Creates the matches */
const createRoundMatchesInDatabase = (gtid, pathViews, matches, roundKey, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamenCollection_details, _golfTournamentDocument_matches])

  let _matches = {}

  if (matches) {
    if (callback) {
      Object.keys(matches).forEach((key, index) => {
        const match = matches[key]
        delete match.lowCourseHandicap
        const { team1, team2 } = match ? match : {}
        removeFromObjects(team1, removeFields_golferMatch)
        removeFromObjects(team2, removeFields_golferMatch)
        const matchId = 'match_' + roundKey + '_' + (index + 1)
        _matches[matchId] = {
          ...match,
          id: matchId
        }
      })
    } else {
      _matches = matches
    }

    const dataToUpdate = {
      [roundKey]:
        { ..._matches }
    }

    // const dataToUpdate = { [roundKey]: deleteField() }


    console.log('_basePath', _basePath)
    console.log('dataToUpdate', dataToUpdate)

    if (_allowRoundMatchCreate) {
      fs_set_doc(_basePath, dataToUpdate, true, callback)
    }

    // fs_set_doc(_basePath, dataToUpdate, true).then(res => {
    //   const data = { [roundKey]: _matches }
    //   fs_set_doc(_basePath, data, true, callback)
    // })

    // ref.set(dataToUpdate, { merge: true }).then(res => {
    //   const data = { [roundKey]: _matches }
    //   ref.set(data, { merge: true }).then(res => {
    //     callback && callback()
    //   })
    // })
  }
}

/** assigns the golferKeys to the lineups
 * @lineups obj
 * @lineup array
 */


const changeTournanemtGolfer = (action, state) => {

  const { existingGolferKey, newGolfer } = action
  const { _details } = state
  const { lineups, golfers } = _details

  const existingGolfer = golfers ? golfers[existingGolferKey] : {}
  const { teamNumber } = existingGolfer ? existingGolfer : 0
  newGolfer.teamNumber = teamNumber

  // change in lineups
  if (lineups) {
    Object.keys(lineups).forEach(lik => {
      const lineupTeam = lineups[lik]
      if (lineupTeam) {
        Object.keys(lineupTeam).forEach(k => {
          const lineupRound = lineupTeam[k]
          const existingGolfer = _.find(lineupRound, { 'golferKey': existingGolferKey })
          if (existingGolfer) {
            existingGolfer.golferKey = newGolfer._itemKey
            // existingGolfer.firstName = newGolfer.firstName
            // existingGolfer.lastName = newGolfer.lastName
            // existingGolfer.name = newGolfer.firstName + ' ' + newGolfer.lastName
          }
        })
      }
    })
  }

  delete golfers[existingGolferKey]
  golfers[newGolfer._itemKey] = newGolfer
  return { lineups, golfers }

}