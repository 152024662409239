import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Select } from 'semantic-ui-react';
import { uniqueKey } from '../common/keys';
import { createNameItemList } from '../optionLists/options';
import { DropGroupWrapper, DroppableWrapper } from './DragDropWrappers';

export const dragDropperTypes = {
  label: 'label',
  menuItemSelect: 'menuItemSelect',
  playerList: 'playerList',
  settingsGroups: 'settingsGroups',
  simple: 'simple',
  viewSettingMenu: 'viewSettingMenu',
}

/** Container for dragging and dropping
 @param ddGroups (dataItems and elements)
 */
const DragDropper = (props) => {

  const {
    ddGroups,
    ddType,
    display_propItem,
    groupChangeKey,
    handleSelected,
    handleSorted,
    headerColor,
    isLabelSort,
    noStartCase,
    positionProp,
    selectionItems,
  } = props

  const { sortFixedCount, sortGroupName } = display_propItem ? display_propItem : {}

  const _positionProp = positionProp ? positionProp : 'position'

  const groupCount = ddGroups ? Object.keys(ddGroups).length : 0

  const [portal, setPortal] = useState()

  let showHeader = true

  switch (ddType) {
    case dragDropperTypes.settingsGroups:
      showHeader = true
      break;
    default:
    // nothing
  }

  useEffect(() => {
    const p = document.createElement('div');
    p.classList.add('ddp');
    ddType && p.classList.add(ddType);
    setPortal(p)
    return () => {
      // document.body.removeChild(portal);
      // Anything in here is fired on component unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])


  const handleUpdate = (result) => {
    // console.log('result', result) 
  }

  const onDragEnd = (result) => {

    // fixed from dnd
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) { return; }

    const destinationGroup = ddGroups[destination.droppableId]
    const sourceGroup = ddGroups[source.droppableId]

    if (source.droppableId === destination.droppableId) {
      const reorderedItems = reorder(
        destinationGroup,
        source.index,
        destination.index,
        _positionProp
      );
      if (isLabelSort) {
        const roi = _.sortBy(reorderedItems, _positionProp)
        ddGroups[destination.droppableId].dataItems = roi
      } else {
        ddGroups[destination.droppableId].dataItems = reorderedItems
      }
      handleSorted && handleSorted(ddGroups)
    } else {
      moveGroup(sourceGroup, destinationGroup, source, destination, true, groupChangeKey, _positionProp)
      ddGroups[destination.droppableId] = destinationGroup
      ddGroups[source.droppableId] = sourceGroup
      handleSorted && handleSorted(ddGroups)
    }
  }

  const handleChange = (e, data) => handleSelected(e, data)

  const select = (ukh, groupKey) => selectionItems ? <Select key={uniqueKey(ukh, 's', groupKey)} groupkey={groupKey} scrolling placeholder='Select' options={createNameItemList(selectionItems)} onChange={handleChange} /> : null

  const header = (ukh, index, groupKey, group) => {

    const { menuGroup } = group ? group : {}
    const { menu_header } = menuGroup ? menuGroup : {}

    const { hdd } = group ? group : {}
    const c = sortGroupName ? groupKey.replace('group', sortGroupName) : groupKey
    return menu_header ? menu_header : <div key={uniqueKey(ukh, index)} className={'drop-group-header drd'} style={headerColor ? { backgroundColor: headerColor } : {}}>
      <div key={uniqueKey(ukh, 'cc', index)}>{noStartCase ? c : _.startCase(c)} </div>
      <div key={uniqueKey(ukh, 'hdd', index)}> {hdd}</div>
      {select(ukh, groupKey)}
    </div>
  }

  const droppableRow = (index, groupKey, group, uks) => {
    const dgwProps = { ...props, group, groupKey, header: header(uks.ukh, index, groupKey, group), index, portal, showHeader, sortFixedCount, uks }
    return <DroppableWrapper key={uniqueKey('ddr', groupKey)} dgwProps={dgwProps} />
  }

  const content = () => {
    const drps = []
    Object.keys(ddGroups).forEach((groupKey, index) => {

      const uks = {
        uk1: uniqueKey('dd', 'dg', 'd', groupKey),
        uk2: uniqueKey('dd', 'dg', 'dd', groupKey),
        ukh: uniqueKey('dd', 'dr', 'h'),
      }

      const group = ddGroups[groupKey]
      if (groupCount > 1) {
        if (group.horizontal) {
          drps.push(droppableRow(index, groupKey, group, uks))
        } else {
          const dgwProps = { ...props, group, groupKey, header: header(uks.ukh, index, groupKey, group), index, portal, showHeader, sortFixedCount, uks }
          drps.push(<DropGroupWrapper key={uniqueKey('ddw', groupKey)} dgwProps={dgwProps} />)
        }
      } else {
        drps.push(droppableRow(index, groupKey, group, uks))
      }
    })
    return drps
  }

  if (portal) {
    document.body.appendChild(portal);
    return <DragDropContext
      onDragEnd={onDragEnd}
      onDragUpdate={handleUpdate}>
      {groupCount > 0 && content()}
      {groupCount === 0 && <div>No Groups Selected</div>}
    </DragDropContext>
  }

  return <div></div>

}

export default DragDropper

const reorder = (group, sourceIndex, destinationIndex, positionProp) => {

  // this is an array
  let dataItems = group.dataItems;

  // if (!_.isArray(group.dataItems)) {
  //   dataItems = createArray(group.dataItems, keyProp)
  //   isArray = false
  // } else {
  //   dataItems = group.dataItems
  // }

  if (group.noDrop) { return Array.from(dataItems) }

  const result = _.isArray(dataItems) ? dataItems : Array.from(dataItems);

  // remove the source...
  const [removed] = result.splice(sourceIndex, 1);

  // insert the source/removed at the destinationIndex
  result.splice(destinationIndex, 0, removed);

  // add the `positionProp` for each of the items in the result
  // NOTE: the `positionProp` is the GROUP `positionProp` 
  result.forEach((r, index) => { r[positionProp] = index })

  return result

  // if (!isArray) {
  //   return createObject(result, keyProp)
  // } else {
  //   return result;
  // }
};

const moveGroup = (sourceGroup, destinationGroup, ds, dd, removeMoved, groupChangeKey, positionProp) => {

  if (destinationGroup.noDrop) { return false }
  if (sourceGroup.noMove && destinationGroup.noMove) { return false }

  // get both the source and destination dataItems
  const sourceClone = Array.from(sourceGroup.dataItems);
  const destClone = Array.from(destinationGroup.dataItems);

  if (removeMoved) {
    const [removed] = sourceClone.splice(ds.index, 1);
    destClone.splice(dd.index, 0, removed);
  } else {
    destClone.push(sourceClone[ds.index]);
  }

  // create the dataItems from the cloned data
  sourceGroup.dataItems = sourceClone;
  destinationGroup.dataItems = destClone;

  // add the `positionProp` for each of the items in the result
  if (sourceGroup.dataItems && sourceGroup.dataItems.length > 0) {
    sourceGroup.dataItems.forEach((r, index) => { r[positionProp] = index })
  }

  // add the `positionProp` for each of the items in the result
  if (destinationGroup.dataItems && destinationGroup.dataItems.length > 0) {
    destinationGroup.dataItems.forEach((r, index) => {
      if (r) {
        r[positionProp] = index
        if (groupChangeKey) { r[groupChangeKey] = dd.droppableId }
      }
    })
  }
};
