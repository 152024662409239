import { _deleteItemProps } from "../common/dataAdjust";
import { backupModes } from "../cnr/reducers/DataManagmentReducer";
import { dispatchProps } from "../cnr/reducers/reducerHelpers/dispatchProps";
import { callFunction } from "./fbAll";

const createFunctionTypes = {
  updateDataCollection: 'updateDataCollection',
  updateLinkItems: 'updateLinkItems',
  backupDataCollection: 'backupDataCollection',
  restoreDataCollection: 'restoreDataCollection',
}

const createGenericTypes = {
  createGeneric: 'createGeneric',
}
/**
 * 
 * @param {object} callData 
 * @returns - the `data` object from the function call
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(createGenericTypes.createGeneric, callData)
  return data
}

/**
 * Updates a collection of dataItems using firebase functions
 * @param {object} pathViews 
 * @param {string} collectionName 
 * @param {object} dataList 
 * @param {boolean} clearCollection 
 * @param {function} dispatch 
 * @returns 
 */
export const fsfn_updateDataCollection = async (pathViews, collectionName, dataList, updateOnly, clearCollection, dispatch) => {

  const deleteProps = _deleteItemProps

  // pathViews, collectionName, dataList, updateOnly, clearCollection, merge, deleteProps, showLogs
  const callData = { createFunctionType: createFunctionTypes.updateDataCollection, pathViews, collectionName, dataList, updateOnly, clearCollection, deleteProps, showLogs: true }
  try {
    const response_data = await callFsFunction(callData)
    const { success, error } = response_data ? response_data : {}
    dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch })
    return response_data
  } catch (error) {
    console.error(error)
    dispatch && dispatch({ type: dispatchProps.error, error })
  }
}

export const fsfn_updateLinkItems = async (pathViews, preview, updateGroupLimit, dispatch) => {

  // pathViews, collectionName, dataList, updateOnly, clearCollection, merge, deleteProps, showLogs
  const callData = { createFunctionType: createFunctionTypes.updateLinkItems, pathViews, preview, updateGroupLimit, showLogs: true }
  try {
    const response_data = await callFsFunction(callData)
    const { success, error } = response_data ? response_data : {}
    dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch })
    return response_data
  } catch (error) {
    console.error(error)
    dispatch && dispatch({ type: dispatchProps.error, error })
  }
}

export const fsfn_backupDataCollection = async (pathViews, collectionName, backupMode, dispatch) => {

  // pathViews, collectionName, dataList, updateOnly, clearCollection, merge, deleteProps, showLogs
  const createFunctionType = backupMode === backupModes.backup ? createFunctionTypes.backupDataCollection : createFunctionTypes.restoreDataCollection
  const callData = { createFunctionType: createFunctionType, pathViews, collectionName, showLogs: true }
  try {
    const response_data = await callFsFunction(callData)
    const { success, error } = response_data ? response_data : {}
    dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch })
    return response_data
  } catch (error) {
    console.error(error)
    dispatch && dispatch({ type: dispatchProps.error, error })
  }
}

