import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { getFbConfig } from '../../../../project/fbConfiguration';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from '../../../cnr/contexts/SettingsDataContext';
import TransferProvider, { TransferContext } from '../../../cnr/contexts/TranferContext';
import { settingsTransferTypes, transferDirectionTypes } from '../../../cnr/reducers/TransferReducer';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import AppForm from '../../../components/forms/appForms/AppForm';
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer';
import DcUpdatesList from '../../../components/viewers/DcUpdatesList';
import { appFormTypes } from '../../../enums/appFormTypes';
import { appIconTypes } from '../../../enums/appIconTypes';
import { listList } from '../../../lists/lists';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import UiConfirmation from '../UiConfirmation';

/** Component for Ammending Data
 * @item pageItemDataActionTypes.convertArrays
  @description updates using (dataManagement_handlers.handleUpdateCollection)
 */
export const AppTransfer = (props) => {

  const { fromDataViewer, handleCancel } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state } = states
  const { pathViews, appArea } = paps_state ? paps_state : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ? dataManagementContext : {}
  const { updating, confirmation } = dataManagement_state ? dataManagement_state : {}
  const inViewer = dataManagement_state ? true : false

  const homeSettingsContext = useContext(HomeSettingsContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const eventSettingsContext = useContext(EventSettingsContext);

  // transferContext
  const transferContext = useContext(TransferContext)
  const { tranfer_state, transfer_handlers } = transferContext ? transferContext : {}
  const { dcUpdates, formData, showConfirmation, altUser, currentSetting, currentDirection } = tranfer_state ? tranfer_state : {}
  const { appUser: altAppUser, authId: altAuthUid } = altUser ? altUser : {}

  const { userName, password, environment, appName } = formData ? formData : {}
  const _fsKey = appName && environment ? appName + '_' + environment : null

  const allowTransfer = altAppUser && currentDirection && currentSetting ? true : false

  const handleSignIn = () => {
    if (_fsKey && userName && password) {
      transfer_handlers.handleSignIn(userName, password)
    }
  }

  useEffect(() => {
    if (appName && environment) {
      const currentConfig = getFbConfig()
      transfer_handlers.handleSetFirestores(currentConfig)
    }
    // eslint-disable-next-line
  }, [appName, environment]);

  useEffect(() => {

    let _settings;
    let _settingsKey;

    const isEvent = pathViews.events
    const isClient = pathViews.clients && !isEvent

    if (isEvent) {
      const { eventSettings_state } = eventSettingsContext ? eventSettingsContext : {}
      const { eventSettings } = eventSettings_state ? eventSettings_state : {}
      _settings = eventSettings
      _settingsKey = pathViews.events
    } else if (isClient) {
      const { clientSettings_state } = clientSettingsContext ? clientSettingsContext : {}
      const { clientSettings } = clientSettings_state ? clientSettings_state : {}
      _settings = clientSettings
      _settingsKey = 'clients'
    } else {
      if (appArea === 'clients') {
        const { clientSettings_state } = clientSettingsContext ? clientSettingsContext : {}
        const { clientSettings } = clientSettings_state ? clientSettings_state : {}
        _settings = clientSettings
        _settingsKey = 'clients'
      } else {
        const { homeSettings_state } = homeSettingsContext ? homeSettingsContext : {}
        const { homeSettings } = homeSettings_state ? homeSettings_state : {}
        _settings = homeSettings
        _settingsKey = 'home'
      }
    }
    transfer_handlers.handleSetSettings(_settings, _settingsKey)
    // eslint-disable-next-line
  }, []);

  const handleCommit = () => {
    // transfer_handlers.handleKillConfirmation()
    transfer_handlers.handleCommitTransfer()
  }

  const handleShowCommit = (co) => transfer_handlers.handleShowConfirmation(co.transferType)
  const handleKillCommit = () => transfer_handlers.handleShowConfirmation()
  const handleUpdateParentData = (d) => transfer_handlers.handleUpdateFormData(d)
  const handleSettingsChange = (e, data) => transfer_handlers.handleSelectSetting(data.value)
  const handleDirectionChange = (e, data) => transfer_handlers.handleSelectDirection(data.value)

  const appForm_appLogin = () => <AppForm
    appFormType={appFormTypes.appLogin}
    parentData={formData}
    handleUpdateParentData={handleUpdateParentData}
  />

  const appForm_tranfer = () => <AppForm
    appFormType={appFormTypes.transferData}
    parentData={formData}
    handleUpdateParentData={handleUpdateParentData}
  />

  const optsList = (list) => listList(list)

  const dropdown = (options, oc, value, placeholder, iconName) => <Dropdown
    button
    className='icon thin'
    color={'blue'}
    disabled={!altAppUser}
    inverted
    icon={{ name: iconName }}
    labeled
    options={options}
    placeholder={placeholder}
    scrolling
    onChange={oc}
    text={_.startCase(value)}
    style={{ float: 'left' }}
  />

  const buttons = () => {
    const btns = [];
    const color = currentDirection === transferDirectionTypes.import ? 'orange' : 'blue'
    const icon = currentDirection === transferDirectionTypes.import ? appIconTypes.arrowLeft : appIconTypes.arrowRight

    let transferType;

    if (currentDirection && currentSetting) {
      transferType = currentDirection + _.startCase(currentSetting)
      transferType = transferType.replace(' ', '')
    }

    let caption = transferType ? _.startCase(transferType) : '---'

    btns.push({ oc: handleShowCommit, color, caption, icon, disabled: !allowTransfer, clickOptions: { transferType } })

    return <UiSaveButtons
      others={btns}
      dropdowns={dropdowns()}
      color={'blue'}
      rows={2}
    />
  }

  const dropdowns = () => {
    return [
      dropdown(optsList(transferDirectionTypes), handleDirectionChange, currentDirection, 'Transfer Direction', 'arrows alternate horizontal'),
      dropdown(optsList(settingsTransferTypes), handleSettingsChange, currentSetting, 'Transfer Type', 'setting'),
    ]
  }

  const dcUpdateList = () => <DcUpdatesList dcUpdates={dcUpdates} />

  const header = () => <div><Icon name={altAuthUid ? 'check circle outline' : 'circle outline'} color={altAuthUid ? 'green' : 'grey'} /></div>

  const formContent = () => <React.Fragment>
    {appForm_appLogin()}
    <Button onClick={() => { handleSignIn() }} style={{ marginLeft: '2em' }}>Sign In</Button>
    {appForm_tranfer()}
  </React.Fragment>

  const container = () => <Wrapper
    header={header()}
    content={formContent()}
    footer={buttons()}
    wrapperType={wrapperTypes.paddedHeader}
    updating={updating}
    confirmation={confirmation}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={container()}
    handleCancel={handleCancel}
    topperCaption={'Transfer Data'}
  />

  if (showConfirmation && !dcUpdates) {
    const confirmationProps = {
      caption: 'Confirmation',
      text: 'Are you sure you want to ' + _.startCase(showConfirmation) + ' ?',
      updateCaption: _.startCase(showConfirmation)
    }
    return <UiConfirmation {...confirmationProps} handleUpdate={handleCommit} handleCancel={handleKillCommit} useFull={true} />
  } else {
    if (dcUpdates) {
      return dcUpdateList()
    } else {
      return inViewer || fromDataViewer ? container() : fullPageWrapper()
    }
  }

}

export const AppTransferWithProvider = (props) => {
  return <TransferProvider >
    <AppTransfer {...props} />
  </TransferProvider>
}