import _ from 'lodash'
import React, { useContext } from 'react'
import { Icon } from 'semantic-ui-react'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { formatTimeAmPm } from '../common/dateFormatting'
import { uniqueKey } from '../common/keys'
import NoData from '../components/alerts/NoData'
import { gEnums } from '../enums/globalEnums'
import { AppNotificationsViewerContext } from './AppNotifications'

const timeZones = {
  america_newYork: 'America/New_York',
  america_losAngeles: 'America/Los_Angeles',
  europe_london: 'Europe/London',
}

const NotificationList = (props) => {

  const { type, notifications_date } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states

  // appNotificationViewerContext
  const appNotificationViewerContext = useContext(AppNotificationsViewerContext)
  const { appNotificationsViewer_handlers, appNotificationsViewer_state } = appNotificationViewerContext ? appNotificationViewerContext : {}
  const { activeNotificationKey } = appNotificationsViewer_state ? appNotificationsViewer_state : {}
  const { handleSetActiveNotificationKey } = appNotificationsViewer_handlers ? appNotificationsViewer_handlers : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appNotifications } = aps_global ? aps_global : {}
  const { notificationDeliveryType } = appNotifications ? appNotifications : {}

  /**
   * Set the active notificationKey to the AppNotifications component
   * @param {string} id 
   */
  const handleNoteClick = (id) => handleSetActiveNotificationKey(id)

  const noteIcon = (id, index, name, color, title, oc, note) => <Icon key={uniqueKey('apn', 'ni', id, index)} name={name} color={color} title={title} onClick={(e) => oc && oc(e, note)} />

  const pntIcon = (ndt) => {
    switch (ndt) {
      case gEnums.notificationDeliveryTypes.inApp:
        return 'tv'
      case gEnums.notificationDeliveryTypes.email:
        return 'mail'
      case gEnums.notificationDeliveryTypes.push:
        return 'fire'
      case gEnums.notificationDeliveryTypes.sms:
        return 'mobile alternate'
      default:
        break;
    }
  }

  /** a Group of icons next to the Name of notification */
  const headerNotificationsIcons = (id, type, sent, active, ndt, hasSub) => <React.Fragment>
    {noteIcon(id, 1, sent ? 'check circle' : 'check', sent ? 'green' : 'grey', 'Sent')}
    {noteIcon(id, 2, 'check square outline', active ? 'green' : 'grey', 'Active')}
    {noteIcon(id, 3, type === gEnums.notificationScheduleTypes.direct ? 'hand point right outline' : 'time', 'grey', 'Send Type')}
    {noteIcon(id, 4, pntIcon(ndt), 'grey', 'Delivery Type')}
    {hasSub && noteIcon(id, 5, 'users', 'green', 'Users')}
  </React.Fragment>

  /**
   * 
   * @returns a list of notifications from the notifications_date array
   */
  const notificationList = () => {

    const lis = []

    const currentDate = new Date()
    const localDate = currentDate.toLocaleString('en-US', { timeZone: timeZones.america_newYork })
    const localTime = new Date(localDate).getTime()

    const _notifications_date = notifications_date ? _.sortBy(notifications_date, 'sendTime') : notifications_date

    _notifications_date.forEach((notification, index) => {

      const { id, title, sendDate, sendTime, sent, active, notificationDeliveryType: notificationDeliveryType_note, targetOptions } = notification
      const { subKey, subItem, subKeys } = targetOptions ? targetOptions : {}

      const sendDateAndTimeUtc = sendDate && sendTime ? new Date(sendDate + ' ' + sendTime) : null
      const noteTime = sendDateAndTimeUtc ? sendDateAndTimeUtc.getTime() : 0
      const diff = localTime - noteTime

      const ndt = notificationDeliveryType_note ? notificationDeliveryType_note : notificationDeliveryType
      const isActive = activeNotificationKey === id
      const hasSub = subItem && (subKey || subKeys) ? true : false
      let cnn = 'app-notification-container'
      if (isActive) { cnn += ' active' }
      if (sent) { cnn += ' sent' }
      if (diff >= 0) {
        if (!sent) {
          cnn += ' past-ns'
        } else {
          cnn += ' past'
        }
      }
      lis.push(
        <div className={cnn} key={uniqueKey('apn', index)} noteid={id} onClick={() => handleNoteClick(id)}>
          <div key={uniqueKey('apn', index, 1)}>{title}</div>
          <div key={uniqueKey('apn', index, 2)}><Icon name={'clock outline'} /> {type === gEnums.notificationScheduleTypes.scheduled ? formatTimeAmPm(sendTime) : 'Direct'} </div>
          <div key={uniqueKey('apn', index, 3)}>{headerNotificationsIcons(id, type, sent, active, ndt, hasSub)}</div>
          <div key={uniqueKey('apn', index, 4)}>{id}</div>
        </div>
      )
    })
    return lis.length > 0 ? lis : <NoData altCaption={_.startCase(type)} />
  }

  return notificationList()

}

export default NotificationList