import _ from 'lodash';
import { hashmark, useHash } from '../../../App';
import { gEnums } from '../../enums/globalEnums';
import { allPageTypes } from '../../enums/allPageTypes';
import { getBaseRef } from '../../firestoreData/helpers/getBaseRef';
import { getPathViews, getSearchPath } from '../../redirection/current';
import { getAreaSettingsProps } from '../../viewSettings/helpers/pageSettings';

export const pageInitialState = (page_init) => {
  const { homeAppUrls } = page_init
  return {
    homeAppUrls,
  }
};

const rts = {
  getGviDeps: 'getGviDeps',
  goBackTo: 'goBackTo',
  handleAmmendPageSettings: 'handleAmmendPageSettings',
  handleGetPageSettings: 'handleGetPageSettings',
  handlePageNav: 'handlePageNav',
  handleUpdatePageDataCaption: 'handleUpdatePageDataCaption',
  pushDrillDown: 'pushDrillDown',
  pushSimple: 'pushSimple',
  pushUrl: 'pushUrl',
  syncViewItems: 'syncViewItems',
}

export const pageReducer = (state, action) => {

  switch (action.type) {
    case rts.handleGetPageSettings:
      const _pss = getAreaSettingsProps(action.aspProps)
      return { ...state, pageSettings: _pss, aspProps: action.aspProps }

    case rts.handleAmmendPageSettings:
      const { appSettings_google } = action
      const { aps_viewItems, aps_views } = appSettings_google ? appSettings_google : {}
      const _aspProps = { ...state.aspProps }
      const { pss } = _aspProps ? _aspProps : {}
      const { area_settings } = pss ? pss : {}
      const { viewItems, views } = area_settings ? area_settings : {}
      area_settings.viewItems = { ...viewItems, ...aps_viewItems }
      area_settings.views = { ...views, ...aps_views }
      const _pss2 = getAreaSettingsProps(_aspProps)
      return { ...state, pageSettings: _pss2 }

    case rts.handleUpdatePageDataCaption:
      return { ...state, pageDataCaption: action.pageDataCaption, pageDataSubCaption: action.pageDataSubCaption }

    case rts.handlePageNav:
      const _pageNav = {
        navOptions: action.navigationOptions,
        navUiItemContext: action.uiItemContext
      }
      return { ...state, pageNav: _pageNav }

    case rts.getGviDeps:
      return { ...state }

    default:
      return { ...state }
  }
}

export const pageHandlers = (dispatch) => {
  return {
    handleAmmendPageSettings: (appSettings_google) => dispatch({ type: rts.handleAmmendPageSettings, appSettings_google }),
    handlePageNav: (navigationOptions, uiItemContext) => dispatch({ type: rts.handlePageNav, navigationOptions, uiItemContext }),
    handleGetPageSettings: (aspProps) => dispatch({ type: rts.handleGetPageSettings, aspProps }),
    handleUpdatePageDataCaption: (pageDataCaption, pageDataSubCaption) => dispatch({ type: rts.handleUpdatePageDataCaption, pageDataCaption, pageDataSubCaption }),
  }
}

/** returns a set of function for the page */
export const pageFunctions = (page_state) => {
  return {
    getGviDeps: (aps_viewItems, uivi, viewKey, aps_page) => getGviDeps(aps_viewItems, uivi, viewKey, aps_page),
    getViRef: (aps_viewItems, uivi, viewKey, pathViews, aps_page) => getViRef(aps_viewItems, uivi, viewKey, pathViews, aps_page),
    goBackTo: (navigate, count) => goBackTo(navigate, count),
    pushDrillDown: (e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps) => pushDrillDown(e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps),
    pushSimple: (psProps) => truePushSimple(psProps),
    pushUrl: (e, url) => pushUrl(e, url),
    syncViewItems: (gvs, vis, vs) => syncViewItems(gvs, vis, vs),
    getVi: (vi, isGlobal) => getVi(vi, isGlobal, page_state),
  }
}

const getViRef = (aps_viewItems, uivi, viewKey, pathViews, aps_page) => {
  const viDeps = getGviDeps(aps_viewItems, uivi, viewKey, aps_page)
  const baseRef = getBaseRef(viDeps, pathViews)
  return baseRef
}

const getVi = (vi, isGlobal, page_state) => {
  const { pageSettings } = page_state ? page_state : {}
  const { aps_page, aps_viewItems } = pageSettings ? pageSettings : {}
  const { viewItems } = aps_page ? aps_page : {}
  return isGlobal ? aps_viewItems[vi] : viewItems[vi]
}

const getGviDeps = (aps_viewItems, uivi, viewKey, aps_page) => {

  const viewItem_page = aps_page && aps_page.viewItems && aps_page.viewItems[uivi] ? aps_page && aps_page.viewItems && aps_page.viewItems[uivi] : null;
  const viewItem_app = aps_viewItems && aps_viewItems[uivi] && aps_viewItems[uivi] ? aps_viewItems[uivi] : null

  // dataSource 
  const { dataConstraints, dataSource } = viewItem_page ? viewItem_page : {}
  const _dataConstraints = dataConstraints ? dataConstraints : dataSource
  const { dataParents: dataParents_page, useSeasonals, dataLinkType, useSubDataCollection, subDataCollectionName } = _dataConstraints ? _dataConstraints : {}
  const { dependencies, documentDependencies, dataParents } = viewItem_app && viewItem_app.dataConnection ? viewItem_app.dataConnection : {}

  if (dependencies || dataParents || dataParents_page) {
    const dpp = dataParents_page ? dataParents_page : dataParents
    return { dependencies, documentDependencies, dataParents: dpp, useSeasonals, dataLinkType, useSubDataCollection, subDataCollectionName }
  } else {
    let deps = null
    switch (uivi) {
      case allPageTypes.home:
        break;

      case allPageTypes.clients:
        if (viewKey) {
          deps = [allPageTypes.clients]
        }
        break;

      case allPageTypes.events:
        deps = [allPageTypes.clients]
        break;

      default:
        // deps = null
        deps = [allPageTypes.clients, allPageTypes.events]
        break;
    }

    return { dependencies: deps, documentDependencies, dataParents: null, useSeasonals, dataLinkType, useSubDataCollection, subDataCollectionName }
  }
}

/** Returns the new path for a clicked item */
const getNewPath = (aps_viewItems, aps_page, paps_state, itemView, itemKey, modifyMode, landingView, pathNameH) => {

  const { mainViews, lastRootPath, lastPathName } = paps_state ? paps_state : {}

  const pathViews = getPathViews(pathNameH)

  let basePath = '';
  let newPath = '';

  switch (itemView) {

    case 'home':
      newPath = '/'
      break;

    case 'allClients':
      newPath = '/clients'
      break;

    case 'client':
      newPath = '/clients/' + pathViews['clients']
      break;

    default:

      if (modifyMode) {

        switch (lastPathName) {
          case landingView:
            newPath = lastRootPath
            break;
          default:
            newPath = pathNameH
        }

        switch (modifyMode) {
          case 'add':
            newPath += '/' + modifyMode
            break;
          case 'edit':
            newPath += '/' + modifyMode
            break;
          case 'viewSettings':
            newPath += '/' + modifyMode
            break;
          case 'qrCode':
            newPath += '/qrCode'
            break;
          case 'notifications':
            newPath += '/notifications'
            break;
          default:
          // nothing
        }

      } else {

        const x = getGviDeps(aps_viewItems, itemView, null, aps_page)
        const { dependencies, documentDependencies, dataParents } = x
        const pvKeys = Object.keys(pathViews)

        if (dependencies && pathViews) {
          Object.keys(dependencies).forEach(key => {
            const dep = dependencies[key]
            if (pathViews[dep]) {
              basePath += '/' + dep + '/' + pathViews[dep]
            }
          })
        }

        if (documentDependencies) {
          documentDependencies.forEach(dd => {
            if (pvKeys.includes(dd)) {
              basePath += '/' + dd + '/' + pathViews[dd]
            }
          })
        }

        // IMPORTANT: add path for dataParents
        if (dataParents && pathViews) {
          Object.keys(dataParents).forEach(key => {
            const dep = dataParents[key]
            if (pathViews[dep]) {
              basePath += '/' + dep + '/' + pathViews[dep]
            }
          })
        }

        newPath = basePath

        if (itemView) {
          newPath += '/' + itemView
          if (itemKey) {
            newPath += '/' + itemKey
            if (mainViews.includes(itemView)) {
              newPath += '/' + landingView
            }
          }
        }
      }
  }

  return newPath

}

const goBackTo = (navigate, count) => {
  if (count) {
    navigate(-(count))
  } else {
    navigate(-1)
  }
}

// const pushSignIn = (navigate) => navigate('/signIn', '')

// IMPORTANT: truePushSimple
const truePushSimple = (psProps) => {
  const {
    appUserAccess,
    aps_viewItems,
    aps_page,
    homeSettingsContext,
    papsContext,
    clickedItem,
    itemKey,
    modifyMode,
    opts,
    ddn,
    ddProps,
    ddGroupIndex,
    weatherZip
  } = psProps
  // item edits and add handled from `dataReducer`    

  let { key: itemView, drillDown, itemClick } = clickedItem ? clickedItem : {}
  let { clickToType, appType, url: click_url, clickToPage: click_toPage } = itemClick ? itemClick : {}

  const clickTos = {
    app: false,
    detect: false,
    noClick: false,
    page: false,
    pdf: false,
    url: false,
  }

  switch (clickToType) {
    case gEnums.clickToTypes.app:
      clickTos.app = true
      break;
    case gEnums.clickToTypes.detectApp:
      clickTos.detect = true
      break;
    case gEnums.clickToTypes.noClick:
      clickTos.noClick = true
      break;
    case gEnums.clickToTypes.normal:
      break;
    case gEnums.clickToTypes.page:
      clickTos.page = true
      break;
    case gEnums.clickToTypes.pdf:
      clickTos.pdf = true
      break;
    case gEnums.clickToTypes.url:
      clickTos.url = true
      break;
    default:
    // nothing
  }

  // LOOK
  if (clickTos.noClick && appUserAccess && (appUserAccess.accessLevel <= gEnums.accessLevels.admin)) { return false }

  const { homeSettings } = homeSettingsContext ? homeSettingsContext : {}
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { appUrls: homeAppUrls } = global_home ? global_home : {}

  const { hash, pathname } = window.location
  const pathNameH = useHash ? hash.replace(hashmark + '/', '/') : pathname

  const { paps_state, navigate } = papsContext
  const { pathName, pathViews, landingView } = paps_state ? paps_state : {}

  if (clickTos.app && appType) { if (openApp(paps_state, appType, homeAppUrls, weatherZip)) { return false } }

  if (clickTos.detect && opts && opts.itemData && opts.itemData.url) {
    openAppUrl(opts.itemData.url)
    return false
  }

  if (clickTos.detect && opts && opts.itemData) {
    const { url, website } = opts.itemData
    if (url || website) {
      const openUrl = url ? url : website
      openAppUrl(openUrl)
      return false
    }
  }

  if (clickTos.url && click_url) {
    openAppUrl(click_url)
    return false
  }

  const { noClick: onc, itemData, directPath } = opts ? opts : {}

  if (onc) { clickTos.noClick = true }
  if (itemData && itemData.as && itemData.as !== itemView) { itemView = itemData.as }
  if (clickTos.page && click_toPage) { itemView = click_toPage }

  let newPath = ddProps ? pathName : getNewPath(aps_viewItems, aps_page, paps_state, itemView, itemKey, modifyMode, landingView, pathNameH)

  if (opts && opts.pageExt) { newPath += '/' + opts.pageExt }

  window.localStorage.removeItem('isBack')

  switch (newPath) {
    case '/landing':
      newPath = '/'
      break;
    default:
    // nothing
  }

  // IMPORTANT: Push
  if (directPath) {
    navigate(directPath)
  } else {
    if (drillDown && drillDown.useDrillDown && opts) {
      let newDDGroupIndex = ddGroupIndex ? ddGroupIndex + 1 : 2
      navigate(newPath + '/ddg/' + newDDGroupIndex)
    } else if (ddn) {
      navigate(getSearchPath(newPath, ddProps, ddn))
    } else {
      if (pathNameH !== newPath) {
        localStorage.setItem('lastPath', pathName);
        localStorage.setItem('routeDepth', Object.keys(pathViews).length);
        navigate(newPath)
      } else {
      }
    }
  }
}

const openApp = (paps_state, appType, homeAppUrls, weatherZip) => {

  const { device } = paps_state ? paps_state : {}
  const { name: deviceName } = device ? device : {}

  if (appType && homeAppUrls.appUrls) {

    const urlProps = _.find(homeAppUrls.appUrls, { 'appName': appType })

    if (urlProps) {

      let url;

      switch (deviceName) {
        case gEnums.deviceNames.desktop:
          if (urlProps.appDesktopUrl) {
            url = urlProps.appDesktopUrl
          } else {
            url = urlProps.appUrl
          }
          break;
        default:
          url = urlProps.appUrl
      }

      switch (appType) {
        case 'weather':
          if (weatherZip) {
            url = url.replace('ZIPCODE', weatherZip)
          }
          break;
        default:
        // nothing
      }
      if (url) {
        window.open(url, '_blank')
        return true
      }
    }
  }
}

/** Opens an appUrl in another window */
const openAppUrl = (appUrl) => { window.open(appUrl, '_blank') }

const pushDrillDown = (aps_viewItems, e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps) => {
  e.stopPropagation()
  const ddn = {
    key: propItem.key,
    value: pvKey
  }
  truePushSimple(aps_viewItems, homeSettingsContext, papsContext, viewItem, itemData.id, null, null, ddn, ddProps)
}

const pushUrl = (e, url) => {
  if (url.indexOf('https://') < 0) { url = 'https://' + url }
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
  window.open(url, '_blank');
}

const syncViewItems = (gvs, vis, vs) => {
  const { viewItems } = vs ? vs : {}
  if (vis && viewItems) {
    Object.keys(viewItems).forEach(key => {
      const vi = viewItems[key]
      const gvi = vis[key]
      if (gvi) {
        if (!vi.key) { vi.key = key }
        if (!gvi.key) { gvi.key = key }
        if (vi && gvi) {
          Object.keys(vi).forEach(k => {
            const vii = vi[k]
            const gii = gvi[k]
            if (vii && gii && _.isObject(vii) && _.isObject(gii) && Object.keys(vii).length > 0 && Object.keys(gii).length > 0) {
              switch (k) {
                case 'display':
                  vii.caption = vii.caption ? vii.caption : gii.caption
                  vii.defaultIcon = gii.defaultIcon
                  break;
                // case 'ui':
                //   vii.caption = vii.caption ? vii.caption : gii.caption
                //   vii.defaultIcon = gii.defaultIcon
                //   break;
                default:
                // nothing
              }
            }
          })
        }
      }

    })
  }
}