import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { getFirstObject } from '../../common/convert';
import { SettingsViewerContext } from '../../components/viewers/FullSettingsViewer';
import { gEnums } from '../../enums/globalEnums';
import { createPropsFromData } from '../../viewSettings/helpers/settingsHelpers';
import { getAllowPopups, itemHandlers, itemInitialState, itemReducer, updateViewItem } from '../reducers/ItemReducer';
import { DataContext } from './DataContext';
import { FilterContext } from './FilterContext';
import { PageAreaContext } from './PageAreaContext';
import { PageAreaParentContext } from './PageAreaParentContext';
import { ParentContext } from './ParentContext';
import { AppUsersContext } from './AppUsersContext';

/** 
 @provides itemAction_handlers
 @provides isCombinedDatea
 @provides uiFilter_handlers
 @provides uiFilter_state
 @provides uiItemsStyle
 @provides updates
 @provides view
 @provides viewItem
 @provides viewListData 
 */

export const UiItemContext = createContext();

const UiItemProvider = (props) => {

  const useMerge = false

  // ParentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state } = states
  const { appUser_fns, eventSettings_fns, page_fns } = fns ? fns : {}
  const { pageSettings } = page_state ? page_state : {}
  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging, permissionsConsole } = homeSettings_global ? homeSettings_global : {}
  const { dataManagementSettings } = permissionsConsole ? permissionsConsole : {}
  const { aps_global, aps_page, aps_viewItems, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // appUsersContext
  const appUsersContext = useContext(AppUsersContext)
  const { appUsers_state } = appUsersContext ? appUsersContext : {}
  const { appUserProfiles } = appUsers_state ? appUsers_state : {}

  // pageAreaParentContext
  const pageAreaParentContext = useContext(PageAreaParentContext)
  const { states: parent_states_p } = pageAreaParentContext ? pageAreaParentContext : {}
  const { framework_state } = parent_states_p

  // papsContext  
  const { pathViews, view, viewKey } = paps_state ? paps_state : {}

  const viewItem_aps = aps_viewItems ? aps_viewItems[view] : null
  const { dataFilters } = viewItem_aps ? viewItem_aps : {}

  // frameworkContext 
  const { desktopMode } = framework_state ? framework_state : {}

  // settingsViewerContext
  const settingsViewerContext = useContext(SettingsViewerContext)
  const { settingsViewer_state } = settingsViewerContext ? settingsViewerContext : {}
  const { viewItem_preview } = settingsViewer_state ? settingsViewer_state : {}

  // pageContext 
  const { appAccess, desktop, display: display_global } = aps_global ? aps_global : {}
  const { displaySingleItemsAsSingle, showDefaultIconsInDisplay, showDefaultIconsInDisplayIfNull, defaultIconColor } = display_global ? display_global : {}
  const { appAccessType } = appAccess ? appAccess : {}
  const { itemWrapType } = desktop ? desktop : {}
  let { layout, desktopLayout } = aps_page ? aps_page : {}
  const _layout = desktopMode ? desktopLayout : layout
  const { layoutType } = _layout

  // pageAreaContext
  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ? pageAreaContext : {}
  const { pageItemsShown, pageViewItemKeys } = pageArea_state ? pageArea_state : {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ? dataContext : {}
  const { isCombinedData, uivi, viewListData, time_stamp, alphabetValue, excludedData } = data_state ? data_state : {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_state } = filterContext ? filterContext : {}
  const { filterStatusType } = uiFilter_state ? uiFilter_state : {}
  const { filterSidebarOpen, divideIntoGroups, filterValues, searchValue, alphabetActive, alphaValue, groupDataProps, selectedFilters } = uiFilter_state ? uiFilter_state : {}

  let { selectedTab } = uiFilter_state ? uiFilter_state : {}
  const { groups } = groupDataProps ? groupDataProps : {}

  // Now for the viewItem  
  let viewItem = pageItemsShown ? pageItemsShown[uivi] : {}
  if (viewItem_preview) { viewItem = viewItem_preview }

  let isPublicSite = false

  switch (appAccessType) {
    case gEnums.siteAuthorizationTypes.public:
    case gEnums.siteAuthorizationTypes.passcode:
      isPublicSite = true
      break;
    default:
    // nothing
  }

  const uviProps = {
    aps_global, appUser, desktopMode, groupDataProps, pageViewItemKeys,
    pathViews, isPublicSite, selectedTab, viewItem, viewKey,
    appUser_fns, display_global
  }

  const { display, desktopDisplay, filter, search, grouping } = viewItem ? viewItem : {}
  let _display = desktopMode && desktopDisplay ? desktopDisplay : display

  const { caption, displayType } = _display ? _display : {}

  const { showFilter } = filter ? filter : {}
  const { showSearch, searchOnPage } = search ? search : {}

  if (groupDataProps && groupDataProps.defaultTab && !selectedTab) { selectedTab = groupDataProps.defaultTab }

  const getInitState = () => {

    const _filterOpts = { alphaValue, alphabetActive, divideIntoGroups, filterSidebarOpen, filterStatusType, filterValues, groups, searchValue, selectedFilters, dataFilters }
    const _pageOpts = { _display, itemWrapType, layout, layoutType }
    const _viewItemOpts = { caption, displayType, ...grouping, searchOnPage, showFilter, showSearch }
    const _dataOpts = { alphabetValue, isCombinedData, time_stamp, uivi, viewListData }
    const { item: _dataItem } = viewListData ? getFirstObject(viewListData) : {}
    const dataItemCount = viewListData ? Object.keys(viewListData).length : 0

    const { email } = _dataItem ? _dataItem : {}

    const { showOverview, appUserOverviewPages } = aps_appUserSettings ? aps_appUserSettings : {}

    if (showOverview && appUserProfiles && appUserProfiles[email]) {
      const appUserProfile = appUserProfiles[email]
      const { bio, aboutMe, appUserPrivacy } = appUserProfile ? appUserProfile : {}
      const { hideBioFromApp, hideAboutMeFromApp } = appUserPrivacy ? appUserPrivacy : {}
      if (!hideAboutMeFromApp && appUserOverviewPages.includes(gEnums.appUserProfilePageTypes.appUserAboutMe) && aboutMe) { _dataItem.aboutMe = aboutMe }
      if (!hideBioFromApp && appUserOverviewPages.includes(gEnums.appUserProfilePageTypes.appUserBio) && bio) { _dataItem.bio = bio }
      if (appUserOverviewPages.includes(gEnums.appUserProfilePageTypes.appUserGallery)) { _dataItem.gallery = 'gallery' }
    }

    // converts the cardDisplayType to normal IF...
    if (displaySingleItemsAsSingle && dataItemCount === 1 && !displayType) {
      switch (displayType) {
        case gEnums.displayTypes.card:
          _display.cardDisplayType = gEnums.cardDisplayTypes.normal
          break;
        default:
        // nothing
      }
    }

    const { name, firstName, lastName } = _dataItem ? _dataItem : {}
    const dataName = lastName ? firstName + ' ' + lastName : name

    if (useMerge) {
      eventSettings_fns.mergeViewItemProps(viewItem)
    }

    const viewItem_key = viewItem ? viewItem.key : null
    const singleDataItem = (view === viewItem_key) && viewKey && dataItemCount === 1 ? true : false
    const viewItemIsView = (view === viewItem_key) && !viewKey ? true : false

    const { props: props_vig } = viewItem_aps ? viewItem_aps : {}

    const setIcon = (pvi, icon) => {
      let allow = false
      if (icon && icon !== 'file alternative') {
        if (showDefaultIconsInDisplay) {
          allow = true
        } else if (showDefaultIconsInDisplayIfNull) {
          allow = pvi.display.icon ? false : true
        }
        if (allow) {
          pvi.display.icon = icon
          pvi.display.defaultIconColor = defaultIconColor
        }
      }
    }

    const getProps_viewItem = () => {

      let { props: props_vi } = viewItem ? viewItem : {}

      // if the props for the viewItem do not exist, get them from the global viewItem
      if (!props_vi || Object.keys(props_vi).length === 0) {
        if (props_vig) {
          props_vi = props_vig
        }
      }

      // if the props for the viewItem do not exist, get them from the viewListData
      if (viewListData && (!props_vi)) {
        props_vi = createPropsFromData(viewListData)
      }

      if (_.isArray(props_vi)) {
        props_vi.forEach(sp => {
          sp.show = true
        })
      }
      return props_vi
    }

    const props_viewItem = getProps_viewItem()

    if (props_viewItem && (showDefaultIconsInDisplay || showDefaultIconsInDisplayIfNull)) {
      Object.keys(props_viewItem).forEach(propKey => {
        const pvi = props_viewItem[propKey]
        if (pvi && pvi.tagged && pvi.display) {
          const prop_vig = props_vig ? props_vig[propKey] : {}
          const { display: display_prop } = prop_vig ? prop_vig : {}
          const { icon: icon_prop } = display_prop ? display_prop : {}

          const vi_g = aps_viewItems ? aps_viewItems[propKey] : {}
          const { display: display_vi_g } = vi_g ? vi_g : {}
          const { defaultIcon } = display_vi_g ? display_vi_g : {}

          const _icon = icon_prop ? icon_prop : defaultIcon

          setIcon(pvi, _icon)

        }
      })
    }

    const getProper = (option, altOption) => {
      const propArray = []
      const props = altOption ? _.filter(props_viewItem, i => ((i[option] === true) && (i[altOption] !== true))) : _.filter(props_viewItem, { [option]: true })
      if (props) { props.forEach(i => propArray.push(i.key)) }
      return {
        props,
        propArray
      }
    }

    const getProperNamed = () => {
      const propArray = []
      const props = _.filter(props_viewItem, i => i.propSection === 'name' && i.tagged === true);
      if (props) { props.forEach(i => propArray.push(i.key)) }
      return {
        props,
        propArray
      }
    }

    const getProperTagged = () => {
      const propArray = []
      const props = _.filter(props_viewItem, i => i.propSection !== 'name' && i.tagged === true);
      if (props) { props.forEach(i => propArray.push(i.key)) }
      return {
        props,
        propArray
      }
    }

    const getProperSchedule = () => {
      const propArray = []
      const props = _.filter(props_viewItem, i => ((i.key === 'startDate') || (i.key === 'endDate') || (i.key === 'startTime') || (i.key === 'endTime')));
      if (props) { props.forEach(i => propArray.push(i.key)) }
      return {
        props,
        propArray
      }
    }

    const getAllArrays = () => {
      let _allArrays = []
      Object.keys(propGroups).forEach(key => {
        switch (key) {
          case 'header':
          case 'meta':
          case 'pin':
          case 'schedule':
          case 'tagged':
            if (propGroups[key] && propGroups[key].propArray) {
              _allArrays = [..._allArrays, ...propGroups[key].propArray]
            }
            break;
          default:
          // nothing
        }
      })
      return _allArrays
    }

    const propGroups = {
      schedule: getProper(gEnums.menuItemOptionTypes.schedule),
      header: getProper(gEnums.menuItemOptionTypes.header),
      meta: getProper(gEnums.menuItemOptionTypes.meta),
      pin: getProper(gEnums.menuItemOptionTypes.pin),
      show: getProper(gEnums.menuItemOptionTypes.show),
      tagged: getProperTagged(gEnums.menuItemOptionTypes.tagged),
      address: getProperTagged(gEnums.menuItemOptionTypes.address),
      contact: getProperTagged(gEnums.menuItemOptionTypes.contact),
      propArrays: []
    }

    if (propGroups && propGroups.header && propGroups.header.propArray.length === 0) { propGroups.header = getProperNamed() }
    if (propGroups && propGroups.schedule && propGroups.schedule.propArray.length === 0) { propGroups.schedule = getProperSchedule() }

    propGroups.propArrays = getAllArrays()

    let allowAppUserEdit;

    if (appUser_fns.validateAccess_eventUserPage(pathViews)) { allowAppUserEdit = true }

    const _allowPopups = getAllowPopups(layoutType, filterStatusType, viewItem_preview)

    return {
      _display,
      allowAppUserEdit,
      allowPopups: _allowPopups,
      appUser_fns,
      appUserAccess,
      dataItem: _dataItem,
      itemData: _dataItem,
      dataItemCount,
      dataName,
      dataOpts: _dataOpts,
      excludedData,
      filterOpts: _filterOpts,
      logging,
      pageOpts: _pageOpts,
      paps_state,
      page_fns,
      props_viewItem,
      propGroups,
      singleDataItem,
      tabIndex: props.tabIndex,
      view,
      viewItem_key,
      viewItem,
      viewItemIsView,
      viewItemOpts: _viewItemOpts,
      viewKey,
      viewListData,
    }
  }

  const [item_state, itemDispatch] = useReducer(itemReducer, itemInitialState(getInitState()));
  const item_handlers = itemHandlers(itemDispatch, item_state)

  useEffect(() => {
    if (viewItem) {
      const _init = getInitState()
      const _initOther = updateViewItem(uviProps, item_state, aps_appUserSettings)
      const _fullInit = { ..._init, ..._initOther }
      item_handlers.handleInitState(_fullInit, dataManagementSettings, aps_appUserSettings, pageSettings)
    }
    // eslint-disable-next-line
  }, [viewItem, viewListData, alphabetValue, filterSidebarOpen, filterStatusType, aps_appUserSettings, pageSettings]);

  // updates if (alphaValue)
  useEffect(() => {
    item_handlers.handleUpdateFilterOpts({ alphaValue, filterValues })
    // eslint-disable-next-line
  }, [alphaValue, filterValues && filterValues.length]);

  return <UiItemContext.Provider value={{ item_state, item_handlers }}>
    {props.children}
  </UiItemContext.Provider>

};

export default UiItemProvider