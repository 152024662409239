
import _ from 'lodash';
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Icon, Image, List, Segment } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { UploadContext } from '../../cnr/contexts/UploadContext';
import { fileStatusTypes } from '../../cnr/reducers/UploadReducer';
import { getAlphaList } from '../../common/convert';
import { uniqueKey } from '../../common/keys';
import UiSaveButtons from "../../components/buttons/UiSaveButtons";
import { gEnums } from '../../enums/globalEnums';
import { _storageSettings } from '../../storage/storageHelpers';
import Wrapper, { wrapperTypes } from "../../wrappers/Wrapper";
import AlphabetMenu from '../menus/AlphabetMenu';
import { DataManagementContext } from '../viewers/DataManagementViewer';
import UploadSegButton from './UploadSegButton';

const _imageProps = {
  list: {
    size: 'huge'
  },
  appUser: {
    imageSize: 'tiny',
    floated: 'right',
    iconSize: 'huge'
  }
}

/**
 * 
 * @param {*} props (handleCancel)
 * @returns a component split into 2 parts. The left part will be a list of items, the right part will be an file selection component
 * This is NOT a fullPageWrapper
 */
const BulkUploader = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state, storage_state } = states ? states : {}
  const { pathViews } = paps_state ? paps_state : {}
  const { galleries } = storage_state ? storage_state : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ? dataManagementContext : {}
  const { viewItem: viewItem_dm, appData: appData_dm } = dataManagement_state ? dataManagement_state : {}
  const { key: viewItemKey_dm } = viewItem_dm ? viewItem_dm : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem } = item_state ? item_state : {}
  const { key: viewItemKey } = viewItem ? viewItem : {}

  // uploadContext
  const uploadContext = useContext(UploadContext)
  const { upload_state, upload_handlers } = uploadContext ? uploadContext : {}
  const {
    selectedFolderFiles,
    appUserCollection,
    manifestFiles,
    pendingFiles,
    storageOptions,
    useClientProfileImage,
    storageLocationType,
    storageRootPath,
    storageType,
    unSelectedFiles,
    updating,
  } = upload_state ? upload_state : {}

  const { saveCollectionImagesToClient, clientStorageImageCollections } = storageOptions ? storageOptions : {}

  let _selectedFolderFiles;

  if (manifestFiles && manifestFiles.length > 0) {
    _selectedFolderFiles = manifestFiles
  } else if (selectedFolderFiles && selectedFolderFiles.length > 0) {
    _selectedFolderFiles = selectedFolderFiles
  }

  const fromManager = appData_dm ? true : false
  const _viewItemKey = viewItemKey_dm ? viewItemKey_dm : viewItemKey

  let _currentGallery;

  switch (storageLocationType) {
    case gEnums.storageLocationTypes.clientProfiles:
      _currentGallery = galleries[gEnums.storageLocationTypes.profiles]
      break;
    default:
      _currentGallery = galleries['direct'] ? galleries['direct'][_viewItemKey] : null
  }

  const _storeItemsInClient = useClientProfileImage && ((appUserCollection === _viewItemKey) || (saveCollectionImagesToClient && clientStorageImageCollections && clientStorageImageCollections.includes(_viewItemKey)))
  let storageClientCollection;

  if (_storeItemsInClient) {
    if (appUserCollection === _viewItemKey) {
      storageClientCollection = _storageSettings.galleryPaths.profiles
    } else if (clientStorageImageCollections.includes(_viewItemKey)) {
      storageClientCollection = _viewItemKey
    }
  }

  const [alphaValue, setAlphaValue] = useState(65)
  const [currentAlphaList, setCurrentAlphaList] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [showAll, setShowAll] = useState()

  const _alphaText = String.fromCharCode(alphaValue)

  const [allows, setAllows] = useState({ count: 0, allow: false, caption: 'Upload Approved' })

  const resetAlphaList = () => {
    const _alphaChar = String.fromCharCode(alphaValue)
    const { alphaList } = getAlphaList(pendingFiles, _alphaChar, 'name', _currentGallery,)
    setCurrentAlphaList(alphaList)
  }
  /**
   * Handles the alpha change
   * @param {number} av 
   */
  const handleAlphaChange = (av) => {
    const _alphaChar = String.fromCharCode(av)
    if (av) {
      const { alphaList } = getAlphaList(pendingFiles, _alphaChar, 'name', _currentGallery)
      setAlphaValue(av)
      setCurrentAlphaList(alphaList)
    }
  }

  // update the alphaList
  useEffect(() => {
    if (pendingFiles) {
      handleAlphaChange(alphaValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pendingFiles]);

  useEffect(() => {
    let allowCount = 0
    let caption = 'Upload Approved'
    let appr = 0
    if (currentAlphaList) {
      Object.keys(currentAlphaList).forEach(key => {
        if (currentAlphaList[key].fileStatusType === fileStatusTypes.approved) {
          appr++
        }
      })
    }
    allowCount = appr
    caption += ' (' + _alphaText + ')'
    const allowUpload = !updating && allowCount > 0
    caption += ' (' + allowCount + ')'
    setAllows({ count: allowCount, allow: allowUpload, caption: caption })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAlphaList]);

  useEffect(() => {
    upload_handlers && upload_handlers.handleUpdate_currentGalleryStatus(_currentGallery, storageLocationType)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_currentGallery]);

  const handleCancel = () => {
    if (dataManagement_handlers) {
      dataManagement_handlers && dataManagement_handlers.handleSelectDataActionOption('options')
      // dataManagement_handlers.handleDataActionSelect()
    } else if (props.handleCancel) {
      props.handleCancel()
    }
  }

  /**
  * Uploads the `approved` images to storage
  * @param {string} key 
  */
  const handleImageUpload = (key) => {
    upload_handlers && upload_handlers.handleStartUpdate()
    if (fromManager) {
      let storageRef;
      if (_storeItemsInClient) {
        storageRef = 'clients/' + pathViews.clients + '/' + storageClientCollection
      } else {
        storageRef = storageRootPath + '/' + _storageSettings.galleryPaths.galleryDirect + '/' + _viewItemKey
      }
      upload_handlers.handleUpload_alpha(storageRef, currentAlphaList, true, resetAlphaList)
    } else {
      upload_handlers.handleUpload_item(key && !_.isEmpty(key) ? key : null)
    }
  }

  /**
   * handles the click of the cardButton
   * @param {string} key 
   */
  const handleSelect = (key) => {
    if (selectedItem === key) {
      setSelectedItem()
    } else {
      setSelectedItem(key)
    }
  }


  /**
   * Toggles approved/found
   * @param {string} key 
   */
  const handleApprove = (key) => {
    const cl = { ...currentAlphaList }
    if (cl[key]) {
      switch (cl[key].fileStatusType) {
        case fileStatusTypes.approved:
          cl[key].fileStatusType = fileStatusTypes.found
          break;
        case fileStatusTypes.found:
          cl[key].fileStatusType = fileStatusTypes.approved
          break;
        default:
        // nothing
      }
    }
    handleSelect(key)
    setCurrentAlphaList(cl)
  }

  /**
   * Updates the selected file with the image file
   * @param {object} f 
   */
  const handleMatch = (f) => {
    const _pendingFiles = { ...pendingFiles }
    if (_pendingFiles[selectedItem]) {
      _pendingFiles[selectedItem].fileStatusType = fileStatusTypes.approved
      _pendingFiles[selectedItem].file = f
      upload_handlers && upload_handlers.handleUpdate_pendingFiles(_pendingFiles)
    }
  }

  const handleUpdateGalleryDir = () => upload_handlers.handleUpdate_galleryDir()
  const handleShowAll = () => setShowAll(!showAll)

  /**
   * 
   * @param {object} f 
   * @returns the source of the file
   */
  const getSource = (f) => {
    const src = URL.createObjectURL(f)
    return src
  }

  const image = (keyy, src, floated, size) => <Image key={keyy} size={size} floated={floated} src={src} alt={'imageEdit'}></Image>
  /**
   * 
   * @returns a segemented list containing the selected device files
   */
  const folderList = () => {
    const items = unSelectedFiles ? unSelectedFiles.map((f, index) => (
      <List.Item key={uniqueKey('fl', index)} onClick={() => { selectedItem && handleMatch(f) }}>
        {f && image(uniqueKey('fli', index), getSource(f), null, _imageProps.appUser.imageSize)}
        <List.Content>{f.name}</List.Content>
      </List.Item>
    )) : []
    return <Segment basic><List divided relaxed >{items}</List></Segment>
  }

  /**
   * 
   * @param {object} dataItem 
   * @param {number} index 
   * @returns the image or icon associated with the card
   */
  const cardImage = (dataItem, index) => {
    const { file, urls } = dataItem ? dataItem : {}
    const { thumbnail } = urls ? urls : {}
    if (file) {
      return image(uniqueKey('fli', index), URL.createObjectURL(file), _imageProps.appUser.floated, _imageProps.appUser.imageSize)
    } else if (thumbnail && !file) {
      return image(uniqueKey('fli', index), thumbnail, _imageProps.appUser.floated, _imageProps.appUser.imageSize)
    } else {
      return <Icon name={'user'} size={_imageProps.appUser.iconSize} style={{ float: _imageProps.appUser.floated }} />
    }
  }

  /**
   * 
   * @param {string} _itemKey 
   * @param {string} fileStatusType 
   * @returns the button associated with the card
   */
  const cardButton = (_itemKey, fileStatusType) => {
    let item = { icon: selectedItem === _itemKey ? 'check' : null }
    let oc;
    switch (fileStatusType) {
      case fileStatusTypes.approved:
        item.caption = 'Approved'
        item.buttonColor = 'green'
        oc = handleApprove;
        break;
      case fileStatusTypes.existing:
        item.caption = 'Existing'
        item.buttonColor = 'blue'
        oc = handleSelect
        break;
      case fileStatusTypes.found:
        item.caption = 'Found'
        item.buttonColor = 'yellow'
        oc = handleApprove;
        break;
      case fileStatusTypes.missing:
        item.caption = 'Missing'
        item.buttonColor = selectedItem === _itemKey ? 'blue' : 'grey'
        oc = handleSelect
        break;
      default:
      // nothing
    }
    return <Button
      floated='right'
      color={item.buttonColor}
      key={uniqueKey('lu', 'cb', _itemKey)}
      content={item.caption}
      icon={item.icon ? <Icon name={item.icon} color={item.iconColor} /> : null}
      labelPosition={item.icon ? 'left' : null}
      onClick={() => { oc && oc(_itemKey) }}
    />
  }

  /**
   * 
   * @param {object} dataItem 
   * @param {number} index 
   * @returns a `Card` for the `dataItem`
   */
  const card = (dataItem, index) => {
    const { firstName, lastName, name, email, _itemKey, fileStatusType, file } = dataItem ? dataItem : {}
    const _name = (firstName && lastName) ? lastName + ', ' + firstName : name
    const selected = selectedItem === _itemKey ? true : false
    let allow = true
    if (!showAll && fileStatusType === fileStatusTypes.existing) { allow = false }
    if (allow) {
      return <Card fluid={false} key={uniqueKey('fl', index)} className={selected ? 'bu sel' : 'bu'}>
        <Card.Content>
          {cardImage(dataItem, index)}
          <Card.Header>{_name}</Card.Header>
          <Card.Meta>{email}</Card.Meta>
          <Card.Meta>{file && file.name}</Card.Meta>
        </Card.Content>
        <Card.Content extra>
          {cardButton(_itemKey, fileStatusType)}
        </Card.Content>
      </Card>
    }
  }

  /**
   * 
   * @returns a `Card.Group`, one `Card` for each of the items in the `dataList`
   */
  const cardList = () => {
    const dataList = currentAlphaList ? _.orderBy(currentAlphaList, ['name']) : []
    const items = dataList ? dataList.map((dataItem, index) => (card(dataItem, index))) : []
    return <Card.Group>
      {items}
    </Card.Group>
  }

  /**
   * 
   * @returns the upload button at the bottom of the selected files
   */
  const uploadSegButton = () => <UploadSegButton
    upload_state={upload_state}
    upload_handlers={upload_handlers}
    storageType={storageType}
    size={'big'}
  />

  const folderListWrapper = () => <Wrapper
    content={_selectedFolderFiles ? folderList() : <div></div>}
    footer={uploadSegButton()}
    wrapperType={wrapperTypes.paddedFooter}
  >
  </Wrapper>

  const listSplit = () => <div className='table-split upl'>
    <Segment basic>{cardList()}</Segment>
    {folderListWrapper()}
  </div>

  const footer = () => {
    const btns = []
    btns.push({ oc: handleCancel, caption: 'Cancel', icon: 'delete', })
    btns.push({ oc: handleImageUpload, caption: allows.caption, icon: 'save', disabled: !allows.allow })
    btns.push({ oc: handleUpdateGalleryDir, caption: 'Update Gallery Dir', icon: 'save outline', fl: true })
    btns.push({ oc: handleShowAll, caption: 'Show All', icon: 'find', fl: true, color: showAll ? 'green' : 'grey' })
    return <UiSaveButtons others={btns} />
  }

  const alphaWrapper = () => <Wrapper
    header={<AlphabetMenu handleAlphaChange={handleAlphaChange} alphaValue={alphaValue} restrictToExisting={true} />}
    content={listSplit()}
    footer={footer(alphaValue)}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  return alphaWrapper()

}

export default BulkUploader