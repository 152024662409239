import React from 'react'
import { Button } from 'semantic-ui-react'
import { uniqueKey } from '../common/keys'
import GenericIcon from './GenericIcon'

const GenericButton = (props) => {

  const { gbProps } = props ? props : {}
  const { key, caption, icon, onClick, clickOptions, opts, noE } = gbProps ? gbProps : {}

  const { fl, disabled, size, color, fluid, sty, id, inverted, labelPosition, cn, iconOnly, ref, centered } = opts ? opts : {}

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  if (iconOnly) {
    return gi(key, icon, onClick, clickOptions, opts)
  }

  let style = { float: fl ? null : 'right' }
  let defaultColor = 'blue'
  let basic = false

  if (centered) { style = { float: 'none' } }

  if (sty) { style = { ...style, ...sty } }
  if (sty && sty.backgroundColor) {
    defaultColor = null
    basic = false
  }

  if (noE) {
    return <Button
      id={id ? id : null}
      ref={ref ? ref : null}
      basic={basic}
      color={color ? color : defaultColor}
      content={caption}
      className={cn ? cn : null}
      disabled={disabled}
      fluid={fluid}
      icon={icon}
      inverted={inverted}
      key={uniqueKey('cnt', 'btn', key)}
      labelPosition='right'
      size={size ? size : 'mini'}
      style={style}
      onClick={(e) => { onClick && onClick(clickOptions, e) }}
    />
  } else {
    return <Button
      id={id ? id : null}
      ref={ref ? ref : null}
      basic={basic}
      color={color ? color : defaultColor}
      content={caption}
      className={cn ? cn : null}
      disabled={disabled}
      fluid={fluid}
      icon={icon}
      inverted={inverted}
      key={uniqueKey('cnt', 'btn', key)}
      labelPosition={labelPosition ? labelPosition : 'right'}
      size={size ? size : 'mini'}
      style={style}
      onClick={(e) => { onClick && onClick(clickOptions, e) }}
    />
  }
}

export default GenericButton