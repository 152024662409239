import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import AppUserManagement from '../../appUsers/AppUserManagement';
import { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import TransferProvider from '../../cnr/contexts/TranferContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { googleModeTypes, googleSheetsTypes } from '../../cnr/reducers/GoogleSheetsReducer';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import { AppTransferWithProvider } from '../../pageItem/modification/dataModifications/AppTransfer';
import { CreateEventWithProvider } from '../../viewSettings/create/addSubs/CreateEvent';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UiSaveButtons from '../buttons/UiSaveButtons';
import ImageMap from '../swipe/ImageMap';
import { AppCheck } from './AppCheck';
import AppDataViewer from './AppDataViewer';
import { GoogleFormsViewerWithProvider } from './GoogleFormsViewer';
import { GoogleSheetsViewerWithProvider } from './GoogleSheetsViewer';
import { RegDataViewerWithProvider } from './RegDataViewer';
import FileManagement from './FileManagement';

export const allDataModes = {
  allClientAppUsers: 'allClientAppUsers',
  appCheck: 'appCheck',
  appData: 'appData',
  appTransfer: 'appTransfer',
  appUsers: 'appUsers',
  clientAppUsers: 'clientAppUsers',
  clientProfiles: 'clientProfiles',
  fileManagement: 'fileManagement',
  googleDashboard: 'googleDashboard',
  googleRegistration: 'googleRegistration',
  imageMapping: 'imageMapping',
  import: 'import',
  registrationData: 'registrationData',
  recreateEvent: 'recreateEvent',
  zoneMapping: 'zoneMapping',
}

export const appViewerColors = {
  dmv: 'black',
  gsv: 'blue',
  fsv: 'black'
}

const _caption = 'Dashboard'

/**
 * This is the overall dataViewer for the app
 * @param {object} props 
 * @returns AppDataViewer, GoogleSheetsViewer or RegDataViewer
 * @contexts (GoogleSheetsContext, RegDataContext)
 */
const AppDashboard = (props) => {

  const { noModal, appViewerKey } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, paps_state, transition_state } = states ? states : {}
  const { pathViews } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { googleSheets } = aps_global ? aps_global : {}
  const { googleSheetsId: googleSheetsId_existing } = googleSheets ? googleSheets : {}

  const isEvent = pathViews.events
  const isClient = pathViews.clients && !isEvent

  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.component] : null
  const { showTransition } = transition ? transition : {}

  // uiItemContext - will not exist if from landing page
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers, item_state } = uiItemContext ? uiItemContext : {}
  const { handleCloseOpenedItem: handleItemClose } = item_handlers ? item_handlers : {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_handlers } = googleSheetsContext ? googleSheetsContext : {}

  const soloItem = item_state ? true : false

  // local state
  const [captions, setCaptions] = useState({ topper: _caption, topper2: 'App Data' })
  const [activeDateMenuItems, setActiveDataMenuItems] = useState({})
  const [mode, setMode] = useState()
  const [dataMode, setDataMode] = useState()

  // get the menuItems that will appear in the side menu
  useEffect(() => {
    let _menuItems = {}
    if (soloItem) {
      _menuItems[allDataModes.appData] = allDataModes.appData
    } else {
      _menuItems[allDataModes.appCheck] = allDataModes.appCheck
      _menuItems[allDataModes.appData] = allDataModes.appData
      _menuItems[allDataModes.appTransfer] = allDataModes.appTransfer
      if (isEvent) {
        _menuItems[allDataModes.appUsers] = allDataModes.appUsers
        _menuItems[allDataModes.fileManagement] = allDataModes.fileManagement
        _menuItems[allDataModes.googleDashboard] = allDataModes.googleDashboard
        _menuItems[allDataModes.googleRegistration] = allDataModes.googleRegistration
        _menuItems[allDataModes.recreateEvent] = allDataModes.recreateEvent
        _menuItems[allDataModes.registrationData] = allDataModes.registrationData
        _menuItems[allDataModes.zoneMapping] = allDataModes.zoneMapping
      }
      if (isClient) {
        // _activeDataModes[allDataModes.clientAppUsers] = allDataModes.clientAppUsers
        _menuItems[allDataModes.clientProfiles] = allDataModes.clientProfiles
      }
    }
    setDataMode(appViewerKey ? appViewerKey : allDataModes.appData)
    setCaptions({ topper: _caption, topper2: _.startCase(appViewerKey ? appViewerKey : allDataModes.appData) })
    setActiveDataMenuItems(_menuItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSheetsId_existing, appViewerKey]);

  const handleCancel = () => {
    props.handleCancel && props.handleCancel()
    googleSheets_handlers && googleSheets_handlers.handleGoogleMode(googleModeTypes.appData)
    if (handleItemClose) { handleItemClose() }
  }

  const handleMode = (adm) => setMode(adm)

  /**
   * changes the dataMode for hte componnent
   * @param {object} adm 
   */
  const handleDataMode = (adm) => {
    const _adm = adm ? adm : allDataModes.appData
    setDataMode(_adm)
    setCaptions({ topper: _caption, topper2: _.startCase(_adm) })
    setMode()
  }

  const modeMenu = () => {
    const mis = []
    Object.keys(activeDateMenuItems).forEach(key => {
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', key)} onClick={() => { handleDataMode(key) }}>{_.startCase(key)}</Menu.Item>)
    })
    return <Segment basic><Menu vertical fluid inverted className={'av-menu'}>{mis}</Menu></Segment>
  }

  // this is the options menu on the left side of the component
  const VerticalSidebarLeft = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv-settings'
      onHide={() => handleMode()}
    >
      {modeMenu()}
    </Sidebar>
  )

  /**
   * 
   * @returns a component from the active tab
   */
  const tabContent = () => {
    switch (dataMode) {
      case allDataModes.appCheck:
        return <AppCheck />
      case allDataModes.appData:
        return <AppDataViewer />
      case allDataModes.appTransfer:
        return <AppTransferWithProvider fromDataViewer={true} handleCancel={handleDataMode} />
      case allDataModes.appUsers:
      case allDataModes.clientAppUsers:
      case allDataModes.clientProfiles:
        return <AppUserManagement fromDataViewer={true} handleCancel={handleDataMode} />
      case allDataModes.fileManagement:
        return <FileManagement />
      case allDataModes.googleDashboard:
        return <TransferProvider>
          <GoogleSheetsViewerWithProvider googleSheetType={googleSheetsTypes.normal} fromDataViewer={true} />
        </TransferProvider>
      case allDataModes.googleRegistration:
        return <TransferProvider>
          <GoogleFormsViewerWithProvider />
        </TransferProvider>
      // case allDataModes.imageMapping:
      case allDataModes.zoneMapping:
        return <ImageMap handleCancel={handleDataMode} imageMapType={gEnums.imageMapTypes.mapping} inDashboard={true} />
      case allDataModes.import:
        return <Segment basic>Import</Segment>
      case allDataModes.recreateEvent:
        return <CreateEventWithProvider fromAppViewer={true} />
      case allDataModes.registrationData:
        return <RegDataViewerWithProvider fromDataViewer={true} handleCancel={handleDataMode} />
      default:
      // nothing
    }
  }

  const modeButtons = () => {
    const btns = [
      { caption: _.startCase(dataMode), oc: handleMode, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={'black'}
      rows={2}
    />
  }

  const header = () => <div className={'header-flex'}>
    {modeButtons()}
  </div>

  const wrapper = () => <Wrapper
    header={header()}
    content={dataMode && tabContent()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ container: 'apv' }}
  />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <VerticalSidebarLeft visible={mode ? true : false} />
    <Sidebar.Pusher dimmed={mode ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={appViewerKey ? wrapper() : sidebar()}
    handleCancel={handleCancel}
    topperCaption={captions.topper}
    topperCaption2={captions.topper2}
    fullMode={true}
    noModal={noModal}
  />

  if (showTransition) {
    return <MotionComponent transition={transition}>
      {fullPageWrapper()}
    </MotionComponent>
  } else {
    return fullPageWrapper()
  }

}

export default AppDashboard