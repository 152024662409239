import React, { createContext, useContext, useEffect, useState } from 'react';
import { gEnums } from '../../enums/globalEnums';
import { getStaticViews } from '../../firestoreData/staticViews/updateStaticViews';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';


/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const StaticViewsClientContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const StaticViewsClientProvider = (props) => {

  const aat = gEnums.appAreaTypes
  const svc = [aat.clients, aat.clubs, aat.districts, aat.event, aat.leagues, aat.schools, aat.organizations, aat.client]

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { appArea, pathViews } = paps_state ? paps_state : {}

  // pageContext
  const pageContext = useContext(PageContext);

  const [allStaticViews, setAllStaticViews] = useState()

  const setResult = (d) => { setAllStaticViews(d) }

  useEffect(
    () => {
      if (svc.includes(appArea)) {
        if (!allStaticViews) {
          getStaticViews(paps_state, null, setResult, pageContext, true)
        } else {
          setAllStaticViews({})
        }
      } else {
        setAllStaticViews({})
      }
    },
    // eslint-disable-next-line
    [pathViews.clients] // does not re-rednder unless uivi has changed
  )

  const context = () => {
    const { staticViews } = allStaticViews
    return <StaticViewsClientContext.Provider value={{ staticViews }}>
      {props.children}
    </StaticViewsClientContext.Provider>
  }

  return allStaticViews ? context() : <div></div>
}

export default StaticViewsClientProvider