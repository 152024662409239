import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { signInResponseTypes, sirts } from '../../cnr/reducers/SignInReducer';

export const signInNewAuth = async (authCreds) => {

  const creds = {
    email: authCreds.email.toLowerCase(),
    password: authCreds.password
  }

  const auth = getAuth()
  return signInWithEmailAndPassword(auth, creds.email, creds.password)
    .then(async (ref) => {
      return ({ type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.signInSuccess, success: true });
    }).catch((err) => {
      if (err.code) {
        switch (err.code) {
          case 'auth/too-many-requests':
            return ({ type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.tooManyAttempts, success: false });
          default:
            return ({ type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.incorrectPassword, success: false });
        }
      } else {
        return ({ type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.incorrectPassword, success: false });
      }
    })
}