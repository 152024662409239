import React from 'react';
import { Button, Icon, Label } from "semantic-ui-react";
import { uniqueKey } from '../common/keys';
import { iconColorTypes } from '../enums/settingsIconTypes';

const ButtonIcon = (props) => {
  const { biProps } = props ? props : {}
  const { iconName, propIsOn, onClick, buttonMode, opts, altColor, text, cornerIcon, appUserAuthList } = biProps ? biProps : {}
  let iconColor = propIsOn ? iconColorTypes.propOn : iconColorTypes.propOff
  if (altColor) { iconColor = altColor }
  const key = opts && opts.key ? opts.key : iconName

  if (appUserAuthList) {
    const { recent, notFound, found } = appUserAuthList ? appUserAuthList : {}
    const _recentCount = recent ? recent.length : 0
    const _notFoundCount = notFound ? notFound.length : 0
    const _foundCount = found ? found.length : 0
    return <Button.Group style={{ padding: 0 }} key={uniqueKey('bi', key)} icon labelPosition={text ? 'right' : null} {...opts} onClick={() => { onClick && onClick(buttonMode) }}>
      <Button color='green'>{_recentCount}</Button>
      <Button color='blue'>{_foundCount}</Button>
      <Button color='yellow'>{_notFoundCount}</Button>
    </Button.Group>
  } else {
    return <Button key={uniqueKey('bi', key)} icon color={iconColor} labelPosition={text ? 'right' : null} {...opts} onClick={() => { onClick && onClick(buttonMode) }}>
      {text && text}
      {cornerIcon && <Icon.Group >
        <Icon name={iconName} />
        <Icon corner name={cornerIcon.name} color={cornerIcon.color ? cornerIcon.color : 'grey'} />
      </Icon.Group>}
      {!cornerIcon && <Icon name={iconName} />}
    </Button>
  }

}

export default ButtonIcon