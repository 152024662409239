import _ from 'lodash'
import { gEnums } from '../../enums/globalEnums'

const optionKeys = {
  appAuction: 'appAuction',
  appConversations: 'appConversations',
  appCredentialing: 'appCredentialing',
  appGallery: 'appGallery',
  appNotes: 'appNotes',
  appNotifications: 'appNotifications',
  appSurvey: 'appSurvey',
  appTicketing: 'appTicketing',
  appVideoConferencing: 'appVideoConferencing',
  appQuestionsAndAnswers: 'appQuestionsAndAnswers',
  appVoting: 'appVoting',
}

const rts = {
  handleActiveAll: 'handleActiveAll',
  handleActiveMenu: 'handleActiveMenu',
  handleActiveMenuGroup: 'handleActiveMenuGroup',
  handleAllowGroupMenuSort: 'handleAllowGroupMenuSort',
  handleMenuGroupItemClick: 'handleMenuGroupItemClick',
  handleOpenSettingsItem: 'handleOpenSettingsItem',
  handleShowSearch: 'handleShowSearch',
  handleUpdateAll: 'handleUpdateAll',
  handleUpdateInit: 'handleUpdateInit',
}

export const settingsMenuReducer = (state, action) => {

  const { lsName } = state
  const { type } = action

  switch (type) {

    case rts.handleUpdateInit:
      return { ...state, isGlobal: action.isGlobal, appArea: action.appArea }

    case rts.handleMenuGroupItemClick:
      return { ...state, selectedGroupItem: action.selectedGroupItem, selectedGroup: action.selectedGroup }

    case rts.handleOpenSettingsItem:
      return { ...state }

    case rts.handleActiveAll:
      return { ...state }

    case rts.handleActiveMenu:
      return { ...state }

    case rts.handleActiveMenuGroup:
      const ams = { ...state.activeMenus }
      ams[action.name] = !ams[action.name]
      localStorage.setItem(lsName, JSON.stringify(ams))
      return {
        ...state,
        activeAll: !state.activeAll,
        activeMenu: action.name === state.activeMenu ? null : action.name,
        activeMenus: ams
      }

    case rts.handleShowSearch:
      return { ...state }

    case rts.handleUpdateAll:
      const _activeMenus = { ...state.activeMenus }
      Object.keys(_activeMenus).forEach(key => {
        _activeMenus[key] = !state.activeAll
      })
      localStorage.setItem(lsName, JSON.stringify(_activeMenus))
      return { ...state, activeAll: !state.activeAll, activeMenus: _activeMenus }

    case rts.handleAllowGroupMenuSort:
      return { ...state, allowGroupMenuSort: !state.allowGroupMenuSort }

    default:
      return { ...state }
  }
}

export const settingsMenuInitialState = (init_state) => {
  return { ...init_state }
};

export const settingsMenuHandlers = (dispatch, state) => {
  return {
    handleActiveAll: () => { dispatch({ type: rts.handleActiveAll }) },
    handleActiveMenu: () => { dispatch({ type: rts.handleActiveMenu }) },
    handleActiveMenuGroup: (name) => { dispatch({ type: rts.handleActiveMenuGroup, name }) },
    handleMenuGroupItemClick: (selectedGroupItem, selectedGroup) => { dispatch({ type: rts.handleMenuGroupItemClick, selectedGroupItem, selectedGroup }) },
    handleOpenSettingsItem: (selectedItem, gi) => { dispatch({ type: rts.handleOpenSettingsItem, selectedItem, gi }) },
    handleShowSearch: () => { dispatch({ type: rts.handleShowSearch }) },
    handleUpdateAll: () => { dispatch({ type: rts.handleUpdateAll }) },
    handleUpdateInit: (appArea, isGlobal) => { dispatch({ type: rts.handleUpdateInit, appArea, isGlobal }) },
    handleAllowGroupMenuSort: () => { dispatch({ type: rts.handleAllowGroupMenuSort }) },
  }
}

export const settingsMenu_fns = (projectOptions, projectModules, groupItemClick_fns, pathViews, nonLandingView, settingsOriginType, productionSettings) => {
  return {
    allowProjectOption: (key) => allowProjectOption(key, projectOptions),
    allowProjectModule: (key) => allowProjectModule(key, projectModules),
    handleGroupItemClick: (selectedGroupItem, gi) => handleGroupItemClick(selectedGroupItem, gi, { groupItemClick_fns, pathViews, nonLandingView, settingsOriginType, productionSettings }),
  }
}

// exported function
const allowProjectModule = (key, projectModules) => {

  const { allowAuction, allowCredentialing, allowQuestionsAndAnswers, allowVoting, allowGallery, allowNotifications, allowSurvey, allowVideoConferencing, allowTicketing } = projectModules ? projectModules : {}

  switch (key) {
    case optionKeys.appAuction:
      return allowAuction
    case optionKeys.appCredentialing:
      return allowCredentialing
    case optionKeys.appGallery:
      return allowGallery
    case optionKeys.appNotifications:
    case optionKeys.notificationsManager:
      return allowNotifications
    case optionKeys.appQuestionsAndAnswers:
      return allowQuestionsAndAnswers
    case optionKeys.appSurvey:
      return allowSurvey
    case optionKeys.appTicketing:
      return allowTicketing
    case optionKeys.appVideoConferencing:
      return allowVideoConferencing
    case optionKeys.appVoting:
      return allowVoting
    default:
      return true
  }
}

// exported function
const allowProjectOption = (key, projectOptions) => {

  const { allowFeeds, allowGeoLocation, allowGeoList, allowImageLinks, allowQuestionsAndAnswers, allowTwitter, allowVideoLinks, allowVoting } = projectOptions ? projectOptions : {}
  const _apits = gEnums.additionalPageItemTypes

  switch (key) {
    case _apits.feeds:
      return allowFeeds
    case _apits.geoList:
      return allowGeoList
    case _apits.geoLocation:
      return allowGeoLocation
    case _apits.imageLinks:
      return allowImageLinks
    case _apits.questionsAndAnswers:
      return allowQuestionsAndAnswers
    case _apits.twitter:
      return allowTwitter
    case _apits.videoLinks:
      return allowVideoLinks
    case _apits.voting:
      return allowVoting
    default:
      return true
  }
}

// exported function
/**
 * This handles the click from the settings menu
 * @param {object} selectedGroupItem 
 * @param {object} gi 
 */
const handleGroupItemClick = (selectedGroupItem, gi, props) => {

  const { groupItemClick_fns, pathViews, nonLandingView, settingsOriginType, productionSettings } = props

  const {
    handleGroupKey,
    handleShowAppViewer,
    handleShowSearch,
    openSettingsItem,
    pushSimple,
    swipeToSelected,
  } = groupItemClick_fns

  const { settingsMenuGroupType } = gi ? gi : {}

  switch (settingsMenuGroupType) {
    case 'appDashboard':
      handleShowAppViewer(selectedGroupItem.key)
      break;

    default:
      if (gi.triggerUrl) {
        openSettingsItem(gi.triggerUrl, pathViews, nonLandingView, productionSettings)
      } else if (gi.close) {
        console.log('close')
      } else if (gi.gotoKey) {
        switch (gi.gotoKey) {
          case 'search':
            handleShowSearch()
            break;
          default:
            pushSimple({ key: gi.gotoKey })
            break;
        }
      } else {
        switch (settingsOriginType) {
          case gEnums.settingsOriginTypes.app:
          case gEnums.settingsOriginTypes.page:
            if (swipeToSelected) {
              const swipeProps = {
                swipedItem: {
                  key: selectedGroupItem.key,
                  settingKey: selectedGroupItem.key,
                }
              }
              swipeToSelected(swipeProps)
            }
            break;
          default:
            // handleGroupKey_left or handleGroupKey_right
            handleGroupKey(selectedGroupItem, gi)
        }
      }
  }
}