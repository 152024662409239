import _ from 'lodash';
import { createItemKeys, getFirstObject } from '../../../../global/common/convert';
import { cleanUpData, removeEmpties, removeEmpty } from '../../../../global/common/dataAdjust';
import { getCalStartDates, isOdd } from '../../../../global/common/filtering';
import { copyObj } from '../../../../global/common_web/copy';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_get_doc, fs_set_doc } from '../../../../global/firestoreData/appData/fsData';
import { createScheduledMatches } from '../../dbActions/updateSeasonSchedule';
import { createTournamentInfo, sortPlayoffLevelTeams } from '../../helpers/tournamentData';
import { ammendPlayoffSeeding, getData_createSeasonInit, getSelectedTeamsFromSchools } from './reducerHelpers/createSportsData';
import { getPks, getSeasonalData, getSports_collectionItem, seasonalUpdateTypes, updateSeasonalOrGlobal, updateSportsCollectionItemsWithParentKeys } from './reducerHelpers/seasonalSportsData';

// Mens Basketball - uY1a6FAsOf3sr6IrCNYf
// Mens Volleyball - eDeXpEonuj9pFjghH6ZF
// Mens Volleyball - JXi2tmd5MY3t8T4tFOO6
// Womens Volleyball - V8h4h1sJDQpc4Mb969ko

const _schedule = {
  details: {

  },
  parentKeys: {

  },
  sectionCounts: {

  },
  sectionDates: {

  },
  teams: {

  },
  scheduleDates: {

  }
}

const rts = {
  handleCheckLevelSection: 'handleCheckLevelSection',

  handleCreate_levelSections: 'handleCreate_levelSections',
  handleCreate_playoff: 'handleCreate_playoff',
  handleCreate_pools: 'handleCreate_pools',
  handleCreate_seasonMatches: 'handleCreate_seasonMatches',
  handleCreate_staff: 'handleCreate_staff',
  handleCreate_teamRoster: 'handleCreate_teamRoster',
  handleCreate_tournament: 'handleCreate_tournament',

  handleCurrentDateGroup: 'handleCurrentDateGroup',
  handleCurrentSectionCount: 'handleCurrentSectionCount',
  handleGetSeasonalData: 'handleGetSeasonalData',
  handleGetSportsSeasonalData: 'handleGetSportsSeasonalData',
  handleHomeAway: 'handleHomeAway',
  handleInit_sportsData: 'handleInit_sportsData',
  handleInit_playoffTeams: 'handleInit_playoffTeams',
  handleLevelCheck: 'handleLevelCheck',
  handleLevelQualifyCount: 'handleLevelQualifyCount',
  handleLevelSectionCount: 'handleLevelSectionCount',
  handleLevelSectionTeams: 'handleLevelSectionTeams',
  handleLevelsFromPaste: 'handleLevelsFromPaste',
  handleNext: 'handleNext',
  handleNominations: 'handleNominations',
  handleNonSelectedSchools: 'handleNonSelectedSchools',
  handlePlayoffDates: 'handlePlayoffDates',
  handleSet_playoffInfo: 'handleSet_playoffInfo',

  handleSeasonal_savePlayoffTournament: 'handleSeasonal_savePlayoffTournament',
  handleSeasonal_update: 'handleSeasonal_update',
  handleSeasonal_updatePlayoffTournament: 'handleSeasonal_updatePlayoffTournament',

  handleSeasonDates: 'handleSeasonDates',
  handleSeasonDetails: 'handleSeasonDetails',
  handleSeasonMatches: 'handleSeasonMatches',
  handleSectionCheck: 'handleSectionCheck',
  handleSectionHomeAway: 'handleSectionHomeAway',
  handleSectionSelect: 'handleSectionSelect',
  handleSelectedAthletes: 'handleSelectedAthletes',
  handleSelectedLevels: 'handleSelectedLevels',
  handleSelectedTeams: 'handleSelectedTeams',
  handleSelectSchoolsOrTeamsSelected: 'handleSelectSchoolsOrTeamsSelected',
  handleSelect_team: 'handleSelect_team',
  handleSetDataResults: 'handleSetDataResults',
  // handleSetPlayoffTeamsFromStandings: 'handleSetPlayoffTeamsFromStandings',
  handleSetSeasonalData: 'handleSetSeasonalData',
  handleSetSportsSeasonalDataItem: 'handleSetSportsSeasonalDataItem',
  handleSortedLevels: 'handleSortedLevels',
  handleTeamRosters: 'handleTeamRosters',
  handleTeamsInfo: 'handleTeamsInfo',
  // handleUpdateCreateData: 'handleUpdateCreateData',
  handleUpdate_playoffInfo: 'handleUpdate_playoffInfo',
  handleUpdate_playoffSeededTeams: 'handleUpdate_playoffSeededTeams',
  handleUpdate_pools: 'handleUpdate_pools',
  handleUpdate_poolsFromSeeds: 'handleUpdate_poolsFromSeeds',
  // handleUpdate_seasonMatches: 'handleUpdate_seasonMatches',
  handleUpdate_seededTeams: 'handleUpdate_seededTeams',
  handleUpdate_teamSchools: 'handleUpdate_teamSchools',
  // updateLevelSectionsClick: 'updateLevelSectionsClick',

  handleUpdate_allStarTeams: 'handleUpdate_allStarTeams',
  handleUpdate_alignment: 'handleUpdate_alignment',
  handleUpdate_rankings: 'handleUpdate_rankings',
  handleUpdate_seasonDetails: 'handleUpdate_seasonDetails',
}

export const sectionCountTypes = {
  checked: 'checked',
  qualifyCount: 'qualifyCount',
  sectionCount: 'sectionCount',
}

export const createScheduleTypes = {
  season: 'season',
  playoff: 'playoff',
}

export const seasonalUpdateSteps = {
  qualifyCount: 'qualifyCount',
  seasonAlignment: 'seasonAlignment',
  seasonDetails: 'seasonDetails',
  seasonSchedule: 'seasonSchedule',
  seasonSectionCount: 'seasonSectionCount',
  seasonSectionDates: 'seasonSectionDates',
  seasonSectionTeams: 'seasonSectionTeams',
  seasonTeams: 'seasonTeams',
  teams: 'teams',
  year: 'year',
}

// Data Structure
// const schools = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0, 
//   }
// }

// const teams = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0,
//     sports: 0,
//     schools: 0,
//   }
// }

// const _seasonal = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0,
//     sports: 0,
//     sportsYears: 0
//   },
//   sectionDates: {
//     5: [],
//     6: [],
//     7: [],
//     8: [],
//     9: [],
//   },
//   teams: {
//     teamKey1: {
//       levels: '6A',
//       name: 'Team Name',
//       position: 0,
//       sections: 'Section `'
//     }
//   }
// }

// const _teams = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0,
//     sports: 0,
//     sportsYears: 0
//   },
//   teams: {
//     teamKey1: {
//       levels: '6A',
//       name: 'Team Name',
//       position: 0,
//       sections: 'Section `'
//       record: {
//          ...a bunch of data
//       }
//     }
//   }
// }


/** 
 * @param {object} state 
 * @param {object} action 
 * @returns 
 */
export const createSportsReducer = (state, action) => {

  const {
    season_info,
    latestSeason,
    levels_info,
    pathViews,
    pickedTeams,
    playoff_info,
    pools,
    selectedSectionItem,
    sports_info,
    sportsDetails,
    sportsKey,
    teams_info,
  } = state

  const _seasonInfo = copyObj(season_info)
  const _levels_info = copyObj(levels_info)
  const { existing, next } = _levels_info
  const _existing = { ...existing }
  const _next = { ...next }

  const _playoffInfo = { ...playoff_info }
  const { teams: playoff_teams } = _playoffInfo ? _playoffInfo : {}

  const { type, dispatch, levelItem, sectionItem, itemChecked } = action

  const { sectionDates, matches: seasonMatches } = season_info ? season_info : {}

  const { year: sportsYears } = sportsDetails ? sportsDetails : {}

  const { levels, sections, teams, schools } = sports_info

  const _sportsData = { ...sports_info }

  const teams_selected = teams_info ? teams_info.selected : {}
  const tcc = _next ? getTeamCountOptions(_.pickBy(_next, { checked: true })) : []

  const { handleSetDataResults, handleLevelSectionTeams, handleSetSeasonalData, handleCreate_levelSections, handleUpdate_poolsFromSeeds, handleUpdate_playoffInfo, handleSetSportsSeasonalDataItem } = createSportsHandlers(dispatch)

  console.log('action', type)

  switch (type) {

    // gets the existing data from the database
    case rts.handleInit_sportsData:
      getData_createSeasonInit(pathViews, sportsKey, handleSetDataResults)
      return { ...state }

    // case rts.handleUpdateCreateData:
    //   handleCreate_levelSections()
    //   return { ...state }

    // handles the returned initial data
    case rts.handleSetDataResults:

      // eams, _hasTeams, _school_data
      const { _teams, _hasTeams, _school_data } = ammendSportsDataFromResult(_sportsData, action)

      return {
        ...state,
        sports_info: _sportsData,
        teams_info: {
          counts: tcc,
          all: action.teams,
        },
        schools_info: {
          ..._school_data,
          // selectedIndexes: selectedIndexes,
        },
        hasTeams: _hasTeams,
        dataUpdates: { ...state.dataUpdates, teams: _teams }
      }

    case rts.handleGetSeasonalData:
      // this only happend when we default from last season
      getSeasonalData(pathViews, sportsKey, action.latestSeason, handleSetSeasonalData)
      return { ...state }

    case rts.handleGetSportsSeasonalData:
      const parentKeys = getPks(pathViews, action.sportsYears, sportsKey)
      getSports_collectionItem(pathViews, parentKeys, action.collectionName, true, { sportsYears: action.sportsYears }, handleSetSportsSeasonalDataItem)
      return { ...state }

    case rts.handleSetSportsSeasonalDataItem:

      const cbm = (data) => {
        console.log('data', data)
      }

      const { data, opts } = action
      const { cbProps } = opts ? opts : {}
      const { sportsYears: _sportsYears } = cbProps ? cbProps : {}
      const { collectionName, sportsItem, parentKeys: pks } = data

      const {
        id: _seasonId,
        details: details_si,
        sectionCounts: sectionCounts_si,
        sectionDates: sectionDates_si,
        teams: teams_si,
      } = sportsItem ? sportsItem : {}

      if (details_si && _sportsYears) {
        details_si.year = _sportsYears
      }

      if (collectionName) {

      } else {
        if (teams_si) {
          // loop the teams, find the school and assign the parentKeys
          Object.keys(teams_si).forEach(k => {
            const t = teams_si[k]
            t.id = k
            let _s = schools ? _.find(schools, { name: t.schools }) : null
            if (schools && !_s) { _s = _.find(schools, { name: t.name }) }
            const _pkz = { ...pks }
            if (_s) { _pkz.schools = _s.id }
            t.parentKeys = _pkz
          })
          _seasonInfo.teams = teams_si
          handleSetSeasonalData({ teams: teams_si })
        }
        if (sectionDates_si) {
          _seasonInfo.sectionDates = sectionDates_si
        }
      }

      if (sectionCounts_si) {
        ammendLevelSectionsFromCounts(_levels_info, sectionCounts_si)
      }

      const _refm = createRefPath_event(pathViews, ['_seasonals', _seasonId, 'matches', 'matches'])
      fs_get_doc(_refm, cbm)

      return {
        ...state,
        _seasonId,
        levels_info: _levels_info,
        ready_levels: _levels_info && _levels_info.next ? _.find(_levels_info.next, { checked: true }) : null,
        ready_sectionCounts: sectionCounts_si ? true : false,
        season_info: _seasonInfo,
        sectionCounts: _levels_info ? getSectionCounts(_levels_info) : {},
        sportsDetails,
        sportsDetails: details_si ? details_si : state,
        dataUpdates: { ...state.dataUpdates, details: details_si, teamsWithSections: teams_si }
      }

    case rts.handleSetSeasonalData:

      const { retData, fk } = action ? action : {}
      const { teams: teams_seasonal } = retData ? retData : {}
      const _parentDataKeys = { ...state.parentDataKeys }

      if (fk) {
        _parentDataKeys['seasonals'] = fk
      }

      const {
        _selectedSchoolIndexes,
        _selectedTeamIndexes,
        homeAways,
        levels_info: lst,
        playoff_teamz,
        ready_levels,
      } = getSelectedTeams(teams_seasonal, state, _levels_info, true)

      const _teamData = getDataInfo(_sportsData.teams, _selectedTeamIndexes, teams_seasonal)
      const _schoolData = getDataInfo(_sportsData.schools, _selectedSchoolIndexes, teams_seasonal)

      _playoffInfo.teams = playoff_teams

      const _tcs = getTeamCountOptions(_.pickBy(_next, { checked: true }))

      if (_tcs) {
        _tcs.forEach(key => {
          if (!_seasonInfo.sectionDates[key]) {
            _seasonInfo.sectionDates[key] = []
          }
        })
      }

      if (homeAways) {
        _seasonInfo.homeAways = homeAways
      }

      return {
        ...state,
        ready_alignment: ready_levels,
        schools_info: {
          ..._schoolData,
        },
        teams_info: {
          ..._teamData,
          playoff: playoff_teamz,
        },
        ready_levels: ready_levels,
        levels_info: lst,
        playoff_info: _playoffInfo,
        season_info: _seasonInfo,
        parentDataKeys: _parentDataKeys,
        dataUpdates: { ...state.dataUpdates, teams: teams_seasonal, teamsWithSections: teams_seasonal }
      }

    case rts.handleLevelsFromPaste:
      const { levels: lvs, teams: tms, nonSchools } = createLevelsFromPaste(state, action.items, action.levelName)
      handleLevelSectionTeams(lvs, 'next', nonSchools, tms)
      return { ...state }

    case rts.handleLevelSectionTeams:
      switch (action.columnKey) {
        case 'existing':
          _levels_info.existing = action.items
          return { ...state, levels_info: _levels_info, csStatus: state.csStatus === 'a' ? 'b' : 'a' }
        case 'next':
          _levels_info.next = { ..._levels_info.next, ...action.items }
          teams_info.counts = _next ? getTeamCountOptions(_.pickBy(_levels_info.next, { checked: true })) : []
          if (action.teams) {
            if (teams_info.selected) {
              teams_info.selected = [...teams_info.selected, ...action.teams]
            } else {
              teams_info.selected = action.teams
            }
            return { ...state, teams_info, levels_info: _levels_info, csStatus: state.csStatus === 'a' ? 'b' : 'a', teams: teams_info, ready_levels: _.find(_levels_info.next, { checked: true }), ready_alignment: _.find(_levels_info.next, { checked: true }) }
          } else {
            const { teams: _lstu } = getTeamsFromLevelSections(levels_info, state.dataUpdates)
            return { ...state, teams_info, dataUpdates: { ...state.dataUpdates, teamsWithSections: _lstu }, levels_info: _levels_info, csStatus: state.csStatus === 'a' ? 'b' : 'a', nonSchools: action.nonSchools, ready_levels: _.find(_levels_info.next, { checked: true }), ready_alignment: _.find(_levels_info.next, { checked: true }) }
          }
        case 'teams':
          return { ...state, teams_selected: action.items, csStatus: state.csStatus === 'a' ? 'b' : 'a' }
        default:
        // nothing
      }
      return { ...state }

    case rts.handleNext:
      _levels_info.next = action.next
      const { teams: xxxx } = getTeamsFromLevelSections(_levels_info, state.dataUpdates)
      return { ...state, dataUpdates: { ...state.dataUpdates, teamsWithSections: xxxx }, levels_info: _levels_info }

    case rts.handleUpdate_seededTeams:
      return { ...state, seededTeams: action.seededTeams }

    case rts.handleSet_playoffInfo:

      const d = new Date()

      const msds = [
        new Date(d.getFullYear(), d.getMonth(), 1),
        new Date(d.getFullYear(), d.getMonth() + 1, 1)
      ]

      if (action.seasonalData) {
        const _seasonalData = action.seasonalData
        const { playoffInfo } = _seasonalData ? _seasonalData : {}
        const { playoffInfo: playoffInfo_sub } = playoffInfo ? playoffInfo : {}
        const _playoff_info = playoffInfo_sub ? playoffInfo_sub : playoffInfo
        const { playoffLevelTeams, playoffDates } = _playoff_info ? _playoff_info : {}
        _playoffInfo.found = true
        if (playoffLevelTeams) {
          ammendPlayoffSeeding(playoffLevelTeams)
          _playoffInfo.playoffLevelTeams = playoffLevelTeams
        }
        if (playoffDates) { _playoffInfo.playoffDates = playoffDates }
        return { ...state, playoff_info: _playoffInfo, monthStartDates: msds }
      } else {
        _playoffInfo.found = false
        return { ...state, playoff_info: _playoffInfo, monthStartDates: msds }
      }

    case rts.handleInit_playoffTeams:

      console.log(state.playoff_info)

      if (!state.playoff_info.playoffTeams) {
        // the playoffTeams are from the sportsSeasonContext, not playoffInfo
        const _playoffTeams = []
        if (action.playoffTeams) {  // 25 of them
          action.playoffTeams.forEach(pt => {
            _playoffTeams.push(pt)
          })
        }
        _playoffInfo.teams = _playoffTeams //playoff_teams

        if (!state.playoff_info.playoffLevelTeams) {
          const plt = sortPlayoffLevelTeams(_playoffTeams)
          // ammendPlayoffSeeding(plt)
          _playoffInfo.playoffLevelTeams = plt
        }
      }

      return { ...state, playoff_info: _playoffInfo }

    case rts.handleTeamsInfo:
      // const xxx = ammendSomething(state, action.teams_all, action.teamsFromSchools)
      return { ...state, currentInfo: action.info, teamsFromSchools: action.teamsFromSchools }

    case rts.handleNonSelectedSchools:
      return { ...state, schools_nonSelected: _.sortBy(action.selected, 'name') }

    case rts.handleSelectedTeams:
      const st = []
      if (teams && action.selected) {
        action.selected.forEach(si => {
          if (teams[si]) {
            st.push(teams[si])
          }
        })
      }
      const _tt = {
        selected: st, //_.sortBy(st, 'name'),
        // selectedIndexes: action.selected
      }
      return { ...state, teams: _tt }

    // case rts.handleSetPlayoffTeamsFromStandings:
    //   let _playoffTeams = _.filter(action.sportsTeams, function (st) { return st.record && st.record.playoffTeam })
    //   const pst_seeded = sortPlayoffTeams(_playoffTeams)
    //   _playoffInfo.teams = _playoffTeams
    //   _playoffInfo.playoffLevelTeams = pst_seeded
    //   return { ...state, playoff_info: _playoffInfo }

    case rts.handleUpdate_playoffSeededTeams:
      ammendPlayoffSeeding(action.playoffLevelTeams)
      _playoffInfo.playoffLevelTeams = action.playoffLevelTeams
      return { ...state, playoff_info: _playoffInfo }


    case rts.handleSelect_team:
      if (_.includes(playoff_teams, action.team)) {
        _.remove(playoff_teams, function (n) {
          return n.id === action.team.id;
        });
      } else {
        playoff_teams.push(action.team)
      }
      _playoffInfo.teams = playoff_teams
      _playoffInfo.playoffLevelTeams = sortPlayoffLevelTeams(playoff_teams)
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleCheckLevelSection:
      return { ...state, selectedSectionItem: action.selectedItem }

    // case rts.updateLevelSectionsClick:
    //   const { groupkey: groupKey_selected } = selectedSectionItem ? selectedSectionItem : {}
    //   const { id } = action.selectedItem // teamId
    //   const gss = groupKey_selected ? groupKey_selected.split('-') : []
    //   if (teams_selected) {
    //     return addTeamToSection(state, gss, _existing, teams_selected, pickedTeams, id)
    //   }
    //   return { ...state }

    case rts.handleLevelQualifyCount:

      const { data: data_qualify } = action
      const { value: value_qualify, lskey: lskey_qualify } = data_qualify

      if (_next && _next[lskey_qualify]) {
        _next[lskey_qualify][sectionCountTypes.qualifyCount] = value_qualify
      }
      _levels_info.next = _next

      let _qualifyReady = true

      if (_next) {
        Object.keys(_next).forEach(nk => {
          if (_next[nk].checked && !_next[nk][sectionCountTypes.qualifyCount])
            _qualifyReady = false
        })
      }

      return {
        ...state,
        levels_info: _levels_info,
        qualifyReady: _qualifyReady,
      }

    case rts.handleLevelSectionCount:

      const { data: data_levelCount } = action
      const { value: sectionCount, lskey: _levelKey } = data_levelCount

      ammendLevelSectionsFromCounts(_levels_info, null, _levelKey, sectionCount)

      return {
        ...state,
        levels_info: _levels_info,
        ready_levels: _.find(_next, { checked: true }),
        dataUpdates: { ...state.dataUpdates, sectionCounts: getSectionCounts(_levels_info) }
      }

    case rts.handleLevelCheck:
      if (_existing[levelItem._itemKey]) {
        _existing[levelItem._itemKey].checked = !itemChecked
      }
      _levels_info.existing = _existing
      return { ...state, levels_info: _levels_info, ready_levels: _.find(_next, { checked: true }) }

    case rts.handleSectionCheck:
      if (_existing[levelItem._itemKey] && _existing[levelItem._itemKey].sections && _existing[levelItem._itemKey].sections[sectionItem._itemKey]) {
        _existing[levelItem._itemKey].sections[sectionItem._itemKey].checked = !itemChecked
      }
      return { ...state, levels_info: _levels_info }

    case rts.handleSectionSelect:
      if (_existing[action.level] && _existing[action.level].sections) {
        const _sections = _existing[action.level].sections
        const sectionKeys = action.value
        Object.keys(_sections).forEach(sk => {
          if (sectionKeys.includes(sk)) {
            _existing[action.level].sections[sk].checked = true
          } else {
            _existing[action.level].sections[sk].checked = false
          }
        })
      }
      return { ...state, levels_info: _levels_info }

    // ****** POOLS *********

    case rts.handleCreate_pools:
      const _pools = {}
      for (var i = 1; i <= action.poolCount; i++) { _pools['pool' + i] = [] }
      return { ...state, pools: _pools }

    case rts.handleUpdate_pools:
      const { groupkey: poolKey, value: poolTeamKey } = action.selectedItem
      if (teams_selected) {
        const selectedTeam = _.find(teams_selected, { id: poolTeamKey })
        if (selectedTeam) {
          if (pools && pools[poolKey]) {
            pools[poolKey].push(selectedTeam)
            const picked = pickedTeams ? [...pickedTeams] : []
            if (!picked.includes(poolTeamKey)) { picked.push(poolTeamKey) }
            return { ...state, pools, pickedTeams: picked, ready_alignment: true }
          }
        }
      }

      return { ...state }

    case rts.handleUpdate_poolsFromSeeds:
      const { tournament } = action
      const { rounds } = tournament
      const round1 = rounds.round1
      const { pools: pools_round } = round1 ? round1 : {}
      if (pools_round) {
        Object.keys(pools_round).forEach(poolKey => {
          const pool = pools_round[poolKey]
          if (pools && pools[poolKey] && pool.teams) {
            Object.keys(pool.teams).forEach(teamKey => {
              const poolTeam = pool.teams[teamKey]
              pools[poolKey].push(poolTeam)
            })
          }
        })
      }
      return { ...state, pools, tournament }

    case rts.handleCurrentSectionCount:
      const csi1 = getCurrentSeasonInfo(state, action)
      return { ...state, season_info: csi1, currentSectionCount: action.currentSectionCount }

    case rts.handleSeasonDates:
      const csi2 = getCurrentSeasonInfo(state, action)
      return { ...state, season_info: csi2, dataUpdates: { ...state.dataUpdates, sectionDates: csi2.sectionDates } }

    case rts.handleCurrentDateGroup:
      const cpi1 = getCurrentPlayoffInfo(state, action)
      return { ...state, playoff_info: cpi1 }

    case rts.handlePlayoffDates:
      const cpi2 = getCurrentPlayoffInfo(state, action)
      return { ...state, playoff_info: cpi2 }

    case rts.handleSeasonDetails:
      let _monthStartDates;
      if (action.sportsDetails && action.sportsDetails.startDate) {
        const { monthStartDates } = action.sportsDetails.startDate ? getCalStartDates([action.sportsDetails.startDate], 3) : {}
        if (monthStartDates) {
          _monthStartDates = monthStartDates
        }
      }
      return { ...state, dataUpdates: { ...state.dataUpdates, details: action.sportsDetails }, sportsDetails: action.sportsDetails, monthStartDates: _monthStartDates }

    // case createSportsTypes.handleUpdate_poolsFromSeeds:
    //   return { ...state, tournament: action.tournament }

    case rts.handleHomeAway:
      if (!_seasonInfo.homeAways) { _seasonInfo.homeAways = {} }
      if (_seasonInfo.homeAways) {
        Object.keys(_seasonInfo.homeAways).forEach(k => {
          _seasonInfo.homeAways[k] = !_seasonInfo.homeAways[k]
        })
      }
      const { seasonMatches: sms3, seasonTeams: stms3 } = createTheSeasonMatches(next, sectionDates, _seasonInfo)
      _seasonInfo.matches = sms3
      return { ...state, season_info: _seasonInfo }

    case rts.handleSectionHomeAway:
      const _levelSectionKey = action.levelKey + '_' + action.sectionKey
      if (!_seasonInfo.homeAways) { _seasonInfo.homeAways = {} }
      _seasonInfo.homeAways[_levelSectionKey] = action.homeAway
      const { seasonMatches: sms2, seasonTeams: stms2 } = createTheSeasonMatches(next, sectionDates, _seasonInfo)
      _seasonInfo.matches = sms2
      return { ...state, season_info: _seasonInfo }

    case rts.handleCreate_seasonMatches:
      const { seasonMatches: sms, seasonTeams: stms } = createTheSeasonMatches(next, sectionDates, _seasonInfo, action.homeAway)
      _seasonInfo.matches = sms
      _seasonInfo.teams = stms
      return { ...state, season_info: _seasonInfo }

    case rts.handleUpdate_playoffInfo:
      _playoffInfo.tournaments = action.tournaments
      _playoffInfo.matches = action.playoffMatches
      return { ...state, playoff_info: _playoffInfo }


    // case rts.handleUpdate_seasonMatches:
    //   _seasonInfo.matches = action.seasonMatches
    //   return { ...state, season_info: _seasonInfo }

    case rts.handleCreate_playoff:
      createTournamentInfo(true, state, handleUpdate_playoffInfo)
      return { ...state }

    case rts.handleCreate_tournament:
      // teams_selected - array
      // seededTeams - object
      createTournamentInfo(false, state, handleUpdate_poolsFromSeeds)
      return { ...state }

    case rts.handleSeasonMatches:
      _seasonInfo.matches = seasonMatches
      return { ...state, season_info: _seasonInfo }

    case rts.handleTeamRosters:
      if (action.rosters) {
        const _teamRosters = addTeamInfoToTeamRosters(action.rosters, teams)
        const rosters = { teams: _.groupBy(_teamRosters, 'teams'), levels: _.groupBy(_teamRosters, 'levels'), sport: _teamRosters }
        return { ...state, rosters: rosters }
      }
      return { ...state }

    case rts.handleNominations:
      const { nominations } = action
      const { item: _first } = getFirstObject(nominations)
      const { athletes } = _first ? _first : {}
      let _nominations = {}
      if (athletes) {
        Object.keys(athletes).forEach(teamKey => {
          const team = athletes[teamKey]
          const { item: _level } = getFirstObject(team)
          if (_level) {
            _nominations = { ..._nominations, ..._level }
          }
        })
      }
      const _noms = _.groupBy(_nominations, 'levels')
      return { ...state, nominations: _noms }

    case rts.handleSelectedAthletes:
      let _selectedAthletes = { ...state.selectedAthletes }
      if (!_selectedAthletes) { _selectedAthletes = {} }
      if (!_selectedAthletes) { _selectedAthletes[levelItem] = _selectedAthletes[levelItem] = {} }
      _selectedAthletes[levelItem] = action.selectedAthletes
      let total = 0
      if (_selectedAthletes) {
        Object.keys(_selectedAthletes).forEach(k => {
          if (_selectedAthletes[k]) {
            total += Object.keys(_selectedAthletes[k]).length
          }
        })
      }
      return { ...state, selectedAthletes: _selectedAthletes, totalAthletes: total }

    case rts.handleSortedLevels:
      return {
        ...state,
        createRankings_info: {
          sortedLevels: action.sortedLevels,
          selectedLevel: action.selectedLevel,
          selectedWeek: action.selectedWeek
        }
      }

    case rts.handleSelectSchoolsOrTeamsSelected:
      const xxx = ammendSomething(state, action.teams_all)
      return { ...xxx }

    // ****** LEVELS AND SECTIONS *********

    case rts.handleCreate_levelSections:

      const { levels_info: _lst, teamCounts: _tc } = createLevelsAndSections(levels, sections, _levels_info)
      return { ...state, levels_info: _lst, teamCounts: _tc }

    // ****** UPDATES *********

    case rts.handleUpdate_teamSchools:
      const cbt = (result) => { console.log('Updated', result) }
      const parentKeys2 = getPks(pathViews, null, sportsKey)
      saveNewTeams_promise(action.newTeams, pathViews, parentKeys2, cbt)
      return { ...state }

    case rts.handleSeasonal_update:

      const cb0 = (result) => { console.log('Updated', result) }

      const { dataUpdates } = state
      const { teamsWithSections: _teamsWithSections, sectionDates: _sectionDates, sectionCounts: _sectionCounts, teams: _teamz } = dataUpdates

      if (_teamsWithSections) {
        Object.keys(_teamsWithSections).forEach(k => {
          delete _teamsWithSections[k].record
          delete _teamsWithSections[k].parentKeys
        })
      }

      const { latestSeason, opts: opts_a } = action
      const { dataToUpdate, currentMatches, isTemp, isBackup, isCommit } = opts_a

      if (currentMatches) {
        Object.keys(currentMatches).forEach(cmk => {
          if (!currentMatches[cmk].setsToWin) { currentMatches[cmk].setsToWin = 3 }
          if (!currentMatches[cmk].setCount) { currentMatches[cmk].setCount = 5 }
          delete currentMatches[cmk]._itemKey
        })
      }

      const _pks = getPks(pathViews, sportsYears, sportsKey)

      if (isCommit) {
        updateSeasonalOrGlobal(seasonalUpdateTypes.teams, pathViews, _pks, 'teams', 'teams', _teamsWithSections, cb0)
        updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, _pks, 'matches', 'matches', currentMatches, cb0)
      } else if (isBackup) {
        const _pksb = getPks(pathViews, latestSeason, sportsKey)

        updateSeasonalOrGlobal(seasonalUpdateTypes.backupMatches, pathViews, _pksb, 'backup_matches', 'matches', currentMatches, cb0)
      } else {
        switch (action.updateType) {

          case seasonalUpdateSteps.seasonAlignment:
          case seasonalUpdateSteps.seasonTeams:
            // _seasonals(parentKeys)/teams
            updateSeasonalOrGlobal(seasonalUpdateTypes.teams, pathViews, _pks, null, 'teams', _teamsWithSections, cb0)
            break;

          case seasonalUpdateSteps.seasonSectionTeams:
            // _seasonals(parentKeys)/teams
            updateSeasonalOrGlobal(seasonalUpdateTypes.teams, pathViews, _pks, null, 'teams', _teamz, cb0)
            break;

          case seasonalUpdateSteps.seasonDetails:
          case seasonalUpdateSteps.year:
            const details = removeEmpties(dataUpdates.details)
            delete details._deleteProps
            // _seasonals(parentKeys)/details 
            updateSeasonalOrGlobal(seasonalUpdateTypes.details, pathViews, _pks, null, 'details', details, cb0)
            break;

          case seasonalUpdateSteps.seasonSchedule:
            // _matches(parentKeys)/matches
            if (isTemp) {
              updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, _pks, null, 'matches', seasonMatches, cb0, { subCollectionName: 'matches' })
            } else {
              updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, _pks, null, 'matches', seasonMatches, cb0, { subCollectionName: 'matches' })
            }
            break;

          case seasonalUpdateSteps.seasonSectionDates:
            // _seasonals(parentKeys)/sectionDates
            updateSeasonalOrGlobal(seasonalUpdateTypes.sectionDates, pathViews, _pks, null, 'sectionDates', _sectionDates, cb0)
            break;

          case seasonalUpdateSteps.seasonSectionCount:
            // _seasonals(parentKeys)/sectionCounts
            updateSeasonalOrGlobal(seasonalUpdateTypes.sectionCounts, pathViews, _pks, null, 'sectionCounts', _sectionCounts, cb0)
            break;

          default:
          // nothing
        }
      }


      return { ...state }

    case rts.handleSeasonal_savePlayoffTournament:
      const cb4 = () => { console.log('Added') }
      const fullData1 = getFullPlayoffInfo(_playoffInfo)
      // updates the `_playoffs` collection with the document matching the parentKeys
      updateSeasonalOrGlobal(seasonalUpdateTypes.playoffBrackets, pathViews, getPks(pathViews, action.latestSeason, sportsKey), 'playoffs', null, fullData1, cb4)
      return { ...state }

    case rts.handleSeasonal_updatePlayoffTournament:
      const cb5 = () => { console.log('Added') }
      const _playoffInfos = getSelectedPlayoffInfo(_playoffInfo, action.playoffInfoType, pathViews, action.latestSeason)
      // updates the `_playoffs` collection with the document matching the parentKeys
      // _playoffs/playoffInfo 
      console.log('_playoffInfos', _playoffInfos)
      updateSeasonalOrGlobal(action.playoffInfoType, pathViews, getPks(pathViews, action.latestSeason, sportsKey), 'playoffs', null, null, cb5, null, _playoffInfos)
      return { ...state }

    case rts.handleCreate_teamRoster:
      const { newAthletes, newRosters, rosterType } = action
      const cb7 = () => { console.log('Added') }
      switch (rosterType) {
        case 'athletes':
          const pks_athletes = getPks(pathViews, action.latestSeason, sportsKey)
          updateSportsCollectionItemsWithParentKeys(seasonalUpdateTypes.athletes, pathViews, pks_athletes, 'athletes', newAthletes, cb7)
          break;
        case 'roster':
          const pks_roster = getPks(pathViews, action.latestSeason, sportsKey)
          updateSeasonalOrGlobal(seasonalUpdateTypes.rosters, pathViews, pks_roster, 'rosters', 'athletes', newRosters, cb7)
          break;
        default:
        // nothing
      }

      return { ...state }

    case rts.handleCreate_staff:
      const cb8 = () => { console.log('Added') }
      const pks_staff = getPks(pathViews, sportsYears, sportsKey)
      updateSeasonalOrGlobal(seasonalUpdateTypes.staff, pathViews, pks_staff, 'staff', 'staff', action.staff, cb8, { ignoreSeason: true, updateToMain: true })

    case rts.handleUpdate_allStarTeams:
      const pks_allStar = getPks(pathViews, latestSeason, sportsKey)
      // LOOK
      // updateSeasonalOrGlobal(seasonalUpdateTypes.allStarTeams, pathViews, pks_allStar, 'athletes', action.data, action.collectionName, true)
      return { ...state }

    case rts.handleUpdate_alignment:
      return { ...state }

    case rts.handleUpdate_rankings:
      const data_rankings = getRankingData(action)
      const _pkss = getPks(pathViews, state.latestSeason, sportsKey)
      updateSeasonalOrGlobal(seasonalUpdateTypes.rankings, pathViews, _pkss, 'rankings', null, data_rankings, action.callback)
      return { ...state }


    case rts.handleUpdate_seasonDetails:
      const cbx = (result) => { console.log('Updated', result) }
      const _pkssx = getPks(pathViews, state.latestSeason, sportsKey)
      // _seasonals(parentKeys)/teams/details
      updateSeasonalOrGlobal(seasonalUpdateTypes.details, pathViews, _pkssx, null, 'details', action.details, cbx)
      return { ...state }

    default:
      return { ...state }
  }
}

export const createSportsHandlers = (dispatch) => {
  return {
    handleInit_playoffTeams: (playoffTeams) => { dispatch({ type: rts.handleInit_playoffTeams, dispatch, playoffTeams }) },
    handleCheckLevelSection: (selectedItem) => { dispatch({ type: rts.handleCheckLevelSection, dispatch, selectedItem }) },
    handleCreate_levelSections: () => { dispatch({ type: rts.handleCreate_levelSections, dispatch }) },
    handleCreate_playoff: () => { dispatch({ type: rts.handleCreate_playoff, dispatch }) },
    handleCreate_pools: (poolCount) => { dispatch({ type: rts.handleCreate_pools, dispatch, poolCount }) },
    handleCreate_seasonMatches: (homeAway) => { dispatch({ type: rts.handleCreate_seasonMatches, dispatch, homeAway }) },
    handleCreate_staff: (staff) => { dispatch({ type: rts.handleCreate_staff, dispatch, staff }) },
    handleCreate_teamRoster: (latestSeason, newAthletes, newRosters, rosterType) => { dispatch({ type: rts.handleCreate_teamRoster, dispatch, latestSeason, newAthletes, newRosters, rosterType }) },
    handleCreate_tournament: () => { dispatch({ type: rts.handleCreate_tournament, dispatch }) },
    handleCurrentDateGroup: (levelKey) => { dispatch({ type: rts.handleCurrentDateGroup, dispatch, levelKey }) },
    handleCurrentSectionCount: (currentSectionCount) => { dispatch({ type: rts.handleCurrentSectionCount, dispatch, currentSectionCount }) },
    handleGetSeasonalData: (latestSeason) => { dispatch({ type: rts.handleGetSeasonalData, dispatch, latestSeason }) },
    handleGetSportsSeasonalData: (dataParents, sportsYears, collectionName) => { dispatch({ type: rts.handleGetSportsSeasonalData, dispatch, dataParents, sportsYears, collectionName }) },
    handleHomeAway: () => { dispatch({ type: rts.handleHomeAway, dispatch }) },
    handleInit_sportsData: () => { dispatch({ type: rts.handleInit_sportsData, dispatch }) },
    handleLevelCheck: (levelItem, itemChecked) => { dispatch({ type: rts.handleLevelCheck, dispatch, levelItem, itemChecked }) },
    handleLevelQualifyCount: (data) => { dispatch({ type: rts.handleLevelQualifyCount, dispatch, data }) },
    handleLevelSectionCount: (data) => { dispatch({ type: rts.handleLevelSectionCount, dispatch, data }) },
    handleLevelSectionTeams: (items, columnKey, nonSchools, teams) => { dispatch({ type: rts.handleLevelSectionTeams, dispatch, items, columnKey, nonSchools, teams }) },
    handleLevelsFromPaste: (items, levelName) => { dispatch({ type: rts.handleLevelsFromPaste, dispatch, items, levelName }) },
    handleNext: (next) => { dispatch({ type: rts.handleNext, dispatch, next }) },
    handleNominations: (nominations) => { dispatch({ type: rts.handleNominations, dispatch, nominations }) },
    handleNonSelectedSchools: (selected) => { dispatch({ type: rts.handleNonSelectedSchools, dispatch, selected }) },
    handlePlayoffDates: (playoffDateProps) => { dispatch({ type: rts.handlePlayoffDates, dispatch, playoffDateProps }) },
    handleSet_playoffInfo: (seasonalData) => { dispatch({ type: rts.handleSet_playoffInfo, dispatch, seasonalData }) },
    handleSeasonal_savePlayoffTournament: (latestSeason) => { dispatch({ type: rts.handleSeasonal_savePlayoffTournament, dispatch, latestSeason }) },
    handleSeasonal_update: (updateType, latestSeason, opts) => { dispatch({ type: rts.handleSeasonal_update, dispatch, updateType, latestSeason, opts }) },
    handleSeasonal_updatePlayoffTournament: (playoffInfoType, latestSeason) => { dispatch({ type: rts.handleSeasonal_updatePlayoffTournament, dispatch, playoffInfoType, latestSeason }) },
    handleSeasonDates: (seasonDateProps) => { dispatch({ type: rts.handleSeasonDates, dispatch, seasonDateProps }) },
    handleSeasonDetails: (sportsDetails) => { dispatch({ type: rts.handleSeasonDetails, dispatch, sportsDetails }) },
    handleSeasonMatches: (seasonMatches) => { dispatch({ type: rts.handleSeasonMatches, dispatch, seasonMatches }) },
    handleSetSeasonalData: (retData, fk) => { dispatch({ type: rts.handleSetSeasonalData, dispatch, retData, fk }) },
    handleSectionCheck: (sectionItem, itemChecked, levelItem) => { dispatch({ type: rts.handleSectionCheck, dispatch, sectionItem, itemChecked, levelItem }) },
    handleSectionHomeAway: (levelKey, sectionKey, homeAway) => { dispatch({ type: rts.handleSectionHomeAway, dispatch, levelKey, sectionKey, homeAway }) },
    handleSectionSelect: (level, value) => { dispatch({ type: rts.handleSectionSelect, dispatch, level, value }) },
    handleSelectedAthletes: (level, selectedAthletes) => { dispatch({ type: rts.handleSelectedAthletes, dispatch, level, selectedAthletes }) },
    handleSelectedTeams: (selected) => { dispatch({ type: rts.handleSelectedTeams, dispatch, selected }) },
    handleSelectSchoolsOrTeamsSelected: (teams_all) => { dispatch({ type: rts.handleSelectSchoolsOrTeamsSelected, dispatch, teams_all }) },
    handleSelect_team: (team) => { dispatch({ type: rts.handleSelect_team, dispatch, team }) },
    handleSetDataResults: (dataResult) => { dispatch({ type: rts.handleSetDataResults, dispatch, dataResult }) },
    // handleSetPlayoffTeamsFromStandings: (sportsTeams) => { dispatch({ type: rts.handleSetPlayoffTeamsFromStandings, dispatch, sportsTeams }) },
    handleSetSportsSeasonalDataItem: (data, opts) => { dispatch({ type: rts.handleSetSportsSeasonalDataItem, dispatch, data, opts }) },
    handleSortedLevels: (sortedLevels, selectedLevel, selectedWeek) => { dispatch({ type: rts.handleSortedLevels, dispatch, sortedLevels, selectedLevel, selectedWeek }) },
    handleTeamRosters: (rosters) => { dispatch({ type: rts.handleTeamRosters, dispatch, rosters }) },
    handleTeamsInfo: (info, teamsFromSchools) => { dispatch({ type: rts.handleTeamsInfo, dispatch, info, teamsFromSchools }) },
    // handleUpdateCreateData: () => { dispatch({ type: rts.handleUpdateCreateData, dispatch }) },
    handleUpdate_playoffInfo: (tournaments, playoffMatches) => { dispatch({ type: rts.handleUpdate_playoffInfo, dispatch, tournaments, playoffMatches }) },
    handleUpdate_playoffSeededTeams: (playoffLevelTeams) => { dispatch({ type: rts.handleUpdate_playoffSeededTeams, dispatch, playoffLevelTeams }) },
    handleUpdate_pools: (selectedItem) => { dispatch({ type: rts.handleUpdate_pools, dispatch, selectedItem }) },
    handleUpdate_poolsFromSeeds: (tournament) => { dispatch({ type: rts.handleUpdate_poolsFromSeeds, dispatch, tournament }) },
    // handleUpdate_seasonMatches: (seasonMatches) => { dispatch({ type: rts.handleUpdate_seasonMatches, dispatch, seasonMatches }) },
    handleUpdate_seededTeams: (seededTeams) => { dispatch({ type: rts.handleUpdate_seededTeams, dispatch, seededTeams }) },
    handleUpdate_teamSchools: (newTeams) => { dispatch({ type: rts.handleUpdate_teamSchools, dispatch, newTeams }) },
    // updateLevelSectionsClick: (selectedItem) => { dispatch({ type: rts.updateLevelSectionsClick, dispatch, selectedItem }) },

    handleUpdate_allStarTeams: (data, collectionName) => { dispatch({ type: rts.handleUpdate_allStarTeams, dispatch, data, collectionName }) },
    handleUpdate_alignment: (selectedItem) => { dispatch({ type: rts.handleUpdate_alignment, dispatch, selectedItem }) },
    handleUpdate_rankings: (actionProps, handleDone) => { dispatch({ type: rts.handleUpdate_rankings, dispatch, actionProps, handleDone }) },
    handleUpdate_seasonDetails: (details, latestSeason, callback) => { dispatch({ type: rts.handleUpdate_seasonDetails, dispatch, details, latestSeason, callback }) },
  }
}

export const createSportsInitialState = (init_state) => {

  const { teams } = init_state

  const _teams = getPlayoffTeamsInit(teams)
  const _playoffLevelTeams = getPlayoffLevelTeamsInit(teams)
  const _playoffDates = {}
  const _playoffDatesReady = {}
  const _playoffDatesNeeded = {}

  if (_playoffLevelTeams) {
    Object.keys(_playoffLevelTeams).forEach(key => {
      const lt = _playoffLevelTeams[key]
      _playoffDates[key] = []
      _playoffDatesReady[key] = false
      _playoffDatesNeeded[key] = datesNeeded(lt.length)
    })
  }

  return {
    ...init_state,
    dataUpdates: {},
    sportsDetails: {},
    levels_info: {
      existing: {},
      next: {},
      ready: false,
    },
    schools_info: {
      all: {},
      nonSelected: {},
      selected: {},
    },
    teams_info: {
      all: {},
      counts: [],
      playoff: {},
      nonSelected: {},
      selected: {},
    },
    sports_info: {
      levels: {},
      sections: {},
      schools: {},
      teams: {}
    },
    season_info: {
      sectionDates: {},
      sectionDatesReady: {}
    },
    playoff_info: {
      teams: _teams,
      playoffLevelTeams: _playoffLevelTeams,
      playoffDates: _playoffDates,
      playoffDatesReady: _playoffDatesReady,
      playoffDatesNeeded: _playoffDatesNeeded,
    },
    parentDataKeys: {}
  };
}

const datesNeeded = (teamCount) => {
  switch (true) {
    case teamCount > 16 && teamCount <= 32:
      return 5
    case teamCount > 8 && teamCount <= 16:
      return 4
    case teamCount > 4 && teamCount <= 8:
      return 3
    case teamCount > 3 && teamCount <= 4:
      return 2
    default:
    // nothing
  }
}

const createLevelsAndSections = (levels, sections, _levels_info) => {

  const _levels = Object.assign({}, levels)
  const _sections = Object.assign({}, sections)

  if (_levels && _sections) {
    const ls = {}
    Object.keys(_levels).forEach(lk => {
      const level = _levels[lk]
      ls[level._itemKey] = { name: level.name, checked: false, sections: {} }
      Object.keys(_sections).forEach(sk => {
        const section = _sections[sk]
        if (section.name !== 'Non-Section') {
          ls[level._itemKey].sections[section._itemKey] = { name: section.name, checked: false }
        }
      })
    })
    const _existing = _.cloneDeep(ls)
    const _next = _.cloneDeep(ls)
    _levels_info.existing = _existing
    _levels_info.next = _next
    const _tc = getTeamCountOptions(_.pickBy(_next, { checked: true }))
    return { levels_info: _levels_info, teamCounts: _tc }
  }
  return {}
}

const ammendSomething = (state, teams_all) => {
  const { pathViews, teamsFromSchools } = state
  const _data = updateTeamsSelected(state, teams_all, teamsFromSchools)
  if (teamsFromSchools) {
    const { teams: _teamsAll1 } = getTeamsFromSelectedData(_data, pathViews, teamsFromSchools ? false : true)
    return { ...state, schools_info: _data, dataUpdates: { ...state.dataUpdates, teams: _teamsAll1 } }
  } else {
    const { teams: _teamsAll2 } = getTeamsFromSelectedData(_data, pathViews, teamsFromSchools ? false : true)
    return { ...state, teams_info: _data, dataUpdates: { ...state.dataUpdates, teams: _teamsAll2 } }
  }
}

const getPlayoffTeamsInit = (teams) => {
  const _teams = _.filter(teams, (t) => t.record && t.record.playoffTeam)
  return _teams
}

const getPlayoffLevelTeamsInit = (teams) => {
  const _teams = _.filter(teams, (t) => t.record && t.record.playoffTeam)
  const teams_levels = _teams ? _.groupBy(_teams, 'levels') : null
  return teams_levels
}

const ammendSportsDataFromResult = (_sportsData, action) => {

  const { dataResult } = action

  const { levels, schools, sections, teams } = dataResult ? dataResult : {}

  if (levels) {
    createItemKeys(levels)
    _sportsData['levels'] = levels
  }

  if (sections) {
    createItemKeys(sections)
    _sportsData['sections'] = _.sortBy(sections, '_itemKey')
  }

  if (teams) {
    _sportsData['teams'] = teams
    _sportsData['teams_district_sports'] = teams
  }

  if (schools) {
    getSchoolsWithTeams(schools, teams)
    _sportsData['schools'] = schools
    _sportsData['schools_district'] = schools
  }

  const x = getSelectedTeamsFromSchools(schools, teams)
  const _school_data = getDataInfo(schools, x.selectedIndexes)
  const _teams = teams ? teams : _school_data.all
  const _hasTeams = teams ? true : false

  return { _teams, _hasTeams, _school_data } //, selectedIndexes: x.selectedIndexes }
}

/**
 * Adds a new `team` to the `teams` collection for each team in `teams`
 * @param {object} teams 
 * @param {object} pathViews 
 * @param {function} callback 
 */
const saveNewTeams_promise = async (teams, pathViews, parentKeys, callback) => {
  const result = await saveNewTeams(teams, pathViews, parentKeys)
  callback(result)
}

/**
 * Adds a new team to the `teams` collection for each team in `teams`
 * @param {object} teams 
 * @param {object} pathViews 
 */
const saveNewTeams = async (teams, pathViews, parentKeys) => {
  const promises = []
  Object.keys(teams).forEach((k, index) => {
    const _newTeam = teams[k]
    const teamKey = createFsDocKey()
    _newTeam.parentKeys = parentKeys
    _newTeam.parentKeys.schools = _newTeam._itemKey
    delete _newTeam.checked
    delete _newTeam.disabled
    delete _newTeam.key
    delete _newTeam._itemKey
    delete _newTeam.sportsTeamKey
    const _ref = createRefPath_event(pathViews, ['teams', teamKey])
    promises.push(fs_set_doc(_ref, _newTeam, true, null, true))
  })
  return Promise.all(promises)
}


const ammendLevelSectionsFromCounts = (levels_info, sectionCounts, levelKey, sectionCount) => {
  const { next } = levels_info
  const _next = { ...next }
  if (sectionCounts) {
    Object.keys(sectionCounts).forEach(_levelKey => {
      const _sectionCount = sectionCounts[_levelKey]
      ammendNext(_next, _levelKey, _sectionCount)
    })
  } else if (levelKey && sectionCount) {
    ammendNext(_next, levelKey, sectionCount)
  }
  levels_info.next = _next
}

const ammendNext = (next, levelKey, sectionCount) => {
  if (next && next[levelKey]) {
    next[levelKey][sectionCountTypes.checked] = sectionCount > 0 // boolean
    next[levelKey][sectionCountTypes.sectionCount] = sectionCount > 0 ? sectionCount : false // boolean
    if (next[levelKey].sections) {
      Object.keys(next[levelKey].sections).forEach(sk => {
        const section = next[levelKey].sections[sk]
        const { name } = section
        const _nameIndex = name.replace('Section ', '')
        section[sectionCountTypes.checked] = parseInt(_nameIndex) <= sectionCount
      })
    }
  }
}

const getSchoolsWithTeams = (schools, teams) => {
  if (teams && schools) {
    Object.keys(teams).forEach(tk => {
      const team = teams[tk]
      if (team && team.parentKeys && team.parentKeys.schools) {
        if (schools[team.parentKeys.schools]) {
          schools[team.parentKeys.schools].sportsTeamKey = tk
        }
      }
    })
  }
}

// 147 / 1333
// Canevin
// Wilkinsburg
// Wilson Christian Academy
// West Shamokin
// Vincentian
// Gr. Works Academy
// Dayton
// Derry / Derry Area

const getTeamsFromLevelSections = (levels_info, dataUpdates) => {
  const _teams = dataUpdates && dataUpdates.teams ? dataUpdates.teams : {}
  const { next } = levels_info
  if (next) {
    Object.keys(next).forEach(levelKey => {
      const level = next[levelKey]
      const { sections } = level ? level : {}
      if (sections) {
        Object.keys(sections).forEach(sectionKey => {
          const section = sections[sectionKey]
          const { teams } = section ? section : {}
          if (teams) {
            Object.keys(teams).forEach((teamKey, index) => {
              const team = teams[teamKey]
              const { id, _itemKey, position, name } = team
              const _id = _itemKey ? _itemKey : id
              _teams[_id] = {
                levels: levelKey,
                sections: sectionKey,
                position: _.isNaN(position) ? position : index,
                name
              }
            })
          }
        })
      }
    })
  }
  return { teams: _teams }
}

const getRankingData = (action) => {
  const { actionProps } = action
  const { selectedLevel, selectedWeek, sortedLevels } = actionProps
  const sortedLevel = sortedLevels[selectedLevel]
  const teams = []

  // const _mk = _matchType + '.' + _matchKey

  sortedLevel.forEach((sl, index) => {
    if (index < 10) { teams.push(sl.name) }
  })

  const data_rankings = {}
  data_rankings['rankings' + '.' + selectedWeek + '.' + selectedLevel] = teams;

  // const data_rankings = {
  //   [selectedWeek]: {
  //     [selectedLevel]: teams
  //   }
  // }
  return data_rankings
}

/**
 * Creates a new `team` for each `selected` item within `schools_info`
 * Generates the `teamKey` using `createFsDocKey()`
 * @param {object} data 
 * @param {object} pathViews 
 * @returns 
 */
const getTeamsFromSelectedData = (data, pathViews, forSeason) => {
  const { selected } = data
  const _teams = {}
  if (selected) {
    Object.keys(selected).forEach(key => {
      const selectedItem = selected[key]
      const { name, _itemKey } = selectedItem
      if (forSeason) {
        _teams[_itemKey] = {
          name,
          sections: 0,
          levels: 0,
          position: 0,
        }
      } else {
        const teamKey = createFsDocKey()
        _teams[teamKey] = {
          name,
          parentKeys: {
            organizations: pathViews.organizations,
            districts: pathViews.districts,
            schools: _itemKey,
            sports: pathViews.sports,
          }
        }
      }

    })
  }
  return { teams: _teams }
}

const getSectionCounts = (levels_info) => {
  const _sectionCounts = {}
  const { next } = levels_info
  if (next) {
    Object.keys(next).forEach(levelKey => {
      const level = next[levelKey]
      const { sectionCount } = level ? level : {}
      if (sectionCount > 0) {
        _sectionCounts[levelKey] = sectionCount
      }
    })
  }
  return _sectionCounts
}

const getSeasonTeams = (teams_selected, pathViews, sportsYears) => {
  const _teams = {}
  if (teams_selected) {
    teams_selected.forEach(ts => {
      const id = ts.id
      _teams[ts.id] = ts
      delete _teams[id].parentKeys
      delete _teams[id].id
      delete _teams[id].schools
    })
  }
  return { teams: _teams, parentKeys: getPks(pathViews, sportsYears) }
}

const getSectionDates = (sectionDates, pathViews, sportsYears) => {
  const _sds = {}
  Object.keys(sectionDates).forEach(key => {
    if (sectionDates[key].length > 0) {
      _sds[key] = sectionDates[key]
    }
  })
  return { sectionDates: _sds, parentKeys: getPks(pathViews, sportsYears) }
}

/**
 * 
 * @param {object} teams 
 * @param {object} lsts 
 * @returns updates the levels/sections with their teams
 */
const ammendLstsFromTeams = (teams, lsts, forHomeAway) => {
  const homeAways = {}
  let ready_alignment = false;
  let groupedLevels = _.groupBy(teams, 'levels')
  if (lsts && groupedLevels) {
    Object.keys(groupedLevels).forEach(lvKey => {
      const level = groupedLevels[lvKey]
      const selectedLevel = _.find(lsts, { name: lvKey })
      if (selectedLevel) {
        const _sections = []
        selectedLevel.checked = true
        const { sections } = selectedLevel
        let groupedSections = _.groupBy(level, 'sections')
        if (groupedSections) {
          Object.keys(groupedSections).forEach(sKey => {
            let selectedSection = _.find(sections, { name: sKey })
            if (!selectedSection) {
              selectedSection = _.find(sections, { name: _.startCase(sKey) })
            }
            if (selectedSection) {
              selectedSection.checked = true
              _sections.push(_.camelCase(sKey))
              const _teams = _.filter(teams, { levels: lvKey, sections: sKey })
              const _levelSectionKey = lvKey + '_' + sKey
              selectedSection.teams = _teams
              ready_alignment = true
              homeAways[_levelSectionKey] = true
            }
          })
        }
      }
    })
  }
  if (forHomeAway) {
    return { ready_alignment, homeAways }
  } else {
    return ready_alignment
  }
}

const addTeamInfoToTeamRosters = (rosters, allTeams) => {
  const _mergedData = []
  // adding the parentKey info to each of the data items
  Object.keys(rosters).forEach(rdKey => {
    const rosterGroup = rosters[rdKey]
    const { parentKeys } = rosterGroup
    const { teams } = parentKeys ? parentKeys : {}
    const rdiData = rosterGroup['athletes']
    Object.keys(rdiData).forEach(keyy => {
      if (teams) { rdiData[keyy].teams = teams }
      if (allTeams) {
        const t = _.find(allTeams, { id: teams })
        if (t) {
          const { name, levels, sections } = t
          if (levels) { rdiData[keyy].levels = levels }
          if (sections) { rdiData[keyy].sections = sections }
          if (name) { rdiData[keyy].teams = name }
          rdiData[keyy].key = keyy
        }
      }
      _mergedData.push(rdiData[keyy])
    })
  })
  return _mergedData
}

/**
 * add a team to a level/section
 * @param {object} state 
 * @param {array} groupKeySplit 
 * @param {object} _lsts 
 * @param {array} teams_selected 
 * @param {array} pickedTeams 
 * @param {string} teamKey 
 * @returns 
 */
const addTeamToSection = (state, groupKeySplit, _lsts, teams_selected, pickedTeams, teamKey) => {
  if (teams_selected) {
    const selectedTeam = _.find(teams_selected, { id: teamKey })
    if (selectedTeam) {
      const levelKey = groupKeySplit[0]
      const sectionKey = _.camelCase(groupKeySplit[1])
      selectedTeam.levels = levelKey
      selectedTeam.sections = sectionKey
      selectedTeam.levelSection = levelKey + '-' + sectionKey
      if (_lsts && _lsts[levelKey] && _lsts[levelKey].sections && _lsts[levelKey].sections[sectionKey]) {
        if (!_lsts[levelKey].sections[sectionKey].teams) { _lsts[levelKey].sections[sectionKey].teams = [] }
        selectedTeam.position = _lsts[levelKey].sections[sectionKey].teams.length
        _lsts[levelKey].sections[sectionKey].teams.push(selectedTeam)
        const picked = pickedTeams ? [...pickedTeams] : []
        if (!picked.includes(teamKey)) { picked.push(teamKey) }
        return { ...state, lsts: _lsts, pickedTeams: picked, ready_alignment: true }
      }
    }
  }
}

const ammendTeam = (teamKey, team, sportsYears) => {
  let { parentKeys } = team
  if (!parentKeys) { parentKeys = {} }
  parentKeys.sportsYears = sportsYears
  return {
    levels: team.levels,
    sections: team.sections,
    schools: team.schools,
    name: team.name,
    parentKeys: team.parentKeys,
    id: team.id ? team.id : teamKey,
    position: team.position
  }
}

const getTeamCountOptions = (activeLevelsAndSections) => {
  const optsA = []
  if (activeLevelsAndSections) {
    Object.keys(activeLevelsAndSections).forEach(key => {
      const ls = activeLevelsAndSections[key]
      if (ls && ls.sections)
        Object.keys(ls.sections).forEach(skey => {
          const section = ls.sections[skey]
          const { teams } = section ? section : {}
          if (teams) {
            if (teams.length > 1) {
              if (!optsA.includes(teams.length)) {
                optsA.push(teams.length)
              }
            }
          }
        })

    })
  } else {
    for (var i = 3; i <= 10; i++) {
      optsA.push(i + ' Teams')
    }
  }
  return optsA.sort()
}

const getDataInfo = (schoolsOrTeams, _selectedIndexes, teams_seasonal) => {

  const _all = {}
  const _selected = {}
  const _nonSelected = {}

  if (schoolsOrTeams) {
    // loop the schools or teams
    Object.keys(schoolsOrTeams).forEach(key => {
      const si = schoolsOrTeams[key]
      const { name, _itemKey, sportsTeamKey } = si
      const _key = _itemKey ? _itemKey : key
      const checked = _selectedIndexes && _selectedIndexes.includes(_key)
      _all[_key] = { key, name, checked, _itemKey: _key, sportsTeamKey, disabled: sportsTeamKey ? true : false }
    })
  }

  if (_all) {
    Object.keys(_all).forEach((key, index) => {
      const item = _all[key]
      const { checked, _itemKey } = item
      const ts = teams_seasonal && teams_seasonal[_itemKey]
      const _selOrNon = checked ? _selected : _nonSelected
      _selOrNon[_itemKey] = { ...item, checked: checked }
      if (ts) {
        const { levels, sections, position } = ts
        _selOrNon[_itemKey].levels = levels
        _selOrNon[_itemKey].sections = sections
        _selOrNon[_itemKey].position = position
      }
    })
  }

  return { all: _all, selected: _selected, nonSelected: _nonSelected }
}

const updateTeamsSelected = (state, teams_all, teamsFromSchools) => {

  const _data = teamsFromSchools ? { ...state.schools_info } : { ...state.teams_info }

  const _selected = {}
  const _nonSelected = {}

  if (teams_all) {
    Object.keys(teams_all).forEach((key, index) => {
      const school = teams_all[key]
      const { name, _itemKey, checked } = school
      if (checked) {
        _selected[_itemKey] = { name, checked: true, _itemKey }
      } else {
        _nonSelected[_itemKey] = { name, checked: false, _itemKey }
      }
    })
  }

  _data.selected = _selected
  _data.nonSelected = _nonSelected

  return _data

}

/**
 * 
 * @param {object} state 
 * @param {object} action  
 * }
 */
const getCurrentSeasonInfo = (state, action) => {

  const { season_info, currentSectionCount } = state

  const { seasonDateProps, currentSectionCount: currentSectionCount_a } = action ? action : {}
  const { teamCount, selectedDates } = seasonDateProps ? seasonDateProps : {}

  const { sectionDates, sectionDatesReady } = season_info ? season_info : {}

  const _currentSectionCount = currentSectionCount_a ? currentSectionCount_a : currentSectionCount

  if (selectedDates && selectedDates.length > 0) {
    sectionDates[_currentSectionCount] = selectedDates ? selectedDates.sort() : []
  }

  let needed = _currentSectionCount * 2
  if (!isOdd(_currentSectionCount)) { needed = needed - 2 }

  if (sectionDates[_currentSectionCount]) {
    sectionDatesReady[_currentSectionCount] = sectionDates[_currentSectionCount].length === needed
  }

  let _scheduleDatesAreReady = true;
  if (sectionDatesReady) {
    Object.keys(sectionDatesReady).forEach(key => {
      if (!sectionDatesReady[key]) {
        _scheduleDatesAreReady = false
      }
    })
  }

  season_info.sectionDatesReady = sectionDatesReady
  season_info.sectionDates = sectionDates

  season_info.scheduleDatesAreReady = _scheduleDatesAreReady

  season_info.scheduleDatesInfo = {
    currentNeeded: needed,
    currentCount: sectionDates[_currentSectionCount] ? sectionDates[_currentSectionCount].length : 0,
    currentDates: sectionDates[_currentSectionCount],
  }

  return season_info

}

const getCurrentPlayoffInfo = (state, action) => {

  const { currentLevelKey } = state
  let { playoff_info } = state
  const { playoffDateProps, levelKey: levelKey_a } = action ? action : {}
  const { selectedDates, levelKey } = playoffDateProps ? playoffDateProps : {}

  if (!playoff_info) { playoff_info = {} }

  const { playoffDates, playoffDatesReady, playoffDatesNeeded } = playoff_info ? playoff_info : {}


  let _currentLevelKey = levelKey_a ? levelKey_a : currentLevelKey
  if (!_currentLevelKey && levelKey) { _currentLevelKey = levelKey }

  if (_currentLevelKey) {

    if (selectedDates && selectedDates.length > 0) {
      playoffDates[_currentLevelKey] = selectedDates ? selectedDates.sort() : []
    }

    let needed = playoffDatesNeeded[_currentLevelKey]

    if (playoffDates[_currentLevelKey]) {
      playoffDatesReady[_currentLevelKey] = playoffDates[_currentLevelKey].length === needed
    }

    playoff_info.playoffDatesReady = playoffDatesReady
    playoff_info.playoffDates = playoffDates

    let _playoffDatesAreReady = true;

    if (playoffDatesReady) {
      Object.keys(playoffDatesReady).forEach(key => {
        if (!playoffDatesReady[key]) {
          _playoffDatesAreReady = false
        }
      })
    }

    playoff_info.playoffDatesAreReady = _playoffDatesAreReady

    playoff_info.playoffDatesInfo = {
      currentNeeded: needed,
      currentCount: playoffDates[_currentLevelKey] ? playoffDates[_currentLevelKey].length : 0,
      currentDates: playoffDates[_currentLevelKey] ? playoffDates[_currentLevelKey] : [],
      currentLevelKey: _currentLevelKey
    }
  }

  return playoff_info

}

/**
 * 
 * @param {object} teams_seasonal 
 * @param {object} sports_info 
 * @param {object} sportsDetails 
 * @param {object} levels_info 
 * @param {boolean} forSeason 
 * @returns 
 */
const getSelectedTeams = (teams_seasonal, state, levels_info, forSeason) => {

  const { sportsDetails, sports_info, schools_info, teams_info } = state
  const { existing, next } = levels_info
  const { schools: schools_sd, teams: teams_sd } = sports_info

  const { sportsYears } = sportsDetails ? sportsDetails : {}

  let playoff_teamz = _.filter(teams_seasonal, function (st) { return st.record && st.record.playoffTeam })

  const _selectedSchoolIndexes = []
  const _selectedTeams = {}
  const _selectedTeamIndexes = []

  let _homeAways;
  let ready_levels;

  const _teams = {}

  if (teams_seasonal) {
    Object.keys(teams_seasonal).forEach((teamKey, index) => {
      _teams[teamKey] = ammendTeam(teamKey, teams_seasonal[teamKey], sportsYears)
    })
  }

  if (forSeason) {
    Object.keys(_teams).forEach((teamKey, index) => {
      _selectedTeamIndexes.push(teamKey)
      _selectedTeams[teamKey] = _teams[teamKey]
    })

    if (schools_sd) {
      Object.keys(_teams).forEach((teamKey, index) => {
        const t = _teams[teamKey]
        const { name: n } = t
        const selectedSchoolIndex = _.findIndex(schools_sd, { name: n })
        _selectedSchoolIndexes.push(selectedSchoolIndex)
      })
    }

    ammendLstsFromTeams(_teams, existing, true)

    const { ready_alignment, homeAways } = ammendLstsFromTeams(_teams, next, true)

    ready_levels = ready_alignment
    _homeAways = homeAways

  } else {
    Object.keys(_teams).forEach((teamKey, index) => {
      if (index < 16) {
        _selectedTeamIndexes.push(index)
        _selectedTeams[teamKey] = _teams[teamKey]
      }
    })
  }

  return {
    _selectedSchoolIndexes,
    _selectedTeamIndexes,
    _selectedTeams,
    homeAways: _homeAways,
    levels_info,
    playoff_teamz,
    ready_levels,
  }
}

const createTheSeasonMatches = (next, sectionDates, season_info, homeAway) => {
  const _allTeams = {}
  let xx = _.pickBy(next, { checked: true })
  if (xx) {
    Object.keys(xx).forEach(key => {
      const ls = xx[key]
      if (ls && ls.sections)
        Object.keys(ls.sections).forEach(skey => {
          const section = ls.sections[skey]
          const { checked, teams } = section ? section : {}
          if (checked && teams) {
            if (teams.length > 1) {
              teams.forEach((team) => {
                _allTeams[team.id] = team
                _allTeams[team.id].position = team.position
              })
            }
          }
        })
    })
  }
  const { allMatches } = createScheduledMatches(sectionDates, _allTeams, season_info, homeAway)
  return { seasonMatches: allMatches, seasonTeams: _allTeams }
}

const ammendLevelSeedings = (_playoffInfo) => {
  const { playoffLevelTeams } = _playoffInfo
  Object.keys(playoffLevelTeams).forEach(key => {
    const teams = playoffLevelTeams[key]
    let _teams = _.filter(teams, function (st) { return st.record && st.record.playoffTeam })
    const pst_seeded = sortPlayoffLevelTeams(_teams)
    playoffLevelTeams[key] = pst_seeded[key]
  })
}

const getSelectedPlayoffInfo = (playoffInfo, playoffInfoType, pathViews, latestSeason) => {

  let _playoffInfoType;

  switch (playoffInfoType) {
    case seasonalUpdateTypes.playoffDates:
      _playoffInfoType = 'playoffDates'
      break;
    case seasonalUpdateTypes.playoffTeams:
    case seasonalUpdateTypes.playoffSeeds:
      _playoffInfoType = 'playoffLevelTeams'
      break;
    case seasonalUpdateTypes.playoffDates:
      _playoffInfoType = 'playoffDates'
      break;
    case seasonalUpdateTypes.playoffBracket:
      _playoffInfoType = 'matches'
      break;
    default:
      break;
  }

  let selectedInfo = playoffInfo && _playoffInfoType && playoffInfo[_playoffInfoType] ? playoffInfo[_playoffInfoType] : null

  switch (playoffInfoType) {
    case seasonalUpdateTypes.playoffSeeds:
      const _selectedInfo = {}
      Object.keys(selectedInfo).forEach(levelKey => {
        _selectedInfo[levelKey] = []
        const teams_level = selectedInfo[levelKey] // arrayOfTeams
        if (teams_level) {
          teams_level.forEach(team => {
            const { id, levels, sections, name, position, playoffRank, seed } = team
            const _info = { id, levels, sections, name, position, seed }
            if (playoffRank) { _info.playoffRank = playoffRank }
            _selectedInfo[levelKey].push(_info)
          })
        }
      })
      selectedInfo = _selectedInfo
      console.log('_selectedInfo', _selectedInfo)
      break;
    default:
    // nothing
  }

  if (selectedInfo) {
    return {
      dtu: { ['playoffInfo.' + _playoffInfoType]: selectedInfo },
      dta: { playoffInfo: { [_playoffInfoType]: selectedInfo } }
    }
  }
}

const getFullPlayoffInfo = (playoffInfo) => {

  const { tournaments: playoffTournaments, matches: playoffMatches, playoffLevelTeams, playoffDates } = playoffInfo ? playoffInfo : {}

  const _pms = removeEmpty(playoffMatches)
  // if (playoffMatches) {
  //   cleanUpData(playoffMatches)
  // }

  if (playoffTournaments) {
    return {
      ['playoffInfo.playoffDates']: playoffDates,
      ['playoffInfo.playoffLevelTeams']: playoffLevelTeams,
      ['playoffMatches']: _pms,
    }

  }
}

const createLevelsFromPaste = (state, items, levelName) => {

  const { next, teams_selected, schools_info, nonSchools } = state

  const { all: schools_all } = schools_info ? schools_info : {}

  const levels = next ? next : {}
  const teams = teams_selected ? teams_selected : []
  const _nonSchools = nonSchools ? nonSchools : []

  if (!levels[levelName]) {
    levels[levelName] = {
      checked: true,
      name: levelName,
      sections: {}
    }
  } else {
    levels[levelName].checked = true
  }

  const level = levels[levelName]

  items.forEach(item => {
    Object.keys(item).forEach((sectionKey, index) => {

      const teamName = item[sectionKey]
      const { school: _school, tn } = findSchool(teamName, schools_all)

      if (!level.sections[sectionKey]) {
        level.sections[sectionKey] = {
          checked: true,
          name: _.startCase(sectionKey),
          teams: []
        }
      } else {
        level.sections[sectionKey].checked = true
      }

      const team = {
        id: createFsDocKey(),
        name: tn,
        sections: sectionKey,
        levels: levelName,
        parentKeys: {
          schools: _school ? _school._itemKey : 0
        },
      }
      if (!_school) { _nonSchools.push(teamName) }
      teams.push(team)
      if (!level.sections[sectionKey].teams) { level.sections[sectionKey].teams = [] }
      const tsc = level.sections[sectionKey].teams.length
      team.position = tsc
      level.sections[sectionKey].teams.push(team)
    })
  })
  return { levels, teams, nonSchools: _nonSchools }
}

export const findSchool = (teamName, schools_all) => {

  let tn = teamName

  switch (tn) {
    case 'Canon McMillian':
    case 'Canon-McMillian':
      tn = 'Canon McMillan'
      break;
    case 'Bethlehem-Center':
      tn = 'Beth Center'
      break;
    case 'OLSH':
      tn = 'O.L.S.H.'
      break;
    case 'Waynesburg Central':
      tn = 'Waynesburg'
      break;
    case 'Eden Christian Acad.':
      tn = 'Eden Christian'
      break;
    case 'Hillcrest Christ. Acad.':
      tn = 'Hillcrest Christian'
      break;
    case 'Serra Catholic':
      tn = 'Serra'
      break;
    case 'Geibel Catholic':
      tn = 'Geibel'
      break;
    case 'South Side':
      tn = 'South Side Beaver'
      break;
    default:
    // nothing 
  }

  let school = _.find(schools_all, { name: tn })

  if (!school) {
    tn = tn.replace('-', ' ')
    school = _.find(schools_all, { name: tn })
  }

  if (!school) {
    if (tn.indexOf('Co.') >= 0) {
      tn = tn.replace('Co.', 'County')
      school = _.find(schools_all, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('C.C.') >= 0) {
      tn = tn.replace('C.C.', 'Central Catholic')
      school = _.find(schools_all, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Ft.') >= 0) {
      tn = tn.replace('Ft.', 'Fort')
      school = _.find(schools_all, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Saint') >= 0) {
      tn = tn.replace('Saint', 'St.')
      school = _.find(schools_all, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Area') >= 0) {
      tn = tn.replace(' Area', '')
      school = _.find(schools_all, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Greater') >= 0) {
      tn = tn.replace('Greater ', '')
      school = _.find(schools_all, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Neighborhood Acad ©') >= 0) {
      tn = tn.replace('Neighborhood Acad ©', 'Neighborhood Academy')
      school = _.find(schools_all, { name: tn })
    }
  }

  if (!school) {
    tn += ' Area'
    school = _.find(schools_all, { name: tn })
  }

  if (!school) {
    console.log('school', school)
  }

  return { school, tn }

}
