import React, { useState } from 'react';
import { Card, Icon, Label } from 'semantic-ui-react';
import { formatTimeAmPm } from '../../../common/dateFormatting';
import { uniqueKey } from '../../../common/keys';

const _size = 'small'

const _icons = {
  up: 'caret square up outline',
  down: 'caret square down outline',
  time: 'bus',
}

const BusScheduleLabels = (props) => {

  const { itemData, _keyy } = props
  const { dates, color: itemColor } = itemData ? itemData : {}

  const [activeDateKey, setActiveDateKey] = useState()

  const handleActiveDate = (key) => {
    if (activeDateKey === key) {
      setActiveDateKey()
    } else {
      setActiveDateKey(key)
    }
  }

  const label = (value, color, index) => <Label key={uniqueKey('bslt', _keyy, index)} size={_size}><Icon color={color} name={_icons.time} />{value}</Label>

  const labels = (_time) => {
    const _labels = []
    const { startTime, endTime } = _time
    if (startTime) { _labels.push(label(formatTimeAmPm(startTime), 'green', 1)) }
    if (endTime) { _labels.push(label(formatTimeAmPm(endTime), 'red', 2)) }
    return _labels
  }

  const cardContent = (_times) => {
    if (_times) {
      const _ccs = []
      Object.keys(_times).forEach(k => {
        const _time = _times[k]
        _ccs.push(<Card.Content extra>
          {labels(_time)}
        </Card.Content>)
      })
      return _ccs
    }
  }

  const card = (key, _date) => {
    const { name, times } = _date
    const isActive = activeDateKey === key
    return <Card fluid className={'card-bus-schedule'}>
      <Card.Content onClick={() => { handleActiveDate(key) }}>
        <Icon size='large' name={isActive ? _icons.up : _icons.down} style={{ float: 'right' }} />
        <Card.Header><Icon name={'calendar'} color={itemColor ? itemColor.toLowerCase() : 'blue'} />{name}</Card.Header>
      </Card.Content>
      {isActive && cardContent(times)}
    </Card>
  }

  if (dates) {
    const cards = []
    Object.keys(dates).forEach(k => {
      const _date = dates[k]
      cards.push(card(k, _date))
    })
    return <Card.Group>
      {cards}
    </Card.Group>
  } else {
    return <div></div>
  }
}

export default BusScheduleLabels