import { getCurrent } from "../../redirection/current";

export const papsInitialState = () => {
  return {}
};

export const papsReducerTypes = {
  handleHomePageSeen: 'handleHomePageSeen',
  handleWaiverSeen: 'handleWaiverSeen',
  handleUpdateCurrentPath: 'handleUpdateCurrentPath',
}

export const papsReducer = (state, action) => {
  const { type, pathname, previousPathName } = action
  switch (type) {
    case papsReducerTypes.handleUpdateCurrentPath:
      const { url } = {}
      const current = getCurrent(url, pathname)
      return {
        ...current,
        previousPathName: previousPathName,
        updated: true
      }

    case papsReducerTypes.handleHomePageSeen:
      const item_hp = action.eventKey ? 'homePageSeen-' + action.eventKey : 'homePageSeen'
      localStorage.setItem(item_hp, true);
      return { ...state }

    case papsReducerTypes.handleWaiverSeen:
      const item_w = action.eventKey ? 'waiverSeen-' + action.eventKey : 'waiverSeen'
      localStorage.setItem(item_w, true);
      return { ...state }

    default:
      return { ...state }
  }
}

export const papsHandlers = (dispatch) => {
  return {
    handleUpdateCurrentPath: (pathname, previousPathName) => { dispatch({ type: papsReducerTypes.handleUpdateCurrentPath, dispatch, pathname, previousPathName }) },
    handleHomePageSeen: (eventKey) => { dispatch({ type: papsReducerTypes.handleHomePageSeen, eventKey }) },
    handleWaiverSeen: (eventKey) => { dispatch({ type: papsReducerTypes.handleWaiverSeen, eventKey }) },
  }
}