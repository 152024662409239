import { useElementScroll } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { g_cns } from '../common/cns';
import { HeaderContext } from "../cnr/contexts/HeaderContext";
import TouchWrapper from "./TouchWrapper";

/** Wraps item-container */
const ItemWrapper = (props) => {

  const { content, header, itemPopup, wrapper, display, vlc, useTitleTransition, key_viewItem } = props ? props : {}
  const { cardDisplayType } = display ? display : {}

  // headerContext
  const headerContext = useContext(HeaderContext)
  const { header_handlers } = headerContext ? headerContext : {}
  const { handleTitleProgress } = header_handlers ? header_handlers : {}

  const isSolo = cardDisplayType && cardDisplayType.endsWith('Plus')

  let prefix = ' ';

  const cn = g_cns.item_content

  const itemRef = useRef()

  const [progress, setProgress] = useState(0);

  const { scrollYProgress } = useElementScroll(itemRef)
  scrollYProgress.onChange(setProgress);

  useEffect(() => {
    if (useTitleTransition) {
      handleTitleProgress(progress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  if (header) { prefix += 'h' }
  if (content) { prefix += 'c' }
  if (itemPopup) { prefix += 'p' }

  if (key_viewItem) { prefix += ' ' + key_viewItem }

  if (isSolo && vlc > 0) { prefix += ' solo' }

  const handleTouchHold = () => {
    console.log('handleTouchHold')
  }

  const contentTouch = (c) => <TouchWrapper handleTouchHold={handleTouchHold}>
    {c}
  </TouchWrapper>

  const main = <React.Fragment>
    {header && <div className={'item-header'}>{header}</div>}
    {content && <div className={cn} ref={itemRef} >{content}</div>}
    {itemPopup && itemPopup}
  </React.Fragment>

  if (wrapper) {
    return <div className={'item-container' + prefix}>
      <div className={wrapper}>
        {main}
      </div>
      {main}
    </div>
  } else {
    return <div className={'item-container' + prefix}>
      {main}
    </div>
  }
}

export default ItemWrapper