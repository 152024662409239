import React, { createContext } from 'react';

export const ViewSettingsComponentContext = createContext();

const ViewSettingsComponentProvider = (props) => {
  const { vsc_state, vsc_handlers } = props
  return <ViewSettingsComponentContext.Provider value={{ vsc_state, vsc_handlers }}>
    {props.children}
  </ViewSettingsComponentContext.Provider>
};

export default ViewSettingsComponentProvider 