// https://medium.com/@alshakero/how-to-setup-your-web-app-manifest-dynamically-using-javascript-f7fbee899a61 
// https://technowhisp.com/dynamic-pwa-manifest/ 

import { useIconFolder } from "../../App";
import { isMeProd } from "../../project/fbConfiguration";
import { manifestSizes } from "../components/imaging/createManifestIcons";

const _alertUrl = false

export const ammendManifestSettings = (aps_global, manifest_state, callback) => {
  const { manifestIcons } = manifest_state ? manifest_state : {}
  const { manifest, themeColors, themedItems } = aps_global ? aps_global : {}
  const _manifest = manifest
  const _manifestIcons = manifestIcons
  if (_manifest) {
    const xMan = mfest(_manifest, themeColors, _manifestIcons, themedItems)
    const stringManifest = JSON.stringify(xMan);
    let content = encodeURIComponent(stringManifest);
    let url = "data:application/manifest+json," + content;
    let element = document.createElement('link');
    element.setAttribute('rel', 'manifest');
    element.setAttribute('href', url);
    document.querySelector('head').appendChild(element);
  }
  callback(true)
}

const getMi = (index, mis, publicUrl) => {
  const mii = manifestSizes[index]
  const def = !useIconFolder ? publicUrl.href + "icon-" + mii + "x" + mii + ".png" : publicUrl.href + "icons/icon-" + mii + "x" + mii + ".png"
  const mi = mis && mis['manifest_' + index] && mis['manifest_' + index].fileUrl ? mis['manifest_' + index].fileUrl : def
  return mi
}

/**
 * 
 * @param {object} manifest 
 * @param {object} themeColors 
 * @param {object} mis 
 * @param {object} themedItems 
 * @returns 
 */
const mfest = (manifest, themeColors, mis, themedItems) => {

  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  const manifestColors = themedItems && themedItems.manifest && themedItems.manifest.colors && themedItems.manifest.colors.apply ? themedItems.manifest.colors : null
  const { shortName, startUrl, colors, id } = manifest ? manifest : {}
  let { name } = manifest ? manifest : {}
  const clrs = manifestColors ? manifestColors : colors
  const { backgroundColor, color } = clrs ? clrs : {}
  const bc = themeColors && backgroundColor && themeColors[backgroundColor] ? themeColors[backgroundColor] : "#0080c5"
  const c = themeColors && color && themeColors[color] ? themeColors[color] : "#23b099"

  const _shortName = isMeProd() ? shortName : shortName + '*'

  if (name === 'name') { name = "Meeting Evolution" }

  const m = {
    "name": name ? name : "Meeting Evolution",
    "short_name": _shortName ? _shortName : "MEM",
    "start_url": startUrl ? startUrl : window.document.URL,
    "theme_color": c,
    "background_color": bc,
    "display": "standalone",
    "orientation": "portrait-primary",
    "gcm_sender_id": "103953800507",
    "id": id ? id : null,
    icons: [
      {
        src: getMi(0, mis, publicUrl),
        sizes: "72x72",
        type: "image/png",
      },
      {
        src: getMi(1, mis, publicUrl),
        sizes: "96x96",
        type: "image/png"
      },
      {
        src: getMi(2, mis, publicUrl),
        sizes: "128x128",
        type: "image/png"
      },
      {
        src: getMi(3, mis, publicUrl),
        sizes: "144x144",
        type: "image/png"
      },
      {
        src: getMi(4, mis, publicUrl),
        sizes: "152x152",
        type: "image/png"
      },
      {
        src: getMi(5, mis, publicUrl),
        sizes: "192x192",
        type: "image/png"
      },
      {
        src: getMi(6, mis, publicUrl),
        sizes: "384x384",
        type: "image/png"
      },
      {
        src: getMi(7, mis, publicUrl),
        sizes: "512x512",
        type: "image/png"
      }
    ]
  }

  if (_alertUrl) {
    alert(m.start_url)
  }
  return m
}