import _ from 'lodash'
import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { uniqueKey } from '../../../common/keys'
import { iconColorTypes } from '../../../enums/settingsIconTypes'

export const GroupConfirmButton = (props) => {

  const { caption, handleConfirm, handleCancel } = props

  console.log('aaaa')

  return <Button.Group icon key={uniqueKey('gcb')} size='small'>
    <Button key={uniqueKey('gcb', 1)} color={iconColorTypes.default} onClick={() => handleCancel()}><Icon name='delete' /></Button>
    <Button key={uniqueKey('gcb', 2)} color={iconColorTypes.update} >{_.startCase(caption)}{'?'}</Button>
    <Button key={uniqueKey('gcb', 3)} color={iconColorTypes.update} onClick={() => handleConfirm()}><Icon name='save' /></Button>
  </Button.Group>
}