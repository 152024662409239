import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { Grid, Icon, Label, Menu } from 'semantic-ui-react'
import { getAppUserAccess } from '../auth/appUserAccessPermissions'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { uniqueKey } from '../common/keys'
import { SegIconGroup } from '../components/layout/gridItems/SegIconGroup'
import { appIconTypes } from '../enums/appIconTypes'
import { gEnums } from '../enums/globalEnums'
import UiDataManagement from '../pageItem/modification/UiDataManagement'
import UiDataModifications, { dataModificationDisplayTypes } from '../pageItem/modification/UiDataModifications'
import { itemActionIcons, itemActionTypes } from '../viewSettings/enums/itemActionTypes'

export const PageItemOptionsMenu = () => {

  const asMenu = true
  const _allowSingleItemPopup = false

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state } = states
  const { appUser_handlers } = handlers
  const { pageSettings, pageNav } = page_state ? page_state : {}
  const { view } = paps_state ? paps_state : {}
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const { aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection, appUserViewTypeProp } = aps_appUserSettings ? aps_appUserSettings : {}
  const appUserAccess = getAppUserAccess(authAppUser)

  const { navOptions, navUiItemContext } = pageNav ? pageNav : {}
  const { appUserLinkItems: appUserActionItems, pageNavOptions } = navOptions
  const { item_state, item_handlers } = navUiItemContext
  const { uivi, viewItem, itemData } = item_state ? item_state : {}
  const viewListData = {}

  const vldCount = viewListData ? Object.keys(viewListData).length : 0

  const { dataModifications } = viewItem ? viewItem : {}
  const { add, sorting } = dataModifications ? dataModifications : {}

  const { appFormType } = add ? add : {}
  const { createListAs } = sorting ? sorting : {}

  const [showDataManagement, setShowDataManagement] = useState()
  const [activeItem, setActiveItem] = useState()

  const handleOpenMenuItem = (groupKey) => setActiveItem(groupKey === activeItem ? null : groupKey)

  const handleUserActionClick = (e, actionItem) => {
    actionItem.remove = actionItem.checked
    actionItem.actionProps.appUserAccess = appUserAccess
    // item_handlers.handleUpdateLinkItem(actionItem, _itemData)
  }

  /** handles the click event for the menu item */
  const handleMenuItemClick = (pat, sat, arrayIndex) => {

    const ct = { modifyActionType: pat }

    switch (pat) {

      case itemActionTypes.connectToAppUser:
        item_handlers.handleShowItemSidebar()
        item_handlers.handleModifyAction(ct)
        break;

      case itemActionTypes.dataManagement:
        const dodm = { modifyActionType: pat, dataFieldName: sat, dataFieldIndex: arrayIndex }
        setShowDataManagement(dodm)
        // item_handlers.handleModifyAction(dodm)
        break;

      case itemActionTypes.signInAs:
        if (itemData) {
          const actionItem = { dataActionType: 'clone', cloneId: itemData.id, cloneVit: appUserCollection, appUserViewTypeProp: appUserViewTypeProp }
          appUser_handlers.handleClone(actionItem, itemData, paps_state, appUserCollection)
          item_handlers.handleShowItemSidebar()
        }
        break;

      case itemActionTypes.subAction:
        const st = { modifyActionType: pat, subActionType: sat }
        item_handlers.handleModifyAction(st)
        break;

      case itemActionTypes.add:
        const at = { modifyActionType: pat, appFormType }
        item_handlers.handleModifyAction(at)
        break;

      default:
        item_handlers.handleModifyAction(ct)
    }
  }

  // console.log('handleDataModificationSelect', handleDataModificationSelect)

  // const menuItem_sub = (item, dmiKey, subKey) => {
  //   const { accessLevel } = item ? item : {}
  //   const icon = getAuthIcon(accessLevel, true)
  //   const cc = subKey ? _.startCase(subKey) : _.startCase(dmiKey)
  //   const cn = icon ? 'iconed' : null
  //   return <Menu.Item
  //     key={subKey ? subKey : dmiKey}
  //     className={cn}
  //     onClick={() => { handleDataModificationSelect(dmiKey, subKey) }}
  //   >
  //     {icon && <Icon name={icon.name} color={icon.color} ></Icon>}
  //     <Icon name={appIconTypes.arrowRight} color={'blue'}></Icon>
  //     {cc}
  //   </Menu.Item>
  // }

  const menuItemContent = (items, cn) => {
    const mis = items ? items.map((item, index) => {
      return <Menu.Item key={uniqueKey('smi', item)} className={cn} >
        <div><Icon key={uniqueKey('uias', item, index)} name={'cog'} /></div>
        <div>{_.startCase(item)}</div>
        <div><Icon name={appIconTypes.arrowRight} color={'blue'}></Icon></div>
      </Menu.Item>
    }) : []
    return mis
  }

  /** returns a menuItem based on the pat and sat */
  const menuItem = (pat, sat, arrayIndex, forData, subItems) => {

    let icon = itemActionIcons[pat] ? itemActionIcons[pat] : 'question'

    let title = _.startCase(pat)
    let titleExtra = ''
    let disabled = false
    let labeled = null

    let cn = 'piom'

    switch (pat) {
      case itemActionTypes.signInAs:
        if (itemData && !itemData.email) {
          disabled = true
          titleExtra = ' (Not available - No Email)'
        } else {
          if (itemData) {
            title = title + ' (' + itemData.email + ')'
          }
        }
        break;

      case itemActionTypes.appUserInfo:
        if (sat) { title = 'View ' + _.startCase(sat) }
        break;

      case itemActionTypes.add:
        icon = appIconTypes.add
        if (appFormType && appFormType !== gEnums.customAddTypes.none) {
          title = 'Add ' + _.startCase(appFormType)
        }
        break;

      case itemActionTypes.imageUpload:
        if (sat) { title = 'Link ' + _.startCase(sat) }
        break;

      case itemActionTypes.itemLinking:
        if (sat) { title = sat }
        icon = appIconTypes.dataLinking
        break;

      case itemActionTypes.createGlobalData:
        if (sat) { title = 'Create Global Data (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.createList:
        if (createListAs) { title = _.startCase(createListAs) }
        break;

      case itemActionTypes.dataLinking:
        title = 'Link ' + _.startCase(uivi) + ' to this page (' + _.startCase(view) + ')'
        icon = appIconTypes.dataLinking
        break;

      case itemActionTypes.imageMapping:
        if (sat) { title = 'Image Mapping (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.showItemCount:
        title = 'Item Count'
        labeled = vldCount
        break;

      case itemActionTypes.sorting:
        if (sat) { title = 'Sort (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.subAction:
        if (sat) { title = _.startCase(sat) }
        break;

      case itemActionTypes.ticketing:
        title = 'Passes' // 'Ticketing'
        break;

      default:
      // nothing
    }

    const keyy = sat ? sat : pat

    const active = (activeItem === keyy) ? true : false

    const cnn = active ? '' : 'dis-none'
    const caretIcon = active ? 'angle down' : 'angle right'


    if (disabled) {
      return <Menu.Item key={uniqueKey('uias', keyy)} className={cn}>
        <Icon key={uniqueKey('uias', keyy, 'i')} name={'ban'} />
        {title + titleExtra}
      </Menu.Item>
    } else if (labeled) {
      return <Menu.Item key={uniqueKey('uias', keyy)} className={cn}>
        <Label key={uniqueKey('uias', keyy, 'l')} color={'blue'}>{labeled}</Label>
        {title + titleExtra}
      </Menu.Item>
    } else {
      // onClick={() => { handleMenuItemClick(pat, sat, arrayIndex) }}
      return <Menu.Item key={uniqueKey('uias', keyy)} className={cn} >
        <Menu.Header onClick={() => handleOpenMenuItem(keyy)}>
          <div><Icon key={uniqueKey('uias', keyy, 'i')} name={icon} /></div>
          <div>{title}</div>
          <div><Icon name={caretIcon} color={'blue'}></Icon></div>
        </Menu.Header>
        <Menu.Menu className={cnn}>
          {menuItemContent(subItems, cnn)}
        </Menu.Menu>
      </Menu.Item>
    }
  }

  /** returns the valid menuItems */
  const getUserMenuItems = () => {

    const mis = []

    // loop all the itemActionTypes
    if (pageNavOptions.appUserDirect) {

      // loog the itemActionTypes
      Object.keys(itemActionTypes).forEach(mat => {

        if (pageNavOptions.appUserDirect[mat]) {

          const iat = pageNavOptions.appUserDirect[mat]
          const { actionCaption } = iat ? iat : {}

          switch (mat) {

            case itemActionTypes.dataLinking:
              mis.push(menuItem(mat, uivi))
              break;

            case itemActionTypes.ticketing:
              mis.push(menuItem(mat, uivi))
              break;

            default:
              mis.push(menuItem(mat, actionCaption))
          }
        }
      })
    }
    return mis
  }

  const userMenuItems = () => {
    const mis = []
    const { appUserSessionKey } = appUserAccess ? appUserAccess : {}
    if (itemData && appUserActionItems && appUserActionItems.length > 0) {
      appUserActionItems.forEach(actionItem => {
        const ad = itemData[appUserCollection]
        const checked = _.includes(ad, appUserSessionKey) ? true : false
        const { text, key } = actionItem
        const icon = checked ? 'check square outline' : 'check square'
        const iconColor = checked ? 'green' : 'grey'
        actionItem.checked = checked
        console.log('actionItem', actionItem)
        if (asMenu) {
          mis.push(<Menu.Item className={'user-mi'} color={'blue'} key={uniqueKey('uias', 'mi', key)} onClick={(e) => { handleUserActionClick(e, actionItem) }}>
            <Icon key={uniqueKey('uias', 'mi', key, 1)} name={'user'} color={iconColor} />
            <Icon key={uniqueKey('uias', 'mi', key, 2)} name={icon} color={iconColor} />
            {text}
          </Menu.Item>)
        } else {
          const segProps = { caption: text, icon }
          mis.push(<Grid.Column className={'user-mi'} color={'blue'} key={uniqueKey('uias', 'mi', key)} onClick={(e) => { handleUserActionClick(e, actionItem) }}>
            <SegIconGroup item={actionItem} segProps={segProps} defaultSnC={{}} handleIconClick={handleUserActionClick} />
          </Grid.Column>)
        }
      })
    }
    return mis
  }

  const getMenuItems = () => {

    const { data, dataManagement, dataModification } = pageNavOptions ? pageNavOptions : {}

    const mis = getUserMenuItems()
    const misu = userMenuItems()

    // combine mis and misu
    const _menuItems = [...mis, ...misu]

    // add a menuItem for the dataActions
    if (data && Object.keys(data).length > 0) {
      _menuItems.push(menuItem(itemActionTypes.dataActions, null, null, true))
    }

    if (dataManagement && Object.keys(dataManagement).length > 0) {
      _menuItems.push(menuItem(itemActionTypes.dataManagement, null, null, true))
    }

    // add a menuItem for the dataModifications
    if (dataModification && Object.keys(dataModification).length > 0) {
      if (Object.keys(dataModification).length === 1 && _allowSingleItemPopup) {
        Object.keys(dataModification).forEach(dmiKey => {
          const dmi = dataModification[dmiKey]
          _menuItems.push(menuItem(dmiKey, null, null, true, dmi))
        })
      } else {
        _menuItems.push(<UiDataModifications dataModificationDisplayType={dataModificationDisplayTypes.menuHeader} />)
      }
    }

    return <Menu className='menu-slim' vertical borderless fluid>
      {_menuItems}
    </Menu>

  }

  if (showDataManagement) {
    return <UiDataManagement />
  } else {
    return getMenuItems()
  }

}
