import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import DateInput from '../../../../global/components/forms/elements/DateInput';
import TimeInput from '../../../../global/components/forms/elements/TimeInput';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';

const MatchForm = (props) => {

  const { shows, handleActionPreview, solo, setMatchReady, historyMatch, handleUpdateParent, match_current: newCurrentMatch } = props
  const { showLocation, showDate, showTeams } = shows ? shows : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { teams_info, matches_info } = sportsSeason_state
  const { teams } = teams_info ? teams_info : {}
  const { matches } = matches_info ? matches_info : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_current } = sportsMatch_state ? sportsMatch_state : {}

  const [_currentMatch, set_currentMatch] = useState()
  const [teamOptsList, setTeamOptsList] = useState()

  useEffect(() => {
    const _cm = historyMatch ? historyMatch : match_current
    set_currentMatch(_cm ? _cm : newCurrentMatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_current]);

  useEffect(() => {
    const list = []
    if (teams) {
      const teamsS = _.sortBy(teams, 'name')
      teamsS.forEach(team => {
        const { _itemKey, name } = team ? team : {}
        list.push({ key: _itemKey, value: _itemKey, text: name })
      })
      setTeamOptsList(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    setMatchReady && setMatchReady(newMatchReady())
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_currentMatch]);

  const newMatchReady = () => {
    if (_currentMatch) {
      const { home, away, startDate, startTime } = _currentMatch
      let ready = away !== null && home !== null
      if (showDate && !startDate) { ready = false }
      if (showDate && !startTime) { ready = false }
      if (ready && matches) {
        const existing = _.find(matches, function (m) {
          const { away: away_m, home: home_m } = m
          const { id: id_home } = home_m ? home_m : {}
          const { id: id_away } = away_m ? away_m : {}
          return (
            (id_away === away) && (id_home === home)
          );
        })
        if (existing) {
          ready = false
        }
      }
      return ready
    }
  }

  const handleSelectTeamA = (e, data) => {
    const cm = { ..._currentMatch }
    cm.away = data.value
    sportsMatch_handlers && sportsMatch_handlers.handleAmmend_currentMatch(cm)
    handleActionPreview && handleActionPreview({ match_current: cm, ready: newMatchReady() })
    handleUpdateParent && handleUpdateParent({ match_current: cm })
    set_currentMatch(cm)
  }

  const handleSelectTeamH = (e, data) => {
    const cm = { ..._currentMatch }
    cm.home = data.value
    sportsMatch_handlers && sportsMatch_handlers.handleAmmend_currentMatch(cm)
    handleActionPreview && handleActionPreview({ match_current: cm, ready: newMatchReady() })
    handleUpdateParent && handleUpdateParent({ match_current: cm })
    set_currentMatch(cm)
  }

  const handleUpdateDate = (e, data) => {
    const cm = { ..._currentMatch }
    cm.startDate = data.value
    sportsMatch_handlers && sportsMatch_handlers.handleAmmend_currentMatch(cm)
    handleActionPreview && handleActionPreview({ match_current: cm, ready: newMatchReady() })
    set_currentMatch(cm)
  }

  const handleUpdateTime = (e, data) => {
    const cm = { ..._currentMatch }
    cm.startTime = data.value
    sportsMatch_handlers && sportsMatch_handlers.handleAmmend_currentMatch(cm)
    handleActionPreview && handleActionPreview({ match_current: cm, ready: newMatchReady() })
    set_currentMatch(cm)
  }

  const handleSelectedLocation = (e, d) => {
    const l = teams[d.value]
    const cm = { ..._currentMatch }
    cm.location = {
      id: l.id,
      name: l.name
    }
    sportsMatch_handlers && sportsMatch_handlers.handleAmmend_currentMatch(cm)
    handleActionPreview && handleActionPreview({ match_current: cm, ready: newMatchReady() })
    set_currentMatch(cm)
  }

  const teamASelect = () => <Form.Dropdown placeholder={'Away'} search scrolling selection value={_currentMatch.away} options={teamOptsList} onChange={handleSelectTeamA} />
  const teamHSelect = () => <Form.Dropdown placeholder={'Home'} search scrolling selection value={_currentMatch.home} options={teamOptsList} onChange={handleSelectTeamH} />
  const dateSelect = () => <DateInput fip={{ propname: 'matchDate', onChange: handleUpdateDate, label: 'Start Date', value: _currentMatch.startDate }} />
  const timeSelect = () => <TimeInput fip={{ propname: 'startTime', onChange: handleUpdateTime, label: 'Start Time', value: _currentMatch.startTime }} />
  const locationSelect = () => <Form.Dropdown label={'Location'} placeholder={'Location'} search scrolling selection value={_currentMatch.location && _currentMatch.location.id} options={teamOptsList} onChange={handleSelectedLocation} />

  const matchTeamsDiv = () => {
    let cn = 'match-teams'
    if (solo) { cn += ' fh' }
    return <Form basic className={cn}>
      {showTeams && <React.Fragment>
        <div className='field'>
          <label style={{ float: 'left' }}>{'Teams'}</label>
        </div>
        <div className={'match-teams-teams'}>
          {teamASelect()}
          {teamHSelect()}
        </div>
      </React.Fragment>}
      {showDate && <div className={'match-team-date'}>{dateSelect()} </div>}
      {showDate && <div className={'match-team-time'}> {timeSelect()}</div>}
      {showLocation && <div className={'match-match-location'}> {locationSelect()}</div>}
    </Form>
  }

  return _currentMatch ? matchTeamsDiv() : <div></div>
}

export default MatchForm