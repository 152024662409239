import { getAuth, sendEmailVerification } from "firebase/auth";

export const sendEmailVerificationToCurrentUser = () => {
  const auth = getAuth()
  sendEmailVerification(auth.currentUser).then(function () {
    // Email sent.
    console.log('Email Sent!!!')
  }).catch(function (error) {
    console.log('Email NOT Sent')
    // An error happened.
  });
}

// {ignoreId: true}
// {returnFirstObject: true}
// {uivi: xxx, isCombinedData}