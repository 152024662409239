export const rts = {
  handleGroupKey_left: 'handleGroupKey_left',
  handleGroupKey_right: 'handleGroupKey_right',
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @rts (handleGroupKey_right, handleGroupKey_right)
 */
export const settingsFrameworkReducer = (state, action) => {

  const { type, groupKey, groupItem } = action

  switch (type) {

    case rts.handleGroupKey_left:
      return {
        ...state,
        leftSidebarGroupKey: groupKey,
        leftSidebarGroupItem: groupItem
      }

    case rts.handleGroupKey_right:
      return {
        ...state,
        rightSidebarGroupKey: groupKey,
        rightSidebarGroupItem: groupItem
      }

    default:
      return { ...state }
  }
}

export const settingsFrameworkInitialState = (settingsMenuType) => {
  const s = {
    settingsMenuType,
  }
  return s
};

export const settingsFrameworkHandlers = (dispatch) => {
  return {
    handleGroupKey_left: (groupKey, groupItem) => { dispatch({ type: rts.handleGroupKey_left, groupKey, groupItem }) },
    handleGroupKey_right: (groupKey, groupItem) => { dispatch({ type: rts.handleGroupKey_right, groupKey, groupItem }) },
  }
}