const courtColor = 'brown'

export const swipeDivs = {
  courtSwipe: {
    maxHeight: 450,
    margin: 0,
    border: 4,
    props: {
      courtColor: courtColor,
      lineColor: 'black',
    },
    style: function () {
      return {
        margin: this.margin,
        border: this.border + 'px solid',
      }
    }
  },
  court: {
    maxHeight: 400,
    margin: 0,
    border: 4,
    props: {
      courtColor: courtColor,
      lineColor: 'black',
    },
    style: function () {
      return {
        margin: this.margin,
        border: this.border + 'px solid',
      }
    }
  },
  smallCourt: {
    maxHeight: 200,
    width: 100,
    margin: 12,
    border: 2,
    props: {
      courtColor: courtColor,
      lineColor: 'black',
    },
    style: function () {
      return {
        margin: 'auto',
        border: this.border + 'px solid',
        width: this.width,
        textAlign: 'center'
      }
    }
  },
  gridColumns: {
    caption: {
      color: 'black'
    },
    single: {
      color: 'blue'
    }
  },
  grid: {

  }
}