import _ from 'lodash'
import React, { useContext } from 'react'
import { Icon, Label } from 'semantic-ui-react'
import { uniqueKey } from '../../common/keys'
import { getStaticName } from '../../common/convert'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { dvLabelColors } from '../../cnr/reducers/DataManagmentReducer'
import DropdownLabel from '../forms/elements/DropdownLabel'

const CellIconLabels = (props) => {

  const {
    allowGuests,
    color,
    cvKey,
    existing,
    handleUpdateNewData,
    isAppData,
    jsonItem,
    propKey,
    staticViews_gs,
    value,
  } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { eventInfo_state } = states ? states : {}
  const { staticViews: staticViews_app } = eventInfo_state ? eventInfo_state : {}

  let _staticViews;

  if (isAppData) {
    _staticViews = staticViews_app
  } else {
    _staticViews = staticViews_gs ? staticViews_gs : staticViews_app
  }

  const dropdown = (keyy, arrayValue, color) => <DropdownLabel
    allowGuests={allowGuests}
    arrayValue={arrayValue}
    color={color}
    isAppData={isAppData}
    propKey={propKey}
    keyy={keyy}
    staticViews_gs={staticViews_gs}
  />

  const labelIcon = (jsonItem, value, cvKey, index, existing, key) => {
    let _value = _.isEmpty(value) && !_.isBoolean(value) ? '' : value
    return <Label
      key={uniqueKey('tv', existing ? 'e' : 'n', cvKey, index)}
      onClick={() => { handleUpdateNewData && handleUpdateNewData({ jsonItem, cvKey, key, existing }) }}
      size={'mini'}
    >
      <Icon name='check circle' color={existing ? dvLabelColors.existingItems : dvLabelColors.googleData} size={'large'} />
      {_value}
    </Label>
  }

  const staticLabelValue = (value) => getStaticName(_staticViews, propKey, value, true, allowGuests)

  if (!_.isObject(value)) {
    if (_.isArray(value)) {
      return dropdown(cvKey, value, color)
    } else {
      const slv = staticLabelValue(value)
      const { sv } = slv
      return [labelIcon(jsonItem, sv, cvKey, 1, existing, propKey)]
    }
  } else {
    if (_.isArray(value)) {
      return dropdown(cvKey, value, color)
    }
  }
}

export default CellIconLabels