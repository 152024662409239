import { gEnums } from "../../enums/globalEnums";

const padding = 8;

// "react-firebase-hooks": "^2.2.0",
// "react-infinite-scroll-component": "^5.0.5",

export const getListStyle = (isDraggingOver) => {
  const s = {}
  // if (isDraggingOver) { s.backgroundColor = '#979797' }
  return s
};

export const getItemStyle = (isDragging, draggableStyle, siCount, settingsOriginType, isLabelSort) => {

  const w = draggableStyle.width
  let c = (siCount - 1)
  let hh = 2

  switch (settingsOriginType) {
    case gEnums.settingsOriginTypes.app:
    case gEnums.settingsOriginTypes.page:
      c++
      hh++
      break;
    default:
    // nothing
  }

  let left = 0

  switch (siCount) {
    case 4:
      left = (w * c) + ((padding * 2) * (c + 1))
      break;
    case 3:
      left = (w * c) + ((padding * 2) * (c + 1))
      break;
    default:
      left = (w * c) + ((padding * 2) * (c + 1))
    // nothing
  }

  if (left) {
    if (isLabelSort) {
      draggableStyle.position = 'fixed'
    } else {
      draggableStyle.left = left - (padding / 2)
      draggableStyle.top = draggableStyle.top - ((draggableStyle.height * hh) - (padding / hh)) + (padding / 2)
    }
  }

  const s = { ...draggableStyle }

  return s

}

export const getSimpleItemStyle = (isDragging, draggableStyle, draggableProvided) => {

  let left = 14 + padding
  let hh = 2

  if (left) {
    draggableStyle.left = left
    draggableStyle.top = draggableStyle.top - ((draggableStyle.height * hh) - (padding / hh))
  }

  const s = { ...draggableStyle }

  return s

}