import _ from 'lodash';
import { getAppUserLinks } from '../../auth/accessPermissions';
import { g_cns } from '../../common/cns';
import { getFirstObject } from '../../common/convert';
import { _sectionProps } from '../../components/props/propsShown';
import { gEnums } from '../../enums/globalEnums';
import { arrayUpdateToDatabase } from '../../firestoreData/updates/arrays/arrayUpdates';
import { getSectionThemeColors, setItemStyleAndClass } from '../../styles/formatting';
import { sectionMerge } from '../../viewSettings/helpers/sectionMerge';
import { getPropSectionsGrouped } from '../../viewSettings/helpers/settingsHelpers';
import { postFilterData } from '../contexts/contextHelpers/postFilterData';
import { firestoreHandlers, firestoreReducer, fsrts } from './FirestoreReducer';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';

const rts = {
  handle_fsSaveDataItemRating: 'handle_fsSaveDataItemRating',
  handle_fsUpdateDbFromGoogleSheet: 'handle_fsUpdateDbFromGoogleSheet', // not sure 
  handle_fsUpdateRequestAccess: 'handle_fsUpdateRequestAccess',
  handleCloseOpenedItem: 'handleCloseOpenedItem',
  handleCurrentGroupKey: 'handleCurrentGroupKey',
  handleDrill: 'handleDrill',
  handleInitState: 'handleInitState',
  handleItemShowEdit: 'handleItemShowEdit',
  handleModifyAction: 'handleModifyAction',
  handleModifyMapping: 'handleModifyMapping',
  handleRateDataItem: 'handleRateDataItem',
  handleSettingsSidebar: 'handleSettingsSidebar',
  handleShowItemSidebar: 'handleShowItemSidebar',
  handleShowSort: 'handleShowSort',
  handleUpdateFilterOpts: 'handleUpdateFilterOpts',
  handleUpdateLinkItem: 'handleUpdateLinkItem',
  setVld: 'setVld',
  ...fsrts,
  ...grts,
}

export const itemReducer = (state, action) => {

  const { type, init_state, viewListData, dispatch, popupAction } = action

  const {
    dataFieldName,
    linkIndex,
    linkType,
    mapFieldName,
    modifyActionType,
    subActionType,
  } = popupAction ? popupAction : {}

  const {
    modifyActionType: _modifyActionType,
    modifySidebarOpen,
    modifyMappingOpen,
    paps_state,
    page_fns,
    settingsSidebarOpen,
    showSort,
    updating,
    vldSorted,
  } = state

  switch (type) {

    case rts.handleCurrentGroupKey:
      return { ...state, currentGroupKey: action.currentGroupKey }

    case rts.handleInitState:
      const { appUserAccess, filterOpts, viewItem: viewItemInit, viewListData: vldInit, logging: loggingInit } = init_state
      const x = ammendVld({ filterOpts, appUserAccess, viewItem: viewItemInit, viewListData: vldInit, logging: loggingInit })
      const _navigationOptions = getNavigationOptions(action.init_state, action.dataManagementSettings, action.aps_appUserSettings, appUserAccess, action.pageSettings)
      return { ...state, filterOpts, appUserAccess, viewItem: viewItemInit, ...action.init_state, vld: x, navigationOptions: _navigationOptions }

    case rts.handleUpdateFilterOpts:
      const { alphaValue, filterValues } = action.filterOpts
      const _filterOpts = { ...state.filterOpts }
      if (alphaValue) { _filterOpts.alphaValue = alphaValue }
      if (filterValues) { _filterOpts.filterValues = filterValues }
      if (alphaValue === 'Favs') {
        // const { appUserAccess: aua } = state
        // const { pageFavs } = aua ? aua : {}
      }
      const y = ammendVld({
        filterOpts: _filterOpts,
        appUserAccess: state.appUserAccess,
        viewItem: state.viewItem,
        viewListData: state.viewListData,
        logging: state.logging,
      })
      return { ...state, filterOpts: _filterOpts, vld: y }

    case rts.handleUpdateLinkItem:
      const uProps = {
        actionItem: action.actionItem,
        dispatch,
        dataActionType: gEnums.itemLinkTypes.linkProfile,
        logging: state.logging,
        page_fns,
        paps_state,
        remove: action.actionItem.remove
      }
      arrayUpdateToDatabase(uProps)
      return { ...state }

    // RESPONSE  
    case rts.handle_fsUpdateDbFromGoogleSheet:
    case rts.handle_fsUpdateRequestAccess: // normal

      const ufProps = getRequestProps(type, state, action)

      if (action.createFunction) {
        action.createFunction(paps_state, ufProps)
      } else {
        // parentDispatch, paps_state, ufProps, parentDispatchProps
        return firestoreReducer(state, action, { type: 'updateFirestoreData', dispatch, paps_state, ufProps })
      }

      switch (type) {
        case rts.handle_fsUpdateDbFromGoogleSheet:
          return { ...state, modifySidebarOpen: false, modifyActionType: null, updating: false, settingsSidebarOpen: false }
        case rts.handle_fsSaveDataItemRating:
          return { ...state, modifyActionType: null, updating: false, rating: true }
        default:
        // nothing
      }
      return { ...state }

    // RATING
    case rts.handleRateDataItem:
      return { ...state, itemRating: action.rating }

    // SHOW SORT
    case rts.handleShowSort:
      return { ...state, showSort: !showSort }

    // SHOW SORT
    case rts.handleItemShowEdit:
      const { editableItem } = action
      if (editableItem) {
        const k = _.camelCase(editableItem.key)
        const ei = { [k]: action.editableItem }
        return { ...state, showSingleItemEdit: true, editableItem: { ...ei } }
      } else {
        return { ...state, showSingleItemEdit: false, editableItem: null }
      }

    case rts.setVld:
      if (!updating) {
        if (viewListData) {
          const { item: item_data } = viewListData ? getFirstObject(viewListData) : {}
          if (!vldSorted) {
            const vlds = _.sortBy(viewListData, 'position')
            return { ...state, vld: viewListData, vldSorted: vlds, vldSet: true, item_data }
          } else {
            return { ...state, vld: viewListData, vldSet: true, item_data }
          }
        }
      } else {
        return { ...state }
      }
      break;

    case rts.handleCloseOpenedItem:
    case rts.handleShowItemSidebar:
      if (modifySidebarOpen) {
        return { ...state, modifySidebarOpen: false, modifyActionType: null, settingsSidebarOpen: false, filterSidebarOpen: false, editableItem: null }
      } else if (_modifyActionType) {
        return { ...state, modifySidebarOpen: false, modifyActionType: null, settingsSidebarOpen: false, filterSidebarOpen: false, editableItem: null }
      } else {
        return { ...state, modifySidebarOpen: true, settingsSidebarOpen: false, filterSidebarOpen: false }
      }

    case rts.handleSettingsSidebar:
      if (settingsSidebarOpen) {
        return { ...state, settingsSidebarOpen: false, modifyActionType: null, modifySidebarOpen: false, editableItem: null }
      } else {
        return { ...state, settingsSidebarOpen: true, modifySidebarOpen: false }
      }

    case rts.handleModifyMapping:
      return { ...state, modifyMappingOpen: !modifyMappingOpen }

    case rts.handleModifyAction:
      return {
        ...state,
        modifyActionType,
        subActionType,
        linkType,
        linkIndex,
        dataFieldName,
        mapFieldName,
        ...popupAction
      }

    case rts.handleDrill:
      return { ...state, drillProps: action.drillProps }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const itemInitialState = (initState) => {
  return { ...initState }
};

export const itemHandlers = (dispatch) => {
  return {
    handleCurrentGroupKey: (currentGroupKey) => { dispatch({ type: rts.handleCurrentGroupKey, dispatch, currentGroupKey }) },
    handleItemShowEdit: (editableItem) => { dispatch({ type: rts.handleItemShowEdit, dispatch, editableItem }) },
    handleModifyAction: (popupAction) => { dispatch({ type: rts.handleModifyAction, dispatch, popupAction }) },
    handleShowSort: () => { dispatch({ type: rts.handleShowSort, dispatch }) },
    handleInitState: (init_state, dataManagementSettings, aps_appUserSettings, pageSettings) => { dispatch({ type: rts.handleInitState, dispatch, init_state, dataManagementSettings, aps_appUserSettings, pageSettings }) },
    handleUpdateFilterOpts: (filterOpts) => { dispatch({ type: rts.handleUpdateFilterOpts, dispatch, filterOpts }) },
    handleUpdateLinkItem: (actionItem) => { dispatch({ type: rts.handleUpdateLinkItem, dispatch, actionItem }) },
    setVld: (viewListData) => { dispatch({ type: rts.setVld, dispatch, viewListData }) },

    handle_fsSaveDataItemRating: () => { dispatch({ type: rts.handle_fsSaveDataItemRating, dispatch }) },
    handle_fsSaveSort: () => { dispatch({ type: rts.handle_fsSaveSort, dispatch }) },
    handle_fsUpdateDbFromGoogleSheet: (selectedItems, parentKeys) => { dispatch({ type: rts.handle_fsUpdateDbFromGoogleSheet, dispatch, selectedItems, parentKeys }) },
    handle_fsUpdateRequestAccess: (appUser, pathName, currentPageDataCaption) => { dispatch({ type: rts.handle_fsUpdateRequestAccess, dispatch, appUser, pathName, currentPageDataCaption }) },
    handleModifyMapping: () => { dispatch({ type: rts.handleModifyMapping, dispatch }) },
    handleCloseOpenedItem: () => { dispatch({ type: rts.handleCloseOpenedItem, dispatch }) },
    handleShowItemSidebar: () => { dispatch({ type: rts.handleShowItemSidebar, dispatch }) },
    handleRateDataItem: (rating) => { dispatch({ type: rts.handleRateDataItem, dispatch, rating }) },
    handleSettingsSidebar: () => { dispatch({ type: rts.handleSettingsSidebar, dispatch }) },
    handleDrill: (drillProps) => { dispatch({ type: rts.handleDrill, dispatch, drillProps }) },

    ...firestoreHandlers(dispatch),
    ...responseHandlers(dispatch)
  }
}

const getRequestProps = (type, state, action) => {
  const { viewItem, itemRating, vld } = state
  switch (type) {
    case rts.handle_fsSaveDataItemRating:
      const ratingItem = vld[Object.keys(vld)[0]]
      return {
        id: ratingItem.id,
        itemData: ratingItem,
        dataUpdateType: gEnums.dataUpdateTypes.rate,
        vit: viewItem.key,
        rating: itemRating
      }
    case rts.handle_fsUpdateDbFromGoogleSheet:
      return {
        id: null,
        selectedDataItems: action.selectedItems,
        dataUpdateType: gEnums.dataUpdateTypes.add,
        vit: viewItem.key,
      }
    case rts.handle_fsUpdateRequestAccess:
      return {
        modifyType: gEnums.dataUpdateTypes.updateAccessRequest,
        dataUpdateType: gEnums.dataUpdateTypes.updateAccessRequest,
        appUser: action.appUser,
        pathName: action.pathName,
        pathCaption: action.currentPageDataCaption
      }
    default:
      break;
  }
}

export const getAllowPopups = (layoutType, filterStatusType, viewItem_preview) => {

  let allowPopups = true

  switch (layoutType) {
    case gEnums.layoutTypes.horizontalSliders:
      break;
    case gEnums.layoutTypes.singlePage:
      allowPopups = false
      break;
    default:
    // nothing
  }

  if (filterStatusType === gEnums.filterStatusTypes.showing) { allowPopups = false }
  if (viewItem_preview) { allowPopups = false }

  return allowPopups

}

const getNavigationOptions = (initState, dataManagementSettings, aps_appUserSettings, appUserAccess, pageSettings) => {

  const { appUser_fns, viewItem, singleDataItem, viewItemIsView, itemData, accessRequests, view, viewKey } = initState
  const pageNavOptions = appUser_fns.validateAccess_uiAction({ viewItem, singleDataItem, viewItemIsView, pageSettings, itemData, accessRequests, view, viewKey, dataManagementSettings })
  const { appUserSessionKey } = appUserAccess ? appUserAccess : {}

  const appUserLinkItems = []
  const { appUserCollection, appUserViewTypeProp, allowLinks, links } = aps_appUserSettings ? aps_appUserSettings : {}
  if (itemData && allowLinks && links && appUserSessionKey) {
    getAppUserLinks(appUserLinkItems, appUserCollection, links, itemData, viewKey, viewItem, itemData.id)
  }

  const navigationOptions = { pageNavOptions, appUserLinkItems }

  return navigationOptions
}

/**
 * 
 * @param {object} props 
 * @param {object} item_state 
 * @returns propSections_allowed and styleAndClassB
 */
export const updateViewItem = (props, item_state, aps_appUserSettings) => {

  const {
    aps_global,
    appUser_fns,
    desktopMode,
    display_global,
    groupDataProps,
    pageViewItemKeys,
    pathViews,
    isPublicSite,
    viewItem,
    viewKey,
  } = props ? props : {}

  const { propGroups } = item_state ? item_state : {}
  const { propArrays } = propGroups ? propGroups : {}

  let {
    selectedTab
  } = props ? props : {}

  const { props: props_viewItem, propSections, display, desktopDisplay, itemColor, drillDown } = viewItem ? viewItem : {}
  let _display = desktopMode && desktopDisplay ? desktopDisplay : display

  const { colors } = itemColor ? itemColor : {}

  const styleAndClassB = {}
  if (colors) { setItemStyleAndClass(aps_global, styleAndClassB, colors) }
  styleAndClassB.className = g_cns.item_content //+ ' ' + uivi

  if (groupDataProps && groupDataProps.defaultTab && !selectedTab) { selectedTab = groupDataProps.defaultTab }

  // isPublicSite, pathViews, props_viewItem, display, viewKey
  appUser_fns.validateAccess_props(isPublicSite, pathViews, props_viewItem, _display, viewKey)

  const propSections_allowed = getPropSectionsGrouped(props_viewItem, propSections, false, null, display_global, propGroups, viewItem, aps_appUserSettings)

  if (propSections_allowed) {
    Object.keys(propSections_allowed).forEach(psKey => {
      const propSection_allowed = propSections_allowed[psKey]
      // remove the section if it is not allowed
      // pathViews, propSection, drillDown, displayContext, display_viewItem, isPublicSite, viewKey
      if (appUser_fns.validateAccess_section(pathViews, propSection_allowed, drillDown, null, _display, isPublicSite, viewKey) === gEnums.viewPermissionTypes.deny) {
        delete propSections_allowed[psKey]
      } else {
        const { props: props_section_allowed } = propSection_allowed ? propSection_allowed : {}
        ammendSectionStyles(aps_global, propSection_allowed)
        propSection_allowed._props_allowed = getUiAllowedProps(psKey, viewItem, props_section_allowed, desktopMode, null, propArrays, pageViewItemKeys)
      }
    })
  }

  return { propSections_allowed, styleAndClassB }
}

const ammendSectionStyles = (aps_global, propSection_allowed) => {

  const { key: key_propSection } = propSection_allowed ? propSection_allowed : {}
  const { display: display_global } = aps_global ? aps_global : {}
  const { propsToSeparate: propsToSeparate_global } = display_global ? display_global : {}

  const { sectionBody, sectionDisplay, sectionHeader } = sectionMerge(aps_global, propSection_allowed)
  const sectionBodyStyle = getSectionThemeColors(aps_global, sectionBody)

  let { bodyDisplayType } = sectionBody ? sectionBody : {}
  let { sectionDisplayType } = sectionDisplay ? sectionDisplay : {}

  switch (bodyDisplayType) {
    case gEnums.contentTypes.header:
      sectionBody.bodyDisplayType = gEnums.sectionBodyTypes.inline
      sectionHeader.headerDisplayType = gEnums.sectionHeaderTypes.none
      break;
    default:
    // nothing
  }

  let isSeparate;

  if ((sectionDisplayType === gEnums.sectionDisplayTypes.description) || (propsToSeparate_global && propsToSeparate_global.includes(key_propSection))) { isSeparate = true }
  if ((sectionDisplayType === gEnums.sectionDisplayTypes.bio) || (propsToSeparate_global && propsToSeparate_global.includes(key_propSection))) { isSeparate = true }

  if (isSeparate) {
    sectionHeader.headerDisplayType = gEnums.sectionHeaderTypes.none
    sectionBody.bodyDisplayType = gEnums.sectionBodyTypes.noCaptions
  }

  propSection_allowed._styles = {
    sectionBody,
    sectionBodyStyle,
    sectionHeader,
    sectionDisplay,
  }
}

const getUiAllowedProps = (psKey, viewItem, props_viewItem, desktopMode, ddGroupIndex, propArrays, pageViewItemKeys) => {

  const { display, desktopDisplay, key: key_viewItem } = viewItem
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { ignoreTaggedProps, cardDisplayType } = _display ? _display : {}

  const currentAllowedProps = {}

  // get the props that are allowed
  if (props_viewItem) {

    Object.keys(props_viewItem).forEach((key, index) => {

      const prop_viewItem = props_viewItem[key]

      const { _allowProp, propType, display: display_prop, data, show } = prop_viewItem ? prop_viewItem : {}
      let { prop: prop_key } = prop_viewItem ? prop_viewItem : {}
      let { elemPropType } = display_prop ? display_prop : {}

      if (_allowProp && show) {

        let _allowUiProp = true

        let { eventKey } = data ? data : {}

        // set defaults if not there
        if (!elemPropType && propType) { elemPropType = propType }
        if (!prop_key) { prop_key = prop_viewItem.key }
        if (eventKey && (prop_key !== eventKey)) { prop_key = eventKey }

        const { allowed } = checkAllowed(psKey, key, key_viewItem, prop_key, index, _sectionProps, ignoreTaggedProps, propArrays, pageViewItemKeys, cardDisplayType)

        if (!allowed) {
          _allowUiProp = false
        }

        if (_allowUiProp) {
          currentAllowedProps[prop_key] = prop_viewItem
        }
      }
    })
  }

  return currentAllowedProps

}

const checkAllowed = (psKey, key, key_viewItem, prop_key, index, _sectionProps, ignoreTaggedProps, propArrays, pageViewItemKeys, cardDisplayType) => {

  let allowed = true
  let reason = ''

  if (prop_key.indexOf('UTC') >= 0) {
    allowed = false
    reason = 'UTC'
  }

  if (_sectionProps.hidden.includes(key)) {
    allowed = false
    reason = 'In Section Props'
  }
  if (psKey === 'schedule') {
    switch (cardDisplayType) {
      case gEnums.cardDisplayTypes.schedule:
      case gEnums.cardDisplayTypes.schedulePlus:
        if (_sectionProps.schedule.includes(key)) {
          allowed = false
          reason = 'schedule'
        }
    }
  }

  if (index > 0) {
    if (ignoreTaggedProps && propArrays && propArrays.includes(prop_key)) {
      allowed = false
      reason = 'ignoreTaggedProps'
    }
    if (pageViewItemKeys && pageViewItemKeys.includes(key) && key_viewItem !== key) {
      allowed = false
      reason = 'pageViewItemKeys'
    }
  } else {

  }

  return { allowed, reason }

}

const ammendVld = ({ filterOpts, appUserAccess, viewItem, viewListData, logging }) => {
  const f = []
  return postFilterData({
    ...filterOpts,
    appUserAccess,
    logging,
    viewItem,
    viewListData,
    f
  })
} 