import React, { useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { AppUserManagerContext } from '../cnr/contexts/AppUserManagerContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { appUserManagerHandlers, appUserManagerInitialState, appUserManagerReducer, showAppManagerTypes, userMenuItems } from '../cnr/reducers/AppUserManagerReducer';
import ConfirmationQuestion from '../components/alerts/ConfirmationQuestion';
import { Popuper } from '../components/popups/Popuper';
import UserAuth from '../components/user/UserAuth';
import UserProfile from '../components/user/UserProfile';
import JsonViewer from '../components/viewers/JsonViewer';
import { gEnums } from '../enums/globalEnums';
import GenericPopupMenuItem from '../genericControls/GenericMenuItem';
import DirectMessage from '../messages/DirectMessage';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import AppUserCard from './AppUserCard';

const AppUserManager = (props) => {

  const { selectedAppUser, selectedAppUserKey, handleCancel, item_cpd } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { projectOptions } = aps_global ? aps_global : {}
  const { showTimeDifferences } = projectOptions ? projectOptions : {}
  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging } = homeSettings_global ? homeSettings_global : {}

  // authContext 
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { accessLevel } = appUserAccess ? appUserAccess : {}

  const allowSA = appUserAccess && (accessLevel > gEnums.accessLevels.admin.value)

  // appUserManager
  const initState = appUserManagerInitialState({ paps_state, selectedAppUser, selectedAppUserKey, logging })
  const [appUserManager_state, dispatch] = useReducer(appUserManagerReducer, initState);
  const appUserManager_handlers = appUserManagerHandlers(dispatch)
  const { authRetrieved, selectedAppUserAuth, showAppManagerType } = appUserManager_state ? appUserManager_state : {}
  const { email } = selectedAppUserAuth ? selectedAppUserAuth : {}

  useEffect(() => {
    appUserManager_handlers.handleGetAppUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleMenuItemClick = (item) => appUserManager_handlers.handleSetSelectedItem(item)
  const cancelSendToEmail = () => appUserManager_handlers.handleSetSelectedItem()
  const sendSignInLink = () => appUserManager_handlers.handleSendSignInLinkToUserEmail(email)
  const sendPasswordReset = () => appUserManager_handlers.handleSendPasswordResetEmail(email)
  const handleResetDisclaimerResponses = () => appUserManager_handlers.handleResetDisclaimerResponses(email)

  const gpmi = (item, oc) => <GenericPopupMenuItem item={item} oc={oc} />

  /** a list of menu items  */
  const sidebarMenuItems = () => {
    const mis = []
    if (selectedAppUserAuth.email) {
      mis.push(gpmi(userMenuItems.resetDisclaimerResponses, handleResetDisclaimerResponses))
      mis.push(gpmi(userMenuItems.sendEmail, handleMenuItemClick))
      mis.push(gpmi(userMenuItems.sendPasswordResetEmail, handleMenuItemClick))
    }

    selectedAppUserAuth.fcmTokens && mis.push(gpmi(userMenuItems.sendPushNotification, handleMenuItemClick))
    selectedAppUserAuth.phoneNumber && mis.push(gpmi(userMenuItems.sendSms, handleMenuItemClick))
    allowSA && mis.unshift(gpmi(userMenuItems.editAuthorization, handleMenuItemClick))
    mis.unshift(gpmi(userMenuItems.editProfile, handleMenuItemClick))
    // selectedAppUserAuth.email && mis.push(gpmi(userMenuItems.sendSignInLinkEmail, handleMenuItemClick))
    return mis
  }

  const jsonViewer = () => <JsonViewer json={selectedAppUserAuth} noFullHeight={true} name={'App User Profile'} inApp={true} />

  const appUserCardEdit = () => <React.Fragment>
    <AppUserCard appUser={selectedAppUserAuth} showTimeDifferences={showTimeDifferences} />
    {jsonViewer()}
  </React.Fragment>

  const appUserWrapper = () => <Wrapper
    content={selectedAppUserAuth && appUserCardEdit()}
    wrapperType={wrapperTypes.padded}
    updating={!authRetrieved}
    updatingCaption={'fetching'}
  />

  const popuper = () => <Popuper
    menuItems={selectedAppUserAuth && sidebarMenuItems()}
    content={appUserWrapper()}
    showPopupIcon={true}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={popuper()}
    handleCancel={handleCancel}
    topperCaption={'App User'}
    topperCaption2={email}
    isBack={true}
  />


  const confirmation_resetDisclaimerResponse = () => <ConfirmationQuestion
    header={'Please Confirm'}
    question={'Reset Disclaimer Responses (' + email + ')?'}
    handleNo={cancelSendToEmail}
    handleYes={sendPasswordReset}
  />

  const confirmation_sendSignInLink = () => <ConfirmationQuestion
    header={'Please Confirm'}
    question={'Send sign in link email to ' + email + '?'}
    handleNo={cancelSendToEmail}
    handleYes={sendSignInLink}
  />

  const confirmation_sendPasswordReset = () => <ConfirmationQuestion
    header={'Please Confirm'}
    question={'Send password reset email ' + email + '?'}
    handleNo={cancelSendToEmail}
    handleYes={sendPasswordReset}
  />

  const content = () => {
    switch (showAppManagerType) {
      case showAppManagerTypes.emailSignInLink:
        return confirmation_sendSignInLink()
      case showAppManagerTypes.emailPasswordReset:
        return confirmation_sendPasswordReset()
      case showAppManagerTypes.email:
      case showAppManagerTypes.notification:
      case showAppManagerTypes.sms:
        return <DirectMessage />
      case showAppManagerTypes.auth:
        return <UserAuth inApp={true} item_cpd={item_cpd} />
      case showAppManagerTypes.profile:
        return <UserProfile item_cpd={item_cpd} />
      default:
        return fullPageWrapper()
    }
  }

  return <AppUserManagerContext.Provider value={{ appUserManager_state, appUserManager_handlers }} >
    {content()}
  </AppUserManagerContext.Provider >

}

export default AppUserManager