import { getMessaging, getToken } from 'firebase/messaging';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { Disclaimers } from '../../../Disclaimers';
import { appUserAccessPermissions, getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { appUserHandlers, appUserReducer, appUserReducerInitialState } from '../reducers/AppUserReducer';
import { AppAccessContext } from './AppAccessContext';
import { AuthContext } from './AuthContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

const _allowStandAlone = true
const _useDisclaimers = false

/** 
@provides appUser_state
@provides appUser_handlers 
*/
export const AppUserContext = createContext();

const AppUserProvider = (props) => {

  // parentContext not available 

  // authContext
  const authContext = useContext(AuthContext)
  const { auth_state } = authContext ? authContext : {}
  const { currentUser, notificationRequestResponse, currentClientMessageToken, messagingSupported, publicVapidKey } = auth_state ? auth_state : {}

  // appAccessContext
  const appAccessContext = useContext(AppAccessContext)
  const { appAccess_state } = appAccessContext ? appAccessContext : {}
  const { accessRequests } = appAccess_state ? appAccess_state : {}

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state, navigate } = papsContext ? papsContext : {}
  const { pathViews, view } = paps_state ? paps_state : {}

  // papsContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { redirectToAppUserPage, appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  const { appSignIn } = aps_global ? aps_global : {}
  const { forceDisclaimers, useCookiesDisclaimer, useSmsDisclaimer, usePushDisclaimer, useEmailDisclaimer } = appSignIn ? appSignIn : {}

  // local state
  const initState = appUserReducerInitialState({ currentUser, aps_appUserSettings, pathViews, accessRequests, pageContext })
  const [appUser_state, appUser_dispatch] = useReducer(appUserReducer, initState);
  const appUser_handlers = appUserHandlers(appUser_dispatch, appUser_state)

  const { appUserChecked, appUser } = appUser_state ? appUser_state : {}
  const { loggedIn } = appUser ? appUser : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { appUserSessionKey, isAdminOrSuper, disclaimerResponses } = appUserAccess ? appUserAccess : {}

  const _forceDisclaimers = forceDisclaimers && !isAdminOrSuper && !disclaimerResponses && (useCookiesDisclaimer || useSmsDisclaimer || usePushDisclaimer || useEmailDisclaimer)

  // a group of functions available to AppUserContext
  const _appUser_fns = appUserAccessPermissions(appUser, appUserCollection)

  // get the profile for the appUser
  useEffect(() => {
    appUser_handlers.handleInitCurrentUser(currentUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(
    () => {
      const isLanding = view === 'events' || view === 'landing'
      if (redirectToAppUserPage && isLanding && appUserSessionKey && appUserCollection && !isAdminOrSuper) {
        const path = '/clients/' + pathViews.clients + '/events/' + pathViews.events + '/' + appUserCollection + '/' + appUserSessionKey
        navigate(path)
      }
    },
    // eslint-disable-next-line
    [view, redirectToAppUserPage, appUserSessionKey, appUserCollection]
  )

  // message - getToken
  // runs when `appUser` changes
  useEffect(() => {
    let isStandAlone;
    if (window.matchMedia('(display-mode: standalone)').matches) {
      isStandAlone = true
    } else {
      isStandAlone = false
    }

    if (notificationRequestResponse === 'granted') {

      const messaging = messagingSupported ? getMessaging() : null

      if ((isStandAlone || _allowStandAlone) && messaging && appUser && appUser.loggedIn && appUser.profileData && !currentClientMessageToken) {

        getToken(messaging, { vapidKey: publicVapidKey }).then((newFcmToken) => {
          if (newFcmToken) {
            appUser_handlers.handleCurrentClientMessageToken(newFcmToken)
            appUser_handlers.handleUpdateAppUserFcmTokenToDatabase(newFcmToken, appUser)
          } else {
            // Show permission request. 
            appUser_handlers.handleCurrentClientMessageToken('xxxx')
          }
        }).catch((err) => {
          appUser_handlers.handleCurrentClientMessageToken(err)
          console.log('An error occurred while retrieving token.', err);
        });
      }
    }
    // eslint-disable-next-line
  }, [appUser]);

  // get the profile for the appUser
  // useEffect(() => {
  //   appUser_handlers.handleGetProfile()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [accessRequests])

  // const fpw = () => <FullPageWrapper
  //   content={'TEST'}
  //   topperCaption={'Disclaimers'}
  // />

  // return fpw()

  const provider = () => {
    if (loggedIn && _forceDisclaimers && _useDisclaimers) {
      return <Disclaimers appUser_handlers={appUser_handlers} />
    } else {
      return <AppUserContext.Provider value={{
        appUser_state,
        appUser_handlers,
        appUser_fns: { ..._appUser_fns },
      }} >
        {props.children}
      </AppUserContext.Provider >
    }
  }

  return appUserChecked ? provider() : <div></div>
}

export default AppUserProvider