import { gEnums } from '../../enums/globalEnums';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fs_delete_doc, fs_set_doc } from '../../firestoreData/appData/fsData';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { removeArrayItem } from '../../common/sorting';

const rts = {
  handleAddQuestion: 'handleAddQuestion',
  handleAddResponse: 'handleAddResponse',
  handleGetQuestionsAndAnswers: 'handleGetQuestionsAndAnswers',
  handleLikeQuestion: 'handleLikeQuestion',
  handleSetQuestionsAndAnswers: 'handleSetQuestionsAndAnswers',
  handleUpdateQandA: 'handleUpdateQandA',
}

export const _collectionName = '_questionsAndAnswers'

export const questionsAndAnswersReducer = (state, action) => {

  const {
    allowQuestionsAndAnswers,
    page_fns,
    pathViews,
  } = state

  const { dispatch, type } = action
  const { getViRef } = page_fns ? page_fns : {}

  // eslint-disable-next-line
  const { handleSetQuestionsAndAnswers } = questionsAndAnswersHandlers(dispatch)

  switch (type) {

    case rts.handleGetQuestionsAndAnswers:
      if (allowQuestionsAndAnswers && pathViews.events) {
        const baseRef = getViRef(_collectionName)
        const { refPath } = baseRef
        const _refPath = createRefPath([_collectionName], refPath)
        fs_get_data({ refPath: _refPath, callback: handleSetQuestionsAndAnswers, opts: { listen: true } })
      }
      return { ...state, questionsAndAnswers: {} }

    // sets the data after the handleGetQuestionsAndAnswers call
    case rts.handleSetQuestionsAndAnswers:
      return { ...state, questionsAndAnswers: action.qas }

    case rts.handleUpdateQandA:
      updateQandA(pathViews, action.itemKey, action.data, action.callback)
      return { ...state }

    case rts.handleAddQuestion:
      updateQandA_question(pathViews, action)
      return { ...state }

    case rts.handleAddResponse:
      updateQandA_response(pathViews, action)
      return { ...state }

    case rts.handleLikeQuestion:
      updateQandA_like(pathViews, action)
      return { ...state }

    default:
      return { ...state }
  }
}

export const questionsAndAnswersInitialState = (initState) => {
  return { ...initState }
};

export const questionsAndAnswersHandlers = (dispatch) => {
  return {
    handleAddQuestion: (itemKey, qAndA, question, callback) => { dispatch({ type: rts.handleAddQuestion, dispatch, itemKey, qAndA, question, callback }) },
    handleAddResponse: (itemKey, qAndA, questionKey, response, callback) => { dispatch({ type: rts.handleAddResponse, dispatch, itemKey, qAndA, questionKey, response, callback }) },
    handleGetQuestionsAndAnswers: () => { dispatch({ type: rts.handleGetQuestionsAndAnswers, dispatch }) },
    handleLikeQuestion: (itemKey, qAndA, questionKey, uid, callback) => { dispatch({ type: rts.handleLikeQuestion, dispatch, itemKey, qAndA, questionKey, uid, callback }) },
    handleSetQuestionsAndAnswers: (qas) => { dispatch({ type: rts.handleSetQuestionsAndAnswers, dispatch, qas }) },
    handleUpdateQandA: (itemKey, data, callback) => { dispatch({ type: rts.handleUpdateQandA, dispatch, itemKey, data, callback }) },
  }
}

const updateQandA = async (pathViews, item_key, data, callback) => {
  if (pathViews && item_key && data) {
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, item_key])
    fs_set_doc(_updateRef2, data, true, callback)
  }
}

const updateQandA_question = async (pathViews, action) => {
  const { itemKey, qAndA, question, callback } = action
  if (pathViews && itemKey && question) {
    const { questions } = qAndA ? qAndA : {}
    const _questions = questions ? questions : {}
    const _questionCount = questions ? Object.keys(questions).length + 1 : 1
    _questions['question' + _questionCount] = { question }
    const data = { questions: _questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    fs_set_doc(_updateRef2, data, true, callback)
  }
}

const updateQandA_response = async (pathViews, action) => {
  const { itemKey, qAndA, questionKey, response, callback } = action
  if (pathViews && itemKey && response) {
    const { questions } = qAndA ? qAndA : {}
    const question = questions[questionKey]
    question.response = response
    const data = { questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    fs_set_doc(_updateRef2, data, true, callback)
  }
}

const updateQandA_like = async (pathViews, action) => {
  const { itemKey, qAndA, questionKey, uid, callback } = action
  if (pathViews && itemKey && uid) {
    const { questions } = qAndA ? qAndA : {}
    const question = questions[questionKey]
    const { likes } = question ? question : {}
    const _likes = likes ? likes : []
    if (_likes.includes(uid)) {
      removeArrayItem(_likes, uid)
    } else {
      _likes.push(uid)
    }
    question.likes = _likes
    const data = { questions }
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
    fs_set_doc(_updateRef2, data, true, callback)
  }
}