import _ from 'lodash';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { gEnums } from '../../../enums/globalEnums';
import { gridItemIconGroup } from './SegIconGroup';

export const gridItemsMain = (props) => {

  const { parentContext, frameworkContext, gridPageItems, gcProps, hidden } = props

  const { states, fns, settings } = parentContext ? parentContext : {}

  const { homeSettings } = settings
  const { appUser_state, page_state, paps_state } = states
  const { page_fns, storage_fns } = fns

  // papsContext 
  const { pathViews } = paps_state ? paps_state : {}

  // appUserContext 
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { appUserType, appUserSessionKey, events: events_appUser } = appUserAccess ? appUserAccess : {}
  const currentAppUserEvent = events_appUser && pathViews.events ? events_appUser[pathViews.events] : null
  const { appUserKey } = currentAppUserEvent ? currentAppUserEvent : {}
  const _appUserSessionKey = appUserKey ? appUserKey : appUserSessionKey

  // frameworkContext 
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode, isMobileDevice } = framework_state ? framework_state : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}

  const { global: global_home } = homeSettings ? homeSettings : {}
  const { appUrls: homeAppUrls } = global_home ? global_home : {}
  const { appUrls: appUrlz } = homeAppUrls ? homeAppUrls : {}

  const weatherLink = page_fns.getLink(appUrlz, 'weather', isMobileDevice)

  const { eventOptions, clientOptions, appGallery, appUserDataSettings } = aps_global ? aps_global : {}
  const { allowGallery, galleryCaption, galleryIcon } = appGallery ? appGallery : {}

  // kara@thgrp.com
  // retreat_2023

  const _appOptions = { ...clientOptions, ...eventOptions }
  let { showLyft, showLyftOnHomeScreen, showUber, showUberOnHomeScreen, showWeatherOnHomeScreen, showWeather, weatherZip } = _appOptions ? _appOptions : {}
  const { appUserCollection, showMyProfileLinkOnHomeScreen, myProfileCaption, myProfileIcon, myProfileIconLocation, allowNonEventAppUsers } = aps_appUserSettings ? aps_appUserSettings : {}

  let _allowMyProfileIcon;

  if (showMyProfileLinkOnHomeScreen) {
    switch (appUserType) {
      case gEnums.appUserTypes.appAdmin:
      case gEnums.appUserTypes.appDataAdmin:
      case gEnums.appUserTypes.appProfile:
      case gEnums.appUserTypes.appSubAdmin:
        if (_appUserSessionKey || allowNonEventAppUsers) {
          _allowMyProfileIcon = true
        }
    }
  }

  const gridItem = (viewItem, segProps, gcProps) => gridItemIconGroup(viewItem, segProps, gcProps, hidden)

  const myProfileGridItem = (items) => {

    const giProps = {
      caption: myProfileCaption ? myProfileCaption : _.startCase('myProfile'),
      icon: myProfileIcon ? myProfileIcon : 'user',
      cloned: true,
    }
    const clickKey = appUserCollection

    if (appUserAccess && pathViews.events && appUserAccess.events && appUserAccess.events[pathViews.events] && appUserAccess.accessLevel > gEnums.accessLevels.guest.value) {
      switch (myProfileIconLocation) {
        case gEnums.iconLocations.last:
          items.push(gridItem({ key: clickKey, itemKey: _appUserSessionKey }, giProps, gcProps))
          break;
        default:
          items.unshift(gridItem({ key: clickKey, itemKey: _appUserSessionKey }, giProps, gcProps))
      }
    } else {
      items.unshift(gridItem({ key: clickKey, itemKey: _appUserSessionKey }, giProps, gcProps))
    }
  }

  // const favoritesGridItem = (items) => {
  //   const giProps = {
  //     caption: _.startCase('favorites'),
  //     icon: 'star outline',
  //   }
  //   items.push(gridItem({ key: 'favorites', itemKey: _appUserSessionKey }, giProps, gcProps))
  // }

  const items = []
  const altItems = []

  // for each viewItem, create an element and view
  Object.keys(gridPageItems).forEach(vit => {
    const viewItem = gridPageItems[vit]
    const segProps = page_fns.getGVIProps(vit, viewItem, storage_fns, desktopMode)
    items.push(gridItem(viewItem, segProps, gcProps))
  })

  if (_allowMyProfileIcon) { myProfileGridItem(items) }
  // if (showFavoritesOnHomeScreen) { favoritesGridItem(items) }

  if (allowGallery) { items.push(gridItem({ key: 'gallery' }, { caption: galleryCaption ? galleryCaption : 'Gallery', icon: galleryIcon ? galleryIcon : 'images outline' }, gcProps)) }

  if (showLyft && showLyftOnHomeScreen) { altItems.push(gridItem({ key: 'lyft' }, { caption: 'Lyft', icon: 'lyft' }, gcProps)) }
  if (showUber && showUberOnHomeScreen) { altItems.push(gridItem({ key: 'uber' }, { caption: 'Uber', icon: 'uber' }, gcProps)) }
  if (showWeather && showWeatherOnHomeScreen && weatherLink && weatherZip) { altItems.push(gridItem({ key: 'weather' }, { caption: 'Weather', icon: 'cloud' }, gcProps)) }

  return [...items, ...altItems]

}