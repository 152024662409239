import React, { useContext, useState } from 'react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import UpdateWrapper from '../../../../global/pageItem/modification/UpdateWrapper';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { createNewMatch } from '../../dbActions/updateMatch';
import MatchForm from './MatchForm';

const MatchCreate = (props) => {

  const { handleSetShowNewMatch, matchDate } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state } = states
  const { pathViews } = paps_state ? paps_state : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { teams_info, latestSeason, sportsKey } = sportsSeason_state
  const { teams } = teams_info ? teams_info : {}

  console.log('sportsSeason_state', sportsSeason_state)

  const initDate = formatItem(formatTypes.fullDate, new Date(matchDate))
  const initTime = '19:00:00'

  const [match_current, setCurrentMatch] = useState({ away: null, home: null, startDate: initDate, startTime: initTime })
  const [matchReady, setMatchReady] = useState()

  const handleUpdateParent = (data) => {
    const { match_current: cm } = data ? data : {}
    setCurrentMatch(cm)
  }

  const handleClose = () => props.handleClose ? props.handleClose() : handleSetShowNewMatch()

  const getTeam = (team) => {
    const t = teams[team]
    return {
      id: t.id,
      name: t.name,
      levels: t.levels,
      sections: t.sections
    }
  }

  const handleCreateNewMatch = () => {
    const cm = { ...match_current }
    const homeTeam = getTeam(cm.home)
    const awayTeam = getTeam(cm.away)
    const newMatch = {
      bestOf: true,
      setCount: 5,
      startDate: matchDate ? matchDate : '???',
      startTime: '7:00 PM',
      home: homeTeam,
      away: awayTeam,
    }
    newMatch.key = newMatch.away.name + '-' + newMatch.home.name
    newMatch.teams = [newMatch.home.id, newMatch.away.id]
    if (newMatch.away.levels === newMatch.home.levels) {
      newMatch.levels = newMatch.home.levels
      if (newMatch.away.sections === newMatch.home.sections) {
        newMatch.sections = newMatch.home.sections
      } else {
        newMatch.sections = 'Non'
      }
    } else {
      newMatch.levels = 'Non'
      newMatch.sections = 'Non'
    }
    const actionProps = { match_current: newMatch }
    createNewMatch(pathViews, latestSeason, sportsKey, actionProps, handleClose)
  }

  return <UpdateWrapper
    content={<MatchForm {...props} setMatchReady={setMatchReady} match_current={match_current} handleUpdateParent={handleUpdateParent} />}
    caption={'createMatch'}
    handleSave={handleCreateNewMatch}
    handleClose={handleClose}
    allowSave={matchReady}
  />

}

export default MatchCreate