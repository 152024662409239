import { _globalCollectionName } from "../../../projectSpecific/sports/dbActions/globals";
import { fsfn_updateStaticViews } from "../../functions/fbDataFunctions";
import { dispatchProps } from "../../cnr/reducers/reducerHelpers/dispatchProps";
import { fs_get_data } from "../appData/fsAppData";
import { getBaseRef } from '../helpers/getBaseRef';

const staticViewCollectionName = 'staticViews'

export const saveToStaticViews = (pathViews, viewItem, viewListData, staticName, fixedStaticView, dataRestrictions, dispatch) => {

  const { key: currentVit } = viewItem ? viewItem : {}

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.backupSeasonals)) {
    dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })
    return true
  }

  if (currentVit) {
    fsfn_updateStaticViews(pathViews, viewItem, viewListData, staticName, fixedStaticView, null, dispatch)
  }
}

/**
 * 
 * @param {object} paps_state 
 * @param {string} currentVit 
 * @param {function} callback 
 * @param {object} pageContext 
 * @param {boolean} clientOnly 
 * @param {object} globals 
 */
export const getStaticViews = (paps_state, currentVit, callback, pageContext, clientOnly, globals) => {

  const { page_fns } = pageContext
  const { getGviDeps } = page_fns ? page_fns : {}

  const { pathViews } = paps_state
  const viDeps = getGviDeps(currentVit)
  const baseRef = getBaseRef(viDeps, pathViews, null, null, null, clientOnly)
  let { refPath } = baseRef

  refPath += globals ? '/' + _globalCollectionName : '/' + staticViewCollectionName

  fs_get_data({ refPath: refPath, opts: { ignoreId: true } }).then(resultData => {
    if (globals) {
      callback({ hasData: true, globals: resultData })
    } else {
      callback({ hasData: true, staticViews: resultData })
    }
  }).catch((err) => {
    if (globals) {
      callback({ hasData: true, globals: {} })
    } else {
      callback({ hasData: true, staticViews: {} })
    }
  })
}

// const getRealtimeDb = (refPath, returnFn) => {
//   const db = getDatabase()
//   getRealTimeDoc(refPath + '/staticViews').then(snapshot => {
//     returnFn({ hasData: true, staticViews: snapshot.val() })
//   })
// }

// export const createStaticViews = (dispatch, eventDocRef, staticViews, type) => {
//   const data = { staticViews: { ...staticViews } }
//   eventDocRef.set(data).then(() => {
//     dispatch({ type: type })
//   }).catch(error => {
//     dispatch({ type: type, error })
//   });
// }

export const createAppStaticViews = (app_state, viSubKeys, aps_viewItems) => {
  const asl = {}
  if (viSubKeys && app_state) {
    viSubKeys.forEach(sk => {
      if (app_state[sk]) {
        const skItems = app_state[sk]
        const vi = aps_viewItems ? aps_viewItems[sk] : null
        asl[sk] = {}

        Object.keys(skItems).forEach(skItemKey => {
          const skItem = skItems[skItemKey]
          if (skItem.name || (skItem.lastName && skItem.firstName)) {
            asl[sk][skItemKey] = {}
            if (skItem.name) {
              asl[sk][skItemKey].name = skItem.name``
            } else if (skItem.lastName && skItem.firstName) {
              asl[sk][skItemKey].firstName = skItem.firstName
              asl[sk][skItemKey].lastName = skItem.lastName
            }
          }

          if (vi && vi.props) {
            if (vi.props) {
              Object.keys(vi.props).forEach(prKey => {
                if (vi.props[prKey] && vi.props[prKey].data && vi.props[prKey].data.saveToStatic) {
                  if (skItem[prKey]) {
                    asl[sk][skItemKey][prKey] = skItem[prKey]
                  }
                }
              })
            }
          }
        })
      }
    })
  }
  return asl
}