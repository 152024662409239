import React from 'react';
import { AppUserTicketingWithProvider } from '../../../projectSpecific/ticketing/components/AppUserTicketing';
import AppNotifications, { appNotificationTypes } from '../../appNotifications/AppNotifications';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { swipePropNew } from '../../cnr/reducers/reducerHelpers/swipeHelpers';
import { appFormTypes } from '../../enums/appFormTypes';
import { gEnums } from '../../enums/globalEnums';
import QrCode from '../../pageItem/modification/pageItemActions/QrCode';
import AppUserPages from './AppUserPages';
import UserAuth from './UserAuth';
import UserConnections from './UserConnections';
import UserConversations from './UserConversations';
import UserEvents from './UserEvents';
import UserFavorites from './UserFavorites';
import UserNotes from './UserNotes';

/**
 * 
 * @param {object} aps_global 
 * @param {object} authAppUser 
 * @returns a group of components associated with the `appUser`
 */
export const userProfileItems = (aps_global, aps_appUserSettings, authAppUser) => {

  /** Simple props builder
 * @returns key
 * @returns captions
 * @returns content
 * @description Items using UiSwiper need to be passed in with these props.
 */

  const { appTicketing, appNotifications, appNotes } = aps_global ? aps_global : {}
  const { appUserPages, qrCodeProps } = aps_appUserSettings ? aps_appUserSettings : {}
  const { allowNotifications } = appNotifications ? appNotifications : {}
  const { allowNotes } = appNotes ? appNotes : {}
  const { ticketingCaption } = appTicketing ? appTicketing : {}
  const _ticketCaption = ticketingCaption ? ticketingCaption : 'Tickets'

  const appUserAccess = getAppUserAccess(authAppUser)

  const addItem = (pageType, items) => {

    switch (pageType) {
      case gEnums.appUserPersonalPageTypes.auth:
        if (appUserAccess && appUserAccess.isAdminOrSuper) items[pageType] = swipePropNew(pageType, <UserAuth itemData={authAppUser} inApp={true} />, true)
        break;

      case gEnums.appUserPersonalPageTypes.connections:
        items[pageType] = swipePropNew(pageType, <UserConnections />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.conversations:
        items[pageType] = swipePropNew(pageType, <UserConversations />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.events:
        if (appUserAccess && !appUserAccess.isAdminOrSuper) items[pageType] = swipePropNew(pageType, <UserEvents />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.favorites:
        items[pageType] = swipePropNew(pageType, <UserFavorites />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.notes:
        if (allowNotes) {
          items[pageType] = swipePropNew(pageType, <UserNotes />, false, true)
        }
        break;

      case gEnums.appUserPersonalPageTypes.notifications:
        if (allowNotifications) {
          items[pageType] = swipePropNew(pageType, <AppNotifications appNotificationType={appNotificationTypes.userNotifications} />, false, true)
        }
        break;

      case gEnums.appUserPersonalPageTypes.qrCode:
        items[pageType] = swipePropNew(pageType, <QrCode qrCodeType={gEnums.qrCodeTypes.appUser} qrCodeProps={qrCodeProps} />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.settings:
        items[pageType] = swipePropNew(pageType, <AppUserPages appFormType={appFormTypes.appUserSettings} useFullPageWrapper={false} />, false, true)
        break;

      case gEnums.appUserPersonalPageTypes.ticketing:
        items[_ticketCaption] = swipePropNew(_ticketCaption, <AppUserTicketingWithProvider />, false, true)
        break;

      default:
      // nothing
    }
    // }
  }

  const items = {}

  if (appUserPages) {
    appUserPages.forEach(aup => {
      addItem(aup, items)
    })
  }

  return items
}
