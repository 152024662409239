import React, { useContext } from 'react';
import { g_cns } from '../common/cns';
import { FilterContext } from '../cnr/contexts/FilterContext';
import GenericIcon from '../genericControls/GenericIcon';

let _iconSize = 'large'

/** ItemPopUp is a component of UiItem */
const UiFilterPopup = (props) => {

  const { forArray } = props
  if (forArray) { _iconSize = 'small' }

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_handlers } = filterContext
  const { handleFilterSidebar } = uiFilter_handlers ? uiFilter_handlers : {}

  const allowItemPopup = true

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const filterPopup = () => {
    console.log('filterPopup')
    return <span className={g_cns.item_popup}>
      {gi('item-icon-filter', 'filter', handleFilterSidebar, null, { circular: true, size: _iconSize, color: 'blue', inverted: true })}
    </span>
  }

  if (allowItemPopup) {
    return filterPopup()
  } else {
    return <div></div>
  }
}

export default UiFilterPopup