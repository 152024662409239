import React, { useContext } from 'react';
import { Card } from 'semantic-ui-react';
import { getAppUserLink } from '../../../auth/accessPermissions';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { uniqueKey } from '../../../common/keys';
import { gEnums } from '../../../enums/globalEnums';
import { _animateTypes } from '../../../motions/AnimateComponent';
import MotionComponent from '../../../motions/MotionComponent';
import { groupTaggedPropSectionElements } from '../../../viewSettings/helpers/settingsHelpers';
import UiImage from '../../imaging/UiImage';
import BusScheduleLabels from './BusScheduleLabels';
import CardLink from './CardLink';
import DateTimeLabels from './DateTimeLabels';
import StatusLabels from './StatusLabels';
import TimeLabels from './TimeLabels';

const CardSchedule = (props) => {

  const { itemData, handleClick, cardProps } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, eventInfo_state, page_state, paps_state, transition_state } = states
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const { staticViews, staticViews_gs } = eventInfo_state ? eventInfo_state : {}
  const { view, viewKey } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}
  const { appUserPermissions } = aps_global ? aps_global : {}
  const { allowRating, ratingCollections, allowLinks, links } = appUserPermissions ? appUserPermissions : {}

  // transitionContext  
  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.pageItemElement] : null
  const { showTransition } = transition ? transition : {}

  // uiItemContext 
  const uiItemContext = useContext(UiItemContext)
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem, _display, propSections_allowed, propGroups } = item_state ? item_state : {}
  const { cardDisplayType } = _display ? _display : {}
  const { scheduleType } = cardProps ? cardProps : {}
  const { images, key: key_viewItem } = viewItem
  const { showItemImage, imageLocation: profileImageLocation, imageSize: profileImageSize } = images ? images : {}

  const appUserAccess = getAppUserAccess(authAppUser)
  const { appUserSession, appUserSessionKey } = appUserAccess ? appUserAccess : {}
  const appUserLink = viewItem && links && getAppUserLink(appUserCollection, links, itemData, viewItem, appUserSession)

  const _staticViews = staticViews_gs ? staticViews_gs : staticViews

  let imageInContent = true

  switch (profileImageLocation) {
    case gEnums.imageLocations.top:
      imageInContent = false
      break;
    default:
    // nothing
  }

  // const ref = useRef(null)
  // const [isPresent, safeToRemove] = usePresence();

  // useEffect(() => {
  //   if (!isPresent) {
  //     console.log('PRESENT')
  //     // gsap.to(ref.current, {
  //     //   opacity: 0,
  //     //   onComplete: () => safeToRemove?.()
  //     // });
  //   }
  // }, [isPresent, safeToRemove]);

  const handleItemClick = () => handleClick(viewItem, itemData.id, null, { itemData: itemData })

  const getCn = () => {
    let _cn = 'card-schedule'
    let _showFullImage = false

    switch (cardDisplayType) {
      case gEnums.cardDisplayTypes.profilePlus:
      case gEnums.cardDisplayTypes.schedulePlus:
        _cn += ' plus'
        _showFullImage = true
        break;
      case gEnums.cardDisplayTypes.event:
        if (itemData && itemData.eventStatusType) {
          _cn += ' ' + itemData.eventStatusType
        }
        break;
      default:
      // nothing
    }
    return { cn: _cn, showFullImage: _showFullImage }
  }

  const { cn, showFullImage } = getCn()

  const cardLink = () => <CardLink
    appUserLink={appUserLink}
    itemData={itemData}
  />

  const cardImage_top = () => <UiImage
    viewItem={viewItem}
    itemData={itemData}
    showFullImage={showFullImage}
  />

  const cardImage_inContent = () => <UiImage
    floated={profileImageLocation ? profileImageLocation : 'left'}
    size={profileImageSize ? profileImageSize : 'small'}
    viewItem={viewItem}
    itemData={itemData}
    fullImage={showFullImage}
  />

  const labels = (_keyy) => {
    switch (cardDisplayType) {
      case gEnums.cardDisplayTypes.busSchedule:
        return <BusScheduleLabels itemData={itemData} />
      case gEnums.cardDisplayTypes.event:
        return <StatusLabels itemData={itemData} />
      default:
        switch (scheduleType) {
          case 'list':
            return <TimeLabels _keyy={_keyy} itemData={itemData} propGroups={propGroups} />
          default:
            return <DateTimeLabels _keyy={_keyy} itemData={itemData} propGroups={propGroups} />
        }
    }
  }

  const card_schedule = (contentOnly) => {

    const _keyy = uniqueKey('crds', 'sc', key_viewItem, itemData.id)

    const ignoreProps = ['startTime', 'startDate', 'endDate', 'endTime', 'locations']

    const { nameElements, metaElements } = groupTaggedPropSectionElements({ _keyy, viewItemKey: key_viewItem, itemData, propsSections: propSections_allowed, ignoreProps, staticViews: _staticViews, appUserSession, aps_global, propGroups, display: _display })

    const cardContent = () => <React.Fragment>
      {showItemImage && !imageInContent && cardImage_top()}
      {scheduleType === 'list' && labels(_keyy)}
      <Card.Content>
        {showItemImage && imageInContent && cardImage_inContent()}
        <Card.Header key={uniqueKey('cs', 'h', _keyy)} as={'h5'}>{nameElements}</Card.Header>
        <Card.Meta key={uniqueKey('cs', 'm', _keyy)}>{metaElements}</Card.Meta>
      </Card.Content>
      {scheduleType !== 'list' && labels(_keyy)}
      {appUserLink && appUserSessionKey && cardLink()}
    </React.Fragment>

    return contentOnly ?
      cardContent()
      :
      <Card fluid key={_keyy} as='div' className={cn} onClick={() => { handleItemClick() }}>
        {cardContent()}
      </Card>
  }

  if (showTransition) {
    return <MotionComponent
      transition={transition}
      cnRoot={'ui fluid card ' + cn}
      oc={handleItemClick}
    >
      {card_schedule(true)}
    </MotionComponent>
  } else {
    return card_schedule()
  }

}

export default CardSchedule