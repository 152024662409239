import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Icon, Label } from 'semantic-ui-react';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { formatItem, formatTypes, timeConvertor } from '../../../common/dateFormatting';
import { uniqueKey } from '../../../common/keys';
import { isDateProp, isTimeProp } from '../../../common/tester';

const _dtProps = {
  startDate: 'startDate',
  endDate: 'endDate',
  startTime: 'startTime',
  endTime: 'endTime',
  location: 'location',
  locations: 'locations',
  time: 'time',
  date: 'date',
}

const _timeTypes = {
  start: 'start',
  end: 'end',
  normal: 'normal'
}
/**
 * 
 * @param {object} props (itemData, _keyy)
 * @param {object} itemData (startDate, startTime, endTime)
 * @returns 
 */
const DateTimeLabels = (props) => {

  const { itemData, _keyy } = props
  const { _dateInfo, locations } = itemData ? itemData : {}
  const { actual, local, tzsDifferent } = _dateInfo ? _dateInfo : {}

  const _itemData = { ...itemData }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, eventInfo_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { display: display_global, imageMapping: imageMapping_global } = aps_global ? aps_global : {}
  const { staticViews, globals: globals_event, appCollectionsData } = eventInfo_state ? eventInfo_state : {}
  const { locations: locations_event } = appCollectionsData ? appCollectionsData : {}
  const { locations: locations_global } = globals_event ? globals_event : {}
  const { groupStartTimes, showTimezoneDifferences: showTimezoneDifferences_g, showTimezoneIndicator: showTimezoneIndicator_g, showTmz: showTmz_g } = display_global ? display_global : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext)
  const { item_state, item_handlers } = uiItemContext ? uiItemContext : {}
  const { propGroups, viewItem } = item_state ? item_state : {}
  const { props: props_viewItem, display } = viewItem ? viewItem : {}
  const { showTimezoneDifferences, showTimezoneIndicator, showTmz } = display ? display : {}
  const { schedule: props_schedule } = propGroups ? propGroups : {}
  const { propArray: propArray_schedule } = props_schedule ? props_schedule : {}

  const _showTimezoneDifferences = (showTimezoneDifferences_g || showTimezoneDifferences) && tzsDifferent
  const _showTimezoneIndicator = showTimezoneIndicator_g || showTimezoneIndicator
  const _showTmz = showTmz_g || showTmz

  const [propsToShow, setPropsToShow] = useState()
  const [otherPropsToShow, setOtherPropsToShow] = useState()

  useEffect(() => {

    const _propsToShow = {}
    const _otherPropsToShow = {}

    if (props_viewItem && _itemData) {
      if (propArray_schedule && propArray_schedule.length > 0) {
        propArray_schedule.forEach(pas => {
          if (props_viewItem[pas] && props_viewItem[pas].show) {
            if (_dtProps[pas]) {
              _propsToShow[pas] = true
            } else {
              _otherPropsToShow[pas] = true
            }
          }
        })
      } else {
        Object.keys(_dtProps).forEach(key => {
          if (props_viewItem[key] && props_viewItem[key].show) {
            if (propArray_schedule.includes(key)) {
              _propsToShow[key] = true
            }
          }
        })
      }
      // change this

    }
    if (Object.keys(_propsToShow).length > 0) { setPropsToShow(_propsToShow) }
    if (Object.keys(_otherPropsToShow).length > 0) { setOtherPropsToShow(_otherPropsToShow) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [itemData, props_viewItem]);

  const handleShowMap = (isMapped) => {
    if (isMapped) {
      item_handlers.handleModifyMapping()
    }
  }

  let cn = 'extra'
  if (groupStartTimes) { cn += ' grp' }
  if (_showTimezoneDifferences) { cn += ' tz' }

  const icon = (iconName, color) => <Icon color={color} name={iconName} />

  const timeLabel = (timeType, dtProp, color) => {

    const { time: time_actual, tzs: tzs_actual } = actual && actual[timeType] && actual[timeType].formatted ? actual[timeType].formatted : {}
    const { time: time_local, tzs: tzs_local } = local && local[timeType] && local[timeType].formatted ? local[timeType].formatted : {}

    if (_showTimezoneDifferences && time_actual && time_local) {
      const t1 = _showTimezoneIndicator ? time_actual + ' ' + tzs_actual : time_actual
      const t2 = _showTimezoneIndicator ? time_local + ' ' + tzs_local : time_local
      return [
        <Label key={uniqueKey('dtl', 4, _keyy, dtProp)} className={'pi-' + dtProp}>
          <div>{icon('time', color)}</div>
          <div>{t1}</div>
          <div>{icon('user outline', color)}</div>
          <div>{t2}</div>
        </Label>,
      ]
    } else {
      return <Label key={uniqueKey('dtl', 4, _keyy, dtProp)} className={'pi-' + dtProp}>
        <div>{icon('time', color)} </div>
        <div>{(_showTmz ? time_actual + ' ' + tzs_actual : time_actual)}</div>
      </Label>
    }
  }

  // njdelia02@gmail.com 
  // njdelia02@gmail.com

  const timeLabel_simple = (text, value, dtProp, key) => <Label key={uniqueKey('dtl', 4, key, dtProp)} className={'pi-' + dtProp}>
    <div>{icon('time', 'green')} </div>
    <div>{text}{': '}{value}</div>
  </Label>

  const labelItemValue = (pts, dtProp, value, k) => {

    if (pts[dtProp] || value) {

      let dtValue1 = value ? value : _itemData[dtProp]

      switch (dtProp) {
        case _dtProps.startDate:
          dtValue1 = actual && actual.start && actual.start.formatted.date
          return dtValue1 && <Label key={uniqueKey('dtl', 1, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('calendar check outline', 'green')}{dtValue1}</Label>

        case _dtProps.endDate:
          dtValue1 = formatItem(formatTypes.shortDate, dtValue1)
          return dtValue1 && <Label key={uniqueKey('dtl', 2, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('calendar check outline', 'red')} {dtValue1}</Label>

        case _dtProps.location:
          return dtValue1 && <Label key={uniqueKey('dtl', 3, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('map marker alternate', 'blue')} {dtValue1}</Label>

        case _dtProps.locations:
          const staticViews_locations = staticViews ? staticViews['locations'] : {}
          const svl = staticViews_locations && staticViews_locations[dtValue1] ? staticViews_locations[dtValue1].name : dtValue1
          const loc = locations_global && locations_global[svl] ? locations_global[svl] : {}
          const { zone } = loc ? loc : {}
          const isMapped = zone ? true : false

          return dtValue1 && <Label key={uniqueKey('dtl', 3, _keyy, dtProp)} className={'pi-' + dtProp}
            onClick={(e) => { handleShowMap(isMapped) }}
          >{icon('map marker alternate', isMapped ? 'blue' : 'grey')} {svl}</Label>

        case _dtProps.startTime:
          return dtValue1 && timeLabel(_timeTypes.start, dtProp, 'green')

        case _dtProps.endTime:
          return dtValue1 && timeLabel(_timeTypes.end, dtProp, 'red')

        case _dtProps.date:
          const value_date = formatItem(formatTypes.shortDate, dtValue1)
          return dtValue1 && <Label key={uniqueKey('dtl', 1, _keyy, dtProp)} className={'pi-' + dtProp}>{icon('calendar check outline', 'green')}{value_date}</Label>

        case _dtProps.time:
          const { hour, min } = timeConvertor(dtValue1)
          let value_time = dtValue1
          if (hour && min) {
            const dt = new Date()
            dt.setHours(hour)
            dt.setMinutes(min)
            value_time = formatItem(formatTypes.time, dt)
          }
          return dtValue1 && timeLabel_simple(k, value_time, dtProp)

        default:
        // nothing
      }
    }
  }

  const otherProps = () => {
    cn += ' other'
    const _others = []
    Object.keys(otherPropsToShow).forEach(k => {
      if (isDateProp(k)) {
        _others.push(labelItemValue(otherPropsToShow, _dtProps.date, _itemData[k], _.startCase(k)))
      }
      if (isTimeProp(k)) {
        _others.push(labelItemValue(otherPropsToShow, _dtProps.time, _itemData[k], _.startCase(k)))
      }
    })
    return <Card.Content key={uniqueKey('dtl', 'c', _keyy)} className={cn}>
      {_others}
    </Card.Content>
  }


  if (otherPropsToShow) {
    return otherProps()
  } else if (propsToShow) {
    return <Card.Content key={uniqueKey('dtl', 'c', _keyy)} className={cn}>
      {labelItemValue(propsToShow, _dtProps.startDate)}
      {labelItemValue(propsToShow, _dtProps.startTime)}
      {labelItemValue(propsToShow, _dtProps.endTime)}
      {labelItemValue(propsToShow, _dtProps.location)}
      {labelItemValue(propsToShow, _dtProps.locations)}
      {otherPropsToShow && otherProps()}
    </Card.Content>
  }
}

export default DateTimeLabels