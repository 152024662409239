import React, { useEffect } from 'react'
import FileUploader from "react-firebase-file-uploader";
import { Icon } from 'semantic-ui-react';

const UploadDirectButton = (props) => {

  const accept = '*'

  const { upload_state, upload_handlers, storageType, storageRef, handleSelectedImageFile } = props

  const { selectedFolderFiles } = upload_state ? upload_state : {}

  useEffect(() => {
    if (selectedFolderFiles && handleSelectedImageFile) {
      handleSelectedImageFile(selectedFolderFiles)
    }
    // return () => window.removeEventListener("transitionend", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedFolderFiles]);

  const handleFileSelect = (e) => upload_handlers.handleImageFileSelect(e, storageType)

  /** Handles the uploading of files to firebase storage */
  const fileUploader = () => <FileUploader
    hidden
    accept={accept}
    name="avatar"
    storageRef={storageRef}
    onUploadStart={upload_handlers.handleStartUpdate}
    onUploadError={upload_handlers.handleUploadError}
    onProgress={upload_handlers.handleProgress}
    onChange={handleFileSelect}
    multiple={true}
  />

  /**
   * 
   * @returns the button that opends the fileUploader
   */
  const selectButton = () => <div className="upload-btn-wrapper direct">
    <button className="btn ui button blue"><Icon name={'add'}></Icon></button>
    {fileUploader()}
  </div>

  return selectButton()
}

export default UploadDirectButton