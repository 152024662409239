import { gEnums } from "../enums/globalEnums";
import { getItemsInArrayOrDirect } from "../common/filtering";
import { listStatic } from "../lists/lists";
import AllOptions from "./options";

export const dataListOptionsList = (optionProps) => {

  let optsList;

  const { itemProp, staticViews, staticViews_client, data_combined } = optionProps

  const { data: data_prop } = itemProp ? itemProp : {}

  const {
    dataListType,
    end: end_list,
    listFiltered,
    listFilteredOn,
    listFilteredOnProp,
    start: start_list,
    staticDataCollection,
    staticList,
  } = data_prop ? data_prop : {}

  let static_views = staticViews

  switch (staticDataCollection) {
    case gEnums.staticDataCollectionTypes.clients:
      if (staticViews_client) { static_views = staticViews_client }
      break;
    default:
    // nothing
  }

  switch (dataListType) {
    case gEnums.dataListTypes.authLevels:
      break;

    case gEnums.dataListTypes.staticViews:
      if (static_views) {
        if (static_views[staticList]) {
          if (listFiltered && listFilteredOn) {
            const staticData = static_views[staticList]
            const filterValue = data_combined ? data_combined[listFilteredOn] : null
            const staticViewFilter = getItemsInArrayOrDirect(staticData, listFilteredOn, filterValue, listFilteredOnProp)
            optsList = listStatic(staticViewFilter, staticList)
          } else {
            optsList = listStatic(static_views[staticList], staticList)
          }
        }

        if (static_views[staticList]) {
          if (listFiltered && listFilteredOn) {
            const staticData = static_views[staticList]
            const filterValue = data_combined ? data_combined[listFilteredOn] : null
            const staticViewFilter = getItemsInArrayOrDirect(staticData, listFilteredOn, filterValue, listFilteredOnProp)
            optsList = listStatic(staticViewFilter, staticList)
          } else {
            optsList = listStatic(static_views[staticList], staticList)
          }
        }
      }
      break;

    case gEnums.dataListTypes.number:
      optsList = AllOptions.numberOptions({ start_list: parseInt(start_list), end_list: parseInt(end_list), noNone: true })
      break;

    case gEnums.dataListTypes.decimal:
      optsList = AllOptions.decimalOptions({ start_list: parseInt(start_list), end_list: parseInt(end_list), noNone: true })
      break;

    default:
    // nothing
  }

}