import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { appUsersHandlers, appUsersReducer, appUsersReducerInitialState } from '../reducers/AppUsersReducer';
import { AppAccessContext } from './AppAccessContext';
import { AuthContext } from './AuthContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** 
@provides appUser_state
@provides appUser_handlers 
*/
export const AppUsersContext = createContext();

const AppUsersProvider = (props) => {

  // parentContext not available 

  // authContext
  const authContext = useContext(AuthContext)
  const { auth_state } = authContext ? authContext : {}
  const { currentUser, notificationRequestResponse, currentClientMessageToken, messagingSupported, publicVapidKey } = auth_state ? auth_state : {}

  // appAccessContext
  const appAccessContext = useContext(AppAccessContext)
  const { appAccess_state } = appAccessContext ? appAccessContext : {}
  const { accessRequests } = appAccess_state ? appAccess_state : {}

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state, navigate } = papsContext ? papsContext : {}
  const { pathViews, view } = paps_state ? paps_state : {}

  // papsContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}

  // local state
  const initState = appUsersReducerInitialState({ pathViews })
  const [appUsers_state, appUsers_dispatch] = useReducer(appUsersReducer, initState);
  const appUsers_handlers = appUsersHandlers(appUsers_dispatch, appUsers_state)

  // get the profile for the appUser
  useEffect(() => {
    appUsers_handlers.handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const provider = () => {
    return <AppUsersContext.Provider value={{
      appUsers_state,
      appUsers_handlers,
    }} >
      {props.children}
    </AppUsersContext.Provider >
  }

  return provider()
}

export default AppUsersProvider