import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';

const MenuItemCenter = (props) => {
  const { menuProps } = props
  const { index, value, handleMenuClick, width, type, disabled, formattedItem } = menuProps
  return <Menu.Item key={uniqueKey('mi', index)} style={{ width: width }} onClick={e => !disabled && handleMenuClick('pick', type)} >
    {formattedItem ? formattedItem : value}
    <Icon name='caret down' />
  </Menu.Item>
}

export default MenuItemCenter