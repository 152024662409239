import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Menu, Segment, Sidebar } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { UiDashboardForm } from '../../../../global/pageItem/modification/dataModifications/UiDashboardForm'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import MatchListUpdate from '../matches/MatchListUpdate'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'

export const sportsDataModes = {
  googleLinks: 'googleLinks',
  teamSettings: 'teamSettings',
  updateSeasonScores: 'updateSeasonScores',
}

const _caption = 'Sports Dashboard'

const SportsDashboard = (props) => {

  const { handleCancel } = props ? props : {}

  const sportsSeasonContext = useContext(SportsSeasonContext)

  const [captions, setCaptions] = useState({ topper: _caption, topper2: 'Sports Data' })
  const [activeMenuItems, setActiveMenuItems] = useState({})
  const [mode, setMode] = useState()
  const [sportsDataMode, setSportsDataMode] = useState()

  useEffect(() => {
    let _menuItems = {}
    Object.keys(sportsDataModes).forEach(k => {
      _menuItems[k] = k
    })
    setSportsDataMode(sportsDataModes.googleLinks)
    setCaptions({ topper: _caption, topper2: _.startCase(sportsDataModes.googleLinks) })
    setActiveMenuItems(_menuItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMode = (adm) => setMode(adm)

  const handleDataMode = (adm) => {
    const _adm = adm ? adm : sportsDataModes.googleLinks
    setSportsDataMode(_adm)
    setCaptions({ topper: _caption, topper2: _.startCase(_adm) })
    setMode()
  }

  const modeMenu = () => {
    const mis = []
    Object.keys(activeMenuItems).forEach(key => {
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', key)} onClick={() => { handleDataMode(key) }}>{_.startCase(key)}</Menu.Item>)
    })
    return <Segment basic><Menu vertical fluid inverted className={'av-menu'}>{mis}</Menu></Segment>
  }

  const VerticalSidebarLeft = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv-settings'
      onHide={() => handleMode()}
    >
      {modeMenu()}
    </Sidebar>
  )

  const modeButtons = () => {
    const btns = [
      { caption: _.startCase(sportsDataMode), oc: handleMode, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={'black'}
      rows={2}
    />
  }

  const content = () => {
    switch (sportsDataMode) {
      case sportsDataModes.googleLinks:
      case sportsDataModes.teamSettings:
        return <UiDashboardForm sportsDataMode={sportsDataMode} forSportsManagement={true} />
      case sportsDataModes.updateSeasonScores:
        return <MatchListUpdate forSportsManagement={true} sportsDataMode={sportsDataMode} />
      default:
        return <div>{'???'}</div>
    }
  }

  const header = () => <div className={'header-flex'}>
    {modeButtons()}
  </div>

  const wrapper = () => <Wrapper
    header={header()}
    content={content()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ container: 'apv' }}
  />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <VerticalSidebarLeft visible={mode ? true : false} />
    <Sidebar.Pusher dimmed={mode ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    handleCancel={handleCancel}
    topperCaption={captions.topper}
    topperCaption2={captions.topper2}
    fullMode={true}
  />

  return fullPageWrapper()
}

export default SportsDashboard