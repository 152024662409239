import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { getItemName } from '../../../common/convert';
import { gEnums } from '../../../enums/globalEnums';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';

/**
 * 
 * @param {object} props (qrCodeType, itemData, viewItem)
 * @returns 
 */
const QrCode = (props) => {

  const { qrCodeType, itemData, viewItem, qrCodeProps, fromSidebar, useShortLink } = props ? props : {}
  const { qrCodes } = viewItem ? viewItem : {}
  const { includedProps } = qrCodes ? qrCodes : {}

  const _name = itemData ? getItemName(itemData) : null

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ? frameworkContext : {}

  const _qrSize = 254
  const _includedProps = qrCodeProps ? qrCodeProps : includedProps

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state } = states ? states : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { displayName, appUserSessionKey } = appUserAccess ? appUserAccess : {}

  // papsContext 
  const { pathViews, sharePath } = paps_state ? paps_state : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_appUserSettings, aps_global } = pageSettings ? pageSettings : {}
  const { eventOptions, clientOptions } = aps_global ? aps_global : {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  const { shortenedLink } = _appOptions ? _appOptions : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ? uiItemContext : {}

  let cn = 'qr-container'
  if (qrCodeType === gEnums.qrCodeTypes.itemData) { cn += ' solo' }

  const handleCancel = () => {
    if (item_handlers && item_handlers.handleShowItemSidebar) {
      item_handlers.handleShowItemSidebar()
    } else if (props.handleCancel) {
      props.handleCancel()
    } else if (fromSidebar) {
      framework_handlers.handleShowAppQrCode()
    }
  }

  const qrAppLink = () => useShortLink && shortenedLink ? shortenedLink : sharePath

  const qrAppUser = () => {
    const qv = {
      clientKey: pathViews ? pathViews.clients : null,
      eventKey: pathViews ? pathViews.events : null,
      [appUserCollection]: appUserSessionKey,
      displayName,
    }
    return JSON.stringify(qv, null, 3)
  }

  const qrItemData = () => {
    let d = {}
    Object.keys(itemData).forEach(key => {
      if (!_.isObject(itemData[key])) {
        if (_includedProps) {
          if (_includedProps.includes(key)) {
            d[key] = itemData[key]
          }
        } else {
          d[key] = itemData[key]
        }
      }
    })
    const x = { ...d }
    const qr = JSON.stringify(x, null, 3)
    return qr
  }

  const qrContent = () => {
    let qrv;
    switch (qrCodeType) {
      case gEnums.qrCodeTypes.appLink:
        qrv = qrAppLink()
        break;
      case gEnums.qrCodeTypes.appUser:
        qrv = qrAppUser()
        break;
      case gEnums.qrCodeTypes.itemData:
        qrv = sharePath
        break;
      default:
      // nothing
    }
    console.log('qrv', qrv)
    return <Segment placeholder className={cn}>
      <QRCode size={_qrSize} value={qrv} />
    </Segment>
  }


  const topper2 = () => {
    switch (qrCodeType) {
      case gEnums.qrCodeTypes.appLink:
        return useShortLink && shortenedLink ? shortenedLink : sharePath
      case gEnums.qrCodeTypes.appUser:
        return displayName
      case gEnums.qrCodeTypes.itemData:
        return _name
      default:
      // nothing
    }
  }

  const wrapper = () => <Wrapper
    content={qrContent(128)}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={'QR Code'}
    topperCaption2={topper2()}
  />

  switch (qrCodeType) {
    case gEnums.qrCodeTypes.appLink:
    case gEnums.qrCodeTypes.itemData:
      return fullPageWrapper()
    default:
      return qrContent()
  }
}

export default QrCode