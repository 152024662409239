import React, { useContext, useEffect, useReducer } from 'react';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../../../global/cnr/reducers/SidebarReducer';
import CreateProvider from '../../cnr/contexts/CreateContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { SettingParentContext } from '../../cnr/contexts/SettingParentContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { createHandlers, createInitialState, createReducer } from '../../cnr/reducers/CreateReducer';
import TableView, { tableViewTypes } from '../../components/tables/TableView';
import { appFormTypes } from '../../enums/appFormTypes';
import { gEnums } from '../../enums/globalEnums';
import MenuSidebars from '../../sidebars/MenuSidebars';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { settingsActionTypes } from '../enums/itemActionTypes';
import { vsComponentTypes } from '../enums/vsComponentTypes';
import AddContent from './createSubs/AddContent';
import { addTypes } from './createSubs/addDropdownOptions';
import { GridItemSelectContent } from './createSubs/SelectExistingContent';

const sidebarType = 'createSomething'

const CreateSomething = (props) => {

  console.log('CreateSomething')

  const {
    createOrigin,
    createType,
    createVit,
    handleCreateInfo,
    isDirect,
    updateParent,
    viewItem_page,
    viewItem,
  } = props

  // settingsParentContext
  const settingsParentContext = useContext(SettingParentContext)
  const { states: states_s, handlers: handlers_s } = settingsParentContext ? settingsParentContext : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ? parentContext : {}

  const _states = states_s ? states_s : states
  const _handlers = handlers_s ? handlers_s : handlers

  // parentContext
  const { page_state, appForms_state, dataModify_state } = _states ? _states : {}
  const { dataModify_handlers } = _handlers ? _handlers : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems, aps_page } = pageSettings ? pageSettings : {}
  const { settingsOptions } = aps_global ? aps_global : {}
  const { useDarkMode } = settingsOptions ? settingsOptions : {}
  const { homeSettings } = settings
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { globalAppSettings, logging } = homeSettings_global ? homeSettings_global : {}
  const { databaseProjectId } = globalAppSettings ? globalAppSettings : {}

  let { viewItems: viewItems_page } = aps_page ? aps_page : {}
  if (!viewItems_page) { viewItems_page = {} }

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}

  // viewSettingsComponentContext 
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state, vsc_handlers } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { swipedItems, isGlobal, swipedItem } = vsc_state ? vsc_state : {}
  const { swipeToSelected } = vsc_handlers ? vsc_handlers : {}
  const { viType, settingKey, dKey } = swipedItem ? swipedItem : {}

  // dataModifyContext 
  const { itemProps: dataItemProps, vit: dataVit, addModeType: addModeType_dms } = dataModify_state ? dataModify_state : {}

  // appFormsContext 
  const { appForms } = appForms_state ? appForms_state : {}

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ? sidebar_state : {}
  const { setInit } = sidebar_handlers ? sidebar_handlers : {}
  const { ms_addModes } = sidebar_items ? sidebar_items : {}

  const viKey = createVit ? createVit : dKey
  let vigProps = aps_viewItems && viKey && aps_viewItems[viKey] ? aps_viewItems[viKey].props : null

  if (!vigProps && !dataItemProps) {

    switch (viType) {
      case vsComponentTypes.notificationsManager:
        vigProps = appForms[appFormTypes.notification]
        break
      default:
      // nothing
    }
  }

  const swipeItemPaths = {}

  if (swipedItems) {
    Object.keys(swipedItems).forEach(key => {
      const gi = swipedItems[key]
      if (gi.vit) { swipeItemPaths['vit'] = gi.vit }
      if (gi.pit) { swipeItemPaths['pit'] = gi.pit }
    })
  }

  // itemData
  const create_initState = { parentContext, viewSettingsComponentContext, viewSettingsContext, databaseProjectId, createType, viType, settingKey, swipeItemPaths, createOrigin, viewItem, logging }
  const [create_state, create_dispatch] = useReducer(createReducer, createInitialState(create_initState));
  const create_handlers = createHandlers(create_dispatch)

  const {
    addModeType: addModeType_cs,
    createInfo,
    createStatus,
    formData,
    selectedItems,
    settings_temp_added,
    settings_temp_updated,
  } = create_state ? create_state : {}

  const _addModeType = addModeType_dms ? addModeType_dms : addModeType_cs

  useEffect(() => {
    const atss = addTypes(createType, swipedItem, isGlobal, viewItem_page)
    setInit({ smt: sidebarMenuTypes.one, items: atss, currents, as: 'addModes', useStartCase: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (ms_addModes && ms_addModes.selected) {
      create_handlers.setAddMode(ms_addModes.selected.item)
      dataModify_handlers && dataModify_handlers.setAddMode(ms_addModes.selected.item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_addModes && ms_addModes.selected && ms_addModes.selected.item]);

  useEffect(() => {
    if (updateParent) { updateParent(formData) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [formData]);

  // updates createInfo via handleCreateInfo (in ViewItemDataActions)
  useEffect(() => {
    if (handleCreateInfo) { handleCreateInfo(createInfo) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [createInfo]);

  useEffect(() => {
    if (settings_temp_updated) {
      console.log('settings_temp_updated', settings_temp_updated)
      if (viewSettings_handlers) { viewSettings_handlers.updateViewSettingsTemp(settings_temp_updated) }
      if (swipeToSelected) { swipeToSelected() }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [settings_temp_updated]);

  useEffect(() => {
    if (settings_temp_added) {
      console.log('settings_temp_added', settings_temp_added)
      if (viewSettings_handlers) { viewSettings_handlers.updateViewSettingsTemp(settings_temp_added) }
      if (swipeToSelected) { swipeToSelected() }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [settings_temp_added]);

  const { handleSelect_dataItem } = create_handlers

  const clickCheck = (_e, item) => { create_handlers.handleClickCheck(item) }

  const grProps = { clicks: { clickCheck }, isGlobal: true }

  const gridSelect = () => {
    const { settings_temp } = createInfo ? createInfo : {}
    const { viewItems } = settings_temp ? settings_temp : {}
    const gridSelectProps = { selectedOptions: Object.keys(viewItems), selectedItems, grProps, createOrigin }
    return <GridItemSelectContent gridSelectProps={gridSelectProps} />
  }

  const gridDataSelect = () => {
    const { initProps } = createInfo ? createInfo : {}
    const { dataCollections } = initProps ? initProps : {}
    const sos = dataCollections && dataCollections[dataVit] ? dataCollections[dataVit] : {}
    if (sos && Object.keys(sos).length > 0) {
      return <TableView
        tableViewType={tableViewTypes.dataSelect}
        viewItem={viewItem}
        viewListData={sos}
        handleDataItemSelect={handleSelect_dataItem}
      />
    } else {
      return <div>No Data</div>
    }
  }

  /** adds any result panes to the tab */
  const resultContent = () => {
    switch (createOrigin) {
      case gEnums.createOriginTypes.createSettings:
        if (createInfo && createInfo.settings_temp && createInfo.settings_temp.viewItems) return gridSelect()
        break;

      case gEnums.createOriginTypes.createDataModify:
        if (createInfo && createInfo.initProps && createInfo.initProps.dataCollections) return gridDataSelect()
        break;
      default:
    }
  }

  const addModeContents = () => <React.Fragment>
    <AddContent />
    {resultContent()}
  </React.Fragment>

  /** Returns a component withing a MenuSidebar */
  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={addModeContents()}
    dimmed={dimmed}
    inverted={useDarkMode && !isDirect}
  />

  /** Wrapper for NON direct create */
  const wrapper = () => <Wrapper
    content={menuSidebars()}
    wrapperType={wrapperTypes.paddedHeader}
  />

  const createContent = () => isDirect ? menuSidebars() : wrapper()

  const content = () => {
    switch (createType) {
      case gEnums.addModeTypes.normal:
      case settingsActionTypes.createClient:
      case settingsActionTypes.createEvent:
      case vsComponentTypes.add:
        if (ms_addModes && ms_addModes.items) {
          switch (ms_addModes.items.length) {
            case 1:
              return <AddContent />
            default:
              return createContent()
          }
        } else {
          return <div></div>
        }
      default:
        return <AddContent />
    }
  }

  return <CreateProvider create_handlers={create_handlers} create_state={create_state} grProps={grProps} csProps={props} _addModeType={_addModeType}>
    {content()}
  </CreateProvider>
}

export default CreateSomething