import _ from 'lodash';
import { ammendGoogleResult, appendProjectData, appendProjectDataNames, updateViewItemViews } from '../firestoreData/helpers/sheetsDataAdjust';
import { callFunction } from "./fbAll";

const sheetsGenericTypes = {
  sheetsGeneric: 'sheetsGeneric',
}

const sheetsFunctionTypes = {
  addSheetRange: 'addSheetRange',
  addSheetRanges: 'addSheetRanges',
  addSheets: 'addSheets',
  addSheetsValidation: 'addSheetsValidation',
  addSheetValidation: 'addSheetValidation',
  addTab: 'addTab',
  createProjectFromSheets: 'createProjectFromSheets',
  createSpreadsheet: 'createSpreadsheet',
  getDocs: 'getDocs',
  getSheetRange: 'getSheetRange',
  getSheetsRange: 'getSheetsRange',
  updateSheetRangeValues: 'updateSheetRangeValues',
  updateSheetValues: 'updateSheetValues',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(sheetsGenericTypes.sheetsGeneric, callData)
  return data
}

/**
 * Gets the data from a googleSheet using the firebase function `getSheets`
 * @param {object} sheetProps 
 * @param {string} appArea 
 * @param {object} googleSheetsImportOptions 
 * @param {object} logging 
 * @returns data
 */
export const fsfn_createProjectFromSheets = async (sheetProps, appArea, googleSheetsImportOptions, logging, staticViews_app, forDataManagement, aps_viewItems) => {

  try {
    const { formData, pathViews } = sheetProps
    const { eventName, googleSheetsId } = formData
    const { allowMultiColumnArrays, allowMultiColumnArraysAsObjects } = googleSheetsImportOptions ? googleSheetsImportOptions : {}

    const callData = {
      sheetFunctionType: sheetsFunctionTypes.createProjectFromSheets,
      eventName, googleSheetsId,
      createViews: true, // this will create the fullProject with the views
      appArea,
      fromRegUrl: true,
      keepRowIds: true,
      opts: {
        allowMultiColumnArrays,
        allowMultiColumnArraysAsObjects,
      },
      showLogs: true,
    }

    const response_data = await callFsFunction(callData) // OK 

    // each item contains a _gsid, which is the key of the data item

    const { success, result } = response_data
    const { result: trueResult } = result ? result : {}

    if (success && trueResult) {
      if (trueResult.projectData && !trueResult.projectData.clientData) { trueResult.projectData.clientData = { clientMobileKey: pathViews.clients } }
      if (trueResult.projectData && !trueResult.projectData.eventData) { trueResult.projectData.eventData = { name: eventName } }

      updateViewItemViews(trueResult)

      console.log('trueResult', trueResult)

      const _trueResult = await ammendGoogleResult(trueResult, googleSheetsImportOptions, staticViews_app, true, forDataManagement, aps_viewItems)

      appendProjectDataNames(_trueResult)

      const ret = {
        success,
        result: _trueResult,
      }
      return ret
    } else {
      return {
        success: false,
        result: {}
      }
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

/**
 * 
 * @param {string} documentId 
 * @returns response_data {success, result, error}
 * @deprecated
 */
export const fsfn_googleDoc = async (documentId) => {
  try {
    const callData = { sheetsFunctionType: sheetsFunctionTypes.getDocs, documentId }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} title  
 * @returns response_data {success, result, error}
 */
export const fsfn_createGoogleSpreadsheet = async (title) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.createSpreadsheet, title, showLogs: true }
    const response_data = await callFsFunction(callData)// OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * adds a sheet (if it doesn't already exist) to an existing spreedsheet and adds the data from the firebase storage
 * @param {*} googleSheetsId 
 * @param {*} paps_state 
 * @param {*} viewItem 
 * @returns response_data {success, result, error} 
 */
export const fsfn_createGoogleSpreadsheetTab = async (googleSheetsId, name, fetchFirestoreData, values) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addTab, googleSheetsId, name, fetchFirestoreData, values }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/** Updates a single row in a single range/sheet */
export const fsfn_updateSheetRangeValues = async (googleSheetsId, sheetName, cellValue, rowData) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.updateSheetRangeValues, googleSheetsId, sheetName, cellValue, rowData, showLogs: true }
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} googleSheetsId 
 * @param {object} viewItem 
 * @returns 
 */
export const fsfn_updateGooglsSheetValues = async (googleSheetsId, viewItem, values) => {

  const callData = { sheetFunctionType: sheetsFunctionTypes.updateSheetValues, googleSheetsId, viewItem, values }
  try {
    const response_data = await callFsFunction(callData) // OK 
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}



/**
 * Creates a named range on the 2nd column (name) for sheet (viewItem.key) within the spreadsheet
 * @param {string} googleSheetsId 
 * @param {object} paps_state 
 * @param {object} viewItem 
 * @returns response_data {success, result, error}
 */
export const fsfn_addGoogleSheetRange = async (googleSheetsId, paps_state, viewItem) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheetRange, googleSheetsId, pathViews: paps_state.pathViews, viewItem }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * Creates a named range on the 2nd column (name) for each of the sheets within the spreadsheet
 * @param {string} googleSheetsId 
 * @param {boolean} createValidation 
 * @returns response_data {success, result, error}
 */
export const fsfn_addGoogleSheetRanges = async (googleSheetsId, createValidation) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheetRanges, googleSheetsId, createValidation }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * fsfn_addGoogleSheetValidation
 * @param {*} googleSheetsId 
 * @param {*} paps_state 
 * @param {*} viewItem 
 * @returns response_data {success, result, error}
 */
export const fsfn_addGoogleSheetValidation = async (googleSheetsId, paps_state, viewItem) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheetsValidation, googleSheetsId, pathViews: paps_state.pathViews, viewItem }
    const response_data = await callFsFunction(callData) // OK
    const { data } = response_data
    const { success, result } = data
    if (success) {
      const { ssResult, ssViews } = result
      const { data: data_result } = ssResult
      const { namedRanges } = data_result
      ssViews.forEach(ssView => {
        const { data: sheetData } = ssView
        const { range, values } = sheetData
        const rangeSplit = range.split('!')
        const rangeName = rangeSplit[0]
        const headers = values ? values[0] : null // values are the rows
        if (headers && rangeName) {
          headers.forEach((header, index) => {
            const headerRangeName = '_' + _.camelCase(header)
            const nr = _.find(namedRanges, { name: headerRangeName })
            if (nr) {
              console.log('nr', nr)
              // const { name, namedRangeId } = nr
            }
          })
        }
      })
    }
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * fsfn_addGoogleSheets
 * @param {string} googleSheetsId 
 * @param {object} paps_state 
 * @param {array} viKeys 
 * @returns response_data {success, result, error}
 */
export const fsfn_addGoogleSheets = async (googleSheetsId, paps_state, viKeys) => {
  try {
    const callData = { sheetFunctionType: sheetsFunctionTypes.addSheets, googleSheetsId, pathViews: paps_state.pathViews, viKeys }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} formData (sheetName, googleSheetsId)
 * @param {*} handleSheetResults 
 * @param {*} handleSheetError 
 */
export const fsfn_getGoogleSheetRange = async (formData, handleSheetResults, handleSheetError, ignoreStartCase, team) => {
  try {
    const sheetName = ignoreStartCase ? formData.sheetName : _.startCase(formData.sheetName)
    const callData = { sheetFunctionType: sheetsFunctionTypes.getSheetsRange, googleSheetsId: formData.googleSheetsId, sheetName, fromRegUrl: true }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ? response_data : {}
    const { error, errors } = result ? result : {}
    if (handleSheetResults && success === true) { handleSheetResults(result, team) }
    if (handleSheetError && success === false) { handleSheetError(error) }
    if (handleSheetError && errors && success === false) { handleSheetError(errors[0]) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} sheetProps 
 * @param {string} appArea 
 * @returns ret
 * @deprecated
 */
export const fsfn_getGoogleSheetsRange = async (sheetProps, appArea) => {
  try {
    const { formData, pathViews } = sheetProps
    const { eventName, googleSheetsId } = formData
    const callData = { sheetFunctionType: sheetsFunctionTypes.getSheetRange, eventName, googleSheetsId, createViews: true, appArea, fromRegUrl: true }
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ? response_data : {}
    if (success) {
      const trueResult = result.result
      if (!trueResult.projectData.clientData) { trueResult.projectData.clientData = { clientMobileKey: pathViews.clients } }
      if (!trueResult.projectData.eventData) { trueResult.projectData.eventData = { name: eventName } }

      updateViewItemViews(trueResult)
      appendProjectData(trueResult)

      const ret = {
        data: {
          success,
          result: trueResult
        }
      }
      return ret
    } else {
      return {
        data: {
          success
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

}

