import { createRefPath } from '../appData/appRefPaths';
import { fs_update_doc } from '../appData/fsData';

// export const updateAttendeeProfiles = (dispatch, profileUpdateList, pathViews, clientRef, dispatchType, dispatchErrorType, setDocName, sendToMe) => {
//   updateProfiles(gEnums.profileSelectTypes.clientProfiles, profileUpdateList, pathViews, null, null, null, dispatch, clientRef, dispatchType, dispatchErrorType, setDocName, sendToMe)
// }

export const updateProfile = (docId, dataToUpdate, callback) => {
  const _updateRef = createRefPath(['profiles', docId])
  fs_update_doc(_updateRef, dataToUpdate, callback)
}

// export const updateProfiles = async (profileSelectType, profileUpdateList, pathViews, profileCollection, setConfirmation, setProfileUpdateList, dispatch, clientRef, dispatchType, dispatchErrorType, setDocName, sendToMe) => {

//   let updatedProfiles = []

//   if (Object.keys(profileUpdateList).length > 0) {

//     const fs = firebase.firestore()
//     const batch = fs.batch();

//     const clientDoc = pathViews ? pathViews.clients : null
//     const eventDoc = pathViews ? pathViews.events : setDocName

//     let baseRef = clientRef ? clientRef : fs.collection('clients').doc(clientDoc)
//     let ref;

//     switch (profileSelectType) {
//       case gEnums.profileSelectTypes.appProfiles:
//         ref = baseRef.collection(profileSelectType);
//         break;
//       case gEnums.profileSelectTypes.clientProfiles:
//         ref = baseRef.collection('profiles');
//         break;
//       default:
//         ref = fs.collection(profileCollection)
//     }

//     if (profileSelectType) {

//       console.log('updateProfiles - profileSelectType', profileSelectType)

//       switch (profileSelectType) {

//         case gEnums.profileSelectTypes.clientProfiles:
//           Object.keys(profileUpdateList).forEach(key => {
//             const pd = profileData(profileUpdateList[key], eventDoc)
//             var mergeRef = ref.doc(pd.email);
//             batch.set(mergeRef, pd, { merge: true });
//             // mergeRef.set(profile, { merge: true });
//           })

//           try {
//             await batch.commit();
//             if (dispatch) {
//               dispatch({ type: dispatchType, sendToMe });
//             } else {
//               setConfirmation && setConfirmation({
//                 title: 'Updated',
//                 content: 'Update was successfull',
//                 success: true
//               });
//               setProfileUpdateList && setProfileUpdateList({});
//             }
//           } catch (err) {
//             if (dispatch && dispatchErrorType) {
//               console.error(err);
//               dispatch({ type: dispatchErrorType, sendToMe });
//             } else {
//               setConfirmation && setConfirmation({
//                 title: 'Not Updated',
//                 content: 'Update was not successfull',
//                 success: false
//               });
//               setProfileUpdateList && setProfileUpdateList({});
//             }
//           }
//           break;

//         case gEnums.profileSelectTypes.profiles:
//         case gEnums.profileSelectTypes.appProfiles:

//           updatedProfiles = profileUpdateList

//           // update existing
//           Object.keys(updatedProfiles).forEach(key => {
//             const profile = profileData(updatedProfiles[key], eventDoc)
//             const updateRef = ref.doc(key)
//             console.log('profile', profile)
//             batch.set(updateRef, profile);
//           })

//           try {
//             await batch.commit();
//             if (dispatch) {
//               dispatch({ type: dispatchType, sendToMe });
//             } else {
//               setConfirmation && setConfirmation({
//                 title: 'Updated',
//                 content: 'Update was successfull',
//                 success: true
//               });
//               setProfileUpdateList && setProfileUpdateList({});
//             }
//           } catch (err_1) {
//             if (dispatch && dispatchErrorType) {
//               console.log(err_1);
//               dispatch({ type: dispatchErrorType, sendToMe });
//             } else {
//               setConfirmation && setConfirmation({
//                 title: 'Not Updated',
//                 content: 'Update was not successfull',
//                 success: false
//               });
//               setProfileUpdateList && setProfileUpdateList({});
//             }
//           }
//           break;
//         default:
//         // nothing
//       }
//     }
//   }
// }

// const profileData = (pd, eventDoc) => {
//   const pr = {
//     firstName: pd.firstName,
//     lastName: pd.lastName,
//     displayName: pd.firstName + ' ' + pd.lastName,
//     email: pd.email,
//     initials: createInitials(pd.firstName + ' ' + pd.lastName),
//     events: { [eventDoc]: true }
//   }
//   return pr
// }