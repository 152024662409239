import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { List, Segment } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'

const MatchList = (props) => {

  const { matches: matches_list } = props

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info } = sportsSeason_state ? sportsSeason_state : {}

  const [dateGroupKeys, setDateGroupKeys] = useState()
  const [dateGroups, setDateGroups] = useState()

  useEffect(() => {
    if (matches_list) {
      const _dateGroups = _.groupBy(matches_list, 'startDate')
      const dateGroupKeys = Object.keys(_dateGroups)
      const _dateGroupKeys = dateGroupKeys.sort()
      setDateGroups(_dateGroups)
      setDateGroupKeys(_dateGroupKeys)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches_list]);

  const matchItem = (m, matchIndex) => {
    const { matchName, results, home, away, homeScore, awayScore, section, level, _changed, _new, _itemKey } = m
    const { scoreDisplayFull } = results ? results : {}
    let _scoreDisplay;
    let _hasScore;
    if (scoreDisplayFull) {
      _scoreDisplay = scoreDisplayFull
      _hasScore = true
    } else if (home, away, homeScore, awayScore) {
      _scoreDisplay = away + ' ' + awayScore + ' - ' + home + ' ' + homeScore
      _hasScore = false
    }
    if (!_scoreDisplay) {
      _scoreDisplay = away + ' - ' + home
    }

    let _iconName = _hasScore ? 'check circle' : 'circle outline'
    let _iconColor = _hasScore ? 'green' : 'grey'

    if (_changed) {
      _iconName = 'edit'
      _iconColor = 'blue'
    }

    if (_new) {
      _iconName = 'add'
      _iconColor = 'blue'
    }

    return <List.Item key={uniqueKey('mlg', 'list-item', matchIndex)}>
      <List.Icon name={_iconName} verticalAlign='middle' color={_iconColor} />
      <List.Content>
        <List.Header>{_scoreDisplay ? _scoreDisplay : matchName}</List.Header>
        <List.Description>{level}{'-'}{_.startCase(section)}</List.Description>
      </List.Content>
    </List.Item>
  }

  const matchList = () => {
    const groups = []
    if (dateGroupKeys && dateGroups) {
      dateGroupKeys.forEach((dgKey, groupIndex) => {
        const mls = []
        const matches_date = dateGroups[dgKey]

        matches_date.forEach((m, index) => {
          mls.push(matchItem(m, index))
        })
        const list = <List divided relaxed>
          {mls}
        </List>
        groups.push(<Segment.Group key={uniqueKey('mlg', groupIndex)} >
          <Segment key={uniqueKey('ml', groupIndex, 'seg', 1)}>{dgKey}</Segment>
          <Segment key={uniqueKey('ml', groupIndex, 'seg', 2)}>{list}</Segment>
        </Segment.Group >)
      })
    }

    return groups
  }

  return matchList()
}

export default MatchList