import _ from 'lodash';
import React, { useContext, useState } from 'react';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import DataPaste from '../../../../global/components/pasting/DataPaste';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { matchUpdateTypes } from '../../cnr/reducers/SportsSeasonReducer';
import MatchHeader from '../../create/MatchHeader';
import MatchForm from './MatchForm';

const MatchPendingUpdate = (props) => {

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext
  const { teams_info, matches_info } = sportsSeason_state ? sportsSeason_state : {}
  const { matches } = matches_info ? matches_info : {}
  const { teams } = teams_info ? teams_info : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { matchPendingUpdate, newMatchDate, match_true } = sportsMatch_state ? sportsMatch_state : {}
  const { matchUpdateType } = matchPendingUpdate ? matchPendingUpdate : {}
  const { _itemKey } = match_true ? match_true : {}

  // eslint-disable-next-line 
  const [matchReady, setMatchReady] = useState()

  const handleItemData = (itemData) => sportsMatch_handlers.handleCreateMatchesFromList({ itemData, teams, matches })

  const optsList = []
  if (teams) {
    const teamsS = _.sortBy(teams, 'name')
    teamsS.forEach(team => {
      optsList.push(
        {
          key: team.id,
          value: team.id,
          text: team.name
        }
      )
    })
  }

  const form_calendar = () => <MatchForm shows={{ showDate: true }} setMatchReady={setMatchReady} />
  const form_location = () => <MatchForm shows={{ showTeams: false, showLocation: true }} setMatchReady={setMatchReady} />
  const form_results = () => <DataPaste handleItemData={handleItemData} />

  const pending = () => {
    let content = _.startCase(matchUpdateType) + '?'
    switch (matchUpdateType) {
      case matchUpdateTypes.updateScore:
        if (matchPendingUpdate.realtimeMatch && matchPendingUpdate.realtimeMatch.currentResults) {
          content += ' (' + matchPendingUpdate.realtimeMatch.currentResults.scoreDisplayFull + ')?'
        }
        break;
      case matchUpdateTypes.updateMatchDate:
        content = form_calendar()
        break;
      case matchUpdateTypes.updateMatchLocation:
        content = form_location()
        break;
      case matchUpdateTypes.updateMatchListResults:
        content = form_results()
        break;
      case matchUpdateTypes.confirmMatchDate:
        content += 'Update Match Date? (' + newMatchDate + ')?'
        break;
      default:
        break;
    }

    const header = () => <MatchHeader />

    const footer = () => <UiSaveButtons
      save={{ oc: sportsMatch_handlers.handleConfirmMatchPending, caption: _.startCase(matchUpdateType) }}
    />

    return <Wrapper
      header={header()}
      content={content}
      footer={footer()}
      wrapperType={wrapperTypes.padded}
    // css={{ container: 'match-pending' }}
    />
  }

  const fullPageWrapper = () => <FullPageWrapper
    content={pending()}
    topperCaption={_.startCase(matchUpdateType)}
    topperCaption2={_itemKey}
    handleCancel={props.handleCancel}
  />

  return fullPageWrapper()
}

export default MatchPendingUpdate