import _ from 'lodash';
import React, { useContext } from 'react';
import { Label } from 'semantic-ui-react';
import { TicketingContext } from '../cnr/contexts/TicketingContext';
import { tickColors } from './AppTicketing';
import { ticketTypes } from './SingleTicket';

const TicketStatus = (props) => {

  const { ticketType, subTickets, isWillCall } = props

  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state, ticketing_handlers } = ticketingContext ? ticketingContext : {}
  const { assignedTickets, selectedLookupTicketKey, appTicketing } = ticketing_state ? ticketing_state : {}
  const { ticketingCaption, allotmentCaption, assignedToCaption, enteredCaption, remainingCaption, showFullTicketStatus } = appTicketing ? appTicketing : {}
  const { showAllotment, showAssignedTo, showEntered, showRemaining } = appTicketing ? appTicketing : {}
  const _ticketCaption = ticketingCaption ? ticketingCaption : 'Tickets'

  const itemStatus = (text, value, color) => <Label color={color} onClick={(e) => { ticketing_handlers.handleTicketFiler(text) }}>
    {text}
    <Label.Detail>{value}</Label.Detail>
  </Label>

  const ticketStatus = () => {

    const captions = {
      tickets: _ticketCaption,
      allotment: allotmentCaption ? allotmentCaption : 'Allotment',
      assignedTo: assignedToCaption ? assignedToCaption : 'Assigned To',
      entered: enteredCaption ? enteredCaption : 'Entered',
      remaining: remainingCaption ? remainingCaption : 'Remaining'
    }

    let _entered = 0
    let _ticketCount = 0
    let _assignedTo = 0

    switch (ticketType) {

      case ticketTypes.infoOnly:
        if (assignedTickets && selectedLookupTicketKey && assignedTickets[selectedLookupTicketKey]) {
          const assignedTicket = assignedTickets[selectedLookupTicketKey]
          const { status } = assignedTicket ? assignedTicket : {}
          const { given, entered, assignedTo } = status ? status : {}
          _entered = entered ? entered : 0
          _ticketCount = given ? given : 0
          _assignedTo = assignedTo ? assignedTo : 0
        }
        break;

      default:
        if (subTickets) {
          _ticketCount = subTickets.length
          if (subTickets && _.isArray(subTickets)) {
            subTickets.forEach(subTicket => {
              const { entered: u, assignedTo: at } = subTicket
              if (u) { _entered++ }
              if (at) { _assignedTo++ }
            })
          }
        }
        break;
    }

    const remaining = _ticketCount - _entered

    switch (ticketType) {
      case ticketTypes.lookup:
        return <div className={'ticket-status'}>
          {showAllotment && itemStatus(captions.tickets, _ticketCount, tickColors.allotment)}
          {showAssignedTo && itemStatus(captions.assignedTo, _assignedTo, tickColors.assignedTo)}
          {showEntered && itemStatus(captions.entered, _entered, tickColors.entered)}
          {showRemaining && itemStatus(captions.remaining, remaining, tickColors.remaining)}
        </div>
      default:
        if (isWillCall) {
          return <div className={'ticket-status'}>
            {showAllotment && itemStatus(captions.tickets, _ticketCount, tickColors.allotment)}
            {showEntered && itemStatus(captions.entered, _entered, tickColors.entered)}
            {showRemaining && itemStatus(captions.remaining, remaining, tickColors.remaining)}
          </div>
        } else {
          if (showFullTicketStatus) {
            const lProps = { size: 'tiny' }
            return <div className={'ticketing-header solo'}>
              {showAllotment && <div>{captions.tickets}</div>}
              {showAssignedTo && <div>{captions.assignedTo}</div>}
              {showEntered && <div>{captions.entered}</div>}
              {showRemaining && <div>{captions.remaining}</div>}
              <div><Label {...lProps} color={tickColors.allotment}>{_ticketCount}</Label></div>
              <div><Label {...lProps} color={tickColors.assignedTo}>{_assignedTo}</Label></div>
              <div><Label {...lProps} color={tickColors.entered}>{_entered}</Label></div>
              <div><Label {...lProps} color={tickColors.remaining}>{remaining}</Label></div>
            </div>
          } else {
            return <div className={'ticket-status'}>
              {showAllotment && itemStatus(captions.tickets, _ticketCount, tickColors.allotment)}
              {showAssignedTo && itemStatus(captions.assignedTo, _assignedTo, tickColors.assignedTo)}
              {showEntered && itemStatus(captions.entered, _entered, tickColors.entered)}
              {showRemaining && itemStatus(captions.remaining, remaining, tickColors.remaining)}
            </div>
          }
        }
    }
  }
  return ticketStatus()
}

export default TicketStatus