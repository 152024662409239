export const settingFormTypes = {
  activePropList: 'activePropList',
  add: 'add',
  appActions: 'appActions',
  appDataSource: 'appDataSource',
  appInstallation: 'appInstallation',
  appOptions: 'appOptions',
  appQuestionsAndAnswers: 'appQuestionsAndAnswers',
  appSettings: 'appSettings',
  appSignIn: 'appSignIn',
  appStatus: 'appStatus',
  appUserConnections: 'appUserConnections',
  appUserLinks: 'appUserLinks',
  appUserSettings: 'appUserSettings',
  banner: 'banner',
  banners: 'banners',
  baseSettings: 'baseSettings',
  card: 'card',
  combine: 'combine',
  connections: 'connections',
  createClient: 'createClient',
  createEvent: 'createEvent',
  createGlobalData: 'createGlobalData',
  createList: 'createList',
  data: 'data',
  dataConnection: 'dataConnection',
  dataLinking: 'dataLinking',
  dataLinks: 'dataLinks',
  dataModifications: 'dataModifications',
  dataOptions: 'dataOptions',
  dataPrivacy: 'dataPrivacy',
  dataRestrictions: 'dataRestrictions',
  description: 'description',
  desktop: 'desktop',
  display: 'display',
  drillDown: 'drillDown',
  eventReg: 'eventReg',
  feeds: 'feeds',
  files: 'files',
  formatting: 'formatting',
  general: 'general',
  golfOptions: 'golfOptions',
  googleActions: 'googleActions',
  googleAppsCreate: 'googleAppsCreate',
  googleAppsData: 'googleAppsData',
  googleAppsUpdate: 'googleAppsUpdate',
  googleSheetsDataOptions: 'googleSheetsDataOptions',
  googleSheetsImportOptions: 'googleSheetsImportOptions',
  groupItemSettings: 'groupItemSettings',
  groupSettings: 'groupSettings',
  header: 'header',
  homePage: 'homePage',
  imageLinks: 'imageLinks',
  imageMapping: 'imageMapping',
  item: 'item',
  items: 'items',
  listItems: 'listItems',
  logging: 'logging',
  manifest: 'manifest',
  messageDirect: 'messageDirect',
  meta: 'meta',
  navigation: 'navigation',
  openAuth: 'openAuth',
  openCloudConsole: 'openCloudConsole',
  openDatabase: 'openDatabase',
  openDatabaseSettings: 'openDatabaseSettings',
  openFunctions: 'openFunctions',
  openStorage: 'openStorage',
  pageNavigation: 'pageNavigation',
  pageSettings: 'pageSettings',
  parentDefaults: 'parentDefaults',
  permissionItem: 'permissionItem',
  projectOptions: 'projectOptions',
  props: 'props',
  propsAdditional: 'propsAdditional',
  qAndA: 'qAndA',
  qrCode: 'qrCode',
  rssFeed: 'rssFeed',
  sectionBody: 'sectionBody',
  sectionHeader: 'sectionHeader',
  settingsActions: 'settingsActions',
  settingsOptions: 'settingsOptions',
  settingsPermissions: 'settingsPermissions',
  sideMenu: 'sideMenu',
  simpleInput: 'simpleInput',
  simpleSelect: 'simpleSelect',
  sorting: 'sorting',
  splashScreen: 'splashScreen',
  subEditProps: 'subEditProps',
  themeColors: 'themeColors',
  themedItem: 'themedItem',
  themedItems: 'themedItems',
  tools: 'tools',
  topMenu: 'topMenu',
  twitter: 'twitter',
  uiElements: 'uiElements',
  uiElementStyle: 'uiElementStyle',
  uiMeta: 'uiMeta',
  uiSections: 'uiSections',
  videoConf: 'videoConf',
  videoLinks: 'videoLinks',
  viewItems: 'viewItems',
  views: 'views',
}