import React, { useContext, useEffect, useState } from 'react';
import { Item, Segment } from 'semantic-ui-react';
import { DataContext } from '../../cnr/contexts/DataContext';
import { DisplayContext } from '../../cnr/contexts/DisplayContext';
import { FilterContext } from '../../cnr/contexts/FilterContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { PageDataContext } from '../../cnr/contexts/PageDataContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { g_cns } from '../../common/cns';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { _find } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import MenuSidebar from '../../sidebars/MenuSidebar';
import { addToClass } from '../../styles/formatting';
import NoData from '../alerts/NoData';
import TableView from '../tables/TableView';
import { SettingsViewerContext } from '../viewers/FullSettingsViewer';
import SemCards from './SemCards';
import SemSchedule from './SemSchedule';
import UsaMap from '../map/UsaMap';
import ErrorBoundary from '../errorHandling/ErrorBoundery';

const CalendarSidebar = React.lazy(() => import('../../sidebars/CalendarSidebar'));
const SemanticItems = React.lazy(() => import('./SemItems'));
const SemElemsAlt = React.lazy(() => import('./SemElemsAlt'));
const SemList = React.lazy(() => import('./SemList'));
const SimpleCarosel = React.lazy(() => import('./SemCarosels'));

/**
 * 
 * @returns Creates and returns semanticUI element for the ui, called mainly from SemUI.js
 */
const SemElems = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, preview_state, transition_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_page } = pageSettings ? pageSettings : {}
  const { previewStatus } = preview_state ? preview_state : {}

  const { transitions } = transition_state ? transition_state : {}
  const transition_pageItem = transitions ? transitions[_animateTypes.pageItem] : null
  const { showTransition: showTransition_pageItem } = transition_pageItem ? transition_pageItem : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  // pageDataContext
  const pageDataContext = useContext(PageDataContext);

  // settingsViewerContext
  const settingsViewerContext = useContext(SettingsViewerContext);

  // pageContext 
  const { desktop, uiElements } = aps_global ? aps_global : {}
  const { useDefaultLayoutType, defaultSingleLayoutType, defaultMultiLayoutType } = desktop ? desktop : {}
  const { layout: layout_page, desktopLayout: desktopLayout_page } = aps_page ? aps_page : {}
  const layout = desktopMode && desktopLayout_page ? desktopLayout_page : layout_page
  const { layoutType, sliderCount: viewSliderCount } = layout ? layout : {}
  const cardProps = uiElements && uiElements.card ? { ...uiElements.card } : {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ? dataContext : {}
  const { alphabetValue } = data_state ? data_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem, vld: viewListData, singleDataItem } = item_state ? item_state : {}

  const { slider, grouping, display, desktopDisplay, itemClick, maps, dataSource, images, qrCodes } = viewItem ? viewItem : {}

  const { dataSourceType } = dataSource ? dataSource : {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { groupActive, groupType } = grouping ? grouping : {}
  const { noClick } = itemClick ? itemClick : {}

  let { displayType, itemsPerRow, cardsPerRow, imageProp, useCss, cardDisplayType } = _display ? _display : {}
  let { horizontalSlider, sliderCount } = slider ? slider : {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_state } = filterContext ? filterContext : {}
  const { groupDataProps } = uiFilter_state ? uiFilter_state : {}

  // displayContext
  const displayContext = useContext(DisplayContext);
  const { display_state } = displayContext ? displayContext : {}
  const { displayProps } = display_state ? display_state : {}
  const { groups, selectedGroupItem } = displayProps ? displayProps : {}

  // componentContexts
  const componentContexts = {
    dataContext,
    displayContext,
    frameworkContext,
    pageDataContext,
    settingsViewerContext,
    uiItemContext,
  }

  const viewItemType = viewItem.key
  const vit = viewItemType

  const [initData, setInitData] = useState({ vld: null, dataFetched: false })
  const [inits, setInits] = useState()

  const { vld, dataFetched } = initData

  const viewListCount = vld ? Object.keys(vld).length : 0

  const { cnc, horizontalSliderCount } = inits ? inits : {}
  let { ignoreGroups } = inits ? inits : {}

  const getInits = (vlc, ipr) => {

    if (!itemsPerRow) { itemsPerRow = 2 }

    const viewListCount = vld ? Object.keys(vld).length : 0
    let _ignoreGroups = false

    let useHorizontalSlider = horizontalSlider ? true : false
    let _horizontalSliderCount = sliderCount ? sliderCount : 0

    let _layoutType = desktopMode && useDefaultLayoutType && defaultMultiLayoutType ? defaultMultiLayoutType : layoutType
    if (viewListCount === 1) { _layoutType = desktopMode && useDefaultLayoutType && defaultSingleLayoutType ? defaultSingleLayoutType : _layoutType }

    if (_layoutType === gEnums.layoutTypes.horizontalSliders) {
      useHorizontalSlider = true
      _horizontalSliderCount = viewSliderCount
    }

    let _cnc = g_cns.cards_container
    if (cardDisplayType) { _cnc = addToClass(_cnc, cardDisplayType) }
    if (viewListCount === 1) { }

    const { showItemImage, imageLocation } = images ? images : {}
    const { showQrCode, qrLocation } = qrCodes ? qrCodes : {}

    switch (groupType) {
      // case gEnums.groupTypes.swipeableGroup:
      //   _cnc = addToClass(_cnc, 'item-horz')
      //   break;
      default:
        _cnc = addToClass(_cnc, useHorizontalSlider ? 'item-horz' : 'item-vert')
      // nothing
    }

    if (useCss) { _cnc += ' ucss' }
    if (singleDataItem) { _cnc += ' solo' }

    if (cardProps && cardProps.gap) { _cnc = addToClass(_cnc, 'gp-' + cardProps.gap) }

    if (ipr > 1) { _cnc = addToClass(_cnc, 'ipr-' + ipr) }
    if (ipr < 3) { _cnc = addToClass(_cnc, 'nmw') }

    if (imageLocation && showItemImage) {
      _cnc = addToClass(_cnc, 'card-img')
      _cnc = addToClass(_cnc, imageLocation, 'img')
    }

    if (showQrCode) {
      _cnc = addToClass(_cnc, 'card-qr')
      _cnc = addToClass(_cnc, qrLocation, 'qr')
    }

    return {
      cnc: _cnc,
      horizontalSliderCount: _horizontalSliderCount,
      ignoreGroups: _ignoreGroups
    }
  }

  useEffect(() => {
    let _vld;
    // get the data to be displayed
    if (groupActive && groups && selectedGroupItem) {
      const gd = _find(groups, 'key', selectedGroupItem)

      if (gd && gd.data) {
        _vld = Object.assign({}, gd.data);
      } else {
        _vld = Object.assign({}, viewListData);
      }
    } else {
      _vld = Object.assign({}, viewListData);
    }
    setInitData({ vld: _vld, dataFetched: true })
    const vlc = _vld ? Object.keys(_vld).length : 0
    setInits(getInits(vlc))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewListData, alphabetValue, previewStatus]);

  if (displayType === gEnums.displayTypes.item) { displayType = gEnums.displayTypes.card }

  // if the item has NO data, return NoData, with some exceptions 
  const content = (contentData) => {

    switch (displayType) {
      case gEnums.displayTypes.questionsAndAnswers:
        // let it go
        break;
      default:
        // return NoData if no data 
        switch (dataSourceType) {
          case gEnums.dataSourceTypes.component:
          case gEnums.dataSourceTypes.pageDataCollection:
            ignoreGroups = true
            // let it go
            break;
          default:
            if (viewListCount === 0) {
              return <NoData viewItem={viewItem} noCaption={true} />
            }
            break;
        }
    }

    if (viewItem.semWrapper && displayType === gEnums.displayTypes.card) {
      return <SimpleCarosel />
    } else {

      switch (displayType) {

        case gEnums.displayTypes.usaMap:
          return <UsaMap />

        case gEnums.displayTypes.list:
          return <SemList componentContexts={componentContexts} viewListData={contentData} />

        case gEnums.displayTypes.card:

          let itemsPerRow = viewListCount < 3 ? viewListCount : 3

          if (!desktopMode) {
            itemsPerRow = 1
          } else {
            if (cardsPerRow) { itemsPerRow = cardsPerRow }
          }

          const card_props = { vit, cnc, itemsPerRow }
          return <SemCards cardProps={card_props} componentContexts={componentContexts} viewListData={contentData} sliderCount={horizontalSliderCount} />
        // return <MotionCards items={contentData} />

        case gEnums.displayTypes.item:

          const cn = viewListCount === 1 ? 'seg-list' : 'seg-item-group'
          const listOnly = false

          if (listOnly) {
            return <Segment basic style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
              <div>LIST</div>
              {/* <SemanticList componentContexts={componentContexts} viewListData={contentData} /> */}
            </Segment>
          } else {
            const itemProps = {}
            return <Segment basic style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
              <Item.Group {...itemProps} vit={vit} unstackable className={cn} >
                <SemanticItems componentContexts={componentContexts} viewListData={contentData} />
              </Item.Group>
            </Segment >
          }

        case gEnums.displayTypes.table:
        case gEnums.displayTypes.taggedTable:
          return <TableView
            viewItem={viewItem}
            viewListData={contentData}
            allowClickTo={true}
          />

        default:
          return <SemElemsAlt
            cnc={cnc}
            componentContexts={componentContexts}
            contentData={contentData}
            displayType={displayType}
            _display={_display}
            maps={maps}
            item_state={item_state}
            imageProp={imageProp}
            viewItem={viewItem}
            vit={vit}
          />
      }
    }
  }

  if (groupActive && groupDataProps && groupType && !ignoreGroups) {

    switch (groupType) {

      case gEnums.groupTypes.segment:
        const { groups: segGroups } = groupDataProps ? groupDataProps : {}
        if (segGroups) {
          segGroups.forEach(segGroup => {

          })
        }
        return <Segment>TEST</Segment>

      case gEnums.groupTypes.calendarSidebar:
      case gEnums.groupTypes.menuSidebar:
        const contents = []
        const menuKeys = []
        const { groups: msGroups, groupByProp } = groupDataProps ? groupDataProps : {}
        if (msGroups) {
          msGroups.forEach(msGroup => {
            const { key: groupKey, data } = msGroup ? msGroup : {}
            if (data && groupKey) {
              menuKeys.push(groupByProp.toLowerCase().indexOf('date') >= 0 ? formatItem(formatTypes.fullDate, groupKey) : groupKey)
              contents.push(content(data))
            }
          })
        }

        switch (groupType) {
          case gEnums.groupTypes.calendarSidebar:
            return <CalendarSidebar prefix={viewItemType} menuKeys={menuKeys} segs={contents} />

          case gEnums.groupTypes.menuSidebar:
            return <MenuSidebar prefix={viewItemType} menuKeys={menuKeys} segs={contents} />

          default:
          // nothing
        }
        break;

      case gEnums.groupTypes.schedule:
      case gEnums.groupTypes.scheduleTop:
        const card_props = { vit }
        return <SemSchedule groupType={groupType} groupDataProps={groupDataProps} card_props={card_props} componentContexts={componentContexts} />

      case gEnums.groupTypes.swipeableTab:
        return content(vld)

      case gEnums.groupTypes.swipeableGroup:
        if (groups) {
          const gis = []
          groups.forEach(group => {
            const { data, key: group_key } = group
            const _content = content(data)
            gis.push(<div key={uniqueKey('sw', 'si', group_key)} className={'swipeable-group-container'}>
              <div>{group_key}</div>
              {_content}
            </div>)
          })
          return gis
        } else {
          return content(vld)
        }

      default:
        return <NoData altCaption={'Group Type'} />
    }
  }

  if (showTransition_pageItem && 1 === 3) {
    return <MotionComponent transition={transition_pageItem}>
      <ErrorBoundary origin={'SemElems'}>
        {dataFetched ? content(vld) : <div>---</div>}
      </ErrorBoundary>
    </MotionComponent>
  } else {
    return dataFetched ? content(vld) : <div></div>
  }


}

export default SemElems