import React, { useContext, useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { getFirstObject } from '../../../common/convert';
import ConfirmationQuestion from '../../../components/alerts/ConfirmationQuestion';
import { getFormPropz } from '../../../components/forms/props/formPropz';
import FormForm from '../../../components/forms/FormForm';
import Uploader from '../../../components/uploading/Uploader';
import { ActionContext } from '../../../cnr/contexts/ActionContext';
import { DataModifyContext } from '../../../cnr/contexts/DataModifyContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { ViewSettingsComponentContext } from '../../../cnr/contexts/ViewSettingsComponentContext';
import { gEnums } from '../../../enums/globalEnums';
import { getData } from '../../../firestoreData/appData/getPageItemData';
import { getRefPropz } from '../../../firestoreData/helpers/getRefProps';
import MapListContent from '../../../viewSettings/create/createSubs/MapListContent';
import { getAppFormProps } from '../../../viewSettings/helpers/settingsHelpers';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';

/**
 * 
 * @param {object} props (modifyProps, subEditViewItem, lockedData, isDirect)
 * @returns a form based on the modifyProps and data_modified 
 * @description This is ONLY called from DataModifySwiper
 */
const DataItemModify = (props) => {

  const { modifyProps } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, appForms_state, eventInfo_state, page_state, paps_state } = states
  const { page_fns } = fns

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems } = pageSettings ? pageSettings : {}

  const { appForms } = appForms_state ? appForms_state : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // pageContext 
  const { settingsOptions, projectOptions, display } = aps_global ? aps_global : {}
  let { useDarkMode } = settingsOptions ? settingsOptions : {}
  const { timeZone } = projectOptions ? projectOptions : {}
  const { useSimpleInputs } = display ? display : {}

  // eventInfoContext 
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  // viewSettingsComponentContext 
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { swipedItem } = vsc_state ? vsc_state : {}

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_state, dataModify_handlers } = dataModifyContext ? dataModifyContext : {}
  const { editableItem, isDirect, lockedData, uploadOpen, questionProps: dataCommitData, data_modified, isList, dataListData, selectedKey } = dataModify_state ? dataModify_state : {}
  let { viewItem, dataListKey } = dataModify_state ? dataModify_state : {}

  const { item: firstData } = dataListData ? getFirstObject(dataListData) : {}
  const _modifyData = firstData ? firstData : data_modified

  // actionContext    
  const actionContext = useContext(ActionContext);
  const { action_state, action_handlers } = actionContext ? actionContext : {}
  const { questionProps: actionCommitData } = action_state ? action_state : {}

  let _inverted = useDarkMode

  if (isDirect || selectedKey) { _inverted = false }
  if (!swipedItem) { _inverted = false }

  // STATE 
  const [dim_state, setDimState] = useState({})

  const { formProps, vit } = dim_state

  const ammendFormProps = (viewProps) => {
    const hdls = { ...dataModify_handlers }
    let ignorePropSections = false
    if (editableItem) { ignorePropSections = true }
    const fpp = { form_handlers: hdls, updateProps: { formKey: null }, viewProps, data_current: _modifyData, viewItem, ignorePropSections: ignorePropSections, editableItem }
    const fps = getFormPropz(fpp)
    setDimState({ formProps: fps, vit })
  }

  const getGetProps = () => {

    let viewItem_global;

    const { vit: uivi } = dataModify_state ? dataModify_state : {}
    if (aps_viewItems && aps_viewItems[uivi]) { viewItem_global = aps_viewItems[uivi] }

    const { nonLandingView, pathViews } = paps_state ? paps_state : {}
    const { dataOptions, googleSheets } = aps_global ? aps_global : {}
    const { dataLimit, limitDevelopmentData } = dataOptions ? dataOptions : {}
    const { googleSheetsId } = googleSheets ? googleSheets : {}

    const _props = {
      stateProps: {
        appUserAccess,
        aps_viewItems,
        dataLimit,
        dataOverrideOn: false,
        googleSheetsId,
        limitDevelopmentData,
        nonLandingView,
        pathViews,
        staticViews,
        timeZone,
      },
      viewItem_global,
      page_fns,
      singleDirect: true,
      uivi,
      viewItem,
      viewKey: dataListKey,
    }

    return getRefPropz(_props)

  }

  const handleLoadData = (modData) => {
    const modDataItem = modData ? modData[dataListKey] : {}
    dataModify_handlers.handleSetModifyData(modDataItem)
  }

  useEffect(() => {
    if (dataListKey) {
      const { dProps, dataCaptionProps } = getGetProps()
      getData(dProps, dataCaptionProps, handleLoadData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dataListKey]);

  useEffect(() => {
    if (viewItem && appForms) {
      const appFormProps = getAppFormProps(viewItem.key, appForms)
      if (appFormProps) {
        ammendFormProps(appFormProps)
      } else {
        if (modifyProps) { ammendFormProps(modifyProps) }
      }
    } else {
      if (modifyProps) { ammendFormProps(modifyProps) }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_modifyData]);

  const confirmationQuestion = () => {
    const cd = dataCommitData ? dataCommitData : actionCommitData
    const y = dataCommitData ? dataModify_handlers.handleCommitYes : action_handlers.handleCommitYes
    const n = dataCommitData ? dataModify_handlers.handleCommitNo : action_handlers.handleCommitNo
    return <ConfirmationQuestion
      modalOpen={cd.check}
      handleYes={y}
      handleNo={n}
      header={cd.header}
      question={cd.question}
      asModal={false}
    />
  }

  let cn_form = 'form-container dm'
  if (useSimpleInputs) { cn_form += ' simp' }

  // THIS IS THE FORM
  const form = () => <Segment basic key={uniqueKey('form', 'div')} className={cn_form} inverted={_inverted}>
    <FormForm
      formAttributes={{ key: uniqueKey('form', 'form') }}
      formProps={formProps}
      swipedItem={swipedItem}
      isList={isList}
      showAll={true}
      lockedData={lockedData}
    />
  </Segment>

  const dataListForm = () => <div key={uniqueKey('form', 'div')} className={cn_form}>
    <MapListContent />
  </div>

  if (formProps) {
    if (uploadOpen) {
      return <Uploader
        origin={'dataItemModify'}
        storageLocationType={gEnums.storageLocationTypes.event}
      />
    } else if ((dataCommitData && dataCommitData.check) || (actionCommitData && actionCommitData.check)) {
      return confirmationQuestion()
    } else {
      return isList ? dataListForm(formProps) : form(formProps)
    }
  }

  return <Segment loading inverted={_inverted}></Segment>

}

export default DataItemModify