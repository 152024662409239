import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { ticketingHandlers, ticketingInitialState, ticketingReducer } from '../reducers/TicketingReducer';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { gEnums } from '../../../../global/enums/globalEnums';
import { getThemeColorDirect, setThemeColors } from '../../../../global/styles/formatting';

/** Global Conversations for the Event */
export const TicketingContext = createContext();

/** Global Conversations for the Event */
const TicketingProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { page_state, paps_state, storage_state, eventInfo_state } = states ? states : {}
  const { globalFiles } = storage_state ? storage_state : {}
  const { image: images_global } = globalFiles ? globalFiles : {}
  const { pathViews } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { rootPaths } = paps_state ? paps_state : {}
  const { appUser_fns, storage_fns } = fns ? fns : {}

  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  const accessLevel = appUser_fns.get_accessLevel()

  let _staticAppUsers;
  const { staticViews } = eventInfo_state ? eventInfo_state : {}
  if (appUserCollection && staticViews && staticViews[appUserCollection]) {
    _staticAppUsers = staticViews[appUserCollection]
  }

  // pageContext 
  const { appTicketing } = aps_global ? aps_global : {}
  const { ticketingCollectionName, ticketImage, ticketColors, ticketBorderColor } = appTicketing ? appTicketing : {}

  const init_state = { pathViews, appTicketing, rootPaths, accessLevel, _staticAppUsers }
  const [ticketing_state, ticketingDispatch] = useReducer(ticketingReducer, ticketingInitialState(init_state));
  const ticketing_handlers = ticketingHandlers(ticketingDispatch, ticketing_state)

  useEffect(() => {
    if (ticketingCollectionName) {
      ticketing_handlers.initTickets(pathViews)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ticketingCollectionName]);

  useEffect(() => {
    if (images_global && ticketImage && images_global[ticketImage]) {
      storage_fns.getImageUrl(ticketImage, false, gEnums.storageTypes.image, ticketing_handlers.handleTicketImage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [images_global, ticketImage]);

  useEffect(() => {
    const _ticketColors = ticketColors ? setThemeColors(aps_global, ticketColors) : {}
    const tcs = {
      background: _ticketColors.backgroundColor,
      text: _ticketColors.color,
      border: getThemeColorDirect(aps_global, ticketBorderColor),
    }
    ticketing_handlers.handleTicketColors(tcs)
    // eslint-disable-next-line 
  }, []);

  const providerContext = () => <TicketingContext.Provider value={{ ticketing_state, ticketing_handlers }}>
    {props.children}
  </TicketingContext.Provider>

  return (
    <React.Fragment>
      {providerContext()}
    </React.Fragment>
  )
}

export default TicketingProvider