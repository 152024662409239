import _ from 'lodash'
import { getHelpGroup } from '../../components/help/helpSettings'
import { createRefPath } from "../../firestoreData/appData/appRefPaths"
import { fs_get_data } from "../../firestoreData/appData/fsAppData"
import { fs_set_doc } from '../../firestoreData/appData/fsData'

const rts = {
  handleHelpInit: 'handleHelpInit',
  handleSetHelpData: 'handleSetHelpData',
  handleUpdateAllHelp: 'handleUpdateAllHelp',
  handleUpdateHelp: 'handleUpdateHelp',
  handleGetCurrentHelp: 'handleGetCurrentHelp',
}

export const helpTypes = {
  actions: 'actions',
  documents: 'documents',
  questions: 'questions',
  singleItem: 'singleItem',
  topics: 'topics',
}

export const helpReducer = (state, action) => {

  const { helpDocs } = state
  const { dispatch, type, helpSettings } = action

  const { handleSetHelpData } = helpHandlers(dispatch)

  switch (type) {

    case rts.handleHelpInit:
      fs_get_data({ refPath: ['help'], callback: handleSetHelpData })
      return { ...state }

    case rts.handleSetHelpData:
      const hd = ammendHelpDocuments(action.helpDocs)
      return { ...state, helpDocs: hd }

    case rts.handleUpdateAllHelp:
      updateHelpDoc(action.helpType, helpSettings)
      return { ...state }

    case rts.handleUpdateHelp:
      updateHelpDoc(action.helpType, helpSettings)
      return { ...state }

    case rts.handleGetCurrentHelp:
      const { helpType, hsProps } = action.helpProps

      let _helpType = helpType;
      if (isTopic()) { _helpType = helpTypes.topics }
      const helpDataObject = getHelpDataObject(helpDocs, _helpType)
      const helpGroup = getHelpGroup(helpDataObject, hsProps)
      return { ...state, helpGroup, helpType_active: _helpType }

    default:
      return { ...state }
  }
}

export const helpInitialState = (init_state) => {
  return { ...init_state }
};

export const helpHandlers = (dispatch, state) => {
  return {
    handleHelpInit: () => { dispatch({ type: rts.handleHelpInit, dispatch }) },
    handleSetHelpData: (helpDocs) => { dispatch({ type: rts.handleSetHelpData, dispatch, helpDocs }) },
    handleUpdateAllHelp: (helpSettings, helpType) => { dispatch({ type: rts.handleUpdateAllHelp, dispatch, helpSettings, helpType }) },
    handleUpdateHelp: (helpSettings, helpType) => { dispatch({ type: rts.handleUpdateHelp, dispatch, helpSettings, helpType }) },
    handleGetCurrentHelp: (helpProps) => dispatch({ type: rts.handleGetCurrentHelp, dispatch, helpProps }),
  }
}

const updateHelpDoc = (helpType, dataItem) => {
  const docKey = 'help' + _.startCase(helpType)
  const _refPath = createRefPath(['help', docKey])
  fs_set_doc(_refPath, dataItem, true)
}

const getHelpDataObject = (helpDocs, helpType) => {
  const { helpDocuments, helpQuestions, helpActions, helpTopics } = helpDocs ? helpDocs : {}
  let activeDoc;
  switch (helpType) {
    case helpTypes.documents:
    case helpTypes.singleItem:
      activeDoc = helpDocuments
      break;
    case helpTypes.questions:
      activeDoc = helpQuestions
      break;
    case helpTypes.actions:
      activeDoc = helpActions
      break;
    // case helpTypes.singleItem:
    case helpTypes.topics:
      activeDoc = helpTopics
      break;
    default:
      activeDoc = helpDocuments
  }
  return activeDoc
}

const isTopic = (helpKey) => {
  switch (helpKey) {
    case 'props':
    case 'viewItems':
    case 'views':
      return true
    default:
    // nothing
  }
}

const ammendHelpDocuments = (helpDocs) => {
  const { helpTopics, helpDocuments } = helpDocs
  if (helpTopics && helpDocuments) {
    Object.keys(helpDocuments).forEach(key => {
      const hds = helpDocuments[key]
      Object.keys(hds).forEach(k => {
        const hd = hds[k]
        if (hd && _.isObject(hd)) {
          if (helpTopics[k]) {
            hd.groupDescription = helpTopics[k]
          } else {
            if (!hd.groupDescription) {
              hd.groupDescription = 'Group settings for ' + _.startCase(k)
            }
          }
        }
      })
    })
  }
  return helpDocs
}