import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { where } from 'firebase/firestore';
import { signInResponseTypes } from '../../cnr/reducers/SignInReducer';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { validateEmail } from '../../validation/validation';


/** Sign In using signInWithEmailAndPassword */
export const createAccount = async (caProps) => {

  const { creds, appSignIn } = caProps
  const { allowCreateAccount, allowedProfileEmails } = appSignIn ? appSignIn : {}

  // validate the email
  if (!validateEmail(creds.email)) {
    return ({ success: false, signInResponseType: signInResponseTypes.invalidEmail, email: creds.email });
  }

  const _callback = () => { }

  if (allowCreateAccount && creds.email && creds.password) {
    let allowCreate = false
    if (allowedProfileEmails) {
      allowedProfileEmails.forEach(ape => {
        if (creds.email.endsWith(ape)) {
          allowCreate = true
        }
      })
    } else {
      allowCreate = true
    }

    if (allowCreate) {
      const auth = getAuth()
      return createUserWithEmailAndPassword(
        auth,
        creds.email.toLowerCase(),
        creds.password
      ).then(async (resp) => {
        var user = auth.currentUser;
        fs_get_data({ refPath: ['profiles'], wheres: [where('email', '==', user.email)], callback: _callback })
      }).catch((err) => {
        return ({ signInResponseType: signInResponseTypes.signUpError, err, success: false })
      })
    }
  } else {
    const err = { message: 'Log-in Error' }
    return ({ signInResponseType: signInResponseTypes.incorrectPassword, err, success: false })
  }
} 