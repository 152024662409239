import React, { createContext } from 'react';

export const AppUserManagerContext = createContext();

const AppUserManagerProvider = (props) => {

  return <AppUserManagerContext.Provider value={{ ...props }}>
    {props.children}
  </AppUserManagerContext.Provider>
};

export default AppUserManagerProvider