import React from 'react';
import { svgColorTypes, svgTypes } from './svgElements';
import { uniqueKey } from '../../common/keys'

const font_size = '8pt'
const _opacity = 0.25

export const svgElement = (ce, i, svgColorType, handleClick, imageDims) => {

  const fixed = 2
  let cn = 'sv-item'
  let text_fill = 'black'
  let useR = true

  const { w, h, sx, sy, text, color, parent } = ce
  const { width: w_p, height: h_p } = parent ? parent : { width: 100, height: 100 }
  const { width: w_i, height: h_i } = imageDims ? imageDims : { width: 100, height: 100 }

  let fill_color = color ? color : 'green'

  switch (svgColorType) {
    case svgColorTypes.selected:
      ce.color = 'black'
      cn += ' selected'
      ce.stroke = 'none'
      useR = false
      break;
    case svgColorTypes.current:
      ce.color = 'blue' //yellow'
      ce.stroke = 'black'
      text_fill = 'blue'
      break;
    default:
      ce.color = 'blue'
      ce.stroke = 'none'
      break;
  }

  const w_r = w_i / w_p
  const w_h = h_i / h_p

  let width = w;
  let height = h;
  let startX = sx;
  let startY = sy;
  let asPerc = false

  // if (percs && 1 === 2) {
  //   width = percs.w
  //   height = percs.h
  //   startX = percs.sx
  //   startY = percs.sy
  //   asPerc = true
  // }

  if (useR) {
    width = width * w_r
    height = height * w_h
    startX = startX * w_r
    startY = startY * w_h
  }

  // const textX = startX + (width / 2)
  // const textY = startY + (height / 2)

  // the element created depends on the `et` of the element

  switch (ce.et) {
    case svgTypes.line:
      if (ce.withArrow) {
        const id = 'marker' + i
        return pathNArrow(ce, id)
      } else {
        return <line key={uniqueKey('sve', i)} x1={startX.toFixed(fixed)} y1={startY.toFixed(fixed)} x2={ce.ex.toFixed(fixed)} y2={ce.ey.toFixed(fixed)} style={{ stroke: fill_color, strokeWidth: ce.strokeWidth }} />
      }

    case svgTypes.circle:
      return <circle className={cn}
        onClick={e => handleClick && handleClick(ce)}
        key={uniqueKey('sve', i)} cx={startX.toFixed(fixed)} cy={startY.toFixed(fixed)} r={ce.r} stroke={"black"} strokeWidth="1" class={ce.className} style={{ fill: fill_color, fillOpacity: _opacity }} />

    case svgTypes.rectangle:
      if (asPerc) {
        if (text) {
          return [
            <rect className={cn} onClick={e => handleClick && handleClick(ce)} stroke={ce.stroke ? ce.stroke : 'none'} key={uniqueKey('sve', i)} x={startX.toFixed(fixed) + '%'} y={startY.toFixed(fixed) + '%'} width={width.toFixed(fixed) + '%'} height={height.toFixed(fixed) + '%'} style={{ fill: fill_color, fillOpacity: _opacity }} />,
            textRect(i, text, startX.toFixed(fixed) + '%', startY.toFixed(fixed) + '%', text_fill),
          ]
        } else {
          return <rect className={cn} onClick={e => handleClick && handleClick(ce)} stroke={ce.stroke ? ce.stroke : 'none'} key={uniqueKey('sve', i)} x={startX.toFixed(fixed) + '%'} y={startY.toFixed(fixed) + '%'} width={width.toFixed(fixed) + '%'} height={height.toFixed(fixed) + '%'} style={{ fill: fill_color, fillOpacity: _opacity }} />
        }
      } else {
        if (text) {
          return [
            <rect className={cn} onClick={e => handleClick && handleClick(ce)} stroke={ce.stroke ? ce.stroke : 'none'} key={uniqueKey('sve', i)} x={startX.toFixed(fixed)} y={startY.toFixed(fixed)} width={width.toFixed(fixed)} height={height.toFixed(fixed)} style={{ fill: fill_color, fillOpacity: _opacity }} />,
            textRect(i, text, startX, startY, text_fill)
          ]
        } else {
          return <rect className={cn} onClick={e => handleClick && handleClick(ce)} stroke={ce.stroke ? ce.stroke : 'none'} key={uniqueKey('sve', i)} x={startX.toFixed(fixed)} y={startY.toFixed(fixed)} width={width.toFixed(fixed)} height={height.toFixed(fixed)} style={{ fill: fill_color, fillOpacity: _opacity }} />
        }
      }

    case svgTypes.text:
      if (asPerc) {
        return <text key={uniqueKey('sve', i)} x={ce.x + '%'} y={ce.y + '%'} fill={fill_color} fontSize={ce.fs} alignmentBaseline="middle" textAnchor="middle">{(ce.stat.perc) ? ce.stat.perc + ' (' + ce.stat.ta + ')' : '---'}</text>
      } else {
        return <text key={uniqueKey('sve', i)} x={ce.x} y={ce.y} fill={fill_color} fontSize={ce.fs} alignmentBaseline="middle" textAnchor="middle">{ce.text}</text>
      }

    case svgTypes.textStat:
      if (asPerc) {
        return <text key={uniqueKey('sve', i)} x={ce.x + '%'} y={ce.y + '%'} fill={fill_color} fontSize={ce.fs} alignmentBaseline="middle" textAnchor="middle">{(ce.stat.perc) ? ce.stat.perc + ' (' + ce.stat.ta + ')' : '---'}</text>
      } else {
        return <text key={uniqueKey('sve', i)} x={ce.x} y={ce.y} fill={fill_color} fontSize={ce.fs} alignmentBaseline="middle" textAnchor="middle">{(ce.stat.perc) ? ce.stat.perc + ' (' + ce.stat.ta + ')' : '---'}</text>
      }

    case svgTypes.textMulti:
      return <text textAnchor="middle" alignmentBaseline="central" x={ce.x.toFixed(fixed)} y={ce.y.toFixed(fixed)} fill={fill_color} fontSize={ce.fs}>{ce.text}
        <tspan x={ce.x.toFixed(fixed)} y={(ce.y + 15).toFixed(fixed)}>{ce.text1}</tspan>
        <tspan x={ce.x.toFixed(fixed)} y={(ce.y + 30).toFixed(fixed)}>{ce.text2}</tspan>
      </text>
    default:
    // nothing
  }
}

const textRect = (key, text, x, y, fill) => {
  const w = 12
  const h = 12
  const textX = x + (w / 2)
  const textY = y + (h / 2)
  return [
    <rect key={uniqueKey('sve', 'trr', key)} x={x} y={y} width={w} height={h} stroke='none' style={{ fill: fill }} />,
    <text key={uniqueKey('sve', 'trt', key)} x={textX} y={textY} fill={'white'} fontSize={font_size} alignmentBaseline="middle" textAnchor="middle">{text}</text>
  ]
}

const pathNArrow = (ce, id) => {
  const ll = []
  const p = path(ce, id)
  ll.push(arrow(ce, id, p))
  ll.push(p)
  return ll
}

const path = (ce, id) => {
  const { sx, sy } = ce
  let startX = sx;
  let startY = sy;
  const fixed = 2
  const dd = 'M' + startX.toFixed(fixed) + ',' + startY.toFixed(fixed) + ' L' + ce.ex.toFixed(fixed) + ',' + ce.ey.toFixed(fixed)
  const startID = '#' + id + 'start'
  const endID = '#' + id + 'end'
  return <path d={dd}
    style={{ stroke: ce.color, strokeWidth: 1.5, fill: 'none', markerStart: 'url(' + startID + ')', markerEnd: 'url(' + endID + ')' }} />
}

const arrow = (ce, id, path) => (
  // <defs>
  //   <marker id={id + 'start'} markerWidth='8' markerHeight='8' refX='5' refY='5'>
  //     <circle cx='5' cy='5' r='3' style={{ stroke: 'none', fill: '#000000' }} />
  //   </marker>
  //   <marker id={id + 'end'} markerWidth='13' markerHeight='13' refX='2' refY='6' orient='auto' style={{ fill: ce.color }}>
  //     <path d='M2,2 L2,11 L10,6 L2,2' style={{ stroke: ce.color}}/>
  //   </marker>
  // </defs>

  <defs>
    <marker id={id + 'start'} markerWidth='8' markerHeight='8' refX='5' refY='5'>
      <circle cx='5' cy='5' r='3' style={{ stroke: 'none', fill: '#000000' }} />
    </marker>
    <marker id={id + 'end'} markerWidth='10' markerHeight='10' refX='0' refY='3' orient='auto' markerUnits='strokeWidth'>
      <path d='M0,0 L0,6 L9,3 z' fill={ce.color} />
      {/* <circle cx='5' cy='5' r='3' style={{ stroke: 'none', fill: '#000000' }} /> */}
      {/* <text alignmentBaseline="middle" textAnchor="middle" x="50%" y="50%" textAnchor="middle" stroke="#51c5cf" strokeWidth="2px" dy=".3em">5</text> */}
    </marker>
  </defs>
)