import _ from 'lodash';
import { viTypes } from "../../cnr/reducers/CreateReducer";
import { copyObj } from '../../common_web/copy';
import { gEnums } from "../../enums/globalEnums";
import { getTempSettingsState, ammendTempSettingsState } from '../../firestoreData/settings/ammendSettings';
import { fsfn_createViewItem, fsfn_createViewItemProps, fsfn_createViewItemPropSection, fsfn_createViewItems, fsfn_createViews } from '../../functions/fbCreate';
import { addViewItemPropViews } from "../create/appSettingsToViews";

/** Add views, props, propSections, etc. to an object */

/**
 * 
 * @param {object} addProps (action,
    addForm,
    dataTableProps,
    formData,
    handleResponse_settingsAdded,
    initViType,
    isGlobal,
    noData,
    page_fns,
    page_state,
    paps_state,
    selectedItems,
    settings_temp,
    addModeType_state,
    state,
    swipedItem,
    vit,)
 * @returns the updated settings_temp
 */
export const settingsAdd = async (addProps) => {

  const _amts = gEnums.addModeTypes

  const {
    action,
    addForm,
    dataTableProps,
    formData,
    handleResponse_settingsAdded,
    initViType,
    isGlobal,
    noData,
    page_fns,
    page_state,
    paps_state,
    selectedItems,
    settings_temp,
    addModeType_state,
    state,
    swipedItem,
    vit,
  } = addProps

  console.log('settingsAdd', addProps)

  let { settings_temp_global } = addProps
  const { createInfo } = state ? state : {}
  const { settings_temp: ciSvs, views: ciViews } = createInfo ? createInfo : {}
  const { viewItems: ciViewItems } = ciSvs ? ciSvs : {}
  let seletedKeys = selectedItems ? Object.keys(selectedItems) : null

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_page, aps_viewItems, aps_views } = pageSettings ? pageSettings : {}

  // await fsfn_createViewItems()

  let _isGlobal = isGlobal

  switch (addModeType_state) {
    case _amts.copyExisting:
      _isGlobal = true;
      break;
    default:
    // nothing
  }

  const { name: newValue } = formData
  const newValue_cc = newValue ? _.camelCase(newValue) : null

  let { _viewItems, _views } = getTempSettingsState(settings_temp, _isGlobal)

  // IMPORTANT: Add stuff
  switch (addModeType_state) {

    case _amts.copyExisting:
      addViewAndViews(formData, _viewItems, _views)
      handleResponse_settingsAdded(settings_temp)
      return { settings_temp }

    case _amts.googleSheets:
    case _amts.googleSheetsOnly:

      if (addProps.newViews) {
        const { viewItems: vis, views: vs } = addProps.newViews
        const newViews = {}
        if (vis) { Object.keys(vis).forEach(viKey => { aps_viewItems[viKey] = vis[viKey] }) }
        if (vs) {
          Object.keys(vs).forEach(vKey => {
            aps_views[vKey] = vs[vKey]
            newViews[vKey] = vs[vKey]
          })
        }
        _viewItems = aps_viewItems
        ammendTempSettingsState(settings_temp, true, null, _viewItems)
        // settings_temp.settings_views = aps_views
        return { settings_temp, newViews }
      } else {
        let newViews = {}

        // the props within the items are already built!!!

        if (aps_global && seletedKeys && ciViewItems && ciViews) {
          seletedKeys.forEach(nvi => {
            aps_viewItems[nvi] = ciViewItems[nvi]
            newViews[nvi] = ciViews[nvi]
            newViews[nvi + '_list'] = ciViews[nvi + '_list']
          })
        }
        _viewItems = aps_viewItems
        return { settings_temp, newViews }
      }

    default:
      const value = {}

      if (!noData && dataTableProps) {
        dataTableProps.forEach(dtp => {
          const dtPropName = _.camelCase(dtp)
          value[dtPropName] = {
            caption: _.startCase(dtPropName),
            key: dtPropName,
            id: dtPropName,
            show: true,
            formItemType: 'text'
          }
        })
      }

      switch (initViType) {

        // *************** RSS FEEDS ***************
        case viTypes.rssFeeds:
          const { settings_global } = settings_temp ? settings_temp : {}
          if (settings_global && settings_global.rssFeeds) {
            settings_global.rssFeeds[newValue] = {}
            handleResponse_settingsAdded(settings_temp)
          }
          break;

        // *************** GLOBAL PAGE ITEMS ***************
        case viTypes.globalPageItems:

          if (!settings_temp_global) { settings_temp_global = {} }
          if (!settings_temp_global.viewItems) { settings_temp_global.viewItems = {} }
          await fsfn_createViewItem(newValue, true, settings_temp_global.viewItems)
          return { settings_temp: settings_temp_global }
        // return { settings_temp_global }

        // *************** VIEW ITEMS ***************
        // add selected appViewItems to view.page viewItems
        case viTypes.viewItems:
          if (seletedKeys && settings_temp) {

            let newVi;

            if (!_viewItems) {
              _viewItems = {}
              newVi = true
            }

            seletedKeys.forEach(async nvi => {

              // aps_viewItems are the existing pages/views
              if (aps_viewItems[nvi]) {
                await addViewItemToViewItems(_viewItems, aps_viewItems, nvi)
                if (_.isEmpty(settings_temp.page)) {
                  settings_temp.page = {
                    show: true,
                    layout: { layoutType: 'swipeable' },
                    menu: { captionType: 'fixed', caption: _.startCase(nvi) },
                    viewItems: _viewItems
                  }
                } else {
                  if (newVi) {
                    settings_temp.page.viewItems = _viewItems
                  }
                }
              } else {
                // item does not exist  
                await fsfn_createViewItems(seletedKeys, aps_viewItems)
                _viewItems = aps_viewItems
                await fsfn_createViews(aps_viewItems, aps_views, true)

                if (aps_viewItems[nvi]) {
                  _viewItems[nvi] = aps_viewItems[nvi]
                  _viewItems[nvi].key = nvi
                  _viewItems[nvi].position = Object.keys(_viewItems).length
                  handleResponse_settingsAdded(settings_temp)
                }

                await fsfn_createViewItems(seletedKeys, aps_viewItems)
                _viewItems = aps_viewItems
                await fsfn_createViews(aps_viewItems, aps_views, true)
                if (aps_viewItems[nvi]) {
                  _viewItems[nvi] = aps_viewItems[nvi]
                  _viewItems[nvi].key = nvi
                  _viewItems[nvi].position = Object.keys(_viewItems).length
                  handleResponse_settingsAdded(settings_temp)
                }
              }
            })
            handleResponse_settingsAdded(settings_temp)
          }
          return { settings_temp }

        // *************** VIEWS ***************
        // case here we are adding a new view/page to the settings in the project
        case viTypes.views:

          let viKeys;
          switch (addModeType_state) {
            case _amts.listAdd:
              viKeys = [formData.name]
              break;

            default:
              // adding new items 
              if (seletedKeys && seletedKeys.length > 0) {
                viKeys = seletedKeys
              } else if (newValue) {
                viKeys = [newValue_cc]
                // viKeys = [newValue]
              }
          }

          if (viKeys) {
            switch (addModeType_state) {
              case _amts.listAdd:
                await createViewItemsAndViews(settings_temp, viKeys, aps_viewItems, aps_views, formData.pageFields)
                break;
              default:
                await createViewItemsAndViews(settings_temp, viKeys, aps_viewItems, aps_views)
              // nothing
            }

            return { settings_temp }
          }

          return { settings_temp }

        // *************** PROPS ***************
        case viTypes.props:

          // get the current viewItem
          let viewItem_vit = isGlobal ? aps_viewItems[vit] : aps_page.viewItems[vit]
          const { viSection } = swipedItem ? swipedItem : {}
          const newProps = {}

          if (viewItem_vit) {
            if (viewItem_vit.props) {
              // check existance

              // get the new props
              const propsToAdd = {}
              if (seletedKeys && seletedKeys.length > 0) {
                seletedKeys.forEach(si => { propsToAdd[si] = { key: si } })
              } else if (newValue) {
                if (!viewItem_vit.props[newValue]) { propsToAdd[newValue] = { key: newValue } }
              }

              await createViewItemProps(settings_temp, propsToAdd, isGlobal, _viewItems, vit, viewItem_vit, viSection, aps_page, aps_viewItems, aps_views, handleResponse_settingsAdded)
              console.log('settings_temp - settingsAdd', settings_temp)
              return { settings_temp, newProps: propsToAdd, vit }
              // return { settings_temp_global: settings_temp, newProps: propsToAdd, vit }

            }
          } else {
            if (!settings_temp_global) { settings_temp_global = {} }
            if (!settings_temp_global.viewItems) { settings_temp_global.viewItems = {} }

            if (selectedItems) {
              selectedItems.forEach(si => {
                newProps[si] = { key: si }
              })

            } else if (newValue) {
              if (!viewItem_vit.props[newValue]) {
                newProps[newValue] = { key: newValue }
              }
            }

            await fsfn_createViewItemProps(newProps, viewItem_vit.props)

            if (!viewItem_vit.propSections[gEnums.sectionHeaderNames.details]) {
              // add the section if it does not exist  
              await fsfn_createViewItemPropSection(gEnums.sectionHeaderNames.details, null, viewItem_vit.propSections)
              _viewItems = isGlobal ? aps_viewItems : aps_page.viewItems
              ammendTempSettingsState(settings_temp, isGlobal, null, _viewItems)
              return { settings_temp: settings_temp_global, newProps, vit }
              // return { settings_temp_global, newProps, vit }
            } else {
              _viewItems = isGlobal ? aps_viewItems : aps_page.viewItems
              ammendTempSettingsState(settings_temp, isGlobal, null, _viewItems)
              return { settings_temp: settings_temp_global, newProps, vit }
              // return { settings_temp_global, newProps, vit }
            }
          }

          return {
            ...state,
            showAddViewItem: false,
            showAddViewItemNoData: false,
          }


        // *************** PROP SECTIONS ***************
        case viTypes.propSections:

          let _viewItems_page = isGlobal ? aps_viewItems : aps_page.viewItems
          let _viewItem_page = _viewItems_page[vit]

          if (_viewItem_page) {
            if (!_viewItem_page.propSections[newValue]) {
              await fsfn_createViewItemPropSection(newValue, null, _viewItem_page.propSections)
              settings_temp['page'].viewItems[vit] = _viewItem_page
              // _viewItems_page = _viewItems
              return { settings_temp_global: settings_temp }
            }
          } else {
            if (!settings_temp_global) { settings_temp_global = {} }
            if (!settings_temp_global.viewItems) { settings_temp_global.viewItems = {} }
            _viewItem_page = settings_temp_global.viewItems[vit]
            if (!_viewItem_page.propSections[newValue]) {
              await fsfn_createViewItemPropSection(newValue, null, _viewItem_page.propSections)
              ammendTempSettingsState(settings_temp, isGlobal, null, _viewItems_page)
              return { settings_temp_global: settings_temp }
            }
          }

          return {
            ...state,
            showAddViewItem: false,
            showAddViewItemNoData: false,
          }

        case viTypes.forms:
          addForm(action.formData, action.fit, paps_state, page_fns)
          return { ...state }

        default:
        // nothing

      }
  }

  return {
    ...state,
    newValue,
  }
}

/**
 * Creates viewItems and views. Updates the settings_temp object
 * @param {array} viKeys 
 * @param {string} addModeType_state 
 * @param {object} settings_temp 
 * @param {object} aps_page 
 * @param {object} aps_viewItems 
 * @param {object} aps_views  
 */
const createViewItemsAndViews = async (settings_temp, viKeys, aps_viewItems, aps_views, newProps) => {
  // create new viewItems based on the viKeys 
  await fsfn_createViewItems(viKeys, aps_viewItems, newProps)
  await fsfn_createViews(aps_viewItems, aps_views, true, newProps)
  ammendTempSettingsState(settings_temp, true, null, aps_viewItems, aps_views)
}

/** create props for the viewItem */
const createViewItemProps = async (settings_temp, propsToAdd, isGlobal, _viewItems, vit, viewItem_vit, viSection, aps_page, aps_viewItems, aps_views, handleResponse_settingsAdded) => {

  // newProps, propSection, existingProps
  await fsfn_createViewItemProps(propsToAdd, viewItem_vit.props, viSection ? viSection : gEnums.sectionHeaderNames.details,)

  console.log('viewItem_vit.props', viewItem_vit.props)

  // add a new propSection if needed
  if (!viewItem_vit.propSections[gEnums.sectionHeaderNames.details]) {
    // FUNCTIONS
    await fsfn_createViewItemPropSection(gEnums.sectionHeaderNames.details, null, viewItem_vit.propSections)
    await addViewItemPropViews(_viewItems, aps_views, viewItem_vit, propsToAdd, vit)
    _viewItems = isGlobal ? aps_viewItems : aps_page.viewItems
    settings_temp.gvvi.viewItems = _viewItems
    ammendTempSettingsState(settings_temp, isGlobal, null, _viewItems)
  } else {
    await addViewItemPropViews(_viewItems, aps_views, viewItem_vit, propsToAdd, vit)
    _viewItems = isGlobal ? aps_viewItems : aps_page.viewItems
    ammendTempSettingsState(settings_temp, isGlobal, null, _viewItems)
  }
}

/** adds a viewItem to viewItems */
const addViewItemToViewItems = async (_viewItems, aps_viewItems, viKey) => {
  _viewItems[viKey] = aps_viewItems[viKey]
  _viewItems[viKey].key = viKey
  _viewItems[viKey].position = Object.keys(_viewItems).length
  delete _viewItems[viKey].dataConnection
}

const ammendItem = (item, name, copyFrom) => {
  item.menu.caption = _.startCase(name)
  item.viewItems[name] = item.viewItems[copyFrom]
  item.viewItems[name].key = name
  item.viewItems[name].display.caption = _.startCase(name)
  if (!item.viewItems[name].dataSource) { item.viewItems[name].dataSource = {} }
  item.viewItems[name].dataSource.altDataCollectionName = copyFrom
}

const addViewAndViews = (action, viewItems, views) => {

  const { name, copyFrom } = action
  const _name = _.camelCase(name)

  const _viewItem = copyObj(viewItems[copyFrom]);
  const _view = copyObj(views[copyFrom]);
  const _viewList = copyObj(views[copyFrom + '_list']);

  _viewItem.key = _name
  _viewItem.dataConnection.altCollectionName = copyFrom
  _viewItem.display.caption = _.startCase(name)

  ammendItem(_view, _name, copyFrom)
  ammendItem(_viewList, _name, copyFrom)

  viewItems[name] = _viewItem
  views[name] = _view
  views[name + '_list'] = _viewList
}