import { serverTimestamp } from "firebase/firestore";
import _ from 'lodash';
import { getPks, getSports_collectionItem } from "../../../../projectSpecific/sports/cnr/reducers/reducerHelpers/seasonalSportsData";
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { removeAllEmpties } from "../../../common/dataAdjust";
import { createRefPath, createRefPath_event } from "../../appData/appRefPaths";
import { createFsDocKey } from '../../appData/fsAppData';
import { fs_add_doc, fs_update_doc } from '../../appData/fsData';
import { addPromise } from '../../promises/addPromise';
import { createNewViewAndViewItemsSettingsDB } from "../../settings/updateSettings";
import { updateRelatedCollections } from "./relatedData";
import { updateSubData } from './subData';

const _allowSubUpdate = true

export const addData = async (props) => {

  const {
    baseRef,
    dispatch,
    opts,
    pathViews,
    ufProps,
  } = props

  const { refPath, directCollection } = baseRef
  let { vit, itemData, selectedDataItems, viDeps, dataRestrictions, appUser, storagePaths, autoUpdateCollectionRelationships, staticViewKeys, useTimestamps } = ufProps ? ufProps : {}
  let { dataParents, useSubDataCollection, subDataCollectionName } = viDeps ? viDeps : {}

  const _itemData = removeAllEmpties(itemData)
  const pks = getPks(pathViews)

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.addData)) {
    const d = {
      _itemData,
      dataParents,
      pks,
      refPath,
      ufProps,
      viDeps,
      vit,
    }
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  if (useSubDataCollection && subDataCollectionName) {
    addSubCollection(pathViews, pks, vit, _itemData, dispatch)
    // addToSubCollection(pathViews, subDataCollectionName, vit, _itemData, selectedDataItems, dispatch)
  } else {

    const callback_add = (results) => {
      if (opts && opts.settingsProps) {
        createNewViewAndViewItemsSettingsDB(opts.settingsProps, pathViews).then(
          dispatch && dispatch({ type: dispatchProps.success, dispatch })
        )
      }
      const { dataToUpdate, newItem, res } = results ? results : {}
      const { id: viewKey } = res ? res : {}
      autoUpdateCollectionRelationships && updateRelatedCollections(pathViews, vit, null, dataToUpdate, viewKey, staticViewKeys)
      dispatch && dispatch({ ...results, dispatch })
    }

    if (useTimestamps) {
      itemData._ts = { _updateDate: serverTimestamp() }
      if (appUser) {
        itemData._uid = appUser.uid
        itemData._user = appUser.displayName
      }
    }

    if (_itemData && (!selectedDataItems || Object.keys(selectedDataItems).length === 0)) {
      switch (vit) {
        default:

          const _addRef = createRefPath([vit], refPath)
          fs_add_doc(_addRef, _itemData, callback_add, false, appUser, storagePaths, vit)
      }
    } else {
      if (selectedDataItems) {
        addPromise(refPath, vit, directCollection, selectedDataItems).then((newItem) => {
          callback_add({ type: dispatchProps.success, addPath: refPath, newItem })
          // if (opts && opts.settingsProps) {
          //   createNewViewAndViewItemsSettingsDB(opts.settingsProps, pathViews).then(
          //     dispatch({ type: dispatchProps.success, dispatch })
          //   )
          // } else {
          //   callback_add({ type: dispatchProps.success, addPath: refPath })
          //   // dispatch({ type: dispatchProps.success, dispatch })
          // }
        }).catch(error => {
          console.log('addData', error)
          dispatch({ type: dispatchProps.error, error, dispatch })
        })
      }
    }
  }
}


/**
 * 
 * @param {object} pathViews 
 * @param {object} pks 
 * @param {string} documentFieldName 
 * @param {object} data 
 * @param {string} collectionName ** with _
 * @param {function} callback 
 * @description Updates the `_seasonalCollectionName` or the collectionName prefixed with an `_`
 */
const addSubCollection = async (pathViews, pks, collectionName, data, callback) => {

  const subCollectionName = '_' + collectionName
  const _collectionItem = await getSports_collectionItem(pathViews, pks, subCollectionName, true)

  if (_collectionItem && !_.isEmpty(_collectionItem)) {

    const { _itemKey } = _collectionItem
    const _refPath = createRefPath_event(pathViews, [subCollectionName, _itemKey])
    const refKey = createFsDocKey('subCollection')

    const dataToUpdate = { [collectionName + '.' + refKey]: data }

    _allowSubUpdate && fs_update_doc(_refPath, dataToUpdate, callback)

  } else {
    const _refPath = createRefPath_event(pathViews, [subCollectionName])

    const dataToAdd = {
      parentKeys: pks,
      [collectionName]: data
    }

    _allowSubUpdate && fs_add_doc(_refPath, dataToAdd, callback)
  }
}

const addToSubCollection = (pathViews, subDataCollectionName, vit, itemData, selectedDataItems, callback) => {

  let trueData = {}

  if (selectedDataItems) {
    Object.keys(selectedDataItems).forEach(key => {
      trueData[createFsDocKey()] = selectedDataItems[key]
    })
  } else {
    trueData = itemData
  }

  updateSubData(pathViews, subDataCollectionName, vit, trueData, callback)
}