import { useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { AppUserContext } from '../../../../global/cnr/contexts/AppUserContext';
import { PapsContext } from '../../../../global/cnr/contexts/PapsContext';
import { giftCardHandlers, giftCardInitialState, giftCardReducer } from '../reducers/GiftCardReducer';

/**
 * @provides tournamentTeams 
 * @provides golfCourse  
 * @provides holes 
 * @provides golfMatchData  
 * @provides roundsStatus 
 * @provides golfEvent_handlers 
 * @provides golfTeam1
 * @provides golfTeam2
 * @provides golfTeam1Style
 * @provides golfTeam2Style
 */

const GiftCardProvider = () => {

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext
  const { pathViews } = paps_state ? paps_state : {}

  const appUserContext = useContext(AppUserContext)
  const { appUser_state } = appUserContext
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { appUserSession } = appUserAccess ? appUserAccess : {}
  const { _itemKey: appUserSessionKey } = appUserSession ? appUserSession : {}
  const { customers: customers_pv } = pathViews ? pathViews : {}

  const init_state = { pathViews }
  const [giftCard_state, giftCard_dispatch] = useReducer(giftCardReducer, init_state, giftCardInitialState)
  const giftCard_handlers = giftCardHandlers(giftCard_dispatch, giftCard_state)
  const { retailers } = giftCard_state ? giftCard_state : {}

  useEffect(() => {
    giftCard_handlers.handleGetRetailers()
    giftCard_handlers.handleGetGiftCards(customers_pv)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserSessionKey]);

  if (retailers) {
    return { giftCard_handlers, giftCard_state }
  } else {
    return { giftCard_handlers }
  }
};

export default GiftCardProvider
