/**
 * Copies and object using JSON.parse/JSON.stringify
 * @param {object} obj 
 * @returns a copied object
 */
export const copyObj = (obj) => obj ? JSON.parse(JSON.stringify(obj)) : obj;

/**
 * 
 * @param {*} textAreaRef 
 * @description copies the `textAreaRef` to the `clipboard` using `document.execCommand`
 */
export const copyCodeToClipboard = (textAreaRef) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(textAreaRef.current.innerHTML);
  }
}

/**
 * 
 * @param {*} textAreaRef 
 * @description copies the `textAreaRef` to the `clipboard` using `document.execCommand`
 */
export const copyTableToClipboard = (tableRef) => {
  const code = tableRef.current.innerText;
  navigator.clipboard.writeText(code).then(res => {
    console.log('Copied')
  });
}

