import { allPageTypes } from '../../../enums/allPageTypes';
import { settingsIconTypes } from '../../../enums/settingsIconTypes';
import { gridItemIconGroup } from './SegIconGroup';

export const gridItemsAdmin = (props) => {

  const { parentContext, pageIcons, gcProps } = props

  const { states } = parentContext ? parentContext : {}
  const { page_state } = states

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appNotifications } = aps_global ? aps_global : {}

  const { useNotificationTopics, showTopicsManagerOnHomeScreen } = appNotifications ? appNotifications : {}

  const gridItem = (viewItem, segProps, gcProps) => gridItemIconGroup(viewItem, segProps, gcProps)

  const items = []
  if (!pageIcons) { items.push(gridItem({ key: allPageTypes.appUsersManager }, { caption: 'App Users', icon: settingsIconTypes.appUsers, cn: 'ao' }, gcProps)) }
  if (!pageIcons) { items.push(gridItem({ key: allPageTypes.appUserRequests }, { caption: 'App Requests', icon: settingsIconTypes.appAccessRequests, cn: 'ao' }, gcProps)) }
  if (!pageIcons && useNotificationTopics && showTopicsManagerOnHomeScreen) { items.push(gridItem({ key: allPageTypes.topicsManager }, { caption: 'Topics', icon: 'chat', cn: 'ao' }, gcProps)) }
  return items
}