import { callFunctionDirect } from "./fbAll";

const fbFunctionTypes = {
  updateRegistrationDirect: 'updateRegistrationDirect',
}

export const fsfn_updateRegistration = (pathViews) => {

  const data = {
    clientKey: pathViews.clients,
    eventKey: pathViews.events,
    registrationKey: 'attendees',
    registrationData: {
      registrationId: '12345',
      firstName: 'Test',
      lastName: 'Tester',
      email: 'test@test.com',
      title: 'Tester',
      company: 'The Testing Company',
      tickets: [
        {
          ticketKey: '158',
          allotment: 2
        },
        {
          ticketKey: '159',
          allotment: 9
        },
      ]
    }
  }


  // const { clientKey, eventKey, registrationData } = data
  // const { registrationId, tickets } = registrationData ? registrationData : {}

  // if (tickets) {
  //   tickets.forEach(ticket => {
  //     const { ticketKey, allotment } = ticket

  //     const updateData = {
  //       userTickets: {
  //         [registrationId]: {
  //           allotment,
  //           // subTickets: createUserSubTickets(allotment, ticketKey)
  //         }
  //       }
  //     }

  //     console.log('updateData', updateData)

  //     const fs = firebase.firestore()

  //     try {
  //       const updateRef = fs
  //         .collection('clients').doc(clientKey)
  //         .collection('events').doc(eventKey)
  //         .collection('_ticketing').doc(ticketKey)

  //       console.log('updateRef', updateRef)
  //     } catch (error) {
  //       console.error(error)
  //     }


  //     // updateRef.set(updateData, { merge: true }).then(() => {
  //     //   functions.logger.info('updateTicketing - SUCCESS')
  //     //   if (res) {
  //     //     res.status(200).json({
  //     //       message: 'updateTicketingAssignments - Success'
  //     //     })
  //     //   }
  //     // }).catch(error => {
  //     //   functions.logger.info('updateTicketing - error', error)
  //     //   if (res) {
  //     //     res.status(200).json({
  //     //       message: 'updateTicketingAssignments - ERROR'
  //     //     })
  //     //   }
  //     // })

  //   })
  // }

  callFunctionDirect(fbFunctionTypes.updateRegistrationDirect, data);
}

// const data = {
//   "registrationData": {
//     "otherDataAboutTheRegistration": "OTHER_INFO",
//     "registrationId": "1234567",
//     "tickets": [
//       { "ticketKey": 159, "allotment": 9 }
//     ]
//   },
//   "clientKey": "PwbtTs9DLDu9aq8NAfh2",
//   "registrationKey": "attendees",
//   "eventKey": "2021 MLS CUP"
// }