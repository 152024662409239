import { gEnums } from '../../enums/globalEnums';

export const touchHandlers = (id, touch) => {
  return {
    onTouchStart: (e) => touch.handlePress(e, id),
    onTouchMove: (e) => touch.handleMove(e),
    onTouchEnd: (e) => touch.handleRelease(e),
    onMouseDown: (e) => touch.handlePress(e, id),
    onMouseUp: (e) => touch.handleRelease(e),
    onMouseLeave: (e) => touch.handleRelease(e),
  }
}

export const toucher = (component) => {

  const { props } = component

  let buttonPressTimer = null

  const touch = {
    on: false,
    delay: 3000,
    margin: 30,
    id: null,
    start: null,
    current: null,
    diff: () => {
      if (touch.current) {
        const d = {
          diffX: touch.start.clientX - touch.current.clientX,
          diffY: touch.start.clientY - touch.current.clientY
        }
        return d
      }
    },
    reset: () => {
      touch.on = false
      if (touch.current) {
        const d = {
          diffX: Math.abs(touch.start.clientX - touch.current.clientX),
          diffY: Math.abs(touch.start.clientY - touch.current.clientY)
        }
        if (d.diffX > touch.margin || d.diffY > touch.margin) {
          if (buttonPressTimer) {
            clearTimeout(buttonPressTimer)
          }
        }
      }
    },

    startTimer: (id) => {
      buttonPressTimer = setTimeout(function () {
        if (touch.on) {
          component.handleOpenModalItem(touch.id)
        }
      }, touch.delay);
    },

    handlePress: (e, id) => {
      if (!e) { return false }
      touch.id = id
      if (!touch.start) {
        if (e.touches) {
          touch.start = e.touches[0]
          touch.current = e.touches[0]
        }
      }
      const { paps } = props
      const { appUser } = paps
      const { appUserAccess } = appUser ? appUser : {}
      if (appUser && appUserAccess.accessLevel >= gEnums.accessLevels.admin.value) {
        touch.on = true
        touch.startTimer()
      }
    },

    handleMove: (e, id) => {
      if (!e) { return false }
      if (e.touches) {
        touch.current = e.touches[0]
      }
      touch.reset()
    },

    handleRelease: () => {
      touch.on = false
      if (buttonPressTimer) {
        clearTimeout(buttonPressTimer);
      }
    }
  }

  return touch

}   