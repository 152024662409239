import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath_event } from '../../appData/appRefPaths';
import { addPromise } from '../../promises/addPromise';

/**
 * Add data items to each of the collection
 * @param {*} pathViews 
 * @param {*} collectionName 
 * @param {*} dataItems 
 * @param {*} useAdd 
 * @param {*} useAutoKey 
 * @param {*} dispatch 
 */
export const addDataItemsToCollectionDatabase = async (pathViews, collectionName, dataItems, useAdd, useAutoKey, dispatch) => {
  const _refPath = createRefPath_event(pathViews)
  await addPromise(_refPath, collectionName, null, dataItems, useAdd, useAutoKey)
  return { type: dispatchProps.success, collectionName }
}