import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import { uniqueKey } from '../../../../global/common/keys';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { gEnums } from '../../../../global/enums/globalEnums';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { CreateSportsContext } from '../../create/CreateSports';
import { MatchScheduleWithProvider } from '../matches/MatchSchedule';

const _allowSelect = false

const SectionStandings = (props) => {

  const { sectionOpts } = props
  const { teams, selectedTeam, selectedTeams, caption, teamOnly, forOverview, forPlayoff, isFavSection } = sectionOpts

  // parentContext
  const parentContext = useContext(ParentContext);
  const { fns, states } = parentContext ? parentContext : {}
  const { appUser_state } = states ? states : {}
  const { page_fns } = fns
  const { pushSimple } = page_fns ? page_fns : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const { profileData } = appUser ? appUser : {}
  const { pageFavs } = profileData ? profileData : {}
  const { teams: team_favs } = pageFavs ? pageFavs : {}

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_fns, sportsSeason_handlers, sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { teams_info, details_info } = sportsSeason_state ? sportsSeason_state : {}
  const { playoffTeams: playoffTeams_season } = teams_info ? teams_info : {}
  const { details } = details_info ? details_info : {}
  const { playoffTeamsPerSection } = details ? details : {}

  // createSportsContext - optional
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state, createSports_handlers } = createSportsContext ? createSportsContext : {}
  const { subActionType, playoff_info } = createSports_state ? createSports_state : {}
  const { teams: playoffTeams } = playoff_info ? playoff_info : {}
  const allowSelect = (subActionType === gEnums.availableSubActionTypes.createPlayoff)

  const sortedTeams = sportsSeason_fns && teams ? sportsSeason_fns.sortSectionStandings(teams, playoffTeamsPerSection) : {}

  const [selected_team, setSelectedTeam] = useState()

  let cellCount = 0
  const tableType = 'tst'

  const tableKey = 'tk'
  const isNonSection = false
  const nameW = 8
  const spanW = 4
  const valueW = 2

  const settings = {}

  useEffect(() => {
    if (allowSelect) {
      createSports_handlers.handleInit_playoffTeams(playoffTeams_season)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleTeamClick = (team) => {
    if (forPlayoff) {
      _allowSelect && setSelectedTeam(selected_team ? null : team)
    } else {
      team && pushSimple && sportsSeason_handlers.handleGoToTeam(pushSimple, team)
    }

  }

  const handlePlayoffClick = (e, team) => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    createSports_handlers.handleSelect_team(team)
  }

  const thc = (index, text, width, rowSpan, colSpan) => <Table.HeaderCell key={uniqueKey('ss', 'th', tableKey, index)} width={width} rowSpan={rowSpan} colSpan={colSpan}>{text}</Table.HeaderCell>

  const winHeader = () => <Table.Header key={uniqueKey('ss', 't', 'th')} className={'standings-headerr'}>
    <Table.Row>
      <Table.HeaderCell rowSpan='2'>Name</Table.HeaderCell>
      <Table.HeaderCell colSpan='2'>Section</Table.HeaderCell>
      <Table.HeaderCell colSpan='2'>Overall</Table.HeaderCell>
    </Table.Row>
    <Table.Row>
      <Table.HeaderCell>W</Table.HeaderCell>
      <Table.HeaderCell>L</Table.HeaderCell>
      <Table.HeaderCell>W</Table.HeaderCell>
      <Table.HeaderCell>L</Table.HeaderCell>
    </Table.Row>
  </Table.Header>

  const tableHeader = () => {
    if (isNonSection) {
      return <Table.Header key={uniqueKey('ss', 'th')}>
        <Table.Row>
          {thc(1, 'P', 1)}
          {thc(2, '#', 2)}
          {thc(3, 'Team', 9)}
          {thc(4, 'SW', 2)}
          {thc(5, 'SL', 2)}
        </Table.Row>
      </Table.Header>
    } else {
      if (tableType === 'normal') {
        return <Table.Header key={uniqueKey('ss', 'th')}>
          <Table.Row key={uniqueKey('ss', 'th', 1)}>
            <Table.HeaderCell key={uniqueKey('ss', 'th', 'hc', 1)} rowSpan='2' width={nameW} verticalAlign='bottom'>Team</Table.HeaderCell>
            <Table.HeaderCell key={uniqueKey('ss', 'th', 'hc', 2)} colSpan='2' width={spanW}>Section</Table.HeaderCell>
            <Table.HeaderCell key={uniqueKey('ss', 'th', 'hc', 3)} colSpan='2' width={spanW}>Non-Section</Table.HeaderCell>
          </Table.Row>
          <Table.Row key={uniqueKey('ss', 'th', 3)}>
            {thc(1, 'W', 2)}
            {thc(2, 'L', 2)}
            {thc(3, 'W', 2)}
            {thc(4, 'L', 2)}
          </Table.Row>
        </Table.Header>
      } else {
        return winHeader()
      }
    }
  }

  // const teamIcon = (team, rowKey, key, defaultImage) => {
  //   const def = (defaultImage) ? defaultImage : 'volleyball'
  //   return <Image key={uniqueKey('ss', 'rc', rowKey, key)} size={settings.imageSize} src={icons.getTeamIcon(team)} className={styles.teamRowIcon} onError={e => e.target.src = '/images/' + def + '.png'} />
  // }

  const recordCell = (value, width) => {
    cellCount++
    return <Table.Cell key={uniqueKey('ss', 'rc', cellCount)} width={valueW}>{value}</Table.Cell>
  }

  const teamRecordCells = (record) => {
    const cells = []
    if (record) {
      if (isNonSection) {
        cells.push(recordCell(record.sw, valueW))
        cells.push(recordCell(record.sl, valueW))
      } else {
        if (tableType === 'normal') {
          cells.push(recordCell(record.nsw, valueW))
          cells.push(recordCell(record.nsl, valueW))
        } else {
          cells.push(recordCell(record.sw, valueW))
          cells.push(recordCell(record.sl, valueW))
        }
        cells.push(recordCell(record.w, valueW))
        cells.push(recordCell(record.l, valueW))
      }
    } else {
      cells.push(recordCell(0, valueW))
      cells.push(recordCell(0, valueW))
      if (!isNonSection) {
        if (tableType === 'normal') {
          cells.push(recordCell(0, valueW))
          cells.push(recordCell(0, valueW))
        } else {
          cells.push(recordCell(0, valueW))
          cells.push(recordCell(0, valueW))
        }
      }
    }
    return cells
  }

  const sectionRankLabel = (sectionRank, team) => {
    const playoffTeam = playoffTeams && team ? _.find(playoffTeams, { id: team._itemKey }) : null
    const checked = playoffTeam ? true : false
    const color = checked ? 'green' : 'grey'
    return <Label size={'mini'} onClick={(e) => { allowSelect && handlePlayoffClick(e, team) }} color={allowSelect ? color : null} >{sectionRank}</Label>
  }

  const currentRankLabel = (currentRank) => <Label size={'mini'} color={'grey'} circular>{currentRank}</Label>

  const teamRow = (team, rowKey, rowCn, firstCn, index) => {
    const { record, _itemKey, currentRank } = team ? team : {}
    const { playoffTeam, eliminated, sectionRank } = record ? record : {}
    const isFav = team_favs && team_favs[_itemKey] ? true : false
    let _rowCn = isFav ? rowCn += ' fav' : rowCn
    const _rowFavIcon = isFav ? <Icon name={'star'} color={'grey'} size={'tiny'} /> : null
    if (isNonSection) {
      return <Table.Row key={uniqueKey('ss', 'tr', rowKey)} className={_rowCn} onClick={(e) => handleTeamClick(team)}>
        <Table.Cell key={uniqueKey('ss', 'tc', rowKey, 1)} width={1}>{index + 1}</Table.Cell>
        <Table.Cell key={uniqueKey('ss', 'tc', rowKey, 2)} width={2}>{'#' + team.teamNumber}</Table.Cell>
        <Table.Cell key={uniqueKey('ss', 'tc', rowKey, 3)} textAlign='left' width={9} >{team.name}</Table.Cell>
        {teamRecordCells(record)}
      </Table.Row>
    } else {
      if (playoffTeam) { _rowCn += ' ss-pt' }
      if (eliminated) { _rowCn += ' ss-elim' }
      return <Table.Row key={uniqueKey('ss', 'tr', rowKey)} className={_rowCn} onClick={(e) => handleTeamClick(team)}>
        <Table.Cell key={uniqueKey('ss', 'tr', 'tc', rowKey)}
          textAlign='left'
          width={nameW}
          className={firstCn}>
          {sectionRankLabel(sectionRank, team)}
          {team.name}
          {_rowFavIcon}
          {currentRank && currentRankLabel(currentRank)}
        </Table.Cell>
        {teamRecordCells(record)}
      </Table.Row>
    }
  }

  const getSelectedStandings = (sortedTeams) => {
    const _selected = []
    const _teams = Object.values(sortedTeams)
    const index = _.findIndex(_teams, { key: selectedTeam });
    const before = _teams[index - 1];
    const selected = _teams[index];
    const after = _teams[index + 1];
    _selected.push(before)
    _selected.push(selected)
    _selected.push(after)
    return _selected;
  }

  const teamRows = () => {

    const rows = []
    let _sortedTeams;

    if (forOverview) {
      _sortedTeams = getSelectedStandings(sortedTeams, 1)
    } else {
      _sortedTeams = sortedTeams
    }

    Object.keys(_sortedTeams).forEach((teamKey, index) => {

      const rowKey = 'r' + index
      const team = _sortedTeams[teamKey]

      let cellCn = null
      let rowCn = team && (selectedTeam === team.id) ? 'standings-selected' : null
      if (team && selectedTeams && selectedTeams.includes(team.id)) { rowCn = 'standings-selected' }

      if (team) {
        rows.push(teamRow(team, rowKey, rowCn, cellCn, index))
        if (selected_team && selected_team._itemKey === team.id) {
          rows.push(<Table.Row key={uniqueKey('ss', rowKey, 'ms')}>
            <Table.Cell>
              <MatchScheduleWithProvider teamKey={team.id} forPlayoff={forPlayoff} />
            </Table.Cell>
          </Table.Row>)
        }
      }
    })
    return rows
  }

  const tableItems = () => {
    const items = []
    items.push(
      <Table.Body key={uniqueKey('ss', 'tb')}>
        {teamRows()}
      </Table.Body>)
    items.unshift(tableHeader())
    return items
  }

  const table = <Table key={uniqueKey('ss', 't')}
    unstackable
    compact
    size={settings.tableSize}
    className={'mtable'}  >
    {tableItems()}
  </Table>

  let cn = 'standings-container'
  if (teamOnly) { cn += ' team' }

  return <div className={cn} key={uniqueKey('ss', 's', 'sg')}>
    {!forOverview && <div key={uniqueKey('ss', 's', 'sh')} className={'standings-header'}>
      {isFavSection && <Icon name={'star'} />}{caption}
    </div>
    }
    <div key={uniqueKey('ss', 's', 'st')} className={'standings-table'}>
      {table}
    </div>
  </div>

  // return <Segment.Group compact className={cn} key={uniqueKey('ss', 's', 'sg')}>
  //   {!forOverview && <Segment key={uniqueKey('ss', 's', 'sh')} className={'standings-header'}>{isFavSection && <Icon name={'star'} />}{caption}</Segment>}
  //   <Segment key={uniqueKey('ss', 's', 'st')} className={'standings-table'}>
  //     {table}
  //   </Segment>
  // </Segment.Group>
}

export default SectionStandings