export const sortTypes = {
  handleShowModalSort: 'handleShowModalSort',
  handleSortOk: 'handleSortOk',
  handleSortReady: 'handleSortReady'
}

export const sortReducer = (state, action) => {

  const { handleSortOk } = state
  const { type, value } = action
 
  switch (type) {
    case sortTypes.handleShowModalSort:
      return {
        ...state,
        showModalSort: !state.showModalSort
      }

    case sortTypes.handleSortOk: 
      if (handleSortOk) {
        handleSortOk(state.sortedItems)
      } 
      
      return {
        ...state,
        showModalSort: false,
        sortOk: false,
        sortedItems: null,
        sortedReady: false,
      }

    case sortTypes.handleSortReady:
      return {
        ...state,
        sortedReady: true,
        sortedItems: value,
      }

    default:
      return state
  }
}

export const sortInitialState = (handleSortOk) => {
  return {
    showModalSort: false,
    sortOk: false,
    sortedItems: null,
    handleSortOk: handleSortOk, 
  }
};

export const sortHandlers = (dispatch, state) => {
  return {
    /** Toggles `showModalSort` */
    handleShowModalSort: () => { dispatch({ type: sortTypes.handleShowModalSort }) },
    /** Applies sortedItems to items */
    handleSortOk: () => { dispatch({ type: sortTypes.handleSortOk }) },
    /** Toggles sortedReady */
    handleSortReady: (items) => { dispatch({ type: sortTypes.handleSortReady, value: items }) },
  }
}