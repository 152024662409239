import _ from 'lodash';
import { getDistance } from 'geolib';
import { findLocations, geoCodeAddress, getMapMarkers } from "../../components/map/mapHelper";

export const googleMapInitialState = (init_state) => {
  return { ...init_state }
};

const rts = {
  handleCloseInfoWindow: 'handleCloseInfoWindow',
  handleGeoCodes: 'handleGeoCodes',
  handleGetAllPlaces: 'handleGetAllPlaces',
  handleGetBounds: 'handleGetBounds',
  handleGetPlaces: 'handleGetPlaces',
  handleGetService: 'handleGetService',
  handleMapListData: 'handleMapListData',
  handleSelectedPlace: 'handleSelectedPlace',
  handleSetAllPlaces: 'handleSetAllPlaces',
  handleSetMapReady: 'handleSetMapReady',
  handleSetPlaces: 'handleSetPlaces',
  handleSetSelectedMarker: 'handleSetSelectedMarker',
}

export const geoListTypes = {
  groupedList: 'groupedList',
  list: 'list',
  location: 'location'
}

export const googleMapReducer = (state, action) => {

  const { google, additionalProp, showClosestItemOnly, getGviDeps, paps_state, viewItem, firestore_handlers, viewListData, mapLists, locationsOf, autoUpdateMapPositions, displayProps } = state

  const { handleSetPlaces, handleSetAllPlaces, handleSelectedPlace } = googleMapHandlers(action.dispatch)

  switch (action.type) {

    case rts.handleSetMapReady:
      const { mr } = action
      const { m, mp } = mr
      return { ...state, mapReady: action.mr ? true : false, map: m, mapProps: mp }

    case rts.handleGetService:
      const service = new google.maps.places.PlacesService(state.map)
      return { ...state, service }

    case rts.handleGeoCodes:
      if (viewListData) {
        Object.keys(viewListData).forEach((key, index) => {
          const itemData = viewListData[key]
          if (!itemData.mapPosition) {
            const { address, street, city, state: state_address, zip } = itemData ? itemData : {}
            const mapDetails = address ? { address } : { address: street + ', ' + city + ', ' + state_address + ' ' + zip }
            const updateProps = {
              paps_state,
              itemData,
              viewItem,
            }
            geoCodeAddress(google, mapDetails, null, autoUpdateMapPositions, updateProps, firestore_handlers)
          }
        })
      }
      return { ...state, ...action }

    case rts.handleMapListData:
      const { mld: mapListData, elemPropType } = ammendLocations({ getGviDeps, paps_state, viewListData, mapLists, locationsOf, displayProps })
      const markers = getMapMarkers(mapListData, handleSelectedPlace)
      const mapBounds = getBounds(google, mapListData, markers)
      return { ...state, ...action, mapListData, markers, mapBounds, elemPropType }

    case rts.handleSelectedPlace:
      return { ...state, selectedPlace: action.selectedPlace, markerInfo: getMarkerInfo(action.selectedPlace) }

    case rts.handleCloseInfoWindow:
      return { ...state, selectedPlace: null }

    case rts.handleGetAllPlaces:
      getAllPlaces(action.itemsToMap, state, handleSetAllPlaces)
      return { ...state }

    case rts.handleGetPlaces:
      getPlaces(action.name, state, handleSetPlaces)
      return { ...state }

    case rts.handleSetAllPlaces:
      const { groupedPlaces } = action
      getGroupedPlaces(state, action, groupedPlaces, additionalProp, showClosestItemOnly)
      const _places = getPlacesFromGroupedPlaces(groupedPlaces)
      return { ...state, places: _places, groupedPlaces }

    case rts.handleSetPlaces:
      return { ...state, places: action.places }


    case rts.handleSetSelectedMarker:
      return { ...state, selectedMarker: action.marker, markerInfo: getMarkerInfo(action.marker) }

    case rts.handleGetBounds:
      return { ...state, mapBounds: getBounds(state.google) }

    default:
      return { ...state }
  }
}

export const googleMapHandlers = (dispatch) => {
  return {
    handleCloseInfoWindow: () => dispatch({ type: rts.handleCloseInfoWindow, dispatch }),
    handleGeoCodes: () => dispatch({ type: rts.handleGeoCodes, dispatch }),
    handleGetAllPlaces: (itemsToMap) => dispatch({ type: rts.handleGetAllPlaces, dispatch, itemsToMap }),
    handleGetBounds: () => dispatch({ type: rts.handleGetBounds, dispatch }),
    handleGetPlaces: (name) => dispatch({ type: rts.handleGetPlaces, dispatch, name }),
    handleGetService: () => dispatch({ type: rts.handleGetService, dispatch }),
    handleMapListData: (mapListData) => dispatch({ type: rts.handleMapListData, dispatch, mapListData }),
    handleSelectedPlace: (selectedPlace) => dispatch({ type: rts.handleSelectedPlace, dispatch, selectedPlace }),
    handleSetMapReady: (mr) => dispatch({ type: rts.handleSetMapReady, dispatch, mr }),
    handleSetAllPlaces: (groupedPlaces, items) => dispatch({ type: rts.handleSetAllPlaces, dispatch, groupedPlaces, items }),
    handleSetPlaces: (places) => dispatch({ type: rts.handleSetPlaces, dispatch, places }),
    handleSetSelectedMarker: (marker) => dispatch({ type: rts.handleSetSelectedMarker, dispatch, marker }),
  }
}

const getGroupedPlaces = (state, action, groupedPlaces, additionalProp, showClosestItemOnly) => {
  Object.keys(groupedPlaces).forEach(gpKey => {
    const _groupedPlaces = []
    const groupedPlace = groupedPlaces[gpKey]
    const _item = _.find(action.items, { name: gpKey })
    if (_item && _item[additionalProp]) {
      groupedPlace.forEach(gp => {
        gp[additionalProp] = _item[additionalProp]
        gp.distanceFromMe = getDistance(state.appUserLocation, gp)
        _groupedPlaces.push(gp)
      })
    }
    if (showClosestItemOnly) {
      const minPlace = getClosestPlace(_groupedPlaces)
      groupedPlaces[gpKey] = minPlace ? [minPlace] : []
    }
  })
}

const getPlacesFromGroupedPlaces = (groupedPlaces) => {
  const _places = []
  Object.keys(groupedPlaces).forEach(gpKey => {
    const groupedPlace = groupedPlaces[gpKey]
    groupedPlace.forEach(gp => {
      _places.push(gp)
    })
  })
  return _places
}

const getMarkerInfo = (selectedMarker) => {

  const { mapPosition, geometry, name, vicinity } = selectedMarker ? selectedMarker : {}
  let _position;

  if (mapPosition && mapPosition.lat && mapPosition.lng) {
    _position = {
      lat: mapPosition.lat,
      lng: mapPosition.lng,
    }
  } else {
    const { location } = geometry ? geometry : {}
    const { lat, lng } = location ? location : {}
    if (lat && lng) {
      _position = {
        lat: lat(),
        lng: lng(),
      }
    }
  }
  return { position: _position, name, vicinity }
}

const getClosestPlace = (places) => {
  let minDistance = 100000
  let minPlace;
  places.forEach(place => {
    if (place.distanceFromMe < minDistance) {
      minPlace = place
      minDistance = place.distanceFromMe
    }
  })
  return minPlace
}

const getAllPlaces = (itemsToMap, state, callback) => {

  const groupedPlaces = {}

  const cb = (places, name, index) => {
    groupedPlaces[name] = places
    if (Object.keys(itemsToMap).length === Object.keys(groupedPlaces).length) {
      callback(groupedPlaces, itemsToMap)
    }
  }

  if (itemsToMap) {
    const names = []
    Object.keys(itemsToMap).forEach(key => {
      names.push(itemsToMap[key].name)
    })

    names.forEach((name, index) => {
      getPlaces(name, state, cb, index)
    })
  }
}

const getPlaces = (name, state, callback, index) => {

  const { google, service, dMargin, appUserLocation } = state

  // const c = getBounds(google)
  const cb = (results, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const _places = []
      for (var i = 0; i < results.length; i++) {
        var place = results[i];
        _places.push(place)
      }
      _places.forEach(place => {
        place.latitude = place.geometry.location.lat()
        place.longitude = place.geometry.location.lng()
      })
      callback(_places, name, index)
    } else {
      callback([], name, index)
    }
  }

  if (service && appUserLocation) {
    const latLng = new google.maps.LatLng(appUserLocation.latitude, appUserLocation.longitude);
    const request = {
      location: latLng,
      // bounds: c.bounds,
      radius: dMargin,
      keyword: [name]
    }
    service.nearbySearch(request, cb);
  }

}

const ammendLocations = (props) => {

  const { getGviDeps, paps_state, viewListData, mapLists, locationsOf, displayProps } = props

  const { pathViews } = paps_state
  const { props_viewItem } = displayProps ? displayProps : {}

  let elemPropType = null

  const listOfProps = _.filter(props_viewItem, 'listOf')

  if (listOfProps && listOfProps.length === 1) {
    const listOfProp = listOfProps[0]
    findLocations(viewListData, mapLists, listOfProp)
  }

  if (viewListData) {
    Object.keys(viewListData).forEach((key, index) => {
      if (viewListData[key].googleMaps) {
        const gm = viewListData[key].googleMaps
        var regex = new RegExp('@(.*),(.*),');
        var lon_lat_match = gm.match(regex);
        var lat = lon_lat_match[1];
        var lng = lon_lat_match[2];
        viewListData[key].mapPosition = {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        }
      }
    })
  }

  let mld = _.filter(viewListData, 'mapPosition')

  if (locationsOf) {
    let locationsOfProp = _.find(Object.values(props_viewItem), function (o) { return o['prop'] === 'locationsOf'; })
    if (locationsOfProp) { elemPropType = locationsOfProp.elemPropType }
    let locationListsData = []
    locationsOf.forEach(location => {
      const deps = getGviDeps(location)
      const locationListData = this.props.fsGetRawData(location, pathViews, deps)
      if (locationListData) {
        locationListsData = [...locationListsData, ...locationListData]
      } else {

      }
    })
    mld = locationListsData
  }

  if (mld) { mld = _.sortBy(mld, 'name') }

  return { mld, elemPropType }
}

const getBounds = (google, mapListData, markers) => {

  const _bounds = new google.maps.LatLngBounds();

  if (mapListData) {
    mapListData.forEach(itemData => {
      if (itemData.mapPosition) {
        const { lat, lng } = itemData.mapPosition
        if (lat && lng) {
          _bounds.extend(itemData.mapPosition);
        }
      }
    })
  }

  const center = _bounds.getCenter()

  return {
    bounds: _bounds,
    initialCenter: {
      lat: center.lat(),
      lng: center.lng()
    }
  }
}

const getRadius = (google) => {

  const _bounds = new google.maps.LatLngBounds();

  const center = _bounds.getCenter();
  const ne = _bounds.getNorthEast();

  // r = radius of the earth in statute miles
  const r = 3963.0;
  const db = 57.2958

  // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
  const lat1 = center.lat() / db;
  const lon1 = center.lng() / db;
  const lat2 = ne.lat() / db;
  const lon2 = ne.lng() / db;

  // distance = circle radius from center to Northeast corner of bounds
  const dis = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));

  return dis
} 