import React from 'react';
import { Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';

const TableList = (props) => {

  const { sectionSemElements, snc } = props

  const divs = sectionSemElements.map(sse => (sse))

  return (
    <div key={uniqueKey('tl', 'd')} {...snc}>
      {divs && divs.length > 0 && divs}
      {divs && divs.length === 0 && <Segment basic className={'nv'}>No Information</Segment>}
    </div>
  )
}

export default TableList
