import React, { createContext, useContext, useReducer } from 'react';
import { firestoreHandlers, firestoreInitialState, firestoreReducer } from '../../cnr/reducers/FirestoreReducer';
import { ParentContext } from './ParentContext';

export const FirestoreContext = createContext();

const FirestoreProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appSettings_state, appUser_state, page_state, paps_state, eventInfo_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { dataRestrictions } = aps_global ? aps_global : {}
  const { page_fns } = fns

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}

  // eventInfoContext 
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  const init_state = firestoreInitialState(paps_state, staticViews, page_fns, appUser, appSettings_state, dataRestrictions)
  const [firestore_state, dispatch] = useReducer(firestoreReducer, init_state);
  const firestore_handlers = firestoreHandlers(dispatch)

  return (
    <FirestoreContext.Provider value={{ firestore_handlers, firestore_state }}>
      {props.children}
    </FirestoreContext.Provider>
  )
}

export default FirestoreProvider