import React, { useEffect } from 'react'
import { Button, Dimmer, Header, Icon, List, Loader, Segment } from 'semantic-ui-react'
import { dispatchConfirmationTypes, dispatchProps } from '../../cnr/reducers/reducerHelpers/dispatchProps'
import { startTypes } from '../../cnr/reducers/StartReducer'
import CircleLoader from '../../motions/CircleLoader'

const WAIT_INTERVAL = 1000

export const DimmerPending = () => <Dimmer active inverted>
  <Loader />
</Dimmer>

export const DimmerWrap = (props) => {
  const { caption, confirmation, confirmationNoModal } = props
  return confirmation ?
    <DimmerConfirmation confirmation={confirmation} confirmationNoModal={confirmationNoModal} />
    :
    <Dimmer active>
      <CircleLoader startType={startTypes.suspense} caption={caption} />
    </Dimmer>
}

/**
 * 
 * @param {object} props (confirmation, confirmationNoModal)
 * @returns 
 */
const DimmerConfirmation = (props) => {

  const { confirmation, confirmationNoModal } = props
  const { content, success, contentAlt, error, opts } = confirmation ? confirmation : {}
  const { message } = error ? error : {}
  const { onClose, dispatch, contentItems, contentAlt: contentAlt_opts, dispatchConfirmationType } = opts ? opts : {}

  const _content = contentAlt_opts ? contentAlt_opts : content

  let _iconName = success ? 'check circle' : 'ban'
  let _iconColor = success ? 'green' : 'red'

  if (contentAlt) {
    _iconName = 'bell'
    _iconColor = 'orange'
  }

  const handleClose = () => {
    if (dispatch) {
      onClose && onClose(dispatch)
      dispatch && (dispatchConfirmationType === dispatchConfirmationTypes.closeAfterConfirmation) && dispatch({ type: dispatchProps.closeConfirmation, dispatch })
    }
  }

  const handleCloseClick = () => dispatch({ type: dispatchProps.closeConfirmation, dispatch })

  useEffect(() => {
    setTimeout(() => !contentAlt && handleClose(), WAIT_INTERVAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const divItem = (h, as) => <div inverted>{h}</div>
  const button = (caption, oc) => <Button size={'small'} color={'blue'} onClick={() => { oc() }}>{caption}</Button>

  const list = () => {
    const lis = Object.keys(contentItems).map(key => <List.Item>{key}{': '}{contentItems[key]}</List.Item>)
    return <List>{lis}</List>
  }

  const confirmationContent = () => <Segment placeholder inverted basic className={'dimmer-seg'}>
    <Header icon as='h4'>
      <Icon name={_iconName} color={_iconColor} />
      <Header.Content>{_content}</Header.Content>
    </Header>
    <Segment basic inverted>
      {contentAlt && divItem(contentAlt, 'h5')}
      {message && divItem(message, 'h5')}
      {contentAlt && button('OK', handleClose)}
      {dispatchConfirmationType === dispatchConfirmationTypes.closeAfterConfirmationClick && button('OK', handleCloseClick)}
      {contentItems && list()}
    </Segment>
  </Segment>

  return confirmationNoModal ? confirmationContent() :
    <Dimmer active onClick={() => { handleClose() }}>
      {confirmationContent()}
    </Dimmer>
}