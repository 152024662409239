import { arrayRemove, arrayUnion } from 'firebase/firestore'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { Card, Icon, Label, List, Segment } from 'semantic-ui-react'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'
import { uniqueKey } from '../../common/keys'
import { getItemName } from '../../common/convert'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { UiItemContext } from '../../cnr/contexts/UiItemContext'
import { appIconTypes } from '../../enums/appIconTypes'
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths'
import { fs_update_doc } from '../../firestoreData/appData/fsData'
import UiSaveButtons from '../../components/buttons/UiSaveButtons'
import FullPageWrapper from '../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import NoData from '../alerts/NoData'

const alertCount = 2000

const UserConnections = (props) => {

  const { modifyActionType } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { handlers, states } = parentContext ? parentContext : {}
  const { appUser_state, eventInfo_state, page_state, paps_state } = states ? states : {}
  const { navigate } = handlers
  const { pageSettings } = page_state ? page_state : {}
  const { aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { appUserSession } = appUserAccess ? appUserAccess : {}
  const { _connections: appUserConnections, _itemKey } = appUserSession ? appUserSession : {}

  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  const { pathViews } = paps_state

  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}

  const [connections] = useState(appUserConnections ? appUserConnections : [])
  const [aupConnections, setAupConnections] = useState()
  const [showScanner, setShowScanner] = useState()
  const [showMessage, setShowMessage] = useState()
  const [scannedAppUser, setScannedAppUser] = useState()

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage()
      }, alertCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [showMessage]);

  useEffect(() => {
    const sv = staticViews ? staticViews[appUserCollection] : null
    const aucs = {}
    if (appUserConnections) {
      appUserConnections.forEach(auc => {
        if (sv[auc]) {
          aucs[auc] = sv[auc]
        }
      })
    }
    setAupConnections(aucs)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleCancel = () => props.handleCancel ? props.handleCancel() : navigate(-1)
  const handleError = err => console.error(err)
  const handleShowScanner = () => setShowScanner(!showScanner)

  const handleScan = (data) => {
    if (data) {
      const _scannedData = JSON.parse(data)
      if (_scannedData[appUserCollection]) {
        const connectionKey = _scannedData[appUserCollection]
        handleDatabaseUpdate_connection(connectionKey)
      }
    }
  }

  const handleSetScannedAppUser = (listItem) => setScannedAppUser(listItem)

  const handleDatabaseUpdate_connection = (connectionKey) => {
    const _ref = createRefPath_event(pathViews, [appUserCollection, _itemKey])
    let _dataToUpdate;
    if (connections.includes(connectionKey)) {
      _dataToUpdate = { _connections: arrayRemove(connectionKey) }
    } else {
      _dataToUpdate = { _connections: arrayUnion(connectionKey) }
    }
    fs_update_doc(_ref, _dataToUpdate).then(res => {
      setShowScanner(false)
    })
  }

  const handleConnection = (e, listItem) => {
    e && e.stopPropagation()
    const { _itemKey: connectionKey } = listItem
    handleDatabaseUpdate_connection(connectionKey)
  }

  const handleConnect = () => {
    const { viewKey } = item_state
    handleDatabaseUpdate_connection(viewKey)
    // const { view, dataItem } = item_state
    // appUser_handlers.handleConnectToAppUser(view, dataItem)
    // item_handlers.handleShowItemSidebar()
  }

  /** The reader */
  const qrReader = () => <QrReader
    delay={300}
    onError={handleError}
    onResult={handleScan}
    style={{ width: '100%' }}
    className="test"
  />

  const connectionsList = () => {
    let list = aupConnections ? _.orderBy(aupConnections, ['name', 'lastName']) : null
    const listItems = []
    if (list) {
      list.forEach((listItem, index) => {
        const { _itemKey } = listItem
        const name = getItemName(listItem)
        listItems.push(<List.Item key={uniqueKey('aul', _itemKey)} onClick={(e) => { handleSetScannedAppUser(listItem) }}>
          <List.Content floated='left'>
            <Label color={'green'} size='tiny'>{index + 1}</Label>
          </List.Content>
          <List.Content>
            <List.Header>{name} </List.Header>
          </List.Content>
        </List.Item>)
      })
    }
    return listItems.length > 0 ? <Segment basic>
      <List divided relaxed>
        {listItems}
      </List>
    </Segment> :
      <NoData altCaption={'Connections'} />
  }

  const connectionCard = () => {
    const { _checkedIn } = scannedAppUser
    const _name = getItemName(scannedAppUser)
    return <Card fluid>
      <Card.Content>
        <Card.Header>{_name}</Card.Header>
        <Card.Description>
          <Icon name={'check circle'} color={_checkedIn ? 'green' : 'grey'} />
          <span>Checked In</span>
        </Card.Description>
      </Card.Content>
    </Card >
  }

  const connectionContent = () => {
    if (scannedAppUser) {
      return connectionCard()
    } else {
      return connectionsList()
    }
  }

  const content = () => {
    if (showScanner) {
      return qrReader()
    } else {
      return connectionContent()
    }
  }

  // const readerContent = () => <div className={'cred-scanner'}>
  //   {content()}
  // </div>

  const footer = () => {
    if (scannedAppUser) {
      const { _checkedIn } = scannedAppUser
      return <UiSaveButtons
        save={{ caption: 'In', oc: handleConnection, icon: 'arrow circle up', disabled: _checkedIn }}
        preview={{ caption: 'Out', icon: 'arrow circle down', oc: handleConnection, disabled: !_checkedIn }}
      />
    } else {
      return <UiSaveButtons
        save={{ caption: showScanner ? 'Turn Off' : 'Scan', oc: handleShowScanner, icon: showScanner ? 'delete' : 'check' }}
      />
    }
  }

  /** wrapper for the reader */
  const wrapperReader = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  />


  const appUserWrapper = () => <Wrapper
    content={_.startCase(modifyActionType) + '?'}
    footer={<UiSaveButtons save={{ oc: handleConnect, caption: 'OK', icon: appIconTypes.check }} />}
    wrapperType={wrapperTypes.padded}
  />

  const appUser_fpw = () => <FullPageWrapper
    content={appUserWrapper()}
    topperCaption={_.startCase(modifyActionType)}
    handleCancel={handleCancel}
  />

  /** Wizard for the reader */
  const scan_fpw = () => <FullPageWrapper
    content={wrapperReader()}
    handleCancel={handleCancel}
    topperCaption={'Connections'}
  />

  if (modifyActionType) {
    return appUser_fpw()
  } else {
    return showScanner ? scan_fpw() : wrapperReader()
  }
}

export default UserConnections