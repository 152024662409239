
import { allPageTypes } from '../../../enums/allPageTypes';
import { getThemeColorDirect } from '../../../styles/formatting';
import { gridItemIconGroup } from './SegIconGroup';

export const gridItemsAppUser = (props) => {

  const { parentContext, pageIcons, gcProps, allows, _appUserSessionKey } = props

  const { states } = parentContext ? parentContext : {}
  const { appNotifications_state, page_state } = states

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appNotifications, appSurvey, appVoting } = aps_global ? aps_global : {}
  const { allowNotifications, showNotificationsOnHomeScreen, showNotificationsCount, notificationLabelColor } = appNotifications ? appNotifications : {}
  const { allowChat, showChatOnHomeScreen, allowNotes, showNotesOnHomeScreen, showFavoritesOnHomeScreen } = aps_appUserSettings ? aps_appUserSettings : {}
  const { allowVoting, showVotingOnHomeScreen, votingHomeScreenCaption } = appVoting ? appVoting : {}
  const { allowSurvey, showSurveyOnHomeScreen, surveyIsActive, surveyHomeScreenCaption, surveyUrl } = appSurvey ? appSurvey : {}

  const { notifications_appUser } = appNotifications_state ? appNotifications_state : {}
  const { unread: notificatiions_unread, hasNew } = notifications_appUser ? notifications_appUser : {}


  let _allowSurvey;

  let allow = !pageIcons && allowSurvey && showSurveyOnHomeScreen && surveyIsActive && surveyUrl
  if (allow && allows.asr) { _allowSurvey = true }

  const notificationProps = {
    text: showNotificationsCount && hasNew && notificatiions_unread && notificatiions_unread.length > 0 ? notificatiions_unread.length : null,
    color: notificationLabelColor ? getThemeColorDirect(aps_global, notificationLabelColor) : null
  }

  const gridItem = (viewItem, segProps, gcProps) => gridItemIconGroup(viewItem, segProps, gcProps)

  const favoritesGridItem = (items) => {
    const giProps = {
      caption: 'Favorites',
      icon: 'star outline',
    }
    items.push(gridItem({ key: 'favorites', itemKey: _appUserSessionKey }, giProps, gcProps))
  }

  const items = []

  if (!pageIcons) {
    if (allowNotifications && showNotificationsOnHomeScreen) { items.push(gridItem({ key: allPageTypes.notifications }, { caption: 'Notifications', icon: 'alarm', notification: notificationProps.text ? notificationProps : null }, gcProps)) }
    if (allowChat && showChatOnHomeScreen) { items.push(gridItem({ key: allPageTypes.chat }, { caption: 'Chat', icon: 'conversation', }, gcProps)) }
    if (showFavoritesOnHomeScreen) { favoritesGridItem(items) }
    if (allowNotes && showNotesOnHomeScreen) { items.push(gridItem({ key: allPageTypes.notes }, { caption: 'Notes', icon: 'edit outline' }, gcProps)) }
    if (_allowSurvey) { items.push(gridItem({ key: allPageTypes.survey }, { caption: surveyHomeScreenCaption ? surveyHomeScreenCaption : 'Survey', icon: 'list ol' }, gcProps)) }
    if (allowVoting && showVotingOnHomeScreen) { items.push(gridItem({ key: allPageTypes.voting }, { caption: votingHomeScreenCaption ? votingHomeScreenCaption : 'Voting', icon: 'check circle outline' }, gcProps)) }
  }

  return items

}