import { updateNotificationsDirect } from '../firestoreData/notifications/notificationsData';
import { callFunction, callFunctionDirect } from "./fbAll";

export const _allowNotificationSend = true

const notificationGenericTypes = {
  notificationGeneric: 'notificationGeneric',
}

const notificationFunctionTypes = {
  addTopicGroups: 'addTopicGroups',
  addTopicToFcmTokens: 'addTopicToFcmTokens',
  getClientTopics: 'getClientTopics',
  sendCustomEmail: 'sendCustomEmail',
  sendNotificationByData: 'sendNotificationByData',
  sendScheduledNotifications_gcs_direct: 'sendScheduledNotifications_gcs_direct',
  sendSms: 'sendSms',
  sendGenericPush: 'sendGenericPush',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(notificationGenericTypes.notificationGeneric, callData)
  return data
}

/**
 * 
 * @param {object} data  
 */
export const fsfn_checkNotification_direct = async (testOnly) => {
  try {
    const callData = { testOnly: testOnly, showLogs: true }
    await callFunctionDirect('checkNotification_direct', callData)
  } catch (error) {
    console.error('sendNotification_hourly - error', error)
  }
}

// {
//   "project": {
//     "id": "card-nudge"
//   },
//   "sendgrid": {
//     "key": "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"
//   }
// }

// {
//   "googleapi": {
//     "client_secret": "A-fjGmOYe_kqx8Lx8QoAgHbp",
//     "client_id": "79555447917-sajgbk51dt16nrm71isein75v2th6vh5.apps.googleusercontent.com"
//   },
//   "sendgrid": {
//     "key": "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"
//   },
//   "someservice": {
//     "key": "apikey"
//   }
// }

// kJy



/**
 * 
 * @param {object} data  
 */
export const fsfn_sendScheduledNotifications_gcs_direct = async () => {
  try {
    const data = {
      notificationFunctionType: notificationFunctionTypes.sendScheduledNotifications_gcs_direct,
      showLogs: true
    }
    console.log('data', data)
    const response_data = await callFsFunction(data) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error('fsfn_getClientTopics - error', error)
  }
}
// clients_x8zNuZFocZCdZ6f6LNYF_events_jPY5DOY0554LcKnk57Ao
// clients_x8zNuZFocZCdZ6f6LNYF_events_jPY5DOY0554LcKnk57Ao_customers_kJyU8einbRTCOxAz5ngl
/**
 * 
 * @param {object} data 
 * @deprecated
 */
export const fsfn_getClientTopics = async (data) => {
  try {
    data.notificationFunctionType = notificationFunctionTypes.getClientTopics
    const response_data = await callFsFunction(data) // OK
    return response_data
  } catch (error) {
    console.error('fsfn_getClientTopics - error', error)
  }
}

/** sends a notifcation to the appUsers tokens
 * {notificationData, appUserAccess, paps_state, callback: handleShowNormal} */
export const sendNotificationToSelf = async (props) => {
  const { notificationData, appUserAccess, pathViews, callback, logging } = props
  return fsfn_sendNotificationByData(notificationData, pathViews, appUserAccess.fcmTokens, null, logging, callback)
}

/** sends a notifcation to the given tokens
 * {notificationData, appUserAccess, paps_state, callback: handleShowNormal} */
/**
 * 
 * @param {object} notificationData 
 * @param {string} pathViews 
 * @param {array} tokens 
 * @param {function} callback 
 * @param {object} logging 
 * @param {function} callbackSelf 
 * @description sends a notifcation to the given tokens
 * @callback - sends a notifcation to the given tokens
 */
export const fsfn_sendNotificationByData = async (notificationData, pathViews, tokens, callback, logging, callbackSelf) => {
  try {

    const callData = {
      notificationFunctionType: notificationFunctionTypes.sendGenericPush,
      token: tokens[0],
      notificationType: 'notification',
      notification: { ...notificationData },
      pathViews,
      tokens,
      showLogs: logging && logging.allowLogging && logging.logNotifications
    }
    if (_allowNotificationSend) {
      console.log('callData', callData)
      const response_data = await callFsFunction(callData) // OK
      callback && updateNotificationsDirect(pathViews, notificationData, response_data, callback)
      console.log('callData', callData)
      callbackSelf && callbackSelf()
      return response_data
    } else {
      console.log('NS - fsfn_sendNotificationByData', callData)
    }
  } catch (error) {
    console.error('#fsfn_sendNotificationByData - error', error)
  }
}

/**
 * 
 * @param {object} notificationData 
 * @returns response_data {success, result, error}
 */
export const fsfn_sendEmail = async (notificationData) => {
  try {
    console.log('fsfn_sendEmail', notificationData)
    notificationData.notificationFunctionType = notificationFunctionTypes.sendCustomEmail
    const response_data = await callFsFunction(notificationData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} data 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
export const fsfn_sendSms = async (data, logging) => {
  try {
    const { to, text } = data
    const sendData = { notificationFunctionType: notificationFunctionTypes.sendSms, to: to, text, showLogs: logging && logging.allowLogging && logging.logSms }
    const response_data = await callFsFunction(sendData) // OK  
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} appProfileData 
 * @param {boolean} unsubscribe 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
export const fsfn_addTopicGroups = async (appProfileData, unsubscribe, logging) => {
  try {
    const data = { notificationFunctionType: notificationFunctionTypes.addTopicGroups, appProfileData, unsubscribe, showLogs: logging && logging.allowLogging && logging.logNotificationTopics }
    const response_data = await callFsFunction(data) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * Add the fcmTokens in to the topic
 * @param {array} fcmTokens 
 * @param {string} topic 
 * @param {boolean} unsubscribe 
 * @returns response_data {success, result, error}
 */
export const fsfn_addTopicToFcmTokens = async (fcmTokens, topic, unsubscribe) => {
  try {
    const data = { notificationFunctionType: notificationFunctionTypes.addTopicToFcmTokens, fcmTokens, topic, unsubscribe }
    const response_data = await callFsFunction(data) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/** Sends a notification to a topic */
export const sendNotification_topic = async (notificationData, appUserAccess, paps_state) => {
  // const { rootPaths } = paps_state ? paps_state : {}
  // const { events } = rootPaths ? rootPaths : {}
  // let topicPath = events ? events.replace(/\//g, '_') : null
  // const { topicOriginGroup, topicOriginGroupKey } = notificationData
  // topicPath += topicOriginGroup + '_' + topicOriginGroupKey
  // const sendProps = appUserAccess ? { profileId: appUserAccess.profileId } : {}
  // const _notificationData = getNotificationData(notificationTypes.notification, {}, { topic: topicPath }, { title, body })
  // const result = await fsfn_sendNotification(sendProps, _notificationData, pathViews)
  // return result
}
