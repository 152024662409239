import { getDownloadURL, getStorage, list, ref } from "firebase/storage";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { manifestSizes } from "../../components/imaging/createManifestIcons";
import { ammendManifestSettings } from "../../manifest/updateManifestSettings";
import { PapsContext } from './PapsContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from "./SettingsDataContext";

const _showState = false

/** 
@state manifest_state(`manifestIcons`, `startIcon`)
@functions manifest_fns (`getManifestUrl`)
@description Returns the `manifest icons` from storage base on the `storageManifestPath`
 */
export const ManifestContext = createContext();

/** Returns the `manifest icons` from storage base on the `storageManifestPath` */
const ManifestProvider = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { settingsDocName, pathViews } = paps_state ? paps_state : {}

  const homeSettingsContext = useContext(HomeSettingsContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const eventSettingsContext = useContext(EventSettingsContext);

  const { homeSettings_state } = homeSettingsContext ? homeSettingsContext : {}
  const { homeSettings } = homeSettings_state ? homeSettings_state : {}

  const { clientSettings_state } = clientSettingsContext ? clientSettingsContext : {}
  const { clientSettings } = clientSettings_state ? clientSettings_state : {}

  const { eventSettings_state } = eventSettingsContext ? eventSettingsContext : {}
  const { eventSettings } = eventSettings_state ? eventSettings_state : {}

  const _hceSettings = {
    events: eventSettings,
    clients: clientSettings,
    home: homeSettings,
  }

  // local state
  const [manifestIcons, setManifestIcons] = useState()
  const [manifestReady, setManifestReady] = useState()
  const [startIcon, setStartIcon] = useState()
  const [trueGlobal, setTrueGlobal] = useState()

  const manifest_state = { manifestIcons, startIcon }

  const manifest_fns = {
    getManifestUrl: (size) => {
      return manifestIcons && manifestIcons['manifest_' + size] ? manifestIcons['manifest_' + size].fileUrl : null
    }
  }

  // const getManifestIcons = async (storageRef, callback) => {
  //   console.log('getManifestIcons')
  //   const xxx = await list(storageRef, { maxResults: 8 })
  //   console.log('xxx', xxx)
  // }

  const getManifestIcons = (storageRef, callback) => {
    list(storageRef, { maxResults: 8 }).then(function (res) {
      manifestPromise(res).then(result => {
        const mis = {}
        let startI;
        result.forEach((r, index) => {
          mis['manifest_' + index] = {
            size: manifestSizes[index],
            fileUrl: r
          }
          if (index === 0) {
            startI = {
              size: manifestSizes[index],
              fileUrl: r
            }
          }
        })
        callback(mis)
        setStartIcon(startI ? startI : {})
      }).catch(error => {
        console.error(error)
        callback({})
        setStartIcon({})
      })
    }).catch(function (error) {
      console.error(error)
      callback({})
      setStartIcon({})
      // Uh-oh, an error occurred!
    });
  }

  if (_showState) {
    console.log(Object.keys(manifest_state).sort())
    console.log(Object.keys(manifest_fns).sort())
  }

  useEffect(
    () => {
      let _settings;
      let _useClient;
      if (pathViews.events && _hceSettings.events) {
        const { settings, useClient } = getTrueSettings(_hceSettings)
        _settings = settings
        _useClient = useClient
      } else if (pathViews.clients && _hceSettings.clients) {
        _settings = _hceSettings.clients
      } else {
        _settings = _hceSettings.home
      }
      let smp2 = getManifestPath(pathViews, _useClient)
      try {
        const storage = getStorage()
        const storageRef = ref(storage, smp2)
        setTrueGlobal(_settings.global)
        try {
          getManifestIcons(storageRef, setManifestIcons)
        } catch (error) {
          console.log('e', error)
        }
      } catch (error) {
        console.error('manifest', error)
      }
    },
    // eslint-disable-next-line
    [settingsDocName]
  )

  useEffect(
    () => {
      if (manifestIcons && trueGlobal) {
        ammendManifestSettings(trueGlobal, { manifestIcons }, setManifestReady)
      }
    },
    // eslint-disable-next-line
    [trueGlobal, manifestIcons]
  )

  const providerContext = () => <ManifestContext.Provider value={{ manifest_state, manifest_fns }}>
    {props.children}
  </ManifestContext.Provider>

  return manifestReady ? providerContext() : <div></div>

}

export default ManifestProvider

const manifestPromise = async (res) => {

  const { items } = res ? res : {}

  const promises = []

  items.forEach(function (itemRef) {
    const { _location } = itemRef
    const { path_ } = _location
    const storage = getStorage()
    const iconRef = ref(storage, path_)
    promises.push(getDownloadURL(iconRef))
  });

  return Promise.all(promises)

}

const getTrueSettings = (hceSettings) => {
  const { clients: settings_client, events: settings_event } = hceSettings
  const { global: global_client } = settings_client ? settings_client : {}
  const { global: global_event } = settings_event ? settings_event : {}
  const { appSettings: appSettings_client } = global_client ? global_client : {}
  const { parentDefaults } = global_event ? global_event : {}
  const { useClientManifest } = appSettings_client ? appSettings_client : {}
  const { useClientManifestIcon } = parentDefaults ? parentDefaults : {}
  if (useClientManifest || useClientManifestIcon) {
    return { settings: settings_client, useClient: true }
  } else {
    return { settings: settings_event, useClient: false }
  }
}

export const getManifestPath = (pathViews, useClient) => {
  let mp = 'clients/' + pathViews.clients
  if (pathViews.events && !useClient) {
    mp += '/events/' + pathViews.events + '/manifests'
  } else if (pathViews.clients) {
    mp += '/manifests'
  }
  return mp
}