import React, { useContext, useEffect, useState } from "react";
import { Image } from 'semantic-ui-react';
import { ParentContext } from "./global/cnr/contexts/ParentContext";
import { gEnums } from './global/enums/globalEnums';
import UiSaveButtons from "./global/components/buttons/UiSaveButtons";
import FullPageWrapper from "./global/wrappers/FullPageWrapper";
import Wrapper, { wrapperTypes } from "./global/wrappers/Wrapper";
import { renderHtml } from "./global/components/html/renderHtml";

export const installAppTypes = {
  auto: 'auto',
  help: 'help',
  manual: 'manual',
}

const WAIT_INTERVAL = 15000

export const AddToHomeScreen = (props) => {

  const { installAppType, forceInstall, handleCancel } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ? parentContext : {}
  const { paps_state, page_state, manifest_state, storage_state } = states ? states : {}
  const { appUser_handlers, appSettings_handlers } = handlers ? handlers : {}
  const { pathViews, device } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { homeSettings } = settings ? settings : {}
  const { appInstallation, topMenu } = aps_global ? aps_global : {}
  const { mainFixedCaption } = topMenu ? topMenu : {}
  const { addToHomeScreenText, waitInterval, showInstallationPdf, installationPdf } = appInstallation ? appInstallation : {}
  const { startIcon } = manifest_state ? manifest_state : {}
  const { fileUrl } = startIcon ? startIcon : {}

  if (device.isAndroid) {
    device.name = gEnums.deviceNames.android
  } else if (device.isApple) {
    device.name = gEnums.deviceNames.apple
  }

  const { global: global_home } = homeSettings ? homeSettings : {}
  const { globalAppSettings } = global_home ? global_home : {}
  const { pwaSubTitle, pwaDescription } = globalAppSettings ? globalAppSettings : {}

  const _addToHomeScreenText = addToHomeScreenText ? addToHomeScreenText : 'Would you like to add `' + mainFixedCaption + '` to your homescreen?'
  const _waitInterval = waitInterval ? waitInterval * 1000 : WAIT_INTERVAL

  const addToHomeScreenItem = eventKey ? 'appInstallation-' + eventKey : 'appInstallation'

  const [allow, setAllow] = useState()
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [activeSnC, setActiveSnC] = useState()
  const [helpUrl, setHelpUrl] = useState()

  const handleShow = () => { }

  useEffect(() => {
    switch (installAppType) {
      case installAppTypes.auto:
        const timer = setTimeout(() => setAllow(true), _waitInterval);
        return () => clearTimeout(timer);
      case installAppTypes.help:
      case installAppTypes.manual:
        setAllow(true)
        break;
      default:
        break;
    }
    // return () => window.removeEventListener("transitionend", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { globalFiles } = storage_state ? storage_state : {}
    const { pdf } = globalFiles ? globalFiles : {}
    const _helpPdf = pdf && pdf[installationPdf]
    const { urls } = _helpPdf ? _helpPdf : {}
    const { full } = urls ? urls : {}
    setHelpUrl(full)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // useEffect(() => {
  //   const _activeSnC =  aps_styles ? aps_styles[gEnums.projectStyles.appInstallation] : {}  
  //   _activeSnC.className += ' fp'
  //   if (_deferredPrompt && installAppType !== installAppTypes.help) {
  //     window.addEventListener("beforeinstallprompt", _deferredPrompt);
  //     setSupportsPWA(allowManualAppInstallationFromApp);
  //     setPromptInstall(_deferredPrompt);
  //     setActiveSnC(_activeSnC)
  //   } else {
  //     _activeSnC.className += ' zz'
  //     setActiveSnC(_activeSnC)
  //   }
  //   // return () => window.removeEventListener("transitionend", handler);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps 
  // }, [_deferredPrompt]);

  const handleShowHelpPdf = () => window.open(helpUrl, '_blank')

  const handleClickYes = () => {
    // _deferredPrompt.preventDefault();
    if (!promptInstall) { return; }
    promptInstall.prompt();
    promptInstall.userChoice
      .then(choice => {
        if (choice.outcome === 'accepted') {
          window.localStorage.setItem(addToHomeScreenItem, true)
          appUser_handlers.handleAddToHomePageResponse(addToHomeScreenItem)
        } else {
          if (!handleCancel) {
            window.localStorage.setItem(addToHomeScreenItem, true)
            appUser_handlers.handleAddToHomePageResponse(addToHomeScreenItem)
          }
        }
      })
  };

  const handleClickNo = () => {
    switch (installAppType) {
      case installAppTypes.help:
        appSettings_handlers.handleShowHomeScreenHelp()
        break;
      default:
        window.localStorage.setItem(addToHomeScreenItem, true)
        appUser_handlers.handleAddToHomePageResponse(addToHomeScreenItem)
    }
  };

  const manIcon = () => <Image src={fileUrl} size={'tiny'} />

  const wrapperContent = () => <div {...activeSnC}>
    <div>{fileUrl && manIcon()}</div>
    <div>{_addToHomeScreenText}</div>
  </div>

  const wrapperNoSupport = () => {
    const _pwaTitle = pwaSubTitle ? pwaSubTitle : 'This device does not allow this app to be loaded automatically.'
    const _pwaDescription = pwaDescription ? pwaDescription : 'For apple devices, click the share button on the Safari menu and scroll to find `Add To Home Screen` – select that and follow the prompts.'
    return <div {...activeSnC}>
      <div>{fileUrl && manIcon()}</div>
      <div>{renderHtml(_pwaTitle)}</div>
      <div>{renderHtml(_pwaDescription)}</div>
    </div>
  }

  const wrapper = () => <Wrapper
    content={supportsPWA ? wrapperContent() : wrapperNoSupport()}
    footer={(supportsPWA || showInstallationPdf) && <UiSaveButtons
      preview={showInstallationPdf ? { oc: handleShowHelpPdf, caption: 'Help', cn: 'btn-help', icon: 'help' } : null}
      save={installAppType !== installAppTypes.help && supportsPWA ? { oc: handleClickYes, caption: 'Yes', cn: 'btn-homepage', icon: 'check' } : null}
    />}
    wrapperType={wrapperTypes.padded}
  >
  </Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleClickNo}
    topperCaption={'Add To Home Screen'}
    halfModal={installAppType === installAppTypes.auto}
  />

  if (allow) {
    return fpw()
  } else {
    return <div></div>
  }
}