const globalIconTypes = {
  activate: 'bolt',
  add: 'add circle',
  appUserGallery: 'appUserGallery',
  appUserFamily: 'appUserFamily',
  appUserProfileImage: 'picture',
  arrowLeft: 'arrow left',
  arrowRight: 'arrow right',
  arrowRightCircled: 'arrow alternate circle right outline',
  bars: 'bars',
  calendar: 'calendar outline',
  cancel: 'delete',
  chat: 'chat',
  check: 'check',
  clock: 'clock',
  closeWindow: 'window close outline',
  cog: 'cog',
  comment: 'comment alternate outline',
  content: 'content',
  dataLinking: 'linkify',
  delete: 'delete',
  discussions: 'chat',
  edit: 'edit',
  ellipsisVertical: 'ellipsis vertical',
  externalAlternate: 'external alternate',
  facebook: 'facebook',
  family: 'users',
  file: 'file text outline',
  find: 'find',
  gallery: 'images outline',
  generic: 'warehouse',
  google: 'google',
  googleMap: 'map',
  googleSheetsOn: 'google blink_me_2',
  googlePreview: 'google plus square',
  googleDrive: 'google drive',
  googlePlus: 'google plus',
  googlePlusG: 'google plus g',
  googleSquare: 'google plus square',
  hamburger: 'content ',
  help: 'help',
  home: 'home',
  homeSettings: 'homeSettings',
  homeMain: 'warehouse',
  image: 'image outline',
  images: 'picture',
  info: 'info circle',
  linkedIn: 'linkedin',
  list: 'list',
  // locationSettings: 'alarm',
  mail: 'mail',
  map: 'map marker',
  notifications: 'alarm',
  notificationSettings: 'map',
  phone: 'phone',
  preview: 'eye',
  profile: 'user',
  profileImage: 'picture',
  profiles: 'users',
  refresh: 'refresh',
  remove: 'remove circle',
  save: 'save',
  search: 'search',
  selected: 'filter',
  setting: 'setting',
  settings: 'settings',
  signIn: 'user',
  signInOut: 'user',
  signOut: 'sign out',
  sort: 'sort',
  todo: 'tasks',
  twitter: 'twitter',
  user: 'user',
  userCancel: 'user cancel',
  userPlus: 'user plus',
  userSecret: 'user secret',
  weather: 'cloud',
  wideMode: 'expand arrows alternate',
  wizard: 'wizard'
}

const groupIconTypes = {
  app: 'tv',
  appDashboard: 'mobile alternate',
  data: 'database',
  permissions: 'thumbs up',
  profiles: 'users',
  quickLinks: 'star',
  sports: 'trophy',
  styling: 'paint brush',
  view: 'file outline',
  viewContent: 'list ul',
  views: 'folder open outline',
}

const authIconTypes = {
  appAuth: 'user secret',
  appUserNotifications: 'bell outline',
  appUserProfile: 'user',
}

export const appIconTypes = {
  activities: 'sun',
  activityFeeds: 'bull horn',
  attendees: 'users',
  attendeeTypes: 'users',
  cityGuide: 'map outline',
  cityGuides: 'map outline',
  cityInfos: 'building outline',
  client: 'handshake',
  clients: 'building outline',
  copy: 'copy',
  documents: 'file outline',
  dressCodes: 'file outline',
  events: 'info circle',
  eventsHome: 'home',
  exhibitors: 'bullhorn',
  facilitys: 'building outline',
  healthAndSafety: 'medkit',
  hotels: 'hotel',
  imageMapping: 'images outline',
  infoBooth: 'info circle',
  landing: 'home',
  localInfo: 'map signs',
  locations: 'map signs',
  mapping: 'map outline',
  maps: 'map',
  marketFair: 'shopping basket',
  meals: 'food',
  messages: 'envelope outline',
  myInfo: 'info circle',
  officeLocations: 'map signs',
  organizations: 'building outline',
  paste: 'paste',
  pdfs: 'file pdf outline',
  registrationTypes: 'list ul',
  relationshipTypes: 'list ul',
  reservations: 'hotel',
  restaurants: 'food',
  securitys: 'key',
  sessionMaps: 'map',
  sessions: 'calendar alternate outline',
  sessionTypes: 'list ul',
  speakers: 'microphone',
  speakerTypes: 'list ul',
  sponsors: 'handshake outline',
  sponsorTypes: 'list ul',
  staff: 'users',
  techTable: 'talk',
  transportations: 'train',
  vendors: 'money bill alternate outline',
  venues: 'building outline',
  videos: 'video',
  volunteers: 'file outline',
  wardrobes: 'clock',
  ...globalIconTypes,
  ...groupIconTypes,
  ...authIconTypes,
}

