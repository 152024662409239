import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { sendSignInLinkToUserEmail } from '../../auth/signInActions/sendSignInLink';
import { appFormTypes } from '../../enums/appFormTypes';
import { fsfn_getAuthByEmail } from "../../functions/fbAuth";
import { fsfn_sendEmail, fsfn_sendSms, sendNotificationToSelf } from "../../functions/fbNotifications";
import { dispatchProps, grts, responseReducers } from "./reducerHelpers/dispatchProps";
import { createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { deleteField } from 'firebase/firestore';
import { fs_update_doc } from '../../firestoreData/appData/fsData';

export const appUserManagerInitialState = (initState) => {
  return { ...initState }
};

export const rts = {
  handleCancel: 'handleCancel',
  handleGetAppUser: 'handleGetAppUser',
  handleSend: 'handleSend',
  handleResetDisclaimerResponses: 'handleResetDisclaimerResponses',
  handleSendPasswordResetEmail: 'handleSendPasswordResetEmail',
  handleSetAuthData: 'handleSetAuthData',
  handleSetSelectedItem: 'handleSetSelectedItem',
  handleShowAuth: 'handleShowAuth',
  handleShowProfile: 'handleShowProfile',
  // handleSendSignInLinkToUserEmail: 'handleSendSignInLinkToUserEmail',
  ...grts
}

export const userMenuItems = {
  editAuthorization: 'editAuthorization',
  editProfile: 'editProfile',
  resetDisclaimerResponses: 'resetDisclaimerResponses',
  sendEmail: 'sendEmail',
  sendPasswordResetEmail: 'sendPasswordResetEmail',
  sendPushNotification: 'sendPushNotification',
  sendSms: 'sendSms',
  // sendSignInLinkEmail: 'sendSignInLinkEmail',
}

export const showAppManagerTypes = {
  auth: 'auth',
  email: 'email',
  emailSignInLink: 'emailSignInLink',
  emailPasswordReset: 'emailPasswordReset',
  notification: 'notification',
  profile: 'profile',
  sms: 'sms',
}

export const appUserManagerReducer = (state, action) => {

  const { selectedAppUser, selectedAppUserAuth, logging, paps_state } = state
  const { pathViews } = paps_state ? paps_state : {}
  const { dispatch, formData } = action
  const { title, body, text, subject } = formData ? formData : {}

  const { handleSetAuthData, handleCancel } = appUserManagerHandlers(dispatch)

  switch (action.type) {

    case rts.handleGetAppUser:
      console.log('handleGetAppUser')
      // fsfn_getAuthByEmail(selectedAppUser, handleSetAuthData)
      return { ...state }

    case rts.handleSetAuthData:
      const { uid } = action.result ? action.result : {}
      if (uid) {
        return { ...state, authRetrieved: true, selectedAppUserAuth: { ...action.result, ...selectedAppUser } }
      } else {
        return { ...state, authRetrieved: true, selectedAppUserAuth: { ...selectedAppUser } }
      }

    case rts.handleSetSelectedItem:
      switch (action.item) {
        case userMenuItems.editAuthorization:
          return { ...state, showAppManagerType: showAppManagerTypes.auth }
        case userMenuItems.sendEmail:
          return { ...state, showAppManagerType: showAppManagerTypes.email, appFormType: appFormTypes.emailDirect }
        case userMenuItems.sendSignInLinkEmail:
          return { ...state, showAppManagerType: showAppManagerTypes.emailSignInLink, appFormType: appFormTypes.emailDirect }
        case userMenuItems.sendPasswordResetEmail:
          return { ...state, showAppManagerType: showAppManagerTypes.emailPasswordReset, appFormType: appFormTypes.emailDirect }
        case userMenuItems.sendPushNotification:
          return { ...state, showAppManagerType: showAppManagerTypes.notification, appFormType: appFormTypes.notificationsDirect }
        case userMenuItems.editProfile:
          return { ...state, showAppManagerType: showAppManagerTypes.profile }
        case userMenuItems.sendSms:
          return { ...state, showAppManagerType: showAppManagerTypes.sms, appFormType: appFormTypes.smsDirect }
        default:
          return { ...state, showAppManagerType: null }
      }

    case rts.handleCancel:
      return { ...state, showAppManagerType: null, confirmation: null }

    case rts.handleResetDisclaimerResponses:
      const _refPath = createRefPath_client(pathViews, ['profiles', action.email])
      const dataToUpdate = {
        disclaimerResponses: deleteField()
      }
      fs_update_doc(_refPath, dataToUpdate)
      // console.log('_refPath', _refPath, dataToUpdate)
      return { ...state }

    case rts.handleSend:

      switch (state.showAppManagerType) {

        case showAppManagerTypes.email:
          if (selectedAppUserAuth && selectedAppUserAuth.email) {
            const nd = {
              email: {
                recipient: selectedAppUserAuth.email,
                sender: 'support@meetingevolution.net',
                subject,
                body,
              }
            }
            fsfn_sendEmail(nd).then(response_data => {
              const { success, error } = response_data ? response_data : {}
              dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch, content: 'The Email has been sent' })
              // handleCancel()
            }).catch(error => {

            })
          }
          break;

        case showAppManagerTypes.notification:
          if (selectedAppUserAuth && selectedAppUserAuth.fcmTokens) {
            const notificationData = { title, body }
            console.log('notification', notificationData)
            sendNotificationToSelf({ notificationData, appUserAccess: selectedAppUserAuth, paps_state, logging }).then(response_data => {
              const { success, error } = response_data ? response_data : {}
              console.log('response_data', response_data)
              dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch, content: 'The message has been sent' })
            }).catch(error => {
              handleCancel()
              console.error(error);
            })
          }
          break;

        case showAppManagerTypes.sms:
          if (selectedAppUserAuth && selectedAppUserAuth.phoneNumber) {
            const data = {
              to: selectedAppUserAuth.phoneNumber,
              text,
            }
            fsfn_sendSms(data, logging).then(response_data => {
              const { success, error } = response_data ? response_data : {}
              dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch, content: 'The message has been sent' })
            }).catch(error => {
              handleCancel()
              console.error(error);
            })
          }
          break;
        default:
      }
      return { ...state, updating: true, updateCaption: 'Sending' }

    case rts.handleSendSignInLinkToUserEmail:
      const { sharePath } = paps_state ? paps_state : {}
      sendSignInLinkToUserEmail(action.email, sharePath, true).then(response => {
        const { success, error } = response ? response : {}
        dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch, content: 'The email has been sent' })
      }).catch(error => {
        handleCancel()
        console.error(error);
      })
      return { ...state }

    case rts.handleSendPasswordResetEmail:
      const auth = getAuth()
      sendPasswordResetEmail(auth, action.email).then(() => {
        dispatch && dispatch({ type: dispatchProps.success, dispatch, content: 'The email has been sent' })
      }).catch(error => {
        console.log('error', error)
        dispatch && dispatch({ type: dispatchProps.error, error, dispatch, content: 'The email has NOT been sent' })
      })
      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { onClose: handleCancel, dispatch, content: action.content })

    default:
      return { ...state }
  }
}

export const appUserManagerHandlers = (dispatch) => {
  return {
    handleCancel: () => dispatch({ type: rts.handleCancel, dispatch }),
    handleGetAppUser: () => dispatch({ type: rts.handleGetAppUser, dispatch }),
    handleResetDisclaimerResponses: (email) => dispatch({ type: rts.handleResetDisclaimerResponses, dispatch, email }),
    handleSend: (formData) => dispatch({ type: rts.handleSend, dispatch, formData }),
    handleSendPasswordResetEmail: (email) => dispatch({ type: rts.handleSendPasswordResetEmail, dispatch, email }),
    handleSendSignInLinkToUserEmail: (email) => dispatch({ type: rts.handleSendSignInLinkToUserEmail, dispatch, email }),
    handleSetAuthData: (result) => dispatch({ type: rts.handleSetAuthData, dispatch, result }),
    handleSetSelectedItem: (item) => dispatch({ type: rts.handleSetSelectedItem, dispatch, item }),
    handleShowAuth: () => dispatch({ type: rts.handleShowAuth, dispatch, }),
    handleShowProfile: () => dispatch({ type: rts.handleShowProfile, dispatch, }),
  }
}