import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper from '../../wrappers/Wrapper';

const FullPageAlert = (props) => {

  const { title, message, navigate } = props

  const handleCancel = () => {
    navigate && navigate(-1)
    props.handleCancel && props.handleCancel()
  }

  return <FullPageWrapper
    content={<Wrapper
      content={<Segment placeholder>
        <Header as='h4' textAlign={'center'} style={{ margin: 'auto' }}>
          <Icon name='bell' color={'blue'} />
          <Header.Content>{message}</Header.Content>
        </Header>
      </Segment>}
    />}
    topperCaption={title ? title : 'Alert'}
    handleCancel={handleCancel}
  />
};

export default FullPageAlert