import React, { useContext, useEffect, useReducer } from 'react';
import { Menu } from 'semantic-ui-react';
import { menuClassNames, SettingsAreaContext } from '../../../cnr/contexts/SettingsAreaContext';
import { SettingsMenuContext } from '../../../cnr/contexts/SettingsMenuContext';
import { settingsAreaMenuHandlers, settingsAreaMenuInitialState, settingsAreaMenuReducer } from '../../../cnr/reducers/SettingsAreaMenuReducer';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import DragDropSimple from '../../../dragNDrops/DragDropSimple';
import { updateHomeGlobalSettingsToDb } from '../../../firestoreData/settings/updateSettings';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { getMenuGroupElements } from './getMenuGroupElements';

/**
* Creates a list of 'MenuItemsGroup' for each group in settingsGroupsByGroupType taken from settingsArea_state
* @param {*} group 
* @param {*} grpSettings 
* @param {*} inverse 
* @returns 
*/
const SettingsAreaGroupsMenu = () => {

  // const x = findResultFromArrayOfNumbers([1, 5, 5, 5], 24, 5000)
  // const x = findResultFromArrayOfNumbers([1, 5, 5, 5, 6, 7], 23, 5000)
  // console.log('x', x) 

  const settingsAreaContext = useContext(SettingsAreaContext);
  const { settingsArea_state } = settingsAreaContext ? settingsAreaContext : {}
  const { settingsOptions, isGlobal, ddGroups, ddGroupsP, menuAuthGroups } = settingsArea_state ? settingsArea_state : {}
  const { showHelpLine } = settingsOptions ? settingsOptions : {}

  // settingsMenuContext
  const settingsMenuContext = useContext(SettingsMenuContext);
  const { settingsMenu_state, settingsMenu_fns } = settingsMenuContext ? settingsMenuContext : {}
  const { appArea: appArea_sm, areaTypeSm, allowGroupMenuSort } = settingsMenu_state ? settingsMenu_state : {}

  // init
  const initState = settingsAreaMenuInitialState({ ddGroups })
  const [settingsAreaMenu_state, dispatch] = useReducer(settingsAreaMenuReducer, initState);
  const settingsAreaMenu_handlers = settingsAreaMenuHandlers(dispatch)

  // the menuItemElements is the content of the Menu.Item
  const { menuItemElements } = settingsAreaMenu_state ? settingsAreaMenu_state : {}

  useEffect(() => {
    if (menuAuthGroups) {
      // IMPORTANT: Settings - getMenuGroupElements  
      const { _menuItemElements, _menuElementGroups } = getMenuGroupElements(menuAuthGroups, settingsMenu_fns)
      settingsAreaMenu_handlers.handleSetElementGroups(_menuItemElements, _menuElementGroups, appArea_sm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [menuAuthGroups, appArea_sm, areaTypeSm]);

  useEffect(() => {
    if (allowGroupMenuSort) { settingsAreaMenu_handlers.handleAmmendDdGroups() }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [allowGroupMenuSort]);

  const handleUpdateGroupItemChange = (ddg) => settingsAreaMenu_handlers.handleUpdateGroupItemChange(ddg)

  const updateSettingsArea = () => {
    const dis = {}
    if (ddGroups) {
      Object.keys(ddGroups).forEach(groupKey => {
        const ddGroup = ddGroups[groupKey]
        if (ddGroup) {
          const { dataItems } = ddGroup
          if (dataItems) {
            dataItems.forEach(di => {
              dis[di.key] = {
                _position: di._position,
                settingsMenuGroupType: di.settingsMenuGroupType
              }
            })
          }
        }
      })
    }
    // setUpdating(true)
    const dataToUpdate = {
      ['settingsConsole.groupItemSettings']: dis
    }
    updateHomeGlobalSettingsToDb(dataToUpdate, null, true)
  }

  const dropDownSimple = () => <DragDropSimple
    ddGroups={ddGroups}
    ddGroupsP={ddGroupsP}
    cn_portal={'msmi three menu-groups'}
    positionProp={'_position'}
    groupChangeKey={'settingsMenuGroupType'}
    handleUpdateGroupItemChange={handleUpdateGroupItemChange}
  />

  const wrapper_dropDownSimple = () => <Wrapper
    content={dropDownSimple()}
    footer={<UiSaveButtons save={{ oc: updateSettingsArea }} />}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  let cn = menuClassNames.menu + ' three'
  if (showHelpLine) { cn += ' hlp' }

  if (allowGroupMenuSort && ddGroups && isGlobal) {
    return wrapper_dropDownSimple()
  } else {
    return menuItemElements ? <Menu borderless inverted={true} vertical fluid className={cn}>
      {menuItemElements}
    </Menu> : <div></div>
  }
};

export default SettingsAreaGroupsMenu