import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { subAppTypes } from '../../../AppSub';
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { signInResponseTypes } from '../../cnr/reducers/SignInReducer';

export const _emailSignInStorageItem = 'emailForSignUp'

export const sendSignInLinkToEmails = (selectedItems, sharePath, callback) => {
  Object.keys(selectedItems).forEach(key => {
    const selectedItem = selectedItems[key]
    const { email } = selectedItem ? selectedItem : {}
    if (email) {
      sendSignInLinkToUserEmail(email, sharePath, false, callback)
    }
  })
}

/** Sends a email to the emailAddress.
 * If the recipient clicks on the 'Sign in to ...' link, they will be redirected to the url use below
 * @callback dispatch: type = handleSignInResponse
 */
export const sendSignInLinkToUserEmail = async (emailAddress, sharePath, useLocalStorage, callback) => {

  const dispatchSuccessType = signInResponseTypes.emailLinkSent
  const dispatchNonSuccessType = signInResponseTypes.emailLinkNotSent

  var actionCodeSettings = {
    // url: 'https://mobile.events.meetingevolution.net', // LOOK
    url: sharePath.replace('landing', subAppTypes.emailSignInConfirmation),
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'example.page.link',
    appName: 'TEST'
  };

  if (emailAddress) {
    const auth = getAuth()
    sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings)
      .then(() => {
        if (useLocalStorage) { window.localStorage.setItem(_emailSignInStorageItem, emailAddress) }
        callback && callback(signInResponseData(null, null, null, dispatchSuccessType))
        return { success: true }
      })
      .catch((error) => {
        console.error(error)
        callback && callback(signInResponseData(null, null, null, dispatchNonSuccessType, error))
        return { success: false, error }
      });
  } else {
    console.error('No Email')
    callback && callback(signInResponseData(null, null, null, dispatchNonSuccessType))
    return { success: false, error: 'No Email' }
  }
} 