import React from 'react'
import { Divider, Image } from 'semantic-ui-react'
import { uniqueKey } from '../../common/keys'
import { gEnums } from '../../enums/globalEnums'
import { iconColorTypes } from '../../enums/settingsIconTypes'
import SemPdfSimple from '../sem/SemPdfSimple'

/**
 * 
 * @param {object} props 
 * @returns a GRID of images only
 */
const ImagesGrid = (props) => {

  const { igProps, viewOnly } = props ? props : {}

  const {
    approvedOnly,
    documentKey,
    gallery,
    galleryItem,
    handleImageClick,
    isFullGalleryItem,
    isProfile,
    isPdf,
    selectedItems,
    showApprovalWizard,
    storageType,
    storageLocationType,
  } = igProps ? igProps : {}

  const _isPdf = storageType === gEnums.storageTypes.pdf

  let _cn = 'grid-storage-images'
  if (documentKey) { _cn += ' ' + documentKey }

  const thumbnailImage = (src, key, divCn, imageCn, customMetadata) => {
    if (_isPdf) {
      return <div className={divCn}>
        {src ? pdfColumns(src, key, imageCn, customMetadata) : <div className={imageCn}>Pending</div>}
      </div>
    } else {
      return <div className={divCn}>
        {src ? <Image className={imageCn} src={src} /> : <div className={imageCn}>Pending</div>}
      </div>
    }
  }

  const thumbnailDivImage = (src, key, approved, customMetadata) => {
    const imageCn = 'img-ts-image'
    let divCn = 'img-ts-container ig'
    divCn += !approved ? ' pending' : ''
    return thumbnailImage(src, key, divCn, imageCn, customMetadata)
  }

  // imageKey, documentKey, isFullGalleryItem, isProfile, view, viewKey, path, customMetadata

  const imageColumn = (src, key, cn, icn, approved, path, customMetadata) => <div
    key={uniqueKey('ig', 'gc', key)}
    className={cn + ' ' + key}
    onClick={(e) => { handleImageClick(e, key, storageLocationType, documentKey, isFullGalleryItem, isProfile, path, customMetadata) }}
  >
    {/* {((storagePermissions.allowApprove && showApprovalWizard) || storagePermissions.allowDelete || storagePermissions.allowSelect) &&
      <Icon className={'img-ico-sel'} {...icn} onClick={(e) => { handleImageSelect(e, key, documentKey) }} />} */}
    {thumbnailDivImage(src, key, approved, customMetadata)}
  </div>

  const md = (customMetadata) => {
    const mds = []
    Object.keys(customMetadata).forEach(k => {
      switch (k) {
        case 'name':
          mds.push(<div key={uniqueKey('ig', 'md', k)} class={'image-meta'}>{customMetadata[k]}</div>)
          break;
        default:
        //nothing
      }
    })
    return mds
  }

  const pdfColumns = (src, key, cn, customMetadata) => <React.Fragment>
    {pdfColumn(src, key, cn, customMetadata)}
    {metadataColumn(key, cn, customMetadata)}
  </React.Fragment>

  const pdfColumn = (src, key, cn) => <div key={uniqueKey('ig', 'gc', key)} className={cn + ' ' + key} width={4}>
    <SemPdfSimple fileUrl={src} inGrid={true} />
  </div>

  const metadataColumn = (key, cn, customMetadata) => <div key={uniqueKey('ig', 'gcm', key)} className={cn + ' ' + key} width={12}>
    {customMetadata && md(customMetadata)}
  </div>

  const gridColumn = (src, key, cn, icn, approved, path, customMetadata) => {
    if (isPdf) {
      return pdfColumns(src, key, cn, customMetadata)
    } else {
      return imageColumn(src, key, cn, icn, approved, path, customMetadata)
    }
  }

  const galleryImageColumns = (imgs) => {

    Object.keys(gallery).forEach(key => {

      const item = gallery[key]

      const { urls, approved, path, customMetadata } = item ? item : {}
      const { full, thumbnail } = urls ? urls : {}

      let icn;
      if (showApprovalWizard) {
        icn = {
          name: approved ? 'check square outline' : 'square outline',
          color: approved ? iconColorTypes.menuItem : iconColorTypes.default,
        }
      } else {
        icn = {
          name: (selectedItems && selectedItems[key]) ? 'check square outline' : 'square outline',
          color: (selectedItems && selectedItems[key]) ? iconColorTypes.menuItem : iconColorTypes.default,
        }
      }

      let cn = 'img-ts-col'
      if (isPdf) { cn += ' pdf' }

      if (approvedOnly && !showApprovalWizard) {
        if (approved) {
          if (thumbnail) {
            imgs.push(gridColumn(thumbnail, key, cn, icn, approved, path, customMetadata))
          } else if (full) {
            imgs.push(gridColumn(full, key, cn, icn, approved, path, customMetadata))
          }
        }
      } else {
        if (thumbnail) {
          imgs.push(gridColumn(thumbnail, key, cn, icn, approved, path, customMetadata))
        } else if (full) {
          imgs.push(gridColumn(full, key, cn, icn, approved, path, customMetadata))
        }
      }
    })
  }

  const galleryImage = (imgs) => {
    const { urls, uid, approved, customMetadata } = galleryItem ? galleryItem : {}
    const { full } = urls ? urls : {}
    if (approvedOnly) {
      if (approved) { imgs.push(gridColumn(full, uid, null, null, null, null, customMetadata)) }
    } else {
      imgs.push(gridColumn(full, uid, null, null, null, null, customMetadata))
    }
  }

  const pageGalleryGrid = () => {
    const imgs = []
    if (gallery) { galleryImageColumns(imgs) }
    if (galleryItem) { galleryImage(imgs) }
    return <div key={uniqueKey('ig', 'gr')} className={_cn} >{imgs}</div>
  }

  const pageGalleryGridWithDivider = () => <React.Fragment>
    <Divider key={uniqueKey('ss', 'h')} horizontal>{'Gallery'}</Divider>
    {pageGalleryGrid()}
  </React.Fragment>

  const content = () => {
    if (gallery || galleryItem) {
      return viewOnly ? pageGalleryGridWithDivider() : pageGalleryGrid()
    } else {
      return <div></div>
    }
  }

  return content()
}

export default ImagesGrid