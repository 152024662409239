import React, { useContext, useEffect, useState } from 'react';
import { AppFormsContext } from '../../../../global/cnr/contexts/AppFormsContext';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import FormForm from '../../../../global/components/forms/FormForm';
import { getFormPropz } from '../../../../global/components/forms/props/formPropz';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { gEnums } from '../../../../global/enums/globalEnums';
import { CreateSportsContext } from '../CreateSports';
import { seasonalUpdateSteps } from '../../cnr/reducers/CreateSportsReducer';

const EditSeason = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { handleCancel, subActionType } = props

  let appFormType;

  switch (subActionType) {
    case _asat.editCurrentSeason:
      appFormType = 'createSeason'
      break;
    default:
      appFormType = 'editSeason'
      break;
  }

  // createSportsContext
  const sportsSeasonContext = useContext(SportsSeasonContext);
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ? sportsSeasonContext : {}
  const { latestSeason, details_info, sportsButtonProps, parentDataKeys } = sportsSeason_state ? sportsSeason_state : {}
  const { details } = details_info ? details_info : {}
  const { details: details_key } = parentDataKeys ? parentDataKeys : {}

  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_handlers } = createSportsContext ? createSportsContext : {}

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state, appForms_fns } = appFormsContext ? appFormsContext : {}
  const { appForms } = appForms_state ? appForms_state : {}
  const { getForms } = appForms_fns ? appForms_fns : {}

  const [formProps, setFormProps] = useState()
  const [seasonDetails, setSeasonDetails] = useState({})

  const handleLocalPropChange = (data_form) => setSeasonDetails(data_form)

  const form_handlers = { handleFormDataChange_local: handleLocalPropChange }

  useEffect(() => {
    if (appForms && appForms[appFormType]) {
      const vprs = appForms[appFormType]

      let _data_current = {}

      switch (subActionType) {
        case _asat.editCurrentSeason:
          _data_current = { ...details }
          break;
        default:
          _data_current = { year: latestSeason }
          break;
      }
      setSeasonDetails(_data_current)
      const fpp = { form_handlers, viewProps: vprs, data_current: _data_current }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
    } else {
      getForms()
    }
    // eslint-disable-next-line 
  }, []);

  const cb_update = () => {
    handleCancel()
  }

  const handleButtonClick = () => {
    const dataToUpdate = {}
    switch (subActionType) {
      case _asat.editCurrentSeason:
        Object.keys(seasonDetails).forEach(sdi => {
          if (seasonDetails[sdi]) {
            dataToUpdate[sdi] = seasonDetails[sdi]
          }
        })
        delete dataToUpdate._deleteProps
        createSports_handlers.handleUpdate_seasonDetails(dataToUpdate, latestSeason)
        break;

      default:
        dataToUpdate.latestSeason = seasonDetails.year
        sportsSeason_handlers.handleUpdateSportsSeasonData(dataToUpdate, cb_update)
        break;
    }
  }

  const form = () => formProps ? <FormForm
    formAttributes={{ autoComplete: "off" }}
    formProps={formProps}
  /> : <div></div>


  const wrapper = () => <Wrapper
    content={form()}
    footer={<UiSaveButtons others={[{ oc: handleButtonClick, caption: 'Update ', ...sportsButtonProps }]} />}
    wrapperType={wrapperTypes.padded}
  />

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={'Edit Season'}
    topperCaption2={details_key}
    handleCancel={handleCancel}
  ></FullPageWrapper>

  return fpw()

}

export default EditSeason