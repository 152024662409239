import { gEnums } from '../../enums/globalEnums';
import { getGallery_clientProfiles, get_documentStorageItems } from '../../storage/storageItems';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';

const rts = {
  handleGet_itemGalleryItems: 'handleGet_itemGalleryItems',
  handleSet_itemStorageImages: 'handleSet_itemStorageImages',
  handleSet_existingGalleryItems: 'handleSet_existingGalleryItems',
  ...grts,
}

export const itemStorageReducer = (state, action) => {

  const { pathViews, useClientProfileImage, appUserCollection } = state
  const { type, dispatch } = action

  const { handleSet_itemStorageImages } = itemStorageHandlers(dispatch)

  switch (type) {
    case rts.handleGet_itemGalleryItems:
      get_documentStorageItems(pathViews, action, handleSet_itemStorageImages)
      if (useClientProfileImage) {
        getGallery_clientProfiles(pathViews, appUserCollection, null, handleSet_itemStorageImages, gEnums.storageLocationTypes.clientProfiles)
      }
      return { ...state }

    case rts.handleSet_itemStorageImages:
      const { galleryItems: _galleryItems } = state
      const { galleryResponse } = action
      const { galleryItems, galleryPath, slt } = galleryResponse
      // send the results to the storage provider   
      const gis = _galleryItems ? { ..._galleryItems, ...galleryItems } : galleryItems
      return { ...state, galleryItems: gis, updateStoragePath: galleryPath, galleryResponse }

    case rts.handleSet_existingGalleryItems:
      return { ...state, galleryItems: action.galleryItems }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterUpdate })

    default:
      return state
  }
}

export const itemStorageInitialState = (initState) => {
  return { ...initState, updateStoragePath: null }
};

export const itemStorageHandlers = (dispatch) => {
  return {
    handleGet_itemGalleryItems: (galleryPath, key_viewItem) => { dispatch({ type: rts.handleGet_itemGalleryItems, dispatch, galleryPath, key_viewItem }) },
    handleSet_itemStorageImages: (galleryResponse) => { dispatch({ type: rts.handleSet_itemStorageImages, dispatch, galleryResponse }) },
    handleSet_existingGalleryItems: (galleryItems) => { dispatch({ type: rts.handleSet_existingGalleryItems, dispatch, galleryItems }) },
    ...responseHandlers(dispatch)
  }
} 