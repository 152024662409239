import { formatDistance } from 'date-fns';
import React from 'react';
import { Card, Icon, Label } from 'semantic-ui-react';
import { uniqueKey } from '../common/keys';
import { appIconTypes } from '../enums/appIconTypes';

const AppUserCard = (props) => {

  const { appUser, index, handleClick, handleGoToEvent, handleSignInAs, viewOnly, showTimeDifferences } = props
  const { displayName, firstName, lastName, email, _itemKey, events, clients, aboutMe, bio, metadata } = appUser
  const { lastSignInTime, creationTime, lastRefreshTime } = metadata ? metadata : {}
  const name = displayName ? displayName : lastName + ', ' + firstName

  const eventLabels = (_itemKey, events) => {
    const lbls = []
    if (events) {
      Object.keys(events).forEach(key => {
        const event = events[key]
        const { name } = event ? event : {}
        lbls.push(<Label key={uniqueKey('aulel', 'c', _itemKey, key)} onClick={(e) => { !viewOnly && handleGoToEvent(e) }}>
          <Icon name='calendar check outline' color={'green'} />{name ? name : key}
        </Label>)
      })
    }
    return lbls
  }

  const icon = (name, color, oc, itemData, _itemKey) => <Icon key={uniqueKey('aul', 'i', name)} name={name} color={color} size={'small'} onClick={(e) => { !viewOnly && oc && oc(e, itemData, _itemKey) }} />

  const signInIcon = (itemData, _itemKey) => {
    return <div style={{ float: 'right' }}>
      {icon('sign in', 'green', handleSignInAs, itemData, _itemKey)}
    </div>
  }

  const cardIcons = (itemData, _itemKey) => {
    const { email, fcmTokens, phoneNumber } = itemData ? itemData : {}
    return <div style={{ float: 'right' }}>
      {icon(appIconTypes.mail, email ? 'blue' : 'grey')}
      {icon(appIconTypes.notifications, fcmTokens ? 'blue' : 'grey')}
      {icon(appIconTypes.phone, phoneNumber ? 'blue' : 'grey')}
      {/* {icon('sign in', 'green', handleSignInAs, itemData, _itemKey)} */}
    </div>
  }

  const clientLabels = (_itemKey, clients) => {
    const lbls = []
    if (clients) {
      Object.keys(clients).forEach(clientKey => {
        const client = clients[clientKey]
        const { events } = client ? client : {}
        if (events) {
          Object.keys(events).forEach(eventKey => {
            const event = events[eventKey]
            const { name } = event ? event : {}
            lbls.push(<Label key={uniqueKey('aulcl', 'c', _itemKey, eventKey)}>{clientKey}</Label>)
            lbls.push(<Label key={uniqueKey('aulcl', 'e', _itemKey, eventKey)}>{name ? name : eventKey}</Label>)
          })
        }
      })
    }
    return lbls
  }

  const metaContent = () => {
    const _lastSignInTime = formatDistance(new Date(lastSignInTime), new Date(), { addSuffix: true })
    const _creationTime = formatDistance(new Date(creationTime), new Date(), { addSuffix: true })
    const _lastRefreshTime = formatDistance(new Date(lastRefreshTime), new Date(), { addSuffix: true })
    return <Card.Content className='app-user-meta'>
      <Card.Meta><Icon color='green' name='refresh' />{showTimeDifferences ? _lastRefreshTime : lastRefreshTime}</Card.Meta>
      <Card.Meta><Icon color='blue' name='sign-in' />{showTimeDifferences ? _lastSignInTime : lastSignInTime}</Card.Meta>
      <Card.Meta><Icon color='yellow' name='user' />{showTimeDifferences ? _creationTime : creationTime}</Card.Meta>
    </Card.Content>
  }


  const appUserCard = () => <Card as='div' fluid key={uniqueKey('aul', index)} className={'card-normal'} onClick={() => { !viewOnly && handleClick && handleClick(appUser, _itemKey) }}>
    <Card.Content>
      <Card.Header>{name}{!viewOnly && signInIcon(appUser, _itemKey)}</Card.Header>
      <Card.Meta>{lastName}{', '}{firstName}</Card.Meta>
      <Card.Meta>{email}</Card.Meta>
    </Card.Content>
    <Card.Content extra className={'aul-events'}>
      {cardIcons(appUser, _itemKey)}
    </Card.Content>
    {!viewOnly && events && <Card.Content extra className={'aul-events'}>
      {eventLabels(_itemKey, events)}
    </Card.Content>}
    {!viewOnly && events && aboutMe && <Card.Content extra >
      {aboutMe}
    </Card.Content>}
    {!viewOnly && events && bio && <Card.Content extra >
      {bio}
    </Card.Content>}
    {!viewOnly && clients && <Card.Content extra className={'aul-events'}>
      {clientLabels(_itemKey, clients)}
    </Card.Content>}
    {metadata && metaContent()}
  </Card>

  return appUserCard()

}

export default AppUserCard