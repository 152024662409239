import React, { useContext, useEffect, useState } from 'react';
import { uniqueKey } from '../../common/keys';
import NoData from '../../components/alerts/NoData';
import { getFormPropz } from '../../components/forms/props/formPropz';
import FormForm from '../../components/forms/FormForm';
import { BaseSettingsContext } from '../../cnr/contexts/BaseSettingsContext';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';

const SettingsFormEdit = (props) => {

  const { isGlobal, settingsType, previewForm, updateForm, closeForm, formData, useWrapper, inSidebar } = props

  // baseSettingsContext
  const baseSettingsContext = useContext(BaseSettingsContext)
  const { baseSettings_state } = baseSettingsContext ? baseSettingsContext : {}
  const { baseSettings } = baseSettings_state ? baseSettings_state : {}
  const { global, page } = baseSettings ? baseSettings : {}
  const aas = isGlobal ? global : page

  const settingsProps = aas && aas[settingsType] ? aas[settingsType] : {}
  const { groupItems } = settingsProps ? settingsProps : {}

  const [formProps, setFormProps] = useState()
  const [currentFormData, setCurrentFormData] = useState(formData ? formData : {});

  const handleSaveForm = () => updateForm(currentFormData, true)
  const handleUpdateForm = () => previewForm(currentFormData)

  const handleLocalPropChange = (data_form) => {
    useWrapper ? setCurrentFormData(data_form) : updateForm(data_form)
  }

  useEffect(() => {
    if (groupItems) {
      const vprs = groupItems
      const form_handlers = { handleFormDataChange_local: handleLocalPropChange }
      const fpp = { form_handlers, viewProps: vprs, data_current: currentFormData }
      const fprs = getFormPropz(fpp)
      console.log('fprs', fprs)
      setFormProps(fprs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const content = () => {
    return formProps ?
      <FormForm
        formAttributes={{ inverted: true }}
        formProps={formProps}
        key={uniqueKey('sf', settingsType)}
      />
      :
      <NoData altCaption={'Missing ' + settingsType} />
  }

  const wrapper = () => <Wrapper
    content={content()}
    footer={<UiSaveButtons
      preview={previewForm ? { oc: handleUpdateForm, caption: 'Preview' } : null}
      save={{ oc: handleSaveForm, caption: 'Save' }}
      cancel={{ oc: closeForm, caption: 'Close' }}
    />
    }
    wrapperType={wrapperTypes.paddedFooter}
    css={{ footer: inSidebar ? 'sbf' : null }}
  />

  return useWrapper ? wrapper() : content()
}

export default SettingsFormEdit