import _ from 'lodash';
import { formatItem } from '../common/dateFormatting';
import { manifestSizes } from '../components/imaging/createManifestIcons';
import { gEnums, listTypes } from '../enums/globalEnums';
import { appFormTypes, appUserFormTypes } from '../enums/appFormTypes';
import { listList, listListO } from '../lists/lists';
import { dataListOptionsList } from './dataListOptionsList';
import { appNames, appNamesP } from '../../project/fbConfiguration';

export const formOptionsList = (props) => {

  const { currentListProps, aps_global, aps_viewItems, staticViews, data_combined, updateProps, staticViews_client, isDeveloper } = props
  const { dataCollectionName } = data_combined ? data_combined : {}

  const ol = {}

  currentListProps.forEach(viewProp => {
    const { data } = viewProp ? viewProp : {}
    const { listType, dataListType, labelProps } = data ? data : {}
    if (labelProps) {
      labelProps.forEach(lp => {
        const { data: data_lp } = lp
        const { listType: listType_lp } = data_lp
        if (listTypes[listType_lp] && gEnums[listType_lp]) {
          ol[listType_lp] = createItemList(listType_lp, gEnums[listType_lp])
        }
      })
    } else {
      if (dataListType) {
        const optionProps = { itemProp: viewProp, aps_global, staticViews, data_combined, updateProps, staticViews_client }
        dataListOptionsList(optionProps)
      } else {
        switch (listType) {

          case listTypes.manifestSizes:
            ol[listType] = listList(manifestSizes)
            break;
          case listTypes.timeZones:
            const tzs = [
              'America/Anchorage',
              'America/Chicago',
              'America/Denver',
              'America/Los_Angeles',
              'America/New_York',
              'Europe/London',
              'Europe/Paris',

            ]
            // console.log('first', Intl.supportedValuesOf('timeZone'))
            ol[listType] = listList(tzs)
            break;
          case listTypes.appFormTypes:
            ol[listType] = listList(appFormTypes)
            break;
          case listTypes.appUserFormTypes:
            ol[listType] = listList(appUserFormTypes)
            break;
          case listTypes.appNames:
            ol[listType] = listList(isDeveloper ? appNamesP : appNames)
            break;
          case listTypes.dataFilters:
            const cvi = aps_viewItems && dataCollectionName ? aps_viewItems[dataCollectionName] : null
            if (cvi) {
              const { dataFilters } = cvi ? cvi : {}
              if (dataFilters) {
                ol[listType] = listListO(dataFilters)
              }
            }
            break;
          case listTypes.notificationDeliveryTypes:
            const { appNotifications } = aps_global ? aps_global : {}
            const { allowPushNotifications, allowSmsNotifications, allowEmailNotifications } = appNotifications ? appNotifications : {}
            if (listTypes[listType] && gEnums[listType]) {
              const ndtList = gEnums[listType]
              if (!allowPushNotifications) { delete ndtList.push }
              if (!allowSmsNotifications) { delete ndtList.sms }
              if (!allowEmailNotifications) { delete ndtList.email }
              ol[listType] = createItemList(listType, ndtList)
            }
            break;
          default:
            if (listTypes[listType] && gEnums[listType]) {
              ol[listType] = createItemList(listType, gEnums[listType])
            }
            break;
        }
      }
    }
  })
  return ol
}

const getText = (listType, key) => {
  switch (listType) {
    case listTypes.dateTypes:
      const d = Date.now()
      return _.startCase(key) + ' (' + formatItem(key, d) + ')'
    default:
      return _.startCase(key)
  }
}

const createItemList = (listType, list) => {

  const items = []

  if (_.isArray(list)) {
    list.forEach(key => {
      items.push({
        text: getText(listType, key),
        value: key,
        key: key,
      })
    })
  } else {
    Object.keys(list).forEach(key => {
      if (list[key].caption) {
        items.push({
          text: list[key].caption,
          value: list[key].value,
          key: list[key].key,
        })
      } else {
        items.push({
          text: getText(listType, key),
          value: key,
          key: key,
        })
      }
    })
  }
  return items
}
