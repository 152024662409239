import React, { useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ImageContext } from '../../cnr/contexts/ImageContext';
import { ItemStorageContext } from '../../cnr/contexts/ItemStorageContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { imageHandlers, imageInitialState, imageReducer } from '../../cnr/reducers/ImageReducer';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import UiUpload from '../../pageItem/modification/dataManagement/UiUpload';
import { useElementOnScreen } from '../sem/visibleElements';

const UiImage = (props) => {

  const { itemData, viewItem, showFullImage } = props
  const { images: images_viewItem, key: key_viewItem, dataModifications, dataSource, showDefaultImage } = viewItem ? viewItem : {}
  const { imageLocation, imageType, borderColor } = images_viewItem ? images_viewItem : {}
  const { images: images_dm } = dataModifications ? dataModifications : {}
  const { dataCollectionName } = dataSource ? dataSource : {}
  const { allowAction } = images_dm ? images_dm : {}
  const { email, _itemKey } = itemData ? itemData : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, page_state, eventInfo_state, storage_state, paps_state, transition_state } = states
  const { viewKey } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { projectOptions, storageOptions, appUserPermissions } = aps_global ? aps_global : {}
  const { useLazyImageLoading } = projectOptions ? projectOptions : {}
  const { allowImageUpload } = appUserPermissions ? appUserPermissions : {}

  const { transitions } = transition_state ? transition_state : {}
  const transition_image = transitions ? transitions[_animateTypes.image] : null

  const { appUserCollection, useClientProfileImage, appUserProfileImageType } = aps_appUserSettings ? aps_appUserSettings : {}

  const { appUserUrls } = eventInfo_state ? eventInfo_state : {}
  const { galleries } = storage_state ? storage_state : {}

  // itemStorageContext
  const itemStorageContext = useContext(ItemStorageContext)
  const { itemStorage_state } = itemStorageContext ? itemStorageContext : {}
  const { galleryItems } = itemStorage_state ? itemStorage_state : {}

  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { accessLevel, appUserSession } = appUserAccess ? appUserAccess : {}
  const { appUserSessionKey } = appUserSession ? appUserSession : {}
  const pageIsAppUsers = viewKey === appUserSessionKey

  const _allowAction = (viewKey && (allowAction || allowImageUpload)) && (pageIsAppUsers || accessLevel >= gEnums.accessLevels.admin.value)

  // eslint-disable-next-line 
  const [containerRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 1.0 })

  let cn = 'card-image'
  cn += ' il-' + imageLocation

  // console.log('galleries', galleries)

  const init_state = {
    _itemKey,
    allowAction: _allowAction,
    appUserCollection,
    appUserProfileImageType,
    appUserUrls,
    borderColor,
    cn,
    containerRef,
    dataCollectionName,
    galleries,
    images_viewItem,
    itemData,
    key_viewItem,
    showFullImage,
    storageOptions,
    transition_image,
    useClientProfileImage,
    useLazyImageLoading,
    viewItem,
    ...props
  }

  // image_state
  const [image_state, image_dispatch] = useReducer(imageReducer, init_state, imageInitialState);
  const image_handlers = imageHandlers(image_dispatch, image_state)
  const { cardImageElement, showImageUpload, fullImageProps } = image_state ? image_state : {}

  useEffect(() => {
    if (galleryItems) {
      image_handlers.handleGet_galleryImage(galleryItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [galleryItems, galleries, email, _itemKey]);

  useEffect(() => {
    if (fullImageProps && isVisible) {
      image_handlers.handleImageVisible(imageType, borderColor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isVisible, fullImageProps]);

  const content = () => {
    if (showImageUpload) {
      return <UiUpload origin={'UiImage'} handleCancel={image_handlers.handleShow_imageUpload} />
    } else {
      return <div className={cn} key={uniqueKey('crdi', _itemKey)} ref={containerRef}>
        {cardImageElement && cardImageElement.element}
      </div>
    }
  }

  const imageProvider = () => <ImageContext.Provider value={{ image_state, image_handlers }}>
    {content()}
  </ImageContext.Provider>

  return imageProvider()

}

export default UiImage

export const addCardImage = (cardImage, image_viewItem, display) => {

  const { displayType, cardDisplayType } = display ? display : {}
  const { imageLocation } = image_viewItem ? image_viewItem : {}

  if (cardImage && displayType === gEnums.displayTypes.card && cardDisplayType === gEnums.cardDisplayTypes.nameSectionOnly) {
    return cardImage
  } else {
    if (imageLocation && imageLocation.indexOf(imageLocation) >= 0) {
      return cardImage
    }
  }
}