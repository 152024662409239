import _ from 'lodash';
import { fsfn_getAuthsByChunkEmail } from '../../functions/fbAuth';

const _authListTypes = {
  recent: 'recent',
  found: 'found',
  notFound: 'notFound',
}

const limit = 100
const dayCount = 4

export const rts = {
  handleGetAppUserAuths: 'handleGetAppUserAuths',
  handleAppUserAuths: 'handleAppUserAuths',
}

export const appUserAuthReducerInitialState = (initState) => {
  return { ...initState }
};

export const appUserAuthReducer = (state, action) => {

  const { appUserCollection, pathViews } = state
  const { type, dispatch, appProfileData } = action

  const { handleAppUserAuths } = appUserAuthHandlers(dispatch)

  switch (type) {

    case rts.handleGetAppUserAuths:
      let _staticAppUsers;
      if (appUserCollection && appProfileData && appProfileData[appUserCollection]) {
        _staticAppUsers = appProfileData[appUserCollection]
        if (_staticAppUsers) {
          const _sau = _.sortBy(_staticAppUsers, 'lastName')
          const chunks = _.chunk(_sau, limit);
          fsfn_getAuthsByChunkEmail(chunks, pathViews, true, handleAppUserAuths, _staticAppUsers)
        }
      }
      return { ...state }

    case rts.handleAppUserAuths:
      const { result } = action
      const { found, staticAppUsers } = result ? result : {}
      if (found && staticAppUsers) {
        const recent = getRecent(found)
        Object.keys(staticAppUsers).forEach((key) => {
          const _staticAppUser = staticAppUsers[key]
          const { email } = _staticAppUser
          const _af = email ? _.find(found, { email: email.toLowerCase() }) : null
          const _ar = email ? _.find(recent, { email: email.toLowerCase() }) : null
          _staticAppUser.foundType = _authListTypes.notFound
          if (_af) { _staticAppUser.foundType = _authListTypes.found }
          if (_ar) { _staticAppUser.foundType = _authListTypes.recent }
          if (_af || _ar) {
            _staticAppUser.metadata = _af.metadata
          }
        })
        const appUserAuthList = _.groupBy(staticAppUsers, 'foundType')
        return { ...state, appUserAuthList }
      }
      return { ...state }


    default:
      return { ...state }
  }
}

export const appUserAuthHandlers = (dispatch) => {
  return {
    handleGetAppUserAuths: (appProfileData) => { dispatch({ type: rts.handleGetAppUserAuths, dispatch, appProfileData }) },
    handleAppUserAuths: (result) => { dispatch({ type: rts.handleAppUserAuths, dispatch, result }) },
  }
}

const getRecent = (found) => {

  const nowDate = new Date()
  const nowTime = nowDate.getTime()
  const dis = 1000 * 3600 * 24

  return _.filter(found, function (f) {
    if (f.metadata && f.metadata.lastRefreshTime) {
      const time = f.metadata.lastRefreshTime
      const lrtDate = new Date(time)
      const lrtTime = lrtDate.getTime()
      const diffTime = nowTime - lrtTime
      const lrtDays = diffTime / dis
      if (lrtDays < dayCount) {
        return found
      }
    }
  })
}