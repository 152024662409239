import { getPageData } from "../../firestoreData/appData/getPageData"
import { pageDataValues } from "../../firestoreData/helpers/pageDataValues"
import { storateStatusTypes } from "../../storage/removeStorage"

const rts = {
  handleGetPageData: 'handleGetPageData',
  handleSetPageData: 'handleSetPageData'
}

export const pageDataReducer = (state, action) => {

  const { dispatch, type } = action

  const { handleSetPageData } = pageDataHandlers(dispatch)

  switch (type) {

    case rts.handleGetPageData:
      getThePageData(action.pageDataProps, state, handleSetPageData)
      return { ...state }

    case rts.handleSetPageData:
      const { pageDataResults } = action
      return { ...state, pageDateRetrieved: true, ...pageDataResults }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return { ...state }

    default:
      return { ...state }
  }
}

export const pageDataInitialState = (initState) => {
  return { ...initState }
};

export const pageDataHandlers = (dispatch) => {
  return {
    handleGetPageData: (pageDataProps) => { dispatch({ type: rts.handleGetPageData, dispatch, pageDataProps }) },
    handleSetPageData: (pageDataResults) => { dispatch({ type: rts.handleSetPageData, dispatch, pageDataResults }) },
  }
}

/** 
 * Gets the data for the page
 * @param {object} pageDataProps 
 * @param {object} state 
 * @param {function} callback 
 */
const getThePageData = (pageDataProps, state, callback) => {

  const {
    pathName,
    captionProps,
    subCaptionProps,
    caption_page
  } = state

  const {
    data_state: pageData_state,
    staticViews,
    view,
    viewKey,
  } = pageDataProps

  const _dataResultProps = { isPageData: true, view, pageData_state, captionProps, subCaptionProps, caption_page, staticViews }

  if (pathName && viewKey) {
    try {
      getPageData(pageDataProps, callback)
    } catch (error) {
      console.error(error)
      callback(storateStatusTypes({}, _dataResultProps))
    }
  } else {
    callback(pageDataValues({}, _dataResultProps))
  }
}
