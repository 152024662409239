
import _ from 'lodash';
import React from 'react';
import { firstItems, menuClassNames } from '../../../cnr/contexts/SettingsAreaContext';
import { createKeys } from '../../../common/creating';
import { uniqueKey } from '../../../common/keys';
import { copyObj } from '../../../common_web/copy';
import SettingsAreaGroupMenu from './SettingsAreaGroupMenu';
import SettingsAreaGroupMenuItemElement from './SettingsAreaGroupMenuItemElement';


/**
 * IMPORTANT: Settings - getMenuGroupElements
 * @param {object} menuAuthGroups   
 * @returns menuItemElements and ammends the ddGroups with the corresponding element
 */
export const getMenuGroupElements = (menuAuthGroups, settingsMenu_fns) => {

  const menuElementGroups = getMenuElementGroups(menuAuthGroups, settingsMenu_fns)

  let cn = menuClassNames.menu + ' three'
  const _menuItemElements = []

  if (menuAuthGroups) {
    Object.keys(menuAuthGroups).forEach(mgk => {
      const menuGroup = menuAuthGroups[mgk]
      const { groupedItems, iconColor } = menuGroup

      // IMPORTANT: Settings - Sort the group menus by caption
      const _groupedItems = _.sortBy(groupedItems, 'caption')

      _groupedItems.forEach(groupedItem => {
        const { key: gik } = groupedItem
        const gagmElement = <SettingsAreaGroupMenu
          key={uniqueKey('mig', 'gr', gik)}
          group={{ ...groupedItem, iconColor: iconColor }}
          grpSettings={{ ...groupedItem }}
          inverse={false}
          menuElementGroups={menuElementGroups}
        />
        if (firstItems.includes(gik)) {
          _menuItemElements.unshift(gagmElement)
        } else {
          _menuItemElements.push(gagmElement)
        }
      })

    })
  }

  return { _menuItemElements, _menuElementGroups: menuElementGroups }
}


/**
 * 
 * @param {object} menuAuthGroups 
 * @returns a group objects containing an array, `menuItemElements` AND  a keyed object, `menuItemElementGroups`.
 * These element are the menu items that will appear where the `menu group` is clicked
 */
const getMenuElementGroups = (menuAuthGroups, settingsMenu_fns) => {

  const { allowProjectOption } = settingsMenu_fns ? settingsMenu_fns : {}

  const menuElementGroups = {}

  if (menuAuthGroups) {
    Object.keys(menuAuthGroups).forEach((authGroupKey, index) => {
      // the authGroupKey is the authLevel
      const authGroup = menuAuthGroups[authGroupKey]
      const { groupedItems } = authGroup

      // loop the items within the authGroup
      Object.keys(groupedItems).forEach(groupedItemKey => {

        const menuItemElements = []
        const menuItemElementGroups = []

        const groupedItem = groupedItems[groupedItemKey]
        const { groupItems } = groupedItem ? groupedItem : {}

        const _groupItems = copyObj(groupItems)
        const _groupItemsAdjusted = getAdjustedGroupItems(allowProjectOption, _groupItems, groupedItemKey)

        // IMPORTANT: Settings - Sort the groupItems
        createKeys(_groupItemsAdjusted)
        const groupItems_s = _.sortBy(_groupItemsAdjusted, ['_position', 'key'])

        groupItems_s.forEach((groupItemS, index) => {
          const { key: groupItemKey } = groupItemS
          // IMPORTANT: Settings - SettingsAreaGroupMenuItemElement
          const element = <SettingsAreaGroupMenuItemElement key={uniqueKey('mig', 'gi', groupItemKey)} groupItemKey={groupItemKey} groupItem={groupItemS} index={index} />
          menuItemElementGroups[groupItemKey] = element
          menuItemElements.push(element)
        })

        menuElementGroups[groupedItemKey] = {
          menuItemElementGroups,
          menuItemElements
        }

      })
    })
  }

  return menuElementGroups
}

const getAdjustedGroupItems = (allowProjectOption, groupItems, groupedItemKey) => {

  const _groupItems = copyObj(groupItems)

  switch (groupedItemKey) {
    case 'pageOptions':
      if (_groupItems) {
        Object.keys(_groupItems).forEach(pageOptionKey => {
          const allow = allowProjectOption(pageOptionKey)
          if (!allow) {
            delete _groupItems[pageOptionKey]
          }
        })
      }
      break;
    default:
    // nothing 
  }

  return _groupItems

}