import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';

const MenuItemLR = (props) => {
  const { menuProps } = props
  const { direction, handleMenuClick, width, type, disabled } = menuProps
  const iconDirection = (direction === 'back') ? 'left' : 'right'
  const iconName = 'arrow circle ' + iconDirection
  return <Menu.Item key={uniqueKey('milr', direction)} style={{ width: width }} name={direction} onClick={e => handleMenuClick(direction, type)} disabled={disabled}>
    <Icon color={(disabled ? 'grey' : 'blue')} key={uniqueKey('milri', direction)} size='large' name={iconName} />
  </Menu.Item>
}

export default MenuItemLR