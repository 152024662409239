import _ from 'lodash'
import { createObject } from "../../common/creating"
import { gEnums } from "../../enums/globalEnums"
import { dataModificationTypes } from "../../viewSettings/enums/itemActionTypes"
import { viewDataModes } from './DataManagmentReducer'
import { firestoreReducer } from "./FirestoreReducer"
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"

const rts = {
  handleAddDataItem: 'handleAddDataItem',
  handleAddProp: 'handleAddProp',
  handleAmmend: 'handleAmmend',
  handleAmmendDataItems: 'handleAmmendDataItems',
  handleCreateListName: 'handleCreateListName',
  handleDataModificationItems: 'handleDataModificationItems',
  handleDataModificationSelect: 'handleDataModificationSelect',
  handleDeleteDataItems: 'handleDeleteDataItems',
  handleGetModifyProps: 'handleGetModifyProps',
  handleSaveCreateList: 'handleSaveCreateList',
  handleSaveList: 'handleSaveList',
  handleSaveSort: 'handleSaveSort',
  handleSetLinkItem: 'handleSetLinkItem',
  handleShowAdditional: 'handleShowAdditional',
  handleSorted: 'handleSorted',
  handleSubAction: 'handleSubAction',
  handleUpdateDataItem: 'handleUpdateDataItem',
  handleUpdateDataItems: 'handleUpdateDataItems',
  handleUpdateLinkItems: 'handleUpdateLinkItems',
  handleUpload: 'handleUpload',
  updateCurrentFormData: 'updateCurrentFormData',
  ...grts
}

export const dataModificationsReducer = (state, action) => {

  const {
    autoUpdateCollectionRelationships,
    dataModificationType,
    paps_state,
    handleItemClose,
    isDirectModify,
    modifyProps,
    restrictArrayEdits,
    useTimestamps,
    staticViewKeys,
  } = state

  const { type, dispatch, data_current, dataModifyReady } = action

  switch (type) {

    case rts.handleGetModifyProps:
      const p = { dataModificationType, editableItem: action.editableItem, props_viewItem: action.props_viewItem, subEditProps: action.subEditProps, restrictArrayEdits }
      const mps = getModifyProps(p)
      return { ...state, modifyProps: mps }

    case rts.handleShowAdditional:
      return { ...state, showAdditional: !state.showAdditional }

    case rts.handleAddProp:
      const { name } = action.fd
      const ips = addPropToProps(modifyProps, name)
      return { ...state, modifyProps: ips, showAdditional: false }

    case rts.handleDataModificationItems:
      return { ...state, dataModificationItems: action.dataModificationItems }

    case rts.handleDataModificationSelect:
      if (isDirectModify && !action.dataModificationType && handleItemClose) {
        handleItemClose()
        return { ...state }
      } else {
        return { ...state, dataModificationType: action.dataModificationType, subModificationType: action.subModificationType }
      }

    case rts.handleAmmendDataItems:
    case rts.handleDeleteDataItems:
    case rts.handleAddDataItem:
    case rts.handleSaveSort:
    case rts.handleUpdateDataItem:
    case rts.handleUpdateDataItems:

      const ufProps = getRequestProps(type, state, action)
      ufProps.staticViewKeys = staticViewKeys
      ufProps.autoUpdateCollectionRelationships = autoUpdateCollectionRelationships
      ufProps.useTimestamps = useTimestamps

      if (action.createFunction) {
        action.createFunction(paps_state, ufProps)
      } else {
        // parentDispatch, paps_state, ufProps, parentDispatchProps 
        return firestoreReducer(state, action, { type: 'updateFirestoreData', dispatch, paps_state, ufProps, settingsProps: action.settingsProps })
      }

      switch (type) {
        case rts.handle_fsUpdateDbFromGoogleSheet:
          return { ...state, modifySidebarOpen: false, modifyActionType: null, updating: false, settingsSidebarOpen: false }
        default:
        // nothing
      }

      return { ...state }

    case rts.updateCurrentFormData:
      return { ...state, data_current, dataModifyReady }

    case rts.handleSorted:
      const { sortedItems } = action.ddGroups ? action.ddGroups : {}
      const { dataItems } = sortedItems ? sortedItems : {}
      return { ...state, vldSorted: dataItems, sortedReady: true }

    case rts.handleCreateListName:
      return { ...state, createListName: action.createListName }

    case rts.handleSetLinkItem:
      return { ...state, linkItem: action.linkItem, actionItem: action.actionItem }

    case rts.handleCloseConfirmation:
      if (state.item_handlers) {
        // LOOK TO MOVE
        state.item_handlers.handleItemShowEdit()
      } else if (handleItemClose) {
        handleItemClose()
        return { ...state }
      }
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    default:
      return { ...state }
  }
}

export const dataModificationsInitialState = (initState) => {
  let { modifyActionType, viewDataMode } = initState
  if (viewDataMode === viewDataModes.dataEdit) { modifyActionType = gEnums.dataUpdateTypes.edit }
  return { ...initState, dataModificationType: modifyActionType ? modifyActionType : null, isDirectModify: modifyActionType ? true : false }
};

export const dataModificationsHandlers = (dispatch) => {
  return {
    handleAddDataItem: (createFunction) => { dispatch({ type: rts.handleAddDataItem, dispatch, createFunction }) },
    handleAddProp: (fd) => { dispatch({ type: rts.handleAddProp, dispatch, fd }) },
    handleAmmendDataItems: (ammendType, vld, selectedItems) => { dispatch({ type: rts.handleAmmendDataItems, dispatch, ammendType, vld, selectedItems }) },
    handleCreateListName: (createListName) => { dispatch({ type: rts.handleCreateListName, dispatch, createListName }) },
    handleDataModificationItems: (dataModificationItems) => { dispatch({ type: rts.handleDataModificationItems, dispatch, dataModificationItems }) },
    handleDataModificationSelect: (dataModificationType, subModificationType) => { dispatch({ type: rts.handleDataModificationSelect, dispatch, dataModificationType, subModificationType }) },
    handleDeleteDataItems: (selectedItems) => { dispatch({ type: rts.handleDeleteDataItems, dispatch, selectedItems }) },
    handleGetModifyProps: (editableItem, props_viewItem, subEditProps, dataModificationType) => { dispatch({ type: rts.handleGetModifyProps, dispatch, editableItem, props_viewItem, subEditProps, dataModificationType }) },
    handleSaveCreateList: (createListProps) => { dispatch({ type: rts.handleSaveCreateList, dispatch, createListProps }) },
    handleSaveSort: () => { dispatch({ type: rts.handleSaveSort, dispatch }) },
    handleSetLinkItem: (linkItem, actionItem) => { dispatch({ type: rts.handleSetLinkItem, dispatch, linkItem, actionItem }) },
    handleShowAdditional: (fd) => { dispatch({ type: rts.handleShowAdditional, dispatch, fd }) },
    handleSorted: (ddGroups) => { dispatch({ type: rts.handleSorted, dispatch, ddGroups }) },
    handleUpdateDataItem: (settingsProps) => { dispatch({ type: rts.handleUpdateDataItem, dispatch, settingsProps }) },
    handleUpdateDataItems: (selectedItems) => { dispatch({ type: rts.handleUpdateDataItems, dispatch, selectedItems }) },
    handleUpdateLinkItems: (preview) => { dispatch({ type: rts.handleUpdateLinkItems, dispatch, preview }) },
    updateCurrentFormData: (data_current, dataModifyReady) => { dispatch({ type: rts.updateCurrentFormData, dispatch, data_current, dataModifyReady }) },
    ...responseHandlers(dispatch)
  }
}

/**
 * returns and object containing information about the update
 * @param {string} type 
 * @param {object} state 
 * @param {object} action 
 * @returns 
 */
const getRequestProps = (type, state, action) => {

  const { vldSorted, viewItem, data_current, itemRating, appData, paps_state, modifyProps } = state
  const { dataSource, dataConstraints } = viewItem ? viewItem : {}
  const { dataCollectionName, altDataCollectionName } = dataSource ? dataSource : {}
  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  const vld = appData

  let _vit = viewItem.key
  if (dataCollectionName) { _vit = dataCollectionName }
  if (altDataCollectionName) { _vit = altDataCollectionName }

  switch (type) {

    case rts.handleAmmendDataItems:
      const ammendItem = vld[Object.keys(vld)[0]]
      return {
        ammendType: action.ammendType,
        dataUpdateType: gEnums.dataUpdateTypes.ammend,
        id: ammendItem.id,
        itemData: ammendItem,
        selectedItems: action.selectedItems,
        vit: viewItem.key,
        vld: action.vld
      }

    case rts.handleDeleteDataItems:
      const deleteItem = vld[Object.keys(vld)[0]]
      return {
        dataUpdateType: gEnums.dataUpdateTypes.delete,
        id: deleteItem.id,
        itemData: deleteItem,
        selectedItems: action.selectedItems,
        vit: viewItem.key,
      }

    case rts.handleAddDataItem:
      let _itemData = { ...data_current };
      if (_dataConstraints) {
        const { view: pageName, viewKey: pageKey } = paps_state ? paps_state : {}
        if (pageName && pageKey) {
          switch (_dataConstraints.pageConstraintType) {
            case gEnums.pageConstraintTypes.arrayContains:
              _itemData[pageName] = [pageKey]
              break;
            case gEnums.pageConstraintTypes.equalTo:
              _itemData[pageName] = pageKey
              break;
            default:
            // nothing
          }
        }
      }

      if (modifyProps && _itemData) {
        Object.keys(_itemData).forEach(pk => {
          if (_itemData[pk] && modifyProps[pk]) {
            const mp = modifyProps[pk]
            const { data } = mp ? mp : {}
            const { isParentKey } = data ? data : {}
            if (isParentKey && _itemData[pk]) {
              if (!_itemData.parentKeys) { _itemData.parentKeys = {} }
              _itemData.parentKeys[pk] = _itemData[pk]
              delete _itemData[pk]
            }
          }
        })
      }

      return {
        dataUpdateType: gEnums.dataUpdateTypes.add,
        id: null,
        itemData: _itemData,
        vit: _vit,
      }

    case rts.handleUpdateDataItem:
      return {
        dataUpdateType: gEnums.dataUpdateTypes.edit,
        id: null,
        itemData: data_current,
        vit: _vit,
      }

    case rts.handleUpdateDataItems:
      return {
        dataUpdateType: gEnums.dataUpdateTypes.edit,
        id: null,
        selectedDataItems: action.selectedItems,
        vit: _vit,
      }

    case rts.handleSaveCreateList:
      return {
        createListProps: action.createListProps,
        dataUpdateType: gEnums.dataUpdateTypes.createList,
        vit: action.createListProps.createListAs,
      }

    case rts.handleSaveSort:
      const upd = createObject(vldSorted, 'id', false, true)
      return {
        dataUpdateType: gEnums.dataUpdateTypes.updateSort,
        id: null,
        itemData: upd,
        vit: _vit,
      }

    case rts.handleSaveDataItemRating:
      const ratingItem = vld[Object.keys(vld)[0]]
      return {
        dataUpdateType: gEnums.dataUpdateTypes.rate,
        id: ratingItem.id,
        itemData: ratingItem,
        rating: itemRating,
        vit: _vit,
      }
    case rts.handle_fsUpdateDbFromGoogleSheet:
      return {
        dataUpdateType: gEnums.dataUpdateTypes.add,
        id: null,
        selectedDataItems: action.selectedItems,
        vit: _vit,
      }
    case rts.handle_fsUpdateRequestAccess:
      return {
        appUser: action.appUser,
        dataUpdateType: gEnums.dataUpdateTypes.updateAccessRequest,
        modifyType: gEnums.dataUpdateTypes.updateAccessRequest,
        pathCaption: action.currentPageDataCaption,
        pathName: action.pathName,
      }
    default:
      break;
  }
}

/**
 * 
 * @param {object} props 
 * @returns the props that are allowed to be modified.
 */
const getModifyProps = (props) => {

  const { dataModificationType, editableItem, props_viewItem, subEditProps, restrictArrayEdits } = props

  let props_modify = {}

  switch (dataModificationType) {
    case dataModificationTypes.add:
      if (props_viewItem) {
        Object.keys(props_viewItem).forEach(key => {
          const pi = props_viewItem[key]
          const { data } = pi ? pi : []
          const { restrictAdd, ignoreOnAddEdit } = data ? data : {}
          if (!restrictAdd || ignoreOnAddEdit) {
            props_modify[key] = pi
          }
        })
      }
      break;

    case dataModificationTypes.edit:
      if (editableItem) {
        Object.keys(editableItem).forEach(k => {
          props_modify[k] = editableItem[k]
          props_modify[k].propSection = 'name'
        })
      } else {
        if (subEditProps && Object.keys(subEditProps).length > 0 && props_viewItem) {
          Object.keys(props_viewItem).forEach(propKey => {
            if (subEditProps.includes(propKey)) {
              props_modify[propKey] = props_viewItem[propKey]
            }
            addDefaultProp(props_viewItem, props_modify, 'name')
            addDefaultProp(props_viewItem, props_modify, 'firstName')
            addDefaultProp(props_viewItem, props_modify, 'lastName')
          })
        } else {
          if (props_viewItem) {
            Object.keys(props_viewItem).forEach(key => {
              const pi = props_viewItem[key]
              const { data } = pi ? pi : []
              const { restrictEdit, ignoreOnAddEdit } = data ? data : {}
              if (!restrictEdit || ignoreOnAddEdit) {
                props_modify[key] = pi
              }
            })
          }
        }
      }
      break;
    default:
      props_modify = props_viewItem
    // nothing
  }
  return props_modify
}

const addDefaultProp = (props_viewItem, mps, propKey) => {
  if (props_viewItem[propKey] && !mps[propKey]) {
    mps[propKey] = props_viewItem[propKey]
    mps[propKey].data.readOnly = true
  }
}

const addPropToProps = (itemProps, name) => {
  const _itemProps = { ...itemProps }
  const pd = _.filter(_itemProps, { propSection: 'details' })
  const pdCount = pd ? pd.length : 0
  _itemProps[_.camelCase(name)] = addProp(name, pdCount + 1)
  return _itemProps
}

const addProp = (key, existingCount) => {
  return {
    position: existingCount ? existingCount : 2,
    show: true,
    display: {
      caption: _.startCase(key),
      elemPropType: gEnums.elemPropTypes.normal
    },
    data: {
      formItemType: gEnums.formItemTypes.text,
    },
    propSection: 'details'
  }
}