import _ from 'lodash';
import { ammendComplexObject, getComplexObjectKeys } from '../../../common/sorting';
import { getTempSettingsState } from '../../../firestoreData/settings/ammendSettings';
import { updateMenuItemSettings } from '../../../viewSettings/components/menus/menuItemHelpers';
import { vsComponentTypes } from '../../../viewSettings/enums/vsComponentTypes';
import { setViewSettingsSortPositions, sortViewSettingItems } from '../../../viewSettings/helpers/sorts';

const removeEmptyValues = false

/**
 * Ammends the temp settings
 * @param {object} props 
 * @returns settings_temp_update
 */
export const ammendViewSettings = (props) => {

  const {
    desktopMode,
    formData,
    isGlobal,
    isGlobalPageItem,
    logging,
    menuType,
    newViews,
    propSections,
    settings_globalPageItems,
    settings_temp_action,
    settings_temp,
    sortedGroups,
    sortedItems,
    sortedPropItems,
    sortedPropSections,
    svsGv,
    updateProps,
  } = props

  // let {
  //   settings_temp_global
  // } = props

  let { _global, _viewItems } = getTempSettingsState(settings_temp, isGlobal)

  const {
    attachToParent,
    dataListType,
    formKey,
    menuItemMod,
    isList,
    parentSettingKey,
    pit,
    psg,
    subKey,
    swipedItems,
    viProp,
    viProps,
    vit,
  } = updateProps ? updateProps : {}

  const swipedItem_first = swipedItems && swipedItems[0] ? swipedItems[0] : null
  const init_viType = swipedItem_first ? swipedItem_first.viType : null

  // determine the setting to update

  let settings_temp_update = settings_temp

  if ((isGlobalPageItem || init_viType === vsComponentTypes.globalPageItems) && settings_globalPageItems) {
    settings_temp_update = { viewItems: settings_globalPageItems }
    _viewItems = settings_globalPageItems
    // settings_temp_global = settings_globalPageItems
  }

  if (settings_temp_action || svsGv) {
    // when the full settings_temp is passed in.
    settings_temp_update = isGlobalPageItem ? svsGv : settings_temp_action
  } else if (sortedGroups && menuType) {
    // ammend the sort positions
    setViewSettingsSortPositions(menuType, _viewItems, vit, sortedGroups)
  } else if (sortedItems || sortedPropItems || sortedPropSections) {
    // ammend the sort positions
    sortViewSettingItems(sortedItems, sortedPropItems, sortedPropSections, _viewItems, propSections, vit)
  } else if (viProps) {
    // set the viProps directly
    _viewItems[vit].props = viProps
    // NEW
  } else if (menuItemMod) {
    updateMenuItemSettings(updateProps, menuItemMod, settings_temp_update, _viewItems, desktopMode)
  } else if (viProp) {
    if (_viewItems[vit].show === true || viProp === 'removed') {
      _viewItems[vit][viProp] = !_viewItems[vit][viProp]
    }
  } else if (isList) {
    // if (viewDataSwipedItems) {
    //   const sks = getComplexObjectKeys(viewDataSwipedItems, 'dKey')
    //   ammendComplexObject(settings_temp, sks, formData, isList)
    // }
  } else {
    // does it have a `formKey`
    if (formKey) {
      ammendFormValue(
        {
          _global,
          _viewItems,
          attachToParent,
          dataListType,
          formData,
          formKey,
          parentSettingKey,
          pit,
          psg,
          subKey,
          swipedItems,
          vit,
        }
      )
    }
  }

  return settings_temp_update
}

/** Changes the value of the selected setting */
const ammendFormValue = (props) => {

  const {
    _global,
    _viewItems,
    attachToParent,
    dataListType,
    formData,
    formKey,
    parentSettingKey,
    pit,
    psg,
    subKey,
    swipedItems,
    vit,
  } = props

  const deleteEmpties = true

  // does it have a `viewItemType`
  if (vit) {
    const viewItem = _viewItems[vit]
    if (viewItem) {
      if (!viewItem[formKey]) { viewItem[formKey] = {} }
      if (pit) { // does it have a `propItemType`
        const _prop = viewItem.props[pit]
        if (_prop) {
          if (subKey) {
            if (!_prop[formKey]) { _prop[formKey] = {} }
            _prop[formKey][subKey] = formData
          } else {
            _prop[formKey] = formData
          }
        }
      } else if (psg) { // does it have a `propSettingType`
        const _propSection = viewItem.propSections[psg]
        if (_propSection) {
          if (subKey) {
            if (!_propSection[formKey]) { _propSection[formKey] = {} }
            _propSection[formKey][subKey] = formData
          } else {
            _propSection[formKey] = formData
          }
        }
      } else {
        if (subKey) { // does it have a `subKey` parentSettingKey???
          if (!viewItem[formKey]) { viewItem[formKey] = {} }
          viewItem[formKey][subKey] = formData
          if (removeEmptyValues && _.isEmpty(viewItem[formKey][subKey])) {
            if (deleteEmpties) { delete viewItem[formKey][subKey] }
          }
        } else {
          viewItem[formKey] = formData
        }
      }
    } else {
      console.log('NO VIEW ITEM')
    }
  } else {
    if (attachToParent) {
      if (!_global[formKey]) { _global[formKey] = {} }
      if (!_global[formKey][dataListType]) { _global[formKey][dataListType] = {} }
      _global[formKey][dataListType] = formData
    } else {
      if (parentSettingKey) {
        if (swipedItems) {
          const sks = getComplexObjectKeys(swipedItems, 'settingKey')
          ammendComplexObject(_global, sks, formData)
        }
      } else {
        // set _global.formKey to the formData
        _global[formKey] = formData
      }
    }
  }
}
