import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { uniqueKey } from '../../common/keys';
import Help from '../../components/help/Help';
import { getTempSettingsState } from '../../firestoreData/settings/ammendSettings';
import ViewSettingsMenu from '../../viewSettings/ViewSettingsMenu';
import { helpTypes } from '../reducers/HelpReducer';
import { viewSettingsMenuHandlers, viewSettingsMenuInitialState, viewSettingsMenuReducer } from '../reducers/ViewSettingsMenuReducer';
import { SettingParentContext } from './SettingParentContext';
import { SettingsAreaContext } from './SettingsAreaContext';
import { ViewSettingsComponentContext } from './ViewSettingsComponentContext';
import { ViewSettingsContext } from './ViewSettingsContext';

const _showState = false

/**
 * @state (`appUserAccess`, `baseSettings_state`, `data_current`, `desktopMode`, `menuTypeKey`, `pathViews`, `parentDefaults`, `settings_globalPageItems`, `settingsArea_fns`, `themeColors`, `viewSettings_handlers`, `vsc_state`)
 * @handlers (`handleSetViewItems`, `handleUpdateMenu`) 
*/
export const ViewSettingsMenuContext = createContext();

const ViewSettingsMenuProvider = (props) => {

  const { menuTypeKey } = props

  const settingsParentContext = useContext(SettingParentContext)
  const { settings, states } = settingsParentContext ? settingsParentContext : {}
  const { appUser_state, appSettings_state, baseSettings_state, framework_state, global_state, paps_state } = states ? states : {}
  const { desktopMode } = framework_state ? framework_state : {}
  const { pathViews, appArea } = paps_state
  const { eventSettings } = settings ? settings : {}
  const { global } = eventSettings ? eventSettings : {}
  const { parentDefaults } = global ? global : {}
  const { helpOn } = appSettings_state ? appSettings_state : {}
  const { settings_globalPageItems } = global_state ? global_state : {}
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext);
  const { settingsArea_state, settingsArea_fns } = settingsAreaContext ? settingsAreaContext : {}

  // viewSettingsSwipeContext 
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state, viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}
  const { settings_temp, settingsStatus } = viewSettings_state ? viewSettings_state : {}

  // viewSettingsComponentContext
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { isGlobal, data_current } = vsc_state ? vsc_state : {}

  const hvps = [menuTypeKey]

  const { _global } = getTempSettingsState(settings_temp, isGlobal)
  const { themeColors } = _global ? _global : {}

  const initState = {
    appArea,
    appUserAccess,
    settingsArea_state,
    baseSettings_state,
    data_current,
    desktopMode,
    menuTypeKey,
    pathViews,
    parentDefaults,
    settings_globalPageItems,
    settingsArea_fns,
    themeColors,
    viewSettings_handlers,
    vsc_state,
  }
  const [vsm_state, dispatch] = useReducer(viewSettingsMenuReducer, viewSettingsMenuInitialState(initState));
  const vsm_handlers = viewSettingsMenuHandlers(dispatch)
  const { viewItems } = vsm_state

  if (_showState) {
    console.log(Object.keys(vsm_state))
    console.log(Object.keys(vsm_handlers))
  }

  // settingsStatus toggles between 'a` and `b` so that the child component re-render
  useEffect(() => {
    // sets the viewItems as well as getting the info from the settingsConsole_home_global  
    vsm_handlers.handleSetViewItems(settings_temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsStatus]);

  const vsmProvider = () => <ViewSettingsMenuContext.Provider value={{ vsm_state }}>
    {helpOn && <Help helpType={helpTypes.topics} helpKey={menuTypeKey} hvps={hvps} />}
    <ViewSettingsMenu key={uniqueKey('vsmc', 'vsm', menuTypeKey)} />
  </ViewSettingsMenuContext.Provider>


  return viewItems ? vsmProvider() : <div></div>
}

export default ViewSettingsMenuProvider
