import React, { useContext } from 'react';
import { Dimmer, Header } from 'semantic-ui-react';
import { StartContext } from '../../cnr/contexts/StartContext';
import { startTexts } from '../../cnr/reducers/StartReducer';
import AnimateComponent, { _animateTypes } from '../../motions/AnimateComponent';
import CircleLoader from '../../motions/CircleLoader';

const StartDimmer = () => {

  // startContext
  const startContext = useContext(StartContext)
  const { start_state } = startContext ? startContext : {}
  const { startCaption, startType } = start_state ? start_state : {}

  const dimmer = () => <Dimmer className={'start-dimmer'} active={true}>
    <Header as='h4' inverted>
      {startCaption ? startCaption : startTexts.app}
    </Header>
    <CircleLoader startType={startType} />
  </Dimmer>

  // do not use transitions
  return <AnimateComponent animateType={_animateTypes.start} >
    {dimmer()}
  </AnimateComponent>

}

export default StartDimmer
