import React, { useContext, useEffect, useState } from 'react';
import { Label, Segment } from 'semantic-ui-react';
import { ActionContext } from '../../cnr/contexts/ActionContext';
import { DataModifyContext } from '../../cnr/contexts/DataModifyContext';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';

const ConfirmationQuestion = (props) => {

  const { noModal, actionFunction } = props ? props : {}

  // actionContext
  const actionContext = useContext(ActionContext)
  const { action_handlers, action_state } = actionContext ? actionContext : {}

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_handlers, dataModify_state } = dataModifyContext ? dataModifyContext : {}
  const _dataModify_state = dataModify_state ? dataModify_state : {}
  const _dataModify_handlers = dataModify_handlers ? dataModify_handlers : {}

  const { questionProps } = action_state ? action_state : _dataModify_state
  const { handleCommitYes, handleCommitNo } = action_handlers ? action_handlers : _dataModify_handlers

  const handleNo = () => {
    if (props.handleNo) {
      props.handleNo()
    } else if (handleCommitNo) {
      handleCommitNo()
    }
  }

  const handleYes = () => {
    if (props.handleYes) {
      props.handleYes()
    } else if (handleCommitNo) {
      handleCommitYes()
    }
  }

  const [header, setHeader] = useState()
  const [question, setQuestion] = useState('Test')

  useEffect(() => {
    if (props.header) {
      setHeader(props.header)
    } else {
      setHeader(questionProps ? questionProps.header : 'Header Missing')
    }
    if (props.question) {
      setQuestion(props.question)
    } else {
      setQuestion(questionProps ? questionProps.question : 'Question Missing')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props]);

  const content = () => <Segment basic inverted>
    {question}
    {actionFunction && <Label color={'teal'} size={'tiny'} style={{ marginTop: '1em' }}>{actionFunction}</Label>}
  </Segment>

  const footer = () => <UiSaveButtons
    save={{ oc: handleYes, caption: 'Yes' }}
    cancel={{ oc: handleNo, caption: 'No' }}
  ></UiSaveButtons>

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
    asSegment={true}
    css={{ header: 'inverted', content: 'inverted', footer: 'inverted', container: 'cmq' }}
  />

  return noModal ? wrapper() : <FullPageWrapper
    content={wrapper()}
    handleCancel={handleNo}
    topperCaption={header}
  />
}

export default ConfirmationQuestion