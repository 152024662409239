import { createResult } from "../altFunctions/common";
import { dispatchProps } from "../cnr/reducers/reducerHelpers/dispatchProps";
import { gEnums } from "../enums/globalEnums";
import { addDataItemsToCollectionDatabase } from "../firestoreData/updates/subUpdates/addDataItemsToCollection";
import { callFunction } from "./fbAll";
import { fsfn_deleteClientCollection, fsfn_deleteFullEventFromDatabase } from "./fbDelete";

export const createModes = {
  create: 'create',
  recreate: 'recreate',
  update: 'update'
}

const createGenericTypes = {
  createGeneric: 'createGeneric',
}

const createFunctionTypes = {
  createClient: 'createClient',
  createEvent: 'createEvent',
  createProject: 'createProject',
  createViewItem: 'createViewItem',
  createViewItemProps: 'createViewItemProps',
  createViewItemPropSection: 'createViewItemPropSection',
  createViewItems: 'createViewItems',
  createViews: 'createViews',
}

/**
 * 
 * @param {object} callData 
 * @returns - the `data` object from the function call
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(createGenericTypes.createGeneric, callData)
  return data
}

/**
 * No callback
 * @param {object} state {previewInfo, pathViews, storageRootPath}
 * @param {function} callback 
 * @returns createResult
 * @description deletes and then recreates the event in the database
 */
export const fsfn_recreateEventInDatabase = async (state, callback) => {
  const { previewInfo, pathViews, storageRootPath, databaseProjectId } = state ? state : {}
  const { projectData } = previewInfo ? previewInfo : {}
  const { dataCollections } = projectData ? projectData : {}
  if (dataCollections) {
    const viewItemKeys = Object.keys(dataCollections)
    const response_data = await fsfn_deleteFullEventFromDatabase(pathViews, viewItemKeys, storageRootPath)
    const { success, error } = response_data
    if (success) {
      if (success) {
        previewInfo.projectData.eventData.id = pathViews.events
        const response_data_2 = fsfn_createEventInDatabase(previewInfo, true, null, pathViews, databaseProjectId)
        return response_data_2
      } else if (error) {
        return createResult(false, {}, error)
      }
    } else {
      return createResult(false, {}, error)
    }
  }
}

/**
 * This will create an event in the database using `createEvent` in functions
 * @param {*} allowUpdates 
 * @param {*} createInfo 
 * @param {*} logging 
 * @returns response_data {success, result, error}
 */
export const fsfn_createEventInDatabase = async (createInfo, recreate, logging, pathViews, databaseProjectId, addModeType) => {
  try {
    // const { createInfo, showLogs, useGenericId } = data ? data : {}
    const callData = {
      createFunctionType: createFunctionTypes.createEvent,
      createInfo,
      showLogs: logging && logging.allowLogging && logging.logCreateEvent,
      useGenericId: recreate ? false : true,
      databaseProjectId,
      addModeType
    }

    switch (addModeType) {
      case gEnums.addModeTypes.googleSheetsOnly:
        callData.createInfo.projectSettings.global.appDataSource.appDataSourceType = gEnums.dataSourceTypes.googleSpreadSheet
        break;
      default:
      // nothing
    }


    const { projectData } = createInfo
    const { eventData } = projectData

    // give the event an id
    if (eventData && !eventData.id && eventData.name) { eventData.id = eventData.name }
    createInfo.mode = recreate ? createModes.recreate : createModes.create

    if (recreate) {
      const viewItemKeys = createInfo && createInfo.projectData && createInfo.projectData.dataCollections ? Object.keys(createInfo.projectData.dataCollections) : []
      // delete the event first
      const response_data = await fsfn_deleteFullEventFromDatabase(pathViews, viewItemKeys, null, true)
      const { success } = response_data
      if (success) {
        const response_data = await callFsFunction(callData)
        return response_data
      }
    } else {
      const response_data = await callFsFunction(callData)
      return response_data
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * Creates a client in the database
 * @param {object} callData (createInfo, forProd)
 * @returns  
 */
export const fsfn_createClientInDatabase = async (callData) => {
  try {
    // const { createInfo } = callData
    // const { projectData } = createInfo ? createInfo : {}
    // const { clientData } = projectData ? projectData : {}
    // const { name } = clientData ? clientData : {}

    callData.createFunctionType = createFunctionTypes.createClient
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/** Creates the settings for a new client using functions */
export const fsfn_createClientProjectSettings = async (data, callback, logging) => {
  try {
    const callData = { createFunctionType: createFunctionTypes.createProject, initialData: { ...data.viewItems }, showLogs: logging && logging.allowLogging && logging.logCreateClientProject }
    callData.createClient = true
    callData.initialData.clients.dataItems = { name: data.clientName }
    callData.clientName = data.clientName
    const response_data = await callFsFunction(callData, true) // OK 
    if (callback) { callback(response_data) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * This will create the eventInfo using firebase functions `createProject`. This does NOT update the database
 * @param {*} callData ( clientName, createClient, direct, eventName, initialData, keepRowIds, showLogs)
 * @returns response_data {success, result, error}
 */
export const fsfn_createProject = async (callData) => {

  try {
    callData.showLogs = true
    callData.createFunctionType = createFunctionTypes.createProject
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {array} viewItemKeys 
 * @param {object} existingViewItems 
 * @description - creates new viewItems (result) based on the viewItemKeys and adds them the existingViewItems
 */
export const fsfn_createViewItems = async (viewItemKeys, existingViewItems, newProps) => {
  try {
    const callData = { createFunctionType: createFunctionTypes.createViewItems, newProps, viewItemKeys, existingViewItemKeys: Object.keys(existingViewItems) }
    const { success, result: viewItems } = await callFsFunction(callData) // OK
    if (success && viewItems) {
      Object.keys(viewItems).forEach(key => {
        existingViewItems[key] = viewItems[key]
      })
    }
  } catch (error) {
    console.error('fsfn_createViewItems: error', error)
  }
}

/**
 * 
 * @param {string} viKey 
 * @param {boolean} forGlobal 
 * @param {object} existingViewItems 
 * @description Creates a new viewItem and adds it the existingViewItems
 */
export const fsfn_createViewItem = async (viKey, forGlobal, existingViewItems) => {
  try {
    const callData = { createFunctionType: createFunctionTypes.createViewItem, viKey, forGlobal, createGenericProps: true, createPropSections: true }
    const { success, result: viewItem } = await callFsFunction(callData) // OK
    if (success && viewItem) {
      existingViewItems[viKey] = viewItem
    }
  } catch (error) {
    console.error('fsfn_createViewItem: error', error)
  }
}

/**
 * 
 * @param {object} viewItems - List of existing viewItems. These are objects
 * @param {object} existingViews 
 * @description Creates a new viewItem and adds it the existingViews
 */
export const fsfn_createViews = async (viewItems, existingViews, directReturn, newProps) => {
  try {
    // viewItems, existingViews, directReturn
    const callData = { createFunctionType: createFunctionTypes.createViews, viewItems, existingViews, directReturn, newProps }
    const { success, result: newViews } = await callFsFunction(callData) // OK
    if (success && newViews) {
      Object.keys(newViews).forEach(key => {
        const newView = newViews[key]
        existingViews[key] = newView
        // add the new view item to the event's view items
        // if (!viewItems_events[key] && newView.viewItems && newView.viewItems[key]) {
        //   viewItems_events[key] = newView.viewItems[key]
        //   viewItems_events[key].display.caption = _.startCase(key)
        //   viewItems_events[key].show = false
        //   viewItems_events[key].showDesktop = false
        // }
      })
    }
    return { views: newViews }
  } catch (error) {
    console.error(error)
  }
}


/** Create new props for the viewItem with the propSection and add them to existingProps after */
// newProps, propSection:
export const fsfn_createViewItemProps = async (propsToAdd, existingProps, propSection) => {
  try {
    const callData = { createFunctionType: createFunctionTypes.createViewItemProps, newProps: propsToAdd, propSection } //existingProps
    const { success, result: props } = await callFsFunction(callData) // OK 
    if (success && props) {
      props.forEach(prop => {
        existingProps[prop.key] = prop
      })
    }
  } catch (error) {
    console.error(error)
  }
}

/** Create new props for the viewItem */
export const fsfn_createViewItemPropSection = async (name, count, propSections) => {
  const callData = { createFunctionType: createFunctionTypes.createViewItemPropSection, name, count, propSections }
  try {
    const { success, result: section } = await callFsFunction(callData) // OK
    if (success && section) {
      propSections[name] = section
    }
  } catch (error) {
    console.error(error)
  }
}



/**
 * 
 * @param {string} pathViews The database path for the event
 * @param {array} dataCollections List of collection items to be updated
 * @param {function} handleUpdating The callback function to notify the UI
 * @param {function} dispatch The callback function when complete
 * @description Loops through the `dataCollections` and adds them using 
 */
export const fsfn_createEventCollectionsInDatabase = async (pathViews, dataCollections, deletePrior, handleUpdating, dispatch) => {
  try {
    Object.keys(dataCollections).forEach(async (dcKey, index) => {
      const dataToUpdate = dataCollections[dcKey]
      handleUpdating && handleUpdating(dcKey)
      if (deletePrior) {
        await fsfn_deleteClientCollection(pathViews, dcKey, 100)
      }
      addDataItemsToCollectionDatabase(pathViews, dcKey, dataToUpdate, false, dispatch)
    })
  } catch (err) {
    dispatch && dispatch({ type: dispatchProps.error, error: err })
  }
}


