// import { dataUpload } from '../../firestoreData/dataUpload';

const rts = {
  handlePageCaption: 'handlePageCaption',
  handleTitleProgress: 'handleTitleProgress',
  handleSetPageDataAction: 'handleSetPageDataAction',
  handleSubCaption: 'handleSubCaption',
  handleSportsCaption: 'handleSportsCaption',
  handleUpdateHeader: 'handleUpdateHeader',
}

export const headerReducer = (state, action) => {

  const { mainCaption, subCaption, type } = action
  const { appCaptions } = state
  const _appCaptions = appCaptions ? appCaptions : {}

  switch (type) {

    case rts.handleUpdateHeader:
      const _apcs = { ..._appCaptions, ...action.appCaptions }
      _apcs.sub = null
      return { ...state, appCaptions: _apcs }

    case rts.handlePageCaption:
      if (mainCaption) {
        _appCaptions.main = mainCaption
        return { ...state, appCaptions: _appCaptions }
      } else {
        return { ...state }
      }

    case rts.handleSubCaption:
      if (_appCaptions.main === subCaption) {
        return { ...state }
      } else {
        _appCaptions.sub = subCaption
        return { ...state, appCaptions: _appCaptions }
      }

    case rts.handleSportsCaption:
      _appCaptions.year = action.yearCaption
      return { ...state, appCaptions: _appCaptions }

    case rts.handleSetPageDataAction:
      return { ...state, allowItemLinking: action.allowItemLinking, actionCaption: action.actionCaption, itemLinked: action.includes }

    case rts.handleTitleProgress:
      const { progress } = action
      const fontSizes = {
        title: 1.6,
        subTitle: 1,
        hideSubTitle: false
      }
      const fontPerc = 1 - progress;
      const fp = fontPerc.toFixed(2)
      fontSizes.title = fontSizes.title * fp
      fontSizes.subTitle = fontSizes.subTitle * fp
      if (fontSizes.title < 1) { fontSizes.title = 1 }
      if (fontSizes.subTitle < .4) {
        fontSizes.subTitle = .4
        fontSizes.hideSubTitle = true
      }
      return { ...state, headerFontSizes: fontSizes }

    default:
      return { ...state }
  }
}

export const headerInitialState = (init_state) => {
  const { appCaptions, allowSiteSearch, menuTitleMax } = init_state
  return { appCaptions, allowSiteSearch, menuTitleMax, itemCount: null }
};

export const headerHandlers = (dispatch, state) => {
  return {
    handlePageCaption: (mainCaption) => { dispatch({ type: rts.handlePageCaption, mainCaption }) },
    handleTitleProgress: (progress) => { dispatch({ type: rts.handleTitleProgress, progress }) },
    handleSetPageDataAction: (allowItemLinking, actionCaption, includes) => dispatch({ type: rts.handleSetPageDataAction, allowItemLinking, actionCaption, includes }),
    handleSubCaption: (subCaption) => { dispatch({ type: rts.handleSubCaption, subCaption }) },
    handleUpdateHeader: (appCaptions) => { dispatch({ type: rts.handleUpdateHeader, appCaptions }) },
    handleSportsCaption: (yearCaption) => { dispatch({ type: rts.handleSportsCaption, yearCaption }) },
  }
}