import React from 'react'
import { Icon, Message } from 'semantic-ui-react'

const NotificationMessage = (props) => {

  const { notification } = props

  const notificationDiv = () => {
    if (notification.title && notification.content) {
      return <Message icon size='tiny'>
        <Icon name='info circle' color='blue' />
        <Message.Content>
          <Message.Header>{notification.title}</Message.Header>
          {notification.content}
        </Message.Content>
      </Message>
    } else {
      return <Message icon size='tiny'>
        <Icon name='info circle' color='blue' />
        <Message.Content>
          {/* <Message.Header>{notification}</Message.Header> */}
        </Message.Content>
      </Message>
    }
  }

  return notificationDiv()

}

export default NotificationMessage