import React, { useContext, useEffect, useReducer } from "react";
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sportsDistrictHandlers, sportsDistrictInitialState, sportsDistrictReducer } from "../reducers/SportsDistrictReducer";

export const SportsDistrictContext = React.createContext()

export default function SportsDistrictProvider(props) {

  const { directReturn } = props ? props : {}

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state } = states ? states : {}

  // appUserContext
  const { appUser } = appUser_state ? appUser_state : {}
  const { uid } = appUser ? appUser : {}

  // papsContext 
  const { view, pathViews } = paps_state ? paps_state : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_page } = pageSettings ? pageSettings : {}

  const { viewItems: viewItems_page } = aps_page ? aps_page : {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ? viewItem : {}
  const { dataParents, useSeasonals } = dataSource ? dataSource : {}

  // organizations/districts/sports
  // organizations/sports

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const init_state = { pathViews, viewItem, dataParents, useSeasonals, uid, ownerPath, inverted: false, cssOpts: { name: 'ssc-normal' } }
  const [sportsDistrict_state, sportsDistrict_dispatch] = useReducer(sportsDistrictReducer, init_state, sportsDistrictInitialState)
  const sportsDistrict_handlers = sportsDistrictHandlers(sportsDistrict_dispatch, sportsDistrict_state)

  useEffect(() => {
    if (pathViews.districts) {
      sportsDistrict_handlers.handleSportsDistrictInit(pathViews.districts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews.districts])

  if (sportsDistrict_state) {
    if (directReturn) {
      // const appStaticViews = createAppStaticViews(golfEvent_state, clientViKeys, aps_viewItems)
      return { sportsDistrict_handlers, sportsDistrict_state }
    } else {
      return <SportsDistrictContext.Provider
        value={{ sportsDistrict_state, sportsDistrict_handlers, appComponent: 'sportsDistrict' }}>
        {props.children}
      </SportsDistrictContext.Provider>
    }
  } else {
    return <SportsDistrictContext.Provider
      value={{}}>
      {props.children}
    </SportsDistrictContext.Provider>
  }
}