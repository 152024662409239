import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Label, List, Segment } from 'semantic-ui-react';
import { getAlphaList, getItemName } from '../common/convert';
import AlphabetMenu from '../components/menus/AlphabetMenu';
import { ParentContext } from '../cnr/contexts/ParentContext';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import AppUserCard from './AppUserCard';
import { uniqueKey } from '../common/keys';

/**
 * 
 * @param {object} props (items, handleClick, showFound, found)
 * @returns A list of appUsers
 */
const AppUserList = (props) => {

  const { items, handleClick, showFound, showCount, found, viewOnly, listOnly } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ? parentContext : {}
  const { paps_state, page_state } = states ? states : {}
  const { appUser_handlers } = handlers ? handlers : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection, appUserViewTypeProp } = aps_appUserSettings ? aps_appUserSettings : {}
  const { projectOptions } = aps_global ? aps_global : {}
  const { showTimeDifferences } = projectOptions ? projectOptions : {}

  const itemCount = items ? Object.keys(items).length : 0

  const [alphaValue, setAlphaValue] = useState(65)
  const [currentDataList, setCurrentDataList] = useState()
  const [sortProp, setSortProp] = useState('name')

  useEffect(() => {
    handleAlphaChange(65)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [items]);

  const handleAlphaChange = (av) => {
    const _alphaChar = String.fromCharCode(av)
    const sortProp = 'lastName'
    if (items && av) {
      const { alphaList, sortProp: sp, firstAlpha } = getAlphaList(items, _alphaChar, sortProp)
      setAlphaValue(av)
      setCurrentDataList(alphaList)
      setSortProp(sp)
    }
  }

  const handleGoToEvent = (e) => e.stopPropagation()

  const handleSignInAs = (e, listItem, _itemKey) => {
    e.stopPropagation()
    const actionItem = { dataActionType: 'clone', cloneId: _itemKey, cloneVit: appUserCollection, appUserViewTypeProp: appUserViewTypeProp }
    appUser_handlers.handleClone(actionItem, listItem, paps_state, appUserCollection)
  }

  const appUserCard = (listItem, lblColor, index) => <AppUserCard
    key={uniqueKey('aul', index)}
    appUser={listItem}
    index={index}
    lblColor={lblColor}
    handleClick={handleClick}
    handleGoToEvent={handleGoToEvent}
    handleSignInAs={handleSignInAs}
    viewOnly={viewOnly}
    showTimeDifferences={showTimeDifferences}
  />

  const appUserCards = () => {
    let list = _.orderBy(currentDataList, ['lastName'])
    const cardItems = []
    if (list) {
      list.forEach((listItem, index) => {
        let lblColor;
        if (showFound) { lblColor = found ? 'green' : 'red' }
        cardItems.push(appUserCard(listItem, lblColor, index))
      })
    }
    return <Segment basic>
      {cardItems}
    </Segment>
  }

  const wrapper = () => <Wrapper
    header={showCount && <Label color={'green'}>{itemCount}</Label>}
    content={appUserCards()}
    wrapperType={wrapperTypes.paddedHeader}
  />

  const alphaWrapper = () => <Wrapper
    header={<AlphabetMenu dataItems={items} handleAlphaChange={handleAlphaChange} alphaValue={alphaValue} restrictToExisting={true} sortProp={sortProp} />}
    content={wrapper()}
    wrapperType={wrapperTypes.paddedFooter}
    css={{ container: 'app-user-list' }}
  ></Wrapper>

  const listItem = (item) => {
    const { email, metadata } = item
    const { lastSignInTime, creationTime, lastRefreshTime } = metadata ? metadata : {}
    const name = getItemName(item)
    return email ? <List.Item>
      <List.Icon name='user' verticalAlign='middle' />
      <List.Content>
        <List.Header >{name}</List.Header>
        <List.Description >{email}</List.Description>
      </List.Content>
      {metadata && <List.Content>
        <List.Header >{lastRefreshTime}</List.Header>
        <List.Description>{lastSignInTime}</List.Description>
        <List.Description>{creationTime}</List.Description>
      </List.Content>}
    </List.Item> : null
  }

  const list = () => {
    if (items) {
      const lis = Object.keys(items).map(key => {
        const item = items[key]
        return listItem(item)
      })
      return <List relaxed divided>{lis}</List>
    }
  }

  const content = () => {
    if (listOnly) {
      return list()
    } else {
      return alphaWrapper()
    }
  }

  return currentDataList ? content() : <div></div>
}

export default AppUserList
