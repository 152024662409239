
import { gEnums } from '../../enums/globalEnums';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { fs_add_doc, fs_delete_doc, fs_set_doc, fs_update_doc } from '../../firestoreData/appData/fsData';
import { updateGlobalAndViewsDBSettings } from '../../firestoreData/settings/updateSettings';
import { updateFirestoreData } from '../../firestoreData/updates/dataUpdates';
import { copyFirestore, pushFirestore } from '../../firestoreData/updates/subUpdates/copyFirestore';
import { fsfn_updateSettingsDb } from '../../functions/fbSettingsFunctions';
import { globalPageItems_update } from '../../viewSettings/actions/globalData';
import { responseReducers, grts } from './reducerHelpers/dispatchProps';

export const fsrts = {
  copyFirestoreData: 'copyFirestoreData',
  pushFirestoreData: 'pushFirestoreData',
  updateFirestoreData: 'updateFirestoreData',
  updateFirestoreDataDirect: 'updateFirestoreDataDirect',
  updateFirestoreSettings_globalAndViewsDb: 'updateFirestoreSettings_globalAndViewsDb',
  updateFirestoreSettings_db: 'updateFirestoreSettings_db',
  updateFirestoreSettings_globalViews: 'updateFirestoreSettings_globalViews',
  killConfirmation: 'killConfirmation',

  handle_addDoc: 'handle_addDoc',
  handle_deleteDoc: 'handle_deleteDoc',
  handle_setDoc: 'handle_setDoc',
  handle_updateDoc: 'handle_updateDoc',
  handle_getData: 'handle_getData',
  ...grts
}

export const firestoreReducer = (state, action, opts) => {

  const { page_fns, appUser, dataRestrictions } = state
  let { paps_state, type, dispatch, parentDispatch, ufProps, settings_temp, fullDbSettings, isGlobal, settings_temp_global, sandboxOn } = action ? action : {}

  if (opts) {
    if (opts.type) { type = opts.type }
    if (opts.paps_state) { paps_state = opts.paps_state }
    if (opts.ufProps) { ufProps = opts.ufProps }
    if (opts.dispatch) { parentDispatch = opts.dispatch }
  }

  switch (type) {

    case fsrts.updateFirestoreData:
      updateFirestoreData(parentDispatch, paps_state, ufProps, page_fns, appUser, dataRestrictions, opts)
      return { ...state }

    // updates using dispatchProps as the callback.
    // updateSuccess or updateError will get updated to the caller
    case fsrts.updateFirestoreDataDirect:
      updateFirestoreData(dispatch, paps_state, ufProps, page_fns, appUser, dataRestrictions)
      return { ...state }

    case fsrts.copyFirestoreData:
      copyFirestore(dispatch, paps_state, ufProps, page_fns)
      return { ...state }

    case fsrts.pushFirestoreData:
      pushFirestore(dispatch, paps_state, ufProps, page_fns)
      return { ...state }

    // from the Big Settings Check
    case fsrts.updateFirestoreSettings_db:
      // this is currently being executed internally
      fsfn_updateSettingsDb(dispatch, settings_temp, paps_state, isGlobal, sandboxOn, appUser).then(data_result => {
        return data_result
        // parentDispatch(res);
      })
      return { ...state }

    case fsrts.updateFirestoreSettings_globalViews:
      globalPageItems_update(settings_temp_global, parentDispatch)
      return { ...state }

    case fsrts.updateFirestoreSettings_globalAndViewsDb:
      updateGlobalAndViewsDBSettings(fullDbSettings, paps_state, sandboxOn, appUser, parentDispatch)
      return { ...state }

    case fsrts.handle_getData:
      // paths, wheres, callback, cbProps, opts
      fs_get_data(action.props)
      return { ...state }

    case fsrts.handle_addDoc:
      fs_add_doc(action.paths, action.data, action.callback)
      return { ...state }

    case fsrts.handle_deleteDoc:
      fs_delete_doc(action.paths, action.data, action.callback)
      return { ...state }

    case fsrts.handle_setDoc:
      fs_set_doc(action.paths, action.data, action.merge, action.callback)
      return { ...state }

    case fsrts.handle_updateDoc:
      fs_update_doc(action.paths, action.data, action.callback)
      return { ...state }

    case fsrts.killConfirmation:
      return { ...state, confirmation: null }

    case fsrts.updateSuccess:
    case fsrts.updateSuccessAlt:
    case fsrts.updateError:
      return responseReducers(state, action, {})

    default:
      return { ...state }
  }
}

export const firestoreHandlers = (dispatch) => {
  return {

    copyFirestoreData: (paps_state, ufProps) => { dispatch({ type: fsrts.copyFirestoreData, dispatch, paps_state, ufProps }) },
    pushFirestoreData: (paps_state, ufProps) => { dispatch({ type: fsrts.pushFirestoreData, dispatch, paps_state, ufProps }) },
    updateFirestoreData: (parentDispatch, paps_state, ufProps, parentDispatchProps) => { dispatch({ type: fsrts.updateFirestoreData, dispatch, parentDispatch, paps_state, ufProps, parentDispatchProps }) },
    updateFirestoreDataDirect: (paps_state, ufProps) => { dispatch({ type: fsrts.updateFirestoreDataDirect, dispatch, paps_state, ufProps }) },

    updateFirestoreSettings_globalAndViewsDb: (parentDispatch, paps_state, isGlobal, fullDbSettings) => { dispatch({ type: fsrts.updateFirestoreSettings_globalAndViewsDb, dispatch, parentDispatch, paps_state, isGlobal, fullDbSettings }) },
    updateFirestoreSettings_db: (parentDispatch, paps_state, isGlobal, settings_temp, sandboxOn, isSolo) => { dispatch({ type: fsrts.updateFirestoreSettings_db, dispatch, parentDispatch, paps_state, isGlobal, settings_temp, sandboxOn, isSolo }) },
    updateFirestoreSettings_globalViews: (parentDispatch, paps_state, settings_temp_global) => { dispatch({ type: fsrts.updateFirestoreSettings_globalViews, dispatch, parentDispatch, paps_state, settings_temp_global }) },

    updateSuccess: () => { dispatch({ type: fsrts.updateSuccess }) },
    updateError: (error) => { dispatch({ type: fsrts.updateError, error }) },
    killConfirmation: () => { dispatch({ type: fsrts.killConfirmation }) },

    handle_addDoc: (paths, data, callback) => { dispatch({ type: fsrts.handle_addDoc, dispatch, paths, data, callback }) },
    handle_deleteDoc: (paths, callback) => { dispatch({ type: fsrts.handle_deleteDoc, dispatch, paths, callback }) },
    handle_setDoc: (paths, data, merge, callback) => { dispatch({ type: fsrts.handle_setDoc, dispatch, paths, data, merge, callback }) },
    handle_updateDoc: (paths, data, callback) => { dispatch({ type: fsrts.handle_updateDoc, dispatch, paths, data, callback }) },
    handle_getData: (props) => { dispatch({ type: fsrts.handle_getData, dispatch, props }) },

  }
}

export const firestoreInitialState = (paps_state, staticViews, page_fns, authAppUser, appSettings_state, dataRestrictions) => {
  return { paps_state, staticViews, page_fns, authAppUser, appSettings_state, dataRestrictions }
};

export const getUfProps = (vit, itemId, itemData, modifyType, selectedDataItems, swipedItems) => {
  return {
    id: itemId,
    itemData,
    modifyType,
    dataUpdateType: modifyType,
    selectedDataItems,
    swipedItem: swipedItems,
    vit: vit,
  }
}

export const getUfCopyProps = (copyFrom, copyTo) => {
  return {
    copyFrom,
    copyTo,
    dataUpdateType: gEnums.dataUpdateTypes.copy,
  }
}

export const getUfPushProps = (pushFrom, pushTo) => {
  return {
    pushFrom,
    pushTo,
    dataUpdateType: gEnums.dataUpdateTypes.push,
  }
} 