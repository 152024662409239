import { serverTimestamp } from "firebase/firestore"
import _ from 'lodash'
import { createRefPath_event } from "../../firestoreData/appData/appRefPaths"
import { fs_get_data } from "../../firestoreData/appData/fsAppData"
import { fs_update_doc } from "../../firestoreData/appData/fsData"

const rts = {
  handleCheckInAppUser: 'handleCheckInAppUser',
  handleCheckedInAppUser: 'handleCheckedInAppUser',
  handleCloseScanner: 'handleCloseScanner',
  handleGetAppUser: 'handleGetAppUser',
  handleGetCheckIns: 'handleGetCheckIns',
  handleSetAppUser: 'handleSetAppUser',
  handleSetCheckIns: 'handleSetCheckIns',
  handleShowScanner: 'handleShowScanner',
  handleShowSelectedAppUser: 'handleShowSelectedAppUser',
  handleHideMessage: 'handleHideMessage',
}

export const _collectionName = '_credentialing'

export const credentialingReducer = (state, action) => {

  const {
    allowCredentialing,
    allowDirectScanValidation,
    appUserCollection,
    checkIns,
    pathViews,
  } = state

  const { dispatch, type } = action

  // eslint-disable-next-line
  const { handleSetCheckIns, handleSetAppUser, handleCheckedInAppUser } = credentialingHandlers(dispatch)

  switch (type) {

    case rts.handleGetCheckIns:
      if (allowCredentialing) {
        handleGetCheckIns(pathViews, handleSetCheckIns)
        return { ...state }
      } else {
        return { ...state, checkIns: {} }
      }

    case rts.handleSetCheckIns:
      return { ...state, checkIns: action.checkIns, currentTotals: getCounts(action.checkIns) }

    case rts.handleGetAppUser:
      handleGetAppUser(pathViews, appUserCollection, action.appUserKey, handleSetAppUser)
      return { ...state }

    case rts.handleSetAppUser:
      const _checkIns = { ...checkIns }
      _checkIns[action.currentAppUser._itemKey] = action.currentAppUser
      return { ...state, checkIns: _checkIns, currentTotals: getCounts(_checkIns), appUser_selected: action.currentAppUser, appUser_scanned: action.currentAppUser, showScanner: allowDirectScanValidation ? state.showScanner : false }

    case rts.handleCheckInAppUser:
      handleCheckIn(pathViews, action.currentAppUser, checkIns, handleCheckedInAppUser)
      return { ...state }

    case rts.handleCheckedInAppUser:
      return { ...state, appUser_selected: null, showMessage: false }

    case rts.handleHideMessage:
      return { ...state, showMessage: false }

    case rts.handleShowScanner:
      return { ...state, showScanner: !state.showScanner }

    case rts.handleCloseScanner:
      return { ...state, showScanner: null }

    case rts.handleShowSelectedAppUser:
      return { ...state, appUser_selected: action.currentAppUser }

    default:
      return { ...state }
  }
}

export const credentialingInitialState = (initState) => {
  return { ...initState }
};

export const credentialingHandlers = (dispatch) => {
  return {
    handleCheckedInAppUser: (currentAppUser, callback) => { dispatch({ type: rts.handleCheckedInAppUser, dispatch, currentAppUser }) },
    handleCheckInAppUser: (currentAppUser, callback) => { dispatch({ type: rts.handleCheckInAppUser, dispatch, currentAppUser }) },
    handleCloseScanner: () => { dispatch({ type: rts.handleCloseScanner, dispatch }) },
    handleGetAppUser: (appUserKey, callback) => { dispatch({ type: rts.handleGetAppUser, dispatch, appUserKey }) },
    handleGetCheckIns: () => { dispatch({ type: rts.handleGetCheckIns, dispatch }) },
    handleHideMessage: () => { dispatch({ type: rts.handleHideMessage, dispatch }) },
    handleSetAppUser: (currentAppUser, callback) => { dispatch({ type: rts.handleSetAppUser, dispatch, currentAppUser }) },
    handleSetCheckIns: (checkIns) => { dispatch({ type: rts.handleSetCheckIns, dispatch, checkIns }) },
    handleShowScanner: () => { dispatch({ type: rts.handleShowScanner, dispatch }) },
    handleShowSelectedAppUser: (currentAppUser) => { dispatch({ type: rts.handleShowSelectedAppUser, dispatch, currentAppUser }) },
  }
}

const getCounts = (checkIns) => {
  const ci = checkIns ? _.filter(checkIns, { checkedIn: true }) : {}
  return { checkedIn: ci.length, total: Object.keys(checkIns).length }
}

const handleGetCheckIns = (pathViews, callback) => {
  const _refPath = createRefPath_event(pathViews, [_collectionName])
  fs_get_data({ refPath: _refPath, callback, opts: { listen: true } })
}

const handleGetAppUser = (pathViews, appUserCollection, appUserKey, callback) => {
  const _refPath = createRefPath_event(pathViews, [appUserCollection, appUserKey])
  fs_get_data({ refPath: _refPath, callback, opts: { listen: true, returnFirstObject: true } })
}

const handleCheckIn = (pathViews, currentAppUser, checkIns, callback) => {
  const { _itemKey, firstName, lastName } = currentAppUser ? currentAppUser : {}
  const checkIn = checkIns ? checkIns[_itemKey] : {}
  const { checkedIn } = checkIn ? checkIn : {}
  const _checkedIn = !checkedIn
  const _ref = createRefPath_event(pathViews, [_collectionName, _itemKey])
  fs_update_doc(_ref, { firstName, lastName, checkedIn: _checkedIn, updateDate: serverTimestamp() }, callback)
}