import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { key_pages } from '../enums/allPageTypes';
import { getViewSettingsBaseName, getViewSettingsKeys } from '../viewSettings/helpers/settingsHelpers';
import { useHash } from '../../App';

const isOdd = (num) => {
  return num % 2 === 1 ? true : false
}

export const getHashPath = () => {
  const { hash, origin, pathname } = window.location
  if (hash === '') {
    return { _hash: origin + '/#' + pathname, _redirect: true, _pathName: '/#' + pathname, _pathNameOriginal: pathname.substring(1) }
  } else {
    return { _hash: hash }
  }
}

export const getSearchPath = (newPath, ddProps, ddn) => {
  let path = newPath + '?'
  if (ddProps) {
    Object.keys(ddProps).forEach(key => {
      path += key + '=' + ddProps[key]
    })
    path += '&' + ddn.key + '=' + ddn.value
  } else {
    path += ddn.key + '=' + ddn.value
  }
  return path
}

// const getSearchProps = (search) => {
//   let searchProps = {}
//   console.log('search', search)
//   if (search) {
//     let fullSearch = search.substring(1)
//     searchProps = JSON.parse('{"' + decodeURI(fullSearch).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
//     return searchProps
//   }
//   return null
// }

const getTrueView = (pathViews, _view, trueView, landingView, _viewKey) => {
  if (trueView === landingView) {
    if (pathViews.events) {
      trueView = 'events'
    } else if (pathViews.clients) {
      trueView = 'clients'
    }
  }
  return trueView
}

// eslint-disable-next-line 
const x = {
  event: {
    key: 'eventKey',
    attendees: {
      one: {
        test: 'test',
        aaaa: 'aaaa'
      },
      two: {
        test: 'test',
        aaaa: 'aaaa'
      }
    }
  }
}

export const getLastPathView = (pathName) => {
  const pathViews = getPathViews(pathName)
  const lastPath = Object.keys(pathViews)[Object.keys(pathViews).length - 1]
  const pvs = pathName.split('/')
  return {
    path: lastPath,
    key: pathViews[lastPath],
    pathView: { [lastPath]: pathViews[lastPath] },
    lastKey: pvs[pvs.length - 1]
  }
}

export const getPathViews = (pathName) => {

  let counter = 1
  let start = 0
  let view = ''
  let hasDD = false
  let hasDDN = false

  const pathViews = {}

  const splitter = pathName.split('/')

  splitter.forEach((item, index) => {

    if (item === 'ddg') { hasDD = true }
    if (item === 'ddn') { hasDDN = true }

    if (index > start) {
      if (isOdd(counter)) {
        if (key_pages.includes(item)) {
        } else {
          if (!hasDD && !hasDDN) {
            view = item
          }
        }
      } else {
        if (!key_pages.includes(item) && !hasDD && !hasDDN) {
          pathViews[view] = item
        }
      }
      counter++
    }
  })

  return pathViews
}

export const convertPathToKeys = (pathName) => {

  let counter = 1
  let start = 0
  let view = ''
  let viewKey = ''
  const pathViews = {}

  pathName.forEach((item, index) => {
    if (index > start) {
      if (isOdd(counter)) {
        view = item
        viewKey = null
      } else {
        viewKey = item
        pathViews[view] = viewKey
      }
      counter++
    }
  })

  return pathViews
}

/**
 * An object containing information about the current path.
 * @param props
 */
export const getCurrent = (url, pathname) => {

  let pathName = pathname ? pathname : url

  // trim pathName if last char is '/'
  if (pathName.substring(pathName.length - 1) === '/') {
    pathName = pathName.substring(0, pathName.length - 2)
  }

  const splitter = pathName.split('/')
  const pathViews = {}

  let hostName;

  if (useHash) {
    hostName = window && window.location ? window.location.hostname : ''
  } else {
    hostName = window && window.location ? window.location.hostname : ''
  }

  let counter = 1
  let dataMasked = false
  let lastPathName = splitter[splitter.length - 1]
  let lastView = ''
  let lastViewKey = ''
  let otherView = null
  let start = 0
  let view = ''
  let viewKey = ''
  let ddGroupIndex = ''
  let ddGroupKey = ''

  const useLanding = true
  const landingView = 'landing'
  const landingViews = ['home', 'landing']
  const mainViews = ['home', 'clients', 'events']

  let hasDD = false
  let hasDDN = false

  splitter.forEach((item, index) => {

    if (item === 'mask') { dataMasked = true }
    if (item === 'ddg') { hasDD = true }
    if (item === 'ddn') { hasDDN = true }

    if (index > start) {
      if (isOdd(counter)) {
        if (key_pages.includes(item)) {
          otherView = item
        } else {
          if (!hasDD && !hasDDN) {
            view = item
            lastView = item
            viewKey = null
          }
        }
      } else {
        if (key_pages.includes(item)) {
          otherView = item
        } else {
          if (hasDD) {
            ddGroupIndex = parseInt(item)
          } else if (hasDDN) {
            ddGroupKey = item
          } else {
            viewKey = item
            lastViewKey = item
            pathViews[view] = viewKey ? viewKey.replace(/%20/g, ' ') : viewKey
          }
        }
      }
      counter++
    }
  })

  let storageRootPath = ''
  let rootPath = ''
  let pageKey = ''
  let rootPaths = {}
  let storagePaths = {}
  let lastRootPath = null

  Object.keys(pathViews).forEach(key => {
    const v = pathViews[key]
    storageRootPath = storageRootPath ? storageRootPath + '/' + key + '/' + v : key + '/' + v
    rootPath = rootPath + '/' + key + '/' + v
    pageKey = pageKey ? pageKey + '-' + v : v
    rootPaths[key] = rootPath + '/'
    const spz = rootPath
    storagePaths[key] = spz.substring(1)
    lastRootPath = rootPath
    if (!viewKey) { lastView = key }
  })

  if (dataMasked) {
    viewKey = null
    view = lastView
  }

  if (!view) { view = 'home' }

  let nonLandingView = view

  if (view === landingView && pathViews && Object.keys(pathViews).length > 0) {
    nonLandingView = Object.keys(pathViews)[Object.keys(pathViews).length - 1]
    viewKey = pathViews[nonLandingView]
  }

  if (useLanding) {
    if (landingViews.includes(viewKey)) {
      viewKey = landingView
    }
  }

  let trueView = view ? view : 'home'

  // console.log('navigator.userAgent', navigator.userAgentData)

  const device = {
    isAndroid: /Android/i.test(navigator.userAgent),
    isApple: /iPhone|iPad|iPod/i.test(navigator.userAgent),
    iPhone: /iPhone/i.test(navigator.userAgent),
    iPad: /|iPad/i.test(navigator.userAgent),
    iPod: /iPod/i.test(navigator.userAgent),
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    appVersion: navigator.appVersion,
    vendor: navigator.vendor,
    name: gEnums.deviceNames.desktop
  }

  if (device.isAndroid) {
    device.name = gEnums.deviceNames.android
  } else if (device.isApple) {
    device.name = gEnums.deviceNames.apple
  }

  let isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  // if (!isMobileDevice && window.screen.width >= 768 && window.screen.width < 1200) { isMobileDevice = true }
  if (!isMobileDevice && window.screen.width < 1200) { isMobileDevice = true }

  let appArea = gEnums.appAreaTypes.home

  if (view === 'clients') { appArea = gEnums.appAreaTypes.clients }
  if (rootPaths.clients) { appArea = gEnums.appAreaTypes.client }
  if (rootPaths.events) { appArea = gEnums.appAreaTypes.event }

  const { origin } = window.location
  let rp;

  switch (appArea) {
    case 'client':
      rp = rootPaths && rootPaths['clients'] ? rootPaths['clients'] : ''
      break;
    case 'event':
      rp = rootPaths && rootPaths['events'] ? rootPaths['events'] : ''
      break;
    default:
      break;
  }
  const sharePath = origin + '/#' + rp + 'landing'
  const sharePathFull = origin + '/#' + pathName

  let ddProps = {}

  const pathModes = {
    attendees: pathViews.attendees ? true : false,
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    event: pathViews.events ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sessions: pathViews.sessions ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: pathViews.teams ? true : false,
  }

  const current = {
    appArea,
    appHome: 'home',
    dataMasked,
    ddGroupIndex,
    ddGroupKey,
    device,
    fullSettingsKey: pathName.replace(/\//g, '_'),
    getTrueView: getTrueView,
    hostName,
    isMobileDevice,
    landingView,
    lastPathName,
    lastRootPath,
    lastView,
    lastViewKey,
    mainViews,
    nonLandingView,
    otherView,
    pathKey: storageRootPath.replace(/\//g, '_'),
    pathname,
    pathName,
    pathViews,
    pathModes,
    pageKey,
    rootPath,
    rootPaths,
    storagePaths,
    sharePath,
    sharePathFull,
    ddProps: ddProps,
    screen: window.screen,
    settingsDocName: getViewSettingsBaseName(view, pathViews),
    settingsDocKeys: getViewSettingsKeys(view, pathViews),
    splitter,
    storageRootPath,
    storageManifestPath: storageRootPath + '/manifest',
    trueView,
    url,
    useLanding,
    view,
    viewAs: view,
    viewKey,
    viewPathKey: viewKey ? view + '-' + viewKey : view,
  }

  switch (view) {
    case 'home':
    case 'clients':
    case 'events':
      current.settingsDocDirect = true
      break;
    default:
      current.settingsDocDirect = false
      break;
  }

  return current
}

export const pushBack = (navigate) => {
  const { location } = navigate
  if (location && location.search) {
    navigate(-2)
  } else {
    window.localStorage.setItem('isBack', true)
    navigate(-1)
  }
}

export const getDevice = () => {
  return {
    isAndroid: /Android/i.test(navigator.userAgent),
    isApple: /iPhone|iPad|iPod/i.test(navigator.userAgent),
    iPhone: /iPhone/i.test(navigator.userAgent),
    iPad: /|iPad/i.test(navigator.userAgent),
    iPod: /iPod/i.test(navigator.userAgent),
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    appVersion: navigator.appVersion,
    vendor: navigator.vendor,
    name: gEnums.deviceNames.desktop
  }
}

export const getStorageItem = (itemKey, array, initValue) => {
  const storageValue = window.localStorage.getItem(itemKey)
  if (storageValue) {
    return getCurrentItem(storageValue, array)
  } else {
    return getCurrentItem(array[0], array)
  }
}

export const getCurrentItem = (defaultItem, array) => {
  const _array = array ? array : []
  const _defaultIndex = _array.indexOf(defaultItem)
  const _defaultValue = _defaultIndex && _defaultIndex > 0 ? _array[_defaultIndex] : _array[0]
  return { index: _defaultIndex, item: _defaultValue ? _defaultValue : {}, visible: false }
}