import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { gEnums } from '../../enums/globalEnums';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { storageGalleryProps, uploadHandlers, uploadInitialState, uploadReducer } from '../../cnr/reducers/UploadReducer';
import { getGallery_clientProfiles } from '../../storage/storageItems';
import UploadSegButton from '../uploading/UploadSegButton';
import { StorageContext } from '../../cnr/contexts/StorageContext';
import { appIconTypes } from '../../enums/appIconTypes';
import Uploader from '../uploading/Uploader';

/**
 * 
 * @param {object} props (profileStorageFile: {urls: {full, thumbnail}})
 * @returns An Image or NoData (if full is empty)
 */
const ProfileImage = (props) => {

  const { profileStorageFile, email, inWizard, updateWizard } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state } = states
  const { appUser } = appUser_state ? appUser_state : {}
  const { pathViews, storagePaths, view, viewKey } = paps_state
  const { pageSettings } = page_state ? page_state : {}
  const { aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  // appUserAccess
  const appUserAccess = getAppUserAccess(appUser)
  const { appUserType, email: email_profile } = appUserAccess ? appUserAccess : {}

  const _email = email_profile // email ? email : email_profile

  let storageLocationType;

  switch (appUserType) {
    case gEnums.appUserTypes.admin:
    case gEnums.appUserTypes.superAdmin:
      storageLocationType = gEnums.storageLocationTypes.profiles
      break;
    default:
      storageLocationType = gEnums.storageLocationTypes.clientProfile
  }

  const storageContext = useContext(StorageContext);
  const { storage_state } = storageContext ? storageContext : {}

  const _sp = {
    appUserAccess,
    storageLocationType,
    storageType: gEnums.storageTypes.image,
    storagePaths,
    viewItem_key: view,
    viewItem_id: viewKey,
  }

  const sgps = storageGalleryProps(_sp)
  const { storageRef } = sgps ? sgps : {}

  // uploadState
  const init_state_u = { states, storageType: gEnums.storageTypes.image, storageRef, storageLocationType, appUserAccess }
  const [upload_state, uploadDispatch] = useReducer(uploadReducer, uploadInitialState(init_state_u));
  const upload_handlers = uploadHandlers(uploadDispatch, upload_state)
  const { firstSelectedFile, updating, confirmation } = upload_state ? upload_state : {}

  const [profileItem, setProfileItem] = useState()
  const { urls } = profileItem ? profileItem : {}
  const { full } = urls ? urls : {}

  const [showDelete, setShowDelete] = useState()
  const [showUpload, setShowUpload] = useState()

  const _callback = (galleryResponse) => {
    const { galleryItems } = galleryResponse ? galleryResponse : {}
    const _profileItem = galleryItems && galleryItems[_email] ? galleryItems[_email] : null
    setProfileItem(_profileItem)
  }

  useEffect(
    () => {
      if (!profileStorageFile) {
        getGallery_clientProfiles(pathViews, appUserCollection, _email, _callback)
      } else {
        setProfileItem(profileStorageFile)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      updateWizard && updateWizard({ upload_state, upload_handlers })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleShowUpload = () => setShowUpload(!showUpload)

  const uploadSegButton = () => <UploadSegButton
    upload_state={upload_state}
    upload_handlers={upload_handlers}
    storageType={gEnums.storageTypes.image}
    imageFile={firstSelectedFile}
  />

  const image = () => <Image className={'img-profile'} src={full} />

  const content = () => full ? image() : uploadSegButton()

  const footer = () => <UiSaveButtons
    save={{ oc: handleShowUpload, caption: 'Update Image', icon: appIconTypes.arrowRight, color: 'blue' }}
  />

  const wrapper = () => <Wrapper
    content={content()}
    footer={!inWizard && footer()}
    wrapperType={wrapperTypes.paddedFooter}
    css={{ content: 'margin-auto' }}
    confirmation={confirmation}
    updating={updating}
  ></Wrapper>

  if (showUpload) {
    return <Uploader
      handleCancel={handleShowUpload}
      itemData={{}}
      origin={'FullImage'}
      storageLocationType={gEnums.storageLocationTypes.pageDirect}
      useFull={true}
    />
  } else {
    return wrapper()
  }
}

export default ProfileImage