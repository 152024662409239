import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { animationVariants } from './animationVariants'
import PageItemDimmer from '../page/PageItemDimmer'

export const _animateTypes = {
  component: 'component',
  data: 'data',
  dimmer: 'dimmer',
  fullPageWrapper: 'fullPageWrapper',
  image: 'image',
  landing: 'landing',
  map: 'map',
  noData: 'noData',
  page: 'page',
  pageItem: 'pageItem',
  pageItemElement: 'pageItemElement',
  splash: 'splash',
  start: 'start',
  title: 'title',
  topBanner: 'topBanner',
}

const AnimateComponent = (props) => {

  const { animateType, cn, sty, styleAndClass } = props ? props : {}

  const _styleAndClass = styleAndClass ? styleAndClass : { style: null, className: null }

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { transition_state } = states ? states : {}
  const { transitions: transitionItems } = transition_state ? transition_state : {}

  const { variants, showPendingDimmer } = animationVariants(animateType, transitionItems, sty)

  let _className;

  const motionWrapper = () => {

    switch (animateType) {

      case _animateTypes.splash:
      case _animateTypes.start:
        return <motion.div
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          exit={'exit'}
          {..._styleAndClass}
        >
          {props.children}
        </motion.div>

      case _animateTypes.page:
        _className = 'motion-container ' + animateType
        if (cn) { _className += ' ' + cn }
        return <motion.div
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          exit={'exit'}
          className={_className}
        >
          {props.children}
        </motion.div>

      case _animateTypes.pageItem:
        _className = 'motion-container ' + animateType
        const { style } = _styleAndClass ? _styleAndClass : {}
        if (cn) { _className += ' ' + cn }
        return <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.1 } }}
          className={_className}
          style={style}
        >
          {props.children}
        </motion.div>

      case _animateTypes.pageItemElement:
        _className = 'motion-container ' + animateType
        if (cn) { _className += ' ' + cn }
        console.log('variants', variants)
        return <motion.p
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          exit={'exit'}
          className={_className}
        >
          {props.children}
        </motion.p>

      default:
        _className = 'motion-div-container ' + animateType
        if (cn) { _className += ' ' + cn }
        return <motion.div
          variants={variants}
          initial={'initial'}
          animate={'animate'}
          className={_className}
        >
          {props.children}
        </motion.div>
    }
  }

  if (showPendingDimmer) {
    return <PageItemDimmer content={motionWrapper()} />
  } else {
    return motionWrapper()
  }

}

export default AnimateComponent