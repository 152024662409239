export const getSectionHeaderItems = (props) => {

  const {
    hddButtons,
    opts,
    swipedItem,
  } = props

  let {
    addType,
  } = props

  const ddis = []

  const { groupKey } = opts ? opts : {}

  if (swipedItem) {
    switch (swipedItem.key) {
      case 'props':
        addType = 'addPropToSection'
        break;
      default:
      // nothing
    }
  }

  ddis.push(hddButtons.swipeTo(addType))
  ddis.push(hddButtons.manageSections(groupKey))
  ddis.push(hddButtons.sortItems(addType))

  return ddis

}