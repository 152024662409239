import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getItemName } from '../../common/convert';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { getAppProfileData } from '../../firestoreData/helpers/appProfileData';
import { getStaticViews } from '../../firestoreData/staticViews/updateStaticViews';
import { getAllAppUserUrls } from '../../storage/storageHelpers';
import { eventInfoHandlers, eventInfoInitialState, eventInfoReducer } from '../reducers/EventInfoReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const EventInfoContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const EventInfoProvider = (props) => {

  const aat = gEnums.appAreaTypes
  const svc = [aat.clients, aat.clubs, aat.districts, aat.event, aat.leagues, aat.schools, aat.organizations, aat.client]

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { appArea, pathViews } = paps_state ? paps_state : {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { startData } = aps_global ? aps_global : {}
  const { appUserCollection, appUserCollections, appUserViewTypeProp } = aps_appUserSettings ? aps_appUserSettings : {}
  const { useStartDataCollections, startDataCollections } = startData ? startData : {}

  const _appUserCollections = appUserCollections ? appUserCollections : []
  if (_appUserCollections.length === 0 && appUserCollection) { _appUserCollections.push(appUserCollection) }

  // appNotificationsViewer
  const initState = eventInfoInitialState({ pathViews })
  const [eventInfo_state, dispatch] = useReducer(eventInfoReducer, initState);
  const eventInfo_handlers = eventInfoHandlers(dispatch)
  const { allStaticViews, clientEvents } = eventInfo_state ? eventInfo_state : {}

  const { staticViews } = allStaticViews ? allStaticViews : {}
  const { attendees } = staticViews ? staticViews : {}

  const setResult = (d) => { eventInfo_handlers.handleAllStaticViews(d) }
  // const setGlobalResult = (d) => { eventInfo_handlers.handleAllGlobals(d) }
  const handleAppProfileData = (d) => { eventInfo_handlers.handleAppProfileData(d) }

  const getStaticName = (staticView, staticValue, returnFound) => {
    const _staticView = staticView === 'guests' ? 'attendees' : staticView
    if (staticViews && staticViews[_staticView] && staticViews[_staticView][staticValue]) {
      const svv = staticViews[_staticView][staticValue]
      if (returnFound) {
        return { sv: getItemName(svv) }
      } else {
        return getItemName(svv)
      }
    } else {
      if (returnFound) {
        return { sv: staticValue, notFound: true }
      } else {
        return staticValue
      }
    }
  }

  useEffect(
    () => {
      if (svc.includes(appArea)) {
        if (!allStaticViews) { getStaticViews(paps_state, null, setResult, pageContext) }
      }
      if (useStartDataCollections) {
        eventInfo_handlers.handleEventInit(startDataCollections)
      }
      eventInfo_handlers.handleGetGlobals()
    },
    // eslint-disable-next-line
    [pathViews.events]
  )

  useEffect(
    () => {
      getAppProfileData({ appUserCollections: _appUserCollections, appUserViewTypeProp, aps_viewItems, pathViews, callback: handleAppProfileData })
    },
    // eslint-disable-next-line
    [pathViews.events]
  )

  useEffect(
    () => {
      if (attendees && (appUserViewTypeProp === gEnums.appUserProfileImageTypes.profile)) {
        getAllAppUserUrls(attendees, eventInfo_handlers.handleAppUserUrls)
      }
    },
    // eslint-disable-next-line
    [attendees]
  )

  useEffect(
    () => {
      eventInfo_handlers.handleGetAppUserData()
      const _refPath = createRefPath_client(pathViews, ['events'])
      fs_get_data({ refPath: _refPath }).then(events => {
        eventInfo_handlers.handleClientEvents(events)
      }).catch(error => {
        console.error(error)
        eventInfo_handlers.handleClientEvents({})
      })
    },
    // eslint-disable-next-line
    []
  )

  const eventInfo_fns = {
    getStaticName: (staticView, staticValue, returnFound) => getStaticName(staticView, staticValue, returnFound)
  }

  if (svc.includes(appArea)) {
    if (allStaticViews && clientEvents) {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    } else {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    }
  } else {
    return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
      {props.children}
    </EventInfoContext.Provider>
  }
}

export default EventInfoProvider