import { GoogleAuthProvider, getAuth, sendSignInLinkToEmail, signInWithPopup } from "firebase/auth";
import { subAppTypes } from '../../../AppSub';
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { signInResponseTypes } from '../../cnr/reducers/SignInReducer';

export const _emailSignInStorageItem = 'emailForSignUp'

/** Sends a email to the emailAddress.
 * If the recipient clicks on the 'Sign in to ...' link, they will be redirected to the url use below
 * @callback dispatch: type = handleSignInResponse
 */
export const sendSignInGoogleLink = async () => {

  const dispatchSuccessType = signInResponseTypes.emailLinkSent
  const dispatchNonSuccessType = signInResponseTypes.emailLinkNotSent
  const provider = new GoogleAuthProvider();

  const auth = getAuth();
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log('credential', credential)
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    })
} 