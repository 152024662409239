import { getAuth, sendPasswordResetEmail as spre } from "firebase/auth";
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { signInResponseTypes } from '../../cnr/reducers/SignInReducer';

export const sendPasswordResetEmail = (email, callback) => {
  const auth = getAuth()
  spre(auth, email).then(res => {
    callback && callback(signInResponseData(null, null, null, signInResponseTypes.emailResetSent, email, res))
  }).catch((error) => {
    callback && callback(signInResponseData(null, null, null, signInResponseTypes.emailResetNotSent, email, null, error))
  })
}