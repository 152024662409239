import _ from 'lodash';
import React from 'react';
import { Icon, Image, Label } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from '../../enums/globalEnums';
import { propsPicker } from './propsPicker';

const GoogleMap = React.lazy(() => import('../map/GoogleMap'));
const SemanticAddress = React.lazy(() => import('../sem/SemAddress'));
const SemItemList = React.lazy(() => import('../sem/SemItemList'));
const SemPdfSimple = React.lazy(() => import('../sem/SemPdfSimple'));
const SemUrlList = React.lazy(() => import('../sem/SemUrlList'));

/**
 * Returns the element for the prop
 * @param {*} parentContext 
 * @param {*} propItems 
 * @param {*} propSection 
 * @param {*} sectionBody 
 * @param {*} componentContexts 
 * @param {*} itemData 
 * @param {*} separator 
 * @param {*} showEventStatus 
 * @param {*} noCaptions 
 * @param {*} showTagDash 
 * @param {*} secret 
 * @returns 
 */
export const createSemPropElements = (parentContext, propSectionKey, propItems, propSection, sectionBody, componentContexts, itemData, separator, showEventStatus, noCaptions, showTagDash, secret) => {

  // parentContext
  const { states, fns, handlers, settings } = parentContext
  const { page_state, framework_state } = states ? states : {}
  const { settingsViewer_handlers } = handlers ? handlers : {}
  const { page_fns } = fns ? fns : {}
  const { pageSettings } = page_state ? page_state : {}
  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging } = homeSettings_global ? homeSettings_global : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { pushSimple, pushUrl } = page_fns ? page_fns : {}

  // componentContexts
  const { uiItemContext } = componentContexts ? componentContexts : {}

  const { desktopMode, propMode } = framework_state ? framework_state : {}

  const { item_state, item_handlers } = uiItemContext ? uiItemContext : {}
  const { viewItem, allowAppUserEdit } = item_state ? item_state : {}

  let { propItems: gpi, themeColors, imageMapping } = aps_global ? aps_global : {}
  const { showPropIcons, propIcons } = gpi ? gpi : {}

  const { key: viKey, maps, itemColor, display, desktopDisplay, itemClick } = viewItem ? viewItem : {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { sortProp, showCaptionInitial, useCss } = _display ? _display : {}
  let { showDataColor, dataColorProp } = itemColor ? itemColor : {}
  const { noClick, detectAppClick } = itemClick ? itemClick : {}

  const isNameSection = propSection.key === gEnums.sectionHeaderNames.name

  const _imageMapping = imageMapping && _.find(imageMapping, { 'collectionName': viKey })
  const { allowMapping, mapFieldName: _imageMappingProp, useImageMapIcon } = _imageMapping ? _imageMapping : {}

  let { bodyDisplayType, vertical } = sectionBody ? sectionBody : {}

  const showMap = () => item_handlers.handleModifyMapping()

  const i = { childKey: itemData.id, noClick: noClick, items: [], groups: [] }

  if (!noClick) { i.onClick = (_e) => pushSimple(viewItem, itemData.id) }

  if (useCss && propItems) { propItems = _.filter(propItems, { 'cssed': true }) }

  // create an element for each prop

  const getPropItems = () => {

    let items = []

    const itemsL = propItems ? propItems.length - 1 : 0

    if (itemData && isNameSection) {
      // add an `initial` label 
      if (showDataColor && dataColorProp && itemData[dataColorProp]) {
        items.push(<Label key={uniqueKey('prcr', 'l', 'dc')} className={'dcp'} circular style={{ backgroundColor: itemData[dataColorProp] }}> </Label>)
      }

      if (showCaptionInitial && sortProp && itemData[sortProp]) {
        items.push(<Label key={uniqueKey('prcr', 'l', 'ci')} circular color='blue'>{itemData[sortProp].substring(0, 1)}</Label>)
      }

      if (itemData.vc && itemData.vc.meetingInProgress) {
        items.push(<Icon className={'icon-glow'} name={'video'} color={'green'} />)
      }
    }

    if (isNameSection && showEventStatus && viewItem && viewItem.key === 'events') { eventStatusIcon(itemData, items) }

    if (propItems) {


      propItems.forEach((propItem, propIndex) => {

        const { display: display_propItem, formatting: formattings_prop, dataModification: prop_dataModification } = propItem
        const { allowAppUserDirectEdit } = prop_dataModification ? prop_dataModification : {}

        let { propDisplayType: propPropDisplayType, caption, icon, elemPropType, labelIfTagged } = display_propItem ? display_propItem : {}

        const keyy = propItem.key + ' ' + propIndex
        let cn = ''
        let propValue = propItem.value

        if (propValue) {
          let showPropCaption = (
            (bodyDisplayType === gEnums.sectionBodyTypes.captions ||
              bodyDisplayType === gEnums.sectionBodyTypes.labelCaptions ||
              propPropDisplayType === gEnums.propDisplayTypes.captions ||
              propPropDisplayType === gEnums.propDisplayTypes.labelCaptions ||
              bodyDisplayType === gEnums.sectionBodyTypes.table) &&
            propPropDisplayType !== gEnums.propDisplayTypes.noCaptions && !noCaptions
          ) ? true : false

          let pdt = propPropDisplayType ? propPropDisplayType : bodyDisplayType
          let itemCaption = showPropCaption ? caption : ''
          let itemIcon = showPropCaption ? icon : null
          let iconColor = null

          if (showPropCaption && !vertical) { itemCaption += ': ' }

          if (showPropIcons) {
            const pri = _.find(propIcons, { propItem: propItem.key })
            itemIcon = pri ? pri.icon : null
            iconColor = pri && pri.color ? pri.color.backgroundColor : null
            iconColor = themeColors[iconColor]
            showPropCaption = true
          }

          showPropCaption = true

          if (bodyDisplayType === gEnums.sectionBodyTypes.table && propPropDisplayType !== gEnums.propDisplayTypes.noCaptions && !noCaptions) {
            pdt = gEnums.propDisplayTypes.captions
          }

          if (useCss) { pdt = gEnums.propDisplayTypes.noCaptions }

          cn += (propItem.highlight) ? 'highlight' : ''
          if (propItem.key === 'date') { propValue = null }

          if (formattings_prop && formattings_prop.apply) {
            Object.keys(formattings_prop).forEach(fp => {
              let fval = formattings_prop[fp];
              switch (fp) {
                case 'fontWeight':
                  fval = 'fw-' + fval
                  break;
                case 'size':
                  fval = 'fs-' + fval
                  break;
                default:
                  // nothing
                  break;
              }
              cn += ' ' + fval
            })
          }

          const cns = setPropStyle(propItem, cn)
          const { style } = cns

          switch (elemPropType) {

            case gEnums.elemPropTypes.urlList:
              items.push(<SemUrlList itemData={itemData} propItem={propItem} />)
              break;

            case gEnums.elemPropTypes.itemList:
              items.push(<SemItemList itemData={itemData} propItem={propItem} />)
              break;

            case gEnums.elemPropTypes.addressOnly:
              items.push(<SemanticAddress propItem={propItem} itemData={itemData} pushUrl={pushUrl} maps={maps} />)
              break;

            case gEnums.elemPropTypes.addressMap:
              items.push(<GoogleMap propItem={propItem} itemData={itemData} viewItem={viewItem} maps={maps} />)
              break;

            case gEnums.elemPropTypes.addressWithMap:

              items.push(<SemanticAddress propItem={propItem} itemData={itemData} pushUrl={pushUrl} maps={maps} showAddress={true} />)
              break;

            case gEnums.elemPropTypes.pdf:
              items.push(pdfImage(propValue))
              break;

            case gEnums.elemPropTypes.image:
              items.push(<Image key={uniqueKey('prcr', 'l', 'di')} src={itemData[propItem.key]} alt={'image'}></Image>)
              break;

            default:

              if (propValue) {

                try {

                  if (propValue === true) { propValue = 'Yes' }
                  if (showTagDash && propIndex > 0) { propValue = ' - ' + propValue }

                  cn = cn.trim()

                  if (cn === '') {
                    cn = 'prp-' + propItem.key
                  } else {
                    cn += ' prp-' + propItem.key
                  }

                  const cnp = 'prp-l-' + propItem.key

                  if (labelIfTagged) { propValue = <Label key={uniqueKey('pc', 'lt', propItem.key)} className={'label-tagged'}>{propValue}</Label> }

                  switch (propItem.key) {

                    default:
                      const _lv = {
                        pdt,
                        items,
                        keyy,
                        useCss,
                        propKey: propItem.key,
                        allowEdit: allowAppUserEdit && allowAppUserDirectEdit,
                        item_handlers,
                        propItem
                      }

                      let _lvi;

                      // pdt, items, label, value, keyy, useCss, propKey

                      switch (pdt) {
                        case gEnums.propDisplayTypes.noCaptions:
                          _lvi = {
                            value: <div key={uniqueKey('l', keyy)} style={style} className={cn} >{propValue}</div>
                          }
                          break;

                        case gEnums.sectionBodyTypes.captions:
                        case gEnums.propDisplayTypes.captions:
                          _lvi = {
                            label: propLabel(keyy, pdt, bodyDisplayType, itemCaption, itemIcon, iconColor, style, cnp),
                            value: <div key={uniqueKey('l', keyy)} style={style} className={cn} >{propValue}</div>
                          }
                          break;
                        case gEnums.sectionBodyTypes.labelCaptions:
                        case gEnums.propDisplayTypes.labelCaptions:
                          _lvi = {
                            label: propLabel(keyy, pdt, bodyDisplayType, itemCaption, itemIcon, iconColor, style, cnp),
                            value: <div key={uniqueKey('lc', keyy)} className={cn} style={style}>{propValue}</div>,
                          }
                          break;
                        default:
                          if (itemIcon) {
                            _lvi = {
                              value: <div key={uniqueKey('ii', keyy)} style={style} className={cn} >{propIcon(itemIcon, iconColor)}{propValue}</div>,
                            }
                          } else {
                            _lvi = {
                              value: <div key={uniqueKey('vl', keyy)} style={style} className={cn} >{propValue}</div>,
                            }
                          }
                      }

                      if (allowMapping && !useImageMapIcon && (_imageMappingProp === propItem.key)) {
                        _lvi.value = <Label className={'iconed-map'} onClick={(e) => { showMap() }} >
                          <Icon name='map' color='blue' />{_lvi.value}
                        </Label>
                      }

                      const _lvc = { ..._lv, ..._lvi }

                      addLV(_lvc)
                  }
                  if (separator && (propIndex < itemsL)) {
                    items.push(<span className={'sep'}>{separator}</span>)
                  }
                } catch (error) {
                  if (logging && logging.allowLogging && logging.logErrors) { console.log('logging >', 'error', error) }
                  items.push(<div key={uniqueKey('prcr', 'l', 'nv')} style={style} className={cn} >{'NO VALUE'}</div>)
                }
              }
          }
        }
      })
    }

    if (detectAppClick) {
      const { url, website } = itemData
      if (url || website) {
        items.unshift(<Icon name={appIconTypes.externalAlternate} color={'green'} style={{ float: 'right' }} />)
      }
    }

    return items
  }

  const items = getPropItems()

  if (settingsViewer_handlers && propMode) {
    return propsPicker({ items, settingsViewer_handlers })
  }

  return items

}

/**
 * 
 * @param {object} itemData 
 * @param {object} items 
 * @param {boolean} propsOnly 
 * @returns 
 */
export const eventStatusIcon = (itemData, items, propsOnly) => {

  let { eventStatusType, published, name: en } = itemData ? itemData : {}

  let color = published === gEnums.publishedTypes.published ? 'green' : 'grey'
  let name = 'question'

  if (eventStatusType) {
    switch (eventStatusType) {
      case gEnums.eventStatusTypes.active:
        name = 'calendar check outline'
        color = 'green'
        break;
      case gEnums.eventStatusTypes.completed:
        name = 'flag checkered'
        color = 'grey'
        break;
      case gEnums.eventStatusTypes.live:
        name = 'calendar check outline'
        color = 'green'
        break;
      case gEnums.eventStatusTypes.restricted:
        name = 'user secret'
        color = 'red'
        break;
      case gEnums.eventStatusTypes.underMaintenance:
        name = 'terminal'
        color = 'yellow'
        break;
      default:
      // nothing
    }
  }

  if (propsOnly) {
    return {
      name,
      color
    }
  } else {
    items.push(<Icon key={uniqueKey('prcr', 'is', en)} bordered color={color} name={name} style={{ marginRight: '1em' }} />)
  }
}

const propLabel = (keyy, pdt, _displayType, itemCaption, itemIcon, iconColor, style, cnp) => {
  if (itemIcon && 1 === 2) {
    return <div key={uniqueKey('pl', keyy)} className={cnp} > {propIcon(itemIcon, iconColor)}</div>
  } else {
    switch (pdt) {
      case gEnums.propDisplayTypes.captions:
        return <div className={cnp} key={uniqueKey('pl', keyy)}>{itemCaption}</div>
      case gEnums.propDisplayTypes.labelCaptions:
        return <div className={cnp} key={uniqueKey('pl', keyy)}><Label key={uniqueKey('pc', 'lbl', keyy)} style={style}>{itemCaption}</Label></div>
      default:
      // nothing
    }
  }
}

const pdfImage = (fileUrl) => <SemPdfSimple fileUrl={fileUrl} />

const addLV = (props) => {

  const { propItem, pdt, items, label, value, keyy, useCss, propKey, allowEdit, item_handlers } = props

  let cn = 'prc'

  const icon = allowEdit ? <Icon name='edit' onClick={(e) => { item_handlers.handleItemShowEdit(propItem) }} /> : null

  if (allowEdit) { cn += ' edt' }

  cn += ' pn-' + propKey

  if (useCss) {
    switch (pdt) {
      case gEnums.propDisplayTypes.noCaptions:
        items.push(value)
        break;
      default:
        const x = <div key={uniqueKey('alv', keyy)} className={cn} propkey={propKey}>
          {label}{value}{icon}
        </div>
        items.push(x)
    }
  } else {
    switch (pdt) {
      case gEnums.propDisplayTypes.noCaptions:
        const xx = <div key={uniqueKey('alv', keyy)} className={cn + ' nc'} propkey={propKey}>
          {value}{icon}
        </div>
        items.push(xx)
        break;
      default:
        const x = <div key={uniqueKey('alv', keyy)} className={cn} propkey={propKey}>
          {label}{value}{icon}
        </div>
        items.push(x)
    }
  }
}

const propIcon = (icon, iconColor) => {
  if (!icon) { icon = 'tag' }
  return <Icon name={icon} style={{ marginRight: '.8rem', color: iconColor }} />
}

const setPropStyle = (pis, cn) => {

  const { css } = pis
  const { textAlignRight, showWhiteSpace } = css ? css : {}

  let sty = {}
  if (textAlignRight) { sty.textAlign = 'right' }
  if (showWhiteSpace) { sty.whiteSpace = 'pre-line' }

  const cns = { cn, style: sty }
  return cns
}