import React, { useContext, useState } from 'react';
import { Button, Icon, Label } from 'semantic-ui-react';
import { isMeProd } from '../../project/fbConfiguration';
import ActionProvider from '../cnr/contexts/ActionContext';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { googleModeTypes } from '../cnr/reducers/GoogleSheetsReducer';
import { g_cns } from '../common/cns';
import { uniqueKey } from '../common/keys';
import AppDashboard from '../components/viewers/AppDashboard';
import { appIconTypes } from '../enums/appIconTypes';
import { iconColorTypes } from '../enums/settingsIconTypes';
import ButtonIcon from '../genericControls/ButtonIcon';

const buttonModes = {
  full: 'full',
  google: 'google',
  default: 'default'
}

// "@google-cloud/firestore": "^6.4.0", 5.0.2
// "@sendgrid/mail": "^7.7.0",
// "@vonage/server-sdk": "^2.11.2",
// "axios": "^1.1.3",
// "cors": "^2.8.5",
// "firebase-admin": "^11.2.0", 10.3.0
// "firebase-functions": "^4.0.1",

/** Controls the settings for the app
 * @param viewPermissionType  
 * @description The top settings menu for the app.
 */
const SettingsAppBottom = () => {

  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ? parentContext : {}
  const { appSettings_state, page_state, paps_state } = states
  const { pathModes, view } = paps_state
  const { appSettings_handlers } = handlers

  const { homeSettings } = settings ? settings : {}
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { globalAppSettings } = global_home ? global_home : {}
  const { databaseProjectId } = globalAppSettings ? globalAppSettings : {}

  const { pageSettings } = page_state
  const { aps_global } = pageSettings ? pageSettings : {}
  const { topMenu, projectOptions } = aps_global ? aps_global : {}
  const { timeZone } = projectOptions ? projectOptions : {}
  const { mainFixedCaption } = topMenu ? topMenu : {}

  // appSettingsContext 
  const { showAppViewer, appViewerKey, desktopOn, pageSettingsOn, globalSettingsOn, appSettingsOn } = appSettings_state ? appSettings_state : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  const isLanding = view === 'landing' || view === 'clients' || view === 'home'
  const googleProps = { allowGoogle: isLanding || view === 'clients' ? true : false }
  const desktopView = desktopMode || desktopOn ? true : false

  const _isMeProd = isMeProd()
  const capCn = _isMeProd ? 'top-settings-caption prod' : 'top-settings-caption'

  const [buttonMode, setButtonMode] = useState(buttonModes.default)

  const handleSportsViewer = () => framework_handlers.handleShowDashboard('sports')
  const handleAppViewer = () => appSettings_handlers.handleShowAppViewer()
  const handlePageSettingsOn = () => appSettings_handlers.handlePageSettingsOn(view)
  const handleGlobalSettingsOn = () => appSettings_handlers.handleGlobalSettingsOn({ view })

  const handleSetButtonMode = (buttonMode) => {
    switch (buttonMode) {
      case buttonModes.default:
      case buttonModes.full:
        if (pageSettingsOn) { appSettings_handlers.handlePageSettingsOn() }
        break;
      case buttonModes.google:
        break;
      default:
        break;
    }
    if (buttonMode) {
      setButtonMode(buttonMode)
    }
  }

  const btni = (iconName, propIsOn, onClick, buttonMode, opts, altColor, text, cornerIcon) => <ButtonIcon key={uniqueKey('bi', iconName)} biProps={{ iconName, propIsOn, onClick, buttonMode, opts, altColor, text, cornerIcon }} />

  const optionButtons = {
    closeAll: btni('grid layout', buttonMode === buttonModes.full, handleSetButtonMode, buttonModes.default, { title: 'Close', color: iconColorTypes.kill }),
    options: btni('grid layout', buttonMode === buttonModes.full, handleSetButtonMode, buttonModes.full, { title: 'Options' }),
    pageSettings: btni(appIconTypes.setting, pageSettingsOn, handlePageSettingsOn, null, { title: 'Page Settings' }),
    globalAppSettings: btni(appIconTypes.settings, globalSettingsOn, handleGlobalSettingsOn, null, { title: 'Settings' }),
    googleAll: btni(appIconTypes.google, buttonMode === buttonModes.google, handleSetButtonMode, buttonModes.google, { title: 'Google' }),
    appDashboard: btni(appIconTypes.appDashboard, globalSettingsOn, handleAppViewer, googleModeTypes.preview, { color: globalSettingsOn ? iconColorTypes.preview : iconColorTypes.default, title: 'Dashboard' }),
    sportsDashboard: btni(appIconTypes.sports, globalSettingsOn, handleSportsViewer, googleModeTypes.preview, { color: globalSettingsOn ? iconColorTypes.preview : iconColorTypes.default, title: 'Dashboard' }),
  }

  // IMPORTANT - topMenuButtons
  /** Group of buttons to for the top menu settings */
  const bottomMenuButtons = () => {
    const b = []
    if (!desktopView) { b.push(optionButtons.options) }
    return b
  }

  const buttons_full = () => {
    const b = []
    if (!desktopView) {
      b.push(optionButtons.closeAll)
      if (isLanding) { b.push(optionButtons.appDashboard) }
      if (pathModes.sport) { b.push(optionButtons.sportsDashboard) }
      if (!appSettingsOn && !googleProps.allowGoogle) { b.push(optionButtons.pageSettings) }
    }
    return b
  }

  const buttonItems = () => {
    switch (buttonMode) {
      case buttonModes.default:
        return bottomMenuButtons()
      case buttonModes.full:
        return buttons_full()
      default:
        break;
    }
  }

  const iconGroup = () => {
    let borderColor = iconColorTypes.semGreen
    switch (buttonMode) {
      case buttonModes.default:
      case buttonModes.full:
        borderColor = iconColorTypes.default
        break;
      case buttonModes.google:
        borderColor = iconColorTypes.semBlue
        break;
      default:
        break;
    }
    return <Button.Group size='small' style={{ borderColor: borderColor }}>
      {buttonItems()}
    </Button.Group>
  }

  const lblIcon = (caption, iconName, color) => <Label size='mini'>
    <Icon name={iconName} color={color} />{caption}
  </Label>

  const menuContent2 = () => <div className={g_cns.app_settings_menu + ' cap'}>
    <div className={capCn}>
      {lblIcon(mainFixedCaption, _isMeProd ? 'sitemap' : 'code', _isMeProd ? 'green' : 'blue')}
      {lblIcon(databaseProjectId, 'database')}
      {lblIcon('me1', 'file alternate outline')}
      {timeZone && lblIcon(timeZone, 'clock')}
    </div>
  </div >

  const menuContent = () => <div className={g_cns.app_settings_menu_bottom}>
    {iconGroup()}
  </div>


  return showAppViewer ?
    <ActionProvider><AppDashboard handleCancel={handleAppViewer} appViewerKey={appViewerKey} /></ActionProvider>
    :
    <React.Fragment>
      {menuContent()}
      {menuContent2()}
    </React.Fragment>

}

export default SettingsAppBottom