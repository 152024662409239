import React from "react";
import FileUploader from "react-firebase-file-uploader";

export const UploadSelectButton = (props) => {

  /**
   * 
   * @returns the button that opends the fileUploader
   */
  const selectButton = () => <div className="upload-btn-wrapper">
    <button className="btn ui button blue">Select</button>
    <UploadFileButton props={props} />
  </div>

  return selectButton()

}

export const UploadFileButton = (props) => {

  const { accept, upload_handlers, _storageRef, storageType } = props

  const handleFileSelect = (e) => upload_handlers.handleImageFileSelect(e, storageType)

  /** Handles the uploading of files to firebase storage */
  const fileUploader = () => <FileUploader
    hidden
    accept={accept}
    name="avatar"
    storageRef={_storageRef}
    // controls the uploading
    onUploadStart={upload_handlers.handleStartUpdate}
    onUploadError={upload_handlers.handleUploadError}
    // onUploadSuccess={allowMultiple ? upload_handlers.handleUploadSuccess : upload_handlers.handleUploadSuccess}
    onProgress={upload_handlers.handleProgress}
    onChange={handleFileSelect}
    multiple={true}
  />

  return fileUploader()

}
