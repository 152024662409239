import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import CircleLoader from '../../../motions/CircleLoader';

const PendingFetch = (props) => {
  const { text, inverted } = props
  return <Segment className={'vs-seg'} inverted={inverted} placeholder style={{ height: '100%' }}>
    <Header as='h5' icon>
      <CircleLoader />
      {text}
    </Header>
  </Segment>
}

export default PendingFetch