// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString 

import _ from "lodash";

const dateOptions = {
  dateAndTime: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  dateMenu: { month: 'numeric', day: 'numeric', timeZone: 'utc' },
  dateOnly: { month: 'long', day: 'numeric', timeZone: 'utc' },
  dayMonth: { month: 'numeric', day: 'numeric', },
  dateDayMonth: { weekday: 'short', month: 'numeric', day: 'numeric', },
  dayOfWeek: { weekday: 'long', timeZone: 'utc' },
  fullDate: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'utc' },
  minimalDate: { year: 'numeric', month: 'numeric', day: 'numeric' },
  monthYear: { year: 'numeric', month: 'long' },
  shortDate: { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone: 'utc' },
  shorterDate: { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'utc' },
  shortestDate: { year: 'numeric', month: 'numeric', day: 'numeric' },
  time: { hour: 'numeric', minute: 'numeric' },
  timeAmPm: { hour: 'numeric', minute: 'numeric' },
}

function addZeroBefore(n) {
  return (n < 10 ? '0' : '') + n;
}

export const getNow = () => {
  const currentdate = new Date();
  return addZeroBefore((currentdate.getMonth() + 1)) + "/"
    + addZeroBefore(currentdate.getDate()) + "/"
    + currentdate.getFullYear() + " @ "
    + addZeroBefore(currentdate.getHours()) + ":"
    + addZeroBefore(currentdate.getMinutes()) + ":"
    + addZeroBefore(currentdate.getSeconds());
}

export const formatTypes = {
  ampm: 'ampm',
  date: 'date',
  dateAndTime: 'dateAndTime',
  dateMenu: 'dateMenu',
  dateOnly: 'dateOnly',
  dayMonth: 'dayMonth',
  dateDayMonth: 'dateDayMonth',
  dayOfWeek: 'dayOfWeek',
  fullDate: 'fullDate',
  minimalDate: 'minimalDate',
  monthYear: 'monthYear',
  shortDate: 'shortDate',
  /** 11/02/2020 */
  shorterDate: 'shorterDate',
  /** 11/02/2020 */
  shortestDate: 'shortestDate',
  time: 'time'
}

/**
 * 
 * @param {*} secs 
 * @returns seconds
 */
export const toDateTime = (secs) => {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

// export const toDateDate = (date) => {
//   var t = new Date(date); // Epoch
//   const secs = t.getSeconds()
//   t.setSeconds(secs);
//   return t;
// }

/**
 * 
 * @param {date} date 
 * @returns dow, monthDay, year, month, day
 */
export const dateTimeSplits = (date) => {
  let df = { month: 0, day: 0, year: 0, dow: '?', monthDay: 0 }
  try {
    const _date = new Date(date)
    const _dateFormatted = formatItem(formatTypes.shortDate, _date)
    const dates = _dateFormatted.split(',')
    df = {
      dow: dates ? dates[0] : null,
      monthDay: dates ? dates[1] : null,
      year: dates ? dates[2] : null,
      month: 0,
      day: 0
    }
    if (df.monthDay) {
      const mdSplit = df.monthDay ? df.monthDay.trim().split(' ') : []
      if (mdSplit.length === 2) {
        df.month = mdSplit[0]
        df.day = mdSplit[1]
      }
    }
  } catch (error) {
    console.error(error)
  }
  return df
}

/**
 * 
 * @param {string} formattingType 
 * @param {value} value 
 * @returns athe formatted `value` based on the `formattingType`
 */
export const formatItem = (formattingType, value, timeZone) => {

  const formatDate = (value, dateOption, formatType) => {

    let _value;

    try {
      _value = value && value.indexOf('-') >= 0 ? value + ' 12:00 UTC' : value
    } catch (error) {
      _value = value
    }

    if (value && value.length === 4) {
      return value
    }

    let _df;

    if (timeZone) {
      const _dateOption = { ...dateOption, timeZone: timeZone ? timeZone : 'America/New_York' }
      _df = new Date(_value).toLocaleString('en', _dateOption)
    } else {
      const df = new Date(_value).toLocaleDateString('en-US', dateOption);
      _df = df
    }

    switch (formatType) {
      case formatTypes.minimalDate:
        const t = new Date(_df);
        const y = t.getFullYear()
        const md = (t.getMonth() + 1) + '/' + t.getDate() + '/' + y.toString().substring(2)
        return md
      case formatTypes.shortestDate:
        const ts = new Date(_df);
        const mds =
          ('0' + (ts.getMonth() + 1)).slice(-2) + '/'
          + ('0' + ts.getDate()).slice(-2) + '/'
          + ts.getFullYear();
        return mds
      default:
      // nothing
    }
    return _df
  }

  const dateAndTime = (value) => { return formatDate(value, dateOptions.dateAndTime) }
  const dateMenu = (value) => { return formatDate(value, dateOptions.dateMenu) }
  const dayMonth = (value) => { return formatDate(value, dateOptions.dayMonth) }
  const dateDayMonth = (value) => { return formatDate(value, dateOptions.dateDayMonth) }
  const dateOnly = (value) => { return formatDate(value, dateOptions.dateOnly) }
  const dayOfWeek = (value) => { return formatDate(value, dateOptions.dayOfWeek) }
  const fullDate = (value) => { return formatDate(value, dateOptions.fullDate) }
  const minimalDate = (value) => { return formatDate(value, dateOptions.minimalDate, formatTypes.minimalDate) }
  const monthYear = (value) => { return formatDate(value, dateOptions.monthYear) }
  const shortDate = (value) => { return formatDate(value, dateOptions.shortDate) }
  const shorterDate = (value) => { return formatDate(value, dateOptions.shorterDate) }
  const shortestDate = (value) => { return formatDate(value, dateOptions.shortestDate, formatTypes.shortestDate) }
  const time = (value) => { return formatTime(value, dateOptions.time) }
  const timeAmPm = (value) => { return formatTime(value, dateOptions.timeAmPm) }

  try {
    let dateValue;
    if (value && value.seconds) {
      const x = toDateTime(value.seconds)
      dateValue = x
    } else {
      dateValue = value
    }
    if (!dateValue) { return dateValue }
    switch (formattingType) {
      case formatTypes.dateAndTime:
        return dateAndTime(dateValue)
      case formatTypes.date:
      case formatTypes.fullDate:
        return fullDate(dateValue)
      case formatTypes.dateOnly:
        return dateOnly(dateValue)
      case formatTypes.dateMenu:
        return dateMenu(dateValue)
      case formatTypes.dayOfWeek:
        return dayOfWeek(dateValue)
      case formatTypes.minimalDate:
        return minimalDate(dateValue)
      case formatTypes.monthYear:
        return monthYear(dateValue)
      case formatTypes.shortDate:
        return shortDate(dateValue)
      case formatTypes.shorterDate:
        return shorterDate(dateValue)
      case formatTypes.shortestDate:
        return shortestDate(dateValue)
      case formatTypes.time:
        return time(dateValue)
      case formatTypes.ampm:
        return timeAmPm(dateValue)
      case formatTypes.dayMonth:
        return dayMonth(dateValue)
      case formatTypes.dateDayMonth:
        return dateDayMonth(dateValue)
      default:
        return formatDate(dateValue, formattingType)
    }
  } catch (error) {
    console.error(error);
    return value
  }
}



const formatTime = (value, dateOption) => {
  const df = new Date(value).toLocaleTimeString(
    'en-US',
    dateOption
  ); //   

  return df
}


/**
 * 
 * @param {*} time 
 * @returns time, hour, min
 */
export const timeConvertor = (time) => {
  try {
    let _time = time
    let _pm;
    // see if AM or PM exists. Take note and remove
    if (time.match('PM') || time.match('AM')) {
      if (time.match('PM')) { _pm = true }
      _time = _time.replace('AM', '')
      _time = _time.replace('PM', '')
    }

    const timeSplit = _time.split(':')
    let hours = parseInt(timeSplit[0])
    let minutes = parseInt(timeSplit[1])
    if (_pm) { hours += 12 }
    if (hours < 10) { hours = '0' + hours }
    if (minutes < 10) { minutes = '0' + minutes }
    const t = hours + ':' + minutes
    return { time: t.trim(), hour: hours, min: minutes }
  } catch (error) {
    return time
  }
}

/**
 * 
 * @param {*} day 
 * @param {*} time 
 * @returns the `data` using Date(n + "T" + time)
 */
export function getStartTimeUtc(day, time) {

  const _hours = time.match(/^(\d+)/)
  const _minutes = time.match(/:(\d+)/)
  const _ampm = time.match(/\s(.*)$/)

  var hours = Number(_hours[1]);
  var minutes = Number(_minutes[1]);
  var AMPM = _ampm ? _ampm[1] : null;
  if (AMPM && AMPM.toLowerCase() === "pm" && hours < 12) hours = hours + 12;
  if (AMPM && AMPM.toLowerCase() === "am" && hours === 12) hours = hours - 12;
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  time = sHours + ":" + sMinutes + ":00";
  var d = new Date(day);
  var n = d.toISOString().substring(0, 10);
  var newDate = new Date(n + "T" + time);
  return newDate;
}

/**
 *  
 * @param {*} time 
 * @returns the `time` using `formatItem` with `ampm` as the `formatType`
 */
export const formatTimeAmPm = (time) => {
  const { hour, min } = time ? timeConvertor(time) : {}
  const dt = new Date()
  dt.setHours(hour ? hour : 12)
  dt.setMinutes(min ? min : 0)
  return formatItem(formatTypes.ampm, dt)
}

/**
 * 
 * @param {*} items 
 * @param {*} dateFormat 
 * @description Creates UTC dates and times for each of the items
 */
export const ammendDateItemsWithUTC = (items, dateFormat) => {
  if (items) {
    Object.keys(items).forEach(key => {
      const item = items[key]
      Object.keys(item).forEach(propKey => {
        if (propKey.toLowerCase().endsWith('date')) {
          let value = item[propKey]
          if (_.isDate(value)) {
            if (dateFormat) {
              value = formatItem(dateFormat, value)
              item[propKey] = value
            }
            if (value) {
              if (propKey.toLowerCase().endsWith('date')) { item[propKey + 'UTC'] = new Date(value) }
            }
          }
        }
      })
      if (item.startDate && item.startTime) { item['startTimeUTC'] = new Date(item.startDate + ' ' + item.startTime) }
    })
  }
}

