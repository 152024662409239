import React, { createContext, useEffect, useReducer } from 'react';
import StartDimmer from '../../components/alerts/StartDimmer';
import { startHandlers, startInitialState, startReducer, startTypes } from '../reducers/StartReducer';

const useStart = true
const killInterval = 0.5
const _showState = false

/** Displays a `PageDimmer` until the `handleUpdateStart` function in called */

/**
 * @state start_state (`dimmerOn`, `startType`, `startTime`, `killInterval`, `startCaption`, `startDocName`, `killDimmer`)
 * @handlers start_handlers (`handleKillDimmer`, `ha
 */
export const StartContext = createContext();

/** 
 * @param {object} props 
 * @state start_state (`dimmerOn`, `startType`, `startTime`, `killInterval`, `startCaption`, `startDocName`, `killDimmer`)
 * @handlers start_handlers (`handleKillDimmer`, `handleTimeLeft`, `handleUpdateStart`)
 * @description Starting point for the App/Page 
 * @description Displays a `PageDimmer` until the `handleUpdateStart` function in called
 */
const StartProvider = (props) => {

  const init_state = { startType: startTypes.app, startTime: Date.now(), killInterval }
  const [start_state, start_dispatch] = useReducer(startReducer, init_state, startInitialState)
  const start_handlers = startHandlers(start_dispatch, start_state)

  if (_showState) {
    console.log(Object.keys(start_state))
    console.log(Object.keys(start_handlers))
  }

  const { dimmerOn, killDimmer, timeLeft, startTime } = start_state

  useEffect(() => {
    if (killDimmer) {
      if (!timeLeft) {
        const diff = (Date.now() - startTime) / 1000
        console.log('diff', diff)
        start_handlers.handleKillDimmer()
      } else {
        const intervalId = setInterval(() => {
          start_handlers.handleTimeLeft();
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line
  }, [timeLeft, killDimmer]);

  return <React.Fragment>
    <StartContext.Provider value={{ start_state, start_handlers }}>
      {useStart && dimmerOn && <StartDimmer />}
      {props.children}
    </StartContext.Provider>
  </React.Fragment>
}

export default StartProvider 