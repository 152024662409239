import { createAppUserData } from '../../cnr/reducers/AppUserReducer';
import { getAppUserProfile } from './getProfile';

export const getClonedAppUser = (clonedUser, paps_state, callback, appUserCollection) => {

  if (clonedUser) {

    const creds = { userName: clonedUser.email }
    const { pathViews } = paps_state ? paps_state : {}

    const _callback = (appUserInfo) => {
      const appUser = createAppUserData(clonedUser, appUserInfo, null, null, pathViews)
      callback(appUser)
    }

    getAppUserProfile(creds, pathViews, appUserCollection, null, _callback)

  } else {

    callback({ noAuth: true })
    return {}
  }
}