import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"

const rts = {
  handleDataManagementItems: 'handleDataManagementItems',
  handleDataManagementSelect: 'handleDataManagementSelect',
  ...grts
}

export const dataActionsReducer = (state, action) => {

  const { type, dispatch } = action

  switch (type) {

    case rts.handleDataManagementItems:
      return { ...state, dataActionItems: action.dataActionKeys }

    case rts.handleDataManagementSelect:
      return { ...state, dataManagementType: action.dataManagementType }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.handleCloseConfirmation:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    default:
      return { ...state }
  }
}

export const dataActionsInitialState = (initState) => {
  return { ...initState }
};

export const dataActionsHandlers = (dispatch) => {
  return {
    handleDataManagementItems: (dataActionKeys) => { dispatch({ type: rts.handleDataManagementItems, dispatch, dataActionKeys }) },
    handleDataManagementSelect: (dataManagementType) => { dispatch({ type: rts.handleDataManagementSelect, dispatch, dataManagementType }) },
    ...responseHandlers(dispatch)
  }
}