import { where } from 'firebase/firestore';
import { fs_get_data } from '../../../../../global/firestoreData/appData/fsAppData';
import { createRefPath, createRefPath_event } from '../../../../../global/firestoreData/appData/appRefPaths';
import { fs_set_doc } from '../../../../../global/firestoreData/appData/fsData';
import { getSportsYear } from '../../../../../global/firestoreData/helpers/years';
import { getPks } from './seasonalSportsData';

const _showRefPath = false

/**
 * Get data from the `_collectionName` with some `dataParent` and `additionalDataParent` constraints
 * @param {string} collectionName 
 * @param {object} pathViews 
 * @param {object} dataParents 
 * @param {function} callback 
 * @param {string} altCollectionName 
 * @returns via callback data from a document based on the collectionName WHERE the `parentKeys` constraints are correct
 */
export const getParentKeyData_sports = async (collectionName, pathViews, dataParents, callback, dataItem, additionalDataParents, sportsKey, latestSeason, cbProps) => {

  const { collectionItems, collectionDoc, noYear, noSeasonal, allDocs, specificYear } = dataItem ? dataItem : {}

  const _latestSeason = specificYear ? specificYear : latestSeason

  let _collectionName = collectionName.key ? collectionName.key : collectionName
  _collectionName = noSeasonal ? collectionName : '_' + _collectionName


  const cb = (data, opts) => {
    const _opts = { ...opts, collectionItems: collectionItems ? collectionItems : [collectionName], collectionDoc, _latestSeason, cbProps, collectionName, dataItem }
    callback(data, _opts)
  }

  const _refPath = createRefPath_event(pathViews, [_collectionName])

  const wheres = []
  const wherez = []

  if (dataParents) {
    dataParents.forEach(dp => {
      switch (dp) {
        case 'allSportsYears':
        case 'schools':
        case 'sports':
        case 'sportsTeams':
        case 'staff':
          // ignore in this context 
          break;
        default:
          if (pathViews[dp]) {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            wherez.push({ pk: dp, value: pathViews[dp] })
          }
      }
    })
  }

  if (additionalDataParents) {
    additionalDataParents.forEach(dp => {
      switch (dp) {
        case 'sports':
          if (sportsKey) {
            wheres.push(where('parentKeys.' + dp, '==', sportsKey))
            wherez.push({ pk: dp, value: sportsKey })
          } else {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            wherez.push({ pk: dp, value: pathViews[dp] })
          }
          break;
        case 'sportsYear':
          if (latestSeason && !noYear) {
            wheres.push(where('parentKeys.sportsYears', '==', getSportsYear(latestSeason)))
            wherez.push({ pk: 'sportsYears', value: getSportsYear(latestSeason) })
          }
          break;
        default:
          if (pathViews[dp]) {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            wherez.push({ pk: dp, value: pathViews[dp] })
          }
      }
    })
  }

  if (_showRefPath) {
    console.log('_refPath', collectionName, wherez)
  }

  fs_get_data({ refPath: _refPath, wheres, callback: cb, opts: { listen: true, returnFirstObject: allDocs ? false : true } })

}

export const updateParentKeyData_sports = async (collectionName, pathViews, fieldName, data, callback, replaceExistingData, dataParentsAlt, sportsKey) => {

  const _callback = (parentalItem) => {

    if (parentalItem) {

      const existingParentalItem = parentalItem[Object.keys(parentalItem)[0]]

      const _refPath = createRefPath_event(pathViews, [collectionName])

      // const updateData = { parentKeys, [fieldName]: data }
      const updateData = { [fieldName]: data }

      try {
        if (existingParentalItem) {
          const _updateRef = createRefPath([existingParentalItem.id], _refPath)
          if (replaceExistingData) {
            fs_set_doc(_updateRef, updateData, true, callback)
          } else {
            fs_set_doc(_updateRef, updateData, true, callback)
          }
        } else {
          // ADD ??? 
        }
      } catch (error) {
        console.log('updateParentKeyData - error', error)
      }
    }
  }

  getParentalForUpdate(pathViews, collectionName, sportsKey, _callback)

}

const getParentalForUpdate = (pathViews, collectionName, sportsKey, callback) => {

  const _refPath = createRefPath_event(pathViews, [collectionName])
  const wheres = []

  const _pks = getPks(pathViews)

  Object.keys(_pks).forEach(pkk => {
    switch (pkk) {
      case 'sports':
        wheres.push(where('parentKeys.' + pkk, '==', sportsKey))
        break;
      default:
        wheres.push(where('parentKeys.' + pkk, '==', _pks[pkk]))
    }
  })

  fs_get_data({ refPath: _refPath, callback, wheres })

}
