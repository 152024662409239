import _ from 'lodash';
import React, { Suspense, createContext, useContext, useEffect, useReducer } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { signInComponentTypes, signInHandlers, signInMethodTypes, signInReducer, signInReducerInitialState, signInTypes } from '../../cnr/reducers/SignInReducer';
import { uniqueKey } from '../../common/keys';
import { appFormTypes } from '../../enums/appFormTypes';
import { gEnums } from '../../enums/globalEnums';
import AnimateComponent, { _animateTypes } from '../../motions/AnimateComponent';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import FullPageAlert from '../alerts/FullPageAlert';
import SuspenseDimmer from '../alerts/SuspenseDimmer';

const UiConfirmChange = React.lazy(() => import('./signInComponents/UiConfirmChange'));
const UiEmailLinkAuth = React.lazy(() => import('./signInComponents/UiEmailLinkAuth'));
const UiEmailPasswordAuth = React.lazy(() => import('./signInComponents/UiEmailPasswordAuth'));
const UiForgotPassword = React.lazy(() => import('./signInComponents/UiForgotPassword'));
const UiGoogleAuth = React.lazy(() => import('./signInComponents/UiGoogleAuth'));
const UiPhoneAuth = React.lazy(() => import('./signInComponents/UiPhoneAuth'));
const UiResetPasswordConfirmation = React.lazy(() => import('./signInComponents/UiResetPasswordConfirmation'));
const UiSignInLinkConfirm = React.lazy(() => import('./signInComponents/UiSignInLinkConfirm'));
const UiTooMany = React.lazy(() => import('./signInComponents/UiTooMany'));

export const SignInContext = createContext();

const SignIn = (props) => {

  const { fromDirect, signInMethodType: _signInMethodType, itemData } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, settings, navigate } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state } = states
  const { clientSettings } = settings ? settings : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ? frameworkContext : {}

  const { global: global_client } = clientSettings ? clientSettings : {}
  const { appSignIn: appSignIn_client } = global_client ? global_client : {}
  const { forceEventSignInAtClient: forceEventSignInAtClient_c, useClientSignInSettings: useClientSignIn_c } = appSignIn_client ? appSignIn_client : {}

  const { pathViews, rootPaths } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const { appUserAccess } = appUser ? appUser : {}
  const { loggedIn } = appUserAccess ? appUserAccess : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appSignIn, topMenu } = aps_global ? aps_global : {}
  const { allowCreateAccount, signInToClient, useClientSignInSettings } = appSignIn ? appSignIn : {}
  const { mainFixedCaption } = topMenu ? topMenu : {}

  const _signInAtClient = forceEventSignInAtClient_c || signInToClient
  const _useClientSignIn = useClientSignIn_c || useClientSignInSettings

  const _appSignIn = _useClientSignIn ? appSignIn_client : appSignIn

  // reducer
  const initState = signInReducerInitialState({ appSignIn: _appSignIn, paps_state, aps_global, aps_appUserSettings, signInMethodType: _signInMethodType })
  const [signIn_state, dispatch] = useReducer(signInReducer, initState);
  const signIn_handlers = signInHandlers(dispatch)

  const { handleCancelSignIn } = signIn_handlers ? signIn_handlers : {}
  const { creds, email: email_state, signInMethods, signInMethodType, signInComponentType, signInType, confirmationType } = signIn_state ? signIn_state : {}
  const { email } = creds ? creds : {}

  const _email = email_state ? email_state : email

  useEffect(() => {
    let cm;
    if (props.handleCancel) {
      cm = props.handleCancel
    } else if (fromDirect && framework_handlers) {
      cm = framework_handlers.handleShowSignIn
    } else if (signInMethodType) {
      cm = handleCancelType
    } else {
      cm = signIn_handlers.handleSignInMethodType
    }
    signIn_handlers.handleSignInMethods(_appSignIn, cm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_signInAtClient && eventKey) {
      const _cp = rootPaths.clients.substring(0, rootPaths.clients.length - 1)
      navigate(_cp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelType = () => handleSignInMethodType()
  const handleSignInType = () => signIn_handlers.handleSignInType()
  const handleSignInMethodType = (mt) => signIn_handlers.handleSignInMethodType(mt)

  const signInButtons = () => signInMethods.map(sim => (
    <Button labelPosition='left' icon key={uniqueKey('sib', sim.name)} color={signInType === signInTypes.createAccount ? 'green' : sim.color} fluid onClick={() => { handleSignInMethodType(sim.name) }}>
      <Icon name={sim.icon} />{_.startCase(sim.name)}
    </Button>
  ))

  const signUpButton = () => <div className={'sign-up'} onClick={() => { handleSignInType() }}>{signInType === signInTypes.createAccount ? 'Sign In' : 'Create Account'}</div>

  const signInContents = () => {
    if (!signInMethodType && signInMethods && signInMethods.length > 1) {
      return <Segment basic placeholder className={'sib'}>
        <Header as='h5' dividing textAlign='center'>
          {signInType === signInTypes.createAccount ? 'Create Account Options' : 'Sign In Options'}
        </Header>
        {signInButtons()}
        {allowCreateAccount && signUpButton()}
      </Segment>
    } else {
      // signInAlertTypes  
      if (confirmationType) {
        return <UiSignInLinkConfirm />
      } else {
        switch (signInComponentType) {
          case signInComponentTypes.emailReset:
            return <div>Confirmation</div>
          case signInComponentTypes.resetPasswordConfirmation:
            return <UiResetPasswordConfirmation email={_email} />
          case signInComponentTypes.confirmPasswordChange:
            return <UiConfirmChange />
          case signInComponentTypes.forgotPassword:
            return <UiForgotPassword appFormType={appFormTypes.signInEmail} mainFixedCaption={mainFixedCaption} />
          case signInComponentTypes.tooManyAttempts:
            return <UiTooMany />
          default:
            // signInMethodTypes
            switch (signInMethodType) {
              case signInMethodTypes.phone:
              case gEnums.signInProviderTypes.phoneNumber:
                return <UiPhoneAuth mainFixedCaption={mainFixedCaption} appSignIn={_appSignIn} itemData={itemData} />
              case gEnums.signInProviderTypes.emailLink:
                return <UiEmailLinkAuth mainFixedCaption={mainFixedCaption} />
              case gEnums.signInProviderTypes.emailPassword:
                return <UiEmailPasswordAuth mainFixedCaption={mainFixedCaption} />
              case gEnums.signInProviderTypes.google:
                return <UiGoogleAuth />
              default:
                return <UiEmailPasswordAuth />
            }
        }
      }
    }
  }

  // do not use transitions
  const content = () => <AnimateComponent animateType={_animateTypes.component} >
    <Suspense fallback={<SuspenseDimmer origin={'Sign In'} />}>
      {signInContents()}
    </Suspense>
  </AnimateComponent>

  const fpw_signIn = () => <SignInContext.Provider value={{ signIn_state, signIn_handlers }}>
    <FullPageWrapper
      content={content()}
      topperCaption={signInType === signInTypes.createAccount ? 'Create Account' : 'Sign In'}
      topperCaption2={mainFixedCaption}
      handleCancel={handleCancelSignIn}
      isBack={signInMethodType ? true : null}
    ></FullPageWrapper>
  </SignInContext.Provider>

  if (loggedIn) {
    return <FullPageAlert message={'You are already signed in'} navigate={navigate} handleCancel={handleCancelSignIn} />
  } else {
    return fpw_signIn()
  }
}

export default SignIn