import { gEnums } from '../../../enums/globalEnums';
import { addIdsToDataItem } from '../../../firestoreData/appData/ammendData';
import { googleModeTypes } from '../../reducers/GoogleSheetsReducer';
import { postDataConstraits } from './postDataConstraits';

export const getGoogleSheetsData = (props) => {

  let _dataItems;
  let noGoogleSheet;

  const { view, viewKey, uivi, googleSheetsData, googleModeType, dataSourceType, refProps } = props

  switch (dataSourceType) {
    case gEnums.dataSourceTypes.googleSpreadSheet:
      if (googleSheetsData && googleSheetsData[uivi]) {
        _dataItems = reduceDataItems(googleSheetsData[uivi], view, viewKey, uivi, refProps)
        addIdsToDataItem(_dataItems)
      } else {
        noGoogleSheet = true
      }
      break;
    case gEnums.dataSourceTypes.googleSheetsData:
      if (googleSheetsData && googleSheetsData[uivi]) {
        _dataItems = reduceDataItems(googleSheetsData[uivi], view, viewKey, uivi, refProps)
      } else {
        noGoogleSheet = true
      }
      break;
    default:
      if (googleModeType === googleModeTypes.sheets) {
        if (googleSheetsData && googleSheetsData[uivi]) {
          _dataItems = reduceDataItems(googleSheetsData[uivi], view, viewKey, uivi, refProps)
        } else {
          noGoogleSheet = true
        }
      }
    // nothing
  }
  // }

  return {
    googleItemData: _dataItems,
    noGoogleSheet
  }
}

const reduceDataItems = (dataItems, view, viewKey, uivi, refProps) => {
  let _gdis = {};
  if (dataItems) {
    if (view === uivi && viewKey && dataItems[viewKey]) {
      _gdis = { viewKey: dataItems[viewKey] }
    } else {
      const { whereOpts } = refProps ? refProps : {}
      const { priors, posts } = whereOpts ? whereOpts : {}
      const rps = {
        whereOpts: {
          posts: [...priors, ...posts]
        }
      }
      let { postData: pds } = postDataConstraits(rps, dataItems)
      _gdis = pds
    }
  }
  return _gdis
}
