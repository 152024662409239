import React, { createContext, useContext, useReducer } from 'react';
import { actionHandlers, actionInitialState, actionReducer } from '../reducers/ActionReducer';
import { ParentContext } from './ParentContext';

/** 
  @provides action_state,
  @provides action_handlers,
 
 */
export const ActionContext = createContext();

const ActionProvider = (props) => {

  const parentContext = useContext(ParentContext);

  // reducer
  const init_state = { parentContext }
  const [action_state, actionDispatch] = useReducer(actionReducer, actionInitialState(init_state));
  const action_handlers = actionHandlers(actionDispatch)

  const providerValues = () => {
    return {
      action_state,
      action_handlers,
    }
  }

  return <ActionContext.Provider value={providerValues()}>
    {props.children}
  </ActionContext.Provider>
};

export default ActionProvider