export const imageEditTypes = {
  setHeight: 'setHeight',
  setMapProps: 'setMapProps',
  setRotate: 'setRotate',
  setUseMapProps: 'setUseMapProps',
  setWidth: 'setWidth',
  setZoom: 'setZoom',
}

const updateMapProps = (avatarEditor, state) => {
  const aeImage = avatarEditor && avatarEditor.current ? avatarEditor.current.state.imageEdit : {}
  const { zoom, rotate } = state
  const mp = {
    zoom: zoom,
    scale: zoom / 100,
    rotate: rotate,
    position: {
      x: aeImage.x,
      y: aeImage.y
    }
  }
  return mp
}

export const imageEditReducer = (state, action) => {

  const { type } = action

  switch (type) {

    case imageEditTypes.setMapProps:
      return {
        ...state,
        mapProps: updateMapProps(action.avatarEditor, state),
      }

    case imageEditTypes.setUseMapProps:
      return {
        ...state,
        useMapProps: !state.useMapProps,
      }

    case imageEditTypes.setZoom:
      return {
        ...state,
        zoom: action.z,
        avatarEditorChanged: true,
        mapProps: updateMapProps(action.avatarEditor, state),
      }

    case imageEditTypes.setRotate:
      return {
        ...state,
        rotate: action.r,
        avatarEditorChanged: true,
        mapProps: updateMapProps(action.avatarEditor, state),
      }

    case imageEditTypes.setWidth:
      return {
        ...state,
        width: action.w,
        avatarEditorChanged: true,
        mapProps: updateMapProps(action.avatarEditor, state),
      }

    case imageEditTypes.setHeight:
      return {
        ...state,
        height: action.h,
        avatarEditorChanged: true,
        mapProps: updateMapProps(action.avatarEditor, state),
      }

    default:
      return state
  }
}

export const imageEditInitialState = (imageEditProps) => {
  const { maxWidth, maxHeight } = imageEditProps ? imageEditProps : {}
  return {
    avatarEditorChanged: false,
    mapProps: {},
    useMapProps: false,
    zoom: 1,
    rotate: 0,
    height: maxHeight,
    width: maxWidth,
    position: {}
  }
};

export const imageEditHandlers = (dispatch, state) => {
  return {
    setMapProps: (mapProps) => { dispatch({ type: imageEditTypes.setAvatorEditor, mapProps: mapProps }) },
    setUseMapProps: () => { dispatch({ type: imageEditTypes.setUseMapProps }) },
    setZoom: (avatarEditor, z) => { dispatch({ type: imageEditTypes.setZoom, avatarEditor, z }) },
    setRotate: (avatarEditor, r) => { dispatch({ type: imageEditTypes.setRotate, avatarEditor, r }) },
    setWidth: (avatarEditor, w) => { dispatch({ type: imageEditTypes.setWidth, avatarEditor, w }) },
    setHeight: (avatarEditor, h) => { dispatch({ type: imageEditTypes.setHeight, avatarEditor, h }) },
  }
}