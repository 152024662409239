import { createRefPath_client } from "../../firestoreData/appData/appRefPaths";
import { fs_get_data } from "../../firestoreData/appData/fsAppData";
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

export const rts = {
  handleInit: 'handleInit',
  handleSetProfiles: 'handleSetProfiles',
  ...grts
}

export const appUsersReducerInitialState = (initState) => {
  return { ...initState }
};

export const appUsersReducer = (state, action) => {

  const { pathViews } = state
  const { type, dispatch } = action

  const { handleSetProfiles } = appUsersHandlers(dispatch)

  switch (type) {

    case rts.handleInit:
      const refPath = createRefPath_client(pathViews, ['profiles'])
      fs_get_data({ refPath: refPath, callback: handleSetProfiles })
      return { ...state }

    case rts.handleSetProfiles:
      return { ...state, appUserProfiles: action.appUserProfiles, profilesRetrieved: true }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const appUsersHandlers = (dispatch) => {
  return {
    handleInit: () => { dispatch({ type: rts.handleInit, dispatch }) },
    handleSetProfiles: (appUserProfiles) => { dispatch({ type: rts.handleSetProfiles, dispatch, appUserProfiles }) },
    ...responseHandlers(dispatch)
  }
}
