import _ from 'lodash';
import React from 'react';
import { Label } from 'semantic-ui-react';
import { ammendUiValue } from '../../common/convert';

const size = 'large'

export const numberLabel = (index, text) => <Label><Label color={'blue'} >{index}</Label>{text}</Label>

export const extraLabel = (index, text, item, additionalProps) => {
  const lbls = []
  lbls.push(<div>{text}</div>)
  additionalProps.forEach(ap => {
    if (item[ap]) {
      lbls.push(<div><Label color={'blue'}>{item[ap]}</Label></div>)
    }
  })
  return <Label className='grid-row' > {lbls}</Label>
}

export const labels = (lbProps) => {

  const { items, viewKey, sortGroupName, handleDrill, numbered, numberLimit, ignoreStatic, staticList, additionalProps } = lbProps

  const itemz = sortGroupName ? _.sortBy(items, 'position') : items

  const handleClick = (e, item) => handleDrill && handleDrill(e, item)

  const lbs = (itemz) => {
    const lbs = []
    if (itemz) {
      Object.keys(itemz).forEach((key, index) => {
        const item = itemz[key]

        const { name, firstName, lastName } = item ? item : {}
        const trueName = lastName ? firstName + ' ' + lastName : name
        let caption = trueName ? trueName : key

        caption = ammendUiValue(caption, key)

        if (!ignoreStatic && staticList && caption && staticList[caption]) {
          const svi = staticList[caption]
          const { name, lastName, firstName } = svi ? svi : {}
          if (lastName && firstName) {
            caption = firstName + ' ' + lastName
          } else if (name) {
            caption = name
          }
        }
        if (numbered) {
          if (!numberLimit) {
            lbs.push(<div className={'drag-number'}>{numberLabel(index + 1, caption)}</div>)
          } else {
            if (index + 1 <= numberLimit) {
              lbs.push(<div className={'drag-number'}>{numberLabel(index + 1, caption)}</div>)
            } else {
              lbs.push(<div className={'drag-number'}>{numberLabel('--', caption)}</div>)
            }
          }
        } else {
          if (additionalProps && 1 === 3) {
            if (viewKey === key) {
              lbs.push(extraLabel(index + 1, caption, item, additionalProps))
            } else {
              lbs.push(extraLabel(index + 1, caption, item, additionalProps))
            }
          } else {
            if (viewKey === key) {
              lbs.push(<Label size={size} className='selected' key={key}>{caption}</Label>)
            } else {
              lbs.push(<Label size={size} key={key} onClick={(e) => { handleClick(e, item) }}>{caption}</Label>)
            }
          }

        }
      })
    }
    return lbs
  }

  return lbs(itemz)

}
