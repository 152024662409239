import React, { useContext, useEffect } from 'react'
import { Checkbox, List, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { uniqueKey } from '../../common/keys';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { _settingsFs } from '../../viewSettings/actions/getSettings';

export const EventsViewer = (props) => {

  const { handleSelectedEvent, handlePreviewInfo, selectedEventKey } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, eventInfo_state } = states
  const { clientEvents } = eventInfo_state

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { events: events_appUser } = appUserAccess ? appUserAccess : {}

  // papsContext 
  const { pathViews } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}

  let noEventsCaption = !pathViews.clients ? 'You do not have any events. The Client is unknown.' : 'You do not have any events.'
  if (!events_appUser) { noEventsCaption = 'Searching Events' }

  useEffect(() => {
    const cb = (result) => handlePreviewInfo(result)
    if (selectedEventKey) {
      const ref = createRefPath([_settingsFs.root, selectedEventKey, _settingsFs.collection])
      fs_get_data({ refPath: ref, callback: cb, opts: { ignoreIds: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedEventKey]);

  const handleCheck = (eventKey) => handleSelectedEvent(eventKey)

  const listItem = (key, name, checked) => <List.Item key={uniqueKey('ct', 'li', key)}>
    <List.Content key={uniqueKey('ev', 'li', 'c1', key)} floated='left'>
      <Checkbox checked={checked} onClick={(e) => { handleCheck(key, name, checked) }} />
    </List.Content>
    <List.Content key={uniqueKey('ct', 'li', 'c2', key)}>{name}</List.Content>
  </List.Item>

  const eventList = () => {
    const lis = []
    if (clientEvents && Object.keys(clientEvents).length > 0) {
      Object.keys(clientEvents).forEach(key => {
        const event = clientEvents[key]
        const { name } = event
        const selected = selectedEventKey === key
        lis.push(listItem(key, name, selected))
      })
      return <div key={uniqueKey('ue', 'd')} className={'c-container'} >
        <div className={'c-content'} >
          <List divided relaxed verticalAlign='middle' className={'list-cb-container'}>
            {lis}
          </List ></div>
      </div>
    } else {
      return <div key={uniqueKey('ue', 'd')} className={'c-container'} >
        <div className={'c-content padd'}>{noEventsCaption}</div></div>
    }
  }

  const header = () => 'Client Events'

  return <Wrapper
    header={!eventKey && header()}
    content={<Segment basic inverted>{eventList()}</Segment>}
    wrapperType={wrapperTypes.padded}
  />
}