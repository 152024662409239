import React, { useEffect, useState } from 'react'
import { Dropdown, Form, Icon, Input, List } from 'semantic-ui-react'
import { uniqueKey } from '../common/keys'
import { removeArrayItem } from '../common/sorting'

const ListAdd = (props) => {

  const { fip, inverted } = props ? props : {}
  const { label, onChange, value } = fip ? fip : {}

  const [list, setList] = useState()
  const [currentValue, setCurrentValue] = useState()

  useEffect(() => {
    setList(value ? value : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [value]);

  const updateValue = (value) => {
    let _value = value
    if (_value && _value.length === 0) { _value = null }
    const x = {
      propname: fip.propname,
      value: _value,
    }
    onChange(null, x)
  }

  const handleChange = (e, data) => setCurrentValue(data.value)

  const handleAddToList = () => {
    const _list = [...list]
    if (!_list.includes(currentValue)) {
      _list.push(currentValue)
      setList(_list)
      updateValue(_list)
    }
    setCurrentValue('')
  }

  const handleRemove = (item) => {
    const _list = [...list]
    removeArrayItem(_list, item)
    setList(_list)
    updateValue(_list)
  }

  const options = [
    { key: '.com', text: '.com', value: '.com' },
    { key: '.net', text: '.net', value: '.net' },
    { key: '.org', text: '.org', value: '.org' },
  ]

  const ddInput = () => <Form>
    <Input
      fluid
      label={<Dropdown defaultValue='.com' options={options} />}
      labelPosition='right'
      placeholder='Find domain'
    />
  </Form>

  const input = () => <Form.Input
    fluid
    value={currentValue}
    label={label}
    onChange={handleChange}
    action={{ disabled: !currentValue, color: 'blue', icon: 'check', onClick: () => { handleAddToList() } }}
  />

  const listItems = () => list.map((item, index) => {
    return <List.Item key={uniqueKey('la', index)} onClick={(e) => { handleRemove(item) }} >
      <List.Content floated='right'>
        <Icon name={'delete'} color={'red'} onClick={(e) => { handleRemove(item) }} />
      </List.Content>
      <List.Content>
        <List.Header>{item}</List.Header>
      </List.Content>
    </List.Item >
  })

  const content = () => <div style={{ padding: '0 0 1em 0' }}>
    {input()}
    <List divided relaxed inverted={inverted} ordered>
      {list && listItems()}
    </List></div>

  return content()

}

export default ListAdd