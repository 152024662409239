import _ from 'lodash'
import React, { createContext, Suspense, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Menu, Segment, Sidebar } from 'semantic-ui-react'
import { getDataManagementOptions } from '../../auth/actionAccessPermissions'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'
import DataProvider, { DataContext } from '../../cnr/contexts/DataContext'
import { FirestoreContext } from '../../cnr/contexts/FirestoreContext'
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent'
import { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { UiItemContext } from '../../cnr/contexts/UiItemContext'
import { dataManagementHandlers, dataManagementInitialState, dataManagementReducer, dataMergeTypes, dvLabelColors, viewDataModes } from '../../cnr/reducers/DataManagmentReducer'
import { helpTypes } from '../../cnr/reducers/HelpReducer'
import { convertViewListDataToGoogleSheetsData } from '../../common/convert'
import { uniqueKey } from '../../common/keys'
import { copyObj } from '../../common_web/copy'
import UiSaveButtons from '../../components/buttons/UiSaveButtons'
import Copy from "../../copy/Copy"
import { appIconTypes } from '../../enums/appIconTypes'
import { updateViewItemsToDb } from '../../firestoreData/settings/updateSettings'
// import { fsfn_updateGooglsSheetValues } from '../../functions/fbSheetsFuntions'
import { listList } from '../../lists/lists'
import DataManagementContent from '../../pageItem/modification/DataManagementContent'
import UiDataModifications, { dataModificationDisplayTypes } from '../../pageItem/modification/UiDataModifications'
import { handleOpenDb, openGoogleSheets } from '../../viewSettings/helpers/settingsLinks'
import SettingsFormEdit from "../../viewSettings/subComponents/SettingsFormEdit"
import FullPageWrapper from '../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import SuspenseDimmer from '../alerts/SuspenseDimmer'
import Help from '../help/Help'
import AlphaTableViewer from "./AlphaTableViewer"
import { appViewerColors } from './AppDashboard'
import AppDataViewer from "./AppDataViewer"
import JsonViewer from "./JsonViewer"
import TableViewer from "./TableViewer"

export const DataManagementContext = createContext();

const _alphaLimit = 75

/**
 * 
 * @param {object} props (fromDataViewer, uivi, viewItem, viewListData)
 * @returns 
 */
export const DataManagementViewer = (props) => {

  const { fromDataViewer, uivi: uivi_direct, viewItem: viewItem_direct, viewListData: viewListData_direct } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state, eventInfo_state } = states ? states : {}
  const { page_fns } = fns ? fns : {}
  const { pathViews, viewKey } = paps_state ? paps_state : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // homeSettingsContext 
  const { homeSettings } = settings ? settings : {}
  const { global } = homeSettings ? homeSettings : {}
  const { settingsConsole: settingsConsole_home_global, permissionsConsole: permissionsConsole_home_global } = global ? global : {}
  const { dataManagementSettings } = permissionsConsole_home_global ? permissionsConsole_home_global : {}
  // const { dataManagementSettings } = settingsConsole_home_global ? settingsConsole_home_global : {}

  // eventInfoContext  
  const { staticViews: staticViews_app } = eventInfo_state ? eventInfo_state : {}

  // pageSettings
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems } = pageSettings ? pageSettings : {}
  const { appDataManagement, googleSheets, dataOptions, productionSettings } = aps_global ? aps_global : {}
  const { allowGoogleSheets, googleSheetsId } = googleSheets ? googleSheets : {}
  const { dateFormat } = dataOptions ? dataOptions : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { fullMode } = framework_state ? framework_state : {}

  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers } = firestoreContext ? firestoreContext : {}

  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_handlers, googleSheets_state } = googleSheetsContext ? googleSheetsContext : {}
  const { updating, confirmation } = googleSheets_state ? googleSheets_state : {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ? dataContext : {}
  const { uivi, fsr, viewListData, viewItem } = data_state ? data_state : {}
  const { dataSource } = viewItem ? viewItem : {}
  const { altDataCollectionName } = dataSource ? dataSource : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}

  const allowDataSource = item_state ? true : false

  let _uivi = altDataCollectionName ? altDataCollectionName : uivi
  if (uivi_direct) { _uivi = uivi_direct }

  let _viewItem = viewItem_direct ? viewItem_direct : viewItem
  if (!_viewItem) { _viewItem = {} }
  const _viewListData = viewListData_direct ? viewListData_direct : viewListData
  const { key: key_viewItem } = _viewItem ? _viewItem : {}

  const init_state = { fsr, productionSettings, firestore_handlers, page_fns, dataManagementType: 'options', paps_state, viewListData: _viewListData, staticViews_app, dateFormat, pathViews, viewKey, viewItem: _viewItem, aps_viewItems }
  const [dataManagement_state, fullData_dispatch] = useReducer(dataManagementReducer, dataManagementInitialState(init_state));
  const dataManagement_handlers = dataManagementHandlers(fullData_dispatch)
  const { handleDataActionSelect } = dataManagement_handlers ? dataManagement_handlers : {}

  const {
    activeTab, // triggered from DataTabs
    appData,
    dataCount,
    diffData,
    fixedData,
    dataManagementType,
    ammendedItem,
    selectedItem,
    showHelp,
    tabData,
    viewDataMode,
    viewItem_previewTemp,
  } = dataManagement_state ? dataManagement_state : {}

  const { ammendedViewItem, ammendedKeys } = ammendedItem ? ammendedItem : {}

  const [copyText, setCopyText] = useState()
  const [dataActionOptions, setDataActionOptions] = useState()
  const [showAlpha, setShowAlpha] = useState()
  const [dataManagementOptions, setDataManagementOptions] = useState()

  const showJson = viewDataMode === viewDataModes.viewJson
  const allowDiffSave = diffData && Object.keys(diffData).length > 0

  const tableRef = useRef()

  useEffect(() => {
    let _dataManagementOptions = getDataManagementOptions(dataManagementSettings, appDataManagement, appUserAccess)
    setDataManagementOptions(_dataManagementOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_uivi, _viewListData]);

  useEffect(() => {
    if (_viewItem) {
      const ada = []
      if (dataManagementOptions) {
        Object.keys(dataManagementOptions).forEach(daKey => {
          const _dmo = dataManagementOptions[daKey]
          switch (daKey) {
            case 'manageData':
            case 'viewData':
              break;
            default:
              if (_dmo.allowAction) {
                ada.push(daKey)
              }
          }
        })
      }
      setDataActionOptions(listList(ada, null, null, null, 'options'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataManagementOptions, _viewListData]);

  useEffect(() => {
    if (_viewListData) {
      dataManagement_handlers.handleAppItemData(_viewItem, _viewListData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_uivi, _viewListData]);

  useEffect(() => {
    if (appData) {
      const dataCount = appData ? Object.keys(appData).length : 0
      if (dataCount > _alphaLimit) {
        setShowAlpha(true)
      } else {
        setShowAlpha(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  useEffect(() => {
    if (fixedData) {
      dataManagement_handlers.handleActiveTab(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedData]);

  const [showDataOptions, setShowDataOptions] = useState()

  const handleDatabaseOpen = () => handleOpenDb(pathViews, null, _uivi, viewKey)
  const handleOpenGoogleSheets = () => openGoogleSheets(googleSheetsId)

  const handleDownloadToGoogleSheets = () => {
    const _itemData = convertViewListDataToGoogleSheetsData(appData, viewItem_previewTemp, staticViews_app, true)
    googleSheets_handlers.handleUpdate_googleSpreadsheetTab(_.startCase(_uivi) + ' App', _itemData)
    // fsfn_updateGooglsSheetValues(googleSheetsId, { key: _uivi + ' App' }, _itemData)
  }
  const handleCreateSpreadsheet = () => googleSheets_handlers.handleCreateSpreadsheet('Testing Spreadsheet')

  // tabName, fetchFirestoreData, values
  const handleCreateSpreadsheetTab = () => {
    googleSheets_handlers.handleStartUpdate()
    const _itemData = convertViewListDataToGoogleSheetsData(appData, viewItem_previewTemp, staticViews_app, false)
    googleSheets_handlers.handleCreateGoogleSpreadsheetTab(_.startCase(_uivi) + ' App', false, _itemData)
  }

  // const handleCreateSpreadsheetTabs = () => {

  //   const { projectData } = previewInfo ? previewInfo : {}
  //   const { dataCollections } = projectData ? projectData : {}

  //   if (dataCollections) {
  //     Object.keys(dataCollections).forEach(k => {
  //       const dataCollection = dataCollections[k]
  //       const _itemData = convertViewListDataToGoogleSheetsData(dataCollection)
  //       console.log('k', k, _itemData)
  //       // googleSheets_handlers.handleCreateGoogleSpreadsheetTab(_.startCase(k) + ' Alt', false, _itemData)
  //     })
  //   }
  //   // console.log('dataCollections', dataCollections) 
  // }


  // shortcuts for dataManagement_handlers
  const handleApplyExisting = () => dataManagement_handlers.handleDiffUpdates(dataMergeTypes.existingValue)
  const handleApplyNew = () => dataManagement_handlers.handleDiffUpdates(dataMergeTypes.newValue)
  const handleGetDiff = (opts) => dataManagement_handlers.handleGetDiff(opts)
  const handleGetNew = (opts) => dataManagement_handlers.handleGetNew(opts)
  const handlePreview = (data) => dataManagement_handlers.handleSettingsFormUpdate(data)
  const handleRowSelect = (item) => dataManagement_handlers.handleRowSelect(item)
  const handleSettingsFormUpdate = (data, save) => dataManagement_handlers.handleSettingsFormUpdate(data, save)
  const handleViewDataMode = (opts) => dataManagement_handlers.handleViewDataMode(opts)
  const handleShowHelp = () => dataManagement_handlers.handleShowHelp()
  const handleNone = () => { }

  const handleFixProps = () => {
    const viewItems = copyObj(aps_viewItems)
    viewItems[key_viewItem] = ammendedViewItem
    updateViewItemsToDb(pathViews, viewItems, ammendedKeys)
  }

  const handleCopy = () => {
    if (tableRef) {
      const { outerText } = tableRef.current
      setCopyText(outerText)
    } else if (tabData) {
      setCopyText(JSON.stringify(tabData))
    }
  }

  const handleShowDataOptions = () => setShowDataOptions(!showDataOptions)

  const handleSelectActionOption = (key) => {
    setShowDataOptions()
    dataManagement_handlers.handleSelectDataActionOption(key)
  }

  /**
   * 
   * @returns a menu with all of the dataActionOptions
   * @description onClick will triggner handleSelectActionOption
   */
  const dataManagementMenu = () => {
    const mis = []
    dataActionOptions.forEach(ao => {
      const { value, key, text } = ao
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', key)} onClick={() => { handleSelectActionOption(value) }}>{_.startCase(text)}</Menu.Item>)
    })
    return <Segment inverted basic><Menu vertical fluid inverted className={'dmv-menu'}>{mis}</Menu></Segment>
  }

  /**
   * 
   * @returns an editable form
   */
  const settingsForm = () => <SettingsFormEdit
    settingsType={viewDataMode}
    formData={selectedItem}
    useWrapper={true}
    previewForm={handlePreview}
    updateForm={viewItem_previewTemp && handleSettingsFormUpdate}
    closeForm={handleViewDataMode}
    isGlobal={false}
    inSidebar={true}
  />

  /**
   * 
   * @returns the content for the sidebar based on the `viewDataMode`
   * @description this will only be shown if `viewDataMode` is set.
   */
  const rightSidebarContent = () => {
    switch (viewDataMode) {
      case viewDataModes.dataSource:
        return settingsForm()
      case viewDataModes.viewJson:
        return <JsonViewer json={tabData} name={viewDataMode} />
      case viewDataModes.dataEdit:
        return <UiDataModifications dataModificationDisplayType={dataModificationDisplayTypes.direct} />
      default:
        return <div>No View Data Mode</div>
    }
  }

  /** The button that triggers the dataOptions to be shown */
  const dataOptionsButton = () => {
    const btns = [
      { caption: _.startCase(dataManagementType), oc: handleShowDataOptions, icon: 'content' },
    ]

    return <UiSaveButtons
      others={btns}
      color={appViewerColors.dmv}
      rows={2}
    />
  }

  /**
   * 
   * @returns The buttons for the top menu
   */
  const topButtons = () => {
    switch (dataManagementType) {
      case 'options':
        switch (activeTab) {
          case 0:
            const btns = []
            if (tabData) {
              btns.push({ caption: _.startCase(viewDataModes.viewJson), oc: handleViewDataMode, icon: 'settings', opts: { viewDataMode: viewDataModes.viewJson } })
            }
            if (allowDataSource) {
              btns.push({ caption: _.startCase(viewDataModes.dataSource), oc: handleViewDataMode, icon: 'filter', opts: { viewDataMode: viewDataModes.dataSource } })
            }
            btns.push({ caption: appData ? Object.keys(appData).length : 0, oc: handleNone, color: 'black', icon: 'hashtag' })
            return <UiSaveButtons
              others={btns}
              color={'blue'}
              rows={2}
            />
          case 1:
          case 2:
            return <UiSaveButtons
              json={{ caption: _.startCase(viewDataModes.viewJson), oc: handleViewDataMode, icon: 'settings', opts: { viewDataMode: viewDataModes.viewJson } }}
              rows={2}
            />
          case 3:
            return <UiSaveButtons
              json={{ caption: _.startCase(viewDataModes.viewJson), oc: handleViewDataMode, icon: 'settings', opts: { viewDataMode: viewDataModes.viewJson } }}
              rows={2}
            />
          default:
            break;
        }
        break;
      default:
        return <div></div>
    }
  }

  /**
   * 
   * @returns The buttons for the bottom menu
   */
  const bottomButtons = () => {

    const btns = [];

    // btns.push({ caption: 'Help', oc: handleShowHelp, icon: 'help', color: 'yellow' })

    switch (dataManagementType) {
      case 'options':
        switch (activeTab) {
          case 0:
            ammendedViewItem && btns.push({ caption: 'Fix View Item Settings', color: 'blue', oc: handleFixProps, icon: appIconTypes.wizard })
            btns.push({ caption: 'Copy', oc: handleCopy, icon: appIconTypes.copy })
            btns.push({ caption: 'Paste', oc: handleCopy, icon: appIconTypes.copy })
            btns.push({ caption: 'Open DB', oc: handleDatabaseOpen, color: 'orange', icon: appIconTypes.externalAlternate })
            // btns.push({ caption: 'Craet DB', oc: handleDatabaseOpen, color: 'orange', icon: appIconTypes.externalAlternate })
            allowGoogleSheets && btns.push({ caption: 'Open GS', color: 'orange', oc: handleOpenGoogleSheets, icon: appIconTypes.externalAlternate })
            allowGoogleSheets && btns.push({ caption: 'Update GS Tab', color: 'orange', oc: handleDownloadToGoogleSheets, icon: 'arrow right' })
            allowGoogleSheets && btns.push({ caption: 'Create GS Tab', color: 'orange', oc: handleCreateSpreadsheetTab, icon: 'arrow right' })
            // allowGoogleSheets && btns.push({ caption: 'Create Google Spreadsheet', color: 'orange', oc: handleCreateSpreadsheet, icon: 'arrow right' })
            break;
          case 1:
            btns.push({ caption: 'Get Differences', oc: handleGetDiff, icon: appIconTypes.arrowRight, opts: { mergeWith: 'regData' } })
            btns.push({ caption: 'Get New', oc: handleGetNew, icon: appIconTypes.arrowRight, opts: { mergeWith: 'regData' } })
            break;
          case 2:
            btns.push({ caption: 'Get Differences', oc: handleGetDiff, icon: appIconTypes.arrowRight, opts: { mergeWith: 'googleData' } })
            break;
          case 3:
            btns.push({ caption: 'Apply New Data', disabled: !allowDiffSave, oc: handleApplyNew, icon: 'check', color: dvLabelColors.googleData })
            btns.push({ caption: 'Apply Existing Data', disabled: !allowDiffSave, oc: handleApplyExisting, icon: 'check', color: dvLabelColors.existingItems })
            break;
          default:
            break;
        }


        if (btns.length > 0) {
          return <UiSaveButtons
            others={btns}
            color={appViewerColors.dmv}
            rows={2}
          />
        } else {
          return <div></div>
        }
      default:
        return <div></div>
    }
  }

  const header = () => <div className={'header-flex head'}>
    {dataOptionsButton()}
    {topButtons()}
  </div>

  const footer = () => {
    switch (dataManagementType) {
      case 'options':
        return <div className={'header-flex foot'}>
          {bottomButtons()}
        </div>
      default:
        return null
    }
  }

  const tableViewer = () => <TableViewer
    handleRowSelect={handleRowSelect}
    isAppData={true}
    jsonData={appData}
    tableRef={tableRef}
    usePropAdjustments={true}
    viewItem={_viewItem}
    showItemKey={true}
  />

  const alphaTableViewer = () => <AlphaTableViewer appData={appData} handleRowSelect={handleRowSelect} tableRef={tableRef} viewItem={_viewItem} showItemKey={true} />
  const dataContent = () => showAlpha ? alphaTableViewer() : tableViewer()

  /**
   * 
   * @returns DataManagementContent bases on the `dataManagementType`
   */
  const dataManagementContent = () => <Suspense fallback={<SuspenseDimmer origin={'DataManagementViewer'} />}>
    <Wrapper
      content={<DataManagementContent dataManagementType={dataManagementType} />}
    />
  </Suspense>


  /**
   * 
   * @returns dataContent OR dataManagementContent
   */
  const content = () => {
    switch (dataManagementType) {
      case 'options':
        return dataContent()
      default:
        return dataManagementContent()
    }
  }

  /**
   * 
   * @returns the content in a wrapper
   */
  const mainContent = () => {
    let cn = 'full-data-viewer'
    if (selectedItem) { cn += ' selected' }
    if (showHelp) {
      return <Help helpType={helpTypes.actions} handleCancel={handleShowHelp} />
    } else {
      return _viewItem ? <React.Fragment>
        <Wrapper
          header={fullMode ? header() : header()}
          content={content()}
          footer={fullMode ? footer() : footer()}
          wrapperType={wrapperTypes.paddedHeaderAndFooter}
          updating={updating}
          confirmation={confirmation}
          css={{ container: cn }}
        />
        <Copy copyText={copyText} forTable={showJson} />
      </React.Fragment>
        :
        <Segment basic>No View</Segment>
    }
  }

  const OptionsSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv'
      onHide={() => handleShowDataOptions()}
    >
      {dataManagementMenu()}
    </Sidebar>
  )

  const RightVerticalSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'right'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv'
      onHide={() => handleViewDataMode()}
    >
      {rightSidebarContent()}
    </Sidebar>
  )

  // className={'h100'}

  const sidebar = () => {
    return <Sidebar.Pushable style={{ overflow: 'hidden' }}>
      <OptionsSidebar visible={showDataOptions ? true : false} />
      <RightVerticalSidebar visible={viewDataMode ? true : false} />
      <Sidebar.Pusher dimmed={viewDataMode ? true : false} >
        {mainContent()}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  }

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    handleCancel={handleDataActionSelect}
    topperCaption={'Data Management'}
    topperCaption2={dataCount ? _.startCase(_uivi) + '(' + dataCount + ')' : _.startCase(_uivi)}
  />

  return _viewItem ? <DataManagementContext.Provider value={{ dataManagement_state, dataManagement_handlers }}>
    <DataProvider uivi={_uivi} fromDataViewer={fromDataViewer}>
      {fromDataViewer ? sidebar() : fullPageWrapper()}
    </DataProvider>
  </DataManagementContext.Provider>
    :
    <AppDataViewer />
}