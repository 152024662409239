import { createResponseTypes } from "./CreateReducer";
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";
import { fsfn_getFormContent, fsfn_getFormResponse } from "../../functions/fbFormsFunctions";
import { resultPromiseHandler } from "./reducerHelpers/reponsePromiseHandler";
import { fsfn_createProjectFromSheets } from "../../functions/fbSheetsFuntions";

const rts = {
  handleGetFormContent: 'handleGetFormContent',
  handleGetFormResponses: 'handleGetFormResponses',
  handleGetSheetsFormResponses: 'handleGetSheetsFormResponses',
  handlePreviewInfo: 'handlePreviewInfo',
  ...grts,
  ...createResponseTypes
}

export const googleFormsInitialState = (initState) => {
  return { ...initState }
};

export const googleFormsReducer = (state, action) => {

  const { pathViews, googleFormsId, googleFormsResponseId } = state
  const { type, dispatch } = action

  const _gsh = googleFormsHandlers(action.dispatch)
  const { handlePreviewInfo } = _gsh

  switch (type) {
    case rts.handleGetSheetsFormResponses:
      const formData = { eventName: 'Settings', googleSheetsId: googleFormsResponseId }
      const sheetProps = { formData, pathViews: pathViews }
      const _rdp = fsfn_createProjectFromSheets(sheetProps, 'event')
      resultPromiseHandler(_rdp, handlePreviewInfo)
      return { ...state }

    case rts.handleGetFormContent:
      fsfn_getFormContent(googleFormsId).then(res => {
        console.log('getFormContent', res)
      })
      return { ...state }

    case rts.handleGetFormResponses:
      fsfn_getFormResponse(googleFormsId).then(res => {
        console.log('getFormResponses', res)
      })
      return { ...state }

    case rts.handlePreviewInfo:
      return { ...state, previewInfo: action.results, updating: false }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action,
        {
          dispatch,
          dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmationClick,
          questionProps: null,
          updatingCaption: 'Creating',
        })

    default:
      return state;
  }
}

export const googleFormsHandlers = (dispatch) => {
  return {
    handlePreviewInfo: (results) => { dispatch({ type: rts.handlePreviewInfo, dispatch, results }) },
    handleGetSheetsFormResponses: () => { dispatch({ type: rts.handleGetSheetsFormResponses, dispatch }) },
    handleGetFormContent: () => { dispatch({ type: rts.handleGetFormContent, dispatch }) },
    handleGetFormResponses: () => { dispatch({ type: rts.handleGetFormResponses, dispatch }) },
    ...responseHandlers(dispatch)
  }
}
