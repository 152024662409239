import React, { useContext, useEffect, useReducer } from 'react';
import { Icon, Label, List, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import NoData from '../components/alerts/NoData';
import AppForm from '../components/forms/appForms/AppForm';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { appUserLevels } from '../enums/globalEnums';
import { appIconTypes } from '../enums/appIconTypes';
import { appFormTypes } from '../enums/appFormTypes';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { votingHandlers, votingInitialState, votingReducer } from '../cnr/reducers/VotingReducer';
import { renderHtml } from '../components/html/renderHtml';

const VotingManagement = (props) => {

  const { handleCancel } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state } = states ? states : {}
  const { pathViews } = paps_state ? paps_state : {}
  const { appUser_fns } = fns ? fns : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appVoting } = aps_global ? aps_global : {}
  const { votingManagerAuthLevel, votingManagerEmails } = appVoting ? appVoting : {}

  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { profileId } = appUserAccess ? appUserAccess : {}

  const allowMods = appUser_fns ? appUser_fns.validateAccess_item(appUserLevels.admin) : false
  const allowVme = appUser_fns.validateAccess_item(null, null, votingManagerEmails)
  const allowVma = appUser_fns.validateAccess_item(votingManagerAuthLevel)

  const _allowVotingManagment = allowMods || allowVme || allowVma

  const init_state = { pathViews, profileId }
  const [voting_state, votingDispatch] = useReducer(votingReducer, votingInitialState(init_state));
  const voting_handlers = votingHandlers(votingDispatch)

  const { showAddEditVoting, showAddEditVoteItem, voteItemEditKey, votingInfos, selectedVotingKey, voteResults, voteItems } = voting_state

  const currentVotingItem = votingInfos ? votingInfos[selectedVotingKey] : null
  const { active: voteActive } = currentVotingItem ? currentVotingItem : {}

  useEffect(() => {
    voting_handlers.handleGetVoting()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleShowVoting = (key) => voting_handlers.handleShowVoting(key)
  const handleShowAddEditVoting = (key, e) => voting_handlers.handleShowAddEditVoting(key, e)
  const handleShowAddEditVoteItem = (key) => voting_handlers.handleShowAddEditVoteItem(key)
  const handleActivateVoting = () => voting_handlers.handleActivateVoting()
  const handleDeleteVoting = () => voting_handlers.handleDeleteVoting()
  const handleDeleteVoteItem = () => voting_handlers.handleDeleteVoteItem()
  const handleSaveVoting = (formData) => voting_handlers.handleSaveVoting(formData)
  const handleSaveVoteItem = (formData) => voting_handlers.handleSaveVoteItem(formData)

  const form_voting = () => {
    const parentData = votingInfos ? votingInfos[selectedVotingKey] : null
    return <AppForm
      appFormType={appFormTypes.simpleInput}
      handleSave={handleSaveVoting}
      handleDelete={handleDeleteVoting}
      parentData={parentData}
    />
  }

  const form_voteItem = () => {
    const parentData = voteItemEditKey ? voteItems[voteItemEditKey] : null
    return <AppForm
      appFormType={appFormTypes.voting}
      handleSave={handleSaveVoteItem}
      handleDelete={handleDeleteVoteItem}
      parentData={parentData}
    />
  }

  const resultCount = (key) => {
    let yesCount = 0
    let noCount = 0
    if (voteResults) {
      Object.keys(voteResults).forEach(vrKey => {
        const voteResult = voteResults[vrKey]
        const { votes } = voteResult ? voteResult : {}
        if (votes) {
          switch (votes[key]) {
            case true:
              yesCount++
              break;
            case false:
              noCount++
              break;
            default:
            // nothing
          }
        }
      })
    }
    return <React.Fragment>
      <Label color='red'><Icon name='thumbs down outline' />{noCount}</Label>
      <Label color='green'><Icon name='thumbs up outline' />{yesCount}</Label>
    </React.Fragment>
  }

  const votingContent = () => {
    const list = []
    if (votingInfos) {
      Object.keys(votingInfos).forEach(key => {
        const votingInfo = votingInfos[key]
        const { name } = votingInfo
        list.push(<List.Item >
          <List.Content floated='right'>
            <Icon name={appIconTypes.arrowRight} onClick={(e) => handleShowVoting(key)} />
          </List.Content>
          <List.Content>{name}</List.Content>
        </List.Item>)
      })
    }
    if (list.length > 0) {
      return <Segment basic>
        <List relaxed divided>
          {list}
        </List>
      </Segment>
    } else {
      return <NoData altCaption={'Voting'} />
    }
  }

  const voteItemsContent = () => {
    const list = []
    if (voteItems) {
      Object.keys(voteItems).forEach(key => {
        const voteItem = voteItems[key]
        const { name, description } = voteItem
        list.push(<Segment.Group>
          <Segment className={'seg-header-label'}><div>{name}</div><Icon name='edit' onClick={(e) => handleShowAddEditVoteItem(key)} /></Segment>
          <Segment>{description ? renderHtml(description) : '???'}</Segment>
          <Segment>{resultCount(key)}</Segment>
        </Segment.Group>)
      })
    }
    return list
  }

  const fpw_addEditVoteItem = () => <FullPageWrapper
    content={form_voteItem()}
    topperCaption={'Add Vote Item'}
    handleCancel={handleShowAddEditVoteItem}
    isBack={true}
  ></FullPageWrapper >

  const fpw_addEditVoting = () => <FullPageWrapper
    content={form_voting()}
    topperCaption={'Add Vote'}
    handleCancel={handleShowAddEditVoting}
    isBack={true}
  ></FullPageWrapper >

  const buttons_voteItems = () => {
    const btns = [
      { caption: 'Activate', oc: handleActivateVoting, color: voteActive ? 'green' : 'grey', icon: appIconTypes.check, fl: true },
      { caption: 'Add Vote Item', oc: handleShowAddEditVoteItem, color: 'blue', icon: appIconTypes.add },
    ]
    return <UiSaveButtons others={btns} />
  }

  const buttons_voting = () => {
    const btns = [
      { caption: 'Add Vote', oc: handleShowAddEditVoting, color: 'blue', icon: appIconTypes.add },
    ]
    return <UiSaveButtons others={btns} />
  }

  const fpw_voteItems = () => <FullPageWrapper
    content={<Wrapper
      content={voteItemsContent()}
      footer={buttons_voteItems()}
      wrapperType={wrapperTypes.padded}
    ></Wrapper>}
    topperCaption={'Vote Items'}
    handleCancel={handleShowVoting}
    isBack={true}
  >
  </FullPageWrapper>

  const fpw_voting = () => <FullPageWrapper
    content={<Wrapper
      content={votingContent()}
      footer={buttons_voting()}
      wrapperType={wrapperTypes.padded}
    ></Wrapper>}
    topperCaption={'Voting Management'}
    handleCancel={handleCancel}
  >
  </FullPageWrapper>

  const fpw_noData = () => <FullPageWrapper
    content={<NoData fullCaption={'You are not authorized to manage voting'} />}
    topperCaption={'Voting Management'}
    handleCancel={handleCancel}
  >
  </FullPageWrapper>

  if (!_allowVotingManagment) {
    return fpw_noData()
  } else {
    if (showAddEditVoting) {
      return fpw_addEditVoting()
    } else if (showAddEditVoteItem) {
      return fpw_addEditVoteItem()
    } else {
      if (selectedVotingKey) {
        return fpw_voteItems()
      } else {
        return fpw_voting()
      }
    }
  }
}

export default VotingManagement