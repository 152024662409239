import _ from 'lodash';
import { createStaticViewFromDataCollections } from "../../common/convert";
import { removeAllEmpties } from "../../common/dataAdjust";
import { copyObj } from "../../common_web/copy";
import { recreateAllDataCollections, recreateAllDataCollectionsOneByOne } from "../../firestoreData/appData/fsDeleteData";
import { fsfn_createEventInDatabase, fsfn_recreateEventInDatabase } from "../../functions/fbCreate";
import { fsfn_replaceEventCollections } from "../../functions/fbDataFunctions";
import { fsfn_updateAppSettings } from "../../functions/fbSettingsFunctions";
import { fsfn_createGoogleSpreadsheet, fsfn_createProjectFromSheets, fsfn_createGoogleSpreadsheetTab, fsfn_updateGooglsSheetValues } from "../../functions/fbSheetsFuntions";
import { updateAllDates } from "../contexts/contextHelpers";
import { createResponseTypes } from "./CreateReducer";
import { dispatchConfirmationTypes, dispatchProps, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";
import { responsePromiseHandler, resultPromiseHandler } from "./reducerHelpers/reponsePromiseHandler";

const rts = {
  handleCallback_updateOneByOne: 'handleCallback_updateOneByOne',
  handleCancel: 'handleCancel',
  handleCancelConfirmation: 'handleCancelConfirmation',
  handleCommitPreview: 'handleCommitPreview',
  handleCreateEventDataFromGs: 'handleCreateEventDataFromGs',
  handleCreateEventFromGs: 'handleCreateEventFromGs',
  handleUpdate_googleSpreadsheetTab: 'handleUpdate_googleSpreadsheetTab',
  handleCreateGoogleSpreadsheetTab: 'handleCreateGoogleSpreadsheetTab',
  handleGetGoogleSheet: 'handleGetGoogleSheet',
  handleGoogleMode: 'handleGoogleMode',
  handleGoogleSheetsResponse_app: 'handleGoogleSheetsResponse_app',
  handleMergeSettings: 'handleMergeSettings',
  handlePreview: 'handlePreview',
  handlePreviewInfo: 'handlePreviewInfo',
  handlePreviousPreviewInfo: 'handlePreviousPreviewInfo',
  handleProjectOptions: 'handleProjectOptions',
  handleRecreateDataCollections: 'handleRecreateDataCollections',
  handleRecreate_dataCollectionsOneByOne: 'handleRecreate_dataCollectionsOneByOne',
  handleUpdate_dataCollectionsOneByOne: 'handleUpdate_dataCollectionsOneByOne',
  handleRecreateEvent: 'handleRecreateEvent',
  handleRefresh: 'handleRefresh',
  handleThemeColors: 'handleThemeColors',
  handleUpdated: 'handleUpdated',
  handleUpdateData: 'handleUpdateData',
  handleUpdateOptions: 'handleUpdateOptions',
  handleUpdatePreviewInfo: 'handleUpdatePreviewInfo',
  handleUpdates: 'handleUpdates',
  handleUpdateSettings: 'handleUpdateSettings',
  handleShowNewOnly: 'handleShowNewOnly',
  ...grts,
  ...createResponseTypes
}

export const googleSheetsTypes = {
  create: 'create',
  normal: 'normal',
  registration: 'registration',
  app: 'app',
}

export const googleModeTypes = {
  none: 'none',
  app: 'app',
  commit: 'commit',
  data: 'data',
  preview: 'preview',
  previewDirect: 'previewDirect',
  previewing: 'previewing',
  sheets: 'sheets',
}

export const googlePreviewTypes = {
  none: 'none',
  newFull: 'newFull',
  newDataOnly: 'newDataOnly',
  dataOnly: 'dataOnly'
}

export const googleConfirmationTypes = {
  create: 'create',
  createWithDataSheet: 'createWithDataSheet',
  data: 'data',
  sheetsData: 'sheetsData',
  settings: 'settings',
  recreate: 'recreate',
}

export const googleSheetsInitialState = (initState) => {
  return { ...initState, googleUpdateStatus: {} }
};

export const googleSheetsReducer = (state, action) => {

  const { type, dispatch } = action

  const { appInfo, googleSheetsId, previewInfo, pathViews, logging, googleSheetsImportOptions, forDataManagement, databaseProjectId } = state ? state : {}
  const { pageSettings: pageSettings_app, staticViews: staticViews_app, aps_viewItems } = appInfo ? appInfo : {}

  const { projectData } = previewInfo ? previewInfo : {}
  const { dataCollections } = projectData ? projectData : {}

  let _googleSheetsImportOptions = action.googleSheetsImportOptions ? action.googleSheetsImportOptions : googleSheetsImportOptions
  if (!_googleSheetsImportOptions) { _googleSheetsImportOptions = {} }

  const _gsh = googleSheetsHandlers(action.dispatch)
  const { handleGoogleSheetsResponse_app, handlePreviewInfo, handleFunctionResponse, handleCallback_updateOneByOne } = _gsh

  switch (type) {

    // get the google sheets data
    case rts.handleGetGoogleSheet:
      const formData = { eventName: 'Settings', googleSheetsId: action.gsid }
      const sheetProps = { formData, pathViews }
      const _rdp = fsfn_createProjectFromSheets(sheetProps, 'event', _googleSheetsImportOptions, null, staticViews_app, true, aps_viewItems)
      if (action.forApp) {
        resultPromiseHandler(_rdp, handleGoogleSheetsResponse_app)
      } else {
        resultPromiseHandler(_rdp, handlePreviewInfo)
      }
      // handlePreviewInfo is the callback
      return { ...state, updating: true }

    case rts.handleGoogleSheetsResponse_app:
      const { projectData, projectSettings } = action.results ? action.results : {}
      const { dataCollections: dcs } = projectData ? projectData : {}
      const svs = createStaticViewFromDataCollections(dcs)

      if (dcs) {
        updateAllDates(dcs)
      }

      const { global: global_g, viewItems: viewItems_g, views: view_g } = projectSettings ? projectSettings : {}
      const appSettings_google = {
        aps_global: global_g,
        aps_viewItems: viewItems_g,
        aps_views: view_g,
      }

      return { ...state, appSettings: appSettings_google, staticViews: svs, googleSheetsData: dcs, googleAppSettings: action.results, googleModeType: googleModeTypes.app, updating: false }

    // create the event in  the database
    case rts.handleCreateEventFromGs:
    case rts.handleCreateEventDataFromGs:
      const createInfo = action.createInfo ? action.createInfo : { ...previewInfo }
      const response_promise = fsfn_createEventInDatabase(createInfo, action.recreate, logging, pathViews, databaseProjectId, action.addModeType)
      responsePromiseHandler(response_promise, handleFunctionResponse, dispatch)
      return { ...state }

    // re-create the event in  the database
    case rts.handleRecreateEvent:
      fsfn_recreateEventInDatabase(state).then(response_data => {
        handleFunctionResponse(response_data, dispatch)
      })
      return { ...state }

    case rts.handleRecreateDataCollections:
      recreateAllDataCollections(state, action.collectionName, action.updateOnly).then(res => {
        dispatch({ type: grts.updateSuccess, dispatch, opts: { contentItems: res } })
      }).catch(error => {
        console.log('error', error)
      })
      return { ...state }

    case rts.handleRecreate_dataCollectionsOneByOne:
      recreateAllDataCollectionsOneByOne(state, action.collectionName, action.updateSettings, action.updateStaticViews, action.updateOnly, action.allowMerge, action.newOnly, handleCallback_updateOneByOne)
      return { ...state }

    case rts.handleUpdate_dataCollectionsOneByOne:
      recreateAllDataCollectionsOneByOne(state, action.collectionName, action.updateSettings, action.updateStaticViews, true, true, state.showNewOnly, handleCallback_updateOneByOne)
      return { ...state }

    case rts.handleCallback_updateOneByOne:
      return { ...state, dcUpdates: action.done ? null : action.dcUpdates, updating: action.done ? null : state.updating }

    // replace and update thte collections
    case rts.handleUpdateData:
      if (dataCollections) {
        fsfn_replaceEventCollections(pathViews, dataCollections, dispatch)
      }
      return { ...state, updating: true, showConfirmationQuestion: false }

    // update the settings only
    case rts.handleUpdateSettings:
      const _appSettings = getAppSettings(previewInfo)
      if (_appSettings) {
        dispatch && dispatch({ type: dispatchProps.success })
        fsfn_updateAppSettings(pathViews.events, _appSettings, logging).then(response_data => {
          const { success, error } = response_data ? response_data : {}
          dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
        }).catch(error => {
          dispatch && dispatch({ type: dispatchProps.error, error })
        })
      }
      return { ...state, updating: true, updatingCaption: 'Updating', showConfirmationQuestion: false }

    case rts.handleCreateSpreadsheet:
      fsfn_createGoogleSpreadsheet(action.title).then(response_data => {
        const { success } = response_data ? response_data : {}
        console.log('SUCCESS', success)
      })
      return { ...state }

    case rts.handleUpdate_googleSpreadsheetTab:
      fsfn_updateGooglsSheetValues(googleSheetsId, { key: action.tabName }, action.values)
      return { ...state }

    case rts.handleCreateGoogleSpreadsheetTab:
      fsfn_createGoogleSpreadsheetTab(googleSheetsId, action.tabName, action.fetchFirestoreData, action.values).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: rts.updateSuccess, dispatch })
        // dispatch({ type: success ? rts.updateSuccess : rts.updateError, error, dispatch })
      })
      return { ...state }

    case rts.handleCommitPreview:
      switch (action.googleModeType) {
        case googleModeTypes.none:
          return {
            ...state,
            googleModeType: googleModeTypes.none,
            googleAppSettings: null,
            googlePreviewType: googlePreviewTypes.none,
            googleSheetsData: null,
            previewInfo: null,
            googleUpdateStatus: null,
          }
        default:
          return {
            ...state,
            googleModeType: state.googleModeType === action.googleModeType ? googleModeTypes.none : action.googleModeType
          }
      }

    case rts.handleRefresh:
      return {
        ...state,
        refreshThis: !state.refreshThis,
      }

    case rts.handleGoogleMode:
      switch (action.googleModeType) {
        case googleModeTypes.none:
          return {
            ...state,
            googleModeType: googleModeTypes.none,
            googleAppSettings: null,
            googlePreviewType: googlePreviewTypes.none,
            googleSheetsData: null,
            previewInfo: null,
            googleUpdateStatus: null,
            showConfirmationQuestion: null
          }
        default:
          return {
            ...state,
            googleModeType: state.googleModeType === action.googleModeType ? googleModeTypes.none : action.googleModeType
          }
      }

    case rts.handleCancel:
      return {
        ...state,
        googleModeType: googleModeTypes.none,
        googleAppSettings: null,
        googlePreviewType: googlePreviewTypes.none,
        googleSheetsData: null,
        previewInfo: null,
        googleUpdateStatus: null,
      }

    case rts.handleShowNewOnly:
      return { ...state, showNewOnly: !state.showNewOnly }
    // handles the return of the googleSheet from fsfn_getGoogleSheet


    case rts.handlePreviewInfo:
      let svss = {};
      try {
        svss = createStaticViewFromDataCollections(action.results.projectData.dataCollections)
      } catch (error) {

      }
      return { ...state, previewInfo: action.results, previewStatic: svss, updating: false }

    case rts.handleUpdatePreviewInfo:
      return { ...state, previewInfo: action.pi }

    case rts.handlePreview:
      const { dataOnly } = action.opts
      return {
        ...state,
        googleAppSettings: previewInfo,
        googlePreviewType: dataOnly ? googlePreviewTypes.newDataOnly : googlePreviewTypes.newFull,
        googleModeType: googleModeTypes.previewing
      }

    case rts.handleCancelConfirmation:
      return { ...state, showConfirmationQuestion: false }

    case rts.handleUpdates:
      return { ...state, showConfirmationQuestion: false, googleUpdateStatus: getGoogleUpdateStatus(state, action) }

    case rts.handleUpdated:
      return { ...state, showConfirmationQuestion: false, googleUpdateStatus: getGoogleUpdatedStatus(state, action) }

    case rts.handleUpdateOptions:
      return { ...state, googleSheetsImportOptions: action.googleSheetsImportOptions, storageRootPath: action.storageRootPath, logging: action.logging }

    case rts.handleProjectOptions:
      return { ...state, projectOptions: action.projectOptions }

    case rts.handleThemeColors:
      return { ...state, themeColors: action.themeColors }

    case rts.handlePreviousPreviewInfo:
      const xxxx = getPreviousPreviewInfo(previewInfo, action.previousEventSettings)
      return { ...state, ...xxxx }

    case rts.handleMergeSettings:
      const _mergedUpdateInfo = getMergedSettings(previewInfo, pageSettings_app, staticViews_app, action.globalOnly, true, true)
      const _mergedRecreateInfo = getMergedSettings(previewInfo, pageSettings_app, staticViews_app, action.globalOnly, false, true)
      return { ...state, mergedUpdateInfo: _mergedUpdateInfo, mergedRecreateInfo: _mergedRecreateInfo }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action,
        {
          dispatch,
          dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation,
          questionProps: null,
          updatingCaption: 'Creating',
        })

    default:
      return state;
  }
}

export const googleSheetsHandlers = (dispatch) => {
  return {
    handleCallback_updateOneByOne: (dcUpdates, done) => { dispatch({ type: rts.handleCallback_updateOneByOne, dispatch, dcUpdates, done }) },
    handleCancel: (googleAppSettings, dataOnly) => { dispatch({ type: rts.handleCancel, dispatch, googleAppSettings, dataOnly }) },
    handleCancelConfirmation: (results) => { dispatch({ type: rts.handleCancelConfirmation, dispatch, results }) },
    handleCommitPreview: (googleModeType) => { dispatch({ type: rts.handleCommitPreview, dispatch, googleModeType }) },
    handleCreateEventDataFromGs: () => { dispatch({ type: rts.handleCreateEventDataFromGs, dispatch }) },
    handleCreateEventFromGs: (createInfo, recreate, addModeType) => { dispatch({ type: rts.handleCreateEventFromGs, dispatch, createInfo, recreate, addModeType }) },
    handleCreateGoogleSpreadsheetTab: (tabName, fetchFirestoreData, values) => { dispatch({ type: rts.handleCreateGoogleSpreadsheetTab, dispatch, tabName, fetchFirestoreData, values }) },
    handleCreateSpreadsheet: (title) => { dispatch({ type: rts.handleCreateSpreadsheet, dispatch, title }) },
    handleGetGoogleSheet: (gsid) => { dispatch({ type: rts.handleGetGoogleSheet, dispatch, gsid }) },
    handleGoogleMode: (googleModeType) => { dispatch({ type: rts.handleGoogleMode, dispatch, googleModeType }) },
    handleGoogleSheetsResponse_app: (results) => { dispatch({ type: rts.handleGoogleSheetsResponse_app, dispatch, results }) },
    handleMergeSettings: (globalOnly) => { dispatch({ type: rts.handleMergeSettings, dispatch, globalOnly }) },
    handlePreview: (opts) => { dispatch({ type: rts.handlePreview, dispatch, opts }) },
    handlePreviewInfo: (results) => { dispatch({ type: rts.handlePreviewInfo, dispatch, results }) },
    handlePreviousPreviewInfo: (previousEventSettings) => { dispatch({ type: rts.handlePreviousPreviewInfo, dispatch, previousEventSettings }) },
    handleProjectOptions: (projectOptions) => { dispatch({ type: rts.handleProjectOptions, dispatch, projectOptions }) },
    handleRecreate_dataCollectionsOneByOne: (collectionName, updateSettings, updateStaticViews, updateOnly, allowMerge, newOnly) => { dispatch({ type: rts.handleRecreate_dataCollectionsOneByOne, dispatch, collectionName, updateSettings, updateStaticViews, updateOnly, allowMerge, newOnly }) },
    handleRecreateDataCollections: (collectionName, updateOnly) => { dispatch({ type: rts.handleRecreateDataCollections, dispatch, collectionName, updateOnly }) },
    handleRecreateEvent: () => { dispatch({ type: rts.handleRecreateEvent, dispatch }) },
    handleRefresh: () => { dispatch({ type: rts.handleRefresh, dispatch }) },
    handleThemeColors: (themeColors) => { dispatch({ type: rts.handleThemeColors, dispatch, themeColors }) },
    handleUpdate_dataCollectionsOneByOne: (collectionName, updateSettings, updateStaticViews) => { dispatch({ type: rts.handleUpdate_dataCollectionsOneByOne, dispatch, collectionName, updateSettings, updateStaticViews }) },
    handleUpdate_googleSpreadsheetTab: (tabName, values) => { dispatch({ type: rts.handleUpdate_googleSpreadsheetTab, dispatch, tabName, values }) },
    handleUpdated: (response) => { dispatch({ type: rts.handleUpdated, dispatch, response }) },
    handleUpdateData: () => { dispatch({ type: rts.handleUpdateData, dispatch }) },
    handleUpdateOptions: (googleSheetsImportOptions, storageRootPath, logging) => { dispatch({ type: rts.handleUpdateOptions, dispatch, googleSheetsImportOptions, storageRootPath, logging }) },
    handleUpdatePreviewInfo: (pi) => { dispatch({ type: rts.handleUpdatePreviewInfo, dispatch, pi }) },
    handleUpdates: (statusKey, status) => { dispatch({ type: rts.handleUpdates, dispatch, statusKey, status }) },
    handleUpdateSettings: (results) => { dispatch({ type: rts.handleUpdateSettings, dispatch, results }) },
    handleShowNewOnly: () => { dispatch({ type: rts.handleShowNewOnly, dispatch }) },
    ...responseHandlers(dispatch)
  }
}

const getAppSettings = (previewInfo) => {
  const { projectSettings } = previewInfo
  const { global, viewItems, views } = projectSettings ? projectSettings : {}
  if (global && viewItems && views) {
    const appSettings = {
      global,
      viewItems,
      views,
    }
    return appSettings
  }
}

const getGoogleUpdateStatus = (state, action) => {
  const { googleUpdateStatus } = state
  const { statusKey, status } = action
  const _updateStatus = { ...googleUpdateStatus }
  if (!_updateStatus[statusKey]) { _updateStatus[statusKey] = {} }
  _updateStatus[statusKey].status = status
  return _updateStatus
}

const getGoogleUpdatedStatus = (state, action) => {
  const { googleUpdateStatus } = state
  const { response } = action
  const { success, collectionName } = response ? response : {}
  const _updateStatus = { ...googleUpdateStatus }
  if (!_updateStatus[collectionName]) { _updateStatus[collectionName] = {} }
  _updateStatus[collectionName].status = success ? 'completed' : 'failed'
  return _updateStatus
}

const getMergedSettings = (previewInfo, pageSettings_app, staticViews_app, globalOnly, forUpdate, newIsPriority) => {
  const { aps_viewItems, aps_views } = pageSettings_app
  const { projectSettings: projectSettings_preview, projectData } = previewInfo ? previewInfo : {}
  const { dataCollections } = projectData ? projectData : {}
  const _dataCollections = dataCollections ? copyObj(dataCollections) : {}
  const { viewItems: viewItems_preview, views: views_preview } = projectSettings_preview ? projectSettings_preview : {}
  let _projectSettings = {}
  if (globalOnly) {
    _projectSettings = {
      viewItems: viewItems_preview,
      views: views_preview,
    }
  } else {
    const vis = _.merge(viewItems_preview, aps_viewItems)
    const vws = _.merge(views_preview, aps_views)
    _projectSettings = {
      viewItems: vis, //newIsPriority ? Object.assign({}, aps_viewItems, viewItems_preview) : Object.assign({}, viewItems_preview, aps_viewItems),
      views: vws, //newIsPriority ? Object.assign({}, aps_views, views_preview) : Object.assign({}, views_preview, aps_views),
    }
  }
  if (forUpdate && _dataCollections && viewItems_preview && staticViews_app) {
    Object.keys(viewItems_preview).forEach(key => {
      if (staticViews_app[key]) {
        delete _dataCollections[key]
      }
    })
  }
  const _projectData = {
    dataCollections: _dataCollections
  }

  return { projectSettings: _projectSettings, projectData: _projectData }
}

const ammendVis = (newItems, existingItems) => {
  Object.keys(newItems).forEach(key => {
    const newItem = newItems[key]
    const existingItem = existingItems[key]
    if (newItem && existingItem) {
      const { viewItems: viewItems_new, props: props_new, propSections: propSections_new } = newItem
      const { viewItems: viewItems_existing, props: props_exiting, propSections: propSections_exiting } = existingItem
      if (viewItems_new && viewItems_existing) {
        Object.keys(viewItems_new).forEach(vKey => {
          const viewItem_new = viewItems_new[vKey]
          const viewItem_existing = viewItems_existing[vKey]
          if (viewItem_new && viewItem_existing) {
            const { props: props_new, propSections: propSections_new } = viewItem_new
            const { props: props_existing, propSections: propSections_existing } = viewItem_existing
            ammendVIProps(props_new, props_existing)
            ammendVIPropSections(propSections_new, propSections_existing)
          }
        })
      } else if (props_new && props_exiting) {
        ammendVIProps(props_new, props_exiting)
        ammendVIPropSections(propSections_new, propSections_exiting)
      }
    }
  })

}

const ammendVIProps = (props_new, props_exiting) => {
  if (props_new && props_exiting) {
    Object.keys(props_new).forEach(propKey => {
      if (!props_exiting[propKey]) {
        props_new[propKey] = props_exiting[propKey]
      }
    })
  }
}

const ammendVIPropSections = (propSections_new, propSections_exiting) => {
  if (propSections_new && propSections_exiting) {
    Object.keys(propSections_new).forEach(propKey => {
      if (!propSections_exiting[propKey]) {
        propSections_new[propKey] = propSections_exiting[propKey]
      }
    })
  }
}

const getPreviousPreviewInfo = (previewInfo, previousEventSettings) => {

  const { global: global_previous, viewItems: viewItems_previous, views: views_previous } = previousEventSettings ? previousEventSettings : {}
  const { projectOptions: projectOptions_previous, themeColors: themeColors_previous } = global_previous ? global_previous : {}

  const _previewInfo = { ...previewInfo }
  const { projectSettings } = _previewInfo ? _previewInfo : {}

  const { global: global_current, viewItems: viewItems_current, views: views_current } = projectSettings ? projectSettings : {}

  _previewInfo.projectSettings.global = global_previous
  _previewInfo.projectSettings.viewItems = { ...viewItems_previous, ...viewItems_current }
  _previewInfo.projectSettings.views = { ...views_previous, ...views_current }

  _previewInfo.projectSettings.global.googleSheets = global_current.googleSheets
  _previewInfo.projectSettings.global.topMenu = { ...global_previous.topMenu, ...global_current.topMenu }

  if (global_current.banner) { _previewInfo.projectSettings.global.banner = global_current.banner }
  if (global_current.homePage) { _previewInfo.projectSettings.global.homePage = global_current.homePage }

  delete _previewInfo.projectSettings.global._itemKey
  delete _previewInfo.projectSettings.global.id
  delete _previewInfo.projectSettings.views._itemKey
  delete _previewInfo.projectSettings.views.id
  delete _previewInfo.projectSettings.viewItems._itemKey
  delete _previewInfo.projectSettings.viewItems.id

  _previewInfo.projectSettings.global = removeAllEmpties(_previewInfo.projectSettings.global)

  return {
    previewInfo: _previewInfo,
    themeColors: themeColors_previous,
    projectOptions: projectOptions_previous
  }
}

const getConfirmationQuestion = (googleConfirmationType, _gsh) => {

  switch (googleConfirmationType) {

    case googleConfirmationTypes.create:
      return {
        header: 'Please Confirm',
        question: 'Are you sure you want to create the event?',
        handleNo: _gsh.handleCancelConfirmation,
        handleYes: _gsh.handleCreateEventFromGs,
        actionFunction: 'handleCreateEventFromGs'
      }

    case googleConfirmationTypes.createWithDataSheet:
      return {
        header: 'Please Confirm',
        question: 'Are you sure you want to create the event with data sheet data?',
        handleNo: _gsh.handleCancelConfirmation,
        handleYes: _gsh.handleCreateEventDataFromGs,
        actionFunction: 'handleCreateEventDataFromGs'
      }

    case googleConfirmationTypes.data:
      return {
        header: 'Data - Please Confirm',
        question: 'Are you sure you want to replace and update the data?',
        handleNo: _gsh.handleCancelConfirmation,
        handleYes: _gsh.handleUpdateData,
        actionFunction: 'handleUpdateData'
      }

    case googleConfirmationTypes.recreate:
      return {
        header: 'Please Confirm',
        question: 'Are you sure you want to recreate the event?',
        handleNo: _gsh.handleCancelConfirmation,
        handleYes: _gsh.handleRecreateEvent,
        actionFunction: 'handleRecreateEvent'
      }

    case googleConfirmationTypes.settings:
      return {
        header: 'Settings - Please Confirm',
        question: 'Are you sure you want to replace the settings?',
        handleNo: _gsh.handleCancelConfirmation,
        handleYes: _gsh.handleUpdateSettings,
        actionFunction: 'handleUpdateSettings'
      }

    default:
      break;
  }
}

