import React, { useContext } from 'react'
import { Card, Label } from 'semantic-ui-react'
import { PageAreaParentContext } from '../../../cnr/contexts/PageAreaParentContext'
import { uniqueKey } from '../../../common/keys'
import { gEnums } from '../../../enums/globalEnums'
import PdfGallery from '../../imaging/PdfGallery'
import { addCardImage } from '../../imaging/UiImage'
import { parentBreadcrumbs } from '../breadcrumbs'
import { cardSections } from '../cardSections'
import CardIndicator from './CardIndicator'

const CardNormal = (props) => {

  const { cnProps, isSubCard } = props

  const {
    _display,
    _itemKey,
    _sections,
    aps_global,
    appUserAccess,
    cardImage,
    cardPrps,
    cardQr,
    divRef,
    getParents,
    handleClick,
    imageInContent,
    image_viewItem,
    index,
    itemData,
    noClick,
    page_fns,
    semSecProps,
    showItemIds,
    showItemImage,
    showPdfIcon,
    showQrCode,
    style,
    viewItem,
  } = cnProps

  // parentContext
  const parentContext = useContext(PageAreaParentContext)
  const { states } = parentContext ? parentContext : {}
  const { eventInfo_state } = states
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  const { indicator } = viewItem ? viewItem : {}
  const { showIndicator, indicatorProp, indicatorLocation } = indicator ? indicator : {}
  const _indicatorLocation = indicatorLocation ? indicatorLocation : gEnums.bannerLocationTypes.bottom

  const { itemPdf } = itemData ? itemData : {}

  const _cardKeyy = uniqueKey('cpr', _itemKey)

  const cardContent = () => {

    const cc = []

    showItemIds && appUserAccess && appUserAccess.isSuperAdmin && cc.push(
      <div key={uniqueKey('sccid', index)} className='card-id'>
        <Label key={uniqueKey('cs', 'l', index)} attached='top right' size={'mini'} color={'grey'}>{_itemKey}</Label>
      </div>)

    if (showPdfIcon && itemPdf) {
      cc.push(<PdfGallery itemPdf={itemPdf} />)
    }

    // add breadcrumbs
    cc.push(parentBreadcrumbs(viewItem, getParents, page_fns))

    // add the sections 
    cc.push(cardSections(_sections, semSecProps))

    return cc
  }

  const cardIndicator = () => <CardIndicator indicator={indicator} cardKey={_cardKeyy} itemData={itemData} aps_global={aps_global} staticViews={staticViews} />

  return <Card
    ref={divRef}
    key={uniqueKey('sccrd', _itemKey)}
    as='div'
    color={'red'}
    style={style}
    {...cardPrps}
    fluid
    // onMouseMove={e => e.stopPropagation()}
    // onMouseOver={(e) => { handleHover() }}
    onClick={() => { handleClick(viewItem, _itemKey, null, { itemData: itemData, noClick }) }}
  >
    {showIndicator && indicatorProp && _indicatorLocation === gEnums.bannerLocationTypes.top && cardIndicator()}
    {showItemImage && !imageInContent && !isSubCard && <Card.Content className={'card-image'}>{addCardImage(cardImage, image_viewItem, _display)}</Card.Content>}
    <Card.Content key={uniqueKey('sc', 'cn', _itemKey, 1)} className='card-content'>{cardContent()}</Card.Content>
    {showItemImage && imageInContent && !isSubCard && <Card.Content className={'card-image'}>{addCardImage(cardImage, image_viewItem, _display)}</Card.Content>}
    {showQrCode && <Card.Content className={'card-qr'}>{cardQr}</Card.Content>}
    {showIndicator && indicatorProp && _indicatorLocation === gEnums.bannerLocationTypes.bottom && cardIndicator()}
  </Card>

}

export default CardNormal