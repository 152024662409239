import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { createRefPath } from '../../appData/appRefPaths';
import { fs_set_doc } from '../../appData/fsData';

export const ammendExisting = (props) => {
  const { baseRef, dispatch, ufProps } = props
  const { refPath } = baseRef
  const { vit, appUser, itemData } = ufProps ? ufProps : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { uid, appUserSessionKey } = appUserAccess ? appUserAccess : {}
  const _uid = uid ? uid : appUserSessionKey
  const _setRef = createRefPath([vit, _uid], refPath)
  fs_set_doc(_setRef, itemData, true, dispatch)
}