import { getDatabase, onValue, ref, remove, set } from "firebase/database";
import { getSportsYear } from '../../../global/firestoreData/helpers/years';

const rtPath = (pathViews, sportsYear, realtimeMatchKey, uid) => {
  const path = 'clients/' + pathViews.clients + '/events/' + pathViews.events + '/realtimeResults/' + sportsYear
  return realtimeMatchKey ? path + '/' + realtimeMatchKey + '/' + uid : path
}

/**
 * Updates the scores into the realtime database
 * @param {object} pathViews 
 * @param {object} realtimeMatch 
 * @param {object} results 
 * @param {object} appUserAccess 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
export const updateRealtimeScoreToDatabase = async (pathViews, match_key, results, appUserAccess, latestSeason, callback) => {

  const { uid, displayName } = appUserAccess ? appUserAccess : {}
  const sportsYear = getSportsYear(latestSeason)
  const db = getDatabase();
  const refString = rtPath(pathViews, sportsYear, match_key, uid)

  const updateData = {
    displayName,
    results
  }

  set(ref(db, refString), updateData).then(() => {
    callback && callback({ success: true })
  }).catch((error) => {
    callback && callback({ success: false, error })
  });
}

/**
 * Gets the scores from the realtime database
 * @param {object} pathViews 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
export const getRealtimeScores = async (pathViews, latestSeason, callback) => {

  const sportsYear = getSportsYear(latestSeason)
  const refString = rtPath(pathViews, sportsYear)

  const db = getDatabase();
  const _ref = ref(db, refString);

  onValue(_ref, (snapshot) => {
    callback(snapshot.val())
  });
}

/**
 * Updates the scores into the realtime database
 * @param {object} pathViews 
 * @param {object} realtimeMatch 
 * @param {object} results 
 * @param {object} appUserAccess 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
export const deleteRealtimeDatabaseScores = async (pathViews, match_key, appUserAccess, latestSeason, callback) => {

  const { uid } = appUserAccess ? appUserAccess : {}
  const sportsYear = getSportsYear(latestSeason)
  const db = getDatabase();
  const refString = rtPath(pathViews, sportsYear, match_key, uid)

  set(ref(db, refString), null).then(() => {
    callback && callback({ success: true })
  }).catch((error) => {
    callback && callback({ success: false, error })
  });

}