
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Label, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { copyObj } from '../../common_web/copy';
import { gEnums } from "../../enums/globalEnums";
import { _storageSettings } from '../../storage/storageHelpers';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import PendingWait from '../alerts/pendings/PendingWait';
import Uploader from '../uploading/Uploader';
import ImagesGrid from './ImagesGrid';

/**
 * Displays the files within storage. 
 * @param {object} props (allowMultiItemSelect, fileViewer_handlers, storageLocationType, storageType, viewerProps)
 *  @description Clicking on the image will get and display the full image
 * @description Allows upload using Uploader
 * @note If the list is for manifest icons, displays ManifestViewer
 *  */

const PdfGallery = (props) => {

  const {
    itemPdf,
    showFile,
    handleCancel,
  } = props ? props : {}

  const storageLocationType = gEnums.storageLocationTypes.pagePdf
  const storageType = gEnums.storageTypes.icon

  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state, storage_state } = states ? states : {}
  const { storage_handlers } = handlers ? handlers : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // papsContext 
  const { view, viewKey, pathViews } = paps_state
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const { appGallery } = aps_global ? aps_global : {}
  const { allowAppUserUpload, allowMultiUpload, uploadAccessLevel } = appGallery ? appGallery : {}

  // storageContext 
  const { storagePermissions,
    confirmation,
    currentGalleryItems,
    globalFiles,
    galleries,
    selectedItems,
    showApprovalWizard,
  } = storage_state ? storage_state : {}

  const { pdf: global_pdf } = globalFiles ? globalFiles : {}
  const { pdf: gallery_pdf } = galleries ? galleries : {}

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}

  const styleAndClass = aps_styles && aps_styles[gEnums.projectStyles.actionButton] ? copyObj(aps_styles[gEnums.projectStyles.actionButton]) : {}
  styleAndClass.className += ' solo'

  // local state  
  const [galleryInfo, setGalleryInfo] = useState({ has: false, count: 0, gallery: null })
  const [showUpload, setShowUpload] = useState()
  const [showPdfs, setShowPdfs] = useState()
  const [pdfsFetched, setPdfsFetched] = useState()
  const [galleryExists, setGalleryExists] = useState()
  const [itemPdfUrl, setItemPdfUrl] = useState()

  useEffect(
    () => {
      setPdfsFetched(false)
      let galleryType = _storageSettings.galleryTypes.pdf
      const _galleryExists = galleries && galleries[galleryType] && galleries[galleryType][view] && galleries[galleryType][view][viewKey]
      if (!_galleryExists) {
        const _storageProps = {
          allowAppUserUpload,
          appUserAccess,
          galleryType,
          storageLocationType: gEnums.storageLocationTypes.pagePdf,
          storageType,
          uploadAccessLevel,
          view,
          viewKey,
        }
        // get s the gallery for the page if does not aleady exist 
        setGalleryExists(false)
        storage_handlers.handleGet_galleryAny(_storageProps)
      } else {
        setGalleryExists(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gallery_pdf, view, viewKey, confirmation]
  )

  useEffect(
    () => {
      let galleryType = _storageSettings.galleryTypes.pdf
      if (galleries && galleries[galleryType] && galleries[galleryType][view] && galleries[galleryType][view][viewKey]) {
        const _gallery = galleries[galleryType][view][viewKey]
        setGalleryInfo({
          gallery: _gallery,
          has: _gallery && Object.keys(_gallery).length > 0,
          count: _gallery ? Object.keys(_gallery).length : 0
        })
        setPdfsFetched(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [galleryExists, pdfsFetched, currentGalleryItems]
  )

  useEffect(
    () => {
      if (global_pdf && global_pdf[itemPdf] && global_pdf[itemPdf].urls && global_pdf[itemPdf].urls.full) {
        setItemPdfUrl(global_pdf[itemPdf].urls.full)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [global_pdf, itemPdf]
  )

  useEffect(
    () => {
      if (confirmation && viewSettings_handlers) {
        viewSettings_handlers.handleStopUpdate()
        handleRefresh()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmation]
  )

  const handlePdfClick = (e, src) => window.open(src, '_blank')
  const handleItemPdfClick = (e, src) => window.open(src, '_blank')
  const handleRefresh = () => storage_handlers.handleGet_globalStorageFiles(storageLocationType, storageType)
  const handleShowUpload = () => setShowUpload(!showUpload)

  const handlePdfIconClick = (e) => {
    e && e.stopPropagation()
    if (itemPdfUrl) {
      handleItemPdfClick(e, itemPdfUrl)
    } else {
      setShowPdfs(!showPdfs)
    }
  }

  const imageGridProps = {
    handlePdfClick,
    storagePermissions,
    selectedItems,
    showApprovalWizard,
    documentKey: null,
    isFullGalleryItem: false,
    gallery: null,
  }

  /** Returns a Grid with images of a single document */
  const pdfGalleryGrid = (documentKey, isFullGalleryItem) => {
    imageGridProps.documentKey = documentKey
    imageGridProps.isFullGalleryItem = isFullGalleryItem
    imageGridProps.gallery = galleryInfo.gallery
    imageGridProps.isPdf = true
    imageGridProps.origin = 'pdfGallery'
    return <ImagesGrid igProps={imageGridProps} />
  }

  const fullPageUploader = () => <Uploader
    allowMultiUpload={allowMultiUpload}
    handleCancel={handleShowUpload}
    origin={'pdfGallery'}
    storageLocationType={storageLocationType}
    storageType={storageType}
    useFull={true}
  />

  const wrapperContent = () => {
    if (!pdfsFetched) {
      return <PendingWait />
    } else {
      if (showUpload) {
        // IMPORTANT: Uploader 
        return fullPageUploader()
      } else {
        return galleryInfo.has ? pdfGalleryGrid(storageLocationType) : <NoData altCaption={'PDFs'} />
      }
    }
  }

  const wrapper = () => <Wrapper
    content={wrapperContent()}
    wrapperType={wrapperTypes.padded}
    updating={false}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handlePdfIconClick}
    topperCaption={'PDF Gallery'}
  />

  const pdfLabel = (showCount) => {
    const _count = itemPdf ? 1 : galleryInfo.count
    return <Label
      size={'large'}
      color={'blue'}
      className='card-icon'
      onClick={(e) => { handlePdfIconClick(e) }}
    >
      <Icon name='file pdf' />{showCount && _count}
    </Label>
  }

  const pdfContent = () => {
    if (showFile) {
      return <Segment.Group>
        <Segment>{itemPdf}</Segment>
        <Segment>{pdfLabel()}</Segment>
      </Segment.Group>
    } else {
      return pdfLabel(true)
    }
  }

  if (itemPdfUrl) {
    return pdfContent()
  } else {
    if (pdfsFetched && galleryInfo.has) {
      if (showPdfs) {
        return fullPageWrapper()
      } else {
        return pdfContent()
      }
    } else {
      return <div></div>
    }
  }
}

export default PdfGallery