import { gEnums } from '../../enums/globalEnums';

/** Gets the base ref */
export const getBaseRef = (viDeps, pathViews, specificView, swipedItem, logging, clientOnly, ufProps) => {

  const { dependencies } = viDeps ? viDeps : {}

  // const fs = firebase.firestore()

  const refPaths = []
  let found = false
  let refPath = ''
  let directCollection = false
  const items = []

  if (logging && logging.allowLogging && logging.logViewItemDeps) { console.log('logging >', 'viewItemDeps', dependencies) }
  if (logging && logging.allowLogging && logging.logPathViews) { console.log('logging >', 'pathViews', pathViews) }

  if (swipedItem) {
    switch (swipedItem.settingKey) {

      case gEnums.profileSelectTypes.profiles:
        refPath += swipedItem.key
        directCollection = swipedItem.key
        break;

      case 'notifications':
        refPath += swipedItem.key
        directCollection = swipedItem.key
        break;

      default:
        if (swipedItem.dKey) { directCollection = swipedItem.dKey }

        if (dependencies && pathViews && Object.keys(pathViews).length > 0) {
          // loop the depenedents 
          dependencies.forEach(viewItemDep => {
            const docKey = pathViews[viewItemDep]
            if (docKey) {
              if (!found) {
                if (refPath.length === 0) {
                  refPath += viewItemDep + '/' + pathViews[viewItemDep]
                  items.push({ viewItemDep, value: pathViews[viewItemDep] })
                  refPaths.push(viewItemDep)
                  refPaths.push(pathViews[viewItemDep])
                } else {
                  refPath += '/' + viewItemDep + '/' + pathViews[viewItemDep]
                  items.push({ viewItemDep, value: pathViews[viewItemDep] })
                  refPaths.push(viewItemDep)
                  refPaths.push(pathViews[viewItemDep])
                }
                if (specificView && specificView === viewItemDep) { found = true }
              }
            }
          })
        }
    }
  } else {
    if (dependencies && pathViews && Object.keys(pathViews).length > 0) {
      // loop the depenedents 
      dependencies.forEach(viewItemDep => {
        let allow = true
        if (clientOnly) {
          if (viewItemDep !== 'clients') {
            allow = false
          }
        }
        if (allow) {
          const docKey = pathViews[viewItemDep]
          if (docKey) {
            if (!found) {
              if (refPath.length === 0) {
                refPath += viewItemDep + '/' + pathViews[viewItemDep]
                items.push({ viewItemDep, value: pathViews[viewItemDep] })

                refPaths.push(viewItemDep)
                refPaths.push(pathViews[viewItemDep])

              } else {
                refPath += '/' + viewItemDep + '/' + pathViews[viewItemDep]
                items.push({ viewItemDep, value: pathViews[viewItemDep] })

                refPaths.push(viewItemDep)
                refPaths.push(pathViews[viewItemDep])

              }
              if (specificView && specificView === viewItemDep) { found = true }
            }
          }
        }
      })
    }
  }

  return {
    directCollection: directCollection,
    refPath,
    items,
    refPaths,
  }
}