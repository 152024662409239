import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Form } from 'semantic-ui-react';

const PhoneAssignment = (props) => {

  const { contact, phoneNumber, handleContact } = props

  const phoneLabel = contact && contact.name ? 'Phone Number (' + contact.name + ')' : 'Phone Number'

  const handleChange = (d) => handleContact({ phoneNumber: d })

  const phoneInput = () => <div className={'phone-container full'}>
    <PhoneInput
      placeholder={phoneLabel}
      defaultCountry="US"
      value={phoneNumber}
      onChange={handleChange} />
  </div>

  const phoneForm = () => <Form>
    <Form.Field>
      <label>{phoneLabel}</label>
      {phoneInput()}
    </Form.Field>
  </Form>

  return phoneForm()
}

export default PhoneAssignment