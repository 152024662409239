import React from 'react';
import { helpTypes } from '../../../global/cnr/reducers/HelpReducer';
import Help from '../../../global/components/help/Help';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper from '../../../global/wrappers/Wrapper';

const TicketHelpWizard = (props) => {

  const { handleShowHelp, isWillCall, noModal } = props

  const groupItems = () => {
    if (isWillCall) {
      return {
        scanTickets: { key: 'scanTickets', prop: 'scanTickets' }
      }
    } else {
      return {
        assignTickets: { key: 'assignTickets', prop: 'assignTickets' },
        forwardTickets: { key: 'forwardTickets', prop: 'forwardTickets' },
        scanTickets: { key: 'scanTickets', prop: 'scanTickets' }
      }
    }
  }

  const helpWrapper = () => {
    const hsProps = { gKey: 'others', gsKey: 'ticketing' }
    return <Wrapper
      content={<Help helpType={helpTypes.documents} helpKey={'ticketing'} hvps={'ticketing'} groupItems={groupItems()} hsProps={hsProps} />}
    />
  }

  const helpWizard = () => <FullPageWrapper
    content={helpWrapper()}
    handleCancel={handleShowHelp}
    noModal={noModal}
    topperCaption={'Help'}
  />

  return helpWizard()
}

export default TicketHelpWizard
