import React, { createContext, useContext } from 'react';
import GiftCardProvider from '../../../projectSpecific/giftCard/cnr/contexts/GiftCardContext';
import GolfEventProvider from '../../../projectSpecific/golf/cnr/contexts/GolfEventContext';
import SportsProvider from '../../../projectSpecific/sports/cnr/contexts/SportsContext';
import { gEnums } from '../../enums/globalEnums';
import { EventInfoContext } from './EventInfoContext';
import { PageContext } from './PageContext';

/** AppContext */
export const AppComponentContext = createContext();

/** Global OtherDatas for the Event */
const AppComponentProvider = (props) => {

  const { cc } = props ? props : {}

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appComponents: appComponents_g, appSettings } = aps_global ? aps_global : {}

  let { useStartAppData, startAppData } = appSettings ? appSettings : {}
  let { componentContent, useComponentContent } = appComponents_g ? appComponents_g : {}

  if (cc) { componentContent = cc }

  const getComponent = useComponentContent || (useStartAppData && startAppData.length > 0) ? true : false

  // staticViewContext
  const staticViewContext = useContext(EventInfoContext);
  const { staticViews } = staticViewContext ? staticViewContext : {}

  const providerContext = () => {
    switch (componentContent) {
      case gEnums.componentContentTypes.golfEvent:
        const gep = GolfEventProvider({ staticViews, directReturn: true })
        if (gep.golfEvent_state) {
          return <AppComponentContext.Provider value={{ ...gep, appComponent: componentContent }}>
            {props.children}
          </AppComponentContext.Provider>
        } else {
          return <AppComponentContext.Provider value={{ appName: null }}>
            {props.children}
          </AppComponentContext.Provider>
        }

      case gEnums.componentContentTypes.sports:
        const sep = SportsProvider({ staticViews, directReturn: true })
        if (sep.sports_state) {
          return <AppComponentContext.Provider value={{ ...sep, appComponent: componentContent }}>
            {props.children}
          </AppComponentContext.Provider>
        } else {
          return <AppComponentContext.Provider value={{}}>
            {props.children}
          </AppComponentContext.Provider>
        }

      // case gEnums.componentContentTypes.sportsDistrict:
      //   const sdp = SportsDistrictProvider({ staticViews, directReturn: true })
      //   if (sdp.sportsDistrict_state) {
      //     return <AppComponentContext.Provider value={{ ...sdp, appComponent: componentContent }}>
      //       {props.children}
      //     </AppComponentContext.Provider>
      //   } else {
      //     return <AppComponentContext.Provider value={{}}>
      //       {props.children}
      //     </AppComponentContext.Provider>
      //   }

      case gEnums.componentContentTypes.giftCard:
        const gcp = GiftCardProvider(staticViews)
        if (gcp.giftCard_state) {
          return <AppComponentContext.Provider value={{ ...gcp, appComponent: componentContent }}>
            {props.children}
          </AppComponentContext.Provider>
        } else {
          return <AppComponentContext.Provider value={{ appName: null }}>
            {props.children}
          </AppComponentContext.Provider>
        }

      default:
        return <AppComponentContext.Provider value={{ genericState: { test: true } }}>
          {props.children}
        </AppComponentContext.Provider>
    }
  }

  return getComponent
    ? providerContext()
    :
    <AppComponentContext.Provider value={{}}>
      {props.children}
    </AppComponentContext.Provider>
}

export default AppComponentProvider