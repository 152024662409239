import React, { createContext } from 'react';

export const ElementContext = createContext();

const ElementProvider = (props) => {

  const elementProvider = () => <ElementContext.Provider value={{ ...props }}>
    {props.children}
  </ElementContext.Provider>

  return elementProvider()
};

export default ElementProvider 