import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { uniqueKey } from '../../common/keys';

const styles = {
  // root: {
  //   padding: '0.5em 2em',
  // },
  slideContainer: {
    padding: '0 10px',
  },
  slide: {
    minHeight: 100,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
};

const _useTest = true

const SwiperGroup = (props) => {

  const { content } = props ? props : {}

  const swipeableContent = () => {
    if (_useTest) {
      return <SwipeableViews enableMouseEvents>
        <div style={Object.assign({}, styles.slide, styles.slide1)}>slide n°2</div>
        <div style={Object.assign({}, styles.slide, styles.slide2)}>slide n°2</div>
        <div style={Object.assign({}, styles.slide, styles.slide3)}>slide n°3</div>
      </SwipeableViews>
    } else {
      return <SwipeableViews
        enableMouseEvents
        key={uniqueKey('ss', 'sv')}
        className='swiper-simple'>
        {content}
      </SwipeableViews>
    }
  }

  return swipeableContent()

}

export default SwiperGroup