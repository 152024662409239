
import { getFirestore, where } from "firebase/firestore";
import { getAuthColor } from "../../auth/authPermissions";
import { convertSnapshot } from '../../cnr/contexts/contextHelpers';
import { signInResponseTypes } from "../../cnr/reducers/SignInReducer";
import { createInitials } from "../../common/dataAdjust";
import { gEnums } from '../../enums/globalEnums';
import { createRefPath, createRefPath_client, createRefPath_event } from "../appData/appRefPaths";
import { fs_get_data, get_docs } from '../appData/fsAppData';
import { doc_get_promise, ref_get } from "../appData/fsRefs";
import { getAdditionalAppUser } from "./getAdditionalAppUser";
import { getAdditionalAppUserInfoPromise } from "./getAppUserAdditional";

function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const _profileCollectionName = 'profiles'
export const _appUserProfileCollectionName = 'appUserProfiles'

/**
 * gets the profile data for the appUser. This is NOT using functions
 * @param {object} creds 
 * @param {object} pathViews 
 * @param {array} appUserCollection 
 * @param {object} logging 
 * @param {function} callback 
 * @returns appUser (profileSources, appUserSessionKey, sessionAppUser)
 */
export const getAppUserProfile = async (creds, pathViews, appUserCollection, logging, callback) => {

  //  { email: any; firstName: any; lastName: any; password: any; }
  const callData = { creds, pathViews, appUserCollection, showLogs: logging && logging.allowLogging && logging.logGetProfile }
  try {
    getAppUserProfileInfos(callData, callback)
  } catch (error) {
    console.error(error)
  }
}

export const getAppUserInfo = (state, handleAmmendAppUser, handleUpdateAppUserEventInfo) => {

  const { currentUser, aps_appUserSettings, pathViews } = state
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  const callback_3 = (appUser) => {
    getAppUserEventInfo(pathViews, appUserCollection, handleUpdateAppUserEventInfo)
    handleAmmendAppUser(appUser)
  }

  const callback_2 = (appUser) => getAdditionalAppUser(appUser, pathViews, callback_3)

  const callback_1 = async (data) => {
    getAdditionalAppUserInfoPromise().then(res => {
      const _appUser = ammendAppUserInfo(res, data, currentUser, pathViews)
      callback_2(_appUser)
    }).catch(err => {
      const _appUserD = ammendAppUserData(currentUser, data, null, null, pathViews)
      _appUserD.profileValidated = true
      _appUserD.loggedIn = true
      callback_2(_appUserD)
    })
  }

  if (currentUser) {
    const creds = { email: currentUser.email, uid: currentUser.uid, phoneNumber: currentUser.phoneNumber }
    const callData = { creds, pathViews, appUserCollection }
    getAppUserProfileInfos(callData, callback_1)
  } else {
    callback_3({ profileValidated: true })
  }
}

/**
 * Gets the profile and user document from the root collections by email
 * @param {object} data (ds, pathViews, appUserCollection, noEmailValidation, showLogs)
 * @param {function} callback 
 * @returns 
 */
export const getAppUserProfileInfos = async (data, callback) => {

  const { creds, pathViews, appUserCollection, noEmailValidation, showLogs } = data
  const { clients: clientKey, events: eventKey } = pathViews ? pathViews : {}
  const { email, userName, uid, phoneNumber } = creds ? creds : {}

  // userName/email
  let _userNameOrEmail = userName ? userName : email
  if (_userNameOrEmail) { _userNameOrEmail = _userNameOrEmail.toLowerCase().trim() }

  // validate email
  if (!noEmailValidation && !validateEmail(_userNameOrEmail)) {
    if (!uid) {
      callback(null, null, { error: signInResponseTypes.invalidEmail })
    }
  }

  const fs = getFirestore()

  const callback_profileData = (appUserInfo) => callback(appUserInfo)

  const callback_profile = async (profileInfo) => {

    const refs = {
      profile: null,
      profile_appUser: null,
      profile_client: null,
      data_client: null,
      data_event: null,
      profile_event: null,
    }

    refs.profile_appUser = _userNameOrEmail || phoneNumber ? appUserProfileRef(fs, _userNameOrEmail, phoneNumber) : null
    refs.profile_client = (_userNameOrEmail || phoneNumber) && pathViews.clients ? clientProfileRef(fs, pathViews, _userNameOrEmail, uid, phoneNumber) : null
    refs.data_client = clientKey ? clientRef(fs, pathViews) : null
    refs.data_event = eventKey ? eventRef(fs, pathViews) : null
    refs.profile_event = (_userNameOrEmail || phoneNumber) && pathViews.events ? eventProfileRef(fs, pathViews, _userNameOrEmail, uid, phoneNumber, appUserCollection) : null

    let data_client;

    if (clientKey) {
      // data_client = await fs_get_data({ refPath: refs.data_client, opts: { returnFirstObject: true } })
      data_client = await get_docs(refs.data_client, { returnFirstObject: true })
    }

    if (eventKey) {
      // const data_event = await fs_get_data({ refPath: refs.data_event, opts: { returnFirstObject: true } })
      const data_event = await get_docs(refs.data_event, { returnFirstObject: true })
      const { appUserCollection: appUserView_event } = data_event ? data_event : {}
      const _props = { userNameOrEmail: _userNameOrEmail, refs, appUserCollection: appUserView_event ? appUserView_event : appUserCollection, data_client, data_event, profileInfo, eventKey, showLogs, callback }
      const appUserInfo = await getAppUserProfileData(_props)
      callback_profileData(appUserInfo)
    } else {
      const _props2 = { userNameOrEmail: _userNameOrEmail, refs, appUserCollection: appUserCollection, data_client, profileInfo, eventKey, showLogs, callback }
      const appUserInfo = await getAppUserProfileData(_props2)
      callback_profileData(appUserInfo)
    }
  }

  profileRef_listen(fs, _userNameOrEmail, uid, callback_profile)

}

/** Returns 2 or more Promises
 * @promise 0: profileRef.get - profile 
 * @promise 1: clientProfileEmailRef.get ** if pathViews.clients
 * @promise 2: appUserRef(appUserCollection/attendee).get ** if pathViews.events 
 */
const appUserProfile_promises = (refs) => {
  const promises = []
  if (refs.profile_appUser) { promises.push(doc_get_promise(refs.profile_appUser)) } // 1 - profile_appUser 
  if (refs.profile_client) { promises.push(doc_get_promise(refs.profile_client)) } // 2 - profile_client 
  if (refs.profile_event) { promises.push(doc_get_promise(refs.profile_event)) } // 3 - profile_event  
  return Promise.all(promises)
}

/**
 * 
 * @param {object} promiseResult - the promiseResult 
 * @param {*} emailOrUserName 
 * @returns (profile, profile_appUser, profile_appUser, profile_event )
 */
const getAppUserPromiseData = (promiseResult, emailOrUserName, profileInfo, data_client, data_event) => {

  const pras = {
    profile_appUser: 0,
    profile_client: 1,
    profile_event: 2,
  }

  const pd = {
    profile: profileInfo, //profiles 
    profile_appUser: promiseResult[pras.profile_appUser] ? convertSnapshot(promiseResult[pras.profile_appUser], true, { returnFirstObject: true }) : null, //appUserProfiles
    profile_client: promiseResult[pras.profile_client] ? convertSnapshot(promiseResult[pras.profile_client], true, { returnFirstObject: true }) : null,// clients/profiles
    profile_event: promiseResult[pras.profile_event] ? convertSnapshot(promiseResult[pras.profile_event], true, { returnFirstObject: true }) : null,  // clients/events/attendees
  }

  return pd

}

const profileRef_listen = async (fs, email, uid, callback) => {
  if (email) {
    const wheres = [where('email', '==', email)]
    const _refPath = createRefPath(['profiles'])
    fs_get_data({ refPath: _refPath, wheres, callback, opts: { listen: true, returnFirstObject: true } })
  } else if (uid) {
    const _refPath = createRefPath(['profiles', uid])
    fs_get_data({ refPath: _refPath, callback, opts: { listen: true, returnFirstObject: true } })
  }
}


const clientRef = (fs, pathViews) => ref_get(fs, createRefPath_client(pathViews))
const eventRef = (fs, pathViews) => ref_get(fs, createRefPath_event(pathViews))

const clientProfileRef = (fs, pathViews, email, uid, phoneNumber) => {
  const _refPath = createRefPath_client(pathViews, ['profiles'])
  if (email) {
    const wheres = [where('email', '==', email)]
    return ref_get(fs, createRefPath_client(pathViews, ['profiles']), wheres)
  } else if (phoneNumber) {
    const wheres = [where('phoneNumber', '==', phoneNumber)]
    return ref_get(fs, createRefPath_client(pathViews, ['profiles']), wheres)
  } else if (uid) {
    return ref_get(fs, createRefPath([uid], _refPath))
  }
}

const eventProfileRef = (fs, pathViews, email, uid, phoneNumber, appUserCollection) => {
  if (email) {
    const wheres = [where('email', '==', email)]
    return ref_get(fs, createRefPath_event(pathViews, [appUserCollection]), wheres)
  } else if (phoneNumber) {
    const wheres = [where('phoneNumber', '==', phoneNumber)]
    return ref_get(fs, createRefPath_event(pathViews, [appUserCollection]), wheres)
  } else if (uid) {
    return ref_get(fs, createRefPath_event(pathViews), [appUserCollection, uid])
  }
}

/**
 * 
 * @param {object} fs 
 * @param {string} email 
 * @param {string} phoneNumber 
 * @returns 
 */
const appUserProfileRef = (fs, email, phoneNumber) => {
  if (email) {
    const wheres = [where('email', '==', email)]
    return ref_get(fs, createRefPath([_appUserProfileCollectionName]), wheres)
  } else if (phoneNumber) {
    const wheres = [where('phoneNumber', '==', phoneNumber)]
    return ref_get(fs, createRefPath([_appUserProfileCollectionName]), wheres)
  }
}

/**
 * Gets the profile information from the `appUserCollection`
 * @param {string} _userNameOrEmail 
 * @param {object} refs 
 * @param {string} appUserCollection https://forms.gle/735ppr1WzGYLfHn16
 * @param {boolean} showLogs 
 * @param {function} callback 
 */
const getAppUserProfileData = async (props) => {
  const { userNameOrEmail, refs, appUserCollection, profileInfo, eventKey, data_client, data_event, callback } = props
  try {
    const promiseResults = await appUserProfile_promises(refs);
    const pd = getAppUserPromiseData(promiseResults, userNameOrEmail, profileInfo, data_client, data_event);
    const appUser = ammendAppUser(appUserCollection, pd, eventKey, data_client, data_event);
    if (appUser) {
      callback && callback(appUser);
      return appUser
    } else {
      callback && callback({});
      return {}
    }
  } catch (error) {
    console.error(error);
    callback && callback({});
    return {}
  }
}



/** Converts the data into 4 objects
 * @param pr - the promise results
 * @param emailOrUserName - self explanitory :)
 * @returns an object with 4 sets of data (profileDate, appProfileData, appUserData, data_event).*/


/** Returns an `appUser` object */
const ammendAppUser = (appUserCollection, pd, eventKey, data_client, data_event) => {

  let au;
  const profileSources = []

  const { profile_appUser, profile_event, profile_client, profile } = pd

  const { events: events_cp } = profile_client ? profile_client : {}
  const { activeEvents: activeEvents_client } = data_client ? data_client : {}

  const { _itemKey: _itemKey_event } = profile_event ? profile_event : {}

  const currentEvent = events_cp ? events_cp[eventKey] : null
  const { appUserKey: appUserKey_event } = currentEvent ? currentEvent : {}

  const appUserEventKeys = events_cp ? Object.keys(events_cp) : []

  const activeEvents_appUser = []

  if (activeEvents_client) {
    appUserEventKeys.forEach(auek => {
      if (activeEvents_client.includes(auek)) {
        activeEvents_appUser.push(auek)
      }
    })
  }

  if (profile_appUser) {
    // attendees
    au = profile_appUser
    au.appUserType = gEnums.appUserTypes.appUser
    au.profileOriginType = gEnums.profileTypes.clientProfiles
    profileSources.push(appUserCollection)
  }

  // if (profile_event) {
  //   // attendees
  //   au = profile_event
  //   au.appUserType = gEnums.appUserTypes.appUser
  //   au.profileOriginType = gEnums.profileTypes.
  //   profileSources.push(appUserCollection)
  // }

  // CLIENT PROFILE
  if (profile_client) {
    au = profile_client
    au.appUserType = gEnums.appUserTypes.appProfile
    au.profileOriginType = gEnums.profileTypes.clientProfiles
    profileSources.push('clients')
  }

  // ADMIN - //profiles
  if (profile) {
    au = profile
    au.appUserType = gEnums.appUserTypes.appUser
    au.profileOriginType = gEnums.profileTypes.adminProfiles
    profileSources.push('profiles')
  }


  if (au) {
    au.profileSources = profileSources

    if (profile_event && profile_event.id) { au.appUserSessionKey = profile_event.id }
    if (profile_appUser && profile_appUser.key) { au.appUserSessionKey = profile_appUser.key }
    if (profile_appUser && profile_appUser.id) { au.appUserSessionKey = profile_appUser.id }

    if (appUserKey_event) { au.appUserSessionKey = appUserKey_event }
    if (profile_appUser) { au.sessionAppUser = profile_appUser }
    if (profile_client) { au.sessionAppUser = profile_client }
    if (activeEvents_client) { au.activeEvents_client = activeEvents_client }
    if (activeEvents_appUser) { au.activeEvents_appUser = activeEvents_appUser }
  }

  return au
}

const ammendAppUserInfo = (promiseResults, appUserInfo, currentUser, pathViews) => {
  const { claims } = promiseResults[0]
  const imageUrls = {
    full: promiseResults && promiseResults[1] && promiseResults[1][0] ? promiseResults[1][0] : null,
    thumbnail: promiseResults && promiseResults[1] && promiseResults[1][1] ? promiseResults[1][1] : null
  }
  const _appUser = ammendAppUserData(currentUser, appUserInfo, claims, imageUrls, pathViews)
  _appUser.profileValidated = true
  _appUser.loggedIn = true
  return _appUser
}

const ammendAppUserData = (currentUser, appUserInfo, claims, imageUrls, pathViews) => {

  const { phoneNumber, displayName } = currentUser ? currentUser : {}
  const { settingsFavs, events: events_profile, clients: clients_profile, appUserSessionKey, fcmTokens, id: profileId, email, activeEvents_appUser } = appUserInfo ? appUserInfo : { profileLevel: 0 }
  const { authLevel, clientAuthLevel, clients, events } = claims ? claims : {}
  const { events: eventKey, clients: clientKey } = pathViews ? pathViews : {}

  let appUserType = gEnums.appUserTypes.appProfile
  let accessLevel;
  let settingsAuthLevel;

  // Set the Access level here

  if (events_profile && eventKey && events_profile[eventKey]) {
    appUserType = gEnums.appUserTypes.appProfile
    accessLevel = gEnums.accessLevels.appProfile.value
  } else if (clients_profile && clientKey && clients_profile[clientKey] && clients_profile[clientKey].events && clients_profile[clientKey].events[eventKey]) {
    appUserType = gEnums.appUserTypes.appProfile
    accessLevel = gEnums.accessLevels.appProfile.value
  }

  if (clientAuthLevel) {
    settingsAuthLevel = clientAuthLevel
    accessLevel = clientAuthLevel
    appUserType = gEnums.appUserTypes.appAdmin
  }

  if (authLevel) {
    settingsAuthLevel = authLevel
    accessLevel = authLevel
    appUserType = gEnums.appUserTypes.admin
  }

  if (email === 'philipaokeeffe@gmail.com') {
    accessLevel = gEnums.accessLevels.superAdminDeveloper.value
    settingsAuthLevel = gEnums.accessLevels.superAdminDeveloper.value
  }

  const tokens = { accessLevel, clients, events }

  let initials = displayName ? createInitials(displayName) : null
  if (!initials && appUserInfo.displayName) { initials = createInitials(appUserInfo.displayName) }

  let isAppUser;
  let address;

  switch (appUserType) {
    case gEnums.appUserTypes.appProfile:
    case gEnums.appUserTypes.appAdmin:
    case gEnums.appUserTypes.appDataAdmin:
    case gEnums.appUserTypes.appSubAdmin:
      isAppUser = true
      break;
    default:
    // nothing
  }

  if (appUserInfo.sessionAppUser) {
    const { street, city, state, zip } = appUserInfo.sessionAppUser
    address = street + ', ' + city + ', ' + state + ' ' + zip
  }

  const appUserAccess = {
    accessLevel: accessLevel,
    activeEvents_appUser,
    address,
    appUserColor: getAuthColor(accessLevel),
    appUserSession: appUserInfo.sessionAppUser,
    appUserSessionKey,
    appUserType,
    displayName: appUserInfo.displayName,
    email,
    fcmTokens,
    initials,
    isAdminOrSuper: settingsAuthLevel >= gEnums.accessLevels.admin.value,
    isSuperAdmin: settingsAuthLevel >= gEnums.accessLevels.superAdmin.value,
    isAppUser,
    loggedIn: true,
    pageFavs: appUserInfo.pageFavs,
    pageFavz: appUserInfo.pageFavz,
    phoneNumber,
    profileId,
    settingsAuthLevel,
    uid: currentUser.uid,
  }

  // ammend the appUserAccess
  if (events_profile) { appUserAccess.events = events_profile }

  if (clients_profile) {
    appUserAccess.clients = clients_profile
    appUserAccess.events = clients_profile[clientKey] ? clients_profile[clientKey].events : null
  }

  if (clientAuthLevel) { appUserAccess.appAuth = { clientAuthLevel, clientAuthKey: clientAuthLevel ? clientKey : null } }

  switch (appUserType) {
    case gEnums.appUserTypes.appAdmin:
    case gEnums.appUserTypes.appDataAdmin:
    case gEnums.appUserTypes.appProfile:
    case gEnums.appUserTypes.appSubAdmin:
      appUserAccess.clientAccessKey = clientKey ? clientKey : null
      break;
    default:
    // nothing
  }

  const appUser = {
    ...currentUser,
    profileData: appUserInfo,
    appUserAccess,
    appUserAccess_fixed: appUserAccess,
    tokens,
    imageUrls: imageUrls,
    settingsFavs,
    authUserValidated: true
  }

  return appUser

}

const getAppUserEventInfo = (pathViews, appUserCollection, _itemKey, forClone, callback) => {
  const _appUserRef = createRefPath_event(pathViews, [appUserCollection, _itemKey])
  fs_get_data({ refPath: _appUserRef, callback, cbProps: { forClone }, opts: { returnFirstObject: true, listen: true } })
}