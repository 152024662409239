import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import { Button, Label, Table } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { createItemArray } from '../../common/creating';
import { PageContext } from '../../cnr/contexts/PageContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { gEnums } from '../../enums/globalEnums';
import { getUniquePropKeys } from '../../common/filtering';
import UiSearchFilter from '../../pageItem/UiSearchFilter';
import { tableHandlers, tableInitialState, tableReducer } from '../../cnr/reducers/TableReducer';
import ConfirmationQuestion from '../alerts/ConfirmationQuestion';
import Checker from '../forms/elements/Checker';

export const tableViewTypes = {
  dataCompare: 'dataCompare',
  dataSelect: 'dataSelect',
  deleteItems: 'deleteItems',
  normal: 'normal',
  previewItems: 'previewItems',
  selectItems: 'selectItems',
}

const TableView = (props) => {

  const {
    allowClickTo,
    filteredList,
    handleDataItemSelect,
    handleFilteredList,
    handleSelectedTableList,
    handleTableList,
    listType,
    maxSelection,
    namePropsOnly,
    selectedProp,
    selectedFilters,
    selectedItems: _selectedItems,
    selectedPropsOnly,
    // swipeOn,
    tableViewType,
    viewItem,
    viewListData, // this is a modified viewListData, also available in dataContext
  } = props

  // viewSettingsComponentContext
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_handlers, vsc_state } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { swipedItem } = vsc_state ? vsc_state : {}
  const { swipeToSelected } = vsc_handlers ? vsc_handlers : {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_fns } = pageContext ? pageContext : {}
  const { pushSimple } = page_fns ? page_fns : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { action_handlers, viewItem: _viewItem } = uiItemContext ? uiItemContext : {}

  // actionContext 
  let allowCommit = false
  let allowDelete = false
  let fullView = true
  let showDiff = false
  let showCount = false
  let showTableSearch = false
  let allowRowEdit = false
  let allowSelect = !allowClickTo

  switch (tableViewType) {
    case tableViewTypes.dataCompare:
      showDiff = true
      allowCommit = true
      break;
    case tableViewTypes.dataSelect:
      allowCommit = true
      allowSelect = true
      showCount = true
      break;
    case tableViewTypes.normal:
      allowCommit = false
      allowSelect = false
      break;
    default:
    // break
  }

  let tableData = _.isArray(viewListData) ? viewListData : createItemArray(viewListData)

  if (filteredList) { tableData = filteredList }

  let tableCn = 'table-content'
  if (allowClickTo) { tableCn += ' np' }

  const searchKeys = getUniquePropKeys(tableData)

  // tableReducer  
  const init_state = { viewItem: _viewItem ? _viewItem : viewItem, tableData, searchKeys, namePropsOnly, selectedPropsOnly, selectedItems: _selectedItems, maxSelection }
  const [table_state, table_dispatch] = useReducer(tableReducer, tableInitialState(init_state));
  const table_handlers = tableHandlers(table_dispatch)
  const { initTableData, tableHeaders, selectedItems, newProps, diffOn, diffOnlyOn, tableList, questionProps, selectedOnlyOn } = table_state

  // update if tableData has chaged
  useEffect(
    () => { if (tableData && !tableHeaders) { table_handlers.createTableHeaders(tableData) } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // update if tableData has changed
  useEffect(
    () => {
      if (tableList) { table_handlers.updateTableData(tableList) }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableList]
  )

  // update if selectedItems has chaged
  useEffect(
    () => {
      if (handleDataItemSelect) { handleDataItemSelect(selectedItems, listType) }
      if (selectedItems && tableList) {
        const stis = {}
        selectedItems.forEach(si => {
          if (tableList[si]) {
            stis[tableList[si].id] = tableList[si]
          }
        })
        handleSelectedTableList && handleSelectedTableList(stis)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItems]
  )

  // update if tableData has chaged
  useEffect(
    () => { if (handleTableList && handleFilteredList) { handleFilteredList(tableList) } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (selectedFilters && selectedFilters.length > 0 && initTableData) {
        const newList = []
        initTableData.forEach(tli => {
          let allow = false
          selectedFilters.forEach(sf => {
            const { prop, value } = sf
            if (tli[prop] === value) { allow = true }
          })
          if (allow) { newList.push(tli) }
        })
        table_handlers.updateTableData(newList, selectedItems)
      } else if (initTableData) {
        table_handlers.updateTableData(initTableData, selectedItems)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFilters]
  )

  const confirmAction = () => {
    if (allowCommit) {
      action_handlers.handleReplaceData(tableList)
    } else if (allowDelete) {
    }
    table_handlers.setCommitData(null)
  }

  const confirmCancel = () => table_handlers.setCommitData(null)

  const handleDeleteQuestion = () => {
    table_handlers.setCommitData({
      header: 'Confirm Delete',
      question: 'Are you sure you want to delete this item? It cannot be recovered.'
    })
  }

  const handleSelectAll = (e, data) => {
    e.cancelBubble = true;
    table_handlers.handleSelectAll(data)
    if (e.handleSelectAll) e.stopPropagation();
  }

  const handleSelect = (e, data) => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    table_handlers.handleSelect(data)
  }

  const handleSearchChange = (value) => { table_handlers.handleSearchChange(value) }
  const searchFilter = () => <UiSearchFilter handleSearchChange={handleSearchChange} />
  const countHeader = () => <Label className={'dbl-lbl'}>{'Selected: '}
    <Label circular color='green'>{selectedItems.length}</Label>
  </Label>
  // const countHeader = () => { return <Label>{'Selected:'}{' '}{selectedItems.length}</Label> }

  const modalConfirmSimple = () => {
    return <ConfirmationQuestion
      handleYes={confirmAction}
      handleNo={confirmCancel}
      header={questionProps.header}
      question={questionProps.question}
      asModal={false}
    />
  }

  const handleRowSelect = (rowIndex) => {
    if (allowRowEdit && !fullView) {
      const tableListData = tableList[rowIndex]
      const editProps = { dataUpdateType: gEnums.dataUpdateTypes.edit, viewItem, viewListData: tableListData }
      const si = { ...swipedItem }
      si.breadCaption = tableListData && tableListData.name ? tableListData.name : _.startCase(swipedItem.key)
      si.viewListData = tableListData
      si.isDataRoot = true
      const swipeProps = {
        swipedItem: si,
        menuType: 'edit',
        editProps
      }
      swipeToSelected(swipeProps)
    }
    if (allowClickTo) {
      const ci = tableList[rowIndex]
      pushSimple(viewItem, ci.id)
    }
  }

  const tableRow = (tableHeaders, rowItem, rowIndex) => {
    let hasDiff = false
    // eslint-disable-next-line 
    const cells = tableHeaders.map((item, cellIndex) => {
      const { key } = item
      const _itemm = key ? key : item
      let allow = true
      if (namePropsOnly) {
        switch (_itemm) {
          case 'name':
          case 'firstName':
          case 'lastName':
            break;
          default:
            allow = false
            break;
        }
      }
      if (selectedProp) {
        if (_itemm !== selectedProp) {
          allow = false
        }
      }
      if (allow) {
        let value = rowItem[_itemm]
        let isSpan = false
        if (value && value.length > 100) { value = value.substring(0, 60) + '...' }
        if (value && _.isString(value) && value.indexOf('XPROP') >= 0) {
          value = value.replace('XPROP', '')
          isSpan = true
        }
        const keyy = 'tc-' + rowIndex + '-' + cellIndex
        if (isSpan) {
          const split = value.split('/')
          if (split.length === 2) { hasDiff = true }
          if (diffOn) {
            return <Table.Cell key={uniqueKey('tvtr', keyy)}>{split[1]}<span className={'x-prop'}>{split[0]}</span></Table.Cell>
          } else {
            return <Table.Cell key={uniqueKey('tvtr', keyy)}>{split[1]}</Table.Cell>
          }
        } else {
          if (_.isObject(value)) {
            return <Table.Cell key={uniqueKey('tvtr', keyy)}>{'{...}'}</Table.Cell>
          } else {
            return <Table.Cell key={uniqueKey('tvtr', keyy)}>{value}</Table.Cell>
          }
        }
      }
    })

    let checked = selectedItems.includes(rowItem._itemKey)

    if (allowDelete || allowSelect) { cells.unshift(<Table.Cell key={uniqueKey('tvtr', 'as')} >{selectToggle(checked, rowItem)}</Table.Cell>) }
    if (showCount) { cells.unshift(<Table.Cell key={uniqueKey('tvtr', 'sc')} >{rowIndex + 1}</Table.Cell>) }
    return {
      cells,
      hasDiff: !diffOnlyOn ? true : hasDiff
    }
  }

  const tableRows = (tableHeaders) => (
    // eslint-disable-next-line  
    tableList.map((rowItem, rowIndex) => {
      const r = tableRow(tableHeaders, rowItem, rowIndex)
      let allow = true
      if (selectedOnlyOn) { allow = rowItem.selected }
      if (allow && r.hasDiff) {
        return <Table.Row key={uniqueKey('tvtr', 'tr', rowIndex)} onClick={e => handleRowSelect(rowIndex)}>{r.cells}</Table.Row>
      }
    })
  )

  const table = () => {
    // eslint-disable-next-line 
    const headers = tableHeaders.map((item, index) => {
      const { key } = item
      const _itemm = key ? key : item
      let allow = true
      if (namePropsOnly) {
        switch (_itemm) {
          case 'name':
          case 'firstName':
          case 'lastName':
            break;
          default:
            allow = false
            break;
        }
      }
      if (selectedProp) {
        if (_itemm !== selectedProp) {
          allow = false
        }
      }
      if (allow) {
        if (item.caption) {
          return <Table.HeaderCell key={uniqueKey('tvtr', 'th', index)} >{item.caption}</Table.HeaderCell>
        } else {
          return <Table.HeaderCell key={uniqueKey('tvtr', 'th', index)} >{_.startCase(item)}</Table.HeaderCell>
        }
      }
    })

    if (allowDelete || allowSelect) { headers.unshift(<Table.HeaderCell key={uniqueKey('tvtr', 'tch', 's')} >{selectAllToggle()}</Table.HeaderCell>) }
    if (showCount) { headers.unshift(<Table.HeaderCell key={uniqueKey('tvtr', 'tch', 'sc')} >{'#'}</Table.HeaderCell>) }

    return <Table singleLine celled compact unstackable striped selectable id='table'  >
      <Table.Header>
        <Table.Row>
          {headers}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tableList && tableRows(tableHeaders)}
      </Table.Body>
    </Table >
  }

  const tableFooter = () => {
    return <Button
      color={'red'}
      disabled={selectedItems.length === 0}
      fluid
      size='large'
      onClick={() => { handleDeleteQuestion() }}
    >
      {'Delete Selected Items'}
    </Button>
  }

  const selectAllToggle = () => <Checker handleClick={handleSelectAll} item={{ key: 'selectAll' }} />
  const selectToggle = (checked, rowItem) => <Checker item={rowItem} handleClick={handleSelect} checked={checked} disabled={maxSelection && (selectedItems.length >= maxSelection)} />
  const selectedOnlyToggle = () => <Checker item={{ key: 'selectedOnly' }} checked={selectedOnlyOn} label={_.startCase('selectedOnly')} handleClick={table_handlers.handleSelectedOnly} style={{ float: 'left' }} />
  const diffToggle = () => <Checker item={{ key: 'diff' }} checked={diffOn} label={_.startCase('showDifferences')} handleClick={table_handlers.handleDiff} style={{ float: 'right' }} />
  const diffOnlyToggle = () => <Checker item={{ key: 'diffOnly' }} label={_.startCase('diffOnly')} toggle handleClick={table_handlers.handleOnlyDiff} checked={diffOnlyOn} />

  if (questionProps) {
    return modalConfirmSimple()
  } else {
    if (tableList && tableHeaders) {
      let cn = 'table-container normal'
      if (showCount) { cn += ' count' }
      if (showTableSearch) { cn += ' search' }
      if (showDiff) { cn += ' toggle' }
      if (allowDelete) { cn += ' foot' }
      if (newProps) { cn += ' props' }
      return <div className={cn}>
        {showTableSearch && <div className={'table-search'}>{searchFilter()}</div>}
        {showCount && <div className={'table-count'}>{selectedOnlyToggle(selectedOnlyOn)}{countHeader()}</div>}
        {/* {newProps && <div className={'table-props'}>{newPropsIcon()}</div>} */}
        {showDiff && <div className={'table-diff'}>
          {showDiff && diffOn && diffOnlyToggle()}
          {diffToggle()}
        </div>}
        <div className={tableCn}>{table()}</div>
        {allowDelete && <div className={'table-footer'}>{tableFooter()}</div>}
      </div>
      // }
    } else {
      return <div> no data</div>
    }
  }
}

export default TableView