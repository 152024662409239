import React, { useEffect, useState } from 'react'
import { getAlphaList } from '../../common/convert'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import AlphabetMenu from '../menus/AlphabetMenu'
import TableViewer from './TableViewer'

const AlphaTableViewer = (props) => {

  const { appData, handleRowSelect, tableRef, viewItem, showItemKey } = props

  const [alphaValue, setAlphaValue] = useState(65)
  const [currentDataList, setCurrentDataList] = useState(appData)
  const [sortProp, setSortProp] = useState('name')

  useEffect(() => {
    handleAlphaChange(64)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appData]);

  const handleAlphaChange = (av) => {
    const _alphaChar = String.fromCharCode(av)
    const sortProp = 'name'
    if (appData && av) {
      const { alphaList, sortProp: sp } = getAlphaList(appData, _alphaChar, sortProp)
      setAlphaValue(av)
      setCurrentDataList(alphaList)
      setSortProp(sp)
    }
  }

  const tableViewer = () => <TableViewer
    tableRef={tableRef}
    jsonData={currentDataList}
    handleRowSelect={handleRowSelect}
    isAppData={true}
    viewItem={viewItem}
    showItemKey={showItemKey}
  />

  const alphaWrapper = () => <Wrapper
    header={<AlphabetMenu handleAlphaChange={handleAlphaChange} alphaValue={alphaValue} restrictToExisting={true} sortProp={sortProp} />}
    content={tableViewer()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  return alphaWrapper()

}


export default AlphaTableViewer