import { deleteField } from 'firebase/firestore';
import _ from 'lodash';
import { _appVersion } from '../../cnr/contexts/AppSettingsContext';
import { dispatchProps, grts } from '../../cnr/reducers/reducerHelpers/dispatchProps';
import { cleanUpData, removeAllEmpties } from '../../common/dataAdjust';
import { getNow } from '../../common/dateFormatting';
import { copyObj } from '../../common_web/copy';
import { _useSettingsSeparation, _settingsFs } from '../../viewSettings/actions/getSettings';
import { createRefPath, createRefPath_event } from '../appData/appRefPaths';
import { doc_set, fs_set_doc, fs_update_doc } from "../appData/fsData";

const _allowUpdates = true
const _useGlobalAlt = false
const _settingsGlobalDocument = 'global'
const _settingsGlobalAltDocument = '_global'


// const separateSaveViewItems = false
// https://howtofirebase.com/firebase-security-rules-88d94606ce4a

export const dv = (value) => {
  const allDocs = {}
  value.forEach(doc => { m(allDocs, doc) })
  return allDocs
}

/**
 * This updates (usings Set) the settings to the database
 * @param {object} data (settings_temp, papsProps, isGlobal, sandboxOn, appUser, specificView, appSettingsChanges)
 * @param {function} dispatch 
 * called from fbSettingsFunctions
 */
export const updateSettingsToDB = async (data, dispatch) => {

  const { settings_temp, papsProps, isGlobal, sandboxOn, appUser, specificView, appSettingsChanges } = data ? data : {}
  const { gvvi, page } = settings_temp ? settings_temp : {}
  const { displayName, uid } = appUser ? appUser : {}

  let _global;
  let _viewItems;
  let _views;

  if (isGlobal) {
    _global = gvvi.global
    _viewItems = gvvi.viewItems
    _views = gvvi.views
  } else {
    _global = page
    _viewItems = page.viewItems
  }

  const settings_name = _useSettingsSeparation ? _settingsFs.root : _settingsFs.root + '_dev'

  const { pathViews, rootPaths, view, viewKey, landingView, settingsDocName } = papsProps ? papsProps : {}

  const newGlobal = _global ? copyObj(_global) : null
  const newViewItems = _viewItems ? copyObj(_viewItems) : null
  const newViews = _views ? copyObj(_views) : null

  const { googleSheets, parentDefaults } = newGlobal ? newGlobal : {}

  if (googleSheets) {
    let dbPath = rootPaths ? rootPaths.events : null
    dbPath = dbPath ? dbPath.substring(1) : null
    googleSheets.dbPath = dbPath ? dbPath : 'unknown'
  }

  if (parentDefaults) {
    // remove these items from the save
    const { useClientThemes, useHomeTransitions, useClientUiElements, useClientUiSections } = parentDefaults
    if (useClientThemes) {
      delete newGlobal.themedItems
      delete newGlobal.themeColors
    }
    if (useHomeTransitions) { delete newGlobal.transitionItems }
    if (useClientUiElements) { delete newGlobal.uiElements }
    if (useClientUiSections) { delete newGlobal.uiSections }
  }

  let trueViewKey = viewKey ? view : view + '_list'
  trueViewKey = getTrueView(pathViews, view, trueViewKey, landingView, viewKey)

  if (specificView) { trueViewKey = specificView }

  // ************ 
  const _refPath = []
  let _ref_path;

  if (sandboxOn && appUser && appUser.uid) {
    _refPath.push(settings_name + '_sandbox', settingsDocName)
  } else {
    _refPath.push(settings_name, settingsDocName)
    _ref_path = createRefPath([_settingsFs.root, settingsDocName])
  }

  // if (newViewItems) { cleanUpData(newViewItems, null, true, true) }

  let dataUpdate;

  if (isGlobal) {
    if (newViews) {
      dataUpdate = {
        global: newGlobal,
        viewItems: newViewItems,
      }
    } else {
      dataUpdate = {
        global: newGlobal,
        viewItems: newViewItems
      }
    }
  } else {
    // the views. will only update the `views` part of the document
    if (_useSettingsSeparation) {
      dataUpdate = {
        views: { [trueViewKey]: { ..._global, viewItems: newViewItems } },
      }
    } else {
      const x = 'views.' + trueViewKey
      dataUpdate = { [x]: { ..._global, viewItems: newViewItems } }
    }
  }

  dataUpdate = removeNulls(dataUpdate)
  // if (dataUpdate.global) { cleanUpData(dataUpdate.global, null, true, true) }
  // if (dataUpdate.viewItems) { cleanUpData(dataUpdate.viewItems, null, true, true) }
  // if (dataUpdate.views) { cleanUpData(dataUpdate.views, null, true, true) }
  // if (dataUpdate.global && dataUpdate.global.viewItems) { cleanUpData(dataUpdate.global.viewItems, null, true, true) }
  // if (dataUpdate.global && dataUpdate.global.viewItems) { dataUpdate.viewItems = dataUpdate.global.viewItems }

  if (dataUpdate.views) {
    Object.keys(dataUpdate.views).forEach(key => {
      const view = dataUpdate.views[key]
      const { viewItems } = view ? view : {}
      ammendDeleteFields(viewItems)
    })
  }

  ammendDeleteFields(dataUpdate.viewItems)

  dataUpdate.status = {
    uid,
    displayName,
    appVersion: _appVersion,
    lastUpdate: getNow()
  }

  console.log('dataUpdate', dataUpdate)

  if (_useSettingsSeparation) {
    settingsUpdate(_ref_path, dataUpdate, isGlobal, trueViewKey).then(res => {
      appSettingsChanges && appSettingsChanges.statusChanged && updateEventStatus(dataUpdate, pathViews)
      dispatch && dispatch({ type: dispatchProps.success, dispatch, res })
    }).catch(error => {
      console.error(error)
      dispatch && dispatch({ type: dispatchProps.error, dispatch, error })
    })
  } else {
    fs_update_doc(_refPath, dataUpdate, dispatch)
    appSettingsChanges && appSettingsChanges.statusChanged && updateEventStatus(dataUpdate, pathViews)
  }
}

/**
 * 
 * @param {object} pathViews 
 * @param {object} viewItems 
 * @param {array} ammendedKeys 
 */
export const updateViewItemsToDb = async (pathViews, viewItems, ammendedKeys) => {
  const settings_name = _useSettingsSeparation ? _settingsFs.root : _settingsFs.root + '_dev'
  const baseRef = createRefPath([settings_name, pathViews.events])
  const _refPath = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)
  fs_set_doc(_refPath, viewItems, true, false, true)
}

/**
 * Updates the settings (fuil)
 * @param {object} pathViews 
 * @param {object} viewItems 
 * @param {object} views 
 * @param {function} callback 
 */
export const createNewViewsAndViewItemsSettingsToDb = async (pathViews, viewItems, views, callback) => {
  createNewViewsAndViewItemsSettings(pathViews, viewItems, views).then(res => {
    callback && callback({ type: dispatchProps.success, callback, res })
  })
}

/**
 * Updates the settings/home/settings/global document
 * @param {object} dataToUpdate 
 * @param {function} callback 
 */
export const updateHomeGlobalSettingsToDb = (dataToUpdate, callback, useAlt) => {
  if (_allowUpdates) { //asdfasfasfd 
    const _settingsRef = createRefPath([_settingsFs.root, 'home', _settingsFs.collection, (_useGlobalAlt || useAlt) ? _settingsGlobalAltDocument : _settingsGlobalDocument])
    fs_update_doc(_settingsRef, dataToUpdate, callback)
  } else {
    callback({ success: false })
  }
}

/**
 * This will update the global, viewItems and views documents to the database
 * @param {object} allAppSettings 
 * @param {function} response 
 */
export const updateSettingsDocsToDatabase = async (allAppSettings, response) => {
  settingsDocsPromise(allAppSettings).then(res => {
    response({ type: grts.updateSuccess })
  }).catch(error => {
    response({ type: grts.updateError, error })
  })
}


/**
 * Updates 3 parts of the settings document (views/viewKey, views/viewKey+_list, viewItems/viewKey)
 * @param {string} viewKey 
 * @param {object} view 
 * @param {object} viewList 
 * @param {object} viewItem 
 * @param {object} pathViews 
 * @param {function} callback 
 */
export const createNewViewAndViewItemsSettingsDB = async (settingsProps, pathViews, callback) => {

  const settings_name = _settingsFs.root

  const _baseRef = createRefPath([settings_name, pathViews.events, _settingsFs.collection])

  updateViSettingsPromises(settingsProps, _baseRef).then(res => {
    console.log('res', res)
  }).catch(error => {
    console.error(error);
  })

}

/**
 * Updates a settings item via dot notation. ('views.' + _view + '.viewItems.' + viewItem.key)
  * @param {object} pathViews 
 * @param {object} viewItem 
 * @param {string} viewKey 
 * @param {function} callback 
 */
export const updateNewViewToViewsSettingsDB = async (viewKey, views, pathViews, callback) => {

  const settings_name = _settingsFs.root
  const _refPath = []
  _refPath.push(settings_name, pathViews.events)

  const viewItem = views[viewKey]
  const viewItem_list = views[viewKey + '_list']

  const dataUpdate = {
    ['views.' + viewKey]: viewItem,
    ['views.' + viewKey + '_list']: viewItem_list,
  }

  fs_update_doc(_refPath, dataUpdate, callback)
}

/**
 * Updates a setting's item via dot notation. ('views.' + _view + '.viewItems.' + viewItem.key)
* @param {object} pathViews 
 * @param {object} viewItem 
 * @param {string} viewKey 
 * @param {function} callback 
 */
export const updateViewViewItemSettingsDB = async (pathViews, viewItem, viewKey, callback) => {

  const settings_name = _useSettingsSeparation ? _settingsFs.collection : _settingsFs.root

  let _refPath = []

  if (_useSettingsSeparation) {
    _refPath = createRefPath([settings_name, pathViews.events, _settingsFs.collection, 'views'])
    const _vv = !viewKey ? viewItem.key + '_list' : viewItem.key
    const dataUpdateN = { [_vv]: viewItem }
    fs_update_doc(_refPath, dataUpdateN, callback)
  } else {
    _refPath = createRefPath([settings_name + '_dev', pathViews.events])
    const _view = !viewKey ? viewItem.key + '_list' : viewItem.key
    const x = 'views.' + _view + '.viewItems.' + viewItem.key
    const dataUpdate = { [x]: viewItem }
    fs_update_doc(_refPath, dataUpdate, callback)
  }
}

export const updateGlobalAndViewsDBSettings = async (fullDbSettings, paps_state, sandboxOn, appUser, callback) => {

  const dataUpdate = copyObj(fullDbSettings)

  let { settingsDocName } = paps_state ? paps_state : {}

  // ************
  // LOOK
  let _docRootPath;
  // let _refPath;

  if (sandboxOn && appUser && appUser.uid) {
    // _refPath = createRefPath([rootSettings_collection_sandbox, appUser.uid], _settingsFs.root, settingsDocName)
    // docRoot = fs.collection(rootSettings_collection_sandbox).doc(appUser.uid).collection(_settingsFs.root).doc(settingsDocName)
  } else {
    // _refPath = createRefPath([_settingsFs.root, settingsDocName])
    // docRoot = fs.collection(_settingsFs.root).doc(settingsDocName)
  }

  _docRootPath = createRefPath([_settingsFs.root, settingsDocName])

  // _docRoot = fs.collection(_settingsFs.root).doc(settingsDocName)

  if (fullDbSettings.global) { cleanUpData(fullDbSettings.global) }
  if (fullDbSettings.views) { cleanUpData(fullDbSettings.views) }

  // if (docRoot.FE) { console.log(docRoot.FE.segments) }

  console.log('_docRootPath', _docRootPath)
  console.log('dataUpdate', dataUpdate)


  // fs_update_doc(_docRootPath, dataUpdate, callback)

}

/** 
 * Updates the settings (fuil)
 * @param {object} pathViews 
 * @param {object} viewItems 
 * @param {object} views 
 * @param {function} callback 
 * @returns 
 */
const createNewViewsAndViewItemsSettings = async (pathViews, viewItems, views, callback) => {

  const settings_name = _settingsFs.root

  const promises = []

  const baseRef = createRefPath([settings_name, pathViews.events])

  // settings/key/settings/viewItems
  const rp_vi = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)
  promises.push(doc_set(rp_vi, viewItems))

  // settings/key/settings/views
  const rp_v = createRefPath([_settingsFs.collection, 'views'], baseRef)
  promises.push(doc_set(rp_v, views))

  return Promise.all(promises)
}

const cleanUpObject = function (obj, label) {
  if (obj.label === label) { return obj; }
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      var _obj = cleanUpObject(obj[i], label);
      if (_obj) {
        console.log('_obj', _obj)
        return _obj;
      }
    }
  }
  return null;
};

export const removeNulls = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeNulls).filter(value => value !== null);
  }

  let result = {};

  Object.entries(obj).forEach(([key, value]) => {
    const newValue = removeNulls(value);
    if (newValue !== null) {
      result[key] = newValue;
    }
    // }
  });

  return Object.keys(result).length === 0 ? null : result;
}

/**
 * 
 * @param {object} settingsProps 
 * @param {string} baseRef 
 * @returns 
 */
const updateViSettingsPromises = async (settingsProps, baseRef) => {

  const { viewKey, view, viewList, viewItem } = settingsProps

  cleanUpData(viewItem)
  cleanUpData(view)
  cleanUpData(viewList)

  const promises = []

  const _refPath_viewItems = createRefPath(['viewItems'], baseRef)
  const _refPath_views = createRefPath(['views'], baseRef)

  const dataUpdate_viewItems = {
    [viewKey]: viewItem,
  }

  const dataUpdate_views = {
    [viewKey]: view,
    [viewKey + '_list']: viewList,
  }

  promises.push(fs_update_doc(_refPath_viewItems, dataUpdate_viewItems, null, true))
  promises.push(fs_update_doc(_refPath_views, dataUpdate_views, null, true))

  return Promise.all(promises)
}

/**
 * 
 * @param {object} viewItems 
 */
const ammendDeleteFields = (viewItems) => {
  if (viewItems) {
    Object.keys(viewItems).forEach(key => {
      const viewItem = viewItems[key]
      const { props, propSections } = viewItem ? viewItem : {}
      if (viewItem.key && _.isObject(viewItem.key) && viewItem.key._methodName) {
        viewItem.key = key
      }
      if (viewItem._deleteMe) {
        viewItems[key] = deleteField()
      } else {
        Object.keys(viewItem).forEach(propKey => {
          if (viewItem[propKey]._deleteMe) {
            viewItem[propKey] = deleteField()
          }
        })
      }
      if (propSections) {
        Object.keys(propSections).forEach(psKey => {
          const propSection = propSections[psKey]
          if (propSection._deleteMe) {
            viewItem.propSections[psKey] = deleteField()
          }
        })
      }
      if (props) {
        Object.keys(props).forEach(pKey => {
          const prop = props[pKey]
          if (prop._deleteMe) {
            viewItem.props[pKey] = deleteField()
          }
        })
      }
    })
  }
}

/**
 * Updates the event status in the database 
 * @param {object} dataUpdate 
 * @param {object} pathViews 
 */
const updateEventStatus = (dataUpdate, pathViews) => {
  const { global } = dataUpdate ? dataUpdate : {}
  const { appStatus } = global ? global : {}
  if (appStatus) {
    const _refPath = createRefPath_event(pathViews)
    const data = global.appStatus
    fs_update_doc(_refPath, data)
  }
}

/**
 * Updates (using Set) to the `settings` documents (main, settings/global, settings/views, settings/viewItems) in the database
 * @param {*} baseRef 
 * @param {*} dataUpdate 
 * @param {*} isGlobal  
 * @param {*} trueViewKey 
 * @returns 
 */
const settingsUpdate = async (baseRef, dataUpdate, isGlobal, trueViewKey) => {

  const merge = true

  const promises = []

  const { status, global, viewItems, views } = dataUpdate

  promises.push(fs_set_doc(baseRef, status, merge, false, true))

  if (isGlobal) {
    const rp_g = createRefPath([_settingsFs.collection, _settingsGlobalDocument], baseRef)
    promises.push(fs_set_doc(rp_g, global, merge, false, true))

    const rp_vi = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)
    promises.push(fs_set_doc(rp_vi, viewItems, merge, false, true))

  } else {
    const rp_v = createRefPath([_settingsFs.collection, 'views'], baseRef)
    const _data = { [trueViewKey]: views[trueViewKey] }
    promises.push(fs_set_doc(rp_v, _data, merge, false, true))
  }

  return Promise.all(promises)
}


/**
 * This will update the global, viewItems and views documents to the database
 * @param {object} allAppSettings 
 * @param {function} response 
 */
const settingsDocsPromise = async (allAppSettings) => {
  const promises = []
  // loop the allAppSettings
  Object.keys(allAppSettings).forEach(settingsKey => {
    const baseRef = createRefPath([_settingsFs.root, settingsKey])
    const appSettings = allAppSettings[settingsKey]
    const { global, viewItems, views } = appSettings
    if (global && views && viewItems) {
      // loop the docs within appSettings
      Object.keys(appSettings).forEach(docKey => {
        const _settingsRef = createRefPath([_settingsFs.collection, docKey], baseRef)
        const appSetting = removeAllEmpties(appSettings[docKey])
        // console.log('_settingsRef', docKey, _settingsRef)
        // console.log('appSetting', appSetting)
        promises.push(doc_set(_settingsRef, appSetting))
      })
    }
  })
  return Promise.all(promises)
}

const m = (o, doc, path) => {
  o[doc.id] = {
    ...doc.data(),
    id: doc.id
  }
}

const getTrueView = (pathViews, _view, trueView, landingView, _viewKey) => {
  if (trueView === landingView) {
    if (pathViews.events) {
      trueView = 'events'
    } else if (pathViews.clients) {
      trueView = 'clients'
    }
  }
  return trueView
} 