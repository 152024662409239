import _ from 'lodash'
import React, { useRef } from 'react'
import { Menu, Ref, Sidebar } from 'semantic-ui-react'
import { uniqueKey } from '../../common/keys'

const popupMenuItems = {
  delete: 'delete',
}

export const PopupConfirm = (props) => {

  const { content, sidebarOpen, sidebarHandler, handleConfirm } = props

  const menuItem = (item) => <Menu.Item key={uniqueKey('pc', 'mi')} onClick={(e) => { handleConfirm(item) }} >{_.startCase(item)}</Menu.Item>

  const sidebarContent = () => {
    const mis = []
    mis.push(menuItem(popupMenuItems.delete))
    return mis
  }

  const contentRef = useRef()

  const PopupSidebar = ({ animation, direction, visible }) => {
    return <Sidebar
      as={Menu}
      animation={animation}
      direction={direction}
      icon='labeled'
      inverted
      vertical
      visible={visible}
      onHide={() => sidebarHandler()}
      target={contentRef}
      width='thin'
      key={uniqueKey('pc', 'sb')}
    >
      {sidebarContent()}
    </Sidebar>
  }

  const slider = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <PopupSidebar
      animation={'overlay'}
      direction={'bottom'}
      visible={sidebarOpen}
    />
    <Ref innerRef={contentRef}>
      <Sidebar.Pusher className={'pusher-container'} dimmed={sidebarOpen}>
        {content}
      </Sidebar.Pusher>
    </Ref>
  </Sidebar.Pushable>

  return slider()
}
