import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { AppSettingsContext } from '../cnr/contexts/AppSettingsContext';
import { mobileDeviceTypes } from '../devices';
import { settingsIconTypes } from '../enums/settingsIconTypes';

export const DropdownDeviceSelect = () => {

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_handlers, appSettings_state } = appSettingsContext ? appSettingsContext : {}
  const { currentDevice } = appSettings_state ? appSettings_state : {}

  const [optsList, setOptsList] = useState()

  let menuPlaceholder = 'currentDisplay'

  const handleChange = (e, data) => appSettings_handlers.handleSetDevice(data.value)

  const displayOptions = () => {
    const mds = mobileDeviceTypes()
    const opts = []
    mds.forEach(item => {
      opts.push({ key: item.key, value: item.value, text: _.startCase(item.text), icon: { name: item.key === 'desktop' ? settingsIconTypes.tv : 'mobile alternate', color: 'blue' } })
    })
    setOptsList(opts)
  }

  useEffect(() => {
    displayOptions()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  if (!optsList) {
    return <div></div>
  } else {
    return <Dropdown
      button
      className='icon thin profile-select app-device'
      icon={{ name: currentDevice.value === 'desktop' ? settingsIconTypes.tv : 'mobile alternate', color: 'blue' }}
      labeled
      options={optsList}
      placeholder={_.startCase(menuPlaceholder)}
      scrolling
      onChange={handleChange}
      text={_.startCase(currentDevice.value)}
    />
  }
}