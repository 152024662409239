import React, { createContext, useEffect, useState } from 'react';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fsfn_getAppForms } from '../../functions/fbSettingsFunctions';

const useFunctions = false

/** Returns all the forms (objects) needed for the App */
export const AppFormsContext = createContext();

/** This will get the homeSettings and clientSettings */
const AppFormsProvider = (props) => {

  const [appForms, setAppForms] = useState()
  const [formsUpdated, setFormsUpdated] = useState()

  const getForms = () => {
    const vvProps = {}
    if (useFunctions) {
      fsfn_getAppForms(vvProps).then(appForms => {
        setAppForms(appForms)
        setFormsUpdated(true)
      })
    } else {
      getAppForms(setAppForms, setFormsUpdated, vvProps)
    }
  }

  useEffect(() => {
    getForms()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const providerContext = () => <AppFormsContext.Provider value={{ appForms_state: { appForms: appForms, formsUpdated }, appForms_fns: { getForms } }}>
    {props.children}
  </AppFormsContext.Provider>

  return appForms ? providerContext() : <div></div>

}

export default AppFormsProvider

const getAppForms = (callback, callback2, vvProps) => {
  const docName = 'appForms'
  fs_get_data({ refPath: createRefPath(['_appForms', docName]), opts: { returnFirstObject: true, ignoreId: true } }).then(appForms => {
    if (appForms && Object.keys(appForms).length === 0) {
      fsfn_getAppForms(vvProps).then(appForms => {
        callback2(appForms)
        callback(true)
      })
    } else {
      callback(appForms ? appForms : {})
      callback2(true)
    }
  }).catch(err => {
    console.error('getAppForms', err)
  })
}