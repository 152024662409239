import React from "react";
// import { AppDummy } from "../../AppDummy";
import AppAccessProvider from "../cnr/contexts/AppAccessContext";
import AppComponentProvider from "../cnr/contexts/AppComponentContext";
import AppFormsProvider from "../cnr/contexts/AppFormsContext";
import AppNotificationsProvider from "../cnr/contexts/AppNotificationsContext";
import AppUserAuthProvider from "../cnr/contexts/AppUserAuthContext";
import AppUserProvider from "../cnr/contexts/AppUserContext";
import ConversationProvider from "../cnr/contexts/AppUserDataContext";
import BaseSettingsProvider from '../cnr/contexts/BaseSettingsContext';
import CredentialingProvider from "../cnr/contexts/CredentialingContext";
import EventInfoProvider from "../cnr/contexts/EventInfoContext";
import UpdateProvider from "../cnr/contexts/FirestoreContext";
import GoogleSheetsProvider from "../cnr/contexts/GoogleSheetsContext";
import HeaderProvider from "../cnr/contexts/HeaderContext";
import PageProvider from "../cnr/contexts/PageContext";
import ParentProvider from "../cnr/contexts/ParentContext";
import PreviewProvider from "../cnr/contexts/PreviewContext";
import QuestionsAndAnswersProvider from "../cnr/contexts/QuestionsAndAnswersContext";
import RoutesProvider from "../cnr/contexts/RoutesContext";
import StartDataProvider from "../cnr/contexts/StartDataContext";
import StaticViewsClientProvider from "../cnr/contexts/StaticViewsClientContext";
import StorageProvider from "../cnr/contexts/StorageContext";
import TransitionProvider from "../cnr/contexts/TransitionContext";
import VideoConferenceProvider from "../cnr/contexts/VideoConferenceContext";
import { googleSheetsTypes } from "../cnr/reducers/GoogleSheetsReducer";
import AppPage from './AppPage';
import AppUsersProvider from "../cnr/contexts/AppUsersContext";

const transitionType = 'css'

/** Starting point fo the App 
@includes PageProvider  
@includes AppAccessProvider   
@includes AppUserProvider  
@includes StorageProvider   
@includes StaticViewsClientProvider
@includes StaticViewsProvider   
@includes AppNotificationsProvider  
@includes ConversationProvider  
@includes UpdateProvider  
@includes AppFormsProvider  
@includes AppDataProvider  
@includes AppPage  
*/
const AppView = (props) => {
  return (
    <PreviewProvider>
      <PageProvider {...props} >
        <RoutesProvider>
          <AppUserProvider>
            <BaseSettingsProvider>
              <AppAccessProvider>
                <StorageProvider>
                  <StaticViewsClientProvider>
                    <EventInfoProvider>
                      <AppUserAuthProvider>
                        <AppNotificationsProvider>
                          <VideoConferenceProvider>
                            <AppFormsProvider>
                              <StartDataProvider>
                                <TransitionProvider>
                                  <ParentProvider>
                                    <AppUsersProvider>
                                      <QuestionsAndAnswersProvider>
                                        <CredentialingProvider>
                                          <GoogleSheetsProvider googleSheetsType={googleSheetsTypes.app}>
                                            <ConversationProvider>
                                              <UpdateProvider>
                                                <HeaderProvider>
                                                  <AppComponentProvider>
                                                    {/* <AppDummy /> */}
                                                    <AppPage transitionType={transitionType} />
                                                  </AppComponentProvider>
                                                </HeaderProvider>
                                              </UpdateProvider>
                                            </ConversationProvider>
                                          </GoogleSheetsProvider>
                                        </CredentialingProvider>
                                      </QuestionsAndAnswersProvider>
                                    </AppUsersProvider>
                                  </ParentProvider>
                                </TransitionProvider>
                              </StartDataProvider>
                            </AppFormsProvider>
                          </VideoConferenceProvider>
                        </AppNotificationsProvider>
                      </AppUserAuthProvider>
                    </EventInfoProvider>
                  </StaticViewsClientProvider>
                </StorageProvider>
              </AppAccessProvider>
            </BaseSettingsProvider>
          </AppUserProvider>
        </RoutesProvider>
      </PageProvider>
    </PreviewProvider>
  )
}

export default AppView