import { createItemKeys } from '../../../../global/common/convert';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';

const rts = {
  handleSportsInit: 'handleSportsInit',
  handleSetSports: 'handleSetSports',
  handleSetGlobals: 'handleSetGlobals',
  handleSetDataResults: 'handleSetDataResults',
}

const dataItems = {
  schools: { collectionItems: ['schools'] },
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsReducer = (state, action) => {

  const { pathViews, dataParents } = state
  const { type, dispatch } = action

  const { handleSetDataResults, handleSetGlobals, handleSetSports } = sportsHandlers(dispatch)

  switch (type) {

    case rts.handleSportsInit:

      const _refPathG = createRefPath_event(pathViews, ['_globals'])
      fs_get_data({ refPath: _refPathG, callback: handleSetGlobals })

      const _refPath = createRefPath_event(pathViews, ['sports'])
      fs_get_data({ refPath: _refPath, callback: handleSetSports })

      // const { selectedSeason } = action
      // const additionalDataParents = []
      // getData_parentKeyCollections(pathViews, dataItems, dataParents, additionalDataParents, null, selectedSeason, handleSetDataResults)
      return { ...state }

    case rts.handleSetGlobals:
      return { ...state, allGlobals: action.globals }

    case rts.handleSetSports:
      const _sports = action.sports
      Object.keys(_sports).forEach(k => {
        _sports[k].active = true
      })
      return { ...state, sports: _sports }

    // handles the result when all the data from the parentKeys are collected
    case rts.handleSetDataResults:

      const { dataResult, parentDataKeys } = action

      const _results = {}

      Object.keys(dataItems).forEach(diKey => {
        _results[diKey] = dataResult[diKey]
        createItemKeys(_results[diKey])
      })

      return {
        ...state,
        ..._results,
        parentDataKeys,
      }

    default:
      return { ...state }
  }
}

export const sportsHandlers = (dispatch) => {
  return {
    handleSportsInit: () => { dispatch({ type: rts.handleSportsInit, dispatch }) },
    handleSetSports: (sports) => { dispatch({ type: rts.handleSetSports, dispatch, sports }) },
    handleSetDataResults: (dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys) => { dispatch({ type: rts.handleSetDataResults, dispatch, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys }) },
    handleSetGlobals: (globals) => { dispatch({ type: rts.handleSetGlobals, dispatch, globals }) },
  }
}

export const sportsInitialState = (init_state) => {
  return { ...init_state, currents: {}, currentSidebars: {} }
};