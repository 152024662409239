import React from 'react';
import { g_cns } from '../../common/cns';
import { gEnums } from '../../enums/globalEnums';
import { SettingsSolo } from '../../viewSettings/SettingsSolo';
import AppSidebarMenu from './AppSidebarMenu';
import { sidebarStyles } from './sidebarStyles';

// AppSidebar > AppSidebarMenu > ? SettingsSolo > ViewSettingsSidebar > ViewSettings


/**
 * 
 * @param {*} param0 
 * @returns The contents of the sidebar contained in Sidebar
 * @description contexts must be passed in, not created in the function
 */
const AppSidebar = ({ frameworkContext, pageContext }) => {

  let cn = g_cns.app_sidebar_left_wrapper

  // frameworkContext 
  const { framework_state, framework_handlers } = frameworkContext ? frameworkContext : {}
  const { appSidebarOpen, settingsSidebarSoloOpen, soloPermissionType } = framework_state
  const { handleAppSidebar } = framework_handlers

  // pageContext 
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const { sideMenu, settingsOptions } = aps_global ? aps_global : {}
  const { allowSwipeOpen } = sideMenu ? sideMenu : {}
  const { useDarkMode } = settingsOptions ? settingsOptions : {}

  const menuSaC = aps_styles ? aps_styles[gEnums.projectStyles.sideMenu] : {}

  if (useDarkMode) { cn += ' dark-mode' }
  cn += ' no-sb'

  const sidebarContents = () => {
    if (settingsSidebarSoloOpen) {
      return <SettingsSolo isGlobal={soloPermissionType === 'appSettings'} />
    } else {
      return <AppSidebarMenu sac={menuSaC} />
    }
  }

  // these are the props for react-sidebar
  return {
    sidebar: appSidebarOpen ? sidebarContents() : <div></div>,
    open: appSidebarOpen,
    onSetOpen: handleAppSidebar,
    sidebarClassName: cn,
    contentClassName: 'sb-content',
    direction: appSidebarOpen ? 'right' : 'left',
    touch: allowSwipeOpen ? true : false,
    transitions: allowSwipeOpen,
    styles: { sidebarStyles },
  }
}

export default AppSidebar