import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Dropdown, Input, Label } from 'semantic-ui-react';
import { DataContext } from '../cnr/contexts/DataContext';
import { FilterContext } from '../cnr/contexts/FilterContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../cnr/reducers/SidebarReducer';
import { filterGroupFilters, getUniqueListData } from '../common/filtering';
import { uniqueKey } from '../common/keys';
import { sortObj } from '../common/sorting';
import NoData from '../components/alerts/NoData';
import { DataManagementContext } from '../components/viewers/DataManagementViewer';
import { gEnums } from '../enums/globalEnums';
import { fs_get_data_all } from '../firestoreData/appData/fsAppData';
import { listList } from '../lists/lists';
import MenuSidebars from '../sidebars/MenuSidebars';
import { dataManagementTypes } from '../viewSettings/enums/itemActionTypes';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { DataModificationsContext } from './modification/UiDataModifications';

export const fullFilterTypes = {
  create: 'create',
  item: 'item',
  notification: 'notification',
  settingsView: 'settingsView',
  table: 'table',
  view: 'view',
}

const sidebarType = 'filters'

export const UiDataFilters = (props) => {

  const { fullFilterType, selectedPageItem, handleSelectFilter, handleSelectedFilters, viewItem_current } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, paps_state, eventInfo_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_viewItems } = pageSettings ? pageSettings : {}
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ? dataManagementContext : {}
  const { updating, confirmation, dataManagementType } = dataManagement_state ? dataManagement_state : {}
  const inViewer = dataManagement_state ? true : false
  const isFilter = dataManagementType === dataManagementTypes.createFilter ? true : false

  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}

  // papsContext 
  const { pathViews } = paps_state ? paps_state : {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_handlers, uiFilter_state } = filterContext ? filterContext : {}
  const { selectedFilters } = uiFilter_state ? uiFilter_state : {}

  // dataContext
  const dataContext = useContext(DataContext)
  const { data_state } = dataContext ? dataContext : {}
  const { viewListData } = data_state ? data_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem } = item_state ? item_state : {}

  const _viewItem = selectedPageItem ? selectedPageItem.pageItem : viewItem
  let _viewItem_global = aps_viewItems && _viewItem && _viewItem.key ? aps_viewItems[_viewItem.key] : null
  if (!_viewItem_global && viewItem_current && aps_viewItems[viewItem_current.key]) { _viewItem_global = aps_viewItems[viewItem_current.key] }

  const { dataFilters } = _viewItem_global ? _viewItem_global : {}

  const { props: props_vi, dataNotifications, filter, key: viewItemKey } = _viewItem ? _viewItem : {}
  const { filterProps } = filter ? filter : {}
  const { notificationFilterProps } = dataNotifications ? dataNotifications : {}

  const { dataFilters: dataFilters_current } = viewItem_current ? viewItem_current : {}
  const _dataFilters = dataFilters_current ? dataFilters_current : dataFilters

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: null }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ? sidebar_state : {}
  const { setInit } = sidebar_handlers ? sidebar_handlers : {}
  const { ms_filters } = sidebar_items ? sidebar_items : {}
  const { selected: filter_selected } = ms_filters ? ms_filters : {}
  const { item: filter_name } = filter_selected ? filter_selected : {}

  const [_props, set_props] = useState()
  const [vld, setVld] = useState()
  const [excludedProps, setExcludedProps] = useState()
  const [dataItems, setDataItems] = useState([])
  const [filterName, setFilterName] = useState()
  const [filterData, setFilterData] = useState()

  const { groups: allFilterGroups, options: filterOptions } = filterData ? filterData : {}

  let buttonDisabled;

  switch (fullFilterType) {
    case fullFilterTypes.create:
      buttonDisabled = filterName && selectedFilters && (Object.keys(selectedFilters).length > 0) ? false : true
      break;
    default:
      buttonDisabled = selectedFilters && Object.keys(selectedFilters).length > 0 ? false : true
  }

  const handleNameChange = (e, data) => setFilterName(data.value)

  const _viewListData = vld ? vld : viewListData

  const buttonCaption = fullFilterType === fullFilterTypes.create ? 'Create (' + dataItems.length + ')' : 'View (' + dataItems.length + ')'

  const validGroup = (items) => {
    // if (Object.keys(items).length === 1 && Object.keys(items)[0].value === undefined) {
    //   return false
    // }
    return true
  }

  const getFilterGroups = (values) => {
    const a = []
    const excluded = []
    if (_props) {
      const _propKeys = getUniqueListData(_viewListData, staticViews)
      console.log('_propKeys', _propKeys)
      Object.keys(_props).forEach(prKey => {
        const prop_vi = _props[prKey]
        let allow = true
        if (notificationFilterProps && notificationFilterProps.length > 0) {
          if (!notificationFilterProps.includes(prKey)) { allow = false }
        }
        if (filterProps && filterProps.length > 0) {
          if (!filterProps.includes(prKey)) { allow = false }
        }
        if (fullFilterType === fullFilterTypes.notification) {
          const { data: data_prop } = prop_vi
          const { notificationFilter } = data_prop ? data_prop : {}
          allow = notificationFilter
        }
        if (allow) {
          if (_viewListData) {
            // loop the data
            Object.keys(_viewListData).forEach(key => {
              const dataItem = _viewListData[key]
              if (dataItem) {
                // the the fields in the data item
                Object.keys(dataItem).forEach(propKey => {
                  const dataPropValue = dataItem[propKey]
                  if (!excluded.includes(propKey)) {
                    if (_.isArray(dataPropValue) || _.isObject(dataPropValue)) {
                      excluded.push(propKey)
                    }
                  }
                })
              }
            })
            const gbr = _.groupBy(_viewListData, prKey)
            if (gbr.undefined) {
              console.log('undefined', prKey, gbr)
            } else {
              console.log('check', prKey, gbr)
            }
            if (values) {
              if (validGroup(gbr) && values.includes(prKey)) { a[prKey] = Object.keys(gbr) }
            } else {
              if (validGroup(gbr)) { a[prKey] = Object.keys(gbr) }
            }
            setExcludedProps(excluded)
          }
        }
      })
    }
    return a
  }

  // set the selected filters
  useEffect(() => {
    let _prs;
    if (isFilter) {
      _prs = props_vi
    } else {
      _prs = props_vi
    }
    set_props(_prs)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // set the selected filters
  useEffect(() => {
    switch (fullFilterType) {
      case fullFilterTypes.create:
      case fullFilterTypes.settingsView:
        break;
      default:
        const _sfs = selectedFilters ? selectedFilters : props.selectedFilters
        uiFilter_handlers.handleCurrentSelectedFilters(_sfs ? _sfs : {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (_dataFilters && filter_name && _dataFilters[filter_name] && _dataFilters[filter_name].filters) {
      switch (fullFilterType) {
        case fullFilterTypes.settingsView:
          const _sfs = selectedFilters ? selectedFilters : props.selectedFilters
          uiFilter_handlers.handleCurrentSelectedFilters(_sfs ? _sfs : {})
          handleSelectFilter && handleSelectFilter(filter_name)
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [filter_name]);

  useEffect(() => {
    if (_dataFilters) {
      const pidf = Object.keys(_dataFilters)
      setInit({ smt: sidebarMenuTypes.one, items: pidf, currents, as: 'filters' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_dataFilters]);

  useEffect(() => {
    if (!_viewListData && selectedPageItem) {
      fs_get_data_all(pathViews, selectedPageItem.key, setVld)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (_props && !allFilterGroups) {
      const a = getFilterGroups()
      setFilterData({ groups: a, keys: Object.keys(a), options: listList(Object.keys(a).sort()) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilterGroups, vld, _props]);

  const handleCancel = () => {
    if (dataModifications_handlers) {
      dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
    } else {
      uiFilter_handlers.handleFilterStatus(gEnums.filterStatusTypes.none)
    }
  }

  const handleButtonClick = () => {
    switch (fullFilterType) {
      case fullFilterTypes.create:
        dataManagement_handlers.handleStartUpdate()
        dataManagement_handlers.handleUpdatePageItemDataFilter(isFilter, viewItemKey, filterName, selectedFilters)
        break;
      default:
        uiFilter_handlers && uiFilter_handlers.handleGroupFilter(selectedFilters)
    }
  }

  const handleOptionChange = (e, data) => {
    const a = getFilterGroups(data.value)
    setFilterData({ groups: a, keys: Object.keys(a), options: filterOptions })
    // setOptionValues(data.value)
  }

  const handleSelected = (groupKey, item) => {
    const dataGroup = null
    const altValue = dataGroup && dataGroup[item] ? dataGroup[item].text : item

    const _sfs = selectedFilters ? { ...selectedFilters } : {}
    if (!_sfs[groupKey]) { _sfs[groupKey] = [] }
    const z = _.find(_sfs[groupKey], { value: item })
    if (!z) {
      _sfs[groupKey].push({ value: item, altValue })
    } else {
      const i = _.findIndex(_sfs[groupKey], { value: item })
      _sfs[groupKey].splice(i, 1)
    }
    uiFilter_handlers.handleCurrentSelectedFilters(_sfs)

    handleSelectedFilters && handleSelectedFilters(_sfs)
    const items = filterGroupFilters(_viewListData, _sfs)
    setDataItems(items)
  }

  const filterGroupItems = (groupKey, items) => {
    const dataGroup = null
    const gis = []
    Object.keys(items).forEach(key => {
      const item = items[key]
      if (item && item !== undefined) {
        if (!_.isArray(item) && !_.isObject(item)) {
          const selectedFilter = selectedFilters ? selectedFilters[groupKey] : []
          const x = _.find(selectedFilter, { value: item })
          const color = x ? 'green' : 'grey'
          const caption = dataGroup && dataGroup[item] ? dataGroup[item].text : item
          gis.push(<Label size='mini' color={color} onClick={() => { handleSelected(groupKey, item) }}>{caption}</Label>)
        }
      }
    })
    return gis
  }

  const groupDiv = (groupKey, items) => {
    return <div key={uniqueKey('udf', groupKey)} className={'filter-group-container'}>
      <div className={'filter-group-header'}>
        {_.startCase(groupKey)}
      </div>
      <div className={'filter-group-content'}>
        {filterGroupItems(groupKey, items)}
      </div>
    </div>
  }

  const availableGroups = () => {
    const grps = []
    if (allFilterGroups) {
      const _all = sortObj(allFilterGroups)
      Object.keys(_all).forEach(groupKey => {
        if (!excludedProps.includes(groupKey)) {
          const items = _all[groupKey]
          grps.push(groupDiv(groupKey, items))
        }
      })
    }
    return grps
  }

  const selectedItemsLabels = () => {
    const count = selectedFilters ? Object.keys(selectedFilters).length : 0
    if (count === 0) {
      return <Label as='a'>
        {'No Filters Selected'}
      </Label>
    } else {
      const lbls = []
      Object.keys(selectedFilters).forEach(key => {
        const selectedFilter = selectedFilters[key]
        let items = ''
        selectedFilter.forEach((item, index) => {
          items += item.value
          if (index < selectedFilter.length - 1) { items += ', ' }
        })
        const value = _.startCase(key) + ': ' + items
        lbls.push(<Label>{value}</Label>)
      })
      return lbls
    }
  }

  const selectedLabelGroup = () => <Label.Group size='tiny' color='blue'>{selectedItemsLabels()}</Label.Group>

  const input = () => <Input
    size='small'
    fluid
    value={filterName}
    label={isFilter ? 'Filter Name' : 'Filter Name'}
    onChange={handleNameChange} />

  const dropdown = () => <Dropdown
    search
    scrolling
    selection
    multiple
    options={filterOptions}
    // value={time[type]}
    onChange={handleOptionChange} />

  const itemFilter = () => {
    return <div className={'filter-container'}>
      {fullFilterType === fullFilterTypes.create && <div className={'filter-header'}>
        {input()}
        {dropdown()}
      </div>}
      <div className={'filter-content'}>
        {availableGroups()}
      </div>
      <div className={'filter-selected'}>
        <div><Label.Group size='tiny' color='blue'>{selectedItemsLabels()}</Label.Group></div>
      </div>
    </div>
  }

  let allowFooter = () => {
    switch (fullFilterType) {
      case fullFilterTypes.notification:
      case fullFilterTypes.create:
      case fullFilterTypes.item:
        return true
      case fullFilterTypes.settingsView:
      case fullFilterTypes.view:
        return false
      default:
        return true
    }
  }

  const footer = () => <Button fluid disabled={buttonDisabled} color={(!buttonDisabled ? 'green' : 'grey')} onClick={handleButtonClick}>{buttonCaption} </Button>

  const itemFilterWrapper = () => <Wrapper
    content={itemFilter()}
    footer={allowFooter() && footer()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const notificationWrapper = () => <Wrapper
    header={selectedLabelGroup()}
    content={availableGroups()}
    wrapperType={wrapperTypes.padded}
  />

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={itemFilterWrapper()}
    inverted={fullFilterType === fullFilterTypes.settingsView ? true : false}
    dimmed={dimmed}
  />

  const viewWizard = () => <FullPageWrapper
    content={menuSidebars()}
    topperCaption={'Filter'}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={itemFilterWrapper()}
    topperCaption={'Filter'}
    handleCancel={handleCancel}
  />

  switch (fullFilterType) {
    case fullFilterTypes.notification:
    case fullFilterTypes.table:
      return notificationWrapper()
    case fullFilterTypes.settingsView:
      return sidebar_items ? menuSidebars() : <NoData altCaption={dataManagementType} inverted={true} />
    case fullFilterTypes.view:
      return sidebar_items ? viewWizard() : <div></div>
    default:
      return inViewer ? itemFilterWrapper() : fullPageWrapper()
  }
}