import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';

/**
 * 
 * @param {object} actionItems 
 * @param {object} appUserCollection 
 * @param {object} links 
 * @param {object} itemData 
 * @param {string} viewKey 
 * @param {object} viewItem 
 * @param {string} docKey 
 */
export const getAppUserLinks = (actionItems, appUserCollection, links, itemData, viewKey, viewItem, docKey) => {
  Object.keys(links).forEach((plKey, index) => {
    const link = links[plKey]
    if (link.viewItem === viewItem.key && viewKey) {
      const ad = itemData[appUserCollection]
      const checked = _.includes(ad, 'TEST') ? true : false
      const icon = checked ? 'check circle outline' : 'circle outline'
      actionItems.push({
        key: 'profile-link' + index,
        text: link.caption,
        icon, checked,
        dataActionType: gEnums.itemLinkTypes.linkProfile,
        actionProps: {
          vl: link,
          // viewKey: viewKey,
          // viewItemKey: docKey,
          appUserCollection,
          linkData: {
            _view: {
              name: appUserCollection,
              key: 'this is the attendee key'
            },
            _viewItem: {
              name: link.viewItem,
              key: docKey,
            },
          },
        },
      })
    }
  })
}

export const getAppUserLink = (appUserCollection, links, itemData, viewItem, appUserSession) => {
  const { appUserSessionKey } = appUserSession ? appUserSession : {}
  const appUserLinks = []
  Object.keys(links).forEach((plKey, index) => {
    const link = links[plKey]
    // of the viewItem.key matches the link.viewItem, allow it.
    if (link.viewItem === viewItem.key) {
      const itemCollectionValue = itemData[appUserCollection]
      const checked = _.includes(itemCollectionValue, appUserSessionKey) ? true : false
      const icon = checked ? 'check circle outline' : 'circle outline'
      appUserLinks.push({
        key: 'profile-link' + index,
        text: link.caption,
        icon, checked,
        dataActionType: gEnums.itemLinkTypes.linkProfile,
        actionProps: {
          vl: link,
          // viewKey: viewKey,
          // viewItemKey: docKey,
          appUserCollection,
          linkData: {
            _view: {
              name: appUserCollection,
              key: appUserSessionKey
            },
            _viewItem: {
              name: link.viewItem,
              key: itemData._itemKey,
            },
          },
        },
      })
    }
  })
  return appUserLinks
}

// export const getAppUserPermissionItems = (actionItems, appUserCollection, collections, itemData, viewKey, viewItem, docKey) => {
//   collections.forEach((collection, index) => {
//     if (collection === viewItem.key && viewKey) {
//       const ad = itemData[appUserCollection]
//       const checked = _.includes(ad, 'TEST') ? true : false
//       const icon = checked ? 'check circle outline' : 'circle outline'
//       actionItems.push({
//         key: 'profile-link' + index, text: link.caption, icon, checked, dataActionType: gEnums.itemLinkTypes.linkProfile,
//         actionProps: {
//           vl: collection,
//         },
//       })
//     }
//   })
// } 