import React, { createContext, useReducer } from 'react';
import { appSettingsHandlers, appSettingsInitialState, appSettingsReducer } from '../reducers/AppSettingsReducer';

export const _appVersion = '2.4.1'

const _showState = false

/** 
@state appSettings_state(`allAppSettings`, `allowSettingsListen`, `appReady`, `appSettingsOn`, `currentDevice`, `dataRefreshOn`, `dbType`, `desktopOn`, `helpOn`, `highlightThemesOn`, `parentStyleOptions`, `sandboxOn`, `settingsPreviewOn`, `showSplash`, `showStartSplash`, `sp`, `updateProps`, `view`, `settingsDocKeys`, `appSettings`, `appUpdated`, `appLanding_global`)
@contexts appSettings_handlers (`handleCopySettings`, `handlePreviewOn`, `handleCommitSettings`, `handleCommitSettingsOn`, `handleAuthOn`, `handleDataOverride`, `handleDatabaseRefresh`, `handleDataSourceType`, `updateSuccess`, `handleHelpOn`, `handleHighlightThemesOn`, `handleGlobalSettingsOn`, `handlePageSettingsOn`, `handleSandboxOn`, `handleSetDevice`, `handleSettingsClose`, `handleSettingsLeftOn`, `handleSettingsOff`, `handleSettingsOn`, `handleSettingsRightOn`, `handleSortingOn`, `handleSplash`, `handleStartSplash`, `handleThemeSelect`, `handleSetAppChange`, `setAppError`, `handleUpdateAppReady`, `handleAmmendAppSettings`, `handleAmmendHCEAppSettings`, `handleUpdateHomeSettings`, `handleUpdateAppSettingsBlank`, `updateSettingsSuccess`, `handleShowAppViewer`, `handleOpenSettingsItem`, `handleShowHomeScreenPrompt`, `handleShowHomeScreenHelp`, `handleGetHceSettings_all`, `handleGetHceSettings`, `handleFunctionResponse`, `handleStartUpdate`, `handleStopUpdate`, `handleCloseConfirmation`)
@description Simple wrapper to handle app settings changes
 */
export const AppSettingsContext = createContext();

/**
 * Simple wrapper to handle app settings changes
 */
const AppSettingsProvider = (props) => {

  const init_state = {}
  const [appSettings_state, appSettings_dispatch] = useReducer(appSettingsReducer, appSettingsInitialState(init_state))
  const appSettings_handlers = appSettingsHandlers(appSettings_dispatch)

  if (_showState) {
    console.log(Object.keys(appSettings_state))
    console.log(Object.keys(appSettings_handlers))
  }

  const checkAppSetting = () => {
    console.log('checkSetting', appSettings_state)
  }

  const fns = {
    checkAppSetting
  }

  return <AppSettingsContext.Provider value={{ appSettings_state, appSettings_handlers, appSettings_fns: fns, appVersion: _appVersion }}>
    {props.children}
  </AppSettingsContext.Provider>
};

export default AppSettingsProvider