import _ from 'lodash';
import { gEnums, listTypes } from '../../enums/globalEnums';

let viewProps = null

export const menuGroup = (group, dividedGroup) => {
  return {
    menuGroup: { key: group },
    settingsMenuGroupType: group,
    dividedGroup: dividedGroup
  }
}

export const createO = (items) => {
  let a = 'const sprs = {\r'
  items.forEach(item => {
    // eslint-disable-next-line
    a += item.prop + ': \'' + item.prop + '\'' + ',\r'
  })
  a += '}'
  console.log(a)
}

export const ba = (text) => ({ breakAfter: text })
export const bb = (text) => ({ breakBefore: text })
export const bbg = (text, icon) => ({ breakBeforeGroup: text, breakBeforeIcon: icon })
export const bbge = (breakAfterGroup) => ({ breakAfterGroup: breakAfterGroup })
export const storageType = (text) => ({ storageType: text })
/** displayCaption */
export const dt = (text) => ({ dividedText: text })
/** displayCaption */
export const dc = (text) => ({ displayCaption: text })
export const showIfKeys = (keys) => ({ showIfKeys: keys })

// IMPORTANT: Settings - defaults
export const propDefaults = (initValue, start, end, slideType, step) => {
  return {
    decimals: { optionType: 'decimal', start: start, end: end, slideType: slideType, step: step, initialValue: initValue, useDecimal: true },
    font: { optionType: 'font', start: .8, end: end ? end : 2, slideType: 'em', step: .05, initialValue: initValue ? initValue : 1 },
    gap: { optionType: 'gap', start: 0, end: 4, slideType: 'em', step: .10, initialValue: initValue },
    height: { optionType: 'height', start: 40, end: 100, slideType: 'px', step: 10, initialValue: initValue },
    numbers: { optionType: 'numbers', start: start, end: end, slideType: slideType ? slideType : 'em', step: step ? step : 1, initialValue: initValue },
    padding: { optionType: 'padding', start: 0, end: 4, slideType: 'em', step: .10, initialValue: initValue },
    radius: { optionType: 'radius', start: 0, end: 30, slideType: 'px', step: 1, initialValue: initValue },
    time: { optionType: 'time', start: 1, end: 10, slideType: 'seconds', initialValue: initValue },
    opacity: { optionType: 'opacity', start: 0, end: 10, initialValue: initValue },
  }
}

export const propDataOpts = (required, formItemType, listType, opts) => {
  const si = {}
  switch (formItemType) {
    case gEnums.formItemTypes.slider:
      listType = listTypes.numberOptions
      break;
    default:
    // nothing
  }
  if (listType) {
    si.options = 'listOptions'
    si.listType = listType
  }
  const x = {
    formItemType: formItemType ? formItemType : gEnums.formItemTypes.text,
    required: required,
    ...si,
    ...opts
  }
  return x
}

export const spp = (prop, propDataOpts, opts) => {
  return app(prop, gEnums.elemPropTypes.normal, gEnums.contentTypes.none, opts, propDataOpts)
}

export const app = (prop, elemPropType, contentType, opts, propDataOpts) => {
  // let allowProp = false
  let viewSettings = {}
  if (viewProps && viewProps[prop]) {
    viewSettings = viewProps[prop]
  } else {
    switch (prop) {
      case 'name':
      case 'firstName':
      case 'lastName':
        // allowProp = true
        break;
      default:
      // nothing
    }
  }

  const dataOpts = {
    ...propDataOpts,
    ...opts
  }

  const _props = {
    prop: prop,
    data: { ...dataOpts },
    ui: {
      caption: _.startCase(prop),
    },
    ...viewSettings
  }

  return _props
}