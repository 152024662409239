import { createRefPath_event } from '../../../global/firestoreData/appData/appRefPaths';
import { fs_set_doc } from '../../../global/firestoreData/appData/fsData';
import { fsfn_sendEmail, fsfn_sendSms } from '../../../global/functions/fbNotifications';
import { fsfn_updateTicketAssignments } from '../../../global/functions/fbTicketing';
import { _globalTicketingName, _useTicketingSubCollection } from '../cnr/reducers/TicketingReducer';

export const updateTicketing = (ticketKey, pathViews, assignedTickets, smsEmailData, tickets, appTicketing, callback) => {

  const { useMeTicketingAllotmentFunction } = appTicketing
  const { smsData, emailData } = smsEmailData ? smsEmailData : {}

  const _refPath = createRefPath_event(pathViews, [_globalTicketingName, ticketKey])

  if (assignedTickets && ticketKey && assignedTickets[ticketKey]) {

    const ticket = tickets && tickets[ticketKey] ? tickets[ticketKey] : {}
    const { name: ticketName } = ticket ? ticket : {}

    if (useMeTicketingAllotmentFunction) {
      fsfn_updateTicketAssignments(pathViews, assignedTickets, callback).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        console.log('success', success, error)
        callback()
      }).catch(error => {
        console.error(error)
      })
    } else {
      const assignedTicket = assignedTickets[ticketKey]
      if (!assignedTicket.ticketName && ticketName) { assignedTicket.ticketName = ticketName }

      if (_useTicketingSubCollection) {
        try {
          try {
            const { userTickets } = assignedTicket ? assignedTicket : {}
            delete assignedTicket.userTickets

            fs_set_doc(_refPath, assignedTicket, true).then(() => {
              if (userTickets) {
                Object.keys(userTickets).forEach(userTicketKey => {
                  const userTicket = userTickets[userTicketKey]
                  const { subTickets } = userTicket ? userTicket : {}
                  if (subTickets) {
                    const updatePath = _refPath + '/userTickets/' + userTicketKey
                    fs_set_doc(updatePath, assignedTicket, true).then(() => {
                      console.log('SUCCESS')
                    }).catch(error => {
                      console.error(error)
                    })
                  }
                })
              }
            }).catch(error => {
              console.error(error)
              callback({ success: true, error })
            })
          } catch (error) {
            console.error(error)
            callback({ success: true, error })
          }
        } catch (error) {
          console.error(error)
          callback({ success: true, error })
        }
      } else {
        try {
          fs_set_doc(_refPath, assignedTicket, true).then(() => {
            if (smsData) { fsfn_sendSms(smsData) }
            if (emailData) { fsfn_sendEmail(emailData) }
            callback({ success: true })
          }).catch(error => {
            console.error(error)
          })
        } catch (error) {
          console.error(error)
          callback({ success: true, error })
        }
      }
    }
  }
}