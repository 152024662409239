import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../../../../global/cnr/reducers/SidebarReducer';
import NoData from '../../../../global/components/alerts/NoData';
import { gEnums } from '../../../../global/enums/globalEnums';
import MenuSidebar from '../../../../global/sidebars/MenuSidebar';
import MenuSidebars from '../../../../global/sidebars/MenuSidebars';
import Wrapper from '../../../../global/wrappers/Wrapper';
import { GolfTournamentContext } from '../../../golf/cnr/contexts/GolfTournamentContext';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { CreateSportsContext } from '../../create/CreateSports';
import PlayerList from './PlayerList';
import StaffList from './StaffList';

const sidebarType = 'sportsAthletes'

const SportsAthletes = (props) => {

  const { athletes, componentContexts, subActionType, displayType } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { eventInfo_state, paps_state } = states

  // componentContexts
  const { uiItemContext } = componentContexts ? componentContexts : {}

  // papsContext 
  const { pathViews } = paps_state ? paps_state : {}

  // golfParentContext
  const golfTournamentContext = useContext(GolfTournamentContext)
  const { golfTournament_state } = golfTournamentContext ? golfTournamentContext : {}
  const { tournamentGolfers } = golfTournament_state ? golfTournament_state : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers, sportsSeason_fns } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info, teams_info, googleLinks_info, roster_gs, roster_gs_home, roster_gs_away } = sportsSeason_state ? sportsSeason_state : {}
  const { gls_teams } = googleLinks_info ? googleLinks_info : {}
  const { teams, rosters: teamRosters_season, staffs } = teams_info ? teams_info : {}
  const { matches, playoffMatches } = matches_info ? matches_info : {}
  const allMatches = { ...matches, ...playoffMatches }

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_current: _currentMatch, currentAthletes } = sportsMatch_state ? sportsMatch_state : {}
  const { results } = _currentMatch ? _currentMatch : {}
  const { players: match_athletes } = results ? results : {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state, createSports_handlers } = createSportsContext ? createSportsContext : {}
  const { rosters, selectedAthletes, nominations } = createSports_state ? createSports_state : {}
  const { levels: level_rosters } = rosters ? rosters : {}

  // uiItemContext 
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem } = item_state ? item_state : {}
  const { dataSource, key: pageItemKey, display } = viewItem ? viewItem : {}
  const { sportsDisplayType } = display ? display : {}
  const { subDataGroup } = dataSource ? dataSource : {}

  const saTypes = {
    athleteOnly: pathViews && (pathViews.athletes || pathViews.tournamentGolfers) ? true : false,
    currentMatchOnly: _currentMatch && _currentMatch._itemKey ? true : false,
    hasSports: pathViews && pathViews.sports ? true : false,
    isTournamentTeam: pathViews && pathViews.tournamentTeams ? true : false,
    matchOnly: pathViews && pathViews.matches ? true : false,
    schoolOnly: pathViews && pathViews.schools ? true : false,
    teamOnly: pathViews && pathViews.teams ? true : false,
  }

  let forSports = false

  if (saTypes.hasSports && !rosters && !saTypes.athleteOnly && !saTypes.teamOnly && !saTypes.schoolOnly && !saTypes.matchOnly && !saTypes.currentMatchOnly) { forSports = true }

  const [_sportsAthletes, set_sportsAthletes] = useState()
  const [_athletes, set_athletes] = useState()
  const [_staff, set_staff] = useState()
  const [_levelRosters, set_levelRosters] = useState()
  const [_awayAthletes, set_awayAthletes] = useState()
  const [_homeAthletes, set_homeAthletes] = useState()
  const [match_current, setCurrentMatch] = useState()
  const [selectedTeam, setSelectedTeam] = useState()

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ? sidebar_state : {}
  const { setInit } = sidebar_handlers ? sidebar_handlers : {}

  const { ms_levels, ms_sections, ms_teams } = sidebar_items ? sidebar_items : {}
  const _levelAthletes = selectedAthletes && ms_levels.selected ? selectedAthletes[ms_levels.selected.item] : null

  let _level_rosters = _levelRosters ? _levelRosters : level_rosters
  if (subActionType === gEnums.availableSubActionTypes.createAllStarTeams && nominations) { _level_rosters = nominations }

  const useChecker = rosters || _levelAthletes || sportsMatch_state

  useEffect(() => {
    if (!saTypes.matchOnly) {
      const _glsTeam = gls_teams && gls_teams[pathViews.teams] ? gls_teams[pathViews.teams] : null
      sportsSeason_handlers.handleGetGsRoster(_glsTeam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (roster_gs_away) {
      set_awayAthletes(roster_gs_away)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [roster_gs_away]);

  useEffect(() => {
    if (roster_gs_home) {
      set_homeAthletes(roster_gs_home)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [roster_gs_home]);

  useEffect(() => {
    if (_level_rosters) {
      const lvs = Object.keys(_level_rosters)
      setInit({ smt: sidebarMenuTypes.one, items: lvs, currents, as: 'levels' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_level_rosters]);

  // updates ms_sections on ms_levels change
  useEffect(() => {
    if (_level_rosters) {
      if (ms_levels && ms_levels.selected) {
        const fa = _level_rosters[ms_levels.selected.item]
        const sections = fa ? _.groupBy(fa, 'sections') : null
        let _sections = sections ? Object.keys(sections) : ['other']
        _sections = _sections.sort()
        setInit({ smt: sidebarMenuTypes.two, items: _sections, currents, as: 'sections' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_levels && ms_levels.selected && ms_levels.selected.item]);

  // updates ms_teams on ms_sections.selected
  useEffect(() => {
    if (_level_rosters) {
      if (ms_levels && ms_levels.selected && ms_sections && ms_sections.selected) {
        const fa = _level_rosters[ms_levels.selected.item]
        const sa = _.filter(fa, { sections: ms_sections.selected.item })
        const teams = sa ? _.groupBy(sa, 'teams') : null
        let _teams = teams ? Object.keys(teams) : ['other']
        _teams = _teams.sort()
        setInit({ smt: sidebarMenuTypes.three, items: _teams, currents, as: 'teams' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_sections && ms_sections.selected && ms_sections.selected.item]);

  // updates _athletes on ms_teams.selected
  useEffect(() => {
    if (_level_rosters) {
      if (ms_teams && ms_teams.selected) {
        const fa = _level_rosters[ms_levels.selected.item]
        let ta = _.filter(fa, { teams: ms_teams.selected.item })
        ta = _.sortBy(ta, 'lastName')
        set_athletes(ta)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_teams && ms_teams.selected && ms_teams.selected.item]);

  // get the athletes based on type
  useEffect(() => {
    console.log('saTypes', saTypes)
    let fa;
    if (saTypes.currentMatchOnly) {
      let cm = allMatches && allMatches[_currentMatch._itemKey] ? allMatches[_currentMatch._itemKey] : null
      if (!cm) { cm = playoffMatches && playoffMatches[_currentMatch._itemKey] ? playoffMatches[_currentMatch._itemKey] : {} }
      if (cm && cm.away && cm.home) {
        sportsSeason_fns.getMatchAthletes(pathViews, '_rosters', cm.away.id, set_awayAthletes, teamRosters_season)
        sportsSeason_fns.getMatchAthletes(pathViews, '_rosters', cm.home.id, set_homeAthletes, teamRosters_season)
      }
      setCurrentMatch(cm)
      setSelectedTeam(cm.away)
    } else {
      if (saTypes.athleteOnly) {
        // ATHLETE
        fa = _.sortBy(athletes, 'lastName')
        if (fa) { set_athletes(fa) }
      } else if (saTypes.teamOnly) {
        // TEAM  
        let teamItems;
        switch (sportsDisplayType) {
          case gEnums.sportsDisplayTypes.staff:
            teamItems = staffs[pathViews.teams]
            fa = _.sortBy(teamItems, 'lastName')
            if (fa) { set_staff(fa) }
            break;
          default:
            teamItems = teamRosters_season[pathViews.teams]
            fa = _.sortBy(teamItems, 'lastName')
            if (fa) { set_athletes(fa) }
        }
      } else if (saTypes.schoolOnly) {
        // SCHOOL
        if (subDataGroup) {
          const sportsAthletes = _.groupBy(athletes, subDataGroup)
          set_sportsAthletes(sportsAthletes)
        }
        fa = _.sortBy(athletes, 'lastName')
        if (fa) { set_athletes(fa) }
      } else if (saTypes.matchOnly) {
        // MATCH
        let cm = allMatches && allMatches[pathViews.matches] ? allMatches[pathViews.matches] : null
        if (!cm) { cm = playoffMatches && playoffMatches[pathViews.matches] ? playoffMatches[pathViews.matches] : {} }
        if (cm && cm.away && cm.home) {
          const _glsTeam_away = gls_teams && gls_teams[cm.away.id] ? gls_teams[cm.away.id] : null
          sportsSeason_handlers.handleGetGsRoster(_glsTeam_away, 'away')

          const _glsTeam_home = gls_teams && gls_teams[cm.home.id] ? gls_teams[cm.home.id] : null
          sportsSeason_handlers.handleGetGsRoster(_glsTeam_home, 'home')

          const _teamRosterA = teamRosters_season[cm.away.id]
          const _teamRosterH = teamRosters_season[cm.home.id]
          set_awayAthletes(_teamRosterA)
          set_homeAthletes(_teamRosterH)
        }
        setCurrentMatch(cm)
        setSelectedTeam(cm.away)
      } else {
        // ALL
        if (forSports) {
          sportsSeason_fns.addTeamInfoToAthletes(athletes)
          const levelz = _.groupBy(athletes, 'levels')
          set_levelRosters(levelz)
        } else {
          if (tournamentGolfers) {
            if (saTypes.isTournamentTeam) {
              const tg = _.filter(tournamentGolfers, { teamNumber: paps_state.viewKey })
              set_athletes(tg)
            } else if (saTypes.athleteOnly) {
              const g = _.find(tournamentGolfers, { key: paps_state.viewKey })
              set_athletes({ g })
            }
          } else {
            fa = _.sortBy(athletes, 'lastName')
            if (fa) { set_athletes(fa) }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [athletes]);

  useEffect(() => {
    if (match_athletes) {
      sportsMatch_handlers.handleSelectAthletes(match_athletes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_athletes]);

  const handleTeamClick = (team) => setSelectedTeam(team)

  const handleCurrentClick = (e, item) => {
    if (createSports_handlers && rosters) {
      const _sa = selectedAthletes[ms_levels.selected.item]
      const siss = { ..._sa }
      if (siss[item.key]) {
        delete siss[item.key]
      } else {
        siss[item.key] = item
      }
      createSports_handlers.handleSelectedAthletes(ms_levels.selected.item, siss)
    } else {
      const sis = { ...currentAthletes }
      if (sis[item.key]) {
        delete sis[item.key]
      } else {
        sis[item.key] = item
      }
      sportsMatch_handlers.handleSelectAthletes(sis)
    }
  }

  const playerList = (plys, teamId, sortProp) => <PlayerList displayType={displayType} saTypes={saTypes} pageItemKey={pageItemKey} athletes={plys} teamId={teamId} useChecker={useChecker} levelAthletes={_levelAthletes} sortProp={sortProp} handlePlayerClick={handleCurrentClick} />
  const staffList = (plys, teamId) => <StaffList displayType={displayType} saTypes={saTypes} pageItemKey={pageItemKey} staff={_staff} teamId={teamId} useChecker={useChecker} levelAthletes={_levelAthletes} handlePlayerClick={handleCurrentClick} />

  const teamCards = () => {
    switch (selectedTeam.id) {
      case match_current.away.id:
        return _awayAthletes ? playerList(_awayAthletes, selectedTeam.id, 'uniformNumber') : <Segment basic>No roster available</Segment>
      case match_current.home.id:
        return _homeAthletes ? playerList(_homeAthletes, selectedTeam.id, 'uniformNumber') : <Segment basic>No roster available</Segment>
      default:
        break;
    }
  }

  const teamsButtonGroups = () => <Button.Group size={'tiny'} >
    <Button color={match_current && selectedTeam === match_current.away ? 'blue' : 'grey'} onClick={() => { handleTeamClick(match_current.away) }}>{match_current.away.name}</Button>
    <Button color={match_current && selectedTeam === match_current.home ? 'blue' : 'grey'} onClick={() => { handleTeamClick(match_current.home) }} >{match_current.home.name}</Button>
  </Button.Group>

  const athletes_sidebar = () => {
    const contents = []
    const menuKeys = []
    Object.keys(_sportsAthletes).forEach(groupKey => {
      const sa = _sportsAthletes[groupKey]
      if (sa && groupKey) {
        const { staticViews } = eventInfo_state ? eventInfo_state : {}
        menuKeys.push(staticViews && staticViews[subDataGroup] && staticViews[subDataGroup][groupKey] ? staticViews[subDataGroup][groupKey].name : groupKey)
        contents.push(playerList(sa))
      }
    })
    return <MenuSidebar prefix={'saa'} menuKeys={menuKeys} segs={contents} />
  }

  const athleteContent = () => {
    if (rosters && _athletes) {
      return playerList(_athletes)
    } else if (_sportsAthletes) {
      return athletes_sidebar()
    } else if (_athletes) {
      return playerList(roster_gs ? roster_gs : roster_gs)
    } else if (_staff) {
      return staffList()
    } else if (saTypes.matchOnly || saTypes.currentMatchOnly) {
      return <Wrapper
        header={selectedTeam && match_current && match_current.away && match_current.home && teamsButtonGroups()}
        content={selectedTeam && match_current && teamCards()}
        css={{ container: 'match-rosters' }}
      />
    } else {
      return <NoData fullCaption={'No Roster'} />
    }
  }

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={athleteContent()}
    dimmed={dimmed}
  />

  const content = () => {
    if (_level_rosters && ms_levels && ms_levels.items && !saTypes.teamOnly) {
      return menuSidebars()
    } else {
      return athleteContent()
    }
  }

  return <Segment basic className={'athletes-container zzz'}>
    {content()}
  </Segment>
}

export default SportsAthletes
//me-mobile-service-account@me-mobile-4410b.iam.gserviceaccount.com