import { sendPasswordResetEmail } from '../../auth/signInActions/resetPasswordEmail';
import { sendPhoneCode } from '../../auth/signInActions/sendPhoneCode';
import { sendSignInLinkToUserEmail } from '../../auth/signInActions/sendSignInLink';
import { signInAppUser } from '../../auth/signInActions/signIn';
import { validateEmail } from '../../validation/validation';
import { gEnums } from '../../enums/globalEnums';
import { getAppUserProfile } from '../../firestoreData/profiles/getProfile';
import { fsfn_getProfileAuth, fsfn_updateAuthPasswordAndSignin } from '../../functions/fbAuth';
import { grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';
import { signInResponseData, getSignInResponse, updateSignInResponse } from "./reducerHelpers/signInProps";
import { createAccount } from '../../auth/signInActions/createAccount';
import { sendSignInGoogleLink } from '../../auth/signInActions/setGoogleLink';

// https://support.google.com/firebase/answer/7000714?authuser=1
// https://www.edwardbeazer.com/sending-email-with-firebase-functions-and-sendgrid/

export const sirts = {
  handleAmmend_profileData: 'handleAmmend_profileData',
  handleBack: 'handleBack',
  handleCancelSignIn: 'handleCancelSignIn',
  handleCloseConfirmation: 'handleCloseConfirmation',
  handleCloseNotification: 'handleCloseNotification',
  handleConfirmPasswordChange: 'handleConfirmPasswordChange',
  handleCreateAccount: 'handleCreateAccount',
  handleDirectSendSignInLinkToEmail: 'handleDirectSendSignInLinkToEmail',
  handleGetProfile: 'handleGetProfile',
  handleReset: 'handleReset',
  handleSendGoogleLink: 'handleSendGoogleLink',
  handleSendPasswordResetEmail: 'handleSendPasswordResetEmail',
  handleSendPhoneCode: 'handleSendPhoneCode',
  handleSendSignInLinkToEmail: 'handleSendSignInLinkToEmail',
  handleSet_authData: 'handleSet_authData',
  handleSet_profileData: 'handleSet_profileData',
  handleShowForgotPassword: 'handleShowForgotPassword',
  handleShowLinkConfirmation: 'handleShowLinkConfirmation',
  handleShowResetPassword: 'handleShowResetPassword',
  handleSignIn: 'handleSignIn',
  handleSignInChange: 'handleSignInChange',
  handleSignInMethods: 'handleSignInMethods',
  handleSignInMethodType: 'handleSignInMethodType',
  handleSignInResponse: 'handleSignInResponse',
  handleSignInType: 'handleSignInType',
  signInGoBack: 'signInGoBack',
  ...grts
}

export const signInMethodTypes = {
  email: 'email',
  emailLink: 'emailLink',
  facebook: 'facebook',
  google: 'google',
  phone: 'phone',
  twitter: 'twitter',
}

export const signInComponentTypes = {
  emailLink: 'emailLink',
  emailReset: 'emailReset',
  tooManyAttempts: 'tooManyAttempts',
  confirmPasswordChange: 'confirmPasswordChange',
  resetPasswordConfirmation: 'resetPasswordConfirmation',
  forgotPassword: 'forgotPassword',
}

export const signInTypes = {
  signIn: 'signIn',
  createAccount: 'createAccount'
}

export const linkConfirmationTypes = {
  email: 'email',
  phone: 'phone',
}

const resetState = () => {
  return {
    confirmationResult: null,
    creds: null,
    hasAuth: false,
    hasProfile: false,
    authData: null,
    profileData_signIn: null,
    profileData: null,
    signInComponentType: null,
    signInFormType: gEnums.signInFormTypes.signInEmail,
    signInResponse: null,
  }
}

export const signInReducer = (state, action) => {

  const { baseProps, profileData_signIn, creds, hasAuth, hasProfile, signInFormType, passwordConfirmation } = state ? state : {}
  const { pathViews, sharePath, appSignIn, appUserCollection, logging } = baseProps ? baseProps : {}
  const { forceAuthValidation } = appSignIn ? appSignIn : {}

  const { dispatch, type } = action

  const { handleSignInResponse, handleAmmend_profileData, handleSet_authData, handleSet_profileData } = signInHandlers(dispatch)

  console.log('signInReducer', type, action)

  switch (type) {

    case sirts.handleSignInChange:
      // updates the signInData on change of the inputs
      if (action && action.signInData && action.signInData.email) {
        action.signInData.email = action.signInData.email.toLowerCase()
      }

      return {
        ...state,
        updating: false,
        creds: action.signInData
      }

    case sirts.handleCancelSignIn:
      if (state.cancelMethod) { state.cancelMethod() }
      return { ...state }

    case sirts.handleSignInType:
      return { ...state, signInType: state.signInType === signInTypes.signIn ? signInTypes.createAccount : signInTypes.signIn }

    // loads the signInMethodTypes to the ui
    case sirts.handleSignInMethods:
      const items = []
      const { allowEmail, allowFacebook, allowGoogle, allowPhone, allowTwitter, allowEmailLink, useEmailLinkOnly } = action.appSignIn ? action.appSignIn : {}
      if (allowEmail && !useEmailLinkOnly) { items.push({ name: signInMethodTypes.email, icon: 'mail', color: 'blue' }) }
      if (allowEmail && allowEmailLink) { items.push({ name: signInMethodTypes.emailLink, icon: 'send', color: 'blue' }) }
      if (allowFacebook) { items.push({ name: signInMethodTypes.facebook, icon: 'facebook', color: 'blue' }) }
      if (allowGoogle) { items.push({ name: signInMethodTypes.google, icon: 'google', color: 'blue' }) }
      if (allowPhone) { items.push({ name: signInMethodTypes.phone, icon: 'phone', color: 'blue' }) }
      if (allowTwitter) { items.push({ name: signInMethodTypes.twitter, icon: 'twitter', color: 'blue' }) }
      let sim;
      switch (items.length) {
        case 0:
          sim = signInMethodTypes.email
          break;
        case 1:
          sim = items[0]
          break;
        default:
          // nothing
          break;
      }
      return {
        ...state,
        signInMethods: items,
        signInMethod: sim,
        cancelMethod: action.cancelMethod,
        ...resetState()
      }

    case sirts.handleSignInMethodType:
      let simt;
      switch (action.mt) {
        case signInMethodTypes.email:
          simt = gEnums.signInProviderTypes.emailPassword
          break;
        case signInMethodTypes.emailLink:
          simt = gEnums.signInProviderTypes.emailLink
          break;
        case signInMethodTypes.phone:
          simt = gEnums.signInProviderTypes.phoneNumber
          break;
        case signInMethodTypes.google:
          simt = gEnums.signInProviderTypes.google
          break;
        default:
          simt = null
      }
      return {
        ...state,
        signInMethodType: simt,
        ...resetState()
      }

    case sirts.handleReset:
      return {
        ...state,
        ...resetState()
      }

    case sirts.handleGetProfile:
      // get the profile and auth information from firebase 

      if (creds.email) { creds.email = creds.email.toLowerCase().trim() }

      // check email 
      const _validEmail = validateEmail(creds.email)

      if (!_validEmail) {
        // bad email
        const rd = signInResponseData(null, null, null, signInResponseTypes.invalidEmail)
        handleSignInResponse(rd)
        return { ...state }
      } else {
        getAppUserProfile(creds, pathViews, appUserCollection, logging, handleAmmend_profileData)
        return {
          ...state,
          updating: true,
          fetchingCaption: 'Searching',
          passwordConfirmation: false,
          signInResponse: null,
          hasAuth: false
        }
      }

    case sirts.handleAmmend_profileData:
      if (action.profileData && action.profileData.email) {
        // profile found. Get the auth...
        if (forceAuthValidation) {
          fsfn_getProfileAuth(action, handleSet_authData)
        } else {
          handleSet_profileData()
        }
        // 
        return { ...state, profileData: action.profileData, appUserData: action.appUserData }
      } else {
        handleSet_profileData(action.opts)
        return { ...state, hasProfile: false, authData: null, profileData: null, profileData_signIn: null, authProviders: null, appUserData: null }
      }

    case sirts.handleSet_authData:
      if (action.authData) {
        handleSet_profileData()
        return { ...state, authData: action.authData, appUserData: action.appUserData }
      } else {
        handleSet_profileData()
        return { ...state, authData: null, appUserData: action.appUserData }
      }

    // this sets the profile
    case sirts.handleSet_profileData:
      updateSignInResponse(state.profileData, state.authData, action, handleSignInResponse)
      return { ...state }

    case sirts.handleCreateAccount:
      const caProps = { handleSignInResponse, creds, appSignIn }
      createAccount(caProps)
      return { ...state }

    // handles the sign in.
    case sirts.handleSignIn:
      const siProps = { callback: handleSignInResponse, hasAuth, hasProfile, creds, pathViews, allowSignUp: false, appUserCollection, appSignIn, signInData: profileData_signIn, passwordConfirmation }
      signInAppUser(siProps)
      return { ...state, updating: true, fetchingCaption: 'Signing in', signInResponse: null }

    case sirts.handleShowLinkConfirmation:
      return {
        ...state,
        signInComponentType: state.signInComponentType === signInComponentTypes.emailLink ? null : signInComponentTypes.emailLink,
        confirmationType: action.confirmationType
      }

    case sirts.handleDirectSendSignInLinkToEmail:
      // send the email (handleSignInResponse)
      if (creds.email) {
        creds.email = creds.email.toLowerCase().trim()
        const _sharePath = action.sp ? action.sp : sharePath
        sendSignInLinkToUserEmail(creds.email, _sharePath, true, handleSignInResponse)
      }
      return { ...state }

    case sirts.handleSendSignInLinkToEmail:
      // send the email (handleSignInResponse)
      // update the users record,
      // then send the email
      const _creds = creds ? creds : {}
      if (!_creds.email && action.email) { _creds.email = action.email }
      if (_creds.email) { _creds.email = _creds.email.toLowerCase().trim() }
      sendSignInLinkToUserEmail(_creds.email, sharePath, true, handleSignInResponse)
      return { ...state, creds: _creds }

    case sirts.handleSendPhoneCode:
      sendPhoneCode(action.phoneNumber, action.recaptcha, handleSignInResponse)
      creds.phoneNumber = action.phoneNumber
      return { ...state, creds }

    case sirts.handleSendPasswordResetEmail:
      const { email: email_action } = action ? action : {}
      const { email: email_creds } = creds ? creds : {}
      const _email = email_action ? email_action : email_creds
      if (_email) {
        sendPasswordResetEmail(_email, handleSignInResponse)
      }
      return { ...state }

    case sirts.handleSendGoogleLink:
      sendSignInGoogleLink()
      return { ...state }

    case sirts.handleShowForgotPassword:
      return { ...state, signInComponentType: state.signInComponentType === signInComponentTypes.forgotPassword ? null : signInComponentTypes.forgotPassword }

    case sirts.handleShowResetPassword:
      return { ...state, show_resetPassword: !state.show_resetPassword }

    case sirts.handleCloseNotification:
      return {
        ...state,
        signInNotification: null,
      }

    case sirts.handleConfirmPasswordChange:
      const data = { email: creds.email, password: creds.password }
      let respp = {
        ...state,
        success: true,
        signInResponseType: signInResponseTypes.signInPending
      }

      fsfn_updateAuthPasswordAndSignin(data, profileData_signIn, pathViews, hasAuth).then(async res => {
        const { success, signInResponse } = res
        if (success === true) {
          respp = { success: true, signInResponseType: signInResponseTypes.signInSuccess }
        } else {
          if (signInResponseTypes[signInResponse]) {
            respp = { success: false, signInResponseType: signInResponse }
          } else {
            respp = { success: false, signInResponseType: signInResponseTypes.unknownError }
          }
        }
        handleSignInResponse(respp)
        return respp
      })

      return { ...state }

    // handles the response from the sign in method
    case sirts.handleSignInResponse:

      const { responseData } = action

      const pd = responseData && responseData.profileData_signIn ? responseData.profileData_signIn : profileData_signIn
      const hp = pd ? true : false

      const resp = {
        ...state,
        updating: false,
        hasProfile: hp,
        hasAuth: hasAuth ? hasAuth : responseData.hasAuth,
        passwordConfirmation: responseData.passwordConfirmation,
        profileData_signIn: responseData.profileData_signIn ? responseData.profileData_signIn : profileData_signIn,
        signInResponseType: responseData.signInResponseType,
        authProviders: responseData.authProviders ? responseData.authProviders : state.authProviders,
        confirmationResult: responseData.confirmationResult,
        // signInComponentType: null
      }

      // philipaokeeffe@gmail.com

      switch (responseData.signInResponseType) {
        case signInResponseTypes.emailResetSent:
          // resp.signInComponentType = signInComponentTypes.forgotPassword
          break;
        case signInResponseTypes.emailLinkSent:
          resp.signInComponentType = signInComponentTypes.emailLink
          break;
        case signInResponseTypes.phoneCodeSent:
          break;
        case signInResponseTypes.profileFound:
          resp.signInFormType = gEnums.signInFormTypes.signInPassword
          break;
        case signInResponseTypes.handlePasswordAlert:
          resp.signInComponentType = signInComponentTypes.confirmPasswordChange
          break;
        case signInResponseTypes.tooManyAttempts:
          resp.signInComponentType = signInComponentTypes.tooManyAttempts
          break;
        default:
          resp.signInFormType = signInFormType
        // nothing
      }

      // wssm23
      // suzanne.schmitz@securian.com

      if (responseData.passwordConfirmation) {
        resp.signInConfirmation = getSignInResponse(responseData, state, pd, appSignIn)
      } else {
        resp.signInResponse = getSignInResponse(responseData, state, pd, appSignIn)
      }

      return resp

    case sirts.handleCloseConfirmation:
      return { ...state, passwordConfirmation: null }

    // case sirts.handleCloseConfirmation:
    case sirts.handleFunctionResponse:
    case sirts.handleStartUpdate:
    case sirts.updateError:
    case sirts.updateSuccess:
    case sirts.updateSuccessAlt:
      return responseReducers(state, action)

    default:
      return {
        ...state,
      }
  }
}

export const signInReducerInitialState = (init_state) => {

  const { paps_state, aps_global, aps_appUserSettings, signInMethodType } = init_state
  let signInFormType = gEnums.signInFormTypes.signInEmail

  const { appSignIn, logging } = aps_global ? aps_global : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}
  const { pathViews, sharePath } = paps_state ? paps_state : {}

  const baseProps = { appSignIn, appUserCollection, pathViews, sharePath, logging }

  return {
    baseProps,
    creds: {},
    hasProfile: false,
    show_resetPassword: false,
    signInMethodType,
    signInResponse: null,
    signInFormType: signInFormType,
    signInType: signInTypes.signIn
  }
};

export const signInHandlers = (dispatch) => {
  return {
    handleAmmend_profileData: (profileData, appUserData, opts) => { dispatch({ type: sirts.handleAmmend_profileData, dispatch, profileData, appUserData, opts }) },
    handleSet_authData: (authData, profileData, appUserData) => { dispatch({ type: sirts.handleSet_authData, dispatch, authData, profileData, appUserData }) },
    handleBack: () => { dispatch({ type: sirts.signInGoBack }) },
    handleCancelSignIn: () => { dispatch({ type: sirts.handleCancelSignIn, dispatch }) },
    handleCloseConfirmation: () => { dispatch({ type: sirts.handleCloseConfirmation }) },
    handleCloseNotification: () => { dispatch({ type: sirts.handleCloseNotification }) },
    handleConfirmPasswordChange: () => { dispatch({ type: sirts.handleConfirmPasswordChange }) },
    handleCreateAccount: () => { dispatch({ type: sirts.handleCreateAccount, dispatch }) },
    handleDirectSendSignInLinkToEmail: (sp) => { dispatch({ type: sirts.handleDirectSendSignInLinkToEmail, dispatch, sp }) },
    handleFormDataChange_local: (signInData) => { dispatch({ type: sirts.handleSignInChange, signInData }) },
    handleGetProfile: () => { dispatch({ type: sirts.handleGetProfile, dispatch }) },
    handleSet_profileData: (opts) => { dispatch({ type: sirts.handleSet_profileData, dispatch, opts }) },
    handleReset: () => { dispatch({ type: sirts.handleReset }) },
    handleSendGoogleLink: () => { dispatch({ type: sirts.handleSendGoogleLink }) },
    handleSendPasswordResetEmail: (email) => { dispatch({ type: sirts.handleSendPasswordResetEmail, dispatch, email }) },
    handleSendPhoneCode: (phoneNumber, recaptcha) => { dispatch({ type: sirts.handleSendPhoneCode, dispatch, phoneNumber, recaptcha }) },
    handleSendSignInLinkToEmail: (email) => { dispatch({ type: sirts.handleSendSignInLinkToEmail, dispatch, email }) },
    handleShowForgotPassword: () => { dispatch({ type: sirts.handleShowForgotPassword }) },
    handleShowLinkConfirmation: (confirmationType) => { dispatch({ type: sirts.handleShowLinkConfirmation, dispatch, confirmationType }) },
    handleShowResetPassword: () => { dispatch({ type: sirts.handleShowResetPassword }) },
    handleSignIn: () => { dispatch({ type: sirts.handleSignIn, dispatch }) },
    handleSignInMethods: (appSignIn, cancelMethod) => { dispatch({ type: sirts.handleSignInMethods, appSignIn, cancelMethod }) },
    handleSignInMethodType: (mt) => { dispatch({ type: sirts.handleSignInMethodType, dispatch, mt }) },
    handleSignInResponse: (responseData) => { dispatch({ type: sirts.handleSignInResponse, dispatch, responseData }) },
    handleSignInType: () => { dispatch({ type: sirts.handleSignInType }) },
    ...responseHandlers(dispatch)
  }
}

export const signInResponseTypes = {
  authFound: 'authFound',
  authNotFound: 'authNotFound',
  confirmation: 'confirmation',
  createAuthError: 'createAuthError',
  createAuthSuccess: 'createAuthSuccess',
  emailLinkNotSent: 'emailLinkNotSent',
  emailLinkSent: 'emailLinkSent',
  emailResetNotSent: 'emailResetNotSent',
  emailResetSent: 'emailResetSent',
  handlePasswordAlert: 'handlePasswordAlert',
  handlePasswordAlertConfirmed: 'handlePasswordAlertConfirmed',
  incorrectPassword: 'incorrectPassword',
  invalidEmail: 'invalidEmail',
  invalidPassword: 'invalidPassword',
  phoneCodeSent: 'phoneCodeSent',
  phoneCodeNotSent: 'phoneCodeNotSent',
  profileError: 'profileError',
  profileFound: 'profileFound',
  profileNotFound: 'profileNotFound',
  signInError: 'signInError',
  signInPassword: 'signInPassword',
  signInPending: 'signInPending',
  signInSuccess: 'signInSuccess',
  signInWithEmailSuccess: 'signInWithEmailSuccess',
  signUpComfirmationError: 'signUpComfirmationError',
  signUpEmailSent: 'signUpEmailSent',
  signUpEmailSentError: 'signUpEmailSentError',
  signUpError: 'signUpError',
  signUpSuccessWithProfileCreated: 'signUpSuccessWithProfileCreated',
  testing: 'testing',
  tooManyAttempts: 'tooManyAttempts',
  unknownError: 'unknownError',
} 