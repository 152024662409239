import React, { createContext, useContext } from 'react'
import { separateContexts } from '../../common/filtering';
import { FilterContext } from './FilterContext';
import { FrameworkContext } from './FrameworkContent';
import { PageAreaContext } from './PageAreaContext';
import { ParentContext } from './ParentContext';

export const GenericContext = createContext();

const GenericProvider = (props) => {

  const parentContext = useContext(ParentContext)
  const pageAreaContext = useContext(PageAreaContext)
  const filterContext = useContext(FilterContext)
  const frameworkContext = useContext(FrameworkContext)

  const contexts = {
    pageAreaContext,
    parentContext,
    filterContext,
    frameworkContext,
  }

  const _parentFilters = {
    stateFilers: [],
    propFilters: ['pageItemsShown', 'profileItems', 'settingsPreviewHasChanged', 'desktopMode', 'view', 'viewKey', 'formatting', 'pageSettings', 'desktopMobileOn']
  }

  const generic_contexts = separateContexts(contexts, _parentFilters)

  return <GenericContext.Provider value={{ generic_contexts }}>
    {props.children}
  </GenericContext.Provider>
}

export default GenericProvider