import React, { useContext, useEffect, useState } from 'react';
import AppUserList from '../appUsers/AppUserList';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { fsfn_getClientProfiles } from '../functions/fbAuth';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';

export const CreateTopics = (props) => {

  const { selectedItems } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state } = states

  // papsContext 
  const { pathViews } = paps_state ? paps_state : {}

  const [emails, setEmails] = useState();
  const [profiles, setProfiles] = useState();
  const [updating, setUpdating] = useState();

  useEffect(() => {
    if (selectedItems) {
      const _emails = []
      Object.keys(selectedItems).forEach(key => {
        if (selectedItems[key].email) {
          _emails.push(selectedItems[key].email)
        }
      })
      setEmails(_emails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleProfileResult = (result) => {
    setProfiles(result)
    setUpdating(false)
  }

  const handleFetchProfiles = () => {
    setUpdating(true)
    fsfn_getClientProfiles(pathViews, emails, handleProfileResult)
  }

  const handleTopic = () => {
    console.log('emails', emails);
  }

  const footer = () => <UiSaveButtons
    preview={{ caption: 'Fetch Profiles', oc: handleFetchProfiles }}
    save={{ caption: 'Update', oc: handleTopic, disabled: !profiles }}
  ></UiSaveButtons>

  const wrapper = () => <Wrapper
    content={selectedItems ? <AppUserList items={selectedItems} showIcons={true} /> : <div></div>}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
    updatingCaption={'fetching'}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={props.handleCancel}
    topperCaption={'Create Topics'}
    isBack={true}
  />

  return fullPageWrapper()
};
