export const addIdsToDataItem = (firstData) => {
  if (firstData) {
    Object.keys(firstData).forEach(key => {
      const dataItem = firstData[key]
      dataItem.id = key
      dataItem._itemKey = key
    })
  }
}

export const addParentKeysToDataItem = (parentKeys, firstData) => {
  if (firstData && parentKeys) {
    Object.keys(firstData).forEach(key => {
      const dataItem = firstData[key]
      Object.keys(parentKeys).forEach(pk => {
        dataItem[pk] = parentKeys[pk]
      })
    })
  }
} 