import React, { createContext, useContext, useReducer } from 'react';

import { framworkHandlers, framworkReducer, framworkReducerInitialState } from '../reducers/FrameworkReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { PapsContext } from './PapsContext';

export const FrameworkContext = createContext();

/**
 * 
 * @param {object} props 
 * @returns a simple FrameworkContext providing framework_state and framework_handlers
 */
const FrameworkProvider = (props) => {

  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ? papsContext : {}
  const { view, pathViews } = paps_state ? paps_state : {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ? appSettingsContext : {}
  const { desktopOn, desktopMobileOn } = appSettings_state ? appSettings_state : {}

  const init_state = { ...props, view, pathViews }
  const [framework_state, pageArea_dispatch] = useReducer(framworkReducer, framworkReducerInitialState(init_state));
  const framework_handlers = framworkHandlers(pageArea_dispatch)

  return <FrameworkContext.Provider value={{ framework_state: { ...framework_state, desktopMode: desktopOn, desktopMobileOn: desktopMobileOn }, framework_handlers }}>
    {props.children}
  </FrameworkContext.Provider>
};

export default FrameworkProvider