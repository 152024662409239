import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { SettingParentContext } from '../cnr/contexts/SettingParentContext';
import { SettingsAreaContext } from '../cnr/contexts/SettingsAreaContext';
import { ViewSettingsContext } from '../cnr/contexts/ViewSettingsContext';
import { viewSettingsComponentHandlers, viewSettingsComponentInitialState, viewSettingsComponentReducer } from '../cnr/reducers/ViewSettingsComponentReducer';
import { uniqueKey } from '../common/keys';
import { altStyles } from '../styles/styles';
/**
 * Toggles with a swipe between settings
 * @param {*} props 
 * @returns SwipeableViews with the swipedElements within the component_state
 */
const ViewSettingsSwiper = (props) => {

  const { trueSolo } = props ? props : {}

  const settingsParentContext = useContext(SettingParentContext)
  const { states, settings } = settingsParentContext ? settingsParentContext : {}
  const { global_state, appSettings_state } = states ? states : {}
  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging } = homeSettings_global ? homeSettings_global : {}
  const { settings_globalPageItems } = global_state ? global_state : {}
  const { pageSettingsOn, pageSettingsPage } = appSettings_state ? appSettings_state : {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext)
  const { settingsArea_state } = settingsAreaContext ? settingsAreaContext : {}
  const { isSolo } = settingsArea_state ? settingsArea_state : {}

  // viewSettingsContext 
  const viewSettingsContext = useContext(ViewSettingsContext);
  let { keyy, viewSettings_state } = viewSettingsContext ? viewSettingsContext : {}
  let { selectedItem, startSwipeItems, settings_temp, settingsStatus } = viewSettings_state

  if (!keyy) {
    if (selectedItem && selectedItem.key) {
      keyy = 'sgwc-' + selectedItem.key
    } else {
      keyy = 'sgwc'
    }
  }

  const swipeToSelected = (swipeProps) => vsc_handlers.swipeToItem(swipeProps)

  const addHelp = (viType) => {
    if (viType) {
      vsc_handlers.addHelp(viType)
    } else {
      vsc_handlers.addHelp()
    }
  }

  const componentContexts = { settingsParentContext, settingsAreaContext, viewSettingsContext }
  const initProps = { componentContexts, logging, addHelp, swipeToSelected, settings_globalPageItems, startSwipeItems }
  const [vsc_state, component_dispatch] = useReducer(viewSettingsComponentReducer, viewSettingsComponentInitialState(initProps));
  const vsc_handlers = viewSettingsComponentHandlers(component_dispatch)

  // viewSettings_state contains the DATA for the currentView settings
  // component_state contains the COMPONENTS for the currentView settings 
  // SwipeableViews is a wrapper with no internal settings data

  // swipedElements are already formed at this point.
  const { swipedElements, selectedIndex } = vsc_state

  useEffect(() => {
    settings_temp && vsc_handlers.updateSettingsTemp(settings_temp)
    // eslint-disable-next-line
  }, [settings_temp, settingsStatus]);

  useEffect(
    () => {
      if (pageSettingsOn) {
        const si = {
          caption: _.startCase(pageSettingsPage),
          isActive: true,
          key: pageSettingsPage,
          show: true,
          showDesktop: true,
          vit: pageSettingsPage
        }
        const swipeProps = { swipedItem: si }
        console.log('swipeProps', swipeProps)
        swipeToSelected(swipeProps)
      }
    },
    // update if appUser or settingsDocName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [pageSettingsOn]
  )

  const handleSwipeChangeIndex = (index) => vsc_handlers.setSelectedIndex(index)

  const swipeableContent = () => <SwipeableViews
    className={'sv-data vss'}
    key={uniqueKey('vss', 'sv')}
    enableMouseEvents
    index={selectedIndex}
    disabled={isSolo || trueSolo}
    threshold={2}
    hysteresis={2.5}
    onChangeIndex={handleSwipeChangeIndex}
    // onTransitionEnd={grid_handlers.onTransitionEnd}
    style={Object.assign({}, altStyles.swipeArea)}
  >
    {swipedElements}
  </SwipeableViews>

  return swipeableContent()

}

export default ViewSettingsSwiper