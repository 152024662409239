import React from 'react'
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper'
import NoData from '../../../components/alerts/NoData'
import { Divider } from 'semantic-ui-react'
import Checker from '../../../components/forms/elements/Checker'
import MenuSelect from '../../../components/menus/MenuSelect'

const _listSize = 'mini'

const UiDataListSelect = (props) => {

  const { list, handleSelect, selectedItems, linkedItems, selectedOnly, checkAllow, allowed, caption, allowCheckAll, count } = props
  const { fullMode, selectedKeyLeft, handleSelectRights } = props

  /**
    * allowed, listSize, checkAllow, handleSelect, ignoreKey, linkedItems, linkedKey, menuList, selectedItems, selectedOnly
    * @param {object} list 
    * @param {function} handleSelect 
    * @param {object} selectedItems 
    * @param {object} linkedItems 
    * @param {boolean} selectedOnly 
    * @param {boolean} checkAllow 
    * @param {*} allowed 
    * @returns 
    */
  const menuSelect = () => <MenuSelect
    allowed={allowed}
    checkAllow={checkAllow}
    // dataLinkType={dataLinkType}
    // handleQuantity={handleQuantity}
    handleSelect={handleSelect}
    ignoreKey={!fullMode}
    linkedItems={linkedItems}
    linkedKey={selectedKeyLeft}
    // linkProps={linkProps}
    listSize={_listSize}
    menuList={list}
    selectedItems={selectedItems}
    selectedOnly={selectedOnly}
  />

  const listItemHeader = (caption, allowCheckAll) => {
    if (allowCheckAll) {
      return <React.Fragment>
        <Divider horizontal>{caption}</Divider>
        {selectedKeyLeft && <Checker
          label={'Select All'}
          handle_click={handleSelectRights}
          item={{ key: 'selectAll' }} />}
      </React.Fragment>
    } else {
      return <Divider horizontal>{caption}</Divider>
    }
  }
  /**
   * 
   * @returns a MenuSelect inside a wrapper
   */
  const listItemWrapper = () => {
    let allow = selectedOnly ? selectedItems && Object.keys(selectedItems).length > 0 : list && list.length > 0
    const _caption = count ? caption + ' (' + count + ')' : caption
    return allow ? <Wrapper
      header={_caption ? listItemHeader(_caption, allowCheckAll) : null}
      content={menuSelect(list, handleSelect, selectedItems, linkedItems, selectedOnly, checkAllow, allowed)}
      wrapperType={wrapperTypes.linking}
    /> : <NoData fullCaption={'None Selected'} />
  }

  return listItemWrapper()

}

export default UiDataListSelect