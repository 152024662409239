import { collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, query, where } from "firebase/firestore";
import _ from 'lodash';
import { _globalCollectionName } from "../../../projectSpecific/sports/dbActions/globals";
import { convertSnapshot } from "../../cnr/contexts/contextHelpers";
import { isOdd } from "../../common/filtering";
import { doc_set } from "./fsData";
import { createRefPath, createRefPath_client, createRefPath_event } from "./appRefPaths";

const _showFsLogs = false
const _showFsErrorLogs = true

export const _dataOptions = {
  ignoreId: 'ignoreId',
  listen: 'listen',
  returnFirstObject: 'returnFirstObject'
}

export const createFsDocKey = (keyType, collectionKey) => {
  const fs = getFirestore()
  const ssd = doc(collection(fs, 'generic'));
  console.log('createFsDocKey', keyType, collectionKey)
  return ssd.id
}

export const arrayString = (arr) => {
  let path = ''
  arr.forEach((item, index) => {
    path += item
    if (index < arr.length - 1) {
      path += '/'
    }
  })
  return path
}

export const _fs = () => {
  return {
    fs_get_rootData
  }
}

const _callbackOpts = (data, cbProps, ref, cbData, error) => {
  return {
    data,
    cbProps,
    ref,
    cbData,
    error
  }
}


/**
 * 
 * @param {string} collectionName the name of collection to retrieve
 * @param {boolean} listen 
 * @param {string} docKey 
 * @param {array} filters 
 * @param {array} pathViewFilters 
 * @param {funtion} callback 
 * @description Gets data from the `root` level
 */
export const fs_get_rootData = (collectionName, listen, docKey, filters, pathViewFilters, callback) => {

  let _refPath = createRefPath([collectionName])
  const wheres = []

  if (filters) {
    filters.forEach(whereProp => {
      wheres.push(where(whereProp.prop, '==', whereProp.value))
    })
  } else if (pathViewFilters) {
    pathViewFilters.forEach(whereProp => {
      wheres.push(where('pathViews.' + whereProp.prop, '==', whereProp.value))
    })
  } else if (docKey) {
    _refPath = createRefPath([docKey], _refPath)
  }

  if (listen) {
    fs_get_data({ refPath: _refPath, opts: { returnFirstObject: true }, callback })
  } else {
    fs_get_data({ refPath: _refPath, opts: { returnFirstObject: true }, callback })
  }
}

/**
 * 
 * @param {object} pathViews 
 * @param {string} collectionName 
 * @param {funtion} callback 
 * @param {boolean} listen 
 * @description Gets data from a global collection at the `events` level
 */
export const fs_get_eventData_global = (pathViews, collectionName, returnFirstObject, callback, listen, cbProps) => {
  let _refPath = createRefPath_event(pathViews, [_globalCollectionName, collectionName])
  fs_get_data({ refPath: _refPath, callback, opts: { ignoreId: true, listen, returnFirstObject, cbProps } })
}

/** 
 * @param {object} props (refPath, wheres, callback, opts, cbData, cbo, fs)
 * @props {string} refPath
 * @props {array} wheres
 * @props {function} callback (data, cbProps, ref, cbData, error) 
 * @props {object} opts (cbProps, listen, ignoreId)
 * @returns gets date from firestore
 * @description options
 */

export const fs_get_data = async (props) => {

  const { refPath, wheres, callback, opts, fs } = props
  const { listen } = opts ? opts : {}

  if (!refPath) { return {} }

  const _fs = fs ? fs : getFirestore()
  const ps = _.isArray(refPath) ? arrayString(refPath) : refPath
  const count = ps.split('/').length
  const isCollection = isOdd(count) ? true : false

  // do we have a refPath
  if (refPath && refPath.length > 0) {
    // are there any wheres
    if (wheres && wheres.length > 0) {
      if (isCollection) {
        // collection query
        if (callback || listen) {
          return collection_listen_query(_fs, ps, wheres, callback, opts)
        } else {
          return collection_get_query(_fs, ps, wheres, opts)
        }
      } else {
        // document query
        if (callback || listen) {
          return doc_listen(_fs, ps, callback, opts)
        } else {
          return doc_get(_fs, ps, opts)
        }
      }
    } else {
      if (isCollection) {
        // collection
        if (callback || listen) {
          return collection_listen(_fs, ps, callback, opts)
        } else {
          return collection_get(_fs, ps, opts)
        }
      } else {
        // document 
        if (callback || listen) {
          return doc_listen(_fs, ps, callback, opts)
        } else {
          return doc_get(_fs, ps, opts)
        }
      }
    }
  }
}

/**
 * 
 * @param {*} pathViews 
 * @param {*} collectionName 
 * @param {*} callback 
 * @param {*} opts {cbProps, clientPath, dataParents, docKey, filters, forPromise, forPromise, listen}
 * @returns data from fs_get_data 
 */
export const fs_get_data_all = (pathViews, collectionName, callback, opts) => {

  const { cbProps, clientPath, dataParents, docKey, filters, forPromise, sportsKey } = opts ? opts : {}

  const wheres = []

  let _refPath;
  if (pathViews.events && !clientPath) {
    _refPath = createRefPath_event(pathViews, [collectionName])
  } else if (pathViews.clients) {
    _refPath = createRefPath_client(pathViews, [collectionName])
  } else {
    _refPath = [collectionName]
  }

  if (filters) {
    filters.forEach(whereProp => {
      wheres.push(where(whereProp.prop, '==', whereProp.value))
    })
  } else if (docKey) {
    _refPath += '/' + docKey
  }

  if (dataParents) {
    dataParents.forEach(dp => {
      if (pathViews[dp]) {
        switch (dp) {
          case 'sports':
            if (sportsKey) {
              wheres.push(where('parentKeys.' + dp, '==', sportsKey))
            } else {
              wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            }
            break;
          default:
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
        }
      }
    })
  }

  if (forPromise) {
    return doc_set(_refPath)
  } else {
    fs_get_data({ refPath: _refPath, wheres, callback, opts, cbProps })
  }
}

/** returns firestore data from `getDoc` and converts the data using convertSnapshot
 * @param prom - the promise
 * @param {object} opts - the options(uivi, isCombinedData, ignoreId, returnFirstObject)
 */
export const get_docs = async (prom, opts) => {
  const data = await getDoc(prom);
  const ssd = convertSnapshot(data, true, opts)
  _showFsLogs && console.log('get_docs', ssd)
  return ssd
}

/**
 *  @param {object} fs - the firestore object. This can be null.
 * @param {string or array} ps - the path
 * @param {object} opts - the options(uivi, isCombinedData, ignoreId, returnFirstObject)
 * @returns firestore data from `getDoc` and converts the data using convertSnapshot
 */
const doc_get = async (fs, ps, opts) => {
  const _fs = fs ? fs : getFirestore()
  if (ps.startsWith('/')) { ps = ps.substring(1) }
  try {
    if (ps && ps.split('/').length > 1 && !isOdd(ps.split('/'))) {
      const ref_d = doc(_fs, ps)
      if (opts && opts.refOnly) { return ref_d }
      const data = await getDoc(ref_d);
      const ssd = convertSnapshot(data, true, opts)
      _showFsLogs && console.log('doc_get', ps, ssd)
      return ssd
    }
  } catch (error) {
    _showFsErrorLogs && console.error('error', error)
  }
}

/** returns firestore data from `onSnapshot` and converts the data using convertSnapshot
 * @param {object} fs - the firestore object. This can be null.
 * @param {string or array} ps - the path
 * @param cbProps - callback props that will be passed into the callback function
 * @param {object} opts - the options(uivi, isCombinedData, ignoreId, returnFirstObject)
 * @description - this HAS a callback. The data will NOT returned
 */
const doc_listen = async (fs, ps, callback, opts) => {
  const { cbData, cbProps, cbo, refOnly, returnFsr } = opts ? opts : {}
  const _fs = fs ? fs : getFirestore()
  // listen
  const ref_d = doc(_fs, ps)
  if (refOnly) { return ref_d }
  onSnapshot(ref_d, (snapshot) => {
    const ssd = convertSnapshot(snapshot, true, opts)
    _showFsLogs && console.log('doc_listen', ps, ssd, opts)
    // data, cbProps, ref, cbData, error 
    if (callback) {
      if (cbo) {
        callback(_callbackOpts(ssd, cbProps, returnFsr ? ref_d : null, cbData))
      } else {
        callback(ssd, cbProps, returnFsr ? ref_d : null, cbData)
      }
    }
    return ssd
  },
    (error) => {
      _showFsErrorLogs && console.error('error', error)
      if (callback) {
        if (cbo) {
          callback(_callbackOpts({}, cbProps, returnFsr ? ref_d : null, cbData, error))
        } else {
          callback({}, cbProps, returnFsr ? ref_d : null, cbData, error)
        }
      }

    });
}

/**
 * 
 * @param {object} fs - the firestore object. This can be null.
 * @param {string or array} ps - the path
 * @param {object} opts 
 * @returns 
 */
const collection_get = async (fs, ps, opts) => {
  const { refOnly } = opts ? opts : {}
  const _fs = fs ? fs : getFirestore()
  const ref_c = collection(_fs, ps)
  if (refOnly) { return ref_c }
  const data = await getDocs(ref_c);
  const ssd = convertSnapshot(data, false, opts)
  _showFsLogs && console.log('collection_get', ps, ssd, opts)
  return ssd
}

const collection_get_query = async (fs, ps, wheres, opts) => {
  const { dataLimit, refOnly } = opts ? opts : {}
  const _fs = fs ? fs : getFirestore()
  const ref_c = collection(_fs, ps)
  const q = dataLimit ? query(ref_c, ...wheres, limit(dataLimit)) : query(ref_c, ...wheres)
  if (refOnly) { return q }
  const data = await getDocs(q)
  const ssd = convertSnapshot(data, false, opts)
  if (_showFsLogs) {
    _showFsLogs && console.log('collection_get', ps, wheres, opts)
    showQ(q)
  }
  return ssd
}

const collection_listen = (fs, ps, callback, opts) => {
  const { cbData, cbProps, cbo, dataLimit, refOnly, returnFsr } = opts ? opts : {}
  const _fs = fs ? fs : getFirestore()
  const ref_c = collection(_fs, ps)
  const q = dataLimit ? query(ref_c, limit(dataLimit)) : ref_c
  if (refOnly) { return ref_c }
  onSnapshot(q, (snapshot) => {
    const ssd = convertSnapshot(snapshot, false, opts)
    if (_showFsLogs) {
      _showFsLogs && console.log('collection_listen', ps, ssd, opts)
      showQ(q)
    }
    if (callback) {
      if (cbo) {
        callback(_callbackOpts(ssd, cbProps, returnFsr ? ref_c : null, cbData))
      } else {
        callback(ssd, cbProps, returnFsr ? ref_c : null, cbData)
      }
    }
    return ssd
  },
    (error) => {
      _showFsErrorLogs && console.error('error', ps, error)
      if (callback) {
        if (cbo) {
          callback(_callbackOpts({}, cbProps, returnFsr ? ref_c : null, cbData, error))
        } else {
          callback({}, cbProps, returnFsr ? ref_c : null, cbData, error)
        }
      }
      return {}
    });
}

/** Returns a collection (listen, query)  */
const collection_listen_query = async (fs, ps, wheres, callback, opts) => {
  const { cbData, cbProps, cbo, dataLimit, refOnly, returnFsr } = opts ? opts : {}
  const _fs = fs ? fs : getFirestore()
  const ref_c = collection(_fs, ps)
  const q = dataLimit ? query(ref_c, ...wheres, limit(dataLimit)) : query(ref_c, ...wheres)
  if (refOnly) { return q }
  onSnapshot(q, (snapshot) => {
    const ssd = convertSnapshot(snapshot, false, opts)
    if (_showFsLogs) {
      _showFsLogs && console.log('collection_listen_query', ps, ssd, wheres, opts)
      showQ(q)
    }
    if (callback) {
      if (cbo) {
        callback(_callbackOpts(ssd, { ...cbProps }, returnFsr ? q : null))
      } else {
        callback(ssd, { ...cbProps }, returnFsr ? q : null)
      }
    }

    return ssd
  },
    (error) => {
      _showFsErrorLogs && console.error('error', ps, error)
      if (callback) {
        if (cbo) {
          callback(_callbackOpts({}, cbProps, returnFsr ? ref_c : null, cbData, error))
        } else {
          callback({}, cbProps, returnFsr ? ref_c : null, cbData, error)
        }
      }
      return {}
    });
}

const showQ = (q) => {
  const { _query } = q
  const { filters } = _query ? _query : {}
  if (filters) { showF(filters) }
}

const showF = (filters) => {
  filters.forEach((f, index) => {
    const { field, op, value } = f
    const { segments } = field ? field : {}
    const { stringValue, arrayValue } = value ? value : {}
    const { values } = arrayValue ? arrayValue : {}
    if (stringValue) {
      console.log('_filter', index, segments, op, stringValue);
    } else {
      console.log('_filter', index, segments, op, values);
    }
  })
}