import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import CircleLoader from '../../../motions/CircleLoader';

const PendingWait = (props) => {
  const { inverted, startType, styleAndClass } = props ? props : {}
  const _styleAndClass = styleAndClass ? styleAndClass : {}
  return <Segment className={'vs-seg pending-wait'} inverted={inverted} placeholder {..._styleAndClass}>
    <Header as='h3' icon>
      <CircleLoader startType={startType} />
    </Header>
  </Segment>
}

export default PendingWait