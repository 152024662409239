import React from 'react';
import { Label } from 'semantic-ui-react';
import { sortObject } from '../../global/common/sorting';
import DragDropper, { dragDropperTypes } from '../../global/dragNDrops/DragDropper';
import { PlayerItem } from '../../projectSpecific/sports/elements/athletes/PlayerList';

export const SimpleDnD = (props) => {

  const { dataItems, handleUpdatedList, additionalProps, ddType } = props

  const handleSorted = (sortedGroups) => {
    // convert back to objects
    if (sortedGroups) {
      const newVld = {}
      Object.keys(sortedGroups).forEach(key => {
        const dataItems = sortedGroups[key].dataItems
        Object.keys(dataItems).forEach(k => {
          const dataItem = dataItems[k]
          const { id, key } = dataItem
          newVld[key ? key : id] = dataItem
        })
      })
      handleUpdatedList({ data: newVld })
    }
  }

  const numberLabel = (index, text) => <Label><Label color={'blue'} >{index}</Label>{text}</Label>

  const menuMenuItems = (dataItems) => {
    const ds = []
    Object.keys(dataItems).forEach((key, index) => {
      const di = dataItems[key]
      switch (ddType) {
        case dragDropperTypes.playerList:
          ds.push(<PlayerItem player={di} />)
          break;
        default:
          const { name, firstName, lastName } = di
          let cn = 'drag-number'
          let apt = firstName && lastName ? firstName + ' ' + lastName : name;
          if (additionalProps) {
            apt += ' ('
            additionalProps.forEach((ap, index) => {
              if (di[ap]) {
                apt += di[ap]
                if (index < additionalProps.length - 1) { apt += '/' }
              }
            })
            apt += ')'
          }
          ds.push(<div className={cn}>{numberLabel(index + 1, apt)}</div>)
          break;
      }
    })
    return ds
  }

  const dd = () => {
    const ddGroups = {}
    const elements = menuMenuItems(dataItems)
    ddGroups['items'] = { elements: elements, dataItems }
    const dd_groups = sortObject(ddGroups, 'key')

    let portalCn = 'msmi four menu'

    switch (ddType) {
      case dragDropperTypes.playerList:
        portalCn += ' athletes-container'
        break;
      default:
        break;
    }

    return <DragDropper
      ddType={dragDropperTypes.simple}
      ddGroups={dd_groups}
      handleSorted={handleSorted}
      allowSort={true}
      portalCn={portalCn}
    />
  }

  return dd()

}
