import _ from 'lodash'
import { UiSaveButtons } from 'paoke-common-controls'
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, Menu, Segment, Sidebar } from 'semantic-ui-react'
import { gEnums } from '../../enums/globalEnums'
import SemFileViewer from '../sem/SemFileViewer'
import { uniqueKey } from '../../common/keys'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import UiDataListSelect from '../../pageItem/modification/dataModifications/UiDataListSelect'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { listListO } from '../../lists/lists'
import { createKeys } from '../../common/creating'

const _caption = 'File Managment'

const FileManagement = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { eventInfo_state, paps_state, page_state } = states
  const { staticViews, globals } = eventInfo_state ? eventInfo_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_page, aps_viewItems } = pageSettings ? pageSettings : {}
  const { viewItems: viewItems_page } = aps_page ? aps_page : {}

  const [mode, setMode] = useState()
  const [captions, setCaptions] = useState({ topper: 'File Management', topper2: 'Sports Data' })
  const [activeMenuItems, setActiveMenuItems] = useState({})
  const [storageType, setStorageType] = useState()
  const [selectionOptions, setSelectionOptions] = useState()
  const [selectedCollection, setSelectedCollection] = useState()
  const [currentList, setCurrentList] = useState()

  useEffect(() => {
    let _menuItems = {}
    Object.keys(gEnums.storageTypes).forEach(k => {
      switch (k) {
        case gEnums.storageTypes.manifest:
          break;
        default:
          _menuItems[k] = k
      }
    })
    setStorageType(gEnums.storageTypes.floormap)
    setCaptions({ topper: _caption, topper2: _.startCase(gEnums.storageTypes.floormap) })
    setActiveMenuItems(_menuItems)
    setSelectionOptions(listListO(aps_viewItems))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCollection && staticViews && staticViews[selectedCollection]) {
      const sv = staticViews[selectedCollection]
      createKeys(sv)
      setCurrentList(_.sortBy(sv, ['name']))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection]);

  const handleMode = (adm) => setMode(adm)

  const handleDataMode = (adm) => {
    const _adm = adm ? adm : gEnums.storageTypes.floormap
    setStorageType(_adm)
    console.log('_adm', _adm)
    setCaptions({ topper: _caption, topper2: _.startCase(_adm) })
    setMode()
  }

  const handleDataSelect = (e, data) => setSelectedCollection(data.value)

  const modeMenu = () => {
    const mis = []
    Object.keys(activeMenuItems).forEach(key => {
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', key)} onClick={() => { handleDataMode(key) }}>{_.startCase(key)}</Menu.Item>)
    })
    return <Segment basic><Menu vertical fluid inverted className={'av-menu'}>{mis}</Menu></Segment>
  }

  const VerticalSidebarLeft = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv-settings'
      onHide={() => handleMode()}
    >
      {modeMenu()}
    </Sidebar>
  )

  const modeButtons = () => {
    const btns = [
      { caption: _.startCase(storageType), oc: handleMode, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={'black'}
      rows={2}
    />
  }

  const header_file = () => <div className={'header-flex'}>
    {modeButtons()}
  </div>

  const fileViewer = () => {
    const formProps = {

    }
    return <SemFileViewer
      allowUpload={true}
      formProps={formProps}
      storageType={storageType}
      fileMode={gEnums.fileModes.view}
      fromFileManagement={true}
    />
  }

  const listItemWrapper = (handleSelect, selectedItems, linkedItems, selectedOnly, checkAllow, allowed, caption, allowCheckAll, count) => {
    const prps = { list: currentList, handleSelect, selectedItems, linkedItems, selectedOnly, checkAllow, allowed, caption, allowCheckAll, count }
    // const prpsg = { fullMode, selectedKeyLeft, handleSelectRights }
    // const _prps = { ...prps, ...prpsg }
    return <UiDataListSelect {...prps}></UiDataListSelect>
  }

  const header_list = () => <Dropdown
    placeholder={_.startCase('Collection')}
    fluid
    selection
    options={selectionOptions ? selectionOptions : []}
    value={selectedCollection}
    onChange={handleDataSelect} />

  const wrapper_list = () => <Wrapper
    header={header_list()}
    content={storageType && listItemWrapper()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ container: 'file-management' }}
  />

  const wrapper_file = () => <Wrapper
    header={header_file()}
    content={storageType && fileViewer()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    css={{ container: 'file-management' }}
  />

  const content_split = () => <div className={'split-list'}>
    {wrapper_file()}
    {wrapper_list()}
  </div>

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <VerticalSidebarLeft visible={mode ? true : false} />
    <Sidebar.Pusher dimmed={mode ? true : false} className={'h100'}>
      {content_split()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  return sidebar()

}

export default FileManagement
