import _ from 'lodash';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { Button, Icon, Image, List, Ref, Segment, Select, Visibility } from 'semantic-ui-react';
import { uniqueKey } from '../common/keys';
import PendingSeg from '../components/alerts/pendings/PendingSeg';
import Checker from '../components/forms/elements/Checker';
import { eventStatusIcon } from '../components/props/propsCreate';
import { PageContext } from '../cnr/contexts/PageContext';
import { gEnums } from '../enums/globalEnums';
import { listList } from '../lists/lists';
import { dataModificationTypes } from '../viewSettings/enums/itemActionTypes';
import { iconColorTypes } from '../enums/settingsIconTypes';
import { _find } from '../common/filtering';
import { createKeys } from '../common/creating';

const scrollProps = {
  scrollIncrement: 25,
  useScroll: false
}

/** Simple list containing the data items of a View 
 * @onClick viewDataSwiper_handlers.handleLinkData
 * @onClick viewDataSwiper_handlers.handleGroupData
 * @onClick viewDataSwiper_handlers.handleDataListClick
 * 
*/
const DataList = (props) => {

  const showImgs = false

  const { dataModificationType, selectedItems, listActionType, listData, isList, handleAmmend, handleSelected, inApp, ignoreInverted, showSelectedOnly } = props
  const { scrollIncrement, useScroll } = scrollProps

  // pageContext
  const pageContext = useContext(PageContext);
  const { aps_global, aps_viewItems, page_fns } = pageContext
  const { settingsOptions } = aps_global ? aps_global : {}
  const { useDarkMode } = settingsOptions ? settingsOptions : {}

  let viewItem = null

  const viewItem_global = aps_viewItems && viewItem && aps_viewItems[viewItem.key] ? aps_viewItems[viewItem.key] : null

  const { display: display_g } = viewItem_global ? viewItem_global : {}
  const { sortProp: sortProp_g } = display_g ? display_g : {}

  let _useDarkMode = useDarkMode
  if (inApp) { _useDarkMode = false }
  if (ignoreInverted) { _useDarkMode = false }

  switch (listActionType) {
    case gEnums.listActionTypes.delete:
      _useDarkMode = false
      break;
    default:
    // nothing
  }

  // ref
  const contextRef = createRef()

  const getViewableList = (scrolled) => {
    let ld;
    if (listData) {
      createKeys(listData)
      if (scrolled) {
        const l = Object.values(listData)
        const nvld = []
        l.slice(0, dataCount).forEach((item, i) => {
          nvld.push(item);
        });
        ld = nvld
      } else {
        if (isList) {
          ld = Object.keys(listData)
        } else {
          ld = Object.values(listData)
        }
      }
    }
    if (ld) { ld = _.sortBy(ld, sortProp_g ? sortProp_g : 'name') }
    return ld ? ld : []
  }

  // local state
  const [fullList] = useState(getViewableList())
  const [viewableVld, setViewableVld] = useState()
  const [dataCount, setDataCount] = useState(scrollIncrement ? scrollIncrement : 10)
  const [optsList, setOptsList] = useState()

  useEffect(() => {
    setViewableVld(getViewableList(useScroll))
    setOptsList(listList(gEnums.eventStatusTypes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  useEffect(() => {
    if (fullList && useScroll && dataCount) {
      const nvld = []
      fullList.slice(0, dataCount).forEach((item, i) => {
        nvld.push(item);
      });
      setViewableVld(nvld)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCount]);

  // handlers
  const handleScroll = (e, value) => {
    if (useScroll) {
      if (value.calculations.bottomVisible) {
        if (dataCount <= fullList.length + 1) {
          let count = dataCount + scrollIncrement
          if (count > fullList.length + 1) { count = fullList.length + 1 }
          setDataCount(count)
        }
      }
    }
  }

  const handlePageClick = (e, item) => {
    e.stopPropagation()
    page_fns.pushSimple({ key: viewItem.key }, item.key)
  }

  const handleClick = (item, checked) => {
    // const key = isList ? item : item.key
    // if (showDirectDataLinking) {
    // viewDataSwiper_handlers.handleLinkData(viewItem.key, key, checked)
    // } else if (showGroupDataLinking) {
    // viewDataSwiper_handlers.handleGroupData(viewItem.key, key, checked)
    // } else {
    // viewDataSwiper_handlers.handleDataListClick(key)
    // }
  }

  const handleCheckClick = (e, item, checked) => {
    e.stopPropagation()
    if (handleSelected) { handleSelected(item, checked) }
  }

  const handleStatusChange = (e, data) => {
    e.stopPropagation()
    const { item, value } = data ? data : {}
    const { key } = item ? item : {}
    const _viewableVld = [...viewableVld]
    const _item = _find(_viewableVld, 'key', key) // _.find(_viewableVld, { key: key })
    if (_item) {
      _item.eventStatusType = value
      setViewableVld(_viewableVld)
      if (handleAmmend) { handleAmmend(_viewableVld) }
    }
  }

  const listItemItem = (checker, gotoPageIcon) => <div className={'list-item-container'}>
    <div>{checker}</div>
    {gotoPageIcon && <div>{gotoPageIcon}</div>}
  </div>

  const imageListItem = (item, index, headerValue, iconName, checked) => <List.Item key={uniqueKey('dl', index)} onClick={e => handleClick(item, checked)}>
    <List.Content floated='right'>
      <Button icon style={{ backgroundColor: 'inherit' }}>
        <Icon color={iconColorTypes.menuItem} name={iconName} />
      </Button>
    </List.Content>
    <Image
      avatar
      src='https://react.semantic-ui.com/images/avatar/small/lena.png'
    />
    <List.Content>{headerValue}</List.Content>
  </List.Item>

  const listItem_checker = (item, index, gotoPageIcon, checked, checker) => <List.Item key={uniqueKey('dl', index)} onClick={e => handleClick(item, checked)}>
    <List.Content>{listItemItem(checker, gotoPageIcon)}</List.Content>
  </List.Item>

  const listItem_emailLink = (item, index, headerValue, emailValue, checker, checked) => <List.Item key={uniqueKey('dl', index)} onClick={e => handleClick(item, checked)}>
    <List.Content floated='left' >{checker}</List.Content>
    <List.Content>{headerValue}</List.Content>
    <List.Content>{emailValue}</List.Content>
  </List.Item>

  const listItem_delete = (item, index, headerValue, checker) => <List.Item key={uniqueKey('dl', index)} >
    <List.Content>{listItemItem(checker)}</List.Content>
  </List.Item>

  const listItem_status = (item, index, headerValue, select) => {
    const { name, color } = eventStatusIcon(item, null, true)
    return <List.Item key={uniqueKey('dl', index)} >
      <List.Icon name={name} size='large' color={color} verticalAlign='middle' />
      <List.Content>
        <List.Header style={{ paddingTop: '.5em', paddingBottom: '.5em' }} >{headerValue}</List.Header>
        <List.Description >{listItemItem(select)}</List.Description>
      </List.Content>
    </List.Item>
  }

  const select = (item, status) => <Select
    key={uniqueKey('dl', 's', item.key)}
    placeholder={'Status'}
    value={status}
    options={optsList && optsList}
    onChange={handleStatusChange}
    item={item}
  />

  const listItem = (item, index) => {

    let headerValue;
    let emailValue
    let iconName; // = dataLinkIcon
    let iconColor = 'blue'
    let status;
    let checked = false

    if (item.title) { headerValue = item.title }
    if (item.lastName && item.firstName) { headerValue = item.firstName + ' ' + item.lastName }
    if (item.name) { headerValue = item.name }
    if (item.email) { emailValue = item.email }
    if (item.eventStatusType) { status = item.eventStatusType }

    if (!headerValue) { headerValue = item.key }
    if (isList && !headerValue) { headerValue = item }
    if (isList && headerValue) { headerValue = _.startCase(headerValue) }

    if (!headerValue && item.email) {
      headerValue = item.email
      item.key = item.email
    }

    if (selectedItems) { checked = selectedItems[item.key] }

    let show = true

    if (showSelectedOnly && !checked) {
      show = false
    }

    if (show) {
      if (showImgs) {
        return imageListItem(item, index, headerValue, iconName, checked)
      } else {
        switch (dataModificationType) {
          case dataModificationTypes.status:
            const _select = select(item, status)
            return listItem_status(item, index, headerValue, _select)
          default:
            const _checker = <Checker item={item} checked={checked} label={headerValue} handle_click={handleCheckClick} />
            const _gotoPageIcon = <List.Icon name={'external square alternate'} color={iconColor} onClick={e => handlePageClick(e, item)} />
            switch (listActionType) {
              case gEnums.listActionTypes.emailSignInLink:
                return listItem_emailLink(item, index, headerValue, emailValue, _checker)
              case gEnums.listActionTypes.delete:
                return listItem_delete(item, index, headerValue, _checker, checked)
              default:
                return listItem_checker(item, index, _gotoPageIcon, checked, _checker)
            }
        }
      }
    }
  }

  const listItems = () => {
    // eslint-disable-next-line  
    return viewableVld.map((item, index) => {
      if (item.key !== 'id') {
        return listItem(item, index)
      }
    })
  }

  const viewList = () => {
    let cn = 'data-list-list'
    if (showImgs) { cn += 'imgz' }
    // relaxed
    if (viewableVld && viewableVld.length) {
      return <List relaxed divided className={cn} inverted={_useDarkMode} verticalAlign='middle'>
        {listItems()}
      </List>
    } else {
      return <Segment basic inverted={_useDarkMode}>
        {'No Items Found'}
      </Segment>
    }

  }

  const visibleList = () => {
    return <Ref innerRef={contextRef}>
      <Visibility offset={[10, 10]} onUpdate={handleScroll} style={{ width: '100%' }}>
        {viewList()}
      </Visibility>
    </Ref>
  }

  const content = () => {
    if (useScroll) {
      return visibleList()
    } else {
      return viewList()
    }
  }

  if (viewableVld) {
    return <div className='data-list-container' >
      {content()}
    </div>
  } else {
    return PendingSeg(14)
  }
}

export default DataList