import { getFbConfig } from '../../../project/fbConfiguration';
import { _settingsFs } from '../actions/getSettings';

export const handleOpenSettingsDb = (pathViews, nonLandingView) => {

  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  let url = fbDbLink(projectId, true)
  let ok = true

  switch (nonLandingView) {
    case 'home':
      url += nonLandingView
      break;
    case 'clients':
      if (pathViews.clients) {
        url += pathViews.clients
      } else {
        url += nonLandingView
      }
      break;
    case 'events':
      if (pathViews.events) {
        url += pathViews.events
      } else {
        url += nonLandingView
      }
      break;
    default:
      if (pathViews.events) {
        url += pathViews.events
      } else {
        ok = false
      }
  }
  if (ok) { window.open(url, '_blank') }
}

export const handleOpenDb = (pathViews, forStorage, viewItemKey, viewKey) => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  const tween = '~2F'
  let url = fbDbLink(projectId, null, forStorage)
  if (pathViews && pathViews.clients) { url += tween + 'clients' + tween + pathViews.clients }
  if (pathViews && pathViews.events) { url += tween + 'events' + tween + pathViews.events }
  if (viewItemKey) { url += tween + viewItemKey }
  if (viewKey) { url += tween + viewKey }
  window.open(url, '_blank')
}
export const handleOpenCredentials = () => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  let url = fbCredentialsLink(projectId)
  let ok = true
  if (ok) { window.open(url, '_blank') }
}

export const handleOpenFunctions = () => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  let url = fbFunctionsLink(projectId)
  let ok = true
  if (ok) { window.open(url, '_blank') }
}

export const handleOpenStorage = () => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  let url = fbStorageLink(projectId)
  let ok = true
  if (ok) { window.open(url, '_blank') }
}

export const openFirebaseAuth = () => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  let url = fbDbLink(projectId, false, false, true)
  window.open(url, '_blank')
}

export const openCloudStorageConsole = (pathViews) => {
  let url = googleCloudStorageLink(pathViews)
  window.open(url, '_blank')
}

export const openCloudDataConsole = () => {
  let url = googleCloudDataLink()
  window.open(url, '_blank')
}

export const openFirebaseDb = (paps_state, currentVit, currentKey, forSettings, forStorage, forAuth) => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  const { pathViews } = paps_state
  const tween = '~2F'
  let url = fbDbLink(projectId, forSettings, forStorage, forAuth)
  if (pathViews && pathViews.clients) { url += tween + 'clients' + tween + pathViews.clients }
  if (pathViews && pathViews.events) { url += tween + 'events' + tween + pathViews.events }
  url += tween + currentVit
  if (currentKey) { url += tween + currentKey }
  window.open(url, '_blank')
}

export const openGoogleSheets = (googleSheetsId) => {
  const urlSheetsLink = 'https://docs.google.com/spreadsheets/d/' + googleSheetsId
  window.open(urlSheetsLink, '_blank')
}

export const openGoogleForm = (googleSheetsId) => {
  const urlSheetsLink = 'https://docs.google.com/forms/d/' + googleSheetsId
  window.open(urlSheetsLink, '_blank')
}

export const fbDbLink = (projectId, forSettings, forStorage, forAuth) => {
  const tween = '~2F'
  let url = 'https://console.firebase.google.com/u/1/project/' + projectId
  if (forStorage) {
    url += '/storage/' + projectId + '.appspot.com/files/'
  } else if (forAuth) {
    url += '/authentication/users/'
  } else {
    url += '/firestore/data/'
    if (forSettings) { url += tween + _settingsFs.root + tween }
  }
  return url
}

export const fbCredentialsLink = (projectId) => {
  // let url = 'https://console.cloud.google.com/apis/credentials'
  let url = 'console.cloud.google.com/apis/credentials?authuser=1&project=' + projectId
  return url
}

export const fbFunctionsLink = (projectId) => {
  let url = 'https://console.firebase.google.com/u/1/project/' + projectId + '/functions'
  return url
}

export const fbStorageLink = (projectId) => {
  let url = 'https://console.firebase.google.com/u/1/project/' + projectId + '/storage/'
  return url
}

export const fbServiceLink = (projectId, openApi) => {
  if (openApi) {
    return 'https://console.cloud.google.com/apis/credentials?authuser=1&' + projectId + '&authuser=1'
  } else {
    return 'https://console.cloud.google.com/identity/serviceaccounts?project=' + projectId + '&authuser=1'
  }
}

export const googleCloudStorageLink = (pathViews) => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  let url = 'https://console.cloud.google.com/storage/browser/' + projectId + '.appspot.com/'
  if (pathViews && pathViews.clients) { url += 'clients/' + pathViews.clients }
  if (pathViews && pathViews.events) { url += '/events/' + pathViews.events }
  url += '?authuser=1&cloudshell=true&project=' + projectId + '&pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&prefix=&forceOnObjectsSortingFiltering=false'
  return url
}

export const googleCloudDataLink = () => {
  const trueConfig = getFbConfig()
  const { projectId } = trueConfig
  let url = 'https://console.cloud.google.com/firestore/export?authuser=1&cloudshell=true&project=' + projectId
  return url
}