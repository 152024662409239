import { gEnums } from "../../enums/globalEnums"
import { updateFirestoreData } from "../../firestoreData/updates/dataUpdates"
import { responseReducers, grts } from "./reducerHelpers/dispatchProps"

const rts = {
  addSimpleData: 'addSimpleData',
  ammendExisting: 'ammendExisting',
  deleteData: 'deleteData',
  updateData: 'updateData',
  ...grts
}

export const simpleDataReducer = (state, action) => {

  const { paps_state, page_fns, onEnd, appUser } = state
  const { type, dataCollection, itemData, itemKey, dispatch } = action

  switch (type) {

    case rts.addSimpleData:
    case rts.ammendExisting:
    case rts.deleteData:
    case rts.updateData:

      const ufProps = {
        itemData,
        vit: dataCollection,
        appUser
      }

      switch (type) {
        case rts.addSimpleData:
          ufProps.dataUpdateType = gEnums.dataUpdateTypes.add
          ufProps.viewKey = itemKey
          break;
        case rts.ammendExisting:
          ufProps.dataUpdateType = gEnums.dataUpdateTypes.ammendExisting
          ufProps.viewKey = itemKey
          break;
        case rts.deleteData:
          ufProps.dataUpdateType = gEnums.dataUpdateTypes.delete
          ufProps.viewKey = itemData.itemKey
          break;
        default:
          ufProps.dataUpdateType = gEnums.dataUpdateTypes.edit
          break;
      }
      if (ufProps.dataUpdateType) {
        updateFirestoreData(dispatch, paps_state, ufProps, page_fns)
      }
      return {
        ...state,
        dataCollection,
        updating: true
      }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      if (type === rts.updateSuccess) {
        if (onEnd) { onEnd() }
      }
      return responseReducers(state, action, { updating: false })


    default:
      return { ...state }
  }
}

export const simpleDataInitialState = (paps_state, onEnd, page_fns, appUser) => {
  return { paps_state, onEnd, page_fns, appUser }
};

export const simpleDataHandlers = (dispatch) => {
  return {
    addSimpleData: (dataCollection, itemData) => { dispatch({ type: rts.addSimpleData, dispatch, dataCollection, itemData }) },
    ammendExisting: (dataCollection, itemData) => { dispatch({ type: rts.ammendExisting, dispatch, dataCollection, itemData }) },
    deleteData: (dataCollection, itemData) => { dispatch({ type: rts.deleteData, dispatch, dataCollection, itemData }) },
    updateData: (dataCollection, itemData, itemKey) => { dispatch({ type: rts.updateData, dispatch, dataCollection, itemData, itemKey }) },
  }
} 