export const convertCanvasToFile = (canvas) => {
  try {
    let dataURL = null
    dataURL = canvas.toDataURL("image/png");
    if (dataURL) {
      const blobBin = atob(dataURL.split(',')[1]);
      const array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new Blob([new Uint8Array(array)], { type: 'image/png' });
      return file
    }
  } catch (error) {
    console.error('There has been a error converting the canvas to a file ' + error)
    return null
  }
}

export const convertToFile = (canvas) => {
  return new Promise(function (resolve, reject) {
    try {
      let dataURL = null
      dataURL = canvas.toDataURL("image/png");
      if (dataURL) {
        const blobBin = atob(dataURL.split(',')[1]);
        const array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        const file = new Blob([new Uint8Array(array)], { type: 'image/png' });
        resolve(file)
      }
    } catch (error) {
      console.error('There has been a error converting the canvas to a file ' + error)
      return null
    }
  })

}

export const getFileFromUrl = (url, fileName) => {

  const s_fileName = fileName ? fileName.split('.') : []
  let type = { type: 'image/png' }

  if (s_fileName && s_fileName[1]) {
    if (s_fileName[1].indexOf('gif') >= 0) { type = { type: 'image/gif' } }
    if (s_fileName[1].indexOf('jpg') >= 0) { type = { type: 'image/jpg' } }
  }

  return new Promise(function (resolve, reject) {
    if (url === null) return reject();
    const image = new Image();
    image.src = url;
    let url1 = new URL(url);
    const file1 = new File([url1], fileName ? fileName : "name", type);
    const _blob = getBlobUrl(file1)
    const filee = new File([_blob], fileName ? fileName : "name", type);
    resolve(filee)
  });
}

export const getFileFromUrll = async (url, fileName) => {

  const s_fileName = fileName ? fileName.split('.') : []
  let type = { type: 'image/png' }

  if (s_fileName && s_fileName[1]) {
    if (s_fileName[1].indexOf('gif') >= 0) { type = { type: 'image/gif' } }
    if (s_fileName[1].indexOf('jpg') >= 0) { type = { type: 'image/jpg' } }
  }

  if (url === null) return null;

  let url1 = new URL(url);
  const file1 = new File([url1], fileName ? fileName : "name", type);
  const blob = getBlobUrl(file1)
  const filee = new File([blob], fileName ? fileName : "name", type);
  return filee
}

const getBlobUrl = (obj) => {
  const x = URL.createObjectURL(obj)
  return x
}

export const getUrlFile = async (url, fileName) => {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  };

  try {
    let response = await fetch(url, requestOptions)
    console.log('response', response)
  } catch (error) {
    console.error(error)
  }
}