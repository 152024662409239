import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { gEnums } from '../../enums/globalEnums';
import { storageHandlers, storageInitialState, storageReducer } from '../reducers/StorageReducer';
import { AppUserContext } from './AppUserContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** Global Files (Icon, Image, PDF) for the Event taken from the storage */
export const StorageContext = createContext();

/** Global Files (Icon, Image, PDF) for the Event taken from storage */
const StorageProvider = (props) => {

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ? appUserContext : {}
  const { appUser } = appUser_state ? appUser_state : {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { rootPaths, settingsDocName, pathViews } = paps_state ? paps_state : {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { projectOptions, storageOptions } = aps_global ? aps_global : {}
  const { useUnderscorePrefix } = projectOptions ? projectOptions : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  let _storageRootPath;

  if (pathViews.events) {
    _storageRootPath = rootPaths.events.substring(1)
  } else if (pathViews.clients) {
    _storageRootPath = rootPaths.clients.substring(1)
  }

  // reducer : splashScreen, banner, banners,
  const init_state = { pathViews, storageRootPath: _storageRootPath, appUser, useUnderscorePrefix, appUserCollection, storageOptions }
  const [storage_state, storageDispatch] = useReducer(storageReducer, storageInitialState(init_state));
  const storage_handlers = storageHandlers(storageDispatch, storage_state)
  const { globalFiles } = storage_state ? storage_state : {}

  const storage_fns = {
    getIconUrl: (allow, fileName) => {
      if (allow && globalFiles && fileName && globalFiles.icon && globalFiles.icon[fileName]) {
        return globalFiles.icon[fileName]
      }
    },
    // LOOK
    getImageUrl: (fileName, fullImage, storageType, callback) => {
      if (globalFiles && globalFiles[storageType] && globalFiles[storageType][fileName]) {
        const { urls } = globalFiles[storageType][fileName]
        const { full, thumbnail } = urls ? urls : {}
        callback({ url: fullImage ? full : thumbnail })
      } else {
        callback()
      }
    },
    getImageUrls: (app_banners, bannerUrlSize, storageType, callback) => {
      let { banners } = app_banners ? app_banners : {}
      if (globalFiles && globalFiles[storageType]) {
        const _bannerUrls = []
        const _banners = _.isArray(banners) ? banners : [banners]
        if (_banners) {
          _banners.forEach(banner => {
            if (globalFiles[storageType][banner]) {
              const { urls } = globalFiles[storageType][banner]
              const { full, thumbnail } = urls ? urls : {}
              _bannerUrls.push(bannerUrlSize === gEnums.banner ? full : thumbnail)
            }
          })
          callback({ urls: _bannerUrls })
        }
      } else {
        callback()
      }
    },
  }

  // run this if settingsDocName changes
  useEffect(
    () => {
      storage_handlers.handleGet_globalFiles()
    },
    // eslint-disable-next-line
    [settingsDocName]
  )

  const providerContext = () => <StorageContext.Provider value={{ storage_state, storage_handlers, storage_fns }}>
    {props.children}
  </StorageContext.Provider>

  return globalFiles ? providerContext() : <div></div>

}

export default StorageProvider