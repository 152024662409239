import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Divider } from 'semantic-ui-react';
import { FormContext } from '../../cnr/contexts/FormContext';
import { createObject } from '../../common/creating';
import { uniqueKey } from '../../common/keys';
import { gEnums, listTypes } from '../../enums/globalEnums';
import { propDataOpts, spp } from '../../viewSettings/settingsProps/settingsAll';
import HtmlEditor from './elements/HtmlEditor';
import { addFormItemPropElements } from './props/addFormItemProps';

const logErrors = true

/**
 * 
 * @param {object} props 
 * @returns the formItems
 */
const FormItems = () => {

  // formContext
  const formContext = useContext(FormContext)
  const { form_state, form_handlers, formParent_contexts } = formContext ? formContext : {}
  const { states, settings } = formParent_contexts ? formParent_contexts : {}
  const { page_state } = states ? states : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_page } = pageSettings ? pageSettings : {}
  const { viewItems: viewItems_page } = aps_page ? aps_page : {}

  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging } = homeSettings_global ? homeSettings_global : {}

  const {
    activeItem,
    componentContexts,
    currentFip,
    currentSectionViewProps,
    currentViewProps,
    data_localForm,
    formProps,
    isWizard,
    noSort,
    optionsList,
    showPropDataEdit,
    viewOnly,
    vpsa,
  } = form_state

  let {
    data_current,
    readOnlyForm,
    useWizard,
    viewItem,
    viewProps,
    viewPropsAllowed,
  } = formProps ? formProps : {}

  // data_current does NOT change with temp updates

  // componentContexts
  const { frameworkContext, viewSettingsComponentContext, viewSettingsContext } = componentContexts ? componentContexts : {}


  let { themeColors, dataOptions } = aps_global ? aps_global : {}
  const { autoUpdateCollectionRelationships } = dataOptions ? dataOptions : {}

  // frameworkContext 
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { fullMode } = framework_state ? framework_state : {}

  const viewItem_page = viewItem && viewItems_page && viewItems_page[viewItem.key] ? viewItems_page[viewItem.key] : {}
  const { dataSource, dataConstraints } = viewItem_page ? viewItem_page : {}

  if (logging && logging.allowLogging && logging.logFormInitData) { console.log('logging >', 'data_current', data_current) }

  // viewSettingsContext  
  const { viewSettings_state } = viewSettingsContext ? viewSettingsContext : {}

  const { settings_temp } = viewSettings_state ? viewSettings_state : {}
  const { gvvi } = settings_temp ? settings_temp : {}
  const { global: _global } = gvvi ? gvvi : {}

  const settingsSort = viewSettingsComponentContext || noSort || fullMode ? true : true

  if (!themeColors && _global && _global.themeColors) { themeColors = _global.themeColors }

  if (vpsa) { viewProps = vpsa }

  const tabbed = _.filter(currentViewProps, i => i.data ? i.data.tabbed : {});
  const tabbedO = createObject(tabbed, 'key')

  // see if viewProps has 'dataListType'
  if (currentViewProps && currentViewProps['dataListType']) {
    if (data_localForm && data_localForm['dataListType'])
      switch (data_localForm['dataListType']) {
        case gEnums.dataListTypes.view:
          currentViewProps['view'] = spp('view', propDataOpts(false, gEnums.formItemTypes.select, listTypes.dataCollection))
          break;
        default:
        // nothing
      }
  }

  // IMPORTANT: Form Items - Update the form item data
  useEffect(() => {
    if (data_current) { form_handlers.handleUpdateLocalFormData(data_current) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [data_current]);

  useEffect(() => {
    form_handlers.handleCurrentSectionViewProps()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewItem, viewProps]);

  useEffect(() => {
    form_handlers.handleCurrentViewProps(viewItems_page)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewProps]);

  // IMPORTANT: Form Items - All Form Item Changes 
  /** Triggered when any of the element changes value */
  const handleFormItemChange = (e, data, items, arrayItems) => form_handlers.handleFormItemChange(e, data, items, arrayItems)

  if (currentFip) {
    return <HtmlEditor
      key={uniqueKey('htmle', currentFip.propname)}
      currentFip={currentFip}
      handleUpdate={handleFormItemChange}
      handleEditor={form_handlers.handleEditor}
    />
  }

  let propElements = []
  let pisa = []

  const aips = {
    activeItem,
    autoUpdateCollectionRelationships,
    componentContexts,
    inPisa: false,
    isWizard,
    data_localForm,
    logErrors,
    optionsList,
    dataConstraints,
    dataSource,
    propElements,
    pisa,
    readOnlyForm,
    tabbedO,
    useWizard,
    viewPropsAllowed,
    viewOnly
  }

  const elementProps = {
    aips,
    data_localForm,
    propElements,
    settingsSort,
    viewOnly
  }

  if (currentSectionViewProps) {
    Object.keys(currentSectionViewProps).forEach(key => {
      switch (key) {
        case 'hidden':
          // nothing
          break
        default:
          propElements.push(<Divider key={uniqueKey('fi', 'd', key)} horizontal>{_.startCase(key)}</Divider>)
          addFormItemPropElements({ ...elementProps, viewProps: currentSectionViewProps[key].props, key })
      }
    })
  } else {
    addFormItemPropElements({ ...elementProps, viewProps: currentViewProps })
  }

  if (showPropDataEdit) {
    // return fullPageWrapper()
  } else {
    return propElements
  }
}

export default FormItems