import React, { useContext, useReducer, useState } from 'react';
import { Icon, Image, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { gEnums } from '../../enums/globalEnums';
import { appIconTypes } from '../../enums/appIconTypes';
import { fileViewerHandlers, fileViewerInitialState, fileViewerReducer } from '../../cnr/reducers/FileViewerReducer';
import { uploadHandlers, uploadInitialState, uploadReducer } from '../../cnr/reducers/UploadReducer';
import { vsComponentTypes } from '../../viewSettings/enums/vsComponentTypes';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import ImageEditor from '../imaging/ImageEditor';
import ImageGallery from '../imaging/ImageGallery';
import ImagesGrid from '../imaging/ImagesGrid';
import UploadDirectButton from '../uploading/UploadDirectButton';
import Uploader from '../uploading/Uploader';
import UploadSegButton from '../uploading/UploadSegButton';
import GenericIcon from '../../genericControls/GenericIcon';
import GenericButton from '../../genericControls/GenericButton';

/** Returns the current image value of the fieldProp */
const SemFileViewer = (props) => {

  const { fileMode, fieldProps, fip, fromFileManagement } = props
  let { itemProp } = fieldProps ? fieldProps : {}
  const { data } = itemProp ? itemProp : {}
  const { storageType: itemStorageType, allowMultiItemSelect, formItemType } = data ? data : {}
  const { value: propValue } = fip ? fip : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { handlers, states, fns } = parentContext ? parentContext : {}
  const { paps_state, page_state, storage_state } = states
  const { storage_handlers } = handlers
  const { page_fns } = fns
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { settingsOptions } = aps_global ? aps_global : {}

  const { useDarkMode } = settingsOptions ? settingsOptions : {}

  // viewSettingsComponentContext
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext)
  const { vsc_handlers } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { swipeToSelected } = vsc_handlers ? vsc_handlers : {}

  let storageType = props.storageType
  if (!storageType && itemStorageType) { storageType = itemStorageType }

  const init_state_u = {}
  const [upload_state, uploadDispatch] = useReducer(uploadReducer, uploadInitialState(init_state_u));
  const upload_handlers = uploadHandlers(uploadDispatch, upload_state)

  const init_state_fv = { propValue, paps_state, page_fns, storage_handlers, storage_state, fileMode, storageType, fieldProps, swipeToSelected, allowMultiItemSelect }
  const [fileViewer_state, fileViewerDispatch] = useReducer(fileViewerReducer, fileViewerInitialState(init_state_fv));
  const fileViewer_handlers = fileViewerHandlers(fileViewerDispatch, fileViewer_state)
  const { propStorageFile, propStorageFiles, selectedFile, saveReady, showUpload } = fileViewer_state

  const [directFile, setDirectFile] = useState()

  let useDirect = false

  switch (formItemType) {
    case gEnums.formItemTypes.fileItemSelectDirect:
      useDirect = true
      break;
    default:
    // nothing
  }

  const handleSelectedImageFile = (asf) => {
    const df = asf[0]
    if (fip && fip.onChange) {
      const x = {
        propname: fip.propname,
        value: df,
        isFileImage: true
      }
      fip.onChange(null, x)
    }
    setDirectFile(df)
  }

  const handleSelectFile = () => fileViewer_handlers.handleSelectFile({ key: vsComponentTypes.filePicker, storageType, fieldProps })
  const handleCancel = () => fileViewer_handlers.handleSelectFile()

  const allows = {
    add: false,
    confirm: false,
    counter: false,
    manifestSelect: false,
    remove: false,
    select: false,
  }

  switch (fileMode) {
    case gEnums.fileModes.viewManifest:
      allows.counter = true
      allows.remove = true
      allows.manifestSelect = true
      break;
    case gEnums.fileModes.view:
      allows.remove = true
      allows.add = true
      break;
    case gEnums.fileModes.viewWithSelectDirect:
      allows.add = true
      break;
    case gEnums.fileModes.viewWithSelect:
      allows.select = true
      break;
    case gEnums.fileModes.viewWithPick:
      allows.confirm = true
      break;
    default:
    // nothing
  }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />
  const gb = (key, caption, icon, onClick, clickOptions, opts, noE) => <GenericButton gbProps={{ key, caption, icon, onClick, clickOptions, opts, noE }} />

  const btnProps = { inverted: useDarkMode }

  const headerButtons = () => <div>
    {allows.confirm && gb('btn-confirm', 'Save', 'check', fileViewer_handlers.handleSelectSave, null, { ...btnProps, disabled: !saveReady })}
    {allows.add && !useDirect && <UploadDirectButton upload_state={upload_state} upload_handlers={upload_handlers} storageType={storageType} handleSelectedImageFile={handleSelectedImageFile} />}
    {allows.select && gi('btn-select', 'exchange', handleSelectFile, null, { ...btnProps })}
  </div>

  const headerCancel = () => <div>
    {gi('btn-cancel', appIconTypes.delete, handleCancel, null, { ...btnProps })}
  </div>

  const header = () => {
    let caption = itemProp && itemProp.caption ? itemProp.caption : null
    if (!caption && fip && fip.label) { caption = fip.label }
    return <React.Fragment>
      <div>{caption}</div>
      {!selectedFile && headerButtons()}
      {selectedFile && headerCancel()}
    </React.Fragment>
  }

  const imageEditor = (imageFile, key) => <ImageEditor
    imageFile={imageFile}
    imageEditProps={{ maxWidth: 240, maxHeight: 240 }}
    key={uniqueKey('su', 'ii', key)}
  />

  const uploadSegButton = (imageFile) => <UploadSegButton
    upload_state={upload_state}
    upload_handlers={upload_handlers}
    storageType={storageType}
    handleSelectedImageFile={handleSelectedImageFile}
    imageFile={imageFile}
  />

  const content = () => {
    switch (storageType) {
      case gEnums.storageTypes.manifest:
        return <ImageGallery
          fip={fip}
          storageLocationType={gEnums.storageLocationTypes.event}
          storageType={storageType}
          viewerProps={props} />

      default:
        if (fieldProps) {
          if (selectedFile) {
            return <ImageGallery
              allowMultiItemSelect={true}
              fip={fip}
              storageLocationType={gEnums.storageLocationTypes.event}
              storageType={selectedFile.storageType}
              viewerProps={props}
              fileViewer_handlers={fileViewer_handlers}
            />
          } else {
            if (propStorageFile) {
              const { urls, customMetadata } = propStorageFile ? propStorageFile : {}
              const { full, thumbnail } = urls ? urls : {}
              const { name } = customMetadata ? customMetadata : {}
              const src = thumbnail ? thumbnail : full

              switch (storageType) {
                case gEnums.storageTypes.pdf:
                  return <Segment basic style={{ color: 'white' }}>
                    <Icon name='file pdf outline' size='huge' color={'blue'} />
                    {name}
                  </Segment>
                default:
                  return <Image src={src} />
              }

            } else if (propStorageFiles) {
              const igProps = {
                gallery: propStorageFiles,
                storagePermissions: {},
                storageType: storageType,
                origin: 'semFileViewer'
              }
              return <ImagesGrid igProps={igProps} />
            } else {
              if (directFile) {
                if (useDirect) {
                  return uploadSegButton(directFile)
                } else {
                  return imageEditor(directFile, 'df')
                }
              } else {
                if (useDirect) {
                  return uploadSegButton()
                } else {
                  return <div>{'No Image Selected'}</div>
                }
              }
            }
          }
        } else {
          return <ImageGallery
            allowMultiItemSelect={true}
            fip={fip}
            fromFileManagement={fromFileManagement}
            storageLocationType={gEnums.storageLocationTypes.event}
            storageType={storageType}
            viewerProps={props} />
        }
    }
  }

  const caroselImages = () => {
    let showHeader = false
    switch (fileMode) {
      case gEnums.fileModes.viewManifest:
      case gEnums.fileModes.view:
      case gEnums.fileModes.viewWithSelect:
      case gEnums.fileModes.viewWithSelectDirect:
      case gEnums.fileModes.viewWithModify:
      case gEnums.fileModes.viewWithPick:
        showHeader = true
        break;
      default:
      // nothing
    }

    return <Wrapper
      header={fieldProps && showHeader && <div className={'images-header'}>{header()}</div>}
      content={content()}
      wrapperType={wrapperTypes.paddedContent}
      css={{ container: itemProp ? 'fip' : '', header: 'imgs', content: 'images-content' }}
    />
  }

  const uploader = () => <Wrapper
    content={<Uploader
      handleCancel={fileViewer_handlers.handleShowUpload}
      origin={'semFileViewer'}
      storageLocationType={gEnums.storageLocationTypes.pageDirect}
    />}
    css={{ footer: 'modify' }}
    wrapperType={wrapperTypes.upload}
  />

  const fpw_upload = () => <FullPageWrapper
    content={uploader()}
    topperCaption={'Upload'}
    handleCancel={fileViewer_handlers.handleShowUpload}
    isBack={true}
  />

  return showUpload ? fpw_upload() : caroselImages()
}

export default SemFileViewer