import React from 'react';
import CreateNotificationWizard from '../../appNotifications/CreateNotificationWizard';
import CreateTopicWizard from '../../appNotifications/CreateTopicWizard';
import ImageMap from '../../components/swipe/ImageMap';
import { gEnums } from '../../enums/globalEnums';
import { dataManagementTypes } from '../../viewSettings/enums/itemActionTypes';
import { fullFilterTypes, UiDataFilters } from '../UiDataFilters';
import UiUpload from './dataManagement/UiUpload';
import UiDataLinking from './dataModifications/UiDataLinking';

const AppDashboard = React.lazy(() => import('../../components/viewers/AppDashboard'));
const UiAmmendData = React.lazy(() => import('./dataManagement/UiAmmendData'));
const UiBackupData = React.lazy(() => import('./dataManagement/UiBackupData'));
const UiConvertToDocument = React.lazy(() => import('./dataManagement/UiConvertToDocument'));
const UiCopyPaste = React.lazy(() => import('./dataManagement/UiCopyPaste'));
const UiCreateGlobalData = React.lazy(() => import('./dataManagement/UiCreateGlobalData'));
const UiFixData = React.lazy(() => import('./dataManagement/UIFixData'));
const UiFixPropData = React.lazy(() => import('./dataManagement/UiFixPropData'));
const UiFixPropNames = React.lazy(() => import('./dataManagement/UiFixPropNames'));
const UiGoogleLinks = React.lazy(() => import('./dataManagement/UiGoogleLinks'));
const UiGoogleSheets = React.lazy(() => import('./dataManagement/UiGoogleSheets'));
const UiLinks = React.lazy(() => import('./dataManagement/UiLinks'));
const UiMoveData = React.lazy(() => import('./dataManagement/UiMoveData'));
const UiParentKeys = React.lazy(() => import('./dataManagement/UiParentKeys'));
const UiProductionDataWithProvider = React.lazy(() => import('./dataManagement/UiProductionData'));
const UiSecretData = React.lazy(() => import('./dataManagement/UiSecretData'));

/**
 * 
 * @param {object} props (dataManagementType, inViewer)
 * @returns a DataManagementContent component based on the `dataManagementType`
 */
const DataManagementContent = (props) => {

  const { dataManagementType, handleItemClose } = props ? props : {}

  switch (dataManagementType) {

    case dataManagementTypes.backupItemData:
      return <UiBackupData />

    case dataManagementTypes.bulkImageUpload:
      return <UiUpload origin={'UiDataManagement'} />

    case dataManagementTypes.convertToDocument:
      return <UiConvertToDocument />

    case dataManagementTypes.convertToParentKeys:
      return <UiParentKeys />

    case dataManagementTypes.copyData:
      return <UiCopyPaste />

    case dataManagementTypes.googleSheetsData:
    case dataManagementTypes.registrationData:
    case dataManagementTypes.productionData:
      return <UiProductionDataWithProvider />

    case dataManagementTypes.createCollectionData:
    case dataManagementTypes.createGlobalData:
    case dataManagementTypes.updateStaticViews:
      return <UiCreateGlobalData />

    case dataManagementTypes.createNotification:
      return <CreateNotificationWizard handleClose={handleItemClose} notificationOriginType={gEnums.notificationOriginTypes.uiItem} />

    case dataManagementTypes.createFilter:
      return <UiDataFilters fullFilterType={fullFilterTypes.create} />

    case dataManagementTypes.createTopic:
      return <CreateTopicWizard topicOriginType={gEnums.topicOriginTypes.page} activeTopic={{ name: 'test' }} />

    // this is catch all
    case dataManagementTypes.fixData:
      return <UiFixData />

    case dataManagementTypes.imageMapping:
      return <ImageMap imageMapType={gEnums.imageMapTypes.zones} handleCancel={handleItemClose} inDashboard={true} />

    case dataManagementTypes.linkData:
      return <UiDataLinking />

    // this is catch all
    case dataManagementTypes.fixPropData:
      return <UiFixPropData />

    case dataManagementTypes.fixPropNames:
    case dataManagementTypes.ammendPropData:
      return <UiFixPropNames />

    // case dataManagementTypes.fixLocations:
    case dataManagementTypes.updateDataRelationships:
    case dataManagementTypes.modifyList:
      return <UiAmmendData />

    case dataManagementTypes.googleSheetLink:
      return <UiGoogleLinks />

    case dataManagementTypes.googleDataUpdate:
      return <UiGoogleSheets />

    case dataManagementTypes.googleSheetUpdate:
      return <UiGoogleSheets />

    case dataManagementTypes.secretData:
      return <UiSecretData />

    case dataManagementTypes.imageLinks:
      return <UiLinks />

    case dataManagementTypes.moveData:
      return <UiMoveData />

    case dataManagementTypes.updateParentKeys:
      return <UiParentKeys />

    case dataManagementTypes.manageData:
      return <AppDashboard />

    default:
      return <div></div>
  }
}

export default DataManagementContent