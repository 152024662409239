import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { sportsMatchHandlers, sportsMatchInitialState, sportsMatchReducer } from "../reducers/SportsMatchReducer";
import { SeasonMatchesContext } from "./SeasonMatchesContext";
import { SportsSeasonContext } from "./SportsSeasonContext";

export const SportsMatchContext = React.createContext()

export default function SportsMatchProvider(props) {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states ? states : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const { pathViews, pathName, viewKey } = paps_state ? paps_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info, viewItem, latestSeason, sportsKey } = sportsSeason_state ? sportsSeason_state : {}
  const { playoffMatches, matches_realtime } = matches_info ? matches_info : {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)

  const componentContexts = { sportsSeasonContext, seasonMatchesContext }

  const init_state = { sportsSeason_handlers, appUserAccess, pathViews, pathName, viewItem, viewKey, playoffMatches, latestSeason, sportsKey }
  const [sportsMatch_state, sportsMatch_dispatch] = useReducer(sportsMatchReducer, init_state, sportsMatchInitialState)
  const sportsMatch_handlers = sportsMatchHandlers(sportsMatch_dispatch, sportsMatch_state)

  useEffect(() => {
    sportsMatch_handlers.handleLatestSeason(latestSeason)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [latestSeason]);

  return <SportsMatchContext.Provider value={{ sportsMatch_state, sportsMatch_handlers, componentContexts }}>
    {props.children}
  </SportsMatchContext.Provider>
}