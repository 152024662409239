import React, { useContext, useEffect } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { createInitials } from '../../../../global/common/dataAdjust';
import { uniqueKey } from '../../../../global/common/keys';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { getSectionColor } from '../../create/sectionColors';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';

const nameLimit = 18

const PoolMatch = (props) => {

  const { match_current, match, wcn, forGrid } = props

  let cn_matchGrid = wcn
  if (match && match.sectionMeeting) { cn_matchGrid += ' sm' }

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ? seasonMatchesContext : {}
  const { viewItem } = seasonMatches_state ? seasonMatches_state : {}
  const { handleMatchClick } = seasonMatches_handlers ? seasonMatches_handlers : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_true } = sportsMatch_state ? sportsMatch_state : {}

  // sportsMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ? sportsRtMatchContext : {}
  const { match_rt } = sportsRtMatch_state ? sportsRtMatch_state : {}

  useEffect(() => {
    const tm = match_current && (match_current.key === match.key) ? match_current : match
    sportsMatch_handlers.handleSetTrueMatch(tm)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_current, match]);

  const handle_matchClick = (key, d) => {
    handleMatchClick(viewItem, key, null, d)
  }

  const getPendingMatch = (pending, pendingType) => {
    if (pending) {
      const { round, roundKey, pool, place, poolName, matchNumber } = pending ? pending : {}
      if (roundKey === 'playoffs') {
        return poolName + ' #' + matchNumber + '/' + place
      } else {
        return 'R' + round + '/' + (poolName ? poolName + '-' + matchNumber : 'P' + pool) + '/' + place
      }
    } else {
      return null
    }
  }

  const locationPlayoffIcon = () => <Icon color={'grey'} name={'map marker alternate'} />

  const poolMatch = () => {

    const { key, home, away, work, matchNumber, seeds, courtNumber, pending, results: results_true, location, name: matchName, startDate, startTime, sectionMeeting } = match_true ? match_true : {}
    const _results = match_rt ? match_rt : results_true

    const { home: home_pending, away: away_pending, work: work_pending } = pending ? pending : {}
    const { name: locationName } = location ? location : {}
    const { score, scores, winner, setNumber, setScore } = _results ? _results : {}
    const { id: winnerId } = winner ? winner : {}

    const _currentSetScore = scores && setNumber && scores[setNumber] ? scores[setNumber] : score

    const homeName = home ? home.name : getPendingMatch(home_pending)
    const awayName = away ? away.name : getPendingMatch(away_pending)
    const workName = work ? work.name : getPendingMatch(work_pending)

    const _homeName = homeName.length > nameLimit ? createInitials(homeName, 3) : homeName
    const _awayName = awayName.length > nameLimit ? createInitials(awayName, 3) : awayName

    const homeSeed = seeds ? seeds['home'] : null
    const awaySeed = seeds ? seeds['away'] : null

    const awayResult = score ? score.away : '-'
    const homeResult = score ? score.home : '-'

    const seedLabel = (seed, color) => <Label key={uniqueKey('pm', 'sl', seed)} color={color} size={'mini'}>{seed}</Label>
    const resultLabel = (team, score, color, cn) => <div key={uniqueKey('pm', 'sl', team, score)} className={'result-' + team + ' ' + cn} >{score}</div>
    const scoreLabel = (team, score, cn) => <div key={uniqueKey('pm', 'rl', team, score)} className={'score-' + team + ' ' + cn} >{score}</div>

    if (_homeName && _awayName) {

      const _awaySeed = away && away.seed && away.seed !== awaySeed ? away.seed : awaySeed
      const _homeSeed = home && home.seed && home.seed !== homeSeed ? home.seed : homeSeed

      const away_upset = away && away.id === winnerId ? true : false
      const home_upset = home && home.seed > homeSeed ? true : false

      const winner = away && away.id === winnerId ? 'away' : 'home'
      const isBye = _awayName === 'BYE'

      if (isBye) { cn_matchGrid += ' bye' }

      const m = []

      let match_cn = 'pool-match'
      let away_cn = 'match-away'
      let home_cn = 'match-home'


      if (forGrid) {

        const seedColors = {
          result: {
            away: 'grey',
            home: 'grey',
          },
          score: {
            away: 'grey',
            home: 'grey',
          }
        }
        // let awaySeedColor = 'grey'
        // let homeSeedColor = 'grey'

        const { sections: section_away } = away ? away : {}
        const { sections: section_home } = home ? home : {}

        if (section_away) { seedColors.result.away = getSectionColor(section_away) }
        if (section_home) { seedColors.result.home = getSectionColor(section_home) }

        if (away_upset) (away_cn += ' upset')
        if (home_upset) (home_cn += ' upset')

        if (match_rt && _currentSetScore) {
          match_cn += ' rt'
          seedColors.score.away = _currentSetScore.home > _currentSetScore.away ? 'up' : _currentSetScore.home < _currentSetScore.away ? 'down' : 'tie'
          seedColors.score.home = _currentSetScore.away > _currentSetScore.home ? 'up' : _currentSetScore.away < _currentSetScore.home ? 'down' : 'tie'
        }

        m.push(<div className={match_cn} onClick={() => { !isBye && handle_matchClick(key, { itemData: match_true }) }}>
          <div key={uniqueKey('pm', 'ht')} className={away_cn}>{seedLabel(_awaySeed, seedColors.result.away)}<span>{_awayName}</span></div>
          <div key={uniqueKey('pm', 'at')} className={home_cn}>{seedLabel(_homeSeed, seedColors.result.home)}<span>{_homeName}</span></div>

          {resultLabel('away', awayResult, seedColors.result.away, winner === 'away' ? 'w' : null)}
          {resultLabel('home', homeResult, seedColors.result.home, winner === 'home' ? 'w' : null)}

          {startDate && startTime && <div key={uniqueKey('pm', 'mdt')} className={'match-dates'}>
            {startDate && <div key={uniqueKey('pm', 'md')} className={'match-date'}>{formatItem(formatTypes.dateDayMonth, startDate)}</div>}
            {startTime && <div key={uniqueKey('pm', 'mt')} className={'match-time'}>{startTime}</div>}
            <div key={uniqueKey('pm', 'mw')} className={'match-location'}>{locationPlayoffIcon()}{locationName ? locationName : 'TBD'}</div>
          </div>}

          {match_rt && _currentSetScore && scoreLabel('away', _currentSetScore.away, seedColors.score.away)}
          {match_rt && _currentSetScore && scoreLabel('home', _currentSetScore.home, seedColors.score.home)}

        </div>)
      } else {
        if (courtNumber) {
          m.push(<div className={'pool-header'}>
            {!location && <div key={uniqueKey('pm', 'mc')} className={'match-court'}>{'Match #'}{matchNumber}{' - '}{'court '}{courtNumber}</div>}
            {!location && <div key={uniqueKey('pm', 'mw')} className={'match-work'}>{'Work: '}{workName}</div>}
          </div>)
        } else {
          m.push(<div className={'pool-header'}>
            {<div key={uniqueKey('pm', 'mc')} className={'match-court'}>{matchName}{' #'}{matchNumber}</div>}
            {locationName && <div key={uniqueKey('pm', 'mw')} className={'match-work'}>{'Location: '}{locationName}</div>}
          </div>)
        }
        m.push(<div className={'pool-match'}>
          <div key={uniqueKey('pm', 'pm', 'at')} className={away_cn}>{<Label color={'green'} size={'mini'}>{awaySeed}</Label>}{_awayName}</div>
          <div key={uniqueKey('pm', 'pm', 'ht')} className={home_cn}>{_homeName}<Label color={'green'} size={'mini'}>{homeSeed}</Label></div>
        </div>)
      }

      return <div
        key={uniqueKey('pm', 'pm')}
        className={cn_matchGrid}
      // onClick={() => { handleMatchClick(key, { itemData: match }) }}
      >
        {m}
      </div>
    }
  }

  return match_true ? poolMatch() : <div></div>
}

export default PoolMatch