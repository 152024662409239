import React, { createContext, useContext, useReducer } from 'react';
import '../../../cssAlt/AppM.css';
import { UiItemContext } from '../../../global/cnr/contexts/UiItemContext';
import { copyObj } from '../../../global/common_web/copy';
import { gEnums } from '../../../global/enums/globalEnums';
import { SportsDistrictContext } from '../cnr/contexts/SportsDistrictContext';
import { SportsSeasonContext } from '../cnr/contexts/SportsSeasonContext';
import { createSportsHandlers, createSportsInitialState, createSportsReducer } from '../cnr/reducers/CreateSportsReducer';
import CreateRoster from './subs/CreateRoster';
import SportsDataPaste from './subs/SportsDataPaste';
import BackupSeasons from './BackupSeasons';
import CreateSeasonDetails from './subs/CreateSeasonDetails';
import { uniqueKey } from '../../../global/common/keys';
import EditSeason from './subs/EditSeason';
import { UiDashboardForm } from '../../../global/pageItem/modification/dataModifications/UiDashboardForm';
import { sportsDataModes } from '../elements/dashboard/SportsDashboard';

const CreateActiveSports = React.lazy(() => import('./CreateActiveSports'));
const CreateAllStarTeams = React.lazy(() => import('./CreateAllStarTeams'));
const CreatePlayoff = React.lazy(() => import('./CreatePlayoff'));
const CreateRankings = React.lazy(() => import('./CreateRankings'));
const CreateSeason = React.lazy(() => import('./CreateSeason'));
const CreateSportsTeams = React.lazy(() => import('./CreateSportsTeams'));
const CreateTournament = React.lazy(() => import('./CreateTournament'));
const ViewItemPaste = React.lazy(() => import('../../../global/viewSettings/importing/ViewItemPaste'));

const _teamItemsToRemove = ['id', 'position', 'record', '_itemKey']

export const CreateSportsContext = createContext();

const CreateSports = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { pathViews, subActionType, viewItem, handleActionPreview, commitSub, handleCancel } = props

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state, item_handlers } = uiItemContext ? uiItemContext : {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ? sportsDistrictContext : {}
  const { schools: schools_district } = sportsDistrict_state ? sportsDistrict_state : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { teams_info, sportsKey, latestSeason } = sportsSeason_state ? sportsSeason_state : {}
  const { teams } = teams_info ? teams_info : {}

  console.log('sportsSeason_state', sportsSeason_state)

  const _teams = teams ? copyObj(teams) : {}

  // ammnend the teams
  if (_teams) {
    Object.keys(_teams).forEach(tk => {
      _teamItemsToRemove.forEach(ti => {
        // delete _teams[tk][ti]
      })
    })
  }

  const _latestSeason = latestSeason ? latestSeason : props.latestSeason

  const init_state = { pathViews, schools_district, subActionType, selectedAthletes: [], teams: _teams, item_state, latestSeason: _latestSeason, sportsKey }
  const [createSports_state, createSports_dispatch] = useReducer(createSportsReducer, init_state, createSportsInitialState)
  const createSports_handlers = createSportsHandlers(createSports_dispatch, createSports_state)

  const content = () => {
    switch (subActionType) {

      case _asat.backupSeasons:
        return <BackupSeasons viewItem={viewItem} handleCancel={handleCancel} />

      case _asat.editSeason:
      case _asat.editCurrentSeason:
        return <EditSeason key={uniqueKey('cs', 'season-edit')} handleCancel={handleCancel} subActionType={subActionType} />

      case _asat.nominatePlayers:
      case _asat.createAllStarTeams:
        return <CreateAllStarTeams viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} subActionType={subActionType} />

      case _asat.createHistory:
        return <CreateAllStarTeams viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} />

      case _asat.createSeason:
        return <CreateSeason viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} alignmentOnly={true} />

      case _asat.createSportsTeams:
        return <CreateSportsTeams viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} alignmentOnly={true} />

      case _asat.createSportsHandlers:
        return <CreateSeason viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} alignmentOnly={true} />

      case _asat.createPlayoff:
        return <CreatePlayoff viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} />

      case _asat.createRoster:
        return <CreateRoster subActionType={subActionType} />

      case _asat.editGoogleSheetLink:
        return <UiDashboardForm sportsDataMode={sportsDataModes.googleLinks} handleCancel={handleCancel} />

      case _asat.createStaff:
        return <SportsDataPaste subActionType={subActionType} />

      case _asat.createRankings:
      case _asat.submitRankings:
        return <CreateRankings handleActionPreview={handleActionPreview} commitSub={commitSub} handleCancel={handleCancel} />

      case _asat.createTournament:
        return <CreateTournament viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} />

      case _asat.updateDistricts:
      case _asat.updateOrganizations:
        return <CreateActiveSports subActionType={subActionType} handleCancel={handleCancel} />

      case _asat.updateOrganizationSports:
      case _asat.updateLeagueSports:
      case _asat.updateSchoolSports:
        return <CreateActiveSports subActionType={subActionType} handleCancel={handleCancel} />
      default:
        return <div>???</div>
    }
  }

  const wrapper = () => {
    return <CreateSportsContext.Provider value={{ createSports_state, createSports_handlers, viewItem, handleActionPreview, item_handlers }}>
      {content()}
    </CreateSportsContext.Provider>
  }

  return wrapper()
}

export default CreateSports