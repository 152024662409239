import { createFsDocKey } from "./fsAppData"
import _ from 'lodash'

/**
 * 
 * @param {object} pathViews 
 * @param {array} items 
 * @returns the path at the CLIENT collection level - client/clientKey/events/eventKey from pathViews
 */
export const createRefPath_client = (pathViews, items) => {
  let path = ''
  if (pathViews.clients) {
    path = 'clients/' + pathViews.clients
    if (items) { path += '/' + createRefPath(items) }
  }
  return path
}

/**
 * 
 * @param {object} pathViews 
 * @param {array} items - additional paths
 * @returns the path at the EVENT collection level - client/clientKey/events/eventKey from pathViews
 */
export const createRefPath_event = (pathViews, items, forBackup) => {
  let path = ''
  if (pathViews.clients && pathViews.events) {
    path = 'clients/' + pathViews.clients + '/events/' + pathViews.events
    if (forBackup) { path += '_backup' }
    if (items) { path += '/' + createRefPath(items) }
  }
  return path
}

/** client/clientKey/events from pathViews; eventKey passed in */
export const createRefPath_events = (pathViews, eventKey, items) => {
  let path = ''
  if (pathViews.clients && eventKey) {
    path = 'clients/' + pathViews.clients + '/events/' + eventKey
    if (items) { path += '/' + createRefPath(items) }
  }
  return path
}

/**
 *  
 * @param {array} items - path items
 * @param {string} existing - an existing path that will proceed the items
 * @returns the path
 */
export const createRefPath = (items, existing) => {
  let path = ''
  if (items && _.isArray(items)) {
    items.forEach((item, index) => {
      path += item
      if (index < items.length - 1) {
        path += '/'
      }
    })
  } else {
    path = createFsDocKey()
  }

  return existing ? existing + '/' + path : path
}

/** client/clientKey/events from pathViews; eventKey passed in */
export const createStoragePath_events = (pathViews, items) => {
  let path = ''
  if (pathViews.clients && pathViews.events) {
    path = 'clients/' + pathViews.clients + '/events/' + pathViews.events
    if (items) { path += '/' + createRefPath(items) }
  }
  return path
}