import React, { useContext, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { createInitials } from '../../../../global/common/dataAdjust';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';
import { allowSeasonalUpdates } from '../../cnr/reducers/reducerHelpers/seasonalSportsData';
import { Keypad } from './Keypad';

export const _keypadTypes = {
  normal: 'normal',
  realtime: 'realtime',
  mini: 'mini',
}

const keyPadIcons = {
  down: 'arrow down',
  up: 'arrow up',
}

/** Keypad for realtime scoring
 * @description Called only from MatchSetScoring
 */
export const MatchScoreKeypad = (props) => {

  const { keypadType } = props

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_true, match_current } = sportsMatch_state ? sportsMatch_state : {}
  const { home, away, setsToWin } = match_true ? match_true : {}
  const { results_current } = match_current ? match_current : {}
  const _setsToWin = setsToWin ? setsToWin : 5

  // sportsMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ? sportsRtMatchContext : {}
  const { match_rt: rtm } = sportsRtMatch_state ? sportsRtMatch_state : {}

  const _results = rtm ? rtm : results_current

  console.log('keypadType', keypadType)

  const { scores, setNumber, setsCompleted, complete_possible } = _results ? _results : {}
  const _setsCompleted = setsCompleted ? setsCompleted : 0

  const [match_rt, setMatch_rt] = useState()
  const [directScoresOn, setDirectScoresOn] = useState()
  const [currentSetNumber, setCurrentSetNumber] = useState()
  const [showUpdate, setShowUpdate] = useState()
  const [showRemove, setShowRemove] = useState()

  const _allowUpdate = allowSeasonalUpdates.any && allowSeasonalUpdates.matches

  useEffect(() => {
    setMatch_rt(rtm)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [rtm]);

  const allowItemChange = (rtType, setNumber) => {
    setDirectScoresOn(rtType)
    if (setNumber) { setCurrentSetNumber(setNumber) }
  }
  const handleShowUpdateScore = () => setShowUpdate(!showUpdate)
  const handleRtUpdateScore = () => sportsMatch_handlers.handleUpdate_rtMatch(match_rt)

  const handleShowRemoveScore = () => setShowRemove(!showRemove)
  const handleRtRemoveScore = () => sportsRtMatch_handlers.handleRtDelete_scores()

  const handleSetScoreChange = (score) => {

    let _match;

    switch (keypadType) {
      case _keypadTypes.normal:
        _match = match_current
        break;
      case _keypadTypes.mini:
      case _keypadTypes.realtime:
        _match = rtm
        break;
    }

    const _scores = score ? score.split('-') : []
    const _matchToUpdate = { ..._match }
    let { setNumber: sn } = _matchToUpdate
    if (currentSetNumber) { sn = currentSetNumber }
    if (!_matchToUpdate.scores) { _matchToUpdate.scores = {} }
    _matchToUpdate.scores[sn] = {
      away: _scores[0] ? _scores[0].trim() : 0,
      home: _scores[1] ? _scores[1].trim() : 0,
    }
    // handleAmmend_currentMatch

    switch (keypadType) {
      case _keypadTypes.normal:
        sportsMatch_handlers.handleAmmend_currentMatch(_matchToUpdate)
        break;

      case _keypadTypes.mini:
      case _keypadTypes.realtime:
        sportsRtMatch_handlers.handleRtChange_setScores(_matchToUpdate)
        break;
    }

    setDirectScoresOn()
  }

  const handleRealTimeChange = (rtType, add) => sportsRtMatch_handlers.handleRt_update(match_rt, rtType, add, currentSetNumber)

  const gc = (text) => <div color={'black'}>{text}</div>
  const gcl = (text, color, rtType, setNumber, disabled) => <div className='keypad-score' color={color ? color : 'blue'} onClick={() => { !disabled && allowItemChange(rtType, setNumber) }}>{text ? text : 0}</div>
  const gci = (name, rtType, add, allow) => <div color={allow ? 'blue' : 'grey'} onClick={() => { allow && handleRealTimeChange(rtType, add) }}><Icon size="big" name={name} /></div>

  const gcu = () => <div color={'black'} onClick={() => { handleShowUpdateScore() }}>{'Update'}</div>
  const gcuc = () => <div color={_allowUpdate ? 'green' : 'blue'} onClick={() => { handleRtUpdateScore() }}>{'Confirm Update'}</div>

  const gcUpdate = () => showUpdate ? gcuc() : gcu()

  const gcr = () => <div color={'black'} onClick={() => { handleShowRemoveScore() }}>{'Remove'}</div>
  const gcrc = () => <div color={_allowUpdate ? 'green' : 'blue'} onClick={() => { handleRtRemoveScore() }}>{'Confirm Remove'}</div>

  const teamInitials = (name) => {
    let tt = createInitials(name)
    if (tt.length === 1) { tt = name.substring(0, 4) }
    return tt
  }

  const getSetProps = (setScores) => {

    let home = 'dark-grey'
    if (setScores.home > setScores.away) { home = 'green' }
    if (setScores.home < setScores.away) { home = 'red' }

    let away = 'dark-grey'
    if (setScores.away > setScores.home) { away = 'green' }
    if (setScores.away < setScores.home) { away = 'red' }

    let allowSetUp = !setNumber || (setNumber <= _setsCompleted)
    let allowSetDown = setNumber > 1

    let allowAwayDown = setScores.away > 0
    let allowHomeDown = setScores.home > 0

    return { away, home, allowSetUp, allowSetDown, allowAwayDown, allowHomeDown }
  }

  const realtimeGrid = () => {

    const setScores = scores && setNumber && scores[setNumber] ? scores[setNumber] : {}

    const setProps = getSetProps(setScores)

    let cn = 'scorepad-container'
    if (complete_possible) { cn += ' done' }

    const headerRow = () => [
      gc(away && teamInitials(away.name)),
      gc('Set #'),
      gc(home && teamInitials(home.name))
    ]

    const upRow = () => [
      gci(keyPadIcons.up, 'away', true, setNumber),
      gci(keyPadIcons.up, 'set', true, setProps.allowSetUp),
      gci(keyPadIcons.up, 'home', true, setNumber)
    ]

    const scoreRow = (setCount, _setScores, _setProps, _disabled) => [
      gcl(_setScores ? _setScores.away : setScores.away, _setProps ? _setProps.away : setProps.away, 'away', setCount, _disabled),
      gcl(setCount ? setCount : setNumber, _disabled ? 'grey' : 'black', 'set', setCount, _disabled),
      gcl(_setScores ? _setScores.home : setScores.home, _setProps ? _setProps.home : setProps.home, 'home', setCount, _disabled)
    ]

    const downRow = () => [
      gci(keyPadIcons.down, 'away', false, setProps.allowAwayDown),
      gci(keyPadIcons.down, 'set', false, setProps.allowSetDown),
      gci(keyPadIcons.down, 'home', false, setProps.allowHomeDown)
    ]

    const scoresRow = () => Array.from({ length: _setsToWin }, (_, index) => {
      const _setCount = index + 1
      const _setScores = scores && _setCount && scores[_setCount] ? scores[_setCount] : {}
      const _setProps = getSetProps(_setScores)
      const _disabled = _setCount > _setsCompleted + 1
      return scoreRow(_setCount, _setScores, _setProps, _disabled)
    });

    switch (keypadType) {
      case _keypadTypes.normal:
        return <div className={cn}>
          {headerRow()}
          {scoresRow()}
        </div>

      case _keypadTypes.realtime:
        return <div className={cn}>
          {headerRow()}
          {upRow()}
          {scoreRow()}
          {downRow()}
          {complete_possible && gcUpdate()}
          {showRemove ? gcrc() : gcr()}
        </div>
    }
  }

  if (directScoresOn) {
    return <Keypad
      setScoresOn={setDirectScoresOn}
      maxValues={2}
      maxDigits={2}
      handleUpdate={handleSetScoreChange}
    />
  } else {
    switch (keypadType) {
      case _keypadTypes.realtime:
        return realtimeGrid()
      default:
        return <div></div>
    }
  }
} 