import React, { useContext } from 'react';
import { Container, Grid, Header, Image, List, Segment } from 'semantic-ui-react';
import { PageContext } from '../cnr/contexts/PageContext';
import { gEnums } from '../enums/globalEnums';

// https://css-tricks.com/couple-takes-sticky-footer/

const DesktopFooter = () => {

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const { desktop } = aps_global ? aps_global : {}
  const { aboutLink, contactUsLink, faqLink, howToAccessLink } = desktop ? desktop : {}

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.desktopFooter] : {}

  const handleClick = (url) => url && window.open(url, '_blank')

  const footer = () => <Segment inverted vertical style={{ padding: '5em 0em' }} {...styleAndClass}>
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={2}>
            <Image centered size='mini' src='/favicon.ico' />
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='About' />
            <List link inverted>
              <List.Item as='a' onClick={() => handleClick(aboutLink)}>Sitemap</List.Item>
              <List.Item as='a' onClick={() => handleClick(contactUsLink)}>Contact Us</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Services' />
            <List link inverted>
              <List.Item as='a' onClick={() => handleClick(faqLink)}>FAQ</List.Item>
              <List.Item as='a' onClick={() => handleClick(howToAccessLink)}>How To Access</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header as='h4' inverted>
              Meeting Evolution
            </Header>
            <p>
              Total Stategic Meetings Manangement
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>

  return footer()
}

export default DesktopFooter