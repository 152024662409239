import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { gEnums } from "../../enums/globalEnums";
import { _emailSignInStorageItem } from './sendSignInLink';

/**
 *  Comfirms via url that vailidty of the users email.
 *
 * @param history 
 * @param href
 * @description Comfirms via url that vailidty of the users email. 
 */
export const signUpConfirm = (href, callback) => {

  // Confirm the link is a sign-in with email link.

  const auth = getAuth()

  if (isSignInWithEmailLink(auth, href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem(_emailSignInStorageItem);
    if (!email) {
      // email = window.prompt('Please provide your email for confirmation');
    } else {
      // The client SDK will parse the code from the link for you. 
      signInWithEmailLink(auth, email, href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem(_emailSignInStorageItem);
          const emailConfirmationResult = {
            status: gEnums.emailValidationTypes.accepted
          }
          console.log('emailConfirmationResult', emailConfirmationResult)
          callback(emailConfirmationResult)
          window.close()
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          const url = new URL(href)
          const { search } = url ? url : {}
          let _href;
          if (search) {
            _href = href.replace(search, '')
            _href = _href.replace('/emailSignInConfirmation', '')
          }
          const emailConfirmationResult = {
            status: gEnums.emailValidationTypes.denied,
            redirectTo: _href,
            error
          }
          callback(emailConfirmationResult)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }
}
