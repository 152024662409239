import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { uniqueKey } from '../../../../global/common/keys'
import { gEnums } from '../../../../global/enums/globalEnums'
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext'
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import CreateMatchResult from '../../create/CreateMatchResult'
import { calcVballMatch } from '../../helpers/match'
import MatchOveriew from './MatchOveriew'
import MatchRealtimeScoring, { scoreTypes } from './MatchRealtimeScoring'
import MatchSchedule from './MatchSchedule'
import MatchScoreTable from './MatchScoreTable'
import MatchSidebar from './MatchSidebar'

const _noResultCaption = 'No Results'

const _icons = {
  home: 'home',
  away: 'bus',
  time: 'clock outline',
  date: 'calendar outline',
  location: 'map marker alternate',
}

export const MatchSummaryContext = createContext();

const MatchSummary = (props) => {

  const { componentContexts } = props ? props : {}
  const latestMatchesCount = 5

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state } = states
  const { page_fns, appUser_fns } = fns
  const { appUser } = appUser_state ? appUser_state : {}
  const { loggedIn } = appUser ? appUser : {}
  const { pathViews } = paps_state ? paps_state : {}
  const { pushSimple } = page_fns ? page_fns : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_styles } = pageSettings ? pageSettings : {}

  const appUserAccess = getAppUserAccess(appUser)
  const { itemAccess } = appUserAccess ? appUserAccess : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info, viewItem, useAppDarkMode } = sportsSeason_state ? sportsSeason_state : {}
  const { matches, playoffMatches, matches_realtime } = matches_info ? matches_info : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_true, match_current } = sportsMatch_state ? sportsMatch_state : {}

  //sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ? sportsRtMatchContext : {}
  const { showRealtimeScoring } = sportsRtMatch_state ? sportsRtMatch_state : {}
  const { handleShow_rtMatch } = sportsRtMatch_handlers ? sportsRtMatch_handlers : {}

  const [showMatchResults, setShowMatchResults] = useState()

  const { _itemKey, key, home, away, startDate, startTime, sections, levels, results, results_current, results_realtime, isPlayoff, poolKey, location } = match_true ? match_true : {}
  const { name: location_name } = location ? location : {}

  const accessLevel = appUser_fns.get_accessLevel()
  const allowScoreEdits = accessLevel >= gEnums.accessLevels.appSubAdmin.value
  const allowMatchEdit = (itemAccess && ((home && itemAccess.includes(home.id)) || (away && itemAccess.includes(away.id)))) ? true : false
  const _allowEdit = allowScoreEdits || allowMatchEdit

  const _sectionCaption = isPlayoff && poolKey ? _.startCase(poolKey) : _.startCase(sections)

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.actionButton] : {}

  let _results = results_current ? results_current : results
  if (results_realtime && !complete) { _results = results_realtime }
  const { complete, score, scoreDisplay, hasScores, players } = _results ? _results : {}

  const [match_result_realtime, setMatch_result_realtime] = useState()
  const { realTimeMatch, realTimeScorer } = match_result_realtime ? match_result_realtime : {}

  useEffect(() => {
    let tm = matches && matches[pathViews.matches] ? matches[pathViews.matches] : null
    if (!tm) { tm = playoffMatches && playoffMatches[pathViews.matches] ? playoffMatches[pathViews.matches] : {} }
    if (tm) {
      calcVballMatch(tm)
      sportsMatch_handlers.handleSetTrueMatch(tm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, playoffMatches]);

  useEffect(() => {
    if (matches_realtime) {
      const realtimeMatches = matches_realtime ? matches_realtime[key] : {}
      const _allResults = []
      let realTimeScorer;
      if (realtimeMatches) {
        Object.keys(realtimeMatches).forEach(rmKey => {
          const match_rt = realtimeMatches[rmKey]
          const { displayName, results } = match_rt ? match_rt : {}
          realTimeScorer = displayName
          _allResults.push(results)
        })
        if (_allResults && _allResults.length > 0) {
          setMatch_result_realtime({ realTimeMatch: _allResults[0], realTimeScorer })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, _itemKey]);

  const handleClick = (team) => {
    team && pushSimple && sportsSeason_handlers.handleGoToTeam(pushSimple, team)
  }

  const handleShowMatchScoreTable = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    if (hasScores) {
      sportsMatch_handlers.handleShowMatchScoreTable(match_true)
    } else {
      sportsMatch_handlers.handleEditScore(match_true)
    }
  }

  const icon = (name) => <Icon name={name} color={'blue'} size={'small'} />

  const matchPlayers = () => players ? <div className={'summary-split'}>
    <div className={'matches-latest-team'}>Team 1</div>
    <div className={'matches-latest-team'}>Team 2</div>
  </div> : 'No Top Players Selected'

  const overview = () => <div className={'team-overview'}>
    {complete && <MatchOveriew
      useAppDarkMode={useAppDarkMode}
      caption={'Top Players'}
      content={matchPlayers()}
      cn={'match-players'}
    />}
    <MatchOveriew
      caption={'Team Form'}
      content={<div className={'summary-split'}>
        <div className={'matches-latest-team'}><MatchSchedule componentContexts={componentContexts} latestMatchesOnly={true} latestMatchesCount={latestMatchesCount} forOverview={true} teamKey={away.id} /></div>
        <div className={'matches-latest-team'}><MatchSchedule componentContexts={componentContexts} latestMatchesOnly={true} latestMatchesCount={latestMatchesCount} forOverview={true} teamKey={home.id} /></div>
      </div>}
    />
  </div>

  const matchContent = () => {
    let resultCn = 'ms-result'
    if (match_current) {
      return <CreateMatchResult match_current={match_current} includePlayers={true} />
    } else if (showMatchResults) {
      return <CreateMatchResult match_current={match_true} handleShowScore={setShowMatchResults} realTimeOnly={true} viewItem={viewItem} />
    } else {
      if (_allowEdit) { resultCn += ' ae' }
      return home && away ?
        <div className='ms-summary-full'>
          <div className={'ms-summary'}>
            <div key={uniqueKey('ms', 'mc', 1)} className={'ms-home'} onClick={() => { handleClick(home) }}>{home.name}{icon(_icons.home)}</div>
            <div key={uniqueKey('ms', 'mc', 2)} className={'ms-away'} onClick={() => { handleClick(away) }}>{icon(_icons.away)}{away.name}</div>
            <div key={uniqueKey('ms', 'mc', 3)} className={'ms-date'}>{icon(_icons.date)}{startDate}</div>
            <div key={uniqueKey('ms', 'mc', 4)} className={'ms-time'}>{icon(_icons.time)}{startTime}</div>
            <div key={uniqueKey('ms', 'mc', 5)} className={'ms-level'}>{'Level: '}{levels}</div>
            <div key={uniqueKey('ms', 'mc', 6)} className={'ms-section'}>{_sectionCaption}</div>
            <div key={uniqueKey('ms', 'mc', 7)} className={'ms-location'}>{icon(_icons.location)}{location_name ? location_name : home.name}</div>
            <div key={uniqueKey('ms', 'mc', 8)} className={resultCn} onClick={(e) => { _allowEdit && handleShowMatchScoreTable(e) }}>{scoreDisplay ? scoreDisplay : _noResultCaption}</div>
            <div key={uniqueKey('ms', 'mc', 9)} className={'ms-score'}><MatchScoreTable match_current={match_true} /></div>
          </div>
          <MatchSummaryContext.Provider value={{ matchSummary_state: { isSummary: true } }}>
            {overview()}
          </MatchSummaryContext.Provider>
        </div>

        : <div></div>
    }
  }

  const sidebar = () => <MatchSidebar
    allowPopup={loggedIn && _allowEdit}
    content={matchContent()}
    handleShowPopupContents={handleShow_rtMatch}
    sidebarContent={<MatchRealtimeScoring
      match_current={match_true}
      scoreType={scoreTypes.realtime}
      viewKey={match_true.key}
    />}
    styleAndClass={styleAndClass}
    visible={showRealtimeScoring}
  />

  return match_true ? sidebar() : <div></div>

}

export default MatchSummary