import _ from 'lodash';

export const getViewableList = (scrolled, viewListData, sortProp, sortReverse, dataCount) => {

  let vld = null

  if (sortProp && sortProp.endsWith('Date')) {

    vld = _.sortBy(viewListData, function (itemData) {
      const dd = itemData[sortProp]
      const useUtc = dd && dd.length > 4
      const _sp = sortProp + 'UTC'
      const dv = itemData && itemData[_sp] && useUtc ? itemData[_sp] : itemData[sortProp]
      return dv;
    })
  } else {
    try {
      vld = _.orderBy(viewListData, [i => i[sortProp].toLowerCase()]);
    } catch (error) {
      vld = _.sortBy(viewListData, sortProp)
    }
  }

  if (scrolled) {
    const nvld = []
    vld.slice(0, dataCount).forEach((vld) => {
      nvld.push(vld);
    });
    return nvld
  } else {
    if (sortReverse) { vld = _.reverse(vld) }
    return vld
  }
}