import { convertSnapshot } from '../../../global/cnr/contexts/contextHelpers';
import { fs_get_data } from '../../../global/firestoreData/appData/fsAppData';
import { createRefPath_event } from '../../../global/firestoreData/appData/appRefPaths';
import { _globalTicketingName, _useTicketingSubCollection } from '../cnr/reducers/TicketingReducer';

export const getTicketing = (pathViews, callback, callbackAlt) => {

  const _refPath = createRefPath_event(pathViews, [_globalTicketingName])

  fs_get_data({ refPath: _refPath }).then(data => {
    callback(data)
    if (_useTicketingSubCollection) {
      if (data) {
        Object.keys(data).forEach(key => {
          const _ticketPaths = createRefPath_event(pathViews, [_globalTicketingName, key, 'userTickets'])
          fs_get_data({ refPath: _ticketPaths }).then(sss => {
            const dataC = convertSnapshot(sss, false, { ignoreId: true })
            callbackAlt(dataC, key)
          })
        })
      }
    } else {
      callback(data)
    }
  })
} 