import { gEnums } from '../../../enums/globalEnums';
import { vsComponentTypes } from '../../enums/vsComponentTypes';

export const addModes = () =>

  [
    gEnums.addModeTypes.additionalPageItems,
    gEnums.addModeTypes.api,
    gEnums.addModeTypes.copyExisting,
    gEnums.addModeTypes.eventSpecific,
    gEnums.addModeTypes.existingItems,
    gEnums.addModeTypes.googleSheets,
    gEnums.addModeTypes.googleSheetsOnly,
    gEnums.addModeTypes.input,
    gEnums.addModeTypes.list,
    gEnums.addModeTypes.listAdd,
    gEnums.addModeTypes.normal,
    gEnums.addModeTypes.pageItems,
    gEnums.addModeTypes.pasteImport,
    gEnums.addModeTypes.previousEvent,
    gEnums.addModeTypes.registrationKey,
    gEnums.addModeTypes.registrationKeyAndGoogleSheets,
    gEnums.addModeTypes.select,
    gEnums.addModeTypes.subEvent,
    gEnums.addModeTypes.url,
    gEnums.addModeTypes.views,
  ]

export const getStartAddMode = (isGlobal, createType, swipedItem) => {

  const amts = gEnums.addModeTypes

  switch (createType) {
    case vsComponentTypes.eventUpdates:
      return amts.eventSpecific

    case vsComponentTypes.createClient:
      return amts.select

    case vsComponentTypes.createEvent:
    case vsComponentTypes.updateEvent:
      return amts.registrationKey

    // IMPORTANT: Add stuff
    case vsComponentTypes.add:
      if (swipedItem) {
        switch (swipedItem.viType) {
          case vsComponentTypes.props:
            if (isGlobal) {
              return amts.input
            } else {
              return amts.existingItems
            }

          case vsComponentTypes.propSections:
            return amts.input

          case vsComponentTypes.views:
            if (isGlobal) {
              return amts.input
            } else {
              return amts.existingItems
            }

          case vsComponentTypes.viewItems:
            if (isGlobal) {
              return amts.input
            } else {
              return amts.existingItems
            }

          case vsComponentTypes.dataLists:
            return amts.list

          default:
            if (isGlobal) {
              return amts.input
            } else {
              return amts.input
            }
        }
      } else {
        if (isGlobal) {
          return amts.input
        } else {
          return amts.input
        }
      }

    case 'normal':
      if (swipedItem) {
        switch (swipedItem.key) {
          case vsComponentTypes.dataLists:
            return amts.list
          default:
            return amts.normal
        }
      } else {
        return amts.normal
      }
    default:
  }
}

export const addTypes = (createType, swipedItem, isGlobal, viewItem_page) => {

  const { dataModifications } = viewItem_page ? viewItem_page : {}
  const { add: add_modification } = dataModifications ? dataModifications : {}
  const { addModeTypes } = add_modification ? add_modification : {}

  const amts = gEnums.addModeTypes
  let modes = null;

  switch (createType) {
    case vsComponentTypes.eventUpdates:
      modes = [amts.eventSpecific]
      break;

    case vsComponentTypes.createClient:
      modes = [amts.select]
      break;

    case vsComponentTypes.createEvent:
    case vsComponentTypes.updateEvent:
      modes = [
        amts.existingItems,
        amts.googleSheets,
        amts.googleSheetsOnly,
        amts.pasteImport,
        amts.previousEvent,
        amts.registrationKey,
        amts.registrationKeyAndGoogleSheets,
        amts.select,
        amts.subEvent,
        amts.url,
      ]
      break;

    case vsComponentTypes.add:
      if (swipedItem) {
        switch (swipedItem.viType) {

          case vsComponentTypes.dataLists:
            modes = [amts.list, amts.normal, amts.googleSheets, amts.registrationKey]
            break;

          case vsComponentTypes.props:
            if (isGlobal) {
              modes = [amts.input]
            } else {
              modes = [amts.existingItems]
            }
            break;

          case vsComponentTypes.propSections:
            modes = [amts.input]
            break;

          case vsComponentTypes.views:
            modes = [amts.input, amts.copyExisting, amts.listAdd, amts.existingItems, amts.googleSheets]
            break;

          case vsComponentTypes.viewItems:
            modes = [amts.existingItems, amts.additionalPageItems]
            break;

          default:
            if (isGlobal) {
              modes = [amts.input, amts.pasteImport]
              // modes = [amts.googleSheets, amts.input, amts.existingItems, amts.pageItems, amts.views, amts.eventKey]
            } else {
              modes = [amts.existingItems, amts.pageItems, amts.views]
            }
        }
      }
      break;

    case 'normal':
      if (swipedItem) {
        switch (swipedItem.key) {
          case vsComponentTypes.dataLists:
            modes = [amts.list, amts.pasteImport, amts.registrationKey]
            break;
          default:
            if (swipedItem.dKey) {
              modes = [amts.normal, amts.pasteImport, amts.registrationKey]
            } else {
              modes = [amts.googleSheets, amts.existingItems, amts.normal]
            }
        }
      } else {
        // amts.googleSheets, amts.existingItems, 
        modes = [amts.normal]
        if (addModeTypes) {
          addModeTypes.forEach(amt => {
            if (!modes.includes(amt)) {
              modes.push(amt)
            }
          })
        }
      }

      if (swipedItem) {
        switch (swipedItem.key) {
          case 'golfCourses':
            modes.push(amts.api)
            break;
          default:
          // nothing
        }
      }
      break;
    default:
    // nothing
  }

  return modes
} 