import _ from 'lodash'
import { getFirstObjectKey } from "../../common/convert"
import { getSwiperContentInit, handleSwipeChange } from './reducerHelpers/swipeHelpers'

const rts = {
  handleHideTabIndex: 'handleHideTabIndex',
  handleSetSwiperContent: 'handleSetSwiperContent',
  handleSetTabIndex: 'handleSetTabIndex',
  handleSetTabIndexByKey: 'handleSetTabIndexByKey',
  handleSetTabView: 'handleSetTabView',
  handleSwipeChangeIndex: 'handleSwipeChangeIndex',
  handleSwiperContentInit: 'handleSwiperContentInit',
}

export const swiperReducer = (state, action) => {

  const { fn_getContent } = state
  const { type, dispatch } = action

  const swipe_handlers = swiperHandlers(dispatch)

  const { handleSetSwiperContent } = swipe_handlers

  // console.log('swiperReducer', action)

  switch (type) {

    case rts.handleSwiperContentInit:
      getSwiperContentInit(state, action.gscProps, handleSetSwiperContent, fn_getContent)
      return { ...state, view: action.view }

    case rts.handleSetSwiperContent:
      return { ...state, swiperContent: action.swiperContent, ...action.gscProps }

    case rts.handleSwipeChangeIndex:
      handleSwipeChange(state, swipe_handlers, action.index, fn_getContent)
      return { ...state }

    case rts.handleSetTabIndex:
      return { ...state, tabIndex: action.tabIndex }

    case rts.handleHideTabIndex:
      return { ...state, tabIndex: action.tabIndex }

    case rts.handleSetTabIndexByKey:
      const { swiperContent } = state
      const { swiperTabs } = swiperContent ? swiperContent : {}
      if (swiperTabs) {
        const st = _.pickBy(swiperTabs, { key: action.key })
        if (st) {
          const xx = getFirstObjectKey(st)
          return { ...state, tabIndex: xx }
        }
      }
      return { ...state }

    case rts.handleSetTabView:
      return { ...state, tabView: action.tvw }

    default:
      return { ...state }
  }
}

export const swiperInitialState = (init_state) => {
  return { ...init_state }
};

export const swiperHandlers = (dispatch, state) => {
  return {
    handleHideTabIndex: (gscProps) => { dispatch({ type: rts.handleHideTabIndex, dispatch, gscProps }) },
    handleSetSwiperContent: (swiperContent, gscProps) => { dispatch({ type: rts.handleSetSwiperContent, dispatch, swiperContent, gscProps }) },
    handleSetTabIndex: (tabIndex) => { dispatch({ type: rts.handleSetTabIndex, dispatch, tabIndex }) },
    handleSetTabIndexByKey: (key) => { dispatch({ type: rts.handleSetTabIndexByKey, dispatch, key }) },
    handleSetTabView: (tvw) => { dispatch({ type: rts.handleSetTabView, dispatch, tvw }) },
    handleSwipeChangeIndex: (index) => { dispatch({ type: rts.handleSwipeChangeIndex, dispatch, index }) },
    handleSwiperContentInit: (gscProps) => { dispatch({ type: rts.handleSwiperContentInit, dispatch, gscProps }) },
  }
}