import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Uploader from '../../../components/uploading/Uploader';
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { gEnums } from '../../../enums/globalEnums';
import ImageGallery from '../../../components/imaging/ImageGallery';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { DataModificationsContext } from '../UiDataModifications';
import { dataModificationTypes } from '../../../viewSettings/enums/itemActionTypes';

/** Component for Uploading Images 
  @description updates using (storage_handlers.handleUpdate_gallery)
 */
const UiUpload = (props) => {

  const { handleCancel: _handleCancel } = props ? props : {}

  console.log('UiUpload', props)

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, storage_state } = states ? states : {}
  const { currentGalleryItems, selectedItems } = storage_state ? storage_state : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { storageOptions } = aps_global ? aps_global : {}
  const { useClientProfileImage } = aps_appUserSettings ? aps_appUserSettings : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ? dataManagementContext : {}
  const { viewItem, dataManagementType } = dataManagement_state ? dataManagement_state : {}
  const { key: viewItemKey } = viewItem ? viewItem : {}

  // dataManagementContext
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_state, dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}
  const { dataModificationType } = dataModifications_state ? dataModifications_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem_key, dataName } = item_state ? item_state : {}
  const _viewItemKey = viewItemKey ? viewItemKey : viewItem_key

  let topperCaption;
  let storageLocationType = gEnums.storageLocationTypes.pageDirect
  let storageType = gEnums.storageTypes.image
  let useFull = true

  // local state
  const [selectedImage, setSelectedImage] = useState()
  const [showUpload, setShowUpload] = useState(props.showUpload)

  switch (dataManagementType) {
    case dataModificationTypes.bulkImageUpload:
      storageLocationType = gEnums.storageLocationTypes.clientProfiles
      topperCaption = showUpload ? 'Bulk Upload' : 'Bulk Images'
      break;
    default:
      topperCaption = showUpload ? 'Upload' : 'Images'
      useFull = false
      // nothing
      break;
  }

  switch (dataModificationType) {
    case dataModificationTypes.pdfs:
      topperCaption = showUpload ? 'Upload' : 'PDFs'
      storageLocationType = gEnums.storageLocationTypes.pagePdf
      storageType = gEnums.storageTypes.pdf
      break;
    default:
      // nothing
      break;
  }

  const handleShowUpload = () => setShowUpload(!showUpload)

  useEffect(() => {
    let _selected;
    if (currentGalleryItems && selectedItems) {
      Object.keys(selectedItems).forEach(key => {
        if (currentGalleryItems[key]) {
          _selected = { key, image: currentGalleryItems[key] }
        }
      })
    }
    setSelectedImage(_selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGalleryItems, selectedItems]);

  const handleCancel = () => {
    _handleCancel && _handleCancel()
    dataManagement_handlers && dataManagement_handlers.handleSelectDataActionOption('options')
    dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
  }

  // const handleSelect = () => storage_handlers.handleUpdate_gallery(selectedImage, _viewItemKey)

  const getTopperCaption2 = () => {
    const _caption = dataName ? dataName : _.startCase(_viewItemKey)
    if (useClientProfileImage) {
      return _caption + ' (to client)'
    } else {
      return _caption
    }
  }

  const wrapper_upload = () => <Wrapper
    content={<Uploader
      handleCancel={handleCancel}
      origin={'uiUpload'}
      storageLocationType={storageLocationType}
      viewItemKey={_viewItemKey}
    />}
    css={{ footer: 'modify' }}
    wrapperType={wrapperTypes.upload}
  />

  const imageGallery = () => <ImageGallery
    storageLocationType={storageLocationType}
    storageType={storageType}
  />

  // current image gallery
  const fpw_images = () => <FullPageWrapper
    content={imageGallery()}
    topperCaption={topperCaption}
    topperCaption2={getTopperCaption2()}
    handleCancel={handleCancel}
    isBack={props && props.handleCancel ? true : false}
    fullMode={useFull}
  />

  const fpw_upload = () => <FullPageWrapper
    content={wrapper_upload()}
    topperCaption={topperCaption}
    topperCaption2={getTopperCaption2()}
    handleCancel={handleCancel}
    isBack={true}
    fullMode={useFull}
  />

  return showUpload ? fpw_upload() : fpw_images()
}

export default UiUpload