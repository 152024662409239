import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { allPageTypes } from '../enums/allPageTypes';
import { validateUiActionAccess } from './actionAccessPermissions';

const publicViews = [allPageTypes.signIn, allPageTypes.videoConference, allPageTypes.signUp, allPageTypes.appProfile, allPageTypes.appUserInfo, allPageTypes.help]

const accessAreaTypes = {
  page: 'page',
  pageItem: 'pageItem',
  propSection: 'propSection',
  prop: 'props'
}

/**
 * 
 * @param {object} appUser 
 * @returns appUserAccess OR appAccess_cloned
 */
export const getAppUserAccess = (appUser) => {
  const { appUserAccess, appAccess_cloned } = appUser ? appUser : {}
  return appAccess_cloned ? appAccess_cloned : appUserAccess
}

/**
 * 
 * @param {object} appUserAccess 
 * @param {string} appUserCollection 
 * @returns accessPermission functions for the current appUser
 */
export const appUserAccessPermissions = (appUser, appUserCollection) => {

  const { appUserAccess: appUserAccess_actual } = appUser ? appUser : {}
  const { isAdminOrSuper } = appUserAccess_actual ? appUserAccess_actual : {}
  const appUserAccess = getAppUserAccess(appUser)

  const { appUserSessionKey, loggedIn } = appUserAccess ? appUserAccess : {}

  /**
 * Returns true if the appUser's accessLevel is >= the itemAppUserLevel
 * @param {number} itemAppUserLevel 
 * @param {boolean} meOnly 
 * @param {array} userArray 
 * @returns allow (boolean) 
 */
  const validateAccess_item = (itemAppUserLevel, meOnly, userArray) => {
    const { accessLevel, uid, email, phoneNumber } = appUserAccess ? appUserAccess : {}
    if (meOnly) {
      return uid === 'isOUmqKoJGOrd6ncgWMxjClA1Cd2'
    } else {
      let { value } = itemAppUserLevel ? itemAppUserLevel : {}
      if (!value && _.isNumber(itemAppUserLevel)) { value = itemAppUserLevel }
      let allowed = accessLevel >= value
      if (!allowed && userArray && (email || phoneNumber)) {
        if (email && userArray.includes(email)) { allowed = true }
        if (phoneNumber && userArray.includes(phoneNumber)) { allowed = true }
      }
      return allowed
    }
  }

  const validateAccess_items = (items) => {

    const { accessLevel: accessLevel_appUser, appUserSession } = appUserAccess ? appUserAccess : {}

    if (items) {
      Object.keys(items).forEach(k => {
        const item = items[k]
        const { accessManagement, dataSource, dataConstraints } = item ? item : {}
        const _dataConstraints = dataConstraints ? dataConstraints : dataSource
        const { accessLevel: accessLevel_item } = accessManagement ? accessManagement : {}
        const { useAppUserDataConstraints, appUserDataConstraintItems } = _dataConstraints ? _dataConstraints : {}

        if (accessLevel_item) {
          if ((accessLevel_item > accessLevel_appUser || !accessLevel_appUser)) {
            item.viewPermissionType = isAdminOrSuper ? gEnums.viewPermissionTypes.allowToAppUserAdmin : gEnums.viewPermissionTypes.deny
          }
        }

        if (appUserSession && useAppUserDataConstraints && appUserDataConstraintItems && appUserDataConstraintItems.length > 0) {
          appUserDataConstraintItems.forEach(audci => {
            const { dataPropName, propValue } = audci
            const appUserSessionValue = appUserSession[dataPropName]
            if (appUserSessionValue && propValue) {
              if (!appUserSessionValue.includes(propValue)) {
                // item.viewPermissionType = isAdminOrSuper ? gEnums.viewPermissionTypes.allowToAppUserAdmin : gEnums.viewPermissionTypes.deny
              }
            }
          })
        }
      })
      // }
    }

  }

  /**
   * Returns true if the appUser has the event in listed in their profile AND they are on the page that is theirs
   * @param {object} pathViews 
   * @returns allow (boolean)
   */
  const validateAccess_eventUserPage = (pathViews) => {
    const allow = validateAccess_event(pathViews)
    if (allow) {
      return pathViews[appUserCollection] === appUserSessionKey
    }
    return false
  }

  /**
 * Returns true if the appUser has the event in listed in their profile
 * @param {object} pathViews 
 * @returns allow (boolean)
 */
  const validateAccess_appUser = (props) => {
    const { pathViews, appUserCollection, view, viewKey, appUserAccess } = props
    const { events: eventKey, clients: clientKey } = pathViews ? pathViews : {}
    const { appUserSessionKey } = appUserAccess ? appUserAccess : {}
    if (clientKey && eventKey && appUserCollection === view && appUserSessionKey === viewKey) {
      return true
    }
    return false
  }

  /**
   * Returns true if the appUser has the event in listed in their profile
   * @param {object} pathViews 
   * @returns allow (boolean)
   */
  const validateAccess_event = (pathViews) => {
    const { events: eventKey, clients: clientKey } = pathViews ? pathViews : {}
    const { clients: clients_appUser, events: events_appUser } = appUserAccess ? appUserAccess : {}
    let allow = events_appUser && events_appUser[eventKey] ? true : false
    if (!allow) {
      allow = clients_appUser && clients_appUser[clientKey] && clients_appUser[clientKey].events && clients_appUser[clientKey].events[eventKey] ? true : false
    }
    // if (!allow) { allow = isSuperAdmin }
    return allow
  }

  const validateAccess_action = (validationType, viPermissions, view, viewKey, viewItemKey) => {

    const { accessLevel } = appUserAccess ? appUserAccess : {}
    let { allowAdd, allowEdit } = viPermissions ? viPermissions : {}

    if (loggedIn) {
      switch (validationType) {
        case 'adminMenuItems':
        case 'adminDDItems':
          if (accessLevel >= gEnums.accessLevels.admin.value) {
            return true
          }
          break;

        case 'allowAdd':
          if (accessLevel >= gEnums.accessLevels.admin.value) {
            if (!viewKey && allowAdd && (accessLevel >= allowAdd)) {
              return true
            }
          }
          break;
        case 'allowEdit':
          if (accessLevel >= gEnums.accessLevels.admin.value) {
            if (viewItemKey === view && allowEdit && (accessLevel >= allowEdit)) {
              return true
            }
          }
          break;
        default:
        // nothing
      }
    }

    return false

  }

  const validateAccess_app = (appStatus) => {
    const { eventStatusType, restrictedAuthLevel } = appStatus
    switch (eventStatusType) {
      case gEnums.eventStatusTypes.restricted:
        const accessLevel = get_accessLevel()
        if (accessLevel >= restrictedAuthLevel) {
          return gEnums.eventStatusTypes.active
        } else {
          return gEnums.eventStatusTypes.restricted
        }
      default:
        return gEnums.eventStatusTypes.active
    }
  }

  /**
   * 
   * @param {object} aps_page 
   * @param {object} paps_state 
   * @param {object} aps_global 
   * @param {object} aps_appUserSettings 
   * @returns page access for the appUser
   */
  const validateAccess_pageView = (aps_page, paps_state, aps_global, aps_appUserSettings) => {

    const { viewKey, otherView, pathViews } = paps_state ? paps_state : {}
    const { viewItems: viewItems_page } = aps_page ? aps_page : {}
    const { appConversations, appNotifications, appNotes, appAccess } = aps_global ? aps_global : {}
    const { allowTempAuthEmail, appAccessType } = appAccess ? appAccess : {}
    const { allowConversations } = appConversations ? appConversations : {}
    const { allowNotifications } = appNotifications ? appNotifications : {}
    const { allowNotes } = appNotes ? appNotes : {}

    const a = gEnums.viewPermissionTypes.allow
    const d = gEnums.viewPermissionTypes.deny

    let isPublicSite = false

    switch (appAccessType) {
      case gEnums.siteAuthorizationTypes.public:
      case gEnums.siteAuthorizationTypes.passcode:
        isPublicSite = true
        break;
      default:
      // nothing
    }

    let allowPageView = isPublicSite ? a : d

    if (publicViews.includes(otherView)) {
      return { allow: allowPageView, viewItems_page }
    } else {
      // first check to see if the appUserAccess is logged in
      //  
      if (isPublicSite || (appUserAccess && appUserAccess.loggedIn)) {

        allowPageView = a

        if (viewItems_page) {
          // check the view's auth 
          allowPageView = isPublicSite ? a : validateAccess(accessAreaTypes.page, appUserAccess, pathViews, aps_page, isPublicSite, viewKey, aps_appUserSettings)

          if (allowPageView === a) {

            Object.values(viewItems_page).forEach(viewItem_page => {

              if (_.isObject(viewItem_page)) {
                viewItem_page.viewPermissionType = allowPageView ? a : d

                // check the viewItem_page's auth  
                viewItem_page.viewPermissionType = isPublicSite ? a : validateAccess(accessAreaTypes.pageItem, appUserAccess, pathViews, viewItem_page, isPublicSite, viewKey, aps_appUserSettings)

                switch (viewItem_page.key) {
                  case allPageTypes.conversations:
                    if (!allowConversations) { viewItem_page.viewPermissionType = d }
                    break;
                  case allPageTypes.notifications:
                    if (!allowNotifications) { viewItem_page.viewPermissionType = d }
                    break;
                  case allPageTypes.notes:
                    if (!allowNotes) { viewItem_page.viewPermissionType = d }
                    break;
                  default:
                  // nothing
                }
              }
            })
          }

          if (publicViews.includes(otherView)) {
            // nothing
            // return { allow: allowPageView, viewItems_page }
          } else {
            switch (allowPageView) {
              case gEnums.viewPermissionTypes.allow:
              case gEnums.viewPermissionTypes.allowToAppUserAdmin:
                break;
              default:
                if (allowTempAuthEmail) {
                  allowPageView = gEnums.viewPermissionTypes.allowTemp
                }
            }
          }
        }
      }
    }

    switch (allowPageView) {
      case gEnums.viewPermissionTypes.allow:
      case gEnums.viewPermissionTypes.allowToAppUserAdmin:
        break;
      default:
        if (allowTempAuthEmail) {
          allowPageView = gEnums.viewPermissionTypes.allowTemp
        }
    }

    return { allow: allowPageView, vip: viewItems_page }
  }

  /** 
 * @param {object} appUser 
 * @param {object} pathViews 
 * @param {string} propSection 
 * @param {object} drillDown 
 * @param {object} displayContext 
 * @param {object} display_viewItem 
 * @param {boolean} isPublicSite 
 * @param {string} viewKey 
 * @returns section access for the appUser
 */
  const validateAccess_section = (pathViews, propSection, drillDown, displayContext, display_viewItem, isPublicSite, viewKey) => {

    const { displayType, cardDisplayType } = display_viewItem ? display_viewItem : {}

    const { display_state } = displayContext ? displayContext : {}
    const { ddGroupIndex, ddGroupKey, ddProps } = display_state ? display_state : {}

    let allowSection = isPublicSite ? gEnums.viewPermissionTypes.allow : validateAccess(accessAreaTypes.propSection, appUserAccess, pathViews, propSection, isPublicSite, viewKey)

    const { useDrillDown, section1: ddSection1, drillDownType } = drillDown ? drillDown : {}

    if (propSection.key !== 'name' && cardDisplayType === gEnums.cardDisplayTypes.nameSectionOnly) {
      return gEnums.viewPermissionTypes.deny
    }

    if (allowSection === gEnums.viewPermissionTypes.allow) {

      if (useDrillDown && drillDownType === gEnums.drillDownTypes.sections && ddSection1) {
        let ddg = 'section1'
        if (ddGroupIndex) { ddg = 'section' + ddGroupIndex }
        if (!drillDown[ddg].includes(propSection.key)) {
          return gEnums.viewPermissionTypes.deny
        }
      }

      if (ddGroupKey) {
        if (propSection.key !== ddGroupKey) {
          return gEnums.viewPermissionTypes.deny
        }
      }

      if (ddProps) {
        if (!ddProps[propSection.key]) {
          return gEnums.viewPermissionTypes.deny
        }
      }

      switch (displayType) {
        case gEnums.displayTypes.card:
          if (cardDisplayType === gEnums.cardDisplayTypes.nameSectionOnly) {
            switch (propSection.key) {
              case 'name':
                // nothing
                break;
              default:
                return gEnums.viewPermissionTypes.deny
            }
            // if (propSection.key !== 'name') { return gEnums.viewPermissionTypes.deny }
          }
          break;
        default:
        // nothing
      }
    }

    return allowSection

  }

  /** 
  * @param {boolean} isPublicSite 
  * @param {object} pathViews 
  * @param {object} props_viewItem 
  * @param {object} display 
  * @param {string} viewKey 
  * @returns prop access for the appUser
  */
  const validateAccess_props = (isPublicSite, pathViews, props_viewItem, display, viewKey) => {

    const { cardDisplayType, taggedPropsOnly } = display ? display : {}

    if (props_viewItem) {

      Object.keys(props_viewItem).forEach(key => {

        const _prop = props_viewItem[key]

        try {
          _prop._allowProp = isPublicSite ? gEnums.viewPermissionTypes.allow : validateAccess(accessAreaTypes.prop, appUserAccess, pathViews, _prop, isPublicSite, viewKey)

          if (!_prop.show) { _prop._allowProp = false }

          switch (cardDisplayType) {
            case gEnums.cardDisplayTypes.profile:
            case gEnums.cardDisplayTypes.schedule:
              _prop._allowProp = _prop.tagged ? gEnums.viewPermissionTypes.allow : gEnums.viewPermissionTypes.deny
              break;
            default:
              if (taggedPropsOnly) {
                if (!_prop.tagged) {
                  _prop._allowProp = isPublicSite ? gEnums.viewPermissionTypes.allow : gEnums.viewPermissionTypes.deny
                } else {
                  _prop._allowProp = gEnums.viewPermissionTypes.allow
                }
              }
          }
        } catch (error) {

        }
      })
    }
  }

  const validateAccess_sidebar = (isPublicSite) => {
    const { loggedIn } = appUserAccess ? appUserAccess : {}
    let allow = isPublicSite ? gEnums.viewPermissionTypes.allow : gEnums.viewPermissionTypes.deny
    if (loggedIn) { allow = gEnums.viewPermissionTypes.allow }
    return allow
  }

  const validate_settingsAuth = (forPageArea) => {
    const { accessLevel } = appUserAccess ? appUserAccess : {}
    if (accessLevel >= gEnums.authLevels.appSubAdmin.value) { return gEnums.viewPermissionTypes.allow }
    if (forPageArea && accessLevel >= gEnums.authLevels.admin.value) { return gEnums.viewPermissionTypes.allow }
    return gEnums.viewPermissionTypes.deny
  }

  const validate_trueSettingsAuth = (forPageArea) => {
    const { appUserAccess: appUserAccess_true } = appUser ? appUser : {}
    const { accessLevel } = appUserAccess_true ? appUserAccess_true : {}
    if (accessLevel >= gEnums.authLevels.appSubAdmin.value) { return gEnums.viewPermissionTypes.allow }
    if (forPageArea && accessLevel >= gEnums.authLevels.admin.value) { return gEnums.viewPermissionTypes.allow }
    return gEnums.viewPermissionTypes.deny
  }

  const validate_settingsMenuAuth = () => {
    const { accessLevel } = appUserAccess ? appUserAccess : {}
    if (accessLevel >= gEnums.authLevels.appSubAdmin.value) { return gEnums.viewPermissionTypes.allow }
    return gEnums.viewPermissionTypes.deny
  }

  const get_accessLevel = () => {
    const { accessLevel } = appUserAccess ? appUserAccess : {}
    return accessLevel
  }

  const get_authColor = () => {
    const { accessLevel } = appUserAccess ? appUserAccess : {}
    return accessLevel
  }

  const get_profileData = () => {
    const { appUser_cloned } = appUser ? appUser : {}
    const _appUser = appUser_cloned ? appUser_cloned : appUser
    const { profileData } = _appUser ? _appUser : {}
    return profileData
  }

  const validateAccess = (accessAreaTypes, appUserAccess, pathViews, auth_setting, isPublicSite, viewKey, aps_appUserSettings) => {

    let { accessManagement } = auth_setting ? auth_setting : {}
    const { accessLevel: accessLevel_appUser, clientAccessKey, appUserType, appUserSessionKey, email, phoneNumber } = appUserAccess ? appUserAccess : {}
    const { clients: clientKey } = pathViews ? pathViews : {}

    const { appUserCollection, allowConnections, connectWith } = aps_appUserSettings ? aps_appUserSettings : {}

    // the auth for the authAreaType
    // eslint-disable-next-line
    const { publicView, accessLevel, restrictToAppUserOnly, accessUsers } = accessManagement ? accessManagement : {}

    // if publicView or isPublicSite, allow
    switch (accessAreaTypes) {
      case accessAreaTypes.page:
      case accessAreaTypes.pageItem:
        if (publicView || isPublicSite) { return gEnums.viewPermissionTypes.allow }
        break;
      default:
      // nothing
    }

    switch (appUserType) {
      // this are ME
      case gEnums.appUserTypes.admin:
      case gEnums.appUserTypes.superAdmin:
        break;

      // if a appAdmin or appProfile, validate the path
      case gEnums.appUserTypes.appDataAdmin:
      case gEnums.appUserTypes.appAdmin:
      case gEnums.appUserTypes.appSubAdmin:
        if (clientKey !== clientAccessKey) {
          return gEnums.viewPermissionTypes.deny_noClientKey
        }
        break;

      case gEnums.appUserTypes.appProfile:
        if (clientKey) {
          if (clientKey !== clientAccessKey) {
            return gEnums.viewPermissionTypes.deny_noClientKey
          }
          if (validateAccess_event(pathViews)) {
            // if (validateAppAccess_event(appUserAccess, pathViews)) {
            // do nothing
          } else {
            return gEnums.viewPermissionTypes.allow_clientLimited
            // return gEnums.viewPermissionTypes.deny_noEventKey
          }
        } else {
          return gEnums.viewPermissionTypes.allow_clientLimited
          // return gEnums.viewPermissionTypes.deny_noEventKey
        }
        break;

      case gEnums.appUserTypes.appUser:
        if (validateAccess_event(pathViews)) {
          // if (validateAppAccess_event(appUserAccess, pathViews)) {
          // do nothing
        } else {
          return gEnums.viewPermissionTypes.allow_clientLimited
          // return gEnums.viewPermissionTypes.deny_noEventKey
        }
        break;

      default:
        return gEnums.viewPermissionTypes.deny
    }

    // if any below fail, return deny
    if (accessLevel) {
      if (accessLevel_appUser < accessLevel) {
        if (accessUsers && (email || phoneNumber)) {
          if (email && accessUsers.includes(email)) { return gEnums.viewPermissionTypes.allow }
          if (phoneNumber && accessUsers.includes(phoneNumber)) { return gEnums.viewPermissionTypes.allow }
        }
        return gEnums.viewPermissionTypes.deny
      }
    }

    if (restrictToAppUserOnly) {
      if (appUserSessionKey === viewKey) {
        return gEnums.viewPermissionTypes.allow
      } else {
        // allow if admin
        if (accessLevel_appUser >= gEnums.accessLevels.appAdmin.value) {
          return gEnums.viewPermissionTypes.allowToAppUserAdmin
        } else {
          if (allowConnections && connectWith && connectWith.includes(appUserCollection)) {
            return gEnums.viewPermissionTypes.allow
          } else {
            return gEnums.viewPermissionTypes.deny
          }
        }
      }
    }

    return gEnums.viewPermissionTypes.allow

  }

  return {
    get_accessLevel: () => get_accessLevel(),
    get_authColor: () => get_authColor(),
    get_profileData: () => get_profileData(),
    validate_settingsAuth: (forPageArea) => validate_settingsAuth(forPageArea),
    validate_settingsMenuAuth: () => validate_settingsMenuAuth(),
    validate_trueSettingsAuth: (forPageArea) => validate_trueSettingsAuth(forPageArea),
    validateAccess_action: (validationType, viPermissions, view, viewKey, viewItemKey) => validateAccess_action(validationType, viPermissions, view, viewKey, viewItemKey),
    validateAccess_app: (appStatus) => validateAccess_app(appStatus),
    validateAccess_event: (pathViews) => validateAccess_event(pathViews),
    validateAccess_item: (itemAppUserLevel, meOnly, userArray) => validateAccess_item(itemAppUserLevel, meOnly, userArray),
    validateAccess_items: (itemAppUserLevel, meOnly, userArray) => validateAccess_items(itemAppUserLevel, meOnly, userArray),
    validateAccess_pageView: (aps_page, paps_state, aps_global, aps_appUserSettings) => validateAccess_pageView(aps_page, paps_state, aps_global, aps_appUserSettings),
    validateAccess_props: (isPublicSite, pathViews, props_viewItem, display, viewKey) => validateAccess_props(isPublicSite, pathViews, props_viewItem, display, viewKey),
    validateAccess_section: (pathViews, propSection, drillDown, displayContext, display_viewItem, isPublicSite, viewKey) => validateAccess_section(pathViews, propSection, drillDown, displayContext, display_viewItem, isPublicSite, viewKey),
    validateAccess_sidebar: (isPublicSite) => validateAccess_sidebar(isPublicSite),
    validateAccess_uiAction: (props) => validateUiActionAccess({ ...props, appUserAccess }),
    validateAccess_eventUserPage: (pathViews) => validateAccess_eventUserPage(pathViews),
    validateAccess_appUser: (pathViews) => validateAccess_appUser(pathViews),
  }
}