import _ from 'lodash';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { updateParentKeyData_sports } from './reducerHelpers/parentKeySportsData';
import { getPks, seasonalUpdateTypes, updateSeasonalMatches } from './reducerHelpers/seasonalSportsData';
import { updateCurrentRealtimeMatchScore, updateTheMatch } from '../../dbActions/updateMatch';
import { calcVballMatch } from '../../helpers/match';
import { matchUpdateTypes } from './SportsSeasonReducer';
import { deleteRealtimeDatabaseScores } from '../../dbActions/realtimeScoring';

const rts = {
  handleConfirmMatchPending: 'handleConfirmMatchPending',
  handleCreateMatchesFromList: 'handleCreateMatchesFromList',
  handleEditScore: 'handleEditScore',
  handleLatestSeason: 'handleLatestSeason',
  handleScoresOn: 'handleScoresOn',
  handleSelectAthletes: 'handleSelectAthletes',
  handleSet_trueMatch: 'handleSet_trueMatch',
  handleShowMatchPending: 'handleShowMatchPending',
  handleShowMatchScore: 'handleShowMatchScore',
  handleShowMatchScoreTable: 'handleShowMatchScoreTable',
  handleShow_rtMatch: 'handleShow_rtMatch',
  handleShowScore: 'handleShowScore',
  setPopupOpen: 'setPopupOpen',
  handleSet_rtMatch: 'handleSet_rtMatch',

  handleRtChange_setScores: 'handleRtChange_setScores',
  handleRtChange_score: 'handleRtChange_score',
  handleRtChange_set: 'handleRtChange_set',
  handleRt_update: 'handleRt_update',
  handleRtUpdate_trueMatch: 'handleRtUpdate_trueMatch',
  handleRtDelete_scores: 'handleRtDelete_scores'
}

export const sportsRtMatchReducer = (state, action) => {

  const {
    appUserAccess,
    match_true,
    handleShowScore,
    latestSeason,
    matchPendingUpdate,
    pathViews,
    playoffMatches,
    match_rt,
    sortedAthletes,
    sportsKey,
    sportsSeason_handlers,
  } = state

  const { type, dispatch } = action

  const { handleRtChange_score, handleRtChange_set } = sportsRtMatchHandlers(dispatch)
  const { handleUpdateSportsSeasonTs } = sportsSeason_handlers ? sportsSeason_handlers : {}
  const _match_rt = match_rt ? { ...match_rt } : null

  switch (type) {

    // ********************** REALTIME **********************

    case rts.handleShow_rtMatch:
      if (action.match_rt) {
        return { ...state, showRealtimeScoringSelected: !state.showRealtimeScoringSelected, match_rt_selected: action.match_rt }
      } else {
        return { ...state, showRealtimeScoring: !state.showRealtimeScoring }
      }

    case rts.handleSet_trueMatch:
      return { ...state, match_true: action.match_true, match_key: action.match_true._itemKey }

    case rts.handleSet_rtMatch:
      const rtm = { ...action.match_rt }
      return { ...state, match_rt: rtm }

    case rts.handleRt_update:
      handleRealTimeChange(action, handleRtChange_score, handleRtChange_set)
      return { ...state }

    case rts.handleRtChange_setScores:
      const _rtm = action.match_rt
      calcAndUpdate({ results: _rtm }, match_true, pathViews, appUserAccess, latestSeason)
      return { ...state, match_rt: _rtm }

    case rts.handleRtChange_set:
      _match_rt.setNumber = action.setNumber
      if (!_match_rt.scores[_match_rt.setNumber]) {
        _match_rt.scores[_match_rt.setNumber] = createNewSet()
      }
      return { ...state, match_rt: _match_rt }

    case rts.handleRtDelete_scores:
      deleteRealtimeDatabaseScores(pathViews, state.match_key, appUserAccess, latestSeason)
      return { ...state, match_rt: null }

    case rts.handleRtChange_score:
      if (!_match_rt.scores) { _match_rt.scores = {} }
      if (!_match_rt.setNumber) { _match_rt.setNumber = 1 }
      if (!_match_rt.scores[_match_rt.setNumber]) {
        _match_rt.scores[_match_rt.setNumber] = createNewSet()
      }
      _match_rt.scores[_match_rt.setNumber][action.team] = action.value
      calcAndUpdate({ results: _match_rt }, match_true, pathViews, appUserAccess, latestSeason)

      return { ...state, match_rt: _match_rt }

    case rts.handleEditScore:
      return { ...state, match_true: action.match, showMatchScoreTable: false }

    case rts.handleLatestSeason:
      return { ...state, latestSeason: action.latestSeason }

    case rts.handleRtUpdate_trueMatch:
      const updatePropsRt = { match_current: match_true, match_rt: action.match_rt, playoffMatches }
      updateTheMatch(matchUpdateTypes.updateScore, pathViews, latestSeason, sportsKey, updatePropsRt, appUserAccess, handleUpdateSportsSeasonTs)
      return { ...state }

    // ********************** PENDING **********************
    case rts.handleShowMatchPending:
      return { ...state, matchPendingUpdate: action.actionProps, popupOpen: false }

    case rts.setPopupOpen:
      return { ...state, popupOpen: !state.popupOpen }

    case rts.handleConfirmMatchPending:

      matchPendingUpdate.match_current = match_true

      const { matchUpdateType } = matchPendingUpdate
      const actionProps = matchPendingUpdate

      switch (matchUpdateType) {

        case matchUpdateTypes.notifyMatch:
          // const sendProps = appUserAccess ? { profileId: appUserAccess.profileId, fcmTokens: appUserAccess.fcmTokens } : {}
          // const topicData = getNotificationData(notificationTypes.notification, pathViews, { topic: viewKey }, { title: 'Match Results', body: scoreDisplayFull, clickPath: pathName })
          // fsfn_sendNotification(sendProps, topicData, pathViews)
          break;

        case matchUpdateTypes.addToHistory:
          const { startDate, results, levels } = match_true
          const { winner, loser } = results ? results : {}
          if (winner && loser) {
            const d = new Date(startDate)
            const year = d.getFullYear()
            const data = {
              [levels]: {
                [year]: {
                  winner: winner.name,
                  runnerUp: loser.name
                }
              }
            }
            updateParentKeyData_sports('_history', pathViews, 'history', data)
          }
          break;

        case matchUpdateTypes.addConsolationMatch:
          console.log('actionProps', actionProps)
          break;

        case matchUpdateTypes.cancelMatch:
        case matchUpdateTypes.confirmMatchDate:
        case matchUpdateTypes.deleteMatch:
        case matchUpdateTypes.deleteRealtimeMatch:
        case matchUpdateTypes.deleteScore:
        case matchUpdateTypes.postponeMatch:
        case matchUpdateTypes.resetByeMatch:
        case matchUpdateTypes.updateMatchDate:
        case matchUpdateTypes.updateMatchLocation:
        case matchUpdateTypes.updateScore:
          actionProps.match_rt = _match_rt
          updateTheMatch(matchUpdateType, pathViews, latestSeason, sportsKey, actionProps, appUserAccess, handleUpdateSportsSeasonTs)
          break;

        case matchUpdateTypes.updateMatchListResults:
        case matchUpdateTypes.updateMatchListResultsFromGs:
          const _cb = (res) => {
            console.log('res', res)
          }

          const { matchesChanged, matchesNew, matchUpdates } = state
          const { toCreate, toUpdate } = matchUpdates
          const { all: all_update } = toUpdate
          const { all: all_create } = toCreate

          let dataToUpdate = {}

          if (all_update && all_create) {
            dataToUpdate = { ...all_update, ...all_create }
          } else if (all_create) {
            dataToUpdate = { ...all_create }
          } else if (all_update) {
            dataToUpdate = { ...all_update }
          }

          if (dataToUpdate && Object.keys(dataToUpdate).length > 0) {
            updateSeasonalMatches(seasonalUpdateTypes.matchesList, pathViews, getPks(pathViews, latestSeason, sportsKey), dataToUpdate, 'matches', _cb)
          }
          // if (all_create && Object.keys(all_create).length > 0) {
          //   updateSeasonalMatches(pathViews, getPks(pathViews, latestSeason, sportsKey), all_create, 'matches', _cb)
          // }
          // updateSeasonalMatches(pathViews, getPks(pathViews, 2023), nonSection, 'matchesNonSection', _cb)
          break;
        default:
          // sportsSeason_handlers.handleUpdateMatch(matchPendingUpdate)
          break;
      }

      handleShowScore && handleShowScore()
      return { ...state }

    case rts.handleShowMatchScore:
      return { ...state, showMatchScoreTable: !state.showMatchScoreTable }

    case rts.handleScoresOn:
      return { ...state, scoresOn: !state.scoresOn }

    case rts.handleShowScore:
      action.e && action.e.preventDefault()
      action.e && action.e.stopPropagation()
      return { ...state, showScore: !state.showScore }

    case rts.handleShowMatchScoreTable:
      return { ...state, scoresOn: false, showMatchScoreTable: !state.showMatchScoreTable }

    // ********************** ATHLETES **********************
    case rts.handleSelectAthletes:
      let sa;
      if (action.currentAthletes) {
        const ath = {}
        Object.keys(action.currentAthletes).forEach(key => {
          ath[key] = action.currentAthletes[key]
          ath[key].name = ath[key].firstName + ' ' + ath[key].lastName
        })
        sa = _.sortBy(ath, 'position')
      }

      return { ...state, currentAthletes: action.currentAthletes, sortedAthletes: sa }

    case rts.handleCreateMatchesFromList:
      return { ...state, matchUpdates: createMatchesFromList(action.data) }

    default:
      return { ...state }
  }
}

export const sportsRtMatchHandlers = (dispatch) => {
  return {
    // match_rt, rtType, add, currentSetNumber
    handleRt_update: (match_rt, rtType, add, currentSetNumber) => { dispatch({ dispatch, type: rts.handleRt_update, match_rt, rtType, add, currentSetNumber }) },
    handleRtChange_score: (value, team) => { dispatch({ type: rts.handleRtChange_score, dispatch, value, team }) },
    handleRtChange_set: (setNumber) => { dispatch({ type: rts.handleRtChange_set, dispatch, setNumber }) },
    handleRtChange_setScores: (match_rt) => { dispatch({ type: rts.handleRtChange_setScores, match_rt }) },
    handleRtDelete_scores: () => { dispatch({ type: rts.handleRtDelete_scores, dispatch }) },
    handleRtUpdate_trueMatch: (match_rt) => { dispatch({ type: rts.handleRtUpdate_trueMatch, match_rt }) },
    handleSet_rtMatch: (match_rt) => { dispatch({ type: rts.handleSet_rtMatch, match_rt }) },

    handleConfirmMatchPending: () => { dispatch({ type: rts.handleConfirmMatchPending }) },
    handleCreateMatchesFromList: (data) => { dispatch({ type: rts.handleCreateMatchesFromList, data }) },
    handleEditScore: (match) => { dispatch({ type: rts.handleEditScore, match }) },
    handleLatestSeason: (latestSeason) => { dispatch({ type: rts.handleLatestSeason, latestSeason }) },
    handleScoresOn: () => { dispatch({ type: rts.handleScoresOn }) },
    handleSelectAthletes: (currentAthletes) => { dispatch({ type: rts.handleSelectAthletes, currentAthletes }) },
    handleSet_trueMatch: (match_true) => { dispatch({ type: rts.handleSet_trueMatch, match_true }) },
    handleShow_rtMatch: (match_rt) => { dispatch({ type: rts.handleShow_rtMatch, match_rt }) },
    handleShowMatchPending: (actionProps) => { dispatch({ type: rts.handleShowMatchPending, actionProps }) },
    handleShowMatchScore: () => { dispatch({ type: rts.handleShowMatchScore }) },
    handleShowMatchScoreTable: (match) => { dispatch({ type: rts.handleShowMatchScoreTable, match }) },
    handleShowScore: (e) => { dispatch({ type: rts.handleShowScore, e }) },
    setPopupOpen: () => { dispatch({ type: rts.setPopupOpen }) },
  }
}

export const sportsRtMatchInitialState = (init_state) => {
  return { ...init_state, currentAthletes: {} }
};

export const ammendTeamName = (name) => {
  switch (name) {
    case 'Shaler':
      return 'Shaler Area'
    case 'Pine-Richland':
      return 'Pine Richland'
    case 'Derry':
      return 'Derry Area'
    case 'Beaver C.C.':
      return 'Beaver County Christian'
    case 'Hillcrest Academy':
      return 'Hillcrest Christian Academy'
    case 'Greensburg C.C.':
      return 'Greensburg Central Catholic'
    case 'Neighborhood A.':
      return 'Neighborhood Academy'
    case 'O.L. Sacred Heart':
      return 'O.L.S.H.'
    case 'Shaler':
      return 'Shaler Area'
    case 'Sto-Rox':
      return name
    case 'Union':
      return 'Union Area'
    default:
      return name.replace('-', ' ')
  }
}

export const matchNeedsUpdated = (matchResults, results) => {
  if (matchResults && matchResults.score && results.score)
    if (matchResults.score.home !== results.score.home || matchResults.score.away !== results.score.away) {
      return true
    }
}

export const fixTeamNames = (matches) => {
  Object.keys(matches).forEach(mk => {
    const match = matches[mk]
    const { home, away } = match ? match : {}
    match.home = ammendTeamName(home)
    match.away = ammendTeamName(away)
  })
}

const calcAndUpdate = (match, match_true, pathViews, appUserAccess, latestSeason) => {
  calcVballMatch(match)

  if (!match.results.setScorez) { match.results.setScorez = {} }
  if (!match.results.setScorez[match.results.setNumber]) { match.results.setScorez[match.results.setNumber] = [] }
  match.results.setScorez[match.results.setNumber].push(match.results.setScoreDisplay)

  const current_props = { match_rt: match.results, match_true }
  updateCurrentRealtimeMatchScore(pathViews, current_props, appUserAccess, latestSeason)
}

const createNewSet = () => {
  return {
    home: 0,
    away: 0
  }
}

const createMatchesFromList = (props) => {

  const { itemData: matchListData, teams, matches_season } = props

  const matches_updated = {
    section: {},
    nonSection: {},
  }

  const matches_created = {
    section: {},
    nonSection: {},
    nonUpdated: [],
    nonTeam: [],
    city: [],
    matchesToUpdate: null,
  }

  fixTeamNames(matchListData)

  // loop the matches_season in the matchData (list)
  if (matchListData) {

    Object.keys(matchListData).forEach(key => {

      // get the match from the list
      const match_item = matchListData[key]
      const { away, home, awayScore, homeScore, startDate, newStartDate, _new, _changed } = match_item

      // if there is a home and awau team
      if (home && away) {

        const _homeName = ammendTeamName(home)
        const _awayName = ammendTeamName(away)

        const _home = _.find(teams, { name: _homeName })
        const _away = _.find(teams, { name: _awayName })

        const { id: id_home, sections: section_home, levels: level_home } = _home ? _home : {}
        const { id: id_away, sections: section_away, levels: level_away } = _away ? _away : {}

        const isSectionMatch = (level_home === level_away) && (section_home === section_away)
        const isLevelMatch = (level_home === level_away)

        let _homeScore;
        let _awayScore;

        try {
          _homeScore = parseInt(homeScore)
          _awayScore = parseInt(awayScore)
          _homeScore = _.isNumber(_homeScore) && !isNaN(_homeScore) ? _homeScore : null
          _awayScore = _.isNumber(_awayScore) && !isNaN(_awayScore) ? _awayScore : null
        } catch (error) {

        }

        // find the match from the existing matches_season list
        const _matchSeasonFound = _.find(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const _matchSeasonFoundKey = _.findKey(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const isValidScore = _.isNumber(_homeScore) && !isNaN(_homeScore) && _.isNumber(_awayScore) && !isNaN(_awayScore) ? true : false

        // if match is found, update the score
        // if the match is not found, it is most likely a non-section match
        if (_matchSeasonFound && _matchSeasonFoundKey) {

          const results = {}

          let matchNeedsUpdating = false

          // check for a valid score

          if (isValidScore) {
            results.score = {
              home: parseInt(homeScore),
              away: parseInt(awayScore),
            }

            if (matchNeedsUpdated(_matchSeasonFound.results, results)) {
              matchNeedsUpdating = true
            }

            if (!_matchSeasonFound.results || matchNeedsUpdating) {
              // add the match to the updated object
              const _matchUpdate = { results: results }
              if (_matchSeasonFound.startDate !== startDate) {
                _matchUpdate.startDate = startDate
              }
              if (newStartDate) { _matchUpdate.startDate = newStartDate }

              if (_matchSeasonFound.sections === 'Non') {
                matches_updated.nonSection[_matchSeasonFoundKey] = _matchUpdate
              } else {
                matches_updated.section[_matchSeasonFoundKey] = _matchUpdate
              }
            }

          } else {
            if ((_matchSeasonFound.startDate !== startDate) || (_matchSeasonFound.startDate !== newStartDate)) {
              const _matchUpdate = { startDate: startDate }
              if (newStartDate) { _matchUpdate.startDate = newStartDate }
              if (_matchSeasonFound.sections === 'Non') {
                matches_updated.nonSection[_matchSeasonFoundKey] = _matchUpdate
              } else {
                matches_updated.section[_matchSeasonFoundKey] = _matchUpdate
              }
            }
          }
        } else {
          // if the match is NOT found, create it.
          if (_home && _away) {
            switch (match_item.section) {
              case 'City League':
                matches_created.city.push(match_item)
                break;
              default:
                // if (!isSectionMatch) {
                const _nsMatch = {
                  bestOf: true,
                  setCount: 5,
                  startDate: formatItem(formatTypes.fullDate, startDate),
                  startTime: '7:00 PM',
                  sections: isSectionMatch ? level_home : 'Non',
                  levels: isLevelMatch ? level_home : 'Non',
                  home: createTeam(_home),
                  away: createTeam(_away),
                  teams: [_home._itemKey, _away._itemKey]
                }
                _nsMatch.results = {}
                if (isValidScore) {
                  _nsMatch.results.score = {
                    home: parseInt(homeScore),
                    away: parseInt(awayScore),
                  }
                } else {
                  _nsMatch.results = {}
                }
                // create a new key for the match
                if (isSectionMatch) {
                  matches_created.section[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                } else {
                  matches_created.nonSection[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                }
              // }
            }
          } else {
            matches_created.nonTeam.push(match_item)
          }
        }
      }
    })

    // add the matches that have been found (matchesCreated.updated) to the matchesToUpdate array
    const matchUpdates = {
      toCreate: {
        section: {},
        nonSection: {},
        all: {},
      },
      toUpdate: {
        section: {},
        nonSection: {},
        all: {},
      }
    }

    Object.keys(matches_updated.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_updated.section[key]
      const { results, startDate } = m ? m : {}
      if (results) { matchUpdates.toUpdate.section[_mk + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.section[_mk + '.startDate'] = startDate };
      if (results) { matchUpdates.toUpdate.all[_mk + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.all[_mk + '.startDate'] = startDate };
    })

    Object.keys(matches_updated.nonSection).forEach(key => {
      const _mk = 'matchesNonSection.' + key
      const _mk2 = 'matches.' + key
      const m = matches_updated.nonSection[key]
      const { results, startDate } = m ? m : {}
      if (results) { matchUpdates.toUpdate.nonSection[_mk + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.nonSection[_mk + '.startDate'] = startDate };
      if (results) { matchUpdates.toUpdate.all[_mk2 + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.all[_mk2 + '.startDate'] = startDate };
    })

    Object.keys(matches_created.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.section[key]
      const { results } = m ? m : {}
      m.results = results
      matchUpdates.toCreate.section[key] = m;
      matchUpdates.toCreate.all[_mk] = m;
    })

    Object.keys(matches_created.nonSection).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.nonSection[key]
      const { results } = m ? m : {}
      m.results = results
      matchUpdates.toCreate.nonSection[key] = m;
      matchUpdates.toCreate.all[_mk] = m;
    })

    return matchUpdates

  }
}

const createTeam = (team) => {
  return {
    id: team._itemKey,
    levels: team.levels,
    sections: team.sections,
    name: team.name
  }
}

const handleRealTimeChange = (action, handleRtChange_score, handleRtChange_set) => {

  const { match_rt, rtType, add, currentSetNumber } = action
  const _realtimeMatch = { ...match_rt }
  const { setNumber } = _realtimeMatch
  let _setNumber = setNumber ? setNumber : 0
  if (currentSetNumber) { _setNumber = currentSetNumber }

  switch (rtType) {
    case 'away':
    case 'home':
      const sn = setNumber
      if (!_realtimeMatch.scores) { _realtimeMatch.scores = {} }
      if (!_realtimeMatch.scores[sn]) {
        _realtimeMatch.scores[sn] = {
          away: 0,
          home: 0
        }
      }
      let teamScore = _realtimeMatch.scores[sn][rtType]
      if (add) {
        teamScore++
      } else {
        teamScore--
      }
      if (teamScore < 0) { teamScore = 0 }
      handleRtChange_score(teamScore, rtType)
      break;

    case 'set':
      if (add) {
        _setNumber++
      } else {
        _setNumber--
      }
      if (_setNumber < 1) { teamScore = 1 }
      if (_setNumber > 5) { teamScore = 5 }
      handleRtChange_set(_setNumber)
      break;

    default:
      break;
  }
}

// const ammendMatchesFromGs = (gsData, matchesData, teams, matches_season, callback) => {

//   if (gsData && matchesData) {

//     const _matchesData = copyObj(matchesData)

//     Object.keys(gsData).forEach(dk => {

//       const match_gs = gsData[dk]

//       let { home: home_gs, away: away_gs } = match_gs ? match_gs : {}

//       const _homeName = ammendTeamName(home_gs)
//       const _awayName = ammendTeamName(away_gs)

//       const match_found = _.find(_matchesData, { home: _homeName, away: _awayName })
//       const match_found_key = _.findKey(_matchesData, { home: _homeName, away: _awayName })

//       if (_matchesData[match_found_key]) { delete _matchesData[match_found_key]._changed }

//       const matchScores = {
//         gs: {
//           home: match_gs.homeScore || _.isNumber(match_gs.homeScore) ? parseInt(match_gs.homeScore) : null,
//           away: match_gs.awayScore || _.isNumber(match_gs.awayScore) ? parseInt(match_gs.awayScore) : null,
//         },
//         found: match_found ? {
//           home: match_found.homeScore || _.isNumber(match_found.homeScore) ? parseInt(match_found.homeScore) : null,
//           away: match_found.awayScore || _.isNumber(match_found.awayScore) ? parseInt(match_found.awayScore) : null,
//         } : {}
//       }

//       if (match_found_key && match_found) {
//         if (_.isNumber(matchScores.gs.home) && _.isNumber(matchScores.gs.away)) {
//           if (_.isNumber(matchScores.found.home) && _.isNumber(matchScores.found.away)) {
//             if ((matchScores.gs.home !== matchScores.found.home) || (matchScores.gs.away !== matchScores.found.away)) {
//               _matchesData[match_found_key].awayScore = matchScores.gs.away
//               _matchesData[match_found_key].homeScore = matchScores.gs.home
//               _matchesData[match_found_key]._changed = true
//             }
//           } else {
//             if (_.isNumber(matchScores.gs.away) && _.isNumber(matchScores.gs.home)) {
//               _matchesData[match_found_key].awayScore = matchScores.gs.away
//               _matchesData[match_found_key].homeScore = matchScores.gs.home
//               _matchesData[match_found_key]._changed = true
//             }
//           }
//         }
//       } else {
//         // new match
//         const _teamHome = _.findKey(teams, { name: _homeName })
//         const _teamAway = _.findKey(teams, { name: _awayName })
//         if (_teamHome && _teamAway) {
//           match_gs._new = true
//           _matchesData[createFsDocKey('updateMatch')] = match_gs
//         }
//       }
//     })
//     const matchUpdates = createMatchesFromList({ itemData: _matchesData, teams, matches_season })

//     const _matchesChanged = _.filter(_matchesData, { _changed: true })
//     const _matchesNew = _.filter(_matchesData, { _new: true })

//     callback(_matchesData, matchUpdates, _matchesChanged, _matchesNew)
//   }
// }