import { where } from 'firebase/firestore';
import _ from 'lodash';
import { formatItem, formatTypes } from '../../../../../global/common/dateFormatting';
import { convertSnapshot } from '../../../../../global/cnr/contexts/contextHelpers';
import { fs_get_data } from '../../../../../global/firestoreData/appData/fsAppData';
import { createRefPath, createRefPath_event, createRefPath_events } from '../../../../../global/firestoreData/appData/appRefPaths';
import { fs_add_doc, fs_set_doc, fs_update_doc } from '../../../../../global/firestoreData/appData/fsData';
import { getSportsYear } from '../../../../../global/firestoreData/helpers/years';
import { dispatchProps } from '../../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { addParentKeysToDataItem } from '../../../../../global/firestoreData/appData/ammendData';

export const _seasonalCollectionName = '_seasonals'

export const seasonalUpdateTypes = {
  allStarTeams: 'allStarTeams',
  athletes: 'athletes',
  backupMatches: 'backupMatches',
  details: 'details',
  googleLinks: 'googleLinks',
  history: 'history',
  matches: 'matches',
  matchesList: 'matchesList',
  playoffBracket: 'playoffBracket',
  playoffDates: 'playoffDates',
  playoffBrackets: 'playoffBrackets',
  playoffSchedule: 'playoffSchedule',
  playoffSeeds: 'playoffSeeds',
  playoffTeams: 'playoffTeams',
  rankings: 'rankings',
  rosters: 'rosters',
  scheduleDates: 'scheduleDates',
  sectionCounts: 'sectionCounts',
  sectionDates: 'sectionDates',
  staff: 'staff',
  teams: 'teams',
}

export const allowSeasonalUpdates = {
  any: true,
  allStarTeams: false,
  athletes: true,
  backupMatches: false,
  backupMatches: false,
  details: false,
  googleLinks: true,
  history: false,
  matches: true,
  matchesList: true,
  playoffBracket: false,
  playoffDates: true,
  playoffBrackets: true,
  playoffSchedule: false,
  playoffSeeds: true,
  playoffTeams: true,
  rankings: true, // OK
  rosters: true,
  scheduleDates: false,
  sectionCounts: false,
  sectionDates: false,
  staff: false,
  teams: false,
}

export const allowSportsUpdates = {
  seasonal: false,
  matches: true,
  athletes: false
}

const _seasonalCollections = {
  districts: 'districts',
  organizations: 'organizations',
  schools: 'schools',
  sports: 'sports',
  teams: 'teams',
}

/**
 * returns data from a collection based on the seasonalCollectionName/altSeasonalName where the parentKey constraints match
 * @param {array} pathViews 
 * @param {array} dataParents 
 * @param {boolean} useSeasonals 
 * @param {function} callback 
 * @param {function} sportsSeason_handlers 
 * @param {string} altSeasonalName 
 * @returns data from a collection based on the seasonalCollectionName/altSeasonalName where the parentKey constraints match
 * pojoSports
 * 
 */
export const getSeasonalData = async (pathViews, sportsKey, latestSeason, callback, sportsSeason_handlers, opts) => {

  const { altSeasonalName, getSeasonalMatchesOnly } = opts ? opts : {}

  const collectionName = altSeasonalName ? altSeasonalName : _seasonalCollectionName
  const sy = getSportsYear(latestSeason)

  const pks = getPks(pathViews, sy, sportsKey)

  if (pks) {

    const parentKeys = {}

    const _baseRef = createRefPath_event(pathViews, [collectionName])
    const wheres = []

    Object.keys(pks).forEach(pk => {
      wheres.push(where('parentKeys.' + pk, '==', pks[pk]))
      parentKeys[pk] = pathViews[pk]
    })

    // AWAIT THE DATA
    const { item: retData, fk } = await fs_get_data({ refPath: _baseRef, wheres, opts: { returnFirstObject: true, ignoreId: true, returnKey: true } })

    if (getSeasonalMatchesOnly) {
      const refPathM = createRefPath_event(pathViews, [collectionName, fk, 'matches', 'matches'])
      const { item: retData_n } = await fs_get_data({ refPath: refPathM, opts: { returnFirstObject: true, ignoreId: true, returnKey: true } })
      callback(retData_n)
      return
    }

    if (retData && retData.id) {
      const _baseRef2 = createRefPath([retData.id, 'details'], _baseRef)
      const retData2 = await fs_get_data({ refPath: _baseRef2, opts: { ignoreId: true } })
      if (callback) {
        callback(retData2, fk)
        return retData2
      } else {
        return retData2
      }
    }

    if (retData && retData._itemKey) {
      const _baseRef2 = createRefPath([retData._itemKey, 'details'], _baseRef)
      const retData2 = await fs_get_data({ refPath: _baseRef2 })
      if (callback) {
        callback(retData2, fk)
        return retData2
      } else {
        return retData2
      }
    }

    if (sportsSeason_handlers) {

      const si = retData

      if (si && si.matches && si.parentKeys) {
        addParentKeysToDataItem(si.parentKeys, si.matches)
      }
      if (si && si.teams && si.parentKeys) { addParentKeysToDataItem(si.parentKeys, si.teams) }
      if (altSeasonalName) {
        sportsSeason_handlers.setPlayoffMatches(si)
      } else {
        sportsSeason_handlers.setSportsSeasonData(si)
      }
    } else {
      if (callback) {
        callback(retData, fk)
      } else {
        return retData
      }
    }
  } else {
    if (callback) {
      callback(null)
    } else {
      return null
    }
  }
}

// eslint-disable-next-line 
const getSeasonalDetails = (baseSeasonalRef, si, callback) => {
  const { id, parentKeys } = si
  const detailsRef = baseSeasonalRef.doc(id).collection('details')
  detailsRef.onSnapshot(result => {
    const d = convertSnapshot(result, false, { ignoreId: true })
    if (callback) {
      if (d && d.matches && parentKeys) { addParentKeysToDataItem(parentKeys, d.matches) }
      if (d && d.teams && parentKeys) { addParentKeysToDataItem(parentKeys, d.teams) }
      // if (d && d.matches) { ammendStartDate(d.matches) } 
      callback({ ...d, parentKeys })
    }
  })
  // detailsRef.get().then(res => {
  //   const d = convertSnapshot(res, false)
  //   console.log('details', d)
  // })
}

/**
 * 
 * @param {object} pathViews 
 * @param {object} pks 
 * @param {string} documentFieldName 
 * @param {object} data 
 * @param {string} collectionName ** with _
 * @param {function} callback 
 * @description Updates the `_seasonalCollectionName` or the collectionName prefixed with an `_`
 */
export const updateSeasonalOrGlobal = async (seasonalUpdateType, pathViews, pks, collectionName, documentFieldName, data, callback, opts, dataInfos) => {

  const allowUpdate = allowSeasonalUpdates.any && allowSeasonalUpdates[seasonalUpdateType]

  const { dtu, dta } = dataInfos ? dataInfos : {}

  console.log('-----------')
  console.log('updateSeasonalOrGlobal', allowUpdate, seasonalUpdateType)

  const { subCollectionName, fullData, ignoreSeason, updateToMain } = opts ? opts : {}

  if (updateToMain) {
    // pathViews, collectionName, parentKeys, data
    updateSportsCollectionItemsWithParentKeys(seasonalUpdateType, pathViews, documentFieldName, pks, dtu ? dtu : data, callback)
  } else {
    if (pks.sportsYears || ignoreSeason) {

      const _collectionName = collectionName ? '_' + collectionName : _seasonalCollectionName

      const _sportsYearCollectionItem = await getSports_collectionItem(pathViews, pks, _collectionName, true)

      if (_sportsYearCollectionItem && !_.isEmpty(_sportsYearCollectionItem)) {
        const { _itemKey } = _sportsYearCollectionItem
        const _refPath = subCollectionName ? createRefPath_event(pathViews, [_collectionName, _itemKey, subCollectionName, subCollectionName]) : createRefPath_event(pathViews, [_collectionName, _itemKey])

        const _data = fullData ? fullData : data

        switch (seasonalUpdateType) {
          case seasonalUpdateTypes.playoffSeeds:
          case seasonalUpdateTypes.playoffTeams:
          case seasonalUpdateTypes.playoffBrackets:
            const _dataToSet = dta ? dta : _data
            const dataToSet = documentFieldName ? { [documentFieldName]: _dataToSet } : _dataToSet
            console.log('dataToSet', dataToSet)
            allowUpdate && fs_set_doc(_refPath, dataToSet, callback)
            break;
          default:
            const _dataToUpdate = dtu ? dtu : _data
            const dataToUpdate = documentFieldName ? { [documentFieldName]: _dataToUpdate } : _dataToUpdate
            console.log('dataToUpdate', dataToUpdate)
            allowUpdate && fs_update_doc(_refPath, dataToUpdate, callback)
        }

      } else {
        const _refPath = createRefPath_event(pathViews, [_collectionName])

        let dataToAdd;
        const _data = dta ? dta : data

        if (fullData) {
          dataToAdd = fullData
        } else {
          if (documentFieldName) {
            dataToAdd = {
              parentKeys: pks,
              [documentFieldName]: _data
            }
          } else {
            dataToAdd = {
              parentKeys: pks,
              ..._data
            }
          }
        }

        console.log('_refPath', _refPath)
        console.log('dataToAdd', dataToAdd)

        allowUpdate && fs_add_doc(_refPath, dataToAdd, callback)
      }
    }
  }
}

export const updateSeasonalMatches = async (seasonalUpdateType, pathViews, pks, dataToUpdate, collectionName, callback, replaceData) => {

  const allowUpdate = allowSeasonalUpdates.any && allowSeasonalUpdates[seasonalUpdateType]

  if (pks.sportsYears) {
    const _collectionName = collectionName ? '_' + collectionName : _seasonalCollectionName

    const _sportsYearCollectionItem = await getSports_collectionItem(pathViews, pks, _collectionName, true)

    if (_sportsYearCollectionItem && !_.isEmpty(_sportsYearCollectionItem)) {
      const { _itemKey } = _sportsYearCollectionItem
      const _refPath = createRefPath_event(pathViews, [_collectionName, _itemKey])

      console.log('_refPath', seasonalUpdateType, allowUpdate, _refPath)
      console.log('dataToUpdate', replaceData, dataToUpdate)

      if (allowUpdate) {
        if (replaceData) {
          fs_set_doc(_refPath, dataToUpdate, false, callback)
        } else {
          // fs_set_doc(_refPath, dataToUpdate, true, callback)
          fs_update_doc(_refPath, dataToUpdate, callback)
        }
      }
    } else {
      const _refPath = createRefPath_event(pathViews, [_collectionName])
      const dataToAdd = {
        parentKeys: pks,
        [collectionName]: dataToUpdate,
      }
      console.log('_refPath', seasonalUpdateType, allowUpdate, _refPath)
      console.log('dataToAdd', dataToAdd)
      allowUpdate && fs_add_doc(_refPath, dataToAdd, callback)
    }
  }
}

export const backupSeasonal = async (pathViews, dataParents, altSeasonalName, dataRestrictions, dispatch) => {

  // https://medium.com/@BrodaNoel/how-to-backup-firebase-firestore-8be94a66138c

  const collectionName = altSeasonalName ? altSeasonalName : _seasonalCollectionName

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.backupSeasonals)) {
    dispatch && dispatch({ type: dispatchProps && dispatchProps.successAlt ? dispatchProps.successAlt : dispatchProps.error })
    return true
  }

  if (dataParents) {
    const parentKeys = {}
    dataParents.forEach(dp => {
      if (pathViews[dp]) {
        parentKeys[dp] = pathViews[dp]
      }
    })

    const sy = getSportsYear()
    parentKeys['sportsYears'] = sy

    getSeasonalForUpdate(pathViews, dataParents, collectionName).then(seasonalItem => {
      if (seasonalItem) {
        const si = seasonalItem[Object.keys(seasonalItem)[0]]

        if (si) {
          const seasonalData = si
          const _updateRef = createRefPath_event(pathViews, [collectionName + '_backup', si.id])
          fs_set_doc(_updateRef, seasonalData, true, dispatch)
        }
      }
    })
  }
}

export const deleteSeasonalData = async (pathViews, dataParents, dataType, data, callback, altData, altSeasonalName) => {

  const collectionName = altSeasonalName ? altSeasonalName : _seasonalCollectionName

  if (dataParents) {
    const parentKeys = {}
    dataParents.forEach(dp => {
      switch (dp) {
        // case 'allSportsYears':
        //   const sy = getSportsYear()
        //   parentKeys['sportsYears'] = sy
        //   break;
        default:
          if (pathViews[dp]) {
            parentKeys[dp] = pathViews[dp]
          }
      }
    })

    const sy = getSportsYear()
    parentKeys['sportsYears'] = sy

    const seasonalData = altData
      ? altData :
      {
        parentKeys,
        [dataType]: data
      }

    getSeasonalForUpdate(pathViews, dataParents, collectionName).then(seasonalItem => {
      if (seasonalItem) {
        const si = seasonalItem[Object.keys(seasonalItem)[0]]

        const _updateRef = createRefPath_event(pathViews, [collectionName])
        if (si) {
          if (altData) {
            fs_update_doc(_updateRef, altData, callback)
          } else {
            fs_set_doc(_updateRef, seasonalData, true, callback)
          }
        } else {
          fs_set_doc(_updateRef, seasonalData, false, callback)
        }
      }
    })
  }
}

// eslint-disable-next-line 
const ammendStartDate = (items) => {
  if (items) {
    Object.keys(items).forEach(key => {
      items[key].key = key
      if (items[key].startDateUTC) { items[key].startDate = formatItem(formatTypes.date, items[key].startDateUTC) }
      if (items[key].startTimeUTC) { items[key].startTime = '7:00 PM' }
    })
  }
}

/**
 * Gets the data based on the refPath_event and the collectionName with dataParents creating wheres
 * @param {string} pathViews 
 * @param {object} dataParents 
 * @param {string} collectionName 
 * @returns 
 */
const getSeasonalForUpdate = async (pathViews, dataParents, collectionName) => {

  if (dataParents) {
    const _refPath = createRefPath_event(pathViews, [collectionName])
    const wheres = []

    dataParents.forEach(dp => {
      switch (dp) {
        case 'allSportsYears':
          const sy = getSportsYear()
          wheres.push(where('parentKeys.sportsYears', '==', sy))
          // firestoreRef = firestoreRef.where('parentKeys.sportsYears', '==', sy)
          break;
        default:
          if (pathViews[dp]) {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            // firestoreRef = firestoreRef.where('parentKeys.' + dp, '==', pathViews[dp])
          }
      }
    })

    const retData = await fs_get_data({ refPath: _refPath, wheres })
    return retData
  } else {
    return null
  }
}

/**
 * 
 * @param {object} parentKeys 
 * @param {array} dataParents 
 * @param {string} collectionName 
 * @returns the data from the collectionName or _seasonalCollectionName via callback
 */
export const getSports_collectionItem = async (pathViews, parentKeys, collectionName, returnFirstObject, cbProps, callback) => {

  const cb = (data) => {
    const cbd = {
      collectionName,
      parentKeys,
      sportsItem: data,
    }
    callback(cbd, { cbProps })
  }

  const _collectionName = collectionName ? collectionName : _seasonalCollectionName

  const _refPath = createRefPath_event(pathViews, [_collectionName])

  const wheres = []

  Object.keys(parentKeys).forEach(pk => {
    switch (pk) {
      case 'sportsYears':
        wheres.push(where('parentKeys.' + pk, '==', parseInt(parentKeys[pk])))
        break;
      default:
        wheres.push(where('parentKeys.' + pk, '==', parentKeys[pk]))
    }
  })

  if (callback) {
    fs_get_data({ refPath: _refPath, callback: cb, wheres, opts: { returnFirstObject } })
  } else {
    const retData = await fs_get_data({ refPath: _refPath, wheres, opts: { returnFirstObject } })
    return retData
  }
}

export const updateSportsCollectionItemsWithParentKeys = async (seasonalUpdateType, pathViews, parentKeys, collectionName, data, callback) => {
  if (data && Object.keys(data).length > 0) {
    const result = await updateSportsCollectionItemsPromise(seasonalUpdateType, pathViews, collectionName, parentKeys, data)
    callback && callback(result)
    return {}
  } else {
    callback && callback({})
    return {}
  }
}

const updateSportsCollectionItemsPromise = async (seasonalUpdateType, pathViews, collectionName, parentKeys, data) => {

  const allowUpdate = allowSeasonalUpdates.any && allowSeasonalUpdates[seasonalUpdateType]

  const promises = []
  Object.keys(data).forEach(k => {
    const dataItem = data[k]
    dataItem.parentKeys = parentKeys
    delete dataItem.year
    delete dataItem.position
    delete dataItem.uniformNumber
    const refPath = createRefPath_event(pathViews, [collectionName, k])
    if (allowUpdate) {
      promises.push(fs_set_doc(refPath, dataItem, false, null, true))
    } else {
      console.log('refPath', refPath, dataItem)
    }
  })
  return Promise.all(promises)
}

// eslint-disable-next-line 
// const updataSubDataTeams = (teams, parentKeys, pathViews, dataParents) => {
//   if (teams) {
//     const _teams = {}
//     Object.keys(teams).forEach(teamKey => {
//       const team = teams[teamKey]
//       _teams[teamKey] = {
//         name: team.name,
//       }
//     })
//     updateParentKeyData('_teams', pathViews, dataParents, 'teams', _teams)
//   }
// }

// eslint-disable-next-line 
// const updataSubDataSchools = async (fs, parentKeys, pathViews, dataParents) => {

//   let schoolsRef = fs
//     .collection('clients').doc(pathViews.clients)
//     .collection('events').doc(pathViews.events)
//     .collection('schools')

//   const _schools = {}
//   const res = await schoolsRef.get();
//   if (!res.empty) {
//     res.forEach(doc => {
//       const school = doc.data();
//       _schools[doc.id] = { name: school.name }
//     });
//     delete parentKeys.sports
//     updateParentKeyData('_schools', pathViews, dataParents, 'schools', _schools)
//   }
// }

// eslint-disable-next-line 
// const updataSubDataDistricts = async (fs, parentKeys, pathViews, dataParents) => {

//   let schoolsRef = fs
//     .collection('clients').doc(pathViews.clients)
//     .collection('events').doc(pathViews.events)
//     .collection('districts')

//   const _districts = {}
//   const res = await schoolsRef.get();
//   if (!res.empty) {
//     res.forEach(doc => {
//       const district = doc.data();
//       _districts[doc.id] = { name: district.name, districtId: district.districtId }
//     });
//     delete parentKeys.sports
//     delete parentKeys.districts
//     updateParentKeyData('_districts', pathViews, dataParents, 'districts', _districts)
//   }
// }

export const getPks = (pathViews, sportsYears, sportsKey, ignores) => {
  const _pks = {}
  Object.keys(pathViews).forEach(pv => {
    let allow = true
    if (ignores && ignores.includes(pv)) {
      allow = false
    }
    if (allow) {
      switch (pv) {
        case _seasonalCollections.districts:
        case _seasonalCollections.organizations:
        case _seasonalCollections.schools:
        case _seasonalCollections.sports:
        case _seasonalCollections.teams:
          if (pathViews[pv]) {
            _pks[pv] = pathViews[pv]
          }
          break;
        default:
        // nothing
      }
    }
  })

  if (sportsKey) { _pks['sports'] = sportsKey }
  if (sportsYears) { _pks['sportsYears'] = parseInt(sportsYears) }
  return _pks
}