import React from 'react';
import { List, Segment } from 'semantic-ui-react';

const DcUpdatesList = (props) => {

  const { dcUpdates, inverted } = props

  const dcUpdateList = () => {

    const listItems = []

    Object.keys(dcUpdates).forEach(key => {
      const dcUpdate = dcUpdates[key]
      const { applied, updating, updated } = dcUpdate ? dcUpdate : {}
      if (applied) {
        let icon = 'ban'
        let color = 'grey'
        if (updating) {
          icon = 'clock'
          color = 'blue'
        }
        if (updated) {
          icon = 'check'
          color = 'green'
        }
        listItems.push(<List.Item>
          <List.Icon name={icon} color={color} />
          <List.Content>
            <List.Header>{key}</List.Header>
          </List.Content>
        </List.Item>)
      }
    })

    return <Segment basic>
      <List divided relaxed inverted={inverted}>
        {listItems}
      </List>
    </Segment>
  }

  return dcUpdateList()

}

export default DcUpdatesList