import React, { createContext } from 'react';

/**
@provides handleBlur
@provides handleCalOpen
@provides handleDataLabelAdd
@provides handleDataLabelClick
@provides handleDataLabelRemoveClick
@provides handleHeaderBackClick
@provides handleHeaderBreadcrumbClick
@provides handleLoadData
@provides handleMapOpen
@provides handleFormDataChange_local
@provides handleUploadOpen
@provides handleSwipeChangeIndex
*/
export const CreateContext = createContext();

const CreateProvider = (props) => {
  return <CreateContext.Provider value={{ ...props }}>
    {props.children}
  </CreateContext.Provider>
};

export default CreateProvider 