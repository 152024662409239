import React, { useContext } from 'react';
import ActionProvider from '../cnr/contexts/ActionContext';
import DataProvider from '../cnr/contexts/DataContext';
import FilterProvider from '../cnr/contexts/FilterContext';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import ItemStorageProvider from '../cnr/contexts/ItemStorageContext';
import { PageAreaContext } from '../cnr/contexts/PageAreaContext';
import { PapsContext } from '../cnr/contexts/PapsContext';
import UiItemProvider from '../cnr/contexts/UiItemContext';
import { gEnums } from '../enums/globalEnums';
import UiItem from '../pageItem/UiItem';
import ErrorBoundary from '../components/errorHandling/ErrorBoundery';

const NonAuthPage = React.lazy(() => import("../components/alerts/NonAuthPage"));
const PageItemAlt = React.lazy(() => import("./PageItemAlt"));
const UnderMaintenancePage = React.lazy(() => import("../components/alerts/UnderMaintenancePage"));

/** Returns the UiItem wrapped in the DataProvider 
 * @returns <UiItem />
*/
const PageItem = (props) => {

  const _apits = gEnums.additionalPageItemTypes

  const { uivi, tabIndex, caption, viewItem_app } = props

  // papsContext
  const papsContext = useContext(PapsContext);
  const { history } = papsContext ? papsContext : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  // pageAreaContext
  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ? pageAreaContext : {}
  const { pageItemsShown } = pageArea_state ? pageArea_state : {}
  const viewItem_page = pageItemsShown && pageItemsShown[uivi] ? pageItemsShown[uivi] : {}
  const { general, viewPermissionType, display, desktopDisplay } = viewItem_page ? viewItem_page : {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { displayType } = _display ? _display : {}

  const { itemUnderMaintenance } = general ? general : {}

  if (itemUnderMaintenance) { return <UnderMaintenancePage itemType={'item'} /> }

  switch (viewPermissionType) {
    case gEnums.viewPermissionTypes.deny:
    case gEnums.viewPermissionTypes.deny_noClientKey:
    case gEnums.viewPermissionTypes.deny_noEventKey:
      // case gEnums.viewPermissionTypes.allow_clientLimited:
      return <NonAuthPage history={history} itemType={'item'} vpt={viewPermissionType} />
    default:
      // nothing. continue
      break;
  }

  switch (displayType) {
    case _apits.geoList:
    case _apits.geoLocation:
    case _apits.feeds:
    case _apits.questionsAndAnswers:
    case _apits.twitter:
    case _apits.videoLinks:
    case _apits.gallery:
    case _apits.photos:
    case _apits.pdfs:
      return <PageItemAlt viewItem_page={viewItem_page} displayType={displayType} />
    default:
    // nothing
  }

  // pageViewData is only data from the VIEW
  const piElems = () =>
    <ErrorBoundary origin={'pageItem'}>
      <DataProvider uivi={uivi}>
        <ActionProvider>
          <FilterProvider>
            <UiItemProvider>
              <ItemStorageProvider>
                <UiItem tabIndex={tabIndex} caption={caption} viewItem_app={viewItem_app} />
              </ItemStorageProvider>
            </UiItemProvider>
          </FilterProvider>
        </ActionProvider>
      </DataProvider>
    </ErrorBoundary>

  if (uivi) {
    return piElems()
  }

  return <div></div>

}

export default PageItem