import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Image, Segment } from 'semantic-ui-react'
import { ManifestContext } from '../../cnr/contexts/ManifestContext'
import { manifestSizes } from '../imaging/createManifestIcons'
import { storageGalleryProps } from '../../cnr/reducers/UploadReducer';
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'
import { gEnums } from '../../enums/globalEnums'
import Uploader from '../uploading/Uploader'

/** Dipslays the manifest icons vertically with a header of the size */
const ManifestViewer = (props) => {

  const { manifestFiles: manifestFiles_preview, preview } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states
  const { appUser } = appUser_state ? appUser_state : {}
  const { storagePaths, view, viewKey } = paps_state

  const appUserAccess = getAppUserAccess(appUser)
  const { appUserType } = appUserAccess ? appUserAccess : {}

  let storageLocationType;

  switch (appUserType) {
    case gEnums.appUserTypes.admin:
    case gEnums.appUserTypes.superAdmin:
      storageLocationType = gEnums.storageLocationTypes.profiles
      break;
    default:
      storageLocationType = gEnums.storageLocationTypes.clientProfile
  }

  const _sp = {
    appUserAccess,
    storageLocationType,
    storageType: gEnums.storageTypes.image,
    storagePaths,
    viewItem_key: view,
    viewItem_id: viewKey,
  }

  const sgps = storageGalleryProps(_sp)
  const { storageRef } = sgps ? sgps : {}

  // storageContext
  const manifestContext = useContext(ManifestContext);
  const { manifest_state } = manifestContext ? manifestContext : {}
  const { manifestIcons } = manifest_state ? manifest_state : {}

  // const init_state_u = { states, storageType: gEnums.storageTypes.manifest, storageRef, storageLocationType, appUserAccess }
  // const [upload_state, uploadDispatch] = useReducer(uploadReducer, uploadInitialState(init_state_u));
  // const upload_handlers = uploadHandlers(uploadDispatch, upload_state)
  // const { firstSelectedFile, updating, confirmation } = upload_state ? upload_state : {}

  const [files, setFiles] = useState()

  useEffect(() => {
    if (preview) {
      setFiles(manifestFiles_preview)
    } else {
      if (manifestIcons && Object.keys(manifestIcons).length > 0) {
        const mfs = _.sortBy(manifestIcons, 'size')
        setFiles(mfs)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const segGroup = (caption, content) => <Segment.Group>
    <Segment inverted>{caption}</Segment>
    <Segment inverted>{content}</Segment>
  </Segment.Group>

  const manImage = (imageFile, key) => <Image key={key} src={imageFile} />

  const uploader = () => <Uploader
    origin={'manifestViewer'}
    storageLocationType={gEnums.storageLocationTypes.event}
    storageType={gEnums.storageTypes.manifest}
  />

  // eslint-disable-next-line 
  const manifestContent = () => files.map((file, index) => {
    if (preview) {
      return segGroup(manifestSizes[index], manImage(URL.createObjectURL(file), index))
    } else {
      const { fileUrl } = file
      if (fileUrl) {
        return segGroup(manifestSizes[index], manImage(fileUrl, index))
      }
    }
  })

  const content = () => <React.Fragment>
    {uploader()}
    {files && manifestContent()}
  </React.Fragment>

  return content()

}

export default ManifestViewer