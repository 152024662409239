import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { videoConferenceHandlers, videoConferenceInitialState, videoConferenceReducer } from '../reducers/VideoConferenceReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** Global VideoConferences (Icon, Image, PDF) for the Event taken from the database */
export const VideoConferenceContext = createContext();

/** This will get the homeSettings and clientSettings */
const VideoConferenceProvider = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { settingsDocName, pathViews } = paps_state ? paps_state : {}

  const pageContext = useContext(PageContext)
  const { page_fns } = pageContext ? pageContext : {}

  // local state
  const init_state = { settingsDocName, pathViews, page_fns }
  const [videoConference_state, videoConferenceDispatch] = useReducer(videoConferenceReducer, videoConferenceInitialState(init_state));
  const videoConference_handlers = videoConferenceHandlers(videoConferenceDispatch, videoConference_state)
  const { videoConferences } = videoConference_state ? videoConference_state : {}

  useEffect(
    () => {
      // only get videoConferences in the events collection  
      videoConference_handlers.handleGetVideoConferences()
    },
    // eslint-disable-next-line
    [settingsDocName] // does not re-rednder unless uivi has changed
  )

  const providerContext = () => {
    return <VideoConferenceContext.Provider value={{ videoConference_state, videoConference_handlers }}>
      {props.children}
    </VideoConferenceContext.Provider>
  }

  if (videoConferences) { return providerContext() }

  return <div></div>

}

export default VideoConferenceProvider