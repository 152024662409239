import { getDownloadURL, getStorage, list, listAll, ref } from "firebase/storage";
import { gEnums } from '../enums/globalEnums';
import { createRefPath } from '../firestoreData/appData/appRefPaths';
import { getGalleryMetadata_global } from "./storageItems";

export const _storageSettings = {
  galleryPaths: {
    galleryDirect: '_gallery_direct',
    galleryEvent: '_gallery_event',
    galleryPage: '_gallery_page',
    pagePdf: '_page_pdf',
    profile_all: '_gallery_profile_all',
    profiles: 'profiles'
  },
  galleryTypes: {
    event: 'event',
    direct: 'direct',
    pdf: 'pdf',
    page: 'page',
    profiles: 'profiles',
  },
  maxResults: 15,
  thumbSuffix: '-thumb',
  allowUpload: true,
  logResult: false
}

/** gets an item for each of the items in the globalFileGroup and assigns the an object
 * urls: full: xxx, thumbnail: yyy}
 */

const getItemUrls = (globalFileGroup, urlz) => {
  const { items } = globalFileGroup ? globalFileGroup : {} // ListResultCompat 
  const results = {}
  if (items) {
    items.forEach(item => {
      const { name, fullPath } = item
      // full only
      if (name.indexOf(_storageSettings.thumbSuffix) < 0) {
        if (!results[name]) { results[name] = { urls: {} } }
        const y = findUrls(urlz, name)
        results[name] = {
          urls: {
            full: y.full ? y.full : null,
            thumbnail: y.thumbnail ? y.thumbnail : null,
          },
          fullPaths: {
            full: fullPath,
            thumbnail: fullPath + _storageSettings.thumbSuffix,
          }
        }
      }
    })
  }
  return results
}

const findUrls = (urls, name) => {
  let _name = name.replace(/\s/g, '%20')
  _name = _name.replace(/@/g, '%40')
  const u = { thumbnail: null, full: null }
  if (urls) {
    urls.forEach(url => {
      if (url && url.indexOf(_name) >= 0) {
        if ((url && url.indexOf(_storageSettings.thumbSuffix) >= 0)) {
          u.thumbnail = url
        } else {
          u.full = url
        }
      }
    })
  }
  return u
}


/** Gets all files within a storage folder */
export const getAllImagesInPath = async (storagePath, callback) => {
  const storage = getStorage()
  const storageRef = ref(storage, storagePath)
  try {
    const globalFileGroup = await list(storageRef, { maxResults: _storageSettings.maxResults });
    const urlz = thumbnailsDownloadUrlPromise(globalFileGroup)
    if (globalFileGroup && urlz) {
      const results = getItemUrls(globalFileGroup, urlz);
      callback(results);
    } else {
      callback({});
    }
  } catch {
    callback({});
  }
}

/** Gets the getDownloadURL files within a storage folder */
const thumbnailsDownloadUrlPromise = async (res) => {

  const { items } = res ? res : {}

  const promises = []
  items.forEach(function (itemRef) {
    const { _location } = itemRef
    const { path_ } = _location
    const storage = getStorage()
    const iconRef = ref(storage, path_)
    promises.push(iconRef.getDownloadURL())
  });
  return Promise.all(promises)
}

/**
 * Gets the files from the floormap, icon, image and pdf folders
 * @param {string} storageRootPath 
 * @param {boolean} useUnderscorePrefix 
 * @param {function} callback 
 * @returns {floormap, icon, image, pdf}
 */
export const getGlobalStorageFiles = async (storageRootPath, useUnderscorePrefix, callback) => {
  const globalFileGroups = await globalFilesPromise(storageRootPath, useUnderscorePrefix)
  const res = await fileGroupsDownloadUrlPromise(globalFileGroups)
  ammendGlobalFiles(globalFileGroups, res, callback)
}

/** Gets the files from the floormap, icon, image and pdf folders
 * @returns floormap
 * @returns icon
 * @returns image
 * @returns pdf
 */
const globalFilesPromise = async (storageRootPath, useUnderscorePrefix) => {

  const storage = getStorage()

  const refs = {
    floormap: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.floormap),
    icon: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.icon),
    image: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.image),
    pdf: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.pdf),
  }

  const promises = []
  promises.push(await listAll(refs.floormap))
  promises.push(await listAll(refs.icon))
  promises.push(await listAll(refs.image))
  promises.push(await listAll(refs.pdf))

  return Promise.all(promises)

}

/** Promise that returns the url of the lists' items */
const fileGroupsDownloadUrlPromise = (globalFileGroups) => {
  const promises = []
  globalFileGroups.forEach(async (globalFileGroup) => {
    promises.push(getDownloadedUrlFiles(globalFileGroup))
  })
  return Promise.all(promises)
}

/**
 * 
 * @param {object} globalFileGroup 
 * @returns the urls (full and thumbnail) from the globalFileGroup
 */
const getDownloadedUrlFiles = async (globalFileGroup) => {

  const storage = getStorage()

  const { items } = globalFileGroup ? globalFileGroup : {}

  const promises = []
  if (items) {
    items.forEach(function (itemRef) {
      const { fullPath } = itemRef
      const iconRef = ref(storage, fullPath)
      promises.push(getDownloadURL(iconRef))
    });
  }

  return Promise.all(promises)
}

/** Loops through each of the globalFiles and assigns each item a url.full and url.thumbnail */
const ammendGlobalFiles = (globalFileGroups, urlResults, callback) => {

  const storage = getStorage();

  const gfs = {
    [gEnums.storageTypes.floormap]: [],
    [gEnums.storageTypes.icon]: [],
    [gEnums.storageTypes.image]: [],
    [gEnums.storageTypes.pdf]: [],
  }

  globalFileGroups.forEach((globalFileGroup, index) => {
    const urlz = urlResults[index]
    const results = getItemUrls(globalFileGroup, urlz)
    switch (index) {
      case 0:
        gfs[gEnums.storageTypes.floormap] = results
        break;
      case 1:
        gfs[gEnums.storageTypes.icon] = results
        break;
      case 2:
        gfs[gEnums.storageTypes.image] = results
        break;
      case 3:
        gfs[gEnums.storageTypes.pdf] = results
        break;
      default:
      // nothing
    }
  })
  getGalleryMetadata_global(gfs, storage, callback)
}

export const profileImagePromises = (currentUser) => {

  const { email } = currentUser ? currentUser : {}

  const storage = getStorage()

  // set the file name to `profile` so there is only one of them
  const sr1 = ref(storage, createRefPath([_storageSettings.galleryPaths.profiles, email, 'profile']))
  const sr2 = ref(storage, createRefPath([_storageSettings.galleryPaths.profiles, email, 'profile' + _storageSettings.thumbSuffix]))

  const promises = []
  promises.push(sp(sr1))
  promises.push(sp(sr2))
  return Promise.all(promises)
}

const getProfileUrls = (appUsers, valid) => {
  const _profileUrls = {}
  Object.keys(appUsers).forEach((key, ai) => {
    const appUser = appUsers[key]
    const { email } = appUser
    const profileUrls = email ? findUrls(valid, email) : null
    if (profileUrls && (profileUrls.full && profileUrls.thumbnail)) {
      appUser.profileUrls = profileUrls
      _profileUrls[email] = profileUrls
    }
  })
}

/** Returns a promise of all the profile storage items */
export const getAllAppUserUrls = async (appUsers, callback) => {
  const imgResult = await profileImagesPromises(appUsers)
  const valid = getValidUrls(imgResult)
  const _profileUrls = getProfileUrls(appUsers, valid)
  callback(_profileUrls)
  // profileImagesPromises(appUsers).then(imgResult => {
  //   const valid = getValidUrls(imgResult)
  //   const _profileUrls = {}
  //   Object.keys(appUsers).forEach((key, ai) => {
  //     const appUser = appUsers[key]
  //     const { email } = appUser
  //     const profileUrls = email ? findUrls(valid, email) : null
  //     if (profileUrls && (profileUrls.full && profileUrls.thumbnail)) {
  //       appUser.profileUrls = profileUrls
  //       _profileUrls[email] = profileUrls
  //     }
  //   })
  //   callback(_profileUrls)
  // })
}

/** Returns a promise of all the profile storage items */
const profileImagesPromises = async (data) => {
  const promises = []
  if (data) {
    Object.keys(data).forEach(key => {
      const dataItem = data[key]
      const { email } = dataItem ? dataItem : {}
      if (email) {
        const storage = getStorage()
        const sr1 = ref(storage, _storageSettings.galleryPaths.profiles + '/' + email + '/profile')
        const sr2 = ref(storage, _storageSettings.galleryPaths.profiles + '/' + email + '/profile' + _storageSettings.thumbSuffix)
        promises.push(sp(sr1))
        promises.push(sp(sr2))
      }
    })
  }
  return Promise.all(promises)
}

const getValidUrls = (imgResult) => {
  const valid = []
  imgResult.forEach(ir => {
    if (ir) {
      valid.push(ir)
    }
  })
  return valid
}

const sp = (sr) => {
  let storagePromise = getDownloadURL(sr).then(function (url) {
    return url
  }).catch(function (error) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        // console.log('profiles/email', 'File does not exist')
        break;
      case 'storage/unauthorized':
        // console.log('profiles/email', 'User does not have permission to access the object')
        break;
      case 'storage/canceled':
        // console.log('profiles/email', 'User canceled the upload')
        break;
      case 'storage/unknown':
        // console.log('profiles/email', 'Unknown error occurred, inspect the server response')
        break;
      default:
      // console.log('profiles/email', 'Unknown error occurred, inspect the server response')
    }
  });
  return storagePromise
}