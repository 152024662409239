import _ from 'lodash';
import React, { Fragment, useContext } from 'react';
import { Button, Card, Icon, Label } from 'semantic-ui-react';
import { UiItemContext } from '../../../../global/cnr/contexts/UiItemContext';
import { PageContext } from '../../../../global/cnr/contexts/PageContext';
import { gEnums } from '../../../../global/enums/globalEnums';
import { FrameworkContext } from '../../../../global/cnr/contexts/FrameworkContent';

export const projectSemanticTypes = {
  attendees: 'attendees'
}

/** Creates and returns semanticUI element for the ui, called mainly from SemUI.js 
 * @param displayType 
 * @param viewListData
 * @param viewItemType
 * @param displayProps
 * @param pushSimple 
 * @param dataHelpers
 */
const ProjectSemElems = () => {

  const frameworkContext = useContext(FrameworkContext);
  const uiItemContext = useContext(UiItemContext);
  const pageContext = useContext(PageContext);

  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  const { page_fns } = pageContext ? pageContext : {}
  const { pushSimple } = page_fns ? page_fns : {}
  const { viewItem, viewListData } = uiItemContext ? uiItemContext : {}
  const { singleDataItem, display, desktopDisplay } = viewItem ? viewItem : {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { displayType } = _display ? _display : {}

  let count = {
    total: 0,
    registered: 0
  }

  if (!singleDataItem && viewListData) {
    const registered = _.filter(viewListData, 'registered')
    count.total = viewListData.length
    count.registered = registered.length
  }

  const icon = (registered) => {
    if (registered) {
      return <Icon bordered inverted color='green' style={{ float: 'right' }} name='check'></Icon>
    } else {
      return <Icon bordered color='grey' style={{ float: 'right' }} name='check'></Icon>
    }
  }

  const card = (itemData) => {
    const { firstName, lastName, email, attendeeTypes, organization, registered } = itemData ? itemData : {}
    return <Card fluid onClick={(e) => { pushSimple(viewItem, itemData.id) }}>
      <Card.Content>
        {icon(registered)}
        <Card.Header>{firstName + ' ' + lastName}</Card.Header>
        <Card.Meta>{attendeeTypes}</Card.Meta>
        <Card.Meta>{organization}</Card.Meta>
        <Card.Description>
          {email}
        </Card.Description>
        <Card.Description>
          {itemData.dup && <Label color='red'>Duplicate Email</Label>}
        </Card.Description>
      </Card.Content>
      {singleDataItem && <Card.Content extra>
        <div className='ui two buttons'>
          <Button basic color='green'>
            Send Message
          </Button>
          <Button basic color='red'>
            Resend Password
          </Button>
        </div>
      </Card.Content>}
    </Card>
  }

  const cards = () => {
    let vld = null
    const groups = _.groupBy(viewListData, 'email')
    Object.keys(groups).forEach(key => {
      if (groups[key].length > 1) {
        const items = groups[key]
        items.forEach((item, index) => {
          item.dup = true
        })
      }
    })

    vld = viewListData
    return Object.keys(vld).map(key => (
      card(vld[key])
    ))
  }

  const content = () => {
    switch (displayType) {
      case gEnums.displayTypes.attendees:
        switch (singleDataItem) {
          case true:
            const itemData = viewListData ? viewListData[0] : null
            return card(itemData)
          default:
            return cards()
        }
      default:
        return <div>{displayType}</div>
      // nothing
    }
  }

  return <Fragment>
    {content()}
  </Fragment>
}

export default ProjectSemElems