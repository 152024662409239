import React from 'react'
import { Icon } from 'semantic-ui-react'

export const GenericField = (props) => {
  const { fip, content, onEditClick } = props
  const { label } = fip
  return <div className='field'>
    <label>{label}{onEditClick && <Icon
      name={'edit'}
      style={{ float: 'right' }}
      onClick={(e) => { onEditClick && onEditClick({ fip }) }}
    />}
    </label>
    {content}
  </div>
}