import React from 'react';
import PapsProvider from '../cnr/contexts/PapsContext';
import AppView from './AppView';
import ManifestProvider from '../cnr/contexts/ManifestContext';
import SettingsDataProvider from '../cnr/contexts/SettingsDataContext';
import DataWrapperProvider from '../cnr/contexts/DataWrapperContext';
import ServiceWorkerWrapper from '../../SwWrapper';

/** Starting point fo the Page
@componentContexts [StartContext, AuthContext, AppSettingContext]  
*/
function AppRoutes() {
  return (
    <PapsProvider>
      <SettingsDataProvider>
        <ManifestProvider>
          <DataWrapperProvider>
            <AppView />
            <ServiceWorkerWrapper />
          </DataWrapperProvider>
        </ManifestProvider>
      </SettingsDataProvider>
    </PapsProvider >
  )
}

export default AppRoutes