import _ from 'lodash';
import React, { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Icon, Label, Segment } from "semantic-ui-react";
import { uniqueKey } from '../../../common/keys';

const UIDictaphone = (props) => {

  const { keyy, list, handleSelect, listenProp, opts, labelOnly } = props

  const [isListening, setIsListening] = useState();
  const [names, setNames] = useState([])

  const { finalTranscript, resetTranscript } = useSpeechRecognition()

  const [currentName, setCurrentName] = useState()

  useEffect(() => {
    if (finalTranscript && handleSelect && isListening && opts) {
      setCurrentName(finalTranscript)
      resetTranscript()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [finalTranscript]);

  useEffect(() => {
    if (currentName && handleSelect && isListening && opts) {
      console.log('currentName', currentName)
      let allow = true
      Object.keys(isListening).forEach(key => {
        if (opts[key] !== isListening[key]) {
          allow = false
        }
      })
      if (allow) {
        const _names = [...names]
        _names.push(currentName)
        setNames(_names)
        if (list) {
          let _name = currentName.replace(' ', '')
          _name = _.startCase(_name)
          let item = _.find(list, { [listenProp]: _name })
          let itemKey = _.findKey(list, { [listenProp]: _name })

          if (!item) {
            item = _.find(list, function (o) { return o[listenProp].indexOf(_name) >= 0; })
            itemKey = _.findKey(list, function (o) { return o[listenProp].indexOf(_name) >= 0; })
          }
          if (item && itemKey) {
            handleSelect(null, item, itemKey, opts)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentName]);

  const handleListing = () => {
    if (isListening) {
      setIsListening();
      SpeechRecognition.stopListening();
      resetTranscript()
    } else {
      setIsListening(opts);
      resetTranscript()
      SpeechRecognition.startListening({
        continuous: true,
      });
    }
  };

  return labelOnly ?
    <Label className={isListening ? 'blink_me_p' : null} onClick={() => handleListing()}>
      <Icon name={isListening ? 'microphone' : 'microphone slash'} />
    </Label>
    :
    <Segment key={keyy ? keyy : uniqueKey('uidt')} basic className="microphone-wrapper">
      <Label color={isListening ? 'green' : 'grey'} onClick={() => handleListing()}>
        <Icon name={isListening ? 'thumbs up' : 'thumbs down'} />Listening
      </Label>
    </Segment>
}

export const UIDictaphoneIcon = (props) => {

  const { handleResult, oneTimeOnly } = props

  const [isListening, setIsListening] = useState();

  const { finalTranscript, resetTranscript } = useSpeechRecognition()

  useEffect(() => {
    if (finalTranscript) {
      resetTranscript()
      let _name = finalTranscript.replace(' ', '')
      handleResult(_name)
      oneTimeOnly && handleListing()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [finalTranscript]);

  const handleListing = () => {
    if (isListening) {
      setIsListening();
      SpeechRecognition.stopListening();
      resetTranscript()
    } else {
      setIsListening(true);
      SpeechRecognition.startListening({
        continuous: true,
      });
    }
  };


  return <Label className={isListening ? 'blink_me_p' : null} onClick={() => handleListing()}><Icon name={isListening ? 'microphone' : 'microphone slash'} /></Label>

}

export default UIDictaphone