import _ from 'lodash';
import { addAdditionalProps } from './additionalProps';
import { addLabelProps } from './labelProps';
import { dynListTypes, gEnums, listTypes } from '../enums/globalEnums';
import { listListO, listRss, listStatic, listYears } from '../lists/lists';
import { addDynamicListOptions } from './dynamicOptions';
import { formOptionsList } from './formOptionsList';

/**
 * 
 * @param {object} props 
 */
export const getFormLists = (props) => {

  const {
    // _handlers,
    appUrls_home,
    aps_global,
    aps_viewItems,
    currentCollectionPropList,
    currentViPropList,
    data_combined,
    // eventInfo_state,
    formProps,
    handleOptionsList,
    isDeveloper,
    // page_state,
    rssFeeds_home,
    staticViews_client,
    staticViews,
    // storage_state,
    swipedItem,
    updateProps,
    viewProps,
    formParent_contexts,
  } = props

  let { dataOptions } = aps_global ? aps_global : {}

  if (viewProps && staticViews) {
    Object.keys(viewProps).forEach(key => {
      const vp = viewProps[key]
      if (key.startsWith('_')) { vp.readOnly = true }
      if (staticViews[key]) {
        if (!vp.data) { vp.data = {} }
        if (vp.data.formItemType !== gEnums.formItemTypes.dropdown && vp.data.formItemType !== gEnums.formItemTypes.component) {
          // LOOK
          vp.data.formItemType = gEnums.formItemTypes.select
          vp.data.dataListType = gEnums.dataListTypes.staticViews
          vp.data.staticList = key
        }
      }
    })
  }

  // returns viewProps where the data.listType has a value  
  const currentListProps = _.filter(viewProps, function (vp) {
    const keyz = vp.key + 's'
    if (vp.data && vp.data.listType && listTypes[vp.data.listType]) {
      return vp.data.listType
    }
    if (listTypes[keyz] && vp.data.formItemType !== gEnums.formItemTypes.component) {
      vp.data.formItemType = gEnums.formItemTypes.select
      vp.data.listType = keyz
      return listTypes[keyz]
    }
  });

  const labelPropsList = addLabelProps(viewProps);
  const _currentListProps = [...currentListProps, ...labelPropsList]

  const ol = formOptionsList({ currentListProps: _currentListProps, aps_global, aps_viewItems, staticViews, data_combined, updateProps, staticViews_client, isDeveloper })

  // returns the list of props that have a list type in dynListTypes
  const currentDynListProps = _.filter(viewProps, function (vp) {
    if (vp.data && vp.data.listType && dynListTypes[vp.data.listType]) {
      return vp.data.listType
    }
  });

  const ols = {}

  if (viewProps) {
    Object.keys(viewProps).forEach(key => {
      const vp = viewProps[key]
      if (vp.data && vp.data.dataListType && vp.data.dataListType === 'staticViews' && vp.data.staticList && staticViews[vp.data.staticList]) {
        ols[key] = listStatic(staticViews[vp.data.staticList])
      }
      switch (key) {
        case 'active':
          ols[key] = listListO(gEnums.trueFalse)
          break;
        case 'year':
          ols[key] = listYears()
          break;
        default:
          break;
      }
    });
  }

  const dynProps = {
    currentCollectionPropList,
    currentViPropList,
    data_combined,
    // eventInfo_state,
    formProps,
    // page_state,
    staticViews_client,
    // storage_state,
    formParent_contexts,
  }

  const ola = addDynamicListOptions({ currentDynListProps, dynProps })

  addAdditionalProps(swipedItem, viewProps, null, dataOptions).then(res => {
    const _ol = { ...ol, ...ola, ...ols }
    if (appUrls_home && appUrls_home.appUrls) {
      const aul = {}
      appUrls_home.appUrls.forEach(u => { aul[u.appName] = u })
      _ol[dynListTypes.appUrls] = aul ? listListO(aul) : null
    }
    if (rssFeeds_home) { _ol[dynListTypes.rssFeeds] = listRss(rssFeeds_home) }
    handleOptionsList(_ol)
  })
}

