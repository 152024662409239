import _ from 'lodash'
import { mergeDirectionTypes } from '../cnr/reducers/DataManagmentReducer'
import { getFirstObject } from './convert'

export const dataDiffTypes = {
  existingItems: 'existingItems',
  googleItems: 'googleItems',
  mergedItems: 'mergedItems',
  diffItems: 'diffItems',
}

/**
 * 
 * @param {object} existingItems 
 * @param {object} otherItems 
 * @param {object} staticViews 
 * @param {boolean} ignoreInit 
 * @param {string} mergeDirectionType 
 * @returns A list of objects that whose values are different
 */
export const getDataDiff = (existingItems, otherItems, staticViews, ignoreInit, mergeDirectionType) => {

  if (existingItems && otherItems) {

    const _diffData = {}
    const _mergedData = {}

    Object.keys(otherItems).forEach(itemKey => {

      const otherItem = otherItems[itemKey]

      const { _gsid, _itemKey, email, name } = otherItem

      // find the corresponding google sheet row by the gsid
      // const newItem = _.find(googleItems, { _gsid: _itemKey })

      let existingItem;

      if (_gsid) {
        existingItem = _.find(existingItems, function (ei) {
          return (ei._gsid === _gsid || ei._itemKey === _itemKey);
        })
      } else if (name) {
        existingItem = _.find(existingItems, function (ei) {
          return (ei.name === name);
        })
      } else if (email) {
        existingItem = _.find(existingItems, function (ei) {
          return (ei.email === email);
        })
      } else if (_itemKey) {
        existingItem = _.find(existingItems, function (ei) {
          return (ei._gsid === _itemKey || ei._itemKey === _itemKey);
        })
      }

      if (existingItem) {
        const { _itemKey: ik } = existingItem
        const _merged = mergeDirectionType === mergeDirectionTypes.existing_new ? { ...existingItem, ...otherItem } : { ...otherItem, ...existingItem }
        if (!_mergedData[ik]) { _mergedData[ik] = {} }
        _mergedData[ik] = _merged
        mergeItem(_diffData, ik, existingItem, otherItem, staticViews, ignoreInit)
      } else {
        _diffData[_itemKey] = otherItem
      }
    })

    return mergeDirectionType ? _mergedData : _diffData
  }
}

export const getDataNew = (existingItems, otherItems) => {
  if (existingItems && otherItems) {
    const _newData = {}
    Object.keys(otherItems).forEach(itemKey => {
      const existingItem = existingItems[itemKey]
      if (!existingItem) {
        _newData[itemKey] = otherItems[itemKey]
      }
    })
    return _newData
  }
}

const mergeItem = (dd, itemKey, existingItem, newItem, staticViews, ignoreInit) => {

  let itemIsDifferent = false

  const _diffItem = {}

  // get a list of propKeys from both new and existing items
  const propKeys = [...Object.keys(existingItem), ...Object.keys(newItem)]

  // loop the item data
  propKeys.forEach(propKey => {

    switch (propKey) {
      case '_itemKey':
      case '_sv':
      case 'endDateUTC':
      case 'endTimeUTC':
      case 'id':
      case 'key':
      case 'parentKeys':
      case 'startDateUTC':
      case 'startTimeUTC':
      case 'uid':
        // ignore
        break;

      default:

        let existingValue = existingItem[propKey]

        if (_.isObject(existingValue) && _.isEmpty(existingValue)) {
          existingValue = null
        }

        let newValue = newItem[propKey]

        newValue = ignoreInit ? newItem[propKey] : appendNewValue(newItem[propKey], propKey, staticViews)

        if (!_.isEmpty(newValue)) {
          _diffItem[propKey] = newValue
        }

        if (_.isArray(existingValue)) {
          if (!_.isEqual(existingValue, newValue)) {
            _diffItem[propKey] = { existingValue, newValue }
          }
        } else {
          if (existingValue !== newValue) {
            if (_.isObject(existingValue)) {
              // console.log('existingValue', existingValue)
            }
            _diffItem[propKey] = { existingValue, newValue }
            if (existingValue && newValue) {
              itemIsDifferent = true
            }
          }
          break;
        }
    }
  })

  if (itemIsDifferent) {
    dd[itemKey] = _diffItem
  }
}


const appendNewValue = (nv, eiKey, staticViews) => {
  if (_.isArray(nv)) {
    const newArray = []
    const _staticView = staticViews ? staticViews[eiKey] : {}
    if (_staticView) {
      const { item: svFirst } = getFirstObject(_staticView)
      const { firstName, lastName, name } = svFirst ? svFirst : {}
      nv.forEach(arrayItem => {
        let matchingKey;
        if (firstName && lastName) {
          const arrayItemS = arrayItem.split(' ')
          if (arrayItemS.length === 2) {
            matchingKey = _.findKey(_staticView, { firstName: arrayItemS[0], lastName: arrayItemS[1] })
          }
        } else if (name) {
          matchingKey = _.findKey(_staticView, { name: arrayItem })
        }
        if (matchingKey) {
          newArray.push(matchingKey)
        } else {
          newArray.push(arrayItem)
        }
      })
      nv = newArray
      return nv
    }
  }

  return nv

}