import { svgElement } from './svgElement';

export const svgTypes = {
  circle: 'circle',
  line: 'line',
  rectangle: 'rectangle',
  text: 'text',
  textMulti: 'textMulti',
  textStat: 'textStat',
}

export const svgColorTypes = {
  normal: 'normal',
  current: 'current',
  selected: 'selected',
}


// add svgElements (as props) to an array
export const svgElements = (elemsAndPercs, currentElem, currentZone, elemsOnly, handleClick, imageDims) => {

  const elems = []

  if (elemsOnly) {
    elemsOnly.map((element, i) => (
      elems.push(svgElement(element, i, svgColorTypes.normal, handleClick, imageDims))
    ))
  } else if (elemsAndPercs) {
    if (elemsAndPercs.elems) {
      elemsAndPercs.elems.forEach((element, i) => {
        elems.push(svgElement(element, i, svgColorTypes.normal, handleClick, imageDims))
      })
    }
  }

  if (currentElem) { elems.push(svgElement(currentElem, 'selected', svgColorTypes.selected, null, imageDims)) }
  if (currentZone) { elems.push(svgElement(currentZone, 'current', svgColorTypes.current, null, imageDims)) }

  return elems
}

// set of svgElementProps
export const svgElementProps = {
  line: (dims, strokeWidth, color, withArrow) => {
    const { sx, ex, sy, ey } = dims
    return {
      sx: sx,
      ex: ex,
      sy: sy,
      ey: ey,
      strokeWidth: strokeWidth,
      color: color,
      et: svgTypes.line,
      withArrow: true
    }
  },
  lineFullHorz: (ex, y, strokeWidth, color) => {
    return {
      sx: 0,
      ex: ex,
      sy: y,
      ey: y,
      color: color,
      strokeWidth: strokeWidth,
      et: svgTypes.line
    }
  },
  rect: (dims, color, gap, halfGap) => {
    const { w, h, sx, sy } = dims
    if (gap) {
      const halfGap = gap / 2
      return {
        // reduce w and h
        w: (w - gap),
        h: (h - gap),
        sx: (sx + halfGap),
        sy: (sy + halfGap),
        color: color,
        et: svgTypes.rectangle,
      }
    } else {
      return {
        // reduce w and h
        w: w,
        h: h,
        sx: sx,
        sy: sy,
        color: color,
        et: svgTypes.rectangle,
      }
    }
  },
  circle: (dims, color, className) => {
    const { sx, sy, r } = dims
    return {
      sx: sx,
      sy: sy,
      r: r,
      color: color,
      strokeWidth: 1,
      et: svgTypes.circle,
      className: className
    }
  },
  text: (x, y, text, color, fs) => {
    return {
      x: x,
      y: y,
      text: text,
      color: color,
      fs: fs,
      et: svgTypes.text
    }
  },
  textStat: (x, y, stat, color, fs) => {
    return {
      x: x,
      y: y,
      stat: stat,
      color: color,
      fs: fs,
      et: svgTypes.textStat,
    }
  },
  textMulti: (x, y, text, color, fs, center, text1, text2) => {
    return {
      x: x,
      y: y,
      text: text,
      color: color,
      fs: fs,
      et: svgTypes.textMulti,
      center: center,
      text1: (text1) ? text1 : '--',
      text2: (text2) ? text2 : '--'
    }
  },
  halfRect: (ce, cs, swipeArea) => {
    // creates a rectangle for HALF the court 
    const color = 'darkgrey'
    if (swipeArea) {
      if (swipeArea.bottom && swipeArea.top) {
        // show both
      } else {
        if (swipeArea.top) {
          ce.rects.push(this.rect(cs.width, cs.halfH, 0, cs.halfH, color))
        } else if (swipeArea.bottom) {
          ce.rects.push(this.rect(cs.width, cs.halfH, 0, 0, color))
        }
      }
    }
  }
}