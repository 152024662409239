import { appIconTypes } from '../enums/appIconTypes';
import { iconColorTypes } from '../enums/settingsIconTypes';

/**
 * 
 * @param {object} appUserAccess 
 * @param {object} aps_global 
 * @param {???} notificationRequestResponse 
 * @param {boolean} messagingSupported 
 * @returns {color, circular, bordered, value, name, appManagmentLeve}
 */
export const getAuthHeaderProps = (props) => {

  const { appUserAccess, aps_global, notificationRequestResponse, messagingSupported } = props
  const { initials, loggedIn } = appUserAccess ? appUserAccess : {}
  let { appUserColor } = appUserAccess ? appUserAccess : {}
  let { appUserViewTypeProp } = aps_global ? aps_global : {}
  if (!appUserViewTypeProp) { appUserViewTypeProp = 'registrationType' }

  let iconName = loggedIn ? null : appIconTypes.user

  return {
    // bordered: false,
    color: loggedIn ? appUserColor : iconColorTypes.default,
    circular: messagingSupported ? true : false,
    bordered: messagingSupported ? false : true,
    value: loggedIn ? initials : null,
    name: iconName,
    appManagementLevel: 0,
    nrr: notificationRequestResponse,
  }
}