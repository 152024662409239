import { profileImagePromises } from '../../storage/storageHelpers';
import { getAuth, getIdTokenResult } from "firebase/auth";

/** Get the user's token (getIdTokenResult) and imageUrl (storage) */
/**
 * 
 * @param {object} auth 
 * @returns a promise with the result of getIdToken and profileImages
 */
export const getAdditionalAppUserInfoPromise = () => {
  const auth = getAuth()
  const { currentUser } = auth
  const promises = []
  // get tokens 
  promises.push(getIdTokenResult(currentUser))
  promises.push(profileImagePromises(currentUser))
  return Promise.all(promises)

}