import React, { useEffect, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { iconColorTypes } from '../../../enums/settingsIconTypes';
import { NumberPickerDirect } from './NumberPicker';

/**
 * 
 * @param {object} props (checkAllow, allowed, item, itemKey, handleClick, handleCount, handle_click, label, checked, cnp, size, optionalData, asLabel, labelLeft, disabled, useCounter)
 * @returns An Icon/Label combination representing an `Checkbox`
 */
const Checker = (props) => {

  const {
    allowed,
    altLabel,
    asLabel,
    checkAllow,
    checked,
    cnp,
    disabled,
    handle_click,
    handleClick,
    handleCount,
    item,
    itemKey,
    label,
    labelLeft,
    optionalData,
    size,
    useCounter
  } = props

  let allowChecking = true

  if (checkAllow) { allowChecking = allowed ? true : false }

  const [itemChecked, setItemChecked] = useState()

  const useShortLabel = false

  useEffect(() => {
    setItemChecked(checked)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [checked]);

  const handleCheck = (e) => {
    if (disabled && !itemChecked) { return false }
    setItemChecked(!itemChecked)
    item.checked = !itemChecked
    handleClick && itemKey && handleClick(e, itemKey)
    handleClick && !itemKey && handleClick(e, item)
    handle_click && handle_click(e, item, itemChecked, optionalData)
  }

  const handleCountChange = (v) => handleCount(item, v)

  const counter = () => <NumberPickerDirect onChange={handleCountChange} start={1} end={2} initialValue={1} />

  const checkerText = (_label) => {
    if (altLabel) {
      return <div className={'checker-text'}>
        {_label && <label>{_label}</label>}
        {altLabel && <label>{altLabel}</label>}
      </div>
    } else {
      return <label>{_label}</label>
    }
  }

  const checker = () => {
    const checkColor = handle_click ? iconColorTypes.success : iconColorTypes.menuItem
    let icon = itemChecked ? 'check' : 'square outline'
    const color = itemChecked ? checkColor : iconColorTypes.default
    if (disabled && !itemChecked) { icon = 'minus' }
    let cn = 'checker'
    if (cnp) { cn += ' ' + cnp }
    if (useCounter) { cn += ' counter' }
    if (altLabel) { cn += ' alt' }

    const _label = useShortLabel && label && label.length > 40 ? label.substring(0, 40) + '...' : label

    if (asLabel) {
      return <div className={cn}>
        <Label key={item.key} propname={item.key} bordered color={color} size={size} inverted onClick={(e) => { handleCheck(e) }}>{_label}</Label>
        {useCounter && itemChecked && counter()}
      </div>
    } else {
      return <div className={cn}>
        {/* {label && labelLeft && <label>{_label}</label>} */}
        {allowChecking && <Icon key={item.key} propname={item.key} bordered color={color} size={size} inverted name={icon} onClick={(e) => { handleCheck(e) }} />}
        {checkerText(_label)}
        {/* {label && !labelLeft && <label>{_label}</label>}
        {altLabel && <label>{altLabel}</label>} */}
        {useCounter && itemChecked && counter()}
      </div>
    }
  }
  return checker()
}

export default Checker