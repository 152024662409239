import _ from 'lodash';
import React, { useContext, useRef } from 'react';
import ReactJson from 'react-json-view';
import { uniqueKey } from "../../common/keys";
import { copyCodeToClipboard } from '../../common_web/copy';
import { PageContext } from '../../cnr/contexts/PageContext';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper from '../../wrappers/Wrapper';
import GenericButton from '../../genericControls/GenericButton';

/**
 * 
 * @param {object} props (keyy, vlProps, itemData, vit, showButtons, inApp, fullView, saveButton, fullScreen, noFullHeight, ignoreSortKeys)
 * @returns a json viewer
 */
const JsonViewer = (props) => {

  let initJson;
  let initName;

  // paste_handlers
  const { keyy, vlProps, itemData, vit, showButtons, inApp, fullView, saveButton, fullScreen, noFullHeight, ignoreSortKeys } = props
  const { fieldProps, fip, data_current } = vlProps ? vlProps : {}

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { settingsOptions } = aps_global ? aps_global : {}
  const { useDarkMode } = settingsOptions ? settingsOptions : {}

  const darkMode = useDarkMode && !inApp ? true : false

  if (vlProps && fip.propname && data_current && data_current[fip.propname]) {
    initName = fip.propname
    initJson = data_current[fip.propname]
  } else if (itemData) {
    initName = vit
    initJson = itemData
  } else {
    initName = props.name
    initJson = props.json
  }

  if (!initName) { initName = 'data' }

  const name = initName
  const json = initJson

  const handleOnChange = () => { }

  // const doThis = (a) => {
  //   const { updated_src } = a //existing_src
  //   if (paste_handlers && paste_handlers.handleUpdatePaste) { paste_handlers.handleUpdatePaste(updated_src) }
  //   if (form_handlers) {
  //     form_handlers.handleSubPropChange(updated_src, fip.propname)
  //   }
  // }

  const textAreaRef = useRef()

  const copyToClipboard = () => copyCodeToClipboard(textAreaRef)

  let cn = 'json-container'
  if (noFullHeight) { cn += ' nfh' }
  if (inApp) { cn += ' in-app' }

  const viewer = () => <div key={uniqueKey('jvv', 'hi', keyy ? keyy : 'jvr')} className={cn} >
    <ReactJson
      name={_.startCase(name)}
      collapsed={fullView ? false : 1}
      displayDataTypes={false}
      enableClipboard={false}
      sortKeys={ignoreSortKeys ? false : true}
      theme={darkMode ? 'monokai' : 'rjv-default'}
      // onEdit={allowEdit ? doThis : null}
      displayObjectSize={false}
      onEdit={false}
      onAdd={false}
      src={json}
    />
  </div>

  const content = () => <React.Fragment>
    {viewer()}
    <textarea key={uniqueKey('jv', 'ta')} ref={textAreaRef} value={JSON.stringify(initJson, null, 3)} style={{ position: 'absolute', left: '-999em' }} onChange={handleOnChange} />
  </React.Fragment>

  const btnProps = { inverted: darkMode }

  const gb = (key, caption, icon, onClick, clickOptions, opts, noE) => <GenericButton gbProps={{ key, caption, icon, onClick, clickOptions, opts, noE }} />

  const header = () => <React.Fragment>
    {showButtons && gb('btn-copy', 'Copy', 'copy', copyToClipboard, null, { ...btnProps })}
    {saveButton && saveButton}
  </React.Fragment>

  const viewerWrap = () => <Wrapper
    header={(showButtons || saveButton) && header()}
    content={content()}
    css={{ container: 'ct-json', header: 'padd5' }}
  >
  </Wrapper>

  const fullPageWrapper = () => <FullPageWrapper
    content={viewerWrap()}
    topperCaption={initName}
  />

  if (fullScreen) {
    return fullPageWrapper()
  } else {
    if (vlProps) {
      return <div className={'field-object-container'} key={uniqueKey('jv')}>
        <div key={uniqueKey('jv', 'fl')} className={'field field-label'}>
          <label style={{ float: 'left' }}>{fieldProps.label}</label>
        </div>
        <div key={uniqueKey('jv', 'vw')} className={'field-objects'}>{viewerWrap()}</div>
      </div>
    } else {
      return viewerWrap()
    }
  }
}

export default JsonViewer