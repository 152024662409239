import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import AppUserList from '../../appUsers/AppUserList';
import { uniqueKey } from '../../common/keys';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { fsfn_getAuthsByChunkEmail } from '../../functions/fbAuth';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';

const limit = 100
const _defaultDays = 7
const _dayBackMax = 60

const _authListTypes = {
  recent: 'recent',
  found: 'found',
  notFound: 'notFound',
}

export const UserAuths = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, paps_state, eventInfo_state } = states
  const { pathViews } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  // eventInfoContext  
  const { appProfileData } = eventInfo_state ? eventInfo_state : {}

  let _staticAppUsers;

  if (appUserCollection && appProfileData && appProfileData[appUserCollection]) {
    _staticAppUsers = appProfileData[appUserCollection]
  }

  const [authsList, setAuthsList] = useState()
  const [authsFound, setAuthsFound] = useState()
  const [selectedListType, setSelectedListType] = useState(_authListTypes.recent)
  const [dayCount, setDayCount] = useState(_defaultDays)

  const handleDayChange = (e, d) => setDayCount(d.value)
  const handleSelectedListType = (sl) => setSelectedListType(sl)

  useEffect(() => {
    if (_staticAppUsers) {
      const _sau = _.sortBy(_staticAppUsers, 'lastName')
      const chunks = _.chunk(_sau, limit);
      fsfn_getAuthsByChunkEmail(chunks, pathViews, true, setAuthsFound)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_staticAppUsers]);

  useEffect(() => {
    if (authsFound && _staticAppUsers) {
      const { found } = authsFound
      const recent = getRecent(found)
      Object.keys(_staticAppUsers).forEach((key) => {
        const _staticAppUser = _staticAppUsers[key]
        const { email } = _staticAppUser
        const _af = email ? _.find(found, { email: email.toLowerCase() }) : null
        const _ar = email ? _.find(recent, { email: email.toLowerCase() }) : null
        _staticAppUser.foundType = _authListTypes.notFound
        if (_af) { _staticAppUser.foundType = _authListTypes.found }
        if (_ar) { _staticAppUser.foundType = _authListTypes.recent }
        if (_af || _ar) {
          _staticAppUser.metadata = _af.metadata
        }
      })
      setAuthsList(_.groupBy(_staticAppUsers, 'foundType'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [authsFound, dayCount]);

  const getRecent = (found) => {

    const nowDate = new Date()
    const nowTime = nowDate.getTime()
    const dis = 1000 * 3600 * 24

    return _.filter(found, function (f) {
      if (f.metadata && f.metadata.lastRefreshTime) {
        const time = f.metadata.lastRefreshTime
        const lrtDate = new Date(time)
        const lrtTime = lrtDate.getTime()
        const diffTime = nowTime - lrtTime
        const lrtDays = diffTime / dis
        if (lrtDays < dayCount) {
          return found
        }
      }
    })
  }

  const options = () => {
    const opts = []
    for (let i = 1; i <= _dayBackMax; i++) {
      opts.push({ text: 'Last ' + i + ' Days', value: i, key: i })
    }
    return opts
  }

  const dropdown = () => <Dropdown text={'Last ' + dayCount + ' Days'} direction='left' className={'ua-search'} placeholder='days' size={'mini'} search selection options={options()} onChange={handleDayChange} />

  const button = (key, basic, text, color, oc) => <Button key={uniqueKey('au', 'b', key)} compact basic={basic} color={color} onClick={(e) => { oc(key) }}>{text}</Button>

  const buttons = () => {
    const rc = authsList && authsList[_authListTypes.recent] ? authsList[_authListTypes.recent].length : 0
    const fc = authsList && authsList[_authListTypes.found] ? authsList[_authListTypes.found].length : 0
    const nfc = authsList && authsList[_authListTypes.notFound] ? authsList[_authListTypes.notFound].length : 0
    return <Button.Group size={'mini'}>
      {button(_authListTypes.recent, (selectedListType === _authListTypes.recent ? false : true), rc, 'green', handleSelectedListType)}
      {button(_authListTypes.found, (selectedListType === _authListTypes.found ? false : true), fc, 'blue', handleSelectedListType)}
      {button(_authListTypes.notFound, (selectedListType === _authListTypes.notFound ? false : true), nfc, 'red', handleSelectedListType)}
    </Button.Group>
  }

  const content = () => {
    const authsRecent = authsList && authsList[_authListTypes.recent] ? authsList[_authListTypes.recent] : []
    const authsFound = authsList && authsList[_authListTypes.found] ? authsList[_authListTypes.found] : []
    const authsNotFound = authsList && authsList[_authListTypes.notFound] ? authsList[_authListTypes.notFound] : []
    switch (selectedListType) {
      case _authListTypes.recent:
        return <AppUserList items={authsRecent} showFound={true} found={true} />
      case _authListTypes.found:
        return <AppUserList items={authsFound} showFound={true} found={true} />
      case _authListTypes.notFound:
        return <AppUserList items={authsNotFound} showFound={true} found={false} />
      default:
      // nothing
    }
  }

  const header = () => <div className='flx-lr'>
    {buttons()}
    {dropdown()}
  </div>

  const wrapper = () => {
    return <Wrapper
      header={header()}
      content={content()}
      wrapperType={wrapperTypes.paddedHeader}
      updating={!authsList}
      cnAlt={'user-auths'}
    ></Wrapper >
  }

  return wrapper()
}