import React from 'react'
import { motion } from 'framer-motion'
import PageItemDimmer from '../page/PageItemDimmer'
// import { Dimmer } from 'semantic-ui-react'

const MotionComponent = (props) => {

  const { transition, cnRoot, oc } = props
  const { animateType, variants, cn, styleAndClass, showPendingDimmer } = transition ? transition : {}

  const _styleAndClass = styleAndClass ? styleAndClass : {}

  let _className = 'motion-container ' + animateType
  if (cn) { _className += ' ' + cn }
  if (cnRoot) { _className += ' ' + cnRoot }

  // const dimmerVariants = {
  //   initial: {
  //     opacity: 1,
  //     y: null
  //   },
  //   animate: {
  //     opacity: 0,
  //     y: 0,
  //     transition: {
  //       duration: 4
  //     }
  //   },
  //   exit: null
  // }

  // const motionDimmer = () => <motion.div
  //   variants={dimmerVariants}
  //   initial={'initial'}
  //   animate={'animate'}
  //   exit={'exit'}
  //   style={{ backgroundColor: 'black' }}
  // >
  //   {props.children}
  //   <Dimmer active />
  // </motion.div>

  const motionDiv = () => <motion.div
    variants={variants}
    initial={'initial'}
    animate={'animate'}
    exit={'exit'}
    className={_className}
    {..._styleAndClass}
    onClick={() => { oc && oc() }}
  >
    {props.children}
    {/* {motionDimmer()} */}
  </motion.div>

  if (showPendingDimmer) {
    return <PageItemDimmer content={motionDiv()} />
  } else {
    return motionDiv()
  }
}

export default MotionComponent