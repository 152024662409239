import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { appNotificationsHandlers, appNotificationsInitialState, appNotificationsReducer } from '../../cnr/reducers/AppNotificationsReducer';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { _notificationsCollection } from '../../firestoreData/notifications/notificationsData';
import { AppUserContext } from './AppUserContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** Notifications for the event
 * @ParentProvider - NOT AVAILIBLE
 */
export const AppNotificationsContext = createContext();

/** Global Files for the Event */
const AppNotificationsProvider = (props) => {

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ? appUserContext : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // papsContext 
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ? papsContext : {}
  const { pathViews, showStatus } = paps_state ? paps_state : {}

  // pageContext 
  const pageContext = useContext(PageContext)
  const { page_state, page_fns, navigate } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appNotifications, logging } = aps_global ? aps_global : {}
  const { allowNotifications, allowPushNotifications, pushNotificationsActive, pushActiveToSelf, useNotificationTopics } = appNotifications ? appNotifications : {}

  // reducer
  const initState = appNotificationsInitialState({ appUser, appNotifications, appUserAccess, aps_appUserSettings, pathViews, page_fns, logging, navigate, aps_viewItems, useNotificationTopics })
  const [appNotifications_state, notifications_dispatch] = useReducer(appNotificationsReducer, initState);
  const appNotifications_handlers = appNotificationsHandlers(notifications_dispatch)

  const { handleAppUserNotifications, handleScheduleNotifications, handleLoadNotifications, handleNewForegroundNotification } = appNotifications_handlers
  const { notifications_app } = appNotifications_state

  if (showStatus && notifications_app) { console.log('appNotifications_state ready', appNotifications_state) }

  const messaging = isSupported() ? getMessaging() : null

  useEffect(
    () => {
      if (pathViews && pathViews.events) {
        const refPath = createRefPath_event(pathViews, [_notificationsCollection])
        fs_get_data({ refPath: refPath, callback: handleLoadNotifications })
      } else {
        handleLoadNotifications({})
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathViews.events] // does not re-rednder unless uivi has changed
  )

  useEffect(() => {
    if (notifications_app && appUserAccess) {
      handleAppUserNotifications(appUserAccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserAccess, notifications_app]);

  if (messaging) {
    // onMessageListener()
    // NOTIFICATION - ON FOREGROUND MESSAGE
    // handles the notication when the app is in the FOREGROUND!!! 
    // try {
    //   onMessage(messaging, (payload) => {
    //     console.log('payload', payload)
    //     // console.log('App has just recieved and FOREGROUND message', payload)
    //     if (payload) {
    //       handleNewForegroundNotification(payload)
    //     }
    //   });
    // } catch (error) {
    //   console.error(error)
    // }
  }

  useEffect(() => {
    if (notifications_app && allowNotifications && allowPushNotifications && (pushNotificationsActive || pushActiveToSelf)) {
      handleScheduleNotifications(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications_app]);

  const providerContext = () => <AppNotificationsContext.Provider value={{ appNotifications_state, appNotifications_handlers, appUser }}>
    {props.children}
  </AppNotificationsContext.Provider>

  return (
    <React.Fragment>
      {(notifications_app) && providerContext()}
    </React.Fragment>
  )
}

export default AppNotificationsProvider