import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { uniqueKey } from '../../common/keys';
import { AppFormsContext } from '../../cnr/contexts/AppFormsContext';
import { AppUserContext } from '../../cnr/contexts/AppUserContext';
import { PageContext } from '../../cnr/contexts/PageContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import { gEnums } from '../../enums/globalEnums';
import { appFormTypes } from '../../enums/appFormTypes';
import { dataModifyHandlers, dataModifyReducer } from '../../cnr/reducers/DataModifyReducer';
import { getFormPropz } from '../forms/props/formPropz';
import Uploader from '../uploading/Uploader';
import FormForm from '../forms/FormForm';

const UserAccess = (props) => {

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ? appUserContext : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { displayName } = appUserAccess ? appUserAccess : {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state, navigate } = papsContext ? papsContext : {}

  // papsContext
  const pageContext = useContext(PageContext);

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state } = appFormsContext ? appFormsContext : {}
  const { appForms } = appForms_state ? appForms_state : {}

  // data_current: vProps,
  const init_state = { appUser, navigate, paps_state, itemData: props.itemData, dataUpdateType: 'edit', vit: 'appUser', pageContext }
  const [dataModify_state, uiItemDataDispatch] = useReducer(dataModifyReducer, init_state);
  const dataModify_handlers = dataModifyHandlers(uiItemDataDispatch, dataModify_state)
  const { uploadOpen, itemData } = dataModify_state

  // local state  
  const [userInfo] = useState(props.itemData)
  const infoReady = false

  const [formProps, setFormProps] = useState({})

  useEffect(() => {
    if (appForms && appForms[appFormTypes.appUserAccess]) {
      const vprs = appForms[appFormTypes.appUserAccess]
      const fpp = { form_handlers: dataModify_handlers, viewProps: vprs, data_current: userInfo }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // handlers
  const handleUpdate = () => { }

  const buttons = (keyy) => {
    const b = []
    b.push(<Button key={uniqueKey('ua', 'btn', keyy)} disabled={!infoReady} icon='check' primary color='blue' basic onClick={() => { handleUpdate() }} />)
    return b
  }

  const content = () => {
    formProps.data_current = userInfo
    return <FormForm formProps={formProps} />
  }

  const userContent = (keyy) => {
    return <div key={uniqueKey('ua', keyy)} className={'hc-container'} >
      <div className={'hc-header padd5'} >
        {displayName}{buttons(keyy)}
      </div>
      <div className={'hc-content padd5'} >{content()}</div>
    </div>
  }

  if (uploadOpen) {
    return <Uploader
      itemData={itemData}
      origin={'userAccess'}
      storageLocationType={gEnums.storageLocationTypes.profiles}
    />
  } else {
    return userContent()
  }
}

export default UserAccess