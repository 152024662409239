import React, { useContext, useEffect, useState } from 'react';
import { DataModifyContext } from '../../../cnr/contexts/DataModifyContext';
import { SettingsAreaContext } from '../../../cnr/contexts/SettingsAreaContext';
import { createObject } from '../../../common/creating';
import FormForm from '../../../components/forms/FormForm';
import { getFormPropz } from '../../../components/forms/props/formPropz';
import Wrapper from '../../../wrappers/Wrapper';
import MapListData from './MapListData';

const MapListContent = (props) => {

  const { mapListProps } = props
  const { isNew, handleSetMapList: setMapList } = mapListProps ? mapListProps : {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext)

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_state, dataModify_handlers } = dataModifyContext ? dataModifyContext : {}
  const { dataListData, dataListKey } = dataModify_state ? dataModify_state : {}
  const { setMapList: setMapListDim } = dataModify_handlers ? dataModify_handlers : {}

  const initForm = isNew ? { name: null } : { name: dataListKey }
  const initListData = dataListData ? dataListData[dataListKey] : null
  const initData = isNew ? null : initListData

  const [formProps, setFormProps] = useState()
  const [formData, setFormData] = useState(initForm)
  const [listData, setListData] = useState(initData)

  const handleLocalPropChange = (data_form) => setFormData(data_form)

  const form_handlers = { handleFormDataChange_local: handleLocalPropChange }

  useEffect(() => {
    if (setMapList) {
      if (formData && listData && formData.name && Object.keys(listData).length > 0) {
        const mapList = { [formData.name]: { ...listData } }
        setMapList(mapList)
      } else {
        setMapList(null)
      }
    }
    if (setMapListDim) {
      if (formData && listData && formData.name && Object.keys(listData).length > 0) {
        const mapList = { [dataListKey]: { ...listData } }
        setMapListDim(mapList)
      } else {
        setMapListDim(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, listData]);

  useEffect(() => {
    const { settingsArea_state } = settingsAreaContext ? settingsAreaContext : {}
    const { other } = settingsArea_state ? settingsArea_state : {}
    const { simpleInput } = other ? other : {}
    const { groupItems } = simpleInput ? simpleInput : {}
    if (groupItems) {
      const ips = createObject(groupItems, 'prop')
      const fpp = { form_handlers, viewProps: ips, data_current: formData }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const content = () => <FormForm
    formAttributes={{ className: 'mlc' }}
    formProps={formProps}
  />

  if (formProps) {
    return <Wrapper
      header={content()}
      content={<MapListData listData={listData} setListData={setListData} />}
      css={{ header: 'padd10' }}
    />
  }

  return <div></div>

}

export default MapListContent