import { arrayRemove, arrayUnion, where } from 'firebase/firestore';
import _ from 'lodash';
import { isLocalhost } from '../../../project/fbConfiguration';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { getAuthColor } from '../../auth/authPermissions';
import { updateAppProfileDirect } from '../../auth/profiles/updateProfile';
import { sendPasswordResetEmail } from '../../auth/signInActions/resetPasswordEmail';
import { signUpEmailDirect } from '../../auth/signInActions/signUpEmail';
import { createInitials } from '../../common/dataAdjust';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { updateProfilePageFavorite } from '../../firestoreData/favorites/updatePageFavorite';
import { getClonedAppUser } from '../../firestoreData/profiles/getClonedAppUser';
import { getAppUserInfo } from "../../firestoreData/profiles/getProfile";
import { updateAppUserFcmTokenToDatabase } from '../../firestoreData/profiles/updateToken';
import { convertPathToKeys } from '../../redirection/current';
import { geoListTypes } from './GoogleMapReducer';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

export const rts = {
  handleAddEvent: 'handleAddEvent',
  handleAddToHomePageResponse: 'handleAddToHomePageResponse',
  handleAppUserPageData: 'handleAppUserPageData',
  handleClipboard: 'handleClipboard',
  handleClone: 'handleClone',
  handleConfirmAccessCode: 'handleConfirmAccessCode',
  handleConnectToAppUser: 'handleConnectToAppUser',
  handleCurrentClientMessageToken: 'handleCurrentClientMessageToken',
  handleDisclaimerResponses: 'handleDisclaimerResponses',
  handleGeoLocation: 'handleGeoLocation',
  handleGetAppUserEventInfo: 'handleGetAppUserEventInfo',
  handleGetProfile: 'handleGetProfile',
  handleGetSomething: 'handleGetSomething',
  handleGetUserLocation: 'handleGetUserLocation',
  handleInitCurrentUser: 'handleInitCurrentUser',
  handleLinkAppUserData: 'handleLinkAppUserData',
  handlePageFavorite: 'handlePageFavorite',
  handleResetAccessLevel: 'handleResetAccessLevel',
  handleSendEMailToEmail: 'handleSendEMailToEmail',
  handleSendPasswordResetEmail: 'handleSendPasswordResetEmail',
  handleSetAppUser: 'handleSetAppUser',
  handleSetClonedUserAccess: 'handleSetClonedUserAccess',
  handleSetMyLocation: 'handleSetMyLocation',
  handleSetNewLocation: 'handleSetNewLocation',
  handleTempAccessLevel: 'handleTempAccessLevel',
  handleUpdateAppUserEventInfo: 'handleUpdateAppUserEventInfo',
  handleUpdateAppUserFcmTokenToDatabase: 'handleUpdateAppUserFcmTokenToDatabase',
  handleUpdateAppUserProfile: 'handleUpdateAppUserProfile',
  handleUpdateNotificationToken: 'handleUpdateNotificationToken',
  handleUpdateToken: 'handleUpdateToken',
  handleUpdateAccess: 'handleUpdateAccess',
  ...grts
}

export const appUserReducerInitialState = (initState) => {
  return { ...initState }
};

export const appUserReducer = (state, action) => {

  const { pathViews, aps_appUserSettings, appUser: appUser_state, accessConfirmed } = state
  const { type, dispatch, email, appUser: appUser_action, paps_state, itemData, cbItems } = action
  const { events: eventKey } = pathViews
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  const appUserAccess = appUser_state ? getAppUserAccess(appUser_state) : {}
  const { appUserSession } = appUserAccess ? appUserAccess : {}
  const { _itemKey, appUserSessionKey } = appUserSession ? appUserSession : {}

  const _accessConfirmed = eventKey ? window.localStorage.getItem('accessConfirmed-' + eventKey) : accessConfirmed

  const appUser_handlers = appUserHandlers(dispatch)

  const { handleSetClonedUserAccess, handleUpdateToken, handleSetAppUser } = appUser_handlers
  const { handleSetMyLocation, handleSetNewLocation, handleGetProfile, handleGetAppUserEventInfo, handleUpdateAppUserEventInfo } = appUserHandlers(dispatch)

  switch (type) {

    case rts.handleInitCurrentUser:
      handleGetProfile()
      return { ...state, currentUser: action.currentUser }

    case rts.handleGetProfile:
      getAppUserInfo(state, handleSetAppUser, handleUpdateAppUserEventInfo)
      getMyLocation(geoListTypes.location, handleSetMyLocation, handleSetNewLocation)
      return { ...state }

    case rts.handleSetAppUser:

      const _appUser_action = { ...appUser_action }
      if (appUser_action && appUser_action.profileData) {
        _appUser_action.pageFavz = getFavs(appUser_action.profileData, pathViews)
      }
      const _state2 = {
        ...state,
        appUser: _appUser_action,
        appUserChecked: true,
        isDeveloper: isDeveloper(_appUser_action),
      }
      if (eventKey) { _state2.accessConfirmed = _accessConfirmed }
      if (!_state2.profileData && _appUser_action.profileData) {
        _state2.profileData = _appUser_action.profileData
      }

      return { ..._state2 }

    case rts.handleConfirmAccessCode:
      window.localStorage.setItem('accessConfirmed-' + eventKey, action.passcode)
      return { ...state, accessConfirmed: action.passcode }

    case rts.handleSetMyLocation:
      return { ...state, appUserLocation: action.location, appUserLocationGranted: action.locationGranted }

    case rts.handleSetNewLocation:
      return { ...state, appUserNewLocation: action.location }

    case rts.handleUpdateAppUserProfile:
      const { appUserData } = action
      updateAppProfileDirect(appUserSession, pathViews, appUserData, dispatch)
      return { ...state }

    case rts.handleUpdateAppUserEventInfo:
      if (state.appUser && state.appUser.appAccess_cloned && state.appUser.appAccess_cloned) {
        const _appUser = { ...state.appUser }
        _appUser.appAccess_cloned.appUserSession = action.result
        return { ...state, appUser: _appUser }
      }
      return { ...state }

    case rts.handleUpdateAccess:
      const _aus = { ...appUser_state }
      if (_aus.appUserAccess) {
        _aus.appUserAccess.itemAccess = action.itemAccess
      }
      return { ...state, appUser: _aus }

    case rts.handleGetSomething:
      return { ...state }

    case rts.handleAddToHomePageResponse:
      return { ...state, homePageResponse: action.homePageResponse, homePageResponded: true }

    case rts.handleClipboard:
      return { ...state, clipboard: cbItems }

    case rts.handleSendPasswordResetEmail:
      sendPasswordResetEmail(dispatch, email)
      return { ...state }

    case rts.handleTempAccessLevel:
      if (action.tempAccessLevel === 0 || action.tempAccessLevel === null) {
        delete appUser_state.appAccess_cloned
        delete appUser_state.appUser_cloned
      } else {
        appUser_state.appAccess_cloned = getAppAccess(action.tempAccessLevel, action.pathViews)
      }
      return {
        ...state,
        appUser: appUser_state,
      }

    case rts.handleResetAccessLevel:
      if (appUser_state.appAccess_cloned) {
        delete appUser_state.appAccess_cloned
        delete appUser_state.appUser_cloned
        return { ...state, appUser_state }
      } else {
        return { ...state, appUser: appUser_state, }
      }

    case rts.handleSendEMailToEmail:
      const { authGlobalPassword } = action
      signUpEmailDirect(dispatch, email, authGlobalPassword, itemData, paps_state)
      return { ...state }

    case rts.handleClone:
      getClonedAppUser(itemData, paps_state, handleSetClonedUserAccess, appUserCollection)
      return { ...state }

    case rts.handleSetClonedUserAccess:
      const { appUser } = action
      const { appUserAccess } = appUser ? appUser : {}
      appUser_state.appAccess_cloned = appUserAccess
      appUser_state.appUser_cloned = appUser
      return {
        ...state,
        appUser: appUser_state,
      }

    case rts.handleConnectToAppUser:
      return { ...state }

    case rts.handleAppUserPageData:
      return { ...state, [action.dataCollectionName]: action.dataValue }

    case rts.handlePageFavorite:
      updateProfilePageFavorite(dispatch, appUser_state, paps_state, action.pageDataCaption, action.favItem)
      return { ...state }

    case rts.handleUpdateNotificationToken:
      return { ...state, notificationToken: action.currentToken }

    case rts.handleCurrentClientMessageToken:
      return { ...state, currentClientMessageToken: action.currentToken }

    case rts.handleUpdateAppUserFcmTokenToDatabase:
      try {
        updateAppUserFcmTokenToDatabase(action.newToken, appUser_state, pathViews, handleUpdateToken)
      } catch (error) {
        console.error(error)
      }
      return { ...state }

    case rts.handleUpdateToken:
      const au = { ...appUser_state }
      const { profileData } = au ? au : {}
      if (profileData) {
        if (!profileData.fcmTokens) { profileData.fcmTokens = [] }
        if (!profileData.fcmTokens.includes(action.newToken)) {
          profileData.fcmTokens.push(action.newToken)
        }
      }
      return { ...state, appUser: au, currentClientMessageToken: action.newToken }

    case rts.handleGeoLocation:
      return { ...state, geoPosition: action.position }

    case rts.handleDisclaimerResponses:
      const disclaimerData = { disclaimerResponses: action.disclaimerResponses }
      updateAppProfileDirect(appUserSession, pathViews, disclaimerData, handleGetProfile)
      return { ...state }

    case rts.handleLinkAppUserData:

      if (appUserSession) {

        const _appUserKey = appUserSessionKey ? appUserSessionKey : _itemKey
        const _data1 = appUserSession[action.view]
        const _dataRef1 = createRefPath_event(pathViews, [action.view, action.viewKey])
        const _dataRef2 = createRefPath_event(pathViews, [appUserCollection, _appUserKey])

        const { dataToUpdate1, dataToUpdate2 } = getDataItemToUpdate(_data1, appUserCollection, _appUserKey, action.view, action.viewKey)

        console.log('_dataRef1', _dataRef1, dataToUpdate1)
        console.log('_dataRef2', _dataRef2, dataToUpdate2)

        // fs_update_doc(_dataRef1, dataToUpdate1).then(res1 => {
        //   fs_update_doc(_dataRef2, dataToUpdate2).then(res2 => {
        //     if (appUser_state.appAccess_cloned) {
        //       handleGetAppUserEventInfo(true)
        //       action.callback()
        //     }
        //   })
        // })
      }
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const appUserHandlers = (dispatch) => {
  return {
    handleAddEvent: () => { dispatch({ type: rts.handleAddEvent }) },
    handleAppUserPageData: (dataCollectionName, dataValue) => { dispatch({ type: rts.handleAppUserPageData, dispatch, dataCollectionName, dataValue }) },
    handleClipboard: (cbItems) => { dispatch({ type: rts.handleClipboard, cbItems }) },
    handleClone: (item, itemData, paps_state, appUserCollection) => { dispatch({ type: rts.handleClone, dispatch, item, itemData, paps_state, appUserCollection }) },
    handleConfirmAccessCode: (passcode) => dispatch({ type: rts.handleConfirmAccessCode, dispatch, passcode }),
    handleConnectToAppUser: (view, dataItem) => { dispatch({ type: rts.handleConnectToAppUser, dispatch, view, dataItem }) },
    handleCurrentClientMessageToken: (currentToken) => { dispatch({ type: rts.handleCurrentClientMessageToken, currentToken }) },
    handleDisclaimerResponses: (disclaimerResponses) => dispatch({ type: rts.handleDisclaimerResponses, dispatch, disclaimerResponses }),
    handleGeoLocation: (position) => { dispatch({ type: rts.handleGeoLocation, dispatch, position }) },
    handleGetAppUserEventInfo: (forClone) => { dispatch({ type: rts.handleGetAppUserEventInfo, dispatch, forClone }) },
    handleGetProfile: () => { dispatch({ type: rts.handleGetProfile, dispatch }) },
    handleGetUserLocation: () => { dispatch({ type: rts.handleGetUserLocation, dispatch }) },
    handleInitCurrentUser: (currentUser) => { dispatch({ type: rts.handleInitCurrentUser, dispatch, currentUser }) },
    handleLinkAppUserData: (view, viewKey, callback) => { dispatch({ type: rts.handleLinkAppUserData, dispatch, view, viewKey, callback }) },
    handlePageFavorite: (paps_state, pageDataCaption, favItem) => { dispatch({ type: rts.handlePageFavorite, paps_state, pageDataCaption, favItem, dispatch }) },
    handleResetAccessLevel: () => { dispatch({ type: rts.handleResetAccessLevel }) },
    handleSendEmailNonSuccess: (email) => { dispatch({ type: rts.handleSendEmailNonSuccess, email, dispatch }) },
    handleSendEmailSuccess: (email) => { dispatch({ type: rts.handleSendEmailSuccess, email, dispatch }) },
    handleSendEMailToEmail: (paps_state, email, itemData, authGlobalPassword) => { dispatch({ type: rts.handleSendEMailToEmail, paps_state, dispatch, email, itemData, authGlobalPassword }) },
    handleSendPasswordResetEmail: (email) => { dispatch({ type: rts.handleSendPasswordResetEmail, email, dispatch }) },
    handleSetMyLocation: (location, locationGranted) => dispatch({ type: rts.handleSetMyLocation, dispatch, location, locationGranted }),
    handleSetNewLocation: (location) => dispatch({ type: rts.handleSetNewLocation, dispatch, location }),
    handleUpdateAppUserEventInfo: (result) => { dispatch({ type: rts.handleUpdateAppUserEventInfo, dispatch, result }) },

    /** Simply sets the user to the AuthContext's state */
    handleSetClonedUserAccess: (appUser) => { dispatch({ type: rts.handleSetClonedUserAccess, dispatch, appUser }) },
    handleTempAccessLevel: (tempAccessLevel, pathViews) => { dispatch({ type: rts.handleTempAccessLevel, dispatch, tempAccessLevel, pathViews }) },
    handleSetAppUser: (appUser, userAccessRequests) => { dispatch({ type: rts.handleSetAppUser, dispatch, appUser, userAccessRequests }) },
    handleUpdateNotificationToken: (currentToken) => { dispatch({ type: rts.handleUpdateNotificationToken, dispatch, currentToken }) },
    /**
     * Updates the user's token to the database
     * @param {string} newToken 
     * @param {object} appUser 
     * @param {object} current 
     */
    handleUpdateAppUserFcmTokenToDatabase: (newToken, appUser) => { dispatch({ type: rts.handleUpdateAppUserFcmTokenToDatabase, dispatch, newToken, appUser }) },
    handleUpdateAppUserProfile: (appUserData, callback) => { dispatch({ type: rts.handleUpdateAppUserProfile, dispatch, appUserData, callback }) },
    handleUpdateToken: (newToken) => { dispatch({ type: rts.handleUpdateToken, dispatch, newToken }) },
    handleAddToHomePageResponse: (homePageResponse) => { dispatch({ type: rts.handleAddToHomePageResponse, dispatch, homePageResponse }) },
    handleGetSomething: () => { dispatch({ type: rts.handleGetSomething, dispatch, }) },
    handleUpdateAccess: (itemAccess) => { dispatch({ type: rts.handleUpdateAccess, dispatch, itemAccess }) },
    ...responseHandlers(dispatch)
  }
}

// Returns an `appUser` object
/** 
 * @param {object} currentUser - the current app user
 * @param {object} appUserInfo - the profile data of the app user
 * @param {object} claims  - any claims associated with the app user
 * @param {array} imageUrls - any image urls associated the the app user
 * @param {string} pathViews - the pathViews of the current page
 * @returns the an `auuUser` object
 *  {...currentUser,
    profileData,
    appUserAccess,
    appUserAccess_fixed: appUserAccess,
    tokens,
    imageUrls: imageUrls,
    settingsFavs,
    authUserValidated: true}
 */
export const createAppUserData = (currentUser, appUserInfo, claims, imageUrls, pathViews) => {

  const { phoneNumber } = currentUser ? currentUser : {}
  const { settingsFavs, events: events_profile, clients: clients_profile, appUserSessionKey, fcmTokens, id: profileId, email, activeEvents_appUser, activeEvents_client } = appUserInfo ? appUserInfo : { profileLevel: 0 }
  const { authLevel, clientAuthLevel, clients, events } = claims ? claims : {}
  const { events: eventKey, clients: clientKey } = pathViews ? pathViews : {}

  let appUserType = gEnums.appUserTypes.appProfile
  let accessLevel;
  let settingsAuthLevel;

  // Set the Access level here

  if (events_profile && eventKey && events_profile[eventKey]) {
    appUserType = gEnums.appUserTypes.appProfile
    accessLevel = gEnums.accessLevels.appProfile.value
  } else if (clients_profile && clientKey && clients_profile[clientKey] && clients_profile[clientKey].events && clients_profile[clientKey].events[eventKey]) {
    appUserType = gEnums.appUserTypes.appProfile
    accessLevel = gEnums.accessLevels.appProfile.value
  }

  if (clientAuthLevel) {
    settingsAuthLevel = clientAuthLevel
    accessLevel = clientAuthLevel
    appUserType = gEnums.appUserTypes.appAdmin
  }

  if (authLevel) {
    settingsAuthLevel = authLevel
    accessLevel = authLevel
    appUserType = gEnums.appUserTypes.admin
  }

  if (email === 'philipaokeeffe@gmail.com') {
    accessLevel = gEnums.accessLevels.superAdminDeveloper.value
    settingsAuthLevel = gEnums.accessLevels.superAdminDeveloper.value
  }

  const tokens = { accessLevel, clients, events }

  let initials = currentUser.displayName ? createInitials(currentUser.displayName) : null
  if (!initials && appUserInfo.displayName) { initials = createInitials(appUserInfo.displayName) }

  let isAppUser;

  switch (appUserType) {
    case gEnums.appUserTypes.appProfile:
    case gEnums.appUserTypes.appAdmin:
    case gEnums.appUserTypes.appDataAdmin:
    case gEnums.appUserTypes.appSubAdmin:
      isAppUser = true
      break;
    default:
    // nothing
  }

  const appUserAccess = {
    accessLevel: accessLevel,
    appUserColor: getAuthColor(accessLevel),
    appUserSession: appUserInfo.sessionAppUser,
    activeEvents_appUser,
    activeEvents_client,
    appUserSessionKey,
    appUserType,
    displayName: appUserInfo.displayName,
    disclaimerResponses: appUserInfo.disclaimerResponses,
    email,
    fcmTokens,
    initials,
    isAdminOrSuper: settingsAuthLevel >= gEnums.accessLevels.admin.value,
    isSuperAdmin: settingsAuthLevel >= gEnums.accessLevels.superAdmin.value,
    isAppUser,
    loggedIn: true,
    pageFavs: appUserInfo.pageFavs,
    pageFavz: appUserInfo.pageFavz,
    phoneNumber,
    profileId,
    settingsAuthLevel,
    uid: currentUser.uid,
  }

  // ammend the appUserAccess
  if (events_profile) { appUserAccess.events = events_profile }

  if (clients_profile) {
    appUserAccess.clients = clients_profile
    appUserAccess.events = clients_profile[clientKey] ? clients_profile[clientKey].events : null
  }

  if (clientAuthLevel) { appUserAccess.appAuth = { clientAuthLevel, clientAuthKey: clientAuthLevel ? clientKey : null } }

  switch (appUserType) {
    case gEnums.appUserTypes.appAdmin:
    case gEnums.appUserTypes.appDataAdmin:
    case gEnums.appUserTypes.appProfile:
    case gEnums.appUserTypes.appSubAdmin:
      appUserAccess.clientAccessKey = clientKey ? clientKey : null
      break;
    default:
    // nothing
  }

  const appUser = {
    ...currentUser,
    profileData: appUserInfo,
    appUserAccess,
    appUserAccess_fixed: appUserAccess,
    tokens,
    imageUrls: imageUrls,
    settingsFavs,
    authUserValidated: true
  }

  return appUser

}

const getAppAccess = (accessLevel, pathViews) => {
  const x = _.find(gEnums.accessLevels, { 'key': accessLevel })
  const aa = {
    accessLevel: accessLevel,
    appUserColor: x.color,
    appUserType: _.camelCase(x.caption),
    clientAccessKey: pathViews ? pathViews.clients : null,
    events: pathViews ? { [pathViews.events]: true } : null,
    initials: createInitials(_.startCase(x.caption), 2),
    isAdminOrSuper: accessLevel >= gEnums.accessLevels.admin.value,
    isSuperAdmin: accessLevel >= gEnums.accessLevels.superAdmin.value,
    loggedIn: true,
    settingsAuthLevel: accessLevel > gEnums.authLevels.appSubAdmin ? accessLevel : 0,
  }
  return aa
}

const getFavs = (profileData, pathViews) => {
  const { pageFavs } = profileData
  const _sortedFavs = {}
  if (pageFavs) {
    // loop the faves
    Object.keys(pageFavs).forEach(key_fav => {
      const pf = pageFavs[key_fav]
      Object.keys(pf).forEach(keyy => {
        const pff = pf[keyy]
        const { name, pathName } = pff ? pff : {}
        if (pathName) {
          const pathNameS = pathName ? pathName.split('/') : ''
          const pathKeys = convertPathToKeys(pathNameS)
          let allow = pathKeys.events === pathViews.events
          if (allow) {
            if (!_sortedFavs[keyy]) { _sortedFavs[keyy] = [] }
            _sortedFavs[keyy].push(
              {
                key: keyy,
                name,
                pathName,
                ...pathKeys,
              }
            )
          }
        }
      })
    })
  }
  return _sortedFavs
}

const getDataItemToUpdate = (existingData, appUserCollection, appUserKey, view, viewKey) => {

  let dataToUpdate1;
  let dataToUpdate2;

  if (existingData) {
    if (existingData.includes(viewKey)) {
      dataToUpdate1 = { [appUserCollection]: arrayRemove(appUserKey) }
      dataToUpdate2 = { [view]: arrayRemove(viewKey) }
    } else {
      dataToUpdate1 = { [appUserCollection]: arrayUnion(appUserKey) }
      dataToUpdate2 = { [view]: arrayUnion(viewKey) }
    }
  } else {
    dataToUpdate1 = { [appUserCollection]: [appUserKey] }
    dataToUpdate2 = { [view]: [viewKey] }
  }

  return { dataToUpdate1, dataToUpdate2 }

}

const getMyLocation = (geoListType, callback_location, callback_newLocation) => {

  const cb_success = (position) => {
    const { coords } = position
    const _location = {
      latitude: coords.latitude,
      longitude: coords.longitude
    }
    callback_location(_location, true)
  }

  const cb_error = (e) => {
    callback_location(null, false)
    console.error("Error Code = " + e.code + " - " + e.message);
  }

  if (navigator.geolocation) {

    navigator.geolocation.getCurrentPosition(cb_success, cb_error);

    switch (geoListType) {
      case geoListTypes.location:
        navigator.geolocation.watchPosition(function (position) {
          const { coords } = position
          const _location = {
            latitude: coords.latitude,
            longitude: coords.longitude
          }
          callback_newLocation(_location)
        });
        break;
      default:
      // nothing
    }
  }
}

const isDeveloper = (appUser) => {
  return isLocalhost() && appUser && appUser.email === 'philipaokeeffe@gmail.com' ? true : false
}