import React, { useEffect, useReducer } from 'react';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../../global/cnr/reducers/SidebarReducer';
import MenuSidebars from './MenuSidebars';

const sidebarType = 'generic'

const MenuSidebar = (props) => {

  const { isAlpha, updateParent, msbData, msType, handlers, segs } = props

  const getAlphaItems = () => {
    const ais = []
    for (var i = 65; i <= 90; i++) {
      ais.push(String.fromCharCode(parseInt(i)))
    }
    return ais
  }

  // sidebar_state
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ handlers, msType }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ? sidebar_state : {}
  const { setInit } = sidebar_handlers ? sidebar_handlers : {}
  const { ms_generic } = sidebar_items ? sidebar_items : {}

  useEffect(() => {
    const mks = isAlpha ? getAlphaItems() : props.menuKeys
    setInit({ smt: sidebarMenuTypes.one, items: mks, currents, as: 'generic' })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [msbData]);

  useEffect(() => {
    if (updateParent) { updateParent(ms_generic.selected) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_generic && ms_generic.selected]);

  const selectedSegs = () => segs[ms_generic.selected.index]

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={selectedSegs()}
    dimmed={dimmed}
  />

  if (ms_generic && ms_generic.selected && Object.keys(segs).length > 0) {
    return menuSidebars()
  } else {
    return <div></div>
  }
}

export default MenuSidebar