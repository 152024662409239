export const golfEnums = {
  roundTypes: {
    alternateShot: 'alternateShot',
    alternateShot1: 'alternateShot1',
    alternateShot2: 'alternateShot2',
    bestBall: 'bestBall',
    bestBall1: 'bestBall1',
    bestBall2: 'bestBall2',
    scramble: 'scramble',
    singles: 'singles'
  },
}