import { callFunction } from "./fbAll";

const transferGenericTypes = {
  transferGeneric: 'transferGeneric',
}

const transferFunctionTypes = {
  getProdData: 'getProdData',
  transferClientToProd: 'transferClientToProd',
  transferDataBetweenDatabases: 'transferDataBetweenDatabases',
  transferDataToProd: 'transferDataToProd',
  transferEventToProd: 'transferEventToProd',
  transferSettingsToProd: 'transferSettingsToProd',
  transferStorageToProduction: 'transferStorageToProduction',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(transferGenericTypes.transferGeneric, callData)
  return data
}

export const transferDataBetweenDatabases = async (collections, source, destination, merge, showLogs) => {
  const callData = { collections, source, destination, merge, showLogs }
  callData.transferFunctionType = transferFunctionTypes.transferDataBetweenDatabases
  try {
    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_transferClientToProd = async (createInfo, pathViews, productionSettings, optz, logging) => {
  const callData = { createInfo, pathViews, showLogs: logging && logging.allowLogging && logging.logTransferEventToProd }
  const { noClientSettings } = optz ? optz : {}
  try {
    const { createInfo } = callData ? callData : {}
    const { projectData } = createInfo ? createInfo : {}
    const { eventData } = projectData ? projectData : {}
    let { id: eventKey } = eventData
    callData.settingsDocKey = eventKey
    callData.pathViews = pathViews
    callData.transferFunctionType = transferFunctionTypes.transferClientToProd
    callData.productionSettings = productionSettings
    callData.noClientSettings = noClientSettings
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * Transfers data to production
 * @param {object} createInfo 
 * @param {string} pathViews 
 * @param {object} logging 
 * @returns 
 */
export const fsfn_transferEventToProd = async (createInfo, pathViews, productionSettings, optz, logging) => {
  const callData = { createInfo, pathViews, showLogs: logging && logging.allowLogging && logging.logTransferEventToProd }
  const { noClientSettings } = optz ? optz : {}
  try {
    const { createInfo } = callData ? callData : {}
    const { projectData } = createInfo ? createInfo : {}
    const { eventData } = projectData ? projectData : {}
    let { id: eventKey } = eventData
    callData.settingsDocKey = eventKey
    callData.pathViews = pathViews
    callData.transferFunctionType = transferFunctionTypes.transferEventToProd
    callData.productionSettings = productionSettings
    callData.noClientSettings = noClientSettings
    callData.merge = false
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * Transfers settings data to production
 * @param {string} settingsDocName 
 * @param {boolean} logging 
 * @returns 
 */
export const fsfn_transferSettingsToProd = async (settingsDocName, logging) => {
  // const callData = { transferFunctionType: transferFunctionTypes.transferSettingsToProd, settingsDocKey: settingsDocName, showLogs: logging && logging.allowLogging && logging.logTransferSettingsToProd }
  const callData = { transferFunctionType: transferFunctionTypes.transferSettingsToProd, settingsDocKey: settingsDocName, showLogs: true }
  try {
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets the data in Dev add the data to Production using Set
 * @param {string} pathViews 
 * @param {object} collections 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
export const fsfn_transferDataToProd = async (pathViews, collections, itemKey, productionSettings, dataToTransfer, merge, logging) => {
  const callData = {
    createInfo: {
      collections,
      itemKey,
      dataToTransfer
    },
    pathViews,
    productionSettings,
    showLogs: true, //logging && logging.allowLogging && logging.logTransferSettingsToProd,
    transferFunctionType: transferFunctionTypes.transferDataToProd,
    merge
  }
  try {
    const response_data = await callFsFunction(callData) // OK 
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_getProductionData = async (pathViews, collectionName, productionSettings, logging, callback) => {
  const callData = {
    createInfo: {
      collectionName
    },
    pathViews,
    productionSettings,
    showLogs: logging && logging.allowLogging && logging.logTransferSettingsToProd,
    transferFunctionType: transferFunctionTypes.getProdData
  }
  try {
    const response_data = await callFsFunction(callData) // OK
    const { result } = response_data
    callback && callback(result)
    return response_data
  } catch (error) {
    console.error(error)
  }
}