import React from 'react';
import AppTicketingWithProvider from '../../../projectSpecific/ticketing/components/AppTicketing';
import AppTicketingScannerWithProvider from '../../../projectSpecific/ticketing/components/AppTicketingScanner';
import AppNotifications, { appNotificationTypes } from '../../appNotifications/AppNotifications';
import CreateTopicWizard from '../../appNotifications/CreateTopicWizard';
import AppUserManagement from '../../appUsers/AppUserManagement';
import VotingManagement from '../../appVoting/VotingManagement';
import VotingWizard from '../../appVoting/VotingWizard';
import UserFavorites from '../../components/user/UserFavorites';
import { allPageTypes } from '../../enums/allPageTypes';
import { gEnums } from '../../enums/globalEnums';
import UiCredentialing from '../../pageItem/modification/dataModifications/UiCredentialing';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import AppAccessRequests from '../access/AppAccessRequests';
import NoData from '../alerts/NoData';
import ErrorBoundary from '../errorHandling/ErrorBoundery';
import ImageGallery from '../imaging/ImageGallery';
import ImageMap from '../swipe/ImageMap';
import UserConversations from '../user/UserConversations';
import UserNotes from '../user/UserNotes';

export const FullPageContent = (props) => {

  const { fullPageType, handleCancel } = props

  const wizardContent = () => {

    switch (fullPageType) {

      case allPageTypes.appUsersManager:
        return <AppUserManagement fromFullPage={true} handleCancel={handleCancel} />

      case allPageTypes.appUserRequests:
        return <AppAccessRequests handleCancel={handleCancel} />

      case allPageTypes.chat:
      case allPageTypes.conversations:
        return <UserConversations handleCancel={handleCancel} />

      case allPageTypes.credentialScan:
        return <UiCredentialing handleCancel={handleCancel} />

      case allPageTypes.favorites:
        return <UserFavorites handleCancel={handleCancel} fullPage={true} />

      case allPageTypes.imageMapping:
        return <ImageMap handleCancel={handleCancel} imageMapType={gEnums.imageMapTypes.mapping} />

      case allPageTypes.notes:
        return <UserNotes handleCancel={handleCancel} fullPage={true} />

      case allPageTypes.notifications:
        return <AppNotifications handleCancel={handleCancel} appNotificationType={appNotificationTypes.notifications} />

      case allPageTypes.notificationsManager:
        return <AppNotifications handleCancel={handleCancel} appNotificationType={appNotificationTypes.notificationsManager} />

      case allPageTypes.ticketingManager:
        return <AppTicketingWithProvider handleCancel={handleCancel} />

      case allPageTypes.ticketingScan:
        return <AppTicketingScannerWithProvider handleCancel={handleCancel} />

      case allPageTypes.topicsManager:
        return <CreateTopicWizard handleCancel={handleCancel} topicOriginType={gEnums.topicOriginTypes.manager} />

      case allPageTypes.voting:
        return <VotingWizard handleCancel={handleCancel} />

      case allPageTypes.votingManager:
        return <VotingManagement handleCancel={handleCancel} />

      case allPageTypes.gallery:
        return <ImageGallery handleCancel={handleCancel} storageLocationType={gEnums.storageLocationTypes.eventGallery} storageType={gEnums.storageTypes.image} />

      default:
        return <FullPageWrapper
          content={<NoData fullCaption="The 'FullPageContent' does not have a return value" />}
          topperCaption={'Missing Prop'}
          handleCancel={handleCancel}
        ></FullPageWrapper>
    }
  }

  return <ErrorBoundary origin={'fullPageContent'} handleCancel={handleCancel}>
    {wizardContent()}
  </ErrorBoundary>

}
