import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { AppSettingsContext } from '../../../cnr/contexts/AppSettingsContext';
import { FormContext } from '../../../cnr/contexts/FormContext';
import { uniqueKey } from '../../../common/keys';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';

const SelectInput = (props) => {

  const { itemProp, listType, altFip } = props
  let { fip } = props
  let { optionList } = props
  const { data } = itemProp ? itemProp : {}
  const { formItemType, showCopy } = data ? data : {}

  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state, appSettings_handlers } = appSettingsContext ? appSettingsContext : {}
  const { copiedData } = appSettings_state ? appSettings_state : {}

  // formContext
  const formContext = useContext(FormContext)
  const { form_state, form_handlers } = formContext ? formContext : {}
  const { themeColors, themeItems, data_localForm } = form_state ? form_state : {}

  const [allowAdditions, setAllowAdditions] = useState()
  const [currentOptions, setCurrentOptions] = useState(optionList && _.isArray(optionList) ? optionList : [])

  useEffect(() => {
    switch (formItemType) {
      case gEnums.formItemTypes.nameSelect:
        setAllowAdditions(true)
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentOptions]);

  const handleAddition = (e, { value }) => {
    const _currentOptions = [...currentOptions, { text: value, value, key: value }]
    const co = _.sortBy(_currentOptions, 'name')
    setCurrentOptions(co)
  }

  if (altFip) { fip = { ...fip, ...altFip } }

  let style = getSelectStyle(fip, listType, currentOptions, themeColors, themeItems)

  if (!fip.value || fip.value === '' || _.isArray(fip.value) || fip.value === undefined || fip.value === 'undefined') {
    delete fip.value
  }

  const copy = () => appSettings_handlers.handleCopySettings(data_localForm)
  const paste = () => form_handlers.handleFormPaste(copiedData)

  const select = () => <Form.Select
    {...fip}
    fluid
    options={currentOptions}
    style={style}
    allowAdditions={allowAdditions}
    search
    onAddItem={allowAdditions ? handleAddition : null}
  />

  const content = () => {
    const items = []
    items.push(<Icon key={uniqueKey('fii')} name={'copy outline'} color={copiedData ? 'blue' : 'grey'} style={{ float: 'right' }} onClick={(e) => copy()} />)
    copiedData && items.push(<Icon key={uniqueKey('fii')} name={appIconTypes.paste} color={'blue'} style={{ float: 'right' }} onClick={(e) => paste()} />)
    items.push(select())
    return items
  }

  return showCopy ? content() : select()

}

export default SelectInput

const getSelectStyle = (fip, listType, optionList, themeColors, themeItems) => {

  let style = {}

  switch (listType) {
    case 'themeItems':
      const tis = themeItems ? themeItems : null
      if (tis) {
        optionList.forEach(opt => {
          if (tis[opt.key]) {
            assignColors(tis, opt)
            if (fip.value === opt.key) {
              style = assignColors(tis, opt, true)
            }
          }
        })
      }
      break;

    case 'themeColors':
      const tcs = themeColors ? themeColors : null
      if (tcs) {
        optionList.forEach(opt => {
          if (tcs[opt.key]) {
            opt.style = { backgroundColor: tcs[opt.key] }
            if (fip.value === opt.key) {
              style = { backgroundColor: tcs[opt.key] }
            }
          }
        })
      }
      break;
    default:
      break;
  }

  return style

}

const assignColors = (tis, opt, returnOnly) => {

  const item = tis[opt.key]

  let bc = item.backgroundColor
  let c = item.color

  if (returnOnly) {
    return { backgroundColor: bc, color: c }
  } else {
    opt.style = { backgroundColor: bc, color: c }
  }
}