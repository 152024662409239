import _ from 'lodash'
import React, { useContext } from 'react'
import { formatItem, formatTypes } from '../../../global/common/dateFormatting'
import { SportsMatchContext } from '../cnr/contexts/SportsMatchContext'

const MatchHeader = () => {

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ? sportsMatchContext : {}
  const { match_current, match_true } = sportsMatch_state ? sportsMatch_state : {}

  const {
    _itemKey,
    away,
    home,
    isPlayoff,
    key: key_match,
    levels,
    matchNumber,
    poolKey,
    results,
    sections,
    startDate,
    startTime,
  } = match_true ? match_true : {}

  const {
    results_current,
  } = match_current ? match_current : {}

  const { complete: complete_actual, scoreDisplayFull: scoreDisplayFull_actual } = results ? results : {}

  const _results = results_current ? results_current : results
  const { complete, scoreDisplayFull } = _results ? _results : {}

  const _matchKey = key_match ? key_match : _itemKey

  const header = () => <div className={'match-menu-header'}>
    <div>{complete_actual ? scoreDisplayFull_actual : away.name + ' vs. ' + home.name}</div>
    {isPlayoff && <div>{_.startCase(poolKey) + ' - Match #' + matchNumber}</div>}
    <div>{formatItem(formatTypes.shortDate, startDate)}{' - '}{startTime}</div>
    <div>{levels}{sections && ' - ' + sections}</div>
    <div>{_matchKey}</div>
    {complete_actual && <div className='rf'>{'Match results have been updated'}</div>}
  </div>

  return header()

}

export default MatchHeader