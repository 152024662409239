import React, { useContext, useState } from 'react';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { appIconTypes } from '../enums/appIconTypes';
import { gEnums } from '../enums/globalEnums';
import GenericIcon from '../genericControls/GenericIcon';
import { _animateTypes } from '../motions/AnimateComponent';
import { H2Topper, H3Topper } from './TopperWrappers';
import Wrapper from './Wrapper';

/**
 * 
 * @param {object} props (
 *  content,
    halfModal,
    handleCancel,
    isBack,
    isSub,
    noModal,
    topperCaption,
    topperCaption2,
    topperHeader)
 * @returns 
 */
const FullPageWrapper = (props) => {

  const {
    content,
    fullMode,
    fullWebPage,
    halfModal,
    handleCancel,
    handleRefresh,
    isBack,
    isSub,
    noModal,
    topperCaption,
    topperCaption2,
    topperHeader,
    updating,
    wrapperCss,
  } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state, transition_state } = states ? states : {}
  const { pageSettings, pageNav } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const { navUiItemContext } = pageNav ? pageNav : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { isMobileDevice } = framework_state ? framework_state : {}

  const { transitions } = transition_state ? transition_state : {}
  const transition_fpw = transitions ? transitions[_animateTypes.fullPageWrapper] : null

  const topSac = aps_styles ? aps_styles[gEnums.projectStyles.topMenu] : {}

  const { projectOptions } = aps_global ? aps_global : {}
  const { useCloseModalSwipe } = projectOptions ? projectOptions : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const _uiItemContext = navUiItemContext ? navUiItemContext : uiItemContext
  const { item_handlers, item_state } = _uiItemContext ? _uiItemContext : {}
  const { editableItem } = item_state ? item_state : {}
  const { handleCloseOpenedItem: handleItemClose, handleItemShowEdit } = item_handlers ? item_handlers : {}

  const [wideMode, setWideMode] = useState(fullMode)

  const handleWide = () => setWideMode(!wideMode)

  const wideModeAvailable = fullMode && !isMobileDevice

  let _handleCancel = handleCancel
  if (!_handleCancel && handleItemClose) { _handleCancel = handleItemClose }

  if (editableItem) { _handleCancel = handleItemShowEdit }

  const buttonProps_cancel = { color: isBack ? 'blue' : 'black', inverted: true }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const infoButton = () => gi('btn-cancel', appIconTypes.check, null, null, { ...buttonProps_cancel, nb: false, nf: true })
  const cancelButton = () => gi('btn-cancel', isBack ? appIconTypes.arrowLeft : appIconTypes.delete, _handleCancel, null, { ...buttonProps_cancel, nb: false, nf: true })
  const refreshButton = () => gi('btn-refresh', appIconTypes.refresh, handleRefresh, null, { ...buttonProps_cancel, nb: true, nf: true })
  const wideModeButton = () => gi('btn-cancel', wideMode ? 'square outline' : appIconTypes.wideMode, handleWide, null, { ...buttonProps_cancel, nb: false, nf: true })

  const toppers = () => <React.Fragment>
    <div>{topperCaption}</div>
    <div style={{ paddingTop: '.5em', wordBreak: 'break-word' }} >{topperCaption2}</div>
  </React.Fragment>

  const topper1 = () => <H2Topper
    leftContent={infoButton()}
    rightContent={topperCaption2 ? toppers() : topperCaption}
    style={topSac.style}
  ></H2Topper>

  const topper2 = () => <H2Topper
    leftContent={_handleCancel && cancelButton()}
    rightContent={topperCaption2 ? toppers() : topperCaption}
    style={topSac.style}
  ></H2Topper>

  const topper3 = () => <H3Topper
    leftContent={_handleCancel && cancelButton()}
    middleContent={topperCaption2 ? toppers() : topperCaption}
    rightContent={handleRefresh && refreshButton()}
    style={topSac.style}
  />

  const topper3Wide = () => <H3Topper
    leftContent={_handleCancel && cancelButton()}
    middleContent={topperCaption2 ? toppers() : topperCaption}
    rightContent={wideModeButton()}
    style={topSac.style}
  />

  const topper = () => {
    if (fullWebPage) {
      return topper1()
    } else if (handleRefresh) {
      return topper3()
    } else if (wideModeAvailable) {
      return topper3Wide()
    } else {
      return topper2()
    }
  }

  let wcn = 'fpw'
  if (isSub) { wcn += ' sub' }

  const wrapper = () => <Wrapper
    topper={topper()}
    handleSwipedRight={useCloseModalSwipe ? _handleCancel : null}
    header={topperHeader}
    content={content}
    css={{ container: wcn }}
    modal={noModal ? false : true}
    halfModal={halfModal}
    fullMode={wideMode}
    fullWebPage={fullWebPage}
    transition_fpw={transition_fpw}
    wrapperCss={wrapperCss}
    updating={updating}
  />

  return wrapper()

}

export default FullPageWrapper