import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { sportsRtMatchHandlers, sportsRtMatchInitialState, sportsRtMatchReducer } from "../reducers/SportsRtMatchReducer";
import { SeasonMatchesContext } from "./SeasonMatchesContext";
import { SportsSeasonContext } from "./SportsSeasonContext";
import { SportsMatchContext } from "./SportsMatchContext";

export const SportsRtMatchContext = React.createContext()

export default function SportsRtMatchProvider(props) {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states ? states : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const { pathViews, pathName, viewKey } = paps_state ? paps_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info, viewItem, latestSeason, sportsKey } = sportsSeason_state ? sportsSeason_state : {}
  const { playoffMatches, matches_realtime } = matches_info ? matches_info : {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)

  const componentContexts = { sportsSeasonContext, seasonMatchesContext }

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ? sportsMatchContext : {}
  const { match_true } = sportsMatch_state ? sportsMatch_state : {}

  const init_state = { sportsSeason_handlers, appUserAccess, pathViews, pathName, viewItem, viewKey, playoffMatches, latestSeason, sportsKey }
  const [sportsRtMatch_state, sportsMatchRealtime_dispatch] = useReducer(sportsRtMatchReducer, init_state, sportsRtMatchInitialState)
  const sportsRtMatch_handlers = sportsRtMatchHandlers(sportsMatchRealtime_dispatch, sportsRtMatch_state)
  const { match_rt } = sportsRtMatch_state ? sportsRtMatch_state : {}

  useEffect(() => {
    if (match_true) {
      sportsRtMatch_handlers.handleSet_trueMatch(match_true)
      const realtimeMatches = matches_realtime ? matches_realtime[match_true._itemKey] : {}
      const _allResults = []
      if (realtimeMatches) {
        Object.keys(realtimeMatches).forEach(rmKey => {
          const realtimeMatch = realtimeMatches[rmKey]
          const { results } = realtimeMatch ? realtimeMatch : {}
          _allResults.push(results)
        })
        if (_allResults && _allResults.length > 0) {
          sportsRtMatch_handlers.handleSet_rtMatch({ ..._allResults[0] })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, match_true]);

  useEffect(() => {
    sportsRtMatch_handlers.handleLatestSeason(latestSeason)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [latestSeason]);

  return <SportsRtMatchContext.Provider value={{ sportsRtMatch_state, sportsRtMatch_handlers, componentContexts }}>
    {props.children}
  </SportsRtMatchContext.Provider>
}