import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import { openGoogleSheets } from '../../../../global/viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext, _sportsFormProps } from '../../cnr/contexts/SportsSeasonContext';
import { matchUpdateTypes } from '../../cnr/reducers/SportsSeasonReducer';
import MatchList from './MatchList';

const MatchListUpdate = (props) => {

  const { sportsDataMode, forSportsManagement, handleCancel } = props

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ? sportsSeasonContext : {}
  const { googleLinks_info, updating, matchesData } = sportsSeason_state ? sportsSeason_state : {}
  const { gls_scores } = googleLinks_info ? googleLinks_info : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { matchPendingUpdate } = sportsMatch_state ? sportsMatch_state : {}
  const { matchUpdateType } = matchPendingUpdate ? matchPendingUpdate : {}

  const [gsValues, setGsValues] = useState({})

  const numSets = 5;
  const commonScores = [];

  const getCommonScoreI = (setScores) => {
    const scoreCounts = setScores.reduce((acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = 1;
      } else {
        acc[curr]++;
      }
      return acc;
    }, {});
    const sortedScoreCounts = Object.entries(scoreCounts).sort((a, b) => b[1] - a[1]);
    const mostCommonScore = sortedScoreCounts[0][0];
    return mostCommonScore
  }

  const getCommonScore = (userScores, setNumber) => {

    const homeScores = []
    const awayScores = []

    Object.keys(userScores).forEach(uk => {
      const userScore = userScores[uk]
      const setScore = _.filter(userScore, { setNumber: setNumber })
      try {
        if (setScore && setScore[0]) {
          awayScores.push(setScore[0].away)
          homeScores.push(setScore[0].home)
        }
      } catch (error) {

      }

    })

    const a = awayScores.length > 0 ? getCommonScoreI(awayScores) : 0
    const b = homeScores.length > 0 ? getCommonScoreI(homeScores) : 0

    return { away: a, home: b }

    // const scoreCounts = setScores.reduce((acc, curr) => {
    //   if (!acc[curr]) {
    //     acc[curr] = 1;
    //   } else {
    //     acc[curr]++;
    //   }
    //   return acc;
    // }, {});
    // const sortedScoreCounts = Object.entries(scoreCounts).sort((a, b) => b[1] - a[1]);
    // const mostCommonScore = sortedScoreCounts[0][0];
    // return mostCommonScore
  }

  const userScores = {
    jim: [
      { home: 23, away: 25, setNumber: 1 },
      { home: 22, away: 25, setNumber: 2 },
      { home: 20, away: 25, setNumber: 3 }
    ],
    jack: [
      { home: 22, away: 25, setNumber: 1 },
      { home: 22, away: 25, setNumber: 2 },
      { home: 21, away: 25, setNumber: 3 }
    ],
    john: [
      { home: 22, away: 25, setNumber: 1 },
      { home: 22, away: 25, setNumber: 2 },
      { home: 21, away: 25, setNumber: 3 }
    ],
  }

  for (let setNumber = 1; setNumber <= numSets; setNumber++) {
    commonScores[setNumber] = getCommonScore(userScores, setNumber)
  }

  useEffect(() => {
    const form_props = _sportsFormProps[sportsDataMode]
    const _gsValues = {}
    if (form_props) {
      Object.keys(form_props).forEach(fp => {
        _gsValues[fp] = gls_scores ? gls_scores[fp] : null
      })
    }
    setGsValues(_gsValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    sportsSeason_handlers.handleMatchesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleGoogleSheetsIdChange = (e, data) => {
    const _gsValues = { ...gsValues }
    _gsValues[_sportsFormProps.googleLinks.googleSheetsId] = data.value
    setGsValues(_gsValues)
  }
  const handleSheetNameChange = (e, data) => {
    const _gsValues = { ...gsValues }
    _gsValues[_sportsFormProps.googleLinks.scoresSheet] = data.value
    setGsValues(_gsValues)
  }

  const handleOpenGoogleSheets = () => openGoogleSheets(gsValues.googleSheetsId)
  const handleUpdateMatchResults = () => sportsMatch_handlers.handleConfirmMatchPending()

  const handleGoogleKeySearch = () => {
    gsValues.sheetName = gsValues.scoresSheet
    sportsSeason_handlers.handleStartUpdate()
    sportsSeason_handlers.handleGetGsMatches(gsValues)
  }

  const input_key = () => <Form.Input
    value={gsValues.googleSheetsId} label='Google Sheets Id' placeholder={'googleSheetsId'} onChange={handleGoogleSheetsIdChange}
  />

  const input_sheetName = () => <Form.Input
    icon={<Icon name='search' inverted circular link onClick={handleGoogleKeySearch} />}
    value={gsValues.scoresSheet} label='Scores Sheet' placeholder={'scoresSheet'} onChange={handleSheetNameChange}
  />

  const googleSheetsForm = () => <Form>
    {input_key()}
    {input_sheetName()}
  </Form>

  // const handleItemData = (itemData) => sportsMatch_handlers.handleCreateMatchesFromList({ itemData, teams, matches_season })

  // const form_results = () => <CreateMatchesPaste handleItemData={handleItemData} />

  const footer = () => <UiSaveButtons
    save={{ oc: handleUpdateMatchResults, caption: 'Update Match Results' }}
    preview={{ oc: handleOpenGoogleSheets, caption: 'Open GS' }}
  />

  const content = () => {
    switch (matchUpdateType) {
      // case matchUpdateTypes.updateMatchListResults:
      //   return form_results()

      default:
        return <React.Fragment>
          {googleSheetsForm()}
          {matchesData && <MatchList matches={matchesData} />}
        </React.Fragment>
    }
  }

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    updating={updating}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={_.startCase(matchUpdateType)}
    handleCancel={handleCancel}
  />

  return forSportsManagement ? wrapper() : fullPageWrapper()
}

export default MatchListUpdate