import _ from 'lodash';
import React from 'react';
import { Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { sortObject } from '../../../common/sorting';
import { useAllSettingsFunctions } from '../../../cnr/contexts/BaseSettingsContext';
import { FormItemProp } from './FormItemProp';

/**
 * Adds the `FormItemProp` `elements` to `propElements` array
 * @param {object} props 
 */
export const addFormItemPropElements = (props) => {

  const {
    aips,
    data_localForm,
    isTabbed,
    key,
    propElements,
    settingsSort,
    viewProps,
  } = props

  if (viewProps) {
    if (!_.isArray(viewProps)) {
      if (settingsSort) {
        const sortedPropsS = useAllSettingsFunctions ? sortObject(viewProps, 'position') : sortObject(viewProps, '_position')
        Object.keys(sortedPropsS).forEach((key, index) => {
          const sp = sortedPropsS[key]
          propElements.push(<FormItemProp aips={aips} itemProp={sp} index={index} isTabbed={isTabbed} />)
        })
      } else {
        const sortedPropsP = _.orderBy(viewProps, ['position'])
        sortedPropsP.forEach((itemProp, index) => {
          propElements.push(<FormItemProp aips={aips} itemProp={itemProp} index={index} isTabbed={isTabbed} />)
        })
      }
    } else {
      viewProps.forEach((itemProp, index) => {
        propElements.push(<FormItemProp aips={aips} itemProp={itemProp} index={index} isTabbed={isTabbed} />)
      })
      const newJson = {}
      viewProps.forEach(vpKey => {
        newJson[vpKey] = data_localForm[vpKey]
      })
    }
  } else {
    propElements.push(<Segment basic key={uniqueKey('afip', key)}>{'No Item Props'}</Segment>)
  }
}