import React from 'react'
import { CalMonth } from './CalMonth'
import { uniqueKey } from '../../common/keys';

export const CalSchedule = (props) => {

  const { monthStartDates, calendar_state, calendar_handlers } = props

  const getMonthName = (md) => {
    try {
      const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(md)
      const year = md.getFullYear()
      return { monthName, year }
    } catch (error) {
      return { monthName: 'January', year: 2020 }
    }
  }

  const calMonths = () => {
    const cm = []
    if (monthStartDates) {
      monthStartDates.forEach((md, index) => {
        const { monthName, year } = getMonthName(md)
        cm.push(<div key={uniqueKey('cm', 'cm', index)} className={'cal-month-container'}>
          <div className={'cal-month'}>{monthName}{' - '}{year}</div>
          <CalMonth
            currentDate={new Date(md)}
            includeMonthHeader={true}
            // dateKeys={monthStartDates}
            calendar_state={calendar_state}
            calendar_handlers={calendar_handlers}
          />
        </div>)
      })
    }
    return cm
  }

  const content = () => <div className={'cal-months-container sch'}>
    <div className={'cal-months'}>
      {calMonths()}
    </div>
  </div>

  return content()
}