import React, { createContext, useReducer } from 'react';
import { imageHandlers, imageInitialState, imageReducer } from '../reducers/ImageReducer';

/** Displays a `PageDimmer` until the `handleUpdateStart` function in called */
export const ImageContext = createContext();

const ImageProvider = (props) => {

  const init_state = {}
  const [image_state, image_dispatch] = useReducer(imageReducer, init_state, imageInitialState)
  const image_handlers = imageHandlers(image_dispatch, image_state)

  return <ImageContext.Provider value={{ image_state, image_handlers }}>
    {props.children}
  </ImageContext.Provider>
}

export default ImageProvider