import _ from 'lodash'
import { dynListTypes, gEnums, listTypes } from "../enums/globalEnums"
import { newPropEditItem } from "../viewSettings/helpers/settingsHelpers"
import { propDataOpts, spp } from '../viewSettings/settingsProps/settingsAll'

/**
 * Add some props to the `viewProps` based on settings
 * @param {object} swipedItem 
 * @param {object} viewProps 
 * @param {object} viewItem 
 * @param {object} dataOptions 
 */
export const addAdditionalProps = async (swipedItem, viewProps, viewItem, dataOptions, allowImage, isDataItem, ignoreItemStatus_p) => {

  const { propsAdditional } = viewItem ? viewItem : {}
  const { ignoreItemStatus, showPdf } = propsAdditional ? propsAdditional : {}
  const { useItemStatus } = dataOptions ? dataOptions : {}

  const allowItemStatus = useItemStatus && !ignoreItemStatus && isDataItem && !ignoreItemStatus_p && viewItem

  if (swipedItem && swipedItem.dKey) {
    switch (swipedItem.dKey) {
      case 'events':
        viewProps['eventStatusType'] = newPropEditItem('eventStatusType', gEnums.formItemTypes.select, listTypes.eventStatusTypes, 'status', 'status')
        if (viewItem) {
          viewItem.propSections['status'] = {
            key: 'status',
            postion: viewItem.propSections.length,
            show: true,
            showDesktop: true,
          }
        }
        break;
      default:
      // nothing
    }
  } else {
    if (showPdf) { addPropItem(viewItem, viewProps, 'itemPdf', propDataOpts(false, gEnums.formItemTypes.select, dynListTypes.itemPdf)) }
    if (allowItemStatus) { addPropItem(viewItem, viewProps, 'itemStatusType', propDataOpts(false, gEnums.formItemTypes.select, listTypes.statusTypes)) }
  }

  if (allowImage) { viewProps['fileImage'] = addImageProp('fileImage', Object.keys(viewProps).length) }

}

const addPropItem = (viewItem, viewProps, propKey, propDataOpt) => {
  viewProps[propKey] = spp(propKey, propDataOpt)
  viewProps[propKey].propSection = propKey
  if (viewItem) {
    viewItem.propSections[propKey] = {
      key: propKey,
      position: Object.keys(viewItem.propSections).length,
      show: true,
      showDesktop: true,
    }
  }
}

const addImageProp = (key, existingCount) => {
  return {
    position: existingCount ? existingCount : 2,
    show: true,
    display: {
      caption: _.startCase(key),
    },
    data: {
      formItemType: gEnums.formItemTypes.fileItemSelect
    },
    propSection: 'details'
  }
}