import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { CreateTopics } from '../appNotifications/CreateTopics';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { appUserManagementHandlers, appUserManagementInitialState, appUserManagementReducer, showAppManagementTypes, userMenuItems } from '../cnr/reducers/AppUserManagementReducer';
import NoData from '../components/alerts/NoData';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import { Popuper } from "../components/popups/Popuper";
import { UserAuths } from '../components/user/UserAuths';
import { gEnums } from '../enums/globalEnums';
import { fsfn_sendScheduledNotifications_gcs_direct } from '../functions/fbNotifications';
import GenericPopupMenuItem from '../genericControls/GenericMenuItem';
import UiSearchFilter from '../pageItem/UiSearchFilter';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import AppUserList from './AppUserList';
import AppUserManager from './AppUserManager';
import AppUserView from "./AppUserView";

/**
 * F
 * @param {*} props - (handleCancel)
 * @returns a component listing all the appUsers
 */
const AppUserManagement = (props) => {

  const { handleCancel, fromNotifications, fromDataViewer } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ? parentContext : {}
  const { page_state, eventInfo_state, paps_state } = states
  const { pathViews } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_appUserSettings } = pageSettings ? pageSettings : {}

  const { homeSettings } = settings ? settings : {}
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { globalAppSettings } = global_home ? global_home : {}
  const { databaseProjectId } = globalAppSettings ? globalAppSettings : {}

  // pageContext  
  const { appUserCollection, appUserCollections, appUserViewTypeProp } = aps_appUserSettings ? aps_appUserSettings : {}
  const { appProfileData } = eventInfo_state ? eventInfo_state : {}

  const _appUserCollections = appUserCollections ? appUserCollections : []
  if (_appUserCollections.length === 0 && appUserCollection) { _appUserCollections.push(appUserCollection) }

  // uiItemContext
  const uiItemContext = useContext(UiItemContext)
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { vld } = item_state ? item_state : {}

  const initState = appUserManagementInitialState({ vld, appProfileData, appUserCollection, appUserViewTypeProp, pathViews })
  const [appUserManagement_state, dispatch] = useReducer(appUserManagementReducer, initState);
  const appUserManagement_handlers = appUserManagementHandlers(dispatch)
  const { handleAppManagementType } = appUserManagement_handlers ? appUserManagement_handlers : {}

  const isEvent = pathViews.events
  const isClient = pathViews.clients && !isEvent

  const {
    appManagementType,
    confirmation,
    filteredItems,
    selectedAppUser,
    selectedAppUserKey,
    selectedItems,
    showFooter,
    profileType,
    updating,
  } = appUserManagement_state ? appUserManagement_state : {}

  const _selectedItems = filteredItems ? filteredItems : selectedItems

  const [showSearch, handleShowSearch] = useState()
  const [closePopup] = useState()

  useEffect(() => {
    switch (appManagementType) {
      case showAppManagementTypes.getClientProfiles:
        appUserManagement_handlers.handleStartUpdate()
        appUserManagement_handlers.handleGetAllClientProfiles()
        handleShowSearch(false)
        break;
      default:
        handleShowSearch(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appManagementType]);

  useEffect(() => {
    if (appProfileData) {
      let _profileType;
      if (isEvent) {
        _profileType = appUserCollection
      } else if (isClient) {
        _profileType = gEnums.profileTypes.clientProfiles
      } else {
        _profileType = gEnums.profileTypes.adminProfiles
      }
      appUserManagement_handlers.handleSetProfileType(_profileType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, appProfileData]);

  const setAppManagementType = () => handleAppManagementType(userMenuItems.updateClientProfiles)

  const handleUpdate_clientProfiles = () => {
    appUserManagement_handlers.handleStartUpdate()
    appUserManagement_handlers.handleUpdateClientProfiles()
  }

  const handleUpdate_clientProfilesMerge = () => {
    appUserManagement_handlers.handleStartUpdate()
    appUserManagement_handlers.handleUpdateClientProfiles(true)
  }

  const handleUpdate_clientProfilesRemove = () => {
    appUserManagement_handlers.handleStartUpdate()
    appUserManagement_handlers.handleUpdateClientProfiles(false, true)
  }

  const handleSendGsc = () => {
    // appUserManagement_handlers.handleStartUpdate()
    fsfn_sendScheduledNotifications_gcs_direct()
  }

  const searchHeader = () => <div className={'search-page'}><UiSearchFilter handleSearchChange={appUserManagement_handlers.handleSearchChange} /></div>
  const appUserList = (items) => <AppUserList items={items} showIcons={true} showCount={true} handleClick={appUserManagement_handlers.handleSelectAppUser} />

  const selectedContent = () => _selectedItems ? appUserList(_selectedItems) : <NoData altCaption={_.startCase(profileType)} />
  //   if (isEvent) {
  //     return _selectedItems ? appUserList(_selectedItems) : <NoData altCaption={'App Users'} />
  //   } else if (isClient) {
  //     const _profiles = _clientProfiles ? _clientProfiles : clientProfiles
  //     return _profiles ? appUserList(_profiles) : <NoData altCaption={'Client Profiles'} />
  //   } else {
  //     return adminProfiles ? appUserList(adminProfiles) : <NoData altCaption={'Admin Profiles'} />
  //   }
  // }

  const wrapperFull = () => <Wrapper
    header={showSearch && searchHeader()}
    content={selectedContent()}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
    confirmation={confirmation}
  />

  const gpmi = (item, oc) => <GenericPopupMenuItem item={item} oc={oc} />

  const popupMenuItems = () => {
    const mis = []
    if (isEvent) {
      mis.push(gpmi(userMenuItems.checkAuthorizations, handleAppManagementType))
      mis.push(gpmi(userMenuItems.createTopics, handleAppManagementType))
      mis.push(gpmi(userMenuItems.getClientProfiles, handleAppManagementType))
      mis.push(gpmi(userMenuItems.sendSignInEmails, handleAppManagementType))
      databaseProjectId === 'card-nudge' && mis.push(gpmi(userMenuItems.sendGCNotifications, handleAppManagementType))
    } else if (isClient) {
      mis.push(gpmi(userMenuItems.checkAuthorizations, handleAppManagementType))
      mis.push(gpmi(userMenuItems.getClientProfiles, handleAppManagementType))
      // mis.push(gpmi(userMenuItems.updateClientProfiles, handleAppManagementType))
      // mis.push(gpmi(userMenuItems.sendSignInEmails, handleAppManagementType))
      databaseProjectId === 'card-nudge' && mis.push(gpmi(userMenuItems.sendGCNotifications, handleAppManagementType))
    } else {
      mis.push(gpmi(userMenuItems.add, handleAppManagementType))
    }
    return mis
  }

  const showPopupIcon = () => { return true }

  const authorization_fpw = () => <FullPageWrapper
    content={<UserAuths />}
    handleCancel={handleAppManagementType}
    topperCaption={'Authorization Check'}
    isBack={true}
  />

  const add_fpw = () => <FullPageWrapper
    content={'Add Content'}
    handleCancel={handleAppManagementType}
    topperCaption={'Add'}
    isBack={true}
  />

  const email_fpw = () => <FullPageWrapper
    content={<AppUserView ignoreInverted={true} />}
    handleCancel={handleAppManagementType}
    topperCaption={'Send Emails'}
    isBack={true}
  />

  const gcNotifications_wrapper = () => <Wrapper
    content={'Are you sure you want to send Gcs'}
    footer={<UiSaveButtons save={{ caption: 'Send', oc: handleSendGsc }} />}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const gcNotifications_fpw = () => <FullPageWrapper
    content={gcNotifications_wrapper()}
    handleCancel={handleAppManagementType}
    topperCaption={'Send Gcs'}
  />

  const clientProfile_fpw = () => <Wrapper
    content={'Are you sure you want to update the client profile collection?'}
    footer={<UiSaveButtons
      save={{ caption: 'Update', oc: handleUpdate_clientProfiles }}
      preview={{ caption: 'Merge', oc: handleUpdate_clientProfilesMerge }}
      delete={{ caption: 'Remove Invalid', oc: handleUpdate_clientProfilesRemove }}
    />}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const clientProfiles_fpw = () => <FullPageWrapper
    content={clientProfile_fpw()}
    handleCancel={appUserManagement_handlers.handleAppManagementType}
    topperCaption={'Update Client Profiles'}
    isBack={true}
  />

  const popuper = () => <Popuper
    menuItems={popupMenuItems()}
    content={wrapperFull()}
    showPopupIcon={showPopupIcon()}
    closePopup={closePopup}
  />

  const popuper_wrapper = () => <Wrapper
    content={popuper()}
    footer={<UiSaveButtons save={{ caption: 'Update', oc: setAppManagementType, color: 'blue', icon: 'arrow right' }} />}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    confirmation={confirmation}
  />

  const content_popup = () => {
    if (showFooter) {
      return popuper_wrapper()
    } else {
      return popuper()
    }
  }

  const fpw_popupper = () => <FullPageWrapper
    content={profileType ? content_popup() : <div>---</div>}
    handleCancel={handleCancel}
    fullMode={fromDataViewer ? true : false}
    topperCaption={'App User Management'}
    topperCaption2={_.startCase(profileType)}
  />

  const appUserContent = () => {
    if (fromNotifications) {
      return profileType ? fpw_popupper() : <div>---</div>
    } else {
      return fpw_popupper()
    }
  }

  switch (appManagementType) {
    case showAppManagementTypes.auth:
      return authorization_fpw()
    case showAppManagementTypes.add:
      return add_fpw()
    case showAppManagementTypes.email:
      return email_fpw()
    case showAppManagementTypes.gcNotifications:
      return gcNotifications_fpw()
    case showAppManagementTypes.topics:
      return <CreateTopics handleCancel={appUserManagement_handlers.handleAppManagementType} selectedItems={_selectedItems} />
    case showAppManagementTypes.updateAppUserProfiles:
    case showAppManagementTypes.updateClientProfiles:
      return clientProfiles_fpw()
    default:
      return selectedAppUserKey ?
        <AppUserManager
          selectedAppUser={selectedAppUser}
          selectedAppUserKey={selectedAppUserKey}
          handleCancel={appUserManagement_handlers.handleCloseAppUser}
        />
        :
        appUserContent()
  }
}

export default AppUserManagement