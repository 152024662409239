import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { appUserDataHandlers, appUserDataInitialState, appUserDataReducer } from '../reducers/AppUserDataReducer';
import { ParentContext } from './ParentContext';

/** Global Conversations for the Event */
export const AppUserDataContext = createContext();

/** Global Conversations for the Event */
const AppUserDataProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state } = states
  const { page_fns } = fns ? fns : {}

  const { appUser } = appUser_state ? appUser_state : {}
  const { pathViews, showStatus } = paps_state ? paps_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { uid } = appUserAccess ? appUserAccess : {}

  // local state
  const initState = appUserDataInitialState(appUser, paps_state, page_state, page_fns)
  const [appUserData_state, appUserData_dispatch] = useReducer(appUserDataReducer, initState);
  const appUserData_handlers = appUserDataHandlers(appUserData_dispatch)
  const { conversations, notes } = appUserData_state

  if (showStatus && (conversations || notes)) { console.log('conversation_state ready', appUserData_state) }

  useEffect(
    () => {
      if (appUser.loggedIn && pathViews && pathViews.events) {
        appUserData_handlers.handleGetConversations(paps_state)
        appUserData_handlers.handleGetNotes(paps_state)
      } else {
        appUserData_handlers.handleLoadConversations({})
        appUserData_handlers.handleLoadNotes({})
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathViews.events, uid] // does not re-rednder unless uivi has changed
  )

  const providerContext = () => {
    return <AppUserDataContext.Provider value={{ appUserData_state, appUserData_handlers, appUser }}>
      {props.children}
    </AppUserDataContext.Provider>
  }

  return (
    <React.Fragment>
      {(conversations && notes) && providerContext(conversations)}
    </React.Fragment>
  )
}

export default AppUserDataProvider