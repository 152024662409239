import _ from 'lodash';
import { dynListTypes, gEnums } from '../enums/globalEnums';
import { sortObject } from '../common/sorting';
import { listList, listListO, listStatic } from "../lists/lists";

/**
 * 
 * @param {object} props (currentDynListProps, dynProps)
 * @returns an object of dynamic list options
 */
export const addDynamicListOptions = (props) => {

  const dl_opts = {}

  const { currentDynListProps, dynProps } = props

  // page_state, eventInfo_state, storage_state,
  const { formParent_contexts, formProps, currentViPropList, currentCollectionPropList, data_combined, staticViews_client } = dynProps

  const { states, handlers } = formParent_contexts ? formParent_contexts : {}
  const { page_state, eventInfo_state, storage_state } = states ? states : {}

  const { updateProps } = formProps ? formProps : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems, aps_appUserSettings, viewItemsList, allPropsList } = pageSettings ? pageSettings : {}
  const { projectOptions } = aps_global ? aps_global : {}
  const { additionalAppUserViewTypeProps, appUserCollection, appUserViewTypeProp } = aps_appUserSettings ? aps_appUserSettings : {}
  const { availableSubActionTypes, availableAddTypes } = projectOptions ? projectOptions : {}
  const { staticViews } = eventInfo_state ? eventInfo_state : {}
  const viewItem_appUser = appUserCollection && aps_viewItems && aps_viewItems[appUserCollection] ? aps_viewItems[appUserCollection] : {}
  const { props: props_appUser } = viewItem_appUser ? viewItem_appUser : {}

  const c = viewItemsList ? [...viewItemsList] : []
  c.push('clients')

  currentDynListProps.forEach(itemProp => {
    const { data, key: key_prop } = itemProp ? itemProp : {}
    const { listType: dynListType, listFrom, staticView: staticView_data, staticListProp: staticListProp_data, formItemType } = data ? data : {}
    const { staticList } = staticListProp_data ? staticListProp_data : {}

    let isNameSelect;

    switch (formItemType) {
      case gEnums.formItemTypes.nameSelect:
        isNameSelect = true
        break;
      default:
        isNameSelect = false
      // nothing
    }

    // list type can be for either listTypes OR dynListTypes

    switch (dynListType) {

      case dynListTypes.appPdfs:
        if (storage_state && storage_state.globalFiles && storage_state.globalFiles.pdf) {
          dl_opts[dynListType] = listListO(storage_state.globalFiles.pdf)
        } else {
          dl_opts[dynListType] = {}
        }
        break;

      case dynListTypes.itemPdf:
        if (storage_state && storage_state.globalFiles && storage_state.globalFiles.pdf) {
          dl_opts[dynListType] = listListO(storage_state.globalFiles.pdf)
        } else {
          dl_opts[dynListType] = {}
        }
        break;

      case dynListTypes.appUserViewTypeProps:
        if (staticViews && staticViews[appUserViewTypeProp]) {
          dl_opts[dynListType] = listStatic(staticViews[appUserViewTypeProp], appUserViewTypeProp)
        }
        break;

      case dynListTypes.activeAppUserViewProps:
        const _props_appUser = props_appUser ? { ...props_appUser } : {}
        Object.keys(_props_appUser).forEach(key => {
          if (key.startsWith('_')) { delete _props_appUser[key] }
        })
        dl_opts[dynListType] = _props_appUser ? listListO(_props_appUser) : null
        break;

      case dynListTypes.activeAppUserView:
        dl_opts[dynListType] = additionalAppUserViewTypeProps ? listList([...additionalAppUserViewTypeProps, appUserCollection]) : listList([appUserCollection])
        break;

      case dynListTypes.activeCollectionPropList:
        dl_opts[dynListType] = currentCollectionPropList ? listListO(currentCollectionPropList) : null
        break;

      case dynListTypes.activePropList:
        dl_opts[dynListType] = currentViPropList ? listListO(currentViPropList) : null
        break;

      case dynListTypes.activePropsList:
        dl_opts[dynListType] = currentViPropList ? listListO(currentViPropList) : null
        break;

      case dynListTypes.activeSectionList:
        dl_opts[dynListType] = currentViPropList ? listListO(sectionsFromProps(currentViPropList)) : null
        break;

      case dynListTypes.activeAppUserStaticView:
        if (staticViews && staticViews[appUserCollection]) {
          dl_opts[dynListType] = listStatic(staticViews[appUserCollection], appUserCollection)
        }
        break;

      case dynListTypes.activeStaticView:
        if (staticViews && staticViews[staticView_data]) {
          dl_opts[dynListType] = listStatic(staticViews[staticView_data], staticView_data, null, isNameSelect, staticView_data)
        }

        if (staticViews_client && staticViews_client[staticView_data]) {
          dl_opts[dynListType] = listStatic(staticViews_client[staticView_data], staticViews_client, null, isNameSelect, staticView_data)
        }
        break;

      case dynListTypes.activeStaticViewsList:
        if (staticListProp_data && staticViews && staticViews[staticList]) {
          dl_opts[dynListType] = listStatic(staticViews[staticList])
        } else {
          dl_opts[dynListType] = listListO(staticViews)
        }
        break;

      case dynListTypes.activeViList:
        dl_opts[dynListType] = listList(viewItemsList)
        break;

      case dynListTypes.activeViPropList:
        if (data_combined && listFrom && data_combined[listFrom]) {
          const listFromValue = data_combined[listFrom]
          if (staticViews && staticViews[listFromValue]) {
            const staticValues = staticViews[listFromValue]
            dl_opts[dynListType] = listStatic(staticValues)
          }
        } else if (aps_viewItems) {
          dl_opts[dynListType] = listListO(aps_viewItems)
        }
        // ola[dynListType] = listList(viewItemsList)
        break;

      case dynListTypes.activeViPropList2:
        if (data_combined && listFrom && data_combined[listFrom]) {
          const listFromValue = data_combined[listFrom]
          if (staticViews && staticViews[listFromValue]) {
            const staticValues = staticViews[listFromValue]
            dl_opts[dynListType] = listStatic(staticValues)
          }
        }
        // ola[dynListType] = listList(viewItemsList)
        break;

      case dynListTypes.activeViListExceptSelf:
        dl_opts[dynListType] = listList(viewItemsList, null, updateProps && updateProps.vit)
        break;

      case dynListTypes.activeViListPlusClient:
        dl_opts[dynListType] = listList(c.sort())
        break;

      case dynListTypes.allPropList:
        dl_opts[dynListType] = listList(allPropsList)
        break;

      case dynListTypes.appAddActions:
        dl_opts[dynListType] = listList(availableAddTypes)
        break;

      case dynListTypes.appActions:
        dl_opts[dynListType] = listList(availableSubActionTypes)
        break;

      case dynListTypes.settingsHandlers:
        dl_opts[dynListType] = listList(getAllHandlers(handlers))
        break;

      case dynListTypes.settingsHandlerRts:
        if (data_combined && listFrom && data_combined[listFrom]) {
          const listFromValue = data_combined[listFrom]
          dl_opts[dynListType] = listList(getHandler(handlers, listFromValue))
        }
        break;

      default:
        break;
    }

    // LOOK
    if (listFrom && data_combined && data_combined[listFrom]) {
      const listFromValue = data_combined[listFrom]
      switch (listFromValue) {
        case 'itemStatusType':
          dl_opts[key_prop] = listList(gEnums.statusTypes)
          break;
        default:
          const vi = aps_viewItems[listFromValue]
          const { props } = vi ? vi : {}
          const zz = props ? listListO(props) : {}
          dl_opts[key_prop] = zz
      }
    }
  })


  return dl_opts
}

const sectionsFromProps = (currentViPropList) => {
  if (currentViPropList) {
    let sl = _.groupBy(currentViPropList, 'propSection')
    if (sl) { return sortObject(sl, 'key') }
  }
  return currentViPropList
}

const getAllHandlers = (handlers) => Object.keys(handlers)
const getHandler = (handlers, handlerKey) => {
  if (handlers && handlers[handlerKey]) {
    return Object.keys(handlers[handlerKey])
  }
}
