import { gEnums } from "../../../enums/globalEnums"
import { filterAlphaData, filterFilteredData, filterGroupFilters, filterSearchValue, getItemsArrayNotInArray } from "../../../common/filtering"

export const postFilterData = (props) => {

  const { viewItem, viewListData, filterValues, searchValue, alphaValue, selectedFilters, dataFilters, ignoreAlpha, logging, f } = props

  const { display, filter, search, dataSource, dataConstraints, grouping } = viewItem ? viewItem : {}
  const { alphabetActive, alphaFilterAfter, alphaFilterType, fetchByAlphabet, alphaProp } = grouping ? grouping : {}
  const { sortProp } = display ? display : {}
  const { filterProp } = filter ? filter : {}
  const { searchProps } = search ? search : {}

  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  let fds;

  const {
    useDataFilter,
    filterName,
    appUserConstraintDisallowProps, // not in use??
    useDataConstraints,
    dataConstraintDisallowProps, // not in use??
  } = _dataConstraints ? _dataConstraints : {}

  const showRefLog = logging && logging.allowLogging && logging.logRefProps

  // eslint-disable-next-line 
  let isAlpha = false

  if (!ignoreAlpha && alphabetActive && !fetchByAlphabet) {
    switch (alphaFilterType) {
      case gEnums.alphaFilterTypes.always:
        isAlpha = true
        break;
      case gEnums.alphaFilterTypes.afterLimit:
        const dataCount = viewListData ? Object.keys(viewListData).length : 0
        const limit = alphaFilterAfter ? alphaFilterAfter : 100
        if (dataCount > limit) { isAlpha = true }
        break;
      default:
      // nothing
    }
  }

  if (viewListData && useDataFilter && filterName && dataFilters && dataFilters[filterName]) {
    const df = dataFilters[filterName]
    fds = filterGroupFilters(viewListData, df)
  } else {
    if (!searchValue) {
      const _alphaProp = alphaProp ? alphaProp : sortProp
      fds = filterFilteredData(viewListData, filterProp, filterValues, f)
      if (selectedFilters) { fds = filterGroupFilters(fds, selectedFilters) }
      if (isAlpha) { fds = filterAlphaData(fds, alphaValue, _alphaProp, f) }
    } else {
      fds = filterSearchValue(viewListData, searchProps, searchValue, f)
    }

    if (useDataConstraints && dataConstraintDisallowProps && appUserConstraintDisallowProps) {
      appUserConstraintDisallowProps.forEach(aucdp => {
        const _dcdp = []
        dataConstraintDisallowProps.forEach(dcdp => {
          _dcdp.push(dcdp)
        })
        fds = getItemsArrayNotInArray(fds, aucdp, _dcdp)
        showRefLog && console.log('dataConstraints - POST (getItemsArrayNotInArray)', aucdp, _dcdp)
      })
    }
  }

  return fds

}