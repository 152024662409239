import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { startTypes } from '../../cnr/reducers/StartReducer';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import { getElementStyle } from '../../styles/formatting';

const Splash = (props) => {

  const { formData, styleAndClass: snc } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ? parentContext : {}
  const { page_state, transition_state } = states ? states : {}
  const { start_handlers } = handlers ? handlers : {}
  const { storage_fns } = fns ? fns : {}

  // startContext  
  const { handleUpdateStart } = start_handlers ? start_handlers : {}

  // pageContext  
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const { themedItems, splashScreen } = aps_global ? aps_global : {}
  const { useSplashScreenLogo, splashScreenLogo, splashScreenImage, useSplashScreenImage } = splashScreen ? splashScreen : {}
  const { splashScreen: splashScreenTheme } = themedItems ? themedItems : {}
  const { topMenu: topMenu_app } = aps_global ? aps_global : {}
  const { mainFixedCaption } = topMenu_app ? topMenu_app : {}

  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.splash] : null
  const { showTransition } = transition ? transition : {}

  // local state
  const [ssImage, setSsImage] = useState()
  const [ssLogo, setSsLogo] = useState()

  const hasLogo = ssLogo && ssLogo.url

  // update the backgroundImage
  // if (snc && ssImage && ssImage.url) { snc.style.backgroundImage = `url(${ssImage.url}) ` }

  useEffect(() => {
    // turns off the start page
    handleUpdateStart && handleUpdateStart(startTypes.finish)
    if (useSplashScreenImage && splashScreenImage) { storage_fns.getImageUrl(splashScreenImage, true, gEnums.storageTypes.image, setSsImage) }
    if (useSplashScreenLogo && splashScreenLogo) { storage_fns.getImageUrl(splashScreenLogo, true, gEnums.storageTypes.image, setSsLogo) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const _styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.splashScreen] : {}
  const styleAndClass = snc ? snc : _styleAndClass
  const itemStyle = getElementStyle(splashScreenTheme, ['fontSize'])

  if (formData) { styleAndClass.className += ' preview' }

  const splashScreen_current = formData ? formData : splashScreen
  let { useSplashScreenText, splashScreenText, splashScreenSubText } = splashScreen_current ? splashScreen_current : {}

  let ssText = useSplashScreenText && splashScreenText ? splashScreenText : mainFixedCaption
  let ssSubText = splashScreenSubText ? splashScreenSubText : null

  if (styleAndClass.className) { if (ssLogo) { styleAndClass.className += ' logo' } }
  if (styleAndClass.className) { if (ssSubText) { styleAndClass.className += ' logo foot' } }

  try {
    if (styleAndClass && ssImage && ssImage.url) { styleAndClass.style.backgroundImage = `url(${ssImage.url}) ` }
  } catch (error) {
    console.error('Splash', error)
  }

  if (hasLogo && !useSplashScreenText && styleAndClass.className) {
    styleAndClass.className += ' logo-only'
  }

  const logo = () => hasLogo ? <Image key={uniqueKey('spl', 'i')} centered src={ssLogo.url} alt={'image'} /> : <div></div>
  const sub = () => ssSubText ? ssSubText : ''

  const splashLogoText = () => <React.Fragment>
    <div className={'splash-logo'}>{logo()}</div>
    <div className={'splash-text'} style={itemStyle}>{ssText}</div>
    <div className={'splash-footer'} >{sub()}</div>
  </React.Fragment>

  const splashLogoOnly = () => <React.Fragment>
    <div className={'splash-text'}>{logo()}</div>
    <div className={'splash-footer'} >{sub()}</div>
  </React.Fragment>

  const splashContent = () => {
    if (hasLogo && !useSplashScreenText) {
      return splashLogoOnly()
    } else {
      return splashLogoText()
    }
  }

  const content = () => <div {...styleAndClass}>
    <div className='splash-content'>{splashContent()}</div>
  </div>


  if (showTransition) {
    return <MotionComponent
      transition={transition}
      styleAndClass={styleAndClass}>
      {content()}
    </MotionComponent>
  } else {
    return content()
  }
}

export default Splash