import _ from 'lodash';
import { formatItem } from './dateFormatting';
import { deleteField } from "firebase/firestore";

const showLog = false
const clgg = (m, a, b) => { console.log(m, a, b) }

export const _deleteItemProps = ['_itemKey', 'id', 'key', '_gs', '_sv', 'published', '_dateInfo', '_allowProp', 'pageDataConstraintType', '_new']
export const _deleteViProps = ['bodyHeight', 'clickPath', 'css', 'path', 'viewPermissionType', 'viewProps']

const delete_field = () => deleteField()

/**
 * 
 * @param {object} updateData 
 * @returns a new object with `deleteField` for empty data fields
 */
export const createDeleteFields = (updateData) => {
  const _updateData = { ...updateData }
  if (_updateData) {
    Object.keys(updateData).forEach(prop => {
      const udv = updateData[prop]
      if (!_.isObject(udv) && !_.isArray(udv) && !_.isBoolean(udv) && !_.isNumber(udv)) {
        if (udv) {
          const xxx = udv ? udv.replace(/ /g, '') : null
          if (!xxx || _.isEmpty(xxx)) {
            _updateData[prop] = delete_field()
          }
        }
      }
      if (_deleteItemProps.includes(prop)) {
        _updateData[prop] = delete_field()
      }
    })
  }
  return _updateData
}

export const ammendDeleteFields = (dataItem) => {
  _deleteItemProps.forEach(df => {
    if (dataItem[df]) {
      dataItem[df] = delete_field()
    }
  })

  if (dataItem._deleteProps) {
    dataItem._deleteProps.forEach(dp => {
      dataItem[dp] = delete_field()
    })
    delete dataItem._deleteProps
  }
}

/**
 * 
 * @param {string} name 
 * @param {number} max 
 * @returns initials for the name
 */
export const createInitials = function (name, max, includeSecond) {
  if (name) {
    let _max = max ? max : 2
    let parts = name.split(' ')
    let initials = ''
    if (parts.length > 1) {
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 1 && parts[i] !== '') {
          initials += parts[i][0].toUpperCase()
        }
      }
      return initials.substring(0, _max)
    } else {
      initials += parts[0].substring(0, includeSecond ? 2 : 1).toUpperCase()
    }
    return initials
  }
  return name
}

/**
 * 
 * @param {object} data 
 * @param {string} dateFormat 
 * @description formats any prop ending in `date` using the `formatItem` function
 */
export const convertDates = function (data, dateFormat) {
  Object.keys(data).forEach(key => {
    const dataItem = data[key]
    Object.keys(dataItem).forEach(propKey => {
      if (propKey.toLowerCase().indexOf('date') >= 0) {
        dataItem[propKey] = formatItem(dateFormat, dataItem[propKey])
      }
    })
  })
}

/**
 * 
 * @param {object} data  
 * @description converts any prop ending in `endingIn` to lowerCase
 */
export const convertToLowerCase = function (data, endingIn) {
  Object.keys(data).forEach(key => {
    const dataItem = data[key]
    Object.keys(dataItem).forEach(propKey => {
      if (propKey.toLowerCase().indexOf(endingIn) >= 0) {
        dataItem[propKey] = dataItem[propKey].toLowerCase()
      }
    })
  })
}

/**
 * 
 * @param {object} data 
 * @param {string} defaultProp 
 * @param {string} defaultValue 
 * @description add a `defaultValue` to all the items in `data` with the `defaultValue`
 */
export const addDefaultProps = function (data, defaultProp, defaultValue) {
  Object.keys(data).forEach(key => {
    const dataItem = data[key]
    dataItem[defaultProp] = defaultValue
  })
}

/**
 * Loops the viewitems and removes/cleans up data
 * This modifies the object. 
 * No object is returned.
 * @param {object} viewItems 
 * @param {boolean} fixBooleans 
 */

export function removeEmpty(obj) {
  // return null, undefined, false objects as is
  if (obj === null || obj === undefined || obj === false) {
    return obj;
  }

  // handle arrays recursively
  if (Array.isArray(obj)) {
    return obj.filter(item => {
      return removeEmpty(item) !== null && removeEmpty(item) !== '' && removeEmpty(item) !== undefined;
    });
  }

  // handle objects recursively
  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const value = removeEmpty(obj[key]);
      if (value !== null && value !== '' && value !== false && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  // return all other values as is
  return obj;
}

/**
 * 
 * @param {object} dataItems 
 * @param {boolean} fixBooleans 
 * @description Loops the viewitems and removes/cleans up data
 * This modifies the object. 
 * No object is returned.
 */
export const cleanUpData = (dataItems, fixBooleans, createDeleteFields, ammendDeletes) => {
  Object.keys(dataItems).forEach(key => {
    const dataItem = dataItems[key]
    if (dataItem && _.isObject(dataItem)) {

      removeViStuff(dataItem)
      deleteEmptyObjectValues(dataItem, createDeleteFields)
      removeAllEmpties(dataItem, createDeleteFields)

      ammendDeletes && ammendDeleteFields(dataItem)

      if (fixBooleans) { fixBooleanItems(dataItem) }
      if (dataItem._deleteProps) {
        dataItem._deleteProps.forEach(dp => {
          // dataItem[dp] = delete_field()
          // dataItem[db] = _deleteProp
        })
        delete dataItem._deleteProps
      }
      if (dataItem.props) {
        ammendDeleteFields(dataItem.props)
        Object.keys(dataItem.props).forEach(pKey => {
          const pr = dataItem.props[pKey]
          if (pr._deleteMe) {
            dataItem.props[pKey] = delete_field()
          }
          delete pr.propAuthLevel
        })
      }
    }
  })
}

/**
 * 
 * @param {object} viewItem 
 * @description Converts any string boolean values to the true or false
 */
const fixBooleanItems = (viewItem) => {
  Object.keys(viewItem).forEach(propKey => {
    const propValue = viewItem[propKey]
    if (propValue === 'TRUE' || propValue === 'true' || propValue === 'True') {
      viewItem[propKey] = true
    }
    if (propValue === 'FALSE' || propValue === 'false' || propValue === 'False') {
      viewItem[propKey] = false
    }
  })
}

/**  
/**
 * 
 * @param {object} vi 
 * @description deletes (bodyHeight, clickPath, css, pah, viewPermissionType and viewProps) 
 */
export const removeViStuff = (vi) => {
  _deleteViProps.forEach(dp => {
    delete vi[dp]
  })
}

/**
 * 
 * @param {object} viewItem 
 * @description deletes all non boolean item values that do not have a value
 */
const deleteEmptyObjectValues = (viewItem, createDeleteFields) => {
  Object.keys(viewItem).forEach(key => {
    switch (key) {
      case 'position':
        break;
      default:
        if (!_.isBoolean(viewItem[key])) {
          if (!viewItem[key]) {
            delete viewItem[key]
          } else {
            const vip = viewItem[key]
            Object.keys(vip).forEach(key2 => {
              if (!_.isBoolean(vip[key2])) {
                if (!vip[key2]) {
                  if (createDeleteFields) {
                    vip[key2] = delete_field()
                  } else {
                    delete vip[key2]
                  }
                }
              }
            })
          }
        }
    }
  })
}

/**
 * 
 * @param {object} obj 
 * @description Removes empty, null, '', etc. values from all the objects in `obj`
 */
export const removeAllEmpties = (obj, createDeleteFields) => {
  Object.keys(obj).forEach(key => {
    if (!_.isBoolean(obj[key])) {
      if (obj[key] && _.isObject(obj[key])) {
        if (Object.keys(obj[key]).length === 0) {
          showLog && clgg('remove key', key, obj[key])
          if (createDeleteFields) {
            obj[key] = delete_field()
          } else {
            delete obj[key];
          }
        } else {
          removeAllEmpties(obj[key], createDeleteFields);
        }
      } else if (obj[key] && _.isArray(obj[key])) {
        if (obj[key].length === 0 || obj[key] === undefined) {
          showLog && clgg('remove key', key, obj[key])
          if (createDeleteFields) {
            obj[key] = delete_field()
          } else {
            delete obj[key];
          }
        }
      } else if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
        showLog && clgg('remove key', key, obj[key])
        if (createDeleteFields) {
          obj[key] = delete_field()
        } else {
          delete obj[key];
        }
      }
    }
  })
  return obj;
};

/**
 * 
 * @param {*} val 
 * @returns if the val is null, empty, undefined, etc.
 */
const isNullish = (val) => {
  if (val === null ||
    val === "" ||
    val === "undefined" ||
    val === undefined ||
    _.isNaN(val) ||
    _.isNull(val) ||
    _.isEmpty(val) ||
    (_.isObject(val) && Object.keys(val).length === 0)) {
    return true
  }
}

/**
 * 
 * @param {object} obj 
 * @returns the `obj` after removing nulls
 */
export const removeEmpties = (obj) => {
  // eslint-disable-next-line
  Object.entries(obj).forEach(([key, val]) => {
    if (val) {
      if (_.isObject(val)) {
        if (_.isEmpty(val)) {
          delete obj[key]
        } else {
          removeEmpties(val)
        }
      } else {
        if (isNullish(val)) {
          delete obj[key]
        }
      }
    } else {
      if (!_.isBoolean(val)) {
        delete obj[key]
      }
    }
  });
  return obj;
};

/**
 * 
 * @param {object} obj 
 * @returns the `obj` after removing nulls
 */
export const removeNullsInObject = (obj) => {
  if (typeof obj === 'string' || obj === "") {
    return;
  }
  _.each(obj, function (key, value) {
    if (value === "" || value === null) {
      delete obj[key];
    } else if (_.isArray(value)) {
      if (value.length === 0) {
        delete obj[key];
        return;
      }
      _.each(value, function (k, v) {
        removeNullsInObject(v);
      });
      if (value.length === 0) {
        delete obj[key];
      }
    } else if (typeof value === 'object') {
      if (Object.keys(value).length === 0) {
        delete obj[key];
        return;
      }
      removeNullsInObject(value);
      if (Object.keys(value).length === 0) {
        delete obj[key];
      }
    }
  });
  return obj
}

/**
 * 
 * @param {object} item 
 * @param {array} removes 
 * @description Removes the `removes` array from the `item`
 */
export const deleteObjectItems = (item, removes) => {
  removes.forEach(remove => {
    delete item[remove]
  })
}
/**
 * 
 * @param {object} item  
 * @description Removes any value in the `item` object ending in `UTC`
 */
export const deleteUTCs = (item) => {
  Object.keys(item).forEach(key => {
    if (key.endsWith('UTC')) {
      delete item[key]
    }
  })
}