import { createItemKeys } from '../../../../global/common/convert';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_get_doc, fs_update_doc } from '../../../../global/firestoreData/appData/fsData';
import { getData_parentKeyCollections } from './reducerHelpers/seasonSportsData';

const rts = {
  handleSetDataResults: 'handleSetDataResults',
  handleSetOrganization: 'handleSetOrganization',
  handleSportsOrganizationInit: 'handleSportsOrganizationInit',
  handleUpdateDistrictSports: 'handleUpdateDistrictSports',
}

const dataItems = {
  // districts: { collectionItems: ['districts'] },
  schools: { collectionItems: ['schools'] },
  history: { collectionItems: ['history'] },
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsOrganizationReducer = (state, action) => {

  const { pathViews, dataParents } = state
  const { type, dispatch } = action

  const sportsOrganization_handlers = sportsOrganizationHandlers(dispatch)
  const { handleSetDataResults, handleSetOrganization } = sportsOrganization_handlers

  switch (type) {

    case rts.handleSportsOrganizationInit:

      const _refPath = createRefPath_event(action.pathViews, ['organizations', action.pathViews.organizations])
      fs_get_doc(_refPath, handleSetOrganization)

      const { selectedSeason } = action
      const additionalDataParents = []

      getData_parentKeyCollections(action.pathViews, dataItems, action.dataParents, additionalDataParents, null, selectedSeason, handleSetDataResults)
      return { ...state, pathViews: action.pathViews }

    case rts.handleSetOrganization:
      const { organization } = action
      const { sports, districts } = organization ? organization : {}
      return { ...state, organization, sports, districts }

    // handles the result when all the data from the parentKeys are collected
    case rts.handleSetDataResults:

      const { dataResult, parentDataKeys } = action

      const _results = {}

      Object.keys(dataItems).forEach(diKey => {
        _results[diKey] = dataResult[diKey]
        createItemKeys(_results[diKey])
      })

      return {
        ...state,
        ..._results,
        parentDataKeys,
      }

    case rts.handleUpdateDistrictSports:
      updateDistrictsPromise(pathViews, action.districts).then(
        action.callback()
      )
      return { ...state }

    default:
      return { ...state }
  }
}

export const sportsOrganizationHandlers = (dispatch) => {
  return {
    handleSportsOrganizationInit: (pathViews, dataParents) => { dispatch({ type: rts.handleSportsOrganizationInit, dispatch, pathViews, dataParents }) },
    handleSetDataResults: (dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys) => { dispatch({ type: rts.handleSetDataResults, dispatch, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys }) },
    handleSetOrganization: (organization) => { dispatch({ type: rts.handleSetOrganization, dispatch, organization }) },
    handleUpdateDistrictSports: (districts, callback) => { dispatch({ type: rts.handleUpdateDistrictSports, dispatch, districts, callback }) },
  }
}

export const sportsOrganizationInitialState = (init_state) => {
  return { ...init_state, currents: {}, currentSidebars: {} }
};

const updateDistricts = async (pathViews, districts) => {
  updateDistrictsPromise(pathViews, districts)
}

const updateDistrictsPromise = async (pathViews, districts) => {
  const promises = []
  if (districts) {
    Object.keys(districts).forEach(key => {
      const district = districts[key]
      const _refPath = createRefPath_event(pathViews, ['districts', key])
      promises.push(fs_update_doc(_refPath, district, null, true))
    })
  }
  return Promise.all(promises)
}