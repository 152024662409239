/** add an id to each of the items of the value and returns the value */
export const dvv = (value) => {
  let data = null
  try {
    value.forEach(doc => {
      data = {
        id: doc.id,
        ...doc.data()
      }
    })
  } catch (error) {
    console.log('dvv', value, error)
  }
  return data
} 