export function refreshPageWithCacheClear() {
  // Clearing cache using different techniques based on browser support

  if (typeof caches !== 'undefined') {
    // Clearing cache using Service Workers (if supported)
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }

  if (window.hasOwnProperty('caches')) {
    // Clearing cache using CacheStorage API (if supported)
    window.caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        window.caches.delete(cacheName);
      });
    });
  }

  // Reloading the page
  window.location.reload(true); // Passing true as a parameter forces a server reload and cache clear on most browsers
}
