import _ from 'lodash';
import { fs_get_data_all, fs_get_eventData_global } from '../../../../../global/firestoreData/appData/fsAppData';
import { getParentKeyData_sports } from './parentKeySportsData';

/**
 * Returns data from `_global.levels`, `_global.sections`, `schools` and `teams` 
 * @param {object} pathViews 
 * @param {function} handleSetDataResults 
 */
export const getData_createSeasonInit = (pathViews, sportsKey, handleSetDataResults) => {

  const datas = {}

  const dataItems = {
    levels: { isGlobal: true, dataParents: null }, // _globals.levels
    sections: { isGlobal: true, dataParents: null },// _globals.sections
    schools: { isGlobal: false, isSub: true, dataParents: ['organizations', 'districts'] }, // _schools with parentKeys (organizations, districts)
    teams: { isGlobal: false, dataParents: ['organizations', 'districts', 'sports'] }, // teams with parentKeys (organizations, districts, sports)
  }

  const cb_datas = () => {
    let valid = true
    Object.keys(dataItems).forEach(key => {
      if (!datas[key]) {
        valid = false
      }
    })

    if (valid) {
      handleSetDataResults(datas)
    }
  }

  const cb = (data, opts) => {
    const { cbProps, collectionName } = opts ? opts : {}
    const { collectionName: collectionName_cbProps } = cbProps ? cbProps : {}
    const _collectionName = collectionName ? collectionName : collectionName_cbProps
    datas[_collectionName] = data ? data : {}
    cb_datas()
  }

  const cb_pk = (data, opts) => {
    const { cbProps, collectionName } = opts ? opts : {}
    const { collectionName: collectionName_cbProps } = cbProps ? cbProps : {}
    const _collectionName = collectionName ? collectionName : collectionName_cbProps
    datas[_collectionName] = data[_collectionName] ? data[_collectionName] : {}
    cb_datas()
  }

  Object.keys(dataItems).forEach(key => {
    const dataItem = dataItems[key]
    const { isGlobal, isSub, dataParents } = dataItem ? dataItem : {}
    if (isGlobal) {
      // levels, sections
      fs_get_eventData_global(pathViews, key, true, cb, false, { collectionName: key })
    } else {
      if (isSub) {
        // schools
        getParentKeyData_sports(key, pathViews, dataParents, cb_pk, null, null, null, null, { collectionName: key })
      } else {
        // teams
        fs_get_data_all(pathViews, key, cb, { sportsKey, dataParents, cbProps: { collectionName: key } })
      }
    }
  })

}

export const getSelectedTeamsFromSchools = (schools, teams) => {
  const selected = {}
  const nonSelected = {}
  const selectedIndexes = []
  if (schools && teams) {
    Object.keys(schools).forEach(sk => {
      const teamKey = _.findKey(teams, function (t) { return t.parentKeys && t.parentKeys['schools'] === sk; })
      if (teamKey) {
        selected[teamKey] = teams[teamKey]
        selectedIndexes.push(sk)
      } else {
        nonSelected[teamKey] = teams[teamKey]
      }
    })
  }
  return { selected, nonSelected, selectedIndexes }
}

export const ammendPlayoffSeeding = (playoffLevelTeams) => {

  const seedTests = []
  const max = 15
  const halfMax = 7

  for (let i = 0; i <= halfMax; i++) {
    seedTests.push({ h: i, a: max - i })
  }

  Object.keys(playoffLevelTeams).forEach(k => {
    const teams = playoffLevelTeams[k]
    seedTests.forEach(st => {
      const h = teams[st.h]
      const a = teams[st.a]
      if (h) { h.badSeed = false }
      if (a) { a.badSeed = false }
      if (h && a) {
        h.badSeed = h.sections === a.sections ? h.sections : null
        a.badSeed = h.sections === a.sections ? h.sections : null
      }
    })
  })
}

export const cleanUpPlayoffSeeding = (playoffLevelTeams) => {

  const seedTests = []
  const max = 15
  const halfMax = 7

  for (let i = 0; i <= halfMax; i++) {
    seedTests.push({ h: i, a: max - i })
  }

  Object.keys(playoffLevelTeams).forEach(k => {
    const teams = playoffLevelTeams[k]
    console.log('teams', teams)
  })
}