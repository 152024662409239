import _ from 'lodash';

const espnFeeds = () => {
  const root = 'espn'
  const url = 'https://www.espn.com/espn/rss/'
  const sports = ['nhl', 'nfl']
  const feeds = {}
  feeds[root] = url + 'news'
  sports.forEach(section => {
    feeds[root + _.startCase(section)] = url + section + '/news'
  })
  return feeds
}

export const rssFeedTypes = {
  bbc: { url: 'http://feeds.bbci.co.uk/news/world/rss.xml' },
  cnn: { url: 'http://rss.cnn.com/rss/edition_world.rss' },
  ...espnFeeds(),
  foxNews: { url: 'http://feeds.foxnews.com/foxnews/latest' },
  guardianSports: { url: 'https://www.theguardian.com/uk/sport/rss' },
  newYorkTimes: { url: 'https://www.nytimes.com/svc/collections/v1/publish/https://www.nytimes.com/section/world/rss.xml' },
  npr: { url: 'https://feeds.npr.org/1004/rss.xml' },
  pgSports: { url: 'https://www.post-gazette.com/rss/sports/highschool' },
  reddit: { url: 'https://www.reddit.com/.rss' },
  tribSports: { url: 'https://tribhssn.triblive.com/feed/' },
  washingtonPost: { url: 'http://feeds.washingtonpost.com/rss/world' },
}

