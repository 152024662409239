
import React from 'react';
import { Route } from 'react-router-dom';
import { subAppTypes } from '../../AppSub';
import { isOdd } from '../common/filtering';
import { uniqueKey } from '../common/keys';
import PageNotFound from '../page/PageNotFound';

/**
 * 
 * @param {element} comp 
 * @param {array} viewKeys 
 * @returns vrs, grs, viewRoutes, genericRoutes
 */
export const getRoutes = (comp, viewKeys) => {

  // provide the routes for the project.
  // all go to the same component

  const ptz = {}

  const addR = (isEven, existing) => {
    const x = isEven ? '/:id' : '/:item'
    return existing ? existing + x : x
  }

  for (var i = 1; i <= 12; i++) {
    const eo = ptz['p' + (i - 1)]
    ptz['p' + i] = addR(!isOdd(i), eo)
  }

  const routeObj = (name, path, element) => ({ key: uniqueKey('rte', name), name, path, element: element ? element : comp, exact: true })

  const eventPath = '/clients/:id/events/:id'

  const brs = [
    routeObj('landing', ''),
    routeObj('base', '/'),
    routeObj('clients', '/clients'),
    routeObj('clients-id', '/clients/:id'),
    routeObj('events', '/clients/:id/events'),
    routeObj('events-id', eventPath),
    routeObj(subAppTypes.emailSignInConfirmation, ''),
    routeObj('notFound404', '404', PageNotFound),
    routeObj('notFound', '*', PageNotFound),
  ]

  const vrs = []
  if (viewKeys) {
    viewKeys.forEach(viewKey => {
      const _viewKey = viewKey.replace('_list', '')
      if (viewKey.endsWith('_list')) {
        vrs.push(routeObj(viewKey, eventPath + '/' + _viewKey))
      } else {
        vrs.push(routeObj(viewKey, eventPath + '/' + _viewKey + '/:id'))
      }
    })
  }

  const grs = []

  let _index = 1;
  Object.keys(ptz).forEach((key, index) => {
    const path = ptz[key]
    if (isOdd(index)) {
      grs.push(routeObj('r' + _index + '-id', path))
      _index++
    } else {
      grs.push(routeObj('r' + _index + '-base', path))
    }
  })

  const _vrs = [...vrs, ...brs]
  const _grs = [...grs, ...brs]

  const viewRoutes = createRoutes(_vrs)
  const genericRoutes = createRoutes(_grs)

  const r = {
    vrs,
    grs,
    viewRoutes,
    genericRoutes
  }

  return r
}

/**
 * 
 * @param {array} rts 
 * @returns an arrow of Routes
 */
const createRoutes = (rts) => {
  const routes = []
  rts.forEach(rt => {
    if (rt.exact) {
      routes.push(<Route key={rt.key} name={rt.name} exact path={rt.path} element={rt.element} />)
    } else {
      routes.push(<Route key={rt.key} name={rt.name} path={rt.path} element={rt.element} />)
    }
  })
  return routes
}