import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { itemStorageHandlers, itemStorageInitialState, itemStorageReducer } from '../reducers/ItemStorageReducer';
import { _storageSettings } from '../../storage/storageHelpers';
import { ParentContext } from './ParentContext';
import { UiItemContext } from './UiItemContext';
import { gEnums } from '../../enums/globalEnums';

/** Global Files (Icon, Image, PDF) for the Event taken from the storage */
export const ItemStorageContext = createContext();

/**
 * 
 * @param {*} props (children)
 * @returns Global Files (Icon, Image, PDF) for the Event taken from storage  
 */
const ItemStorageProvider = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ? parentContext : {}
  const { page_state, paps_state, storage_state } = states ? states : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection, useClientProfileImage } = aps_appUserSettings ? aps_appUserSettings : {}
  const { galleries } = storage_state ? storage_state : {}
  const { direct: gallery_direct, profile: gallery_profile } = galleries ? galleries : {}

  // console.log('gallery_direct', gallery_direct)
  // console.log('gallery_profile', gallery_profile)

  const { storage_handlers } = handlers ? handlers : {}
  const { pathViews } = paps_state ? paps_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem } = item_state ? item_state : {}
  const { images, key: key_viewItem } = viewItem ? viewItem : {}
  const { showItemImage } = images ? images : {}

  const init_state = { pathViews, useClientProfileImage, appUserCollection }
  const [itemStorage_state, itemStorageDispatch] = useReducer(itemStorageReducer, itemStorageInitialState(init_state));
  const itemStorage_handlers = itemStorageHandlers(itemStorageDispatch, itemStorage_state)
  const { galleryResponse, updateStoragePath } = itemStorage_state ? itemStorage_state : {}

  useEffect(
    () => {
      if (showItemImage) {
        // let _galleryItems;
        // let's see if the image exists in the 'direct'
        const _galleryItems_d = gallery_direct && gallery_direct[key_viewItem] ? gallery_direct[key_viewItem] : {}
        const _galleryItems_p = gallery_profile && gallery_profile[key_viewItem] ? gallery_profile[key_viewItem] : {}
        const _galleryItems = { ..._galleryItems_d, ..._galleryItems_p }
        if (_galleryItems && Object.keys(_galleryItems).length > 0) {
          itemStorage_handlers.handleSet_existingGalleryItems(_galleryItems)
        } else {
          itemStorage_handlers.handleGet_itemGalleryItems(_storageSettings.galleryPaths.galleryDirect, key_viewItem)
        }
      }
    },
    // eslint-disable-next-line
    [showItemImage, pathViews, key_viewItem]
  )

  useEffect(
    () => {
      if (updateStoragePath && galleryResponse) {
        // update the global storage so we don't have to recall this !!!
        galleryResponse.slt = gEnums.storageLocationTypes.pageDirect
        storage_handlers.handleResponse_getAny(galleryResponse)
      }
    },
    // eslint-disable-next-line
    [updateStoragePath]
  )

  const providerContext = () => <ItemStorageContext.Provider value={{ itemStorage_state, itemStorage_handlers }}>
    {props.children}
  </ItemStorageContext.Provider>
  return providerContext()

}

export default ItemStorageProvider