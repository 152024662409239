import React from "react";
import FullPageWrapper from "../../wrappers/FullPageWrapper";

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, handleCancel: props.handleCancel };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { message, stack } = error ? error : {}
    const { componentStack } = errorInfo ? errorInfo : {}
    console.log('error', error)
    console.log('stack', stack)
    console.log('message', message)
    const stackTrace = errorInfo && errorInfo.componentStack.split('\n');
    let ul;
    if (stackTrace) {
      const lis = stackTrace.map((stack, index) => {
        if (index < 5) {
          return <li key={index}>{stack.trim()}</li>
        }
      })
      ul = <ul>{lis}</ul>
    }

    //log the error to an error reporting service  
    let _error = message
    _error += stack
    _error += componentStack
    this.setState({ message, error, componentStack, stackError: _error, ul })
    // errorService.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // return _error
      return <FullPageWrapper
        content={<div className="stack-error" style={{ overflowY: 'auto', height: '100%' }}>{this.state.ul}</div>}
        handleCancel={this.state.handleCancel}
        topperCaption={'Oops, something went wrong'}
        topperCaption2={this.state.message}
      ></FullPageWrapper>

    }
    return this.props.children;
  }
}

export default ErrorBoundary