const rts = {
  handleDataConstraintPreview: 'handleDataConstraintPreview',
  handleSettingsPreview: 'handleSettingsPreview',
  handleSettingsPreviewCancel: 'handleSettingsPreviewCancel',
}

export const previewReducer = (state, action) => {

  const { settingsPreviewHasChanged, previewStatus } = state
  const { type } = action

  switch (type) {

    case rts.handleDataConstraintPreview:
      const _previews = { ...state.previews }
      _previews[action.key] = action.currentData
      return { ...state, previews: _previews, previewStatus: previewStatus === 'a' ? 'b' : 'a' }

    case rts.handleSettingsPreviewCancel:
      return { ...state, global: null, page: null, settingsPreviewHasChanged: !settingsPreviewHasChanged }

    case rts.handleSettingsPreview:
      return { ...state, settings_preview: action.settings, settingsPreviewHasChanged: !settingsPreviewHasChanged }

    default:
      return { ...state }
  }
}

export const previewHandlers = (dispatch) => {
  return {
    handleDataConstraintPreview: (settings, key, currentData) => dispatch({ type: rts.handleDataConstraintPreview, settings, key, currentData }),
    handleSettingsPreview: (settings, isGlobal) => dispatch({ type: rts.handleSettingsPreview, settings, isGlobal }),
    handleSettingsPreviewCancel: (settings) => dispatch({ type: rts.handleSettingsPreviewCancel, settings }),
  }
}

export const previewInitialState = () => {
  return { previews: {} }
};