import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { List, Segment } from 'semantic-ui-react'
import { getFirstObject } from '../common/convert'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { pickAll } from '../common/filtering'
import FullPageWrapper from '../wrappers/FullPageWrapper'
import UiSearchFilter from '../pageItem/UiSearchFilter'
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper'

const WAIT_INTERVAL = 500;

const SearchPage = (props) => {

  const { handleShowSearch, item_state, data_state } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { eventInfo_state } = states
  const { page_fns } = fns

  const { pushSimple } = page_fns ? page_fns : {}
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  const { viewListData: vld } = data_state ? data_state : {}
  const { viewItem } = item_state ? item_state : {}
  const { key: key_viewItem } = viewItem ? viewItem : {}

  const [searchValue, updateSearchValue] = useState()
  const [foundItems, setFoundItems] = useState(key_viewItem && vld ? { [key_viewItem]: { vld } } : null)

  // const handleOk = () => {    console.log('Searching...')  }

  const triggerChange = (value) => {
    const allMatches = {}
    if (key_viewItem && vld) {
      const x = pickAll(value, vld, ['name', 'lastName', 'firstName'])
      if (x && x.length > 0) {
        allMatches[key_viewItem] = x
      }
    } else {
      if (staticViews) {
        Object.keys(staticViews).forEach(key => {
          const sv = staticViews[key]
          const x = pickAll(value, sv, ['name', 'lastName', 'firstName'])
          if (x && x.length > 0) {
            allMatches[key] = x
          }
        })
      }
    }
    setFoundItems(allMatches)
  }

  const handleClick = (key, itemKey) => {
    handleShowSearch && handleShowSearch()
    pushSimple({ key: key }, itemKey)

  }
  const handleSearchChange = (value) => { updateSearchValue(value) }
  const searchHeader = () => <div className={'search-page'}><UiSearchFilter handleSearchChange={handleSearchChange} /></div>

  useEffect(() => {
    if (searchValue) {
      const timer = setTimeout(() => triggerChange(searchValue), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  const resultList = (key, items) => {
    const _items = _.sortBy(items, ['name', 'lastName', 'firstName'])
    const lis = _items.map(item => {
      const { firstName, lastName, name, _itemKey: itemKey } = item
      if (firstName && lastName) {
        return <List.Item onClick={(e) => { handleClick(key, itemKey) }}>
          <List.Content>{firstName}{' '}{lastName}</List.Content>
        </List.Item>
      } else {
        return <List.Item onClick={(e) => { handleClick(key, itemKey) }}>{name}</List.Item>
      }
    })
    return <List relaxed divided>{lis}</List>
  }

  const resultSeg = (key, items) => {
    if (vld) {
      return <Segment basic>{resultList(key, items)}</Segment>
    } else {
      return <Segment.Group>
        <Segment>{_.startCase(key)}</Segment>
        <Segment basic>{resultList(key, items)}</Segment>
      </Segment.Group>
    }
  }

  const searchResults = () => Object.keys(foundItems).map(key => {
    const item = foundItems[key]
    const { item: _item } = getFirstObject(item)
    return resultSeg(key, _item)
  })

  const content = () => <Wrapper
    header={searchHeader()}
    content={foundItems ? searchResults() : <div></div>}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const searchWizard = () => <FullPageWrapper
    content={content()}
    handleCancel={handleShowSearch}
    topperCaption={'Search'}
  />

  return searchWizard()
}

export default SearchPage