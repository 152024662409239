import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ammendDataWithStaticViewValues } from '../../common/convert';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { gEnums } from '../../enums/globalEnums';
import { getData } from '../../firestoreData/appData/getPageItemData';
import { getRefPropz, getStartEnd } from '../../firestoreData/helpers/getRefProps';
import { fsfn_createViewItemProps } from '../../functions/fbCreate';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import { dataHandlers, dataInitialState, dataReducer } from '../reducers/DataReducer';
import { googleModeTypes } from '../reducers/GoogleSheetsReducer';
import { getGoogleSheetsData } from './contextHelpers/googleSheetData';
import { postDataConstraits, postKeyConstraits } from './contextHelpers/postDataConstraits';
import { GoogleSheetsContext } from './GoogleSheetsContext';
import { PageDataContext } from './PageDataContext';
import { ParentContext } from './ParentContext';
import { ViewSettingsContext } from './ViewSettingsContext';

/** gets the data for the uiItem.
 @returns viewListData,  
 @returns confirmation, 
 @returns globalFiles 
*/
export const DataContext = createContext();

const DataProvider = (props) => {

  const {
    diKey,
    fieldProps,
    forAppViewer,
    isAppData,
    isSettingData,
    itemListData,
    uivi,
    updateProps,
  } = props

  let { itemProp } = fieldProps ? fieldProps : {}
  const { storageType } = itemProp ? itemProp : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, settings } = parentContext ? parentContext : {}
  const { appSettings_state, appUser_state, page_state, paps_state, eventInfo_state, preview_state, storage_state, transition_state } = states
  const { appUser_handlers, eventInfo_handlers } = handlers
  const { page_fns } = fns ? fns : {}
  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging } = homeSettings_global ? homeSettings_global : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems, aps_views, aps_page } = pageSettings ? pageSettings : {}
  const { dataOverrideOn, dataRefreshOn, dataSourceType } = appSettings_state ? appSettings_state : {}

  const { previews } = preview_state ? preview_state : {}
  const { dataConstraints: dataConstraints_pr } = previews ? previews : {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ? googleSheetsContext : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  // papsContext  
  const { otherView, view, viewKey, pageKey, showStatus } = paps_state ? paps_state : {}

  // transitionContext  
  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.data] : null
  const { showTransition } = transition ? transition : {}

  // storageContext 
  const { pageItemImages } = storage_state ? storage_state : {}
  const pageImages = pageItemImages && pageItemImages[view] ? pageItemImages[view] : {}

  // viewSettingsSwipeContext - THIS has all the info
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state } = viewSettingsContext ? viewSettingsContext : {}
  const { settingsStatus } = viewSettings_state ? viewSettings_state : {}

  // pageContext  
  const { dataOptions, appDataSource, projectOptions } = aps_global ? aps_global : {}
  const { appDataSourceType } = appDataSource ? appDataSource : {}
  const { onDemand, fetchExistingData, hideInactiveItems: hideInactiveItems_do } = dataOptions ? dataOptions : {}
  const { timeZone } = projectOptions ? projectOptions : {}
  const { menu, viewItems: viewItems_page } = aps_page ? aps_page : {}

  // pageDataContext
  const pageDataContext = useContext(PageDataContext);
  const { pageData_state } = pageDataContext ? pageDataContext : {}
  const { currentPageData } = pageData_state ? pageData_state : {}

  // eventInfoContext 
  const { staticViews, appData, appCollectionsData } = eventInfo_state ? eventInfo_state : {}

  // googleSheetsContext 
  const { googleModeType, googleSheetsData, staticViews: staticViews_gs, googlePreviewType } = googleSheets_state ? googleSheets_state : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ? dataManagementContext : {}
  const { viewItem_preview } = dataManagement_state ? dataManagement_state : {}

  const _appDataSourceType = dataSourceType ? dataSourceType : appDataSourceType

  // test for other settings
  let isCombinedData = false
  let cbnVis = {}
  let returnFsr;

  let viewItem_page = viewItems_page ? viewItems_page[uivi] : {}

  if (viewItem_preview) {
    viewItem_page = viewItem_preview
    returnFsr = true
  }

  const { combinedGroups, dataSource, dataConstraints, grouping } = viewItem_page ? viewItem_page : {}


  const _dataConstraints = dataConstraints ? dataConstraints : dataSource

  const { pageDataCollection } = dataSource ? dataSource : {}
  const { usePostDataConstraint, usePostAppUserPageConstraint, usePostAppUserDataConstraint, usePostPageConstraint } = _dataConstraints ? _dataConstraints : {}
  const _usePostConstraint = usePostDataConstraint || usePostAppUserPageConstraint || usePostAppUserDataConstraint || usePostPageConstraint

  const { fetchByAlphabet } = grouping ? grouping : {}

  let { dataSourceType: dataSourceType_page } = _dataConstraints ? _dataConstraints : {}

  if (combinedGroups) {
    cbnVis[viewItem_page.key] = viewItem_page
    Object.keys(combinedGroups).forEach(key => {
      cbnVis[key] = combinedGroups[key]
    })
    isCombinedData = true
  }

  let viewItem_global;
  if (aps_viewItems && aps_viewItems[uivi]) { viewItem_global = aps_viewItems[uivi] }
  const { dataConnection } = viewItem_global ? viewItem_global : {}
  const { dataSourceType: dataSourceType_viewItem, documentDependencies, altCollectionName } = dataConnection ? dataConnection : {}

  const getDataSourceType = () => {

    let _dst = dataSourceType_page ? dataSourceType_page : dataSourceType_viewItem

    if (!_dst) { _dst = _appDataSourceType }

    _dst = _appDataSourceType ? _appDataSourceType : _dst

    if (googleModeType === googleModeTypes.app || googleSheetsData) {
      _dst = gEnums.dataSourceTypes.googleSpreadSheet
    }

    if (googleModeType === googleModeTypes.sheets || googleSheetsData) {
      _dst = gEnums.dataSourceTypes.googleSpreadSheet
    }

    // if(_appDataSourceType == gEnums.dataSourceTypes.googleSpreadSheet)
    // // if (googleDataOn) {
    //   _dst = gEnums.dataSourceTypes.googleSheetsData
    // }
    return _dst
  }

  const _dataSourceType = getDataSourceType()

  let dataCollection = uivi
  let trueViewKey = viewKey

  if (altCollectionName) {
    dataCollection = altCollectionName
    trueViewKey = null
  }

  let delayData = onDemand ? true : false

  // IMPORTANT: Data - Get the data for the viewItem
  const dataInit_state = { diKey, isCombinedData, isSettingData, itemListData, uivi, updateProps, viewItem: viewItem_page, pageImages }
  const [data_state, data_dispatch] = useReducer(dataReducer, dataInitialState(dataInit_state));
  const data_handlers = dataHandlers(data_dispatch, data_state)

  const { alphaValue, alphabetValue, fetchData } = data_state ? data_state : {}

  if (onDemand && alphaValue) { delayData = false }

  let viewListData = (data_state && dataCollection && data_state[dataCollection]) ? data_state[dataCollection] : null

  if (showStatus && viewListData) { console.log('data_state ready', dataCollection, data_state) }

  const getGetProps = () => {

    const { dataOverrideOn } = appSettings_state ? appSettings_state : {}
    const { nonLandingView, pathViews } = paps_state ? paps_state : {}
    const { dataOptions, googleSheets } = aps_global ? aps_global : {}
    const { dataLimit, limitDevelopmentData, hideInactiveItems: hideInactiveItems_do } = dataOptions ? dataOptions : {}
    const { googleSheetsId } = googleSheets ? googleSheets : {}

    const propz = {
      stateProps: {
        appUserAccess,
        aps_viewItems,
        dataLimit,
        dataOverrideOn,
        googleSheetsId,
        hideInactiveItems_do,
        limitDevelopmentData,
        nonLandingView,
        pathViews,
        staticViews,
        timeZone,
      },
      alphabetValue,
      alphaItem: alphaValue,
      currentPageData,
      isAppData,
      logging,
      page_fns,
      singleDirect: false,
      storageType,
      trueViewKey,
      uivi: dataCollection,
      view,
      viewItem_global,
      viewItem: viewItem_page,
      viewKey,
      dataConstraints_pr,
    }

    return getRefPropz(propz, menu, data_handlers)
  }

  const _dataResultProps = { name: dataCollection, singleDataItem: false }

  /**
   * Handlest the returned data
   * @param {object} dataValue 
   * @param {object} dataResultProps 
   * @param {object} fsr 
   */
  const callback_loadData = (dataValue, dataResultProps, fsr) => {
    if (viewItem_page) {
      if (_appDataSourceType === gEnums.dataSourceTypes.googleSpreadSheet) {
        ammendDataWithStaticViewValues(staticViews_gs, dataValue)
      } else {
        ammendDataWithStaticViewValues(staticViews, dataValue)
      }
    }
    const { whereOpts } = fsr ? fsr : {}
    const { posts: wherez, dataKeys } = whereOpts ? whereOpts : {}

    // the postDataConstraits are only filtered in certain cases 
    const _usePostConstraints = !dataOverrideOn && (_usePostConstraint || hideInactiveItems_do) && (wherez && wherez.length > 0)
    let { postData: _postData, excludedData } = _usePostConstraints ? postDataConstraits(fsr, dataValue) : { postData: dataValue }
    if (dataKeys && dataKeys.length > 0) { _postData = postKeyConstraits(fsr, _postData) }

    data_handlers.handleLoadData(_postData, dataResultProps, fsr, excludedData)

    const { name: dataCollectionName } = dataResultProps ? dataResultProps : {}
    const { dataSource, dataConstraints } = viewItem_page ? viewItem_page : {}
    const _dc = dataConstraints_pr ? dataConstraints_pr : dataConstraints
    const _dataConstraints = _dc ? _dc : dataSource
    const { includeInAppUserData } = _dataConstraints ? _dataConstraints : {}
    const { refKey } = fsr ? fsr : {}

    if (refKey) {
      eventInfo_handlers.handleAppData(refKey, dataValue)
    }

    // add this dataValue to the appUser page data
    if (includeInAppUserData && appUser_handlers && appUser_handlers.handleAppUserPageData) {
      appUser_handlers.handleAppUserPageData(dataCollectionName, dataValue)
    }
  }


  // get the data
  useEffect(
    () => {
      // pick the dataSourceType 

      switch (_dataSourceType) {
        case gEnums.dataSourceTypes.component:
          // dataValue, name, singleDataItem, dataRef, origin, dataId
          callback_loadData({}, _dataResultProps)
          break;

        case gEnums.dataSourceTypes.static:
          const staticViewItems = ammendStatic(staticViews, dataCollection)
          if (fetchByAlphabet) {
            let av = alphabetValue
            if (!av) { av = 65 }
            const se = getStartEnd(av)
            const filteredItems = _.filter(staticViewItems, function (o) { return o.name && o.name.startsWith(se.start); });
            callback_loadData(filteredItems, _dataResultProps)
          } else {
            callback_loadData(staticViewItems, _dataResultProps)
          }
          break;

        case gEnums.dataSourceTypes.googleSpreadSheet:
        case gEnums.dataSourceTypes.googleSheetsData:

          const { dProps, dataCaptionProps } = getGetProps()
          const { refProps } = dProps ? dProps : {}

          let { googleItemData, noGoogleSheet } = getGoogleSheetsData({ uivi, googleSheetsData, googleModeType, dataSourceType: _dataSourceType, view, viewKey, refProps })

          if (noGoogleSheet) {
            getData(dProps, dataCaptionProps, callback_loadData, currentPageData, returnFsr)
          } else {
            if (googleItemData) {
              if (trueViewKey && googleItemData[trueViewKey]) {
                callback_loadData({ [trueViewKey]: googleItemData[trueViewKey] }, _dataResultProps, refProps)
              } else {
                callback_loadData(googleItemData, _dataResultProps, refProps)
              }
            }
          }

          break;

        case gEnums.dataSourceTypes.pageDataCollection:
          const coll = pageDataCollection ? pageDataCollection : uivi
          if (currentPageData && coll && currentPageData[coll]) {
            const _cpd = currentPageData[coll]
            callback_loadData(_cpd, _dataResultProps)
          } else {
            callback_loadData({}, _dataResultProps)
          }
          break;

        default:
          if (dataCollection) {
            if (isAppData) { // APP DATA
              const staticViewItems = ammendStatic(staticViews, dataCollection)
              callback_loadData(staticViewItems, _dataResultProps)
            } else {
              if ((!delayData || fetchData) && otherView !== 'add') {
                const { dProps, dataCaptionProps } = getGetProps()
                const { refProps } = dProps ? dProps : {}
                const { refKey } = refProps ? refProps : {}

                if (documentDependencies && documentDependencies.includes(view)) {
                  const { singles } = pageData_state
                  const dd = getDependencyData(documentDependencies, singles, dataCollection, trueViewKey)
                  callback_loadData(dd, _dataResultProps)
                } else {
                  // if (currentPageViewListData && (pageDataKey === uivi)) {
                  //   loadDataCallback(currentPageViewListData, _dataResultProps)
                  // } else {
                  // if (appData && appData[_refPath]) {
                  //   console.log('appData', _refPath, appData)
                  // }
                  if (!viewKey && appCollectionsData && appCollectionsData[uivi]) {
                    console.log('c')
                    callback_loadData(appCollectionsData[uivi], _dataResultProps)
                  } else if (viewKey && appCollectionsData && appCollectionsData[uivi] && appCollectionsData[uivi][viewKey]) {
                    console.log('ci')
                    callback_loadData(appCollectionsData[uivi], _dataResultProps)
                  } else if (fetchExistingData && appData && appData[refKey]) {
                    // console.log('data', refKey, appData[refKey])
                    callback_loadData(appData[refKey], _dataResultProps)
                  } else {
                    // console.log('get', refKey, appData[refKey])
                    // gd(dProps, dataCaptionProps, loadDataCallback, currentPageData, returnFsr)
                    getData(dProps, dataCaptionProps, callback_loadData, currentPageData, returnFsr, dataConstraints_pr)
                  }


                  // }
                }
              } else {
                callback_loadData({}, _dataResultProps)
              }
            }
          }
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [
    _appDataSourceType,
    _appDataSourceType,
    alphabetValue,
    appUserAccess,
    dataConstraints_pr,
    dataOverrideOn,
    dataRefreshOn,
    googlePreviewType,
    pageKey,
    settingsStatus,
    viewItem_page,
  ])

  useEffect(
    () => {
      if (viewListData && viewItem_global && viewItem_global.props) {
        const prs = { ...viewItem_global.props }
        const prks = Object.keys(prs)
        const newProps = getNewPropsFromData(viewListData, prks)
        if (newProps && Object.keys(newProps).length > 0) {
          fsfn_createViewItemProps(newProps, prs, 'details').then(() => {
            viewItem_global.props = prs
            getNewPropsToData(dataCollection, viewItem_global, aps_views)
          })
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [viewListData]
  )

  const viewDataProviderContext = () => <DataContext.Provider value={{ data_state, data_handlers }}>
    {props.children}
  </DataContext.Provider>

  const itemDataProviderContext = () => <DataContext.Provider value={{ data_state, data_handlers }}>
    {props.children}
  </DataContext.Provider>

  const content = () => {
    if (viewItem_page && viewListData) {
      return viewDataProviderContext()
    } else if (itemListData) {
      return itemDataProviderContext()
    } else {
      if (forAppViewer && viewListData) {
        return viewDataProviderContext()
      } else {
        return <div></div>
      }
    }
  }

  if (showTransition) {
    return <MotionComponent transition={transition}>
      {content()}
    </MotionComponent>
  } else {
    return content()
  }
}

export default DataProvider

const ammendStatic = (staticViews, uivi) => {
  const vitStaticData = staticViews[uivi]
  if (vitStaticData) {
    Object.keys(vitStaticData).forEach(key => {
      if (_.isObject(vitStaticData[key])) {
        vitStaticData[key].id = key
        vitStaticData[key]._itemKey = key
      }
    })
    delete vitStaticData.id
    delete vitStaticData.uid
  }

  return vitStaticData
}

const getNewPropsFromData = (viewListData, propKeys) => {
  const newPropKeys = {}
  if (viewListData) {
    Object.keys(viewListData).forEach(vldKey => {
      const vks = Object.keys(viewListData[vldKey])
      vks.forEach(vk => {
        if (!propKeys.includes(vk) && !newPropKeys[vk]) {
          switch (vk) {
            case 'parentKeys':
            case 'id':
            case 'uid':
            case '_gs':
              // ignore
              break;
            default:
              newPropKeys[vk] = {}
          }
        }
      })
    })
  }
  return newPropKeys
}

const getNewPropsToData = (uivi, viewItem_global, aps_views) => {
  const globalViPropKeys = Object.keys(viewItem_global.props)
  // loop the views
  Object.keys(aps_views).forEach(viewKey => {
    const view = aps_views[viewKey]
    if (view && view.viewItems) {
      Object.keys(view.viewItems).forEach(vk => {
        if (vk === uivi) {
          if (view.viewItems[vk].props) {
            const viewPropKeys = Object.keys(view.viewItems[vk].props)
            globalViPropKeys.forEach(gpk => {
              if (!viewPropKeys.includes(gpk)) {
                // add prop
                switch (vk) {
                  case 'parentKeys':
                  case 'id':
                  case 'uid':
                  case '_gs':
                    // ignore
                    break;
                  default:
                    view.viewItems[vk].props[gpk] = viewItem_global.props[gpk]
                }
              }
            })
          }
        }
      })
    }
  })
}

const getDependencyData = (documentDependencies, singles, uivi, viewKey) => {
  let d;
  documentDependencies.forEach(dd => {
    if (singles[dd]) {
      d = singles[dd]
    } else if (d && d[dd] && d[dd][viewKey]) {
      d = d[dd][viewKey]
    }
  })
  d = d ? d[uivi] : null
  return d
}

