import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { gEnums } from '../../enums/globalEnums';
import { swiperHandlers, swiperInitialState, swiperReducer } from '../reducers/SwiperReducer';
import { FilterContext } from './FilterContext';
import { FrameworkContext } from './FrameworkContent';
import { PageAreaContext } from './PageAreaContext';
import { ParentContext } from './ParentContext';
import { PreviewContext } from './PreviewContext';
import { UiItemContext } from './UiItemContext';

/** Returns pageItems in a swipable format depending on the `swipeContentType`
 * @swipeContentTypes (pageAreaContent, groupedItems, normalItems)
 * @pageAreaContent pageItemsShown
 * @groupedItems groupDataProps
 * @normalItems swipeItems
 */
export const SwiperContext = createContext();

/** Returns pageItems in a swipable format depending on the `swipeContentType`
 * @swipeContentTypes (pageAreaContent, groupedItems, normalItems)
 * @pageAreaContent pageItemsShown
 * @groupedItems groupDataProps
 * @normalItems swipeItems
 */
const SwiperProvider = (props) => {

  const { swipeItems, swipeContentType, fn_getContent } = props

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { page_state, paps_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { formatting, projectOptions } = aps_global ? aps_global : {}

  // papsContext 
  const { view, viewKey } = paps_state

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  // previewContext
  const previewContext = useContext(PreviewContext)
  const { preview_state } = previewContext ? previewContext : {}
  const { settingsPreviewHasChanged } = preview_state ? preview_state : {}

  // pageAreaContext
  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ? pageAreaContext : {}
  const { pageItemsShown, profileItems } = pageArea_state ? pageArea_state : {}

  const uiItemContext = useContext(UiItemContext)
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem_key } = item_state ? item_state : {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_state } = filterContext ? filterContext : {}
  const { groupDataProps, viewItem } = uiFilter_state ? uiFilter_state : {}
  const { display, grouping: grouping_viewItem } = viewItem ? viewItem : {}
  const { defaultToToday, groupType } = grouping_viewItem ? grouping_viewItem : {}
  const { defaultTab } = groupDataProps ? groupDataProps : {}
  const { showTabCount } = display ? display : {}

  const _viewItem = viewItem_key ? viewItem_key : view

  const getInitInfo = () => {

    let currentSwipeItems;

    switch (swipeContentType) {
      case gEnums.swipeContentTypes.pageAreaContent:
        currentSwipeItems = pageItemsShown
        break;
      case gEnums.swipeContentTypes.groupedItems:
        currentSwipeItems = groupDataProps
        break;
      case gEnums.swipeContentTypes.normalItems:
        currentSwipeItems = swipeItems
        break;
      default:
    }

    const isBack = window.localStorage.getItem('isBack')
    let initTabIndex = 0
    let _initTabIndex = 0

    switch (swipeContentType) {
      case gEnums.swipeContentTypes.groupedItems:
        _initTabIndex = window.localStorage.getItem('tab-container-' + _viewItem)
        break;
      default:
        _initTabIndex = window.localStorage.getItem('tab-' + _viewItem)
    }

    if (isBack) {
      let initIndex;
      switch (swipeContentType) {
        case gEnums.swipeContentTypes.groupedItems:
          initIndex = window.localStorage.getItem('tab-container-' + _viewItem)
          break;
        default:
          initIndex = window.localStorage.getItem('tab-' + _viewItem)
      }
      initTabIndex = initIndex ? initIndex : 0
    } else {
      // initTabIndex = _initTabIndex ? _initTabIndex : 0
    }

    if (defaultToToday && defaultTab && defaultTab.value && currentSwipeItems && currentSwipeItems.groups) {
      const dt = _.findKey(currentSwipeItems.groups, function (i) { return (i.key && i.key === defaultTab.value); });
      if (dt) { initTabIndex = parseInt(dt) }
    }
    return { initTabIndex, currentSwipeItems }
  }

  // local state
  const init_state = { desktopMode, formatting, projectOptions, groupType, fn_getContent }
  const [swiper_state, swiper_dispatch] = useReducer(swiperReducer, swiperInitialState(init_state));
  const swiper_handlers = swiperHandlers(swiper_dispatch, swiper_state)
  const { swiperContent } = swiper_state ? swiper_state : {}

  useEffect(() => {
    const { initTabIndex, currentSwipeItems } = getInitInfo()
    const gscProps = { swipeContentType, tabIndex: initTabIndex ? initTabIndex : 0, view, _viewItem, currentSwipeItems, profileItems, showTabCount, group_viewItem: grouping_viewItem }
    swiper_handlers.handleSwiperContentInit(gscProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [grouping_viewItem, groupDataProps, swipeItems, pageItemsShown, settingsPreviewHasChanged, swipeContentType, view, viewKey, viewItem_key]
  );

  if (swiperContent) {
    return <SwiperContext.Provider value={{ ...props, swiper_state, swiper_handlers, swiper_fns: { fn_getContent } }}>
      {props.children}
    </SwiperContext.Provider>
  }

  return <div></div>

};

export default SwiperProvider