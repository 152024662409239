import React, { createContext, useContext, useEffect, useReducer } from "react";
import { SportsSeasonContext } from "./SportsSeasonContext";
import { seasonMatchesHandlers, seasonMatchesInitialState, seasonMatchesReducer } from "../reducers/SeasonMatchesReducer";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";

export const SeasonMatchesContext = createContext()

export default function SeasonMatchesProvider(props) {

  const {
    commonMatches,
    latestMatchesCount,
    forBackup,
    latestMatchesOnly,
    nextMatchOnly,
    previewMatches,
    teamKey,
  } = props

  const {
    currentLevel,
    dateMatches,
    dateMatchResults,
    handleMatchClick,
    isTournament,
    levelDateGroup,
    levelDateKey,
    poolMatches,
    pools,
    roundKey,
    sepBySection,
    viewItem,
  } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { paps_state, appUser_state } = states
  const { page_fns } = fns
  const { pushSimple } = page_fns ? page_fns : {}

  const { pathViews } = paps_state ? paps_state : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const { profileData } = appUser ? appUser : {}
  const { pageFavs } = profileData ? profileData : {}
  const { teams: team_favs } = pageFavs ? pageFavs : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers, sportsSeason_fns } = sportsSeasonContext ? sportsSeasonContext : {}
  const { sportDistricts, seasonal_info, timestamp, currents, inverted, teams_info, matches_info, districts_info, seasonMatches_info, matchScheduleDisplayType, useAppDarkMode } = sportsSeason_state ? sportsSeason_state : {}
  const { organization: districts_org } = districts_info ? districts_info : {}
  const { sportLevels } = seasonal_info ? seasonal_info : {}
  const { sectionKeys } = teams_info ? teams_info : {}

  const _matches_info = forBackup && seasonMatches_info ? seasonMatches_info : matches_info

  const _teamKey = teamKey ? teamKey : pathViews.teams

  const scheduleTypes = {
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: teamKey || (pathViews && pathViews.teams) ? true : false,
    common: commonMatches ? true : false
  }

  const { matchesAll } = _matches_info ? _matches_info : {}

  let _sepBySection = true
  if (scheduleTypes.team || scheduleTypes.match || scheduleTypes.common) { _sepBySection = false }

  // sidebar
  const init = {
    _teamKey,
    commonMatches,
    latestMatchesCount,
    latestMatchesOnly,
    matchesInfo: _matches_info,
    nextMatchOnly,
    pathViews,
    previewMatches,
    pushSimple,
    scheduleTypes,
    sepBySection: _sepBySection,
    sportsSeason_fns,
    // poolMatches,
    // pools,
  }
  const [seasonMatches_state, seasonMatches_dispatch] = useReducer(seasonMatchesReducer, seasonMatchesInitialState(init));
  const seasonMatches_handlers = seasonMatchesHandlers(seasonMatches_dispatch)

  // update the scheduledMatches
  useEffect(() => {
    seasonMatches_handlers.handleMatchesInit(matchesAll)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, matchesAll]);

  useEffect(() => {
    seasonMatches_handlers.handlePlayoffMatchesInit(pools, poolMatches)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, poolMatches, pools]);

  return <SeasonMatchesContext.Provider
    value={{ seasonMatches_state, seasonMatches_handlers }}>
    {props.children}
  </SeasonMatchesContext.Provider>
}