import _ from 'lodash';
import { isMeProd } from '../../../project/fbConfiguration';
import { createResponse } from '../../altFunctions/common';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { createFsDocKey } from '../../firestoreData/appData/fsAppData';
import { fs_set_doc } from '../../firestoreData/appData/fsData';
import { createSimpleEvent } from "../../firestoreData/events/createSimpleEvent";
import { getBaseRef } from '../../firestoreData/helpers/getBaseRef';
import { fsfn_createClientInDatabase, fsfn_createClientProjectSettings, fsfn_createEventInDatabase } from '../../functions/fbCreate';
import { getStartAddMode } from '../../viewSettings/create/createSubs/addDropdownOptions';
import { vsComponentTypes } from '../../viewSettings/enums/vsComponentTypes';
import { sendConfirmationToMe } from "../../viewSettings/fetching/fetching";
import { settingsAdd } from '../../viewSettings/helpers/addSettingsReducerHelper';
import { handleAnyPaste, handleDataTablePaste } from '../../viewSettings/importing/pasteHelpers';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';
import { fetchHandlers, fetchReducers, frts } from './reducerHelpers/fetchProps';

export const createModes = {
  create: 'create',
  update: 'update'
}

export const viTypes = {
  dataCollections: 'dataCollections',
  dataLists: 'dataLists',
  forms: 'forms',
  props: 'props',
  propSections: 'propSections',
  rssFeeds: 'rssFeeds',
  globalPageItems: 'globalPageItems',
  viewItems: 'viewItems',
  views: 'views',
}

export const createTypes = {
  createClient: 'createClient',
  createEvent: 'createEvent',
  updateClient: 'updateClient',
  updateEvent: 'updateEvent',
  recreateDataAndSettings: 'recreateDataAndSettings',
  recreateDataCollection: 'recreateDataCollection',
  recreateDataCollections: 'recreateDataCollections',
  recreateSettings: 'recreateSettings',
  updateSettings: 'updateSettings',
  updateDataCollection: 'updateDataCollection',
  updateDataCollections: 'updateDataCollections',
}

const rts = {
  handleAmmend_settingsTemp: 'handleAmmend_settingsTemp',
  handleClickCheck: 'handleClickCheck',
  handleCommitCreateConfirmation: 'handleCommitCreateConfirmation',
  handleConfirmClose: 'handleConfirmClose',
  handleCreate_addData: 'handleCreate_addData',
  handleCreate_clientOrEventInDb: 'handleCreate_clientOrEventInDb',
  handleCreate_clientProjectSettings: 'handleCreate_clientProjectSettings',
  handleCreate_eventFromFetch: 'handleCreate_eventFromFetch',
  handleCreate_fromAppVis: 'handleCreate_fromAppVis',
  handleDTPaste: 'handleDTPaste',
  handleError: 'handleError',
  handleFormDataChange_create: 'handleFormDataChange_create',
  handleJsonPaste: 'handleJsonPaste',
  handlePaste: 'handlePaste',
  handleResponse_create: 'handleResponse_create',
  handleResponse_createInfoCreated: 'handleResponse_createInfoCreated',
  handleResponse_settingsAdded: 'handleResponse_settingsAdded',
  handleSelect_dataItem: 'handleSelect_dataItem',
  handleSetMapList: 'handleSetMapList',
  handleSettingsViewPick: 'handleSettingsViewPick',
  handleTabChange: 'handleTabChange',
  handleUpdate_createInfo: 'handleUpdate_createInfo',
  handleUpdate_projectResult: 'handleUpdate_projectResult',
  noClientMobileKey: 'noClientMobileKey',
  setAddMode: 'setAddMode',
  setDataItems: 'setDataItems',
  setEventInfo: 'setEventInfo',
  setUpdateInfo: 'setUpdateInfo',
  ...frts,
  ...grts,
}

export const createResponseTypes = {
  catchError: 'catchError',
  clientAlreadyExists: 'clientAlreadyExists',
  clientNotFound: 'clientNotFound',
  createClientFailed: 'createClientFailed',
  createClientSuccess: 'createClientSuccess',
  createEventFailed: 'createEventFailed',
  createEventSuccess: 'createEventSuccess',
  eventAlreadyExists: 'eventAlreadyExists',
  invalidMode: 'invalidMode',
  noClient: 'noClient',
  noClientMobileKey: 'noClientMobileKey',
  noEvent: 'noEvent',
  noUpdateCode: 'noUpdateCode',
  handleAmmend_settingsTemp: 'handleAmmend_settingsTemp',
}

const fetchOriginTypes = {
  feed: 'feed',
  googleSheet: 'googleSheet',
  ivs: 'ivs',
  url: 'url',
}

export const createReducer = (state, action) => {

  const _amts = gEnums.addModeTypes

  const {
    addModeType: addModeType_state,
    aps_viewItems,
    createInfo,
    createOrigin,
    createType,
    databaseProjectId,
    dataTableProps,
    fetchOrigin,
    formData,
    initViType,
    isGlobal,
    logging,
    navigate,
    newValue,
    page_fns,
    page_state,
    paps_state,
    selectedItems,
    settingKey,
    settings_temp_global,
    settings_temp,
    swipedItem,
    swipeItemPaths,
    swipeToSelected,
    viewItem,
    viewSettings_handlers,
  } = state

  const { dispatch, response_data, eventInfo, type, mode, addModeType, pureEi, updateInfo, clientMobileKey, item, value } = action
  const { dataTableItems, noData, viType, importType } = action
  let { info } = state

  const vit = swipeItemPaths ? swipeItemPaths['vit'] : null

  const { result: data_result } = response_data ? response_data : {}

  const appArea = createType === createTypes.createEvent ? 'event' : 'client'
  let vis = aps_viewItems

  const { pathViews } = paps_state ? paps_state : {}
  const { clients: clientPathKey } = pathViews ? pathViews : {}

  const h = createHandlers(dispatch)

  const {
    handleAmmend_settingsTemp,
    handleCreate_addData,
    handleCreate_clientOrEventInDb,
    handleResponse_create,
    handleResponse_createInfoCreated,
    handleResponse_settingsAdded,
    handleStartUpdate,
  } = h

  switch (type) {

    case rts.setAddMode:

      const allowSaveDirect = getAllowDirect(settingKey, createOrigin, addModeType)

      return {
        ...state,
        createInfo: null,
        addModeType,
        allowSaveDirect,
        activeTabIndex: 0,
        selectedItems: {}
      }

    case rts.handleCommitCreateConfirmation:

      const { dataModify_handlers } = state

      // the createOrigin is passed into the createReducer via the CreateSomething component
      switch (createOrigin) {

        case gEnums.createOriginTypes.createSettings:
          handleCreate_addData()
          break;

        case gEnums.createOriginTypes.viewSettings:
          // IMPORTANT: Create Event Or Client
          handleCreate_clientOrEventInDb()
          break;

        case gEnums.createOriginTypes.createDataModify:
          const { selectedDataItems, dataItems } = state
          const { initProps } = createInfo ? createInfo : {}
          const { dataCollections } = initProps ? initProps : {}
          const sdis = {}
          if (dataItems) {
            selectedDataItems.forEach(sdi => {
              if (dataItems[sdi]) {
                sdis[sdi] = dataItems[sdi]
                delete sdis[sdi].uid
                delete sdis[sdi].id
                delete sdis[sdi].key
              }
            })
          }

          if (dataCollections) {
            if (viewItem && dataCollections[viewItem.key]) {
              const items = dataCollections[viewItem.key]
              selectedDataItems.forEach(sdi => {
                if (items[sdi]) {
                  sdis[sdi] = items[sdi]
                  delete sdis[sdi].uid
                  delete sdis[sdi].id
                  delete sdis[sdi].key
                }
              })
            }
          }
          dataModify_handlers.handleUpdateAppFirestore(sdis)
          break;

        default:
        // nothing
      }

      return { ...state, updating: false }

    case rts.handleCreate_addData:

      const addProps = {
        action,
        addForm,
        addModeType: addModeType ? addModeType : state.addModeType,
        dataTableItems,
        dataTableProps,
        formData,
        handleResponse_settingsAdded,
        importType,
        initViType,
        isGlobal,
        newValue,
        newViews: state.newViews,
        noData,
        page_fns,
        page_state,
        paps_state,
        selectedItems,
        settingKey,
        settings_temp_global,
        settings_temp,
        addModeType_state,
        state,
        swipedItem,
        swipeToSelected,
        viewSettings_handlers,
        vit,
        viType,
      }

      if (!state.updating) {
        // this will return the new state of the settings_temp or settings_temp_global
        settingsAdd(addProps).then(results => {
          const { settings_temp } = results
          handleAmmend_settingsTemp(settings_temp)
        })
      }
      return { ...state, updating: true }

    case rts.handleAmmend_settingsTemp:
      return {
        ...state,
        updating: false,
        settings_temp_updated: action.settings_temp,
        createStatus: state.createStatus >= 100 ? 0 : state.createStatus + 1,
      }

    // IMPORTANT: Add stuff
    case rts.handleResponse_settingsAdded:
      return { ...state, updating: false, settings_temp_added: action.settings_temp_added }

    case rts.handlePaste:

      const viItemKey = viewItem ? viewItem.key : null
      const res = handleAnyPaste(value)
      let ci;
      if (viItemKey) {
        ci = {
          initProps: { dataCollections: { [viItemKey]: res.itemData } }
        }
      } else {
        ci = { initProps: { dataCollections: res.itemData } }
      }

      switch (createOrigin) {
        case gEnums.createOriginTypes.createDataSettings:
          if (res.itemData) {
            const id = {}
            Object.keys(res.itemData).forEach(key => {
              id[key] = {
                text: key,
                value: key
              }
            })
          }
          break;
        default:
        // nothing
      }

      switch (viItemKey) {
        case 'schools':
          const schools = getSchools(pathViews, res.itemData)
          ci = {
            initProps: { dataCollections: { [viItemKey]: schools } }
          }
          return {
            ...state,
            createInfo: ci
          }
        case 'teams':
          const teams = getTeams(pathViews, res.itemData)
          ci = {
            initProps: { dataCollections: { [viItemKey]: teams } }
          }
          return {
            ...state,
            createInfo: ci
          }
        default:
          return {
            ...state,
            createInfo: ci
          }
      }

    case rts.handleDTPaste:
      const dtp = handleDataTablePaste(value)
      return { ...state, dataTableValue: value, dataTableProps: dtp.props, dataTableItems: dtp.items }

    // creates the client or event
    // IMPORTANT: Create Event or Client
    case rts.handleCreate_clientOrEventInDb:

      handleStartUpdate()

      if (!state.creating && createInfo) {

        const { projectData } = createInfo
        const { clientData, eventData } = projectData ? projectData : {}

        if (clientData) {
          if (clientData.client_mobile_key) {
            clientData.clientMobileKey = clientData.client_mobile_key
          } else {
            clientData.clientMobileKey = clientPathKey
          }
        }

        if (eventData && !eventData.name) {
          eventData.name = formData.eventName
          eventData.id = formData.eventName
        }

        if (eventData && !eventData.name && formData.name) {
          eventData.name = formData.name
          eventData.id = formData.name
        }

        switch (createType) {

          case createTypes.updateEvent:
            break;

          // IMPORTANT: Create Event
          case createTypes.createEvent:

            // there are 4 different ways to create...
            switch (state.addModeType) {

              // IMPORTANT: Create Event from `Event Key`
              case _amts.registrationKey:
                const { eventKey, eventId: eid } = formData
                const { clientMobileKey } = clientData ? clientData : {}
                const ek = eventKey ? eventKey : eid
                navigate.push('/createFeedEvent?clientId=' + clientMobileKey + '&eventId=' + ek + '&mode=create&testing=true')
                break;

              // IMPORTANT: Create Event - from `Select`
              case _amts.select:
                createSimpleEvent(handleResponse_create, paps_state, createInfo)
                break;

              // IMPORTANT: Create Event - from `subEvent`
              case _amts.subEvent:
                fsfn_createEventInDatabase(createInfo, false, logging, pathViews, databaseProjectId).then(response_data => {
                  handleResponse_create(response_data, createInfo)
                }).catch(err => {
                  handleResponse_create(createResponse(false, 'Not Successfull', createResponseTypes.catchError, err))
                })
                break;

              // IMPORTANT: Create Event - from `Google Sheets`
              case _amts.googleSheets:
                fsfn_createEventInDatabase(createInfo, false, logging, pathViews, databaseProjectId).then(response_data => {
                  handleResponse_create(response_data, createInfo)
                }).catch(err => {
                  handleResponse_create(createResponse(false, 'Not Successfull', createResponseTypes.catchError, err))
                })
                break;

              default:
                fsfn_createEventInDatabase(createInfo, false, logging, pathViews, databaseProjectId).then(response_data => {
                  handleResponse_create(response_data, createInfo)
                }).catch(err => {
                  handleResponse_create(createResponse(false, 'Not Successfull', createResponseTypes.catchError, err))
                })
            }
            break;

          // IM
          case createTypes.createClient:
            const d = { createInfo, forProd: isMeProd(), databaseProjectId }
            fsfn_createClientInDatabase(d).then(response_data => {
              const { success, error } = response_data
              dispatch({ type: success ? rts.updateSuccess : rts.updateError, error });
              // handleResponse_create(response_data, createInfo)
            }).catch(err => {
              dispatch({ type: rts.updateError, err });
              // handleResponse_create(createResponse(false, 'Not Successfull', createResponseTypes.catchError, err))
            })
            break;

          default:

        }
      }

      return {
        ...state,
        creating: true
      }

    case rts.handleFormDataChange_create:
      const { formDataInfo } = action
      let fd = { ...formDataInfo }
      return { ...state, formData: fd }

    case rts.setDataItems:
      return { ...state, dataItems: action.dataItems }

    case rts.setEventInfo:
      return { ...state, createInfo: pureEi }

    case rts.setUpdateInfo:
      return { ...state, updateInfo: updateInfo }

    case rts.handleCreate_fromAppVis:

      const x = {}
      if (formData.selectedViews) {
        formData.selectedViews.forEach(item => {
          if (vis && vis[item]) {
            x[item] = { ...vis[item] }
          }
        })

        if (!x.events) { x.events = {} }

        const data_client = { viewItems: x, settingsGlobal: { viewItems: aps_viewItems }, appArea, ignoreProps: true }

        fsfn_createClientProjectSettings(data_client, handleResponse_createInfoCreated)

        return { ...state, mode, fetchOrigin: fetchOriginTypes.ivs, updating: true, createInfo: null }

      } else {
        return { ...state }
      }

    case rts.handleCreate_clientProjectSettings:

      handleStartUpdate()

      const xx = {}

      if (formData.selectedViews) {
        formData.selectedViews.forEach(item => {
          xx[item] = {}
        })
        if (!xx.clients) { xx.clients = {} }
        if (!xx.events) { xx.events = {} }
      } else {
        if (!xx.clients) { xx.clients = {} }
        if (!xx.events) { xx.events = {} }
      }

      const eds = { viewItems: xx, settingsGlobal: { viewItems: aps_viewItems }, appArea, ignoreProps: true, clientName: action.data ? action.data.name : formData.name }
      fsfn_createClientProjectSettings(eds, handleResponse_createInfoCreated)

      return { ...state, mode, fetchOrigin: fetchOriginTypes.ivs, updating: true, createInfo: null }

    // handles the response from createEventFn
    case rts.handleResponse_createInfoCreated:

      const { projectData, projectSettings } = data_result ? data_result : {}
      const { clientData } = projectData ? projectData : {}
      const { views } = projectSettings ? projectSettings : {}
      const { clients } = views ? views : {}
      const { viewItems } = clients ? clients : {}
      const { events } = viewItems ? viewItems : {}

      if (events) {
        events.show = true
        events.showDesktop = true
      }

      if (viewSettings_handlers) {
        const { settings_temp, initProps } = data_result ? data_result : {}
        const { dataCollections, staticViews } = initProps ? initProps : {}

        console.log('handleResponse_createInfoCreated', 'settings_temp', settings_temp)
        settings_temp && viewSettings_handlers.updateViewSettingsTemp(settings_temp)
        dataCollections && viewSettings_handlers.handleUpdateDataCollections(dataCollections, staticViews)
      }

      switch (fetchOrigin) {
        case fetchOriginTypes.feed:
          const ei = { ...data_result }
          ei.mode = state.mode
          ei.addUser = true
          if (clientData && clientData.client_mobile_key) {
            clientData.clientMobileKey = clientData.client_mobile_key
          }
          break;
        default:
        // nothing
      }

      return {
        ...state,
        projectResult: data_result,
        createReady: data_result ? true : false,
        updating: false,
        createInfo: data_result,
      }

    case rts.handleUpdate_projectResult:
      return { ...state, projectResult: action.projectResult }

    case rts.handleUpdate_createInfo:
      return { ...state, createInfo: action.createInfo }

    // IMPORTANT: handleResponse_create
    case rts.handleResponse_create:

      const { success, result } = response_data ? response_data : {}

      if (success) {
        switch (createType) {
          case createTypes.createEvent:
            if (result.type === createResponseTypes.createEventSuccess) {
              if (info) {
                sendConfirmationToMe(info.eventId, true, false)
                info.clientMobileKey = clientMobileKey
              }
            }
            break;
          default:
          // nothing
        }

        return {
          ...state,
          confirmation: getCreateConfirmation(success, result.type, result),
          info,
          eventInfo,
          creating: false
        }
      } else {
        return {
          ...state,
          confirmation: getCreateConfirmation(success, result.type, result),
          creating: false
        }
      }

    case rts.handleClickCheck:
      const sis = { ...selectedItems }
      if (sis[item.key]) {
        delete sis[item.key]
        return {
          ...state,
          selectedItems: sis,
          newValue: sis
        }
      } else {
        sis[item.key] = item
        return {
          ...state,
          selectedItems: sis,
          newValue: sis
        }
      }

    case rts.handleSettingsViewPick:
      return {
        ...state,
        newViews: action.newViews,
      }

    case rts.handleSelect_dataItem:
      return {
        ...state,
        selectedDataItems: action.selected,
      }

    case rts.handleSetMapList:
      return {
        ...state,
        mapList: action.mapList,
      }

    case rts.handleTabChange:
      return {
        ...state,
        activeTabIndex: action.activeIndex,
      }

    case rts.handleConfirmClose:
      return {
        ...state,
        confirmation: null
      }

    case rts.handleFetch_googleSheet:
    case rts.handleFetch_url:
    case rts.handleFetch_urlFeed:
    case rts.handleFetch_urlFeedData:
    case rts.handleFetchResponse:
    case rts.handleCreateProject:
      if (!type.endsWith('Response')) { handleStartUpdate() }
      return fetchReducers(state, action, handleResponse_create, handleResponse_createInfoCreated)

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const createInitialState = (create_initState) => {

  const {
    createOrigin,
    createType,
    initInfo,
    parentContext,
    settingKey,
    swipeItemPaths,
    urlData,
    viewItem,
    viewSettingsComponentContext,
    viewSettingsContext,
    viType,
  } = create_initState

  // parentContext
  const { states, handlers, fns } = parentContext ? parentContext : {}
  const { page_state, paps_state } = states ? states : {}
  const { page_fns } = fns ? fns : {}
  const { navigate } = handlers ? handlers : {}

  // pageContext
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems } = pageSettings ? pageSettings : {}
  const { appSettings, googleSheetsImportOptions, logging } = aps_global ? aps_global : {}
  const { clientRegistrationUrl, useClientRegistrationUrl } = appSettings ? appSettings : {}

  const { global: global_home } = {} ? {} : {} //homeSettings ? homeSettings : {}
  const { globalAppSettings } = global_home ? global_home : {}
  const { registrationUrl, databaseProjectId } = globalAppSettings ? globalAppSettings : {}

  // viewSettingsContext
  const { viewSettings_state, viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}
  const { settings_temp } = viewSettings_state ? viewSettings_state : {}

  // viewSettingsComponentContext
  const { vsc_state, vsc_handlers } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { isGlobal, swipedItem } = vsc_state ? vsc_state : {}
  const { swipeToSelected } = vsc_handlers ? vsc_handlers : {}

  // dataModifyContext
  const { dataModify_handlers } = {} ? {} : {} //dataModifyContext ? dataModifyContext : {}

  const addModeType = getStartAddMode(isGlobal, createType, swipedItem)
  const allowSaveDirect = getAllowDirect(settingKey, createOrigin, addModeType)

  return {
    activeTabIndex: 0,
    addModeType,
    allowSaveDirect,
    // allVis,
    aps_viewItems,
    createInfo: null,
    createOrigin,
    createStatus: 0,
    createType,
    dataModify_handlers,
    databaseProjectId,
    formData: {},
    googleSheetsImportOptions,
    navigate,
    info: initInfo,
    initViType: viType,
    isGlobal,
    logging,
    page_state,
    paps_state,
    page_fns,
    regUrl: useClientRegistrationUrl && clientRegistrationUrl ? clientRegistrationUrl : registrationUrl,
    selectedDataItems: [],
    selectedItems: {},
    settingKey,
    settings_temp,
    swipedItem,
    swipeItemPaths,
    swipeToSelected,
    urlData: urlData ? urlData : { url: '' },
    viewItem,
    viewSettings_handlers,
    viewSettings_state,
  }
};

export const createHandlers = (dispatch) => {
  return {
    // handleUpdateItemData: (itemData) => { dispatch({ type: createReducerTypes.handleUpdateItemData, itemData }) },

    handleResponse_settingsAdded: (settings_temp) => { dispatch({ type: rts.handleResponse_settingsAdded, settings_temp_added: settings_temp }) },

    handleAmmend_settingsTemp: (settings_temp) => { dispatch({ type: rts.handleAmmend_settingsTemp, dispatch, settings_temp }) },
    handleClickCheck: (item) => { dispatch({ type: rts.handleClickCheck, item }) },
    handleCommitCreateConfirmation: () => { dispatch({ type: rts.handleCommitCreateConfirmation, dispatch }) },
    handleConfirmClose: () => { dispatch({ type: rts.handleConfirmClose, dispatch }) },
    handleCreate_addData: () => { dispatch({ type: rts.handleCreate_addData, dispatch }) },
    handleCreate_clientOrEventInDb: () => { dispatch({ type: rts.handleCreate_clientOrEventInDb, dispatch }) },
    handleCreate_clientProjectSettings: (e, data) => { dispatch({ type: rts.handleCreate_clientProjectSettings, dispatch, data }) },
    handleCreate_eventFromFetch: (mode, clientRegUrl, pathViews) => { dispatch({ type: rts.handleCreate_eventFromFetch, dispatch, mode, clientRegUrl, pathViews }) },
    handleCreate_fromAppVis: (e, data) => { dispatch({ type: rts.handleCreate_fromAppVis, dispatch, data }) },
    handleResponse_create: (response_data, eventInfo) => { dispatch({ type: rts.handleResponse_create, dispatch, response_data, eventInfo }) },
    handleCreateSimpleEvent: (createInfo) => { createSimpleEvent(dispatch, createInfo) },
    handleDTPaste: (value) => { dispatch({ type: rts.handleDTPaste, dispatch, value }) },
    handleError: (createError) => { dispatch({ type: rts.handleError, dispatch, createError }) },
    handleFormDataChange_create: (formDataInfo) => { dispatch({ type: rts.handleFormDataChange_create, dispatch, formDataInfo }) },
    handleJsonPaste: (json) => { dispatch({ type: rts.handleJsonPaste, dispatch, json }) },
    handlePaste: (e, data) => { dispatch({ type: rts.handlePaste, dispatch, value: data.value }) },
    handleResponse_createInfoCreated: (response_data) => { dispatch({ type: rts.handleResponse_createInfoCreated, dispatch, response_data }) },
    handleSelect_dataItem: (selected) => { dispatch({ type: rts.handleSelect_dataItem, dispatch, selected }) },
    handleSetMapList: (mapList) => { dispatch({ type: rts.handleSetMapList, dispatch, mapList }) },
    handleSettingsViewPick: (newViews) => { dispatch({ type: rts.handleSettingsViewPick, newViews }) },
    handleTabChange: (e, { activeIndex }) => { dispatch({ type: rts.handleTabChange, dispatch, activeIndex }) },
    handleUpdate_createInfo: (createInfo) => { dispatch({ type: rts.handleUpdate_createInfo, dispatch, createInfo }) },
    handleUpdate_projectResult: (projectResult) => { dispatch({ type: rts.handleUpdate_projectResult, dispatch, projectResult }) },
    setAddMode: (addModeType) => { dispatch({ type: rts.setAddMode, dispatch, addModeType }) },
    setDataItems: (dataItems) => { dispatch({ type: rts.setDataItems, dispatch, dataItems }) },
    ...fetchHandlers(dispatch),
    ...responseHandlers(dispatch)
  }
}

export const isCreateReady = (cr_state, data_modified_ready, mapList, selectedItems, selectedDataItems) => {

  const _amts = gEnums.addModeTypes

  const { allowSaveDirect, createOrigin, addModeType, settingKey, formData } = cr_state

  let directReady = false
  let commitIcon = 'save'

  if (allowSaveDirect) {

    switch (createOrigin) {
      case gEnums.createOriginTypes.createDataSettings:
        switch (addModeType) {
          case _amts.list:
            switch (settingKey) {
              case vsComponentTypes.dataLists:
                if (mapList && Object.keys(mapList).length > 0) { directReady = true }
                break;
              default:
              // nothing
            }
            break;
          case _amts.normal:
            directReady = data_modified_ready
            break;
          default:
          // nothing
        }
        break;

      case gEnums.createOriginTypes.createSettings:
        commitIcon = appIconTypes.add
        switch (addModeType) {
          case _amts.additionalPageItems:
          case _amts.existingItems:
          case _amts.googleSheets:
          case _amts.googleSheetsOnly:
          case _amts.pageItems:
          case _amts.pasteImport:
          case _amts.registrationKey:
          case _amts.views:
            if (selectedItems && Object.keys(selectedItems).length > 0) { directReady = true }
            break;

          case _amts.copyExisting:
            if (formData && formData.name && formData.copyFrom) { directReady = true }
            break;

          case _amts.input:
            if (formData && formData.name) { directReady = true }
            break;

          case _amts.listAdd:
            if (formData && formData.pageFields && formData.pageFields.length > 0) { directReady = true }
            break;

          case _amts.normal:
            directReady = data_modified_ready
            break;
          case _amts.select:
            break;
          case _amts.subEvent:
            break;
          case _amts.url:
            break;
          default:
            if (mapList || data_modified_ready) {
              directReady = true
            }
          // nothing
        }
        break;

      case gEnums.createOriginTypes.createDataModify:

        switch (addModeType) {
          case _amts.pasteImport:
          case _amts.googleSheets:
            if (selectedDataItems && selectedDataItems.length > 0) { directReady = true }
            break;
          case _amts.normal:
            directReady = data_modified_ready
            break;
          case _amts.copyExisting:
          case _amts.existingItems:
          case _amts.input:
          case _amts.pageItems:
          case _amts.registrationKey:
          case _amts.select:
          case _amts.subEvent:
          case _amts.url:
          case _amts.views:
            break;
          default:
            if (mapList || data_modified_ready) {
              directReady = true
            }
          // nothing
        }
        break;

      case gEnums.createOriginTypes.viewSettings:
        break;
      default:
      // nothing
    }
  }

  return { directReady, commitIcon }

}

const getAllowDirect = (settingKey, createOrigin, addModeType) => {

  const _amts = gEnums.addModeTypes

  let allowSaveDirect;
  switch (settingKey) {
    case vsComponentTypes.dataLists:
      allowSaveDirect = true
      break;
    default:
    // nothing
  }

  switch (createOrigin) {
    case gEnums.createOriginTypes.createDataModify:
    case gEnums.createOriginTypes.createSettings:
      switch (addModeType) {
        case _amts.googleSheets:
          allowSaveDirect = false
          break;
        default:
          allowSaveDirect = true
        // nothing
      }
      break;
    default:
    // nothing
  }
  return allowSaveDirect
}

const getCreateConfirmation = (success, type, result, urlData) => {

  const { message, error } = result ? result : {}

  switch (type) {

    case createResponseTypes.createClientSuccess:
      return {
        title: success ? 'Client created' : 'Client not created',
        success: true,
        content: success ? 'The data and settings for the Client have been created.' : 'The data and settings for the Client have NOT been created.'
      }

    case createResponseTypes.createClientFailed:
      return {
        title: 'Client NOT created',
        success: false,
        content: 'The data and settings for the Client have NOT been created.'
      }

    case createResponseTypes.createEventSuccess:
      return {
        title: success ? 'Event created' : 'Event not created',
        success: true,
        content: success ? 'The data and settings for the event have been created.' : 'The data and settings for the Client have NOT been created.'
      }

    case createResponseTypes.createEventFailed:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'The data and settings for the event have NOT been created.'
      }

    case createResponseTypes.noClient:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'Client not found!'
      }

    case createResponseTypes.noClientMobileKey:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'No client mobile key!'
      }

    case createResponseTypes.invalidMode:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'Invalid mode'
      }

    case createResponseTypes.clientAlreadyExists:
      return {
        title: 'Client NOT created',
        success: false,
        content: 'A Client with the same name already exists.'
      }

    case createResponseTypes.eventAlreadyExists:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'Client was found, but the event has already been created.'
      }

    case createResponseTypes.noUpdateCode:
      return {
        title: 'Event NOT created',
        success: false,
        content: 'All good but the update portion of the code has not been built!'
      }

    case createResponseTypes.handleFetchResponse:
      let content = message
      if (content && error) {
        const { message } = error
        if (message) { content = message }
      }
      return {
        title: 'Fetch Response',
        success: success,
        content: content
      }

    default:
      switch (type) {
        case rts.handleFetchResponse:
          const otherContent = success ? 'Successful' : 'Not Successful'
          let content = message ? message : otherContent
          if (urlData && urlData.url) (content += ' url:' + urlData.url)
          return {
            title: _.startCase('fetchResponse'),
            success: success,
            content: content
          }
        default:
          return {
            title: 'No rvType. Check settings',
            success: false,
            content: _.startCase(type)
          }

      }
  }
}

const addForm = (data, fit, paps_state, page_fns) => {
  try {
    const { getGviDeps } = page_fns ? page_fns : {}
    const { name, formData } = data
    const { view, pathViews } = paps_state
    const viDeps = getGviDeps(view)
    const baseRef = getBaseRef(viDeps, pathViews)
    const { refPath } = baseRef
    const _setRef = fit ? createRefPath(['forms', fit], refPath) : createRefPath(['forms', createFsDocKey('form')], refPath)
    fs_set_doc(_setRef, { name: name, formData: formData })
  } catch (error) {
    console.error(error)
  }
}

const getSchools = (pathViews, data) => {
  const s = {}
  Object.keys(data).forEach((schoolKey, index) => {
    const school = data[schoolKey]
    const parentKeys = {
      organizations: pathViews.organizations,
      districts: pathViews.districts
    }
    school.parentKeys = parentKeys
    Object.keys(school).forEach(k => {
      if (!school[k] || school[k] === "NULL") {
        delete school[k]
      }
    })
    delete school.sports
    delete school.key
    delete school.id
    s[index] = school
  })
  return s
}

const getTeams = (pathViews, data) => {
  const t = []
  Object.keys(data).forEach(schoolKey => {
    const parentKeys = {
      organizations: pathViews.organizations,
      districts: pathViews.districts
    }
    data[schoolKey].parentKeys = parentKeys
    const schoolSports = data[schoolKey].sports
    if (schoolSports) {
      Object.keys(schoolSports).forEach(sportsKey => {
        if (sportsKey === 'volleyball') {
          const sport = schoolSports[sportsKey]
          if (sport) {
            Object.keys(sport).forEach(spKey => {
              if (spKey === 'mens') {
                const sportItem = sport[spKey]
                if (sportItem) {
                  Object.keys(sportItem).forEach(spiKey => {
                    if (spiKey === 'varsity') {
                      const sportItemItem = sportItem[spiKey]
                      if (sportItemItem === true) {
                        t.push(
                          {
                            parentKeys: { ...parentKeys, sports: '8' },
                            sportsYears: '2021',
                            name: data[schoolKey].name,
                            seasons: 'Spring'
                          }
                        )
                      }
                    }
                  })
                }
              }
            })
          }
        }

      })
    }
  })
  return t
}