import React, { useContext, useEffect, useRef, useState } from 'react';
import { Image, Reveal } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import AnimateComponent, { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';

const Banner = () => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { page_state, transition_state } = states
  const { storage_fns } = fns

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const { themedItems, banner: app_banner } = aps_global ? aps_global : {}
  const { appBanner: appBannerTheme } = themedItems ? themedItems : {}
  const { apply, padding } = appBannerTheme ? appBannerTheme : {}

  // transitionContext  
  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.topBanner] : null
  const { showTransition } = transition ? transition : {}

  const { bannerType, banner, bannerDirectUrl, bannerSize, bannerHeight } = app_banner ? app_banner : {}

  const [topBanner, setTopBanner] = useState()

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.appBanner] : {}

  if (bannerHeight) { styleAndClass.style.height = bannerHeight }

  useEffect(() => {
    switch (bannerType) {
      case gEnums.bannerTypes.fromFile:
        if (banner) {
          storage_fns.getImageUrl(banner, true, gEnums.storageTypes.image, setTopBanner)
        }
        break;
      case gEnums.bannerTypes.directUrl:
        if (bannerDirectUrl) { setTopBanner({ url: bannerDirectUrl }) }
        break;
      default:
        setTopBanner()
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [bannerDirectUrl, bannerType]);

  if (apply && padding) { styleAndClass.style.padding = padding + 'em' }

  const bannerContainer = useRef(null);

  const bannerImage = () => {
    if (topBanner.url) {
      return <Image key={uniqueKey('sbr')} size={bannerSize ? bannerSize : 'large'} className={'banner-image'} centered src={topBanner.url} alt={''} />
    } else {
      return <Image key={uniqueKey('sbr')} size={bannerSize ? bannerSize : 'large'} className={'banner-image gen'} centered src={'/genericImage.png'} style={{ display: 'block' }} alt={'image'} />
    }
  }

  const settingsReveal = (content) => {
    return <Reveal animated='small fade'>
      <Reveal.Content visible>
        {content}
      </Reveal.Content>
      <Reveal.Content hidden>
        <div>Banner Settings</div>
      </Reveal.Content>
    </Reveal>
  }

  const useReveal = false

  const bannerWrap = () => {
    const b = <div {...styleAndClass} ref={bannerContainer}>{bannerImage()}</div>
    return useReveal ? settingsReveal(b) : b
  }

  if (topBanner) {
    if (showTransition) {
      return <MotionComponent transition={transition}>
        {bannerWrap()}
      </MotionComponent>
    } else {
      return bannerWrap()
    }
  } else {
    return <div></div>
  }
}

export default Banner