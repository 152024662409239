import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Checkbox, Form, Icon } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import JsonViewer from '../viewers/JsonViewer';
import { PageContext } from '../../cnr/contexts/PageContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import Wrapper from '../../wrappers/Wrapper';
import { pasteHandlers, pasteInitialState, pasteReducer } from '../../cnr/reducers/PasteReducer';
import { groupActionButton } from '../../viewSettings/actions/buttons/simpleActionButton';

const DataPaste = (props) => {

  const { tableRows, handleItemData } = props ? props : {}
  let { caption } = props

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ? papsContext : {}
  const { view } = paps_state ? paps_state : {}

  const pageContext = useContext(PageContext);

  // pasteReducer  
  const [paste_state, paste_dispatch] = useReducer(pasteReducer, pasteInitialState(paps_state, props.viewItem, props.viewListData, pageContext));
  const paste_handlers = pasteHandlers(paste_dispatch)
  const { pasteValue, itemData, allInfo, confirmation } = paste_state ? paste_state : {}

  const ready = itemData && Object.keys(itemData).length > 0 ? true : false

  const taRows = tableRows ? tableRows : 15

  if (!caption) { caption = 'Paste' }
  caption = _.startCase(caption)

  const [url, setUrl] = useState()
  const [confirmOn, setConfirmOn] = useState()
  const [toggleOn, setToggleOn] = useState(false)
  const [buttonClicked, setButtonClicked] = useState();
  const handleButtonClicked = (actionName) => { setButtonClicked(actionName) }

  const handleUrlSearch = (e, data) => { paste_handlers.handleGolfCourseSearch(url) }
  const handleUrlChange = (e, data) => { setUrl(data.value) }
  const handleToggle = () => { setToggleOn(!toggleOn) }
  const handlePaste = (e, { value }) => { paste_handlers.handlePaste(value) }
  const killConfirmation = () => { setConfirmOn(false) }

  const handleButtonConfirm = () => {
    switch (buttonClicked) {
      case 'updateFromPaste':
        paste_handlers.handleCommitData()
        break;
      case 'updateToSelectedPageFromPaste':
        paste_handlers.handleCommitData(view)
        break;
      default:
      // nothing
    }
  }

  const buttonHandlers = { handleButtonClicked, handleButtonConfirm }

  useEffect(() => {
    if (itemData) {
      handleItemData && handleItemData(itemData)
    }
    // eslint-disable-next-line
  }, [itemData]);

  useEffect(() => {
    if (confirmation) {
      setConfirmOn(true)
      const timer = setTimeout(() => killConfirmation(), confirmation.success === true ? 2000 : 3000);
      return () => { clearTimeout(timer) };
    }
    // eslint-disable-next-line
  }, [confirmation]);

  const input = () => <Form.Input
    icon={<Icon name='search' inverted circular link onClick={handleUrlSearch} />}
    value={url} label='Url' placeholder={'url'} onChange={handleUrlChange}
  />

  const pasteForm = () => <Form>
    {input()}
    <Form.TextArea value={pasteValue} className={'text-area-paste'} key={uniqueKey('dp', 'ta')} onChange={handlePaste} rows={taRows} placeholder='paste here...' />
  </Form>

  const pasteToggle = () => <div className={'paste-toggle'}>
    <Checkbox className={'check-toggle'} disabled={!ready} toggle checked={toggleOn} label={_.startCase('showResults')} onClick={() => handleToggle()} />
  </div>

  const content = () => <div className={'paste-container'}>
    {pasteToggle()}
    <div className={'paste-content'}>
      {!toggleOn && pasteForm()}
      {itemData && toggleOn && <JsonViewer json={allInfo} paste_handlers={paste_handlers} />}
    </div>
  </div>

  const updatePasteButton = () => groupActionButton('updateFromPaste', buttonHandlers, confirmation, buttonClicked, null, ready)
  const updatePasteToButton = () => groupActionButton('updateToSelectedPageFromPaste', buttonHandlers, confirmation, buttonClicked, null, ready)

  const footer = () => <React.Fragment>
    {updatePasteButton()}
    {updatePasteToButton()}
  </React.Fragment>

  return <div>
    <Wrapper
      header={'Paste Data'}
      content={content()}
      footer={footer()}
      css={{ header: 'padd5', footer: 'padd5 modify' }}
    />
  </div>
}

export default DataPaste