import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Label, List, Segment } from 'semantic-ui-react'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'
import { uniqueKey } from '../../common/keys'
import { getItemName } from '../../common/convert'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { appIconTypes } from '../../enums/appIconTypes'
import { fs_get_data } from '../../firestoreData/appData/fsAppData'
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths'
import { fs_update_doc } from '../../firestoreData/appData/fsData'
import { appFormTypes } from '../../enums/appFormTypes'
import UiSaveButtons from '../../components/buttons/UiSaveButtons'
import FullPageWrapper from '../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import NoData from '../alerts/NoData'
import AppForm from '../forms/appForms/AppForm'

const UserFamily = (props) => {

  const { fullPage } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { handlers, states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states ? states : {}
  const { navigate } = handlers

  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { appUserSession } = appUserAccess ? appUserAccess : {}
  const { _itemKey } = appUserSession ? appUserSession : {}

  const { pathViews } = paps_state

  const [showAdd, setShowAdd] = useState()
  const [familyMembers, setFamilyMembers] = useState()
  const [currentFamilyItem, setCurrentFamilyItem] = useState()

  const { familyMembers: _familyMembers } = familyMembers ? familyMembers : {}

  useEffect(() => {
    const cb = (res) => { setFamilyMembers(res) }
    const _refPath = createRefPath_event(pathViews, ['_family', _itemKey])
    fs_get_data({ refPath: _refPath, callback: cb, opts: { listen: true, returnFirstObject: true, ignoreId: true } })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleCancelForm = () => {
    setShowAdd()
    setCurrentFamilyItem()
  }

  const handleShowAdd = () => setShowAdd(!showAdd)
  const handleShowFamilyItem = (familyMember) => setCurrentFamilyItem(familyMember)

  const handleUpdate = (fms) => {
    const cb = () => handleCancelForm()
    const _refPath = createRefPath_event(pathViews, ['_family', _itemKey])
    const dtu = { familyMembers: fms }
    fs_update_doc(_refPath, dtu, cb)
  }

  const handleDelete = () => {
    const { firstName, lastName } = currentFamilyItem ? currentFamilyItem : {}
    const _fms = _familyMembers ? [..._familyMembers] : []
    const fmi = _.findIndex(_fms, function (f) {
      return (f.firstName === firstName || f.lastName === lastName);
    })
    _fms.splice(fmi, 1);
    handleUpdate(_fms)
  }

  const handleSave = (formData) => {
    const _fms = _familyMembers ? [..._familyMembers] : []
    _fms.push(formData)
    handleUpdate(_fms)
  }

  const handleCancel = () => props.handleCancel ? props.handleCancel() : navigate(-1)

  const form = () => <AppForm
    appFormType={appFormTypes.family}
    handleSave={handleSave}
    handleDelete={currentFamilyItem && handleDelete}
    handleCancel={handleCancelForm}
    parentData={currentFamilyItem}
  />

  const familyList = () => {
    const listItems = []
    if (_familyMembers) {
      _familyMembers.forEach((familyMember, index) => {
        const { _itemKey } = familyMember
        const name = getItemName(familyMember)
        listItems.push(<List.Item key={uniqueKey('aul', _itemKey)} onClick={(e) => { handleShowFamilyItem(familyMember) }}>
          <List.Content floated='left'>
            <Label color={'green'} size='tiny'>{index + 1}</Label>
          </List.Content>
          <List.Content>
            <List.Header>{name} </List.Header>
          </List.Content>
        </List.Item>)
      })
    }
    return listItems.length > 0 ? <Segment basic>
      <List divided relaxed>
        {listItems}
      </List>
    </Segment> :
      <NoData altCaption={'Family'} />
  }

  const footer_add = () => {
    const btns = [
      { caption: 'Add', oc: handleShowAdd, color: 'blue', icon: appIconTypes.add },
    ]
    return <UiSaveButtons others={btns} />
  }

  const wrapper_list = () => <Wrapper
    content={familyList()}
    footer={footer_add()}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>


  const fpw_add = () => <FullPageWrapper
    content={form()}
    handleCancel={handleCancelForm}
    topperCaption={'Family Member'}
  />

  const fpw_list = () => <FullPageWrapper
    content={wrapper_list()}
    handleCancel={handleCancel}
    topperCaption={'Family'}
  />

  if (fullPage) {
    if (showAdd || currentFamilyItem) {
      return fpw_add()
    } else {
      return fpw_list()
    }
  } else {
    if (showAdd || currentFamilyItem) {
      return fpw_add()
    } else {
      return wrapper_list()
    }
  }
}

export default UserFamily