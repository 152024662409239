import React, { useContext, useEffect, useState } from 'react'
import { Card, Icon } from 'semantic-ui-react'
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions'
import { ParentContext } from '../../../cnr/contexts/ParentContext'
import { UiItemContext } from '../../../cnr/contexts/UiItemContext'
import { uniqueKey } from '../../../common/keys'
import { gEnums } from '../../../enums/globalEnums'
import { arrayUpdateToDatabase } from '../../../firestoreData/updates/arrays/arrayUpdates'
import { getThemeColorDirect } from '../../../styles/formatting'

const CardLink = (props) => {

  const { appUserLink, indicator, cardKey, itemData } = props
  const { indicatorBackgroundColor, } = indicator ? indicator : {}

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states
  const { page_fns } = fns
  const { appUser } = appUser_state ? appUser_state : {}
  const { view, viewKey, pageSettings } = paps_state
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}

  const appUserAccess = getAppUserAccess(appUser)
  const { appUserSessionKey, appUserSession, pageFavs } = appUserAccess ? appUserAccess : {}
  const _color_bc = getThemeColorDirect(aps_global, indicatorBackgroundColor)

  const { allowFavorites, favoriteCollections } = aps_appUserSettings ? aps_appUserSettings : {}

  let actionItem;
  if (appUserLink && appUserLink[0]) {
    actionItem = appUserLink[0]
  }

  const { actionProps } = actionItem ? actionItem : {}
  const { appUserCollection } = actionProps ? actionProps : {}

  const itemData_appUserCollection = itemData && appUserCollection ? itemData[appUserCollection] : null

  const _checked = itemData_appUserCollection && itemData_appUserCollection.includes(appUserSessionKey) ? true : false

  // uiItemContext
  const uiItemContext = useContext(UiItemContext)
  const { item_handlers, item_state } = uiItemContext ? uiItemContext : {}

  const { viewItem_key } = item_state ? item_state : {}
  const { _itemKey } = itemData ? itemData : {}

  const isPageFav = pageFavs && pageFavs[viewItem_key] && pageFavs[viewItem_key][_itemKey] ? true : false

  const [hasCardLink, setHasCardLink] = useState()

  const handleToggle = (e, checked) => {
    e.stopPropagation()
    if (actionItem) {
      actionItem.actionProps.appUserAccess = appUserAccess
      const uProps = {
        actionItem,
        dataActionType: gEnums.itemLinkTypes.linkProfile,
        page_fns,
        paps_state,
        remove: checked
      }
      window.localStorage.setItem('isBack', true)
      arrayUpdateToDatabase(uProps)
      // actionItem.remove = checked
      // actionItem.actionProps.appUserAccess = appUserAccess
      // item_handlers.handleUpdateLinkItem(actionItem, itemData)
    }
  }

  useEffect(() => {
    appUserSession && setHasCardLink(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserLink, itemData]);

  const icon_link = () => <div key={uniqueKey('cp', 'ilnk', cardKey)} >
    <Icon
      key={uniqueKey('cl', 'icn', cardKey)}
      name={'user'}
      circular
      inverted
      color={_checked ? 'green' : 'grey'}
      onClick={(e) => handleToggle(e, _checked)}
    />
  </div>

  const icon_fav = () => <div key={uniqueKey('cp', 'ifav', cardKey)} >
    <Icon
      key={uniqueKey('cl', 'icn', cardKey)}
      name={'star'}
      circular
      inverted
      color={'green'}
    />
  </div>

  const icons = () => {
    const _icons = []
    if (isPageFav) { _icons.push(icon_fav()) }
    if (hasCardLink) { _icons.push(icon_link()) }
    return _icons
  }

  return hasCardLink ?
    <Card.Content key={uniqueKey('cl', cardKey)} className={'card-link'} extra style={{ backgroundColor: _color_bc ? _color_bc : 'white' }} >{icons()}</Card.Content>
    :
    <div></div>
}

export default CardLink 