import React, { useEffect, useState } from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';

const ServiceWorkerWrapper = () => {

  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  // if (isNewVersionAvailable) {
  //   console.log('isNewVersionAvailable', isNewVersionAvailable)
  // }

  useEffect(() => {
    const checkForNewVersion = async () => {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration && registration.waiting) {
        setIsNewVersionAvailable(true);
      }

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });
    };

    checkForNewVersion();
  }, []);

  const handleReload = () => {
    if (isNewVersionAvailable) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      });
    }
  };

  return (
    <>
      {isNewVersionAvailable && (
        <div className="new-version-alert">
          <Segment basic className="new-version-alert">
            <Button icon color='blue' onClick={() => handleReload()}>
              <Icon name={'refresh'} />
              {'new version is available'}
            </Button>
          </Segment>
        </div>
      )}
    </>
  );
};

export default ServiceWorkerWrapper;
