import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Label, Icon, Button } from 'semantic-ui-react';
import { uniqueKey } from '../../../../global/common/keys';
import NoData from '../../../../global/components/alerts/NoData';
import Checker from '../../../../global/components/forms/elements/Checker';
import { PapsContext } from '../../../../global/cnr/contexts/PapsContext';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { CreateSportsContext } from '../../create/CreateSports';

const _cns = {
  details: 'athlete-details',
  icon: 'athlete-icon',
  index: 'athlete-index',
  name: 'athlete-name',
  title: 'athlete-position',
  team: 'athlete-team',
}

const StaffList = (props) => {

  const { staff, forList, teamId, saTypes } = props

  const [currentStaff, setCurrentStaff] = useState()

  useEffect(() => {
    const _currentStaff = _.sortBy(staff, 'lastName')
    setCurrentStaff(_currentStaff)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const staffItem = (staffMember, index) => <StaffItem key={uniqueKey('pl', index)} {...props} staffMember={staffMember} index={index} />

  const staffList = () => {
    if (currentStaff && currentStaff.length > 0) {
      return currentStaff.map((staffMember, index) => (staffItem(staffMember, index)))
    } else {
      return <NoData altCaption={'Staff'} />
    }
  }

  let cn = 'athletes-container'

  const wrapper = () => <Wrapper
    content={staffList()}
    wrapperType={wrapperTypes.paddedHeader}
    css={{ content: cn }}
  ></Wrapper>

  return forList ? staffList() : wrapper()

}

export const StaffItem = (props) => {

  const { displayType, staffMember, handleClick } = props

  let cn = 'athlete'
  cn += ' ' + displayType

  const staffList = () => {
    //  verticalAlign='middle' 
    const { lastName, firstName, title, id, key } = staffMember
    let keyy = id ? id : key
    return <div className={cn} key={uniqueKey('pi', keyy)} onClick={(e) => { handleClick && handleClick(staffMember) }}>
      <div key={uniqueKey(keyy, 1)} className={_cns.icon}><Icon name='user' size='large' /></div>
      <div key={uniqueKey(keyy, 2)} className={_cns.details}>
        <div key={uniqueKey(keyy, 3)} className={_cns.name}>{firstName}{' '}{lastName}</div>
        <div key={uniqueKey(keyy, 5)} className={_cns.title}>{title}</div>
      </div>
    </div >
  }


  return staffList()

}

export default StaffList