import { deleteAppAdminProfileAndAuth } from "../../../auth/signInActions/deleteAppAdmin";
import { gEnums } from "../../../enums/globalEnums";
import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps";
import { createRefPath_event } from "../../appData/appRefPaths";
import { fs_delete_doc } from "../../appData/fsData";
import { deletePromise } from '../../promises/deletePromise';

// IMPORTANT: Delete document
/** Deletes a document or documents from a collection */
export const deleteData = (props) => {

  const { baseRef, ufProps, pathViews, dispatch } = props

  const { directCollection } = baseRef
  let { vit, itemData, viewKey, selectedItems, dataRestrictions } = ufProps ? ufProps : {}

  if (!viewKey && itemData && itemData.id) { viewKey = itemData.id }
  const coll = directCollection ? directCollection : vit

  const _refPath = createRefPath_event(pathViews, [coll, viewKey])

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.deleteData)) {
    console.log('ufProps', ufProps)
    console.log('_refPath', _refPath)
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  switch (vit) {
    case gEnums.profileSelectTypes.profiles:
      deleteAppAdminProfileAndAuth(dispatch, itemData, pathViews)
      break;

    default:
      // return false 
      if (selectedItems) {
        deletePromise(_refPath, vit, selectedItems).then(() => {
          dispatch({ type: dispatchProps.success, dispatch })
        }).catch(err => {
          dispatch({ type: dispatchProps.error, dispatch })
        });
      } else {
        fs_delete_doc(_refPath, dispatch)
      }
  }
}