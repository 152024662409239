import { addScaleCorrection, AnimatePresence } from 'framer-motion';
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import { addCssFile, addIndexHtmlLinks } from '../../links/AppLinks';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { RoutesContext } from '../cnr/contexts/RoutesContext';
import PageError, { pageErrorReasonTypes } from '../components/alerts/PageError';
import StartDimmer from '../components/alerts/StartDimmer';

/**
 * 
 * @param {object} props 
 * @returns a Route for the page
 */
const AppPage = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { manifest_state, page_state, storage_state, transition_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { transitions: transitionItems } = transition_state ? transition_state : {}
  const { page: trans_page } = transitionItems ? transitionItems : {}
  const { useTransitions: useTransitions_page } = trans_page ? trans_page : {}
  const _usePageTransitions = useTransitions_page

  // routesContext
  const routesContext = useContext(RoutesContext)
  const { routes_state } = routesContext ? routesContext : {}
  const { viewRoutes, genericRoutes } = routes_state ? routes_state : {}

  // storageContext 
  const { globalFiles } = storage_state ? storage_state : {}

  // pageContext 
  const { projectOptions } = aps_global ? aps_global : {}
  const { useEventViewRoutes } = projectOptions ? projectOptions : {}

  const _routes = useEventViewRoutes && 1 === 3 ? viewRoutes : genericRoutes

  useEffect(() => {
    addIndexHtmlLinks(aps_global, globalFiles, manifest_state)
    // if (useCssFile && cssFile) { addCssFile(cssFile) }
    addCssFile('CssTest.css')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return _routes ? <PageSwitch {...props} routes={_routes} usePageTransitions={_usePageTransitions} /> : <div></div>
}

/**
 * 
 * @param {object} props 
 * @returns a Route for the page
 */
const PageSwitch = (props) => {

  const { routes, usePageTransitions } = props ? props : {}
  const location = useLocation()

  const [trueLocation, setTrueLocation] = useState(location)

  useEffect(() => {
    setTrueLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  if (trueLocation) {
    if (usePageTransitions) {
      return (
        <AnimatePresence initial={false}>
          <Routes location={trueLocation} key={trueLocation.key}>
            {routes}
            <Route path="*" element={<PageError reason={pageErrorReasonTypes.noPage} />}></Route>
          </Routes>
        </AnimatePresence>
      )
    } else {
      return (
        <Routes location={trueLocation} key={trueLocation.key}>
          {routes}
          <Route path="*" element={<PageError reason={pageErrorReasonTypes.noPage} />}></Route>
        </Routes>
      )
    }
  }

  return <StartDimmer />

}

export default AppPage