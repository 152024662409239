import _ from 'lodash';
import React from 'react';
import { createItemKeys } from '../../../common/convert';
import { uniqueKey } from '../../../common/keys';
import { gEnums } from '../../../enums/globalEnums';
import { settingsIconTypes } from '../../../enums/settingsIconTypes';
import SettingsAreaGroupMenuItemElement from './SettingsAreaGroupMenuItemElement';

/**
 * 
 * @param {object} groupItems 
 * @param {object} settingsMenuContext 
 * @returns an ARRAY of menu.item ELEMENTS
 */
export const getSettingsAreaGroupMenuItemElements = (groupKey, groupItems, settingsMenuContext, settingsAreaContext) => {

  const _apits = gEnums.additionalPageItemTypes

  const { settingsArea_state } = settingsAreaContext ? settingsAreaContext : {}
  const { projectOptions, projectModules } = settingsArea_state ? settingsArea_state : {}

  // settingsMenu_state
  const { settingsMenu_fns } = settingsMenuContext ? settingsMenuContext : {}
  const { allowProjectOption } = settingsMenu_fns ? settingsMenu_fns : {}

  const _menuItems = []
  const _menuItemGroups = []

  // get the subMenus
  if (groupItems) {

    const groupItems_ammended = {}

    createItemKeys(groupItems, 'key')

    Object.keys(groupItems).forEach(key => {
      const groupItem = groupItems[key]
      const { gotoKey } = groupItem ? groupItem : {}
      groupItems_ammended[key] = {
        key,
        caption: _.startCase(key),
        icon: settingsIconTypes[key] ? settingsIconTypes[key] : 'cog',
        gotoKey
      }
      // }
    })

    const groupItems_sorted = _.sortBy(groupItems_ammended, ['key', '_position'])

    // loop the groupItems and create an element for each groupItem
    groupItems_sorted.forEach((groupItem, index) => {
      const { key } = groupItem

      if (key) {
        let allow = true

        if (projectOptions && _apits[key]) {
          allow = false // allowGroupProjectOptions ? allowGroupProjectOptions(key) : true
        }

        if (allow) {
          const element = <SettingsAreaGroupMenuItemElement key={uniqueKey('mig', 'gi', key)} groupItemKey={key} groupItem={groupItem} index={index} groupItemSettingz={groupItems_ammended} />
          _menuItemGroups[key] = element
          _menuItems.push(element)
        }
      }
    })
  }

  return { menuItemElements: _menuItems, menuItemElementGroups: _menuItemGroups }

}