import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext'
import MatchWrapper, { sportsMatchViewTypes } from './MatchWrapper'
import SwiperGroup from '../../../../global/components/swipers/SwiperGroup'

export const playoffBracketTypesByCount = (count) => {
  switch (count) {
    case 16:
      return playoffBracketTypes.roundOf32
    case 8:
      return playoffBracketTypes.roundOf16
    case 4:
      return playoffBracketTypes.quarterFinals
    case 2:
      return playoffBracketTypes.semiFinals
    case 1:
      return playoffBracketTypes.finals
    default:
      return playoffBracketTypes.roundOf16
  }
}

export const playoffBracketTypes = {
  finals: 'finals',
  quarterFinals: 'quarterFinals',
  roundOf16: 'roundOf16',
  roundOf32: 'roundOf32',
  semiFinals: 'semiFinals',
}

const bracketTypes = {
  startFull: 'startFull',
  startSolo: 'startSolo',
  solo: 'solo'
}

const useGrid = true

const PlayoffBracket = (props) => {

  const { pool } = props ? props : {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state } = seasonMatchesContext ? seasonMatchesContext : {}
  const { pools } = seasonMatches_state

  const [selectedBracket] = useState(bracketTypes.startSolo)

  const getMatch = (match) => {
    const { key, id, matchKey } = match
    const keyy = key ? key : id

    let matchCss = matchKey
    matchCss = matchCss.replace('playoffs', 'p')
    matchCss = matchCss.replace('roundOf16', 'r16')
    matchCss = matchCss.replace('quarterFinals', 'qf')
    matchCss = matchCss.replace('semiFinals', 'sf')
    matchCss = matchCss.replace('finals', 'f')

    const m = <MatchWrapper
      key={uniqueKey('mls', keyy)}
      sportsMatchViewType={sportsMatchViewTypes.singlePool}
      match={match}
    />
    return useGrid ? <div className={matchCss}>{m}</div> : <div className={matchCss}>{m}</div>
  }

  /**
   * 
   * @param {string} playoffBracketType 
   * @param {object} seeds 
   * @returns a group of matches base on the pool
   */
  const bracket = (playoffBracketType, seeds) => {
    let pool;
    pool = pools ? pools[playoffBracketType] : {}
    const { matches } = pool
    // eslint-disable-next-line 
    const bs = seeds ? seeds.map(seed => {
      const m = _.find(matches, function (m) { return ((m.home && m.home.seed === seed) || (m.pending && m.pending.home && m.pending.home.originalSeed === seed)); })
      if (m) {
        return getMatch(m)
      }
    }) : []
    return useGrid ? <Grid.Column>{bs}</Grid.Column> : bs
  }

  // const horizonalBracket = () => (
  //   <Grid columns={poolCount} divided className={'playoff-grid'}>
  //     <Grid.Row stretched>
  //       {roundColumns()}
  //     </Grid.Row>
  //   </Grid>
  // )

  // const fullBracket = () => {
  //   return <Grid columns={7} divided className={'playoff-grid'}>
  //     <Grid.Row stretched>
  //       {bracket([1, 16, 9, 8, 5, 12, 13, 4])}
  //       {bracket([1, 8, 5, 4])}
  //       {bracket([1, 4])}
  //       {bracket([1, 'C', 2])}
  //       {bracket([2, 3])}
  //       {bracket([2, 7, 6, 3])}
  //       {bracket([2, 15, 10, 7, 6, 11, 14, 3])}
  //     </Grid.Row>
  //   </Grid>
  // }

  // const fullMatchBracket = () => {
  //   return <div className={'grid-overflow'}>
  //     <Grid columns={6} divided className={'playoff-grid'}>
  //       <Grid.Row stretched>
  //         {bracket([1, 8, 5, 4])}
  //         {bracket([1, 4])}
  //         {bracket([1])}
  //         {bracket([2])}
  //         {bracket([2, 3])}
  //         {bracket([2, 7, 6, 3])}
  //       </Grid.Row>
  //     </Grid>
  //   </div>
  // }

  const headerCol = (playoffBracketType) => <Grid.Column key={uniqueKey('pb', playoffBracketType)}><Segment key={uniqueKey('pb', 'seg', playoffBracketType)}>{_.startCase(playoffBracketType)}</Segment></Grid.Column>

  const col32 = () => headerCol(playoffBracketTypes.roundOf32)
  const col16 = () => headerCol(playoffBracketTypes.roundOf16)
  const colQf = () => headerCol(playoffBracketTypes.quarterFinals)
  const colSf = () => headerCol(playoffBracketTypes.semiFinals)
  const colF = () => headerCol(playoffBracketTypes.finals)

  const r32_left = () => bracket(playoffBracketTypes.roundOf32, [1, 16, 9, 8, 5, 12, 13, 4])
  const r32_right = () => bracket(playoffBracketTypes.roundOf32, [2, 15, 10, 7, 6, 11, 14, 3])

  const r16_left = () => bracket(playoffBracketTypes.roundOf16, [1, 8, 5, 4])
  const r16_right = () => bracket(playoffBracketTypes.roundOf16, [2, 7, 6, 3])

  const quarter_left = () => bracket(playoffBracketTypes.quarterFinals, [1, 4])
  const quarter_right = () => bracket(playoffBracketTypes.quarterFinals, [2, 3])

  const semi_left = () => bracket(playoffBracketTypes.semiFinals, [1])
  const semi_right = () => bracket(playoffBracketTypes.semiFinals, [2])

  const finals = () => bracket(playoffBracketTypes.finals, [1, 3])

  const headerCols_full = () => {
    const cols = []
    switch (pool.poolKey) {
      case playoffBracketTypes.roundOf32:
        cols.push(col32())
        cols.push(col32())
        break;

      case playoffBracketTypes.roundOf16:
        cols.push(col16())
        cols.push(col16())
        break;

      case playoffBracketTypes.quarterFinals:
        cols.push(col16())
        cols.push(colQf())
        cols.push(colQf())
        cols.push(col16())
        break;

      case playoffBracketTypes.semiFinals:
        cols.push(col16())
        cols.push(colQf())
        cols.push(colSf())
        cols.push(colSf())
        cols.push(colQf())
        cols.push(col16())
        break;

      case playoffBracketTypes.finals:
        cols.push(col16())
        cols.push(colQf())
        cols.push(colSf())
        cols.push(colF())
        cols.push(colSf())
        cols.push(colQf())
        cols.push(col16())
        break;

      default:
        break;
    }
    return cols
  }

  // const headerCols_solo = () => {
  //   const cols = []
  //   switch (pool.poolKey) {
  //     case playoffBracketTypes.roundOf32:
  //       cols.push(col32())
  //       cols.push(col32())
  //       break;

  //     case playoffBracketTypes.roundOf16:
  //       cols.push(col16())
  //       cols.push(col16())
  //       break;

  //     case playoffBracketTypes.quarterFinals:
  //       cols.push(colQf())
  //       cols.push(colQf())
  //       break;

  //     case playoffBracketTypes.semiFinals:
  //       cols.push(colSf())
  //       cols.push(colSf())
  //       break;

  //     case playoffBracketTypes.finals:
  //       cols.push(colF())
  //       break;

  //     default:
  //       break;
  //   }
  //   return cols
  // }

  const startBracket_full = () => {

    const cols = []
    let columns = 2

    switch (pool.poolKey) {
      case playoffBracketTypes.roundOf32:
        cols.push(r32_left())
        cols.push(r32_right())
        break;

      case playoffBracketTypes.roundOf16:
        cols.push(r16_left())
        cols.push(r16_right())
        break;

      case playoffBracketTypes.quarterFinals:
        cols.push(r16_left())
        cols.push(quarter_left())
        cols.push(quarter_right())
        cols.push(r16_right())
        columns = 4
        break;

      case playoffBracketTypes.semiFinals:
        cols.push(r16_left())
        cols.push(quarter_left())
        cols.push(semi_left())
        cols.push(semi_right())
        cols.push(quarter_right())
        cols.push(r16_right())
        columns = 6
        break;

      case playoffBracketTypes.finals:
        cols.push(r16_left())
        cols.push(quarter_left())
        cols.push(semi_left())
        cols.push(finals())
        cols.push(semi_right())
        cols.push(quarter_right())
        cols.push(r16_right())
        columns = 7
        break;

      default:
        break;
    }

    return <div className={'grid-overflow'}>
      <Grid columns={columns} className={'playoff-grid-header'} >
        {headerCols_full()}
      </Grid>
      <Grid columns={columns} className={'playoff-grid'}>
        <Grid.Row>
          {cols}
        </Grid.Row>
      </Grid>
    </div>
  }

  const bracket_grid = (columns, cols) => <div className={'grid-overflow-c'}>
    <div className={'grid-overflow'}>
      <Grid columns={columns} divided className={'playoff-grid'}>
        <Grid.Row>
          {cols}
        </Grid.Row>
      </Grid>
    </div>
  </div>

  const bracket_div = (cols, pk) => <div className={'p-bracket ' + pk}>{cols}</div>

  const startBracket_solo = () => {

    const cols = []
    let columns = 2

    if (pool) {
      switch (pool.poolKey) {
        case playoffBracketTypes.roundOf32:
          cols.push(r32_left())
          cols.push(r32_right())
          break;

        case playoffBracketTypes.roundOf16:
          cols.push(r16_left())
          cols.push(r16_right())
          break;

        case playoffBracketTypes.quarterFinals:
          cols.push(quarter_left())
          cols.push(quarter_right())
          columns = 2
          break;

        case playoffBracketTypes.semiFinals:
          cols.push(semi_left())
          cols.push(semi_right())
          columns = 2
          break;

        case playoffBracketTypes.finals:
          cols.push(finals())
          columns = 1
          break;

        default:
          break;
      }

    }

    return cols.length > 0 ? useGrid ? bracket_grid(columns, cols) : bracket_div(cols, pool.poolKey) :
      <div>---</div>
  }

  const content = () => {
    switch (selectedBracket) {
      case bracketTypes.startSolo:
        return startBracket_solo()
      case bracketTypes.startFull:
        return startBracket_full()
      default:
        break;
    }
  }

  // return <SwiperGroup content={'asdfasdfasdfadsf'} />

  return content()

}

export default PlayoffBracket