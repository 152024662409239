import { getAuth, signInWithEmailLink, signOut } from "firebase/auth";
import { allAppKeys } from "../../../project/fbConfiguration";
import { updateAppProfileTempDirect } from "../../auth/profiles/updateAppProfileTempDirect";
import { _emailSignInStorageItem } from "../../auth/signInActions/sendSignInLink";
import { getHashPath, getPathViews } from "../../redirection/current";
import { grts, responseReducers } from "./reducerHelpers/dispatchProps";

export const rts = {
  handleBadEmailLink: 'handleBadEmailLink',
  handleSetAuth: 'handleSetAuth',
  handleSignOut: 'handleSignOut',
  handleUpdateNotificationRequest: 'handleUpdateNotificationRequest',
  handleSignInWithValidatedEmail: 'handleSignInWithValidatedEmail',
  handleMessagingSupported: 'handleMessagingSupported',
  handleSignedOut: 'handleSignedOut',
  ...grts
}

export const authReducerInitialState = (initState) => {
  return { ...initState }
};

export const authReducer = (state, action) => {

  const { type, dispatch } = action

  const { handleSignedOut } = authHandlers(dispatch)

  switch (type) {

    case rts.handleMessagingSupported:
      const appKeys = allAppKeys()
      const { publicVapidKey } = appKeys ? appKeys : {}
      return { ...state, messagingSupported: action.messagingSupported, publicVapidKey }

    case rts.handleSetAuth:
      return {
        ...state,
        authValidated: true,
        loggedIn: action.currentUser ? true : false,
        currentUser: action.currentUser,
        noAuth: !action.currentUser
      }

    case rts.handleUpdateNotificationRequest:
      return { ...state, notificationRequestResponse: action.result }

    case rts.handleSignOut:
      const auth = getAuth()
      signOut(auth).then(() => {
        console.log('OUT')
        handleSignedOut()
      })
      return {
        ...state,
        appUser: null,
        loggedIn: false,
        currentUser: null,
      }

    case rts.handleSignedOut:
      return {
        ...state,
        authValidated: true,
        appUser: null,
        loggedIn: false,
        currentUser: null,
      }

    case rts.handleBadEmailLink:
      return { ...state, emailConfirmationResult: action.emailConfirmationResult }

    case rts.handleSignInWithValidatedEmail:
      signInWithValidatedEmail(action.confirmationEmail, action.callback)
      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const authHandlers = (dispatch) => {
  return {
    handleMessagingSupported: (messagingSupported) => { dispatch({ type: rts.handleMessagingSupported, dispatch, messagingSupported }) },
    handleBadEmailLink: (emailConfirmationResult) => { dispatch({ type: rts.handleBadEmailLink, dispatch, emailConfirmationResult }) },
    handleSetAuth: (currentUser) => { dispatch({ type: rts.handleSetAuth, dispatch, currentUser }) },
    handleSignOut: () => { dispatch({ type: rts.handleSignOut, dispatch }) },
    handleSignedOut: () => { dispatch({ type: rts.handleSignedOut, dispatch }) },
    handleUpdateNotificationRequest: (result) => { dispatch({ type: rts.handleUpdateNotificationRequest, dispatch, result }) },
    handleSignInWithValidatedEmail: (confirmationEmail, callback) => { dispatch({ type: rts.handleSignInWithValidatedEmail, dispatch, confirmationEmail, callback }) },
  }
}

const signInWithValidatedEmail = (confirmationEmail, callback) => {
  let { _hash } = getHashPath()
  const auth = getAuth()
  signInWithEmailLink(auth, confirmationEmail, window.location.href)
    .then((result) => {
      // Clear email from storage.
      const pathViews = getPathViews(_hash)
      window.localStorage.removeItem(_emailSignInStorageItem);
      updateAppProfileTempDirect(confirmationEmail, pathViews, null, callback)
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    })
    .catch((error) => {
      callback({ success: false, error })
      // setInvalidEmail(error)
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
    });
}