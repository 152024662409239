import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { startDataHandlers, startDataInitialState, startDataReducer } from '../reducers/StartDataReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

export const StartDataContext = createContext();

/** Global OtherDatas for the Event */
const StartDataProvider = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext
  const { pathViews } = paps_state ? paps_state : {}

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { startData } = aps_global ? aps_global : {}
  const { useStartAppData, startAppData, startAppGlobalData } = startData ? startData : {}

  const init_state = {}
  const [startData_state, startData_dispatch] = useReducer(startDataReducer, init_state, startDataInitialState)
  const startData_handlers = startDataHandlers(startData_dispatch, startData_state)
  const { handleAppData } = startData_handlers

  useEffect(() => {
    if (useStartAppData && ((startAppData && startAppData.length > 0) || (startAppGlobalData && startAppGlobalData.length > 0))) {
      getStartDataCollectionItem(pathViews, handleAppData, startAppData, startAppGlobalData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const providerContext = () => <StartDataContext.Provider value={{ startData_state, startData_handlers }}>
    {props.children}
  </StartDataContext.Provider>

  const providerContextNull = () => <StartDataContext.Provider value={{ startData_state: {}, startData_handlers }}>
    {props.children}
  </StartDataContext.Provider>

  return useStartAppData && ((startAppData && startAppData.length > 0) || (startAppGlobalData && startAppGlobalData.length > 0)) ? providerContext() : providerContextNull()

}

export default StartDataProvider

/** Gets the data from the startAppPages collections */
const getStartDataCollectionItem = (pathViews, callback, startAppData, startAppGlobalData) => {

  if (startAppData && startAppData.length > 0) {
    startAppData.forEach((appPage, index) => {
      fs_get_data({ refPath: createRefPath_event(pathViews) }).then(data => {
        callback(appPage, data)
      }).catch(error => {
        callback(appPage, {})
      })
    })
  }

  if (startAppGlobalData && startAppGlobalData.length > 0) {
    startAppGlobalData.forEach((appPage, index) => {
      fs_get_data({ refPath: createRefPath_event(pathViews, ['_' + appPage, appPage]) }).then(data => {
        const ddata = data && data[appPage] ? data[appPage] : data
        callback('_' + appPage, ddata)
      }).catch(error => {
        callback(appPage, {})
      })
    })
  }
}