import React, { useContext, useEffect, useState } from 'react';
import { Button, Label } from 'semantic-ui-react';
import { GoogleSheetsContext } from '../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { PreviewContext } from '../cnr/contexts/PreviewContext';
import { googleModeTypes } from '../cnr/reducers/GoogleSheetsReducer';
import { g_cns } from '../common/cns';
import { uniqueKey } from "../common/keys";
import { appIconTypes } from '../enums/appIconTypes';
import { gEnums } from '../enums/globalEnums';
import { iconColorTypes, settingsIconTypes } from '../enums/settingsIconTypes';
import ButtonIcon from '../genericControls/ButtonIcon';
import { GroupConfirmButton } from '../viewSettings/actions/buttons/GroupConfirmButton';
import { DataSourceSelect, getDataSourceItem } from './DataSourceSelect';
import { DropdownDeviceSelect } from './DropdownDeviceSelect';
import ProfileSelect from './ProfileSelect';
import { motion } from "framer-motion";

const _buttonSize = 'mini'

const fadeVariant = {
  start: { background: "#fff" },
  end: { background: "#000", transition: { duration: 2 } }
};

/** Controls the settings for the app
 * @param viewPermissionType  
 * @description The top settings menu for the app.
 */
const SettingsAppTopMenu = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, settings, fns, validApp, navigate } = parentContext ? parentContext : {}

  const { homeSettings } = settings
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { settingsPermissions } = global_home ? global_home : {}
  const { allowGridIconSort } = settingsPermissions ? settingsPermissions : {}

  const { appSettings_state, paps_state, page_state, framework_state, appUserAuth_state } = states
  const { appSettings_handlers, appUser_handlers } = handlers
  const { appUser_fns } = fns
  const { pageSettings } = page_state
  const { homeSettings_global, aps_global, aps_viewItems, aps_views, settingsDocName } = pageSettings ? pageSettings : {}
  const { dataSourceType, showAppViewer } = appSettings_state ? appSettings_state : {}
  const { appArea, pathViews } = paps_state ? paps_state : {}
  const { appUserAuthList } = appUserAuth_state ? appUserAuth_state : {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ? googleSheetsContext : {}

  // appSettingsContext 
  const {
    allowSettingsListen,
    appSettingsOn,
    authOn,
    commitSettingsOn,
    dataOverrideOn,
    desktopOn,
    helpOn,
    sandboxOn,
    settingsLeftOn,
    settingsPreviewOn,
    settingsRightOn,
    sortingOn,
  } = appSettings_state ? appSettings_state : {}

  const previewContext = useContext(PreviewContext);
  const { preview_handlers } = previewContext ? previewContext : {}

  const { googleModeType } = googleSheets_state ? googleSheets_state : {}

  // pageContext 
  const { appDataSource, googleSheets, dataOptions } = aps_global ? aps_global : {}
  const { appDataSourceType } = appDataSource ? appDataSource : {}
  const { globalAppSettings } = homeSettings_global ? homeSettings_global : {}
  const { allowSettingsSandbox } = globalAppSettings ? globalAppSettings : {}
  const { allowGoogleSheets, googleSheetsId } = googleSheets ? googleSheets : {}
  const { limitDevelopmentData, dataLimit } = dataOptions ? dataOptions : {}

  const _allowRefreshDb = (dataSourceType === gEnums.dataSourceTypes.googleSpreadSheet) || (appDataSourceType === gEnums.dataSourceTypes.googleSpreadSheet)

  // frameworkContext 
  const { desktopMode } = framework_state ? framework_state : {}

  const desktopView = desktopMode || desktopOn ? true : false

  const _allowDataSource = allowGoogleSheets && googleSheetsId ? true : false

  // local
  let viewPermissionType = appUser_fns.validate_settingsAuth(null, true)

  const showMinimal = viewPermissionType === gEnums.viewPermissionTypes.allow ? false : true

  const [menuButtons, setMenuButtons] = useState([])

  useEffect(() => {
    const allows = {
      adminOverride: appArea === 'event',
      authOn: appArea === 'event',
      client: pathViews && pathViews.events ? true : false,
      commitSandbox: true,
      dataLimit: true,
      dataSource: _allowDataSource,
      displaySelect: true,
      help: true,
      home: appArea !== 'home' ? true : false,
      liveGlobal: appArea === 'event',
      livePage: appArea === 'event',
      maskCancel: true,
      profileSelect: true,
      refreshDb: _allowRefreshDb,
      sandbox: allowSettingsSandbox,
      settings: !desktopView,
      settingsRight: desktopView,
      settingsLeft: desktopView,
      sorting: allowGridIconSort,
    }
    const b = []
    allows.home && b.push(topButtons.home)
    allows.client && b.push(topButtons.client)
    allows.adminOverride && b.push(topButtons.adminOverride)
    allows.dataLimit && limitDevelopmentData && b.push(topButtons.dataLimit)
    allows.dataSource && b.push(topButtons.dataSource)
    allows.dataSource && b.push(dataSourceSelect())
    allows.refreshDb && b.push(topButtons.refreshDb)
    allows.settingsLeft && b.push(topButtons.settingsLeft)
    allows.displaySelect && b.push(displaySelect())
    allows.sorting && b.push(topButtons.sorting)
    allows.sandbox && b.push(topButtons.sandbox)
    allows.sandbox && b.push(topButtons.commitSandbox)
    allows.settings && b.push(topButtons.settings)
    allows.livePage && b.push(topButtons.livePage)
    allows.profileSelect && b.push(profileSelect())
    allows.maskCancel && b.push(topButtons.maskCancel)
    allows.settingsRight && b.push(topButtons.settingsRight)
    allows.liveGlobal && b.push(topButtons.liveGlobal)
    allows.authOn && b.push(topButtons.authOn)
    allows.help && b.push(topButtons.help)
    setMenuButtons(b)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appArea, appSettingsOn, sandboxOn, authOn, dataSourceType, dataOverrideOn, _allowRefreshDb]);

  const handleGoToHome = () => navigate('/')
  const handleGoToClient = () => navigate('/clients/' + pathViews.clients)

  const handlePreviewOn = (isGlobal) => {
    appSettings_handlers.handlePreviewOn(isGlobal)
    if (isGlobal && settingsPreviewOn) { preview_handlers.handleSettingsPreviewCancel() }
  }

  const handleCommitSettings = () => {
    const settings = {
      global: aps_global,
      viewItems: aps_viewItems,
      views: aps_views
    }
    appSettings_handlers.handleCommitSettings(settingsDocName, settings)
  }

  // dropdowns
  const dataSourceSelect = () => <DataSourceSelect key={uniqueKey('ds')} />
  const displaySelect = () => <DropdownDeviceSelect key={uniqueKey('dds')} />
  const profileSelect = () => <ProfileSelect key={uniqueKey('ps')} profileSelectType={gEnums.profileSelectTypes.accessLevels} selectOnly={true} asDropdownButton={true} />

  const btni = (iconName, propIsOn, onClick, buttonMode, opts, altColor, text, cornerIcon, altKey) => <ButtonIcon key={uniqueKey('bi', iconName, altKey)} biProps={{ iconName, propIsOn, onClick, buttonMode, opts, altColor, text, cornerIcon }} />
  const btni_au = (iconName, propIsOn, onClick, buttonMode, opts, altColor, text, cornerIcon, altKey) => <ButtonIcon key={uniqueKey('bi', iconName, altKey)} biProps={{ iconName, propIsOn, onClick, buttonMode, opts, altColor, text, cornerIcon, appUserAuthList: authOn ? appUserAuthList : null }} />

  const topButtons = {
    adminOverride: btni('check square outline', dataOverrideOn, appSettings_handlers.handleDataOverride, false, { title: 'Data Override' }),
    authOn: btni_au('users', authOn, appSettings_handlers.handleAuthOn, false, { title: 'Auth On' }),
    commitSandbox: btni('database', commitSettingsOn, appSettings_handlers.handleCommitSettingsOn, false, { title: 'Commit Settings' }),
    client: btni('handshake outline', null, handleGoToClient, false, { title: 'Go to Client' }),
    dataLimit: btni('hashtag', limitDevelopmentData, null, false, { title: dataLimit ? 'Data Limit - ' + dataLimit : 'Data Limit', color: 'green' }),
    dataSource: btni(getDataSourceItem(appDataSourceType ? appDataSourceType : gEnums.dataSourceTypes.firebase), null, null, null, { title: 'App Data Source' }),
    help: btni('help circle', helpOn, appSettings_handlers.handleHelpOn, false, { title: 'Help On' }),
    home: btni('home', null, handleGoToHome, false, { title: 'Go to Home' }),
    liveGlobal: btni('refresh', settingsPreviewOn, handlePreviewOn, true, { key: 'rlg', title: 'Live Settings', className: settingsPreviewOn ? 'blink_me_1' : '' }, null, null, { name: 'setting', color: 'blue' }),
    maskCancel: btni(appIconTypes.delete, null, appUser_handlers.handleResetAccessLevel, false, { title: 'Cancel' }),
    refreshDb: btni('refresh', null, appSettings_handlers.handleDatabaseRefresh, false, { title: 'Refresh' }, null, null, null, 'refr'),
    sandbox: btni(sandboxOn ? 'dashboard' : 'lightning', sandboxOn, appSettings_handlers.handleSandboxOn, false, { title: 'User Settings' }, sandboxOn ? iconColorTypes.settingsUser : iconColorTypes.settingsLive),
    settings: btni('cog', appSettingsOn, appSettings_handlers.handleSettingsOn, false, { title: 'Settings' }, null, null, allowSettingsListen ? 'yellow' : null),
    settingsRight: btni('setting', settingsRightOn, appSettings_handlers.handleSettingsRightOn, false, { title: 'Page Settings' }),
    settingsLeft: btni('settings', settingsLeftOn, appSettings_handlers.handleSettingsLeftOn, true, { title: 'App Settings' }),
    sorting: btni('sort', sortingOn, appSettings_handlers.handleSortingOn, true, { title: 'Show Sorting' }),
  }

  const getBorderColor = () => {
    if (sandboxOn) {
      return iconColorTypes.semBlue
    } else if (validApp) {
      return iconColorTypes.semGreen
    } else {
      return iconColorTypes.semBlue
    }
  }

  const md = (content) => <motion.div
    variants={fadeVariant}
    initial="start"
    animate="end"
  >
    {content}
  </motion.div>

  const iconGroup = () => <Button.Group size={_buttonSize} style={{ borderColor: getBorderColor() }}>
    {menuButtons}
  </Button.Group>

  const iconGroupM = () => <Button.Group size={_buttonSize}>
    {profileSelect()}
    {btni(appIconTypes.delete, null, appUser_handlers.handleResetAccessLevel, false, { title: 'Cancel' })}
  </Button.Group>

  const confirmButton = () => <GroupConfirmButton caption={'Commit Settings'}
    handleCancel={appSettings_handlers.handleCommitSettingsOn}
    handleConfirm={handleCommitSettings}
  />

  const menuContent = () => <div className={g_cns.app_settings_menu}>
    {!showMinimal && iconGroup()}
    {showMinimal && iconGroupM()}
    {commitSettingsOn && <div style={{ marginTop: '1em' }}>{confirmButton()}</div>}
    {googleModeType === googleModeTypes.previewing && <div><Label className={'sat-preview'} color={'purple'}>{'Preview'}</Label></div>}
  </div>

  return showAppViewer ? <div></div> : menuContent()

}

export default SettingsAppTopMenu