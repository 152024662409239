import { gEnums } from '../../enums/globalEnums';
import { getBaseRef } from '../helpers/getBaseRef';
import { _profileCollectionName } from '../profiles/getProfile';
import { addData } from './subUpdates/addData';
import { ammendData } from './subUpdates/ammendData';
import { ammendExisting } from './subUpdates/ammendExisting';
import { createList } from './subUpdates/createList';
import { deleteData } from './subUpdates/deleteData';
import { editData } from './subUpdates/editData';
import { mergeData } from './subUpdates/mergeData';
import { updateAccessRequest } from './subUpdates/updateAccessRequest';
import { updateData } from './subUpdates/updateData';
import { updateRating } from './subUpdates/updateRating';
import { updateSort } from './subUpdates/updateSort';
import { updateImageLinks } from './subUpdates/updateUrlLinks';

/** Funnel function for all firebase data
 * @function activate
 * @function add
 * @function ammendExisting
 * @function copy
 * @function createList
 * @function delete
 * @function edit
 * @function rate
 * @function imageLinks
 * @function updateAccessRequest
 * @function updates
 * @function updateSort
 */
export const updateFirestoreData = (dispatch, paps_state, ufProps, page_fns, appUser, dataRestrictions, opts) => {

  const { getGviDeps } = page_fns ? page_fns : {}
  const { dataUpdateType, swipedItem } = ufProps ? ufProps : {}
  let { vit } = ufProps ? ufProps : {}

  const { pathViews, storagePaths } = paps_state
  const viDeps = getGviDeps(vit)
  const baseRef = getBaseRef(viDeps, pathViews, null, swipedItem, null, null, ufProps)

  ufProps.viDeps = viDeps
  ufProps.dataRestrictions = dataRestrictions
  ufProps.storagePaths = storagePaths

  // special cases
  switch (vit) {
    case 'appProfiles':
      vit = _profileCollectionName
      break;
    default:
    // nothing
  }

  const allProps = {
    appUser,
    baseRef,
    dispatch,
    opts,
    pathViews,
    ufProps,
  }

  switch (dataUpdateType) {

    case gEnums.dataUpdateTypes.ammend:
      ammendData(allProps)
      break;

    case gEnums.dataUpdateTypes.add:
      addData(allProps)
      break;

    // case gEnums.dataUpdateTypes.ammendAll:
    //   ammendData(allProps)
    //   break;

    case gEnums.dataUpdateTypes.ammendExisting:
      ammendExisting(allProps)
      break;

    case gEnums.dataUpdateTypes.copy:
      return false;

    case gEnums.dataUpdateTypes.createList:
      createList(allProps)
      break;

    case gEnums.dataUpdateTypes.delete:
      deleteData(allProps)
      break;

    case gEnums.dataUpdateTypes.edit:
      editData(allProps)
      break;

    case gEnums.dataUpdateTypes.imageLinks:
      updateImageLinks(allProps)
      break;

    case gEnums.dataUpdateTypes.merge:
      mergeData(allProps)
      break;

    case gEnums.dataUpdateTypes.rate:
      updateRating(allProps)
      break;

    case gEnums.dataUpdateTypes.updateAccessRequest:
      updateAccessRequest(allProps)
      break;

    case gEnums.dataUpdateTypes.updates:
      updateData(allProps)
      break;

    case gEnums.dataUpdateTypes.updateSort:
      updateSort(allProps)
      break;

    default:
    // nothing
  }
}