import _ from 'lodash';
import { sortObj } from '../common/sorting';
import { gEnums } from '../enums/globalEnums';
import { getLastPathView } from '../redirection/current';
import { appUserPermissionTypes, dataDataActionTypes, dataManagementTypes, dataModificationTypes, itemActionTypes, pageItemActionTypes } from '../viewSettings/enums/itemActionTypes';

/**
 * 
 * @param {object} dataManagementSettings 
 * @param {object} appDataManagement 
 * @param {object} appUserAccess 
 * @returns a list of objects that 
 */
export const getDataManagementOptions = (dataManagementSettings, appDataManagement, appUserAccess) => {

  const { accessLevel } = appUserAccess ? appUserAccess : {}

  let _dmo = {}

  // loop the dataDataActionTypes (ammendPropData, backupItemData, bulkImageUpload, etc.)
  Object.keys(dataDataActionTypes).forEach(dat => {
    const dms = dataManagementSettings && dataManagementSettings[dat]
    const adm = appDataManagement && appDataManagement[dat]

    let _accessLevel = 0

    // if global has a settings, use that
    if (adm && adm.allowAction && adm.accessLevel) {
      _accessLevel = adm.accessLevel
    } else {
      if (dms) {
        if (dms && dms.settingsAuthLevel) {
          _accessLevel = dms.settingsAuthLevel
        }
      }
    }
    _dmo[dat] = { allowAction: accessLevel >= _accessLevel }
  })

  return _dmo
}

/**
 * 
 * @param {object} dataModifications_pageItem 
 * @param {object} dataModifications_global 
 * @returns  the merged `dataModifications` from the page and global
 */
export const getDataModifications = (dataModifications_pageItem, dataModifications_global, singleDataItem, appUserAccess, trimBySingle) => {

  const _allowCombo = appUserAccess && appUserAccess.accessLevel >= gEnums.authLevels.appDataAdmin.value

  let _dm = {}

  if (_allowCombo) {
    if (dataModifications_global && dataModifications_pageItem) {
      _dm = { ...dataModifications_global, ...dataModifications_pageItem }
    } else if (dataModifications_global) {
      _dm = { ...dataModifications_global }
    }
  } else {
    _dm = dataModifications_pageItem ? { ...dataModifications_pageItem } : {}
  }

  if (trimBySingle) {
    if (singleDataItem) {
      delete _dm.add
      delete _dm.addWithImage
      delete _dm.sorting
      delete _dm.activate
    } else {
      delete _dm.edit
      delete _dm.images
    }
  }

  return _dm

}

/**
 * 
 * @param {object} dataModifications 
 * @param {object} appUserAccess 
 * @returns a list of dataModificationKeys
 */
export const getAllowedDataModificationKeys = (dataModifications, appUserAccess) => {
  const _das = sortObj(dataModifications)
  const dataModificationKeys = {}

  // loop  the dataModifications object
  Object.keys(_das).forEach(key => {

    const dm = _das[key]

    if (dataModificationTypes[key] && dm) {
      let allowMod = true
      const { allow, allowAction, accessLevel } = dm
      if (allow || allowAction) {
        // if there is an accessLevel for the 
        if (accessLevel) {
          if (appUserAccess && appUserAccess.accessLevel < accessLevel) {
            allowMod = true
          }
        }
      }
      if (allowMod) {
        switch (key) {
          case 'dataModifications':
            // nothing
            break;
          default:
            dataModificationKeys[key] = dm
            break;
        }
      }
    }
  })
  return dataModificationKeys
}

/**
 * returns 
 * @param {object} props (accessRequests, authAppUser, itemData, pageSettings, singleDataItem, view, viewItem, viewKey)
 * @returns whether the `action popup` will be displayed
 */
export const validateUiActionAccess = (props) => {

  const {
    accessRequests,
    appUserAccess,
    itemData,
    pageSettings,
    singleDataItem,
    view,
    viewItem,
    viewItemIsView,
    viewKey,
  } = props

  const { accessLevel: accessLevel_appUser, loggedIn, itemAccess } = appUserAccess ? appUserAccess : {}

  const { pageItemActions, dataModifications: dataModifications_pageItem, appUserPagePermissions, search, maps } = viewItem ? viewItem : {}
  const { aps_global, aps_viewItems, aps_appUserSettings } = pageSettings ? pageSettings : {}

  const {
    appNotifications,
    appSignIn,
    appUserPermissions,
    dataModifications: dataModifications_global,
    dataOptions,
    imageMapping,
    tools,
  } = aps_global ? aps_global : {}

  const { allowNotifications, allowDirectToNotificationTargets, notificationTargets, notificationsAuthLevel } = appNotifications ? appNotifications : {}
  const { allowRating, ratingCollections, allowRequestAccess, requestAccessCollections } = appUserPermissions ? appUserPermissions : {}
  const { appUserCollection, allowCloning } = aps_appUserSettings ? aps_appUserSettings : {}
  const { allowSignInDirect, directSignInType } = appSignIn ? appSignIn : {}
  const { showSearch, searchOnPage } = search ? search : {}
  const { showGeoMapList } = maps ? maps : {}
  const { showItemCount } = tools ? tools : {}

  // LOOK
  // dataModifications_pageItem && removeEmpties(dataModifications_pageItem)

  const _dataManagement = {} //getDataManagementOptions(dataManagementSettings, appDataManagement, appUserAccess)
  const _dataModifications = getDataModifications(dataModifications_pageItem, dataModifications_global, singleDataItem, appUserAccess, true)

  const { allowItemDataModification, accessLevel: accessLevel_dm } = dataOptions ? dataOptions : {}

  const appSettings_viewItem = aps_viewItems && viewItem && aps_viewItems[viewItem.key] ? aps_viewItems[viewItem.key] : null
  const { dataModifications: dataModifications_app } = appSettings_viewItem ? appSettings_viewItem : {}

  const _imageMapping = imageMapping ? _.find(imageMapping, { collectionName: viewItem.key }) : {}
  const { allowMapping, mapFieldName } = _imageMapping ? _imageMapping : {}

  const dataModifications_c = { ...dataModifications_app, ..._dataModifications }

  const actionItems = {
    appUserDirect: {},
    dataManagement: {},
    dataModification: {},
  }

  // IMPORTANT: PageAction - add to the dataManagement (dataManagementTypes)
  checkListType_modification(actionItems.dataManagement, dataManagementTypes, _dataManagement, singleDataItem, viewItemIsView, appUserAccess)

  // IMPORTANT: PageAction - add to the dataModification (dataManagementTypes)
  checkListType_modification(actionItems.dataModification, dataModificationTypes, dataModifications_c, singleDataItem, viewItemIsView, appUserAccess)

  // adds a menuItem if `allowMapping` and 'mapFieldName` exist  
  if (allowMapping && mapFieldName && accessLevel_appUser >= gEnums.accessLevels.admin.value) { actionItems.dataManagement[dataManagementTypes.imageMapping] = true }

  // IMPORTANT: PageAction - add to the appUserDirect (pageItemActions)
  checkListType_modification(actionItems.appUserDirect, pageItemActionTypes, pageItemActions, singleDataItem, viewItemIsView, appUserAccess)
  checkListType_global(actionItems.appUserDirect, aps_global, singleDataItem, viewItemIsView, appUserAccess, view, viewKey)

  // IMPORTANT: PageAction - add to the appUserDirect (appUserPagePermissions)
  // questionsAndAnswers, qrCode, ticketing, videoConferencing
  checkListType_modification(actionItems.appUserDirect, appUserPermissionTypes, appUserPagePermissions, singleDataItem, viewItemIsView, appUserAccess)

  Object.keys(dataManagementTypes).forEach(modType => {
    switch (modType) {
      case itemActionTypes.notifications:
        break;
      default:
        const { allow: _allow_dm } = validateDataModification(modType, appUserAccess, getPermissions(_dataManagement, modType, singleDataItem, viewItemIsView, appUserAccess))
        if (_allow_dm) { actionItems.dataManagement[modType] = { allow: true } }
        break;
    }
  })

  if (allowNotifications && allowDirectToNotificationTargets && notificationTargets && viewItem && notificationTargets.includes(viewItem.key)) {
    let allowNotif = false;
    switch (viewItem.key) {
      case [appUserCollection]:
        allowNotif = notificationTargets.includes(viewItem.key)
        break;
      default:
        allowNotif = notificationTargets.includes(viewItem.key)
        break;
    }
    if (allowNotif) {
      if (notificationsAuthLevel) {
        allowNotif = accessLevel_appUser >= notificationsAuthLevel
      } else {
        allowNotif = appUserAccess.isAdminOrSuper
      }
      if (allowNotif) {
        actionItems.appUserDirect[itemActionTypes.notifications] = { allow: true }
      }
    }
  }

  // adds a menuItem if `signInAs` and the other criterea are met
  if (singleDataItem && allowCloning && viewItem && (viewItem.key === appUserCollection) && appUserAccess && appUserAccess.isAdminOrSuper) {
    actionItems.appUserDirect[itemActionTypes.signInAs] = { allow: true }
  }

  // if (appUserAccess.isAdminOrSuper) {
  //   actionItems.appUserDirect[itemActionTypes.openDatabase] = { allow: true }
  // }

  // if (allowAppUserConnection && viewItem && (viewItem.key === appUserCollection)) {
  //   actionItems.appUserDirect[itemActionTypes.connectToAppUser] = { allow: true }
  // }

  addMenuItem_signInDirect(actionItems.appUserDirect, allowSignInDirect, directSignInType, appUserCollection, view, viewKey, viewItem, loggedIn)

  if (allowItemDataModification) {
    if (accessLevel_appUser >= accessLevel_dm) {
      if (!singleDataItem && viewItem.key === view) { actionItems.appUserDirect[itemActionTypes.add] = { allow: true } }
      if (singleDataItem && viewItem.key === view) { actionItems.appUserDirect[itemActionTypes.edit] = { allow: true } }
      if (singleDataItem && viewItem.key === appUserCollection) { actionItems.appUserDirect[itemActionTypes.appUserInfo] = { allow: true } }
    }
  }

  if (showSearch && !searchOnPage && !singleDataItem) { actionItems.appUserDirect[itemActionTypes.search] = { allow: true } }
  // if (showVoiceSearch && !searchOnPage && !singleDataItem) { actionItems.appUserDirect[itemActionTypes.voiceSearch] = { allow: true } }
  if (showGeoMapList) { actionItems.appUserDirect[itemActionTypes.geoMapList] = { allow: true } }
  if (showItemCount) { actionItems.appUserDirect[itemActionTypes.showItemCount] = { allow: true } }

  if (allowRating && ratingCollections && viewKey && ratingCollections.includes(view)) {
    actionItems.appUserDirect[appUserPermissionTypes.rating] = { allow: true }
  }

  if (allowRequestAccess && requestAccessCollections && viewKey && requestAccessCollections.includes(view)) {
    actionItems.appUserDirect[appUserPermissionTypes.requestAccess] = { allow: true }
  }

  addMenuItem_accessRequests(actionItems.appUserDirect, accessRequests, singleDataItem, itemData, viewItem)

  return actionItems

}

/**
 * adds a menuItem if `directSignInType` exists
 * @param {array} dms 
 * @param {boolean} allowSignInDirect 
 * @param {string} directSignInType 
 * @param {object} appUserCollection 
 * @param {string} view 
 * @param {string} viewKey 
 * @param {object} viewItem 
 * @param {boolean} loggedIn 
 */
const addMenuItem_signInDirect = (dms, allowSignInDirect, directSignInType, appUserCollection, view, viewKey, viewItem, loggedIn) => {
  if (allowSignInDirect && view !== 'landing' && viewKey && viewItem && viewItem.key && (viewItem.key.toLowerCase().indexOf(appUserCollection.toLowerCase()) >= 0)) {
    if (!loggedIn) {
      switch (directSignInType) {
        case gEnums.directSignInTypes.email:
          dms[itemActionTypes.signInDirectEmail] = { allow: true }
          break;
        case gEnums.directSignInTypes.phoneNumber:
          dms[itemActionTypes.signInDirectPhone] = { allow: true }
          break;
        default:
        // nothing
      }
    }
  }
}

/**
 * adds a menuItem if `accessRequest` exists
 * @param {array} dms 
 * @param {object} accessRequests 
 * @param {array} singleDataItem
 * @param {boolean} itemData 
 * @param {object} viewItem 
 */
const addMenuItem_accessRequests = (dms, accessRequests, singleDataItem, itemData, viewItem) => {
  if (accessRequests && singleDataItem && itemData) {
    Object.keys(accessRequests).forEach(key => {
      const accessRequest = accessRequests[key]
      delete accessRequest.id
      Object.keys(accessRequest).forEach(keyy => {
        const ar = accessRequest[keyy]
        if (ar.pathName) {
          const alp = getLastPathView(ar.pathName)
          const { path, key } = alp ? alp : {}
          if (viewItem.key === path && itemData.id === key) {
            dms[itemActionTypes.viewAccessRequests] = { allow: true }
          }
        }
      })
    })
  }
}

/**
 * 
 * @param {object} actions 
 * @param {string} modType 
 * @param {boolean} singleDataItem 
 * @param {object} appUserAccess 
 * @returns the action object if it is allowed
 */
const getPermissions = (actions, modType, singleDataItem, viewItemIsView, appUserAccess) => {

  const action = actions ? actions[modType] : {}
  const { allowAction } = action ? action : {}
  let allow = allowAction

  if (!action) {
    allow = false
  } else {
    if (singleDataItem) {
      switch (modType) {
        case itemActionTypes.sorting:
          // allow = false
          break;
        default:
        // nothing
      }
    } else {
      switch (modType) {
        case itemActionTypes.add:
          if (!viewItemIsView) { allow = false }
          break;
        case itemActionTypes.delete:
          allow = false
          break;
        case itemActionTypes.clipboard:
        case itemActionTypes.backupItemData:
        case itemActionTypes.imageUpload:
        case itemActionTypes.rating:
        case itemActionTypes.requestAccess:
          allow = false
          break;
        case itemActionTypes.itemLinking:
        case itemActionTypes.edit:
          allow = true
          break;
        default:
        // nothing
      }
    }
  }

  switch (modType) {
    case itemActionTypes.edit:
      if (appUserAccess >= gEnums.accessLevels.admin.value) {
        return action
      }
      break;
    default:
    // nothing
  }

  return allow ? action : {}
}

/**
 * 
 * @param {string} modType 
 * @param {object} appUserAccess 
 * @param {object} permissionProps 
 * @returns true if the action is allowed by the appUser
 */
const validateDataModification = (modType, appUserAccess, permissionProps) => {

  const { allowAction, accessLevel: accessLevel_item, actionCaption, subActionTypes } = permissionProps ? permissionProps : {}
  const { accessLevel: accessLevel_appUser } = appUserAccess ? appUserAccess : {}

  const allows = {
    allow: false,
    actionCaption
  }

  // if the appUser is a admin, allow the action
  if (accessLevel_appUser && (accessLevel_appUser >= gEnums.accessLevels.admin.value)) {
    allows.allow = allowAction
    // return { allow: allowAction, actionCaption }
  } else {
    if (accessLevel_item) {
      switch (accessLevel_item) {
        // allow if accessLevel is public
        case gEnums.accessLevels.public.value:
          allows.allow = true
          break;
        default:
          if (allowAction && accessLevel_appUser >= accessLevel_item) {
            allows.allow = true
          }
      }
    } else {
      switch (modType) {
        case itemActionTypes.emailAuth:
          if (allowAction && !appUserAccess.userData) {
            allows.allow = true
            // return { allow: true, actionCaption }
          }
          break;
        default:
        // nothing
      }
    }
  }

  if (subActionTypes) {
    allows.subActionTypes = subActionTypes
  }

  return { ...allows }
}

/**
 * sets where the `listType` within the `listTypes` is `allowed` by checking the 
 * permissions using `validateDataModification` and `getPermissions`
 * @param {enum} listTypes 
 * @param {string} listType 
 * @param {object} actions 
 * @param {boolean} singleDataItem 
 * @param {object} appUserAccess 
 */
const checkListType_modification = (listTypes, listType, actions, singleDataItem, viewItemIsView, appUserAccess) => {
  Object.keys(listType).forEach(type => {
    const { allow, actionCaption, subActionTypes } = validateDataModification(type, appUserAccess, getPermissions(actions, type, singleDataItem, viewItemIsView, appUserAccess))
    if (allow) {
      listTypes[type] = { allow: true, actionCaption, subActionTypes }
    }
  })
}

const checkListType_global = (listTypes, aps_global, singleDataItem, viewItemIsView, appUserAccess, view, viewKey) => {

  const { accessLevel } = appUserAccess ? appUserAccess : {}

  const {
    appQuestionsAndAnswers,
    appVideoConferencing,
  } = aps_global ? aps_global : {}

  if (appQuestionsAndAnswers &&
    appQuestionsAndAnswers.allowQuestionsAndAnswers &&
    accessLevel >= appQuestionsAndAnswers.qAndAAuthLevel &&
    appQuestionsAndAnswers.questionsAndAnswersCollections &&
    appQuestionsAndAnswers.questionsAndAnswersCollections.includes(view)) {
    // listTypes['questionsAndAnswers'] = { allow: true }
    listTypes['qAndAManager'] = { allow: true }
  }

  if (appVideoConferencing &&
    appVideoConferencing.allowVideoConferencing &&
    accessLevel >= appVideoConferencing.videoConferencingAuthLevel) {
    listTypes['videoConferencing'] = { allow: true }
  }

}