import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { formatItem, formatTypes } from '../../../common/dateFormatting';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import { ScheduleCalendar } from '../../calendar/Calendar';

const DateInput = (props) => {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { dataOptions } = aps_global ? aps_global : {}
  const { dataFormat } = dataOptions ? dataOptions : {}

  const { fip } = props ? props : {}
  const _fip = { ...fip }

  const trueDate = _fip && _fip.value ? new Date(_fip.value) : null
  const formattedDate = trueDate ? formatItem(formatTypes.fullDate, _fip.value) : null

  _fip.value = formattedDate

  // local state
  const [inputDate, setInputDate] = useState(formattedDate)
  const [showCalendar, setShowCalendar] = useState()

  useEffect(() => {
    // const _selectedDate = formatTypes.shortDate ? formatItem(dataFormat ? dataFormat : formatTypes.fullDate, inputDate) : inputDate
    const _selectedDate = inputDate ? formatItem(dataFormat ? dataFormat : formatTypes.shortestDate, inputDate) : inputDate
    const data = {
      propname: fip.propname,
      value: _selectedDate
    }
    fip.onChange(null, data)
    setShowCalendar()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [inputDate]);

  const handleDateSelect = (d) => {
    setInputDate(d)
  }
  const handleShowCalendar = () => setShowCalendar(!showCalendar)

  const content = () => <ScheduleCalendar handleDateSelect={handleDateSelect} inputDate={inputDate} {...props} />

  const fullPageWrapper = () => <FullPageWrapper
    content={content()}
    topperCaption={'Select Date'}
    // handleCancel={calendar_handlers.handleClose}
    isBack={true}
  />

  return showCalendar ?
    fullPageWrapper() :
    <Form.Input {..._fip}
      icon={'calendar'}
      fluid onClick={() => handleShowCalendar()}
    />

}

export default DateInput