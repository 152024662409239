import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { getAuthHeaderProps } from '../auth/authSettings';
import { HeaderContext } from '../cnr/contexts/HeaderContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { uniqueKey } from '../common/keys';
import AppUserPages from '../components/user/AppUserPages';
import { appFormTypes } from '../enums/appFormTypes';
import { appIconTypes } from '../enums/appIconTypes';
import { appUserDropdownItems } from './AppUserItems';
import SearchPage from './SearchPage';

/**
 * 
 * @returns a Menu.Item
 */
export const TopMenuIcons = () => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { auth_state, appUser_state, page_state, paps_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { page_fns } = fns

  // auth 
  let { notificationRequestResponse, messagingSupported } = auth_state ? auth_state : {}

  // appUser 
  let { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  let { loggedIn, appUserSessionKey, isAppUser } = appUserAccess ? appUserAccess : {}

  // papsContext 
  const { view, landingView, pathViews } = paps_state

  // pageContext
  const { pushSimple } = page_fns ? page_fns : {}
  const { topMenu } = aps_global ? aps_global : {}
  const { menuLocation } = topMenu ? topMenu : {}
  const { myProfileCaption, myProfileIcon, showProfileAvatar, appUserCollection, showHeaderDropdown } = aps_appUserSettings ? aps_appUserSettings : {}

  // headerContext
  const headerContext = useContext(HeaderContext)
  const { header_state } = headerContext ? headerContext : {} // yes, this is true
  const { allowSiteSearch } = header_state ? header_state : {}

  const [authProps, setAuthProps] = useState()
  const [showTopMenuSearch, setShowTopMenuSearch] = useState()
  const [showSettings, setShowSettings] = useState();

  const upward = menuLocation === 'bottom' && view === landingView ? true : false

  // get the time form
  useEffect(() => {
    const _authProps = getAuthHeaderProps({ appUserAccess, authAppUser, aps_global, notificationRequestResponse, messagingSupported })
    setAuthProps(_authProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess]);

  const handleShowSearch = () => setShowTopMenuSearch(!showTopMenuSearch)
  const handleIconClick = (dropItem, itemKey) => pushSimple({ key: dropItem }, itemKey)
  const handleMenuClick = (dropItem, itemKey) => pushSimple({ key: dropItem }, itemKey)
  const handleShowSettings = () => setShowSettings(!showSettings)

  const handleHomeClick = () => {
    if (pathViews.events) {
      pushSimple({ key: landingView })
    }
  }

  const iSearch = () => <Menu.Item
    onClick={() => { handleShowSearch() }}>
    <Icon name={appIconTypes.search} ></Icon>
  </Menu.Item>

  const iIcon = (iconName, value, cn) => <Icon
    circular={authProps.circular}
    bordered={authProps.bordered}
    inverted
    color={authProps.color}
    name={iconName}
    className={cn ? cn : null}
    onClick={() => { appUserCollection && appUserSessionKey && isAppUser && handleIconClick(appUserCollection, appUserSessionKey) }}
  >
    {value ? value : null}
  </Icon>

  const menuIcon = (iconName, value, cn) => <Menu.Item>
    {iIcon(iconName, value, cn)}
  </Menu.Item>

  /** Creates an `Icon` that will trigger the `menu dropdown`.
* @returns Icon
*/
  const iTrigger = () => {
    if (authProps) {
      if (authProps.value) {
        if (showProfileAvatar && appUserAccess && authAppUser.imageUrls && authAppUser.imageUrls.thumbnail) {
          return <Image src={authAppUser.imageUrls.thumbnail} avatar></Image>
        } else {
          let cn = 'icon-text'
          if (authProps.nrr) { cn += ' nrr' }
          return showHeaderDropdown ? iIcon(authProps.name, authProps.value, 'icon-text') : menuIcon(authProps.name, authProps.value, cn)
        }
      } else {
        return showHeaderDropdown ? iIcon(appIconTypes.user) : menuIcon(appIconTypes.user)
      }
    }
  }

  const menuRight = () => {
    const icon = !authAppUser && appIconTypes.ellipsisVertical
    const trigger = iTrigger()

    if (showHeaderDropdown) {
      return <React.Fragment>
        {allowSiteSearch && iSearch()}
        {trigger}
        <Dropdown item icon={icon} trigger={trigger} upward={upward} >
          <Dropdown.Menu className={'menu-drop-right'} size='tiny'  >
            <Dropdown.Item key={uniqueKey('tmi', 'di', 'home')} icon='home' text='Home' onClick={() => { handleHomeClick() }} />
            {loggedIn && appUserDropdownItems({ appUserAccess, myProfileCaption, myProfileIcon, loggedIn, pathViews, appUserCollection, handleMenuClick, handleShowSettings })}
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    } else {
      return <React.Fragment>
        {allowSiteSearch && iSearch()}
        {trigger}
      </React.Fragment>
    }

  }

  if (showSettings) {
    return <AppUserPages
      appFormType={appFormTypes.appAuth}
      useFullPageWrapper={true}
      handleCancel={handleShowSettings}
    />
  } else {
    return showTopMenuSearch ? <SearchPage handleShowSearch={handleShowSearch} /> : menuRight()
  }
} 