import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Icon, List, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { uniqueKey } from '../common/keys';
import NoData from '../components/alerts/NoData';
import StepWizard from '../components/wizard/StepWizard';
import { ParentContext } from '../cnr/contexts/ParentContext';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { votingHandlers, votingInitialState, votingReducer } from '../cnr/reducers/VotingReducer';
import { renderHtml } from '../components/html/renderHtml';

const VotingWizard = (props) => {

  const { handleCancel } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states ? states : {}
  const { pathViews } = paps_state ? paps_state : {}

  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { profileId } = appUserAccess ? appUserAccess : {}

  const [steps, setSteps] = useState([])
  const [step, setStep] = useState({ index: 0, name: 'paste' })
  const [voted, setVoted] = useState()

  const init_state = { pathViews, profileId }
  const [voting_state, votingDispatch] = useReducer(votingReducer, votingInitialState(init_state));
  const voting_handlers = votingHandlers(votingDispatch)

  const { votingStatus, currentVote, currentVoteItems, votes } = voting_state ? voting_state : {}
  const { name: voteName, active: voteActive } = currentVote ? currentVote : {}
  const { allow } = votingStatus ? votingStatus : {}

  const yesSelected = votes && votes[step.key] === true ? true : false
  const noSelected = votes && votes[step.key] === false ? true : false

  useEffect(() => {
    voting_handlers.handleGetVoting(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    const _allVotes = []
    if (currentVoteItems) {
      Object.keys(currentVoteItems).forEach((key, index) => {
        const voteItem = currentVoteItems[key]
        const { name, description } = voteItem
        _allVotes.push({
          key,
          index,
          name,
          description,
        })
      })
      _allVotes.push({
        index: Object.keys(currentVoteItems).length,
        name: 'Please confirm your vote',
        description: 'Vote list'
      })
      setSteps(_allVotes)
      setStep(_allVotes[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentVoteItems]);


  const stepCount = steps.length

  const handleVoted = () => setVoted(true)
  const handleStep = ({ index, name }) => setStep({ ...name })
  const handleNoVote = () => voting_handlers.handleNoVote(step.key)
  const handleYesVote = () => voting_handlers.handleYesVote(step.key)
  const handleSaveVote = () => voting_handlers.handleSaveVote(handleVoted)

  const validateComponent = () => {
    switch (stepCount) {
      default:
        return true
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const button = (key, caption, icon, color, oc, selected) => <Button key={uniqueKey('vw', key)} size={'tiny'} color={selected ? color : 'grey'} onClick={() => { oc() }}><Icon name={icon}></Icon>{caption}</Button>

  const buttons = () => {
    return [
      button('no', 'No', 'delete', 'red', handleNoVote, noSelected),
      button('yes', 'Yes', 'check', 'green', handleYesVote, yesSelected)
    ]
  }

  const confirmationContent = () => {
    if (currentVoteItems) {
      const listItems = []
      Object.keys(currentVoteItems).forEach((key, index) => {
        const voteItem = currentVoteItems[key]
        const { name } = voteItem
        const _yesSelected = votes && votes[key] === true ? true : false
        const _noSelected = votes && votes[key] === false ? true : false
        let icon = _yesSelected ? 'thumbs up outline' : 'question'
        if (_noSelected) { icon = 'thumbs down outline' }
        let iconColor = _yesSelected ? 'green' : 'grey'
        if (_noSelected) { iconColor = 'red' }
        listItems.push(<List.Item>
          <List.Icon color={iconColor} name={icon} />
          <List.Content>{name}</List.Content>
        </List.Item>)

      })
      return <Segment basic>
        <List divided relaxed>
          {listItems}
        </List>
      </Segment>
    }
  }

  const content = () => {
    const { name, description, index } = step ? step : {}
    switch (index) {
      case 2:
        return <Wrapper
          content={confirmationContent()}
          footer={<UiSaveButtons
            save={{ caption: 'Confirm Vote', oc: handleSaveVote }}
          ></UiSaveButtons>}
          wrapperType={wrapperTypes.padded}
        ></Wrapper>
      default:
        return <Wrapper
          content={<Segment.Group>
            <Segment>{name}</Segment>
            <Segment>{description ? renderHtml(description) : '???'}</Segment>
          </Segment.Group>}
          footer={<div className={'btn-space'}>{buttons()}</div>}
          wrapperType={wrapperTypes.padded}
        ></Wrapper>
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    handleCancel={handleCancel}
    setStep={handleStep}
    step={step}
    stepCaption={_.startCase(step.name)}
    steps={steps}
    topperCaption={'Voting: ' + voteName}
    useTopper={true}
  ></StepWizard>

  if (votingStatus) {
    if (voted) {
      return <FullPageWrapper
        content={<NoData fullCaption={'Thank you for voting'} />}
        handleCancel={handleCancel}
        topperCaption={'Voting: ' + voteName}
      />
    } else {
      if (voteActive && votingStatus) {
        if (allow) {
          return step ? stepWizard() : <div></div>
        } else {
          return <FullPageWrapper
            content={<NoData fullCaption={'You have already voted'} />}
            handleCancel={handleCancel}
            topperCaption={'Voting: ' + voteName}
          />
        }
      } else {
        return <FullPageWrapper
          content={<NoData fullCaption={'No Votes are currently active'} />}
          handleCancel={handleCancel}
          topperCaption={'Voting: ' + voteName}
        />
      }
    }
  } else {
    return <div></div>
  }
}

export default VotingWizard