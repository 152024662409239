import { createRefPath } from "../appData/appRefPaths"
import { doc_update } from "../appData/fsData"

export const mergePromise = (refPath, vit, directCollection, itemData) => {
  const promises = []
  Object.keys(itemData).forEach(itemKey => {
    const item = itemData[itemKey]
    const coll = directCollection ? directCollection : vit
    const _refPath = createRefPath([coll, itemKey], refPath)
    const r = doc_update(_refPath, item)
    promises.push(r)
  })
  return Promise.all(promises)
}