
import { getFirestore } from 'firebase/firestore';
import { createRefPath, createRefPath_event } from '../appData/appRefPaths';
import { doc_get_promise, ref_get } from '../appData/fsRefs';
import { dvv } from '../dataHelpers';
import { getBaseRef } from '../helpers/getBaseRef';

export const getAdditionalAppUser = (appUser, pathViews, callback, aps_appUserSettings, profile, pageContext) => {

  const { userData } = appUser
  const { appUserCollection, appUserConnections } = aps_appUserSettings ? aps_appUserSettings : {}

  if (userData) {
    const { pvInfo } = userData
    const { pathViews } = pvInfo ? pvInfo : {}
    if (appUserCollection && pathViews && pathViews[appUserCollection]) {
      userData[appUserCollection] = pathViews[appUserCollection]
    }
  }

  additionalInfoPromises(appUser, appUserCollection, appUserConnections, pathViews, pageContext).then(promiseResults => {

    const appUserData = promiseResults[0] ? dvv(promiseResults[0]) : null
    const appUserViewData = promiseResults[1] ? promiseResults[1].data() : null

    const { appUserViewTypeProp: auVtp, additionalAppUserViewTypeProps: aauVtp } = aps_appUserSettings ? aps_appUserSettings : { appUserCollection: 'attendees', appUserViewTypeProp: 'registrationType' }

    if (appUserData) {
      if (auVtp) {
        appUser.attendeeInfo = {
          [auVtp]: appUserData[auVtp] ? appUserData[auVtp] : appUserData[auVtp.toLowerCase()]
        }
      }
      if (aauVtp) {
        aauVtp.forEach(item => {
          if (appUserData[item]) {
            appUser[item] = appUserData[item]
          }
        })
      }
    }

    if (appUser && appUserViewData) { appUser[appUserCollection] = appUserViewData }

    if (profile) {
      callback(appUser, profile)
    } else {
      callback(appUser)
    }
  })
}

const additionalInfoPromises = (appUser, appUserCollection, appUserConnections, pathViews, pageContext) => {

  const fs = getFirestore()
  const promises = []

  if (pathViews.clients && pathViews.events && appUserCollection && appUser) {

    const _refPathAppUser = createRefPath_event(pathViews, [appUserCollection])
    promises.push(doc_get_promise(ref_get(fs, _refPathAppUser)))

    const { userData } = appUser ? appUser : {}

    if (userData && appUserCollection && userData[appUserCollection]) {
      const globalAppUserValue = userData && appUserCollection && userData[appUserCollection]
      if (globalAppUserValue) {
        const { page_fns } = pageContext ? pageContext : {}
        const { getGviDeps } = page_fns ? page_fns : {}
        const viDeps = getGviDeps(appUserCollection)
        const baseRef = getBaseRef(viDeps, pathViews)
        const { refPath } = baseRef
        const refPath2 = refPath + createRefPath([appUserCollection, globalAppUserValue])
        promises.push(ref_get(fs, refPath2))
      }
    }
  }

  return Promise.all(promises)

}