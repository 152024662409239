import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Icon, Label, Menu, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { setStyleAndClass } from '../../styles/formatting';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { PageContext } from '../../cnr/contexts/PageContext';
import SwiperProvider, { SwiperContext } from '../../cnr/contexts/SwiperContext';
import { gEnums } from '../../enums/globalEnums';
import { projectStyles } from '../../styles/projectStyles';
import { altStyles } from '../../styles/styles';
import { getTabView } from '../../cnr/reducers/reducerHelpers/swipeHelpers';

const _userIconColor = 'grey'
const _adminIconColor = 'yellow'

/** Using SwipeableViews
 * @param `items` - if passed in, these will be the swipe items. If not, `viewItems` from `PageContext` will be the swipe items.
  */
const UiSwiper = (props) => {

  const { fromAppUserProfile } = props ? props : {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMobileOn } = framework_state ? framework_state : {}

  // swiperContext
  const swiperContext = useContext(SwiperContext)
  const { swiper_handlers, swiper_state } = swiperContext ? swiperContext : {}
  const { groupType, swiperContent, tabIndex } = swiper_state ? swiper_state : {}

  // note > swiperItems: {content, loaded}
  const { swiperItems, swiperTabs, tabContainerId } = swiperContent ? swiperContent : {}

  const viewCount = swiperTabs ? swiperTabs.length : null

  useEffect(() => {
    if (swiperTabs) {
      const tvw = getTabView(tabContainerId, 'mi')
      tvw.set(swiperTabs.length)
      swiper_handlers.handleSetTabView(tvw)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [tabIndex]);

  const menuOnTop = true

  const menuPropsAndStyles = {
    widths: menuOnTop ? null : viewCount,
    labeled: menuOnTop ? null : 'labeled',
    borderless: menuOnTop ? true : false,
    useStyle: menuOnTop ? true : false,
    styles: menuOnTop ? projectStyles().menuHorizontal : projectStyles().menuHorizontalBottom
  }

  const tabsSnC = setStyleAndClass(aps_global, fromAppUserProfile ? gEnums.projectStyles.tabsAppUser : gEnums.projectStyles.tabs)
  const tabsSnC_active = setStyleAndClass(aps_global, fromAppUserProfile ? gEnums.projectStyles.tabsAppUser : gEnums.projectStyles.tabs, null, null, true)
  const tabsSnC_line = setStyleAndClass(aps_global, fromAppUserProfile ? gEnums.projectStyles.tabsAppUser : gEnums.projectStyles.tabs, null, null, false, true)

  const activeSnC = { className: 'swipe-tab' }
  activeSnC.style = { ...tabsSnC_line.style, ...tabsSnC_active.style }

  const { style: activeStyle } = activeSnC ? activeSnC : {}

  const _activeSac = activeSnC ?
    activeSnC :
    {
      className: activeSnC.className,
      style: {
        color: activeStyle.color ? activeStyle.color : null,
        borderBottom: '2px solid ' + activeStyle.borderColor
      }
    }

  const menuItem = (swipeTab, index, asHeader) => {
    // set the style here 
    let active = parseInt(tabIndex, 10) === parseInt(index, 10) ? true : false
    const itemSaC = active ? _activeSac : tabsSnC
    const { caption, showTabItemCount, adminOnly, userOnly, dataCount } = swipeTab ? swipeTab : {}
    return asHeader ?
      <Segment>{caption}</Segment>
      : <Menu.Item
        {...itemSaC}
        key={uniqueKey('swmi', index)}
        id={'mi_' + index}
        value={index}
        active={active}
        onClick={() => { swiper_handlers.handleSwipeChangeIndex(index) }}
      >
        {adminOnly && <Icon color={_adminIconColor} name={'user secret'} title={'You are only seeing this item because you are an admin'}>{dataCount}</Icon>}
        {userOnly && <Icon color={_userIconColor} size={'small'} name={'user'}>{dataCount}</Icon>}
        {caption}
        {showTabItemCount && <Label>{dataCount}</Label>}
      </Menu.Item>
  }

  const menuItems = () => (swiperTabs.map((swipeTab, index) => (menuItem(swipeTab, index))))
  const itemContent = () => (Object.values(swiperItems).map(item => (item.content)))

  const tabMenu = () => (
    <Menu pointing secondary
      {...tabsSnC}
      className={'swipe-tab-container'}
      id={tabContainerId}
      widths={menuPropsAndStyles.widths}
    >
      {viewCount > 0 && menuItems()}
    </Menu>
  )

  const swipeableContent = () => <SwipeableViews
    enableMouseEvents={!desktopMobileOn}
    index={parseInt(tabIndex)}
    threshold={2}
    onChangeIndex={swiper_handlers.handleSwipeChangeIndex}
    style={Object.assign({}, altStyles.swipeArea)}
    animateTransitions={!desktopMobileOn}
  >
    {itemContent()}
  </SwipeableViews>

  const verticalMenu = () => {
    const acci = []
    Object.keys(swiperItems).forEach((key, index) => {
      const si = swiperItems[key]
      const cn = tabIndex !== index ? 'dis-none' : ''
      acci.push(
        <Menu.Item key={uniqueKey('vmi', index)} className={'acc-header'}>
          <Menu.Header onClick={() => swiper_handlers.handleSwipeChangeIndex(index)} >
            <div>{key}</div>
            <Icon name={'caret down'} />
          </Menu.Header>
          <Menu.Menu className={cn}>
            {si.content && si.content}
          </Menu.Menu>
        </Menu.Item>
      )
    })
    return <Menu vertical fluid >{acci}</Menu>
  }

  const addSegs = (x, si, index) => {
    let caption = si.key
    if (_.isDate(si.key)) { caption = formatItem(formatTypes.fullDate, si.key) }
    x.push(<div key={uniqueKey('swas', index, 1)} className={'seg-header'} style={tabsSnC.style}>{caption}</div>)
    x.push(<div key={uniqueKey('swas', index, 2)} className={'seg-content'}>{si.content}</div>)
  }

  const segments = () => {
    const x = []
    Object.keys(swiperItems).forEach((key, index) => {
      addSegs(x, swiperItems[key], index)
    })
    return <div className={'seg-container'}  >{x}</div>
  }

  const content = () => {
    switch (groupType) {
      case gEnums.groupTypes.swipeableTab:
        return <div className={'app-swipe-container'}>
          <div className={'app-swipe-menu'}>{tabMenu()}</div>
          <div className={'app-swipe-content'}>{swiperItems && swipeableContent()}</div>
        </div>
      case gEnums.groupTypes.swipeableGroup:
        return <div className={'app-swipe-container'}>
          <div className={'app-swipe-content'}>{swiperItems && swipeableContent()}</div>
        </div>
      case gEnums.groupTypes.accordion:
        return <div className={'app-accordion-container'}>
          <div className={'app-accordion-content'}>{swiperItems && verticalMenu()}</div>
        </div>
      case gEnums.groupTypes.schedule:
      case gEnums.groupTypes.scheduleTop:
        return <div className={'app-segment-container'}>
          <div className={'app-segment-content'}>{'SCHEDULE'}</div>
        </div>
      case gEnums.groupTypes.segment:
        return <div className={'app-segment-container'}>
          <div className={'app-segment-content'}>{swiperItems && segments()}</div>
        </div>
      default:
        return <div className={'app-swipe-container'}>
          <div className={'app-swipe-menu'}>{tabMenu()}</div>
          <div className={'app-swipe-content'}>{swiperItems && swipeableContent()}</div>
        </div>
    }
  }

  return content()
}


const UiSwiperWithProvider = (props) => {
  const { key, swipeContentType, swipeItems, view, fn_getContent, fromAppUserProfile } = props
  return <SwiperProvider
    swipeItems={swipeItems}
    view={view}
    swipeContentType={swipeContentType}
    fn_getContent={fn_getContent}
  >
    <UiSwiper key={uniqueKey(key)} fromAppUserProfile={fromAppUserProfile} />
  </SwiperProvider>
}

export { UiSwiperWithProvider, UiSwiper }

