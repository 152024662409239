/**
 * Returns ui segment basic or +inverted
 */
export const cn_s = (inverted) => inverted ? ' ui segment basic inverted' : ' ui segment basic'

/**
 * Returns ui segment basic padd0 or +inverted
 */
export const cn_snp = (inverted) => inverted ? ' ui segment basic inverted padd0' : ' ui segment basic padd0'

/**
 * Adds the cn to the existing cn
 */
export const cnPlus = (cn) => cn ? ' ' + cn : ''

/**
 * Adds the ecn or the ecn and cnp
 */
export const cnnAdd = (ecn, cnp) => {
  if (cnp) { return ecn + ' ' + cnp }
  return ecn
}

/**
 * Group of class and style anums corresponding it named items in one of the css files
 */
export const g_cns = {
  app_settings_container: 'app-settings-container',
  app_settings_grid: 'app-settings-grid',
  app_settings_menu: 'app-settings-menu',
  app_settings_menu_bottom: 'app-settings-menu-bottom',
  app_settings_menu_google: 'app-settings-menu-google',

  settings_app_container: 'settings-app-container',
  settings_app_content: 'settings-app-content',
  settings_app_mobile_wrap: 'settings-app-mobile-wrap',
  settings_app_desktop_wrap: 'settings-app-desktop-wrap',

  dt_container: 'dt-container',
  dt_top: 'dt-top',
  dt_header: 'dt-header',
  dt_footer: 'dt-footer',

  dt_content: 'dt-content',
  dt_main: 'dt-main',
  dt_app_nav: 'dt-app-nav',
  dt_page_nav: 'dt-page-nav',

  app_container: 'app-container',
  app_banner: 'app-banner',
  app_banners: 'app-banners',
  app_footer: 'app-footer',
  app_header: 'app-header',
  app_content: 'app-content',

  page_area_container: 'page-area-container',
  page_area_content: 'page-area-content',

  app_sidebar_left_wrapper: 'app-sidebar-left-wrapper',

  item_container: 'item-container',
  item_caption: 'item-caption',
  // item_ui_header: 'item-ui-header',
  item_mask: 'item-mask',
  item_filter: 'item-filter',
  item_alpha: 'item-alpha',
  item_content: 'item-content',

  app_horz_item: 'app-horz-item',

  content_container: 'content-container',
  content_content: 'content-content',

  cards_container: 'cards-container',

  sbrc: 'sbrc',
  item_popup: 'item-popup',
  item_popup_single_left: 'item-popup single-left',
  item_popup_single_right: 'item-popup single-right',
  item_popup_solo_right: 'item-popup solo-right',
  item_popup_solo_left: 'item-popup solo-left',
  item_popup_indy: 'item-popup indy',
  item_mapping: 'item-mapping',
  item_meeting: 'item-meeting',
  item_settings: 'item-settings',
  item_allow: 'item-allow',

  hc_container: 'hc-container',
  hc_header: 'hc-header',
  hc_content: 'hc-content',
  hc_footer: 'hc-footer',

  hcf_container: 'hcf-container',
  hcf_header: 'hcf-header',
  hcf_content: 'hcf-content',
  hcf_footer: 'hcf-footer',

  hcff_container: 'hcff-container',
  hcff_header: 'hcff-header',
  hcff_content: 'hcff-content',
  hcff_footer: 'hcff-footer',

  cf_container: 'cf-container',
  cf_content: 'cf-content',
  cf_footer: 'cf-footer',
}