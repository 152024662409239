import React, { useContext } from 'react';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import MatchSetFilter from './MatchSetFilter';

const MatchSetScores = (props) => {

  const { viewKey, single } = props

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info } = sportsSeason_state ? sportsSeason_state : {}
  const { matches } = matches_info ? matches_info : {}
  const match_page = matches && matches[viewKey] ? matches[viewKey] : null

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_current } = sportsMatch_state ? sportsMatch_state : {}

  const _currentMatch = match_page ? match_page : match_current

  const { home, away, results, results_current } = _currentMatch ? _currentMatch : {}
  const _results = results_current ? results_current : results
  const { scores, setNumber } = _results ? _results : {}

  const handleScoresChange = (value, team) => sportsMatch_handlers.handleChange_scores(value, team)
  const setSetFilter = (sn) => { }

  const scoresDiv = (m, team, cn) => <div className={cn} onClick={() => { handleScoresChange(m, team) }}>{m}</div>

  const setScoringDivs = (start, end, team, scores, sn) => {
    const lastPoint = setNumber === 5 ? 15 : 25
    const c = []
    for (var m = start; m >= end; m--) {
      let cn;
      if (scores) {
        const setScore = scores[sn]
        if (setScore) {
          if (setScore[team] === m) { cn = 'selected' }
        }
      }
      if (m === lastPoint) { cn += ' def' }
      c.push(scoresDiv(m, team, cn))
    }
    return c
  }

  const setScoring = () => {
    const lastSet = setNumber === 5 ? true : false
    const setPoints = {
      first: { max: lastSet ? 19 : 29, min: 0 },
      last: { max: 50, min: lastSet ? 20 : 30 },
    }
    let cn = 'scoring-team'
    if (!setNumber) { cn += ' ns' }
    return <React.Fragment>
      <MatchSetFilter setSetFilter={setSetFilter} />
      <div className={'scores-teams'}>
        <div className={'scores-team'}>{away.name}</div>
        <div className={'scores-team'}>{home.name}</div>
      </div>
      <div className={'scores'}>
        <div className={cn}>{setScoringDivs(setPoints.first.max, setPoints.first.min, 'away', scores, setNumber)}{setScoringDivs(setPoints.last.max, setPoints.last.min, 'away', scores, setNumber)}</div>
        <div className={cn}>{setScoringDivs(setPoints.first.max, setPoints.first.min, 'home', scores, setNumber)}{setScoringDivs(setPoints.last.max, setPoints.last.min, 'home', scores, setNumber)}</div>
      </div>
    </React.Fragment>
  }


  const singleSetScoring = () => {
    const lastSet = setNumber === 5 ? true : false
    const setPoints = {
      first: { max: lastSet ? 19 : 29, min: 0 },
      last: { max: 50, min: lastSet ? 20 : 30 },
    }
    let ccn = 'scores'
    let cn = 'scoring-team'
    if (single) { ccn += ' single' }
    if (!setNumber) { cn += ' ns' }
    return <div className={ccn}>
      <div className={cn}>{setScoringDivs(setPoints.first.max, setPoints.first.min, 'away', scores, setNumber)}{setScoringDivs(setPoints.last.max, setPoints.last.min, 'away', scores, setNumber)}</div>
    </div>
  }

  if (single) {
    return singleSetScoring()
  } else {
    return setScoring()
  }
}

export default MatchSetScores