import { getFirestore } from 'firebase/firestore';
import _ from 'lodash';
import { convertSnapshot } from '../../cnr/contexts/contextHelpers';
import { createRefPath, createRefPath_client, createRefPath_event } from '../appData/appRefPaths';
import { fs_get_data } from '../appData/fsAppData';
import { doc_get_promise, ref_get } from '../appData/fsRefs';
import { _profileCollectionName } from '../profiles/getProfile';

/** Returns a list of topics groups
 * 1 - adminProfiles
 * 2 - attendees
 * 3 - registrationTypes
 * 4 - other attached data (sessions...)
 * @note if sendCallback exists, it means that this list is being generated prior to sending a scheduled message
 */
export const getAppProfileData = (props) => {

  const { appUserCollections, aps_viewItems, pathViews, callback, callback_send, np } = props
  const _viewItemKeys = Object.keys(aps_viewItems)

  const fs = getFirestore()

  let refs = {}

  if (pathViews.events) {
    refs = {
      clientProfiles: ref_get(fs, createRefPath_client(pathViews, [_profileCollectionName])),
      profiles: ref_get(fs, createRefPath(['profiles'])),
    }
    if (appUserCollections) {
      appUserCollections.forEach(auv => {
        refs[auv] = ref_get(fs, createRefPath_event(pathViews, [auv]))
      })
    }
  } else if (pathViews.clients) {
    refs = {
      clientProfiles: ref_get(fs, createRefPath_client(pathViews, [_profileCollectionName])),
      profiles: ref_get(fs, createRefPath(['profiles'])),
    }
  } else {
    refs = {
      profiles: ref_get(fs, createRefPath(['profiles'])),
    }
  }
  // , appUserCollection, aps_viewItems
  getAppProfileInfoPromise(refs).then(res => {
    const collections = {}
    if (res) {
      res.forEach((r, index) => {
        const item = Object.keys(refs)[index]
        const c = r ? convertSnapshot(r, false, { ignoreId: true }) : null
        collections[item] = c
      })
    }

    const appProfileData = getProfileGroups(appUserCollections, collections, _viewItemKeys)
    callback && callback(appProfileData)

    // if sendCallback exists, it means that this list is being generated prior to sending a scheduled message
    if (callback_send && np) {
      np.appProfileData = appProfileData
      callback_send(np)
    }
  })
}

/**
 * returns (via callback) all of the profiles for the client with their event and attendeeKey information
 * @param {object} pathViews 
 * @param {string} eventKey 
 * @param {function} callback 
 */
export const getAllClientProfiles = async (pathViews, eventKey, appUserCollection, callback) => {

  const _refPath_events = eventKey ? createRefPath_client(pathViews, ['events', eventKey]) : createRefPath_client(pathViews, ['events'])
  const _refPath_profiles = createRefPath_client(pathViews, ['profiles'])
  const profiles = await fs_get_data({ refPath: _refPath_profiles })
  const events = await fs_get_data({ refPath: _refPath_events })

  const profileKeys = profiles ? Object.keys(profiles) : []
  const eventKeys = events ? Object.keys(events) : []

  if (events) {
    getAllClientAppUsers(pathViews, events, appUserCollection).then(res => {

      const attendees_event = {}
      const profiles_existing = {}
      const profiles_delete = {}
      const profiles_modified = {}
      const profiles_alt = {}

      eventKeys.forEach((evk, index) => {
        if (evk.indexOf('_backUp') < 0) {
          const _event = events[evk]
          const { name: eventName } = _event ? _event : {}
          const attendees = res[index] ? res[index] : null
          attendees_event[evk] = attendees

          // loop the attendess in the event
          Object.keys(attendees_event).forEach(ek => {
            const attendees = attendees_event[ek]

            // loop the profileKeys to try and find an email match
            profileKeys.forEach(pk => {

              let isAlt;

              const profile = profiles[pk]

              let attendeeKey = _.findKey(attendees, { email: pk })

              // if no attendeeKey, check the attendees with a lower case email
              if (!attendeeKey) {
                const { email } = profile ? profile : {}
                attendeeKey = _.findKey(attendees, { email: email.toLowerCase() })
                isAlt = true
              }

              if (attendeeKey) {

                const pk_lc = pk.toLowerCase()
                const isDelete = pk_lc !== pk

                let _currentProfiles = isDelete ? profiles_delete : profiles_existing
                let _pk = pk

                if (isAlt) {
                  _currentProfiles = profiles_delete
                  // _pk = pk.toLowerCase()
                  profile.email = profile.email.toLowerCase()
                }

                if (!_currentProfiles[_pk]) {
                  _currentProfiles[_pk] = { ...profile }
                  _currentProfiles[_pk].events = {}
                }

                if (!_currentProfiles[_pk].events) { _currentProfiles[_pk].events = {} }
                if (!_currentProfiles[_pk].events[ek]) {
                  _currentProfiles[_pk].events[ek] = {
                    name: eventName,
                    appUserKey: attendeeKey
                  }
                }
              }
            })
          })
        }
      })

      ammendModified(profiles_delete, profiles_existing, profiles_modified)
      ammendModified(profiles_alt, profiles_existing, profiles_modified)

      callback({ profiles_existing, profiles_delete, profiles_modified, profiles_alt, profiles_combined: { ...profiles_existing, ...profiles_modified } })
    })
  }
}

const ammendModified = (profiles, profiles_existing, profiles_modified) => {
  Object.keys(profiles).forEach(key => {
    const apd = profiles[key]
    const { email } = apd
    const key_lc = key.toLowerCase()
    if (!profiles_existing[key_lc]) {
      profiles_modified[key_lc] = { ...apd }
      profiles_modified[key_lc].email = email.toLowerCase()
    }
  })
}

/**
 * 
 * @param {object} pathViews 
 * @param {object} events  
 * @returns a promise with all the attendees for each of the events
 */
const getAllClientAppUsers = async (pathViews, events, appUserCollection) => {
  const promises = []
  Object.keys(events).forEach(eventKey => {
    if (eventKey.indexOf('_backUp') < 0) {
      const _refPath_attendees = createRefPath_client(pathViews, ['events', eventKey, appUserCollection])
      promises.push(fs_get_data({ refPath: _refPath_attendees }))
    }
  })
  return Promise.all(promises)
}

/** 3 functions
 * 1 - Gets the `appUserCollection` data collection
 * 2 - Gets the `clients/id/profiles` data collection that have `fcmTokens`
 * 3 - Gets the `profiles` data collection with that have `fcmTokens`
 */
const getAppProfileInfoPromise = async (refs) => {
  const promises = []
  Object.keys(refs).forEach(key => {
    const ref = refs[key]
    promises.push(doc_get_promise(ref))
  })
  // }
  return Promise.all(promises)
}

const getProfileGroups = (appUserCollections, collections, _viewItemKeys) => {

  const { clientProfiles, profiles } = collections

  const _adminProfiles = profiles ? ammendProfileInfo(profiles) : null
  const _allAppUsers = {}

  if (appUserCollections) {
    appUserCollections.forEach(appUserCollection => {
      if (collections[appUserCollection]) {

        const _appUsers = {}
        const appUsers = collections[appUserCollection]

        // loop the targetData 
        if (appUsers) {
          Object.keys(appUsers).forEach(key => {
            const appUser = appUsers[key]
            ammendAppUsers(_appUsers, appUser, key, clientProfiles)
          })
        }
        _allAppUsers[appUserCollection] = _appUsers
      }
    })
  }

  const profileInfo = {}
  if (_adminProfiles) { profileInfo.adminProfiles = _adminProfiles }
  if (clientProfiles) { profileInfo.clientProfiles = clientProfiles }
  // if (_clientAdminProfiles) { profileInfo.clientAdminProfiles = _clientAdminProfiles }

  return { ..._allAppUsers, ...profileInfo }

}

const ammendProfileInfo = (profiles) => {
  const _profiles = {}
  if (profiles) {
    Object.keys(profiles).forEach(key => {
      const profile = profiles[key]
      _profiles[key] = {
        lastName: profile.lastName,
        firstName: profile.firstName,
        email: profile.email,
        fcmTokens: profile.fcmTokens,
        initials: profile.initials,
        displayName: profile.displayName,
        pageFavs: profile.pageFavs,
        _itemKey: key,
        // phoneNumber: profile.phoneNumber,
      }
    })
  }
  return _profiles
}

// const ammendClientAdmins = (clientAdmins) => {
//   const _clientAdmins = {}
//   if (clientAdmins) {
//     Object.keys(clientAdmins).forEach(key => {
//       const clientAdmin = clientAdmins[key]
//       _clientAdmins[key] = {
//         lastName: clientAdmin.lastName,
//         firstName: clientAdmin.firstName,
//         email: clientAdmin.email,
//         fcmTokens: clientAdmin.fcmTokens,
//         _itemKey: key,
//       }
//     })
//   }
//   return _clientAdmins
// }

/**
 * Updates the _appUsers object with the data from the clientProfiles object
 * @param {object} _appUsers 
 * @param {object} appUser 
 * @param {string} key 
 * @param {object} clientProfiles 
 */
const ammendAppUsers = (_appUsers, appUser, key, clientProfiles) => {

  _appUsers[key] = { key: key }

  const { firstName, lastName, email, phoneNumber } = appUser

  _appUsers[key].lastName = lastName
  _appUsers[key].firstName = firstName
  _appUsers[key].email = email
  _appUsers[key].phoneNumber = phoneNumber
  _appUsers[key]._itemKey = key

  const cp = _.find(clientProfiles, { email: email })

  if (cp && cp.fcmTokens) { _appUsers[key].fcmTokens = cp.fcmTokens }
  if (cp && cp.initials) { _appUsers[key].initials = cp.initials }
  if (cp && cp.displayName) { _appUsers[key].displayName = cp.displayName }
  if (cp && !cp.lastName && lastName) { cp.lastName = lastName }
  if (cp && !cp.firstName && firstName) { cp.firstName = firstName }

}