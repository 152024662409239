import { createItemKeys } from '../../../../global/common/convert';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_doc } from '../../../../global/firestoreData/appData/fsData';
import { getData_parentKeyCollections } from './reducerHelpers/seasonSportsData';

const rts = {
  handleSportsDistrictInit: 'handleSportsDistrictInit',
  handleSetDataResults: 'handleSetDataResults',
  handleSetDistrict: 'handleSetDistrict',
}

const dataItems = {
  schools: { collectionItems: ['schools'] },
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsDistrictReducer = (state, action) => {

  const { pathViews, dataParents } = state
  const { type, dispatch } = action

  const sportsDistrict_handlers = sportsDistrictHandlers(dispatch)

  const { handleSetDataResults, handleSetDistricts, handleSetDistrict } = sportsDistrict_handlers

  switch (type) {

    case rts.handleSportsDistrictInit:

      const _refPath = createRefPath_event(pathViews, ['districts', pathViews.districts])
      fs_get_doc(_refPath, handleSetDistrict)

      const { selectedSeason } = action
      const additionalDataParents = []
      getData_parentKeyCollections(pathViews, dataItems, dataParents, additionalDataParents, null, selectedSeason, handleSetDataResults)
      return { ...state }

    case rts.handleSetDistrict:
      const { district } = action
      const { sports } = district ? district : {}
      return { ...state, district, sports }

    // handles the result when all the data from the parentKeys are collected
    case rts.handleSetDataResults:

      const { dataResult, parentDataKeys } = action

      const _results = {}

      Object.keys(dataItems).forEach(diKey => {
        _results[diKey] = dataResult[diKey]
        createItemKeys(_results[diKey])
      })

      return {
        ...state,
        ..._results,
        parentDataKeys,
      }

    default:
      return { ...state }
  }
}

export const sportsDistrictHandlers = (dispatch) => {
  return {
    handleSportsDistrictInit: () => { dispatch({ type: rts.handleSportsDistrictInit, dispatch }) },
    handleSetDataResults: (dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys) => { dispatch({ type: rts.handleSetDataResults, dispatch, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys }) },
    handleSetDistrict: (district) => { dispatch({ type: rts.handleSetDistrict, district }) },
  }
}

export const sportsDistrictInitialState = (init_state) => {
  return { ...init_state, currents: {}, currentSidebars: {} }
};