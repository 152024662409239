import React from 'react'
import { Divider, Grid, Header } from 'semantic-ui-react'
import { uniqueKey } from '../../common/keys'

export const IconsGridGrid = (props) => {

  const { iconsPerRow, dividerText, gridItems } = props

  const grid = (gridRows) => <Grid key={uniqueKey('igr')} columns={iconsPerRow}>
    {gridRows.map(gridRow => gridRow.map((gridItem, index) => <Grid.Column key={uniqueKey('irg', index)}>{gridItem.element}</Grid.Column>))}
  </Grid>

  const getGridRows = () => {
    let colCount = 0
    const gridRows = []
    let rowCols = []
    gridItems.forEach(col => {
      rowCols.push(col)
      colCount++
      if (colCount >= iconsPerRow) {
        gridRows.push(rowCols)
        colCount = 0
        rowCols = []
      }
    })
    if (rowCols.length > 0) { gridRows.push(rowCols) }
    return gridRows
  }

  const gridWithDivider = () => <React.Fragment>
    {dividerText && <Divider horizontal>
      <Header>
        {/* {icon()} */}
        {dividerText}
      </Header>
    </Divider>}
    {grid(getGridRows())}
  </React.Fragment>

  return gridWithDivider()

} 