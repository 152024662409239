import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { setStyleAndClass } from '../../styles/formatting';
import { getPageSettingsProps } from '../../viewSettings/helpers/pageSettings';
import { pageFunctions, pageHandlers, pageInitialState, pageReducer } from '../reducers/PageReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUserContext } from './AppUserContext';
import { GoogleSheetsContext } from './GoogleSheetsContext';
import { PapsContext } from './PapsContext';
import { PreviewContext } from './PreviewContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from './SettingsDataContext';

export const PageContext = createContext();

const PageProvider = (props) => {

  // ParentContext - not available    

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ? appUserContext : {}
  const { appUser } = appUser_state ? appUser_state : {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state } = appSettingsContext ? appSettingsContext : {}
  const { settingsPreviewOn, googleSettings } = appSettings_state ? appSettings_state : {}

  // previewContext
  const previewContext = useContext(PreviewContext)
  const { preview_state } = previewContext ? previewContext : {}
  const { settingsPreviewHasChanged } = preview_state ? preview_state : {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state, navigate } = papsContext ? papsContext : {}
  const { fullSettingsKey, pathName, view, otherView, pathViews, lastView, landingView } = paps_state ? paps_state : {}
  let { viewKey, getTrueView, pageKey } = paps_state ? paps_state : {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ? googleSheetsContext : {}
  const { googlePreviewType } = googleSheets_state ? googleSheets_state : {}

  // settingsContexts 
  const homeSettingsContext = useContext(HomeSettingsContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const eventSettingsContext = useContext(EventSettingsContext);

  // homeSettings
  const { homeSettings_state } = homeSettingsContext ? homeSettingsContext : {}
  const { homeSettings } = homeSettings_state ? homeSettings_state : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging: logging_home, appUrls: homeAppUrls } = homeSettings_global ? homeSettings_global : {}

  const { clientSettings_state } = clientSettingsContext ? clientSettingsContext : {}
  const { eventSettings_state } = eventSettingsContext ? eventSettingsContext : {}

  const hceStates = { homeSettings_state, clientSettings_state, eventSettings_state }

  const homeClientEventProps = { pathViews, lastView, hceStates }
  const pageSettingsProps = getPageSettingsProps(homeClientEventProps)

  // get the actual view for the page
  let trueView = (viewKey || otherView) ? view : view + '_list'
  trueView = getTrueView(pathViews, view, trueView, landingView, viewKey)

  //pageReducer
  const page_init = { homeAppUrls }
  const [page_state, page_dispatch] = useReducer(pageReducer, page_init, pageInitialState)
  const page_handlers = pageHandlers(page_dispatch, page_state)
  const page_functions = pageFunctions(page_state)

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_page } = pageSettings ? pageSettings : {}
  const { logging: logging_app } = aps_global ? aps_global : {}

  let { aps_viewItems } = pageSettings ? pageSettings : {}

  // props
  const logging = { ...logging_home, ...logging_app }
  const { showAppSettings, showHomeSettings } = logging ? logging : {}

  // IMPORTANT: Page Settings- get the settings for the page
  useEffect(() => {
    const aspProps = {
      appUser,
      googleSheets_state,
      pageKey,
      paps_state,
      pss: pageSettingsProps,
      preview_state,
      settingsPreviewOn,
      trueView,
      view,
      viewKey,
    }
    page_handlers.handleGetPageSettings(aspProps)
    // eslint-disable-next-line
  }, [fullSettingsKey, googlePreviewType, googleSettings, pathName, pageSettingsProps.time_stamp, settingsPreviewHasChanged, settingsPreviewOn, trueView, viewKey]);

  if (showHomeSettings) console.log('homeSettings_global', homeSettings_global)
  if (showAppSettings) console.log('aps_global', aps_global)

  // IMPORTANT: Push - pushSimple
  const pushSimple = (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => {
    const psProps = { aps_viewItems, aps_page, homeSettingsContext, papsContext, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex }
    return page_functions.pushSimple(psProps)
  }

  const getVi = (uivi, isGlobal) => { return page_functions.getVi(uivi, isGlobal) }
  const getViRef = (uivi) => { return page_functions.getViRef(aps_viewItems, uivi, viewKey, pathViews) }
  const getGviDeps = (uivi) => { return page_functions.getGviDeps(aps_viewItems, uivi, viewKey, aps_page) }
  const goBackTo = (count) => page_functions.goBackTo(navigate, count)
  const pushUrl = (e, url) => page_functions.pushUrl(e, url)
  const syncViewItems = (gvs, vis, vs) => page_functions.syncViewItems(gvs, vis, vs)
  const pushDrillDown = (e, viewItem, itemData, pvKey, propItem, ddProps) => page_functions.pushDrillDown(e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps)
  const getLink = (appUrlz, name, isMobileDevice) => {
    const x = _.find(appUrlz, { appName: name })
    if (x) {
      if (!isMobileDevice) {
        if (x.appDesktopUrl) {
          return x.appDesktopUrl
        } else {
          return x.appUrl
        }
      } else {
        return x.appUrl
      }
    }
  }

  const getGVIProps = (vit, viewItem, storage_fns, desktopMode) => {
    const gvi = aps_viewItems[vit]
    const { display: display_g } = gvi ? gvi : {}
    const { display, desktopDisplay } = viewItem ? viewItem : {}
    const _display = desktopMode && desktopDisplay ? desktopDisplay : display

    let { caption, defaultIcon, iconImage, useIconImage } = display_g ? display_g : {}

    caption = _display && _display.caption ? _display.caption : caption
    defaultIcon = _display && _display.defaultIcon ? _display.defaultIcon : defaultIcon

    iconImage = useIconImage && iconImage ? iconImage : null

    const segProps = {
      caption: caption ? caption : _.startCase(vit) + '*',
      icon: defaultIcon ? defaultIcon : 'file alternate',
      iconImage: iconImage ? iconImage : null,
      iconImageUrls: storage_fns.getIconUrl(useIconImage, iconImage)
    }

    if (caption) { segProps.caption = caption }
    if (defaultIcon) { segProps.icon = defaultIcon }

    return segProps

  }

  // functions 
  const page_fns = {
    getGviDeps,
    getVi,
    getViRef,
    goBackTo,
    pushDrillDown,
    pushSimple,
    pushUrl,
    syncViewItems,
    getLink,
    getGVIProps,
    setStyleAndClass: (aps_global, item, opts, ignoreColors, getColors) => setStyleAndClass(aps_global, item, opts, ignoreColors, getColors)
  }

  const contextProvider = () => {

    const cp = {
      homeSettings_global,
      logging,
      page_fns,
      page_handlers,
      page_state,
    }

    return <PageContext.Provider value={cp}>
      {props.children}
    </PageContext.Provider>
  }

  return pageSettings ? contextProvider() : <div></div>
}

export default PageProvider