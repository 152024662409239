import { parentFilters as pf_golf } from 'paoke-golf';
import { parentFilters as pf_swiper } from 'paoke-swiper';
import React, { createContext, useEffect, useState } from 'react';

/**
 * @state components_state (componentFilters)
 */
export const ComponentsContext = createContext();

/**
 * 
 * @param {object} props  
 */
const ComponentsProvider = (props) => {

  const [componentFilters, setComponentFilters] = useState()

  useEffect(() => {
    const _cfs = {
      state: [...pf_golf.state, ...pf_swiper.state],
      functions: [...pf_golf.functions, ...pf_swiper.functions],
      handlers: [...pf_golf.handlers, ...pf_swiper.handlers],
    }
    setComponentFilters(_cfs)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  return <ComponentsContext.Provider value={{ components_state: { componentFilters } }}>
    {props.children}
  </ComponentsContext.Provider>
};

export default ComponentsProvider