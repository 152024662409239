import React from 'react'

export const PageWrapper = (props) => {
  const { content, showPrint, isMobileDevice } = props
  let cnc = 'page-wrapper-container'
  let cn = 'page-wrapper'
  if (isMobileDevice) { cn += ' mobile' }
  if (showPrint) { cn += ' print' }
  return <div className={cnc}>
    <div className={cn} id={cn}>
      <div></div>
      <div>{content}</div>
      <div></div>
    </div>
  </div>
}

