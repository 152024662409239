// import { dataUpload } from '../../firestoreData/dataUpload';

export const rts = {
  handleAppData: 'handleAppData',
  handlePageData: 'handlePageData,'
}

export const startDataReducer = (state, action) => {

  const { appData } = state
  const { type, key, data } = action

  switch (type) {

    case rts.handlePageData:
      const pds = state.pageDatas ? { ...state.pageDatas } : {}
      const { pageDataKey, currentPageData } = action
      const { name, firstName, lastName } = currentPageData ? currentPageData : {}
      pds[pageDataKey] = firstName && lastName ? firstName + ' ' + lastName : name
      return { ...state, pageDatas: pds }

    case rts.handleAppData:
      console.log('handleAppData', action)
      const ad = { ...appData }
      ad[key] = data
      return {
        ...state,
        appData: ad
      }


    default:
      return { ...state }
  }
}

export const startDataInitialState = () => {
  return { appData: {} }
};

export const startDataHandlers = (dispatch, state) => {
  return {
    handleAppData: (key, data) => { dispatch({ type: rts.handleAppData, key, data }) },
    handlePageData: (pageDataKey, currentPageData) => { dispatch({ type: rts.handlePageData, pageDataKey, currentPageData }) },
  }
}