import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { uniqueKey } from '../../../common/keys'
import { getStaticName } from '../../../common/convert'
import { labelList } from '../../../lists/lists'
import { SelectList } from '../../../lists/SelectList'
import FullPageWrapper from '../../../wrappers/FullPageWrapper'
import { GenericField } from './GenericField'

/**
 * 
 * @param {object} props (allowGuests, arrayValue, color, isAppData,propKey, keyy, staticViews_gs)
 * @returns 
 */
const DropdownLabel = (props) => {

  const {
    allowGuests,
    arrayValue,
    color,
    fip,
    isAppData,
    propKey,
    keyy,
    staticViews_app,
    staticViews_gs,
    handleIssues,
  } = props

  const { propname } = fip ? fip : {}

  const _propKey = propname ? propname : propKey

  const isArray = _.isArray(arrayValue)

  const [showEdit, setShowEdit] = useState()
  const [currentArrayValue, setCurrentArrayValue] = useState([])
  const [staticView, setStaticView] = useState()
  const [sva, setSva] = useState([])
  const [nfs, setNfs] = useState()

  useEffect(() => {
    let _staticViews;
    let _sva = [];
    let _nfs = [];

    if (isAppData) {
      _staticViews = staticViews_app
    } else {
      _staticViews = staticViews_gs ? staticViews_gs : staticViews_app
    }

    if (arrayValue) {
      const _currentArrayValue = arrayValue
      if (_currentArrayValue && isArray) {
        _currentArrayValue.forEach(av => {
          const slv = getStaticName(_staticViews, _propKey, av, true, allowGuests)
          const { sv, notFound } = slv
          _sva.push(sv)
          if (notFound) {
            _nfs.push(av)
          }
        })
      }
      setSva(_sva)
      setCurrentArrayValue(_currentArrayValue)
      setNfs(_nfs)
      setStaticView(_staticViews && _staticViews[_propKey] ? _staticViews[_propKey] : [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayValue]);


  useEffect(() => {
    if (nfs && nfs.length > 0) {
      handleIssues(propKey, nfs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nfs]);

  const handleShowEdit = () => setShowEdit(!showEdit)

  const handleArrayValue = (av) => {
    setCurrentArrayValue(av)
    setShowEdit()
  }

  const dropdown = () => {

    const ddKey = uniqueKey('tv', 'dd', keyy ? keyy : _propKey)
    const ddOpts = labelList(sva, true, null, keyy, null, { icon: false })
    const count = currentArrayValue && _.isArray(currentArrayValue) ? currentArrayValue.length : 0

    let cn = 'icon hdd'
    if (!fip) { cn += ' tv' }
    if (nfs && nfs.length > 0) { cn += ' nf' }

    return <Dropdown
      key={ddKey}
      className={cn}
      options={ddOpts}
      button
      labeled
      selection
      text={_.startCase(propKey) + ' (' + count + ')'}
      icon={isArray ? { name: 'list ul', color: 'green' } : { name: 'list ', color: 'blue' }}
      style={{ color: color }}
    />
  }

  const ddEdit = () => <GenericField
    fip={fip}
    content={currentArrayValue && dropdown()}
    onEditClick={handleShowEdit}
  />

  const selectList = () => <SelectList items={staticView} arrayValues={currentArrayValue} handleArrayValue={handleArrayValue} />

  const fullPageWrapper = () => <FullPageWrapper
    content={selectList()}
    handleCancel={handleShowEdit}
    topperCaption={'List Edit'}
    topperCaption2={fip.label}
  />

  if (showEdit) {
    return fullPageWrapper()
  } else {
    return fip ? ddEdit() : dropdown()
  }
}

export default DropdownLabel