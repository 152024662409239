import React from 'react';
import { uniqueKey } from '../../common/keys';
import { addToClass, getSectionClass, getSectionThemeColors } from '../../styles/formatting';
import { gEnums } from '../../enums/globalEnums';
import { sectionMerge } from '../../viewSettings/helpers/sectionMerge';

/** Creates a section div for a single section  */
export const cardSections = (sections, semSecProps) => Object.keys(sections).map((sectionKey, index) => {

  const {
    aps_global,
    cardDisplayType,
    propSections,
    showQrCode,
  } = semSecProps

  const section = sections[sectionKey]
  const propSection = propSections ? propSections[sectionKey] : {}
  const isNameSection = propSection.key === gEnums.sectionHeaderNames.name

  const { display: display_global } = aps_global ? aps_global : {}
  const { propsToSeparate: propsToSeparate_global } = display_global ? display_global : {}

  const { sectionHeader, sectionDisplay } = sectionMerge(aps_global, propSection)
  const { sectionDisplayType } = sectionDisplay ? sectionDisplay : {}
  const { items, noContent } = section

  let isPlus;

  switch (cardDisplayType) {
    case gEnums.cardDisplayTypes.profilePlus:
    case gEnums.cardDisplayTypes.schedulePlus:
      isPlus = true
      break;
    default:
    // nothing
  }

  if (isNameSection && isPlus) {
    // nothing
    return null
  } else {
    if (noContent) {
      return items.map(item => (item))
    } else {
      // each section will have the 'sec' className
      let cn = 'sec-container'
      cn = addToClass(cn, isNameSection ? 'header' : 'sub-h')
      cn = addToClass(cn, showQrCode ? 'qrc' : null)
      cn = addToClass(cn, sectionDisplayType ? sectionDisplayType : null)

      if (propsToSeparate_global) {
        if (propsToSeparate_global.includes(sectionKey)) {
          cn = addToClass(cn, 'separate')
        }
      }

      const snc = {
        style: isNameSection ? getSectionThemeColors(aps_global, sectionHeader) : {},
        className: isNameSection ? getSectionClass(cn, sectionHeader) : cn += ' ' + sectionHeader.headerDisplayType
      }

      return <div key={uniqueKey('crd', 'cs', index)} {...snc} >
        {items.map(item => { return item })}
      </div >
    }
  }
})