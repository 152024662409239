import { callFunction } from "./fbAll";

const formsGenericTypes = {
  formsGeneric: 'formsGeneric',
}

const formsFunctionTypes = {
  getFormContent: 'getFormContent',
  getFormResponses: 'getFormResponses',
  createFormQuestions: 'createFormQuestions',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(formsGenericTypes.formsGeneric, callData)
  return data
}

export const fsfn_getFormContent = async (formID) => {
  try {
    const callData = { formFunctionType: formsFunctionTypes.getFormContent, formID, showLogs: true }
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ? response_data : {}
    if (success) {
      const ret = {
        data: {
          success,
          result: result
        }
      }
      return ret
    } else {
      return {
        data: {
          success
        }
      }
    }
  } catch (error) {
    console.error(error)
    return {
      data: {
        success: false
      }
    }
  }
}

/**
 * 
 */
export const fsfn_getFormResponse = async (formID) => {
  try {
    const callData = { formFunctionType: formsFunctionTypes.getFormResponses, formID, showLogs: true }
    const response_data = await callFsFunction(callData) // OK
    const { success, result } = response_data ? response_data : {}
    if (success) {
      const ret = {
        data: {
          success,
          result: result
        }
      }
      return ret
    } else {
      return {
        data: {
          success
        }
      }
    }
  } catch (error) {
    console.error(error)
    return {
      data: {
        success: false
      }
    }
  }
}

export const fsfn_createFormQuestions = async (formID, teams) => {
  try {
    const callData = { formFunctionType: formsFunctionTypes.createFormQuestions, formID, teams, showLogs: true }
    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK
    console.log('response_data', response_data)
    const { success, result } = response_data ? response_data : {}
    if (success) {
      const ret = {
        data: {
          success,
          result: result
        }
      }
      return ret
    } else {
      return {
        data: {
          success
        }
      }
    }
  } catch (error) {
    console.error(error)
    return {
      data: {
        success: false
      }
    }
  }
}