import { allPageTypes } from '../../../enums/allPageTypes';
import { gridItemIconGroup } from './SegIconGroup';

export const gridItemsAppAdmin = (props) => {

  const { parentContext, pageIcons, gcProps, allows } = props

  const { states } = parentContext ? parentContext : {}
  const { page_state } = states

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appCredentialing, appNotifications, appVoting } = aps_global ? aps_global : {}
  const { showNotificationsManagerOnHomeScreen, notificationsAuthLevel } = appNotifications ? appNotifications : {} // allowNotifications
  const { allowVoting, showVotingManagerOnHomeScreen } = appVoting ? appVoting : {}
  const { credentialingHomeScreenCaption, showCredentialingManagerOnHomeScreen } = appCredentialing ? appCredentialing : {}

  let _allowVoting;
  let allowVtm = !pageIcons && allowVoting && showVotingManagerOnHomeScreen
  if (allowVtm && allows.avt) { _allowVoting = true }

  let _allowCredentialing;
  let allowCredentialing = !pageIcons && showCredentialingManagerOnHomeScreen
  if (allowCredentialing && allows.avt) { _allowCredentialing = true }

  const gridItem = (viewItem, segProps, gcProps) => gridItemIconGroup(viewItem, segProps, gcProps)

  const items = []

  if (!pageIcons) {
    if (_allowCredentialing) { items.push(gridItem({ key: allPageTypes.credentialScan }, { caption: credentialingHomeScreenCaption ? credentialingHomeScreenCaption : 'Credentials', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
    if (showNotificationsManagerOnHomeScreen && notificationsAuthLevel) { items.push(gridItem({ key: allPageTypes.notificationsManager }, { caption: 'Notifications', icon: 'calendar check outline', cn: 'ao' }, gcProps)) }
    if (_allowVoting) { items.push(gridItem({ key: allPageTypes.votingManager }, { caption: 'Voting Manager', icon: 'clipboard check', cn: 'ao' }, gcProps)) }
  }

  return items

}