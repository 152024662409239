export const altStyles = {

  // keep
  swipeArea: {
    width: '100%',
    overflow: 'hidden',
    paddingTop: 0,
    height: '100%',
  },

  menuTabActive: {
    textTransform: 'uppercase',
    fontSize: '1em',
    fontWeight: 'bold',
    borderRadius: 0,
    // IMPORTANT
    borderBottom: '3px solid #f1f506', //#f3c5ff',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 1,
    backgroundColor: 'inherit'
  },

  menuTabNonActive: {
    textTransform: 'uppercase',
    fontSize: '.9em',
    fontWeight: 'normal',
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'inherit'
  },

  modalMain: {
    marginTop: 10
  },

  tab76: {
    height: '76vh',
    overflowY: 'auto',
  },
  // THIS IS THE END  
}
