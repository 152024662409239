import _ from 'lodash';
import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { propModTypes } from '../cnr/reducers/DataManagmentReducer';
import { sortObject } from "../common/sorting";
import { isDateProp, isEmailProp, isTimeProp } from '../common/tester';
import { allPageTypes } from '../enums/allPageTypes';

const vitCaptions = (vit) => {
  if (vit) {
    switch (vit) {
      case allPageTypes.cityInfos:
        return 'City Info'
      case allPageTypes.facilitys:
        return 'Facilities'
      case allPageTypes.securitys:
        return 'Security'
      case allPageTypes.transportations:
        return 'Transportation'
      default:
        return null
    }
  } else {
    return null
  }
}

/**
 * 
 * @param {object} list - ARRAY
 * @param {boolean} noNone 
 * @param {object} exception 
 * @param {string} keyy 
 * @param {string} noneAs 
 * @returns a key/value/text options list
 */
export const listList = (list, noNone, exception, keyy, noneAs) => {
  const items = []
  list = _.sortBy(list)
  list.forEach((key, index) => {
    if (key !== exception) {
      if (_.isObject(key)) {
        items.push(key)
      } else {
        items.push({
          text: vitCaptions(key) ? vitCaptions(key) : _.startCase(key),
          value: key,
          key: keyy ? keyy + '-' + key + '-' + index : key + '-' + index,
        })
      }
    }
  })
  if (!noNone) addNone(items, noneAs)
  return items
}

export const listListA = (list, prop) => {
  const items = []
  list = _.sortBy(list)
  list.forEach(li => {
    const t = li[prop]
    items.push({
      text: _.startCase(t),
      value: t,
      key: t,
    })
  })
  return items
}

const removeTypes = (pats, propKey) => {
  if (!isDateProp(propKey)) {
    const pat = _.findIndex(pats, { modType: propModTypes.fixDates })
    if (pat) { pats.splice(pat, 1) }
  }
  if (!isTimeProp(propKey)) {
    const pat = _.findIndex(pats, { modType: propModTypes.fixTime })
    if (pat) { pats.splice(pat, 1) }
  }
  if (!isEmailProp(propKey)) {
    const pat = _.findIndex(pats, { modType: propModTypes.fixEmails })
    if (pat) { pats.splice(pat, 1) }
  }
}

export const labelListHeader = (propAdjustmentTypes, opts) => {
  const { propKey, handleItemClick } = opts ? opts : {}
  console.log('handleItemClick', handleItemClick)
  const items = []
  const _propAdjustmentTypes = _.sortBy(propAdjustmentTypes, 'modType')
  removeTypes(_propAdjustmentTypes, propKey)
  _propAdjustmentTypes.forEach((pa, index) => {
    const { modType, icon, color } = pa
    const tvk = {
      text: _.startCase(modType),
      value: modType,
      key: modType + '-' + index,
      icon: <Icon color={color} name={icon} size={'small'} onClick={() => { handleItemClick && handleItemClick({ propKey, modType: modType }) }}></Icon>
    }
    items.push(tvk)
  })
  return items
}

export const labelList = (list, noNone, exception, keyy, noneAs, opts) => {
  const { icon, label, propKey, handleItemClick } = opts ? opts : {}
  const items = []
  list = _.sortBy(list)
  list.forEach((key, index) => {
    if (key !== exception) {
      if (_.isObject(key)) {
        items.push(key)
      } else {
        const tvk = {
          text: vitCaptions(key) ? vitCaptions(key) : _.startCase(key),
          value: key,
          key: keyy ? keyy + '-' + key + '-' + index : key + '-' + index,
        }
        tvk.label = label && <Label circular empty color='blue'></Label>
        tvk.icon = icon && <Icon name='check' size={'small'} onClick={() => { handleItemClick && handleItemClick({ propKey, itemAction: key }) }}></Icon>
        items.push(tvk)
      }
    }
  })
  if (!noNone) addNone(items, noneAs)
  return items
}

/** Retuns a key, value, text list from an object */

/**
 * 
 * @param {*} list - OBJECT
 * @param {*} exception 
 * @param {*} noNone 
 * @returns a key/value/text options list
 */
export const listListO = (list, exception, noNone) => {
  const l = { ...list }
  if (l && exception) { delete l[exception] }
  const slist = sortObject(l, 'key')
  const items = Object.keys(slist).map(key => (
    {
      text: vitCaptions(key) ? vitCaptions(key) : _.startCase(key),
      value: key,
      key: key,
    }
  ))
  if (!noNone) addNone(items)
  return items
}

export const listListL = (list, exception, noNone) => {
  const l = { ...list }
  if (l && exception) { delete l[exception] }
  const slist = sortObject(l, 'key')
  const items = Object.keys(slist).map(key => {
    const li = slist[key]
    return {
      text: li.text,
      value: li.value,
      // key: li.key,
    }
  })
  if (!noNone) addNone(items)
  return items
}

export const listStatic = (list, listName, noNone, isNameSelect, staticView_data) => {
  const l = { ...list }
  delete l.uid
  delete l.id
  delete l.key
  const sortBy = (listName === 'attendees' || listName === 'golfers') ? 'lastName' : 'name'
  const slist = sortObject(l, sortBy)
  const items = []
  Object.keys(slist).forEach(key => {
    const item = slist[key]
    if (key !== 'id' && key !== 'key' && key !== undefined) {
      let text = item.name ? item.name : null
      if (!text) { text = item.firstName && item.lastName ? item.lastName + ', ' + item.firstName : null }
      if (text) {
        items.push({
          text: text,
          value: isNameSelect ? text : key,
          key: isNameSelect ? text : key,
          altvalue: isNameSelect && staticView_data ? key : null,
          altprop: isNameSelect && staticView_data ? staticView_data : null
        })
      }
    }
  })
  if (!noNone) addNone(items)
  return items
}

export const listRss = (feeds) => {
  const opts = []
  Object.keys(feeds).forEach(key => {
    const feed = feeds[key]
    const { show, source, baseUrl, categories } = feed
    if (show && baseUrl) {
      if (categories) {
        feed.categories.forEach(category => {
          opts.push({
            key: category.name,
            text: source + ' - ' + _.startCase(category.name),
            value: key, // + '_' + category.name,
            // value: baseUrl + category.categoryPath
          })
        })
      } else {
        opts.push({ key: source, text: source, value: key })
      }
    }
  })
  return opts
}

export const listYears = () => {
  const d = new Date()
  const optsA = []
  for (var i = d.getFullYear() - 60; i <= d.getFullYear() + 5; i++) {
    optsA.push(
      {
        text: i,
        value: i,
        key: i,
      }
    )
  }
  return optsA
}

const addNone = (items, noneAs) => {
  items.unshift({
    text: 'none',
    value: noneAs ? noneAs : null,
    key: noneAs ? noneAs : 'none',
  })
}