export async function sendConfirmationToMe(eventId, success, deleteItem) {

  let url = 'https://demo.events.meetingevolution.net/index.php/attendeeregistration-event-create-mobile-app-response.html?eventId=' + eventId
  // 5FEB35BE9571E

  console.log('sendConfirmationToMe - url', url)
  console.log('eventId, success, deleteItem', eventId, success, deleteItem)

  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(getBody(success, deleteItem))
  };

  try {
    let response = await fetch(url, requestOptions);
    await response.json();
  } catch (error) {
    console.error('error', error.message)
  }
}

const getBody = (success, deleteItem) => {
  if (success) {
    if (deleteItem) {
      return {
        status: 0,
        message: 'deleted'
      }
    } else {
      return {
        status: 1,
        message: 'Success'
      }
    }
  } else {
    return {
      status: 0,
      message: 'Something went wrong'
    }
  }
}