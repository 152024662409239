import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import { _deferredPrompt } from './serviceWorker';
import { ParentContext } from './global/cnr/contexts/ParentContext';
import FullPageWrapper from './global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from './global/wrappers/Wrapper';
import { renderHtml } from './global/components/html/renderHtml';
import UiSaveButtons from './global/components/buttons/UiSaveButtons';

const WAIT_INTERVAL = 15000

export const installAppTypes = {
  auto: 'auto',
  help: 'help',
  manual: 'manual',
}

const AddToHomeScreenTest = (props) => {

  const { installAppType, forceInstall, handleCancel } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ? parentContext : {}
  const { paps_state, page_state, manifest_state, storage_state } = states ? states : {}
  const { appUser_handlers, appSettings_handlers } = handlers ? handlers : {}
  const { pathViews, device } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { homeSettings } = settings ? settings : {}
  const { appInstallation, topMenu } = aps_global ? aps_global : {}
  const { mainFixedCaption } = topMenu ? topMenu : {}
  const { addToHomeScreenText, waitInterval, showHomeScreenPrompt, allowManualAppInstallationFromApp, showInstallationPdf, installationPdf } = appInstallation ? appInstallation : {}
  const { startIcon } = manifest_state ? manifest_state : {}
  const { fileUrl } = startIcon ? startIcon : {}

  const { global: global_home } = homeSettings ? homeSettings : {}
  const { globalAppSettings } = global_home ? global_home : {}
  const { pwaSubTitle, pwaDescription, pwaForceInstallDescription } = globalAppSettings ? globalAppSettings : {}

  const _addToHomeScreenText = addToHomeScreenText ? addToHomeScreenText : 'Add `' + mainFixedCaption + '` to homescreen?'
  const addToHomeScreenItem = eventKey ? 'appInstallation-' + eventKey : 'appInstallation'
  const _waitInterval = waitInterval ? waitInterval * 1000 : WAIT_INTERVAL

  const [promptEvent, setPromptEvent] = useState(_deferredPrompt);
  const [allow, setAllow] = useState()
  const [showHelp, setShowHelp] = useState()
  const [activeSnC, setActiveSnC] = useState()
  const [helpUrl, setHelpUrl] = useState()

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setPromptEvent(event);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    switch (installAppType) {
      case installAppTypes.auto:
        const timer = setTimeout(() => setAllow(true), _waitInterval);
        return () => clearTimeout(timer);
      case installAppTypes.help:
      case installAppTypes.manual:
        setAllow(true)
        break;
      default:
        break;
    }
    // return () => window.removeEventListener("transitionend", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { globalFiles } = storage_state ? storage_state : {}
    const { pdf } = globalFiles ? globalFiles : {}
    const _helpPdf = pdf && pdf[installationPdf]
    const { urls } = _helpPdf ? _helpPdf : {}
    const { full } = urls ? urls : {}
    setHelpUrl(full)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleAddToHomeScreen = () => {
    if (promptEvent) {
      promptEvent.prompt();
      promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User added the PWA to the home screen.');
        } else {
          console.log('User dismissed the PWA installation prompt.');
        }
        setPromptEvent(null);
      });
    }
  };

  const handleClickNo = (e) => {
    e.preventDefault()
    e.stopPropagation()
    switch (installAppType) {
      case installAppTypes.help:
        appSettings_handlers.handleShowHomeScreenHelp()
      default:
        window.localStorage.setItem(addToHomeScreenItem, true)
        appUser_handlers.handleAddToHomePageResponse(addToHomeScreenItem)
    }
  };

  const handleShowHelp = () => setShowHelp(!showHelp)
  const handleShowHelpPdf = () => window.open(helpUrl, '_blank')

  const manIcon = () => <Image src={fileUrl} size={'tiny'} />

  const wrapperNoSupport = () => {
    const _pwaTitle = pwaSubTitle ? pwaSubTitle : 'This device does not allow this app to be loaded automatically.'
    const _pwaDescription = pwaDescription ? pwaDescription : 'For apple devices, click the share button on the Safari menu and scroll to find `Add To Home Screen` – select that and follow the prompts.'
    return <div {...activeSnC}>
      <div>{fileUrl && manIcon()}</div>
      <div>{renderHtml(_pwaTitle)}</div>
      <div>{renderHtml(_pwaDescription)}</div>
    </div>
  }

  const wrapper = () => <Wrapper
    content={wrapperNoSupport()}
    footer={(promptEvent || showInstallationPdf) && <UiSaveButtons
      preview={showInstallationPdf ? { oc: handleShowHelpPdf, caption: 'Help', cn: 'btn-help', icon: 'help' } : null}
    // save={installAppType !== installAppTypes.help && supportsPWA ? { oc: handleClickYes, caption: 'Yes', cn: 'btn-homepage', icon: 'check' } : null}
    />}
    wrapperType={wrapperTypes.padded}
  >
  </Wrapper>

  const fpw = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleShowHelp}
    topperCaption={'Add To Home Screen Help'}
  // halfModal={installAppType === installAppTypes.auto}
  />

  let cn = 'app-homepage'
  if (promptEvent) { cn += ' nh' }

  const content = () => <div className={cn}>
    <Button.Group fluid color={'blue'} size='small'>
      <Button labelPosition='left' icon='delete' onClick={(e) => { handleClickNo(e) }} />
      <Button content={_addToHomeScreenText} onClick={(e) => { handleAddToHomeScreen(e) }} />
      {!promptEvent && <Button labelPosition='right' icon='help' onClick={(e) => { handleShowHelp(e) }} />}
    </Button.Group>
  </div>

  if (showHelp) {
    return fpw()
  } else {
    return allow ? content() : <div></div>
  }
};

export default AddToHomeScreenTest;
