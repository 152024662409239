import _ from 'lodash';
import React, { useContext } from 'react';
import { Label } from 'semantic-ui-react';
import DataProvider from '../cnr/contexts/DataContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import TargetConfirmation from './TargetConfirmation';

export const NotificationItemStatus = (props) => {

  const { notification, statusOnly } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { eventInfo_state } = states
  const { appProfileData, staticViews } = eventInfo_state ? eventInfo_state : {}

  const {
    notificationRecipients,
    selectedTopicGroups,
    targetOptions,
  } = notification ? notification : {}


  const _notification = { ...notification, ...targetOptions }
  const { pageItem, pageItemKey } = targetOptions ? targetOptions : {}

  const staticItemName = staticViews && staticViews[pageItem] && staticViews[pageItem][pageItemKey] ? staticViews[pageItem][pageItemKey].name : null

  const stgs = () => {
    const divs = []
    if (selectedTopicGroups) {
      Object.keys(selectedTopicGroups).forEach(key => {
        const targetGroup = selectedTopicGroups[key]
        const { groupKey, itemKey } = targetGroup
        divs.push(<div>
          <Label color={'green'} size={'tiny'}>{_.startCase(groupKey)}</Label>
          {itemKey && <Label color={'blue'} size={'tiny'}>{_.startCase(itemKey)}</Label>}
        </div>)
      })
    }
    return divs
  }

  const divLine = (propKey, doubleLine, sc, useStatic) => {
    if (_notification && _notification[propKey]) {
      let value = _notification[propKey]
      if (_.isArray(value)) {
        const divs = []
        let av;
        value.forEach(v => {
          if (sc) {
            av = _.startCase(v)
          } else {
            av = v
          }
          divs.push(<div>{av}</div>)
        })
        value = <div className={'nsstp'}>{divs}</div>
      } else {
        if (sc) { value = _.startCase(value) }
        if (useStatic && staticItemName) { value = staticItemName }
      }
      return <div className={'nsi ns-' + propKey + ' ' + (doubleLine ? 'dl' : 'sl')}>
        <div>{_.startCase(propKey)}{doubleLine ? '' : ':'}</div>
        <div>{value}</div>
      </div>
    }
  }

  const notificationStatus = () => <div className={'notification-status'}>
    <div className={'nsi-header'}>{'Notification'} </div>
    {divLine('description', true)}
    {divLine('title', true)}
    {divLine('body', true)}
    {divLine('notificationScheduleType', false, true)}
    {divLine('sendDate')}
    {divLine('sendTime')}
    {divLine('pageItem', false, true)}
    {divLine('pageItemKey', false, false, true)}
    {/* {divLine('recipientItem', false, true)}
    {divLine('subItem', false, true)}
    {divLine('subKey')}
    {divLine('subKeys')}
    {divLine('subType', false, true)}
    {divLine('subTypes', false, true, true)} */}
  </div>

  const confirmationContent = () => <React.Fragment>
    {notificationStatus()}
    <div className={'notification-tg'}>
      {stgs()}
    </div>
    {!statusOnly && <TargetConfirmation
      notificationData={notification}
      fromCreate={true}
      appProfileData={appProfileData}
      selectedTopicGroups={selectedTopicGroups}
      notificationRecipients={notificationRecipients}
    />}
  </React.Fragment>

  return confirmationContent()

}


const NotificationItemStatusWithProvider = (props) => {
  return <DataProvider uivi={'attendees'}   >
    <NotificationItemStatus {...props} />
  </DataProvider>
}

export default NotificationItemStatusWithProvider