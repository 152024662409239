import React, { useContext, useReducer, useState } from 'react';
import { Icon, Input, Label } from 'semantic-ui-react';
import { AppUserDataContext } from "../../cnr/contexts/AppUserDataContext";
import ElementProvider from '../../cnr/contexts/ElementContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { simpleDataHandlers, simpleDataInitialState, simpleDataReducer } from '../../cnr/reducers/SimpleDataReducer';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from "../../common/keys";
import { orderObject } from '../../common/sorting';
import { appFormTypes } from '../../enums/appFormTypes';
import { gEnums } from '../../enums/globalEnums';
import DirectMessage from '../../messages/DirectMessage';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import UiSaveButtons from '../buttons/UiSaveButtons';
import { copyObj } from '../../common_web/copy';

// https://codeburst.io/how-to-add-push-messages-on-firebase-cloud-messaging-to-react-web-app-de7c6f04c920
// https://rharshad.com/web-push-messages-react-firebase/
// https://firebase.google.com/docs/cloud-messaging/js/client

const UserConversations = (props) => {

  const clickedItem = { key: 'conversations' }

  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state, eventInfo_state } = states
  const { navigate } = handlers
  const { page_fns } = fns
  const { pageSettings, viewKey, otherView } = page_state ? page_state : {}
  const { aps_styles, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  // authContext 
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const { senderKey } = authAppUser ? authAppUser : {}

  // appUserDataContext
  const appUserDataContext = useContext(AppUserDataContext);
  const { conversations: oc } = appUserDataContext ? appUserDataContext : {}

  const conversations = orderObject(oc, 'timestamp', true)

  // pageContext 
  const { pushSimple } = page_fns ? page_fns : {}

  // eventInfoContext 
  const { staticViews } = eventInfo_state ? eventInfo_state : {}
  const recipList = staticViews && appUserCollection && staticViews[appUserCollection] ? staticViews[appUserCollection] : {}

  // action
  const [simpleData_state, simpleDataDispatch] = useReducer(simpleDataReducer, simpleDataInitialState(paps_state, props.handleOnClick, page_fns, authAppUser));
  const simpleData_handlers = simpleDataHandlers(simpleDataDispatch, simpleData_state)

  const currentConversation = viewKey ? conversations[viewKey] : null

  const styleAndClass = aps_styles && aps_styles[gEnums.projectStyles.actionButton] ? copyObj(aps_styles[gEnums.projectStyles.actionButton]) : {}
  styleAndClass.className += ' solo'

  const [showAdd, setShowAdd] = useState()
  const [currentMessage, setCurrentMessage] = useState()

  const handleCancel = () => props.handleCancel && props.handleCancel()

  const handleUpdateConversation = () => {
    const itemData = { ...currentConversation }
    const count = Object.keys(currentConversation.messages).length + 1
    itemData.messages[count] = {
      message: currentMessage,
      senderId: senderKey,
      timestamp: new Date()
    }
    simpleData_handlers.updateData('conversations', itemData, viewKey, true)
  }

  const handleNameChange = (e, data) => { setCurrentMessage(data.value) }

  const handleShowAdd = () => {
    if (otherView === 'add') {
      navigate(-1)
    } else {
      setShowAdd(!showAdd)
    }
  }

  const getTimeStamp = (timestamp) => {
    let time_stamp = 'T'
    if (timestamp && timestamp.seconds) {
      time_stamp = formatItem(formatTypes.dateOnly, timestamp.toDate())
    }
    return time_stamp
  }

  const getRecips = (recipients, index) => {
    const sendToo = []
    if (recipList && recipients) {
      recipients.forEach(s => {
        if (recipList[s]) {
          const { firstName, lastName } = recipList[s]
          sendToo.push(<Label key={uniqueKey('ucn', index)} size='mini' color='grey'>{firstName + ' ' + lastName}</Label>)
        }
      })
    }
    return sendToo
  }

  const messageInput = () => {
    return <Input
      size='small'
      fluid
      value={currentMessage}
      onChange={handleNameChange}
      action={{ disabled: !currentMessage, color: 'blue', icon: 'send', onClick: () => { handleUpdateConversation() } }} />
  }

  const messageItem = (message) => {
    const { message: messageText, timestamp, senderId } = message
    let time_stamp = getTimeStamp(timestamp)
    let cn = 'message-line'
    if (senderId === senderKey) { cn += ' au' }
    return <div className={cn} >
      <div className={'message-icon'}><Icon circular name='user' /></div>
      <div className={'message-message'}>{messageText}</div>
      {time_stamp && <div className={'message-date'}>{time_stamp}</div>}
    </div>
  }

  const conversationMessageItems = () => {
    const { messages: om } = currentConversation
    const messages = orderObject(om, 'timestamp', true)
    const items = Object.keys(messages).map(key => messageItem(messages[key]))
    return <div className={'messages-container'}>
      <div className={'messages-content'}>
        {items}
      </div>
      <div className={'messages-footer'}>
        {messageInput()}
      </div>
    </div>
  }

  const conversationItem = (conversation, index) => {

    const { messages, recipients, timestamp } = conversation

    if (messages) {
      const lastMessageKey = Object.keys(messages)[Object.keys(messages).length - 1]
      const lastMessage = messages[lastMessageKey]
      const { message: messageText, senderId } = lastMessage ? lastMessage : {}
      let time_stamp = getTimeStamp(timestamp)
      let cn = 'conversation-line'
      if (senderId === senderKey) { cn += ' au' }

      const item = <ElementProvider
        key={uniqueKey('uc', 'ep')}
        itemData={conversation}
      >
        <div className={cn} onClick={(e) => { pushSimple(clickedItem, conversation.id) }}>
          <div className={'conversation-icon'}><Icon circular large name='user' /></div>
          <div className={'conversation-from'}>{getRecips(recipients, index)}</div>
          <div className={'conversation-message'}>{messageText}</div>
          {time_stamp && <div className={'conversation-date'}>{time_stamp}</div>}
        </div>
      </ElementProvider>

      return item

    }
  }

  const conversationList = () => Object.keys(conversations).map((key, index) => (conversationItem(conversations[key], index)))

  const content = () => {
    if (conversations && Object.keys(conversations).length > 0) {
      if (viewKey && currentConversation) { conversationMessageItems() }
      if (viewKey && !viewKey) { return conversationList() }
      if (!viewKey) { return conversationList() }
    } else {
      return <NoData altCaption={'Chat'} />
    }
  }

  const footer = () => <UiSaveButtons
    save={{ caption: 'Add', oc: handleShowAdd, icon: 'add' }}
  />

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWizard = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={'My Chats'}
  />

  if (showAdd || otherView === 'add') {
    return <DirectMessage appFormType={appFormTypes.messageDirect} handleCancel={handleShowAdd} />
  } else {
    return fullPageWizard()
  }
}

export default UserConversations