import { gEnums } from '../../enums/globalEnums';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fs_set_doc } from '../../firestoreData/appData/fsData';
import { _settingsFs } from '../../viewSettings/actions/getSettings';
import { getGroupDataInfo } from './reducerHelpers/groups';

// http://dptoot.github.io/react-event-calendar/

export const rts = {
  handleAlphaChange: 'handleAlphaChange',
  handleCurrentSelectedFilters: 'handleCurrentSelectedFilters',
  handleDateMenuSelect: 'handleDateMenuSelect',
  handleDropFilterChange: 'handleDropFilterChange',
  handleFilterSidebar: 'handleFilterSidebar',
  handleFilterStatus: 'handleFilterStatus',
  handleGroupFilter: 'handleGroupFilter',
  handleInit: 'handleInit',
  handleItemMenuSelect: 'handleItemMenuSelect',
  handleMaskClick: 'handleMaskClick',
  handleSaveFilter: 'handleSaveFilter',
  handleSearchChange: 'handleSearchChange',
  handleSetFilterMenu: 'handleSetFilterMenu',
  handleShowFilterSave: 'handleShowFilterSave',
  handleUpdateState: 'handleUpdateState',
  handleUpdateViewItem: 'handleUpdateViewItem',
  tabChange: 'tabChange',
}

export const uiFilterReducer = (state, action) => {

  let { selectedTab, filterSidebarOpen } = state
  if (!selectedTab) { selectedTab = {} }

  switch (action.type) {

    case rts.handleInit:
      const { dataFilterz, gdps } = getGroupDataInfo(state, action.viewItem, action.viewListData)
      return {
        ...state,
        ...dataFilterz,
        groupDataProps: gdps,
        ready: true
      }

    case rts.tabChange:
      return { ...state, tabIndex: action.index }

    case rts.handleFilterStatus:
      if (action.filterStatusType === gEnums.filterStatusTypes.none) {
        return { ...state, filterStatusType: action.filterStatusType, selectedFilters: null }
      } else {
        return { ...state, filterStatusType: action.filterStatusType }
      }

    case rts.handleFilterSidebar:
      if (filterSidebarOpen) {
        return { ...state, filterSidebarOpen: false }
      } else {
        return { ...state, filterSidebarOpen: true }
      }

    case rts.handleAlphaChange:
      return {
        ...state,
        alphaValue: action.alpha === 0 ? null : action.alpha,
        alphaSelected: action.alphaSelected,
        filterSidebarOpen: false
      }

    case rts.handleSaveFilter:
      const { data } = action
      const { name } = data ? data : {}
      const { page_state, paps_state, view, selectedFilters } = state
      const { settingsDocName } = paps_state ? paps_state : {}
      const { pageSettings } = page_state ? page_state : {}
      const { aps_viewItems } = pageSettings ? pageSettings : {}
      const viewItem = aps_viewItems ? aps_viewItems[view] : {}
      if (!viewItem.dataFilters) { viewItem.dataFilters = {} }
      viewItem.dataFilters[name] = selectedFilters
      const dataToUpdate = {
        [view]: {
          dataFilters: {
            [name]: selectedFilters
          }
        }
      }
      const _refPath = createRefPath([_settingsFs.root, settingsDocName, _settingsFs.collection, 'viewItems'])
      fs_set_doc(_refPath, dataToUpdate, true)
      return { ...state, showFilterSave: !state.showFilterSave }

    case rts.handleShowFilterSave:
      return { ...state, showFilterSave: !state.showFilterSave }

    case rts.handleCurrentSelectedFilters:
      return { ...state, selectedFilters: action.selectedFilters }

    case rts.handleDateMenuSelect:
      return { ...state, selectedTab: action.item }

    case rts.handleItemMenuSelect:
      return { ...state, selectedTab: action.item }

    case rts.handleDropFilterChange:
      return { ...state, filterValues: action.filterValues }

    case rts.handleSearchChange:
      return { ...state, searchValue: action.searchValue }

    case rts.handleGroupFilter:
      return { ...state, selectedFilters: action.selectedFilters, filterStatusType: gEnums.filterStatusTypes.active }

    case rts.handleUpdateState:
      return { ...action.updatedState }

    case rts.handleUpdateViewItem:
      return { ...state, viewItem: action.viewItem }

    default:
      return { ...state }
  }
}

/** Sets the initial state for Filter Reducer */
export const uiFilterInitialState = (filter_init) => {
  return { tabIndex: 0, ...filter_init }
};

export const uiFilterHandlers = (dispatch, state) => {
  return {
    /** Commits the new item value` */
    handleAlphaChange: (alpha, alphaSelected) => { dispatch({ type: rts.handleAlphaChange, alpha, alphaSelected }) },
    handleCurrentSelectedFilters: (selectedFilters) => { dispatch({ type: rts.handleCurrentSelectedFilters, selectedFilters }) },
    handleDateMenuSelect: (item, index) => { dispatch({ type: rts.handleDateMenuSelect, item, index }) },
    handleDropFilterChange: (filterValues) => { dispatch({ type: rts.handleDropFilterChange, filterValues }) },
    handleFilterSidebar: () => { dispatch({ type: rts.handleFilterSidebar, dispatch }) },
    handleFilterStatus: (filterStatusType) => { dispatch({ type: rts.handleFilterStatus, filterStatusType }) },
    handleGroupFilter: (selectedFilters) => { dispatch({ type: rts.handleGroupFilter, selectedFilters }) },
    handleInit: (viewItem, viewListData) => { dispatch({ type: rts.handleInit, viewItem, viewListData }) },
    handleItemMenuSelect: (item) => { dispatch({ type: rts.handleItemMenuSelect, item }) },
    handleMaskClick: () => { dispatch({ type: rts.handleMaskClick }) },
    handleSaveFilter: (data) => { dispatch({ type: rts.handleSaveFilter, data }) },
    handleSearchChange: (value) => { dispatch({ type: rts.handleSearchChange, searchValue: value }) },
    handleSetFilterMenu: (value) => { dispatch({ type: rts.handleSetFilterMenu }) },
    handleShowFilterSave: (value) => { dispatch({ type: rts.handleShowFilterSave }) },
    handleTabChange: () => { dispatch({ type: rts.tabChange }) },
    handleUpdateState: (updatedState) => { dispatch({ type: rts.handleUpdateState, updatedState }) },
    handleUpdateViewItem: (viewItem) => { dispatch({ type: rts.handleUpdateViewItem, dispatch, viewItem }) },
  }
} 