import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { Button, Icon, Label } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext'

const _allowRtScoring = true

const MatchScoreDisplay = (props) => {

  const {
    allowScoreEdits,
    display,
    handleShowMatchScoreTable,
    key_match,
    keyy,
    latestMatchesOnly,
    match_rt,
  } = props

  const { setNumber } = match_rt ? match_rt : {}

  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_handlers } = sportsRtMatchContext ? sportsRtMatchContext : {}

  const [showScoring, setShowScoring] = useState()

  const handleScoreUpdate = (e, rtType, add) => {
    e.stopPropagation()
    sportsRtMatch_handlers.handleRt_update(match_rt, rtType, add)
    setShowScoring(false)
  }

  const handleMatchRtClick = (e) => {
    e.stopPropagation()
    if (_allowRtScoring) {
      setShowScoring(!showScoring)
    } else {
      handleShowMatchScoreTable(e)
    }
  }

  const handleMatchClick = (e) => {
    e.stopPropagation(e)
    handleShowMatchScoreTable(e)
  }

  const teamSetLabel = (score, t) => {
    let cn = latestMatchesOnly ? 'team-score-' + t : t
    return <Label className={cn} key={uniqueKey('ms', 'tsl', t)} size={'mini'}>{score.score}</Label>
  }

  const teamSetButtons = (score, team, t) => {
    let cn = latestMatchesOnly ? 'team-score-' + t : t
    return <Button.Group size='mini' >
      <Button icon='arrow left' color='red' onClick={(e) => { handleScoreUpdate(e, team, false) }} />
      <Button content={score.score} className={cn} color='black' />
      <Button icon='arrow right' color='green' onClick={(e) => { handleScoreUpdate(e, team, true) }} />
    </Button.Group>
  }

  const teamScores = (score, team, t) => showScoring && allowScoreEdits ? teamSetButtons(score, team, t) : teamSetLabel(score, t)

  const matchLabel = (display) => <Label
    className={latestMatchesOnly ? 'match-display-score' : null}
    key={uniqueKey(key_match, keyy)}
    onClick={(e) => { allowScoreEdits && handleMatchRtClick(e) }}>
    {display}
  </Label>

  const setButtons = (display) => {
    return <Button.Group size='mini' >
      <Button icon='arrow left' color='red' onClick={(e) => { handleScoreUpdate(e, 'set', false) }} />
      <Button content={display} color='black' />
      <Button icon='arrow right' color='green' onClick={(e) => { handleScoreUpdate(e, 'set', true) }} />
    </Button.Group>
  }

  const matchSets = (display) => showScoring && allowScoreEdits ? matchLabel(display) : matchLabel(display)

  const matchDisplay = () => {

    if (match_rt) {
      const { score, scores } = match_rt
      const { home, away } = score ? score : {}
      const _display = away + '-' + home
      const rtScores = {
        home: 0,
        away: 0,
        setNumber: 0
      }
      if (scores) {
        Object.keys(scores).forEach((sk, index) => {
          const _score = scores[sk]
          if (_score.home || _.isNumber(_score.home)) {
            rtScores.home = {
              score: _score.home,
              color: _score.home > _score.away ? 'green' : _score.home < _score.away ? 'red' : 'blue',
              cn: _score.home > _score.away ? 'up' : _score.home < _score.away ? 'down' : 'tie'
            }
            rtScores.setNumber = index
          }
          if (_score.away || _.isNumber(_score.away)) {
            rtScores.away = {
              score: _score.away,
              color: _score.away > _score.home ? 'green' : _score.away < _score.home ? 'red' : 'blue',
              cn: _score.away > _score.home ? 'up' : _score.away < _score.home ? 'down' : 'tie'
            }
            rtScores.setNumber = index
          }
        })
      }

      return <div key={uniqueKey(key_match, keyy)} className='match-display' onClick={(e) => { allowScoreEdits && handleMatchRtClick(e) }}>
        {teamScores(rtScores.away, 'away', 'away ' + rtScores.away.cn)}
        {matchSets(_display)}
        {teamScores(rtScores.home, 'home', 'home ' + rtScores.home.cn)}
      </div>
    } else {
      return <div key={uniqueKey(key_match, keyy)} className='match-display' onClick={(e) => { allowScoreEdits && handleMatchClick(e) }}>{display}</div>
    }

  }

  return matchDisplay()
}

export default MatchScoreDisplay