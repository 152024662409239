import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { AppUserContext } from '../../../../global/cnr/contexts/AppUserContext';
import { createInitials } from '../../../../global/common/dataAdjust';
import { formatItem, formatTypes, timeConvertor } from '../../../../global/common/dateFormatting';
import { uniqueKey } from '../../../../global/common/keys';
import { gEnums } from '../../../../global/enums/globalEnums';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import CreateMatchResult from '../../create/CreateMatchResult';
import { getSectionColor } from '../../create/sectionColors';
import MatchScoreTable from './MatchScoreTable';
import PoolMatch from './PoolMatch';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import MatchScoreDisplay from './MatchScoreDisplay';
import { getFirstObject } from '../../../../global/common/convert';
import { MatchSummaryContext } from './MatchSummary';
import { useElementOnScreen } from '../../../../global/components/sem/visibleElements';

const key_match = 'ms-match-score'
const key_away = 'ms-match-away'
const key_home = 'ms-match-home'
const key_round = 'ms-match-round'
const key_loc = 'ms-match-loc'
const _usePlayoff = false
const _allowByes = true
const _opponentOnly = false

const MatchSingle = (props) => {

  // match is the actual match
  // current match is the selectedMatch
  const { match, teamKey, playoffDates, tournamentType, latestMatchesOnly, nextMatchOnly } = props
  let { wcn } = props

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states
  const { appUser } = appUser_state ? appUser_state : {}
  const { pathViews } = paps_state ? paps_state : {}

  const appUserAccess = getAppUserAccess(appUser)
  const { itemAccess } = appUserAccess ? appUserAccess : {}

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_fns } = appUserContext ? appUserContext : {}
  const accessLevel = appUser_fns.get_accessLevel()

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info, useScoreBlock, useScoreTeamBlock, teams_info, sportsKey } = sportsSeason_state ? sportsSeason_state : {}
  const { teams } = teams_info ? teams_info : {}
  const { matches_realtime } = matches_info ? matches_info : {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state, seasonMatches_handlers } = seasonMatchesContext ? seasonMatchesContext : {}
  const { viewItem, isTournament } = seasonMatches_state ? seasonMatches_state : {}
  const { handleMatchClick } = seasonMatches_handlers ? seasonMatches_handlers : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_true, match_current, showMatchScoreTable } = sportsMatch_state ? sportsMatch_state : {}

  const matchSummaryContext = useContext(MatchSummaryContext)
  const { matchSummary_state } = matchSummaryContext ? matchSummaryContext : {}
  const { isSummary } = matchSummary_state ? matchSummary_state : {}

  const [rtMatchData, setRtMatchData] = useState()
  const { match_rt, match_rt_scorer } = rtMatchData ? rtMatchData : {}

  const [divRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 1.0 })

  // match_true
  const {
    _itemKey,
    key,
    away,
    cancelled,
    home,
    isBye,
    isConsolation,
    isPast,
    isPlayoff,
    levels,
    location,
    ls,
    matchNumber,
    matchName,
    pending,
    poolKey,
    postponed,
    results_current,
    results,
    sections,
    seeds,
    startDate,
    startTime,
  } = match_true ? match_true : {}

  const { home: home_pending, away: away_pending } = pending ? pending : {}
  const _isPlayoff = isPlayoff && _usePlayoff ? true : false
  const _results = results_current ? results_current : results
  const { complete } = _results ? _results : {}
  const { winner, hasScores, score, scoreDisplay } = _results ? _results : {}

  let _ls = ls

  if (isPlayoff) {
    if (isConsolation) {
      _ls = _ls.replace('Finals', 'Consulation')
    } else if (isPlayoff) {
      if (poolKey !== 'finals') {
        _ls += ' - #' + matchNumber
      }
    }
  }

  const lsColor = sections === 'Non' || levels === 'Non' ? 'grey' : 'blue'

  useEffect(() => {
    const tm = match_current && (match_current.key === match.key) ? match_current : match
    sportsMatch_handlers.handleSetTrueMatch(tm)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_current, match]);

  useEffect(() => {
    // these are all of the realtimeMatches
    if (matches_realtime) {
      const rtm_currentMatch = matches_realtime ? matches_realtime[_itemKey] : {}
      if (rtm_currentMatch) {
        const rtm = getFirstObject(rtm_currentMatch)
        const { item } = rtm ? rtm : {}
        const { displayName, results } = item ? item : {}
        if (results) {
          setRtMatchData({ match_rt: results, match_rt_scorer: displayName })
        } else {
          setRtMatchData({ match_rt: null, match_rt_scorer: null })
        }
      } else {
        setRtMatchData({ match_rt: null, match_rt_scorer: null })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, _itemKey]);

  /**
   * handles the click to show the score table
   * @param {object} e 
   */
  const handleShowMatchScoreTable = (e) => {
    // e && e.preventDefault()
    // e && e.stopPropagation()
    // if (hasScores) {
    //   sportsMatch_handlers.handleShowMatchScoreTable(match)
    // } else { 
    sportsMatch_handlers.handleEditScore(match_true)
    // }
  }

  const handleEditScore = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    sportsMatch_handlers.handleEditScore(match_true)
  }

  const _teamKey = teamKey ? teamKey : pathViews.teams
  const teamOnly = teamKey || (pathViews && pathViews.teams) ? true : false
  const matchOnly = pathViews && pathViews.matches ? true : false

  const teamSetLabel = (score, t) => <Label className={latestMatchesOnly ? 'team-score-' + t : t} key={uniqueKey('ms', 'tsl', t)} size={'tiny'}>{score.score}</Label>
  const playoffIcon = () => <Icon color={'grey'} name={'caret right'} />
  const locationIcon = (home) => <Icon color={home.id === pathViews.teams ? 'teal' : 'orange'} name={home.id === _teamKey ? 'home' : 'bus'} />
  const locationPlayoffIcon = () => <Icon color={'grey'} name={'map marker alternate'} />
  const trophyIcon = () => <Icon color={'grey'} name={'trophy'} />
  const seedLabel = (seed, section) => <Label size={'mini'} color={getSectionColor(section)}>{seed}</Label>

  const handle_matchClick = (key, d) => {
    if (latestMatchesOnly) {
      handleMatchClick({ key: 'matches' }, key, null, d)
    } else {
      handleMatchClick(viewItem, key, null, d)
    }
  }

  const _matchCns = {
    awayTeam: 'match-away',
    homeTeam: 'match-home',
    opponentFull: 'match-opponent-full',
    opponent: 'match-opponent',
    matchDate: 'match-date',
    sectionLevel: 'match-sl',
    lastDate: 'match-last-date',
    status: 'match-score-status',
    awayScore: 'match-away-score',
    homeScore: 'match-home-score',
    locationIcon: 'match-location',
    trophy: 'match-trophy',
    result: 'match-result',
  }

  const divsToShow = {
    dateAndSection: (teamOnly || matchOnly) && !_isPlayoff
  }

  const scoreDiv = (value, cn, icon) => {
    if (icon) {
      return <div className={cn}><Icon name={icon} /></div>
    } else {
      return <div className={cn}>{value}</div>
    }
  }

  const teamScoreBlockDiv = (display, _opponent, _matchIcon) => {
    const divs = []
    divs.push(scoreDiv(_opponent, _matchCns.opponentFull))
    divs.push(scoreDiv(null, _matchCns.locationIcon, _matchIcon))
    divs.push(scoreDiv(display, _matchCns.result))
    return divs
  }

  const scoreBlockDiv = (awayScore, homeScore) => {
    const divs = []
    divs.push(<div className={_matchCns.awayScore}>{awayScore.score}</div>)
    divs.push(<div className={_matchCns.homeScore}>{homeScore.score}</div>)
    divs.push(<div className={_matchCns.status}>{complete ? 'Final' : startTime}</div>)
    return divs

  }
  const getMatchDisplay = () => {

    let showPast = false

    if (accessLevel >= gEnums.authLevels.admin.value) {
      if (isPast && (!_results && !postponed && !cancelled)) {
        showPast = true
      }
    }

    let _allowScoreEdits = accessLevel >= gEnums.accessLevels.appSubAdmin.value

    let _matchDisplay = 'match-display'

    // if (_allowScoreEdits) { _matchDisplay += ' ae' }
    // if (match_rt && !complete) { _matchDisplay += ' rt' }
    // if (complete) { _matchDisplay += ' complete' }
    // if (showPast) { _matchDisplay += ' past' }
    // if (hasScores) { _matchDisplay += ' score-available' }

    if (!matchName) {
      _matchDisplay += ' nr'
      _allowScoreEdits = false
    }

    if (latestMatchesOnly) {
      _allowScoreEdits = false
    }

    return { cn_matchDisplay: _matchDisplay, allowScoreEdits: _allowScoreEdits }
  }

  let { allowScoreEdits } = getMatchDisplay()

  const matchInProgressDisplay = (display, allowScoreEdits) => <MatchScoreDisplay
    allowScoreEdits={allowScoreEdits}
    display={display}
    handleShowMatchScoreTable={handleShowMatchScoreTable}
    key_match={key_match}
    keyy={key}
    latestMatchesOnly={latestMatchesOnly}
    match_rt={!complete && match_rt}
  />

  const getOpponent = () => {
    let opponent;
    if (home && home.id && away && away.id) {
      if (home && home.id && _teamKey === home.id) {
        opponent = away.name
      } else if (away && away.id && _teamKey === away.id) {
        opponent = home.name
      }
    }
    return opponent
  }

  const matchDisplayDiv = (asLabel, allowScoreEdits) => {

    const _opponent = getOpponent()
    const _matchIcon = home && home.id === _teamKey ? 'home' : 'bus'

    const awayScore = {
      score: score ? score.away : useScoreBlock ? '' : '-',
      color: score && score.away > score.home ? 'green' : 'grey',
      winner: score && score.away > score.home ? 'winner' : ''
    }

    const homeScore = {
      score: score ? score.home : useScoreBlock ? '' : '-',
      color: score && score.home > score.away ? 'green' : 'grey',
      winner: score && score.home > score.away ? 'winner' : ''
    }

    const cn_matchDisplay = 'match-display'

    let _startTime;
    const { hour, min } = startTime ? timeConvertor(startTime) : {}
    const dt = new Date()
    hour && dt.setHours(hour)
    min && dt.setMinutes(min)
    _startTime = dt ? formatItem(formatTypes.time, dt) : '--'

    let display = scoreDisplay ? scoreDisplay : _startTime

    if (postponed) { display = 'ppd' }
    if (cancelled) { display = 'canc' }
    if (!display) { display = 'res' }

    if (teamOnly && complete) {
      if (useScoreTeamBlock) {
        return teamScoreBlockDiv(display, _opponent, _matchIcon)
      } else {
        if (asLabel) {
          return <div key={uniqueKey(key_match, key)} className={cn_matchDisplay} ><Label onClick={(e) => { allowScoreEdits && handleShowMatchScoreTable(e) }}>{display}</Label></div>
        } else {
          return <div key={uniqueKey(key_match, key)} className={cn_matchDisplay} onClick={(e) => { allowScoreEdits && handleShowMatchScoreTable(e) }}>{display}</div>
        }
      }
    } else {
      if (useScoreBlock) {
        return scoreBlockDiv(awayScore, homeScore)
      } else {
        return matchInProgressDisplay(display, allowScoreEdits)
      }
    }
  }

  const opponentDiv = (cnAway, key, opponent) => <div key={uniqueKey(key_away, key)} className={cnAway}><span>{opponent}</span></div>
  const rankLabel = (rank) => <Label className={'lbl-rnk'} color='grey' circular size='mini'>{rank}</Label>

  const awayDiv = (cnAway, key, rank) => {
    if (isPlayoff) {
      return <div key={uniqueKey(key_away, key)} className={cnAway}>{seedLabel(away.seed ? away.seed : '-', away.sections)}<span>{away.name}</span></div>
    } else {
      return <div key={uniqueKey(key_away, key)} className={cnAway}><span>{away.name}{rank ? rankLabel(rank) : null}</span></div>
    }
  }

  const homeDiv = (cnHome, key, rank) => {
    if (isPlayoff) {
      return <div key={uniqueKey(key_home, key)} className={cnHome}><span>{home.name}</span>{seedLabel(home.seed ? home.seed : '-', home.sections)}</div>
    } else {
      return <div key={uniqueKey(key_home, key)} className={cnHome}><span>{rank ? rankLabel(rank) : null}{home.name}</span></div>
    }
  }

  const pendingAwayDiv = (pending, cnAway, key) => {
    const { poolName, matchNumber, place, originalSeed } = pending
    const value = poolName + ' #' + matchNumber + '/' + place
    return <div key={uniqueKey(key_away, key)} className={cnAway}>{seedLabel(originalSeed ? originalSeed : '-')}{value}</div>
  }

  const pendingHomeDiv = (pending, cnHome, key) => {
    const { poolName, matchNumber, place, originalSeed } = pending
    const value = poolName + ' #' + matchNumber + '/' + place
    return <div key={uniqueKey(key_home, key)} className={cnHome}>{value}{seedLabel(originalSeed ? originalSeed : '-')}</div>
  }

  const playoffDiv = () => {
    const playoffCaption = poolKey === 'finals' ? '' : '/#' + matchNumber
    let loc = location ? location.name : null
    if (!loc) { loc = home ? home.name : '---' }
    const divs = []
    divs.push(<div key={uniqueKey(key_loc, key, 'ipl')} className={_matchCns.trophy}>{trophyIcon()}{'Playoff'}</div>)
    divs.push(<div key={uniqueKey(key_loc, key)} className={_matchCns.locationIcon}>{locationPlayoffIcon()}{loc}</div>)
    return divs
  }

  const dateAndSectionDivs = () => {
    return [
      <div key={uniqueKey('ms', 'sm', 1)} className={_matchCns.matchDate}>{teamOnly ? locationIcon(home) : null} {formatItem(formatTypes.dateDayMonth, startDate)}</div>,
      <div key={uniqueKey('ms', 'sm', 2)} className={_matchCns.sectionLevel}><Label color={lsColor} size={'mini'}>{_ls}</Label></div>
    ]
  }

  const matchScoreTable = () => <div key={uniqueKey('full', 'score', key_match)} className={'match-full-score'}>
    <MatchScoreTable key={match_true.id} match_current={match_true} handleShowScore={handleEditScore} />
  </div>

  const match_away = (rank) => {
    if (away) {
      return awayDiv(_matchCns.awayTeam, key, rank)
    } else if (away_pending) {
      return pendingAwayDiv(away_pending, _matchCns.awayTeam, key)
    }
  }

  const match_home = (rank) => {
    if (home) {
      return homeDiv(_matchCns.homeTeam, key, rank)
    } else if (home_pending) {
      return pendingHomeDiv(home_pending, _matchCns.homeTeam, key)
    }
  }

  const match_sectionSingleLine = (ranks, allowMatchEdit) => {
    const m = []
    isPlayoff && m.push(<div key={uniqueKey('ms', 'sm', 2)} className={_matchCns.sectionLevel}><Label color={lsColor} size={'mini'}>{_ls}</Label></div>)
    m.push(match_away(ranks.away))
    !_isPlayoff && m.push(matchDisplayDiv(null, allowScoreEdits, allowMatchEdit))
    m.push(match_home(ranks.home))
    return m
  }

  const match_teamSingleLine = (opponent, allowMatchEdit, ranks) => {
    const m = []
    m.push(dateAndSectionDivs())
    m.push(match_away(ranks.away))
    !_isPlayoff && m.push(matchDisplayDiv(null, allowScoreEdits, allowMatchEdit))
    m.push(match_home(ranks.home))
    // !_isPlayoff && m.push(matchDisplayDiv(false, allowScoreEdits, allowMatchEdit))
    return m
  }

  const singleMatch = () => {

    const segs = []

    if ((isTournament || playoffDates || match_true.matchNumber) && tournamentType) {
      const pm = <PoolMatch match={match_true} match_current={match_current} wcn={wcn} />
      segs.push(pm)
      return segs
    }

    const m = []

    if ((home || home_pending) && (away || away_pending)) {

      let isPending = (!home || !away) ? true : false

      const _home = home_pending ? home_pending : home
      const _away = away_pending ? away_pending : away

      const homeSeed = seeds ? seeds['home'] : null
      const awaySeed = seeds ? seeds['away'] : null

      const _opponent = getOpponent()

      const teamIsHome = _home.id === _teamKey
      const away_upset = awaySeed > homeSeed ? true : false

      const allowMatchEdits = {
        match: (itemAccess && ((_home && itemAccess.includes(_home.id)) || (_away && itemAccess.includes(_away.id)))) ? true : false,
        sport: (itemAccess && itemAccess.includes(sportsKey)) ? true : false,
        any: false
      }

      if (allowMatchEdits.match || allowMatchEdits.sport) {
        allowMatchEdits.any = true
      }

      const ranks = {
        home: null,
        away: null
      }

      if (!isPast && _home && _home.id && teams && teams[_home.id] && teams[_home.id].currentRank) { ranks.home = teams[_home.id].currentRank }
      if (!isPast && _away && _away.id && teams && teams[_away.id] && teams[_away.id].currentRank) { ranks.away = teams[_away.id].currentRank }

      if (!latestMatchesOnly) {

        if (winner && winner.id === away.id) {
          _matchCns.awayTeam += ' winner'
          if (_isPlayoff && away_upset && 1 === 3) { _matchCns.awayTeam += ' upset' }
        }

        if (winner && winner.id === home.id) {
          _matchCns.homeTeam += ' winner'
        }

        if (teamOnly) {
          m.push(match_teamSingleLine(_opponent, allowMatchEdits.any, ranks))
        } else {
          m.push(match_sectionSingleLine(ranks, allowMatchEdits.any))
        }

        // if (match_rt_scorer) { m.push(match_rt_scorer) }

        if (isPlayoff && !isBye) {
          m.push(playoffDiv())
          hasScores && showMatchScoreTable && m.push(matchScoreTable())
        }
      } else {
        const _opponentName = createInitials(_opponent, 3, true)
        m.push(matchDisplayDiv(null, allowScoreEdits))
        { !isSummary && m.push(<div className={_matchCns.lastDate}>{formatItem(formatTypes.dateDayMonth, startDate)}</div>) }
        m.push(<div className={_matchCns.opponent}>{_opponentName}</div>)
      }

      if (winner) {
        wcn += winner.id === _teamKey ? ' win' : ' loss'
        wcn += winner.id === home.id ? ' wh' : ' wa'
      }


      if (match_rt && !complete) { wcn += ' rt' }
      if (complete) { wcn += ' complete' }
      if (!complete) { wcn += ' future' }
      if (isPending) { wcn += ' pending' }
      if (isBye) { wcn += ' bye' }
      if (allowScoreEdits) { wcn += ' ae' }
      if (allowMatchEdits.any) { wcn += ' ame' }
      if (allowMatchEdits.sport) { wcn += ' ase' }

      if (teamIsHome) {
        wcn += ' tih'
      } else {
        wcn += ' tia'
      }

      if (_opponentOnly) { wcn += ' oo' }

      segs.push(<div
        ref={divRef}
        key={uniqueKey('ms', 'd', _itemKey)}
        className={wcn}
        onClick={() => { handle_matchClick(_itemKey, { itemData: match_true }) }}>
        {m}
      </div>)

      if (match_current && match_current.key === match.key) {
        segs.push(<CreateMatchResult match_current={match_current} includePlayers={true} />)
      }
    }

    return segs
  }

  return match_true ? singleMatch() : <div></div>

}

export default MatchSingle