import React, { useContext, useEffect, useReducer, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { ActionContext } from '../../../cnr/contexts/ActionContext';
import DataModifyProvider from '../../../cnr/contexts/DataModifyContext';
import { FirestoreContext } from '../../../cnr/contexts/FirestoreContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { ViewSettingsComponentContext } from '../../../cnr/contexts/ViewSettingsComponentContext';
import { ViewSettingsContext } from '../../../cnr/contexts/ViewSettingsContext';
import { dataModifyHandlers, dataModifyInitialState, dataModifyReducer } from '../../../cnr/reducers/DataModifyReducer';
import { uniqueKey } from '../../../common/keys';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import AppForm from '../../../components/forms/appForms/AppForm';
import Uploader from '../../../components/uploading/Uploader';
import UserAccess from '../../../components/user/UserAccess';
import { appFormTypes } from '../../../enums/appFormTypes';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';
import { eventOptionsList } from '../../../optionLists/eventOptions';
import CreateSomething from '../../../viewSettings/create/CreateSomething';
import AllHeaders from '../../../viewSettings/headers/AllHeaders';
import { createPropsFromData, createPropsFromItem } from '../../../viewSettings/helpers/settingsHelpers';
import { openFirebaseDb } from '../../../viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import UiUpload from '../dataManagement/UiUpload';
import { DataModificationsContext } from '../UiDataModifications';
import DataItemModify from './DataItemModify';
import { QrScanContext } from '../pageItemActions/QrScan';

/** Swiper containing the data plus and objects that can be swiped.
 * @returns DataItemModify(s) or 
 * @calledFrom UiAddEdit
  */
export const DataModifySwiper = (props) => {

  const _amts = gEnums.addModeTypes

  const {
    allowProdDataEdit,
    editableItem,
    dataUpdateType,
    handleCancel,
    data_initial,
    ignoreItemStatus,
    isDirect,
    lockedData,
    modifyProps,
    selectedKey,
    useWizard,
    viewItem: viewItem_props,
  } = props

  let inModal = false

  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ? parentContext : {}
  const { appForms_state, paps_state, page_state, appUser_state, eventInfo_state } = states
  const { view: currentView, viewKey: currentViewKey } = paps_state ? paps_state : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const { staticViewKeys } = eventInfo_state ? eventInfo_state : {}
  const { navigate } = handlers

  const appUserAccess = getAppUserAccess(appUser)

  // appFormsContext
  const { appForms } = appForms_state ? appForms_state : {}

  // pageContext
  const { pageSettings, pageNav } = page_state ? page_state : {}
  const { navUiItemContext } = pageNav ? pageNav : {}
  const { aps_global, aps_viewItems, aps_page } = pageSettings ? pageSettings : {}
  const { dataRestrictions } = aps_global ? aps_global : {}
  const { viewItems: viewItems_page } = aps_page ? aps_page : {}

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext);

  // viewSettingsComponentContext 
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { swipedItem } = vsc_state ? vsc_state : {}
  const { settingKey } = swipedItem ? swipedItem : {}

  // actionContext    
  const actionContext = useContext(ActionContext);
  const { action_state } = actionContext ? actionContext : {}
  const { questionProps: actionDataCommit } = action_state ? action_state : {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext)

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const _uiItemContext = navUiItemContext ? navUiItemContext : uiItemContext
  const { item_handlers, item_state } = _uiItemContext ? _uiItemContext : {}
  const { showSingleItemEdit } = item_state ? item_state : {}

  // dataModificationsContext ***
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_state, dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}
  const { dataModificationItems, updating, confirmation, data_current, showAdditional } = dataModifications_state ? dataModifications_state : {}
  const { add: add_dmi, edit: edit_dmi } = dataModificationItems ? dataModificationItems : {}

  // qrContext
  const qrContext = useContext(QrScanContext)
  const { qrScan_state } = qrContext ? qrContext : {}
  const { scannedData } = qrScan_state ? qrScan_state : {}

  if (item_handlers) { inModal = true }

  // local
  const vit = viewItem_props ? viewItem_props.key : null
  const viewItem_app = vit && aps_viewItems && aps_viewItems[vit] ? aps_viewItems[vit] : null
  const viewItem_page = vit && viewItems_page && viewItems_page[vit] ? viewItems_page[vit] : null
  const { dataSource, dataModifications } = viewItem_page ? viewItem_page : {}
  const { subDataCollectionName, useSubDataCollection } = dataSource ? dataSource : {}
  const { edit, add } = dataModifications ? dataModifications : {}

  const _add = add_dmi ? add_dmi : add
  const _edit = edit_dmi ? edit_dmi : edit

  const { allowImage: allowImage_edit, allowAdditionalProps: allowAdditionalProps_edit } = _edit ? _edit : {}
  const { allowImage: allowImage_add, allowAdditionalProps: allowAdditionalProps_add } = _add ? _add : {}

  const _allowImage = allowImage_add || allowImage_edit
  const _allowAdditionalProps = allowAdditionalProps_add || allowAdditionalProps_edit

  let _data_initial = data_initial ? data_initial : {}
  _data_initial = scannedData ? { ..._data_initial, ...scannedData } : _data_initial

  const { name, firstName, lastName } = _data_initial ? _data_initial : {}

  let _subCaption = name
  if (!_subCaption && firstName && lastName) { _subCaption = firstName + ' ' + lastName }
  if (!_subCaption && currentView && currentViewKey) {
    _subCaption = 'to ' + currentView + ' (' + currentViewKey + ')'
  }

  let isRestricted = false;

  switch (dataUpdateType) {
    case gEnums.dataUpdateTypes.edit:
      if (dataRestrictions && dataRestrictions.editData) {
        isRestricted = true
      }
      break;
    default:
      break;
  }

  const [optionsList] = useState(eventOptionsList())

  const si = {
    caption: _data_initial && _data_initial.name ? _data_initial.name : '',
    dKey: vit ? vit : null,
    key: _data_initial ? _data_initial.id : null,
  }

  // STATE    
  const [cdr] = useState()
  const [showUpload, setShowUpload] = useState()

  // DATA MODIFY REDUCER 
  const componentContexts = { firestoreContext, viewSettingsContext }

  const init_state = {
    allowProdDataEdit,
    appUser,
    allowImage: _allowImage,
    addModeType: _amts.normal,
    componentContexts,
    data_initial: _data_initial,
    data_modified: _data_initial,
    dataUpdateType,
    editableItem,
    handleCancel,
    isDirect,
    lockedData,
    navigate,
    fns,
    states,
    staticViewKeys,
    selectedKey,
    swipedItems: [si],
    useWizard,
    viewItem_props,
    viewItem: viewItem_app,
    vit,
  }

  // dataModify - reducer
  const [dataModify_state, dataModify_dispatch] = useReducer(dataModifyReducer, dataModifyInitialState(init_state));
  const dataModify_handlers = dataModifyHandlers(dataModify_dispatch, dataModify_state)
  const { itemProps, swipedItems, data_modified, selectedDataItems, questionProps: dataCommitData, selectedIndex, addModeType, data_modified_ready } = dataModify_state

  useEffect(() => {
    dataModify_handlers.handleGetDataModifyProps(appForms, aps_viewItems, viewItem_page, modifyProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [modifyProps]);

  useEffect(() => {
    if (dataModifications_handlers) { dataModifications_handlers.updateCurrentFormData(data_modified, data_modified_ready) }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [data_modified]);

  const handleOpenDb = () => {
    if (data_current && data_current._itemKey) {
      openFirebaseDb(paps_state, vit, data_current._itemKey)
    } else {
      openFirebaseDb(paps_state, vit)
    }
  }

  // SAVE_DATA
  // IMPORTANT: Data - UPDATES the data to the database 
  const handleUpdate_dataModifications = () => {

    if (dataModifications_handlers) {
      dataModifications_handlers.handleStartUpdate()
      if (selectedDataItems) {
        dataModifications_handlers.handleUpdateDataItems(selectedDataItems)
      } else {
        switch (dataUpdateType) {
          case gEnums.dataUpdateTypes.add:
            dataModifications_handlers.handleAddDataItem()
            break;
          case gEnums.dataUpdateTypes.edit:
            dataModifications_handlers.handleUpdateDataItem()
            break;
          default:
            break;
        }
      }
    }
  }

  const handleDelete = () => dataModifications_handlers.handleDeleteDataItems()

  /** Updates the data  */
  const handleUpdateDataFromHeader = () => {
    if (cdr && cdr.combinedItems) {
      const newProps = createPropsFromData(cdr.combinedItems)
      // remove existing
      Object.keys(newProps).forEach(k => {
        if (viewItem_app.props[k]) { delete newProps[k] }
      })
    }

    switch (addModeType) {
      case _amts.normal:
        break;
      case _amts.dataTable:
      case _amts.googleSheets:
      case _amts.googleSheetsOnly:
      case _amts.pasteImport:
        return false
      default:
    }
    dataModify_handlers.handleUpdateAppFirestore()
  }

  const handleShowUploader = () => setShowUpload(!showUpload)
  const handleShowAdditional = () => dataModifications_handlers.handleShowAdditional()
  const handleAddProp = (fd) => dataModifications_handlers.handleAddProp(fd)

  /**
   * 
   * @returns the content for the `swipeableViews`
   */
  const swipeableContent = () => {
    const divz = []
    if (swipedItems.length > 0) {
      swipedItems.forEach((si, index) => {
        let { dataUpdateType } = si
        let md = { ...data_modified }
        if (!dataUpdateType) { dataUpdateType = gEnums.dataUpdateTypes.edit }
        if (dataUpdateType === gEnums.dataUpdateTypes.add) { md = {} }
        let prs = {}
        if (index === 0) {
          prs = itemProps
        } else {
          createPropsFromItem(md, prs, optionsList, true)
        }
        if (modifyProps) { prs = modifyProps }

        if (si.isImage) {
          divz.push(<Uploader
            origin={'dataModifySwiper'}
            storageLocationType={gEnums.storageLocationTypes.event}
            swipedItem={si}
          />)
        } else if (si.isAccess) {
          divz.push(<UserAccess itemData={md} />)
        } else {
          if (prs) {
            divz.push(<DataItemModify
              key={uniqueKey('dms')}
              modifyProps={prs}
            />)
          }
        }
      })
    }
    return divz
  }

  const swipeableViews = () => <SwipeableViews
    className={'sv-data dms'}
    key={uniqueKey('dms', 'sv')}
    enableMouseEvents
    index={selectedIndex}
    threshold={2}
    hysteresis={2.5}
    onChangeIndex={dataModify_handlers.handleSwipeChangeIndex}
  >
    {swipeableContent()}
  </SwipeableViews>

  const header = () => <AllHeaders
    dataHeaderType={gEnums.dataHeaderTypes.dataModify}
    isDirect={isDirect}
    handleUpdateData={handleUpdateDataFromHeader}
    index={selectedIndex}
    dataUpdateType={dataUpdateType} />

  const otherContent = () => {
    if (swipedItems && swipedItems.length > 1) { return swipeableViews() }
    return swipeableContent()
  }

  const dataModifyWrapper = () => {
    if (dataCommitData || actionDataCommit) {
      return <Wrapper
        content={swipeableContent()}
        wrapperType={wrapperTypes.paddedHeader}
      />
    } else {
      switch (dataUpdateType) {
        case gEnums.dataUpdateTypes.add:
          return <CreateSomething
            createOrigin={settingKey ? gEnums.createOriginTypes.createDataSettings : gEnums.createOriginTypes.createDataModify}
            createType={'normal'}
            isDirect={isDirect}
            lockedData={lockedData}
            useWizard={useWizard}
            viewItem_page={viewItem_page}
            viewItem={viewItem_app}
          />

        default:
          let hcn = ' dat'
          if (isDirect) { hcn += ' direct' }
          return <Wrapper
            header={!inModal && header()}
            content={otherContent()}
            css={{ header: hcn }}
          />
      }
    }
  }

  const dataModifyProvider = () => <DataModifyProvider
    dataModify_state={dataModify_state}
    dataModify_handlers={dataModify_handlers}
  >
    {dataModifyWrapper()}
  </DataModifyProvider>

  /**
   * 
   * @returns the footer for the SAVE
   */
  const footer = () => {

    let caption = 'Save'
    let allowDelete = false

    if (item_handlers) {
      switch (dataUpdateType) {
        case gEnums.dataUpdateTypes.add:
          switch (addModeType) {
            case _amts.pasteImport:
              caption = 'Add Selected Items'
              break;
            default:
              caption = 'Add Item'
          }
          break;
        case gEnums.dataUpdateTypes.edit:
          caption = 'Update Item'
          allowDelete = true
          break;
        default:
        // nothing
      }

      const btns = []
      btns.push({ caption: caption, icon: appIconTypes.save, oc: handleUpdate_dataModifications, disabled: !data_modified_ready, color: isRestricted ? 'yellow' : null })
      allowDelete && btns.push({ caption: 'Delete Item', icon: appIconTypes.delete, oc: handleDelete, disabled: !data_modified_ready, color: 'red', fl: true })
      _allowAdditionalProps && btns.push({ caption: 'Add Prop', icon: appIconTypes.add, oc: handleShowAdditional, fl: true })
      appUserAccess && appUserAccess.isSuperAdmin && btns.push({ icon: appIconTypes.externalAlternate, oc: handleOpenDb })

      return <UiSaveButtons others={btns} />
    } else {
      return <UiSaveButtons
        save={{ caption: 'Save', oc: handleUpdate_dataModifications }}
      />
    }
  }

  const wrapper = () => <Wrapper
    content={dataModifyProvider()}
    footer={footer()}
    wrapperType={showSingleItemEdit ? wrapperTypes.padded : wrapperTypes.paddedHeader}
    updating={updating}
    confirmation={confirmation}
  />

  const fullPageWrapper = (caption, noModal) => <FullPageWrapper
    content={wrapper()}
    topperCaption={useSubDataCollection && subDataCollectionName ? caption + ' (sdc)' : caption}
    topperCaption2={_subCaption ? _subCaption : null}
    isBack={true}
  />

  const appForm = () => <AppForm
    appFormType={appFormTypes.simpleInput}
    handleCancel={handleShowAdditional}
    handleSave={handleAddProp}
    handleSaveCaption={'Add Prop'}
    topperCaption={'Add New Prop'}
    useFullPageWrapper={true}
  />

  if (showAdditional) {
    return appForm()
  } else if (showUpload) {
    return <DataModifyProvider
      dataModify_state={dataModify_state}
      dataModify_handlers={dataModify_handlers}
    >
      <UiUpload origin={'DataMofiySwiper'} handleCancel={handleShowUploader} />
    </DataModifyProvider>
  } else {
    switch (dataUpdateType) {
      case gEnums.dataUpdateTypes.add:
        return fullPageWrapper('Add')
      case gEnums.dataUpdateTypes.edit:
        return fullPageWrapper('Edit')
      default:
        return fullPageWrapper('???')
    }
  }
} 