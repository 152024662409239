import _ from 'lodash';
import { _appVersion } from '../cnr/contexts/AppSettingsContext';
import { updateSettingsToDB } from '../firestoreData/settings/updateSettings';
import { callFunction } from "./fbAll";

const settingsGenericTypes = {
  settingsGeneric: 'settingsGeneric',
}

const settingsFunctionTypes = {
  allSettings: 'allSettings',
  allSettingsApp: 'allSettingsApp',
  appSettings: 'appSettings',
  backupSettings: 'backupSettings',
  fixAllSettings: 'fixAllSettings',
  formsApp: 'formsApp',
  updateAppSettings: 'updateAppSettings',
  updateAppSettingsByType: 'updateAppSettingsByType',
  updateSettingsDb: 'updateSettingsDb',
  updateViews: 'updateViews',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(settingsGenericTypes.settingsGeneric, callData)
  return data
}

/**
 * Updates the settings/doc in the database. Currently this is NOT using functions. It is using updateSettingsDB
 * @param {function} dispatch 
 * @param {object} settings_temp 
 * @param {object} paps_state 
 * @param {boolean} isGlobal 
 * @param {boolean} sandboxOn 
 * @param {object} appUser 
 * @returns result
 */
export const fsfn_updateSettingsDb = async (dispatch, settings_temp, paps_state, isGlobal, sandboxOn, appUser, appSettingsChanges) => {
  try {
    const { pathViews, pathKey, rootPaths, view, viewKey, landingView, settingsDocName } = paps_state ? paps_state : {}
    const papsProps = { pathViews, pathKey, rootPaths, view, viewKey, landingView, settingsDocName }
    const callData = { settingFunctionType: settingsFunctionTypes.updateSettingsDb, settings_temp, papsProps, isGlobal, sandboxOn, appUser, appSettingsChanges }
    updateSettingsToDB(callData, dispatch)
  } catch (error) {
    console.error(error)
  }
}

/** Updates the settings
 * @param settingsDocName
 * @param appSettings global, viewItems, views
 */
export const fsfn_updateAppSettings = async (settingsDocName, appSettings, logging) => {
  const showLogs = true // logging && logging.allowLogging && logging.logUpdateAppSettings
  try {
    appSettings.appVersion = _appVersion
    const callData = { settingFunctionType: settingsFunctionTypes.updateAppSettings, settingsDocName, appSettings, showLogs }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.log('fsfn_updateAppSettings - error', error)
    showLogs && console.error(error)
    return false
  }
}

/** Updates the settings/doc in the datbase by `type`  */
export const fsfn_updateAppSettingsByType = async (settingsDocName, updateJson, updateType) => {
  try {
    const callData = { settingFunctionType: settingsFunctionTypes.allSettingsApp, settingsDocName, updateJson, updateType }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    return false
  }
}


/**
 * Gets the default setting for the app by going to firebase. settingsGeneric.allSettingsApp
 * @param {*} vvProps_g 
 * @param {*} logging 
 * @returns with {home, clients, client, event} >> {global, page} >> {vw, vi, pr, ps, other} for each of them
 */
export const fsfn_getAppSettings = async (vvProps_g, logging) => {
  const showLogs = true //logging && logging.allowLogging && logging.logAllSettingsApp 
  try {
    const callData = { settingFunctionType: settingsFunctionTypes.appSettings, vvProps: vvProps_g, showLogs }
    const { result } = await callFsFunction(callData)
    return result
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets the default setting for the app by going to firebase. settingsGeneric.allSettingsApp
 * @param {*} vvProps_g 
 * @param {*} logging 
 * @returns with {home, clients, client, event} >> {global, page} >> {vw, vi, pr, ps, other} for each of them
 */
export const fsfn_getAllSettingsApp = async (vvProps_g, logging) => {
  const showLogs = logging && logging.allowLogging && logging.logAllSettingsApp
  console.log('showLogs', showLogs)
  try {
    const callData = { settingFunctionType: settingsFunctionTypes.allSettingsApp, vvProps: vvProps_g, showLogs }
    console.log('showLogs', showLogs)
    const { result } = await callFsFunction(callData) // OK 
    return result
  } catch (error) {
    console.error(error)
  }
}


/** Retuns the settings object from firebase functions
 * @param formType
 * @param vvProps
 * @param form_handlers 
 * @returns `Forms` object firebase functions
 */
export const fsfn_getAppForms = async () => {
  try {
    const callData = { settingFunctionType: settingsFunctionTypes.formsApp }
    const { result: appForms } = await callFsFunction(callData) // OK
    return appForms
  } catch (error) {
    console.error('fsfn_getAppForms', error)
    return {}
  }
}

/**
 * fixes the settings for the selected `settingsKey`
 * @param {string} settingsKey 
 * @param {boolean} previewOnly 
 * @returns result
 */
export const fsfn_fixAllSettings = async (settingsKey, previewOnly) => {
  const callData = { settingFunctionType: settingsFunctionTypes.fixAllSettings, settingsKey, previewOnly }
  try {
    const { result } = await callFsFunction(callData) // OK 
    return result
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} settingsDocName 
 * @param {boolean} update 
 * @param {boolean} previewInfo 
 * @returns response_data {success, result, error}
 */
export const fsfn_backupSettings = async (settingsDocName, update, previewInfo) => {
  const callData = { settingFunctionType: settingsFunctionTypes.backupSettings, settingsDocName, update, previewInfo }
  try {
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    return { data: { success: false } }
  }
}


/**
 * 
 * @param {*} viewItems 
 * @param {*} views 
 * @returns  
 * @deprecated
 */
export const fsfn_updateViews = async (viewItems, views) => {
  try {
    const callData = { settingFunctionType: settingsFunctionTypes.updateViews, viewItems, views }
    const fn_response = await callFsFunction(callData)
    return fn_response
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} fn_response 
 * @returns previewInfo
 * @deprecated
 */
export const projectDataFromResponse = (fn_response) => {
  const { data } = fn_response ? fn_response : {}
  const { result } = data ? data : {}
  const { projectData, projectSettings } = result ? result : {}
  const previewInfo = {
    projectData,
    projectSettings,
    previewOk: true,
  }
  return previewInfo
}

/**
 * 
 * @param {object} dataItems 
 * @returns - {headerProps, viewDataItems}
 * @deprecated
 */
export const getDataItems = (dataItems) => {

  let viewDataItems = {}
  const headerProps = {}

  if (_.isArray(dataItems)) {
    dataItems.forEach(function (dataItem, index) {
      if (index === 0) {
        dataItem.forEach(function (item) {
          headerProps[item] = {}
          // headerItems.push(item)
        })
      }
    });
    // array
    dataItems.forEach(function (dataItem, index) {
      if (index > 0) {
        const di = {}
        Object.keys(headerProps).forEach(function (headerItem, headerIndex) {
          if (dataItem[headerIndex]) {
            switch (headerItem.toLowerCase()) {
              case 'id':
                break;
              default:
                // @ts-ignore
                di[_.camelCase(headerItem)] = dataItem[headerIndex]
            }
          }
        })
        viewDataItems[_.camelCase(dataItem[0])] = di
      }
    })
  } else {
    viewDataItems = dataItems
    Object.keys(dataItems).forEach(function (key, index) {
      const dataItem = dataItems[key]
      if (index === 0) {
        Object.keys(dataItem).forEach(function (keyy) {
          headerProps[keyy] = {}
        })
      }
    });
  }

  return {
    headerProps,
    viewDataItems,
  }
}