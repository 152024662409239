import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../../global/common/keys';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';

/** Return as the score in a table-like format */
const MatchScoreTable = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state } = states
  const { appUser } = appUser_state ? appUser_state : {}

  const appUserAccess = getAppUserAccess(appUser)
  const { displayName } = appUserAccess ? appUserAccess : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ? sportsMatchContext : {}
  const { match_true } = sportsMatch_state ? sportsMatch_state : {}

  // sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state } = sportsRtMatchContext ? sportsRtMatchContext : {}
  const { match_rt } = sportsRtMatch_state ? sportsRtMatch_state : {}

  const { home, away, results, results_current } = match_true ? match_true : {}
  const results_match = results_current ? results_current : results

  let _results = match_rt ? match_rt : results_match

  const { complete } = _results ? _results : {}

  if (match_rt && !complete) { _results = match_rt }

  const { score, scores, setNumber, setWinners, setsCompleted, inProgress } = _results ? _results : {}

  const handleEditScore = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    // sportsMatch_handlers.handleEditScore(currentMatch)
  }

  let color;
  if (complete) { color = 'blue' }
  if (inProgress) { color = 'green' }

  const fullScoreDiv = (team, teamType) => {
    const c = []
    const colz = 5
    for (var col = -1; col <= colz; col++) {
      const x = col
      switch (col) {
        case -1: // team names
          c.push(<div key={uniqueKey('mst', 'tn', team)}>{team ? team.name : 'Team'}</div>)
          break;
        case 0: // set number
          if (team) {
            const teamScore = score ? score[teamType] : '-'
            c.push(<div key={uniqueKey('mst', 'ts', team, teamType)}>{teamScore}</div>)
          } else {
            c.push(<div key={uniqueKey('mst', 'ts', team, teamType)}>{'S'}</div>)
          }
          break;
        default: //
          if (team) {
            if (scores) {
              const setScore = scores[col]
              if (setScore) {
                // score
                const sw = setWinners && setWinners[col] ? setWinners[col] : null
                let cnn;
                if (sw === teamType) { cnn = 'winner' }
                c.push(<div key={uniqueKey('mst', 'ts', team, teamType, col)} className={cnn}>{setScore[teamType]}</div>)
              } else {
                // no score
                c.push(<div ckey={uniqueKey('mst', 'ts', team, teamType, col)} className={(setNumber === col) ? 'selected' : ''}>{'-'}</div>)
              }
            } else {
              // no score
              c.push(<div key={uniqueKey('mst', 'ts', team, teamType, col)} className={(setNumber === col) ? 'selected' : ''}>{'-'}</div>)
            }
            break;
          } else {
            //  set number
            let scn = (setNumber === col) ? 'selected' : null
            if (scn && col <= setsCompleted) { scn += ' complete' }
            if (x <= setsCompleted + 1) { scn += ' avail' }
            c.push(<div key={uniqueKey('mst', 'sn', col)} className={scn} >{col}</div>)
          }
      }
    }
    return c
  }

  const fullMatchSeg = () => <Segment className={'match-score-table'} color={color} onClick={(e) => handleEditScore(e)}>
    <div key={uniqueKey('mst', 'scoring-owner')} className={'scoring-owner'}>{'Scorer: '}{displayName ? displayName : ''}</div>
    <div key={uniqueKey('mst', 'scoring-header')} className={'scoring-header'}>{fullScoreDiv()}</div>
    <div key={uniqueKey('mst', 'scoring-row-2')} className={'scoring-row-2'}>{fullScoreDiv(away, 'away')}</div>
    <div key={uniqueKey('mst', 'scoring-row-1')} className={'scoring-row-1'}>{fullScoreDiv(home, 'home')}</div>
  </Segment>

  return fullMatchSeg()

}

export default MatchScoreTable