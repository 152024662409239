import React from 'react';
import { TextArea } from 'semantic-ui-react';
import { gEnums } from '../../../enums/globalEnums';
import { uniqueKey } from '../../../common/keys';
import { TextAreaToggle } from './TextAreaToggle';

const TextAreaInput = (props) => {
  const { fip, handleEditor, formItemType } = props
  const { propname } = fip ? fip : {}
  let rows = 5
  switch (propname) {
    case 'aboutMe':
    case 'bio':
    case 'note':
      rows = 20
      break;
    default:
      break;
  }
  switch (formItemType) {
    case gEnums.formItemTypes.textArea:
      return <TextAreaToggle fip={fip} handleEditor={handleEditor} rows={rows} />
    case gEnums.formItemTypes.textAreaSimple:
      return <React.Fragment>
        <div className='field'><label style={{ float: 'left' }}>{fip.label}</label></div>
        <TextArea key={uniqueKey('ta', fip.key)} {...fip} rows={rows} />
      </React.Fragment>
    default:
      return <React.Fragment>
        <div className='field'><label style={{ float: 'left' }}>{fip.label}</label></div>
        <TextArea key={uniqueKey('ta', fip.key)} {...fip} rows={rows} />
      </React.Fragment>
  }
}

export default TextAreaInput