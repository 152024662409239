import { where } from 'firebase/firestore';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fsfn_sendEmail, fsfn_sendSms } from '../../../../global/functions/fbNotifications';

export const giftCardReducer = (state, action) => {

  const { pathViews, giftCards } = state
  const { dispatch, type } = action

  const { handleSetCustomers, handleSetRetailers, handleSetGiftCards } = giftCardHandlers(dispatch)

  switch (type) {
    case rts.handleGetCustomers:
      getCustomers(pathViews, handleSetCustomers)
      return { ...state }

    case rts.handleSetCustomers:
      return { ...state, customers: action.customers }

    case rts.handleGetRetailers:
      getRetailers(pathViews, handleSetRetailers)
      return { ...state }

    case rts.handleSetRetailers:
      return { ...state, retailers: action.retailers }

    case rts.handleGetGiftCards:
      getGiftCards(pathViews, action.appUserSessionKey, handleSetGiftCards)
      return { ...state }

    case rts.handleSetGiftCards:
      return { ...state, giftCards: action.giftCards }

    case rts.handleSendEmail:
      const nd = {
        email: {
          recipient: action.email,
          sender: 'support@meetingevolution.net',
          subject: 'GC Update',
          body: getEmailBody(giftCards)
        }
      }
      console.log('nd', nd)
      fsfn_sendEmail(nd).then(response_data => {
        console.log('response_data', response_data)
      }).catch(error => {

      })
      return { ...state }

    case rts.handleSendSms:
      const data = {
        to: action.phoneNumber,
        text: getEmailBody(giftCards),
        showLogs: true
      }
      fsfn_sendSms(data).then(response_data => {
        console.log('response_data', response_data)
      }).catch(error => {
        console.error(error);
      })
      return { ...state }

    default:
      return { ...state }
  }
}

export const giftCardHandlers = (dispatch) => {
  return {
    handleGetCustomers: () => { dispatch({ type: rts.handleGetCustomers, dispatch }) },
    handleGetGiftCards: (appUserSessionKey) => { dispatch({ type: rts.handleGetGiftCards, dispatch, appUserSessionKey }) },
    handleGetRetailers: () => { dispatch({ type: rts.handleGetRetailers, dispatch }) },
    handleSendEmail: (email) => { dispatch({ type: rts.handleSendEmail, dispatch, email }) },
    handleSetCustomers: (customers) => { dispatch({ type: rts.handleSetCustomers, dispatch, customers }) },
    handleSetGiftCards: (giftCards) => { dispatch({ type: rts.handleSetGiftCards, dispatch, giftCards }) },
    handleSetRetailers: (retailers) => { dispatch({ type: rts.handleSetRetailers, dispatch, retailers }) },
    handleSendSms: (phoneNumber) => { dispatch({ type: rts.handleSendSms, dispatch, phoneNumber }) },
  }
}

const rts = {
  handleGetCustomers: 'handleGetCustomers',
  handleSetCustomers: 'handleSetCustomers',
  handleGetGiftCards: 'handleGetGiftCards',
  handleGetRetailers: 'handleGetRetailers',
  handleSendEmail: 'handleSendEmail',
  handleSetGiftCards: 'handleSetGiftCards',
  handleSetRetailers: 'handleSetRetailers',
  handleSendSms: 'handleSendSms',
}

export const giftCardInitialState = (init_state) => {
  return { ...init_state }
};

const getRetailers = (pathViews, callback) => {
  const _ref = createRefPath_event(pathViews, ['retailers'])
  fs_get_data({ refPath: _ref, callback })
}

const getGiftCards = (pathViews, appUserSessionKey, callback) => {
  const _ref = createRefPath_event(pathViews, ['giftCards'])
  const wheres = [where('customers', 'array-contains', appUserSessionKey)]
  fs_get_data({ refPath: _ref, callback, wheres })
}

const getCustomers = (pathViews, callback) => {
  const _ref = createRefPath_event(pathViews, ['customers'])
  fs_get_data({ refPath: _ref, callback })
}

const getEmailBody = (giftCards) => {
  let body = '<strong>You have gift cards!. Go spend it!</strong><br><br>'
  if (giftCards) {
    Object.keys(giftCards).forEach(key_gc => {
      const giftCard = giftCards[key_gc]
      const { name, giftCardAmount } = giftCard ? giftCard : {}
      body += '<strong>' + name + '</strong>: $' + giftCardAmount + '<br><br>'
    })
  }
  return body
} 