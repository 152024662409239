import _ from 'lodash';
import { createFsDocKey } from '../../../global/firestoreData/appData/fsAppData';
import { sgs } from '../helpers/scheduleGrids';
import { getPks, getSeasonalData, seasonalUpdateTypes, updateSeasonalOrGlobal } from '../cnr/reducers/reducerHelpers/seasonalSportsData';

export const updateScheduledMatches = async (paps_state, latestSeason, scheduleProps, callback) => {

  const { pathViews } = paps_state
  const pks = getPks(pathViews, latestSeason)
  // updates `_seasonals`
  updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, pks, null, 'matches', scheduleProps.allMatches, callback)

}

// export const getScheduledMatches = async (paps_state, viewItem, callback) => {

//   const { pathViews } = paps_state
//   const { dataSource } = viewItem ? viewItem : {}
//   const { dataParents, useSeasonals } = dataSource ? dataSource : {}

//   getSeasonalData(pathViews, sportsKey, dataParents, useSeasonals, callback)

// }

export const createScheduledMatches = (sectionDates, teams, season_info, homeAway) => {

  const allM = {}
  const levels = _.groupBy(teams, 'levels')
  const sportInfo = { startTime: '7:00 PM' }
  const { homeAways } = season_info ? season_info : {}

  Object.keys(levels).forEach(levelKey => {
    const sections = _.groupBy(levels[levelKey], 'sections')
    Object.keys(sections).forEach(sectionKey => {
      const _levelSectionKey = levelKey + '_' + sectionKey
      const sectionTeams = sections[sectionKey]
      const teamCount = Object.keys(sectionTeams).length
      const selectedDates = sectionDates && sectionDates[teamCount] ? sectionDates[teamCount] : []
      if (selectedDates) {
        const playGrids = sgs.wpial[teamCount]
        if (playGrids) {
          for (var allezRetour = 1; allezRetour <= 2; allezRetour++) {
            const _homeAway = homeAways && homeAways[_levelSectionKey] ? homeAways[_levelSectionKey] : homeAway
            createMatches(sectionTeams, allezRetour, selectedDates, sportInfo, playGrids, allM, _homeAway)
          }
        }
      }
    })
  })

  return {
    allMatches: allM,
  }
}

const createMatches = (teams, allezRetour, selectedDates, sportInfo, playGrids, allM, homeAway) => {

  // loop the grids
  let pdi = (allezRetour === 2) ? Object.keys(playGrids).length : 0

  if (playGrids) {
    Object.keys(playGrids).forEach((pgsKey, index) => {
      const gridMatches = playGrids[pgsKey]
      if (gridMatches) {
        Object.keys(gridMatches).forEach(key => {
          const gridMatch = gridMatches[key]
          const newMatch = createMatch(teams, gridMatch, selectedDates[pdi], sportInfo.startTime, homeAway, allezRetour)
          const _key = createFsDocKey('createMatches')
          allM[_key] = newMatch
        })
      }
      pdi++
    })
  }
}

export const createMatch = (teams, match, startDate, startTime, homeAway, allezRetour) => {
  const newMatch = {
    bestOf: true,
    setCount: 5,
    startDate: startDate ? startDate : '???',
    startTime: startTime,
    home: { ...getHomeAway(homeAway, teams, match, allezRetour, true) },
    away: { ...getHomeAway(homeAway, teams, match, allezRetour, false) },
  }
  newMatch.key = newMatch.away.name + '-' + newMatch.home.name
  newMatch.teams = [newMatch.home.id, newMatch.away.id]
  if (newMatch.away.levels === newMatch.home.levels) {
    newMatch.levels = newMatch.home.levels
  } else {
    newMatch.levels = 'Non'
  }
  if (newMatch.away.sections === newMatch.home.sections) {
    newMatch.sections = newMatch.home.sections
  } else {
    newMatch.sections = 'Non'
  }
  return newMatch
}

export const createNonSectoinMatch = (teams, match, startDate, startTime, homeAway, allezRetour) => {
  const newMatch = {
    bestOf: true,
    setCount: 5,
    startDate: startDate,
    startTime: '7:00 PM',
    home: { ...getHomeAway(homeAway, teams, match, allezRetour, true) },
    away: { ...getHomeAway(homeAway, teams, match, allezRetour, false) },
  }
  newMatch.key = newMatch.away.name + '-' + newMatch.home.name
  newMatch.teams = [newMatch.home.id, newMatch.away.id]
  if (newMatch.away.levels === newMatch.home.levels) {
    newMatch.levels = newMatch.home.levels
  } else {
    newMatch.levels = 'Non'
  }
  if (newMatch.away.sections === newMatch.home.sections) {
    newMatch.sections = newMatch.home.sections
  } else {
    newMatch.sections = 'Non'
  }
  return newMatch
}

const getTeam = (teams, teamNumber, withParents) => {

  const rTeam = {}

  Object.keys(teams).forEach(teamKey => {
    const team = teams[teamKey]
    if (team.position + 1 === teamNumber) {
      rTeam.id = team.id
      rTeam.name = team.name
      rTeam.sections = team.sections
      rTeam.levels = team.levels
      if (withParents) {
        rTeam.organizations = team.parentKeys.organizations
        rTeam.districts = team.parentKeys.districts
      }
    }
  })

  return rTeam

}

const getHomeAway = (homeAway, t, match, allezRetour, isHome) => {
  if (homeAway) {
    if (isHome) {
      if (allezRetour === 1) {
        return getTeam(t, match.a)
      } else {
        return getTeam(t, match.h)
      }
    } else {
      if (allezRetour === 1) {
        return getTeam(t, match.h)
      } else {
        return getTeam(t, match.a)
      }
    }
  } else {
    if (isHome) {
      if (allezRetour === 1) {
        return getTeam(t, match.h)
      } else {
        return getTeam(t, match.a)
      }
    } else {
      if (allezRetour === 1) {
        return getTeam(t, match.a)
      } else {
        return getTeam(t, match.h)
      }
    }
  }
}