import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Button, Segment } from 'semantic-ui-react';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import { validateEmail } from '../../../global/validation/validation';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { TicketingContext } from '../cnr/contexts/TicketingContext';
import ContactAssignment from './ContactAssignment';
import EmailAssignment from './EmailAssignment';
import PhoneAssignment from './PhoneAssignment';
import { ticketTypes } from './SingleTicket';
import SubTickets from './SubTickets';

export const assignTypes = {
  assign: 'assign',
  forward: 'forward'
}

const AssignTicket = (props) => {

  const { assignType, isWillCall, ticket, subTicket, subTicketSelected, handleCancel } = props ? props : {}
  const { _itemKey: ticketKey } = ticket ? ticket : {}

  // ticketingContext
  const ticketingContext = useContext(TicketingContext)
  const { ticketing_handlers, ticketing_state } = ticketingContext ? ticketingContext : {}
  const { appTicketing, updating } = ticketing_state ? ticketing_state : {}
  const { allowAssignToContact, allowAssignToEmail, allowAssignToPhone } = appTicketing ? appTicketing : {}

  const _allowAssignments = allowAssignToEmail || allowAssignToPhone

  const [contact, setContact] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [email, setEmail] = useState()
  const [phoneNumberValid, setPhoneNumberValid] = useState()
  const [emailValid, setEmailValid] = useState()
  const [selectButton, setSelectButton] = useState('phone')

  const isValid = phoneNumberValid || emailValid

  const handleContact = (contact) => {
    if (contact) {
      if (contact.phoneNumber) { setPhoneNumber(contact.phoneNumber) }
      if (contact.email) { setEmail(contact.email) }
      setContact(contact)
    }
  }

  useEffect(() => {
    ticketing_handlers.handleClearContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      setPhoneNumberValid(isPossiblePhoneNumber(phoneNumber))
    } else {
      setPhoneNumberValid(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [phoneNumber]);

  useEffect(() => {
    if (email) {
      if (validateEmail(email)) {
        setEmailValid(email)
        setEmail(email)
      } else {
        setEmail(email)
      }
    } else {
      setEmail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [email]);

  // ticket, ticketKey, phoneNumber, contact, unassign, subTicketSelected
  const handleAssignToContact = (selectedItem) => {
    //  (ticket, ticketKey, phoneNumber, email, contact, unassign, subTicketSelected
    const { item: item_selected, type: type_selected } = selectedItem ? selectedItem : {}
    if (type_selected === 'email') {
      ticketing_handlers.handleAssign(ticket, ticketKey, null, item_selected, contact, false)
    } else {
      let _pn = item_selected;
      if (_pn.length === 10) { _pn = '+1' + _pn }
      if (isPossiblePhoneNumber(_pn)) {
        ticketing_handlers.handleAssign(ticket, ticketKey, _pn, null, contact, false)
      }
    }
  }

  const handleAssign = () => ticketing_handlers.handleAssign(ticket, ticketKey, phoneNumber, email, contact, false)
  const handleUnAssign = () => ticketing_handlers.handleAssign(ticket, ticketKey, phoneNumber, email, contact, true)
  const handleForward = () => ticketing_handlers.handleForward(ticket, subTicket, phoneNumber, email, false)
  const handleUnForward = () => ticketing_handlers.handleForward(ticket, subTicket, phoneNumber, email, true)

  const button = (type, icon) => <Button key={type} icon={icon} content={_.startCase(type)} color={selectButton === type ? 'blue' : 'grey'} onClick={(e) => { setSelectButton(type) }}></Button>
  const segText = () => {
    const messageType = selectButton === 'phone' ? 'SMS message' : 'Email'
    return <Segment>{'The recipient will receive an ' + messageType + ' with a link to their will call ticket'}</Segment>
  }

  const buttonSelect = () => <div className={'padd10'}>
    <Button.Group fluid>
      {allowAssignToPhone && button('phone', 'mobile alternate')}
      {allowAssignToEmail && button('email', 'mail outline')}
    </Button.Group>
  </div>


  const content = () => <div className={'ticket-sms'}>
    {allowAssignToContact && (navigator.contacts || navigator.mozContacts) && <ContactAssignment handleContact={handleContact} handleCancel={handleCancel} handleAssignToContact={handleAssignToContact} />}
    {_allowAssignments && buttonSelect()}
    {allowAssignToPhone && selectButton === 'phone' && <PhoneAssignment contact={contact} handleContact={handleContact} handleCancel={handleCancel} phoneNumber={phoneNumber} />}
    {allowAssignToEmail && selectButton === 'email' && <EmailAssignment contact={contact} handleContact={handleContact} handleCancel={handleCancel} email={email} />}
    {_allowAssignments && segText()}
    <SubTickets ticket={ticket} ticketType={ticketTypes.full} subTicketSelected={subTicketSelected} forSend={true} />
  </div>

  const forwardWrapper = () => <Wrapper
    content={content()}
    footer={<UiSaveButtons
      save={{ oc: handleForward, caption: 'Forward', disabled: !isValid, icon: 'arrow right' }}
      preview={{ oc: handleUnForward, caption: 'Un-Forward' }}
    />}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
  />

  const footer = () => <UiSaveButtons
    save={{ oc: handleAssign, caption: 'Assign', disabled: !isValid }}
    preview={{ oc: handleUnAssign, caption: 'Un-Assign' }}
  />

  const assignWrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
  />

  const smsWizard = () => <FullPageWrapper
    content={assignType === assignTypes.assign ? assignWrapper() : forwardWrapper()}
    handleCancel={handleCancel}
    topperCaption={assignType === assignTypes.assign ? 'Assign Pass(es)' : 'Forward Pass(es)'}
    noModal={isWillCall ? true : false}
  />

  return smsWizard()

}

export default AssignTicket