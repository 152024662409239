import { svgElementProps, svgTypes } from "../../components/svg/svgElements"
import { swipeDivs } from "../../components/swipe/swipeDivs"
import { thumbTouch } from "../../components/swipe/ThumbTouch"

const tickMargin = 10
const allowUpdate = true

// const usePerc = false

export const thumbSwipeTypes = {
  handleFinish: 'handleFinish',
  getZoneStatElements: 'getZoneStatElements',
  handleDisplayItem: 'handleDisplayItem',
  handleDiv: 'handleDiv',
  handleSvgElement: 'handleSvgElement',
  handleSvgType: 'handleSvgType',
  handleTouchEnd: 'handleTouchEnd',
  handleTouchMove: 'handleTouchMove',
  handleTouchStart: 'handleTouchStart',
}

export const thumbSwipeReducer = (state, action) => {

  const { svgType, isTouching, thumbTouch, actionLineColor, thisCourt, settings } = state
  const { type, touchEvent, dispatch, swipeHolder } = action

  let _touch = thumbTouch.touch

  const { handleFinish, handleSvgElement } = thumbSwipeHandlers(dispatch)

  switch (type) {

    case thumbSwipeTypes.handleDiv:
      return { ...state, swipeDiv: action.swipeDiv.current }

    case thumbSwipeTypes.handleSvgType:
      return { ...state, svgType: action.svgType }

    case thumbSwipeTypes.handleReset:
      return { ...state, lastActionElement: null, done: false }

    case thumbSwipeTypes.handleDisplayItem:
      return { ...state, displayItem: action.displayItem }

    case thumbSwipeTypes.handleSvgElement:
      console.log('d')

      let _elem;

      switch (svgType) {
        case svgTypes.line:
          _elem = svgElementProps.line(_touch.line(), 10, actionLineColor, true)
          break;

        case svgTypes.rectangle:
          _elem = svgElementProps.rect(_touch.rect(), 'blue')
          break;

        case svgTypes.circle:
          _elem = svgElementProps.circle(_touch.circle(), 'blue')
          break;

        default:
        // nothing
      }

      return { ...state, currentElem: _elem, lastActionElement: _elem }

    case thumbSwipeTypes.handleTouchStart:

      let os = state.swipeDiv.getBoundingClientRect();
      console.log('os', os)

      _touch.offsetLeft = os.left
      _touch.offsetTop = os.top

      const ok = thumbTouch.sectors ? thumbTouch.courtData(true, thisCourt.maxHeight) : true

      const { clientX, clientY, isTouch, isMouse } = getCoords(touchEvent)

      _touch.touchStartX = clientX;
      _touch.touchStartY = clientY;
      _touch.isTouch = isTouch
      _touch.isMouse = isMouse

      if (ok) {
        if (settings.allowHolder) {
          return { ...state, isTouching: true, done: false, totalDuration: 0 }
        } else {
          return { ...state, isTouching: true, done: false, totalDuration: 0, timerTotal: setInterval(state.tock, 50) }
        }
      }

      return { ...state }

    case thumbSwipeTypes.handleTouchMove:

      if (isTouching) {
        if (_touch.isTouch || _touch.isMouse) {

          const { clientX, clientY } = getCoords(touchEvent)

          if (clientX && clientY) {

            // }
            // if (touchEvent.touches && touchEvent.touches[0]) {

            let tempDiffX = Math.abs(_touch.touchNowX - clientX)
            let tempDiffY = Math.abs(_touch.touchNowY - clientY)

            // allow some movement to the touch hold
            if (settings.allowHolder) {
              if (tempDiffX < tickMargin && tempDiffY < tickMargin) {
                clearInterval(state.timer);
                // this.duration = 0
                // this.timer = setInterval(this.tick, 10);
              }
            }

            _touch.touchNowX = clientX
            _touch.touchNowY = clientY

            // reset the timer if moved 
            _touch.moveDiffX = Math.abs(_touch.touchStartX - _touch.touchNowX)
            _touch.moveDiffY = Math.abs(_touch.touchStartY - _touch.touchNowY)

            if (_touch.direction === 'n') {
              if (_touch.moveDiffX > _touch.moveDiffY) {
                _touch.direction = 'h'
              } else {
                _touch.direction = 'v'
              }
            }

            if (_touch.touchNowY <= _touch.touchStartY) {
              _touch.up = true
            } else {
              _touch.up = false
            }

            thumbTouch.sectors && thumbTouch.courtData(false, thisCourt.maxHeight)
            handleSvgElement()

          }
        } else if (_touch.isMouse) {

        }
      }

      return { ...state, done: false }

    case thumbSwipeTypes.handleTouchEnd:
      if (isTouching) {
        clearInterval(state.timer);
        clearInterval(state.timerTotal)
        handleFinish()
        return { ...state, timerOn: false, done: true }
      } else {
        return { ...state }
      }

    case thumbSwipeTypes.handleFinish:

      let touchD = thumbTouch.touchData

      let newResult = touchD.csr.swipeResult
      let timerOn;

      if (swipeHolder) {
        newResult = swipeHolder
        touchD.actionLineColor = touchD.csr.color
        timerOn = false
        clearInterval(state.timer);
        clearInterval(state.timerTotal)
        touchD.csr.swipeResult = swipeHolder
      }

      if (thumbTouch.swipeData && thumbTouch.swipeData.resultItems.slowers && thumbTouch.swipeData.resultItems.slowers[thumbTouch.touchData.csr.resultItems]) {
        if (state.totalDuration > 25) {
          newResult = thumbTouch.swipeData.resultItems.slowers[thumbTouch.touchData.csr.swipeResult]
        }
      }

      if (thumbTouch.swipeData) { updateSwipeProps(state, newResult) }

      // let actionElements = state.currentLines
      // actionElements = []
      // actionElements.push(actionElements)

      return {
        ...state,
        isTouching: false,
        done: true,
        timerOn,
        actionLineColor: 'black',
        courtOuterColor: 'grey',
        statView: null,
        statGroupStatItem: null
      }

    default:
      return { ...state }
  }
}

export const thumbSwipeInitialState = (initState) => {

  const { imageStyle, statCategory, tss, props } = initState ? initState : {}

  const touchData = {}
  const thisCourt = swipeDivs.courtSwipe

  return {
    isTouching: false,
    swipeType: initState.swipeType,
    statCategory,
    tss: initState.tss,

    // statGroup and statView are the same if action not seleted 
    statGroup: tss ? tss.statGroup : null,
    statView: tss ? tss.statView : null,

    settings: {
      allowSwipe: tss ? tss.allowSwipeHold : false,
      colors: statCategory ? statCategory.colors : null,
      icons: statCategory ? statCategory.icons : null,
      handlers: tss ? tss.handlers : null,
      styleHeights: props ? props.styleHeights : {}
    },

    sectors: null,

    // category specific
    rotation: null,

    // from parent
    player: props ? props.player : null,

    // from parent via
    matchData: statCategory ? statCategory.matchData : null,
    statGroupStatItem: tss ? tss.statGroupStatItem : null, // NEED

    action: (tss && tss.statGroupStatItem && tss.statGroupStatItem.requestItems.action) ? tss.statGroupStatItem.requestItems.action : null,

    currentElem: null,

    actionPlayElements: [],

    zoneStatElements: {
      pass: [],
      oppAttack: []
    },

    touchData: touchData,
    actionLineColor: 'black',
    courtOuterColor: 'black',

    next: '--',

    elapsed: 0,
    duration: 0,
    totalDuration: 0,
    maxCourtHeight: thisCourt ? thisCourt.maxHeight : 0,

    // top AND bottom
    // if (swipeType === 'swipe') {
    //   swipeArea = (statView.swipeArea) ? statView.swipeArea : state.statGroupStatItem.swipeArea
    // }

    lastActionElement: null,
    resultGroupItemName: null,
    statGroupItem: null,
    groupColor: null,

    thumbTouch: thumbTouch,

    courtSVG: null,
    thisCourt,
    imageStyle,

    categorySVGProps: {}
  }
};

export const thumbSwipeHandlers = (dispatch, state) => {
  return {
    getZoneStatElements: () => { dispatch({ type: thumbSwipeTypes.getZoneStatElements }) },
    handleDisplayItem: (displayItem) => { dispatch({ type: thumbSwipeTypes.handleDisplayItem, displayItem }) },
    handleDiv: (swipeDiv) => { dispatch({ type: thumbSwipeTypes.handleDiv, swipeDiv }) },
    handleFinish: (isEOP, swipeHolder) => { dispatch({ type: thumbSwipeTypes.handleFinish, isEOP, swipeHolder }) },
    handleReset: () => { dispatch({ type: thumbSwipeTypes.handleReset }) },
    handleSvgElement: (isEnd) => { dispatch({ type: thumbSwipeTypes.handleSvgElement, isEnd }) },
    handleSvgType: (svgType) => { dispatch({ type: thumbSwipeTypes.handleSvgType, svgType }) },
    handleTouchEnd: (touchEvent) => { dispatch({ type: thumbSwipeTypes.handleTouchEnd, touchEvent, dispatch }) },
    handleTouchMove: (touchEvent) => { dispatch({ type: thumbSwipeTypes.handleTouchMove, touchEvent, dispatch }) },
    handleTouchStart: (touchEvent) => { dispatch({ type: thumbSwipeTypes.handleTouchStart, touchEvent, dispatch }) },
  }
}

const updateSwipeProps = (state, newResult) => {

  let updateIsGood = true

  const { thumbTouch, settings, thisCourt } = state
  let touchD = thumbTouch.touchData

  let swipeProps = { details: {} }

  Object.keys(thumbTouch.swipeData.requestItems).forEach(key => {
    switch (key) {
      case 'action':
        swipeProps[key] = state.action
        break;
      case 'zone':
        swipeProps[key] = touchD.current.zone
        break;
      case 'swipeResult':
        swipeProps[key] = newResult
        break;
      case 'xtra':
        swipeProps[key] = touchD.csr.xtra
        break;
      default:
      // nothing
    }
  })

  // touchD.current
  swipeProps.details['movement'] = touchD.movement(state.sectors.maxCourtWidth, thisCourt.maxHeight, touchD)
  if (touchD.dipped) { swipeProps['dipped'] = touchD.dipped }

  if (thumbTouch.swipeData) {
    Object.keys(thumbTouch.swipeData.requestItems).forEach(key => {
      if (key !== 'xtra') {
        if (!thumbTouch.swipeData.requestItems[key] || thumbTouch.swipeData.requestItems[key] === '---') {
          console.log('NOT GOOD' + key)
          updateIsGood = false
        }
      }
    })
  }

  if (allowUpdate && updateIsGood && !touchD.areasSame()) {
    settings.handlers.updateSwipeAction(swipeProps)
  }
}

const getCoords = (touchEvent) => {

  let clientX;
  let clientY;
  let isTouch;
  let isMouse;

  if (touchEvent.touches && touchEvent.touches[0]) {
    clientX = touchEvent.touches[0].clientX;
    clientY = touchEvent.touches[0].clientY;
    isTouch = (touchEvent.touches);
  } else {
    clientX = touchEvent.clientX ? touchEvent.clientX : window.event.clientX;
    clientY = touchEvent.clientY ? touchEvent.clientY : window.event.clientY;
    isMouse = true
  }

  return { clientX, clientY, isTouch, isMouse }
}