import React, { useContext } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { gEnums } from '../../../enums/globalEnums';
import CircleLoader from '../../../motions/CircleLoader';

const PendingAuth = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_styles } = pageSettings ? pageSettings : {}

  const backgroundSaC = aps_styles ? aps_styles[gEnums.projectStyles.background] : {}

  const { inverted } = props

  return <Segment className={'vs-seg'} {...backgroundSaC} inverted={inverted} placeholder style={{ height: '100%' }}>
    <Header as='h5' icon>
      <CircleLoader />
      {'Validating your profile...'}
    </Header>
  </Segment>
}

export default PendingAuth