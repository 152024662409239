import { getFormPropz } from '../../components/forms/props/formPropz';
import { grts, responseReducers } from './reducerHelpers/dispatchProps';

const rts = {
  handleFormInit: 'handleFormInit',
  handleAppForms: 'handleAppForms',
  handleLocalPropChange: 'handleLocalPropChange',
  ...grts
}

export const appFormReducer = (state, action) => {

  const { type, dispatch } = action

  const { handleLocalPropChange } = appFormHandlers(dispatch)
  const form_handlers = { handleFormDataChange_local: handleLocalPropChange }

  switch (type) {

    case rts.handleAppForms:
      if (action.appForms && action.appForms.appForms) {
        return { ...state, currentAppForms: action.appForms.appForms }
      } else {
        return { ...state, currentAppForms: action.appForms }
      }

    case rts.handleFormInit:
      const data = getFormInit(state, action, form_handlers)
      return { ...state, ...data }

    case rts.handleLocalPropChange:
      return { ...state, data_form: action.data_form }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const appFormInitialState = (initState) => {
  return { ...initState }
};

export const appFormHandlers = (dispatch) => {
  return {
    handleAppForms: (appForms) => { dispatch({ type: rts.handleAppForms, appForms }) },
    handleFormInit: (parentData) => { dispatch({ type: rts.handleFormInit, dispatch, parentData }) },
    handleLocalPropChange: (data_form) => { dispatch({ type: rts.handleLocalPropChange, dispatch, data_form }) },
  }
}

const getFormInit = (state, action, form_handlers) => {

  const { currentAppForms, appFormType, appFormTypes, propIgnores, vit, parentData: parentData_state } = state
  const { parentData: parentData_action } = action

  const _parentData = parentData_action ? parentData_action : parentData_state

  const data = {
    formProps: null,
    viewProps: null,
    noProps: false,
    form_data: _parentData
  }

  if (currentAppForms) {
    if (appFormTypes) {
      let allViewProps = {}
      appFormTypes.forEach(aft => {
        if (currentAppForms[aft]) {
          const _viewProps = currentAppForms[aft]
          allViewProps = { ...allViewProps, ..._viewProps }
        }
      })
      if (allViewProps && propIgnores) propIgnores.forEach(pi => { delete allViewProps[pi] })
      const fpp = { form_handlers, updateProps: { vit }, viewProps: allViewProps, data_current: _parentData }
      const _formProps = getFormPropz(fpp)
      data.formProps = _formProps
      data.viewProps = allViewProps
    } else if (appFormType && currentAppForms[appFormType]) {
      const _viewProps = currentAppForms[appFormType]
      if (_viewProps && propIgnores) propIgnores.forEach(pi => { delete _viewProps[pi] })
      const fpp = { form_handlers, updateProps: { vit }, viewProps: _viewProps, data_current: _parentData }
      const _formProps = getFormPropz(fpp)
      data.formProps = _formProps
      data.viewProps = _viewProps
    } else {
      data.noProps = true
    }
  }

  return data
}