import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Icon, Label, List, Message } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { imageMapHandlers, imageMapInitialState, imageMapReducer } from '../../cnr/reducers/ImageMapReducer';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../../cnr/reducers/SidebarReducer';
import { g_cns } from '../../common/cns';
import { getFirstObject } from '../../common/convert';
import { uniqueKey } from '../../common/keys';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { gEnums } from '../../enums/globalEnums';
import { fs_get_data_all, fs_get_eventData_global } from '../../firestoreData/appData/fsAppData';
import GenericIcon from '../../genericControls/GenericIcon';
import MenuSidebars from '../../sidebars/MenuSidebars';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import { DataManagementContext } from '../viewers/DataManagementViewer';
import { ThumbSwipe } from './ThumbSwipe';

const _useNew = false

// const url = 'https://i.pinimg.com/736x/7f/92/62/7f9262868a0a7aa99684aa04239cde49--home-floor-plans-large-house-floor-plans.jpg'

const sidebarType = 'swipers'
const _zoneCollectionName = '_zones'
const _iconSize = 'large'
const useStatic = false

/**
 * 
 * @param {object} props 
 * @returns A sidebar (imageMap, locations, zones )
 */
const ImageMap = (props) => {

  const { inDashboard, contentOnly, imageMapType } = props

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { paps_state, page_state, eventInfo_state, storage_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { imageMapping: imageMapping_global } = aps_global ? aps_global : {}

  const { storage_fns } = fns

  // papsContext  
  const { pathViews } = paps_state

  // storageContext 
  const { globalFiles } = storage_state ? storage_state : {}
  const { floormap: floorMapFiles } = globalFiles ? globalFiles : {}
  const floorMapFileKeys = floorMapFiles ? Object.keys(floorMapFiles) : []

  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_handlers } = dataManagementContext ? dataManagementContext : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers, item_state } = uiItemContext ? uiItemContext : {}
  const { vld, viewItem } = item_state ? item_state : {}
  const { key: uivi } = viewItem ? viewItem : {}

  // pageContext 
  const imageMapping_vi = imageMapping_global ? _.find(imageMapping_global, { collectionName: uivi }) : {}
  const { mapFieldName: mapFieldName_vi } = imageMapping_vi ? imageMapping_vi : {}
  const _mapFieldName = mapFieldName_vi ? mapFieldName_vi : 'locations'

  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  const { item: itemData } = vld ? getFirstObject(vld) : {}
  const itemLocation = itemData ? itemData[_mapFieldName] : null

  let allowEdit = false

  switch (imageMapType) {
    case gEnums.imageMapTypes.location:
      allowEdit = false
      break;
    default:
      allowEdit = true
  }

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ? sidebar_state : {}
  const { setInit } = sidebar_handlers ? sidebar_handlers : {}
  const { ms_floormap, ms_zones, ms_fields } = sidebar_items ? sidebar_items : {}

  // imageMap_state
  const [imageMap_state, imageMap_dispatch] = useReducer(imageMapReducer, imageMapInitialState({
    collectionName: _zoneCollectionName,
    imageMapType,
    itemLocation,
    mapFieldName: _mapFieldName,
    pathViews,
    setInit,
    storage_fns,
  }));

  const imageMap_handlers = imageMapHandlers(imageMap_dispatch, imageMap_state)
  const { _allZones, mapFieldData, floormapUrl, zones, updating, locationName } = imageMap_state ? imageMap_state : {}
  const mapFieldDataKeys = mapFieldData ? Object.values(mapFieldData).map(item => (item.name)) : []

  const _url = floormapUrl ? floormapUrl : null

  const [allowMapping, setAllowMapping] = useState()
  const [showMessage, setShowMessage] = useState()

  let fullLocation;

  if (ms_floormap && ms_zones) {
    fullLocation = ms_floormap.selected.item + '/' + ms_zones.selected.item
  }

  const handleCancel = () => {
    props.handleCancel && props.handleCancel()
    dataManagement_handlers && dataManagement_handlers.handleSelectDataActionOption('options')
  }

  const handleAllowMapping = () => setAllowMapping(!allowMapping)

  // get _allZones, mapFieldData
  useEffect(() => {
    // gets the zones 
    // get the data from _zones >> _allZones

    fs_get_data_all(pathViews, _zoneCollectionName, imageMap_handlers.setAllZones) // _allZones 

    switch (imageMapType) {
      case gEnums.imageMapTypes.location:
      case gEnums.imageMapTypes.zones:
        // get the _mapFieldName from _globals    
        if (useStatic && staticViews && staticViews[_mapFieldName]) {
          imageMap_handlers.setCurrentMapFieldData(staticViews[_mapFieldName])
        } else {
          fs_get_eventData_global(pathViews, _mapFieldName, false, imageMap_handlers.setCurrentMapFieldData) // mapFieldData
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (_allZones && floormapUrl) {
      imageMap_handlers.handleFloormapUrlChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_allZones, floormapUrl]);

  // sets up the menus (mapping and zones only)
  useEffect(() => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.mapping:
        setInit({ smt: sidebarMenuTypes.one, items: floorMapFileKeys, currents, as: 'floormap', useStartCase: true })
        break;

      case gEnums.imageMapTypes.zones:
        setInit({ smt: sidebarMenuTypes.one, items: floorMapFileKeys, currents, as: 'floormap', useStartCase: true })
        if (!_useNew) {
          setInit({ smt: sidebarMenuTypes.three, items: mapFieldDataKeys.sort(), currents, as: 'fields', useStartCase: true })
        }
        break;

      // this is single mapped location
      case gEnums.imageMapTypes.location:
        const _staticLocations = staticViews ? staticViews[_mapFieldName] : {}
        let _locationName;
        if (_.isArray(itemLocation)) {
          const _locationKey = itemLocation[0]
          const staticLocation = _staticLocations ? _staticLocations[_locationKey] : null
          if (staticLocation) {
            _locationName = staticLocation.name
          }
        } else {
          if (_staticLocations && itemLocation && _staticLocations[itemLocation]) {
            _locationName = _staticLocations[itemLocation].name
          }
        }
        imageMap_handlers.handleLocationName(_locationName)
        break;

      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [mapFieldData, floorMapFiles]);

  useEffect(() => {
    if (ms_floormap && ms_floormap.selected && ms_floormap.selected.item) {
      switch (imageMapType) {
        case gEnums.imageMapTypes.zones:
        case gEnums.imageMapTypes.mapping:
          imageMap_handlers.getFloormapImageFromStorage(ms_floormap.selected.item)
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [mapFieldData, _allZones, ms_floormap && ms_floormap.selected && ms_floormap.selected.item]);

  useEffect(() => {
    if (itemLocation) {
      switch (imageMapType) {
        case gEnums.imageMapTypes.location:
          if (mapFieldData && itemLocation) {
            const _itemKey = locationName ? _.findKey(mapFieldData, { name: locationName }) : _.findKey(mapFieldData, { name: itemLocation })
            if (_itemKey) {
              if (mapFieldData[_itemKey]) {
                const mapItem = mapFieldData[_itemKey]
                const { zone } = mapItem ? mapItem : {}
                const { imageKey } = zone ? zone : {}
                if (imageKey) {
                  imageMap_handlers.getFloormapImageFromStorage(imageKey)
                }
              }
            }
          }
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [mapFieldData, _allZones, itemLocation]);

  useEffect(() => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.location:
        if (_allZones && mapFieldData && itemLocation) {
          imageMap_handlers.handleLocation(imageMapType)
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [mapFieldData, _allZones]);

  // set the zone
  useEffect(() => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.zones:
      case gEnums.imageMapTypes.mapping:
        if (ms_zones && ms_zones.selected && ms_zones.selected.item) {
          imageMap_handlers.setCurrentZone(zones[ms_zones.selected.item])
        }
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ms_zones && ms_zones.selected && ms_zones.selected.item]);

  // simple message
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage()
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [showMessage]);

  const handleZoneClick = (ce) => sidebar_handlers.handleItemClick('zones', ce.zone)
  const updateZonesToDb = () => imageMap_handlers.updateZonesToDb(ms_floormap.selected.item)

  const handleListClick = (item) => {
    const updateProps = {
      fieldItem: item,
      floormapSelectedItem: ms_floormap.selected.item,
      zoneIndex: ms_zones.selected.index
    }
    imageMap_handlers.updateFieldData(updateProps)
  }

  const updateFieldDataToDb = () => {
    const updateProps = {
      fieldItem: ms_fields.selected.item,
      floormapSelectedItem: ms_floormap.selected.item,
      zoneIndex: ms_zones.selected.index
    }
    imageMap_handlers.updateFieldDataToDb(updateProps)
  }

  const doneMessage = () => <Message size={'tiny'} color={'green'}  >
    <Message.Content>
      {"Updated"}
    </Message.Content>
  </Message>

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const mappingPopup = () => <span className={g_cns.item_allow}>
    {gi('item-icon-allow', 'block layout', handleAllowMapping, null, { circular: true, size: _iconSize, color: allowMapping ? 'blue' : 'grey', inverted: true })}
  </span>

  const thumbSwipe = () => <ThumbSwipe
    url={_url}
    imageMapType={allowMapping ? imageMapType : imageMapType}
    handleZoneClick={handleZoneClick}
    imageMap_handlers={imageMap_handlers}
    imageMap_state={imageMap_state}
  />

  const listItem = (item, index) => {
    const mld = mapFieldData[item]
    const { name, zone } = mld ? mld : {}
    const { imageKey, zoneIndex } = zone ? zone : { imageKey: 'none', zoneIndex: 0 }
    const _zoneIndex = zoneIndex ? zoneIndex + 1 : 'none'
    const currentLocation = imageKey + '/' + _zoneIndex
    return <List.Item key={uniqueKey('im', 'li', index)}>
      <List.Content>
        <List.Header >{name}</List.Header>
        <List.Description> <Label size={'small'} color={'green'} inverted >
          <Icon name='check' />{currentLocation}
        </Label></List.Description>
        <List.Description><Label size={'small'} color={'blue'} inverted onClick={() => { handleListClick(item) }}>
          <Icon name='refresh' />{fullLocation}
        </Label></List.Description>
      </List.Content>
    </List.Item>
  }

  const locationList = () => {
    const listItems = mapFieldDataKeys.sort().map((item, index) => (listItem(item, index)))
    return <Wrapper
      header={'Locations'}
      content={<List divided relaxed size='tiny'>
        {listItems}
      </List>}
      wrapperType={wrapperTypes.padded}
    >
    </Wrapper>
  }

  const zoneContent = () => <div className='zone-split'>
    {thumbSwipe()}
    {mapFieldDataKeys && locationList()}
  </div>

  const mapContent = () => {
    if (_url) {
      switch (imageMapType) {
        case gEnums.imageMapTypes.zones:
          return _useNew ? zoneContent() : thumbSwipe()
        default:
          return thumbSwipe()
      }
    } else {
      return <NoData altCaption={'Map Images'} />
    }
  }

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={mapContent()}
    dimmed={dimmed}
  />
  const isOk = (ms) => ms && ms.selected && ms.selected.item ? true : false

  const saveButtons_mapping = () => <UiSaveButtons save={{ oc: updateZonesToDb, caption: 'Update Zones' }} />

  const saveButtons_zones = () => {
    const allow = isOk(ms_floormap) && isOk(ms_zones) && isOk(ms_fields)
    return <UiSaveButtons save={{ disabled: !allow, oc: updateFieldDataToDb, caption: 'Update ' + _.startCase(_mapFieldName) }} />
  }

  const sbs = () => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.zones:
        return saveButtons_zones()
      case gEnums.imageMapTypes.mapping:
        return saveButtons_mapping()
      default:
        return saveButtons_mapping()
    }
  }

  const footer = () => showMessage ? doneMessage() : sbs()

  const sidebarContent = () => <React.Fragment>
    {mappingPopup()}
    {menuSidebars()}
  </React.Fragment>

  const sidebarWrapper = () => <Wrapper
    content={sidebarContent()}
    footer={allowEdit && footer()}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
    handleBack={inDashboard ? handleCancel : null}
  />

  const content = () => {
    switch (imageMapType) {
      case gEnums.imageMapTypes.location:
        return mapContent()
      case gEnums.imageMapTypes.mapping:
        return sidebarWrapper()
      case gEnums.imageMapTypes.zones:
        return sidebarWrapper()
      default:
        return sidebarWrapper()
    }
  }

  const fullPageWrapper = () => {

    let hc = props.handleCancel ? handleCancel : null
    let caption = locationName ? locationName : itemLocation
    let fullMode = inDashboard

    if (!caption) { caption = 'Image Mapping' }

    if (!hc && item_handlers) {
      switch (imageMapType) {
        case gEnums.imageMapTypes.location:
          hc = item_handlers.handleModifyMapping
          break;
        default:
          hc = item_handlers.handleShowItemSidebar
          caption = 'Image Mapping'
      }
    } else {
      switch (imageMapType) {
        case gEnums.imageMapTypes.mapping:
          fullMode = true
          break;
        default:
        // nothing
      }
    }

    return <FullPageWrapper
      content={content()}
      handleCancel={hc}
      topperCaption={caption}
      topperCaption2={_mapFieldName ? _.startCase(_mapFieldName) : null}
      fullMode={fullMode}
    />
  }

  if (contentOnly) {
    if (allowEdit) {
      return ms_floormap && ms_floormap.items ? content() : <div></div>
    } else {
      return content()
    }
  } else {
    if (allowEdit) {
      return ms_floormap && ms_floormap.items ? fullPageWrapper() : <div></div>
    } else {
      return fullPageWrapper()
    }
  }
}

export default ImageMap