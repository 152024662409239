import _ from "lodash";

/**
 * 
 * @param {object} vld - the data for the page 
 * @param {object} dataResultProps - object containing setting for the page
 * @returns 
 */
export const pageDataValues = (vld, dataResultProps) => {

  const { isPageData, view, viewItem, data_state, captionProps, subCaptionProps, caption_page, singleDataItem, staticViews } = dataResultProps

  if (isPageData) {
    const data = vld ? vld[Object.keys(vld)[0]] : {}
    let currentPageDataCaption;
    let currentPageDataSubCaption;
    if (data) {
      if (captionProps && captionProps.length > 0) {
        currentPageDataCaption = ''
        captionProps.forEach((cp, index) => {
          const cv = data[cp]
          if (cv) {
            currentPageDataCaption += cv
            if (index < captionProps.length) { currentPageDataCaption += ' ' }
          }
        })
      }

      if (subCaptionProps && subCaptionProps.length > 0) {
        currentPageDataSubCaption = ''
        subCaptionProps.forEach((cp, index) => {
          let cv = data[cp]
          if (cv) {
            const staticList = staticViews && staticViews[cp] ? staticViews[cp] : null
            if (staticList && staticList[cv]) { cv = staticList[cv].name }
            currentPageDataSubCaption += cv
            if (index < subCaptionProps.length) { currentPageDataSubCaption += ' ' }
          }
        })
      }
    }

    const prs = {
      singles: data_state ? data_state.singles : null,
      currentPageData: data,
      currentPageViewListData: !_.isEmpty(vld) ? vld : null,
      currentPageDataCaption: currentPageDataCaption ? currentPageDataCaption : caption_page,
      currentPageDataSubCaption: currentPageDataSubCaption,
      fetched: true,
      pageDataKey: view,
    }
    return prs
  } else {
    const x = {
      isLoading: false,
      singleDataItem,
      dataName: view,
      [view]: vld,
      uivi: view,
      fetched: true,
      viewItem,
      viewListData: vld,
      pageDataKey: view
    }
    return x
  }
}