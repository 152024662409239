import React from 'react';
import 'react-phone-number-input/style.css';
import { Form } from 'semantic-ui-react';

const EmailAssignment = (props) => {

  const { contact, email, handleContact } = props

  const emailLabel = contact && contact.name ? 'Email (' + contact.name + ')' : 'Email'

  const handleChange = (e, data) => handleContact({ email: data.value })

  const emailInput = () => <div className={'phone-container full'}>
    <Form.Input
      placeholder={emailLabel}
      value={email}
      itemkey={'email'}
      label={emailLabel}
      propname='email'
      onChange={handleChange}
    />
  </div>

  const emailForm = () => <Form>
    <Form.Field>
      {emailInput()}
    </Form.Field>
  </Form>

  return emailForm()
}

export default EmailAssignment