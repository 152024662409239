import { _globalCollectionName } from "../../../projectSpecific/sports/dbActions/globals"
import { ammendDeleteFields } from "../../common/dataAdjust"
import { copyObj } from "../../common_web/copy"
import { gEnums } from "../../enums/globalEnums"
import { createRefPath_client, createRefPath_event } from "../../firestoreData/appData/appRefPaths"
import { fs_get_data } from "../../firestoreData/appData/fsAppData"
import { fs_set_doc } from "../../firestoreData/appData/fsData"
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"

const rts = {
  handleAllStaticViews: 'handleAllStaticViews',
  handleAppData: 'handleAppData',
  handleAppProfileData: 'handleAppProfileData',
  handleAppUserUrls: 'handleAppUserUrls',
  handleClientEvents: 'handleClientEvents',
  handleEventInit: 'handleEventInit',
  handleGetAppUserData: 'handleGetAppUserData',
  handleGetGlobals: 'handleGetGlobals',
  handleGoogleSheetsStaticViews: 'handleGoogleSheetsStaticViews',
  handleSetEventInit: 'handleSetEventInit',
  handleSetGlobals: 'handleSetGlobals',
  handleUpdateEvents: 'handleUpdateEvents',
  handleUpdateEventStatus: 'handleUpdateEventStatus',
  ...grts
}

export const eventInfoReducer = (state, action) => {

  const { appData, appCollectionsData, pathViews } = state
  const { type, dispatch } = action

  const eventInfo_handlers = eventInfoHandlers(dispatch)
  const { handleSetEventInit, handleSetGlobals } = eventInfo_handlers

  const _appData = appData ? copyObj(appData) : {}
  const _appCollectionsData = appCollectionsData ? copyObj(appCollectionsData) : {}

  switch (type) {

    case rts.handleEventInit:

      const { startDataCollections } = action
      startDataCollections.forEach(ic => {
        const _refPath = createRefPath_event(pathViews, [ic])
        fs_get_data({ refPath: _refPath, callback: handleSetEventInit, opts: { listen: true, cbProps: { collectionName: ic } } })
      })
      return { ...state }

    case rts.handleSetEventInit:
      const { data, cbProps } = action
      const { collectionName } = cbProps
      _appCollectionsData[collectionName] = data
      return {
        ...state,
        appCollectionsData: _appCollectionsData
      }

    case rts.handleGetGlobals:
      let _refPathG = createRefPath_event(pathViews, [_globalCollectionName])
      fs_get_data({ refPath: _refPathG, callback: handleSetGlobals, opts: { ignoreId: true } })
      return { ...state }

    case rts.handleSetGlobals:
      return { ...state, globals: action.d }

    case rts.handleAppData:
      _appData[action.pageKey] = action.data
      return { ...state, staticViews_gs: action.staticViews_gs, appData: _appData }

    case rts.handleGetAppUserData:
      return { ...state }

    case rts.handleAllStaticViews:
      const allStaticViews = action.d
      const { staticViews } = allStaticViews ? allStaticViews : {}
      return { ...state, allStaticViews, staticViews, staticViewKeys: staticViews ? Object.keys(staticViews) : [] }

    case rts.handleAppUserUrls:
      return { ...state, appUserUrls: action.profileUrls }

    case rts.handleAppProfileData:
      return { ...state, appProfileData: action.d }

    case rts.handleClientEvents:
      return { ...state, clientEvents: action.events }

    case rts.handleGoogleSheetsStaticViews:
      return { ...state, staticViews_gs: action.staticViews_gs }

    case rts.handleUpdateEventStatus:
      const { events: eventKey } = pathViews
      const _eventData = copyObj(action.eventData)
      ammendDeleteFields(_eventData)
      const _refPath = createRefPath_client(pathViews, ['events', eventKey])
      // fs_set_doc(_refPath, _eventData, true)
      return { ...state }

    case rts.handleUpdateEvents:
      const _currentAppData = [...action.currentAppData]
      const activeEvents = []
      _currentAppData.forEach(itemData => {
        let allow = true
        if (action.itemKey && action.itemKey !== itemData._itemKey) { allow = false }
        const _selectedItem = copyObj(itemData)
        if (_selectedItem.eventStatusType === gEnums.eventStatusTypes.active) {
          activeEvents.push(_selectedItem._itemKey)
        }
        if (allow) {
          const _refPath = createRefPath_client(pathViews, ['events', _selectedItem._itemKey])
          ammendDeleteFields(_selectedItem)
          fs_set_doc(_refPath, _selectedItem, true)
        }
      })
      const _refPathC = createRefPath_client(pathViews)
      const clientData = { activeEvents: activeEvents }
      fs_set_doc(_refPathC, clientData, true, action.cb)
      return { ...state }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.handleCloseConfirmation:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    default:
      return { ...state }
  }
}

export const eventInfoInitialState = (initState) => {
  return { ...initState, appData: {}, appCollectionsData: {} }
};

export const eventInfoHandlers = (dispatch) => {
  return {
    handleAllStaticViews: (d) => { dispatch({ type: rts.handleAllStaticViews, dispatch, d }) },
    handleAppData: (pageKey, data) => { dispatch({ type: rts.handleAppData, dispatch, pageKey, data }) },
    handleAppProfileData: (d) => { dispatch({ type: rts.handleAppProfileData, dispatch, d }) },
    handleAppUserUrls: (profileUrls) => { dispatch({ type: rts.handleAppUserUrls, dispatch, profileUrls }) },
    handleClientEvents: (events) => { dispatch({ type: rts.handleClientEvents, dispatch, events }) },
    handleEventInit: (startDataCollections) => { dispatch({ type: rts.handleEventInit, dispatch, startDataCollections }) },
    handleGetAppUserData: (d) => { dispatch({ type: rts.handleGetAppUserData, dispatch, d }) },
    handleGetGlobals: () => { dispatch({ type: rts.handleGetGlobals, dispatch }) },
    handleSetGlobals: (d) => { dispatch({ type: rts.handleSetGlobals, dispatch, d }) },
    handleGoogleSheetsStaticViews: (staticViews_gs) => { dispatch({ type: rts.handleGoogleSheetsStaticViews, dispatch, staticViews_gs }) },
    handleSetEventInit: (data, cbProps) => { dispatch({ type: rts.handleSetEventInit, dispatch, data, cbProps }) },
    handleUpdateEvents: (currentAppData, itemKey, cb) => { dispatch({ type: rts.handleUpdateEvents, dispatch, currentAppData, itemKey, cb }) },
    handleUpdateEventStatus: (eventData) => { dispatch({ type: rts.handleUpdateEventStatus, dispatch, eventData }) },
    ...responseHandlers(dispatch)
  }
} 