import React, { useContext, useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { DataContext } from '../../cnr/contexts/DataContext';
import { FilterContext } from '../../cnr/contexts/FilterContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';
import { getFirstObject } from '../../common/convert';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { getAlphaMenuItems } from './genericMenuItem';

const AlphabetMenu = (props) => {

  const { dataItems } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states } = parentContext ? parentContext : {}
  const { page_state, paps_state } = states

  // papsContext  
  const { view, viewKey } = paps_state ? paps_state : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_styles } = pageSettings ? pageSettings : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem } = item_state ? item_state : {}
  const { display, grouping } = viewItem ? viewItem : {}
  const { alphaDisplayType, restrictToExisting, fetchByAlphabet, showFavorites, alphaProp } = grouping ? grouping : {}
  const { sortProp } = display ? display : {}

  const _restrictToExisting = restrictToExisting || (props && props.restrictToExisting)

  // filterContext 
  const filterContext = useContext(FilterContext);
  const { uiFilter_handlers } = filterContext ? filterContext : {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state, data_handlers } = dataContext ? dataContext : {}
  const { viewListData } = data_state ? data_state : {}

  const _viewListData = dataItems ? dataItems : viewListData

  let storageItem = 'alpha-item-'
  storageItem += viewKey ? view + '-' + viewKey : view

  const alphabetSnC = aps_styles ? aps_styles[gEnums.projectStyles.alphabet] : {}

  // local state
  const [alphaItems, setAlphaItems] = useState([])
  const [alphaItem, setAlphaItem] = useState()
  const [dataAlphaProp, setDataAlphaProp] = useState()
  const [snc, setSnc] = useState({})

  // 176192
  useEffect(() => {
    if (_viewListData) {
      let _alphaProp = alphaProp ? alphaProp : sortProp
      if (props && props.sortProp) { _alphaProp = props.sortProp }
      const { item: dataItem } = getFirstObject(_viewListData)
      if (!_alphaProp && dataItem) {
        if (dataItem.lastName) {
          _alphaProp = 'lastName'
        } else if (dataItem.name) {
          _alphaProp = 'name'
        }
      }
      setDataAlphaProp(_alphaProp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_viewListData]);

  useEffect(() => {
    if (_viewListData && dataAlphaProp) {
      const { alphaItems: ais, firstAlpha: fa } = getAlphaMenuItems(_viewListData, dataAlphaProp, _restrictToExisting)
      setAlphaItems(ais)
      let _alphaItem = window.localStorage.getItem(storageItem) ? parseInt(window.localStorage.getItem(storageItem)) : null
      if (props && props.alphaValue) { _alphaItem = props.alphaValue }
      // set the _alphaItem to the first item if it is not there
      if (!ais.includes(_alphaItem)) { _alphaItem = ais[0] }
      setAlphaItem(_alphaItem ? _alphaItem : fa)
      if (ais.length === 0) {
        setSnc({ ...alphabetSnC, ...{ style: { display: 'none' } } })
      } else {
        setSnc({ ...alphabetSnC, })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dataAlphaProp, _viewListData]);

  useEffect(() => {
    if (alphaItem) {
      if (fetchByAlphabet && data_handlers) {
        data_handlers.handleSetAlphabetValue(alphaItem)
      } else if (props.handleAlphaChange) {
        props.handleAlphaChange(alphaItem)
      } else {
        uiFilter_handlers && uiFilter_handlers.handleAlphaChange(alphaItem, true)
        props && props.handleAlphaChange && props.handleAlphaChange(alphaItem)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [alphaItem]);

  const handleItemClick = (e, { name }) => {
    window.localStorage.setItem(storageItem, name);
    setAlphaItem(name)
  }

  const menuItem = (val, isChar) => <Menu.Item
    name={val.toString()}
    key={uniqueKey('abm', val)}
    active={val === parseInt(alphaItem)}
    onClick={handleItemClick}
  >
    {!isChar && String.fromCharCode(val)}
    {isChar && val}
  </Menu.Item>

  const alphaMis = () => {
    const mis = []
    alphaItems.forEach(ai => {
      mis.push(menuItem(ai))
    })
    if (showFavorites) {
      mis.unshift(menuItem('Favs', true))
    }
    // if (mis.length === 0) { mis.push(menuItem(48)) } 
    return mis
  }

  const menu = () => <Menu
    inverted
    borderless
    vertical={alphaDisplayType === gEnums.alphaDisplayTypes.vertical ? true : false}
    compact={alphaDisplayType === gEnums.alphaDisplayTypes.vertical ? true : null}
    {...snc}>
    {alphaItems && alphaMis()}
  </Menu>

  return <React.Fragment>
    {menu()}
  </React.Fragment>

}

export default AlphabetMenu