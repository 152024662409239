import _ from 'lodash';
import { removeAllEmpties } from "../../common/dataAdjust";
import { createRefPath, createRefPath_event } from "../../firestoreData/appData/appRefPaths";
import { fs_delete_doc, fs_set_doc, fs_update_doc } from "../../firestoreData/appData/fsData";
import { fsfn_deleteEventCollection } from "../../functions/fbDelete";
import { createNewViewsAndViewItemsSettingsToDb } from "../settings/updateSettings";
import { ammendItemsForWeb } from '../helpers/sheetsDataAdjust';

const _allowUpdates = true

export const recreateAllDataCollectionsOneByOne = async (state, collectionName, updateSettings, updateStaticViews, updateOnly, allowMerge, showNewOnly, callback) => {

  const { previewInfo, previewStatic, pathViews, mergedRecreateInfo } = state ? state : {}
  const { projectData } = previewInfo ? previewInfo : {}
  const { dataCollections } = projectData ? projectData : {}
  const dataCollectionKeys = Object.keys(dataCollections)
  const dcUpdates = {}

  const callback_settings = () => callback(dcUpdates, true)

  const checkDone = (updates) => {
    let done = true
    Object.keys(updates).forEach(key => {
      const update = updates[key]
      if (update.applied && !update.updated) {
        done = false
      }
    })
    if (updateSettings && mergedRecreateInfo) {
      if (done) {
        ammendStaticViewsPromise(updateStaticViews, pathViews, previewStatic).then(res => {
          if (updateSettings) {
            const { projectSettings } = mergedRecreateInfo ? mergedRecreateInfo : {}
            const { viewItems, views } = projectSettings ? projectSettings : {}
            createNewViewsAndViewItemsSettingsToDb(pathViews, viewItems, views, callback_settings)
            return false
          } else {
            return done
          }
        })
      }
    } else {
      return done
    }
  }

  dataCollectionKeys.forEach(k => {
    if (collectionName) {
      if (k === collectionName) {
        dcUpdates[k] = { updating: false, updated: false, applied: false }
      }
    } else {
      dcUpdates[k] = { updating: false, updated: false, applied: false }
    }
  })

  // loop the dataCollections
  dataCollectionKeys.forEach((collectionKey, index) => {
    let allowUpdate = false
    if (collectionName) {
      if (collectionName === collectionKey) {
        allowUpdate = true
      }
    } else {
      allowUpdate = true
    }
    if (allowUpdate) {
      const dataCollectionData = dataCollections[collectionKey]
      let _dataCollectionData;
      if (showNewOnly) {
        _dataCollectionData = _.filter(dataCollectionData, { _new: true })
      } else {
        _dataCollectionData = dataCollectionData
      }
      dcUpdates[collectionKey].applied = true
      dcUpdates[collectionKey].updating = true
      callback(dcUpdates)

      recreateDataCollection(pathViews, collectionKey, _dataCollectionData, updateOnly, allowMerge).then(res => {
        dcUpdates[collectionKey].updating = false
        dcUpdates[collectionKey].updated = true
        callback(dcUpdates, checkDone(dcUpdates))
      })
    }
  })
}

const ammendStaticViewsPromise = async (updateStaticViews, pathViews, previewStatic) => {
  const promises = []
  if (updateStaticViews) {
    Object.keys(previewStatic).forEach(psKey => {
      const previewStaticItem = previewStatic[psKey]
      const _refPath = createRefPath_event(pathViews, ['staticViews', psKey])
      promises.push(fs_set_doc(_refPath, previewStaticItem, null, null, true))
    })
  }
  return Promise.all(promises)
}

export const recreateAllDataCollections = async (state, collectionName, updateOnly) => {
  const { previewInfo } = state ? state : {}
  const { projectData } = previewInfo ? previewInfo : {}
  const { dataCollections } = projectData ? projectData : {}
  const dataCollectionKeys = collectionName ? [collectionName] : Object.keys(dataCollections)
  const result = await recreateDataCollections(state, collectionName, updateOnly)
  const items = {}
  if (result) {
    dataCollectionKeys.forEach((k, index) => {
      items[k] = result[index] ? 'Updated ' + result[index].length + ' Document(s)' : "No Documents Updated"
    })
  }
  return items
}

const recreateDataCollections = async (state, collectionName, updateOnly) => {
  const promises = []
  const { pathViews, previewInfo } = state ? state : {}
  const { projectData } = previewInfo ? previewInfo : {}
  const { dataCollections } = projectData ? projectData : {}
  if (dataCollections) {
    Object.keys(dataCollections).forEach(collectionKey => {
      if (collectionName) {
        if (collectionKey === collectionName) {
          promises.push(recreateDataCollection(pathViews, collectionKey, dataCollections[collectionKey], updateOnly))
        }
      } else {
        promises.push(recreateDataCollection(pathViews, collectionKey, dataCollections[collectionKey], updateOnly))
      }
    })
  }
  return Promise.all(promises)
}

/**
 * Deletes the data collection and adds the data to a promise
 * @param {object} pathViews 
 * @param {string} collectionKey 
 * @param {object} dataCollectionData 
 * @returns a promise
 */
const recreateDataCollection = async (pathViews, collectionKey, dataCollectionData, updateOnly, allowMerge) => {
  const promises = []
  // const _refPath = createRefPath_event(pathViews, [collectionKey])
  // const _existingData = await fs_get_data({ refPath: _refPath })
  // if (_existingData) {

  if (!updateOnly) {
    await fsfn_deleteEventCollection(pathViews, collectionKey, 100)
    await removeStaticView(pathViews, collectionKey)
  }

  ammendItemsForWeb(dataCollectionData, ['description'])

  promises.push(addDataCollectionPromise(pathViews, collectionKey, dataCollectionData, updateOnly, allowMerge))
  // }
  return Promise.all(promises)
}

const removeStaticView = async (pathViews, collectionKey) => {
  const _refPath = createRefPath_event(pathViews, ['staticViews', collectionKey])
  await fs_delete_doc(_refPath)
}

/**
 * Add a collection based on the collectionKey with the data in dataToUpdate
 * @param {string} collectionKey 
 * @param {object} dataToUpdate 
 * @returns a promise
 */
const addDataCollectionPromise = async (pathViews, collectionKey, dataCollectionData, updateOnly, allowMerge) => {
  const promises = []
  const _refPath = createRefPath_event(pathViews, [collectionKey])
  if (dataCollectionData) {
    Object.keys(dataCollectionData).forEach(key => {
      if (key) {
        const data = dataCollectionData[key]
        removeDeleteFields(data)
        removeAllEmpties(data)
        if (data) {
          const _updatePath = createRefPath([key], _refPath)
          if (updateOnly) {
            if (allowMerge) {
              if (_allowUpdates) {
                promises.push(fs_set_doc(_updatePath, data, true, null, true))
              } else {
                console.log('addDataCollectionPromise - update/merge', _updatePath, data)
              }
            } else {
              if (_allowUpdates) {
                promises.push(fs_update_doc(_updatePath, data, null, true))
              } else {
                console.log('addDataCollectionPromise - update only', _updatePath, data)
              }
            }
          } else {
            if (_allowUpdates) {
              promises.push(fs_set_doc(_updatePath, data, null, null, true))
            } else {
              console.log('addDataCollectionPromise - set only', _updatePath, data)
            }
          }
        }
      }
    })
  }
  return Promise.all(promises)
}

const removeDeleteFields = (data) => {
  if (data) {
    Object.keys(data).forEach(propName => {
      if (propName.startsWith('_') || (data[propName] && (data[propName]._deleteMe))) {
        delete data[propName]
      }
    })
  }
}


/**
 * Deletes a collection based on the collectionKey
 * @param {string} collectionKey 
 * @param {object} existingData 
 * @returns a promise
 */
const deleteDataCollectionPromise = async (pathViews, collectionKey, existingData) => {
  const promises = []
  const _refPath = createRefPath_event(pathViews, [collectionKey])
  if (existingData) {
    Object.keys(existingData).forEach(key => {
      const _deletePath = createRefPath([key], _refPath)
      promises.push(fs_delete_doc(_deletePath, null, true))
    })
  }
  return Promise.all(promises)
}