import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { DataManagementContext } from '../../components/viewers/DataManagementViewer';
import { gEnums } from '../../enums/globalEnums';
import { googleSheetsHandlers, googleSheetsInitialState, googleSheetsReducer, googleSheetsTypes } from '../reducers/GoogleSheetsReducer';
import { ParentContext } from './ParentContext';

/** AppContext */
export const GoogleSheetsContext = createContext();

/** Global OtherDatas for the Event */
const GoogleSheetsProvider = (props) => {

  const { googleSheetsType } = props ? props : {}

  const parentContext = useContext(ParentContext)
  const { states, handlers, settings } = parentContext ? parentContext : {}
  const { appSettings_state, eventInfo_state, page_state, paps_state } = states ? states : {}
  const { eventInfo_handlers, page_handlers } = handlers ? handlers : {}
  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { globalAppSettings, logging } = homeSettings_global ? homeSettings_global : {}
  const { databaseProjectId } = globalAppSettings ? globalAppSettings : {}
  const { dataRefreshOn, dataSourceType } = appSettings_state ? appSettings_state : {}

  // papsContext 
  const { pathViews } = paps_state ? paps_state : {}

  // pageContext  
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems } = pageSettings ? pageSettings : {}
  const { googleSheets, googleForms, googleSheetsImportOptions, appDataSource, dataOptions } = aps_global ? aps_global : {}
  const { appDataSourceType } = appDataSource ? appDataSource : {}
  const { googleSheetsId } = googleSheets ? googleSheets : {}
  const { googleFormsResponseId } = googleForms ? googleForms : {}
  const { dateFormat } = dataOptions ? dataOptions : {}

  const _appDataSourceType = dataSourceType ? dataSourceType : appDataSourceType

  // eventInfoContext  
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ? dataManagementContext : {}

  const forDataManagement = dataManagement_state ? true : false

  // googleSheets
  const appInfo = { pageSettings, staticViews, aps_viewItems }
  const googleSheets_initState = { appInfo, pathViews, databaseProjectId, googleSheetsImportOptions, forDataManagement, logging }
  const [googleSheets_state, googleSheets_dispatch] = useReducer(googleSheetsReducer, googleSheetsInitialState(googleSheets_initState));
  const googleSheets_handlers = googleSheetsHandlers(googleSheets_dispatch, googleSheets_state)
  const { googleSheetsOn, googleSheetsData, staticViews: staticViews_googleSheets, appSettings: appSettings_google } = googleSheets_state ? googleSheets_state : {}

  useEffect(() => {
    if (googleSheetsId) {
      switch (googleSheetsType) {
        case googleSheetsTypes.normal:
          if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
          googleSheets_handlers.handleGetGoogleSheet(googleSheetsId)
          break;
        case googleSheetsTypes.registration:
          googleSheets_handlers.handleGetGoogleSheet(googleFormsResponseId)
          break;
        case googleSheetsTypes.app:
          switch (_appDataSourceType) {
            case gEnums.dataSourceTypes.googleSpreadSheet:
              if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
              googleSheets_handlers.handleStartUpdate()
              googleSheets_handlers.handleGetGoogleSheet(googleSheetsId)
              break;
            default:
            // nothing
          }
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSheetsId, googleFormsResponseId, dataRefreshOn, _appDataSourceType]);

  // updates the eventInfo.staticViews_gs
  useEffect(() => {
    if (appSettings_google) {
      console.log('appSettings_google', appSettings_google)
      page_handlers.handleAmmendPageSettings(appSettings_google)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSettings_google]);

  // updates the eventInfo.staticViews_gs
  useEffect(() => {
    if (eventInfo_handlers) {
      switch (_appDataSourceType) {
        case gEnums.dataSourceTypes.googleSpreadSheet:
          eventInfo_handlers.handleGoogleSheetsStaticViews(staticViews_googleSheets)
          break;
        default:
          eventInfo_handlers.handleGoogleSheetsStaticViews(null)
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSheetsData, _appDataSourceType]);

  const providerContext = () => <GoogleSheetsContext.Provider
    value={{ googleSheets_handlers: googleSheets_handlers, googleSheets_state: googleSheets_state }}>
    {props.children}
  </GoogleSheetsContext.Provider>

  switch (_appDataSourceType) {
    case gEnums.dataSourceTypes.googleSpreadSheet:
    case gEnums.dataSourceTypes.googleSheetsData:
      return googleSheetsData ? providerContext() : providerContext()
    default:
      if (googleSheetsOn) {
        return googleSheetsData ? providerContext() : providerContext()
      } else {
        return providerContext()
      }
  }
}

export default GoogleSheetsProvider