import { removeFromObject } from '../../../../global/common/sorting';
import { createRefPath, createRefPath_client, createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_set_doc, fs_update_doc } from '../../../../global/firestoreData/appData/fsData';

const _clientCollections = ['golfCourses'];
const eventCollections = ['teams', 'golfers'];

const _allowGolferUpdate = false

export const golfEventReducer = (state, action) => {

  const { paps_state } = state
  const { pathViews } = paps_state ? paps_state : {}
  const { dispatch, type, golfData, viKey } = action

  const { setGolfEventData } = golfEventHandlers(dispatch)

  switch (type) {

    case rts.handleGet_clientSubData:
      getClientSubData(pathViews, setGolfEventData)
      return { ...state }

    case rts.handleGet_eventSubData:
      getEventSubData(pathViews, setGolfEventData)
      return { ...state }

    case rts.setGolfEventData:
      let _golfData = golfData
      switch (viKey) {
        case 'teams':
          if (golfData) {
            const teams = {}
            Object.keys(golfData).forEach((key, index) => {
              teams['team' + (index + 1)] = golfData[key]
              teams['team' + (index + 1)].key = key
            })
            _golfData = teams
          }
          break;

        case 'golfers': // allGolfers
          if (_golfData) {
            Object.keys(_golfData).forEach((key, index) => {
              _golfData[key]._itemKey = key
            })
            // console.log('_golfData', _golfData)
            // updateClientGolfers(pathViews,_golfData) 
            // updateEventGolfers(pathViews, _golfData) 
            // copyGolfers(pathViews, _golfData)
          }
          break;
        default:
        // nothing
      }

      return {
        ...state,
        [viKey]: _golfData,
      }

    case rts.handleUpdate_eventGolfer:
      handleUpdate_eventGolfer(pathViews, action.golfer, action.callback)
      return { ...state }

    default:
      return { ...state }
  }
}

export const golfEventHandlers = (dispatch) => {
  return {
    handleGet_clientSubData: () => { dispatch({ type: rts.handleGet_clientSubData, dispatch }) },
    handleGet_eventSubData: () => { dispatch({ type: rts.handleGet_eventSubData, dispatch }) },
    handleUpdate_eventGolfer: (golfer, callback) => { dispatch({ type: rts.handleUpdate_eventGolfer, dispatch, golfer, callback }) },
    setGolfEventData: (golfData, viKey) => { dispatch({ type: rts.setGolfEventData, dispatch, golfData, viKey }) },
  }
}

export const rts = {
  handleGet_clientSubData: 'handleGet_clientSubData',
  handleGet_eventSubData: 'handleGet_eventSubData',
  handleUpdate_eventGolfer: 'handleUpdate_eventGolfer',
  setGolfEventData: 'setGolfEventData',
}

export const golfEventInitialState = (init_state) => {
  return { ...init_state }
};

const handleUpdate_eventGolfer = (pathViews, golfer, callback) => {
  const _golfer = { ...golfer }
  const { _itemKey } = _golfer
  // const docFieldG = 'golfers.' + _itemKey 
  removeFromObject(_golfer, ['_itemKey', 'id', 'handicap', 'checked'])
  const _updateRef = createRefPath_event(pathViews, ['golfers', _itemKey])
  console.log('_updateRef', _updateRef)
  console.log('dataToUpdate', _golfer)
  if (_allowGolferUpdate) {
    fs_set_doc(_updateRef, _golfer, true, callback)
  }
}


/** gets the data for the golfEvent */
const getClientSubData = (pathViews, callback) => {
  const _baseRef = createRefPath_client(pathViews)
  _clientCollections.forEach((viKey, index) => {
    const _clientRef = createRefPath([viKey], _baseRef)
    fs_get_data({ refPath: _clientRef }).then(rd => {
      callback(rd, viKey)
    })
  })
}

const getEventSubData = (pathViews, callback) => {

  const _baseRef = createRefPath_event(pathViews)

  eventCollections.forEach((viKey, index) => {
    const _getRef = createRefPath([viKey], _baseRef)
    //  opts: { listen: true } 
    fs_get_data({ refPath: _getRef, }).then(rd => {
      // if (viKey.startsWith('_')) {
      //   const rdn = viKey.substring(1)
      //   const _rd = rd && rd[rdn] ? rd[rdn][rdn] : {}
      //   callback(_rd, rdn)
      // } else {
      let dk = viKey
      if (viKey === 'golfers') {
        // dk = 'allGolfers'
      }
      callback(rd, dk)
      // }
    })
  })
}

const updateClientGolfers = (pathViews, golfers) => {
  Object.keys(golfers).forEach(k => {
    const g = { ...golfers[k] }
    delete g._existingKey
    delete g._itemKey
    delete g.teams
    const _ref = createRefPath_client(pathViews, ['golfers', k])
    fs_update_doc(_ref, g)
  })
}

const updateEventGolfers = (pathViews, golfers) => {
  Object.keys(golfers).forEach(k => {
    const g = { ...golfers[k] }
    delete g._existingKey
    delete g._itemKey
    delete g.teams
    const _ref = createRefPath_event(pathViews, ['golfers', k])
    fs_update_doc(_ref, g)
  })
}