import React, { useContext, useRef } from 'react';
import { g_cns } from '../common/cns';
// import PageItemsMenu from '../components/layout/PageItemsMenu';
import { ParentContext } from '../cnr/contexts/ParentContext';
import Banners from '../components/sem/SemBanners';
import AppSidebarMenu from '../components/sidebars/AppSidebarMenu';
import { gEnums } from '../enums/globalEnums';
import Wrapper from '../wrappers/Wrapper';
import DesktopFooter from './DesktopFooter';

const Desktop = (props) => {

  const { headerContent, appContent } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state } = states
  const { pageSettings, allowPageView } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}

  // papsContext 
  const { appUser } = appUser_state ? appUser_state : {}

  // papsContext 
  const { landingView, otherView, view } = paps_state ? paps_state : {}

  // pageContext 
  const { desktop } = aps_global ? aps_global : {}
  const { contentWidth, showPageNavigation } = desktop ? desktop : {}
  let { appNavigationLocation } = desktop ? desktop : {}

  if (!appNavigationLocation) { appNavigationLocation = gEnums.appNavigationLocations.top }

  const isHome = view === landingView
  let allowPageNav = false
  let allowTopNav = true

  if (showPageNavigation && !isHome) { allowPageNav = true }

  if (allowPageView === gEnums.viewPermissionTypes.deny) {
    allowTopNav = false
  }

  const containerSaC = aps_styles ? aps_styles[gEnums.projectStyles.desktop] : {}
  const navSac = aps_styles ? aps_styles[gEnums.projectStyles.topMenu] : {}
  const appNavSaC = aps_styles ? aps_styles[gEnums.projectStyles.desktop] : {}
  const pageNavSaC = aps_styles ? aps_styles[gEnums.projectStyles.desktop] : {}

  // let headerCn = g_cns.dt_header

  containerSaC.className = g_cns.dt_container
  appNavSaC.className = g_cns.dt_app_nav
  pageNavSaC.className = g_cns.dt_page_nav

  // if (navigationAlignment && navigationAlignment === gEnums.captionAlignmentTypes.right) { headerCn += ' na-' + navigationAlignment }
  if (contentWidth) { containerSaC.className += ' cw-' + contentWidth }

  let appNavTop = false

  switch (appNavigationLocation) {
    case gEnums.appNavigationLocations.left:
      containerSaC.className += ' anl-left'
      break;
    case gEnums.appNavigationLocations.top:
      containerSaC.className += ' anl-top'
      appNavTop = true
      break;
    case gEnums.appNavigationLocations.hidden:
      containerSaC.className += ' anl-hidden'
      break;
    default:
      containerSaC.className += ' anl-top'
  }

  if (otherView || !appUser.loggedIn) {
    containerSaC.className += ' ov'
  }

  const centerRef = useRef();

  const appNav = () => <div className={g_cns.dt_app_nav}>
    <AppSidebarMenu appNavTop={appNavTop} sac={navSac} centerRef={centerRef} navOnly={true} />
  </div>

  const header = () => <React.Fragment>
    <Banners />
    {headerContent}
    {appNavTop && allowTopNav && !otherView && appNav()}
  </React.Fragment>

  const desktopFull = () => {
    return <Wrapper
      header={header()}
      content={<div className={'dt-contents'}>{appContent}</div>}
      footer={<DesktopFooter />}
      dt={true}
    />
  }

  if (allowPageNav) { containerSaC.className += ' spn' }

  return desktopFull(containerSaC)
}

export default Desktop