import { listTypes } from "../enums/globalEnums";

export const addLabelProps = (viewProps) => {
  const labelPropsList = [];
  if (viewProps) {
    Object.keys(viewProps).forEach(key => {
      const vp = viewProps[key]
      if (vp.data && vp.data.labelProps) {
        vp.data.labelProps.forEach(lp => {
          if (lp && lp.data && lp.data.listType && listTypes[lp.data.listType]) {
            if (!labelPropsList.includes(lp.data.listType)) {
              labelPropsList.push(vp)
            }
          }
        })
      }
    })
  }
  return labelPropsList
}