import { query, where, getDocs } from "firebase/firestore";
import { deleteField } from "firebase/firestore";
import { getFirstObjectKey } from '../../common/convert';
import { convertSnapshot } from '../../cnr/contexts/contextHelpers';
import { getClientProfileRef } from './updateProfile';

export const updateAppProfileTempDirect = async (email, pathViews, callback) => {

  var baseRef = getClientProfileRef(pathViews)
  const q = query(baseRef, where("tempEmail", "==", email));
  getDocs(q).then(res => {
    if (!res.empty) {
      const data = convertSnapshot(res)
      const itemKey = getFirstObjectKey(data)
      const updateData = { email: email }
      baseRef.doc(itemKey).update({ ...updateData, tempEmail: deleteField }).then(res => {
        callback({ success: true, data })
      }).catch(error => {
        callback({ success: false, error })
      })
    } else {
      callback({ success: false })
    }
  }).catch(error => {
    callback({ success: false, error })
  })
}