import _ from 'lodash';
import React from 'react';

export const createScorecard = (holes) => {

  const divs = [] 
  const distances = {}
  const firstDs = {}
  let dCount = 0
  let gotD = false

  Object.keys(holes).forEach(key => {
    const hole = holes[key]
    distances[key] = {}
    Object.keys(hole).forEach(hKey => {
      switch (hKey) {
        case 'holeNumber':
        case 'par':
        case 'handicap':
          break;
        default:
          distances[key][hKey] = hole[hKey]
          if (!gotD) {
            firstDs[hKey] = { key: hKey, distance: hole[hKey] }
            dCount++
          }
      }
    })
    gotD = true
  })
 
  const dds = _.sortBy(firstDs, 'distance').reverse()
  
  divs.push(<div>{'Hole'}</div>)
  divs.push(<div>{'Par'}</div>)
  divs.push(<div>{'Hcp'}</div>)
  dds.forEach(d => { divs.push(<div>{_.startCase(d.key)}</div>) })

  Object.keys(holes).forEach(key => {
    const hole = holes[key]
    const dist = distances[key]
    const { holeNumber, par, handicap } = hole
    divs.push(<div className='gsch'>{holeNumber}</div>)
    divs.push(<div>{par}</div>)
    divs.push(<div>{handicap}</div>)
    dds.forEach(d => {
      divs.push(<div>{dist[d.key]}</div>)
    }) 
  })

  let cn = 'golf-scorecard-container gtc-' + dCount
  return <div className={cn}>{divs}</div>
}