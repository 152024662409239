import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { List, Segment } from 'semantic-ui-react';
import { AppUserDataContext } from "../../cnr/contexts/AppUserDataContext";
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { appFormTypes } from '../../enums/appFormTypes';
import { gEnums } from '../../enums/globalEnums';
import DirectMessage from '../../messages/DirectMessage';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import ConfirmationQuestion from '../alerts/ConfirmationQuestion';
import NoData from '../alerts/NoData';
import { copyObj } from '../../common_web/copy';

const cutoffText = 100

const UserNotes = (props) => {

  const { fullPage } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states
  const { pageSettings, } = page_state ? page_state : {}
  const { aps_global, aps_styles } = pageSettings ? pageSettings : {}
  const { appNotes } = aps_global ? aps_global : {}
  const { allowSendNotesToEmail } = appNotes ? appNotes : {}

  // appUserDataContext
  const appUserDataContext = useContext(AppUserDataContext);
  const { appUserData_state } = appUserDataContext ? appUserDataContext : {}
  const { notes: notes_user } = appUserData_state ? appUserData_state : {}

  const styleAndClass = aps_styles && aps_styles[gEnums.projectStyles.actionButton] ? copyObj(aps_styles[gEnums.projectStyles.actionButton]) : {}
  styleAndClass.className += ' solo'

  // local state
  const [notes, setNotes] = useState()
  const [showAdd, setShowAdd] = useState()
  const [showSendToEmail, setShowSendToEmail] = useState()
  const [currentNoteKey, setCurrentNoteKey] = useState()

  const _currentNote = notes_user && currentNoteKey ? notes_user[currentNoteKey] : null

  useEffect(() => {
    if (notes_user) {
      const _notes = {}
      Object.keys(notes_user).forEach(key => {
        _notes[key] = notes_user[key]
      })
      setNotes(_notes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes_user]);

  const handleShowNote = (noteKey) => setCurrentNoteKey(noteKey)
  const handleShowAdd = () => setShowAdd(!showAdd)
  const handleShowSendToEmail = () => setShowSendToEmail(!showSendToEmail)
  const cancelSendToEmail = () => setShowSendToEmail()
  const sendToEmail = () => setShowSendToEmail()

  const handleCancel = () => props.handleCancel ? props.handleCancel() : handleShowNote()

  const handleCloseNote = () => {
    setCurrentNoteKey()
    setShowAdd()
  }

  const noteItem = (ni, index) => {
    const { note, timestamp, _itemKey: key, view, pageDataCaption } = ni ? ni : {}
    const ts = timestamp ? formatItem(formatTypes.dateAndTime, timestamp.toDate()) : null
    const _note = note && note.length > cutoffText ? note.substring(0, cutoffText) + '...' : note
    return <List.Item key={uniqueKey('un', index)} onClick={() => { handleShowNote(key) }}>
      <List.Icon name='sticky note outline' size='large' verticalAlign='middle' />
      <List.Content>
        <List.Header>{_.startCase(view)}</List.Header>
        <List.Header>{pageDataCaption}</List.Header>
        <List.Description style={{ padding: '.5em 0' }}>{_note}</List.Description>
        <div>{ts}</div>
      </List.Content>
    </List.Item>
  }

  const noteList = () => {
    const nis = _.orderBy(notes, 'timestamp.seconds', 'desc')
    const noteItems = nis.map((ni, index) => (noteItem(ni, index)))
    return <Segment basic> <List divided relaxed>
      {noteItems}
    </List></Segment>
  }

  const contentWrapper = (content) => <div className={'item-container notes'}>
    <div className='item-header'></div>
    <div className='item-content'>{content}</div>
  </div>

  const content = () => {
    if (notes && Object.keys(notes).length > 0) {
      return contentWrapper(noteList())
    } else {
      return contentWrapper(<NoData altCaption={'Notes'} />)
    }
  }

  const noteContent = () => <DirectMessage
    appFormType={appFormTypes.note}
    handleCancel={handleCloseNote}
    currentDataKey={currentNoteKey}
    currentFromData={_currentNote}
  />

  const wrapper = () => <Wrapper
    content={content()}
    footer={<UiSaveButtons
      save={{ oc: handleShowAdd, caption: 'Add', icon: 'add' }}
      preview={{ oc: handleShowSendToEmail, caption: 'Send to Email', disabled: !allowSendNotesToEmail }}
    ></UiSaveButtons>}
    wrapperType={wrapperTypes.padded}
  />

  const noteEditWizard = () => {
    const { timestamp } = _currentNote ? _currentNote : {}
    const ts = timestamp ? formatItem(formatTypes.dateAndTime, timestamp.toDate()) : ''
    return <FullPageWrapper
      content={noteContent()}
      handleCancel={handleCloseNote}
      topperCaption={ts}
      isBack={true}
    />
  }

  const sentToEmailConfirmation = () => <ConfirmationQuestion
    header={'Please Confirm?'}
    question={'Send your notes to email?'}
    handleNo={cancelSendToEmail}
    handleYes={sendToEmail}
  />

  const notesContent = () => {
    if (showAdd) {
      return noteContent()
    } else if (_currentNote) {
      return noteEditWizard()
    } else {
      return wrapper()
    }
  }

  const fullPageWizard = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel ? handleCancel : handleShowNote}
    topperCaption={'My Notes'}
  />

  const fullPageContent = () => {
    if (showAdd) {
      return notesContent()
    } else if (_currentNote) {
      return noteEditWizard()
    } else {
      return fullPageWizard()
    }
  }

  if (showSendToEmail) {
    return sentToEmailConfirmation()
  } else {
    return fullPage ? fullPageContent() : notesContent()
  }

}

export default UserNotes