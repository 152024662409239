import React, { useEffect, useRef, useState } from 'react';
import { Menu, Ref, Sidebar } from 'semantic-ui-react';
import { g_cns } from '../../../global/common/cns';
import { uniqueKey } from '../../../global/common/keys';
import { appIconTypes } from '../../../global/enums/appIconTypes';
import GenericIcon from '../../genericControls/GenericIcon';

let _iconSize = 'large'

/**
 * 
 * @param {object} props: (sidebarContent, content, showPopup, closePopup)
 * @param menuItems items in the sidebar
 * @param cn className
 * @param content main content
 * @param showPopupIcon boolean
 * @param closePopup
 * @returns 
 */
export const Popuper = (props) => {

  const { menuItems, cn, content, showPopupIcon, closePopup } = props

  const [sidebarOpen, setSidebarOpen] = useState()

  useEffect(() => {
    if (closePopup) {
      setSidebarOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [closePopup]);

  const handlePopupOpen = () => setSidebarOpen(!sidebarOpen)

  const contentRef = useRef()

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  /** This is the icon that opens the sidebar menu */
  const settingsPopup = () => <span className={g_cns.item_popup}>
    {gi(uniqueKey('pop', 'sp'), appIconTypes.ellipsisVertical, handlePopupOpen, null, { circular: true, size: _iconSize, inverted: true })}
  </span>

  const PopupSidebar = ({ animation, direction, visible }) => {
    return <Sidebar
      animation={animation}
      as={Menu}
      className={cn ? cn : null}
      direction={direction}
      icon='labeled'
      inverted
      key={uniqueKey('pc', 'pu')}
      onHide={() => setSidebarOpen()}
      target={contentRef}
      vertical
      visible={visible}
      width='thin'
    >
      {menuItems}
    </Sidebar>
  }

  const slider = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <PopupSidebar
      animation={'overlay'}
      direction={'bottom'}
      visible={sidebarOpen}
    />
    <Ref innerRef={contentRef}>
      <Sidebar.Pusher className={'pusher-container'} dimmed={sidebarOpen}>
        {content}
        {showPopupIcon && settingsPopup()}
      </Sidebar.Pusher>
    </Ref>
  </Sidebar.Pushable>

  return slider()
}