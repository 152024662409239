import { gEnums } from '../../enums/globalEnums';
import { _find } from '../../common/filtering';

export const getCardColor = (getGviDeps, viewItem, itemData, itemColor, propColors, colorProps, prcs, getParents) => {

  let { usePropColor, showParentColor, colorProp } = itemColor ? itemColor : {}

  let color = usePropColor && itemData.color ? itemData.color.toLowerCase() : null
  if (color) { colorProps.color = color }

  if (showParentColor) {
    color = parentProp(getGviDeps, viewItem, getParents, 'color')
    colorProps.color = color ? color.toLowerCase() : color
  }

  if (colorProp && prcs) {
    Object.keys(prcs).forEach(prc => {
      if (itemData[prc]) {
        const d = itemData[prc]
        const x = _find(prcs[prc], 'value', d[0]) // _.find(prcs[prc], { 'value': d[0] })
        if (x) {
          color = x.setting.toLowerCase()
          colorProps.color = color
        }
      }
    })
  }

  if (colorProp && itemData[colorProp] && propColors) {
    const propColorValue = itemData[colorProp]
    if (propColorValue && propColorValue.length === 1) {
      if (propColors[propColorValue[0]]) {
        color = propColors[propColorValue[0]]
        colorProps.color = propColors[propColorValue[0]]
        color = color.toLowerCase()
        colorProps.color = color.toLowerCase()
      } else {
        color = 'grey'
        colorProps.color = 'grey'
      }
    } else {
      color = 'grey'
      colorProps.color = 'grey'
    }
  }

  if (!color) { color = 'blue' }

  return color

}

const parentProp = (getGviDeps, viewItem, getParents, prop) => {
  const { key } = viewItem
  const x = getGviDeps(key)
  const { dependencies: deps } = x
  if (getParents) {
    const depItems = getParents(deps)
    let val = null
    if (depItems && Object.keys(depItems).length > 0) {
      Object.keys(depItems).forEach(key => {
        const di = depItems[key]
        val = di[prop] ? di[prop] : val
      })
    }
    return val
  }
}

export const getCardStyle = (usePropColor, color, colorDisplayType) => {

  const csp = {
    cardCn: '',
    labelCn: '',
    cardStyle: null,
    showColorLabel: false,
  }

  if (usePropColor && color) {
    switch (colorDisplayType) {
      case gEnums.colorDisplayTypes.bottomCard:
        csp.cardCn += ' color-card-bottom'
        csp.cardStyle = { borderBottom: '8px solid ' + color, borderRadius: 6 }
        break;
      case gEnums.colorDisplayTypes.lineLabel:
        csp.labelCn = 'color-line-label-full'
        csp.showColorLabel = true
        break;
      case gEnums.colorDisplayTypes.sideCard:
        csp.cardCn += ' color-card-side'
        csp.cardStyle = { borderLeft: '12px solid ' + color, borderRadius: 12 }
        break;
      case gEnums.colorDisplayTypes.tagLabel:
        csp.labelCn = 'color-line-label'
        csp.showColorLabel = true
        break;
      case gEnums.colorDisplayTypes.topCard:
        csp.cardCn += ' color-card-top'
        csp.cardStyle = { borderTop: '8px solid ' + color, borderRadius: 6 }
        break;
      default:
        csp.labelCn = 'color-line-label'
        csp.showColorLabel = true
        break;
    }
  }

  if (csp.cardCn) { csp.cardCn = csp.cardCn.trim() }
  return csp
}  