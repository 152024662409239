import React from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { registerServiceWorker } from './serviceWorker'

registerServiceWorker()

// eslint-disable-next-line 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// https://github.com/Semantic-Org/Semantic-UI-CSS/issues/75

// serviceWorkerRegistration.register() 