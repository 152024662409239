import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';

const gridWidths = {
  one: 2,
  two: 7,
  three: 7
}

const UserTicketStatus = (props) => {

  const { userTicketStatus, handleCancel } = props

  const userStatusRow = (subTicket, userName) => {
    const { assignedTo, entered, subTicketIndex } = subTicket
    return entered ? <Grid.Row>
      <Grid.Column width={gridWidths.one}>{subTicketIndex}</Grid.Column>
      <Grid.Column width={gridWidths.two}>{assignedTo ? assignedTo : userName}</Grid.Column>
      <Grid.Column width={gridWidths.three}></Grid.Column>
    </Grid.Row>
      :
      <Grid.Row>
        <Grid.Column width={gridWidths.one}>{subTicketIndex}</Grid.Column>
        <Grid.Column width={gridWidths.two}></Grid.Column>
        <Grid.Column width={gridWidths.three} >{assignedTo ? assignedTo : userName}</Grid.Column>
      </Grid.Row>
  }

  const userStatusContent = () => {
    const { appUser, appUserTicket } = userTicketStatus ? userTicketStatus : {}
    const { firstName, lastName } = appUser ? appUser : {}
    const { subTickets } = appUserTicket ? appUserTicket : {}
    const userName = firstName + ' ' + lastName
    const grs = []
    if (subTickets) {
      subTickets.forEach(st => {
        grs.push(userStatusRow(st, userName))
      })
    }
    return <Segment><Grid columns={3} textAlign={'center'} className={'ticket-status-grid'}>
      <Grid.Row>
        <Grid.Column width={gridWidths.one}>{'#'}</Grid.Column>
        <Grid.Column width={gridWidths.two}>{'Entered'}</Grid.Column>
        <Grid.Column width={gridWidths.three}>{'Unused'}</Grid.Column>
      </Grid.Row>
      {grs}
    </Grid></Segment>
  }

  const userStatusWrapper = () => <Wrapper
    content={userStatusContent()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const stepWizardUserStatus = () => <FullPageWrapper
    content={userStatusWrapper()}
    handleCancel={handleCancel}
    topperCaption={'User Ticket Status'}
    isBack={true}
  />

  return stepWizardUserStatus()

}

export default UserTicketStatus