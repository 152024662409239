import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Tab } from 'semantic-ui-react'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { getFirstObject } from '../../common/convert'
import { getViewItemHeaders } from '../../viewSettings/helpers/settingsHelpers'
import DataListTable from './DataListTable'
import { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext'
import { _filter } from '../../common/filtering'

const menuOnBottom = false
const _menuSize = 'tiny'

/**
 * 
 * @param {object} props (previewInfo, handleActiveTab)
 * @previewInfo (projectData)
 * @projectData (dataCollections)
 * @returns a `Tab` with a `Tab` for each `dataCollection` in `dataCollections`
 */
const GoogleSheetsTabs = (props) => {

  const { previewInfo, handleActiveTab, dcUpdates } = props
  const { projectData, newCounts } = previewInfo ? previewInfo : {}
  const { dataCollections } = projectData ? projectData : {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ? googleSheetsContext : {}
  const { showNewOnly } = googleSheets_state ? googleSheets_state : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states ? states : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_viewItems } = pageSettings ? pageSettings : {}

  // local state
  const [dataTabs, setDataTabs] = useState()
  const [activeTab, setActiveTab] = useState()

  useEffect(() => {
    if (dataCollections) {
      const _dataTabs = []
      Object.keys(dataCollections).forEach(dcKey => {
        _dataTabs.push(dcKey)
      })
      setDataTabs(_dataTabs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCollections]);

  const handleTabChange = (e, { activeIndex }) => {
    const _activeTab = dataTabs[activeIndex]
    setActiveTab(activeIndex)
    handleActiveTab({ index: activeIndex, name: _activeTab })
  }

  const pane = (tabName, tabCount, tabContent, newCount) => {
    const mi = newCount ? tabName + ' (' + tabCount + '/' + newCount + ')' : tabName + ' (' + tabCount + ')'
    const _loading = dcUpdates && dcUpdates[tabName] && dcUpdates[tabName].updating
    return {
      menuItem: mi,
      render: () => <Tab.Pane attached={false} loading={_loading}>{tabContent}</Tab.Pane>,
    }
  }

  const dataListTable = (fd, viewItem, vih) => <DataListTable
    fixedData={fd}
    viewItem={viewItem}
  />

  const panes = () => {
    const p = []
    if (dataCollections) {
      const _dataTabs = []
      Object.keys(dataCollections).forEach(dcKey => {
        switch (dcKey) {
          case 'clients':
          case 'events':
          case 'fieldNames':
            // nothing
            break;
          default:
            const dataCollection = dataCollections[dcKey]
            const dcl = Object.keys(dataCollection).length
            const newCount = newCounts ? newCounts[dcKey] : 0
            const viewItem = aps_viewItems[dcKey]

            const _news = _filter(dataCollection, '_new', true, null, '_itemKey')

            const _dataCollection = showNewOnly ? _news : dataCollection

            if (viewItem) {
              const vih = getViewItemHeaders(viewItem)
              p.push(pane(_.startCase(dcKey), dcl, dataListTable(_dataCollection, viewItem, vih), newCount))
              _dataTabs.push(dcKey)
            } else {
              try {
                const { item: dch } = _dataCollection ? Object.keys(getFirstObject(dataCollection)) : {}
                p.push(pane(_.startCase(dcKey), dcl, dataListTable(_dataCollection, {}, dch), newCount))
                _dataTabs.push(dcKey)
              } catch (error) {

              }
            }
        }
      })
    }
    return p
  }

  const tab = () => <Tab
    activeIndex={activeTab}
    menu={{ color: 'blue', attached: menuOnBottom ? 'bottom' : null, size: _menuSize }}
    panes={panes()}
    onTabChange={handleTabChange}
    className={menuOnBottom ? 'gst bot' : 'gst'}
  />

  return tab()

}

export default GoogleSheetsTabs