import React from 'react';
import { Label, Menu } from 'semantic-ui-react';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import MenuItemCenter from './MenuItemCenter';
import MenuItemLR from './MenuItemLR';
import { menuWidths } from './menuSlider';

export const TriMenu = (props) => {

  const { menuProps } = props
  // value, handleMenuClick, style, count, prefix, isDate, opts
  const { value, handleMenuClick, style, count, prefix, isDate, opts } = menuProps

  let title = value
  let label = null

  if (count) { label = <Label key={uniqueKey('trml')} color={'blue'}>{count}</Label> }
  const type = 'pick'

  const menuItems = () => {
    const mi = isDate ? formatItem(opts && opts.formatType ? opts.formatType : formatTypes.shortDate, value) : <React.Fragment>{prefix}{title}{label}</React.Fragment>
    const mis = []
    // direction, handleMenuClick, width, type, disabled
    mis.push(<MenuItemLR key={uniqueKey('trm', 'left')} menuProps={{ direction: 'back', handleMenuClick, width: menuWidths['3'].lr, type }} />)
    mis.push(<MenuItemCenter key={uniqueKey('trm', 'center')} menuProps={{ index: 0, value: mi, handleMenuClick, width: menuWidths['3'].mid, type }} />)
    mis.push(<MenuItemLR key={uniqueKey('trm', 'right')} menuProps={{ direction: 'forward', handleMenuClick, width: menuWidths['3'].lr, type }} />)
    return mis
  }

  return <Menu key={uniqueKey('trm')} widths={3} style={style} className='menu-slider tri'>
    {menuItems()}
  </Menu>
}

export const triMenu = (value, handleMenuClick, style, count, prefix, isDate, opts) => <TriMenu menuProps={{ value, handleMenuClick, style, count, prefix, isDate, opts }} />

