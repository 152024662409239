import React, { useContext, useEffect, useReducer } from 'react';
import { Header, List, Menu, Modal, Segment, Sidebar } from 'semantic-ui-react';
import { createScheduleTypes } from '../../../projectSpecific/sports/cnr/reducers/CreateSportsReducer';
import { CreateSportsContext } from '../../../projectSpecific/sports/create/CreateSports';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { calendarHandlers, calendarInitialState, calendarReducer } from '../../cnr/reducers/CalendarReducer';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import Checker from '../forms/elements/Checker';
import { triMenu } from '../menus/TriMenu';
import { CalendarMonths } from './CalendarMonths';
import { CalMonth } from './CalMonth';
import { CalSchedule } from './CalSchedule';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Decemeber']

const today = new Date()

export const ScheduleCalendarModal = (props) => {
  const { calendar_state } = props
  return <Modal key={uniqueKey('scm')} size={'small'} open={calendar_state.calOpen} style={{ marginTop: 30, top: 60 }}>
    <ScheduleCalendar {...props} />
  </Modal>
}

export const ScheduleCalendar = (props) => {

  const { createScheduleType, handleDateSelect, handleSeasonDates, currentDates, fip } = props ? props : {}

  const trueDate = fip && fip.value ? new Date(fip.value) : null

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { page_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { dataOptions } = aps_global ? aps_global : {}
  const { dateFormat } = dataOptions ? dataOptions : {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state } = createSportsContext ? createSportsContext : {}
  const { season_info, playoff_info, monthStartDates } = createSports_state ? createSports_state : {}
  const { scheduleDatesInfo } = season_info ? season_info : {}
  const { playoffDatesInfo } = playoff_info ? playoff_info : {}

  const { currentNeeded: currentNeeded_season, currentCount: currentCount_season } = scheduleDatesInfo ? scheduleDatesInfo : {}
  const { currentNeeded_playoff, currentCount: currentCount_playoff, currentLevelKey } = playoffDatesInfo ? playoffDatesInfo : {}

  const _currentDates = currentDates
  const _currentCount = createScheduleType === createScheduleTypes.season ? currentCount_season : currentCount_playoff
  const _currentNeeded = createScheduleType === createScheduleTypes.season ? currentNeeded_season : currentNeeded_playoff

  const initDate = trueDate ? new Date(trueDate.getFullYear(), trueDate.getMonth(), 1) : new Date(today.getFullYear(), today.getMonth(), 1)

  const init_state = { initDate, currentDate: initDate, dateFormat: dateFormat ? dateFormat : formatTypes.shortestDate, singleSelect: handleDateSelect ? true : false }
  const [calendar_state, calendarDispatch] = useReducer(calendarReducer, calendarInitialState(init_state));
  const calendar_handlers = calendarHandlers(calendarDispatch, calendar_state)
  const { singleSelect, currentDate, selectedDate, selectedDates, monthsVisible, calListOn, isModal, selectOnly } = calendar_state ? calendar_state : {}

  useEffect(() => {
    handleDateSelect && singleSelect && selectedDate && handleDateSelect(selectedDate)
    // eslint-disable-next-line 
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDates && selectedDates.length > 0 && currentLevelKey) {
      handleSeasonDates && handleSeasonDates(selectedDates, currentLevelKey)
    }
    // eslint-disable-next-line 
  }, [selectedDates]);

  useEffect(() => {
    _currentDates && calendar_handlers.handleSelectedDates(_currentDates, currentLevelKey)
    // eslint-disable-next-line 
  }, [_currentDates]);

  const handleTodayClick = (date) => calendar_handlers.handleDateSelect(date)
  const handleYearClick = (key) => calendar_handlers.handleYearSelect(key)
  const handleItemClick = (monthIndex) => calendar_handlers.handleItemClick(monthIndex)
  const handleMenuClick = (key, date) => calendar_handlers.handleMenuClick(key, date)

  const calMonth = () => <CalMonth currentDate={currentDate} selectedDate={selectedDate} calendar_state={calendar_state} calendar_handlers={calendar_handlers} />
  const calMonths = () => <CalSchedule monthStartDates={monthStartDates} calendar_state={calendar_state} calendar_handlers={calendar_handlers} />

  const cal = () => {
    switch (createScheduleType) {
      case createScheduleTypes.season:
      case createScheduleTypes.playoff:
        return calMonths()
      default:
        return calMonth()
    }
  }

  const selectAllToggle = () => <Checker handleClick={calendar_handlers.handleShowCalList} label={'Show Selected Dates'} item={{ key: 'showCalList' }} />

  const calList = () => {
    const items = []
    if (selectedDates) {
      selectedDates.forEach((si, index) => {
        items.push(<List.Item color={'blue'}>{(index + 1)}{' '}{si}</List.Item>)
      })
    }
    return <Segment><List divided relaxed>{items}</List></Segment>
  }

  const content = () => <Segment.Group className={'seg-cal-full'}>
    {isModal && <Segment attached='top' >
      <Header as='h3'>Today</Header>
    </Segment>}
    <Sidebar.Pushable key={uniqueKey('cal', 'ss')} as={Segment}>
      <CalendarMonths calProps={{ months: monthNames, year: currentDate ? currentDate.getFullYear() : null, isVisible: monthsVisible, handleItemClick, handleYearClick }} />
      <Sidebar.Pusher data-et={'pusher-seg'} >
        <Segment className={'seg-cal-select'} style={{ padding: 0, border: 0 }}>
          {triMenu(formatItem(formatTypes.monthYear, currentDate), handleMenuClick)}
          {!selectOnly && !handleDateSelect && selectAllToggle()}
          {_currentCount && _currentNeeded && <div style={{ textAlign: 'center', paddingBottom: '1em' }}>{'Count: '}{_currentCount}{' of '}{_currentNeeded}</div>}
          {!calListOn && cal()}
          {calListOn && calList()}
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
    {isModal && <Segment attached='bottom' style={{ padding: 0 }}>
      <Menu widths={2} syle={{ border: 0, borderRadius: 0 }}>
        <Menu.Item name='Today' onClick={() => handleTodayClick(formatItem(formatTypes.date, today))} />
        <Menu.Item name='Cancel' onClick={() => calendar_handlers.handleClose()} />
      </Menu>
    </Segment>}
  </Segment.Group>

  switch (createScheduleType) {
    case createScheduleTypes.season:
    case createScheduleTypes.playoff:
      return calMonths()
    default:
      return content()
  }

}

export const CalendarDirect = (props) => {

  const { handleSelectedDate } = props

  const init_state = {}
  const [calendar_state, calendarDispatch] = useReducer(calendarReducer, calendarInitialState(init_state));
  const calendar_handlers = calendarHandlers(calendarDispatch, calendar_state)
  const { selectedDate } = calendar_state ? calendar_state : {}

  useEffect(() => {
    handleSelectedDate(selectedDate)
    // eslint-disable-next-line 
  }, [selectedDate]);

  return <ScheduleCalendar calendar_state={calendar_state} calendar_handlers={calendar_handlers} />
}