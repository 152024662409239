import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps";
import { createRefPath } from "../../appData/appRefPaths";
import { doc_update } from "../../appData/fsData";

// IMPORTANT: Delete document
/** Deletes a document or documents from a collection */
export const ammendData = (props) => {

  const {
    baseRef,
    dispatch,
    ufProps,
  } = props

  const { refPath } = baseRef
  let { vit, selectedItems, ammendType, dataRestrictions } = ufProps ? ufProps : {}

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.ammendData)) {
    // Object.keys(vld).forEach(itemKey => {
    //   const _d = { [ammendType]: selectedItems && selectedItems[itemKey] ? true : false }
    // })
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  if (selectedItems) {
    ammendPromise(refPath, vit, selectedItems, ammendType).then(() => {
      dispatch({ type: dispatchProps.success, dispatch })
    }).catch(err => {
      dispatch({ type: dispatchProps.error, dispatch })
    });
  }
}

const ammendPromise = async (refPath, vit, data, ammendType) => {
  const promises = []
  Object.keys(data).forEach(itemKey => {
    const _d = { [ammendType]: true }
    const _updateRef = createRefPath([vit, itemKey], refPath)
    const r = doc_update(_updateRef, _d)
    promises.push(r)
  })
  return Promise.all(promises)
}