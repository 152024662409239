import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { signInResponseData } from "../../cnr/reducers/reducerHelpers/signInProps";
import { signInResponseTypes } from "../../cnr/reducers/SignInReducer";

const _testing = false

/**
 * 
 * @param {string} phoneNumber 
 * @param {object} recaptcha 
 * @param {function} callback 
 */
export const sendPhoneCode = (phoneNumber, recaptcha, callback) => {
  const _phoneNumber = _testing ? '+14125552081' : phoneNumber
  const auth = getAuth()
  signInWithPhoneNumber(auth, _phoneNumber, recaptcha)
    .then(function (confirmationResult) {
      callback && callback(signInResponseData(null, null, null, signInResponseTypes.phoneCodeSent, null, confirmationResult))
      // callback && callback({ type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.phoneCodeSent, phoneNumber, confirmationResult })
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
    }).catch(function (error) {
      console.error('sendPhoneCode', error)
      // Error; SMS not sent
      callback && callback(signInResponseData(null, null, null, signInResponseTypes.phoneCodeNotSent, phoneNumber, null, error))
      // ...
    });
}