import { dispatchProps } from '../../cnr/reducers/reducerHelpers/dispatchProps';
import { _settingsFs } from '../../viewSettings/actions/getSettings';
import { createRefPath_client, createRefPath } from '../appData/appRefPaths';
import { fs_add_doc, fs_set_doc } from '../appData/fsData';

export const createSimpleEvent = (dispatch, paps_state, createInfo) => {

  const { initProps } = createInfo ? createInfo : {}
  const { dataCollections } = initProps ? initProps : {}
  const { event } = dataCollections ? dataCollections : {}

  if (event) {
    const { pathViews } = paps_state ? paps_state : {}
    const _eventPath = createRefPath_client(pathViews, ['events'])
    fs_add_doc(_eventPath, event).then(res => {
      const { global, viewItems, views } = createInfo
      const settingsData = { global, viewItems, views }
      const _settingsPath = createRefPath([_settingsFs.root, res.id])
      fs_set_doc(_settingsPath, settingsData, false, dispatch)
    })
  } else {
    dispatch({ type: dispatchProps.error });
  }
}