import _ from 'lodash';
import { modifyParentKeyData } from '../../../../../global/cnr/contexts/contextHelpers';
import { createItemKeys } from '../../../../../global/common/convert';
import { createKeyedObjectFromKey } from '../../../../../global/common/creating';
import { formatItem, formatTypes } from '../../../../../global/common/dateFormatting';
import { addIdsToDataItem } from '../../../../../global/firestoreData/appData/ammendData';
import { calcAllVballMatches } from '../../../helpers/match';
import { getParentKeyData_sports } from './parentKeySportsData';

const _showRefPath = true

/**
 * Gets the data for teams, matches, matchesNonSection, rankings, history, scheduleDates and playoffMatches
 * @param {object} pathViews 
 * @param {object} dataParents 
 * @param {object} additionalDataParents 
 * @param {string} selectedSeason 
 * @param {function} handleSetDataResults 
 * @param {function} handleSetRealtimeResults 
 */
export const getData_parentKeyCollections = async (pathViews, dataItems, dataParents, additionalDataParents, sportsKey, selectedSeason, handleSetDataResults) => {

  const datas = {}
  const parentDataKeys = {}

  let playoffTournaments;
  let matchDateGroups;
  let levelMatches;
  let levelDateGroups;
  let levelLatestDates;

  const callback_datas = () => {

    let valid = true

    Object.keys(dataItems).forEach(dataItemKey => {
      const dataItem = dataItems[dataItemKey]
      const { collectionItems, collectionDoc } = dataItem ? dataItem : {}
      if (collectionItems) {
        collectionItems.forEach(collectionItem => {
          if (collectionDoc) {
            if (!datas[collectionDoc]) {
              valid = false
            }
          } else if (!datas[collectionItem]) {
            valid = false
          }
        })
      }
    })

    if (valid) {

      const { matches, rosters, teams, staff, details } = datas

      if (matches) {
        const { _matches, _dateGroups, _levelMatches, _levelDateGroups, missingMatches } = getAllMatches(datas)
        datas.matches = _matches
        matchDateGroups = _dateGroups
        levelMatches = _levelMatches
        levelDateGroups = _levelDateGroups
        levelLatestDates = getLatestMatchDates(_levelDateGroups)
      }

      if (teams && rosters) { datas['rosters'] = getAllTeamItems(datas, 'rosters', 'athletes') }
      if (teams && staff) { datas['staffs'] = getAllTeamItems(datas, 'staffs', 'staffs') }
      if (details) { datas['details'] = details }

      handleSetDataResults(datas, playoffTournaments, selectedSeason, matchDateGroups, parentDataKeys, sportsKey, levelMatches, levelDateGroups, levelLatestDates)
    }
  }

  // data, opts
  const callback_data = (data, opts) => {

    const { collectionItems, collectionField, latestSeason, dataItem } = opts ? opts : {}
    const { docField, docFields } = dataItem ? dataItem : {}

    collectionItems.forEach(collectionName => {

      let dataResultName = collectionName
      let dataCollectionName = collectionName
      let ignoreData = false;

      const { _itemKey } = data ? data : {}
      let d = data ? data[dataResultName] : {}

      switch (collectionName) {
        case 'matches':
        case 'teams':
          addIdsToDataItem(d)
          break;
        default:
        // nothing
      }

      switch (collectionName) {
        case 'googleLinks':
          if (docFields) {
            ignoreData = true
            docFields.forEach(df => {
              d = data ? data[df] : {}
              datas[collectionName + '_' + df] = d ? d : {}
              parentDataKeys[collectionName + '_' + df] = _itemKey ? _itemKey : null
            })
          } else if (docField) {
            d = data ? data[docField] : {}
            dataCollectionName = docField
          }
          break;

        case 'seasonals':
          d = data ? data['details'] : {}
          dataCollectionName = 'details'
          break;

        case 'staff':
        case 'rosters':
          d = data
          break;

        case 'matches':
        case 'matchesNonSection':
          d && calcAllVballMatches(d)
          break;

        case 'playoffMatches':
          d && calcAllVballMatches(d)
          d && modifyParentKeyData(d)
          playoffTournaments = createPlayoffTournaments(d)
          break;
        default:
        // nothing
      }


      if (_showRefPath) {
        // console.log('dataResultName', dataResultName, d)
        // console.log('parentDataKeys', parentDataKeys)
      }

      if (!ignoreData) {
        datas[dataCollectionName] = d ? d : {}
        parentDataKeys[dataCollectionName] = _itemKey ? _itemKey : null
      }

      callback_datas()
    })
  }

  // loop the dataItems, the items that will get retrieved from the database
  Object.keys(dataItems).forEach(key => {
    const dataItem = dataItems[key]
    getParentKeyData_sports(key, pathViews, dataParents, callback_data, dataItem, additionalDataParents, sportsKey, selectedSeason)
  })

}

const getAllMatches = (datas) => {
  const { matches, matchesNonSection, playoffMatches } = datas
  if (matches) {
    let _matches = matchesNonSection ? { ...matches, ...matchesNonSection } : { ...matches }
    if (playoffMatches) { _matches = { ...matches, ...playoffMatches } }
    const { dateGroups: _dateGroups, missingMatches } = getMatchDateGroups(_matches)
    const { _levelMatches, _levelDateGroups } = getLevelMatches(_matches)
    return { _matches, _dateGroups, _levelMatches, _levelDateGroups, missingMatches }
  }
}

const getLevelMatches = (matches) => {

  const groupedMatches = _.groupBy(matches, 'levels')

  const _levelMatches = {}
  const _levelDateGroups = {}

  if (groupedMatches) {

    Object.keys(groupedMatches).forEach(levelKey => {
      const groupMatches = groupedMatches[levelKey]
      const _groupMatches = createKeyedObjectFromKey(groupMatches, null, '_itemKey')
      _levelMatches[levelKey] = _groupMatches
      const { dateGroups: _dateGroups, missingMatches } = getMatchDateGroups(_groupMatches)
      _levelDateGroups[levelKey] = _dateGroups
    })
  }
  return { _levelMatches, _levelDateGroups }
}

const getAllTeamItems = (datas, itemType, itemPropType) => {
  const _items = {}
  const { teams } = datas
  const _data = datas[itemType]
  if (teams && _data) {
    Object.keys(teams).forEach(tk => {
      const xxx = _.filter(_data, function (item) {
        return (item && item.parentKeys && item.parentKeys.teams === tk);
      })
      if (xxx && xxx.length === 1) {
        _items[tk] = xxx[0][itemPropType]
      }
    })
  }
  return _items
}

const getAllTeamRosters = (datas) => {
  const _teamRosters = {}
  const { teams, rosters } = datas
  if (teams && rosters) {
    Object.keys(teams).forEach(tk => {
      const xxx = _.filter(rosters, function (item) {
        return (item.parentKeys && item.parentKeys.teams === tk);
      })
      if (xxx && xxx.length === 1) {
        _teamRosters[tk] = xxx[0].athletes
      }
    })
  }
  return _teamRosters
}

const getAllTeamStaff = (datas) => {
  const _teamStaff = {}
  const { teams, staff } = datas
  if (teams && staff) {
    Object.keys(teams).forEach(tk => {
      const xxx = _.filter(staff, function (item) {
        return (item.parentKeys && item.parentKeys.teams === tk);
      })
      if (xxx && xxx.length === 1) {
        _teamStaff[tk] = xxx[0].staff
      }
    })
  }
  return _teamStaff
}

const getMatchDateGroups = (sortedMatches) => {

  createItemKeys(sortedMatches)

  const today = new Date()
  const todayF = formatItem(formatTypes.fullDate, today)

  let matchDates = _.groupBy(sortedMatches, 'startDate')

  const dateGroups = []
  let missingMatches = []

  // let groupKeys = Object.keys(x)
  let groupKeys = Object.keys(matchDates)
  groupKeys = _.sortBy(groupKeys, function (value) { return new Date(value); })

  groupKeys.forEach(gk => {
    dateGroups.push({
      matchDate: gk,
      matches: createKeyedObjectFromKey(matchDates[gk], null, '_itemKey')
    })
  })

  if (groupKeys[todayF]) {
    dateGroups.unshift({
      matchDate: todayF,
      matches: createKeyedObjectFromKey(matchDates[todayF], null, '_itemKey')
    })
  }

  const mz = getMissing(sortedMatches)

  if (mz && Object.keys(mz).length > 0) {
    missingMatches = mz
  }

  return { dateGroups, missingMatches }

}

const createPlayoffTournaments = (playoffMatches) => {
  const ts = []
  const levelMatches = _.groupBy(playoffMatches, 'levels')
  if (levelMatches) {
    Object.keys(levelMatches).forEach(levelKey => {
      ts[levelKey] = { rounds: { playoffs: { itemKey: 'playoffs', pools: {} } } }
      const lm = levelMatches[levelKey]
      const poolMatches = _.groupBy(lm, 'poolKey')
      Object.keys(poolMatches).forEach(poolKey => {
        ts[levelKey].rounds.playoffs.pools[poolKey] = {
          poolKey: poolKey,
          matches: mz(poolMatches[poolKey])
        }
      })
    })
  }
  return ts
}

const mz = (matches) => {
  const ms = {}
  matches.forEach(m => {
    ms['match' + m.matchNumber] = m
  })
  return ms
}

const getMissing = (matches) => {
  if (matches) {
    const missing = _.filter(Object.values(matches), function (m) {
      const today = new Date()
      const yesterday = today.setDate(today.getDate() - 1)
      return (
        new Date(m.startDate) < yesterday
        && !m.results
        // && !m.cancelled
        // && !m.postponed
      );
    })
    if (missing) {
      const m_s = createKeyedObjectFromKey(missing)
      return m_s
    }
  }
}

const getLatestMatchDates = (levelDateGroups) => {
  const latestLevelDates = {}
  Object.keys(levelDateGroups).forEach(key => {
    latestLevelDates[key] = getCurrentDateKey(levelDateGroups[key])
  })
  return latestLevelDates
}

const getCurrentDateKey = (ldg) => {

  const ldgKeys = []

  if (ldg) {
    Object.keys(ldg).forEach(ldgKey => {
      ldgKeys.push(ldg[ldgKey].matchDate)
    })
  }

  let dd = false;
  let _dateKey;

  ldgKeys.forEach((dgk, index) => {
    var d1 = new Date(dgk);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    _dateKey = ldgKeys[index]

    if (diff < 0 && !dd) {
      const xxx = ldgKeys.indexOf(dgk)
      const yyy = xxx - 1
      const zzz = ldgKeys[yyy]
      _dateKey = zzz
      dd = true
    }
  })
  return _dateKey
}