import _ from 'lodash';
import { getFirstObject } from '../../common/convert';
import { gEnums } from '../../enums/globalEnums';
import { approveStorageFilesToDatabase, updateStorageFilesToDatabase } from '../../storage/documentStorageHelpers';
import { deleteStorageFiles, removeFilesFromStorage } from '../../storage/removeStorage';
import { get_storageForders } from '../../storage/storageFolders';
import { getAllImagesInPath, getGlobalStorageFiles, _storageSettings } from '../../storage/storageHelpers';
import { getGallery_appUserGallery, getGallery_clientProfiles, getGallery_event, getGallery_page, getPdfs } from '../../storage/storageItems';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';

const rts = {
  handleApprove_image: 'handleApprove_image',
  handleApprove_selected: 'handleApprove_selected',
  handleBigImage: 'handleBigImage',
  handleClear_gallery: 'handleClear_gallery',
  handleClick_image: 'handleClick_image',
  handleClose_bigImage: 'handleClose_bigImage',
  handleDelete_selected: 'handleDelete_selected',
  handleDelete_storageItem: 'handleDelete_storageItem',
  handleGet_docsFromDatabase: 'handleGet_docsFromDatabase',
  handleGet_galleryAny: 'handleGet_galleryAny',
  handleClear_currentGalleryItems: 'handleClear_currentGalleryItems',
  handleGet_galleryFolders: 'handleGet_galleryFolders',
  handleGet_galleryImages: 'handleGet_galleryImages',
  handleGet_globalFiles: 'handleGet_globalFiles',
  handleGet_globalStorageFiles: 'handleGet_globalStorageFiles',
  handleGet_profileStorageFile: 'handleGet_profileStorageFile',
  handleGet_profileStorageFiles: 'handleGet_profileStorageFiles',
  handlePermissions: 'handlePermissions',
  handleRemove_filesFromStorage: 'handleRemove_filesFromStorage',
  handleResponse_getAny: 'handleResponse_getAny',
  handleSelect_image: 'handleSelect_image',
  handleSet_galleryFolders: 'handleSet_galleryFolders',
  handleSet_globalFiles: 'handleSet_globalFiles',
  handleSet_manifestFiles: 'handleSet_manifestFiles',
  handleSet_pagePdfs: 'handleSet_pagePdfs',
  handleSet_profile: 'handleSet_profile',
  handleSet_profileStorageFile: 'handleSet_profileStorageFile',
  handleSet_profileStorageFiles: 'handleSet_profileStorageFiles',
  handleShow_approvalWizard: 'handleShow_approvalWizard',
  handleStart_fetch: 'handleStart_fetch',
  handleUpdate_gallery: 'handleUpdate_gallery',
  ...grts,
}

export const storageReducer = (state, action) => {

  const { type, dispatch, globalFiles, manifestFiles, selectedKey, removeProps, profileStorageRootPath, fullImageProps, galleryType } = action
  const { appUserCollection, currentStorageFiles, profileStorageFiles, selectedItems, currentGalleryItems, pathViews, storageRootPath, galleries, useUnderscorePrefix } = state

  const {
    handleGet_galleryFolders,
    handleGet_globalFiles,
    handleGet_globalStorageFiles,
    handleGet_profileStorageFile,
    handlePermissions,
    handleResponse_getAny,
    handleSet_galleryFolders,
    handleSet_globalFiles,
    handleSet_manifestFiles,
    handleSet_profileStorageFile,
    handleShow_approvalWizard,
  } = storageHandlers(dispatch)

  const { galleryPaths } = _storageSettings

  let { direct, event, page, profile, profileGallery, profiles, pdf } = galleries ? galleries : {}

  if (!direct) { direct = {} }
  if (!event) { event = {} }
  if (!page) { page = {} }
  if (!pdf) { pdf = {} }
  if (!profile) { profile = {} }
  if (!profiles) { profiles = {} }
  if (!profileGallery) { profileGallery = {} }

  // this the response from any of the storage fetches3

  switch (type) {

    case rts.handleStart_fetch:
      return { ...state, currentGalleryItems: null, galleryFetched: action.kill ? true : false }

    // the global files for the app(floormap, icon, image, pdf)
    case rts.handleGet_globalFiles:
      if (pathViews && storageRootPath) {
        if (pathViews.events) {
          getGlobalStorageFiles(storageRootPath, useUnderscorePrefix, handleSet_globalFiles)
        } else {
          handleSet_globalFiles({})
        }
      } else {
        handleSet_globalFiles({})
      }
      return { ...state, pathViews, storageRootPath }

    // handles the above result
    case rts.handleSet_globalFiles:
      return {
        ...state,
        globalFiles,
        selectedItems: {}
      }

    //   callback for getGalleryDocumentsFromDatabase
    case rts.handleGet_galleryAny:

      let caption;

      handlePermissions(action.sp)

      const { appUserAccess, storageLocationType: slt, storageType, manifestIcons, email, view, viewKey } = action.sp

      const { gallery_gallery, gallery_type, gallery_exists } = getExistingGallery({ handleGet_galleryFolders, storageLocationType: slt, galleries, viewKey, view, email })

      if (gallery_exists) {
        return { ...state, galleryFetched: true, currentGalleryItems: gallery_gallery, galleryType: gallery_type }
      }

      switch (slt) {

        case gEnums.storageLocationTypes.eventGallery:
          getGallery_event(pathViews, handleResponse_getAny, slt)
          break;

        case gEnums.storageLocationTypes.clientProfiles:
          getGallery_clientProfiles(pathViews, appUserCollection, null, handleResponse_getAny, slt)
          break;

        case gEnums.storageLocationTypes.profileGallery:
          getGallery_appUserGallery(email, handleResponse_getAny, slt)
          break;

        case gEnums.storageLocationTypes.pageDirect:
        case gEnums.storageLocationTypes.pageGallery:
          getGallery_page(pathViews, action.sp, handleResponse_getAny, slt)
          break;

        case gEnums.storageLocationTypes.pagePdf:
          getPdfs(pathViews, action.sp, handleResponse_getAny, slt)
          break;

        case gEnums.storageLocationTypes.profiles:
          handleGet_profileStorageFile(galleryPaths.profiles + '/' + appUserAccess.email)
          break;

        case gEnums.storageLocationTypes.event:
          switch (storageType) {
            case gEnums.storageTypes.manifest:
              if (manifestIcons) { handleSet_manifestFiles(manifestIcons) }
              caption = 'Manifest Icons'
              break;
            default:
              handleGet_globalStorageFiles(slt, storageType)
              caption = _.startCase(storageType) + ': (' + _.startCase(slt) + ')'
          }
          break;

        default:
          switch (storageType) {
            case gEnums.storageTypes.manifest:
              if (manifestIcons) { handleSet_manifestFiles(manifestIcons) }
              caption = 'Manifest Icons'
              break;
            default:
            // nothing
          }
      }

      return { ...state, caption, galleryType: gallery_type }

    case rts.handleResponse_getAny:

      const { galleryResponse: gr } = action
      const { slt: _slt, galleryItems, email: _email, cb } = gr ? gr : {}

      switch (_slt) {
        case gEnums.storageLocationTypes.eventGallery:
          galleries.event = galleryItems
          break;

        case gEnums.storageLocationTypes.profiles:
          break;

        case gEnums.storageLocationTypes.clientProfiles:
          galleries.profiles = galleryItems
          break;

        case gEnums.storageLocationTypes.profileGallery:
          // galleries.profiles - one for each profile by email
          if (!profileGallery[_email]) { profileGallery[_email] = {} }
          profileGallery[_email] = galleryItems
          galleries.profileGallery = profileGallery
          // if (!profiles[gr.email]) { page[gr.email] = {} }
          // profiles[gr.email] = galleryItems
          // galleries.profiles = profiles
          break;

        case gEnums.storageLocationTypes.pageDirect:

          // this is a single item
          switch (gr.galleryPath) {
            case galleryPaths.galleryDirect:
              direct[gr.viewItemKey] = galleryItems
              galleries.direct = direct
              break;

            case galleryPaths.profiles:
              const auv = gr.appUserCollection
              if (auv) {
                const _profile = { ...profile, ...galleryItems }
                galleries.profile = _profile
              }
              break;

            default:
            // nothing
          }
          break;

        case gEnums.storageLocationTypes.pageGallery:

          const _gd = { ...galleries }

          switch (gr.galleryPath) {
            case galleryPaths.galleryPage:
              if (!page[gr.viewItemKey]) { page[gr.viewItemKey] = {} }
              if (!page[gr.viewItemKey][gr.viewKey]) { page[gr.viewItemKey][gr.viewKey] = {} }
              page[gr.viewItemKey][gr.viewKey] = gr.galleryItems
              galleries.page = page
              break;

            case galleryPaths.profile_all:
              _gd.profile = gr.gallery
              break;
            default:
            // nothing
          }
          break;

        case gEnums.storageLocationTypes.pagePdf:
          if (!pdf[gr.viewItemKey]) { pdf[gr.viewItemKey] = {} }
          if (!pdf[gr.viewItemKey][gr.viewKey]) { pdf[gr.viewItemKey][gr.viewKey] = {} }
          pdf[gr.viewItemKey][gr.viewKey] = gr.galleryItems
          galleries.pdf = pdf
          break;

        default:
        // nothing
      }

      return { ...state, galleries, galleryFetched: true }
    // return { ...state, galleries, currentGalleryItems: gr.galleryItems, galleryFetched: true }

    case rts.handleClear_gallery:
      const _g = { ...galleries }
      if (_g && _g[action.galleryType]) {
        delete _g[action.galleryType]
        return { ...state, galleries: _g }
      } else {
        return { ...state, galleries: {} }
      }

    case rts.handleClear_currentGalleryItems:
      return { ...state, currentGalleryItems: null }

    case rts.handleGet_globalStorageFiles:

      let _currentStorageFiles;
      switch (action.storageLocationType) {
        // gets the files from globalFiles
        case gEnums.storageLocationTypes.profiles:
        case gEnums.storageLocationTypes.event:
          _currentStorageFiles = state.globalFiles && state.globalFiles[action.storageType] ? state.globalFiles[action.storageType] : {}
          break;
        default:
        // nothing
      }

      return {
        ...state,
        storageLocationType: gEnums.storageLocationTypes.event,
        currentStorageFiles: _currentStorageFiles,
        selectedItems: {},
        galleryFetched: true
      }

    case rts.handleGet_galleryFolders:
      get_storageForders(pathViews, action, handleSet_galleryFolders)
      return { ...state }

    case rts.handleSet_galleryFolders:
      return { ...state, galleryFolders: gr.folderItems }

    case rts.handlePermissions:
      return { ...state, ...ammendPermissions(action.sp) }

    case rts.handleGet_profileStorageFile:
      getAllImagesInPath(profileStorageRootPath, handleSet_profileStorageFile)
      return { ...state, profileStorageRootPath }

    case rts.handleRemove_filesFromStorage:
      removeProps._thumbS8uffix = _storageSettings.thumbSuffix
      removeFilesFromStorage(removeProps)
      return { ...state }

    case rts.handleUpdate_gallery:
      if (selectedItems) {
        updateStorageFilesToDatabase(galleries, pathViews, action.selectedImage, action.viewItem_key).then(() => {
          handleShow_approvalWizard()
        })
        return { ...state }
      }
      return { ...state }

    case rts.handleDelete_storageItem:
      const { fullImageProps } = action
      const { path: _path } = fullImageProps ? fullImageProps : {}
      const x = []
      if (_path) {
        x.push(_path)
        deleteStorageFiles(null, x, _storageSettings.thumbSuffix).then((res) => {
          // const confirmation = { success: true, title: 'Files deleted' }
          // const cfs = { ...currentStorageFiles }
          // Object.keys(currentStorageFiles).forEach(key => {
          //   delete cfs[key]
          // })
          // handleGet_globalFiles()
          dispatch({ type: rts.updateSuccess, dispatch })
          return { ...state }
        })
      }
      return { ...state }

    case rts.handleDelete_selected:
      if (selectedItems) {
        deleteStorageFiles(currentStorageFiles, selectedItems, _storageSettings.thumbSuffix).then((res) => {
          const confirmation = { success: true, title: 'Files deleted' }
          const cfs = { ...currentStorageFiles }
          Object.keys(currentStorageFiles).forEach(key => {
            delete cfs[key]
          })
          handleGet_globalFiles()
          dispatch({ type: rts.updateSuccess, dispatch })
          return { ...state, confirmation, currentStorageFiles: cfs }
        })
      }
      return { ...state }

    case rts.handleApprove_selected:
      approveStorageFilesToDatabase(galleries, pathViews).then(() => {
        handleShow_approvalWizard()
      })
      return { ...state }

    case rts.handleGet_galleryImages:
      if (galleries) {
        const { documentName } = action
        if (galleries && galleries[galleryType] && galleries[galleryType][documentName]) {
          const items = galleries[galleryType][documentName]
          if (items) {
            const _currentGallery = {}
            switch (type) {
              case rts.handleGet_galleryImages:
                const galleryItem = _currentGallery[0]
                if (action.callback) {
                  action.callback(galleryItem)
                }
                break;
              default:
              // nothing
            }
          }
        }
      }
      return { ...state, selectedItem: null, galleryItem: null }

    case rts.handleSet_manifestFiles:
      return {
        ...state,
        storageLocationType: gEnums.storageLocationTypes.event,
        manifestFiles,
        galleryFetched: true
      }

    case rts.handleSet_profileStorageFile:
      let storageFile;
      if (action.storageFiles && Object.keys(action.storageFiles).length > 0) {
        const { item } = getFirstObject(action.storageFiles)
        storageFile = item
      }
      return {
        ...state,
        storageLocationType: gEnums.storageLocationTypes.profiles,
        profileStorageFile: storageFile,
        selectedItems: {}
      }

    case rts.handleSet_profileStorageFiles:
      return {
        ...state,
        storageLocationType: gEnums.storageLocationTypes.profiles,
        profileStorageFiles: action.storageFiles,
        selectedItems: {}
      }

    case rts.handleClick_image:

      const { isFullGalleryItem, storageLocationType, documentKey, isProfile, imageKey, path, customMetadata } = action

      if (documentKey) {
        if (isFullGalleryItem) {
          if (galleries && galleries.page && galleries.page[documentKey]) {
            const cf = galleries.page[documentKey]
            const cfi = cf ? cf[imageKey] : null
            const { urls, uid, user } = cfi ? cfi : {}
            const { full: url_full, thumbnail } = urls ? urls : {}
            if (url_full) {
              return { ...state, fullImageProps: { url: url_full, storageLocationType, documentKey, imageKey, uid, user, path, customMetadata } }
            } else if (thumbnail) {
              return { ...state, fullImageProps: { url: thumbnail, storageLocationType, documentKey, imageKey, uid, user, path, customMetadata } }
            }
          }
        } else {
          let _cf;
          switch (documentKey) {
            case gEnums.storageLocationTypes.pageDirect:
              _cf = currentGalleryItems
              break;
            default:
              _cf = currentGalleryItems && currentGalleryItems[imageKey] ? currentGalleryItems[imageKey] : null
          }
          if (_cf) {
            const { urls, uid, user } = _cf ? _cf : {}
            const { full: url_full, thumbnail } = urls ? urls : {}
            if (url_full) {
              return { ...state, fullImageProps: { url: url_full, storageLocationType, documentKey, imageKey, uid, user, path, customMetadata } }
            } else if (thumbnail) {
              return { ...state, fullImageProps: { url: thumbnail, storageLocationType, documentKey, imageKey, uid, user, path, customMetadata } }
            }
          }
        }
      } else {
        if (isProfile) {
          const prf = profileStorageFiles[imageKey]
          const { urls, uid, user } = prf ? prf : {}
          const { full: url_full, thumbnail } = urls ? urls : {}
          if (url_full) {
            return { ...state, fullImageProps: { url: url_full, storageLocationType, documentKey, imageKey, uid, user, path, customMetadata } }
          } else if (thumbnail) {
            return { ...state, fullImageProps: { url: thumbnail, storageLocationType, documentKey, imageKey, uid, user, path, customMetadata } }
          }
        }
      }
      return { ...state, imageKey }

    case rts.handleBigImage:
      return { ...state, fullImageProps }

    case rts.handleClose_bigImage:
      return { ...state, fullImageProps: null }

    case rts.handleSelect_image:
      if (action.allowMultiItemSelect) {
        const sis = { ...selectedItems }
        if (sis[selectedKey]) {
          delete sis[selectedKey]
        } else {
          sis[selectedKey] = { selected: true }
        }
        return { ...state, selectedItems: sis }
      } else {
        if (selectedItems && selectedItems[selectedKey]) {
          return { ...state, selectedItems: {} }
        } else {
          const si = { [selectedKey]: {} }
          return { ...state, selectedItems: si }
        }
      }

    case rts.handleApprove_image:
      const _galleries = galleries ? { ...galleries } : {}
      const { page: gallery_page } = _galleries ? _galleries : {}
      if (gallery_page) {
        const { documentKey } = action
        if (gallery_page[documentKey] && gallery_page[documentKey][selectedKey]) {
          gallery_page[documentKey][selectedKey].approved = !gallery_page[documentKey][selectedKey].approved
        }
      }
      return { ...state, galleries: _galleries }

    case rts.handleSet_profile:
      return { ...state }

    case rts.handleShow_approvalWizard:
      return { ...state, showApprovalWizard: !state.showApprovalWizard }

    case rts.handleCloseOpenedItem:
      return { ...state, confirmation: null, updating: null, fullImageProps: null }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return state
  }
}

export const storageInitialState = (initState) => {
  return { ...initState, storagePermissions: {}, selectedItems: {}, galleries: {} }
};

export const storageHandlers = (dispatch) => {
  return {
    handleApprove_image: (selectedKey, documentKey) => { dispatch({ type: rts.handleApprove_image, selectedKey, documentKey, dispatch }) },
    handleApprove_selected: () => { dispatch({ type: rts.handleApprove_selected, dispatch }) },
    handleBigImage: (bigImage) => { dispatch({ type: rts.handleBigImage, bigImage, dispatch }) },
    handleClear_gallery: (galleryType) => { dispatch({ type: rts.handleClear_gallery, dispatch, galleryType }) },
    handleClear_currentGalleryItems: () => { dispatch({ type: rts.handleClear_currentGalleryItems, dispatch }) },
    handleClick_image: (imageKey, storageLocationType, documentKey, isFullGalleryItem, isProfile, view, viewKey, path, customMetadata) => { dispatch({ type: rts.handleClick_image, dispatch, imageKey, storageLocationType, documentKey, isFullGalleryItem, isProfile, view, viewKey, path, customMetadata }) },
    handleClose_bigImage: () => { dispatch({ type: rts.handleClose_bigImage, dispatch }) },
    handleDelete_selected: () => { dispatch({ type: rts.handleDelete_selected, dispatch }) },
    handleDelete_storageItem: (fullImageProps) => { dispatch({ type: rts.handleDelete_storageItem, dispatch, fullImageProps }) },
    handleGet_docsFromDatabase: (currentGalleryPath) => { dispatch({ type: rts.handleGet_docsFromDatabase, dispatch, currentGalleryPath }) },
    handleGet_galleryAny: (sp) => { dispatch({ type: rts.handleGet_galleryAny, dispatch, sp }) },
    handleGet_galleryFolders: (sp) => { dispatch({ type: rts.handleGet_galleryFolders, dispatch, sp }) },
    handleGet_galleryImages: (galleryType, documentName, documentId, callback) => { dispatch({ type: rts.handleGet_galleryImages, dispatch, galleryType, documentName, documentId, callback }) },
    handleGet_globalFiles: () => { dispatch({ type: rts.handleGet_globalFiles, dispatch }) },
    handleGet_globalStorageFiles: (storageLocationType, storageType) => { dispatch({ type: rts.handleGet_globalStorageFiles, storageLocationType, storageType, dispatch }) },
    handleGet_profileStorageFile: (profileStorageRootPath) => { dispatch({ type: rts.handleGet_profileStorageFile, dispatch, profileStorageRootPath }) },
    handleGet_profileStorageFiles: (profileStorageRootPath) => { dispatch({ type: rts.handleGet_profileStorageFiles, dispatch, profileStorageRootPath }) },
    handlePermissions: (sp) => { dispatch({ type: rts.handlePermissions, sp }) },
    handleRemove_filesFromStorage: (removeProps) => { dispatch({ type: rts.handleRemove_filesFromStorage, removeProps, dispatch }) },
    handleResponse_getAny: (galleryResponse) => { dispatch({ type: rts.handleResponse_getAny, dispatch, galleryResponse }) },
    handleSelect_image: (selectedKey, documentKey, allowMultiItemSelect) => { dispatch({ type: rts.handleSelect_image, selectedKey, documentKey, allowMultiItemSelect, dispatch }) },
    handleSet_galleryFolders: (galleryResponse) => { dispatch({ type: rts.handleSet_galleryFolders, galleryResponse }) },
    handleSet_globalFiles: (globalFiles) => { dispatch({ type: rts.handleSet_globalFiles, globalFiles, dispatch }) },
    handleSet_manifestFiles: (manifestFiles) => { dispatch({ type: rts.handleSet_manifestFiles, manifestFiles, dispatch }) },
    handleSet_profile: () => { dispatch({ type: rts.handleSet_profile, dispatch }) },
    handleSet_profileStorageFile: (storageFiles) => { dispatch({ type: rts.handleSet_profileStorageFile, storageFiles, dispatch }) },
    handleSet_profileStorageFiles: (storageFiles) => { dispatch({ type: rts.handleSet_profileStorageFiles, storageFiles, dispatch }) },
    handleShow_approvalWizard: () => { dispatch({ type: rts.handleShow_approvalWizard, dispatch }) },
    handleStart_fetch: (kill) => { dispatch({ type: rts.handleStart_fetch, dispatch, kill }) },
    handleUpdate_gallery: (selectedImage, viewItem_key) => { dispatch({ type: rts.handleUpdate_gallery, dispatch, selectedImage, viewItem_key }) },
    ...responseHandlers(dispatch)
  }
}

export const getGalleryImage = ({ galleries, galleryType, documentName, documentId, callback }) => {
  if (galleries && galleries[galleryType] && galleries[galleryType][documentName]) {
    const items = galleries[galleryType][documentName]
    const _image = items[documentId]
    if (_image) {
      callback(_image)
    } else {
      callback()
    }
  } else {
    callback()
  }
}

const ammendPermissions = (sp) => {

  const {
    allowAppUserUpload,
    appUserAccess,
    appUserCollection,
    galleryApprovalAccessLevel,
    galleryNeedApproval,
    pathViews,
    storageLocationType,
    uploadAccessLevel,
  } = sp

  const { appUserSessionKey, accessLevel } = appUserAccess ? appUserAccess : {}
  const appUserPageKey = pathViews ? pathViews[appUserCollection] : null

  const _storagePermissions = {}

  let inverted;
  let approvedOnly;
  let addIcon = 'add'

  const hasAdminLevel = accessLevel >= gEnums.accessLevels.admin.value

  switch (storageLocationType) {

    case gEnums.storageLocationTypes.profiles:
      _storagePermissions.allowUpload = true;
      inverted = true
      addIcon = 'edit outline'
      break;

    case gEnums.storageLocationTypes.profileGallery:
      _storagePermissions.allowUpload = (appUserSessionKey === appUserPageKey) || (hasAdminLevel);
      inverted = true
      _storagePermissions.iconColor = hasAdminLevel ? 'red' : null
      break;

    case gEnums.storageLocationTypes.event:
      inverted = true
      _storagePermissions.allowUpload = true;
      _storagePermissions.allowRefresh = true
      _storagePermissions.allowDelete = true
      _storagePermissions.allowHeader = true
      break;

    case gEnums.storageLocationTypes.pageGallery:
      approvedOnly = galleryNeedApproval
      _storagePermissions.allowUpload = allowAppUserUpload && (accessLevel >= uploadAccessLevel);
      break;

    case gEnums.storageLocationTypes.pageDirect:
    case gEnums.storageLocationTypes.pagePdf:
      _storagePermissions.allowUpload = (allowAppUserUpload && (accessLevel >= uploadAccessLevel)) || (hasAdminLevel);
      _storagePermissions.allowSelect = true
      break;

    case gEnums.storageLocationTypes.fullGallery:
      if (galleryNeedApproval) {
        _storagePermissions.allowApprove = (accessLevel >= galleryApprovalAccessLevel) ? true : false
        approvedOnly = true
      } else {
        _storagePermissions.allowApprove = true
      }
      break;
    default:
    // nothing
  }

  return {
    storagePermissions: _storagePermissions,
    inverted,
    approvedOnly,
    addIcon,
  }

}

const getExistingGallery = (props) => {

  const { handleGet_galleryFolders, storageLocationType, galleries, viewKey, view, email } = props

  let _galleryType;

  switch (storageLocationType) {
    case gEnums.storageLocationTypes.fullGallery:
      handleGet_galleryFolders()
      break;
    case gEnums.storageLocationTypes.clientProfile:
      break;
    case gEnums.storageLocationTypes.clientProfiles:
      _galleryType = _storageSettings.galleryTypes.profiles
      break;
    case gEnums.storageLocationTypes.event:
      _galleryType = _storageSettings.galleryTypes.event
      break;
    case gEnums.storageLocationTypes.eventGallery:
      _galleryType = _storageSettings.galleryTypes.event
      break;
    case gEnums.storageLocationTypes.pageDirect:
      _galleryType = _storageSettings.galleryTypes.direct
      break;
    case gEnums.storageLocationTypes.pageGallery:
      _galleryType = _storageSettings.galleryTypes.page
      break;
    case gEnums.storageLocationTypes.pagePdf:
      _galleryType = _storageSettings.galleryTypes.pdf
      break;
    case gEnums.storageLocationTypes.profiles:
      break;
    case gEnums.storageLocationTypes.profileGallery:
      _galleryType = _storageSettings.galleryTypes.profiles
      break;
    default:
    // nothing
  }

  const existingProps = {
    gallery_gallery: null,
    gallery_type: null,
    gallery_exists: false
  }

  if (_galleryType) {

    switch (storageLocationType) {

      case gEnums.storageLocationTypes.clientProfiles:
        if (galleries && galleries[_galleryType] && galleries[_galleryType]) {
          existingProps.gallery_gallery = galleries[_galleryType]
          existingProps.gallery_type = _galleryType
          existingProps.gallery_exists = true
        }
        break;

      case gEnums.storageLocationTypes.eventGallery:
        if (galleries && galleries[_galleryType] && galleries[_galleryType]) {
          existingProps.gallery_gallery = galleries[_galleryType]
          existingProps.gallery_type = _galleryType
          existingProps.gallery_exists = true
        }
        break;

      case gEnums.storageLocationTypes.profileGallery:
        if (galleries && galleries[_galleryType] && galleries[_galleryType][email]) {
          existingProps.gallery_gallery = galleries[_galleryType][email]
          existingProps.gallery_type = _galleryType
          existingProps.gallery_exists = true
        }
        break;

      case gEnums.storageLocationTypes.profiles:
        if (galleries && galleries[_galleryType] && galleries[_galleryType][email]) {
          existingProps.gallery_gallery = galleries[_galleryType][email]
          existingProps.gallery_type = _galleryType
          existingProps.gallery_exists = true
        }
        break;

      default:
        if (galleries && galleries[_galleryType] && galleries[_galleryType][view] && galleries[_galleryType][view][viewKey]) {
          existingProps.gallery_gallery = galleries[_galleryType][view][viewKey]
          existingProps.gallery_type = _galleryType
          existingProps.gallery_exists = true
        }
    }
  }

  return existingProps
}