import _ from 'lodash'
import { gEnums } from '../../../global/enums/globalEnums'
import { createFsDocKey } from '../../../global/firestoreData/appData/fsAppData'
import { playoffBracketTypes } from '../elements/matches/PlayoffBracket'
import { sortDB } from './league'

const matchSequence = {
  // home, away ,work
  playoff: {
    32: [
      [1, 32, 1],
      [16, 17, 1],
      [9, 24, 1],
      [8, 25, 1],

      [5, 28, 1],
      [12, 21, 1],
      [13, 20, 1],
      [4, 29, 1],

      [3, 30, 1],
      [14, 19, 1],
      [11, 22, 1],
      [6, 27, 1],

      [7, 26, 1],
      [10, 23, 1],
      [15, 18, 1],
      [2, 31, 1],
    ],
    16: [
      [1, 16, 1],
      [8, 9, 1],

      [5, 12, 1],
      [4, 13, 1],

      [3, 14, 1],
      [6, 11, 1],

      [7, 10, 1],
      [2, 15, 1],
    ],
    8: [
      [1, 8, 1],
      [4, 5, 1],
      [3, 6, 1],
      [2, 7, 1]
    ],
    4: [
      [1, 4, 1],
      [2, 3, 1],
    ],
    2: [
      [1, 2, 1],
      [3, 4, 1, true],
    ]
  },
  normal: {
    3: [
      [1, 3, 2],
      [2, 3, 1],
      [1, 2, 3],
    ],
    4: [
      [1, 3, 2],
      [2, 4, 1],
      [1, 4, 3],
      [2, 3, 1],
      [3, 4, 2],
      [1, 2, 4]
    ],
  },
  odd: {
    round1: {
      3: [
        [1, 3, 2],
        [2, 3, 1],
        [1, 2, 3],
      ],
      4: [
        [1, 4, { split: 2 }],
        [2, 3, { split: 1 }],
        [{ loserOf: 1 }, { loserOf: 2 }, { splitWinners: 'losers' }],
        [{ winnerOf: 1 }, { winnerOf: 2 }, { splitLosers: 'winners' }],
      ]
    },
    round2: {
      3: [
        [1, 3, 2],
        [2, 3, 1],
        [1, 2, 3],
      ],
      4: [
        [1, 4, { split: 2 }],
        [2, 3, { split: 1 }],
        [{ loserOf: 1 }, { loserOf: 2 }, { splitWinners: 'losers' }],
        [{ winnerOf: 1 }, { winnerOf: 2 }, { splitLosers: 'winners' }],
      ]
    },
  }
}

/**
 * returns the teamInfo (seed) by finding the teamNumber from the teamPoolNumber
 * @param {object} pool - object of teams
 * @param {*} round 
 * @param {*} teamPoolNumber 
 * @returns 
 */
const getPoolTeamInfo = (pool, teamPoolNumber) => {

  let realTeamPoolNumber = teamPoolNumber

  if (teamPoolNumber.winnerOf) {
    realTeamPoolNumber = teamPoolNumber.winnerOf
  } else if (teamPoolNumber.loserOf) {
    realTeamPoolNumber = teamPoolNumber.loserOf
  } else if (teamPoolNumber.split) {
    realTeamPoolNumber = teamPoolNumber.split
  } else if (teamPoolNumber.splitWinners) {
    realTeamPoolNumber = teamPoolNumber.split
  } else if (teamPoolNumber.splitWinners || teamPoolNumber.splitLosers) {
    if (teamPoolNumber.splitWinners) {
      // teamName = 'winners split'
    } else {
      // teamName = 'losers split'
    }
  }

  // get the team name
  const seededTeam = _.find(pool.teams, { teamNumber: realTeamPoolNumber })

  const teamInfo = {
    seed: seededTeam ? seededTeam.seed : teamPoolNumber
  }

  return teamInfo

}

// const getPoolSeedTotals = (tournament) => {
//   Object.keys(tournament.roundInfo).forEach(roundKey => {
//     const r = tournament.roundInfo[roundKey]
//     Object.keys(r.pools).forEach(poolKey => {
//       const p = r.pools[poolKey]
//       let totalSeed = 0
//       Object.keys(p.teams).forEach(teamKey => {
//         const t = p.teams[teamKey]
//         totalSeed = totalSeed + t.seed
//       })
//     })
//   })
// }

/**
 * 
 * @param {object} tournament 
 * @param {number} teamsPerPool 
 * @param {object} pool 
 * @param {object} round 
 * @returns 
 */
const createPoolMatches = (tournament, teamsPerPool, pool, round) => {

  const poolMatches = {}
  const matchOrders = matchSequence.normal[teamsPerPool]

  matchOrders && Object.keys(matchOrders).forEach((matchOrderKey, index) => {

    const matchOrder = matchOrders[matchOrderKey]
    const matchNumber = index + 1

    const homeSeed = matchOrder[0]
    const awaySeed = matchOrder[1]
    const workSeed = matchOrder[2]

    // always 3 matchOrder (home, away, work)
    const homeTeam = getPoolTeamInfo(pool, homeSeed)
    const awayTeam = getPoolTeamInfo(pool, awaySeed)
    const workTeam = getPoolTeamInfo(pool, workSeed)

    const match = {
      name: 'match ' + matchNumber,
      matchNumber: matchNumber,
      courtNumber: pool.courtNumber,

      away: {
        id: awayTeam.id,
        name: awayTeam.name
      },

      home: {
        id: homeTeam.id,
        name: homeTeam.name
      },

      work: {
        id: workTeam.id,
        name: workTeam.name
      },

      seeds: {
        away: awayTeam.seed,
        home: homeTeam.seed,
        work: workTeam.seed,
      },
    }

    if (!tournament.isPlayoff) {
      match.seeds.work = workTeam.seed
    }

    poolMatches['match' + (index + 1)] = match
  })

  return poolMatches

}

/**
 * Add teams to existing matches based on their playoff seed.
 * @param {object} tournament 
 * @param {object} round 
 * @param {object} pool 
 * @param {object} roundInfo 
 * @param {object} otherRound 
 */
const assignTeamsToSeeds = (tournament, round, pool, roundInfo, otherRound) => {

  let initialSet = false

  const { tournamentTeams } = tournament ? tournament : {}

  Object.keys(pool.matches).forEach((key_match, matchNumber) => {

    const match = pool.matches[key_match]
    const matchSeeds = match.seeds

    const { isConsolation } = match ? match : {}
    const { away: awaySeed, home: homeSeed } = matchSeeds

    if ((roundInfo.isInitialPool || tournament.isPlayoff) && !tournament.initialSet) {

      if (tournament.isPlayoff) { initialSet = true }

      match.home = (round.seeds[homeSeed]) ? round.seeds[homeSeed] : { name: 'BYE', id: homeSeed }
      match.away = (round.seeds[awaySeed]) ? round.seeds[awaySeed] : { name: 'BYE', id: awaySeed }

      if (tournament.isPlayoff) {

        if (round.itemIndex === 1) {

          const team_away = _.find(tournamentTeams, { name: match.away.name })
          const team_home = _.find(tournamentTeams, { name: match.home.name })

          if (team_away) {
            match.away.sections = team_away.sections
            match.away.levels = team_away.levels
          }

          if (team_home) {
            match.home.sections = team_home.sections
            match.home.levels = team_home.levels
          }

          if (!team_away && !team_home) {
            match.doubleBye = true
          }

          match.location = match.home
        }
      } else {
        match.work = (round.seeds[matchSeeds.work]) ? round.seeds[matchSeeds.work] : ''
      }
      match.teams = [match.home.id, match.away.id]

      if (match.home.sections === match.away.sections) {
        match.sectionMeeting = true
      }

    } else {

      match.home = ''
      match.away = ''
      match.work = ''

      // add pending
      if (roundInfo.isPlayoff && otherRound) {
        match.pending = {
          home: findPlayoffSeedInfo(round, pool.itemIndex, homeSeed, isConsolation),
          away: findPlayoffSeedInfo(round, pool.itemIndex, awaySeed, isConsolation)
        }
      } else {
        match.pending = {
          home: findRoundPoolSeedInfo(tournament, round.itemIndex, homeSeed),
          away: findRoundPoolSeedInfo(tournament, round.itemIndex, awaySeed)
        }
      }

      if (!tournament.isPlayoff) {
        match.pending.work = findRoundPoolSeedInfo(tournament, round.itemIndex, matchSeeds.work)
      }
    }


    // const work = match.work
    // if (work.split) {
    //   Object.keys(pool.matches).forEach((matchKey2, matchIndex2) => {
    //     if ((matchIndex2 + 1) === work.split) {
    //       match.work = pool.matches[matchKey2].away + '/' + pool.matches[matchKey2].home
    //     }
    //   })
    // }
  })

  if (!tournament.initialSet) {
    tournament.initialSet = initialSet
  }

}

const assignWorkTeam = (pool) => {
  Object.keys(pool.matches).forEach((key_match, matchNumber) => {
    const match = pool.matches[key_match]
    const work = match.work
    if (work.split) {
      Object.keys(pool.matches).forEach((matchKey2, matchIndex2) => {
        if ((matchIndex2 + 1) === work.split) {
          match.work = pool.matches[matchKey2].away + '/' + pool.matches[matchKey2].home
        }
      })
    }
  })
}

const findRoundPoolSeedInfo = (tournament, roundIndex, seed) => {

  let seedInfo = {
    round: null,
    pool: null,
    place: null,
    originalSeed: 0,
  }

  Object.keys(tournament.rounds).forEach((roundKey, index1) => {
    if ((index1 + 1) === (roundIndex - 1)) {
      const round = tournament.rounds[roundKey]
      Object.keys(round.pools).forEach(poolKey => {
        const pool = round.pools[poolKey]
        Object.keys(pool.teams).forEach(teamKey => {
          const team = pool.teams[teamKey]
          if (team.seed === seed) {
            seedInfo.round = round.itemIndex
            seedInfo.pool = pool.itemIndex
            seedInfo.place = team.teamNumber
            seedInfo.originalSeed = seed
          }
        })
      })
    }
  })
  return seedInfo
}

// const findResult = (tournament, seedInfo) => {
//   const { rounds } = tournament ? tournament : {}
//   const { round, pool } = seedInfo ? seedInfo : {}

//   if (rounds && Object.keys(rounds).length > 0) {
//     if (rounds && rounds[round] && rounds[round].pools && rounds[round].pools[pool]) {
//       console.log('rounds[round].pools[pool]', rounds[round].pools[pool])
//     }
//   }
// }

const findPlayoffSeedInfo = (round, poolIndex, seed, isConsolation) => {

  let seedInfo = {
    round: null,
    pool: null,
    matchNumber: null,
    place: null,
    originalSeed: 0
  }

  Object.keys(round.pools).forEach((poolKey, index) => {
    const pool = round.pools[poolKey]
    if ((index + 1) === (poolIndex - 1)) {
      const { matches } = pool ? pool : {}
      Object.keys(matches).forEach(key_match => {
        const match = matches[key_match]
        // winner
        const { matchNumber, seeds, matchKey: match_key } = match ? match : {}
        const { home, away } = seeds ? seeds : {}
        if (home === seed || away === seed) {
          seedInfo.round = round.itemIndex
          seedInfo.roundKey = round.roundKey
          seedInfo.pool = pool.itemIndex
          seedInfo.poolKey = poolKey
          seedInfo.poolName = pool.altNameAbr
          seedInfo.matchNumber = matchNumber
          seedInfo.matchKey = key_match
          seedInfo.originalSeed = seed
          seedInfo.place = 'winner'
          seedInfo.match_key = match_key
          if (isConsolation) {
            seedInfo.place = 'loser'
          }
        }
      })
    }
  })

  return seedInfo

}

const addInitialPoolTeams = (round, roundInfo, tournamentTeamCount) => {

  let seedInc = 0
  let poolInc = 0
  let poolAsc = true

  // loop the poolTeamCount
  for (var tc = 0; tc <= tournamentTeamCount - 1; tc++) {

    let pool = null

    seedInc++

    // changed the pool number up and back in the first round
    if (poolAsc) {
      poolInc++
    } else {
      poolInc--
    }

    if (poolInc > roundInfo.poolsInRound) {
      poolInc = roundInfo.poolsInRound
      poolAsc = false
    } else if (poolInc < 1) {
      poolInc = 1
      poolAsc = true
    }

    const poolName = 'pool' + poolInc

    // create the pool
    if (round.pools[poolName] === undefined) {
      pool = {
        itemIndex: poolInc,
        name: 'pool ' + poolInc, // + String.fromCharCode(64 + poolInc),
        courtNumber: poolInc,
        teams: {},
      }
      round.pools[poolName] = pool
    } else {
      pool = round.pools[poolName]
    }

    const teamNumber = Object.keys(pool.teams).length + 1

    // add the team
    pool.teams['team' + teamNumber] = {
      teamNumber: teamNumber,
      seed: seedInc,
      ...round.seeds[seedInc]
    }
  }
}

const addPostInitialRoundPoolTeams = (round, roundInfo, tournamentTeamCount) => {

  let currentTeamSeed = 1
  let teamCount = 0
  let remaining = roundInfo.poolsInRound * roundInfo.maxTeams
  let pool = null
  let maxTeams = roundInfo.maxTeams
  let poolsRemaining = roundInfo.poolsInRound

  for (var pc = 1; pc <= roundInfo.poolsInRound; pc++) {

    const poolName = 'pool' + pc

    if ((poolsRemaining * maxTeams) !== remaining) {
      console.log('We have a problem')
      maxTeams--
    }

    // create the pool if it doesn't exist
    if (round.pools[poolName] === undefined) {
      pool = {
        itemIndex: pc,
        name: 'pool ' + pc,
        courtNumber: pc,
        teams: {}
      }
      round.pools[poolName] = pool
      poolsRemaining--
    } else {
      // set the pool
      pool = round.pools[poolName]
    }

    // add teams to the pool
    for (var tc = 1; tc <= maxTeams; tc++) {
      pool.teams['team' + tc] = {
        teamNumber: tc,
        seed: currentTeamSeed,
      }
      teamCount++
      currentTeamSeed++
    }
    remaining = tournamentTeamCount - teamCount
  }
}

/**
 * 
 * @param {object} tournament 
 * @param {object} round 
 * @param {object} roundInfo 
 * @param {number} tournamentTeamCount 
 */
const addPlayoffPoolTeams = (tournament, round, roundInfo, tournamentTeamCount) => {

  let roundCount = 1
  let pc = 0
  let teamPlayoffRoundCount = roundInfo.maxTeams
  let otherRound = false
  let roundNames = []
  let roundNamesAbr = []

  switch (roundInfo.maxTeams) {
    case 32:
      roundCount = 5
      roundNames = [playoffBracketTypes.roundOf32, playoffBracketTypes.roundOf16, playoffBracketTypes.quarterFinals, playoffBracketTypes.semiFinals, playoffBracketTypes.finals]
      roundNamesAbr = ['R32', 'R16', 'QF', 'SF', 'F']
      break;
    case 16:
      roundCount = 4
      roundNames = [playoffBracketTypes.roundOf16, playoffBracketTypes.quarterFinals, playoffBracketTypes.semiFinals, playoffBracketTypes.finals]
      roundNamesAbr = ['R16', 'QF', 'SF', 'F']
      break;
    case 8:
      roundCount = 3
      roundNames = [playoffBracketTypes.quarterFinals, playoffBracketTypes.semiFinals, playoffBracketTypes.finals]
      roundNamesAbr = ['QF', 'SF', 'F']
      break;
    case 4:
      roundCount = 2
      roundNames = [playoffBracketTypes.semiFinals, playoffBracketTypes.finals]
      roundNamesAbr = ['SF', 'F']
      break;
    case 2:
      roundCount = 1
      roundNames = ['Finals']
      roundNamesAbr = ['F']
      break;
    default:
    // nothing
  }

  // loop the ROUNDS
  for (var prc = 1; prc <= roundCount; prc++) {

    pc++

    const pn = 'pool' + pc
    let poolKey = roundNames[prc - 1]
    let altNameAbr = roundNamesAbr[prc - 1]

    // get the number of teams in the round
    switch (poolKey) {
      case playoffBracketTypes.roundOf32:
        teamPlayoffRoundCount = 32
        break;
      case playoffBracketTypes.roundOf16:
        teamPlayoffRoundCount = 16
        break;
      case playoffBracketTypes.quarterFinals:
        teamPlayoffRoundCount = 8
        break;
      case playoffBracketTypes.semiFinals:
        teamPlayoffRoundCount = 4
        break;
      case playoffBracketTypes.finals:
        teamPlayoffRoundCount = 2
        break;
      default:
      // nothing
    }

    // create a new POOL
    const pool = {
      itemIndex: pc,
      name: pn,
      poolKey,
      altNameAbr: altNameAbr,
      courtNumber: 'TBD',
      teams: {},
      startDate: (tournament.isPlayoff && tournament.selectedDates) ? tournament.selectedDates[pc - 1] : null
    }

    // add the pool to the round
    round.pools[_.camelCase(poolKey)] = pool

    // loop the number of team in the ROUND
    for (var tc = 1; tc <= teamPlayoffRoundCount; tc++) {
      if (tc <= tournamentTeamCount) {
        pool.teams['team' + tc] = {
          teamNumber: tc,
          seed: tc,
        }
      }
    }

    pool.matches = createPlayoffPoolMatches(tournament, pool, round, teamPlayoffRoundCount)

    otherRound = (prc !== 1) ? true : false
    assignTeamsToSeeds(tournament, round, pool, roundInfo, otherRound)
  }
}

/**
 * 
 * @param {object} tournament 
 * @param {object} round 
 * @param {object} roundInfo 
 * @param {number} tournamentTeamCount 
 */
const addTeamsToPools = (tournament, round, roundInfo, tournamentTeamCount) => {

  const { isInitialPool, isPlayoff } = roundInfo ? roundInfo : {}

  if (isInitialPool) {
    addInitialPoolTeams(round, roundInfo, tournamentTeamCount)
  } else if (isPlayoff) {
    addPlayoffPoolTeams(tournament, round, roundInfo, tournamentTeamCount)
  } else {
    addPostInitialRoundPoolTeams(round, roundInfo, tournamentTeamCount)
  }
}

/**
 * Creates the matches for the round
 * @param {object} tournament 
 * @param {object} round 
 * @param {object} roundInfo 
 */
const createMatches = (tournament, round, roundInfo) => {
  Object.keys(round.pools).forEach((poolKey, index) => {
    const pool = round.pools[poolKey]
    const teamCount = Object.keys(pool.teams).length
    pool.poolKey = poolKey
    if (!roundInfo.isPlayoff) {
      pool.matches = createPoolMatches(tournament, teamCount, pool, round)
      pool.startDate = (tournament.isPlayoff) ? tournament.selectedDates[index] : ''
      assignTeamsToSeeds(tournament, round, pool, roundInfo)
      assignWorkTeam(pool)
    }
  })
}

/**
 * Creates the playoff matches. No teams are included here
 * @param {object} tournament 
 * @param {object} pool 
 * @param {object} round 
 * @param {number} teamPlayoffRoundCount 
 * @returns 
 */
const createPlayoffPoolMatches = (tournament, pool, round, teamPlayoffRoundCount) => {

  const poolMatches = {}

  // matchOrders are an array of 3 number [homeSeed, awaySeed, workSeed]
  const matchOrders = matchSequence.playoff[teamPlayoffRoundCount]

  // loop the 
  Object.keys(matchOrders).forEach((matchOrderKey, index) => {

    const matchOrder = matchOrders[matchOrderKey]
    const matchNumber = index + 1


    // homeSeed, awaySeed, workSeed
    const homeSeed = matchOrder[0]
    const awaySeed = matchOrder[1]
    const workSeed = matchOrder[2]
    const isConsolation = matchOrder[3]

    // always 3 matchOrder (home, away, work)
    const homeTeam = getPoolTeamInfo(pool, homeSeed)
    const awayTeam = getPoolTeamInfo(pool, awaySeed)
    const workTeam = getPoolTeamInfo(pool, workSeed)

    // create the match
    const match = {
      name: pool.poolKey ? pool.poolKey : 'match',
      poolKey: pool.poolKey,
      roundKey: round.roundKey,
      matchKey: round.roundKey + '-' + pool.poolKey + '-' + matchNumber,
      matchNumber: matchNumber,
      seeds: {
        away: awayTeam.seed,
        home: homeTeam.seed,
      },
    }

    // update the work team
    if (!tournament.isPlayoff) {
      match.seeds.work = workTeam.seed
    }

    // update the startDate
    if (pool.startDate) {
      match.startDate = pool.startDate
      match.startTime = '7:00 PM'
    }

    if (tournament.levels) {
      match.levels = tournament.levels
    }

    match.isPlayoff = true
    match.isConsolation = isConsolation

    // add a match to the poolMatches 
    poolMatches['match' + (index + 1)] = match
  })

  return poolMatches

}

/**
 * Create one round for a tournament
 * @param {object} tournament 
 * @param {object} roundInfo 
 * @param {number} roundIndex 
 */
const createRound = (tournament, roundInfo, roundIndex) => {

  const { teams: tournamentTeams } = tournament ? tournament : {}

  // create the round
  const round = {}

  const tournamentTeamCount = Object.keys(tournamentTeams).length

  // new 
  roundInfo.realRoundIndex = (roundIndex + 1)

  const { realRoundIndex, maxTeams, isPlayoff } = roundInfo

  round.roundKey = isPlayoff ? 'playoffs' : 'round' + realRoundIndex
  round.itemIndex = realRoundIndex
  round.name = (roundInfo.isPlayoff) ? 'Playoffs' : 'Round ' + realRoundIndex

  if (tournamentTeamCount > 0) {

    roundInfo.poolsInRound = (maxTeams) ? Math.round(tournamentTeamCount / maxTeams) : 0

    if (realRoundIndex === 1) {
      round.seeds = getRoundSeeds(tournamentTeams, round.seeds)
    }

    // new POOLS
    round.pools = {}

    addTeamsToPools(tournament, round, roundInfo, tournamentTeamCount)
    createMatches(tournament, round, roundInfo)
  }

  tournament.rounds[round.roundKey] = round

  // getPoolSeedTotals(tournament)
}

/** Update the seed for the first round of the tournament */
const getRoundSeeds = (teams) => {
  const seeds = {}
  Object.keys(teams).forEach(ttKey => {
    const tt = teams[ttKey]
    seeds[tt.position + 1] = {
      id: tt.id,
      name: tt.name,
      seed: tt.position + 1
    }
  })
  return seeds
}

/** Create rounds for a tournament */
const createRounds = (tournament, playoff_info) => {

  // reset the maxTeams 
  let max = 0

  Object.keys(tournament.roundInfo).forEach((riKey, roundIndex) => {
    const roundInfo = tournament.roundInfo[riKey]
    max = max + roundInfo.maxTeams
  })

  Object.keys(tournament.roundInfo).forEach((riKey, roundIndex) => {
    const roundInfo = tournament.roundInfo[riKey]
    createRound(tournament, roundInfo, roundIndex)
  })
}

/**
 * 
 * @param {object} tournament 
 * @param {object} playoff_info 
 */
export const appendTournament = (tournament, playoff_info) => {
  tournament.rounds = {}
  createRounds(tournament, playoff_info)
}

/**
 * creates a tournament with teams, roundInfo
 * @param {boolean} playoffOnly 
 * @param {object} state 
 * @param {function} callback 
 */
export const createTournamentInfo = (playoffOnly, state, callback) => {

  const { subActionType, teams_selected, seededTeams, playoff_info } = state
  const { playoffLevelTeams, playoffDates } = playoff_info ? playoff_info : {}

  let { sportsDetails } = state

  switch (subActionType) {
    case gEnums.availableSubActionTypes.createTournament:
      const tournament = {
        tournamentTeams: teams_selected,
        teams: seededTeams,
        roundInfo: createRoundInfo(playoffOnly, sportsDetails, seededTeams),
        isPlayoff: playoffOnly
      }
      appendTournament(tournament)
      callback(tournament)
      break;

    case gEnums.availableSubActionTypes.createPlayoff:
      if (playoffLevelTeams) {
        const tournaments = {}
        Object.keys(playoffLevelTeams).forEach(key => {
          const teams = playoffLevelTeams[key]
          const selectedDates = playoffDates[key]
          if (!sportsDetails) { sportsDetails = {} }
          sportsDetails.tournamentTeamCount = teams.length
          const _tournament = {
            selectedDates,
            tournamentTeams: teams,
            teams: teams,
            roundInfo: createRoundInfo(playoffOnly, sportsDetails, teams),
            isPlayoff: playoffOnly,
            levels: key
          }
          try {
            appendTournament(_tournament)
            tournaments[key] = _tournament
          } catch (error) {
            console.log('_tournament error', error)
          }
        })
        const playoffMatches = getPlayoffMatches(tournaments)
        appendTournamentMatches(tournaments, playoffMatches)
        callback(tournaments, playoffMatches)
      }
      break;
    default:
      break;
  }
}

const appendTournamentMatches = (tournaments, playoffMatches) => {

  if (tournaments) {
    Object.keys(tournaments).forEach(tk => {
      const tournament = tournaments[tk]
      const { rounds } = tournament ? tournament : {}
      if (rounds) {
        Object.keys(rounds).forEach(rk => {
          const round = rounds[rk]
          const { pools } = round ? round : {}
          if (pools) {
            Object.keys(pools).forEach(pk => {
              const pool = pools[pk]
              const { matches } = pool ? pool : {}
              const matchRemoves = []
              if (matches) {
                Object.keys(matches).forEach(mk => {
                  const match = matches[mk]
                  const { pending } = match ? match : {}
                  if (pending) {

                    const { home, away } = pending

                    const match_home = getOriginalMatch(tk, playoffMatches, home.originalSeed)
                    const match_away = getOriginalMatch(tk, playoffMatches, away.originalSeed)

                    const match_home0 = match_home && match_home.length === 1 ? match_home[0] : null
                    const match_away0 = match_away && match_away.length === 1 ? match_away[0] : null

                    const { away: home_away } = match_home0 ? match_home0 : {}
                    const { away: away_away } = match_away0 ? match_away0 : {}

                    if (home_away && home_away.name === 'BYE') {
                      match.home = match_home0.home
                      matchRemoves.push(mk)
                    }

                    if (away_away && away_away.name === 'BYE') {
                      match.away = match_away0.home
                      matchRemoves.push(mk)
                    }
                  }
                })
              }
              // matchRemoves.forEach(mr => {
              //   delete pool.matches[mr] 
              // })
            })
          }
        })
      }
    })
  }
}

const getOpponent = (levels, playoffMatches, playoffMatch, homeOrAway) => {
  const { pending: _pending } = playoffMatch
  if (_pending) {
    const pendingTeam = _pending[homeOrAway]
    const { originalSeed } = pendingTeam
    const originalMatch = getOriginalMatch(levels, playoffMatches, originalSeed)
    if (originalMatch && originalMatch.length === 1) {
      return originalMatch[0][homeOrAway]
    }
  }
}

const getOriginalMatch = (levels, playoffMatches, originalSeed) => _.filter(playoffMatches, function (pm) {
  const { home } = pm
  return levels === pm.levels && home && (
    home.seed === originalSeed);
})

/**
 * Updates the parameters for the playoff for the roundInfo
 * @param {boolean} playoffOnly 
 * @param {object} sportsDetails 
 * @param {object} seededTeams 
 * @returns roundInfo
 */
const createRoundInfo = (playoffOnly, sportsDetails, seededTeams) => {

  const { roundCount, teamsPerPool, maxPlayoffTeams } = sportsDetails ? sportsDetails : {}
  const roundInfo = {}

  if (!playoffOnly) {
    for (var r = 1; r <= roundCount; r++) {
      const prefix = 'round' + r
      let isInitialPool = (r === 1) ? true : false
      if (!roundInfo[prefix]) { roundInfo[prefix] = {} }
      roundInfo[prefix].maxTeams = teamsPerPool
      roundInfo[prefix].setsPerMatch = 3
      roundInfo[prefix].pointsPerSet = 25
      roundInfo[prefix].bestOf = true
      roundInfo[prefix].isPlayoff = false
      roundInfo[prefix].isInitialPool = isInitialPool
      roundInfo[prefix].teams = seededTeams
      roundInfo[prefix].tournamentTeamCount = Object.keys(seededTeams).length
    }
  }
  ammendPlayoffInfo(roundInfo, maxPlayoffTeams, seededTeams)
  return roundInfo
}

/**
 * Updates the parameters for the playoff for the roundInfo
 * @param {object} roundInfo 
 * @param {*} maxPlayoffTeams 
 * @param {*} seededTeams 
 */
const ammendPlayoffInfo = (roundInfo, maxPlayoffTeams, seededTeams) => {

  let max_teams = maxPlayoffTeams ? maxPlayoffTeams : seededTeams.length

  switch (true) {
    case max_teams > 16 && max_teams <= 32:
      max_teams = 32
      break;
    case max_teams > 8 && max_teams <= 16:
      max_teams = 16
      break;
    case max_teams > 4 && max_teams <= 8:
      max_teams = 8
      break;
    case max_teams > 3 && max_teams <= 4:
      max_teams = 4
      break;
    default:
    // nothing
  }

  roundInfo['playoffs'] = {
    maxTeams: max_teams ? max_teams : 16,
    setsPerMatch: 3,
    pointsPerSet: 25,
    bestOf: true,
    isPlayoff: true,
    isInitialPool: false,
    teams: seededTeams,
    tournamentTeamCount: max_teams ? max_teams : 16,
  }
}


const getPlayoffMatches = (tournaments) => {
  const allMatches = {}
  if (tournaments) {
    Object.keys(tournaments).forEach(tournamentKey => {
      const tournament = tournaments[tournamentKey]
      if (tournament.rounds) {
        Object.keys(tournament.rounds).forEach(roundKey => {
          const round = tournament.rounds[roundKey]
          const { pools } = round ? round : {}
          if (pools) {
            Object.keys(pools).forEach(poolKey => {
              const pool = pools[poolKey]
              const { matches } = pool ? pool : {}
              if (matches) {
                Object.keys(matches).forEach(matchKey => {
                  const match = matches[matchKey]
                  allMatches[createFsDocKey('tournamentMatch')] = match
                })
              }
            })
          }
        })
      }
    })
  }
  return allMatches
}

/**
 * 
 * @param {object} playoffTeams 
 * @returns A group of teams by section, 
 */
export const sortPlayoffLevelTeams = (playoffTeams) => {

  const teams_levels = _.groupBy(playoffTeams, 'levels')

  const pst = {}

  Object.keys(teams_levels).forEach(levelKey => {
    const _playoffTeams = teams_levels[levelKey]
    const s_team = sortDB.sortLevelStandings(_playoffTeams)
    pst[levelKey] = s_team
  })

  const _playoffLevelTeams = {}

  Object.keys(pst).forEach(key => {
    const _teams = pst[key]
    const _seededTeams = createSeededTeamsFromSectionRank(_teams)
    _playoffLevelTeams[key] = _.sortBy(_seededTeams, 'seed')
  })

  return _playoffLevelTeams

}

/**
 * 
 * @param {object} teams 
 * @returns a list of teams sorted by section rank
 */
const createSeededTeamsFromSectionRank = (teams) => {
  const _seededTeams = {}
  Object.keys(teams).forEach((key, index) => {
    const team = teams[key]
    const { id, name, levels, sections, record, playoffSeed } = team
    const _position = playoffSeed ? playoffSeed : index
    const { sectionRank } = record
    _seededTeams[id] = {
      id: id,
      name: name,
      levels: levels,
      sections: sections,
      record: {
        ...record,
        sectionRank,
      },
      seed: _position,
      position: _position,
    }
  })
  return _seededTeams
}