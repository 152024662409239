import React, { createContext, useContext, useReducer } from 'react';
import { googleFormsHandlers, googleFormsInitialState, googleFormsReducer } from '../reducers/GoogleFormsReducer';
import { ParentContext } from './ParentContext';

/** AppContext */
export const GoogleFormsContext = createContext();

/** Global OtherDatas for the Event */
const GoogleFormsProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state, page_state } = states ? states : {}
  const { pathViews } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { googleForms } = aps_global ? aps_global : {}
  const { googleFormsId, googleFormsResponseId } = googleForms ? googleForms : {}

  const init_state = { pathViews, googleFormsId, googleFormsResponseId }
  const [googleForms_state, googleForms_dispatch] = useReducer(googleFormsReducer, googleFormsInitialState(init_state));
  const googleForms_handlers = googleFormsHandlers(googleForms_dispatch, googleForms_state)

  const providerContext = () => <GoogleFormsContext.Provider
    value={{ googleForms_handlers: googleForms_handlers, googleForms_state: googleForms_state }}>
    {props.children}
  </GoogleFormsContext.Provider>

  return providerContext()
}

export default GoogleFormsProvider