/**
 * 
 * @param {object} fn_response 
 * @returns - The `data` {success,result, error} object from the fn_response
 */
export function getFnResponseData(rsp) {
  const { data } = rsp
  return data
}

/**
 * 
 * @param {*} success 
 * @param {*} result 
 * @param {*} error 
 * @returns - {succes, result, error}
 */
export function createResult(success, result, error) {
  return { success, result, error }
}

export function createResponse(success, message, type, error) {
  const response = {
    data: {
      result: {
        message,
        error
      },
      success,
      type
    }
  }
  return response
}