import _ from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import AppUserList from '../appUsers/AppUserList';
import AppUserManagement from '../appUsers/AppUserManagement';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { viewModes } from '../cnr/reducers/AppNotificationViewerReducer';
import { notificationItemHandlers, notificationItemInitialState, notificationItemReducer, stepTypes } from '../cnr/reducers/NotificationItemReducer';
import { getFirstObject } from '../common/convert';
import { uniqueKey } from '../common/keys';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import AppForm from '../components/forms/appForms/AppForm';
import { DataManagementContext } from '../components/viewers/DataManagementViewer';
import StepWizard from '../components/wizard/StepWizard';
import { appFormTypes } from '../enums/appFormTypes';
import { gEnums } from '../enums/globalEnums';
import { DataModificationsContext } from '../pageItem/modification/UiDataModifications';
import UiStaticDropdowns from '../pageItem/modification/UiStaticDropdowns';
import { fullFilterTypes, UiDataFilters } from '../pageItem/UiDataFilters';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { AppNotificationsViewerContext } from './AppNotifications';
import NotificationItemStatusWithProvider from './NotificationItemStatus';
import TopicGroupSelect from './TopicGroupSelect';

const WAIT_INTERVAL = 2000

const CreateNotificationWizard = (props) => {

  const { noModal, notificationOriginType, notificationScheduleType, activeNote, isSub } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state, eventInfo_state } = states
  const { page_fns } = fns
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appNotifications } = aps_global ? aps_global : {}
  const { notificationTargets, useNotificationTopics, allowPushNotifications, allowSmsNotifications, allowEmailNotifications } = appNotifications ? appNotifications : {}
  const { appUserViewTypeProp, appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  // authContext 
  const { appUser, notificationToken } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  const { appProfileData, staticViews } = eventInfo_state ? eventInfo_state : {}
  const staticView_appUser = staticViews && appUserCollection ? staticViews[appUserCollection] : {}
  const _staticView = staticViews && appUserViewTypeProp ? staticViews[appUserViewTypeProp] : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state, item_handlers } = uiItemContext ? uiItemContext : {}
  const { viewItem, viewListData, viewKey, viewItem_key, currentGroupKey } = item_state ? item_state : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_handlers } = dataManagementContext ? dataManagementContext : {}

  // dataModificationsContext
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}

  // dataModificationsContext
  const appNotificationsViewerContext = useContext(AppNotificationsViewerContext)
  const { appNotificationsViewer_handlers } = appNotificationsViewerContext ? appNotificationsViewerContext : {}

  let spi = activeNote ? activeNote.pageItem : null
  if (!spi) { spi = viewItem ? viewItem.key : null }
  if (!spi && viewItem_key) { spi = viewItem_key }

  const handleClose = () => {
    if (item_handlers) {
      switch (notificationScheduleType) {
        case gEnums.notificationScheduleTypes.direct:
          item_handlers.handleShowItemSidebar()
          break;
        default:
          props.handleClose && props.handleClose()
          break;
      }
    } else if (appNotificationsViewer_handlers) {
      appNotificationsViewer_handlers.handleSetViewMode(viewModes.normal)
    } else {
      props.handleClose && props.handleClose()
    }
  }

  // reducer
  const initState = notificationItemInitialState({
    activeNote,
    appProfileData,
    appUserAccess,
    appUserCollection,
    appUserViewTypeProp,
    currentGroupKey,
    handleClose,
    item_handlers,
    item_state,
    notificationOriginType,
    notificationScheduleType,
    notificationToken,
    page_fns,
    paps_state,
    staticView: _staticView,
    staticView_appUser,
    useNotificationTopics,
    viewListData,
  })

  const [notificationItem_state, dispatch] = useReducer(notificationItemReducer, initState);
  const notificationItem_handlers = notificationItemHandlers(dispatch)

  const topperCaption = activeNote ? 'Edit Notification' : 'Create Notification'

  let topperCaption2;
  let isBack = false;

  switch (notificationOriginType) {
    case gEnums.notificationOriginTypes.appNotification:
      // topperCaption2 = viewItem_key ? viewItem_key : '???'
      break;
    case gEnums.notificationOriginTypes.uiItem:
      topperCaption2 = viewItem_key ? viewItem_key : '???'
      break;
    default:
    // nothing
  }

  const {
    allowBack,
    allowForward,
    notificationData,
    notificationRecipients,
    saving,
    selectedAppUsers,
    selectedFilter,
    selectedFilters,
    selectedPageItem,
    selectedTargets,
    selectedTopicGroups,
    sending,
    step,
    steps,
    success,
  } = notificationItem_state ? notificationItem_state : {}

  const {
    handleCancel: handleCancel_note,
    handlePageItem,
    handleFormDataChange_local,
    handleSelectedFilters,
    handleSelectFilter,
    handleStep,
    handleTopicOrigin,
    handleNotificationSave,
    handleNotificationSend,
    handleSendToSelf,
    handleSuccess,
  } = notificationItem_handlers ? notificationItem_handlers : {}

  if (notificationData) {
    if (selectedFilter) { notificationData.filter = selectedFilter }
    if (selectedFilters) { notificationData.filters = selectedFilters }
    if (selectedTargets) { notificationData.selectedTargets = selectedTargets }
    if (notificationScheduleType === gEnums.notificationScheduleTypes.direct) { }
  }

  const _updating = saving || sending

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => handleSuccess(), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [success]);

  useEffect(() => {
    const { item: dataItem } = viewListData ? getFirstObject(viewListData) : {}
    const appUserList = appProfileData ? appProfileData[appUserCollection] : {}
    const dataUserList = dataItem ? dataItem[appUserCollection] : []
    const _selectedAppUsers = {}
    if (dataUserList && appUserList && _.isArray(dataUserList)) {
      dataUserList.forEach(ui => {
        if (appUserList[ui]) {
          _selectedAppUsers[ui] = appUserList[ui]
        }
      })
    }
    notificationItem_handlers.handleSelectedAppUsers(_selectedAppUsers)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleCancel = () => {
    handleCancel_note && handleCancel_note()
    dataManagement_handlers && dataManagement_handlers.handleSelectDataActionOption('options')
    dataModifications_handlers && dataModifications_handlers.handleDataModificationSelect()
  }

  const create_filter = () => <UiDataFilters fullFilterType={fullFilterTypes.settingsView} notificationData={notificationData} selectedPageItem={selectedPageItem} handleSelectedFilters={handleSelectedFilters} handleSelectFilter={handleSelectFilter} selectedFilters={selectedFilters} />
  const create_page = () => <UiStaticDropdowns handlePageItem={handlePageItem} selectedPageItem={selectedPageItem} notificationTargets={notificationTargets} notificationData={notificationData} />

  const appForm = (appFormType) => <AppForm
    appFormType={appFormType}
    parentData={notificationData}
    handleUpdateParentData={handleFormDataChange_local}
    key={uniqueKey(appFormType)}
    ignoreItemStatus={true}
  />

  const footer = () => {
    const { notificationScheduleType } = notificationData ? notificationData : {}
    let btns = []
    if (notificationScheduleType === gEnums.notificationScheduleTypes.scheduled) {
      btns = [
        { caption: 'Save', oc: handleNotificationSave, icon: 'save' },
      ]
    } else {
      btns = [
        { caption: 'Send', oc: handleNotificationSave, icon: 'delete', opts: { fl: true } },
      ]
    }
    return <UiSaveButtons others={btns} />
  }

  const confirmationInfo = () => {
    const _notification = { ...notificationData, currentGroupKey, appUserViewTypeProp, appUserCollection }
    return <Wrapper
      content={<NotificationItemStatusWithProvider
        selectedAppUsers={selectedAppUsers}
        notification={_notification}
        notificationRecipients={notificationRecipients}
      />}
      footer={footer()}
      wrapperType={wrapperTypes.padded}
      updating={_updating}
    />
  }

  const stepCaption = () => {
    const name = _.startCase(step.name)
    switch (step.name) {
      default:
        return name
    }
  }

  const content = () => {
    switch (step.name) {
      case stepTypes.notification:
        return appForm(appFormTypes.notificationsDirect)
      case stepTypes.filters:
        return create_filter()
      case stepTypes.notificationTarget:
        return create_page()
      case stepTypes.notificationOptions:
        return appForm(appFormTypes.notificationOptions)
      case stepTypes.deliveryType:
        return appForm(appFormTypes.notificationsDelivery)
      case stepTypes.scheduleType:
        return appForm(appFormTypes.notificationsSchedule)
      case stepTypes.appUserList:
        return <AppUserManagement fromNotifications={true} currentGroupKey={currentGroupKey} />
      case stepTypes.appUserCollectionList:
        return <AppUserList items={viewListData} fromNotifications={true} viewOnly={true} />
      case stepTypes.appProfileData:
        return <TopicGroupSelect
          handleTargetSelect={handleTopicOrigin}
          appProfileData={appProfileData}
          selectedTopicGroups={selectedTopicGroups}
        />
      case stepTypes.confirmation:
        return confirmationInfo()
      default:
        break;
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    handleCancel={handleCancel}
    noModal={noModal}
    setStep={handleStep}
    step={step}
    stepCaption={stepCaption()}
    steps={steps}
    topperCaption={topperCaption}
    topperCaption2={_.startCase(topperCaption2)}
    isSub={isSub}
    isBack={isBack}
    useTopper={true}
  ></StepWizard>

  return step ? stepWizard() : <div></div>

}

export default CreateNotificationWizard