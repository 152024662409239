import _ from 'lodash';
import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import { FormContext } from '../../cnr/contexts/FormContext';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { _find } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { checkbox } from './elements/checkbox';
import DateInput from './elements/DateInput';
import DropdownInput from './elements/DropdownInput';
import FormInput from './elements/FormInput';
import { ImageInput } from './elements/ImageInput';
import NumberPicker from './elements/NumberPicker'
import SelectInput from './elements/SelectInput';
import TextAreaInput from './elements/TextAreaInput';
import { formItemProps } from './props/formItemProps';
import { getFormOptions } from './props/formOptions';

const AddressForm = React.lazy(() => import('./AddressForm'));
const DataConstraintList = React.lazy(() => import('./elements/DataConstraintList'));
const DropdownLabel = React.lazy(() => import('./elements/DropdownLabel'));
const GenericCard = React.lazy(() => import('../../genericControls/GenericCard'));
const GenericList = React.lazy(() => import('../../genericControls/GenericList'));
const GenericProfile = React.lazy(() => import('../../genericControls/GenericProfile'));
const GolfTeams = React.lazy(() => import('../../../projectSpecific/golf/elements/GolfTeams'));
const InlineFormElements = React.lazy(() => import('./groupedElements/InlineFormElements'));
const ItemList = React.lazy(() => import('./groupedElements/ItemList'));
const ItemMap = React.lazy(() => import('./groupedElements/ItemMap'));
const ListAdd = React.lazy(() => import('../../lists/ListAdd'));
const ManifestPreview = React.lazy(() => import('../../manifest/ManifestPreview'));
const MenuItemsSelect = React.lazy(() => import('./elements/MenuItemsSelect'));
const NumberPadPicker = React.lazy(() => import('./elements/NumberPadPicker'));
const PhoneNumberInput = React.lazy(() => import('./elements/PhoneNumberInput'));
const PropGroup = React.lazy(() => import('./elements/PropGroup'));
const SemFileViewer = React.lazy(() => import('../sem/SemFileViewer'));
const SignIn = React.lazy(() => import('../auth/SignIn'));
const Splash = React.lazy(() => import('../alerts/Splash'));
const TableMap = React.lazy(() => import('./elements/TableMap'));
const TextConfig = React.lazy(() => import('./elements/TextConfig'));
const ThemedColors = React.lazy(() => import('./elements/ThemedColors'));
const UploadInput = React.lazy(() => import('./elements/UploadInput'));

const logErrors = true

/**
 * 
 * @param {*} props (activeItem, allow, index, itemProp, propKey, readOnlyForm)
 * @returns a single formItem (element) based on the `itemProp`
 */
const FormItem = (props) => {

  const {
    activeItem,
    allow,
    index,
    itemProp,
    propKey,
    readOnlyForm,
    viewOnly,
  } = props

  // formContext
  const formContext = useContext(FormContext)
  const { form_state, form_handlers, swipeToSelected, swipedItem, lockedData, formParent_contexts } = formContext ? formContext : {}
  const { data_localForm: formData, formProps, optionsList, modifyActionType } = form_state ? form_state : {}
  const { handlePropPush, handleEditor, handleFormItemChange } = form_handlers ? form_handlers : {}
  const { formKey, subKey, data_current, vit, updateProps, form_handlers: form_handlers_props } = formProps ? formProps : {}
  let { itemKey, isReadOnly } = formProps

  // formParent_contexts
  const { states, settings } = formParent_contexts ? formParent_contexts : {}
  const { appUser_state, eventInfo_state, page_state } = states ? states : {}
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appSettings, themeColors, dataOptions } = aps_global ? aps_global : {}
  const { clientRegistrationUrl, useClientRegistrationUrl } = appSettings ? appSettings : {}
  const { dateFormat } = dataOptions ? dataOptions : {}
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  const { homeSettings } = settings ? settings : {}
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { globalAppSettings } = global_home ? global_home : {}
  const { registrationUrl } = globalAppSettings ? globalAppSettings : {}

  const regUrl = useClientRegistrationUrl && clientRegistrationUrl ? clientRegistrationUrl : registrationUrl

  const formItem = () => {

    const { data, parents, display: display_itemProp } = itemProp ? itemProp : {}
    let { formItemType, eventKey, defaultToTodayIfDate, required, appQuestion } = data ? data : {}
    let { caption } = display_itemProp ? display_itemProp : {}
    let captionQuestion;

    if (appQuestion) {
      if (globalAppSettings && globalAppSettings[appQuestion]) {
        captionQuestion = globalAppSettings[appQuestion]
      }
    }

    let truePropKey = propKey
    let propValue = null

    try {
      if (formData) {
        propValue = eventKey && (truePropKey !== eventKey) ? formData[eventKey] : formData[truePropKey]
      }
    } catch (error) {
      logErrors && console.error('ERROR', error)
    }

    caption = caption ? caption : _.startCase(truePropKey)
    formItemType = formItemType ? formItemType : gEnums.formItemTypes.text

    switch (formItemType) {
      case 'label':
        formItemType = gEnums.formItemTypes.text
        break;
      default:
      // nothing
    }

    if (formData && parents) {
      let cd = formData
      itemProp.parents.forEach(parent => {
        if (cd) { cd = cd[parent] }
      })
      propValue = cd ? cd[truePropKey] : null
    }

    if (itemProp && itemProp.data && itemProp.data.propFrom && itemProp.data.propFromProp) {
      switch (itemProp.data.propFrom) {
        case 'appUser':
          if (authAppUser && authAppUser[itemProp.data.propFromProp]) {
            propValue = authAppUser[itemProp.data.propFromProp]
          }
          break;
        default:
        // nothing
      }
    }

    const isEdit = modifyActionType === gEnums.dataUpdateTypes.edit

    const fieldProps = {
      key: itemProp.as ? itemProp.as + '-' + truePropKey + '-' + index : truePropKey + '-' + index,
      caption: caption,
      disabled: itemProp.disabled || !allow ? true : null,
      itemProp: itemProp,
      label: caption,
      parentkey: formKey,
      propkey: truePropKey,
      propname: truePropKey,
      readOnly: (itemProp.readOnly && isEdit) || viewOnly ? true : false,
      subkey: subKey,
      value: propValue ? propValue : undefined,
      vit: vit,
      onChange: handleFormItemChange,
      form_handlers: form_handlers_props,
      opts: itemProp.opts,
      itemKey: itemKey,
      formitemtype: formItemType,
    }

    if (itemProp.as) { fieldProps.icon = propValue }
    if (itemProp && itemProp.data && itemProp.data.readOnly && isEdit) { fieldProps.readOnly = true }
    if (lockedData && lockedData.includes(truePropKey)) { fieldProps.disabled = true }
    if (readOnlyForm && isEdit) { fieldProps.readOnly = true }
    if (isReadOnly) { fieldProps.readOnly = true }

    // set the defaults
    switch (formItemType) {
      case gEnums.formItemTypes.upload:
        if (!fieldProps.value) { fieldProps.value = '' }
        break;
      case gEnums.formItemTypes.textArea:
      case gEnums.formItemTypes.textAreaSimple:
        if (!fieldProps.value) { fieldProps.value = '' }
        break;
      case gEnums.formItemTypes.checkbox:
        if (!fieldProps.value) { fieldProps.value = false }
        break;
      default:
        if (!fieldProps.value) { fieldProps.value = '' }
      // nothing
    }

    const tpklc = truePropKey.toLowerCase()

    if (tpklc.indexOf('password') >= 0) {
      fieldProps.type = 'password'
      fieldProps.otype = 'password'
    }

    const fip = formItemProps(fieldProps, handleFormItemChange, required)

    if (tpklc.endsWith('active')) {
      formItemType = gEnums.formItemTypes.checkbox
      itemProp.data.formItemType = gEnums.formItemTypes.checkbox
    }

    if (tpklc.indexOf('date') >= 0 &&
      tpklc.endsWith('date') &&
      !tpklc.endsWith('update') &&
      tpklc.indexOf('format') < 0 &&
      tpklc.indexOf('menus') < 0 &&
      tpklc.indexOf('horizontalIsDate') < 0) {
      formItemType = gEnums.formItemTypes.date
    }

    if (tpklc.endsWith('year')) {
      formItemType = gEnums.formItemTypes.year
      itemProp.data.formItemType = gEnums.formItemTypes.select
    }

    if (tpklc.indexOf('phonenumber') >= 0 && truePropKey !== 'displayPhoneNumber' && truePropKey !== 'allowPhoneNumber' && truePropKey !== 'hidePhoneNumber' && truePropKey !== 'hidePhoneNumberFromApp') {
      formItemType = gEnums.formItemTypes.phoneNumber
    }

    if (fieldProps.value && _.isObject(fieldProps.value)) {

      switch (formItemType) {
        case gEnums.formItemTypes.text:
          formItemType = gEnums.formItemTypes.dropdownSelect
          break;

        case gEnums.formItemTypes.itemArrayMulti:
        case gEnums.formItemTypes.dropdown:
        case gEnums.formItemTypes.dropdownAdd:
        case gEnums.formItemTypes.dropdownGroup:
        case gEnums.formItemTypes.listSelect:
          // nothing
          break;
        default:
        // nothing
      }
    }

    switch (formItemType) {
      case gEnums.formItemTypes.select:
        if (fieldProps.value && _.isArray(fieldProps.value))
          formItemType = gEnums.formItemTypes.dropdown
        break;
      default:
      // nothing
    }

    const optionsProps = { itemProp, optionsList, data_current }
    const fipProps = { fip, itemProp, regUrl, form_handlers_props, handlePropPush }

    delete fip.formItemType

    switch (formItemType) {

      case gEnums.formItemTypes.image:
        return <ImageInput />

      case gEnums.formItemTypes.address:
        return <AddressForm formData={formData} fip={fip} />

      case gEnums.formItemTypes.authAppUserTypes:
        return <div>authAppUserTypes</div>

      case gEnums.formItemTypes.inlineItems:
        return <InlineFormElements
          key={uniqueKey('fi', 'ife')}
          fip={fip}
          activeItem={activeItem}
          itemProp={itemProp}
        />

      case gEnums.formItemTypes.checkbox:
        if (!fieldProps.value) { fieldProps.value = false }
        return checkbox(fip, captionQuestion)

      case gEnums.formItemTypes.backgroundColorSelectSingle:
      case gEnums.formItemTypes.colorSelect:
      case gEnums.formItemTypes.colorSelectSingle:
        return <ThemedColors key={uniqueKey('fi', 'tc')} fip={fip} themeColors={themeColors} itemProp={itemProp} />

      case gEnums.formItemTypes.dropdown:
      case gEnums.formItemTypes.dropdownAdd:
      case gEnums.formItemTypes.listSelect:
        try {
          if (optionsList) {
            const { data: prop_data } = itemProp ? itemProp : {}
            let optionList = prop_data.trueList ? prop_data.trueList : getFormOptions(optionsProps)

            if (optionList && fip.value && _.isArray(fip.value)) {
              if (prop_data && prop_data.staticList && staticViews && staticViews[prop_data.staticList]) {
                const svc = staticViews[prop_data.staticList]
                const newV = []
                fip.value.forEach(v => {
                  const x = _find(svc, 'name', v) // _.find(svc, { 'name': v })
                  if (x) { newV.push(x.key) }
                })
                if (newV.length > 0) { fip.value = newV }
              }
              fip.value.forEach((v, index) => {
                const xx = _find(optionList, 'value', v) // _.find(optionList, { 'value': v })
                if (!xx) { fip.value.splice(index) }
              })
            }
            switch (formItemType) {
              case gEnums.formItemTypes.listSelect:
                return <div>TEST</div>
              default:
                return <DropdownInput fip={fip} optionList={optionList} selectMulti={true} itemProp={itemProp} />
            }
          } else {
            switch (formItemType) {
              case gEnums.formItemTypes.listSelect:
                return <div>TEST</div>
              default:
                return <DropdownInput fip={fip} optionList={[]} selectMulti={true} itemProp={itemProp} />
            }
          }
        } catch (error) {
          logErrors && console.error('itemProp', itemProp, error)
        }
        break;

      case gEnums.formItemTypes.dropdownGroup:
        try {
          if (optionsList) {
            let optionList = getFormOptions(optionsProps)
            return <PropGroup dropdown={<DropdownInput fip={fip} optionList={optionList} selectMulti={true} />} />
          } else {
            return <DropdownInput fip={fip} optionList={[]} selectMulti={true} />
          }
        } catch (error) {
          logErrors && console.error('itemProp', itemProp, error)
        }
        break;

      case gEnums.formItemTypes.dropdownSelect:
        return <DropdownLabel
          arrayValue={fieldProps.value}
          forEdit={true}
          fip={fip}
          isAppData={true}
          propKey={truePropKey}
          staticViews_app={staticViews}
        ></DropdownLabel>

      case gEnums.formItemTypes.gridRowSelect:
      case gEnums.formItemTypes.gridRowSelectFull:
        let optionList = itemProp && itemProp.data && itemProp.data.trueList ? itemProp.data.trueList : getFormOptions(optionsProps)
        return <MenuItemsSelect menuList={optionList} itemProp={itemProp} fip={fip} />

      case gEnums.formItemTypes.menuItemSelect:
      case gEnums.formItemTypes.menuItemSelectFull:
      case gEnums.formItemTypes.menuItemSelectFullCount:
        let menuList = itemProp && itemProp.data && itemProp.data.trueList ? itemProp.data.trueList : getFormOptions(optionsProps)
        return <MenuItemsSelect menuList={menuList} itemProp={itemProp} fip={fip} />

      case gEnums.formItemTypes.itemMap:
      case gEnums.formItemTypes.itemArrayMulti:
        return <ItemMap fip={fip} formItemType={formItemType} themeColors={themeColors} labelProps={itemProp.data ? itemProp.data.labelProps : null} />

      case gEnums.formItemTypes.itemList:
        return <ItemList
          documentData={formData}
          fip={fip}
          updateProps={updateProps}
          itemProp={itemProp}
        />

      case gEnums.formItemTypes.fileItemSelect:
      case gEnums.formItemTypes.fileItemSelectDirect:
        return <SemFileViewer fip={fip} fieldProps={fieldProps} formProps={formProps} fileMode={gEnums.fileModes.viewWithSelectDirect} />

      case gEnums.formItemTypes.fileManifestSelect:
        return <SemFileViewer fip={fip} fieldProps={fieldProps} formProps={formProps} fileMode={gEnums.fileModes.viewManifest} />

      case gEnums.formItemTypes.fileSelect:
        return <SemFileViewer fip={fip} fieldProps={fieldProps} formProps={formProps} fileMode={gEnums.fileModes.viewWithSelect} />

      case gEnums.formItemTypes.listAdd:
      case gEnums.formItemTypes.listView:
        return <ListAdd fip={fip} fieldProps={fieldProps} inverted={true} />

      case gEnums.formItemTypes.appUserDataConstraintList:
      case gEnums.formItemTypes.dataConstraintList:
      case gEnums.formItemTypes.pageConstraintList:
        return <DataConstraintList formItemType={formItemType} fip={fip} fieldProps={fieldProps} vit={vit} />

      case gEnums.formItemTypes.select:
      case gEnums.formItemTypes.nameSelect:
      case gEnums.formItemTypes.year:

        try {
          if (optionsList) {
            let optionList = itemProp && itemProp.data && itemProp.data.trueList ? itemProp.data.trueList : getFormOptions(optionsProps)

            if (optionList && fip.value && _.isArray(fip.value)) {
              if (itemProp && itemProp.data && itemProp.data.staticList && staticViews && staticViews[itemProp.data.staticList]) {
                const svc = staticViews[itemProp.data.staticList]
                const newV = []
                fip.value.forEach(v => {
                  const x = _.find(svc, { 'name': v })
                  if (x) { newV.push(x.key) }
                })
                if (newV.length > 0) { fip.value = newV }
              }
            }

            if (!optionList) {
              fipProps.fip.label += ' (** No options list provided)'
              return <FormInput ipProps={fipProps} />
            } else {
              return <SelectInput fip={fip} itemProp={itemProp} optionList={optionList} listType={itemProp.listType} />
            }
          } else {
            return <SelectInput fip={fip} itemProp={itemProp} optionList={[]} listType={itemProp.listType} />
          }
        } catch (error) {
          logErrors && console.error('itemProp', itemProp, truePropKey, error)
        }

        return <SelectInput fip={fip} itemProp={itemProp} optionList={[]} />

      case gEnums.formItemTypes.numberPicker:
        return <NumberPadPicker fip={fip} itemProp={itemProp} />

      case gEnums.formItemTypes.slider:
        return <NumberPicker fip={fip} itemProp={itemProp} />

      case gEnums.formItemTypes.phoneNumber:
        const pnProps = { fip, itemProp, form_handlers_props, handlePropPush }
        return <PhoneNumberInput pnProps={pnProps} />

      case gEnums.formItemTypes.tableMap:
        const tmProps = { fip, itemProp, data_current, swipedItem, swipeToSelected }
        return <TableMap tmProps={tmProps} />

      case gEnums.formItemTypes.date:
        if (!fip.value && defaultToTodayIfDate) {
          const d = new Date()
          const dd = formatItem(formatTypes.date, d)
          fip.value = dd
        }
        return <DateInput fip={fip} itemProp={itemProp} dateFormat={dateFormat} />

      case gEnums.formItemTypes.text:
      case gEnums.formItemTypes.autoFill:
        return <FormInput ipProps={fipProps} />

      case gEnums.formItemTypes.textArea:
      case gEnums.formItemTypes.textAreaSimple:
        if (!fieldProps.value) { fieldProps.value = '' }
        return <TextAreaInput fip={fip} itemProp={handleEditor} formItemType={formItemType} />

      case gEnums.formItemTypes.textConfig:
        return <TextConfig fip={fip} />

      case gEnums.formItemTypes.upload:
        if (!fieldProps.value) { fieldProps.value = '' }
        return <UploadInput fip={fip} form_handlers={form_handlers_props} />

      default:
        const { previewType } = data ? data : {}
        switch (previewType) {
          case gEnums.previewTypes.display:
            return <div>DISPLAY</div>
          case gEnums.previewTypes.manifest:
            return <ManifestPreview formData={formData} />
          case gEnums.previewTypes.splashScreen:
            return <Splash formData={formData} />
          case gEnums.previewTypes.signIn:
            return <SignIn />
          case gEnums.previewTypes.golferTeamSelect:
            return <GolfTeams forSelect={true} />
          case gEnums.previewTypes.dataFilters:
            return <Segment inverted>dataFilters</Segment>
          case gEnums.previewTypes.list:
            return <GenericList formData={formData} itemProp={itemProp} subKey={subKey} />
          case gEnums.previewTypes.card:
          case gEnums.previewTypes.header:
          case gEnums.previewTypes.sectionBody:
          case gEnums.previewTypes.sectionHeader:
          case gEnums.previewTypes.profile:
            switch (subKey) {
              case 'description':
              case 'header':
              case 'meta':
                return <GenericProfile formData={formData} itemProp={itemProp} subKey={subKey} />
              default:
                return <GenericCard formData={formData} itemProp={itemProp} />
            }
          default:
            return <div>{'No available form item settings for ' + formItemType}</div>
        }
      // nothing
    }
  }

  return formItem()

}

export default FormItem