import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Segment, Sidebar } from 'semantic-ui-react';
import GoogleSheetsProvider, { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { createTypes } from '../../cnr/reducers/CreateReducer';
import { viewDataModes } from '../../cnr/reducers/DataManagmentReducer';
import { googleModeTypes, googleSheetsTypes } from '../../cnr/reducers/GoogleSheetsReducer';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { appIconTypes } from '../../enums/appIconTypes';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import { createNewViewsAndViewItemsSettingsToDb } from '../../firestoreData/settings/updateSettings';
import SettingsViewer, { settingsViewerTypes } from '../../viewSettings/actions/SettingsViewer';
import { handleOpenDb, openGoogleForm, openGoogleSheets } from '../../viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import ConfirmationQuestion from '../alerts/ConfirmationQuestion';
import PendingWait from '../alerts/pendings/PendingWait';
import { appViewerColors } from './AppDashboard';
import DcUpdatesList from './DcUpdatesList';
import GoogleSheetsTabs from './GoogleSheetsTabs';
import SearchInputForm from './SearchInputForm';

const dataModes = {
  appData: 'appData',
  googleSheets: 'googleSheets',
}

export const GoogleSheetsViewer = (props) => {

  const { noModal, noWizard, fromCreate, fromDataViewer, googleSheetType } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states, settings } = parentContext ? parentContext : {}
  const { paps_state, page_state, googleData_state } = states ? states : {}
  const { pathViews, storageRootPath } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}

  const { homeSettings } = settings ? settings : {}
  const { global: homeSettings_global } = homeSettings ? homeSettings : {}
  const { logging } = homeSettings_global ? homeSettings_global : {}

  const { aps_global, aps_viewItems, aps_views } = pageSettings ? pageSettings : {}
  const { googleSheets, googleSheetsImportOptions, dataOptions, googleForms } = aps_global ? aps_global : {}
  const { googleSheetsId: googleSheetsId_existing } = googleSheets ? googleSheets : {}
  const { dateFormat } = dataOptions ? dataOptions : {}
  const { confirmation: confirmation_gd, updating: updating_gd, } = googleData_state ? googleData_state : {}
  const { googleFormsId, googleFormsResponseId } = googleForms ? googleForms : {}

  const _googleSheetsId = googleSheetType === googleSheetsTypes.registration ? googleFormsResponseId : googleSheetsId_existing

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state, googleSheets_handlers } = googleSheetsContext ? googleSheetsContext : {}

  const {
    confirmation: confirmation_gs,
    confirmationQuestion: _confirmationQuestion,
    dcUpdates,
    googleConfirmationType,
    googleModeType,
    googleUpdateStatus,
    mergedRecreateInfo,
    mergedUpdateInfo,
    previewInfo,
    showConfirmationQuestion,
    showNewOnly,
    updating: updating_gs,
  } = googleSheets_state ? googleSheets_state : {}

  const getMergedSettings = (globalOnly) => {
    const { projectSettings: projectSettings_preview } = previewInfo ? previewInfo : {}
    const { global: global_preview, viewItems: viewItems_preview, views: views_preview } = projectSettings_preview ? projectSettings_preview : {}
    let _pageSettings = {}
    if (globalOnly) {
      _pageSettings = {
        global: { ...global_preview, ...aps_global },
        viewItems: viewItems_preview,
        views: views_preview,
      }
    } else {
      _pageSettings = {
        global: Object.assign({}, global_preview, aps_global),
        viewItems: Object.assign({}, viewItems_preview, aps_viewItems),
        views: Object.assign({}, views_preview, aps_views),
      }
    }
    return _pageSettings
  }

  const [googleSheetsId, setGoogleSheetsId] = useState(_googleSheetsId && (fromDataViewer || (googleModeType === googleModeTypes.previewDirect)) ? _googleSheetsId : null)
  const [activeTab, handleActiveTab] = useState()
  const [viewDataMode, setViewDataMode] = useState()
  const [dataMode] = useState(dataModes.googleSheets)
  const [updating_s, setUpdating_s] = useState()

  const _updating = updating_gd || updating_gs || updating_s
  const _confirmation = confirmation_gd || confirmation_gs

  const { name: tabName } = activeTab ? activeTab : {}

  const caption = 'Data Viewer'

  const callback_settings = async () => {
    // switch (viewDataMode) {
    //   case viewDataModes.recreateDataAndSettings:
    //   case viewDataModes.recreateSettings:
    //     if (previewInfo && previewInfo.projectData && previewInfo.projectData.dataCollections) {
    //       await fsfn_createEventCollectionsInDatabase(pathViews, previewInfo.projectData.dataCollections, true)
    //       setUpdating_s()
    //     }
    //     break;
    //   default:
    //     setUpdating_s()
    //   // nothing
    // }
  }

  useEffect(() => {
    if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
    googleSheets_handlers.handleUpdateOptions(googleSheetsImportOptions, storageRootPath, logging)
    // fsfn_genericCall('permission')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // if (googleFormsId) {
    //   console.log('googleFormsId', googleFormsId)
    //   fsfn_getFormResponse(googleFormsId)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (!fromCreate && _googleSheetsId) {
      if (googleSheetsImportOptions) { googleSheetsImportOptions.dateFormat = dateFormat }
      // pathViews, gsid, googleSheetsImportOptions, logging, forApp
      googleSheets_handlers.handleGetGoogleSheet(_googleSheetsId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_googleSheetsId]);

  useEffect(() => {
    if (previewInfo) {
      googleSheets_handlers.handleMergeSettings(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewInfo]);

  /**
   * handles the save/update from SettingsViewer
   */
  const handleSettingsDbUpdate = () => {
    let ps;

    switch (viewDataMode) {
      case viewDataModes.updateDataCollections:
        handleUpdateDataCollections()
        break;

      case viewDataModes.updateDataCollection:
        handleUpdateDataCollection()
        break;

      case viewDataModes.recreateDataCollection:
        handleRecreateDataCollection()
        break;

      case viewDataModes.recreateDataCollections:
        handleRecreateDataCollections()
        break;

      case viewDataModes.recreateDataAndSettings:
        handleRecreateDataCollections(true, true)
        break;

      case viewDataModes.recreateSettings:
      case viewDataModes.viewSettings:
      case viewDataModes.viewSettingsMerged:

        const { previewInfo } = googleSheets_state ? googleSheets_state : {}

        switch (viewDataMode) {
          case viewDataModes.recreateSettings:
            ps = mergedRecreateInfo
            break;
          case viewDataModes.viewSettings:
            ps = previewInfo
            break;
          case viewDataModes.viewSettingsMerged:
            ps = mergedUpdateInfo
            break;
          default:
          // nothing
        }
        // triggers `updating` for the Wrapper
        setUpdating_s(true)
        const { projectSettings } = ps ? ps : {}
        const { viewItems, views } = projectSettings ? projectSettings : {}
        createNewViewsAndViewItemsSettingsToDb(pathViews, viewItems, views, callback_settings)
        break;

      default:
      // nothing
    }
  }

  const handleCancel = () => googleSheets_handlers.handleGoogleMode(googleModeTypes.none)
  const handleDatabaseOpen = () => handleOpenDb(pathViews, null, tabName)
  // pathViews, gsid, googleSheetsImportOptions, logging, forApp
  const handleGetGoogleSheet = () => googleSheets_handlers.handleGetGoogleSheet(googleSheetsId)
  const handleGoogleChange = (e, data) => setGoogleSheetsId(data.value)
  const handleOpenGoogleSheets = () => openGoogleSheets(googleSheetsId)
  const handleOpenGoogleForms = () => openGoogleForm(googleFormsId)
  const handleViewDataMode = (opts) => setViewDataMode(opts ? opts.viewDataMode : null)
  const handleShowNewOnly = () => googleSheets_handlers.handleShowNewOnly()

  const handleHideSidebar = () => !dcUpdates && handleViewDataMode()

  /**
   * recreates all the data collections
   */
  const handleRecreateDataCollections = (updateSettings, updateStaticViews) => {
    googleSheets_handlers.handleStartUpdate()
    googleSheets_handlers.handleRecreate_dataCollectionsOneByOne(null, updateSettings, updateStaticViews)
  }

  /**
   * recreates the selected data collection
   */
  const handleRecreateDataCollection = () => {
    googleSheets_handlers.handleStartUpdate()
    googleSheets_handlers.handleRecreate_dataCollectionsOneByOne(tabName)
  }

  const handleUpdateDataCollections = (updateSettings, updateStaticViews) => {
    googleSheets_handlers.handleStartUpdate()
    googleSheets_handlers.handleRecreate_dataCollectionsOneByOne(null, updateSettings, updateStaticViews, true, true)
  }

  const handleUpdateDataCollection = () => {
    googleSheets_handlers.handleStartUpdate()
    googleSheets_handlers.handleUpdate_dataCollectionsOneByOne(tabName)
  }

  const confirmationQuestion = () => <ConfirmationQuestion
    {..._confirmationQuestion}
    noModal={true}
  />

  /**
   * 
   * @returns a small from with the input for the google sheets id
   */
  const inputForm = () => <SearchInputForm
    disabled={!googleSheetsId}
    label='Google Sheets Key'
    noSeg={true}
    onChange={handleGoogleChange}
    onClick={handleGetGoogleSheet}
    value={googleSheetsId ? googleSheetsId : ''}
  />

  const topButtons = () => {
    const btns = [
      { disabled: !previewInfo, caption: _.startCase(viewDataModes.updateDataCollections), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.updateDataCollections, commitType: viewDataModes.updateDataCollections } },
      { disabled: !previewInfo, caption: _.startCase(viewDataModes.recreateDataCollections), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.recreateDataCollections, commitType: viewDataModes.recreateDataCollections } },
      { disabled: !previewInfo, caption: _.startCase(viewDataModes.recreateDataAndSettings), oc: handleViewDataMode, icon: appIconTypes.arrowRight, opts: { viewDataMode: viewDataModes.recreateDataAndSettings } },
      { disabled: !previewInfo, caption: _.startCase(viewDataModes.recreateSettings), oc: handleViewDataMode, icon: appIconTypes.settings, opts: { viewDataMode: viewDataModes.recreateSettings } },
      { disabled: !previewInfo, caption: _.startCase(viewDataModes.viewSettings), oc: handleViewDataMode, icon: appIconTypes.settings, opts: { viewDataMode: viewDataModes.viewSettings } },
      { disabled: !previewInfo, caption: _.startCase(viewDataModes.viewSettingsMerged), oc: handleViewDataMode, icon: appIconTypes.settings, opts: { viewDataMode: viewDataModes.viewSettingsMerged } },
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.gsv}
      rows={2}
    />
  }

  const saveButtons = () => {
    let _tabName = _.startCase(tabName)
    if (showNewOnly) { _tabName += ' - New Only' }
    const btns = [
      { caption: _.startCase(viewDataModes.updateDataCollection) + ' (' + _tabName + ')', oc: handleViewDataMode, icon: appIconTypes.arrowRight, disabled: !tabName, opts: { viewDataMode: viewDataModes.updateDataCollection, commitType: viewDataModes.updateDataCollection } },
      { caption: _.startCase(viewDataModes.recreateDataCollection) + ' (' + _tabName + ')', oc: handleViewDataMode, icon: appIconTypes.arrowRight, disabled: !tabName, opts: { viewDataMode: viewDataModes.recreateDataCollection, commitType: viewDataModes.recreateDataCollection } },
    ]
    return <UiSaveButtons
      others={btns}
      color={'blue'}
      rows={2}
    />
  }

  const bottomButtons = () => {
    const btns = [
      { caption: 'Open DB', oc: handleDatabaseOpen, icon: 'database', color: iconColorTypes.external }
    ]
    switch (googleSheetType) {
      case googleSheetsTypes.normal:
        btns.push({ caption: 'Open GS', oc: handleOpenGoogleSheets, icon: appIconTypes.externalAlternate, color: iconColorTypes.external, disabled: !googleSheetsId })
        btns.push({ caption: 'Refresh GS', oc: handleGetGoogleSheet, icon: appIconTypes.refresh, color: iconColorTypes.normal, disabled: !googleSheetsId })
        btns.push({ caption: 'New Only', oc: handleShowNewOnly, icon: appIconTypes.data, color: (showNewOnly ? iconColorTypes.propOn : iconColorTypes.default), disabled: !googleSheetsId })
        break;
      case googleSheetsTypes.registration:
        btns.push({ caption: 'Open GF (' + googleFormsId + ')', oc: handleOpenGoogleForms, icon: appIconTypes.externalAlternate, color: iconColorTypes.external, disabled: !googleFormsId })
        break;
      default:
      // nothing
    }
    return <UiSaveButtons
      others={btns}
      color={'blue'}
      rows={2}
    />
  }

  const tabGs = () => <GoogleSheetsTabs previewInfo={previewInfo} handleActiveTab={handleActiveTab} dcUpdates={dcUpdates} />
  const tabApp = () => <div>TEST</div>

  const tabContent = () => {
    switch (dataMode) {
      case dataModes.googleSheets:
        return previewInfo ? tabGs() : <PendingWait />
      case dataModes.appData:
        return tabApp()
      default:
      // nothing
    }
  }

  const settingsViewerProps = () => {

    let ci;
    let createType = createTypes.updateSettings
    let _createType = fromCreate ? null : settingsViewerTypes.settingsOnly

    switch (viewDataMode) {

      case viewDataModes.updateDataCollections:
        const projDCUs = {
          projectData: mergedRecreateInfo.projectData,
          projectCollection: tabName,
        }
        ci = projDCUs
        _createType = settingsViewerTypes.updateEvent
        createType = createTypes.updateDataCollections
        break;

      case viewDataModes.updateDataCollection:
        const projDCU = {
          projectData: mergedRecreateInfo.projectData,
          projectCollection: tabName,
        }
        ci = projDCU
        _createType = settingsViewerTypes.updateEvent
        createType = createTypes.updateDataCollection
        break;

      case viewDataModes.recreateDataCollection:
        const projDC = {
          projectData: mergedRecreateInfo.projectData,
          projectCollection: tabName,
        }
        ci = projDC
        _createType = settingsViewerTypes.updateEvent
        createType = createTypes.recreateDataCollection
        break;

      case viewDataModes.recreateDataCollections:
        const projA = {
          projectData: mergedRecreateInfo.projectData
        }
        ci = projA
        _createType = settingsViewerTypes.updateEvent
        createType = createTypes.recreateDataCollections
        break;

      case viewDataModes.recreateDataAndSettings:
        const projR = {
          projectSettings: mergedRecreateInfo.projectSettings,
          projectData: mergedRecreateInfo.projectData
        }
        ci = projR
        _createType = settingsViewerTypes.updateEvent
        createType = createTypes.recreateDataAndSettings
        break;

      case viewDataModes.recreateSettings:
        const projU = {
          projectSettings: mergedUpdateInfo.projectSettings,
          projectData: mergedUpdateInfo.projectData
        }
        ci = projU
        _createType = settingsViewerTypes.updateEvent
        createType = createTypes.recreateSettings
        break;

      case viewDataModes.viewSettings:
        ci = previewInfo
        break;

      case viewDataModes.viewSettingsMerged:
        ci = mergedUpdateInfo
        break;

      default:
        ci = previewInfo
    }

    return { _createType, createType, ci }
  }

  const settingsViewer = () => {

    const { _createType, createType, ci } = settingsViewerProps()

    return <SettingsViewer
      actionName={createType}
      createInfo={{ ...ci }}
      createType={_createType}
      ignoreSingleUpdates={true}
      googleUpdateStatus={googleUpdateStatus}
      // handleConfirmClicked={handleShowCommit}
      showNewOnly={showNewOnly}
      handleUpdateClick={handleSettingsDbUpdate}
    />
  }

  const content = () => {
    if (showConfirmationQuestion && !_updating) {
      return confirmationQuestion(googleConfirmationType)
    } else {
      if (fromCreate) {
        return <React.Fragment>
          {((googleModeType === googleModeTypes.preview) || (googleModeType === googleModeTypes.previewDirect) || noModal || noWizard) && inputForm()}
          {previewInfo && settingsViewer()}
        </React.Fragment>
      } else {
        return <React.Fragment>
          {previewInfo && settingsViewer()}
        </React.Fragment>
      }
    }
  }

  const header = () => <div className={'header-flex'}>
    <div></div>
    {topButtons()}
  </div>

  const footer = () => <div className={'header-flex'}>
    {bottomButtons()}
    {saveButtons()}
  </div>

  let cn = 'full-data-viewer'

  const VerticalSidebarRight = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'right'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv-settings'
      onHide={() => handleHideSidebar()}
    >
      {settingsViewer()}
    </Sidebar>
  )

  const dcUpdateList = () => <DcUpdatesList dcUpdates={dcUpdates} />

  const wrapper = () => <Wrapper
    header={header()}
    content={tabContent()}
    footer={!showConfirmationQuestion && !_updating && !fromCreate && footer()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={_updating}
    confirmation={_confirmation}
    confirmationNoModal={fromCreate}
    css={{ container: cn, content: 'google-data-container' }}
  />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <VerticalSidebarRight visible={viewDataMode ? true : false} />
    <Sidebar.Pusher dimmed={viewDataMode ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    handleCancel={handleCancel}
    topperCaption={caption}
    topperCaption2={_googleSheetsId}
    noModal={noModal}
  />

  if (dcUpdates) {
    return dcUpdateList()
  } else {
    return noWizard || fromCreate ? content() : fromDataViewer ? sidebar() : fullPageWrapper()
  }

}

export const GoogleSheetsViewerWithProvider = (props) => {
  return <GoogleSheetsProvider googleSheetsType={props.googleSheetType}>
    <GoogleSheetsViewer {...props} />
  </GoogleSheetsProvider>
}