import _ from 'lodash';

export function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateTime(time) {
  var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
  return isValid;
}

export const isItemReady = (itemProps, itemData) => {
  let itemIsReady = true
  Object.keys(itemProps).forEach(key => {
    if (itemIsReady) {
      const itemProp = itemProps[key]
      if (itemProp.data && itemProp.data.required) {
        if (!itemData[key]) {
          itemIsReady = false
        }
      }
    }
  })
  return itemIsReady
}

export const validatePropValues = (viewProps, formData) => {
  if (viewProps) {
    Object.keys(viewProps).forEach(vpKey => {
      const vp = viewProps[vpKey]
      if (formData && formData[vpKey]) {
        const { data: vpd } = vp
        if (vpd && vpd.camelCase) {
          formData[vpKey] = _.camelCase(formData[vpKey])
        }
      }
    })
  }
}

export const keysEqual = (key1, key2) => parseInt(key1) === parseInt(key2)