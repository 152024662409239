import _ from 'lodash';
import React from 'react';
import { Label, List } from 'semantic-ui-react';
import Checker from '../forms/elements/Checker';
import { getItemName } from '../../common/convert';

/**
 * 
 * @param {*} props (allowed, checkAllow, handleSelect, ignoreKey, linkedItems, linkedKey, menuList, selectedItems, selectedOnly)
 * @returns a List with List.Items
 */
const MenuSelect = (props) => {

  const { allowed, listSize, checkAllow, handleSelect, ignoreKey, linkedItems, linkedKey, menuList, selectedItems, selectedOnly } = props

  /**
   * 
   * @param {object} menuListItem 
   * @param {string} itemKey 
   * @returns 
   */
  const listItem = (menuListItem, itemKey) => {

    const { key, caption, startDate } = menuListItem ? menuListItem : {}

    let label;
    if (linkedItems && linkedItems[key]) {
      const lis = _.filter(linkedItems[key], { selected: true })
      const count = lis.length
      label = <Label color={linkedKey === key ? 'green' : 'blue'} size={'tiny'}>{count}</Label>
    }

    let itemCaption = getItemName(menuListItem)

    // if (firstName && lastName) {
    //   itemCaption = lastName + ', ' + firstName
    // } else if (name) {
    //   itemCaption = name
    // }
    if (!itemCaption) { itemCaption = itemKey }
    if (caption) { itemCaption = caption }

    let checked;

    if (selectedItems) {
      if (_.isArray(selectedItems)) {
        checked = selectedItems && selectedItems.includes(key) ? true : false
      } else {
        checked = selectedItems && selectedItems[key] && selectedItems[key].selected
      }
    }

    let allowListItem = true
    if (selectedOnly) { allowListItem = checked }

    return allowListItem ? <List.Item>
      <List.Content floated='right'>
        {!ignoreKey && <span className='ms-key'>{key}</span>}
        {label}
      </List.Content>
      <Checker item={menuListItem} label={itemCaption} altLabel={startDate} handleClick={handleSelect} checked={checked} checkAllow={checkAllow} allowed={allowed} />
    </List.Item> : null
  }

  const listItems = () => {
    const elements = []
    if (menuList) {
      Object.keys(menuList).forEach(key => {
        const menuListItem = menuList[key]
        if (_.isObject(menuListItem)) {
          elements.push(listItem(menuListItem, key))
        }
      })
      return elements
    }
  }

  const list = () => <List divided size={listSize ? listSize : null} verticalAlign='middle'>{listItems()}</List>

  return list()

}

export default MenuSelect