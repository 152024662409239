import React, { useContext, useState } from 'react'
import { List, ListItem, Segment } from 'semantic-ui-react'
import { PageContext } from '../../../cnr/contexts/PageContext'
import { uniqueKey } from '../../../common/keys'
import MapInputContent from './MapInputContent'

const MapListData = (props) => {

  const { listData, setListData } = props

  // pageContext
  const pageContext = useContext(PageContext);
  const { aps_global } = pageContext
  const { settingsOptions } = aps_global ? aps_global : {}
  let { useDarkMode } = settingsOptions ? settingsOptions : {}
  // useDarkMode = false

  const [newItemData] = useState({ key: null, text: null, value: null })
  const [selectedKey, setSelectedKey] = useState(-1)

  const listCount = listData ? Object.keys(listData).length : 0

  const updateList = (lineData) => {
    const id = { ...listData }
    if (selectedKey === 'new') {
      id[lineData.key] = {
        text: lineData.text,
        value: lineData.value
      }
    } else {
      id[selectedKey] = {
        text: lineData.text,
        value: lineData.value
      }
    }
    setListData(id)
    setSelectedKey(-1)
  }

  const removeFromList = () => {
    const id = { ...listData }
    delete id[selectedKey]
    setListData(id)
  }

  const selectItem = (lineKey) => {
    if (lineKey === selectedKey) {
      setSelectedKey(-1)
    } else {
      setSelectedKey(lineKey)
    }
  }

  const lineContent = (lineKey, lineData) => {
    if (lineData.text) {
      return lineKey + ' (' + lineData.text + ')'
    } else {
      return lineKey
    }
  }

  const lineItem = (index, lineKey, lineData, icon, isNew) => {
    const cn = lineKey === selectedKey ? 'line-s' : 'line-h'
    return <ListItem key={uniqueKey('mld', 'li', index)} >
      <List.Icon name={icon} size={'large'} color={'blue'} onClick={() => { selectItem(lineKey) }} />
      <List.Content onClick={() => { selectItem(lineKey) }}>
        {lineContent(lineKey, lineData)}
      </List.Content>
      <List.Content className={cn}>
        <MapInputContent lineKey={lineKey} lineData={lineData} updateList={updateList} removeFromList={removeFromList} isNew={isNew} />
      </List.Content>
    </ListItem>
  }

  const listItems = () => {
    const lis = []
    Object.keys(listData).forEach((key, index) => {
      const item = listData[key]
      if (key !== 'key') {
        const icon = (key === selectedKey) ? 'arrow circle down' : 'arrow circle right'
        lis.push(lineItem(index, key, item, icon))
      }
    })
    return lis
  }

  const list = () => {
    const lis = listData ? listItems() : []
    lis.push(lineItem(listCount + 1, 'new', newItemData, 'add', true))
    return <List divided relaxed inverted={useDarkMode} >{lis}</List>
  }

  return (
    <Segment basic className={'map-list-data padd10'} inverted={useDarkMode}>
      {list()}
    </Segment>
  )
}

export default MapListData