import React, { createContext } from 'react';

/**
@provides handleBlur
@provides handleCalOpen
@provides handleDataLabelAdd
@provides handleDataLabelClick
@provides handleDataLabelRemoveClick
@provides handleHeaderBackClick
@provides handleHeaderBreadcrumbClick
@provides handleLoadData
@provides handleMapOpen 
@provides handleUploadOpen
@provides handleSwipeChangeIndex
*/
export const DataModifyContext = createContext();

const DataModifyProvider = (props) => {
  return <DataModifyContext.Provider value={{ ...props }}>
    {props.children}
  </DataModifyContext.Provider>
};

export default DataModifyProvider 