import _ from 'lodash'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { Icon, Message } from 'semantic-ui-react'
import { ParentContext } from '../../../global/cnr/contexts/ParentContext'
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../../../global/cnr/reducers/SidebarReducer'
import { getFirstObjectKey } from '../../../global/common/convert'
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons'
import { appUserLevels } from '../../../global/enums/globalEnums'
import MenuSidebars from '../../../global/sidebars/MenuSidebars'
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper'
import TicketingProvider, { TicketingContext } from '../cnr/contexts/TicketingContext'
import { scannedTicketsStatusTypes } from '../cnr/reducers/TicketingReducer'
import { ticketTypes } from './SingleTicket'
import TicketInfo from './TicketInfo'
import TicketingLookup from './TicketingLookup'

const alertCount = 2000

const sidebarType = 'ticketingScanner'

const AppTicketingScanner = (props) => {

  const parentContext = useContext(ParentContext);
  const { handlers, fns } = parentContext ? parentContext : {}
  const { appUser_fns } = fns
  const { navigate } = handlers

  const allowMods = appUser_fns ? appUser_fns.validateAccess_item(appUserLevels.admin) : false

  // ticketingContext
  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state, ticketing_handlers } = ticketingContext ? ticketingContext : {}
  const { scannedInfo, assignedTickets, selectedLookupTicket, showLookup, showScanner, appTicketing, tickets } = ticketing_state ? ticketing_state : {}
  const { allowInOut } = appTicketing ? appTicketing : {}
  const { allowIn, allowOut, scannedData, scannedTicket, scannedTicketStatus, ticketUserKey } = scannedInfo ? scannedInfo : {}

  const { ticketKey: ticketKey_scanned, subTicketKey: subTicketKey_scanned } = scannedData ? scannedData : {}
  // const { name } = actualTicket ? actualTicket : {}

  // sidebar_state 
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ? sidebar_state : {}
  const { setInits } = sidebar_handlers ? sidebar_handlers : {}

  const { ms_tickets } = sidebar_items ? sidebar_items : {}
  const { selected: selected_ticket } = ms_tickets ? ms_tickets : {}
  const { item: item_ticket } = selected_ticket ? selected_ticket : {}
  const _ticket = tickets ? _.pickBy(tickets, { name: item_ticket }) : null
  const _ticketKey = _ticket ? getFirstObjectKey(_ticket) : null

  const [showMessage, setShowMessage] = useState()

  useEffect(() => {
    if (tickets) {
      let ticketKeys = []
      Object.keys(tickets).forEach(key => {
        ticketKeys.push(tickets[key].name)
      })
      ticketKeys = ticketKeys.sort()
      setInits([{ smt: sidebarMenuTypes.one, items: ticketKeys, currents, as: 'tickets', useStartCase: true }])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [tickets]);

  useEffect(() => {
    if (_ticketKey) {
      ticketing_handlers.handleSelectedLookupTicket(_ticketKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [assignedTickets, _ticketKey]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage()
      }, alertCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [showMessage]);

  const handleCancel = () => props.handleCancel ? props.handleCancel() : navigate(-1)
  const handleCredit = () => ticketing_handlers.handleUpdateWillCallTicket(ticketKey_scanned, subTicketKey_scanned, ticketUserKey, true)
  const handleDebit = () => ticketing_handlers.handleUpdateWillCallTicket(ticketKey_scanned, subTicketKey_scanned, ticketUserKey, false)
  const handleScan = (data) => ticketing_handlers.handleScan(data)
  const handleError = err => console.error(err)

  /** The reader */
  const reader = () => <QrReader
    delay={300}
    onError={handleError}
    onResult={handleScan}
    style={{ width: '100%' }}
  />

  const ticketStatus = () => {
    const { ticketKey, subTicketKey } = scannedTicket ? scannedTicket : {}
    switch (scannedTicketStatus) {
      case scannedTicketsStatusTypes.nonEvent:
        return <Message
          icon='ban'
          header='Invalid Event Pass'
          content='The ticket presented is NOT VALID for this event.'
          color={'red'}
        />
      case scannedTicketsStatusTypes.valid:
        return <Message>
          <Message.Header><Icon name='check circle' />{'Valid Pass'}</Message.Header>
          <Message.List>
            <Message.Item>{'Pass Key: '}{ticketKey}</Message.Item>
            <Message.Item>{'Sub Pass Key: '}{subTicketKey}</Message.Item>
          </Message.List>
        </Message>
      case scannedTicketsStatusTypes.entered:
        return <Message
          icon='check'
          header='Valid Pass'
          content='The pass presented is valid for this event, but has already been used.'
          color={'yellow'}
        />
      default:
        break;
    }
  }

  const scannerContent = () => {
    if (scannedData) {
      return <div className={'scanned-status'} onClick={(e) => { ticketing_handlers.handleKillScannedInfo() }}>{ticketStatus()}</div>
    } else if (showScanner) {
      return reader()
    } else {
      return <div>---</div>
    }
  }

  const readerContent = () => <div className={'ticket-scanner'}>
    <TicketInfo ticket={selectedLookupTicket} ticketType={ticketTypes.infoOnly} />
    {scannerContent()}
  </div>

  const footer = () => {
    if (scannedData) {
      return <UiSaveButtons
        save={{ caption: 'In', oc: handleDebit, icon: 'arrow circle up', disabled: !allowIn }}
        preview={{ caption: 'Out', icon: allowInOut ? 'arrow circle down' : 'ban', oc: handleCredit, disabled: !allowOut }}
      />
    } else {
      return <UiSaveButtons
        save={{ caption: showScanner ? 'Turn Off' : 'Turn On', oc: ticketing_handlers.handleShowScanner, icon: showScanner ? 'delete' : 'check', disabled: !allowMods }}
        preview={{ oc: ticketing_handlers.handleShowLookup, caption: 'Lookup', icon: 'search plus', disabled: showScanner }}
      />
    }
  }

  /** wrapper for the reader */
  const wrapperReader = () => <Wrapper
    content={readerContent()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={wrapperReader()}
    dimmed={dimmed}
    style={{ background: 'black' }}
  />

  /** Wizard for the reader */
  const stepWizard_reader = () => <FullPageWrapper
    content={tickets && sidebar_items ? menuSidebars() : <div>---</div>}
    handleCancel={handleCancel}
    topperCaption={'Scanner'}
  />


  if (showLookup) {
    return <TicketingLookup ticketing_state={ticketing_state} ticketing_handlers={ticketing_handlers} />
  } else {
    return stepWizard_reader()
  }

}

const AppTicketingScannerWithProvider = (props) => <TicketingProvider>
  <AppTicketingScanner {...props} />
</TicketingProvider>

export default AppTicketingScannerWithProvider