import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { Icon, Label, Segment } from 'semantic-ui-react'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { gEnums } from '../enums/globalEnums'
import TargetConfirmation from './TargetConfirmation'

const TopicGroupSelect = (props) => {

  const { selectedTopicGroups, appProfileData, handleTargetSelect } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { eventInfo_state, page_state } = states
  const { staticViews } = eventInfo_state ? eventInfo_state : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}

  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedItem, setSelectedItem] = useState()

  const handleOpen_group = (group, item) => setSelectedGroup(group === selectedGroup ? null : group)
  const handleOpen_item = (group, item) => setSelectedItem(item === selectedItem ? null : item)

  const handleSelect_group = (groupKey) => {
    const selected = selectedTopicGroups[groupKey] ? true : false
    return <Label size='tiny' color={selected ? 'green' : 'grey'}
      onClick={(e) => {
        e.stopPropagation()
        handleTargetSelect && handleTargetSelect({ groupKey })
      }}>
      Select
    </Label>
  }

  const handleSelect_item = (groupKey, itemKey) => {
    const selected = selectedTopicGroups[groupKey + '_' + itemKey] ? true : false
    return <Label size='tiny' color={selected ? 'green' : 'grey'}
      onClick={(e) => {
        e.stopPropagation()
        handleTargetSelect && handleTargetSelect({ groupKey, itemKey })
      }}>
      Select
    </Label>
  }

  const itemTopic = (item) => {
    const divs = []
    const { targets } = item ? item : {}
    if (targets) {
      divs.push(<TargetConfirmation currentTargets={targets} />)
    }
    return divs
  }

  // handleSelectedGroup, selectGroupButton
  const segHeader = (cpKey, itemKey, name, count, onLabelClick, onSelectClick) => <Segment className={'header-button-select'} >
    <Label color={'blue'} size={'small'} inverted bordered onClick={(e) => { onLabelClick(cpKey, itemKey) }}>
      <Icon name={selectedItem === itemKey ? 'arrow down' : 'arrow right'} />{count}
    </Label>
    <div>{name}</div>
    {handleTargetSelect && onSelectClick(cpKey, itemKey)}
  </Segment>

  const getHeaderTitle = (cpKey, itemKey) => {
    const staticView = staticViews ? staticViews[cpKey] : null
    const staticItem = staticView ? staticView[itemKey] : null
    const { name, lastName, firstName } = staticItem ? staticItem : {}
    let _name = lastName && firstName ? firstName + ' ' + lastName : name
    if (!_name) { _name = itemKey }
    return _name
  }

  const itemTopics = (cpKey, items) => {
    const segs = []
    if (items)
      switch (cpKey) {
        case gEnums.profileTypes.adminProfiles:
        case appUserCollection:
          selectedGroup === cpKey && segs.push(<TargetConfirmation currentTargets={items} />)
          break;
        default:
          Object.keys(items).forEach(itemKey => {
            const _name = getHeaderTitle(cpKey, itemKey)
            const count = items && items[itemKey] && items[itemKey].targets ? Object.keys(items[itemKey].targets).length : 0
            segs.push(<Segment.Group className={'target-group-container'}>
              {segHeader(cpKey, itemKey, _name, count, handleOpen_item, handleSelect_item)}
              <Segment style={{ display: selectedItem === itemKey ? 'block' : 'none' }}>{itemTopic(items[itemKey])}</Segment>
            </Segment.Group>)
          })
      }
    return segs
  }

  const create_allTopics = () => {
    const segs = []
    if (appProfileData) {
      Object.keys(appProfileData).forEach(cpKey => {
        let isSingle;
        let count;
        switch (cpKey) {
          case gEnums.profileTypes.adminProfiles:
          case appUserCollection:
            isSingle = true
            count = appProfileData && appProfileData[cpKey] && appProfileData[cpKey] ? Object.keys(appProfileData[cpKey]).length : 0
            break;
          default:
            isSingle = false
        }
        segs.push(<Segment.Group className={'target-group-container'}>
          {isSingle && segHeader(cpKey, null, _.startCase(cpKey), count, handleOpen_group, handleSelect_group)}
          {!isSingle && <Segment>{_.startCase(cpKey)}</Segment>}
          <Segment>{itemTopics(cpKey, appProfileData[cpKey])}</Segment>
        </Segment.Group>)
      })
    }
    return segs
  }

  return <div className={'topic-groups'}>
    {create_allTopics()}
  </div>

}

export default TopicGroupSelect