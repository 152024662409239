import React from 'react';
import DragDropper, { dragDropperTypes } from './DragDropper';
import DragDropperr from './DragDropperr';

const useDdp = false

const DragDropSimple = (props) => {

  const { cn_portal, groupChangeKey, positionProp, ddGroups, ddGroupsP, handleUpdateGroupItemChange } = props

  //sortedGroups is a group of objects with an array as the value

  const handleSorted = (ddg) => handleUpdateGroupItemChange(ddg)

  const getDropDownDropperGroups = () => {
    const ddg = {}
    if (useDdp) {
      Object.keys(ddGroupsP).forEach(pk => {
        const ddGroupP = ddGroupsP[pk]
        ddGroupP.forEach(ddGroup => {
          const { groupKey, dataItems, elements, caption } = ddGroup ? ddGroup : {}
          const hdd = <div>{caption}</div>  // <HeaderDropdown key={uniqueKey('vsm', 'gc', 'hdd')} opts={{ groupKey: key, iconSize: 'small' }} />
          ddg[groupKey] = { dataItems, elements, hdd }
        })
      })
    } else {
      Object.keys(ddGroups).forEach(groupKey => {
        const ddGroup = ddGroups[groupKey]
        const { dataItems, elements } = ddGroup ? ddGroup : {}
        const hdd = null // <HeaderDropdown key={uniqueKey('vsm', 'gc', 'hdd')} opts={{ groupKey: key, iconSize: 'small' }} />
        ddg[groupKey] = { dataItems, elements, hdd }
      })
    }

    return ddg
  }

  const _ddGroups = getDropDownDropperGroups()
  // return <DragDropperr />

  return <DragDropper
    ddType={dragDropperTypes.settingsGroups}
    ddGroups={_ddGroups}
    handleSorted={handleSorted}
    groupChangeKey={groupChangeKey}
    allowSort={true}
    portalCn={cn_portal}
    positionProp={positionProp}
    headerColor={'grey'}
  />

}

export default DragDropSimple