import React, { useContext, useEffect, useState } from 'react';
import { getFormPropz } from '../../../../global/components/forms/props/formPropz';
import FormForm from '../../../../global/components/forms/FormForm';
import { AppFormsContext } from '../../../../global/cnr/contexts/AppFormsContext';
import { CreateSportsContext } from '../CreateSports';

const CreateSeasonDetails = (props) => {

  const { appFormType } = props

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state, createSports_handlers } = createSportsContext ? createSportsContext : {}
  const { sportsDetails } = createSports_state ? createSports_state : {}

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state, appForms_fns } = appFormsContext ? appFormsContext : {}
  const { appForms } = appForms_state ? appForms_state : {}
  const { getForms } = appForms_fns ? appForms_fns : {}

  const [formProps, setFormProps] = useState()

  const handleLocalPropChange = (data_form) => {
    createSports_handlers && createSports_handlers.handleSeasonDetails(data_form)
  }

  const form_handlers = { handleFormDataChange_local: handleLocalPropChange }

  useEffect(() => {
    if (appForms && appForms[appFormType]) {
      const vprs = appForms[appFormType]
      const fpp = { form_handlers, viewProps: vprs, data_current: sportsDetails }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
    } else {
      getForms()
    }
    // eslint-disable-next-line 
  }, [sportsDetails]);

  return formProps ? <FormForm
    formAttributes={{ autoComplete: "off" }}
    formProps={formProps}
  /> : <div></div>
}

export default CreateSeasonDetails