import React, { createContext, useReducer } from 'react';
import { previewHandlers, previewInitialState, previewReducer } from '../reducers/SettingsPreviewReducer';

export const PreviewContext = createContext();

/**
 * 
 * @param {object} props 
 * @returns - {preview_state, preview_handlers }
 */
const PreviewProvider = (props) => {

  const view_init = {}
  const [preview_state, settingsPreview_dispatch] = useReducer(previewReducer, view_init, previewInitialState)
  const preview_handlers = previewHandlers(settingsPreview_dispatch, preview_state)

  const providerContext = () => <PreviewContext.Provider value={{ preview_state, preview_handlers }}>
    {props.children}
  </PreviewContext.Provider>

  return providerContext()
}

export default PreviewProvider