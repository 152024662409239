import React from 'react';
import { Icon } from 'semantic-ui-react';

/** Create a Pdf Icon */
const SemPdfSimple = (props) => {

  const { fileUrl, inGrid } = props

  const handleOpenPdf = () => {
    window.open(fileUrl, '_blank')
  }

  return inGrid ?
    <Icon size={'huge'} name='file pdf outline' onClick={(e) => handleOpenPdf()} />
    :
    <div>
      <Icon size={'huge'} name='file pdf outline' onClick={(e) => handleOpenPdf()} />
    </div>
}

export default SemPdfSimple