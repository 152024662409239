import React, { useContext } from 'react';
import FrameworkProvider from '../cnr/contexts/FrameworkContent';
import { ParentContext } from "../cnr/contexts/ParentContext";
import { g_cns } from '../common/cns';
import { gEnums } from "../enums/globalEnums";
import PageFramework from "./PageFramework";
import CreateFeedEvent from '../viewSettings/events/CreateFeedEvent';

// const CreateFeedEvent = React.lazy(() => import("../viewSettings/events/CreateFeedEvent"));

// react-lorem-ipsum
// https://www.framer.com/motion

// const AppMedia = createMedia({
//   breakpoints: {
//     mobile: 320,
//     tablet: 768,
//     computer: 992,
//     largeScreen: 1200,
//     widescreen: 1920
//   }
// });

// const mediaStyles = AppMedia.createMediaStyle();
// const { Media, MediaContextProvider } = AppMedia;

/**
 * 
 * @returns PageFramework within a FrameworkProvider
 */
const PageDevice = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { appSettings_state, appUser_state, paps_state } = states

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const { appUserAccess } = appUser ? appUser : {}

  // appSettings_state 
  let { desktopOn } = appSettings_state ? appSettings_state : {}

  // paps_state 
  const { isMobileDevice, view } = paps_state ? paps_state : {}
  const ima = isMobileDevice ? 'm' : 'd'

  const frameworkContent = (mediaDeviceType) => {
    const mp = { addSettings: false, mediaDeviceType, hasSettings: true, isMobileDevice }
    return <FrameworkProvider {...mp}  >
      <PageFramework />
    </FrameworkProvider>
  }

  const mobileOrDesktop = () => {
    let rcn = g_cns.page_area_content + ' au'
    rcn += desktopOn ? ' dt-on' : ' dt-off'
    rcn += ' ima-' + ima
    // determine the width of the appSidebar by the user's accessLevel
    if (appUserAccess) { rcn += ' apl-' + appUserAccess.accessLevel }
    return isMobileDevice ?
      <div className={rcn}>
        <div className={g_cns.page_area_container + ' pg-' + paps_state.view} >
          {frameworkContent(gEnums.mediaDeviceTypes.mobile)}
        </div>
      </div>
      :
      <div className={rcn}>
        <div className={g_cns.page_area_container + ' pg-' + paps_state.view} >
          {frameworkContent(gEnums.mediaDeviceTypes.desktop)}
        </div>
      </div>
  }

  switch (view) {
    case 'createFeedEvent':
      return <CreateFeedEvent />
    default:
      return mobileOrDesktop()
  }
}

export default PageDevice 