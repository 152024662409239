import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Segment, Sidebar } from 'semantic-ui-react'
import UiSaveButtons from '../../components/buttons/UiSaveButtons'
import { DataContext } from '../../cnr/contexts/DataContext'
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent'
import { ParentContext } from '../../cnr/contexts/ParentContext'
import { uniqueKey } from '../../common/keys'
import { updateViewViewItemSettingsDB } from '../../firestoreData/settings/updateSettings'
import PageItem from '../../page/PageItem'
import AppSettingsSidebarWithProvider, { settingsMenuTypes } from '../../viewSettings/AppSettingsSidebar'
import FullPageWrapper from '../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper'
import { appViewerColors } from './AppDashboard'

const viewSettingsModes = {
  none: 'none',
  display: 'display',
  grouping: 'grouping',
  images: 'images',
  props: 'props',
  viewJson: 'viewJson',
}

export const SettingsViewerContext = createContext();

export const FullSettingsViewer = () => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state } = states ? states : {}
  const { pathViews, view, viewKey } = paps_state ? paps_state : {}

  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers, framework_state } = frameworkContext ? frameworkContext : {}
  const { propMode } = framework_state ? framework_state : {}

  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ? dataContext : {}
  const { uivi: name, viewItem } = data_state ? data_state : {}

  const [viewSettingsMode, setViewSettingsMode] = useState();
  const [viewItem_preview, setViewItemPreview] = useState();
  const [viewItemChanged, setViewItemChanged] = useState();
  const [updating, setUpdating] = useState()

  useEffect(() => {
    setViewItemPreview({ ...viewItem })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewItem]);

  const callback = (result) => setUpdating()

  const handleUpdateViewItem = (propKey) => {
    const _viewItemPreview = { ...viewItem_preview }
    _viewItemPreview.props[propKey].pending = !_viewItemPreview.props[propKey].pending
    setViewItemPreview(_viewItemPreview)
    setViewItemChanged(true)
  }

  // const handleFormUpdate = (data, save) => {
  //   const _viewItemPreview = { ...viewItem_preview }
  //   _viewItemPreview[viewSettingsMode] = data
  //   setViewItemPreview(_viewItemPreview)
  //   if (save) {
  //     setUpdating(true)
  //     updateViewViewItemSettingsDB(pathViews, viewItem_preview, viewKey, callback)
  //   }
  // }

  const handleViewSettingsMode = (opts) => {
    setViewSettingsMode(opts ? opts.viewSettingsMode : null)
    // if (opts && !opts.viewSettingsMode) { setViewItemPreview() }
  }

  const handlePropMode = () => framework_handlers.handlePropMode()
  const handleSave = () => updateViewViewItemSettingsDB(pathViews, view, viewItem_preview)

  const pageItem = () => {
    let cn = 'item-content'
    if (propMode) { cn += ' prop-mode' }
    return <div className={'app-container mcn top dt-mode'}>
      <div className={'app-menu'}></div>
      <div className={'app-content'}>
        <div className={'item-container hcp'}>
          <div className={cn}>
            <PageItem key={uniqueKey('pcpic', name)} uivi={name} caption={name} viewItemPreview={viewItem_preview} />
          </div>
        </div>
      </div>
    </div>
  }

  const header = () => {
    const btns = [
      { caption: _.startCase(viewSettingsModes.viewJson), oc: handleViewSettingsMode, icon: 'settings', opts: { viewSettingsMode: viewSettingsModes.viewJson } },
      { caption: 'Display', icon: 'cog', oc: handleViewSettingsMode, opts: { viewSettingsMode: viewSettingsModes.display } },
      { caption: 'Group', icon: 'cog', oc: handleViewSettingsMode, opts: { viewSettingsMode: viewSettingsModes.grouping } },
      { caption: 'Images', icon: 'cog', oc: handleViewSettingsMode, opts: { viewSettingsMode: viewSettingsModes.images } },
      { caption: 'Props', icon: 'cog', oc: handlePropMode }
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.fsv}
      rows={2}
    />
  }

  const footer = () => <UiSaveButtons
    save={{ oc: handleSave, disabled: !viewItemChanged }}
    rows={2}
  />

  const viewerContent = () => {
    let cn = 'full-data-viewer sett'
    return <Wrapper
      header={header()}
      content={pageItem()}
      footer={footer()}
      wrapperType={wrapperTypes.paddedHeaderAndFooter}
      css={{ container: cn }}
      updating={updating}
    />
  }

  const VerticalSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'right'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='sidebar-fdv dark-mode'
      onHide={() => { handleViewSettingsMode() }}
    >
      {viewSettingsSidebar()}
    </Sidebar>
  )

  const viewSettingsSidebar = (isGlobal) => <AppSettingsSidebarWithProvider isGlobal={isGlobal} settingsMenuType={settingsMenuTypes.fullSettingsView} />

  const sidebar = () => {
    return <Sidebar.Pushable style={{ overflow: 'hidden' }}>
      <VerticalSidebar visible={viewSettingsMode ? true : false} />
      <Sidebar.Pusher dimmed={viewSettingsMode ? true : false} className={'h100'}>
        {viewerContent()}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  }

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    topperCaption={'Settings Views'}
    topperCaption2={_.startCase(name)}
  />

  return viewItem_preview ? <SettingsViewerContext.Provider value={{ settingsViewer_state: { viewItem_preview }, settingsViewer_handlers: { handleUpdateViewItem } }}>
    {fullPageWrapper()}
  </SettingsViewerContext.Provider> : <div></div>
} 