import React, { createContext, useContext, useReducer } from 'react';
import { transferHandlers, transferReducer, transferReducerInitialState } from '../reducers/TransferReducer';
import { ParentContext } from './ParentContext';

/** AppContext */
export const TransferContext = createContext();

/** Global OtherDatas for the Event */
const TransferProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state } = states
  const { pathViews } = paps_state ? paps_state : {}

  const initState = transferReducerInitialState({ currentFirestores: {}, formData: { userName: 'philipaokeeffe@gmail.com' }, pathViews })
  const [tranfer_state, transfer_dispatch] = useReducer(transferReducer, initState);
  const transfer_handlers = transferHandlers(transfer_dispatch, tranfer_state)

  const providerContext = () => <TransferContext.Provider
    value={{ tranfer_state, transfer_handlers }}>
    {props.children}
  </TransferContext.Provider>

  return providerContext()
}

export default TransferProvider