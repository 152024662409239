import _ from 'lodash';
import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { eventStatusIcon } from '../../../components/props/propsCreate';

const StatusLabels = (props) => {

  const { itemData } = props

  const label = (index, value, iconName, iconColor) => <Label key={uniqueKey('tl', 'sl', index)} >
    <Icon color={iconColor} name={iconName} />{value ? value : '---'}
  </Label>

  const statusLabels = () => {
    const { startDate, endDate, useClientLandingPage, eventStatusType } = itemData
    const { color, name } = eventStatusIcon(itemData, null, true)
    const sls = [
      label(1, _.startCase(eventStatusType), name, color),
      label(2, useClientLandingPage ? 'Client' : 'Event', 'home', useClientLandingPage ? 'blue' : 'green'),
      label(3, startDate, 'calendar check outline', 'green'),
      label(4, endDate, 'calendar times outline', 'red'),
      // label(6, _itemKey, 'key')
    ]
    return sls
  }

  return <div className={'df-r2-c2'}>{statusLabels()}</div>

}

export default StatusLabels