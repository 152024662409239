import { EmailAuthProvider, fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import _ from 'lodash';
import { gEnums } from "../../../enums/globalEnums";
import { signInMethodTypes, signInResponseTypes } from "../SignInReducer";

/**
 * 
 * @param {boolean} success 
 * @param {string} title 
 * @param {object} content 
 * @param {object} additionalContent 
 * @param {string} icon 
 * @param {enum} signInMethodType 
 * @returns 
 */
const signInResponse = (success, title, content, additionalContent, icon, signInMethodType) => {
  return {
    success,
    title,
    content,
    additionalContent,
    icon: icon ? icon : 'user',
    signInMethodType,
  }
}

const ivp = () => {
  return 'Passwords must contain at least one number, one lowercase and one uppercase letter. The password must also be at least eight characters in length.'
}

export const signInResponseData = (profileData, authData, authProviders, signInResponseType, authCode, confirmationResult, error) => {
  const d = {
    signInResponseType: signInResponseType ? signInResponseType : signInResponseTypes.profileFound,
    profileData_signIn: profileData,
    hasAuth: authData && !_.isEmpty(authData) ? true : false,
    hasProfile: profileData && !_.isEmpty(profileData) ? true : false,
    updating: false,
    authProviders,
    authCode: authCode,
    confirmationResult,
    error,
  }
  return d
}

export const updateSignInResponse = (profileData, authData, action, callback) => {

  console.log('profileData', profileData)
  console.log('authData', authData)

  const { email: email_profile } = profileData ? profileData : {}
  const { email: email_auth, providerData } = authData ? authData : {}
  const { opts } = action ? action : {}
  const { error } = opts ? opts : {}

  const _email = email_profile ? email_profile : email_auth

  const authProviders = {
    [gEnums.signInProviderTypes.emailPassword]: false,
    [gEnums.signInProviderTypes.emailLink]: false,
    [gEnums.signInProviderTypes.phoneNumber]: false,
  }

  if (providerData) {
    providerData.forEach(pdi => {
      if (pdi.providerId === 'phone') {
        authProviders[gEnums.signInProviderTypes.phoneNumber] = true
      }
    })
  }

  if (authData && _email) {

    const auth = getAuth()
    // we have found a profile
    // see if the already have an account, and if so, how did they log in
    fetchSignInMethodsForEmail(auth, _email).then((signInMethods) => {
      // This returns the same array as fetchProvidersForEmail but for email provider identified by 'password' string, signInMethods would contain 2 different strings:
      // A user could have both. 
      if (signInMethods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
        // 'password' if the user has a password.
        // User can sign in with email/password.  
        authProviders[gEnums.signInProviderTypes.emailPassword] = true
      }
      if (signInMethods.includes(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD)) {
        // 'emailLink' if the user previously signed in with an email/link
        // User can sign in with email/link. 
        authProviders[gEnums.signInProviderTypes.emailLink] = true
      }
      // if (signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !== -1) {
      //   // 'emailLink' if the user previously signed in with an email/link
      //   // User can sign in with email/link. 
      //   authProviders[gEnums.signInProviderTypes.emailLink] = true
      // }
      callback(signInResponseData(profileData, authData, authProviders))
    })
      .catch((error) => {
        const { code } = error ? error : {}
        switch (code) {
          case 'auth/invalid-email':
          case 'auth/missing-identifier':
            callback(signInResponseData(null, null, null, signInResponseTypes.invalidEmail))
            break;
          default:
            callback(signInResponseData(null, null, null, signInResponseTypes.unknownError))
            break;
        }
        // Some error occurred, you can inspect the code: error.code
      });
    // dispatchSignIn(dispatch, result_profile, null, null, success_auth)
  } else {
    if (profileData) {
      callback(signInResponseData(profileData))
      // callback(signInResponseData(null, null, null, error ? error : signInResponseTypes.profileFound))
    } else {
      callback(signInResponseData(null, null, null, error ? error : signInResponseTypes.profileNotFound))
    }
  }
}


/**
 * 
 * @param {object} responseData 
 * @param {object} state 
 * @param {object} profileData_signIn 
 * @param {object} appSignIn  
 * @returns {success, title, content, additionalContent, icon, signInMethodType}
 */
export const getSignInResponse = (responseData, state, profileData_signIn, appSignIn) => {

  const { signInResponseType, currentUser, error, authCode, email: email_rd } = responseData
  const { code: errorCode } = error ? error : {}

  const { creds } = state ? state : {}
  const { email } = creds ? creds : {}
  let { displayName, appUserType } = profileData_signIn ? profileData_signIn : {}
  const { allowNewEventPasswordReplace } = appSignIn ? appSignIn : {}

  let _email = authCode ? authCode : email
  if (!_email && email_rd) { _email = email_rd }

  if (!displayName) { displayName = currentUser && currentUser.displayName ? currentUser.displayName : null }
  if (!displayName && profileData_signIn) { displayName = profileData_signIn.displayName }

  console.log('signInResponseType', signInResponseType)

  switch (signInResponseType) {

    case signInResponseTypes.confirmation:
      return signInResponse(
        true,
        'Confirmed',
        'Confirmed.')

    case signInResponseTypes.signInPending:
      return signInResponse(
        true,
        'Please wait',
        'We are updating your profile.')

    case signInResponseTypes.invalidEmail:
      return signInResponse(
        false,
        'Invalid Email',
        'The email `' + _email + '` is not a valid email.')

    case signInResponseTypes.invalidPassword:
      return signInResponse(
        false,
        'Invalid Password',
        'The password is not a valid password. ' + ivp())

    case signInResponseTypes.phoneCodeSent:
      return signInResponse(
        true,
        'Phone Code Sent',
        'A code has been sent to your phone number at ' + creds.phoneNumber,
        'Please check your phone and follow the instructions to sign in.')

    case signInResponseTypes.phoneCodeNotSent:
      return signInResponse(
        true,
        'Phone Code Not Sent',
        'A code has NOT been sent to ' + creds.phoneNumber,
        errorCode ? errorCode : 'Unknown Error')

    case signInResponseTypes.emailLinkSent:
      return signInResponse(
        true,
        'Email sent',
        'An email has been sent to your email account at ' + _email + '. Please check your email and follow the instructions to sign in.')

    case signInResponseTypes.emailLinkNotSent:
      return signInResponse(
        true,
        'Email has been not been sent',
        'An email has been sent to your email account at ' + _email + '. Please follow the instructions to reset your password.')

    case signInResponseTypes.emailResetSent:
      return signInResponse(
        true,
        'Email has been sent',
        'An email has been sent to your email account at ' + _email + '. Please follow the instructions to reset your password. Please check you span folder if you do not see this email in your normal inbox.')

    case signInResponseTypes.emailResetNotSent:
      return signInResponse(
        false,
        'Email has not been sent',
        'An email was attempted to be to your email address at `' + _email + '`, but the email does not exist in the system.')

    case signInResponseTypes.incorrectPassword:
      return signInResponse(
        false,
        'Sign In Error',
        'The User Name and Password combination is not valid.')

    case signInResponseTypes.signUpSuccessWithProfileCreated:
      return signInResponse(
        true,
        'Welcome',
        'You have successfully signed in and your profile has been created.')

    case signInResponseTypes.createAuthSuccess:
      return signInResponse(
        true,
        'Success',
        'An authentication has been created.')

    case signInResponseTypes.createAuthError:
      return signInResponse(
        false,
        'Error',
        'An authentication has NOT been created.')

    case signInResponseTypes.signInWithEmailSuccess:
      return signInResponse(
        true,
        'Welcome ' + displayName,
        'You have successfully signed in!')

    case signInResponseTypes.profileError:
      return signInResponse(
        true,
        'Profile Error!',
        'Something went wrong finding your profile.')

    // CHECK PROFILE
    case signInResponseTypes.profileFound:
    case signInResponseTypes.signInPassword:
      const response = signInResponseMessage(responseData, appUserType)
      const { additionalMessage, message, signInMethodType } = response
      return signInResponse(
        true,
        displayName ? 'Hello ' + displayName : 'Hello',
        message,
        allowNewEventPasswordReplace ?
          additionalResponse(responseData.hasAuth, appUserType)
          :
          additionalMessage,
        null,
        signInMethodType)

    case signInResponseTypes.authNotFound:
      return signInResponse(
        false,
        _.startCase(signInResponseType),
        'We are unable to find your authorization information.')

    case signInResponseTypes.profileNotFound:
      return signInResponse(
        false,
        _.startCase(signInResponseType),
        'We are unable to find your profile information.')

    case signInResponseTypes.signUpEmailSent:
      return signInResponse(
        true,
        'Email Sent',
        'You have successfully signed up. A confirmation email has been sent to ' + responseData.accountName + '. Please confirm your email to gain full access to the app.')

    case signInResponseTypes.signUpEmailSentError:
      return signInResponse(
        true,
        'Email Not Sent',
        'You have successfully signed up. However, we were unable to send confirmation email to ' + responseData.accountName + '.')

    case signInResponseTypes.signUpComfirmationError:
      return signInResponse(
        false,
        'Sign Up Confirmation Error',
        responseData.err ? responseData.err.message : 'Unknown Error')

    case signInResponseTypes.signUpError:
      return signInResponse(
        false,
        'Error',
        responseData.err ? responseData.err.message : 'Unknown Error',
      )

    case signInResponseTypes.signInError:
      return signInResponse(
        false,
        'Sign In Error',
        responseData.err ? responseData.err.message : 'Unknown Error',
      )

    case signInResponseTypes.handlePasswordAlert:
      return signInResponse(
        true,
        'New Password Confirmation',
        "You are attempting to sign in using an `event password`.",
        "By confirming, your profile will be updated and this password will become your new password. If you would like to use your current password, click on the `X` and enter your password."
      )

    case signInResponseTypes.handlePasswordAlertConfirmed:
      return signInResponse(
        false,
        'New Password Confirmation',
        'Confirmed.',
        null,
        null,
        true
      )

    case signInResponseTypes.tooManyAttempts:
      return signInResponse(
        false,
        'Too Many Attempts.',
        responseData.errorMessage ? responseData.errorMessage : 'You have made too many attempts to login.',
      )

    case signInResponseTypes.unknownError:
      return signInResponse(
        false,
        'Error',
        responseData.err ? responseData.err.message : 'Unknown Error')

    default:
      return null
  }
}

const signInResponseMessage = (action, appUserType) => {

  const { hasAuth, authProviders } = action

  const response = {
    message: 'We have successfully located your profile.',
    additionalMessage: null,
    signInMethodType: null
  }

  switch (appUserType) {
    case gEnums.appUserTypes.admin:
    case gEnums.appUserTypes.superAdmin:
      response.message += " Please enter your password."
      if (authProviders[gEnums.signInProviderTypes.emailPassword]) {
        if (hasAuth) {
          response.additionalMessage += " If you have forgotten your password, please click on `Reset Password`."
        }
      }
      break;
    default:
      if (authProviders) {
        if (authProviders[gEnums.signInProviderTypes.emailLink]) {
          if (hasAuth) {
            response.additionalMessage += " Since you have previously signed in using an email link, you can click on the `Send Email Link` below to re-authenticate your profile. You can also enter a password if you have one."
            response.signInMethodType = signInMethodTypes.emailLink
          } else {
            response.additionalMessage += " Please click on the `Send Email Link` to authenticate your profile."
          }
        } else if (authProviders[gEnums.signInProviderTypes.emailPassword]) {
          if (hasAuth) {
            response.additionalMessage += " Please enter your password. If you have forgotten your password, please click on `Reset Password`."
          } else {
            response.additionalMessage += " Please enter the 'event password'."
          }
        } else {
          if (hasAuth) {
            response.additionalMessage += " Please enter your password. If you have forgotten your password, please click on `Reset Password`."
          } else {
            response.additionalMessage += " Please enter the 'event password'."
          }
        }
      } else {
        if (hasAuth) {
          response.additionalMessage += " Please enter your password."
        } else {
          response.additionalMessage += " Please enter the 'event password'."
        }
      }
  }

  return response
}

const additionalResponse = (hasAuth, appUserType) => {
  switch (appUserType) {
    case gEnums.appUserTypes.admin:
    case gEnums.appUserTypes.appAdmin:
    case gEnums.appUserTypes.appDataAdmin:
    case gEnums.appUserTypes.appSubAdmin:
    case gEnums.appUserTypes.superAdmin:
      return null
    default:
      return hasAuth ?
        " You already have an authorized account with a password. You can either sign in using your current password, or the `event password`. If you use the 'event password', this will become your new password for any future event."
        :
        " The `event password` will become your new password for any future event."
  }
}
