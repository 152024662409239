import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { sortObject } from '../../../common/sorting';
import { getFormPropz } from '../props/formPropz';
import FormForm from '../FormForm';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { appIconTypes } from '../../../enums/appIconTypes';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import { H3Topper } from '../../../wrappers/TopperWrappers';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { createWizardGroups } from '../../../viewSettings/helpers/wizard';
import GenericIcon from '../../../genericControls/GenericIcon';

/**
 * 
 * @param {object} props 
 * @param {object} - form_handlers: group of handler functions
 * @param {function} - handlePreview: function to handle the preview
 * @param {function} - handleSave: data function to handle the save
 * @param {boolean} - noModal: set if the component is in modal mode
 * @param {boolean} - topperCaption: caption for the top of the wizard
 * @param {boolean} - topperCaption2: caption for the sub top of the wizard
 * @param {object} - viewProps: group of viewProps
 * @description This will take the normal appForm and turn it into a wizard. It will break the viewProps into wizardGroup.
 * @returns StepWizard
 */
export const AppFormWizard = (props) => {

  const {
    form_handlers,
    handlePreview,
    handleSave,
    noModal,
    topperCaption,
    topperCaption2,
    viewProps,
  } = props


  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ? uiItemContext : {}

  const handleCancel = () => {
    item_handlers && item_handlers.handleShowItemSidebar && item_handlers.handleShowItemSidebar()
    props.handleCancel && props.handleCancel()
  }

  // local state  
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState(props.formData)
  const [wizardGroups, setWizardGroups] = useState()
  const [wizardForms, setWizardForms] = useState()
  const [currentForm, setCurrentForm] = useState()

  const stepCount = wizardForms ? Object.keys(wizardForms).length : 0

  const form = (fprs, key) => <FormForm
    formAttributes={{ className: 'form-' + key }}
    formProps={fprs}
    isWizard={true}
  />

  /** Creates the forms for the wizard */
  const getForms = (_wizardGroups) => {
    if (Object.keys(_wizardGroups).length > 0) {
      const frms = []
      Object.keys(_wizardGroups).forEach(key => {
        const wizardGroup = _wizardGroups[key]
        const fpp = { form_handlers, viewProps: wizardGroup, data_current: formData }
        const fprs = getFormPropz(fpp)
        frms.push({ form: form(fprs, key), name: key })
      })
      setWizardGroups(_wizardGroups)
      setWizardForms(frms)
      setCurrentForm(frms[Object.keys(frms)[0]])
    }
  }

  useEffect(() => {
    if (viewProps) {
      const sortedProps = sortObject(viewProps, '_position')
      const _wizardGroups = createWizardGroups(sortedProps)
      getForms(_wizardGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    setFormData(props.formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [props.formData]);

  const getCurrentForm = (newStep) => {
    const _wg = Object.keys(wizardGroups)[newStep]
    const wizardGroup = wizardGroups[_wg]
    const fpp = { form_handlers, viewProps: wizardGroup, data_current: formData }
    const fprs = getFormPropz(fpp)
    const frm = { form: form(fprs, _wg), name: _wg }
    setCurrentForm(frm)
    setStep(newStep)
  }

  const validateCurrentForm = () => {
    let itemIsReady = true
    const wizardGroup = wizardGroups[currentForm.name]
    Object.keys(wizardGroup).forEach(key => {
      if (itemIsReady) {
        const itemProp = wizardGroup[key]
        if (itemProp && itemProp.data && itemProp.data.required) {
          if (formData) {
            if (!formData[key] || formData[key] === '') {
              itemIsReady = false
            }
          }
        }
      }
    })
    return itemIsReady
  }

  const validateForm = () => {
    let formIsReady = true
    Object.keys(wizardGroups).forEach(wgKey => {
      const wizardGroup = wizardGroups[wgKey]
      Object.keys(wizardGroup).forEach(key => {
        if (formIsReady) {
          const itemProp = wizardGroup[key]
          if (itemProp && itemProp.data && itemProp.data.required) {
            if (formData) {
              if (!formData[key] || formData[key] === '') {
                formIsReady = false
              }
            }
          }
        }
      })
    })
    return formIsReady
  }

  const allowBack = step > 0
  const allowForward = (step < (stepCount - 1) && validateCurrentForm())
  const allowSave = wizardGroups ? validateForm() : false

  const handleStep = (co) => {
    const { count } = co
    let newStep = step + count
    if (newStep < 0) { newStep = 0 }
    if (newStep > (stepCount - 1)) { newStep = stepCount - 1 }
    getCurrentForm(newStep)
    // const current_form = wizardForms[Object.keys(wizardForms)[newStep]]
    // setCurrentForm(current_form)
    // setStep(newStep)
  }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const buttonProps = { color: 'black', inverted: true }
  const backButton = () => gi('btn-back', appIconTypes.arrowLeft, handleStep, { count: -1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowBack })
  const nextButton = () => gi('btn-forward', appIconTypes.arrowRight, handleStep, { count: 1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowForward })

  const header = () => <H3Topper
    leftContent={backButton()}
    middleContent={currentForm ? _.startCase(currentForm.name) : '-'}
    rightContent={nextButton()}
  />

  const footer = () => <UiSaveButtons
    preview={{ oc: handlePreview, disabled: !allowSave }}
    save={{ oc: handleSave, disabled: !allowSave }}
  ></UiSaveButtons>

  const wrapper = () => <Wrapper
    header={header()}
    content={currentForm.form}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={topperCaption}
    topperCaption2={topperCaption2}
    noModal={noModal}
  />

  return currentForm ? fullPageWrapper() : <div></div>

}