import React, { createContext, useContext } from 'react';
import { separateContexts } from '../../common/filtering';
import { AppAccessContext } from './AppAccessContext';
import { AppFormsContext } from './AppFormsContext';
import { AppNotificationsContext } from './AppNotificationsContext';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUserAuthContext } from './AppUserAuthContext';
import { AppUserContext } from './AppUserContext';
import { AuthContext } from './AuthContext';
import { DataWrapperContext } from './DataWrapperContext';
import { EventInfoContext } from './EventInfoContext';
import { ManifestContext } from './ManifestContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';
import { PreviewContext } from './PreviewContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from './SettingsDataContext';
import { StartContext } from './StartContext';
import { StartDataContext } from './StartDataContext';
import { StorageContext } from './StorageContext';
import { TransitionContext } from './TransitionContext';
import { VideoConferenceContext } from './VideoConferenceContext';

/**
 * Provider providing the following Contexts
  appAccessContext
  , `appFormsContext`
  , `appNotificationsContext`
  , `appSettingsContext` 
  , `authContext` 
  , `clientSettingsContext` 
  , `appUserDataContext` 
  , `dataWrapperContext` 
  , `eventSettingsContext` 
  , `homeSettingsContext`
  , `manifestContext`
  , `pageContext`
  , `papsContext` 
  , `startContext`
  , `startDataContext`
  , `eventInfoContext`
  , `storageContext`
  , `videoConferenceContext`
 */
export const ParentContext = createContext();

const ParentProvider = (props) => {

  const appUserAuthContext = useContext(AppUserAuthContext)
  const appAccessContext = useContext(AppAccessContext);
  const appFormsContext = useContext(AppFormsContext);
  const appNotificationsContext = useContext(AppNotificationsContext);
  const appSettingsContext = useContext(AppSettingsContext)
  const appUserContext = useContext(AppUserContext)
  const authContext = useContext(AuthContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const dataWrapperContext = useContext(DataWrapperContext)
  const eventInfoContext = useContext(EventInfoContext);
  const eventSettingsContext = useContext(EventSettingsContext);
  const homeSettingsContext = useContext(HomeSettingsContext);
  const manifestContext = useContext(ManifestContext);
  const pageContext = useContext(PageContext);
  const papsContext = useContext(PapsContext);
  const previewContext = useContext(PreviewContext)
  const startContext = useContext(StartContext);
  const startDataContext = useContext(StartDataContext);
  const storageContext = useContext(StorageContext);
  const transitionContext = useContext(TransitionContext)
  const videoConferenceContext = useContext(VideoConferenceContext);

  const contexts = {
    appAccessContext,
    appUserAuthContext,
    appFormsContext,
    appNotificationsContext,
    appSettingsContext,
    appUserContext,
    authContext,
    clientSettingsContext,
    dataWrapperContext,
    eventInfoContext,
    eventSettingsContext,
    homeSettingsContext,
    manifestContext,
    pageContext,
    papsContext,
    previewContext,
    startContext,
    startDataContext,
    storageContext,
    transitionContext,
    videoConferenceContext
  }

  const { navigate } = papsContext ? papsContext : {}

  const parent_contexts = separateContexts(contexts)

  // Object.keys(contexts).forEach(k => {
  //   if (!contexts[k]) {
  //     console.log('k', k)
  //   }
  // })

  // console.log('parent_contexts', parent_contexts)

  const { states, settings } = parent_contexts
  const { eventSettings, clientSettings, homeSettings } = settings ? settings : {}

  const { global: global_h } = homeSettings ? homeSettings : {}
  const { global: global_c } = clientSettings ? clientSettings : {}
  const { global: global_e } = eventSettings ? eventSettings : {}

  if (eventSettings && eventSettings.global && eventSettings.global.parentDefaults) {
    const { useClientThemes, useClientUiElements, useClientUiSections } = global_e.parentDefaults
    if (useClientThemes) {
      if (global_c && global_c.themeColors) { global_e.themeColors = global_c.themeColors }
      if (global_c && global_c.themedItems) { global_e.themedItems = global_c.themedItems }
    }
    if (useClientUiElements) {
      if (global_c && global_h.uiElements) { global_e.uiElements = global_c.uiElements }
    }
    if (useClientUiSections) {
      if (global_c && global_c.uiSections) { global_e.uiSections = global_c.uiSections }
    }
  }

  const validateApp = () => {
    const { page_state, manifest_state } = states
    const { pageSettings } = page_state ? page_state : {}
    const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
    const { manifest, topMenu } = aps_global ? aps_global : {}
    const { appUserCollection } = aps_appUserSettings ? aps_appUserSettings : {}
    const { startIcon, manifestIcons } = manifest_state ? manifest_state : {}
    const { mainFixedCaption } = topMenu ? topMenu : {}
    const { appleStartUpImage, appleTouchIcon72, appleTouchIcon120, appleTouchIcon152, name: name_manifest, shortName } = manifest ? manifest : {}

    const appUserOk = appUserCollection ? true : false
    const menuOk = mainFixedCaption ? true : false
    const manifestOk = manifestIcons && Object.keys(manifestIcons).length === 8 && startIcon ? true : false
    const manifestIOk = (appleStartUpImage && appleTouchIcon72 && appleTouchIcon120 && appleTouchIcon152 && name_manifest && shortName) ? true : false

    if (appUserOk && menuOk && manifestOk && manifestIOk) {
      return true
    }
  }

  return <ParentContext.Provider value={{ ...props, validApp: validateApp(), ...parent_contexts, navigate }}>
    {props.children}
  </ParentContext.Provider>
};

export default ParentProvider