import _ from 'lodash';
import React from 'react';
import { Label } from 'semantic-ui-react';
import { helpTypes } from '../../cnr/reducers/HelpReducer';
import { gEnums } from '../../enums/globalEnums';

export const groupIgnore = (key) => {
  switch (key) {
    case 'groupItemSettings':
    case 'groupSettings':
    case 'json':
    case 'logging':
    case 'notificationsManager':
    case 'productionActions':
    case 'props':
    case 'settings_globalPageItems':
    case 'settingsActions':
    case 'settingsConsole':
    case 'themeColors':
    case 'viewActions':
    case 'viewItems':
    case 'views':
      return true
    default:
      return false
  }
}

export const groupItemIgnore = (key, combinedHelp) => {
  if (key) {
    switch (key) {
      default:
        if (key.startsWith('color')) { return true }
        if (key.endsWith('SettingsAuthLevel')) {
          if (combinedHelp) { addHelpItem('settingsAuthLevel', combinedHelp) }
          return true
        }
        if (key.endsWith('AuthUser')) {
          if (combinedHelp) { addHelpItem('authUser', combinedHelp) }
          return true
        }
        if (key.startsWith('allowAppUser')) {
          if (combinedHelp) { addHelpItem('allowAppUser', combinedHelp) }
          return true
        }
        return false
    }
  } else {
    return true
  }
}

const addHelpItem = (key, combinedHelp) => {
  if (!combinedHelp.includes(key)) { combinedHelp.push(key) }
}

const isOdd = (num) => {
  return num % 2 === 1 ? true : false
}

export const formatHelp = (ds, description, cn) => {
  const x = description.split("`")
  if (x) {
    x.forEach((item, index) => {
      if (isOdd(index)) {
        ds.push(<mark className={cn}>{item}</mark >)
      } else {
        ds.push(<span>{item}</span>)
      }
    })
  }
}

export const getHelpGroup = (helpDocuments, hsProps) => {
  const { helpDocField, helpDocSubField } = hsProps ? hsProps : {}
  const helpGroup = helpDocuments && helpDocuments[helpDocField] && helpDocuments[helpDocField][helpDocSubField] ? helpDocuments[helpDocField][helpDocSubField] : null
  return helpGroup
}

/**
 * 
 * @param {object} helpGroup 
 * @param {object} hsProps 
 * @returns the description of either the item or the group
 */
export const getHelps = (helpGroup, hsProps) => {
  const { helpProp } = hsProps ? hsProps : {}
  const helps = {
    description: '',
    line: '',
  }
  if (helpProp) {
    helps.description = helpGroup && helpGroup.items && helpGroup.items[helpProp] && helpGroup.items[helpProp].description ? helpGroup.items[helpProp].description : ''
    helps.line = helpGroup && helpGroup.items && helpGroup.items[helpProp] && helpGroup.items[helpProp].line ? helpGroup.items[helpProp].line : ''
  } else {
    helps.description = helpGroup && helpGroup.groupDescription ? helpGroup.groupDescription : ''
    helps.line = helpGroup && helpGroup.groupLine ? helpGroup.groupLine : ''
  }
  return helps
}

export const formatDescription = (description, css, helpKey) => {
  const ds = []
  if (description) {
    if (description.indexOf("`") > 0) {
      formatHelp(ds, description, css)
    } else {
      ds.push(<span>{description}</span>)
    }
    return ds
  }
  return helpKey
}

export const getEnumLabels = (prop) => {
  if (gEnums[prop + 's']) {
    return Object.keys(gEnums[prop + 's']).map(e => <Label color={'grey'}>{_.startCase(e)}</Label>)
  }
}

export const getHelpDocuments = (groupSettings, helpSettings, helpTopics, handleUpdateHelp, helpDocuments) => {

  const helpz = {}
  // const altHelpz = {}
  Object.keys(groupSettings).forEach(helpDocField => { // vi, vs, pi, pr
    const groupSetting = groupSettings[helpDocField]
    Object.keys(groupSetting).forEach(helpDocSubField => {
      const groupSettingItem = groupSetting[helpDocSubField]
      const { groupItems } = groupSettingItem

      if (!helpz[helpDocField]) { helpz[helpDocField] = {} }
      if (!helpz[helpDocField][helpDocSubField]) { helpz[helpDocField][helpDocSubField] = {} }

      if (helpTopics && helpTopics[helpDocSubField]) {
        helpz[helpDocField][helpDocSubField].groupDescription = helpTopics[helpDocSubField]
      } else {
        helpz[helpDocField][helpDocSubField].groupDescription = 'Group settings for `' + _.startCase(helpDocSubField) + '`'
      }

      if (groupItems) {
        Object.keys(groupItems).forEach(key => {
          const gsi = groupItems[key]
          const { prop } = gsi


          let description = 'No definition';
          if (helpSettings[prop]) {
            description = helpSettings[prop]
            if (!helpz[helpDocField][helpDocSubField].items) { helpz[helpDocField][helpDocSubField].items = {} }
            if (!helpz[helpDocField][helpDocSubField].items[prop]) { helpz[helpDocField][helpDocSubField].items[prop] = { description } }

            if (helpDocuments) {
              if (helpDocuments[helpDocField] && !helpDocuments[helpDocField][helpDocSubField]) {
                if (!helpDocuments[helpDocField]) { helpDocuments[helpDocField] = {} }
                if (!helpDocuments[helpDocField][helpDocSubField]) {
                  helpDocuments[helpDocField][helpDocSubField] = {
                    groupDescription: 'Group Settings for ' + _.startCase(helpDocSubField),
                    items: {}
                  }
                }
              }
              if (!helpDocuments[helpDocField][helpDocSubField].items[prop]) {
                helpDocuments[helpDocField][helpDocSubField].items[prop] = { description }
              }
            }
          }
        })
      }
    })
  })

  if (handleUpdateHelp) { handleUpdateHelp(helpz, helpTypes.documents) }
  return helpz
}