import { dispatchProps } from "../cnr/reducers/reducerHelpers/dispatchProps";
import { callFunction } from "./fbAll";

export const createModes = {
  create: 'create',
  update: 'update'
}

const deleteGenericTypes = {
  deleteGeneric: 'deleteGeneric',
}

export const deleteFunctionTypes = {
  deleteClient: 'deleteClient',
  deleteClientCollection: 'deleteClientCollection',
  deleteEvent: 'deleteEvent',
  deleteEventCollection: 'deleteEventCollection',
  deleteEventCollections: 'deleteEventCollections',
  deleteFullClient: 'deleteFullClient',
  deleteFullEvent: 'deleteFullEvent',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success, result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(deleteGenericTypes.deleteGeneric, callData)
  return data
}

/**
 * 
 * @param {object} pathViews 
 * @param {array} viewItemKeys 
 * @param {string} storageRootPath 
 * @description Deletes the eventDocument after deleting all of the sub collections
 * @returns - response_data {success, result, error}
 */
export const fsfn_deleteFullEventFromDatabase = async (pathViews, viewItemKeys, storageRootPath, leaveEvent) => {
  try {
    const callData = { deleteFunctionType: deleteFunctionTypes.deleteFullEvent, pathViews, viewItemKeys, storageRootPath, leaveEvent, showLogs: true }
    const response_data = await callFsFunction(callData, true) // OK 
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} pathViews 
 * @param {array} viewItemKeys 
 * @param {string} storageRootPath 
 * @description Deletes the eventDocument after deleting all of the sub collections
 * @returns - response_data {success, result, error}
 */
export const fsfn_deleteFullClientFromDatabase = async (pathViews, storageRootPath) => {
  try {
    const callData = { deleteFunctionType: deleteFunctionTypes.deleteFullClient, pathViews, storageRootPath }
    const response_data = await callFsFunction(callData, true) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} pathViews 
 * @param {string} collectionName 
 * @param {number} batchSize 
 * @param {object} logging 
 * @returns - response_data {success, result, error}
 */
export const fsfn_deleteClientCollection = async (pathViews, collectionName, batchSize, logging) => {
  const callData = { deleteFunctionType: deleteFunctionTypes.deleteClientCollection, pathViews, collectionName, batchSize, showLogs: logging && logging.allowLogging && logging.logDeleteCollection }
  try {
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_deleteClientCollections = async (pathViews, clientCollections, handleUpdating, dispatch) => {
  try {
    clientCollections.forEach(async (key, index) => {
      handleUpdating && handleUpdating(key)
      // fsfn_deleteClientCollection(pathViews, key, 100).then(response_data => {
      //   const { success, error } = response_data ? response_data : {}
      //   dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      // }).catch(error => {
      //   dispatch && dispatch({ type: dispatchProps.error, error })
      // })
      // }
    })
  } catch (err) {
    dispatch && dispatch({ type: dispatchProps.error, error: err })
  }
}

// export const fsfn_deleteEventCollections = async (pathViews, dataCollectionKeys, batchSize, logging) => {
//   const callData = { deleteFunctionType: deleteFunctionTypes.deleteEventCollections, pathViews, dataCollectionKeys, batchSize, showLogs: logging && logging.allowLogging && logging.logDeleteCollection }
//   try {
//     const response_data = await callFsFunction(callData)
//     console.log('response_data', response_data);
//     return response_data
//   } catch (error) {
//     console.error(error)
//     return { type: dispatchProps.error, error }
//   }
// }

export const fsfn_deleteEventCollections = (pathViews, dataCollectionKeys, callbackWhenDone) => {
  const items = {}
  dataCollectionKeys.forEach(async (dcKey, index) => {
    // delete a single collection (await)
    const response_data = await fsfn_deleteEventCollection(pathViews, dcKey, 100)
    const { success, error } = response_data ? response_data : {}
    items[dcKey] = { icon: 'check', color: success ? 'green' : 'red', success: success, error }
    if (Object.keys(items).length === dataCollectionKeys.length) { callbackWhenDone(items) }
  })
}

/**
 * 
 * @param {string} pathViews The database path
 * @param {string} collectionName Name of the collection to be deleted
 * @param {number} batchSize Size of the delete batch. Must be <= 100
 * @param {object} logging Contains information about whether logging will triggered in functions
 * @description Deletes all the items from a single collection under the `event` document
 * @returns response_data {success, result, error}
 */
export const fsfn_deleteEventCollection = async (pathViews, collectionName, batchSize, logging) => {
  const callData = { deleteFunctionType: deleteFunctionTypes.deleteEventCollection, pathViews, collectionName, batchSize, showLogs: true } // logging && logging.allowLogging && logging.logDeleteCollection }
  try {
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}
