import React from 'react'
import { g_cns } from '../common/cns'

const UiWrapper = (content) => {
  return (
    <div className={g_cns.app_content + ' g-normal'}>
      <div className={g_cns.items_content}>
        {content}
      </div>
    </div>
  )
}

export default UiWrapper