import _ from 'lodash';
import { filterSearchValue } from "../../common/filtering";
import { createPropHeadersFromData } from "../../viewSettings/importing/pasteHelpers";

export const tableReducerTypes = {
  createTableHeaders: 'createTableHeaders',
  handleDiff: 'handleDiff',
  handleOnlyDiff: 'handleOnlyDiff',
  handleSearchChange: 'handleSearchChange',
  handleSelectedOnly: 'handleSelectedOnly',
  handleSelect: 'handleSelect',
  handleSelectAll: 'handleSelectAll',
  setCommitData: 'setCommitData',
  updateTableData: 'updateTableData',
}

export const tableReducer = (state, action) => {

  const { selectedOnlyOn, namePropsOnly, selectedPropsOnly, viewItem, viewListData, tableList, tableData, maxSelection, diffOn, diffOnlyOn, selectedItems, searchKeys } = state
  const { type, data, questionProps, searchValue } = action
  const list_data = tableData ? [...tableData] : []
  let selected_items = selectedItems ? [...selectedItems] : []

  switch (type) {

    case tableReducerTypes.updateTableData:
      const tl = {}
      action.tableData.forEach((tdi, index) => {
        tl[index] = tdi
        tl[index]._existingKey = index
        tl[index]._itemKey = index
      })
      return {
        ...state,
        tableList: action.tableData,
      }

    case tableReducerTypes.createTableHeaders:
      let hds;
      if (viewItem && viewItem.props && (namePropsOnly || selectedPropsOnly)) {
        const xx = []
        let x;
        if (selectedPropsOnly) {
          x = _.filter(viewItem.props, 'show')
          x = _.sortBy(x, 'position')
        } else if (namePropsOnly) {
          x = _.filter(viewItem.props, i => i.propSection === 'name')
        }
        if (x) {
          Object.values(x).forEach(prop => {
            const { display } = prop
            const { caption } = display ? display : {}
            xx.push({ key: prop.key, caption: caption ? caption : null })
          })
        }
        hds = { propHeaders: xx }
      } else {
        hds = createPropHeadersFromData(action.tableData, viewItem ? viewItem.props : null)
      }

      const { propHeaders, newProps } = hds

      return {
        ...state,
        tableHeaders: propHeaders,
        newProps: newProps
      }

    case tableReducerTypes.handleDiff:
      return {
        ...state,
        diffOn: !diffOn,
      }

    case tableReducerTypes.handleOnlyDiff:
      return {
        ...state,
        diffOnlyOn: !diffOnlyOn,
      }

    case tableReducerTypes.handleSelectedOnly:
      return {
        ...state,
        selectedOnlyOn: !selectedOnlyOn,
      }

    case tableReducerTypes.setCommitData:
      return {
        ...state,
        questionProps,
      }

    case tableReducerTypes.handleSearchChange:

      if (searchValue) {
        const f = []
        const x = filterSearchValue(viewListData, searchKeys, searchValue, f)
        return {
          ...state,
          tableList: x
        }
      } else {
        return {
          ...state,
          tableList: list_data
        }
      }

    case tableReducerTypes.handleSelectAll:
      const { checked: checkedAll } = data
      if (checkedAll) {
        tableList.forEach((item, index) => {
          if (maxSelection) {
            if (selected_items.length < maxSelection) {
              selected_items.push(item._itemKey)
            }
          } else {
            selected_items.push(item._itemKey)
          }
        })
      } else {
        selected_items = []
      }

      return {
        ...state,
        selectedItems: selected_items
      }

    case tableReducerTypes.handleSelect:

      const { _itemKey } = data

      if (_.includes(selected_items, _itemKey)) {
        _.remove(selected_items, function (n) {
          return n === _itemKey;
        });
      } else {
        if (maxSelection) {
          if (selected_items.length < maxSelection) {
            selected_items.push(_itemKey)
          }
        } else {
          selected_items.push(_itemKey)
        }

      }

      return {
        ...state,
        selectedItems: selected_items
      }

    case tableReducerTypes.handleInitSelected:
      return {
        ...state,
        selectedItems: action.initSelected
      }

    default:
      return { ...state }
  }
}

export const tableInitialState = (props) => {
  const { viewItem, tableData, searchKeys, namePropsOnly, selectedPropsOnly, selectedItems, maxSelection } = props
  return {
    diffOn: true,
    initTableData: tableData,
    maxSelection,
    namePropsOnly,
    selectedPropsOnly,
    searchKeys,
    selectedItems: selectedItems ? selectedItems : [],
    selectedOnlyOn: false,
    viewItem,
  }
};

export const tableHandlers = (dispatch) => {
  return {
    createTableHeaders: (tableData) => { dispatch({ type: tableReducerTypes.createTableHeaders, dispatch, tableData }) },

    handleDiff: () => { dispatch({ type: tableReducerTypes.handleDiff, dispatch }) },
    handleOnlyDiff: () => { dispatch({ type: tableReducerTypes.handleOnlyDiff, dispatch }) },
    handleSearchChange: (searchValue) => { dispatch({ type: tableReducerTypes.handleSearchChange, dispatch, searchValue }) },
    handleSelectedOnly: () => { dispatch({ type: tableReducerTypes.handleSelectedOnly, dispatch }) },
    handleSelect: (data) => { dispatch({ type: tableReducerTypes.handleSelect, dispatch, data }) },
    handleSelectAll: (data) => { dispatch({ type: tableReducerTypes.handleSelectAll, dispatch, data }) },
    handleInitSelected: (initSelected) => { dispatch({ type: tableReducerTypes.handleInitSelected, dispatch, initSelected }) },
    setCommitData: (questionProps) => { dispatch({ type: tableReducerTypes.setCommitData, dispatch, questionProps }) },

    updateTableData: (tableData) => { dispatch({ type: tableReducerTypes.updateTableData, dispatch, tableData }) },
  }
}