import React from 'react';
import { Label } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { formatItem, formatTypes } from '../../common/dateFormatting';
import { getArrayIndex } from '../../common/sorting';
import { sidebarMenuTypes } from '../../cnr/reducers/SidebarReducer';

const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const _calMonthProps = {
  labelSize: 'mini',
  labelColor: 'green',
  formatType: formatTypes.shortestDate
}

export const CalMonth = (props) => {

  const { currentDate, selectedDate, calendar_state, calendar_handlers, sbProps, dateKeys, dateKeyz, clickType } = props
  const { fixedDates, dateGroups, dateGroupProp, selectedDates } = calendar_state ? calendar_state : {}

  const _currentDate = formatItem(formatTypes.shortestDate, new Date())

  const { handleItemClick, sidebar_handlers, type: sidebarMenuType } = sbProps ? sbProps : {}
  const { handleGenericItemClick } = sidebar_handlers ? sidebar_handlers : {}

  const _selectedDate = selectedDate ? selectedDate : currentDate

  const fixed_dates = dateKeys ? dateKeys : fixedDates

  /**
   * handles the click of a date
   * @param {array} dv 
   */
  const handleDateClick = (dv) => {
    if (handleItemClick) {
      handleItemClick(null, null, sidebarMenuType ? sidebarMenuType : sidebarMenuTypes.date, dv)
    } else if (handleGenericItemClick) {
      handleGenericItemClick(null, null, clickType ? clickType : sidebarMenuTypes.date, dv)
    } else if (calendar_handlers && calendar_handlers.handleDateSelect) {
      calendar_handlers.handleDateSelect(dv)
    } else if (props.handleClick) {
      props.handleClick(dv)
    }
  }

  const dateSpan = (d, dc) => {
    try {
      if (dc) {
        return <React.Fragment>
          <div key={uniqueKey('cm', 'd', d)}>{d}</div>
          <Label key={uniqueKey('cm', 'dl', d)} circular color={_calMonthProps.labelColor} size={_calMonthProps.labelSize}>{dc}</Label>
        </React.Fragment>
      } else {
        return <React.Fragment >
          <div key={uniqueKey('cm', 'd1', d)}>{d}</div>
          <div key={uniqueKey('cm', 'd2', d)}></div>
        </React.Fragment>
      }
    } catch (error) {
      return <div key={uniqueKey('cm', 'd1', d)}>{'xxx'}</div>
    }
  }

  const calDates = () => {

    const cds = []

    const _firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    let _firstDayOfMonth = _firstDateOfMonth.getDay() + 1

    const months = 1
    const weeks = 6
    const days = 7
    let dd = 0
    let go = false
    let month = 0

    for (var wd = 1; wd <= days; wd++) {
      cds.push(<div key={uniqueKey('cm', 'wd', wd)} className='dow'>{dateSpan(weekday[wd - 1])}</div>)
    }

    // loop the months
    for (var m = 1; m <= months; m++) {
      // loop the weeks
      for (var w = 1; w <= weeks; w++) {
        const ow = []
        let owdn = 0
        // loop the days
        for (var d = 1; d <= days; d++) {
          if (d >= _firstDayOfMonth && !go) {
            go = true
            month = currentDate.getMonth()
          }
          if (go) {
            dd++

            const _thisDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), dd)

            if (month === _thisDayOfMonth.getMonth()) {

              const _selDate = formatItem(_calMonthProps.formatType, _selectedDate)
              const _thisDate = formatItem(_calMonthProps.formatType, _thisDayOfMonth)
              const _dateCount = dateKeyz && dateKeyz[_thisDate]

              let sel;

              if (!fixed_dates) {
                try {
                  if (selectedDates && selectedDates.length > 0) {
                    sel = selectedDates.includes(_thisDate)
                  } else {
                    sel = (_selDate === _thisDate)
                  }
                } catch (error) {
                  console.error(error)
                }
              }

              if (fixed_dates && (getArrayIndex(fixed_dates, _thisDate) < 0)) {
                ow.push(<div key={uniqueKey('cm', m, w, d)} className={'non-sel'}>{dateSpan(dd, null)}</div>)
              } else {
                let dc = _dateCount
                if (sel) {
                  let cn = dc ? 'norm-count' : 'norm'
                  cn += ' sel'
                  if (_currentDate === _thisDate) { cn += ' current-date' }
                  ow.push(<div key={uniqueKey('cm', m, w, d)} className={cn} onClick={e => handleDateClick(_thisDate)}>{dateSpan(dd, dc)}</div>)
                } else {
                  let cn = dc ? 'norm-count' : 'norm'
                  if (dateKeys) { cn += ' dk' }
                  if (_currentDate === _thisDate) { cn += ' current-date' }
                  ow.push(<div key={uniqueKey('cm', m, w, d)} className={cn} onClick={e => handleDateClick(_thisDate)}>{dateSpan(dd, dc)}</div>)
                }
              }
            } else {
              ow.push(<div key={uniqueKey('cm', m, w, d)} className='nd'>{'--'}</div>)
              owdn++
            }
          } else {
            ow.push(<div key={uniqueKey('cm', m, w, d)} className='nd'>{'--'}</div>)
            owdn++
          }
        }
        if (owdn < 7) {
          cds.push([...ow])
        }
      }
    }
    return cds
  }

  return <div key={uniqueKey('cm')} className="calendar-container">
    {calDates()}
  </div>
}
