import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { SettingsAreaContext } from '../../cnr/contexts/SettingsAreaContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { settingsMenuHandlers, settingsMenuInitialState, settingsMenuReducer, settingsMenu_fns } from '../../cnr/reducers/SettingsMenuReducer';
import { SettingParentContext } from './SettingParentContext';

/** 
* @description This is the 2nd Component/Provider for Settings: (`settingsAreaContext` > `SettingsMenuContext`)
* @state settingsMenu_state (`activeMenu`, `activeMenus`, `activeAll`,`allow`, `allowGroupMenuSort`, `appArea`, `appUser_fns`, `helpDisplayType`, `helpDocuments`, `helpOn`, `helpTopics`, `isGlobal`, `lsName`, `projectOptions`, `settingsConsole`, `selectedGroup`, `selectedGroupItem`, `sortGroupsByPermission`, `useCollapsibleMenus`, `useFlatMenuIcons`)
* @handlers settingsMenu_handlers ( `handleActiveAll`, `handleActiveMenu`, `handleActiveMenuGroup`, `handleMenuGroupItemClick`, `handleOpenSettingsItem`, `handleShowSearch`, `handleUpdateAll`, `handleUpdateInit`, `handleAllowGroupMenuSort`)
* @functions settingsMenu_fns (`getCurrentConsoleSettings`,  `getSelectedGroupItem`, `getSettingsBaseItem`, `ammendDataGroupItem`) 
* 
*/
export const SettingsMenuContext = createContext();

const SettingsMenuProvider = (props) => {

  // settingsParentContext
  const settingsParentContext = useContext(SettingParentContext)
  const { states, handlers, fns } = settingsParentContext ? settingsParentContext : {}
  const { paps_state } = states ? states : {}
  const { appSettings_handlers, settingsFramework_handlers } = handlers ? handlers : {}
  const { page_fns } = fns ? fns : {}
  const { handleGroupKey_left, handleGroupKey_right } = settingsFramework_handlers ? settingsFramework_handlers : {}
  const { appArea } = paps_state ? paps_state : {}
  const { pushSimple } = page_fns ? page_fns : {}

  // viewSettingsComponentContext
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { handleShowSearch } = viewSettingsComponentContext ? viewSettingsComponentContext : {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext);
  const { settingsArea_state } = settingsAreaContext ? settingsAreaContext : {}
  const {
    allow,
    appArea: appArea_sa,
    isGlobal,
    helpDocs, //help_state
    helpOn, // appSettings_state
    settingsConsole,
    nonLandingView,
    pathViews, // paps_state
    productionSettings, // aps_global
    projectModules,
    projectOptions, // aps_global
    settingsOptions, //aps_global
    settingsOriginType,
  } = settingsArea_state ? settingsArea_state : {}

  const { useCollapsibleMenus, sortGroupsByPermission, helpDisplayType, useFlatMenuIcons } = settingsOptions ? settingsOptions : {}
  const { helpTopics, helpDocuments } = helpDocs ? helpDocs : {}

  const handleGroupKey = isGlobal ? handleGroupKey_left : handleGroupKey_right
  const _helpDocuments = helpDocuments ? helpDocuments[(isGlobal ? 'vw' : 'vi')] : {}

  let lsName = 'activeMenus_'
  lsName += isGlobal ? 'global' : 'view'
  let initActive = localStorage.getItem(lsName)

  if (!initActive) {
    initActive = { app: false, data: false, content: true, permissions: false, styling: true, view: true }
  } else {
    initActive = JSON.parse(initActive)
  }

  const init_state = {
    activeMenus: initActive,
    allow,
    appArea,
    helpDisplayType,
    helpDocuments: _helpDocuments,
    helpOn,
    helpTopics,
    settingsConsole,
    isGlobal,
    lsName, // local
    projectModules,
    projectOptions, // aps_global via sac 
    sortGroupsByPermission,
    useCollapsibleMenus,
    useFlatMenuIcons,
  }

  // reducerState
  // settingsMenu_fns are set within SettingsMenuProvider
  const [settingsMenu_state, settingsMenu_dispatch] = useReducer(settingsMenuReducer, settingsMenuInitialState(init_state));
  const settingsMenu_handlers = settingsMenuHandlers(settingsMenu_dispatch, settingsMenu_state)

  // sets the allowed groups for the settings menu
  useEffect(
    () => {
      settingsMenu_handlers.handleUpdateInit(appArea, isGlobal)
    },
    // update if appUser or settingsDocName changes
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [appArea_sa]
  )

  const groupItemClick_fns = {
    handleGroupKey,
    handleShowAppViewer: appSettings_handlers.handleShowAppViewer,
    handleShowSearch,
    openSettingsItem: appSettings_handlers.handleOpenSettingsItem,
    pushSimple,
  }

  const _settingsMenu_fns = settingsMenu_fns(projectOptions, projectModules, groupItemClick_fns, pathViews, nonLandingView, settingsOriginType, productionSettings)

  const providerContext = () => <SettingsMenuContext.Provider value={{ settingsMenu_state, settingsMenu_handlers, settingsMenu_fns: _settingsMenu_fns }}>
    {props.children}
  </SettingsMenuContext.Provider>

  return providerContext()

}

export default SettingsMenuProvider