import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Reveal } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { filterObject, _filter } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { sortObject } from '../../common/sorting';
import DragDropper from '../../dragNDrops/DragDropper';
import { allPageTypes } from '../../enums/allPageTypes';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import { FullPageContent } from './FullPageContent';
import { gridItemsAdmin } from './gridItems/gridItemsAdmin';
import { gridItemsAppAdmin } from './gridItems/gridItemsAppAdmin';
import { gridItemsAppUser } from './gridItems/gridItemsAppUser';
import { gridItemsMain } from './gridItems/gridItemsMain';
import { gridItemsTickets } from './gridItems/gridItemsTickets';
import { IconsGridGrid } from './IconsGridGrid';
import { fsfn_updateSheetRangeValues } from '../../functions/fbSheetsFuntions';

const useItemWizard = true

const _defaultAdminLevels = {
  adminOnly: gEnums.accessLevels.admin.value,
  appAdminOnly: gEnums.accessLevels.appDataAdmin.value,
}

/**
 * 
 * @param {object} props (pageIcons) 
 * @returns an element containing a group of gridIcons. Each gridIcon will redirect the app to a new page
 */
const IconsGrid = (props) => {

  const { pageIcons } = props

  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ? parentContext : {}

  const { homeSettings } = settings
  const { appSettings_state, appUser_state, page_state, paps_state, storage_state, transition_state } = states
  const { appUser_fns, page_fns } = fns
  const { globalFiles } = storage_state ? storage_state : {}

  const { pathViews } = paps_state ? paps_state : {}

  // authContext 
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const { profileValidated } = authAppUser ? authAppUser : {}
  const appUserAccess = getAppUserAccess(authAppUser)
  const { accessLevel, displayName } = appUserAccess ? appUserAccess : {}

  // transitionContext  
  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.pageItemElement] : null
  const { showTransition } = transition ? transition : {}

  // appSettingsContext 
  const { sortingOn } = appSettings_state ? appSettings_state : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode, isMobileDevice } = framework_state ? framework_state : {}

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles, aps_page, aps_viewItems } = pageSettings ? pageSettings : {}
  const { pushSimple, syncViewItems } = page_fns ? page_fns : {}

  const backgroundSaC = aps_styles ? aps_styles[gEnums.projectStyles.background] : {}

  let { viewItems: viewItems_page } = aps_page ? aps_page : {}

  const { global: global_home } = homeSettings ? homeSettings : {}
  const { appUrls: homeAppUrls, settingsPermissions } = global_home ? global_home : {}
  const { appUrls: appUrlz } = homeAppUrls ? homeAppUrls : {}

  const weatherLink = page_fns.getLink(appUrlz, 'weather', isMobileDevice)

  const { appTicketing, clientOptions, eventOptions, appVoting, appSurvey } = aps_global ? aps_global : {}

  const { allowVoting, showVotingManagerOnHomeScreen, showVotingOnHomeScreen } = appVoting ? appVoting : {}
  const { surveyUrl } = appSurvey ? appSurvey : {}
  const { ticketingManagers, scanningManagers } = appTicketing ? appTicketing : {}
  const { gridIcons } = aps_global && aps_global.themedItems ? aps_global.themedItems : {}
  const { allowHiddenIcons } = settingsPermissions ? settingsPermissions : {}
  const { iconsPerRow: iconsPerRow_gi, textLimit } = gridIcons ? gridIcons : {}

  const _appOptions = { ...clientOptions, ...eventOptions }
  let { weatherZip, weatherCountryCode, weatherCode } = _appOptions ? _appOptions : {}

  // eslint-disable-next-line 
  const globalIconsPerRow = iconsPerRow_gi ? iconsPerRow_gi : 3

  const { layout: layout_page } = aps_page ? aps_page : {}
  const { iconsPerRow } = layout_page ? layout_page : {}

  let _iconsPerRow = iconsPerRow ? iconsPerRow : globalIconsPerRow

  const [gridItemGroups, setGridItemGroups] = useState({ page: null, pageHidden: null, admin: null, appAdmin: null, ticketing: null, ready: false, voting: false, survey: false })
  const [showFullPage, setShowFullPage] = useState()

  const segProps = {}

  const defaultSnC = aps_styles ? aps_styles[gEnums.projectStyles.gridIcons] : {}

  if (syncViewItems) { syncViewItems(aps_global, aps_viewItems, aps_page) }

  // retrict  
  const showProp = desktopMode ? gEnums.showTypes.showDesktop : gEnums.showTypes.show

  let iconCount = gridItemGroups && gridItemGroups.page ? Object.keys(gridItemGroups.page).length : 0

  if (!pageIcons && allowVoting && (showVotingManagerOnHomeScreen || showVotingOnHomeScreen)) { iconCount++ }

  if (iconCount <= _iconsPerRow) { _iconsPerRow = iconCount }

  /**
   * handles the click of the gridIcon
   * @param {object} viewItem 
   * @param {string} itemKey 
   */
  const handleIconClick = (viewItem, itemKey) => {

    const { key, itemClick } = viewItem ? viewItem : {}
    const { clickToType, pdf: pdf_name } = itemClick ? itemClick : {}

    switch (clickToType) {
      case gEnums.clickToTypes.pdf:
        const { pdf } = globalFiles ? globalFiles : {}
        if (pdf && pdf[pdf_name]) {
          const _currentPdf = pdf[pdf_name]
          if (_currentPdf && _currentPdf.urls && _currentPdf.urls.full) {
            window.open(_currentPdf.urls.full, '_blank')
          }
        }
        break;

      default:
        switch (key) {
          case allPageTypes.appTicketing:
          case allPageTypes.appUserRequests:
          case allPageTypes.appUsersManager:
          case allPageTypes.chat:
          case allPageTypes.conversations:
          case allPageTypes.credentialScan:
          case allPageTypes.favorites:
          case allPageTypes.gallery:
          case allPageTypes.imageMapping:
          case allPageTypes.notes:
          case allPageTypes.notifications:
          case allPageTypes.notificationsManager:
          case allPageTypes.ticketingManager:
          case allPageTypes.ticketingScan:
          case allPageTypes.topicsManager:
          case allPageTypes.voting:
          case allPageTypes.votingManager:
            if (useItemWizard) {
              setShowFullPage(key)
            } else {
              pushSimple(viewItem, itemKey)
            }
            break;

          case allPageTypes.survey:
            window.open(surveyUrl, '_blank')
            break;

          case allPageTypes.weather:
            if (weatherLink) {
              // https://weather.com/weather/today/l/15235:4:US"
              // https://weather.com/weather/today/l/98000:4:MNXX0002
              // https://weather.com/weather/today/l/98000:4:US
              let url = weatherLink
              url = weatherCode ? url.replace('ZIPCODE', weatherCode) : url.replace('ZIPCODE', weatherZip)
              if (weatherCode) {
                url = url.replace(':4:US', '')
              } else {
                if (weatherCountryCode) { url = url.replace('US', weatherCountryCode) }
              }
              window.open(url, '_blank')
            }
            break;
          default:
            pushSimple(viewItem, itemKey)
        }
    }
  }



  const gcProps = { defaultSnC, handleIconClick, textLimit }

  const getGridItems = (items, hidden) => gridItemsMain({ parentContext, frameworkContext, gridPageItems: items, defaultSnC, pageIcons, gcProps, hidden })
  const getGridItemsAppAdmin = (a) => gridItemsAppAdmin({ parentContext, pageIcons, defaultSnC, gcProps, allows: a })
  const getGridItemsAppUser = (a) => gridItemsAppUser({ parentContext, pageIcons, defaultSnC, gcProps, allows: a })
  const getGridItemsAdmin = () => gridItemsAdmin({ parentContext, pageIcons, defaultSnC, gcProps })
  const getGridItemsTm = (a) => gridItemsTickets({ parentContext, pageIcons, allows: a, gcProps })
  // const getGridItemsCd = (a) => gridItemsCredentialing({ parentContext, pageIcons, allows: a, gcProps })
  // const getGridItemsVt = (a) => gridItemsVoting({ parentContext, pageIcons, allows: a, gcProps })
  // const getGridItemsSur = (a) => gridItemsSurvey({ parentContext, pageIcons, allows: a, gcProps })

  useEffect(() => {

    // const x = fsfn_updateSheetRangeValues('1E3Z93nTHf6yNPgpwbBW45flQEqHvr3O6MvkXsLEfub8', 'test', '1', { name: 'Bob Walers', description: 'Test' })
    // console.log('x', x)

    if (pageIcons) {
      setGridItemGroups({ page: pageIcons })
    } else {
      const _allows = {
        ao: appUserAccess && (appUserAccess.accessLevel >= _defaultAdminLevels.adminOnly),
        aao: appUserAccess && (appUserAccess.accessLevel >= _defaultAdminLevels.appAdminOnly),
        atm: false,
        ats: false,
        avt: true,
        asr: true,
        au: false,
      }

      _allows.atm = _allows.ao || appUser_fns.validateAccess_item(null, null, ticketingManagers)
      _allows.ats = _allows.ao || appUser_fns.validateAccess_item(null, null, scanningManagers)

      let gpis = viewItems_page
      appUser_fns.validateAccess_items(gpis)

      gpis = !pageIcons && gpis ? filterObject(gpis, showProp) : gpis
      gpis = !pageIcons && gpis ? filterObject(gpis, 'viewPermissionType', [gEnums.viewPermissionTypes.allow, gEnums.viewPermissionTypes.allowToAppUserAdmin]) : gpis
      gpis = !pageIcons && gpis ? sortObject(gpis, 'position') : gpis

      // let aaa = !pageIcons && viewItems_page ? _.filter(viewItems_page, i => i.show !== true) : gpis
      let gpish = !pageIcons && viewItems_page ? _filter(viewItems_page, 'show', true, true) : gpis

      if (gpish) { gpish = _.sortBy(gpish, 'key') }

      const _gridItemGroups = {
        page: getGridItems(gpis),
        pageHidden: _allows.ao && allowHiddenIcons ? getGridItems(gpish, true) : [],
        admin: _allows.ao ? getGridItemsAdmin(_allows) : [],
        appAdmin: _allows.aao ? getGridItemsAppAdmin(_allows) : [],
        appUser: getGridItemsAppUser(_allows),
        ticketing: _allows.atm ? getGridItemsTm(_allows) : [],
        ready: true,
      }
      setGridItemGroups(_gridItemGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, viewItems_page, profileValidated, accessLevel, appUserAccess]);

  const handleShowWizard = () => setShowFullPage(!showFullPage)

  const getGridRows = (gridItems, dt) => {
    let colCount = 0
    const gridRows = []
    let rowCols = []
    gridItems.forEach(col => {
      rowCols.push(col)
      colCount++
      if (colCount >= _iconsPerRow) {
        gridRows.push(rowCols)
        colCount = 0
        rowCols = []
      }
    })
    if (rowCols.length > 0) { gridRows.push(rowCols) }
    return gridRows
  }

  const gridContainer = (contents, appUserContents, appAdminContents, ticketingContents, adminContents, hiddenContents) => {
    return <div key={uniqueKey('iggc')} {...backgroundSaC}>
      <div className={'grid-icons-container'}  {...segProps}>
        <div className={defaultSnC.className}>
          {contents}
          {appUserContents}
          {appAdminContents}
          {ticketingContents}
          {adminContents}
          {hiddenContents}
        </div>
      </div>
    </div>
  }

  const content = () => {
    const { ready, page, pageHidden, appAdmin, appUser, admin, ticketing } = gridItemGroups ? gridItemGroups : {}
    if (ready) {
      return gridContainer(
        <IconsGridGrid gridItems={page} iconsPerRow={_iconsPerRow} />,
        appUser.length > 0 && <IconsGridGrid gridItems={appUser} iconsPerRow={_iconsPerRow} dividerText={displayName} dividerIcon={'user'} />,
        appAdmin.length > 0 && <IconsGridGrid gridItems={appAdmin} iconsPerRow={_iconsPerRow} dividerText={'App Admin Only'} />,
        ticketing.length > 0 && <IconsGridGrid gridItems={ticketing} iconsPerRow={_iconsPerRow} dividerText={'Ticketing'} />,
        admin.length > 0 && <IconsGridGrid gridItems={admin} iconsPerRow={_iconsPerRow} dividerText={'Admin Only'} />,
        pageHidden.length > 0 && <IconsGridGrid gridItems={pageHidden} iconsPerRow={_iconsPerRow} dividerText={'Admin Hidden'} />,
      )
    } else {
      return <div></div>
    }
  }

  const gridContent = () => {
    if (showTransition) {
      return <MotionComponent transition={transition}>
        {content()}
      </MotionComponent>
    } else {
      return content()
    }
  }

  const settingsReveal = (c) => {
    return <Reveal animated='small fade'>
      <Reveal.Content visible>
        {c}
      </Reveal.Content>
      <Reveal.Content hidden>
        <div>Grid Settings</div>
      </Reveal.Content>
    </Reveal>
  }

  const useReveal = false

  const getRowElements = (gridRow) => gridRow.map(gridItem => <Grid.Column>{gridItem.element}</Grid.Column>)
  const getRowDataElements = (gridRow) => {
    const des = []
    gridRow.forEach(gridItem => {
      des.push(gridItem.viewItem)
    })
    return des
  }

  // return <Sorter />

  if (showFullPage) {
    return <FullPageContent fullPageType={showFullPage} handleCancel={handleShowWizard} />
  } else {
    if (sortingOn) {
      const gridRows = getGridRows(getGridItems(true))
      const ddGroupz = {}
      gridRows.forEach((gr, index) => {
        ddGroupz['sortedItems_' + index] = {
          dataItems: getRowDataElements(gr),
          elements: getRowElements(gr),
          horizontal: true
        }
      })
      const dd = <DragDropper ddGroups={ddGroupz} horizontal={true} allowSort={true} portalCn={'column fx'} wrapperCns={'ui grid three column'} />
      return gridContainer(dd)
    } else {
      return useReveal ? settingsReveal(gridContent()) : gridContent()
    }
  }
}

export default IconsGrid

// const Sorter = () => {
//   const [items, setItems] = useState(initialItems);

//   return (
//     <Reorder.Group axis="y" onReorder={setItems} values={items}>
//       {items.map((item) => (
//         <Item key={item} item={item} />
//       ))}
//     </Reorder.Group>
//   );
// }

// const Item = (item) => {
//   const y = useMotionValue(0);

//   return (
//     <Reorder.Item value={item} id={item}  >
//       <span>{item}</span>
//     </Reorder.Item>
//   );
// };