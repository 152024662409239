import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Divider, Header, Icon, Label, Segment } from 'semantic-ui-react';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { findClosestDate } from '../../../../global/common/filtering';
import { uniqueKey } from '../../../../global/common/keys';
import { sortObj } from '../../../../global/common/sorting';
import { CalMonths } from '../../../../global/components/calendar/CalMonths';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper from '../../../../global/wrappers/Wrapper';
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { getSectionColor } from '../../create/sectionColors';
import SectionStandings from '../standings/SectionStandings';
import MatchCreate from './MatchCreate';
import MatchWrapper, { sportsMatchViewTypes } from './MatchWrapper';
import { gEnums } from '../../../../global/enums/globalEnums';

// const key_level = 'msl-level'
// const key_away = 'ms-match-away'
// const key_home = 'ms-match-home'
// const key_round = 'ms-match-round'
// const key_loc = 'ms-match-loc'

const WAIT_INTERVAL = 500

const _usePlayoff = false
const _allowByes = true

const matchHeaderIcons = {
  today: 'calendar check outline',
  calendar: 'calendar alternate outline'
}

const MatchListSelected = (props) => {

  const { tournamentType, teamKey, latestMatchesOnly, nextMatchOnly, forPlayoff } = props ? props : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_fns } = sportsSeasonContext ? sportsSeasonContext : {}
  const { matches_info, matchScheduleDisplayType, useScoreBlock, _startHour } = sportsSeason_state ? sportsSeason_state : {}
  const { matchDateKeys_count, matchDateKeys, levelLatestDates } = matches_info ? matches_info : {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state } = seasonMatchesContext ? seasonMatchesContext : {}
  const { pathViews, dateMatchResults, dateMatches, commonMatches, sepBySection, isTournament, poolMatches, levelDateGroup, levelDateKey } = seasonMatches_state
  const { levelDateMatches, sectionDateMatches, favMatches, matchDate } = dateMatchResults ? dateMatchResults : {}

  const pds = _.filter(sectionDateMatches, { isPlayoff: true })
  const playoffDates = pds && Object.keys(pds).length > 0

  const showDateHeader = matchScheduleDisplayType === gEnums.matchScheduleDisplayTypes.allDates

  const scheduleTypes = {
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: teamKey || (pathViews && pathViews.teams) ? true : false,
    common: commonMatches ? true : false
  }

  const showByes = _allowByes && (scheduleTypes.team)

  const [availableTeams, setAvailableTeams] = useState()
  const [matchesReady, setMatchesReady] = useState(true)
  const [scrollBehavior, setScrollBehavior] = useState("instant")
  const [selectedDate, setSelectedDate] = useState()
  const [selectedDateChanged, setSelectedDateChanged] = useState()
  const [showCalendar, setShowCalendar] = useState()
  const [showMissingOnly, setShowMissingOnly] = useState()
  const [showNewMatch, setShowNewMatch] = useState()

  const _selectedDate = selectedDate ? selectedDate : levelDateKey

  const scollToRef = useRef();

  useEffect(() => {
    if (scollToRef && scollToRef.current && !forPlayoff) {
      const timer = setTimeout(() => handleDone(), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedDateChanged]);

  // useEffect(() => {
  //   if (levelDateKey) {
  //     handleSelectedDate(levelDateKey)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [levelDateKey]);

  const handleSelectedDate = (d) => {
    if (d === 'Missing') {
      setShowMissingOnly(!showMissingOnly)
    } else {
      let _d = !matchDateKeys[d] ? findClosestDate(d, matchDateKeys) : d
      setSelectedDate(_d)
      setSelectedDateChanged(!selectedDateChanged)
      setScrollBehavior('smooth')
      setShowMissingOnly(false)
    }
    setShowCalendar(false)
  }

  const handleShowCalendar = () => setShowCalendar(!showCalendar)
  const handleDone = () => {
    scollToRef.current.scrollIntoView({ behavior: scrollBehavior })
    // setMatchesReady(true)
  }

  const handleGetAvailableTeams = () => {
    if (availableTeams) {
      setAvailableTeams()
    } else {
      const ta = sportsSeason_fns.getTeamsAvailableByDate(matchDate)
      setAvailableTeams(_.sortBy(ta, 'name'))
    }
  }

  const handleSetShowNewMatch = () => setShowNewMatch(!showNewMatch)
  // const handle_matchClick = (key, d) => handleClick(viewItem, key, null, d)

  const newMatchIcon = () => <Icon color={'blue'} name={'add'} bordered onClick={() => { handleSetShowNewMatch() }} />
  const teamAvailableIcon = () => <Icon color={'blue'} name={'search'} bordered onClick={() => { handleGetAvailableTeams() }} />
  const calendarIcon = () => <Icon color={'grey'} name={matchHeaderIcons.calendar} bordered onClick={() => { handleShowCalendar() }} />
  const todayIcon = () => <Icon color={'grey'} name={matchHeaderIcons.today} bordered onClick={() => { handleSelectedDate(formatItem(formatTypes.shortestDate, new Date())) }} />

  const singleMatch = (segs, match) => {

    const { complete, isPlayoff, isBye, key, id } = match ? match : {}

    const _isPlayoff = isPlayoff && _usePlayoff ? true : false

    const keyy = key ? key : id

    let wcn = 'schedule-match'

    if (latestMatchesOnly) { wcn += ' lmo' }
    if (useScoreBlock) { wcn += ' blocked' }
    if (complete) { wcn += ' complete' }

    if (tournamentType) {
      wcn += ' pool'
    } else if (scheduleTypes.match) {
      wcn += ' match'
    } else if (scheduleTypes.team && !_isPlayoff) {
      wcn += ' teams'
    } else {
      wcn += ' section'
    }

    if (playoffDates || isPlayoff) {
      wcn += ' playoff'
      if (isBye) {
        // wcn += ' bye'
      }
    }

    if (!isBye || showByes) {
      const m = <MatchWrapper
        key={uniqueKey('mls', keyy)}
        sportsMatchViewType={sportsMatchViewTypes.single}
        match={match}
        wcn={wcn}
        tournamentType={tournamentType}
        teamKey={teamKey}
        latestMatchesOnly={latestMatchesOnly}
        nextMatchOnly={nextMatchOnly}
      />
      segs.push(m)
    }
  }

  const matchList = (matches, showFavs, isFuture) => {

    const segs = []

    if (_.isArray(matches)) {
      if (matches && matches.length > 0) {
        matches.forEach(match => {
          singleMatch(segs, match, showFavs)
        })
      }
    } else {
      if (matches && Object.keys(matches).length > 0) {
        Object.keys(matches).forEach(key => {
          const match = matches[key]
          singleMatch(segs, match, showFavs)
        })
      }
    }

    return segs.length > 0 ? segs : <Segment key={uniqueKey('mls', 'ml')} className={'seg-ph-full'} placeholder style={{ height: '100%', textAlign: 'center', fontWeight: 'bold' }}>{isFuture ? 'No Upcoming Matches' : 'No Matches'}</Segment>
  }

  const sectionLabel = (section) => <Label circular size={'mini'} color={getSectionColor(section)}></Label>

  const divider = (value, icon, index, sectionKey) => <Divider key={uniqueKey('mls', 'tm', index, value)} horizontal>
    <Header as='h5'>
      {icon && <Icon name={icon} />}
      {value}
      {sectionKey && sectionLabel(sectionKey)}
    </Header>
  </Divider>

  const dateHeader = (matchDate) => <div key={uniqueKey('mls', 'd', 'sl', matchDate)} ref={(matchDate === _selectedDate) ? scollToRef : null}>
    <div>{formatItem(formatTypes.shorterDate, matchDate)}</div>
    <div>
      {todayIcon()}
      {calendarIcon()}
    </div>
  </div>

  const noMatchesDiv = () => ""

  const sectionDateSegs = (matches, segsArr, levelKey, matchDate, index) => {

    // group the matches by section
    let _matches_section = levelKey ? _.groupBy(matches, 'sections') : _.groupBy(matches, 'sections')
    const _matches_section_sorted = sortObj(_matches_section)
    // loop Sections  
    const sectionSegs = []

    Object.keys(_matches_section_sorted).forEach(sectionKey => {

      const keyy = levelKey + '-' + sectionKey

      const sectionMatches = _matches_section[sectionKey]

      const sectionMatchesSorted = _.sortBy(sectionMatches, 'key')
      const sectionMatchSegs = matchList(sectionMatchesSorted, true)

      sectionSegs.push(<div key={uniqueKey('mls', 'lds', keyy)} className={'schedule-section'}>
        {sectionKey && sectionKey !== 'undefined' && divider(_.startCase(sectionKey, index), null, null, sectionKey)}
        <div key={uniqueKey('mls', 'lds', 'st')} className={'schedule-section-teams'}>{sectionMatchSegs}</div>
      </div>)
    })

    if (sectionSegs.length > 0) {
      if (matchDate) {
        segsArr.push(<div key={uniqueKey('mls', matchDate)} className={'sdw'}>
          {dateHeader(matchDate)}
          <div key={uniqueKey('mls', 'lds', 'sl', matchDate)} className={'schedule-level'}>
            <div key={uniqueKey('mls', 'lds', 'sls', matchDate)} className={'schedule-level-section'}>{sectionSegs.length > 0 ? sectionSegs : noMatchesDiv()}</div>
          </div>
        </div>)
      } else {
        segsArr.push(<div key={uniqueKey('mls', 'lds', 'sl')} className={'schedule-level'}>
          <div key={uniqueKey('mls', 'lds', 'sls')} className={'schedule-level-section'}>{sectionSegs.length > 0 ? sectionSegs : noMatchesDiv()}</div>
        </div>)
      }
    }

  }

  const favSegs = (favSegs) => {
    if (favMatches) {
      const favMatchSegs = matchList(favMatches)
      favSegs.unshift(<div key={uniqueKey('mls', 'lds', 'f')} className={'schedule-section fav'}>
        <Divider horizontal>{'Following'}</Divider>
        <div key={uniqueKey('mls', 'lds', 'fst')} className={'schedule-section-teams'}>{favMatchSegs}</div>
      </div>)
    }
  }

  const todaysMatches = () => {

    const levelSegs = []
    const _favSegs = []

    favSegs(_favSegs)

    if (sepBySection) {
      switch (matchScheduleDisplayType) {
        case gEnums.matchScheduleDisplayTypes.allDates:
          console.log('levelDateGroup', levelDateGroup)
          if (levelDateGroup) {
            Object.keys(levelDateGroup).forEach((dgKey, index) => {
              const levelGroupDate = levelDateGroup[dgKey]
              const { matchDate, matches } = levelGroupDate ? levelGroupDate : {}
              sectionDateSegs(matches, levelSegs, null, matchDate, index)
            })
          }
        default:
          sectionDateSegs(sectionDateMatches, levelSegs)
      }
    } else if (levelDateMatches) {
      Object.keys(levelDateMatches).forEach(levelKey => {
        const sectionSegs = []
        let sections = _.groupBy(levelDateMatches[levelKey], 'sections')
        const ss = sortObj(sections)
        Object.keys(ss).forEach(sectionKey => {
          const sectionMatches = sections[sectionKey]
          sectionDateSegs(sectionMatches, sectionSegs, levelKey)
        })

        levelSegs.push(<div key={uniqueKey('mls', 'tm')} className={'schedule-level'}>
          <div key={uniqueKey('mls', 'tm', 'h')} className={'schedule-level-header'}>{levelKey}</div>
          <div key={uniqueKey('mls', 'tm', 'ls')} className={'schedule-level-section'}>{sectionSegs}</div>
        </div>)
      })
    }

    let cn = 'schedule-dates'
    if (!matchesReady) { cn += ' nr' }

    return <div key={uniqueKey('mls', 'sd')} className={cn}>
      {showDateHeader && <div key={uniqueKey('mls', 'ti')} className={'schedule-dates-header'}>{matchDate}{newMatchIcon()}{teamAvailableIcon()}</div>}
      <div key={uniqueKey('mls', 'sl')} className={'schedule-levels'}>{_favSegs}{levelSegs}</div>
    </div>
  }

  const todaysPlayoffMatches = () => {
    const sectionMatchesSorted = _.sortBy(sectionDateMatches, 'matchNumber')
    const sectionMatchSegs = matchList(sectionMatchesSorted)
    return <div key={uniqueKey('mls', 'tpm')} className={'schedule-dates'}>
      <div key={uniqueKey('mls', 'tpm', 'sl')} className={'schedule-levels'}>{sectionMatchSegs}</div>
    </div>
  }

  const wrapper_availableTeams = () => <Wrapper
    content={<SectionStandings sectionOpts={{ teams: availableTeams }} caption={'Idle Teams'} />}
  ></Wrapper>

  const fpw_availableTeams = () => <FullPageWrapper
    content={wrapper_availableTeams()}
    handleCancel={handleGetAvailableTeams}
    topperCaption={'Idle Teams'}
    topperCaption2={matchDate}
  ></FullPageWrapper>

  const content = () => {
    if (showCalendar) {
      const sidebarItem = {
        items: matchDateKeys,
        itemz: matchDateKeys_count,
        keyAs: "matchDates",
        showToday: true,
        useStartCase: undefined,
        // showMissing: true,
      }

      return <Wrapper
        content={<CalMonths sidebarItem={sidebarItem} handleClick={handleSelectedDate} handleClose={handleShowCalendar} />}
        wrapperCss={{ container: 'app-dark-mode' }}
      >
      </Wrapper>
    } else {
      if (availableTeams) {
        return fpw_availableTeams()
      } else if (isTournament) {
        const pms = _.sortBy(poolMatches, 'matchNumber')
        return <div className={'tournament-match-container'}>{matchList(pms)}</div>
      } else {
        if (scheduleTypes.common) {
          return matchList(commonMatches)
        } else if (scheduleTypes.match) {
          // MATCH  
          return matchList(dateMatches)
        } else if (scheduleTypes.team) {
          if (!latestMatchesOnly && !nextMatchOnly) {
            const xxx = Object.values(dateMatches)
            const today_before = new Date().setHours(_startHour, 0, 0, 0);
            const today_after = new Date().setHours(_startHour, 0, 0, 0);
            const matchesBeforeToday = xxx.filter(match => new Date(match.startDate).setHours(_startHour, 0, 0, 0) < today_before);
            const matchesTodayAndBeyond = xxx.filter(match => new Date(match.startDate).setHours(_startHour, 0, 0, 0) >= today_after);
            return <div>
              {matchesBeforeToday && matchList(matchesBeforeToday)}
              {matchesTodayAndBeyond &&
                <div>
                  <div ref={scollToRef} className='upcoming-matches'>Upcoming Matches</div>
                  {matchList(matchesTodayAndBeyond, null, true)}
                </div>
              }
            </div>
          } else {
            return matchList(dateMatches)
          }
        } else if (playoffDates) {
          return todaysPlayoffMatches()
        } else {
          // LEVEL 
          return showNewMatch ? <MatchCreate
            handleSetShowNewMatch={handleSetShowNewMatch}
            shows={{ showTeams: true, showDate: true, showLocation: false }}
            matchDate={matchDate} />
            :
            todaysMatches()
        }
      }
    }
  }

  return content()

}

export default MatchListSelected