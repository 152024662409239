import { _animateTypes } from "./AnimateComponent";

/**
 * 
 * @param {string} animateType 
 * @param {object} transitionItems 
 * @param {object} sty 
 * @returns 
 */
export const animationVariants = (animateType, transitionItems, sty) => {

  // set the initial values
  const values = {
    duration: .1,
    scale: { end: 1, start: 1 },
    opacity: { end: 1, start: 1 },
    y: { end: 0, start: 0 },
  }

  // set the initial useValues
  const useValues = {
    opacity: false,
    scale: false,
    exit: false,
    y: false,
  }

  const _transitionItems = transitionItems ? transitionItems : getDefaultTransitionItems()

  const animationItem = ammendAnimationItem(animateType, _transitionItems, useValues)
  const { useTransitions, showPendingDimmer } = animationItem ? animationItem : {}

  ammendValue(animationItem, useValues, values)

  const variants = {
    initial: { // nount
      scale: useValues.scale ? values.scale.start : null,
      opacity: useValues.opacity ? values.opacity.start : null,
      y: useValues.y ? values.y.start : null
    },
    animate: {
      scale: values.scale.end,
      opacity: values.opacity.end,
      y: values.y.end,
      transition: { duration: values.duration },
    },
    exit: useValues.exit ?
      {
        scale: useValues.scale ? values.scale.end : null,
        opacity: useValues.opacity ? values.opacity.end : null,
      }
      :
      null,
  }

  return { variants, showPendingDimmer, useTransitions }

}

/**
 * 
 * @param {string} animateType 
 * @param {object} transitionItems 
 * @param {boolean} useValues 
 * @returns 
 */
const ammendAnimationItem = (animateType, transitionItems, useValues) => {

  const {
    component,
    data,
    element,
    page,
    pageItem,
    pageItemElement,
    image,
    map: mapp,
    splash,
    title,
    fullPageWrapper,
    topBanner,
  } = transitionItems ? transitionItems : {}

  let animationItem;

  switch (animateType) {

    case _animateTypes.start:
      // case _animateTypes.splash:
      useValues.opacity = true
      animationItem = {
        useOpacity: true,
        opacityValue: 9,
        duration: .1
      }
      break;

    case _animateTypes.splash:
      useValues.opacity = true
      animationItem = splash ? splash : {
        useOpacity: true,
        opacityValue: 9,
        duration: .1
      }
      break;

    case _animateTypes.pageItemElement:
      animationItem = pageItemElement
      break;

    case _animateTypes.component:
      animationItem = component
      break;

    case _animateTypes.data:
      animationItem = data
      break;

    case _animateTypes.fullPageWrapper:
      animationItem = fullPageWrapper
      break;

    case _animateTypes.image:
      animationItem = image
      break;

    case _animateTypes.map:
      animationItem = mapp
      break;

    case _animateTypes.page:
      animationItem = page
      useValues.exit = true
      break;

    case _animateTypes.pageItem:
      animationItem = pageItem
      break;

    case _animateTypes.title:
      animationItem = title
      break;

    case _animateTypes.topBanner:
      animationItem = topBanner
      break;

    default:
    //nothing
  }

  return animationItem

}

const ammendValue = (item, useValues, values) => {

  const { useScale, useOpacity, opacityValue, scaleValue, duration } = item ? item : {}

  useValues.opacity = useOpacity
  useValues.scale = useScale

  values.scale.start = scaleValue ? Math.abs((scaleValue * .1).toFixed(2)) : .98
  values.opacity.start = opacityValue ? Math.abs((opacityValue * .1).toFixed(2)) : .9

  values.duration = duration ? duration : .1

}

/**
 * 
 * @returns the default transition items
 */
const getDefaultTransitionItems = () => {
  return {
    component: {
      duration: .4,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useTransitions: true,
    },
    data: {
      duration: .2,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: false,
      useTransitions: false,
    },
    fullPageWrapper: {
      duration: .2,
      opacityValue: 9,
      useOpacity: true,
      useTransitions: true,
    },
    image: {
      duration: .6,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: true,
      useTransitions: true,
    },
    page: {
      duration: .5,
      opacityValue: 8.0,
      scaleValue: 9.6,
      useOpacity: true,
      useScale: true,
      useTransitions: true,
    },
    pageItem: {
      duration: .5,
      opacityValue: 8.0,
      scaleValue: 9.6,
      useOpacity: true,
      useScale: true,
      useTransitions: true,
    },
    pageItemElement: {
      duration: .2,
      opacityValue: 8,
      scaleValue: 9.8,
      useOpacity: true,
      useScale: false,
      useTransitions: false,
    },
    topBanner: {
      duration: .8,
      opacityValue: 8,
      scaleValue: 9.1,
      useOpacity: true,
      useTransitions: true,
    }
  }
}