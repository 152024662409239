import { getStorage, ref } from "firebase/storage";
import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Button, Icon, Message } from 'semantic-ui-react';
import { getAppUserAccess } from "../../auth/appUserAccessPermissions";
import { DataModifyContext } from "../../cnr/contexts/DataModifyContext";
import { ParentContext } from "../../cnr/contexts/ParentContext";
import { UiItemContext } from "../../cnr/contexts/UiItemContext";
import UploadProvider from "../../cnr/contexts/UploadContext";
import { storageGalleryProps, uploadHandlers, uploadInitialState, uploadReducer } from '../../cnr/reducers/UploadReducer';
import UiSaveButtons from "../../components/buttons/UiSaveButtons";
import { appIconTypes } from "../../enums/appIconTypes";
import { gEnums } from '../../enums/globalEnums';
import GenericIcon from "../../genericControls/GenericIcon";
import { DataModificationsContext } from "../../pageItem/modification/UiDataModifications";
import { dataModificationTypes } from "../../viewSettings/enums/itemActionTypes";
import { handleOpenDb } from "../../viewSettings/helpers/settingsLinks";
import FullPageWrapper from "../../wrappers/FullPageWrapper";
import Wrapper, { wrapperTypes } from "../../wrappers/Wrapper";
import { DataManagementContext } from "../viewers/DataManagementViewer";
import BulkUploader from "./BulkUploader";
import SingleImageUploader from "./SingleImageUploader";
import { UploadFileButton, UploadSelectButton } from "./UploadButton";

/**
 * This is a single item uploader with the select button that opens up a file directory
 * @param {*} props (storageLocationType, fileViewer_handlers, itemData: altItemData, forBulk)
 * @returns BulkUploader OR SingleImageUploader
 */
const Uploader = (props) => {

  const {
    fileViewer_handlers,
    handleCancel,
    itemData: altItemData,
    storageLocationType,
    useFull,
    fullMode,
  } = props

  console.log('props', props)

  // appUser 
  // event 
  // fullGallery 
  // pageDirect 
  // pageGallery 
  // profile 
  // profileGallery 

  let { appStorageCollection, storageType } = props

  if (!storageType) { storageType = gEnums.storageTypes.image }

  const _uploadButtonCaption = 'Upload (' + _.startCase(storageLocationType) + '/' + _.startCase(storageType) + ')'

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state, storage_state } = states
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { productionSettings, storageOptions } = aps_global ? aps_global : {}
  const { appUserCollection, useClientProfileImage } = aps_appUserSettings ? aps_appUserSettings : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { accessLevel, isAdminOrSuper } = appUserAccess ? appUserAccess : {}

  // dataManagementContext
  const dataModificationContext = useContext(DataModificationsContext)
  const { dataModifications_state } = dataModificationContext ? dataModificationContext : {}
  const { appData: appData_dmod } = dataModifications_state ? dataModifications_state : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ? dataManagementContext : {}
  const { viewItem, appData: appData_dman, dataManagementType } = dataManagement_state ? dataManagement_state : {}
  const { key: viewItemKey_dm } = viewItem ? viewItem : {}

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_handlers, dataModify_state } = dataModifyContext ? dataModifyContext : {}
  const { dataModificationType } = dataModify_state ? dataModify_state : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { viewItem: viewItem_item, dataItem } = item_state ? item_state : {}
  const { key: viewItemKey } = viewItem_item ? viewItem_item : {}

  // papsContext 
  const { storagePaths, pathViews, storageRootPath, view, viewKey, lastView, lastViewKey } = paps_state

  const allowShowRefPath = ((accessLevel >= gEnums.authLevels.admin.value) || (isAdminOrSuper))
  const appData = appData_dmod ? appData_dmod : appData_dman

  let _showBulkUploader;

  switch (dataManagementType) {
    case dataModificationTypes.bulkImageUpload:
      _showBulkUploader = true
      break;
    default:
      break;
  }

  switch (dataModificationType) {
    case dataModificationTypes.bulkImageUpload:
      _showBulkUploader = true
      break;
    default:
      // nothing
      break;
  }

  const _viewItemKey = viewItemKey_dm ? viewItemKey_dm : viewItemKey

  // dataModifyContext 
  const _sp = {
    appUserAccess,
    storageLocationType,
    storageType: storageType ? storageType : gEnums.storageTypes.image,
    storagePaths,
    viewItem_key: view,
    viewItem_id: viewKey,
    useDarkMode: false,
    _viewItemKey,
    dataItem,
    storageOptions,
    appUserCollection,
    lastView,
    useClientProfileImage,
  }

  const sgps = storageGalleryProps(_sp)
  const { useDarkMode, storageRef } = sgps ? sgps : {}

  const viewUploadProps = { view: lastView, viewKey: lastViewKey }
  if (storageLocationType === gEnums.storageLocationTypes.profiles) { viewUploadProps.imageUrlOnly = true }

  // local state
  const init_state = {
    altItemData,
    appStorageCollection,
    appUserCollection,
    dataModificationType,
    forBulk: _showBulkUploader,
    handleCancel,
    handlers,
    lastView,
    pathViews,
    storageOptions,
    productionSettings,
    useClientProfileImage,
    states,
    storageLocationType,
    storageRef,
    storageRootPath,
    storageType,
    viewItemKey: _viewItemKey,
    viewListData: appData,
    viewUploadProps,
  }

  const [upload_state, uploadDispatch] = useReducer(uploadReducer, uploadInitialState(init_state));
  const upload_handlers = uploadHandlers(uploadDispatch, upload_state)
  const { formData, avatarEditor, selectedFolderFiles, manifestFiles, updating, imageImage, confirmation } = upload_state ? upload_state : {}

  const _storage = getStorage()
  const _storageRef = ref(_storage, storageRef)

  const inverted = storageLocationType === gEnums.storageLocationTypes.event ? true : false

  let _selectedFiles;

  if (manifestFiles && manifestFiles.length > 0) {
    _selectedFiles = manifestFiles
  } else if (selectedFolderFiles && selectedFolderFiles.length > 0) {
    _selectedFiles = selectedFolderFiles
  }

  const accept = '*'

  let isCroppable = false

  const [useAvatar, setAvatar] = useState(isCroppable)
  const [showPath, setShowPath] = useState()
  const [uploadReady, setUploadReady] = useState()

  useEffect(() => {
    let _uploadReady = (_selectedFiles && _selectedFiles.length > 0) ? true : false
    if (imageImage) { _uploadReady = true }
    switch (storageType) {
      case gEnums.storageTypes.pdf:
        const { name } = formData ? formData : {}
        if (!name) {
          _uploadReady = false
        }
        break;
      default:
      // nothing
    }
    setUploadReady(_uploadReady)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageImage, _selectedFiles, formData]);


  /** Starts the process of uploading the file to Firebase Storage using the upload_handlers */
  const handleImageUpload = () => {
    upload_handlers.handleStartUpdate()
    if (useAvatar) {
      const canvasScaled = avatarEditor.getImageScaledToCanvas()
      upload_handlers.handleUpload_manual(canvasScaled, useAvatar)
    } else {
      upload_handlers.handleUpload_manual()
    }
  }

  const handleImageDecline = () => {
    setShowPath()
    upload_handlers.handleDecline()
  }

  const handleOpenStorage = () => handleOpenDb(pathViews, true)

  // const blobToFile = (blob, fileName) => new File([blob], fileName, { type: blob.type })

  // selected the file for upload. This will show the images on  the UI
  // const handleFileSelect = (e) => upload_handlers.handleImageFileSelect(e, storageType)

  /** Handles the uploading of files to firebase storage */
  // const fileUploader = () => <FileUploader
  //   hidden
  //   accept={accept}
  //   name="avatar"
  //   storageRef={_storageRef}
  //   // controls the uploading
  //   onUploadStart={upload_handlers.handleStartUpdate}
  //   onUploadError={upload_handlers.handleUploadError}
  //   // onUploadSuccess={allowMultiple ? upload_handlers.handleUploadSuccess : upload_handlers.handleUploadSuccess}
  //   onProgress={upload_handlers.handleProgress}
  //   onChange={handleFileSelect}
  //   multiple={true}
  // />

  /**
   * 
   * @returns the button that opends the fileUploader
   */
  // const selectButton = () => <div className="upload-btn-wrapper">
  //   <button className="btn ui button blue">Select</button>
  //   {fileUploader()}
  // </div>

  const btnProps = { inverted: useDarkMode }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const killIcon = () => gi('icn-kill', appIconTypes.arrowLeft, fileViewer_handlers.handleShowUpload, null, { ...btnProps, fl: true })
  const backIcon = () => gi('icn-back', appIconTypes.arrowLeft, dataModify_handlers.handleCloseImageUploader, null, { ...btnProps, fl: true })

  /** buttons that commit or reject the current image */
  const upload_buttons = () => <UiSaveButtons
    cancel={{ oc: handleImageDecline }}
    save={{ oc: handleImageUpload, caption: _uploadButtonCaption, disabled: !uploadReady }}
  ></UiSaveButtons>

  const avatarButton = () => <Button icon basic color={useAvatar ? 'blue' : 'grey'} onClick={() => setAvatar(!useAvatar)} style={{ float: 'left' }} size={'small'}>
    <Icon name={'crop'} />
  </Button>

  /** Fragment containing an upload button AND the FileUploader */
  const uploadButtons = () => <React.Fragment>
    {upload_buttons()}
    <UploadFileButton accept={accept} upload_handlers={upload_handlers} _storageRef={_storageRef} storageType={storageType} />
  </React.Fragment>

  const selectButtons = () => (
    <div className={'uploader-header'}>
      {fileViewer_handlers && killIcon()}
      {dataModify_handlers && backIcon()}
      {isCroppable && avatarButton()}
      {!uploadReady && <UploadSelectButton accept={accept} upload_handlers={upload_handlers} _storageRef={_storageRef} storageType={storageType} />}
      {!_showBulkUploader && uploadReady && uploadButtons()}
    </div>
  )

  /**
   * 
   * @returns BulkUploader OR SingleImageUploader
   */
  const contents = () => {
    if (_showBulkUploader) {
      return <BulkUploader handleCancel={handleCancel} />
    } else {
      return <SingleImageUploader
        showPath={showPath}
        storageRef={storageRef}
        useAvatar={useAvatar}
        viewItemKey={_viewItemKey}
        storageType={storageType}
        inverted={inverted}
      />
    }
  }

  // const message = () => <Message icon size={'tiny'} info >
  //   <Icon name='database' color={'blue'} size="tiny" onClick={() => handleOpenStorage()} />
  //   <Message.Header>{'Storage Path'}</Message.Header>
  //   <p>{storageRef}</p>
  // </Message>

  const message = () => <Message
    info={true}
    size='tiny'
    icon='database'
    header='Storage Path'
    content={storageRef}
    onClick={() => handleOpenStorage()}
  />

  const wrapper = () => <Wrapper
    header={allowShowRefPath && storageRef && message()}
    content={contents()}
    footer={!_showBulkUploader && uploadReady && selectButtons()}
    wrapperType={wrapperTypes.paddedHeader}
    updating={updating}
    confirmation={confirmation}
    css={{ header: 'wbbw', container: 'upl-' + storageLocationType }}
  />

  const uploadWrapper_withProvider = () => <UploadProvider upload_state={upload_state} upload_handlers={upload_handlers}>
    {wrapper()}
  </UploadProvider>

  const fullPageUploader = () => <FullPageWrapper
    content={uploadWrapper_withProvider()}
    handleCancel={handleCancel}
    topperCaption={'Upload Image'}
    topperCaption2={_.startCase(storageLocationType)}
    fullMode={fullMode}
  />

  if (useFull) {
    return fullPageUploader()
  } else {
    return uploadWrapper_withProvider()
  }

}

export default Uploader