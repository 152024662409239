import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { List, Segment } from 'semantic-ui-react';
import { AppUserContext } from '../../cnr/contexts/AppUserContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { convertPathToKeys } from '../../redirection/current';
import NoData from '../alerts/NoData';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';

const icon = 'star'
const iconColor = 'blue'

const UserFavorites = (props) => {

  const { fullPage } = props ? props : {}

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state, appUser_handlers } = appUserContext ? appUserContext : {}
  const { appUser } = appUser_state ? appUser_state : {}

  const appUserAccess = getAppUserAccess(appUser)
  const { pageFavs } = appUserAccess ? appUserAccess : {}

  const papsContext = useContext(PapsContext);
  const { navigate, paps_state } = papsContext ? papsContext : {}
  const { pathViews } = paps_state ? paps_state : {}

  const [sortedFavs, setSortedFavs] = useState()

  // const pageFavs = {
  //   sessions: {
  //     key: {
  //       name: '',
  //       pathName: ''
  //     }
  //   }
  // }

  useEffect(() => {
    const _sortedFavs = {}
    if (pageFavs) {
      // loop the faves
      console.log('pageFavs', pageFavs)
      Object.keys(pageFavs).forEach(favCollectionKey => {
        const collectionFav = pageFavs[favCollectionKey]
        Object.keys(collectionFav).forEach(keyy => {
          const pff = collectionFav[keyy]
          const { name, pathName, sports } = pff ? pff : {}
          if (pathName) {
            const pathNameS = pathName ? pathName.split('/') : ''
            const pathKeys = convertPathToKeys(pathNameS)
            let allow = pathKeys.events === pathViews.events
            if (allow) {
              if (!_sortedFavs[favCollectionKey]) { _sortedFavs[favCollectionKey] = [] }
              let _caption;
              switch (favCollectionKey) {
                case 'teams':
                  _caption = name
                  break;
                case 'sports':
                  _caption = _.startCase(keyy)
                  break;
              }
              _sortedFavs[favCollectionKey].push(
                {
                  key: favCollectionKey,
                  caption: _caption,
                  name,
                  pathName,
                  ...pathKeys,
                }
              )
            }
          }
        })
      })
    }
    console.log('_sortedFavs', _sortedFavs)
    setSortedFavs(_sortedFavs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFavs]);

  const handleCancel = () => props.handleCancel ? props.handleCancel() : navigate(-1)

  const handlePush = (item) => {
    handleCancel()
    navigate(item.pathName)
  }

  const handleUpdateFav = (e, favItem) => {
    e.stopPropagation()
    appUser_handlers.handlePageFavorite(paps_state, null, favItem)
  }

  const favItems = (favs) => {
    const items = []
    const favz = _.sortBy(favs, 'name')
    favz.forEach(fav => {
      items.push(<List.Item onClick={() => { handlePush(fav) }}>
        <List.Icon name={icon} color={iconColor} verticalAlign='middle' onClick={(e) => { handleUpdateFav(e, fav) }} />
        <List.Content>{fav.caption ? fav.caption : fav.name}</List.Content>
      </List.Item>)
    })
    return <List divided relaxed>
      {items}
    </List>
  }

  const content = () => {
    const segGroups = []
    if (sortedFavs && Object.keys(sortedFavs).length > 0) {
      Object.keys(sortedFavs).forEach(key => {
        const sortedFav = sortedFavs[key]
        segGroups.push(<Segment.Group>
          <Segment className={'fav-header'}>{_.startCase(key)}</Segment>
          <Segment className={'fav-items'}>{favItems(sortedFav)}</Segment>
        </Segment.Group>)
      })
    } else {
      return <NoData altCaption={'Favorites'} />
    }
    return segGroups
  }

  const container = () => <Wrapper
    content={content()}
    wrapperType={wrapperTypes.padded}
    css={{ container: 'app-dark-mode' }}
  ></ Wrapper>

  const fullPageWrapper = () => <FullPageWrapper
    content={container()}
    handleCancel={handleCancel}
    topperCaption={'Favorites'}
  />

  return fullPage ? fullPageWrapper() : container()

}

export default UserFavorites