import { zonedTimeToUtc } from 'date-fns-tz';
import _ from 'lodash';
import { getFirstObject } from '../../common/convert';
import { formatItem, formatTimeAmPm, formatTypes, getStartTimeUtc, toDateTime } from '../../common/dateFormatting';
import { sortObject } from '../../common/sorting';

const _ignoreKeys = ['_svs', '_sv', '_dateInfo', '_itemKey', '_new']

export const convertSnapshotToRealtimeData = (value, viewItem) => {

  const { propSections, props } = viewItem ? viewItem : {}

  let sProps;
  if (props) { sProps = sortObject(props, 'position') }

  const headers = []
  let allValues = {}

  if (!sProps) {
    value.forEach(doc => {
      const data = doc.data()
      Object.keys(data).forEach(key => {
        if (!headers.includes(key) && key !== 'id') { headers.push(key) }
      })
    })
  } else {
    if (props && propSections) {
      const pss = sortObject(propSections, 'position')
      Object.keys(pss).forEach(psKey => {
        const prs = _.filter(props, { 'propSection': psKey })
        if (prs.length > 0) {
          const x = _.sortBy(prs, 'position')
          if (x.length > 0) {
            x.forEach(item => {
              if (item.key !== 'id') { headers.push(item.key) }
            })
          }
        }
      })
    } else {
      Object.keys(sProps).forEach(hKey => {
        if (hKey !== 'id') { headers.push(hKey) }
      })
    }
  }

  value.forEach(doc => {
    const data = doc.data()
    allValues[doc.id] = {}
    headers.forEach(header => {
      if (data[header]) {
        allValues[doc.id][header] = data[header]
      } else {
        allValues[doc.id][header] = ""
      }
    })
  })
  return allValues
}

export const convertJsonToSheetsData = (json) => {

  const headers = []
  let allValues = []

  json.forEach(data => {
    Object.keys(data).forEach(key => {
      if (!headers.includes(key)) { headers.push(key) }
    })
  })

  json.forEach(data => {
    const dataA = []
    headers.forEach(header => {
      if (header === 'id') {
        dataA.push(data.id)
      } else {
        if (data[header]) {
          dataA.push(data[header])
        } else {
          dataA.push("")
        }
      }
    })
    allValues.push(dataA)
  })
  allValues.unshift(headers)
  return allValues
}

/**
 * 
 * @param {object} value 
 * @param {object} viewItem  
 * @returns 
 */
export const convertSnapshotToSheetsData = (value, viewItem) => {

  const { props } = viewItem ? viewItem : {}
  let sProps;
  if (props) { sProps = sortObject(props, 'position') }

  let headers = []
  let allValues = []

  if (!sProps) {
    value.forEach(doc => {
      const data = doc.data()
      Object.keys(data).forEach(key => {
        if (!headers.includes(key)) { headers.push(key) }
      })
    })
  } else {
    headers.push('id')
    Object.keys(sProps).forEach(hKey => {
      if (!_ignoreKeys.includes(hKey)) {
        headers.push(hKey)
      }
    })
  }

  headers = headers.sort()
  // array_move(headers, headers.indexOf('bio'), headers.length - 1)

  if (_.isArray(value)) {
    value.forEach(doc => {
      const dataA = []
      const data = doc.data()
      headers.forEach(header => {
        if (header === 'id') {
          dataA.push(doc.id)
        } else {
          if (data[header]) {
            dataA.push(data[header])
          } else {
            dataA.push("")
          }
        }
      })
      allValues.push(dataA)
    })
  }

  headers.forEach((header, index) => {
    for (var i = 0; i < 20; i++) {
      if (header.endsWith('_' + i)) {
        header = header.replace('_' + i, '')
      }
    }
    headers[index] = _.startCase(header)
  })

  allValues.unshift(headers)
  return allValues
}




// Selling Eclipse Accumulator & BGA IUL II
// Selling Eclipse Accumulator & BGA IUL II

/** Ammends the data for each item in the data collection
 * @note Adds an `id` property to each item from the `documentId`
 * @opts uivi, isCombinedData, ignoreId, returnFirstObject
 */
export const convertSnapshot = (value, singleDataItem, opts) => {
  const { uivi, isCombinedData, ignoreId, returnFirstObject, returnKey, timeZone } = opts ? opts : {}
  const { empty, query: isQuery, _document: isDocument } = value ? value : {}
  let allDocs = {}
  try {
    if (!singleDataItem || isQuery) {
      if (!empty && value) {
        value.forEach(doc => {
          ammendThisData(allDocs, doc, uivi, isCombinedData, ignoreId, timeZone)
        })
      }
    } else {
      if (!empty && isDocument) {
        ammendThisData(allDocs, value, uivi, isCombinedData, ignoreId, timeZone)
      }
    }
  } catch (error) {
    console.log('convertSnapshot', value)
    console.error('error', error)
  }

  if (returnFirstObject) {
    const { item, fk } = getFirstObject(allDocs)
    if (returnKey) {
      return { item, fk }
    } else {
      return item
    }
  } else {
    return allDocs
  }
}

/**
 * add a KEY and updates the DATES to each of the data items
 * @param {object} data 
 */
export const modifyParentKeyData = (data) => {
  Object.keys(data).forEach(key => {
    const d = data[key]
    if (d) {
      d.key = key
      updateDate(d)
    }
  })
}

/** Ammends the data
 * @note Adds an `id` property to each item from the `documentId`
 */
const ammendThisData = (o, doc, uivi, isCombinedData, ignoreId, timeZone) => {
  if (doc.data) {
    const d = doc.data()
    if (d) {
      updateDate(d, timeZone)
      o[doc.id] = { ...d }
      if (!ignoreId) {
        o[doc.id].id = doc.id
        o[doc.id]._itemKey = doc.id
      }
      if (isCombinedData) { o[doc.id].asVi = uivi }
    }
  }
}

export const updateAllDates = (allItems) => {
  if (allItems) {
    Object.keys(allItems).forEach(key => {
      const items = allItems[key]
      updateDates(items)
    })
  }
}

export const updateDates = (items) => {
  if (items) {
    Object.keys(items).forEach(key => {
      updateDate(items[key])
    })
  }
}

export const adjustedTime = (startTime) => {
  let pm;
  let timeSplit;
  if (startTime.indexOf('PM') >= 0 || startTime.indexOf('AM') >= 0) {
    if (startTime.indexOf('PM') >= 0) { pm = true }
    startTime = startTime.replace('AM', '')
    startTime = startTime.replace('PM', '')
  }
  try {
    timeSplit = startTime ? startTime.split(':') : [1, 0]
  } catch (error) {
    timeSplit = startTime ? [startTime, 0] : [1, 0]
  }
  let hours = parseInt(timeSplit[0])
  let minutes = parseInt(timeSplit[1])
  if (pm) { hours += 12 }
  if (minutes < 10) { minutes = minutes + '0' }
  if (hours < 10) { hours = hours + '0' }
  return hours + ':' + minutes
}

const getUtcTime = (date, time, timeZone) => {
  try {
    if (date) {
      if (time) {
        return timeZone ? new Date((date + ' ' + time).toLocaleString('en-US', { timeZone: timeZone })) : new Date(date + ' ' + time)
      } else {
        return new Date(date)
      }
    }
  } catch (error) {
    return new Date()
  }
}

const updateDate = (d, timeZone) => {

  if (d.startDate) {
    d.startDate = formatItem(formatTypes.shortestDate, d.startDate)
    let sd;
    if (d.seconds) {
      sd = d.startDate && d.startDate.seconds ? toDateTime(d.startDate.seconds) : d.startDate
      d.startDate = sd
      if (d.startTime) {
        try {
          d.startTimeUTC = getStartTimeUtc(d.startDate, d.startTime)
        } catch (error) {
          console.error('time format error', error)
        }
      }
    } else {
      const dd = getUtcTime(d.startDate, d.startTime, timeZone)
      d._dateInfo = getTimeZones(d, timeZone)
      d.startTimeUTC = dd
    }

    if (d.endTime && sd) {
      try {
        d.endTimeUTC = sd
        d.endTimeAMPM = formatItem(formatTypes.shortDate, sd)
      } catch (error) {
        console.error('tme')
        console.error('time format error', error)
      }
    }
  } else if (d.startDateUTC) {
    d.startDate = formatItem(formatTypes.date, d.startDateUTC)
    d.startTime = '7:00 PM'
  }
}

function getTimeZones(itemData, actualTimeZone) {

  const { startDate, startTime, endTime } = itemData

  if (itemData.name === 'Hospitality') {
    console.log('itemData', itemData)
  }

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  let _actualTimeZone = itemData.timeZone ? itemData.timeZone : actualTimeZone
  if (!_actualTimeZone) { _actualTimeZone = userTimeZone }

  const utcStartTime = startDate && startTime ? getUtcTime(startDate, startTime, _actualTimeZone) : null
  const itemStartDate = utcStartTime ? zonedTimeToUtc(utcStartTime, _actualTimeZone) : null

  const utcEndTime = startDate ? getUtcTime(startDate, endTime, _actualTimeZone) : null
  const itemEndDate = utcEndTime ? zonedTimeToUtc(utcEndTime, _actualTimeZone) : null

  const tzs = {
    item: {
      startDate,
      startTime,
      endTime,
    },
    local: {
      start: getDataInfo(itemStartDate, utcStartTime, userTimeZone),
      end: getDataInfo(itemEndDate, utcEndTime, userTimeZone)
    },
    actual: {
      start: getDataInfo(itemStartDate, utcStartTime, _actualTimeZone),
      end: getDataInfo(itemEndDate, utcEndTime, _actualTimeZone)
    },
    tzsDifferent: userTimeZone !== _actualTimeZone
  }


  ammendDateFormats(tzs.local.start, itemData)
  ammendDateFormats(tzs.local.end)

  ammendDateFormats(tzs.actual.start)
  ammendDateFormats(tzs.actual.end)

  return tzs

}

const getDataInfo = (itemDate, utcTime, timeZone) => {
  return {
    full: utcTime ? itemDate.toLocaleString('en-US', { timeZone, timeZoneName: 'short' }) : null,
    utc: itemDate ? new Date(itemDate.toLocaleString('en-US', { timeZone })) : null,
    timeZone: timeZone
  }
}

const ammendDateFormats = (item) => {
  const full = item.full
  const d = full ? full.slice(0) : null
  const tzs = full ? full.slice(full.length - 3) : null
  item.formatted = {
    date: item.utc ? formatItem(formatTypes.shortDate, item.utc) : null,
    time: item.utc ? formatTimeAmPm(item.utc.getHours() + ':' + item.utc.getMinutes()) : null,
    tzs: tzs
  }
}
