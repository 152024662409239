import _ from 'lodash';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { Card, Message, Ref, Segment, Visibility } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import ElementProvider from '../../cnr/contexts/ElementContext';
import { PageAreaParentContext } from '../../cnr/contexts/PageAreaParentContext';
import { getAlphaList } from '../../common/convert';
import { _find } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import QrCode from '../../pageItem/modification/pageItemActions/QrCode';
import { addToClass, getThemeItem } from '../../styles/formatting';
import UiImage from '../imaging/UiImage';
import AlphabetMenu from '../menus/AlphabetMenu';
import SwiperGroup from '../swipers/SwiperGroup';
import { propBreadcrumbs, sectionBreadcrumbs } from './breadcrumbs';
import CardNormal from './cards/CardNormal';
import CardProfile from './cards/CardProfile';
import CardSchedule from './cards/CardSchedule';
import { getCardColor, getCardStyle } from './semHelpers';
import GetSectionElements from './SemSections';
import { getViewableList } from './viewable/getViewableList';
import { useElementOnScreen } from './visibleElements';
import { copyObj } from '../../common_web/copy';
import { AppUsersContext } from '../../cnr/contexts/AppUsersContext';
// import UsaMap from '../map/UsaMap';

const CardGeneric = React.lazy(() => import('./cards/CardGeneric'));
const ErrorPage = React.lazy(() => import('../alerts/ErrorPage'));
const PdfGallery = React.lazy(() => import('../imaging/PdfGallery'));

/**
 * 
 * @param {object} props (componentContexts, sliderCount, viewListData, cardProps)
 * @returns 
 */
const SemCards = (props) => {

  // viewListData is passed into props because it can be modfied form the orignial list!!!
  const { componentContexts, sliderCount, viewListData, cardProps: card_props } = props

  // componentContexts
  const { frameworkContext, displayContext, uiItemContext } = componentContexts

  // parentContext
  const parentContext = useContext(PageAreaParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state, preview_state, transition_state } = states ? states : {}
  const { previewStatus } = preview_state ? preview_state : {}
  const { page_fns } = fns ? fns : {}

  // authContext  
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(authAppUser)

  // frameworkContext 
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  const { view } = paps_state ? paps_state : {}

  // pageContext  
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { pushSimple, goBackTo, getGviDeps } = page_fns ? page_fns : {}
  const { dataOptions, tools, uiElements, propItems, themedItems, themeColors, clientOptions, eventOptions, desktop, projectOptions } = aps_global ? aps_global : {}
  const { useLazyImageLoading } = projectOptions ? projectOptions : {}
  const { useDefaultLayoutType, defaultDisplayType } = desktop ? desktop : {}
  const { useScroll, scrollIncrement } = dataOptions ? dataOptions : {}
  const _appOptions = { ...clientOptions, ...eventOptions }
  const { showEventStatus } = _appOptions ? _appOptions : {}
  const { showItemIds } = tools ? tools : {}
  const { card: cardStyle } = uiElements ? uiElements : {}
  const { content: contentTheme } = themedItems ? themedItems : {}
  const { propValueColors } = propItems ? propItems : {}
  const cardProps = uiElements && uiElements.card ? { ...uiElements.card } : {}

  // transitionContext  
  const { transitions } = transition_state ? transition_state : {}
  const transition_image = transitions ? transitions[_animateTypes.image] : null

  const imageOptions = {
    transition_image,
    useLazyImageLoading
  }

  // displayContext 
  const { display_state } = displayContext ? displayContext : {}
  const { displayProps } = display_state ? display_state : {}
  const { props_viewItem, ddGroupIndex } = displayProps ? displayProps : {}

  // uiItemContext 
  const { item_state, getParents } = uiItemContext ? uiItemContext : {}
  const { viewItem, propSections_allowed, singleDataItem } = item_state ? item_state : {}
  const { key: viewItem_key } = viewItem ? viewItem : {}

  const { pageFavs, uid: uid_aua } = appUserAccess ? appUserAccess : {}
  const { allowFavorites, favoriteCollections, showFavoritesFirst } = aps_appUserSettings ? aps_appUserSettings : {}
  const _showFavs = allowFavorites && showFavoritesFirst && favoriteCollections && favoriteCollections.includes(viewItem_key) && (view !== viewItem_key)
  const _pageFavs = pageFavs && pageFavs[viewItem_key] && pageFavs[viewItem_key]

  // ref
  const contextRef = createRef()
  const headerColors = getThemeItem(aps_global, 'headers')

  let style = contentTheme && contentTheme.gap ? { marginBottom: contentTheme.gap + 'em' } : {}

  const { itemColor, images: image_viewItem, qrCodes, drillDown, propSections, display, desktopDisplay, grouping, pdfs, indicator } = viewItem ? viewItem : {}
  const { alphabetActive, alphaSortProp, groupActive, groupType } = grouping ? grouping : {}
  const { showPdfIcon } = pdfs ? pdfs : {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { indicatorProp, indicatorLocation } = indicator ? indicator : {}

  const { backgroundUrl, showPdf, pdfFile } = _display ? _display : {}
  let { displayType, displayDirection, cardDisplayType, cssContainerName, sortProp, sortReverse, sortByPosition, useLazyLoading } = _display ? _display : {}

  if (desktopMode && useDefaultLayoutType && defaultDisplayType) { displayType = defaultDisplayType }

  if (!cardDisplayType) { cardDisplayType = gEnums.cardDisplayTypes.normal }

  const { useDrillDown, drillDownType } = drillDown ? drillDown : {}
  let { usePropColor, colorProp, colorDisplayType, showParentColor } = itemColor ? itemColor : {}
  const { showItemImage, imageLocation } = image_viewItem ? image_viewItem : {}
  const { showQrCode } = qrCodes ? qrCodes : {}
  const { propColors } = {}
  const colorProps = {}
  let prcs = null

  const getCn = () => {
    const _indicatorLocation = indicatorLocation ? indicatorLocation : gEnums.bannerLocationTypes.bottom
    let _cn = ''
    // let _cn = 'card-profile'
    if (imageLocation && showItemImage) {
      _cn = addToClass(_cn, 'card-img')
      _cn = addToClass(_cn, imageLocation, 'img')
    }
    if (showQrCode) { _cn += ' card-qr' }
    if (indicatorProp) {
      _cn += ' indic'
      if (_indicatorLocation === gEnums.bannerLocationTypes.top) { _cn += ' top' }
    }
    switch (cardDisplayType) {
      case gEnums.cardDisplayTypes.profilePlus:
      case gEnums.cardDisplayTypes.schedulePlus:
        _cn += ' plus'
        // showFullImage = true
        break;
      default:
      // nothing
    }
    return _cn
  }

  let imageInContent = true

  const cn = getCn()

  switch (imageLocation) {
    case gEnums.imageLocations.top:
      imageInContent = false
      break;
    default:
    // nothing
  }

  if (propValueColors) { prcs = _.groupBy(propValueColors, 'propItem') }

  if (!sortProp && props_viewItem) {
    if (_.includes(Object.keys(props_viewItem), 'lastName')) {
      sortProp = 'lastName'
    } else {
      sortProp = 'name'
    }
  }

  if (sortByPosition) { sortProp = 'position' }

  if (alphabetActive && alphaSortProp) { sortProp = alphaSortProp }

  let noClick;
  let bc;

  if (aps_global && aps_global.colorDisplayType) { colorDisplayType = aps_global.colorDisplayType }
  if (usePropColor && !colorDisplayType) { colorDisplayType = gEnums.colorDisplayTypes.tagLabel }

  // local state 
  const [fullList, setFullList] = useState()
  const [viewableVld, setViewableVld] = useState()
  const [dataCount, setDataCount] = useState(scrollIncrement ? scrollIncrement : 10)
  const [selectedKey] = useState()
  const [alphaValue, setAlphaValue] = useState(65)

  useEffect(() => {

    let _viewListData = viewListData

    if (_viewListData && alphabetActive && groupActive) {
      const _alphaChar = String.fromCharCode(alphaValue)
      const { alphaList } = getAlphaList(_viewListData, _alphaChar, sortProp)
      _viewListData = alphaList
    }

    const fl = getViewableList(false, _viewListData, sortProp, sortReverse, dataCount)
    let vld = getViewableList(useScroll || useLazyLoading, _viewListData, sortProp, sortReverse, dataCount)

    if (selectedKey) {
      const _data = _find(vld, 'id', selectedKey) // _.find(vld, { id: selectedKey })
      setViewableVld([_data])
    } else {
      setViewableVld(copyObj(vld))
    }
    setFullList(fl)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [viewListData, previewStatus]);


  const handleAlphaChange = (alphaItem) => {
    if (viewableVld) {
      const _alphaChar = String.fromCharCode(alphaItem)
      const { alphaList } = getAlphaList(viewListData, _alphaChar, sortProp)
      let vld = getViewableList(useScroll || useLazyLoading, alphaList, sortProp, sortReverse, dataCount)
      setViewableVld(vld)
      setAlphaValue(alphaItem)
    }
  }

  const [divRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 1.0 })

  const handleClick = (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => {
    if (itemKey) pushSimple(clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex)
  }

  // handle the scroll
  const handleScroll = (e, value) => {
    if (useScroll || useLazyLoading) {
      if (value.calculations.bottomVisible) {
        if (dataCount <= fullList.length + 1) {
          let count = dataCount + scrollIncrement
          if (count > fullList.length + 1) { count = fullList.length + 1 }
          setDataCount(count)
        }
      }
    }
  }

  // drillDown
  if (useDrillDown && ddGroupIndex) {
    switch (drillDownType) {
      case gEnums.drillDownTypes.sections:
        if (!drillDown['section' + (ddGroupIndex + 1)]) { noClick = true }
        if (drillDown.showBreadcrumbs) { bc = sectionBreadcrumbs(drillDown, propSections, propSections_allowed, viewListData, ddGroupIndex, goBackTo) }
        break;
      default:
        if (!drillDown['group' + (ddGroupIndex + 1)]) { noClick = true }
        if (drillDown.showBreadcrumbs) { bc = propBreadcrumbs(drillDown, viewableVld, propSections_allowed, ddGroupIndex, goBackTo) }
    }
  }

  const cards = () => {

    let crdTop;
    const crds = []
    const crds_favs = []

    // eslint-disable-next-line 
    viewableVld.forEach((itemData, index) => {

      const { _itemKey } = itemData ? itemData : {}

      let addCard = true
      let showFullImage = singleDataItem

      if (sliderCount && (index >= sliderCount)) { addCard = false }

      if (!_.isObject(itemData)) {
        addCard = false
        showFullImage = true
      }

      if (addCard) {

        let { inactive } = itemData ? itemData : {}
        let _showItemImage = showItemImage

        switch (cardDisplayType) {
          case gEnums.cardDisplayTypes.profile:
          case gEnums.cardDisplayTypes.profilePlus:
          case gEnums.cardDisplayTypes.schedule:
          case gEnums.cardDisplayTypes.schedulePlus:
            _showItemImage = false
            break;

          case gEnums.cardDisplayTypes.page:
            ammendPropSections(cardDisplayType, propSections)
            break;
          default:
          // nothing
        }

        const cardImage = _showItemImage ? <UiImage
          key={uniqueKey('scci', index)}
          itemData={itemData}
          viewItem={viewItem}
          index={index}
          showFullImage={showFullImage}
          inView={isVisible}
          imageOptions={imageOptions}
        /> : null

        const cardQr = showQrCode ? <QrCode
          qrCodeType={gEnums.qrCodeTypes.itemData}
          key={uniqueKey('sccq', index)}
          itemData={itemData}
          viewItem={viewItem}
          index={index}
          showFullImage={showFullImage}
          inView={isVisible}
        /> : null

        // IMPORTANT: UI - Sections - Get the Sections
        const sprops = { parentContext, componentContexts, itemData, headerColors, cardImage, cardQr, showEventStatus }
        const _sections = GetSectionElements(sprops)

        if (!_sections) { return <Card fluid><Message>No Props selected. Check view settings.</Message></Card> }

        if (_sections && Object.keys(_sections).length === 0) {
          return <Card fluid><Message>No Sections are set to show. Check settings.</Message></Card>
        }


        const allowItem = true
        const inactiveClass = inactive ? ' item-inactive' : ''

        if (allowItem) {

          let color = getCardColor(getGviDeps, viewItem, itemData, itemColor, propColors, colorProps, prcs, getParents)
          const csp = getCardStyle(usePropColor, color, colorDisplayType)
          const cardPrps = {}

          if (cardProps.raised) { cardPrps.raised = true }
          if (csp.cardStyle) { style = { ...style, ...csp.cardStyle } }

          if (backgroundUrl) {
            style.backgroundImage = `url(${backgroundUrl})`
          }

          // items passed into iCardItems
          const semSecProps = {
            aps_appUserSettings,
            aps_global,
            cardDisplayType,
            cardImage,
            cardStyle,
            color,
            colorProp,
            colorProps,
            csp,
            display_viewItem: _display,
            displayType,
            headerColors,
            images: image_viewItem,
            propSections,
            showParentColor,
            showQrCode,
            statusIcon: showEventStatus && viewItem && viewItem.key === 'events' ? 'check' : 'cog',
            themeColors,
            usePropColor,
          }

          let cardWrapperCn = csp.cardCn + inactiveClass

          if (cssContainerName) {
            if (cardWrapperCn) {
              cardWrapperCn = addToClass(cardWrapperCn, 'ccn-' + cssContainerName)
            } else {
              cardWrapperCn = 'ccn-' + cssContainerName
            }
          }

          if (cardProps && cardProps.shadowless) { cardWrapperCn = addToClass(cardWrapperCn, 'nbs') }

          const cnProps = {
            _display,
            _itemKey,
            _sections,
            aps_appUserSettings,
            aps_global,
            appUserAccess,
            cardImage,
            cardPrps,
            cardQr,
            divRef,
            getParents,
            handleClick,
            imageInContent,
            image_viewItem,
            index,
            itemData,
            noClick,
            page_fns,
            semSecProps,
            showItemIds,
            showItemImage,
            showPdfIcon,
            showQrCode,
            style,
            viewItem,
          }

          const card = () => {

            switch (cardDisplayType) {

              case gEnums.cardDisplayTypes.profile:
                return <CardProfile key={uniqueKey('sc', 'cp', uid_aua)} viewItem={viewItem} display={_display} itemData={itemData} imageOptions={imageOptions} handleClick={handleClick} />

              case gEnums.cardDisplayTypes.profilePlus:
                crdTop = <CardProfile key={uniqueKey('sc', 'cp', uid_aua)} viewItem={viewItem} display={_display} itemData={itemData} imageOptions={imageOptions} handleClick={handleClick} />
                return <CardNormal cnProps={cnProps} isSubCard={true} />

              case gEnums.cardDisplayTypes.busSchedule:
              case gEnums.cardDisplayTypes.client:
              case gEnums.cardDisplayTypes.event:
              case gEnums.cardDisplayTypes.schedule:
                return <CardSchedule viewItem={viewItem} display={_display} itemData={itemData} cardProps={card_props} handleClick={handleClick} />

              case gEnums.cardDisplayTypes.schedulePlus:
                crdTop = <CardSchedule viewItem={viewItem} display={_display} itemData={itemData} handleClick={handleClick} />
                return <CardNormal cnProps={cnProps} isSubCard={true} />

              case gEnums.cardDisplayTypes.taggedOnly:
                return <div>TEST</div>

              case gEnums.cardDisplayTypes.venue:
                return <CardGeneric viewItem={viewItem} display={_display} itemData={itemData} handleClick={handleClick} />

              default:
                return <CardNormal cnProps={cnProps} />
            }
          }

          const cardProvider = () => <ElementProvider
            key={uniqueKey('cp', index)}
            itemData={itemData}
            cn={cn}
          >
            {card()}
          </ElementProvider>

          if (_showFavs && _pageFavs && _pageFavs[_itemKey]) {
            crds_favs.push(cardProvider())
          } else {
            crds.push(cardProvider())
          }
          // crds.push(cardProvider())
          // return cardProvider()

        }
      }
    })

    let useSwiperGroup;

    if (groupActive) {
      switch (groupType) {
        case gEnums.groupTypes.swipeableGroup:
          useSwiperGroup = true
          break;
        default:
        // nothing 
      }
    }

    switch (displayDirection) {
      case gEnums.displayDirectionTypes.horizontal:
        useSwiperGroup = true
        break;
      default:
      // nothing 
    }

    const cards_top = (cds) => <React.Fragment key={'test'}>
      {crdTop}
      <Card.Group vit={card_props.vit} className={card_props.cnc} itemsPerRow={card_props.itemsPerRow}  >
        {cds}
      </Card.Group>
    </React.Fragment>

    const cards_normal = (cds) => <Card.Group vit={card_props.vit} className={card_props.cnc} itemsPerRow={card_props.itemsPerRow}>
      {cds}
    </Card.Group>

    const cards_favs = (cds) => <Card.Group className={card_props.cnc + ' card-group-favs'}>
      <div>Following</div>
      {cds}
    </Card.Group>

    if (useSwiperGroup) {
      return <SwiperGroup content={crds} />
    } else {
      if (crdTop) {
        return cards_top(crds)
      } else {
        if (alphabetActive && groupActive) {
          return <React.Fragment>
            <AlphabetMenu handleAlphaChange={handleAlphaChange} />
            {cards_normal(crds)}
          </React.Fragment>
        } else {
          if (crds_favs.length > 0) {
            return <React.Fragment>
              {cards_favs(crds_favs)}
              {cards_normal(crds)}
            </React.Fragment>
          } else {
            return cards_normal(crds)
          }
        }
      }
    }
  }

  const pdfLine = () => <PdfGallery itemPdf={pdfFile} showFile={true} />

  // IMPORTANT: UI - Create CARDS for EACH itemData
  // LOOP data 
  const allContent = () => {
    if (!viewableVld) { return <div key={uniqueKey('ac', 'nvd')}></div> }
    return <React.Fragment>
      {showPdf && pdfFile && pdfLine()}
      {cards()}
    </React.Fragment>
  }

  const content = () => {
    const _allContent = allContent()
    if (useScroll || useLazyLoading) {
      return <Ref innerRef={contextRef}>
        <Visibility offset={[10, 10]} onUpdate={handleScroll} className={'scroll-container'}>
          {_allContent}
        </Visibility>
      </Ref>
    } else {
      return _allContent
    }
  }

  try {
    if (viewableVld) {
      if (bc) {
        return <React.Fragment>
          {bc}
          {allContent()}
        </React.Fragment>
      } else {
        return content()
      }
    } else {
      return <div></div>
    }
  } catch (error) {
    return <ErrorPage error={error} component={'Sem Cards'} />
  }
}

const ammendPropSections = (cardDisplayType, ps) => {
  if (ps) {
    Object.keys(ps).forEach(key => {
      switch (cardDisplayType) {
        case gEnums.cardDisplayTypes.page:
          const sps = ps[key]
          if (sps.sectionHeader) {
            sps.sectionHeader.overrideAppSettings = true
            sps.sectionHeader.headerDisplayType = gEnums.sectionHeaderTypes.none
          }
          if (sps.sectionBody) {
            sps.sectionBody.overrideAppSettings = true
            sps.sectionBody.bodyDisplayType = gEnums.sectionBodyTypes.noCaptions
          }
          break;
        default:
        // nothing
      }
    })
  }
}

export default SemCards