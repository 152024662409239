import { getStorage, ref, uploadBytes } from 'firebase/storage';
import _ from 'lodash';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { getManifestPath } from '../../cnr/contexts/ManifestContext';
import { createManifestIcons, createThumbnailIcons } from '../../components/imaging/createManifestIcons';
import { convertCanvasToFile } from '../../components/imaging/imageHelpers';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_set_doc } from '../../firestoreData/appData/fsData';
import { _storageSettings } from '../../storage/storageHelpers';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';

// import { deleteStorageFolder } from '../../functions/fbBucketFunctions';

const rts = {
  test: 'test',
  handleAccept: 'handleAccept',
  handleChange: 'handleChange',
  handleCloseUpdate: 'handleCloseUpdate',
  handleDecline: 'handleDecline',
  handleFileFormData: 'handleFileFormData',
  handleImageButtonFileSelect: 'handleImageButtonFileSelect',
  handleImageFileSelect: 'handleImageFileSelect',
  handleManifestFiles: 'handleManifestFiles',
  handleUpload_manual: 'handleUpload_manual',
  handleUpload_alpha: 'handleUpload_alpha',
  handleUpload_item: 'handleUpload_item',
  handleProgress: 'handleProgress',
  handleSet_allFiles: 'handleSet_allFiles',
  handleSet_avatorEditor: 'handleSet_avatorEditor',
  handleSet_imageImage: 'handleSet_imageImage',
  handleShowModalAlert: 'handleShowModalAlert',
  handleUpdate_currentGalleryStatus: 'handleUpdate_currentGalleryStatus',
  handleUpdate_galleryDir: 'handleUpdate_galleryDir',
  handleUpdate_pendingFiles: 'handleUpdate_pendingFiles',
  handleUpdate_singleItemStatus: 'handleUpdate_singleItemStatus',
  handleUpload_manifest: 'handleUpload_manifest',
  handleUploadResponse: 'handleUploadResponse',
  ...grts
}

export const uploadStatusTypes = {
  completed: 'completed',
  failed: 'failed',
  pending: 'pending',
}

export const fileStatusTypes = {
  approved: 'approved',
  existing: 'existing',
  found: 'found',
  missing: 'missing',
}

export const storageGalleryProps = (spProps) => {

  const {
    _viewItemKey,
    appUserAccess,
    appUserCollection,
    dataItem,
    lastView,
    storageLocationType,
    storageOptions,
    storagePaths,
    storageType,
    useDarkMode,
    viewItem_id,
    viewItem_key,
    useClientProfileImage,
  } = spProps

  const { galleryPaths } = _storageSettings
  const { saveCollectionImagesToClient, clientStorageImageCollections } = storageOptions ? storageOptions : {}

  let _allowAppUsersInClientProfiles = false;

  if (storageLocationType && useClientProfileImage) {
    switch (storageLocationType) {
      case gEnums.storageLocationTypes.clientProfiles:
        _allowAppUsersInClientProfiles = true
        break;
      case gEnums.storageLocationTypes.pageDirect:
        if (lastView === appUserCollection && dataItem && dataItem.email) {
          _allowAppUsersInClientProfiles = true
        }
        break;
      default:
    }
  }

  switch (storageLocationType) {

    case gEnums.storageLocationTypes.clientProfile:
      return {
        inClient: false,
        storageRef: storagePaths.clients + '/' + galleryPaths.profiles + '/' + appUserAccess.email,
        collectionName: galleryPaths.profiles,
        useDarkMode: true,
        removeExisting: true,
        storageLocationType
      }

    case gEnums.storageLocationTypes.event:
      if (storagePaths.events) {
        return {
          inClient: true,
          storageRef: storagePaths.events + '/' + storageType,
          collectionName: storageType,
          useDarkMode: true,
          storageLocationType
        }
      } else if (storagePaths.clients) {
        return {
          inClient: true,
          storageRef: storagePaths.clients + '/' + storageType,
          collectionName: storageType,
          useDarkMode: true,
          storageLocationType
        }
      } else {
        return {
          inClient: true,
          storageRef: 'home/' + storageType,
          collectionName: storageType,
          useDarkMode: true,
          storageLocationType
        }
      }

    case gEnums.storageLocationTypes.eventGallery:
      return {
        inClient: true,
        storageRef: storagePaths.events + '/' + galleryPaths.galleryEvent,
        collectionName: storageType,
        useDarkMode: true,
        storageLocationType
      }

    case gEnums.storageLocationTypes.pageDirect:

      let sr;

      if (_allowAppUsersInClientProfiles) {
        sr = storagePaths.clients + '/' + _storageSettings.galleryPaths.profiles + '/' + dataItem.email
      } else {
        if (viewItem_id) {
          sr = storagePaths.events + '/' + galleryPaths.galleryDirect + '/' + viewItem_key + '/' + viewItem_id
        } else {
          sr = storagePaths.events + '/' + galleryPaths.galleryDirect + '/' + viewItem_key
        }
      }

      return {
        inClient: true,
        storageRef: sr,
        collectionName: galleryPaths.galleryDirect,
        useDarkMode: false,
        storageLocationType
      }

    case gEnums.storageLocationTypes.pageGallery:
      return {
        inClient: true,
        storageRef: storagePaths.events + '/' + galleryPaths.galleryPage + '/' + viewItem_key + '/' + viewItem_id,
        collectionName: galleryPaths.galleryPage,
        useDarkMode: false,
        storageLocationType
      }

    case gEnums.storageLocationTypes.pagePdf:
      return {
        inClient: true,
        storageRef: storagePaths.events + '/' + galleryPaths.pagePdf + '/' + viewItem_key + '/' + viewItem_id,
        collectionName: galleryPaths.pagePdf,
        useDarkMode: false,
        storageLocationType
      }

    case gEnums.storageLocationTypes.profileGallery:
      return {
        inClient: false,
        storageRef: galleryPaths.profile_all + '/' + appUserAccess.email,
        collectionName: galleryPaths.profile_all,
        useDarkMode: true,
        storageLocationType
      }

    case gEnums.storageLocationTypes.profiles:
      return {
        inClient: false,
        storageRef: galleryPaths.profiles + '/' + appUserAccess.email,
        collectionName: galleryPaths.profiles,
        useDarkMode: true,
        removeExisting: true,
        storageLocationType
      }

    default:
      return {
        inClient: true,
        storageRef: _storageSettings.galleryTypes.galleryDirect,
        useDarkMode,
        storageLocationType
      }
  }
}

const dc = (success, header, message) => ({
  success: success,
  header: header,
  message: message,
})

export const uploadReducer = (state, action) => {

  const { type, dispatch } = action
  const {
    selectedFolderFiles,
    altItemData,
    appUserAccess,
    formData,
    handleCancel_showUpload,
    galleries,
    manifestFiles,
    pathViews,
    pendingFiles,
    productionSettings,
    storage_handlers,
    storageLocationType,
    storageRef,
    storageType,
  } = state

  const { found } = pendingFiles ? pendingFiles : {}

  let _storageRefProd = ''

  const callback_afterUpload = () => {
    // storage_handlers && storage_handlers.handleClear_gallery && storage_handlers.handleClear_gallery()
    // handleCancel_showUpload && handleCancel_showUpload()
    dispatch({ type: rts.updateSuccess, dispatch })
  }

  if (productionSettings && productionSettings.clientKey && storageRef) {
    const sr = storageRef.split('/')
    sr.forEach((s, index) => {
      switch (index) {
        case 1:
          _storageRefProd += productionSettings.clientKey ? productionSettings.clientKey : s
          break;
        default:
          _storageRefProd += s
      }
      if (index < sr.length - 1) {
        _storageRefProd += '/'
      }
    })
  }

  // internal handlers
  const { handleCloseUpdate, handleUpload_manifest, handleStartUpdate, handleManifestFiles, handleSet_allFiles } = dispatch ? uploadHandlers(dispatch) : {}

  switch (type) {
    case rts.test:
      break;

    case rts.handleImageButtonFileSelect:
      ammendSelectedFiles(action, [action.f], handleSet_allFiles, handleManifestFiles)
      return { ...state }

    case rts.handleImageFileSelect:
      const { target: { files } } = action.e;
      ammendSelectedFiles(action, files, handleSet_allFiles, handleManifestFiles)

      // const _allSelectedFiles = [];
      // const _allSelectedImages = []

      // Object.keys(files).forEach((key) => {
      //   _allSelectedFiles.push(files[key])
      // })

      // switch (_allSelectedFiles.length) {
      //   case 1:
      //     switch (action.storageType) {
      //       case gEnums.storageTypes.manifest:
      //         const manifestName = 'manifest'
      //         const defaultFile = _allSelectedFiles[0]
      //         createManifestIcons(defaultFile).then(res => {
      //           const manifestFiles = []
      //           res.forEach((r, index) => {
      //             manifestFiles.push(new File([r], manifestName + '_' + index, { type: r.type }))
      //           })
      //           handleManifestFiles(manifestFiles, storageType)
      //         })
      //         break;
      //       default:
      //         if (_allSelectedFiles) {
      //           _allSelectedFiles.forEach(f => {
      //             _allSelectedImages.push(URL.createObjectURL(f))
      //           })
      //         }
      //         handleSet_allFiles(_allSelectedFiles, _allSelectedImages)
      //     }
      //     break;
      //   default:
      //     handleSet_allFiles(_allSelectedFiles, _allSelectedImages)
      //     break;
      // }
      return { ...state }

    case rts.handleUpload_manual:
      if (action.useAvatar && action.canvas) {
        const file = convertCanvasToFile(action.canvas)
        if (file) {
          // this is the fileName
          getFileName(file, storageLocationType, selectedFolderFiles, altItemData, formData)
          file.metadata = { name: 'test' }
          const sfp = { file, fileKey: file.name, appUserAccess, storageRef, handleStartUpdate, _storageRefProd, formData }
          uploadSingleFile(sfp)
        }
      } else {
        switch (storageType) {
          case gEnums.storageTypes.manifest:
            handleUpload_manifest(manifestFiles)
            break;

          default:
            const _asf = {}
            selectedFolderFiles.forEach(asf => { _asf[asf.name] = asf })
            uploadAllFilesPromise({ callback: callback_afterUpload, autoReduceImage: true, ignoreFileKey: true, action: {}, files: _asf, appUserAccess, storageRef, handleStartUpdate, storageLocationType, _storageRefProd, formData })
            return { ...state }
        }
      }
      return { ...state }

    case rts.handleUpload_alpha:
      const { currentAlphaList, useEmail } = action
      const updateList = {}
      if (currentAlphaList) {
        Object.keys(currentAlphaList).forEach(key => {
          const item = currentAlphaList[key]
          const { fileStatusType, file, email } = item
          if (file && fileStatusType === fileStatusTypes.approved) {
            if (useEmail && email) {
              updateList[email] = file
            } else {
              updateList[key] = file
            }
          }
        })
      }
      uploadAllFilesPromise({ callback: handleCloseUpdate ? handleCloseUpdate : callback_afterUpload, action, files: updateList, appUserAccess, storageRef: action.storageRef, handleStartUpdate, storageLocationType, _storageRefProd })
      return { ...state }

    case rts.handleUpload_item:
      uploadAllFilesPromise({ callback: callback_afterUpload, action, files: found, appUserAccess, storageRef, handleStartUpdate, storageLocationType, _storageRefProd })
      return { ...state }

    case rts.handleCloseUpdate:
      if (action && action.callback) { action.callback() }
      return { ...state, updating: false }

    case rts.handleUpdate_currentGalleryStatus:
      return { ...state, uploadUpdateStatus: getUploadGalleryStatus(state, action) }

    case rts.handleUpdate_singleItemStatus:
      const uus = getUploadSingleItemStatus(state)
      return { ...state, uploadUpdateStatus: uus, updating: uus.allComplete ? false : true }

    case rts.handleUpload_manifest:
      let smp2 = getManifestPath(pathViews)
      if (smp2) {
        uploadManifestFiles(smp2, manifestFiles, appUserAccess).then(res => {
          dispatch({ type: rts.updateSuccess, dispatch })
        }).catch(error => {
          dispatch({ type: rts.updateError, error, dispatch })
        })
      }
      return { ...state }

    case rts.handleSet_avatorEditor:
      return {
        ...state,
        avatarEditor: action.avatarEditor
      }

    case rts.handleDecline:
      return {
        ...state,
        selectedFolderFiles: null,
        firstSelectedFile: null,
        manifestFiles: null,
      }

    case rts.handleChange:
      return {
        ...state,
        description: action.data
      }

    // case rts.handleAddStorageSuccess:
    //   return {
    //     ...state,
    //     storageUpdate: dc(true, 'Success', 'The item was successfully uploaded'),
    //   }

    // case rts.handleError_addStorage:
    //   return {
    //     ...state,
    //     storageUpdate: dc(false, 'Error', action.error)
    //   }

    case rts.handleProgress:
      return {
        ...state,
        progress: action.progress
      }

    case rts.handleSet_allFiles:

      const { pendingFiles, selectedFiles } = getPending(state, action)

      const unSelectedFiles = getUnselected(action.selectedFolderFiles, selectedFiles)

      return {
        ...state,
        selectedFolderFiles: action.selectedFolderFiles,
        allSelectedImages: action.allSelectedImages,
        firstSelectedFile: action.selectedFolderFiles ? action.selectedFolderFiles[0] : null,
        firstSelectedImage: action.allSelectedImages ? action.allSelectedImages[0] : null,
        selectedFiles,
        unSelectedFiles,
        uploadUpdateStatus: {},
        pendingFiles,
      }

    case rts.handleUpdate_pendingFiles:
      return { ...state, pendingFiles: action.pendingFiles }

    case rts.handleManifestFiles:
      return {
        ...state,
        manifestFiles: action.manifestFiles
      }

    case rts.handleShowModalAlert:
      return {
        ...state,
        showAlertModal: action.show,
        importStarted: action.show
      }

    case rts.handleSet_imageImage:
      return {
        ...state,
        imageImage: action.imageImage,
        selectedFolderFiles: [action.imageImage]
      }

    case rts.handleUpdate_galleryDir:
      updateGalleryDir(pathViews, galleries)
      return { ...state }

    case rts.handleFileFormData:
      return { ...state, formData: action.formData }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, uploadUpdateStatus: getUploadUpdateStatus(state, action) })

    default:
      return state
  }
}

export const uploadHandlers = (dispatch) => {
  return {
    test: () => { dispatch({ type: rts.test }) },
    handleAccept: () => { dispatch({ type: rts.handleAccept }) },
    handleChange: (data) => { dispatch({ type: rts.handleChange, data }) },
    handleCloseUpdate: (action) => { dispatch({ type: rts.handleCloseUpdate, dispatch, action }) },
    handleDecline: () => { dispatch({ type: rts.handleDecline }) },
    handleFileFormData: (formData) => { dispatch({ type: rts.handleFileFormData, formData }) },
    handleImageButtonFileSelect: (f, storageType) => { dispatch({ type: rts.handleImageButtonFileSelect, dispatch, f, storageType }) },
    handleImageFileSelect: (e, storageType) => { dispatch({ type: rts.handleImageFileSelect, dispatch, e, storageType }) },
    handleManifestFiles: (manifestFiles) => { dispatch({ type: rts.handleManifestFiles, manifestFiles }) },
    handleProgress: (progress) => { dispatch({ type: rts.handleProgress, progress: progress }) },
    handleSet_allFiles: (selectedFolderFiles, allSelectedImages) => { dispatch({ type: rts.handleSet_allFiles, selectedFolderFiles, allSelectedImages }) },
    handleSet_avatorEditor: (avatarEditor) => { dispatch({ type: rts.handleSet_avatorEditor, avatarEditor }) },
    handleSet_imageImage: (imageImage) => { dispatch({ type: rts.handleSet_imageImage, imageImage }) },
    handleShowAlertModal: (show) => { dispatch({ type: rts.handleShowModalAlert, show }) },
    handleUpdate_currentGalleryStatus: (currentGallery, storageLocationType) => { dispatch({ type: rts.handleUpdate_currentGalleryStatus, dispatch, currentGallery, storageLocationType }) },
    handleUpdate_galleryDir: () => { dispatch({ type: rts.handleUpdate_galleryDir }) },
    handleUpdate_pendingFiles: (pendingFiles) => { dispatch({ type: rts.handleUpdate_pendingFiles, pendingFiles }) },
    handleUpdate_singleItemStatus: () => { dispatch({ type: rts.handleUpdate_singleItemStatus, dispatch }) },
    handleUpload_alpha: (storageRef, currentAlphaList, useEmail, callback) => { dispatch({ type: rts.handleUpload_alpha, dispatch, storageRef, currentAlphaList, useEmail, callback }) },
    handleUpload_item: (fileKey) => { dispatch({ type: rts.handleUpload_item, dispatch, fileKey }) },
    handleUpload_manifest: (manifestFiles) => { dispatch({ type: rts.handleUpload_manifest, dispatch, manifestFiles }) },
    handleUpload_manual: (canvas, useAvatar, fileKey) => { dispatch({ type: rts.handleUpload_manual, dispatch, canvas, useAvatar, fileKey }) },
    handleUploadResponse: () => { dispatch({ type: rts.handleUploadResponse, dispatch }) },
    ...responseHandlers(dispatch)
  }
}

export const uploadInitialState = (init_state) => {

  const {
    appStorageCollection,
    appUserCollection,
    forBulk,
    handleCancel,
    handlers,
    lastView,
    pathViews,
    productionSettings,
    useClientProfileImage,
    states,
    storageLocationType,
    storageOptions,
    storageRef,
    storageRootPath,
    storageType,
    viewItemKey,
    viewListData,
    viewUploadProps,
  } = init_state

  const { appUser_state, paps_state, page_state, storage_state } = states ? states : {}
  const { storage_handlers } = handlers ? handlers : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { dataRestrictions } = aps_global ? aps_global : {}
  const { galleries } = storage_state ? storage_state : {}
  const { direct } = galleries ? galleries : {}

  let _allowAppUsersInClientProfiles = false;
  let _currentGallery;

  if (galleries && storageLocationType) {
    switch (storageLocationType) {
      case gEnums.storageLocationTypes.clientProfiles:
        _currentGallery = galleries[gEnums.storageLocationTypes.profiles]
        _allowAppUsersInClientProfiles = true
        break;
      case gEnums.storageLocationTypes.pageDirect:
        if (lastView === appUserCollection) {
          _allowAppUsersInClientProfiles = true
        }
        break;
      default:
    }
  }

  const { pendingFiles } = getPending({ viewListData, forBulk })

  return {
    allowAppUsersInClientProfiles: _allowAppUsersInClientProfiles,
    appStorageCollection: appStorageCollection,
    appUser: appUser,
    appUserAccess: getAppUserAccess(appUser),
    appUserCollection,
    avatarEditor: null,
    currentGallery: _currentGallery,
    dataRestrictions,
    description: null,
    forBulk,
    galleryDirect: direct && direct[viewItemKey],
    handleCancel_showUpload: handleCancel,
    importStarted: false,
    isUploading: false,
    paps_state,
    pathViews,
    pendingFiles,
    productionSettings,
    progress: 0,
    showAlertModal: false,
    storage_handlers,
    storageLocationType,
    storageOptions,
    storageRef,
    storageRootPath,
    storageType,
    uploadUpdateStatus: {},
    useClientProfileImage,
    viewListData,
    viewUploadProps,
  }
};

const getFileName = (file, storageLocationType, selectedFolderFiles, altItemData, formData) => {
  let fileName;
  switch (storageLocationType) {
    case gEnums.storageLocationTypes.pageDirect:
    case gEnums.storageLocationTypes.clientProfiles:
    case gEnums.storageLocationTypes.clientProfile:
    case gEnums.storageLocationTypes.profileGallery:
    case gEnums.storageLocationTypes.profiles:
      if (file.name.indexOf('-thumb') >= 0) {
        fileName = 'profile-thumb' // appUser.uid
      } else {
        fileName = 'profile' // appUser.uid
      }
      break;
    case gEnums.storageLocationTypes.pagePdf:
      fileName = formData.name
      break;
    default:
      if (selectedFolderFiles) {
        fileName = selectedFolderFiles[0].name
      }
  }
  if (altItemData && altItemData.email) { fileName = altItemData.email }
  return fileName
}

/**
 * 
 * @param {object} props (file, fileKey, appUserAccess, storageRef, handleStartUpdate)
 * @returns 
 */
export const uploadSingleFile = (props) => {

  const { file, fileKey, appUserAccess, storageRef, handleStartUpdate, formData } = props

  const metadata = {
    customMetadata: {
      uploadedBy: appUserAccess.email,
    }
  };

  if (formData && formData.name) {
    metadata.customMetadata.name = formData.name
  }

  const promises = []

  handleStartUpdate && handleStartUpdate({ fileKey })
  let _sr = storageRef
  if (fileKey) { _sr = _sr.replace('null', fileKey) }
  const storage = getStorage()

  // create a thumbnail for the file
  createThumbnailIcons(file, true).then(res => {
    const allFiles = []
    // allFiles.push(file) // add the original
    res.forEach(r => { // add the thumbnails
      allFiles.push(new File([r], file.name + _storageSettings.thumbSuffix, { type: r.type }))
    })
    if (allFiles) {
      allFiles.forEach(file => {
        file.metadata = { name: 'test' }
        // LOOK
        const _storageRef = ref(storage, _sr + '/' + file.name)
        promises.push(uploadBytes(_storageRef, file, metadata))
        // const prodPath = _sr + '/' + file.name
        // uploadProdFile(prodPath, file, ulb)
        // promises.push(_storageRef.put(file, file.metadata))
      })
    }
  })
  return Promise.all(promises)
}
/**
 * 
 * @param {object} props (callback, action, files, autoReduceImage, appUserAccess, storageRef, handleStartUpdate, storageLocationType, formData)
 */
const uploadAllFilesPromise = async (props) => {

  const { callback, action, files, autoReduceImage, appUserAccess, storageRef, handleStartUpdate, storageLocationType, formData } = props
  const uploadAll = action.fileKey ? false : true

  console.log('storageLocationType', storageLocationType)

  const metadata = {
    customMetadata: {
      uploadedBy: appUserAccess.email,
    }
  };

  if (formData && formData.name) {
    metadata.customMetadata.name = formData.name
  }

  // const promises = []
  const storage = getStorage()

  if (files && (action.fileKey || uploadAll)) {

    const fileCount = Object.keys(files).length * 2
    let counter = 0

    // loop the pending uploads
    Object.keys(files).forEach(async fileKey => {

      let allowItem = uploadAll
      if (!allowItem && (fileKey === action.fileKey)) { allowItem = true }

      if (allowItem) {

        handleStartUpdate && handleStartUpdate({ fileKey })
        const mff = files[fileKey]
        let _sr = storageRef

        // add a prefix to some
        let prefix = '';

        switch (storageLocationType) {
          case gEnums.storageLocationTypes.pageGallery:
          case gEnums.storageLocationTypes.profileGallery:
          case gEnums.storageLocationTypes.clientProfiles:
            prefix = fileKey + '/'
            break;
          default:
          // nothing
        }

        const { type } = mff ? mff : {}

        switch (type) {
          case 'application/pdf':
            const _srf = _sr + '/' + prefix + mff.name
            const _storageRef = ref(storage, _srf)
            uploadBytes(_storageRef, mff, metadata).then(res => {
              callback(action)
            })
            break;

          default:
            createThumbnailIcons(mff, autoReduceImage).then(thumbnailResults => {

              const allFiles = []

              !autoReduceImage && allFiles.push(mff) // add the original 

              // add the thumbnails to the allFiles array
              thumbnailResults.forEach((r, index) => { // add the thumbnails 
                const { name } = r
                let _name;
                switch (storageLocationType) {
                  case gEnums.storageLocationTypes.pageDirect:
                    _name = 'defaultItem'
                    break;
                  default:
                    _name = mff.name
                  // nothing
                }
                if (name === 'n_200') {
                  allFiles.push(new File([r], _name + _storageSettings.thumbSuffix, { type: r.type }))
                } else {
                  allFiles.push(new File([r], _name, { type: r.type }))
                }
              })

              if (allFiles) {

                allFiles.forEach(file => {

                  const { name } = file
                  const isThumb = name === 'n_200' ? true : false

                  file.metadata = { name: 'test', email: appUserAccess.email }

                  const _fileName = name
                  const _altFileName = isThumb ? getFileName(file, storageLocationType, null, null, formData) : null
                  let _name = _altFileName ? _altFileName : _fileName


                  const _srf = _sr + '/' + prefix + _name
                  const _storageRef = ref(storage, _srf)

                  console.log('_storageRef', _storageRef, file)

                  uploadBytes(_storageRef, file, metadata).then(res => {
                    counter++
                    if (counter === fileCount) {
                      // updateMetadata(_storageRef, metadata)
                      //   .then((metadata) => {
                      //     console.log('metadata', metadata)
                      //     // Updated metadata for 'images/forest.jpg' is returned in the Promise
                      //   }).catch((error) => {
                      //     console.error(error);
                      //     // Uh-oh, an error occurred!
                      //   });
                      callback(action)
                    }
                  })
                })
              }
            })
        }
      }

      // create a thumbnail for the file

    })
  }
  // return Promise.all(promises)
}

const uploadManifestFiles = async (storageRef, manifestFiles, appUserAccess) => {

  const metadata = {
    customMetadata: {
      uploadedBy: appUserAccess.email,
    }
  };

  const promises = []
  const storage = getStorage()
  if (manifestFiles) {
    manifestFiles.forEach((manifestFile, index) => {
      manifestFile.metadata = { name: 'test', user: appUserAccess.uid, email: appUserAccess.email }
      const _storageRef = ref(storage, storageRef + '/' + manifestFile.name)
      promises.push(uploadBytes(_storageRef, manifestFile, metadata))
      // promises.push(_storageRef.put(manifestFile, manifestFile.metadata))
    })
  }
  return Promise.all(promises)
}

const getUploadUpdateStatus = (state, action) => {
  const { uploadUpdateStatus } = state
  const { fileKey } = action
  const _updateStatus = { ...uploadUpdateStatus }
  if (!_updateStatus[fileKey]) { _updateStatus[fileKey] = {} }
  _updateStatus[fileKey].status = uploadStatusTypes.pending
  return _updateStatus
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns updateStatus for each item in the currentGallery
 */
const getUploadGalleryStatus = (state, action) => {
  const { uploadUpdateStatus, } = state
  const { currentGallery } = action ? action : {}
  const _updateStatus = { ...uploadUpdateStatus }
  if (currentGallery) {
    Object.keys(currentGallery).forEach(fileKey => {
      if (!_updateStatus[fileKey]) { _updateStatus[fileKey] = {} }
      _updateStatus[fileKey].status = uploadStatusTypes.completed
    })
  }
  return _updateStatus
}

const getUploadSingleItemStatus = (state) => {
  const { uploadUpdateStatus, selectedFolderFiles } = state
  const _updateStatus = { ...uploadUpdateStatus }
  const allCount = selectedFolderFiles ? selectedFolderFiles.length : 0
  let successCount = 0
  if (selectedFolderFiles) {
    selectedFolderFiles.forEach(file => {
      const fileKey = file.name
      if (!_updateStatus[fileKey]) { _updateStatus[fileKey] = {} }
      _updateStatus[fileKey].status = uploadStatusTypes.completed
      successCount++
    })
  }
  if (allCount === successCount) {
    _updateStatus.allComplete = true
  }
  return _updateStatus
}

const getPending = (state, action) => {

  const { forBulk, viewListData, currentGallery, allowAppUsersInClientProfiles } = state
  const { selectedFolderFiles } = action ? action : {}

  const pendingFiles = {}
  const selectedFiles = []

  if (forBulk && viewListData) {

    // loop the data
    Object.keys(viewListData).forEach(dataKey => {

      const dataItem = viewListData[dataKey]
      const { lastName, firstName, name, fullName, email } = dataItem

      pendingFiles[dataKey] = { _itemKey: dataKey, firstName, lastName, name: lastName ? lastName + ', ' + firstName : name, fullName, email }

      const _file = selectedFolderFiles ? getFileFromName(selectedFolderFiles, dataKey, dataItem, pendingFiles) : null

      const _galleryItemKey = allowAppUsersInClientProfiles && email ? email : dataKey

      if (_file && _file.length === 1) {

        selectedFiles.push(_file)

        pendingFiles[dataKey].file = _file[0]
        pendingFiles[dataKey].fileName = _file.name

        if (currentGallery && currentGallery[dataKey]) {
          pendingFiles[dataKey].fileStatusType = fileStatusTypes.existing
        } else {
          pendingFiles[dataKey].fileStatusType = fileStatusTypes.approved
        }
      } else {
        if (currentGallery && currentGallery[_galleryItemKey]) {
          const { urls } = currentGallery[_galleryItemKey]
          pendingFiles[dataKey].urls = urls
          pendingFiles[dataKey].fileStatusType = fileStatusTypes.existing
        } else {
          pendingFiles[dataKey].fileStatusType = fileStatusTypes.missing
        }
      }
    })
  }

  return {
    pendingFiles,
    selectedFiles,
  }
}

const getUnselected = (selectedFolderFiles, selectedFiles) => {
  const unSelectedFiles = []
  selectedFolderFiles.forEach(ssf => {
    const fi = _.find(selectedFiles, function (sf) {
      return (sf && (sf[0] === ssf));
    })
    if (!fi) { unSelectedFiles.push(ssf) }
  })
  return unSelectedFiles
}

const getFileFromName = (selectedFolderFiles, dataKey, dataItem, pendingFiles) => {

  const { lastName, firstName, name, fullName, email } = dataItem

  pendingFiles[dataKey] = { _itemKey: dataKey, firstName, lastName, name: lastName ? lastName + ', ' + firstName : name, fullName, email }

  const _ln = lastName && lastName.toLowerCase().replace("'", '')
  const _fn = firstName && firstName.toLowerCase()
  const _nm = name && name.toLowerCase()

  const _file = _.filter(selectedFolderFiles, function (f) {
    let n = f.name.replace(/\W/g, '')
    n = n.replace('jpg', '')
    if (_ln && _fn) {
      return (n.toLowerCase().indexOf(_fn) >= 0 && n.toLowerCase().indexOf(_ln) >= 0)
    } else {
      return (n.toLowerCase().indexOf(_nm) >= 0)
    }
  })

  switch (_file.length) {
    case 0:
      return;
    case 1:
      return _file
    default:
      const _file2 = _.filter(_file, function (f) {
        if (fullName) {
          const fullNameS = fullName.split(' ')
          let exists = true;
          fullNameS.forEach(fns => {
            if (f.name.indexOf(fns) < 0) {
              exists = false
            }
          })
          return exists
        }
      })

      switch (_file2.length) {
        case 0:
          return;
        case 1:
          return _file2
        default:
          return;
      }
  }
}

const updateGalleryDir = (pathViews, galleries) => {
  const { direct } = galleries ? galleries : {}
  if (direct) {
    const _diss = {}
    Object.keys(direct).forEach(key => {
      const _dis = {}
      const di = direct[key]
      Object.keys(di).forEach(diKey => {
        const dii = di[diKey]
        const { pathz } = dii ? dii : {}
        const { full, thumbnail } = pathz ? pathz : {}
        let _full;
        let _thumbnail;
        if (full && full.indexOf('-thumb') < 0) {
          _full = full
        } else if (full) {
          _full = full.replace('-thumb', '')
        }
        if (thumbnail && thumbnail.indexOf('-thumb') < 0) {
          _thumbnail = thumbnail
        } else if (thumbnail) {
          _thumbnail = thumbnail.replace('-thumb', '')
        }
        const p = _full ? _full : _thumbnail
        _dis[diKey] = p //{ path: p }
      })
      _diss[key] = _dis
    })
    Object.keys(_diss).forEach(key => {
      const _did = _diss[key]
      const _refPath = createRefPath_event(pathViews, [_storageSettings.galleryPaths.galleryDirect, key])
      fs_set_doc(_refPath, _did).then(res => {
        // console.log('res', res)
      }).catch(error => {
        console.error(error);
      })
      // fs_update_dataCollection(pathViews, '_gallery_dir', key, _dis )
    })
  }
}

const ammendSelectedFiles = (action, files, handleSet_allFiles, handleManifestFiles) => {

  const { storageType } = action

  const _allSelectedFiles = [];
  const _allSelectedImages = []

  Object.keys(files).forEach((key) => {
    _allSelectedFiles.push(files[key])
  })

  switch (_allSelectedFiles.length) {
    case 1:
      switch (storageType) {
        case gEnums.storageTypes.manifest:
          const manifestName = 'manifest'
          const defaultFile = _allSelectedFiles[0]
          createManifestIcons(defaultFile).then(res => {
            const manifestFiles = []
            res.forEach((r, index) => {
              manifestFiles.push(new File([r], manifestName + '_' + index, { type: r.type }))
            })
            handleManifestFiles(manifestFiles, storageType)
          })
          break;
        default:
          if (_allSelectedFiles) {
            _allSelectedFiles.forEach(f => {
              const obj = URL.createObjectURL(f)
              _allSelectedImages.push(obj)
            })
          }
          handleSet_allFiles(_allSelectedFiles, _allSelectedImages)
      }
      break;
    default:
      handleSet_allFiles(_allSelectedFiles, _allSelectedImages)
      break;
  }
}