import React, { useContext, useEffect, useState } from 'react';
import { Button, Label, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../../global/auth/appUserAccessPermissions';
import { AppAccessContext } from '../../../../global/cnr/contexts/AppAccessContext';
import { AppUserContext } from '../../../../global/cnr/contexts/AppUserContext';
import { PapsContext } from '../../../../global/cnr/contexts/PapsContext';
import { uniqueKey } from '../../../../global/common/keys';
import { NumberPad, numberPadTypes } from '../../../../global/components/forms/elements/NumberPad';
// import ScoringSlider from '../../../../global/components/forms/elements/ScoringSlider';
// import SliderHorz from '../../../../global/components/forms/elements/Slider';
import { gEnums } from '../../../../global/enums/globalEnums';
import UiRequestAccess from '../../../../global/pageItem/modification/userActions/UiRequestAccess';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { calcVballMatch } from '../../helpers/match';
import MatchScoreTable from './MatchScoreTable';
import { MatchScoreKeypad, _keypadTypes } from './MatchScoreKeypad';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';

const _allowVertScoring = true

const MatchItemScoring = (props) => {

  const { setScoring } = props

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ? appUserContext : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { accessLevel } = appUserAccess ? appUserAccess : {}

  // papsContext 
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext
  const { view, viewKey } = paps_state ? paps_state : {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_current } = sportsMatch_state ? sportsMatch_state : {}

  // sportsMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state } = sportsRtMatchContext ? sportsRtMatchContext : {}
  const { match_rt: rtm } = sportsRtMatch_state ? sportsRtMatch_state : {}

  console.log('match_current', match_current)
  console.log('rtm', rtm)
  console.log('setScoring', setScoring)

  // appAccessContext
  const appAccessContext = useContext(AppAccessContext)
  const { appAccess_fns } = appAccessContext ? appAccessContext : {}

  const [showRequest, setShowRequest] = useState()
  const [currentScoringSet, setCurrentScoringSet] = useState()

  const { results, results_current } = match_current ? match_current : {}
  const { scores: scores_r, score: score_r } = results ? results : {}
  const { scores: scores_rc, score: score_rc } = results_current ? results_current : {}
  const { scores: scores_rt, score: score_rt } = rtm ? rtm : {}

  let _scores;
  let _score

  if (score_rt) {
    _score = score_rt
  } else if (score_rc) {
    _score = score_rc
  } else if (score_r) {
    _score = score_r
  }

  if (scores_rt) {
    _scores = scores_rt
  } else if (scores_rc) {
    _scores = scores_rc
  } else if (scores_r) {
    _scores = scores_r
  }

  console.log('_score', _score)
  console.log('_scores', _scores)

  const rez = results_current && (results_current.scores || results_current.score) ? results_current : results
  const { score: score_match, complete, scores, scoresCompleted } = rez ? rez : {}


  useEffect(() => {
    if (!results_current) {
      sportsMatch_handlers.handleAmmend_currentMatch(match_current, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const allowUserUpdate = appAccess_fns.allowRequest(view, viewKey)

  // const handleSlideChange = (v, opts) => {
  //   handleScoreChange(null, { value: v, opts })
  // }

  const allowUpdate = accessLevel >= gEnums.authLevels.admin.value || allowUserUpdate === gEnums.accessStatusTypes.approved ? true : false

  const handleScoreChange = (e, data) => {
    const { value: score, opts } = data
    const { team, setNumber } = opts
    const cm = { ...match_current }
    if (!cm.results_current) { cm.results_current = { setNumber: 1, score: { home: 0, away: 0 } } }
    if (!cm.results_current.score) { cm.results_current.score = { home: 0, away: 0 } }
    cm.results_current.score[team] = score
    calcVballMatch(cm)
    sportsMatch_handlers.handleChange_score(score, team, setNumber)
  }

  const handleScorePad = (e, team, score) => {
    const cm = { ...match_current }
    e.preventDefault()
    e.stopPropagation()
    if (!cm.results_current) { cm.results_current = { setNumber: 1, score: { home: 0, away: 0 } } }
    if (!cm.results_current.score) { cm.results_current.score = { home: 0, away: 0 } }
    cm.results_current.score[team] = score
    calcVballMatch(cm)
    sportsMatch_handlers.handleAmmend_currentMatch(cm)
  }

  const scoreLabel = (score, team) => <Label key={uniqueKey('mis', 'sc', 'sl', team)} color={(complete ? 'green' : 'grey')}>{score}</Label>

  const teamScoreLabel = (team, score, allowSet, teamSetScore) => {
    const teamScore = score_match && score_match[team] ? score_match[team] : 0
    let color = teamScore === score ? 'blue' : 'black'
    let disabled = false
    if (score === 3) {
      if (team === 'home') {
        if (score_match && score_match.away && score_match.away === 3) {
          disabled = true
        }
      } else {
        if (score_match && score_match.home && score_match.home === 3) {
          disabled = true
        }
      }
    }

    const lineScoring = () => <NumberPad value={teamSetScore} onChange={handleScoreChange} numberPadType={numberPadTypes.grid} opts={{ team, setNumber: score, disabled: !allowSet, noSize: true }} />

    return setScoring ?
      lineScoring()
      :
      <Label
        key={uniqueKey('mis', 'l', team, score)}
        color={(disabled ? 'grey' : color)}
        onClick={(e) => { !disabled && handleScorePad(e, team, score) }}>
        {score}
      </Label>
  }

  // 0 - 1 - 2 - 3
  const teamScoreColumn = (team, name) => {
    const min = 0
    const max = 4
    const divs = []
    const teamScore = score_match && score_match[team] ? score_match[team] : 0

    divs.push(<div key={uniqueKey('mis', team, 'n')}>{name}</div>)
    divs.push(scoreLabel(teamScore, team))
    divs.push(<div key={uniqueKey('mis', team, 's')}>{'Sets'}</div>)

    const divvs = Array.from({ length: max }, (_, index) => teamScoreLabel(team, index));
    const _divs = [...divs, ...divvs]

    return <div key={uniqueKey('mis', team, 'ts')} className={'team-score'}>{_divs}</div>
  }

  // scores - score - scores
  const teamScores = () => {
    return <div className={'team-scores'}>
      {teamScoreColumn('away', match_current.away.name)}
      {teamScoreColumn('home', match_current.home.name)}
    </div>
  }

  const teamScoringColumns = () => {
    return currentScoringSet ?
      <MatchScoreKeypad />
      // <ScoringSlider match_current={match_current} currentScoringSet={currentScoringSet} handleCancel={setCurrentScoringSet} sportsMatch_handlers={sportsMatch_handlers} />
      :
      <MatchScoreKeypad keypadType={setScoring ? _keypadTypes.realtime : _keypadTypes.normal} />
    // <div className={'team-scoring'}>
    //   {teamScoringColumn('away', match_current.away.name)}
    //   {setColumn()}
    //   {teamScoringColumn('home', match_current.home.name)}
    // </div>

  }
  // scores - score - scores
  const teamScoring = () => {
    return <React.Fragment>
      <MatchScoreTable match_current={match_current} />
      {teamScoringColumns()}
    </React.Fragment>
  }

  const request = () => {
    return <Segment.Group>
      <Segment>{'You do not have permission to update this match'}{'?'}</Segment>
      <Segment textAlign={'right'}>
        <Button size={'small'} color={'blue'} onClick={(e) => { setShowRequest(true) }}>Request Access</Button>
        <Button size={'small'} onClick={(e) => { setShowRequest() }}>Close</Button>
      </Segment>
    </Segment.Group>
  }

  const matchScoringUpdate = () => {
    if (!allowUpdate) {
      return showRequest ? requestAccess() : request()
    } else {
      return <div className={'scoring-update'}>
        {setScoring ? teamScoring() : teamScores()}
      </div>
    }
  }

  const requestAccess = () => <UiRequestAccess direct={true} directCancel={setShowRequest} />

  return matchScoringUpdate()
}

export default MatchItemScoring

