import React, { useState } from 'react';
import TimeKeeper from 'react-timekeeper';
import { Form } from 'semantic-ui-react';
import { validateTime } from '../../../validation/validation';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';

export const TimePicker = (props) => {

  const { fip } = props ? props : {}

  const [selectedTime, setSelectedTime] = useState(fip && fip.value ? fip.value : '12:00')
  const [showTime, setShowTime] = useState()

  const _selectedTime = selectedTime && selectedTime.length === 8 ? selectedTime.substring(selectedTime, 5) : selectedTime

  const validTime = validateTime(_selectedTime)

  const handleShowTime = () => setShowTime(!showTime)

  const handleDone = () => {
    const data = {
      propname: fip.propname,
      value: _selectedTime
    }
    fip.onChange(null, data)
    setShowTime(false)
  }

  const handleSelectedTime = (t) => {
    const _t = t && t.length === 4 ? '0' + t : t
    setSelectedTime(_t)
  }

  const timeKeeper = () => <div className={'time-keeper'}><TimeKeeper
    time={validTime ? _selectedTime : '12:00'}
    onChange={(data) => handleSelectedTime(data.formatted24)}
    onDoneClick={handleDone}
    switchToMinuteOnHourSelect
  /></div>

  const stepTimer = () => <FullPageWrapper
    content={_selectedTime && timeKeeper()}
    handleCancel={handleShowTime}
    topperCaption={'Select Time'}
    topperCaption2={!validTime && 'The current selected time is invalid'}
  />

  return <React.Fragment>
    <Form.Input {...fip} icon={'clock'} fluid onClick={(e) => handleShowTime(fip)} />
    {showTime && stepTimer()}
  </React.Fragment>

}
