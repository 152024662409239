import { where } from 'firebase/firestore'
import _ from 'lodash'
import { fs_get_data } from '../../firestoreData/appData/fsAppData'
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths'
import { fs_add_doc, fs_delete_doc, fs_set_doc, fs_update_doc } from '../../firestoreData/appData/fsData'

const rts = {
  handleActivateVoting: 'handleActivateVoting',
  handleAmmendCurrentVotes: 'handleAmmendCurrentVotes',
  handleAmmendCurrentVotingItems: 'handleAmmendCurrentVotingItems',
  handleAmmendVoting: 'handleAmmendVoting',
  handleAmmendVotingItems: 'handleAmmendVotingItems',
  handleCurrentVote: 'handleCurrentVote',
  handleDeleteVoteItem: 'handleDeleteVoteItem',
  handleDeleteVoting: 'handleDeleteVoting',
  handleGetVoting: 'handleGetVoting',
  handleNoVote: 'handleNoVote',
  handleSaveVote: 'handleSaveVote',
  handleSaveVoteItem: 'handleSaveVoteItem',
  handleSaveVoting: 'handleSaveVoting',
  handleShowAddEditVoteItem: 'handleShowAddEditVoteItem',
  handleShowAddEditVoting: 'handleShowAddEditVoting',
  handleShowBio: 'handleShowBio',
  handleShowVoting: 'handleShowVoting',
  handleVoting: 'handleVoting',
  handleYesVote: 'handleYesVote',
}
// 2022NSC
export const votingReducer = (state, action) => {

  const { pathViews, currentVote, votingInfos, selectedVotingKey, voteItemEditKey, votes } = state
  const { dispatch, type } = action

  const { handleAmmendVoting, handleCurrentVote, handleAmmendVotingItems, handleAmmendCurrentVotes, handleAmmendCurrentVotingItems } = votingHandlers(dispatch)

  const _votingInfos = votingInfos ? { ...votingInfos } : {}
  const _votingInfo = _votingInfos ? _votingInfos[selectedVotingKey] : {}
  let { voteItems } = _votingInfo ? _votingInfo : {}
  const _voteItems = voteItems ? voteItems : {}

  const _votes = { ...votes }

  switch (type) {
    case rts.handleGetVoting:
      const { activeOnly } = action
      const _refPath = createRefPath_event(pathViews, ['_voting'])
      if (activeOnly) {
        const wheres = activeOnly ? where('active', '==', true) : null
        fs_get_data({ refPath: _refPath, callback: handleCurrentVote, wheres, opts: { listen: true, returnFirstObject: true } })
      } else {
        fs_get_data({ refPath: _refPath, callback: handleAmmendVoting, opts: { listen: true } })
      }
      return { ...state }

    case rts.handleAmmendVoting:
      return { ...state, votingInfos: action.results }

    case rts.handleCurrentVote:
      const { result } = action
      const { _itemKey } = result ? result : {}
      const _refPath3 = createRefPath_event(pathViews, ['_voting', _itemKey, 'voteItems'])
      fs_get_data({ refPath: _refPath3, callback: handleAmmendCurrentVotingItems, opts: { listen: true } })

      const _refPath5 = createRefPath_event(pathViews, ['_voting', _itemKey, 'votes'])
      fs_get_data({ refPath: _refPath5, callback: handleAmmendCurrentVotes, opts: { listen: true } })

      return { ...state, currentVote: result }

    case rts.handleAmmendVotingItems:
      return { ...state, voteItems: action.results }

    case rts.handleAmmendCurrentVotingItems:
      return { ...state, currentVoteItems: action.results }

    case rts.handleAmmendCurrentVotes:
      let _votingStatus;
      if (action.results && action.results[state.profileId]) {
        _votingStatus = { allow: false }
      } else {
        _votingStatus = { allow: true }
      }
      return { ...state, voteResults: action.results, votingStatus: _votingStatus }

    case rts.handleActivateVoting:
      const _activatePath = createRefPath_event(pathViews, ['_voting', selectedVotingKey])
      const dtu = { active: !_votingInfo.active }
      fs_update_doc(_activatePath, dtu)
      return { ...state }

    case rts.handleDeleteVoteItem:
      if (voteItemEditKey) {
        const _editPath = createRefPath_event(pathViews, ['_voting', voteItemEditKey])
        fs_delete_doc(_editPath).then(res => {
          console.log('res', res)
        }).catch(error => {
          console.error(error);
        })
      }
      return { ...state, showAddEditVoteItem: null }

    case rts.handleDeleteVoting:
      if (selectedVotingKey) {
        const _editPath = createRefPath_event(pathViews, ['_voting', selectedVotingKey])
        fs_delete_doc(_editPath)
      }
      return { ...state }

    case rts.handleSaveVoteItem:
      if (selectedVotingKey) {
        const { name } = action.formData
        _voteItems[name] = action.formData
        let dataToUpdate = action.formData
        let _updatePath;
        if (voteItemEditKey) {
          _updatePath = createRefPath_event(pathViews, ['_voting', selectedVotingKey, 'voteItems', voteItemEditKey])
          fs_update_doc(_updatePath, dataToUpdate)
        } else {
          _updatePath = createRefPath_event(pathViews, ['_voting', selectedVotingKey, 'voteItems'])
          fs_add_doc(_updatePath, dataToUpdate)
        }
      }
      return { ...state, showAddEditVoteItem: false }

    case rts.handleSaveVoting:
      if (selectedVotingKey) {
        const _editPath = createRefPath_event(pathViews, ['_voting', selectedVotingKey])
        fs_update_doc(_editPath, action.formData)
      } else {
        const _addPath = createRefPath_event(pathViews, ['_voting'])
        fs_add_doc(_addPath, action.formData)
      }
      return { ...state, showAddEditVoting: null }

    case rts.handleShowAddEditVoteItem:
      const _voteItemEditKey = !_.isObject(action.key) ? action.key : null
      return { ...state, showAddEditVoteItem: !state.showAddEditVoteItem, voteItemEditKey: _voteItemEditKey }

    case rts.handleShowAddEditVoting:
      if (action.e) { action.e.stopPropagation() }
      return { ...state, showAddEditVoting: !state.showAddEditVoting, votingEditKey: action.key }

    case rts.handleShowBio:
      return { ...state, bioKey: action.bioKey }

    case rts.handleShowVoting:
      const _refPath2 = createRefPath_event(pathViews, ['_voting', action.key, 'voteItems'])
      fs_get_data({ refPath: _refPath2, callback: handleAmmendVotingItems, opts: { listen: true } })

      const _refPath4 = createRefPath_event(pathViews, ['_voting', action.key, 'votes'])
      fs_get_data({ refPath: _refPath4, callback: handleAmmendCurrentVotes, opts: { listen: true } })

      return { ...state, selectedVotingKey: action.key, selectedVotingInfo: _votingInfo }

    case rts.handleNoVote:
      _votes[action.key] = false
      return { ...state, votes: _votes }

    case rts.handleYesVote:
      _votes[action.key] = true
      return { ...state, votes: _votes }

    case rts.handleSaveVote:
      const _votePath = createRefPath_event(pathViews, ['_voting', currentVote._itemKey, 'votes', state.profileId])
      fs_set_doc(_votePath, { votes: _votes }, false, action.callback)
      return { ...state }

    default:
      return { ...state }
  }
}

export const votingInitialState = (initState) => {
  return { ...initState, votes: {} }
};

export const votingHandlers = (dispatch) => {
  return {
    handleActivateVoting: () => { dispatch({ type: rts.handleActivateVoting, dispatch }) },
    handleAmmendCurrentVotes: (results) => { dispatch({ type: rts.handleAmmendCurrentVotes, dispatch, results }) },
    handleAmmendCurrentVotingItems: (results) => { dispatch({ type: rts.handleAmmendCurrentVotingItems, dispatch, results }) },
    handleAmmendVoting: (results) => { dispatch({ type: rts.handleAmmendVoting, dispatch, results }) },
    handleAmmendVotingItems: (results) => { dispatch({ type: rts.handleAmmendVotingItems, dispatch, results }) },
    handleCurrentVote: (result) => { dispatch({ type: rts.handleCurrentVote, dispatch, result }) },
    handleDeleteVoteItem: () => { dispatch({ type: rts.handleDeleteVoteItem, dispatch }) },
    handleDeleteVoting: () => { dispatch({ type: rts.handleDeleteVoting, dispatch }) },
    handleGetVoting: (activeOnly) => { dispatch({ type: rts.handleGetVoting, dispatch, activeOnly }) },
    handleNoVote: (key) => { dispatch({ type: rts.handleNoVote, dispatch, key }) },
    handleSaveVote: (callback) => { dispatch({ type: rts.handleSaveVote, dispatch, callback }) },
    handleSaveVoteItem: (formData) => { dispatch({ type: rts.handleSaveVoteItem, dispatch, formData }) },
    handleSaveVoting: (formData) => { dispatch({ type: rts.handleSaveVoting, dispatch, formData }) },
    handleShowAddEditVoteItem: (key) => { dispatch({ type: rts.handleShowAddEditVoteItem, dispatch, key }) },
    handleShowAddEditVoting: (key, e) => { dispatch({ type: rts.handleShowAddEditVoting, dispatch, key, e }) },
    handleShowBio: (bioKey) => { dispatch({ type: rts.handleShowBio, dispatch, bioKey }) },
    handleShowVoting: (key) => { dispatch({ type: rts.handleShowVoting, dispatch, key }) },
    handleYesVote: (key) => { dispatch({ type: rts.handleYesVote, dispatch, key }) },
  }
}
