import { getAppUserAccess } from "../../auth/appUserAccessPermissions";
import { createItemKeys } from "../../common/convert";
import { getUserCollectionData, updateUserCollectionData, updateMarkAsRead } from "../../firestoreData/appUserData/getAppUserData";

export const appUserDataReducerTypes = {
  checkConversation: 'checkConversation',
  handleError: 'handleError',
  handleGetConversations: 'handleGetConversations',
  handleGetNotes: 'handleGetNotes',
  handleLoadConversations: 'handleLoadConversations',
  handleLoadNotes: 'handleLoadNotes',
  handleMarkAsRead: 'handleMarkAsRead',
  handleMessageSuccess: 'handleMessageSuccess',
  loadConversation: 'loadConversation',
  handleSaveConversation: 'handleSaveConversation',
  handleSaveNote: 'handleSaveNote',
  setMessageData: 'setMessageData',
}

export const userCollectionTypes = {
  conversations: 'conversations',
  notes: 'notes',
}
export const _conversationsCollection = '_conversations'
export const _notesCollection = '_notes'

export const appUserDataReducer = (state, action) => {

  const { messageData, paps_state, appUser, page_fns } = state
  const { data, type, dispatch, resultData, noteId } = action
  const appUserAccess = getAppUserAccess(appUser)
  const { uid, appUserSessionKey } = appUserAccess ? appUserAccess : {}

  const { handleLoadConversations, handleLoadNotes, handleError } = appUserDataHandlers(dispatch)

  switch (type) {

    case appUserDataReducerTypes.handleGetConversations:
      getUserCollectionData(appUserAccess, userCollectionTypes.conversations, action.paps_state, handleLoadConversations, handleError)
      return { ...state }

    case appUserDataReducerTypes.handleGetNotes:
      getUserCollectionData(appUserAccess, userCollectionTypes.notes, action.paps_state, handleLoadNotes, handleError)
      return { ...state }

    case appUserDataReducerTypes.handleSaveConversation:
      updateUserCollectionData(appUserAccess, userCollectionTypes.conversations, paps_state, messageData, dispatch)
      return { ...state }

    case appUserDataReducerTypes.handleSaveNote:
      updateUserCollectionData(appUserAccess, userCollectionTypes.notes, paps_state, action.note, action.callback)
      return { ...state }

    case appUserDataReducerTypes.handleLoadConversations:
      return {
        ...state,
        conversations: resultData,
      }

    case appUserDataReducerTypes.handleLoadNotes:
      createItemKeys(resultData)
      return { ...state, notes: resultData }

    case appUserDataReducerTypes.handleError:
      return {
        ...state,
        err: action.err,
      }

    case appUserDataReducerTypes.setMessageData:
      const md = { ...messageData }
      md[data.placeholder] = data.value
      return {
        ...state,
        messageData: md,
        messageReady: md && md.title && md.body ? true : false
      }
    case appUserDataReducerTypes.handleMarkAsRead:
      updateMarkAsRead(appUser, paps_state, noteId, page_fns)
      return { ...state }

    default:
      return { ...state }
  }
}

export const appUserDataInitialState = (appUser, paps_state, page_state, page_fns) => {
  return {
    appUser,
    paps_state,
    messageData: {},
    messageReady: false,
    fromConversation: null,
    sentConversation: null,
    page_state,
    page_fns,
  }
};

export const appUserDataHandlers = (dispatch) => {
  return {
    checkConversation: () => { dispatch({ type: appUserDataReducerTypes.checkConversation }) },
    handleError: (err) => { dispatch({ type: appUserDataReducerTypes.handleError, err }) },
    handleGetConversations: (paps_state) => { dispatch({ type: appUserDataReducerTypes.handleGetConversations, dispatch, paps_state }) },
    handleGetNotes: (paps_state) => { dispatch({ type: appUserDataReducerTypes.handleGetNotes, dispatch, paps_state }) },
    handleLoadConversations: (resultData) => { dispatch({ type: appUserDataReducerTypes.handleLoadConversations, resultData }) },
    handleLoadNotes: (resultData) => { dispatch({ type: appUserDataReducerTypes.handleLoadNotes, resultData }) },
    handleMarkAsRead: (noteId) => { dispatch({ type: appUserDataReducerTypes.handleMarkAsRead, noteId }) },
    handleSaveConversation: () => { dispatch({ type: appUserDataReducerTypes.handleSaveConversation, dispatch }) },
    handleSaveNote: (note, callback) => { dispatch({ type: appUserDataReducerTypes.handleSaveNote, dispatch, note, callback }) },
    setMessageData: (data) => { dispatch({ type: appUserDataReducerTypes.setMessageData, data }) },
  }
} 