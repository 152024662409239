import React from 'react';
import { Form } from 'semantic-ui-react';
import FormWithProvider from './FormWithProvider';

/**
 * 
 * @param {object} props (_formProp) 
 * @returns FormWithProvider wrapped in a `Form`
 * @description the formAttributes is added as attibutes to the `Form`
 */
const FormForm = (props) => {
  const { formAttributes } = props ? props : {}
  return <Form {...formAttributes}>
    <FormWithProvider {...props} />
  </Form>
}

export default FormForm