import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { SettingParentContext } from '../../../cnr/contexts/SettingParentContext';
import FormForm from '../../../components/forms/FormForm';
import { getFormPropz } from '../../../components/forms/props/formPropz';
import { appFormTypes } from '../../../enums/appFormTypes';
import GenericButton from '../../../genericControls/GenericButton';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';

const MapInputContent = (props) => {

  const { lineKey, updateList, removeFromList, isNew } = props

  const settingsParentContext = useContext(SettingParentContext)
  const { states, settings } = settingsParentContext ? settingsParentContext : {}
  const { appForms_state } = states ? states : {}

  const { homeSettings } = settings ? settings : {}
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { settingsOptions: settingsOptions_home } = global_home ? global_home : {}

  let { useDarkMode } = settingsOptions_home ? settingsOptions_home : {}

  // appFormsContext
  // const appFormsContext = useContext(AppFormsContext);
  // const { appForms_state } = appFormsContext ? appFormsContext : {}
  const { appForms } = appForms_state ? appForms_state : {}

  const [lineData, setLineData] = useState()

  const isReady = () => {
    if (lineData) {
      if (isNew) {
        if (lineData.key && lineData.text && lineData.value) { return true }
      } else {
        if (lineData.text && lineData.value) { return true }
      }
    }
  }

  const lineReady = isReady()

  const handleLocalPropChange = (value) => {
    setLineData(value)
    updateList(value)
  }

  // const handleUpdate = () => updateList(lineData)
  const handleRemove = () => removeFromList(lineData)

  const [formProps, setFormProps] = useState()

  useEffect(() => {
    if (appForms && appForms[appFormTypes.mapInput]) {
      const _lineData = props.lineData ? props.lineData : { key: lineKey, value: lineKey, text: _.startCase(props.lineKey) }
      const vprs = appForms[appFormTypes.mapInput]
      const form_handlers = { handleFormDataChange_local: handleLocalPropChange }
      const fpp = { form_handlers, viewProps: vprs, data_current: _lineData }
      const fprs = getFormPropz(fpp)
      setFormProps(fprs)
      setLineData(_lineData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [lineKey]);

  const content = () => <Segment inverted={useDarkMode}>
    <FormForm formAttributes={{ inverted: useDarkMode, className: 'mlc' }} formProps={formProps} />
  </Segment>

  const gb = (key, caption, icon, onClick, clickOptions, opts, noE) => <GenericButton gbProps={{ key, caption, icon, onClick, clickOptions, opts, noE }} />

  const footer = () => <div className={'form-buttons'}>
    {!isNew && gb('btn-remove-line', 'Remove', 'delete', handleRemove, null, { disabled: !lineReady, color: 'red' })}
  </div>

  if (formProps) {
    return <Wrapper
      content={content()}
      footer={footer()}
      wrapperType={wrapperTypes.padded}
    />
  }

  return <div></div>

}

export default MapInputContent