import { PaokeSwiper } from 'paoke-swiper'
import React, { useContext } from 'react'
import GenericProvider, { GenericContext } from '../cnr/contexts/GenericContext'

/**
 * 
 * @param {object} props (_key, swipeContentType, swipeItems, view, fn_getContent, fromAppUserProfile)
 * @returns A swiper 
 * @description This MUST be wrapped in a GenericProvider
 */
const UiPaokeSwiper = (props) => {

  const { _key, swipeContentType, swipeItems, view, fn_getContent, fromAppUserProfile } = props

  const genericContext = useContext(GenericContext)
  const { generic_contexts } = genericContext

  return <PaokeSwiper
    _key={_key}
    swipeContentType={swipeContentType}
    swipeItems={swipeItems}
    view={view}
    fn_getContent={fn_getContent}
    parent_contexts={generic_contexts}
  />
}

const UiPaokeSwiperGeneric = (props) => <GenericProvider>
  <UiPaokeSwiper {...props} />
</GenericProvider>

export default UiPaokeSwiperGeneric
