import _ from 'lodash';
import React, { Suspense, useContext, useEffect, useReducer, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import AppNotifications, { appNotificationTypes } from '../appNotifications/AppNotifications';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import ActionProvider, { ActionContext } from '../cnr/contexts/ActionContext';
import DataProvider from '../cnr/contexts/DataContext';
import { GlobalContext } from '../cnr/contexts/GlobalContext';
import { PreviewContext } from '../cnr/contexts/PreviewContext';
import { SettingParentContext } from '../cnr/contexts/SettingParentContext';
import { SettingsAreaContext } from '../cnr/contexts/SettingsAreaContext';
import ViewSettingsComponentProvider, { ViewSettingsComponentContext } from '../cnr/contexts/ViewSettingsComponentContext';
import { ViewSettingsContext } from '../cnr/contexts/ViewSettingsContext';
import ViewSettingsMenuProvider from '../cnr/contexts/ViewSettingsMenuContext';
import { helpTypes } from '../cnr/reducers/HelpReducer';
import { sortInitialState, sortReducer } from '../cnr/reducers/SortReducer';
import { getSwipeProps } from '../cnr/reducers/ViewSettingsComponentReducer';
import { uniqueKey } from '../common/keys';
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import { gEnums } from '../enums/globalEnums';
import { _profileCollectionName } from '../firestoreData/profiles/getProfile';
import { getTempSettingsState } from '../firestoreData/settings/ammendSettings';
import { UiDataFilters, fullFilterTypes } from '../pageItem/UiDataFilters';
import { vsComponentTypes } from './enums/vsComponentTypes';
import AllHeaders from './headers/AllHeaders';
import SegItemWrap from './wrappers/SegItemWrap';

const AppAccessRequests = React.lazy(() => import('../components/access/AppAccessRequests'));
const CardSettings = React.lazy(() => import("./create/createSubs/CardSettings"));
const ConfirmationQuestion = React.lazy(() => import('../components/alerts/ConfirmationQuestion'));
const CreateSomething = React.lazy(() => import("./create/CreateSomething"));
const DataConstraintBuilder = React.lazy(() => import("./create/createSubs/DataConstraintBuilder"));
const DeleteClient = React.lazy(() => import('./events/DeleteClient'));
const DeleteEvent = React.lazy(() => import('./events/DeleteEvent'));
const FormsList = React.lazy(() => import('./components/FormsList'));
const Help = React.lazy(() => import('../components/help/Help'));
const HomeGlobalSettings = React.lazy(() => import('./HomeGlobalSettings'));
const ImageMappingBuilder = React.lazy(() => import("./create/createSubs/ImageMappingBuilder"));
const ImageMappingList = React.lazy(() => import('../components/forms/elements/ImageMappingList'));
const JsonPreview = React.lazy(() => import('../components/alerts/JsonPreview'));
const JsonViewer = React.lazy(() => import('../components/viewers/JsonViewer'));
const PropDropper = React.lazy(() => import('../dragNDrops/PropDropper'));
const SemFileViewer = React.lazy(() => import('../components/sem/SemFileViewer'));
const SettingsActionItems = React.lazy(() => import('./components/SettingsActionItems'));
const SettingsAreaMenu = React.lazy(() => import('./components/menus/SettingsAreaMenu'));
const SettingsConsole = React.lazy(() => import('./components/menus/SettingsConsole'));
const SettingsFormPage = React.lazy(() => import('./subComponents/SettingsFormPage'));
const TabbedDataView = React.lazy(() => import('../components/tables/TabbedDataView'));
const Uploader = React.lazy(() => import('../components/uploading/Uploader'));
const ViewDataSidebar = React.lazy(() => import('./data/ViewDataSidebar'));

// LOOK - make this lazy

export const newSwipeItem = (vsComponentType) => {
  return {
    key: vsComponentType
  }
}

const sortAllows = ['props', 'propSections', 'viewItems', 'views']
const arrangeAllows = ['props', 'views']
const _showConsole = false

/**
 * Contains the settings component(s) for the selected item
 * @returns a view for a group of settings
 * @description First child of ViewSettingsComponentProvider, which contains all the information
 * @description
 */
const ViewSettingsComponent = () => {

  // settingsParentContext
  const settingsParentContext = useContext(SettingParentContext)
  const { handlers, states, setting } = settingsParentContext ? settingsParentContext : {}
  const { appUser_state, paps_state, page_state, appSettings_state } = states ? states : {}
  const { clientSettings } = setting ? setting : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)

  const { global: global_client } = clientSettings ? clientSettings : {}
  const { appSignIn: appSignIn_client } = global_client ? global_client : {}
  const { forceEventSignInAtClient, useClientSignInSettings: useClientSignInSettings_client } = appSignIn_client ? appSignIn_client : {}

  // papsContext
  const { pathViews, appArea } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}

  // pageContext  
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_viewItems, aps_views, aps_page } = pageSettings ? pageSettings : {}
  const { appSignIn, settingsOptions, parentDefaults } = aps_global ? aps_global : {}
  const { signInToClient, useClientSignInSettings: useClientSignInSettings_event } = appSignIn ? appSignIn : {}
  const { useDarkMode } = settingsOptions ? settingsOptions : {}
  const { useClientThemes, useHomeTransitions } = parentDefaults ? parentDefaults : {}

  // temp depending
  const _signInAtClient = forceEventSignInAtClient || signInToClient
  const _useClientSignInSettings = useClientSignInSettings_client || useClientSignInSettings_event
  const _clientSignIn = _signInAtClient || _useClientSignInSettings

  // previewContext
  const previewContext = useContext(PreviewContext);
  const { preview_handlers } = previewContext ? previewContext : {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext);
  const { settingsArea_state, settingsArea_fns } = settingsAreaContext ? settingsAreaContext : {}
  const { saSplits, settingsOriginType } = settingsArea_state ? settingsArea_state : {}

  // globalContext
  const globalContext = useContext(GlobalContext)
  const { global_state } = globalContext ? globalContext : {}
  const { settings_globalPageItems } = global_state ? global_state : {}

  const { settingsPreviewOn } = appSettings_state ? appSettings_state : {}

  // viewSettingsContext
  const viewSettingsContext = useContext(ViewSettingsContext)
  const { viewSettings_state, viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}
  const { testItem, settings_temp, showPermissions, helpKey, settings_preview_changed, settings_preview, currentFormData } = viewSettings_state ? viewSettings_state : {}

  // this is set in ViewSettingsComponentReducer and contains the information needed to fill the component
  // viewSettingsComponentContext  
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { isGlobal, data_current, updateProps } = vsc_state ? vsc_state : {}
  let { swipedItem, swipedItems } = vsc_state ? vsc_state : {}

  const { _global, _viewItems } = getTempSettingsState(settings_temp, isGlobal)

  // actionContext
  const actionContext = useContext(ActionContext)
  const { action_state } = actionContext ? actionContext : {}
  const { questionProps, preview } = action_state ? action_state : {}

  // local state
  const [sortOk, setSortOk] = useState()
  const [showSort] = useState()
  const [sortedItems, setSortedItems] = useState()
  const [propSections, setPropSections] = useState()

  const { rawData } = viewSettings_state

  let viewItems = _viewItems

  // this is unique to settings_globalPageItems  
  if (swipedItem && swipedItem.key === vsComponentTypes.globalPageItems && settings_globalPageItems) {
    viewItems = settings_globalPageItems
  }

  // local state
  const [sort_state] = useReducer(sortReducer, sortInitialState());
  const [vscProps, setVscProps] = useState()

  const { formProps, viewProps, isMulti, isStatic, restrictedAccess } = vscProps ? vscProps : {}

  useEffect(() => {
    // if settings_preview_changed, update the preview 
    if (settings_preview && settingsPreviewOn) {
      preview_handlers.handleSettingsPreview(settings_preview, settingsPreviewOn)
    }
    // eslint-disable-next-line
  }, [settings_preview_changed]);

  useEffect(() => {
    const _vscProps = getSwipeProps(data_current, viewSettings_handlers, swipedItem, updateProps, viewItems, settingsArea_fns, isGlobal, appArea)
    if (swipedItem) {
      switch (swipedItem.key) {
        case 'appActions':
        case 'files':
        case 'manifestPicker':
        case 'productionActions':
        case 'settingsActions':
          _vscProps.isStatic = true
          break;
        default:
        // nothing
      }
    }
    setVscProps(_vscProps)
    // eslint-disable-next-line
  }, []);

  const setSortedReady = (nx, propSections) => {
    setSortOk(true)
    setSortedItems(nx)
    if (propSections) { setPropSections(propSections) }
  }

  const getMessage = (key) => {
    let message = 'The SETTINGS_TYPE settings are being controlled by the SETTINGS_AREA. The settings will be saved, but changing the settings here will have no effect and will continue to show the SETTINGS_AREA settings.'
    const captionReplace = _.startCase(key)
    switch (key) {
      case 'appSignIn':
        if (_clientSignIn && eventKey) {
          return message.replace('SETTINGS_TYPE', captionReplace).replace('SETTINGS_AREA', '`client settings`')
        }
        break;
      case 'themeColors':
        if (useClientThemes) {
          return message.replace('SETTINGS_TYPE', captionReplace).replace('SETTINGS_AREA', '`client settings`')
        }
        break;
      case 'transitionItems':
        if (useHomeTransitions) {
          return message.replace('SETTINGS_TYPE', captionReplace).replace('SETTINGS_AREA', '`home settings`')
        }
        break;
      default:
      // nothing
    }
  }

  /** Returns whether the `add` button will appear at the top of the header based on the `key` */
  const allowAdd = (key, _subKey, isList) => {
    if (isList) {
      return true
    } else {
      switch (key) {
        case vsComponentTypes.globalPageItems:
        case vsComponentTypes.notificationsManager:
        case vsComponentTypes.props:
        case vsComponentTypes.rssFeeds:
        case vsComponentTypes.viewItems:
        case vsComponentTypes.views:
          return true;
        default:
          // nothing
          return false
      }
    }
  }

  const allowSection = (key, _subKey, isList) => {
    if (isList) {
      return true
    } else {
      switch (key) {
        case vsComponentTypes.props:
          return true;
        default:
          return false
      }
    }
  }

  /**
   * 
   * @param {*} key 
   * @param {*} viType 
   * @param {*} buttonPermissions 
   * @returns the header for the item based on the key, viType and button permissions
   */
  const itemHeader = (key, viType, buttonPermissions) => {
    const stateProps = {
      sortOk,
      propSections,
      sortedItems,
    }
    if (buttonPermissions) { buttonPermissions.formActions = formProps.formActions }
    const headerProps = { key, viType, buttonPermissions, stateProps, data_current, restrictedAccess, caption: swipedItem.caption ? swipedItem.caption : null }
    return <AllHeaders dataHeaderType={gEnums.dataHeaderTypes.itemHeader} headerProps={headerProps} />
  }

  /** Returns the `FormsList` component */
  const formListPage = (buttonPermissions, swipedItem) => {
    const viType = swipedItem.key
    buttonPermissions.allowAdd = true
    formProps.header = itemHeader(swipedItem.key, viType, buttonPermissions)
    const pageContent = <FormsList />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.createSettings })
  }

  /** Returns the `CreateSomething` component */
  const createSomething = (key, _swipedItem) => {
    formProps.header = itemHeader(key)
    const pageContent = <CreateSomething createOrigin={gEnums.createOriginTypes.viewSettings} createType={key} createVit={'clients'} />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.createEvent })
  }

  /** Returns the `CreateSomething` component */
  const createSettingsPage = () => {
    formProps.header = itemHeader('add')
    const pageContent = <CreateSomething createOrigin={gEnums.createOriginTypes.createSettings} createType={'add'} />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.createSettings })
  }

  /** Returns the `DeleteClient` component */
  const deleteClientPage = () => {
    formProps.header = itemHeader('deleteClient')
    return SegItemWrap(formProps.header, <DeleteClient />, null, { vsct: vsComponentTypes.deletePage })
  }

  /** Returns the `DeleteEvent` component */
  const deleteEventPage = () => {
    formProps.header = itemHeader('deleteEvent')
    return SegItemWrap(formProps.header, <DeleteEvent />, null, { vsct: vsComponentTypes.deletePage })
  }

  /** Returns the `ViewData` component within a `DataProvider` */
  const dataListPage = (buttonPermissions, swipedItem) => {
    const viType = swipedItem.key
    formProps.header = itemHeader(swipedItem.key, viType, buttonPermissions)
    const itemListData = _global[swipedItem.key] ? _global[swipedItem.key] : {}
    const ups = { key: swipedItem.key, isList: true }
    const pageContent = <ViewDataSidebar viewItem={{ key: viType }} isList={true} />
    return <DataProvider itemListData={itemListData} isSettingData={false} updateProps={ups} >
      {SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.dataPage })}
    </DataProvider>
  }

  const imageMappingPage = (key) => {
    formProps.header = itemHeader(key)
    const { fieldProps } = swipedItem
    const itemData = _global[swipedItem.key] ? _global[swipedItem.key] : []
    const pageContent = <ImageMappingList
      key={uniqueKey('vsc', 'fpp')}
      fieldProps={fieldProps}
      itemData={itemData}
    />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.imageMapping })
  }

  /** Returns the `SemFileViewer` component for manifest files */
  const manifestPickerPage = () => {
    formProps.header = itemHeader('selectManifestIcon')
    const { fieldProps } = swipedItem
    const pageContent = <SemFileViewer
      key={uniqueKey('vsc', 'mpp')}
      fieldProps={fieldProps}
      fileMode={gEnums.fileModes.view}
      storageType={gEnums.storageTypes.manifest}
    />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.manifestPickerPage })
  }

  /** Returns the `SemFileViewer` component for selecting files */
  const filePickerPage = () => {
    formProps.header = itemHeader('selectFile')
    const { fieldProps } = swipedItem
    const pageContent = <SemFileViewer
      key={uniqueKey('vsc', 'fpp')}
      fieldProps={fieldProps}
      fileMode={gEnums.fileModes.viewWithPick}
    />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.filePickerPage })
  }

  /** Returns the `SemFileViewer` component for uploading files */
  const filesPage = (storageType) => {
    formProps.header = itemHeader(storageType, storageType)
    const pageContent = <SemFileViewer
      allowUpload={true}
      formProps={formProps}
      storageType={storageType}
      fileMode={gEnums.fileModes.view}
    />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.filesPage })
  }

  /** Returns the `FormPage` component */
  const settingsFormPage = (subKey, vpsa, diKey, message) => <SettingsFormPage formProps={formProps} swipedItem={swipedItem} subKey={subKey} vpsa={vpsa} diKey={diKey} message={message} />

  /** Returns the `Help` component */
  const helpPage = () => {
    formProps.header = itemHeader('help')
    const { formKey, pit, psg, vit } = updateProps ? updateProps : {}
    let hsProps = {}
    if (pit) {
      hsProps = { helpDocField: 'pr', helpDocSubField: formKey }
    } else if (psg) {
      hsProps = { helpDocField: 'ps', helpDocSubField: formKey }
    } else {
      if (isGlobal && !vit) {
        hsProps = { helpDocField: 'vw', helpDocSubField: formKey }
      } else {
        hsProps = { helpDocField: 'vi', helpDocSubField: formKey }
      }
    }
    if (!hsProps.helpDocSubField) { hsProps.helpDocSubField = vsc_state.swipedItem.settingKey }
    return <Help key={uniqueKey('vsc', 'hp', helpKey)} helpType={helpTypes.documents} hsProps={hsProps} groupItems={viewProps} />
  }

  /** Returns the `PropDropper` component */
  const propOrganizeGrid = (_type) => {
    const upsSections = { ...updateProps }
    upsSections.formKey = 'propSections'
    return <PropDropper />
  }

  /** Returns the `Uploader` component */
  const uploadPage = () => {
    formProps.header = itemHeader('upload')
    const pageContent = <Uploader
      origin={'viewSettingsComponent'}
      swipedItem={swipedItem}
    />
    return SegItemWrap(formProps.header, pageContent, null, { vsct: vsComponentTypes.uploadPage })
  }

  const copyPage = () => {
    formProps.header = itemHeader('copy')
    return SegItemWrap(formProps.header, <Segment>[{'TEST'}</Segment>, null, { vsct: vsComponentTypes.uploadPage })
  }

  /** This is the first function that gets hit after the initial grid is loaded */
  const settingsMenuContainer = (menuItems, menuTypeKey, getColors, menuType, otherData) => {
    const pips = { getColors, menuType, menuItems, menuTypeKey, otherData }
    const sortProps = { setSortedReady, showSort, sort_state }
    return <ViewSettingsMenuProvider pips={pips} sortProps={sortProps} />
  }

  /** Retuns `settingsMenuContainer` after setting the `menuItems` */
  const settingsMenuContainer_wrap = (menuTypeKey) => <ViewSettingsMenuProvider menuTypeKey={menuTypeKey} />

  /** Return the content based on the `subKey` */

  /**
   * Return the content based on the `subKey`, If the subKey exists
   * @param {string} subKey 
   * @param {object} buttonPermissions 
   * @returns 
   */
  const subContent = (subKey, buttonPermissions) => {

    _showConsole && console.log('subKey', subKey)

    formProps.subKey = subKey

    const viType = subKey

    buttonPermissions.allowHelp = true

    formProps.header = itemHeader(subKey, viType, buttonPermissions)

    switch (subKey) {
      case gEnums.viewSettingTypes.appData:
        return SegItemWrap(formProps.header, settingsMenuContainer(viewItems, subKey, false, gEnums.menuTypes.viewItemData))

      case vsComponentTypes.appAccessRequests:
        return <AppAccessRequests />

      case vsComponentTypes.actions:
      case vsComponentTypes.viewActions:
        return SegItemWrap(formProps.header, <SettingsActionItems actionOrigin={'viewSettingsComponent'} />)

      case vsComponentTypes.createEvent:
      case vsComponentTypes.updateEvent:
      case vsComponentTypes.eventUpdates:
        return createSomething(subKey)

      case vsComponentTypes.createClient:
        return createSomething(subKey, swipedItem)

      case vsComponentTypes.deleteClient:
        return deleteClientPage()

      case vsComponentTypes.deleteEvent:
        return deleteEventPage()

      case vsComponentTypes.globalSettings:
      case vsComponentTypes.groupSettings:
      case vsComponentTypes.pageSettings:
      case vsComponentTypes.pageItemSettings:
      case vsComponentTypes.propSettings:
      case vsComponentTypes.propSectionSettings:
        return SegItemWrap(formProps.header, <SettingsConsole vsComponentType={subKey} />)

      default:
        return SegItemWrap(formProps.header, settingsFormPage(subKey), null, { viType, vsct: vsComponentTypes.subPage })
    }
  }

  /** Return the content based on the `key` */
  const settingsContent = (key, buttonPermissions) => {

    let { formKey } = updateProps ? updateProps : {}
    if (!formKey) { formKey = key }

    const viType = formKey

    const { vit } = updateProps ? updateProps : {}
    const viewItem_current = viewItems && viewItems[vit] ? viewItems[vit] : null

    formProps.header = itemHeader(key, viType, buttonPermissions)

    _showConsole && console.log('key', key)

    switch (key) {

      case gEnums.viewSettingTypes.dataFilters:
        return <UiDataFilters fullFilterType={fullFilterTypes.settingsView} viewItem_current={viewItem_current} />

      case gEnums.viewSettingTypes.appData:
      case gEnums.viewSettingTypes.globalPageItems:
      case gEnums.viewSettingTypes.props:
      case gEnums.viewSettingTypes.propSection:
      case gEnums.viewSettingTypes.propSections:
      case gEnums.viewSettingTypes.viewItems:
      case gEnums.viewSettingTypes.views:
        return settingsMenuContainer_wrap(key)

      case gEnums.viewSettingTypes.appActions:
      case gEnums.viewSettingTypes.googleActions:
      case gEnums.viewSettingTypes.productionActions:
      case gEnums.viewSettingTypes.settingsActions:
      case gEnums.viewSettingTypes.baseSettings:
      case gEnums.viewSettingTypes.viewActions:
        // anything with an action will be directed here
        return <SettingsActionItems isGlobal={isGlobal} actionOrigin={'viewSettingsComponent'} viType={viType} />

      case gEnums.viewSettingTypes.appAccessRequests:
        return <AppAccessRequests />

      case gEnums.viewSettingTypes.appUserSignInEmails:
        // not sure if thisis used
        return <div>{'appUserSignInEmails'}</div>

      case vsComponentTypes.notificationsManager:
        return <AppNotifications appNotificationType={appNotificationTypes.notificationsManager} forSettings={true} useDarkMode={true} />

      case gEnums.viewSettingTypes.json:
        const settingsJson = isGlobal ? { aps_global, aps_viewItems, aps_views } : { ...aps_page }
        const settingsName = isGlobal ? 'settings' : 'pageSettings'
        return <JsonViewer json={settingsJson} name={settingsName} isGlobal={isGlobal} />

      case gEnums.viewSettingTypes.propOrganize:
        return propOrganizeGrid(key)

      case gEnums.viewSettingTypes.raw:
        return rawData && <TabbedDataView viewListData={rawData} />

      case vsComponentTypes.constraintBuilder:
        return <DataConstraintBuilder viewItem_current={viewItem_current} useDarkMode={useDarkMode} />

      case vsComponentTypes.imageMappingBuilder:
        return <ImageMappingBuilder _global={_global} useDarkMode={useDarkMode} />

      case vsComponentTypes.card:
        return SegItemWrap(formProps.header, <CardSettings content={settingsFormPage(key)} useDarkMode={useDarkMode} />, null, { viType: viType, vsct: vsComponentTypes.card })

      default:
        if (isMulti) {
          return settingsMenuContainer_wrap(key)
        } else {
          // if `isMulti` is false, a form will be displayed
          buttonPermissions.allowHelp = true
          formProps.header = itemHeader(key, viType, buttonPermissions)
          return settingsFormPage(null, null, null, getMessage(key))
        }
    }
  }

  /**
   * 
   * @param {string} caption 
   * @param {function} onClickFunction 
   * @param {string} dataKey 
   * @returns 
   */
  const footer = (caption, oc, dataKey) => <UiSaveButtons
    save={{ oc: updateFromFooter, caption }}
    clickOptions={{ oc, dataKey }}
  />

  /**
   * handles the click from the save button
   * @param {object} clickOptions 
   */
  const updateFromFooter = (clickOptions) => {
    if (clickOptions && clickOptions.oc) {
      clickOptions.oc(_global[swipedItem.key], swipedItem.key, currentFormData)
    }
  }

  /**
   * Catch all wrapper
   * @param {string} key 
   * @param {object} buttonPermissions 
   * @param {boolean} forForm 
   * @returns the content wrapped in a SegItemWrap
   */
  const settingsContentWithWrap = (key, buttonPermissions, forForm, footer) => {
    const pageContent = settingsContent(key, buttonPermissions)
    let flexName = 'hc'
    const cnn = 'setz scww ' + key
    return SegItemWrap(formProps.header, pageContent, null, { flexName: flexName, vsct: 'key', forForm: forForm }, footer, null, null, null, cnn)
  }

  const getButtonPermissions = (swipedItem) => {

    const { key, subKey, isList } = swipedItem

    const xxx = isGlobal ? saSplits.global.global : saSplits.page.page

    let allowHomeSettings = appUserAccess.isSuperAdmin && xxx && xxx[key] ? true : false
    let allowHelp = xxx && xxx[key] ? true : false

    const buttonPermissions = {
      allowAdd: allowAdd(key, subKey, isList),
      allowAddSection: allowSection(key, subKey, isList),
      allowManageSection: allowSection(key, subKey, isList),
      allowArrange: arrangeAllows.includes(key) ? true : false,
      allowAuth: key === _profileCollectionName ? true : false,
      allowFavorite: isGlobal && swipedItems.length < 2 ? true : false,
      allowFiles: key === 'files' ? true : false,
      allowHelp: allowHelp,
      allowNotification: key === 'notifications' ? true : false,
      allowSort: sortAllows.includes(key) ? true : false,
      allowSortOk: key === 'sort' ? true : false,
      allowHomeSettings: allowHomeSettings,
      allowModifyPropData: key === 'data' ? true : false,
    }
    return buttonPermissions
  }

  /**
   * 
   * @returns the content of the swiped item after the initial load
   */
  const swipeContent = () => {

    const { key, subKey, vit, dKey, diKey, helpKey } = swipedItem

    const buttonPermissions = getButtonPermissions(swipedItem)

    if (questionProps) { return <ConfirmationQuestion /> }
    if (preview) { return <JsonPreview /> }

    if (vit) {
      return settingsContentWithWrap(key, buttonPermissions)
    } else if (dKey && diKey) {
      return settingsFormPage(dKey, null, diKey, 'test')
    } else if (helpKey) {
      return helpPage()
    } else {
      if (subKey) {
        // if subKey, this is a subset of the previously selected item  
        switch (key) {
          case vsComponentTypes.files:
            return filesPage(subKey)
          default:
            return subContent(subKey, buttonPermissions)
        }
      } else {
        // certain keys redirect to a specific component   

        const { formHandler } = formProps

        switch (key) {

          case vsComponentTypes.add:
            return createSettingsPage()

          case vsComponentTypes.createClient:
          case vsComponentTypes.createEvent:
          case vsComponentTypes.updateEvent:
            return createSomething(key)

          case vsComponentTypes.deleteClient:
            return deleteClientPage()

          case vsComponentTypes.deleteEvent:
            return deleteEventPage()

          case vsComponentTypes.imageMapping:
            return imageMappingPage(key)

          case vsComponentTypes.uploadImage:
            return uploadPage()

          case vsComponentTypes.dataCollections:
          case vsComponentTypes.dataLists:
            return dataListPage(buttonPermissions, swipedItem)

          case vsComponentTypes.forms:
            return formListPage(buttonPermissions, swipedItem)

          case vsComponentTypes.filePicker:
            return filePickerPage()

          case vsComponentTypes.manifestPicker:
            return manifestPickerPage()

          default:
            // this will wrap the contents 
            if (formHandler) {
              // case vsComponentTypes.transitionItems:
              //   return settingsContentWithWrap(key, buttonPermissions, true, footer('Update Home Transitions', appSettings_handlers.handleUpdateHomeSettings, 'transitionItems'))

              // case vsComponentTypes.scheduledNotifications:
              //   return settingsContentWithWrap(key, buttonPermissions, true, footer('Save Event Scheduled Notifications', appNotifications_handlers.handleUpdateScheduledNotifications))

              // case vsComponentTypes.appStatus:
              //   return settingsContentWithWrap(key, buttonPermissions, true, footer('Update Event Status', eventInfo_handlers.handleUpdateEventStatus))

              const { allowSettingsUpdate, settingsUpdateHandler, settingsUpdateHandlerFunction } = formHandler
              const handler = handlers ? handlers[settingsUpdateHandler] : {}
              const handlerFn = handler ? handler[settingsUpdateHandlerFunction] : {}
              if (allowSettingsUpdate && handlerFn) {
                const caption = settingsUpdateHandlerFunction.replace('handle', '')
                return settingsContentWithWrap(key, buttonPermissions, true, footer(_.startCase(caption), handlerFn, key))
              } else {
                return settingsContentWithWrap(key, buttonPermissions, true)
              }
            } else {
              return settingsContentWithWrap(key, buttonPermissions, true)
            }
        }
      }
    }
  }

  /**
   * 
   * @returns a component
   * @description Returns a component. Most are swipe base, others (helpPage and copyPage) are static,
   */
  const content = () => {

    if (showPermissions) {
      return <HomeGlobalSettings isGlobal={isGlobal} />
    } else if (helpKey) {
      return helpPage()
    } else if (testItem) {
      return copyPage()
    } else if (showSort) {
      return swipeContent()
    } else {
      if (swipedItem) {
        if (swipedItem.sortedItems) {
          return swipeContent()
        } else {
          if (swipedItem.key) {
            return swipeContent()
          }
        }
      } else {
        switch (settingsOriginType) {
          case gEnums.settingsOriginTypes.app:
            return <SettingsAreaMenu />
          default:
            console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaa')
            return <div>{'settingsMenuContainer'}</div>
        }
      }
    }
    return <div></div>
  }

  if ((formProps) || isStatic) {
    return <Suspense fallback={<SuspenseDimmer origin={'View Settings Component'} />}>
      {content()}
    </Suspense>
  }

  return <Segment inverted>{'No Props'}</Segment>

}

const ViewSettingsComponentWithProvider = (props) => {

  const { ncProps } = props

  const {
    appArea,
    addHelp,
    data_current, // yes
    dataRoot,
    editProps,
    isGlobal, // yes
    swipedItem, // yes
    swipedItems, // yes
    swipeToSelected, // yes
    updateProps, // yes
  } = ncProps

  const vsc_state = { data_current, dataRoot, editProps, isGlobal, swipedItem, swipedItems, updateProps, appArea }
  const vsc_handlers = { addHelp, swipeToSelected }

  return <ViewSettingsComponentProvider
    vsc_state={vsc_state}
    vsc_handlers={vsc_handlers}
  >
    <ActionProvider>
      <ViewSettingsComponent />
    </ActionProvider>
  </ViewSettingsComponentProvider>
}

export default ViewSettingsComponentWithProvider