import { allPageTypes } from '../../../enums/allPageTypes';
import { gridItemIconGroup } from './SegIconGroup';

export const gridItemsTickets = (props) => {

  const { parentContext, pageIcons, allows, gcProps } = props
  const { states } = parentContext ? parentContext : {}

  const { page_state } = states

  // pageContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appTicketing } = aps_global ? aps_global : {}
  const { ticketingCaption, allowTicketing, allowTicketingScan } = appTicketing ? appTicketing : {}

  const _ticketCaption = ticketingCaption ? ticketingCaption : 'Ticketing'
  const _ticketScanCaption = ticketingCaption ? 'Scan ' + ticketingCaption : 'Scan Ticketing'

  let _allowTm;
  let _allowTs;
  let allowTm = !pageIcons && allowTicketing
  let allowTs = !pageIcons && allowTicketingScan
  if (allowTm && allows.atm) { _allowTm = true }
  if (allowTs && allows.ats) { _allowTs = true }

  const gridItem = (viewItem, segProps, gcProps) => gridItemIconGroup(viewItem, segProps, gcProps)

  const items = []

  if (_allowTm) { items.push(gridItem({ key: allPageTypes.ticketingManager }, { caption: _ticketCaption, icon: 'ticket', cn: 'ao' }, gcProps)) }
  if (_allowTs) { items.push(gridItem({ key: allPageTypes.ticketingScan }, { caption: _ticketScanCaption, icon: 'qrcode', cn: 'ao' }, gcProps)) }

  return items

}