import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from "../../auth/appUserAccessPermissions";
import { gEnums } from '../../enums/globalEnums';
import { baseSettingsHandlers, baseSettingsInitialState, baseSettingsReducer } from "../reducers/BaseSettingsReducer";
import { AppUserContext } from "./AppUserContext";
import { PapsContext } from './PapsContext';
import { HomeSettingsContext } from './SettingsDataContext';

export const useAllSettingsFunctions = false
export const useSettingsCollection_get = true
export const useSettingsCollection_save = true

/** BaseSettingsProvider returns the default settings (from fsfn_functions) for the settings */
export const BaseSettingsContext = createContext();

/**
 *  BaseSettingsProvider returns the default settings (from fsfn_functions) for the settings
 * @param {object} props 
 * @returns - {hcceSettings, _baseSettings}
 */
const BaseSettingsProvider = (props) => {

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ? appUserContext : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { accessLevel } = appUserAccess ? appUserAccess : {}
  const providerNeeded = appUserAccess && appUserAccess.accessLevel >= gEnums.authLevels.appDataAdmin.value

  const aua = appUserAccess ? { uid: appUserAccess.uid, displayName: appUserAccess.displayName } : { uid: 0, displayName: 'Unknown' }

  // papsContext 
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ? papsContext : {}
  const { appArea, pathViews } = paps_state ? paps_state : {}

  // homeSettingsContext
  const homeSettingsContext = useContext(HomeSettingsContext);
  const { homeSettings_state } = homeSettingsContext ? homeSettingsContext : {}
  const { homeSettings } = homeSettings_state ? homeSettings_state : {}
  const { global } = homeSettings ? homeSettings : {}
  const { settingsConsole: settingsConsole_home_global } = global ? global : {}

  const init_state = { accessLevel, settingsConsole_home_global, aua, pathViews }
  const [baseSettings_state, baseSettingsDispatch] = useReducer(baseSettingsReducer, baseSettingsInitialState(init_state));
  const baseSettings_handlers = baseSettingsHandlers(baseSettingsDispatch)
  const { baseSettings } = baseSettings_state ? baseSettings_state : {}

  // only get allSettingsApp if the user has admin rights
  useEffect(() => {
    if (providerNeeded && appUserAccess) {
      baseSettings_handlers.handleGetBaseSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess]);

  useEffect(() => {
    if (providerNeeded && baseSettings && appArea) {
      baseSettings_handlers.handleGetBaseAppAreaSettings(appArea)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [baseSettings, appArea]);

  const providerContext = () => <BaseSettingsContext.Provider value={{ baseSettings_state, baseSettings_handlers }}>
    {props.children}
  </BaseSettingsContext.Provider>

  if (providerNeeded) {
    return baseSettings ? providerContext() : <div></div>
  } else {
    return providerContext()
  }

}

export default BaseSettingsProvider