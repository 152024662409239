const getMs = (type) => {
  const spl = type.split('x')
  return { w: parseInt(spl[0]), h: parseInt(spl[1]) }
}

const getMd = (device, type) => {
  return { key: device, value: device, text: device, msettings: getMs(type) }
}

export const parentStyleOptions = () => {
  const psos = []
  psos.push({ key: 'home', value: 'home', text: 'home' })
  psos.push({ key: 'client', value: 'client', text: 'client' })
  psos.push({ key: 'event', value: 'event', text: 'event' })
  return psos
}

export const mobileDeviceTypes = () => {
  const devices = []
  devices.push(getMd('desktop', '900x900'))
  devices.push(getMd('desktop-mobile', '900x860'))
  devices.push(getMd('tablet-mobile', '900x860'))
  devices.push(getMd('galaxyNote', '400x640'))
  devices.push(getMd('galaxyS', '480x800'))
  devices.push(getMd('iphone3GS', '320x480'))
  devices.push(getMd('iphone4', '320x480'))
  devices.push(getMd('iphone4S', '320x480'))
  devices.push(getMd('iphone5', '320x568'))
  devices.push(getMd('iphone5C', '320x568'))
  devices.push(getMd('iphone5S', '320x568'))

  devices.push(getMd('iphone6', '375x667'))
  devices.push(getMd('iphone6s', '375x667'))
  devices.push(getMd('iphone7', '375x667'))
  devices.push(getMd('iphone8', '375x667'))

  devices.push(getMd('iphone6Plus', '414x736'))
  devices.push(getMd('iphone6SPlus', '414x736'))
  devices.push(getMd('iphone7Plus', '414x736'))
  devices.push(getMd('iphone8Plus', '414x736'))

  devices.push(getMd('iphone11Pro', '375x812'))
  devices.push(getMd('iphoneX', '375x812'))
  devices.push(getMd('iphoneXs', '375x812'))

  devices.push(getMd('iphone11', '414x896'))
  devices.push(getMd('iphoneXr', '414x896'))

  devices.push(getMd('iphoneXProMax', '414x896'))
  devices.push(getMd('iphoneXsMax', '414x896'))

  // devices.push(getMd('samsung', '360x640'))
  devices.push(getMd('samsung', '400x760'))
  devices.push(getMd('samsungDroid', '320x569'))
  devices.push(getMd('samsungDroid3', '360x640'))
  devices.push(getMd('samsungDroid4', '360x640'))

  return devices
}