import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_client, createRefPath } from '../appData/appRefPaths';
import { fs_update_doc } from '../appData/fsData';

/**
 * Updates the `profiles` collection (viewKey) with the `name` and `path` of the selected item.
 * @param {function} dispatch 
 * @param {object} appUser 
 * @param {object} paps_state 
 * @param {string} pageDataCaption 
 */
export const updateProfilePageFavorite = (dispatch, appUser, paps_state, pageDataCaption, favItem) => {

  const { pathViews, view, viewKey, pathName } = paps_state ? paps_state : {}
  const { key: key_fav, name: name_fav, pathName: pathName_fav } = favItem ? favItem : {}
  const favCollectionKey = key_fav ? favItem[key_fav] : null

  const _view = key_fav ? key_fav : view
  const _viewKey = favCollectionKey ? favCollectionKey : viewKey
  const _pageDataCaption = name_fav ? name_fav : pageDataCaption
  const _pathName = pathName_fav ? pathName_fav : pathName

  const appUserAccess = getAppUserAccess(appUser)

  let { appUserType, email, pageFavs } = appUserAccess ? appUserAccess : {}
  const { profileData } = appUser ? appUser : {}

  if (!pageFavs) { pageFavs = {} }
  if (!pageFavs[_view]) { pageFavs[_view] = {} }

  if (pageFavs[_view][_viewKey]) {
    delete pageFavs[_view][_viewKey]
  } else {
    pageFavs[_view][_viewKey] = {
      name: _pageDataCaption,
      pathName: _pathName
    }
  }

  let _refPath;

  switch (appUserType) {
    case gEnums.appUserTypes.admin:
    case gEnums.appUserTypes.superAdmin:
      _refPath = createRefPath(['profiles', profileData.id])
      break;
    default:
      _refPath = createRefPath_client(pathViews, ['profiles', email])
  }

  const d = { pageFavs }
  console.log('updateProfilePageFavorite', _refPath, d)
  fs_update_doc(_refPath, d, dispatch)
}