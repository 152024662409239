import React, { createContext } from 'react';

export const PageAreaContext = createContext();

const PageAreaProvider = (props) => {

  const handleUpdatePageItemsShown = () => {
    console.log('handleUpdatePageItemsShown')
  }

  const pageArea_handlers = { handleUpdatePageItemsShown: handleUpdatePageItemsShown }

  return <PageAreaContext.Provider value={{ pageArea_state: { ...props }, pageArea_handlers }}>
    {props.children}
  </PageAreaContext.Provider>
};

export default PageAreaProvider