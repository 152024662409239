import React, { useContext, useEffect, useState } from 'react';
import { ActionContext } from '../../cnr/contexts/ActionContext';
import { AppUserManagerContext } from '../../cnr/contexts/AppUserManagerContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import { appFormTypes } from '../../enums/appFormTypes';
import { fsfn_deleteAuthByUid, fsfn_getAuthByEmail, fsfn_getAuthByPhoneNumber, fsfn_updateAuthByUid } from '../../functions/fbAuth';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import NoData from '../alerts/NoData';
import PendingWait from '../alerts/pendings/PendingWait';
import AppForm from '../forms/appForms/AppForm';

/** Returns a user's authhentication
 * @param itemData
 */
const UserAuth = () => {

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ? papsContext : {}
  const { pathViews } = paps_state ? paps_state : {}

  // appFormsContext
  const actionContent = useContext(ActionContext);
  const { action_handlers } = actionContent ? actionContent : {}
  const { handleStopFetch } = action_handlers ? action_handlers : {}

  // appUserManagerContext
  const appUserManagerContext = useContext(AppUserManagerContext)
  const { appUserManager_state, appUserManager_handlers } = appUserManagerContext ? appUserManagerContext : {}
  const { selectedAppUser } = appUserManager_state ? appUserManager_state : {}

  const [authUid, setAuthUid] = useState()
  const [invalidUser, setInvalidUser] = useState()
  const [confirmation, setConfirmation] = useState()
  const [updating, setUpdating] = useState()

  const [authData, setAuthData] = useState()
  const { email } = authData ? authData : {}

  const cb_userAuth = (result) => {
    const { uid, customClaims } = result ? result : {}
    if (uid) {
      const md = customClaims ? { ...result, ...customClaims } : result
      setAuthUid(uid)
      setAuthData(md)
    } else {
      setInvalidUser(true)
      // setAuthData({})
    }
    handleStopFetch && handleStopFetch()
  }

  const cb_getAuth = (result) => {
    const { uid } = result ? result : {}
    if (uid) {
      cb_userAuth(result)
    } else {
      fsfn_getAuthByPhoneNumber(selectedAppUser, cb_userAuth)
    }
  }

  useEffect(() => {
    console.log('selectedAppUser', selectedAppUser)
    // fsfn_getAuthByEmail(selectedAppUser, cb_getAuth)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedAppUser]);

  // confirmation
  useEffect(() => {
    if (confirmation) {
      const timer = setTimeout(() => {
        setConfirmation()
      }, confirmation.success === true ? 3000 : 5000);
      return () => {
        clearTimeout(timer)
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  const handleSaveResponse = (response) => {
    const { data } = response ? response : {}
    const { success, result } = data ? data : {}
    const { error } = result ? result : {}
    const { errorInfo } = error ? error : {}
    const { message } = errorInfo ? errorInfo : {}
    setUpdating(false)
    setConfirmation({
      title: success ? 'Updated' : message,
      success
    });
  }

  const handleUpdateAuthData = (d) => setAuthData(d)

  const handleSaveAuth = () => {
    const _authData = { pathViews }
    Object.keys(authData).forEach(key => {
      switch (key) {
        case 'newPassword':
        case 'confirmNewPassword':
          break;
        case 'authLevel':
        case 'disabled':
        case 'displayName':
        case 'email':
        case 'phoneNumber':
        case 'profileLevel':
          if (authData[key]) {
            _authData[key] = authData[key]
          }
          break;

        case 'clientAuthLevel':
          if (authData[key]) {
            _authData[key] = authData[key]
          } else {
            delete _authData[key]
          }
          break;
        default:
      }
    })
    if (authData.newPassword) {
      if (authData.newPassword === authData.confirmNewPassword) {
        _authData.password = authData.newPassword
      }
    }
    setUpdating(true)
    fsfn_updateAuthByUid(authUid, _authData, handleSaveResponse)
  }

  const handleDeleteAuth = () => {
    setUpdating(true)
    if (authUid) { fsfn_deleteAuthByUid(authUid, null, handleSaveResponse) }
  }

  const appUserForm = () => <AppForm
    appFormType={pathViews && pathViews.clients ? appFormTypes.appAuth : appFormTypes.auth}
    parentData={authData}
    handleUpdateParentData={handleUpdateAuthData}
    handleSave={handleSaveAuth}
    handleDelete={handleDeleteAuth}
    updating={updating}
  />

  const authContent = () => {
    if (invalidUser) {
      return <NoData fullCaption={'This user has not been authenticated.'} />
    } else {
      return authData ? appUserForm() : <PendingWait />
    }
  }

  const fpw = () => <FullPageWrapper
    topperCaption={'Authorization'}
    topperCaption2={email}
    content={authContent()}
    handleCancel={appUserManager_handlers.handleCancel}>
  </FullPageWrapper>

  return fpw()

}

export default UserAuth