import React, { useContext, useState } from 'react';
import { PageContext } from '../cnr/contexts/PageContext';
import { gEnums } from '../enums/globalEnums';
import { appIconTypes } from '../enums/appIconTypes';
import { iconColorTypes } from '../enums/settingsIconTypes';
import ImageMap from '../components/swipe/ImageMap';
import GenericIcon from '../genericControls/GenericIcon';

const UiHeader = (props) => {

  const { caption, icons, storageType, isSingle } = props ? props : {}

  // pageViewContext
  const pageContext = useContext(PageContext);
  const { page_fns } = pageContext ? pageContext : {}
  const { pushSimple } = page_fns ? page_fns : {}

  const [showMapping, setShowMapping] = useState()

  const handlePush = () => pushSimple({ key: null }, null, 'add')
  const handleMapping = () => setShowMapping(!showMapping)

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  /** Returns the buttons for the right side of the header */
  const rightButtons = () => {
    const bts = []
    if (icons) {
      icons.forEach((singleIcon, index) => {
        const { handleOnClick, icon, title, showAdd, inverted, fl } = singleIcon ? singleIcon : {}
        const defaultIcon = showAdd ? appIconTypes.delete : appIconTypes.add
        const bi = icon ? icon : defaultIcon
        const bt = title ? title : 'Add New Items'
        const bc = icon === 'delete' ? iconColorTypes.kill : iconColorTypes.menuItem

        // add button
        handleOnClick && bts.push(gi('ib-click-' + index, bi, handleOnClick ? handleOnClick : handlePush, null, { title: bt, inverted, color: bc, fl: fl }))
        // map button
        storageType === gEnums.storageTypes.floormap && isSingle && bts.push(gi('ib-floormap', 'map outline', handleMapping, null, { title: bt, inverted, color: bc }))
      })
    }
    return bts
  }

  if (showMapping) {
    return <ImageMap imageMapType={gEnums.imageMapTypes.location} handleCancel={setShowMapping} />
  } else {
    return <div>
      {caption && caption}
      {rightButtons()}
    </div>
  }
}

export default UiHeader