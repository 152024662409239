/**
 * triggers the promise and sends the reponse to the callback function
 * @param {promise} responsePromise 
 * @param {function} callback 
 */
export const resultPromiseHandler = (responsePromise, callback) => {
  responsePromise.then(rd => {
    const { success, result } = rd ? rd : {}
    if (success) {
      if (result) {
        callback(result);
      }
    } else {
      callback(result);
    }
  })
}

export const responsePromiseHandler = (responsePromise, callback, dispatch) => {
  responsePromise.then(rd => {
    callback(rd, dispatch)
  })
}