import { removeFromObjects } from '../../../common/sorting';
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { createRefPath_client, createRefPath_event } from '../../appData/appRefPaths';
import { fs_set_doc } from '../../appData/fsData';

/**
 * @description creates a single document containing collection of documents in a document with an underscore `_` as it's prefix
 */
export const updateDataToDocument = async (pathViews, collection_pk, data, dataRestrictions, dispatch) => {

  removeFromObjects(data, ['_itemKey', 'id'], 'key')

  const documentData = { [collection_pk]: data }
  const documentName = '_' + collection_pk

  const _refPath = pathViews.events ?
    createRefPath_event(pathViews, [documentName, collection_pk])
    :
    createRefPath_client(pathViews, [documentName, collection_pk])

  if (dataRestrictions.convertToDocument) {
    dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  fs_set_doc(_refPath, documentData, true, dispatch)

  return false

}