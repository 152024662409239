import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Button } from 'semantic-ui-react';
import { validateEmail } from '../../../global/validation/validation';
import NoData from '../../../global/components/alerts/NoData';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import EmailAssignment from './EmailAssignment';
import PhoneAssignment from './PhoneAssignment';
import { ticketTypes } from './SingleTicket';
import Tickets from './Tickets';

const TicketingLookup = (props) => {

  const { ticketing_handlers, ticketing_state } = props
  const { willCallTickets, groupedWillCalls, appTicketing, updating } = ticketing_state ? ticketing_state : {}
  const { ticketingCaption } = appTicketing ? appTicketing : {}
  const _ticketCaption = ticketingCaption ? ticketingCaption : 'Tickets'

  const [contact, setContact] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [email, setEmail] = useState()

  const [selectButton, setSelectButton] = useState('phone')

  useEffect(() => {
    if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
      ticketing_handlers.handleGetWillCallByKey(phoneNumber, phoneNumber, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [phoneNumber, groupedWillCalls]);

  useEffect(() => {
    if (email && validateEmail(email)) {
      ticketing_handlers.handleGetWillCallByKey(email, email, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [email, groupedWillCalls]);

  const handleClose = () => {
    setContact()
    setPhoneNumber()
    setEmail()
    ticketing_handlers.setWillCallTickets()
    ticketing_handlers.handleShowLookup()
  }
  const handleCancel = () => { }

  const handleContact = (contact) => {
    if (contact) {
      if (contact.phoneNumber) { setPhoneNumber(contact.phoneNumber) }
      if (contact.email) { setEmail(contact.email) }
      setContact(contact)
    }
  }

  const button = (type, icon) => <Button key={type} icon={icon} content={_.startCase(type)} color={selectButton === type ? 'blue' : 'grey'} onClick={(e) => { setSelectButton(type) }}></Button>

  const buttonSelect = () => <div className={'padd10'}>
    <Button.Group fluid>
      {button('phone', 'mobile alternate')}
      {button('email', 'mail outline')}
    </Button.Group>
  </div>

  const content = () => <div className={'ticket-sms'}>
    {buttonSelect()}
    {selectButton === 'phone' && <PhoneAssignment contact={contact} handleContact={handleContact} handleCancel={handleCancel} phoneNumber={phoneNumber} />}
    {selectButton === 'email' && <EmailAssignment contact={contact} handleContact={handleContact} handleCancel={handleCancel} email={email} />}
  </div>

  const lookupWrapper = () => <Wrapper
    header={content()}
    content={willCallTickets ? <Tickets tickets={willCallTickets} ticketType={ticketTypes.willCall} /> : <NoData altCaption={_ticketCaption} />}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
  />

  const lookupWizard = () => <FullPageWrapper
    content={lookupWrapper()}
    handleCancel={handleClose}
    topperCaption={_ticketCaption + ' Lookup'}
  />

  return lookupWizard()

}

export default TicketingLookup