import _ from 'lodash';
import { fsfn_updateAppSettings } from '../../functions/fbSettingsFunctions';
import { mobileDeviceTypes, parentStyleOptions } from '../../devices';
import { handleOpenDb, handleOpenCredentials, handleOpenFunctions, handleOpenSettingsDb, openCloudDataConsole, openCloudStorageConsole, openFirebaseAuth, fbServiceLink } from '../../viewSettings/helpers/settingsLinks';
import { grts, responseHandlers } from './reducerHelpers/dispatchProps';
import { getAppSettingsFromDb, getHCEAppSettings } from '../../viewSettings/actions/getSettings';
import { consoleStart } from '../../../AppSub';
import { updateHomeGlobalSettingsToDb } from '../../firestoreData/settings/updateSettings';
import { getFbConfig } from '../../../project/fbConfiguration';

const triggerUrlTypes = {
  auth: 'auth',
  cloudDataConsole: 'cloudDataConsole',
  cloudStorageConsole: 'cloudStorageConsole',
  credentials: 'credentials',
  database: 'database',
  databaseFunctions: 'databaseFunctions',
  databaseSettings: 'databaseSettings',
  databaseStorage: 'databaseStorage',
  development: 'development',
  production: 'production',
  serviceAccount: 'serviceAccount',
  serviceApiAccount: 'serviceApiAccount',
}

const rts = {
  handleAmmendAppSettings: 'handleAmmendAppSettings',
  handleAmmendHCEAppSettings: 'handleAmmendHCEAppSettings',
  handleAuthOn: 'handleAuthOn',
  handleCommitSettings: 'handleCommitSettings',
  handleCommitSettingsOn: 'handleCommitSettingsOn',
  handleCopySettings: 'handleCopySettings',
  handleDatabaseRefresh: 'handleDatabaseRefresh',
  handleDataOverride: 'handleDataOverride',
  handleDataSourceType: 'handleDataSourceType',
  handleGetHceSettings_all: 'handleGetHceSettings_all',
  handleGetHceSettings: 'handleGetHceSettings',
  handleGlobalPreviewOn: 'handleGlobalPreviewOn',
  handleGlobalSettingsOn: 'handleGlobalSettingsOn',
  handleHelpOn: 'handleHelpOn',
  handleHighlightThemesOn: 'handleHighlightThemesOn',
  handleOpenSettingsItem: 'handleOpenSettingsItem',
  handlePagePreviewOn: 'handlePagePreviewOn',
  handlePageSettingsOn: 'handlePageSettingsOn',
  handleSandboxOn: 'handleSandboxOn',
  handleSetAppChange: 'handleSetAppChange',
  handleSetDevice: 'handleSetDevice',
  handleSettingsClose: 'handleSettingsClose',
  handleSettingsLeftOn: 'handleSettingsLeftOn',
  handleSettingsOff: 'handleSettingsOff',
  handleSettingsOn: 'handleSettingsOn',
  handleSettingsRightOn: 'handleSettingsRightOn',
  handleShowAppViewer: 'handleShowAppViewer',
  // handleShowSportsViewer: 'handleShowSportsViewer',
  handleShowHomeScreenHelp: 'handleShowHomeScreenHelp',
  handleShowHomeScreenPrompt: 'handleShowHomeScreenPrompt',
  handleSortingOn: 'handleSortingOn',
  handleSplash: 'handleSplash',
  handleStartSplash: 'handleStartSplash',
  handleThemeSelect: 'handleThemeSelect',
  handleUpdateAppReady: 'handleUpdateAppReady',
  handleUpdateAppSettingsBlank: 'handleUpdateAppSettingsBlank',
  handleUpdateHomeSettings: 'handleUpdateHomeSettings',
  setAppError: 'setAppError',
  updateSettingsSuccess: 'updateSettingsSuccess',
  ...grts
}

export const appSettingsInitialState = (props) => {
  const { allowSettingsListen } = props ? props : {}
  const mds = mobileDeviceTypes()
  return {
    allAppSettings: null,
    allowSettingsListen,
    appReady: false,
    appSettingsOn: false,
    currentDevice: getDevice(mds, 'samsung'),
    dataRefreshOn: 1,
    dbType: 'dev',
    desktopOn: false,
    helpOn: false,
    highlightThemesOn: false,
    parentStyleOptions: parentStyleOptions(),
    sandboxOn: false,
    settingsPreviewOn: false,
    showSplash: true,
    showStartSplash: true,
    sp: null,
    updateProps: null,
    view: null,
    // ...grts,
  }
};

export const appSettingsReducer = (state, action) => {

  const { type, settings, settingsDocName, pathViews, dispatch } = action
  const allAppSettings = Object.assign({}, state.allAppSettings)

  const { handleAmmendHCEAppSettings, handleAmmendAppSettings, updateSuccess } = appSettingsHandlers(dispatch)

  switch (type) {

    case rts.handleCopySettings:
      return { ...state, copiedData: action.copiedData }

    case rts.handleUpdateAppSettingsBlank: {
      allAppSettings[action.docName] = {}
      return {
        ...state,
        allAppSettings: allAppSettings,
      };
    }

    case rts.handleGetHceSettings_all:
      const prps2 = { ...action.apsProps, handleAmmendHCEAppSettings, handleAmmendAppSettings }
      getHCEAppSettings(prps2)
      return { ...state }

    // get the base settings from the database
    case rts.handleGetHceSettings:
      const prps = { ...action.apsProps, handleAmmendHCEAppSettings, handleAmmendAppSettings }
      const { settingsDocKeys } = action.apsProps ? action.apsProps : {}
      getAppSettingsFromDb(prps)
      return { ...state, settingsDocKeys }

    case rts.handleAmmendHCEAppSettings:
      return {
        ...state,
        allAppSettings: action.allAppSettings,
        appUpdated: false,
        appReady: true,
        showSplash: true
      }

    // this updates the appSettings for the current settingsDocName (event/client/home)
    case rts.handleAmmendAppSettings:
      // settings from action
      const _dsks = action.settingsDocKeys ? action.settingsDocKeys : state.settingsDocKeys
      allAppSettings[settings.settingsDocName] = {
        global: settings.global,
        viewItems: settings.viewItems,
        views: settings.views,
        settingsDocKey: settings.settingsDocKey,
        settingsDocName: settings.settingsDocName,
        isGlobalStatic: settings.isGlobalStatic,
        isViewStatic: settings.isViewStatic,
        status: settings.status,
        time_stamp: settings.time_stamp,
        missingSettings: settings.missingSettings,
        error: settings.error,
      }
      const _appSettings = checkSettings(allAppSettings, _dsks)
      const { hceSettings } = _appSettings ? _appSettings : {}
      const { home } = hceSettings ? hceSettings : {}
      const { global: global_home } = home ? home : {}
      const { appLanding: appLanding_global } = global_home ? global_home : {}
      return {
        ...state,
        settingsDocKeys: _dsks,
        allAppSettings: allAppSettings,
        appSettings: _appSettings,
        appUpdated: false,
        appReady: true,
        showSplash: true,
        appLanding_global,
      };

    case rts.handleUpdateHomeSettings:
      const { dataItem, dataKey } = action
      const { home: settings_home } = allAppSettings ? allAppSettings : {}
      const { global: home_global } = settings_home ? settings_home : {}
      if (home_global && home_global[dataKey]) {
        home_global[dataKey] = dataItem
        const dataToUpdate = {
          [dataKey]: dataItem
        }
        updateHomeGlobalSettingsToDb(dataToUpdate, updateSuccess)
      }
      return { ...state, updating: true }

    case rts.handleUpdateAppReady:
      return {
        ...state,
        appReady: true,
      };
    case rts.handleSortingOn:
      return {
        ...state,
        sortingOn: !state.sortingOn,
      }

    case rts.handleHelpOn:
      return {
        ...state,
        helpOn: !state.helpOn,
      }

    case rts.handleSettingsOff:
      if (state.appSettingsOn) {
        return {
          ...state,
          appSettingsOn: false,
        }
      } else {
        return { ...state }
      }

    case rts.handleGlobalSettingsOn:
      return {
        ...state,
        globalSettingsOn: !state.globalSettingsOn,
        globalSettingsOpts: action.opts,
      }

    case rts.handlePageSettingsOn:
      return {
        ...state,
        pageSettingsOn: !state.pageSettingsOn,
        pageSettingsPage: action.page,
      }

    case rts.handleSettingsOn:
      return {
        ...state,
        appSettingsOn: !state.appSettingsOn,
        pageSettingsOn: false
      }

    case rts.handleSettingsLeftOn:
      return {
        ...state,
        settingsLeftOn: !state.settingsLeftOn,
        settingsRightOn: false,
        pullRight: false
      }

    case rts.handleSettingsRightOn:
      return {
        ...state,
        settingsRightOn: !state.settingsRightOn,
        settingsLeftOn: false,
        pullRight: false
      }

    case rts.handleSettingsClose:
      return {
        ...state,
        settingsLeftOn: false,
        settingsRightOn: false,
      }

    case rts.handleSandboxOn:
      return {
        ...state,
        sandboxOn: !state.sandboxOn,
      }

    case rts.handleCommitSettingsOn:
      return {
        ...state,
        commitSettingsOn: !state.commitSettingsOn,
      }

    case rts.handleAuthOn:
      return { ...state, authOn: !state.authOn }

    case rts.handleDataOverride:
      return {
        ...state,
        dataOverrideOn: !state.dataOverrideOn,
      }

    case rts.handleDatabaseRefresh:
      return {
        ...state,
        dataRefreshOn: state.dataRefreshOn === 1 ? 2 : 1,
      }

    case rts.handleDataSourceType:
      return {
        ...state,
        dataSourceType: action.dataSourceType
      }

    case rts.handleCommitSettings:
      fsfn_updateAppSettings(settingsDocName, settings).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? rts.updateSuccess : rts.updateError, error })
      }).catch(error => {
        console.error(error)
        dispatch({ type: rts.updateError, error })
      })
      return {
        ...state,
      }

    case rts.handleHighlightThemesOn:
      return {
        ...state,
        highlightThemesOn: !state.highlightThemesOn,
      }

    case rts.handleThemeSelect:
      return {
        ...state,
        themeSelected: state.themeSelected && state.themeSelected === action.parentSettingKey ? null : action.parentSettingKey,
      }

    case rts.handleGlobalPreviewOn:
      return {
        ...state,
        settingsPreviewOn: !state.settingsPreviewOn,
      }

    case rts.handlePagePreviewOn:
      return {
        ...state,
        settingsPreviewOn: false,
      }

    case rts.handleSetDevice:
      const x = getDevice(mobileDeviceTypes(), action.deviceValue)
      return {
        ...state,
        desktopOn: x && x.key === 'desktop' ? true : false,
        desktopMobileOn: x && x.key === 'desktop-mobile' ? true : false,
        currentDevice: x ? x : state.currentDevice,
      }

    case rts.handleSplash:
      return {
        ...state,
        showSplash: false
      }

    case rts.handleStartSplash:
      return {
        ...state,
        showStartSplash: false
      }

    case rts.handleSetAppChange:
      return {
        ...state,
        appUpdated: action.updated
      }

    case rts.handleShowHomeScreenPrompt:
      return {
        ...state,
        showHomeScreenPrompt: true,
      }

    case rts.handleShowHomeScreenHelp:
      return {
        ...state,
        showHomeScreenHelp: !state.showHomeScreenHelp,
      }

    case rts.handleShowAppViewer:
      return { ...state, showAppViewer: !state.showAppViewer, appViewerKey: action.selectedKey }

    // case rts.handleShowSportsViewer:
    //   return { ...state, showSportsViewer: !state.showSportsViewer }

    case rts.handleOpenSettingsItem:
      const { triggerUrl, productionSettings } = action
      switch (triggerUrl) {
        case triggerUrlTypes.development:
          let suffix_dev;
          if (pathViews.events) {
            suffix_dev = 'clients/' + pathViews.clients + '/events/' + pathViews.events
          } else if (pathViews.clients) {
            suffix_dev = 'clients/' + pathViews.clients
          }
          window.open('https://mobiledev.events.meetingevolution.net/#/' + suffix_dev, '_blank')
          break;
        case triggerUrlTypes.production:
          const { clientKey, eventKey } = productionSettings ? productionSettings : {}
          let suffix_prod;
          if (eventKey) {
            suffix_prod = 'clients/' + clientKey + '/events/' + eventKey
          } else if (clientKey) {
            suffix_prod = 'clients/' + clientKey
          }
          window.open('https://mobile.events.meetingevolution.net/#/' + suffix_prod, '_blank')
          break;
        case triggerUrlTypes.auth:
          openFirebaseAuth()
          break;
        case triggerUrlTypes.database:
          handleOpenDb(pathViews)
          break;
        case triggerUrlTypes.databaseSettings:
          handleOpenSettingsDb(pathViews, action.nonLandingView)
          break;
        case triggerUrlTypes.credentials:
          handleOpenCredentials()
          break;
        case triggerUrlTypes.databaseFunctions:
          handleOpenFunctions()
          break;
        case triggerUrlTypes.databaseStorage:
          handleOpenDb(pathViews, true)
          break;
        case triggerUrlTypes.cloudStorageConsole:
          openCloudStorageConsole(pathViews)
          break;
        case triggerUrlTypes.cloudDataConsole:
          openCloudDataConsole()
          break;
        case triggerUrlTypes.serviceAccount:
        case triggerUrlTypes.serviceApiAccount:
          const trueConfig = getFbConfig()
          const { projectId } = trueConfig
          const openApi = triggerUrl === triggerUrlTypes.serviceApiAccount
          let url = fbServiceLink(projectId, openApi)
          window.open(url, '_blank')
          break;
        default:
        // nothing
      }
      return { ...state, }

    case rts.updateSettingsSuccess:
      return {
        ...state,
        commitSettingsOn: !state.commitSettingsOn,
      }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return { ...state, updateSuccess: true, showConfirmationQuestion: false }

    case rts.updateError:
      return { ...state, updateSuccess: false, updateError: true }

    default:
      return { ...state };
  }
}

export const appSettingsHandlers = (dispatch) => {

  return {
    handleCopySettings: (copiedData) => { dispatch({ type: rts.handleCopySettings, dispatch, copiedData }) },
    handlePreviewOn: (isGlobal) => {
      if (isGlobal) {
        dispatch({ type: rts.handleGlobalPreviewOn })
      } else {
        dispatch({ type: rts.handlePagePreviewOn })
      }
    },
    handleCommitSettings: (settingsDocName, settings) => { dispatch({ type: rts.handleCommitSettings, dispatch, settingsDocName, settings }) },
    handleCommitSettingsOn: () => { dispatch({ type: rts.handleCommitSettingsOn, dispatch }) },
    handleAuthOn: () => { dispatch({ type: rts.handleAuthOn, dispatch }) },
    handleDataOverride: () => { dispatch({ type: rts.handleDataOverride, dispatch }) },
    handleDatabaseRefresh: () => { dispatch({ type: rts.handleDatabaseRefresh, dispatch }) },
    handleDataSourceType: (dataSourceType) => { dispatch({ type: rts.handleDataSourceType, dispatch, dataSourceType }) },
    updateSuccess: () => { dispatch({ type: rts.updateSuccess, dispatch }) },

    handleHelpOn: () => { dispatch({ type: rts.handleHelpOn, dispatch }) },
    handleHighlightThemesOn: () => { dispatch({ type: rts.handleHighlightThemesOn, dispatch }) },
    handleGlobalSettingsOn: (opts) => { dispatch({ type: rts.handleGlobalSettingsOn, dispatch, opts }) },
    handlePageSettingsOn: (page) => { dispatch({ type: rts.handlePageSettingsOn, dispatch, page }) },
    handleSandboxOn: () => { dispatch({ type: rts.handleSandboxOn, dispatch }) },
    handleSetDevice: (deviceValue) => { dispatch({ type: rts.handleSetDevice, dispatch, deviceValue }) },
    handleSettingsClose: () => { dispatch({ type: rts.handleSettingsClose, dispatch }) },
    handleSettingsLeftOn: () => { dispatch({ type: rts.handleSettingsLeftOn, dispatch }) },
    handleSettingsOff: () => { dispatch({ type: rts.handleSettingsOff, dispatch }) },
    handleSettingsOn: () => { dispatch({ type: rts.handleSettingsOn, dispatch }) },
    handleSettingsRightOn: () => { dispatch({ type: rts.handleSettingsRightOn, dispatch }) },
    handleSortingOn: () => { dispatch({ type: rts.handleSortingOn, dispatch }) },
    handleSplash: () => { dispatch({ type: rts.handleSplash, dispatch }) },
    handleStartSplash: () => { dispatch({ type: rts.handleStartSplash, dispatch }) },
    handleThemeSelect: (parentSettingKey) => { dispatch({ type: rts.handleThemeSelect, dispatch, parentSettingKey }) },

    handleSetAppChange: (updated) => { dispatch({ type: rts.handleSetAppChange, dispatch, updated }) },
    setAppError: () => { dispatch({ type: rts.setAppError, dispatch }) },
    handleUpdateAppReady: () => { dispatch({ type: rts.handleUpdateAppReady, dispatch }) },
    handleAmmendAppSettings: (settings, pathViews, settingsDocKeys) => { dispatch({ type: rts.handleAmmendAppSettings, dispatch, settings, pathViews, settingsDocKeys }) },
    handleAmmendHCEAppSettings: (allAppSettings, pathViews) => { dispatch({ type: rts.handleAmmendHCEAppSettings, dispatch, allAppSettings, pathViews }) },
    handleUpdateHomeSettings: (dataItem, dataKey) => { dispatch({ type: rts.handleUpdateHomeSettings, dispatch, dataItem, dataKey }) },
    handleUpdateAppSettingsBlank: (docName) => { dispatch({ type: rts.handleUpdateAppSettingsBlank, dispatch, docName }) },
    updateSettingsSuccess: () => { dispatch({ type: rts.updateSettingsSuccess, dispatch }) },

    handleShowAppViewer: (selectedKey) => { dispatch({ type: rts.handleShowAppViewer, dispatch, selectedKey }) },
    // handleShowSportsViewer: (selectedKey) => { dispatch({ type: rts.handleShowSportsViewer, dispatch, selectedKey }) },
    handleOpenSettingsItem: (triggerUrl, pathViews, nonLandingView, productionSettings) => { dispatch({ type: rts.handleOpenSettingsItem, dispatch, triggerUrl, pathViews, nonLandingView, productionSettings }) },
    handleShowHomeScreenPrompt: () => { dispatch({ type: rts.handleShowHomeScreenPrompt, dispatch }) },
    handleShowHomeScreenHelp: () => { dispatch({ type: rts.handleShowHomeScreenHelp, dispatch }) },
    handleGetHceSettings_all: (apsProps) => { dispatch({ type: rts.handleGetHceSettings_all, dispatch, apsProps }) },
    handleGetHceSettings: (apsProps) => { dispatch({ type: rts.handleGetHceSettings, dispatch, apsProps }) },
    ...responseHandlers(dispatch)
  }
}

const getDevice = (mobileDeviceTypes, value) => _.find(mobileDeviceTypes, { 'value': value })

export const checkSettings = (allAppSettings, settingsDocKeys) => {

  let settingsReady = true;
  let settingsError = null;
  let missingSettings = null

  const _hceSettings = {}
  if (allAppSettings && settingsDocKeys) {
    Object.keys(settingsDocKeys).forEach(sdnKey => {
      const sdn = settingsDocKeys[sdnKey]
      if (allAppSettings[sdn]) {
        _hceSettings[sdnKey] = allAppSettings[sdn]
      }
    })
  }

  Object.keys(settingsDocKeys).forEach(sdk => {
    if (!_hceSettings[sdk]) { settingsReady = false }
    if (_hceSettings[sdk] && _hceSettings[sdk].error) { settingsError = _hceSettings[sdk].error }
    if (_hceSettings[sdk] && _hceSettings[sdk].missingSettings) { missingSettings = _hceSettings[sdk].missingSettings }
  })

  if (settingsReady && consoleStart) { console.log('_hceSettings', _hceSettings) }

  return { settingsReady, hceSettings: _hceSettings, settingsError, missingSettings }

}