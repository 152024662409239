import { arrayUnion } from "firebase/firestore";
import { formatItem, formatTimeAmPm, formatTypes } from "../../common/dateFormatting";
import { gEnums } from "../../enums/globalEnums";
import { createRefPath, createRefPath_event } from '../appData/appRefPaths';
import { fs_add_doc, fs_delete_doc, fs_set_doc, fs_update_doc } from '../appData/fsData';
import { getBaseRef } from '../helpers/getBaseRef';

export const _notificationsCollection = '_notifications'
const _allowNotificationsUpdates = true

export const updateNotificationsDirect = (pathViews, notification, response_data, callback) => {

  const { clients: clientKey, events: eventKey } = pathViews ? pathViews : {}
  const { id: notificationId } = notification ? notification : {}

  const { result, success } = response_data ? response_data : {}
  const { response_multicast, failedTokens } = result ? result : {}
  const { failureCount, successCount } = response_multicast ? response_multicast : {}

  let _data;

  if (success) {
    _data = { sent: success, readBy: [], sentDate: new Date(), sendData: { failedTokens: failedTokens && failedTokens.length > 0 ? failedTokens : 'none', failureCount: failureCount ? failureCount : 0, successCount: successCount ? successCount : 0 } }
  } else {
    _data = { sent: false }
  }

  if (notification && clientKey && eventKey && notificationId) {
    const _setRef = createRefPath_event(pathViews, [_notificationsCollection, notificationId])
    fs_set_doc(_setRef, _data, true, callback)
  }
}

/**
 * Updates/creates a notification in the database
 * @param {object} pathViews 
 * @param {object} page_fns 
 * @param {object} notification 
 * @param {object} activeNote 
 * @param {function} callback 
 */
export const updateNotifications = (pathViews, page_fns, notification, activeNote, callback) => {

  const { getGviDeps } = page_fns ? page_fns : {}

  if (notification && getGviDeps) {
    const viDeps = getGviDeps(_notificationsCollection)
    const baseRef = getBaseRef(viDeps, pathViews)
    const { refPath } = baseRef
    const _editRef = activeNote ? createRefPath([_notificationsCollection, activeNote.id], refPath) : createRefPath([_notificationsCollection], refPath)
    delete notification.notificationRecipients
    delete notification.currentGroupKey
    delete notification.appUserCollection
    delete notification.appUserViewTypeProp

    // add or update
    if (_allowNotificationsUpdates) {
      if (notification.notificationScheduleType === gEnums.notificationScheduleTypes.direct) {
        const currentDate = new Date()
        notification.active = true
        notification.sent = true
        notification.sendDate = formatItem(formatTypes.shortestDate, currentDate)
        notification.sendTime = formatTimeAmPm(currentDate.getHours() + ':' + currentDate.getMinutes())
      }
      if (activeNote) {
        fs_set_doc(_editRef, notification, true, callback)
      } else {
        fs_add_doc(_editRef, notification, callback)
      }
    } else {
      alert('Item not updated. Check console for data: ' + _editRef)
    }
  }
}

export const updateMarkAsRead = (appUserAccess, pathViews, noteId, page_fns, callback) => {

  const { getGviDeps } = page_fns ? page_fns : {}

  if (noteId) {

    const { uid } = appUserAccess ? appUserAccess : {}

    if (uid) {
      const viDeps = getGviDeps(_notificationsCollection)
      const baseRef = getBaseRef(viDeps, pathViews)
      const { refPath } = baseRef
      const _editRef = createRefPath([_notificationsCollection, noteId], refPath)
      const data = { readBy: arrayUnion(uid) }
      fs_update_doc(_editRef, data, callback)
    }
  }
}

export const removeNotification = async (pathViews, page_fns, noteId, dispatch, callback) => {

  const { getGviDeps } = page_fns ? page_fns : {}

  if (noteId) {
    const viDeps = getGviDeps(_notificationsCollection)
    const baseRef = getBaseRef(viDeps, pathViews)
    const { refPath } = baseRef

    // add or update
    const _deleteRef = createRefPath([_notificationsCollection, noteId], refPath)
    fs_delete_doc(_deleteRef, callback ? callback : dispatch)
  }
}