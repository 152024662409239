import React, { useContext, useEffect, useState } from 'react';
import { Button, Menu } from 'semantic-ui-react';
import { AppSettingsContext } from '../../cnr/contexts/AppSettingsContext';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import SettingsAreaGroupMenuItem from '../../viewSettings/components/menus/SettingsAreaGroupMenuItem';
import Wrapper from '../../wrappers/Wrapper';
import GroupActionButton from '../buttons/GroupActionButton';
import MenuItems from './MenuItems';
import NavMenu from './NavMenu';
import NavSideMenu from './NavSideMenu';
import { slideMenuItems } from './slideMenuItems';

export const appClicks = {
  add: 'add',
  appSettings: 'appSettings',
  cancelSignOut: 'cancelSignOut',
  confirmSignOut: 'confirmSignOut',
  desktop: 'desktop',
  signOut: 'signOut',
  viewSettings: 'viewSettings',
}

/** The contents of the sidebar contained in Sidebar
 * @description: This is NOT the settings sidebar.
 */
const AppSidebarMenu = (props) => {

  const { navOnly, sac } = props

  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, page_state } = states
  const { auth_handlers } = handlers
  const { appUser_fns, page_fns } = fns
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles, aps_viewItems: viewItems } = pageSettings ? pageSettings : {}
  const { appAccess, sideMenu } = aps_global ? aps_global : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const { appUserAccess } = appUser ? appUser : {}
  const { loggedIn } = appUserAccess ? appUserAccess : {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appVersion } = appSettingsContext

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers, framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  const signOutSnC = aps_styles ? aps_styles[gEnums.projectStyles.signOut] : {}

  // pageContext 
  const { pushSimple } = page_fns ? page_fns : {}
  const { sideMenuItems } = sideMenu ? sideMenu : {}
  const { appAccessType } = appAccess ? appAccess : {}

  let isPublicSite = false

  switch (appAccessType) {
    case gEnums.siteAuthorizationTypes.public:
    case gEnums.siteAuthorizationTypes.passcode:
      isPublicSite = true
      break;
    default:
    // nothing
  }

  let viewPermissionType = appUser_fns.validate_settingsMenuAuth()
  const allowSidebarView = appUser_fns.validateAccess_sidebar(isPublicSite)

  const handleButtonConfirm = () => {
    auth_handlers.handleSignOut()
    framework_handlers.handleAppSidebar()
  }

  const pushSimpleItem = (clickedItem, itemKey, modifyMode) => {
    !desktopMode && framework_handlers.handleAppSidebar && framework_handlers.handleAppSidebar()
    pushSimple(clickedItem, itemKey, modifyMode)
  }

  // local state 
  const [showSignOut, setShowSignOut] = useState()
  const [menuItems_side, setSlideMenuItems] = useState()

  const { adminSlideItems, appSlideItems, gotoItems } = menuItems_side ? menuItems_side : {}

  useEffect(() => {
    let _sideViewItems = {}
    if (sideMenuItems) {
      sideMenuItems.forEach(svi => {
        if (viewItems[svi]) {
          _sideViewItems[svi] = viewItems[svi]
          _sideViewItems[svi].viewPermissionType = gEnums.viewPermissionTypes.allow
        }
      })
    }
    appUser_fns.validateAccess_items(_sideViewItems)
    const _slideMenuItems = slideMenuItems(appUser_fns, appUser, paps_state, _sideViewItems, showSignOut, handleSignOut)
    setSlideMenuItems(_slideMenuItems)
    // eslint-disable-next-line
  }, [sideMenuItems]);

  useEffect(() => {
    setShowSignOut(loggedIn)
    // eslint-disable-next-line
  }, [loggedIn]);

  const handleSignOut = () => console.log('TEST')
  const handleSignIn = () => framework_handlers.handleShowSignIn && framework_handlers.handleShowSignIn()

  /** handles the click of a menuItem */
  const handleClick = (item, noClick) => {
    if (noClick) {
      framework_handlers.handleAppSidebar && framework_handlers.handleAppSidebar()
    } else {
      switch (item.key) {
        case appClicks.signOut:
          // setShowSignOut(true)
          break;
        case appClicks.cancelSignOut:
          // setShowSignOut(false)
          break;
        case appClicks.confirmSignOut:
          auth_handlers.handleSignOut()
          framework_handlers.handleAppSidebar && framework_handlers.handleAppSidebar()
          setShowSignOut(false)
          break;
        case appClicks.add:
          framework_handlers.handleAppSidebar && framework_handlers.handleAppSidebar()
          pushSimpleItem(null, null, 'add')
          break;

        case appClicks.viewSettings:
          framework_handlers.handleSettingSoloSidebar(item.permissionProps.permissionType)
          break;

        case appClicks.appSettings:
          if (item.permissionProps) {
            framework_handlers.handleSettingSoloSidebar(item.permissionProps.permissionType)
          }
          break;
        default:
          if (item.permissionProps) {
            framework_handlers.handleSettingSoloSidebar(item.permissionProps.permissionType)
          } else {
            pushSimpleItem(item)
          }
        // nothing
      }
    }
  }

  /** returns a set of menu items (admin) */
  const sideItems = (sis, horz, alignRight) => {
    const nmiProps = { items: sis, horz, alignRight, handleClick }
    return <MenuItems {...nmiProps} />
  }

  /** the HEADER portion of the menu. Contains signUp and signOut */
  const signOutButton = () => <GroupActionButton
    key={uniqueKey('gab-so')}
    actionName={'signOut'}
    handleConfirm={handleButtonConfirm}
    saveIcon={'check'}
    iconColor={iconColorTypes.signOut}
    styleAndClass={signOutSnC}
  />

  const signInButton = () => <Button
    color={iconColorTypes.signOut}
    fluid
    onClick={() => { handleSignIn() }}
  >
    Sign In
  </Button>


  /** the NAVIGATION portion of the menu. */
  const navMenu = () => {
    const nmProps = { items: appSlideItems, handleClick, navOnly }
    return <NavMenu {...nmProps} sob={signOutButton()} sib={signInButton()} />
  }

  /** the BOTTOM portion of the menu */
  const bottomMenu = () => {
    const nvmProps = { gotoItems, allowSidebarView, pushSimpleItem, handleClick, appVersion }
    return <NavSideMenu {...nvmProps} />
  }

  /** The contents of the app sidebar */
  const contentMenu = () => <React.Fragment>
    {/* {<div className='sidebar-title'>TEST</div>} */}
    {navMenu()}
    {bottomMenu()}
  </React.Fragment>

  /** the ADMIN portion of the menu */
  const adminMenu = () => {
    const smgProps = {
      caption: 'Admin',
      groupIcon: 'settings',
      groupKey: 'admin',
      iconColor: 'red',
      inverse: true,
      menuItemElements: sideItems(adminSlideItems),
    }
    if (allowSidebarView === gEnums.viewPermissionTypes.allow) {
      const x = <SettingsAreaGroupMenuItem key={uniqueKey('smgh', 'adm')} {...smgProps} />
      return <Menu className='adm inh msmi three' borderless fluid inverted={true} vertical>{x}</Menu>
    }
  }

  const menuFlexWrap = (menuClassName, content) => <div className={'ct-vs app inh mfw ' + menuClassName}>
    {content}
  </div>

  const flexWrap = () => {
    return <Wrapper
      header={(showSignOut ? signOutButton() : signInButton())}
      content={contentMenu()}
      footer={viewPermissionType === gEnums.viewPermissionTypes.allow ? menuFlexWrap('adm', adminMenu()) : null}
      css={{ container: 'ct-vs app inh fw' }}
      style={sac ? sac.style : null}
    />
  }

  if (navOnly) {
    return navMenu()
  } else {
    return flexWrap()
  }

}

export default AppSidebarMenu