import { getBaseRef } from './getBaseRef';
import { getWhereConditionals } from './getRefConditionals';

// https://firebase.google.com/docs/firestore/query-data/query-cursors

export const getRefPropz = (propz, menu, data_handlers) => {
  const {
    alphabetValue,
    alphaItem,
    currentPageData,
    isAppData,
    logging,
    page_fns,
    searchingValue,
    singleDirect,
    storageType,
    stateProps,
    uivi,
    view,
    viewItem_global,
    viewItem,
    viewKey,
    dataConstraints_pr
  } = propz


  const grpp = {
    alphabetValue,
    alphaItem,
    currentPageData,
    isAppData,
    logging,
    page_fns,
    searchingValue,
    singleDirect,
    stateProps,
    storageType,
    uivi,
    view: uivi,
    viewItem_global,
    viewItem,
    viewKey,
    dataConstraints_pr,
  }

  const { nonLandingView, timeZone } = stateProps ? stateProps : {}

  const refProps = getRefProps(grpp)

  const dProps = { refProps, data_handlers, uivi, viewKey, isAppData, view, timeZone }
  const dataCaptionProps = { menu, viewItem, viewItem_global, nonLandingView }

  return { dProps, dataCaptionProps }
}


/**
 * 
 * @param {object} grpp 
 * @returns Creates the ref for firestore
 */
export const getRefProps = (grpp) => {

  const {
    currentPageData,
    logging,
    page_fns,
    searchingValue,
    singleDirect,
    stateProps,
    uivi,
    viewItem_global,
    viewItem,
    viewKey,
    dataConstraints_pr,
  } = grpp

  let { alphabetValue } = grpp

  const {
    appUserAccess,
    aps_viewItems,
    dataLimit,
    dataOverrideOn,
    googleSheetsId,
    hideInactiveItems_do,
    limitDevelopmentData,
    nonLandingView,
    pathViews,
    staticViews
  } = stateProps ? stateProps : {}

  // page_fns 
  const { getGviDeps } = page_fns ? page_fns : {}

  const { dataSource, dataConstraints, display, key: key_viewItem, grouping } = viewItem ? viewItem : {}
  let { sortProp } = display ? display : {}
  let dataFilters_alt;

  if (!sortProp) { sortProp = 'name' }

  const { altDataCollectionName, useSubDataCollection, subDataCollectionName } = dataSource ? dataSource : {}

  if (altDataCollectionName && aps_viewItems && aps_viewItems[altDataCollectionName]) {
    const viewItem_dataFilter = aps_viewItems[altDataCollectionName]
    const { dataFilters: dfa } = viewItem_dataFilter
    dataFilters_alt = dfa
  }

  let { uiData: globalUiData, data: globalData, dataConnection, dataFilters } = viewItem_global ? viewItem_global : {}
  const { useParentKeysOnly } = dataConnection ? dataConnection : {}
  if (!globalUiData && globalData) { globalUiData = globalData }

  let trueUivi = uivi

  // change the uivi in needed
  if (altDataCollectionName) {
    trueUivi = altDataCollectionName
  }

  const viDeps = getGviDeps(trueUivi)
  const { dependencies: deps } = viDeps

  switch (trueUivi) {
    case 'home':
      if (deps) { deps.pop() }
      break;
    default:
    // nothing
  }

  let singleDataItem = false

  // Get the base ref based on dependencies 
  const baseRef = getBaseRef(viDeps, pathViews, null, null, logging)
  let { showDataLinkType } = logging ? logging : {}

  showDataLinkType = true

  let { refPath, refPaths } = baseRef

  // this sets the ref 
  const firestoreRefs = []

  const whereOpts = { priors: [], posts: [], dataKeys: [] }

  let orders;
  let _dataLimit;

  // LOOK
  if (limitDevelopmentData) {
    _dataLimit = dataLimit ? dataLimit : 10
  }

  // if useSubDataCollection, add the subDataCollectionName to the ref
  if (useSubDataCollection && subDataCollectionName) {
    refPaths.push(subDataCollectionName)
  } else {
    refPaths.push(trueUivi)
  }

  // see if this is a document
  if (singleDirect) {
    singleDataItem = true
    refPath += '/' + trueUivi + '/' + viewKey
  } else {
    getWhereConditionals({
      alphabetValue, // data_state
      appUserAccess, // appUser_state
      currentPageData, //pageData_state
      dataConnection, //viewItem_global
      dataFilters_alt, // aps_viewItems
      dataFilters, // viewItem_global
      dataOverrideOn, // appSettings_state
      dataConstraints: dataConstraints_pr ? dataConstraints_pr : dataConstraints,
      dataSource, // viewItem
      grouping, // viewItem
      hideInactiveItems_do,
      logging, // aps_global
      nonLandingView, // paps_state
      orders, // lodal
      pathViews, // paps_state
      refPaths, // baseRef
      searchingValue,
      showDataLinkType, // logging
      sortProp, // viewItem.display
      staticViews, // eventInfo_state
      trueUivi, // props from
      viewItem, // viewItem_page
      viewKey, // paps_state
      whereOpts, // local
    })

    let getSingleItem = false

    if (viewKey && (key_viewItem === nonLandingView) && !useParentKeysOnly) {
      getSingleItem = true
    }

    if (getSingleItem) {
      singleDataItem = true
      refPath += '/' + trueUivi + '/' + viewKey
      if (viewKey) { refPaths.push(viewKey) }
    }
  }

  let refKey = refPath ? refPath.replace(/\//g, '_') : ''
  if (trueUivi) { refKey += '/' + trueUivi }
  if (viewKey) { refKey += '/' + viewKey }

  return {
    dataLimit: _dataLimit,
    firestoreRefs,
    googleSheetsId,
    orders,
    refPath,
    refKey: refKey ? refKey.replace(/\//g, '_') : null,
    refPaths,
    singleDataItem,
    whereOpts,
  }
}

export const getStartEnd = (ai) => {
  let afiStart = 'A'
  let afiEnd = 'B'
  afiStart = String.fromCharCode(parseInt(ai))
  afiEnd = String.fromCharCode(parseInt(ai) + 1)
  return {
    start: afiStart,
    end: afiEnd
  }
}