import _ from 'lodash';
import { signInNewAuth } from '../auth/signInActions/signInAuthGlobalUser';
import { dispatchProps } from "../cnr/reducers/reducerHelpers/dispatchProps";
import { signInResponseTypes, sirts } from '../cnr/reducers/SignInReducer';
import { callFunction } from "./fbAll";

const authGenericTypes = {
  authGeneric: 'authGeneric',
}

const authFunctionTypes = {
  addAuthToken: 'addAuthToken',
  addProfileTopic: 'addProfileTopic',
  createAuth: 'createAuth',
  deleteAuth: 'deleteAuth',
  deleteAuthByEmail: 'deleteAuthByEmail',
  disableAuth: 'disableAuth',
  getAuthByEmail: 'getAuthByEmail',
  getAuthByEmailOrPhoneNumber: 'getAuthByEmailOrPhoneNumber',
  getAuthByPhoneNumber: 'getAuthByPhoneNumber',
  getAuthsByEmail: 'getAuthsByEmail',
  getClientProfiles: 'getClientProfiles',
  getProfile: 'getProfile',
  removeProfileTopic: 'removeProfileTopic',
  updateAuth: 'updateAuth',
  updateAuthByUid: 'updateAuthByUid',
  updateAuthPassword: 'updateAuthPassword',
  // subscribeToTopic: 'subscribeToTopic',
  // getEventUsersByEmail: 'getEventUsersByEmail',
  // unsubscribeFromTopic: 'unsubscribeFromTopic',
}


/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(authGenericTypes.authGeneric, callData)
  return data
}

/**
 * 
 * @param {*} chunks 
 * @param {function} callback 
 */
export const fsfn_getAuthsByChunkEmail = async (chunks, pathViews, updateDatabase, callback, staticAppUsers) => {
  try {
    const response = await chunkPromise(chunks, pathViews, updateDatabase)
    let _found = []
    if (response) {
      response.forEach(item => {
        const { result, success } = item ? item : {}
        if (success) {
          const { found } = result ? result : {}
          if (found) {
            _found = [..._found, ...found]
          }
        }
      })
    }
    if (callback) {
      callback({ found: _found, staticAppUsers })
    } else {
      return { found: _found }
    }
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_getClientProfiles = async (pathViews, emails, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.getClientProfiles, pathViews, emails, showLogs: true }
    const response_data = await callFsFunction(callData) // OK 
    const { result } = response_data ? response_data : {}
    if (callback) {
      callback(result)
    } else {
      return result
    }
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_getAuthsByEmail = async (appUsers, callback, index) => {
  try {
    const _appUsers = []
    Object.keys(appUsers).forEach(key => {
      const appUser = appUsers[key]
      const { email, phoneNumber } = appUser
      if (email || phoneNumber) {
        const _appUser = { email, phoneNumber }
        _appUsers.push(_appUser)
      }
      // if (appUsers[key].email) {
      //   _appUsers.push({ email: appUsers[key].email })
      // }
    })
    const callData = { authFunctionType: authFunctionTypes.getAuthsByEmail, appUsers: _appUsers }
    const { result } = await callFsFunction(callData) // OK
    if (callback) {
      callback(result)
    } else {
      return result
    }
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_getProfileAuth = async (action, callback) => {
  const { profileData, appUserData } = action
  const { email } = profileData
  try {
    const callData = { authFunctionType: authFunctionTypes.getAuthByEmail, email }
    const response_data = await callFsFunction(callData) // OK  
    const { result: authData } = response_data ? response_data : {}
    callback(authData, profileData, appUserData)
  } catch (error) {
    console.error(error)
    callback({})
  }
}

/**
 * 
 * @param {object} itemData 
 * @param {function} callback
 * @description - adds a token (using setCustomUserClaims) to auth where email. No return needed 
 */
export const fsfn_addAuthCustomToken = async (itemData, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.addAuthToken, ...itemData }
    const response_data = await callFsFunction(callData) // OK
    if (callback) { callback(response_data) }
  } catch (error) {
    console.error(error)
  }
}


/** Deletes an auth user by uid */
export const fsfn_deleteAuthByUid = async (uid, dispatch, callback) => {
  try {
    const allowDelete = false
    if (allowDelete) {
      const callData = { authFunctionType: authFunctionTypes.deleteAuth, uid }
      const { success } = await callFsFunction(callData) // OK
      if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
      if (callback) { callback(success) }
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} itemData 
 * @param {function} dispatch 
 * @param {function} callback 
 * @deprecated
 */
export const fsfn_deleteAuthByEmail = async (itemData, dispatch, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.deleteAuthByEmail }
    const { success } = await callFsFunction(callData) // OK
    if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
    if (callback) { callback(success) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} uid 
 * @param {object} itemData 
 * @param {function} callback 
 * @deprecated
 */
export const fsfn_disableAuth = async (uid, itemData, callback) => {
  try {
    const { disabled } = itemData
    const data = { authFunctionType: authFunctionTypes.disableAuth, uid, disable: disabled }
    const { success } = await callFsFunction(data) // OK
    if (callback) { callback(success) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} authUid 
 * @param {object} authData 
 * @param {function} callback 
 * @callback response_data
 */
export const fsfn_updateAuthByUid = async (authUid, authData, callback) => {
  try {
    const itemData = { authFunctionType: authFunctionTypes.updateAuthByUid, uid: authUid, authData }
    const response_data = await callFsFunction(itemData) // OK
    if (callback) { callback(response_data) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} authUid 
 * @param {*} authData 
 * @param {*} callback 
 * @deprecated
 */
export const fsfn_removeFcm = async (authUid, authData, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.updateAuthByUid, uid: authUid, authData }
    const response_data = await callFsFunction(callData) // OK
    if (callback) { callback(response_data) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} itemData 
 * @param {*} callback  
 * @param {*} returnResult 
 * @returns - the `result` object
 */
export const fsfn_getAuthByEmail = async (itemData, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.getAuthByEmail, ...itemData }
    console.log('callData', callData)
    const { result } = await callFsFunction(callData) // OK 
    if (callback) { callback(result) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} itemData 
 * @param {*} callback  
 * @param {*} returnResult 
 * @returns - the `result` object
 */
export const fsfn_getAuthByPhoneNumber = async (itemData, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.getAuthByPhoneNumber, ...itemData }
    console.log('callData', callData)
    const { result } = await callFsFunction(callData) // OK 
    if (callback) { callback(result) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} itemData 
 * @param {*} callback  
 * @param {*} returnResult 
 * @returns - the `result` object
 */
export const fsfn_getAuthByEmailOrPhoneNumber = async (itemData, callback) => {
  try {
    itemData.showLogs = true
    const callData = { authFunctionType: authFunctionTypes.getAuthByEmailOrPhoneNumber, ...itemData }
    console.log('callData', callData)
    const { result } = await callFsFunction(callData) // OK 
    if (callback) { callback(result) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} itemData (uid, topic)
 * @param {function} dispatch 
 * @param {enum} dispatchType 
 * @returns Subscribes a user (via email or uid) to a topic using admin.messaging().subscribeToTopic
 */
export const fsfn_addProfileTopic = async (itemData, dispatch, dispatchType) => {
  // data: { email: any, topic: any; uid: any }
  try {
    const callData = { authFunctionType: authFunctionTypes.addProfileTopic, ...itemData }
    const response_data = await callFsFunction(callData, true) // OK
    const { success } = response_data ? response_data : {}
    if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
    return response_data

  } catch (error) {
    console.error(error)
  }
}

export const fsfn_removeProfileTopic = async (itemData, dispatch, dispatchType) => {
  // data: { email: any, topic: any; }
  try {

  } catch (error) {
    console.error(error)
  }
  const callData = { authFunctionType: authFunctionTypes.removeProfileTopic, ...itemData }
  const response_data = await callFsFunction(callData) // OK
  const { success } = response_data ? response_data : {}
  if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
}

/** Updates the auth based on the email address  */
export const fsfn_updateAuth = async (itemData, dispatch, dispatchType) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.updateAuth, ...itemData }
    const response_data = await callFsFunction(callData) // OK
    const { success } = response_data ? response_data : {}
    if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} itemData 
 * @returns  Updates the password of in auth based on the email address 
 * @deprecated
 */
export const fsfn_updateAuthPassword = async (itemData) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.updateAuthPassword, ...itemData }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/** Gets the auth by email and then updates the auth password */
export const fsfn_updateAuthPasswordAndSignin = async (data, signInData, pathViews, hasAuth) => {
  try {
    let result;
    if (hasAuth) {
      // update the user's password
      data.authFunctionType = authFunctionTypes.updateAuthPassword
      const response_data_password = await callFsFunction(data) // OK
      const { success: success_password } = response_data_password ? response_data_password : {}
      if (success_password) {
        // password updated
        result = await signInNewAuth(data, signInData, pathViews)
      } else {
        result = { type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.signInError, success: false };
      }
    } else {
      const caData = data
      caData.authFunctionType = authFunctionTypes.createAuth
      caData.displayName = signInData.displayName
      caData.pathViews = pathViews
      // create a new auth
      const response_data_newAuth = await callFsFunction(caData) // OK
      const { success: success_newAuth } = response_data_newAuth ? response_data_newAuth : {}
      if (success_newAuth) {
        result = await signInNewAuth(data, signInData, pathViews)
      } else {
        result = { type: sirts.handleSignInResponse, signInResponseType: signInResponseTypes.signInError, success: false };
      }
    }
    return result
  } catch (error) {
    console.error(error)
  }
}

const chunkPromise = async (chunks, pathViews, updateDatabase) => {
  const promises = []
  chunks.forEach(chunk => {
    const appUsers = []
    Object.keys(chunk).forEach(key => {
      const chunkItem = chunk[key]
      let { email, phoneNumber } = chunkItem ? chunkItem : {}
      email = email ? email.replace(/ /g, '') : null
      phoneNumber = phoneNumber ? phoneNumber.replace(/ /g, '') : null
      if ((email && !_.isEmpty(email)) || (phoneNumber && !_.isEmpty(phoneNumber))) {
        const item = {}
        if (email) { item.email = email.toLowerCase() }
        if (phoneNumber) { item.phoneNumber = phoneNumber }
        appUsers.push(item)
      }
    })
    const callData = { authFunctionType: authFunctionTypes.getAuthsByEmail, appUsers, pathViews, updateDatabase: false }
    promises.push(callFsFunction(callData))
  })
  return Promise.all(promises)
}


// export const fsfn_subscribeToTopic = async (itemData, dispatch, dispatchType) => {
//   try {
//     itemData.authFunctionType = authFunctionTypes.subscribeToTopic
//     const response_data = (await httpsCallable(functions, authFunctionTypes.subscribeToTopic)(itemData))
//     if (dispatch) { dispatch({ type: dispatchType, response_data, ast: 'Subscribed to Topic', aft: 'Not subscribed to Topic' }) }
//   } catch (error) {
//     console.error(error)
//   }
// }

// export const fsfn_unsubscribeFromTopic = async (itemData, dispatch, dispatchType) => {
//   try {
//     itemData.authFunctionType = authFunctionTypes.unsubscribeFromTopic
//     const response_data = (await httpsCallable(functions, authFunctionTypes.unsubscribeFromTopic)(itemData))
//     if (dispatch) { dispatch({ type: dispatchType, response_data, ast: 'Unsubscribed from Topic', aft: 'Not unsubscribed to Topic' }) }
//   } catch (error) {
//     console.error(error)
//   }
// }


/** adds a token to auth where email */
// export const fsfn_addAdminToken = (tokenType, tokenId, itemData, dispatch, dispatchType) => {
//    data : { authFunctionType:authFunctionTypes.addAuthToken, email: null, token: null }
//   try {
//     var fn = httpsCallable(functions, authGenericTypes.authGeneric);
//     if (fn) {
//       const data = {
//         email: itemData.email,
//         token: {
//           [tokenType]: tokenType === 'clients' ? tokenId : [tokenId],
//         }
//       }
//       if (itemData.adminLevel) { data.token.adminLevel = itemData.adminLevel }
//       fn(data).then(function (result) {
//         dispatch({ type: dispatchType })
//       })
//     }
//   } catch (error) {
//     console.error(error)
//   }
// }

/**
 * itemData {email: any; firstName: any; lastName: any; password: any}
 * @param {*} itemData (email)
 * @param {*} dispatch
 * @param {*} dispatchType
 */
// export const fsfn_createAuth = async (itemData, dispatch, dispatchType, callback) => {
//   //  { email: any; firstName: any; lastName: any; password: any; }
//   const response_data = (await httpsCallable(functions, authFunctionTypes.createAuth)(itemData))
//   const result = { type: dispatchType, response_data, ast: 'Auth Created', aft: 'Auth Not Created' }
//   if (dispatch) { dispatch(result) }
//   if (callback) { callback(result) }
//   return result
// }