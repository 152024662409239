import React from 'react'
import { CalMonth, _calMonthProps } from './CalMonth'
import { sidebarMenuTypes } from '../../cnr/reducers/SidebarReducer';
import { formatItem } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import { getCalStartDates } from '../../common/filtering';

export const CalMonths = (props) => {

  const { sidebarItem, sbProps, type } = props

  const { items, itemz, keyAs, showToday, showMissing } = sidebarItem ? sidebarItem : {}
  const { type: sidebarMenuType, sidebar_handlers } = sbProps ? sbProps : {}
  const { handleCloseSidebar, handleGenericItemClick } = sidebar_handlers ? sidebar_handlers : {}

  const clickType = keyAs ? keyAs : sidebarMenuType

  const { monthDates } = getCalStartDates(items)

  const today = new Date()

  const handleClose = () => {
    handleCloseSidebar && handleCloseSidebar()
    props.handleClose && props.handleClose()
  }

  const handleClick = (clickType, date) => {
    handleGenericItemClick && handleGenericItemClick(null, null, clickType, date)
    props.handleClick && props.handleClick(date)
  }

  const getMonthName = (md) => {
    try {
      const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(md)
      const year = md.getFullYear()
      return { monthName, year }
    } catch (error) {
      return 'January'
    }
  }

  const calMonths = () => {
    const cm = []
    if (monthDates) {
      monthDates.forEach((md, index) => {
        const { monthName, year } = getMonthName(md)
        cm.push(<div key={uniqueKey('cm', 'cm', index)} className={'cal-month-container'}>
          <div className={'cal-month'}>{monthName}{' - '}{year}</div>
          <CalMonth {...props} currentDate={new Date(md)} includeMonthHeader={true} sbProps={sbProps} dateKeys={items} dateKeyz={itemz} clickType={clickType} type={type} />
        </div>)
      })
    }
    return cm
  }

  const innerContent = () => {
    if (showMissing || showToday) {
      return <React.Fragment>
        <div className={'cal-month-cb'} onClick={() => { handleClose() }}>Close</div>
        {showToday && <div className={'cal-month-ic1'} onClick={() => { handleClick(clickType ? clickType : sidebarMenuTypes.date, formatItem(_calMonthProps.formatType, today)) }}>Today</div>}
        {showMissing && <div className={'cal-month-ic2'} onClick={() => { handleClick(clickType ? clickType : sidebarMenuTypes.date, 'Missing') }}>Missing</div>}
      </React.Fragment>
    } else {
      return <React.Fragment>
        <div className={'cal-month-cb'} onClick={() => { handleClose() }}>Close</div>
      </React.Fragment>
    }
  }

  let closeCn = 'cal-close'
  if (showMissing) { closeCn += ' missing' }
  if (showToday) { closeCn += ' today' }

  const content = () => <div className={'cal-months-container'}>
    <div className={closeCn}>
      {innerContent()}
    </div>
    <div className={'cal-months'}>
      {calMonths()}
    </div>
  </div>

  return content()
}