import React, { createContext, useEffect, useReducer } from 'react';
import { helpHandlers, helpInitialState, helpReducer } from '../reducers/HelpReducer';
// import { FirestoreContext } from './FirestoreContext';

/** 
@provides handleUpdateHelp 
*/
/**
 * help_state (helpDocs)- helpActions, helpDocuments, helpDocumentz, helpSettings, helpTopics
 * help_handlers (handleUpdateHelp, handleUpdateAllHelp)
 */
export const HelpContext = createContext();

const HelpProvider = (props) => {

  const init_state = {}
  const [help_state, help_dispatch] = useReducer(helpReducer, init_state, helpInitialState)
  const help_handlers = helpHandlers(help_dispatch, help_state)

  useEffect(() => {
    help_handlers.handleHelpInit()
    // eslint-disable-next-line
  }, []) // run once

  const providerContext = () => {
    return <HelpContext.Provider value={{ help_state, help_handlers }}>
      {props.children}
    </HelpContext.Provider>
  }

  return (
    <React.Fragment>
      {providerContext()}
    </React.Fragment>
  )
}

export default HelpProvider