import _ from 'lodash'
import { _find } from '../../common/filtering';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';

export const rts = {
  handleAppAreaChange: 'handleAppAreaChange',
  handleCloseGroupItem: 'handleCloseGroupItem',
  handleOpenGroupItem: 'handleOpenGroupItem',
}

const _showConsole = false

export const settingsAreaReducer = (state, action) => {

  const { type, selectedGroupItem } = action
  const { isGlobal } = state

  switch (type) {

    // IMPORTANT: Settings - this needs to be updated when the pageArea changed
    case rts.handleAppAreaChange:

      const { baseSettings_state, appAreaStates } = action
      const { projectModules, projectOptions, settingsOptions, productionSettings } = appAreaStates ? appAreaStates : {}

      // aagpSetting is the the current aagpSetting for the appArea
      const { baseSettings, baseSettings_combined, saSplits } = baseSettings_state ? baseSettings_state : {}

      const split = {
        authGroups: isGlobal ? saSplits.authGroups.global : saSplits.authGroups.page,
        group: isGlobal ? saSplits.group.global : saSplits.group.page,
        pageItem: isGlobal ? saSplits.pageItem.global : saSplits.pageItem.page,
        prop: isGlobal ? saSplits.prop.global : saSplits.prop.page,
        propSection: isGlobal ? saSplits.propSection.global : saSplits.propSection.page,
      }

      const baseSettings_side = isGlobal ? baseSettings.global : baseSettings.page

      const settingsMenuGroups = {}

      if (split.group) {
        Object.keys(split.group).forEach(gk => {
          const splitGroup = split.group[gk]
          const { settingsAuthLevel, groupItems } = splitGroup ? splitGroup : {}
          const authLevel = _find(gEnums.authLevels, 'value', settingsAuthLevel)
          const iconColor = authLevel ? authLevel.color : iconColorTypes.menuItemFgeoLi
          settingsMenuGroups[gk] = {
            key: gk,
            ...splitGroup,
            iconColor,
            groupItems,
          }
        })
      }

      return {
        ...state,
        baseSettings,
        baseSettings_combined,
        baseSettings_side,
        saSplits,
        appArea: action.appArea,
        menuAuthGroups: split.authGroups,
        productionSettings,
        projectModules,
        projectOptions,
        settingsOptions,
      }

    case rts.handleOpenGroupItem:
      return { ...state, selectedGroupItem }

    case rts.handleCloseGroupItem:
      return { ...state, selectedGroupItem: null }

    default:
      return { ...state }
  }
}

export const settingsAreaInitialState = (initState) => {
  return { ...initState }
};

export const settingsAreaHandlers = (dispatch) => {
  return {
    handleAppAreaChange: (appArea, isGlobal, baseSettings_state, appAreaStates) => { dispatch({ type: rts.handleAppAreaChange, appArea, isGlobal, baseSettings_state, appAreaStates }) },
    handleOpenGroupItem: (selectedGroupItem) => { dispatch({ type: rts.handleOpenGroupItem, selectedGroupItem }) },
    handleCloseGroupItem: () => { dispatch({ type: rts.handleCloseGroupItem }) },
  }
}

export const settingsAreaFunctions = (settingsArea_state) => {
  const { baseSettings_side } = settingsArea_state
  return {
    getCurrentConsoleSettings: (key) => getCurrentConsoleSettings(baseSettings_side, key),
    getSelectedGroupItem: (swipedItem, isGlobal) => getSelectedGroupItem(settingsArea_state, swipedItem, isGlobal),
    ammendDataGroupItem: (swipedItem, updateProps, swipeProps) => ammendDataGroupItem(settingsArea_state, swipedItem, updateProps, swipeProps),
    getSettingsBaseItem: (key, si) => getSettingsBaseItem(settingsArea_state, key, si),
  }
}

const getSelectedGroupItem = (settingsArea_state, swipedItem, isGlobal) => {

  const { saSplits } = settingsArea_state ? settingsArea_state : {}
  const { pageItem, prop, propSection } = saSplits ? saSplits : {}

  const _pageItem = isGlobal ? pageItem.global : pageItem.page
  const _prop = isGlobal ? prop.global : prop.page
  const _propSection = isGlobal ? propSection.global : propSection.page

  let selectedGroupItem;

  if (swipedItem.vit) {
    // viewItems
    // returns the items in the vi object
    selectedGroupItem = { groupItems: _pageItem }
  } else if (swipedItem.pit) {
    // props
    // returns the items in the pr object
    selectedGroupItem = { groupItems: _prop }
  } else if (swipedItem.psg) {
    // propSections
    // returns the items in the ps object
    selectedGroupItem = { groupItems: _propSection }
  }

  return selectedGroupItem

}

const ammendDataGroupItem = (settingsArea_state, swipedItem, updateProps, swipeProps) => {

  const { baseSettings_combined, isGlobal } = settingsArea_state ? settingsArea_state : {}

  const { pageItem: _pageItem, page: _page, global: _global, prop: _prop, propSection: _propSection } = baseSettings_combined ? baseSettings_combined : {}

  const _side = isGlobal ? _global : _page

  let selectedGroupItem;

  // the selectedGroupItem will contain the groupItems

  if (updateProps && updateProps.vit) {
    if (updateProps.pit) {
      // take from props objects
      selectedGroupItem = _prop && _prop[swipeProps.key]
    } else if (updateProps.psg) {
      // take from propSettings objects 
      selectedGroupItem = _propSection && _propSection[swipeProps.key]
    } else {
      // take from viewItem objects 
      selectedGroupItem = _pageItem && _pageItem[swipeProps.key]
    }
  } else {
    if (updateProps && updateProps.subKey) {
      switch (updateProps.subKey) {
        case 'dataListItem': // LOOK 
          selectedGroupItem = _global && _global[swipedItem.key]
          swipeProps.viewProps = selectedGroupItem
          break;
        default:
          selectedGroupItem = _global && _global[swipedItem.key]
          swipeProps.viewProps = selectedGroupItem
      }
    } else {
      // take from view objects 
      // console.log('looking for ...', isGlobal, swipedItem.key, swipedItem)
      selectedGroupItem = _side && _side[swipedItem.key]
      swipeProps.viewProps = selectedGroupItem
    }
  }

  const _sideItem = _side ? _side[swipedItem.key] : null

  const { allowSettingsUpdate, settingsUpdateHandler, settingsUpdateHandlerFunction } = _sideItem ? _sideItem : {}
  if (allowSettingsUpdate && settingsUpdateHandler && settingsUpdateHandlerFunction) {
    swipeProps.formHandler = {
      allowSettingsUpdate,
      settingsUpdateHandler,
      settingsUpdateHandlerFunction
    }
  }

  return selectedGroupItem

}

const getSettingsBaseItem = (settingsArea_state, key, si) => {

  const { baseSettings_combined } = settingsArea_state ? settingsArea_state : {}
  const { pageItem, global, prop, propSection } = baseSettings_combined ? baseSettings_combined : {}

  const sub = si ? si : 'groupItems'

  if (global[key]) { return global[key][sub] }
  if (pageItem[key]) { return pageItem[key][sub] }
  if (prop[key]) { return prop[key][sub] }
  if (propSection[key]) { return propSection[key][sub] }
}

const getCurrentConsoleSettings = (baseSettings_side, key) => {
  if (baseSettings_side[key]) {
    return baseSettings_side[key]
  }
}