import _ from 'lodash';
import { createFsDocKey } from '../../../global/firestoreData/appData/fsAppData';
import { matchUpdateTypes } from '../cnr/reducers/SportsSeasonReducer';
import { allowSeasonalUpdates, getPks, seasonalUpdateTypes, updateSeasonalOrGlobal } from '../cnr/reducers/reducerHelpers/seasonalSportsData';
import { updateRealtimeScoreToDatabase, deleteRealtimeDatabaseScores } from './realtimeScoring';
import { deleteField } from 'firebase/firestore';
import { playoffBracketTypes } from '../elements/matches/PlayoffBracket';

const _allowRealtimeMatchUpdates = true
const _allowPendingMatchUpdates = true

export const updateTheMatch = async (matchUpdateType, pathViews, latestSeason, sportsKey, actionProps, appUserAccess, callback) => {

  const { match_current, sortedAthletes, match_rt, playoffMatches } = actionProps ? actionProps : {}
  const { _itemKey, isPlayoff, startDate, startTime, location, results, results_current, home, away } = match_current ? match_current : {}

  let _results = results_current ? results_current : results
  if (match_rt) { _results = match_rt }

  const { score, scores, winner, loser, inProgress, complete } = _results ? _results : {}

  const _matchKey = _itemKey

  let _dataType = isPlayoff ? 'playoffMatches' : 'matches'
  let _matchType = isPlayoff ? 'playoffs' : 'matches'

  const pks = getPks(pathViews, latestSeason, sportsKey, ['teams'])

  const data = {}

  if (match_current && _matchKey) {

    const _mk = _dataType + '.' + _matchKey

    if (!match_current.teams) {
      data[_mk + '.teams'] = [home.id, away.id];
    }

    switch (matchUpdateType) {
      case matchUpdateTypes.deleteRealtimeMatch:
        break;

      case matchUpdateTypes.deleteMatch:
        data[_mk] = deleteField();
        break;

      case matchUpdateTypes.deleteScore:
        data[_mk + '.results'] = {};
        break;

      case matchUpdateTypes.updateMatchDate:
        data[_mk + '.startDate'] = startDate;
        data[_mk + '.startTime'] = startTime;
        data[_mk + '.postponed'] = deleteField();
        data[_mk + '.cancelled'] = deleteField();
        break;

      case matchUpdateTypes.postponeMatch:
        data[_mk + '.postponed'] = true;
        data[_mk + '.cancelled'] = deleteField();
        break;

      case matchUpdateTypes.cancelMatch:
        data[_mk + '.cancelled'] = true;
        data[_mk + '.postponed'] = deleteField();
        break;

      case matchUpdateTypes.resetByeMatch:
        const { place, poolName, matchNumber } = match_current.pending ? match_current.pending : {}
        const byeName = poolName + ' #' + matchNumber + '/' + place
        console.log('byeName', byeName)
        data[_mk + '.away'] = { id: match_current.seeds.away, name: byeName };
        break;

      case matchUpdateTypes.updateMatchLocation:
        data[_mk + '.location'] = location;
        break;

      case matchUpdateTypes.updateScore:

        if (score || scores) {

          const results = {}

          if (scores) {
            if (_.isArray(scores)) {
              results.scores = _.compact(scores);
            } else {
              results.scores = scores;
            }
          }

          if (score) { results.score = score; }

          let players;

          if (sortedAthletes) {
            players = {}
            sortedAthletes.forEach((sa, index) => {
              players[sa.key] = {
                firstName: sa.firstName,
                lastName: sa.lastName,
                position: index
              }
            })
            results.players = players
          }

          data[_mk + '.results'] = results;
        }
        break;

      default:
        break;
    }

    switch (matchUpdateType) {
      case matchUpdateTypes.deleteRealtimeMatch:
        deleteRealtimeDatabaseScores(pathViews, _matchKey, appUserAccess, latestSeason)
        break;
      default:
        await updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, pks, _matchType, null, data)
        if (match_rt && allowSeasonalUpdates.any && allowSeasonalUpdates.matches) {
          // await deleteRealtimeDatabaseScores(pathViews, _matchKey, appUserAccess, latestSeason)
        }
        if (allowSeasonalUpdates.any && allowSeasonalUpdates.matches && isPlayoff && playoffMatches && matchUpdateType === matchUpdateTypes.updateScore) {
          updatePendingMatch(pathViews, latestSeason, sportsKey, null, match_current, playoffMatches, winner, loser, _matchType, callback)
        }
    }
  }
}

// export const deleteMatchResults = async (pathViews, viewItem, actionProps, callback) => {
//   const { dataSource } = viewItem ? viewItem : {}
//   const { dataParents } = dataSource ? dataSource : {}
//   const { match_current } = actionProps ? actionProps : {}
//   const { key: matchKey, isPlayoff } = match_current ? match_current : {}

//   const _matches = isPlayoff ? 'playoffMatches' : 'matches'
//   const altSeasonalName = isPlayoff ? '_playoffs' : null

//   if (allowUpdates && match_current && matchKey) {
//     const data = {}
//     data[_matches + '.' + matchKey + '.results'] = {};
//     deleteSeasonalData(pathViews, dataParents, 'matches', null, callback, data, altSeasonalName)
//   } else {
//     console.log(matchKey, match_current)
//   }
// }

export const updateCurrentRealtimeMatchScore = async (pathViews, actionProps, appUserAccess, latestSeason, callback) => {

  const { match_rt, match_true } = actionProps ? actionProps : {}
  const { score, scores, complete, winner, loser, setNumber, setScorez } = match_rt ? match_rt : {}
  const { _itemKey: match_key } = match_true ? match_true : {}

  if (match_rt && match_key && (score || scores)) {

    const results = {}

    // if (inProgress) { results.inProgress = true; }
    if (complete) { results.complete = true; }
    if (scores) { results.scores = scores; }
    if (score) { results.score = score; }
    if (winner) { results.winner = winner; }
    if (loser) { results.loser = loser; }
    if (setNumber) { results.setNumber = setNumber; }
    if (setScorez) { results.setScorez = setScorez; }

    if (_allowRealtimeMatchUpdates) {
      updateRealtimeScoreToDatabase(pathViews, match_key, results, appUserAccess, latestSeason, callback)
    }
  }
}

/**
 * Updates the next match of the team updated
 * @param {object} pathViews 
 * @param {string} latestSeason 
 * @param {string} dataType 
 * @param {object} match_current 
 * @param {object} playoffMatches 
 * @param {object} winner 
 * @param {string} altSeasonalName 
 * @param {func} callback 
 */
const updatePendingMatch = async (pathViews, latestSeason, sportsKey, documentFieldName, match_current, playoffMatches, winner, loser, altSeasonalName, callback) => {

  const pks = getPks(pathViews, latestSeason, sportsKey)

  const data_winner = {}
  const data_loser = {}

  // this is the match that was updated
  const { home, away, poolKey, levels, pending } = match_current
  const { seed: seed_home } = home ? home : {}
  const { seed: seed_away } = away ? away : {}
  const { home: pending_home } = pending ? pending : {}
  const { away: pending_away } = pending ? pending : {}
  const { originalSeed: originalSeed_home } = pending_home ? pending_home : {}
  const { originalSeed: originalSeed_away } = pending_away ? pending_away : {}

  const _seed_home = originalSeed_home ? originalSeed_home : seed_home
  const _seed_away = originalSeed_away ? originalSeed_away : seed_away

  // this is the high seed  
  // let _poolKey;
  // // the homeSeed ...

  // switch (poolKey) {
  //   case playoffBracketTypes.roundOf32:
  //     _poolKey = playoffBracketTypes.roundOf16
  //     break;
  //   case playoffBracketTypes.roundOf16:
  //     _poolKey = playoffBracketTypes.quarterFinals
  //     break;
  //   case playoffBracketTypes.quarterFinals:
  //     _poolKey = playoffBracketTypes.semiFinals
  //     break;
  //   case playoffBracketTypes.semiFinals:
  //     _poolKey = playoffBracketTypes.finals
  //     break;
  //   case playoffBracketTypes.finals:
  //     _poolKey = playoffBracketTypes.roundOf16
  //     break;

  // }

  // get the MATCH from playoffMatches for the pending HOME team
  const winnersMatch = {
    home: getPendingMatch(levels, playoffMatches, 'home', poolKey, _seed_home),
    away: getPendingMatch(levels, playoffMatches, 'away', poolKey, _seed_home)
  }

  const losersMatch = {
    home: getPendingMatch(levels, playoffMatches, 'home', poolKey, _seed_away),
    away: getPendingMatch(levels, playoffMatches, 'away', poolKey, _seed_away)
  }

  if (winnersMatch.home && winnersMatch.home.length === 1) {
    getMatchData(winnersMatch.home[0], 'home', 'away', playoffMatches, levels, winner, data_winner)
  }

  if (winnersMatch.away && winnersMatch.away.length === 1) {
    getMatchData(winnersMatch.away[0], 'away', 'home', playoffMatches, levels, winner, data_winner)
  }

  if (losersMatch.home && losersMatch.home.length === 1) {
    getMatchData(losersMatch.home[0], 'home', 'away', playoffMatches, levels, loser, data_loser)
  }

  if (losersMatch.away && losersMatch.away.length === 1) {
    getMatchData(losersMatch.away[0], 'away', 'home', playoffMatches, levels, loser, data_loser)
  }

  if (_allowPendingMatchUpdates) {
    // updates `_` + altSeasonalName
    if (data_winner && data_loser) {
      await updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, pks, altSeasonalName, documentFieldName, data_winner)
      updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, pks, altSeasonalName, documentFieldName, data_loser, callback)
    } else if (data_winner) {
      updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, pks, altSeasonalName, documentFieldName, data_winner, callback)
    } else if (data_loser) {
      updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, pks, altSeasonalName, documentFieldName, data_loser, callback)
    }
  } else {
    console.log('UPDATE THE PENDING MATCH')
    console.log('pks', pks)
    console.log('match_current', match_current)
    console.log('data_winner', data_winner)
    console.log('data_loser', data_loser)
    console.log('*****')
  }
}

const getMatchData = (match, teamType, oppType, playoffMatches, levels, winner, data) => {
  const { key: matchKey } = match
  const _playoffMatch = playoffMatches[matchKey]
  const opponent = getOpponent(levels, playoffMatches, _playoffMatch, oppType)
  data['playoffMatches.' + matchKey + '.' + teamType] = winner;
  if (opponent) { data['playoffMatches.' + matchKey + '.' + oppType] = opponent; }
}

/**
 * 
 * @param {object} levels 
 * @param {object} playoffMatches 
 * @param {string} homeOrAway 
 * @param {string} poolKey 
 * @param {number} originalSeed 
 * @returns 
 */
const getPendingMatch = (levels, playoffMatches, homeOrAway, poolKey, originalSeed) => _.filter(playoffMatches, function (pm) {
  const { pending } = pm
  return levels === pm.levels && pending &&
    (
      pending[homeOrAway].poolKey === poolKey &&
      (pending[homeOrAway].originalSeed === originalSeed)
    );
})

const getOriginalMatch = (levels, playoffMatches, originalSeed) => _.filter(playoffMatches, function (pm) {
  const { home } = pm
  return levels === pm.levels && home && (
    home.seed === originalSeed);
})

const getOpponent = (levels, playoffMatches, playoffMatch, homeOrAway) => {
  const { pending: _pending } = playoffMatch
  const pendingTeam = _pending[homeOrAway]
  const { originalSeed } = pendingTeam
  const originalMatch = getOriginalMatch(levels, playoffMatches, originalSeed)
  if (originalMatch && originalMatch.length === 1) {
    return originalMatch[0][homeOrAway]
  }
}

export const createNewMatch = async (pathViews, latestSeason, sportsKey, actionProps, callback) => {

  const { match_current } = actionProps ? actionProps : {}
  const { isPlayoff } = match_current ? match_current : {}

  const pks = getPks(pathViews, latestSeason, sportsKey)

  const _matches = isPlayoff ? 'playoffMatches' : 'matches'
  const altSeasonalName = isPlayoff ? '_playoffs' : null

  if (match_current) {
    const matchKey = createFsDocKey('newMatch')
    const data = {}
    data[_matches + '.' + matchKey] = match_current;
    updateSeasonalOrGlobal(seasonalUpdateTypes.matches, pathViews, pks, 'matches', null, data, callback)
  } else {
    console.log(match_current)
  }
}