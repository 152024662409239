import React, { useContext, useState, useEffect } from 'react';
import NoData from '../../../components/alerts/NoData';
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer';
import { PageContext } from '../../../cnr/contexts/PageContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { DataModifySwiper } from './DataModifySwiper';
import { dataModificationTypes } from '../../../viewSettings/enums/itemActionTypes';
import { DataModificationsContext } from '../UiDataModifications';

/**
 * 
 * @param {object} props (selectedKey, selectedItem)
 * @returns DataModifySwiper
 */
const UiAddEdit = (props) => {

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings, pageNav } = page_state ? page_state : {}
  const { navUiItemContext } = pageNav ? pageNav : {}
  const { aps_viewItems } = pageSettings ? pageSettings : {}

  // only to get the editProps
  // uiItemContext
  const uiItemContext = useContext(UiItemContext);

  const _uiItemContext = navUiItemContext ? navUiItemContext : uiItemContext

  const { item_state } = _uiItemContext ? _uiItemContext : {}
  const { showSingleItemEdit, editableItem, viewItem, viewItem_key, view, vld: _viewListData } = item_state ? item_state : {}
  const { dataModifications } = viewItem ? viewItem : {}
  const { edit } = dataModifications ? dataModifications : {}
  const { subEditProps } = edit ? edit : {}

  // dataManagement
  const dataManagement = useContext(DataManagementContext);
  const { dataManagement_state } = dataManagement ? dataManagement : {}
  const { viewItem_preview, selectedItem } = dataManagement_state ? dataManagement_state : {}
  const { props: prop_viewItemPreview } = viewItem_preview ? viewItem_preview : {}

  const _viewItem = viewItem_preview ? viewItem_preview : viewItem

  let _itemData = _viewListData

  let selectedKey;

  if (selectedItem) {
    selectedKey = selectedItem._itemKey
    _itemData = selectedItem
  }

  // dataModificationsContext
  const dataModificationsContext = useContext(DataModificationsContext)
  const { dataModifications_state, dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}
  const { modifyProps } = dataModifications_state ? dataModifications_state : {}
  let { dataModificationType } = dataModifications_state ? dataModifications_state : {}

  if (selectedKey || showSingleItemEdit) { dataModificationType = dataModificationTypes.edit }
  if (props.dataModificationType) { dataModificationType = props.dataModificationType }

  const viewItem_edit = aps_viewItems && aps_viewItems[viewItem_key] ? aps_viewItems[viewItem_key] : null
  const { props: props_viewItem } = viewItem_edit ? viewItem_edit : {}

  const _props = props_viewItem ? props_viewItem : prop_viewItemPreview

  // let createFunction = null
  let useWizard = false

  // editableModifyProps are the props that will be displayed
  const [editableModifyProps, setEditableModifyProps] = useState()

  const _modifyProps = editableModifyProps ? editableModifyProps : modifyProps
 
  useEffect(() => {
    if (dataModifications_handlers) {
      dataModifications_handlers.handleGetModifyProps(editableItem, _props, subEditProps, dataModificationType)
    } else if (editableItem) {
      let emps = {}
      Object.keys(editableItem).forEach(k => {
        emps[k] = editableItem[k]
        emps[k].propSection = 'name'
      })
      setEditableModifyProps(emps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // IMPORTANT: Add/Edit Data
  const content = () => {

    let _modifyData = _itemData ? _itemData[Object.keys(_itemData)[0]] : {}
    if (selectedItem) { _modifyData = selectedItem }

    const dmsProps = {
      dataUpdateType: dataModificationType,
      editableItem,
      isDirect: selectedKey ? false : true,
      modifyProps: _modifyProps,
      selectedKey,
      useWizard,
      viewItem: _viewItem,
      ignoreItemStatus: props.ignoreItemStatus,
    }

    switch (dataModificationType) {

      case dataModificationTypes.add:
        // add the page's itemData value to the initial data
        const _addData = _modifyData && _modifyData[view] ? { [view]: _modifyData[view] } : null
        const lockedData = [view]
        return <DataModifySwiper
          {...dmsProps}
          lockedData={lockedData}
          data_initial={_addData}
        />

      case dataModificationTypes.edit:
        return <DataModifySwiper
          {...dmsProps}
          data_initial={_modifyData}
        />

      default:
        return <NoData altCaption={'Modify Action Type'} />
    }
  }

  return _modifyProps ? content() : <div></div>
}

export default UiAddEdit