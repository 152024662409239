import _ from 'lodash';
import { gEnums } from '../../../enums/globalEnums';
import { vsComponentTypes } from '../../enums/vsComponentTypes';

/** Returns the props for the fetch button 
 * @description included the `caption` and the `onClick` function
 * @returns (eventKey, eventSpecific, googleSheets, input, normal, select, subEvent and url)
 * @triggers function in create_handlers
 * Called from CreateSomthing
*/
export const fetchButtonProps = (props) => {

  const _amts = gEnums.addModeTypes

  const { addModeType, createType, create_state, create_handlers } = props
  const { createInfo, createError, fetching } = create_state ? create_state : {}
  const { handleFetch_url } = create_handlers ? create_handlers : {}

  let buttonCaption;
  let oc;

  switch (addModeType) {

    case _amts.registrationKey:
      buttonCaption = _.startCase('getRegistrationData')
      oc = create_handlers.handleFetch_urlFeed
      break;

    case _amts.eventSpecific:
      buttonCaption = _.startCase('selectAction')
      oc = create_handlers.handleFetch_googleSheet
      break;

    case _amts.googleSheets:
    case _amts.googleSheetsOnly:
      buttonCaption = _.startCase('getSheetsData')
      oc = create_handlers.handleFetch_googleSheet
      break;

    case _amts.input:
      switch (createType) {
        case 'createClient':
          buttonCaption = _.startCase('createClient')
          oc = handleFetch_url
          break;
        default:
          buttonCaption = _.startCase('add')
          oc = handleFetch_url
          break;
      }
      break;

    case _amts.normal:
      buttonCaption = 'Add'
      oc = create_handlers.handleFetch_url
      break;

    case _amts.select:
      buttonCaption = _.startCase('getEventSettings')
      oc = create_handlers.handleCreate_clientProjectSettings
      switch (createType) {
        case vsComponentTypes.createClient:
          buttonCaption = _.startCase('getClientSettings')
          break;
        default:
        // nothing
      }
      break;

    case _amts.subEvent:
      buttonCaption = _.startCase('getEventInfo')
      oc = create_handlers.handleCreate_fromAppVis
      break;

    case _amts.url:
      buttonCaption = _.startCase('getUrlData')
      oc = handleFetch_url
      break;

    default:
    // nothing
  }
  if (oc) {
    return { oc, caption: buttonCaption, createInfo, createError, fetching }
  }
}
