/** Prop information for the form
 * @returns data_current
 * @returns form_handlers from uiItemDataReducer
 * @returns source (details about the origin of the request)
 * @returns updateProps - props (formKey) for the update
 * @returns viewItem - viewItem settings 
 * @returns viewProps - set of props coming from the viewItem.props
 * @returns viewPropsAllowed
 */
export const getFormPropz = (props) => {
  const {
    camelCase,
    creds,
    data_current,
    editableItem,
    form_handlers,
    formActions,
    handlers,
    ignorePropSections,
    menuType,
    readOnlyForm,
    formHandler,
    source,
    updateProps,
    useWizard,
    viewItem,
    viewProps,
    viewPropsAllowed,
  } = props
  return {
    creds,
    data_current,
    editableItem,
    form_handlers,
    formActions,
    menuType,
    ignorePropSections,
    handlers: handlers,
    readOnlyForm,
    source,
    updateProps,
    useWizard,
    formHandler,
    viewItem,
    viewProps,
    viewPropsAllowed,
    camelCase,
  }
}
