import { getFirestore } from "firebase/firestore";
import { initFirestore, _auth } from "paoke-firebase";
import { fbAppConfigs } from "../../../project/appConfigs";
import { envTypes } from "../../../project/fbConfiguration";
import { removeFromObject } from "../../common/sorting";
import { createRefPath, createRefPath_client } from "../../firestoreData/appData/appRefPaths";
import { fs_get_data } from "../../firestoreData/appData/fsAppData";
import { fs_set_doc } from "../../firestoreData/appData/fsData";
import { fsfn_deleteEventCollection } from "../../functions/fbDelete";
import { getPathViews } from "../../redirection/current";
import { _appFormsCollectionName, _appSettingsCollectionName } from "./BaseSettingsReducer";
import { grts, responseReducers } from "./reducerHelpers/dispatchProps";
import { _settingsFs } from "../../viewSettings/actions/getSettings";
import { sportsDbCollections } from "../../../projectSpecific/sports/cnr/reducers/SportsSeasonReducer";

const _allowTransfer = {
  event: true,
  settings: true,
  collections: true
}

// https://console.cloud.google.com/storage/transfer
// I@mgtlts@2023!

export const transferTypes = {
  exportAppSettings: 'exportAppSettings',
  exportAuth: 'exportAuth',
  exportClientSettings: 'exportClientSettings',
  exportData: 'exportData',
  exportEvent: 'exportEvent',
  exportOther: 'exportOther',
  exportSettings: 'exportSettings',
  importAppSettings: 'importAppSettings',
  importAuth: 'importAuth',
  importClientSettings: 'importClientSettings',
  importData: 'importData',
  importEvent: 'importEvent',
  importOther: 'importOther',
  importSettings: 'importSettings',
}

export const transferDirectionTypes = {
  export: 'export',
  import: 'import',
}

export const settingsTransferTypes = {
  appSettings: 'appSettings',
  auth: 'auth',
  clientSettings: 'clientSettings',
  data: 'data',
  event: 'event',
  other: 'other',
  settings: 'settings',
}

export const rts = {
  handleAltUser: 'handleAltUser',
  handleCommitTransfer: 'handleCommitTransfer',
  handleKillConfirmation: 'handleKillConfirmation',
  handleSelectApp: 'handleSelectApp',
  handleSetFirestores: 'handleSetFirestores',
  handleSetSettings: 'handleSetSettings',
  handleShowConfirmation: 'handleShowConfirmation',
  handleSignIn: 'handleSignIn',
  handleUpdateFormData: 'handleUpdateFormData',
  handleSelectEnvironment: 'handleSelectEnvironment',
  handleSelectSetting: 'handleSelectSetting',
  handleSelectDirection: 'handleSelectDirection',
  handleCallback_transfer: 'handleCallback_transfer',
  ...grts
}

export const transferReducerInitialState = (initState) => {
  return { ...initState }
};

export const transferReducer = (state, action) => {

  const { formData, showConfirmation, init_alt, currentFirestores, fs_alt, fs_current, pvs, pathViews } = state

  const { userName, password, appName, environment } = formData ? formData : {}
  const _env = environment === envTypes.development ? 'dev' : 'prod'
  const _fsKey = appName && environment ? appName + '_' + environment : null

  let altCollections;

  switch (appName) {
    case 'thumbstat':
      altCollections = sportsDbCollections
      break;
    default:
    //nothing
  }

  const { type, dispatch } = action

  const { handleAltUser, handleCallback_transfer } = transferHandlers(dispatch)

  switch (type) {

    case rts.handleSetFirestores:
      if (appName && environment) {
        const _currentFirestores = { ...currentFirestores }
        const _config_alt = fbAppConfigs[appName][_env]
        const _init_alt = initFirestore(_config_alt, _fsKey)
        const _fs_alt = getFirestore(_init_alt)
        const _fs_current = getFirestore()
        _currentFirestores[_fsKey] = _init_alt
        return { ...state, currentFirestores: _currentFirestores, fs_current: _fs_current, fs_alt: _fs_alt, init_alt: _init_alt }
      } else {
        return { ...state, currentConfig: action.currentConfig }
      }

    case rts.handleCommitTransfer:

      let _import = showConfirmation.startsWith('import');

      const fss = {
        source: _import ? fs_alt : fs_current,
        destination: _import ? fs_current : fs_alt,
      }

      const pvKeys = {
        source: {
          clients: _import ? pvs.clients : pathViews.clients,
          events: _import ? pvs.events : pathViews.events,
        },
        destination: {
          clients: _import ? pathViews.clients : pvs.clients,
          events: _import ? pathViews.events : pvs.events,
        },
      }

      switch (showConfirmation) {
        case transferTypes.importAuth:
        case transferTypes.exportAuth:
          // callFsFunction()
          break;

        case transferTypes.exportAppSettings:
        case transferTypes.importAppSettings:
          transferAppSettings(fss, handleCallback_transfer)
          break;

        case transferTypes.importOther:
        case transferTypes.exportOther:
          transferOther(fss, pvKeys, handleCallback_transfer)
          break;

        case transferTypes.importData:
        case transferTypes.exportData:
          transferData(fss, pvKeys, handleCallback_transfer, altCollections)
          break;

        case transferTypes.importClientSettings:
        case transferTypes.exportClientSettings:
          transferSettings(fss, pvKeys, null, true, false, handleCallback_transfer)
          break;

        case transferTypes.importSettings:
        case transferTypes.exportSettings:
          transferSettings(fss, pvKeys, null, null, false, handleCallback_transfer)
          break;

        case transferTypes.importEvent:
        case transferTypes.exportEvent:
          transferEvent(fss, pvKeys, handleCallback_transfer)
          break;

        default:
        // nothing
      }

      return { ...state, showConfirmation: false }

    case rts.handleCallback_transfer:
      return { ...state, showConfirmation: false, dcUpdates: action.done ? action.dcUpdates : action.dcUpdates, updating: action.done ? null : state.updating }

    case rts.handleSetSettings:
      return { ...state, settings: action.settings, settingsKey: action.settingsKey }

    case rts.handleUpdateFormData:
      const { d } = action
      let _pvs = {};
      if (d.urlPath) {
        _pvs = getPathViews(d.urlPath)
        if (_pvs.clients) { d.clientKey = fixKey(_pvs.clients) }
        if (_pvs.events) {
          d.eventKey = fixKey(_pvs.events)
        } else {
          d.eventKey = fixKey(pathViews.events)
          _pvs.events = pathViews.events
        }
        return { ...state, formData: d, pvs: _pvs }
      } else {
        if (d.clientKey) { _pvs.clients = d.clientKey }
        if (d.eventKey) { _pvs.events = d.eventKey }
        return { ...state, formData: d, pvs: _pvs }
      }

    case rts.handleSignIn:
      if (init_alt && userName && password) {
        const altAuth = _auth.getAuth(init_alt)
        _auth.signInWithEmailAndPassword(altAuth, userName, password).then(res => {
          const { currentUser: cu } = altAuth ? altAuth : {}
          const altUser = {
            authId: res.user.uid,
            appUser: cu
          }
          handleAltUser(altUser)
        })
      }
      return { ...state }

    case rts.handleAltUser:
      return { ...state, altUser: action.altUser }

    case rts.handleKillConfirmation:
      return { ...state, showConfirmation: false }

    case rts.handleShowConfirmation:
      return { ...state, showConfirmation: action.transferType }

    case rts.handleSelectApp:
      return { ...state, appName: action.appName }

    case rts.handleSelectEnvironment:
      return { ...state, environment: action.environment }

    case rts.handleSelectSetting:
      return { ...state, currentSetting: action.currentSetting }

    case rts.handleSelectDirection:
      return { ...state, currentDirection: action.currentDirection }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const transferHandlers = (dispatch) => {
  return {
    handleCommitTransfer: () => { dispatch({ type: rts.handleCommitTransfer, dispatch }) },
    handleAltUser: (altUser) => { dispatch({ type: rts.handleAltUser, dispatch, altUser }) },
    handleKillConfirmation: () => { dispatch({ type: rts.handleKillConfirmation, dispatch }) },
    handleSelectApp: (appName) => { dispatch({ type: rts.handleSelectApp, dispatch, appName }) },
    handleSetFirestores: (currentConfig) => { dispatch({ type: rts.handleSetFirestores, dispatch, currentConfig }) },
    handleSetSettings: (settings, settingsKey) => { dispatch({ type: rts.handleSetSettings, dispatch, settings, settingsKey }) },
    handleShowConfirmation: (transferType) => { dispatch({ type: rts.handleShowConfirmation, dispatch, transferType }) },
    handleSignIn: (userName, password) => { dispatch({ type: rts.handleSignIn, dispatch, userName, password }) },
    handleUpdateFormData: (d) => { dispatch({ type: rts.handleUpdateFormData, dispatch, d }) },
    handleSelectEnvironment: (environment) => { dispatch({ type: rts.handleSelectEnvironment, dispatch, environment }) },
    handleSelectSetting: (currentSetting) => { dispatch({ type: rts.handleSelectSetting, dispatch, currentSetting }) },
    handleSelectDirection: (currentDirection) => { dispatch({ type: rts.handleSelectDirection, dispatch, currentDirection }) },
    handleCallback_transfer: (dcUpdates, done) => { dispatch({ type: rts.handleCallback_transfer, dispatch, dcUpdates, done }) },
  }
}

/**
 * 
 * @param {object} state 
 * @param {object} fss  
 */
const transferEvent = (fss, pvKeys, callback) => {

  // callback after the collections are fetched
  const _cb2 = async (_event_source, sourceDataCollections, settings) => {
    const _refPathEvent_destination = createRefPath_client(pvKeys.destination, ['events', pvKeys.destination.events])
    // get the event from the source
    let eventDestination = await fs_get_data({ refPath: _refPathEvent_destination, opts: { returnFirstObject: true } })
    if (_event_source && !eventDestination) {
      removeFromObject(_event_source, ['id', '_itemKey', '_new'])
      if (_allowTransfer.event) {
        // updates the EVENT
        eventDestination = await fs_set_doc(_refPathEvent_destination, _event_source, false, null, null, fss.destination)
      } else {
        console.log('*** Transfer to ' + fss.destination._databaseId.projectId + ' Event')
        console.log('_refPath', _refPathEvent_destination, _event_source)
      }
    }

    // if we have the sourceDataCollections
    if (sourceDataCollections) {
      // transfers the data
      await updateDestinationEventCollectionsPromise(_refPathEvent_destination, fss.destination, sourceDataCollections, pvKeys.destination, callback)
      // not tranfer the settings
      transferSettings(fss, pvKeys, settings, false, true, callback)
    }
  }

  // callback - get event and dataCollections from the source
  const _cb = async (settings) => {
    const { viewItems } = settings
    const _refPathE = createRefPath(['clients', pvKeys.source.clients, 'events', pvKeys.source.events])
    const _event_source = await fs_get_data({ refPath: _refPathE, fs: fss.source, opts: { returnFirstObject: true } })
    const viewItemKeys = Object.keys(viewItems)

    // GET ALL the data collections from the source
    const dataCollections = await getDataCollections(fss.source, pvKeys.source, viewItemKeys)
    // send the response the the callback
    _cb2(_event_source, dataCollections, settings)
  }

  // get the settings from the source
  getSourceSettings(fss.source, pvKeys.source, false, _cb)

}

const transferSettings = (fss, pvKeys, settings, clientSettingsOnly, tranferOtherData, callback) => {

  const _cb = async (settingz) => {
    const _settingsKey = clientSettingsOnly ? pvKeys.destination.clients : pvKeys.destination.events
    await updateDestinationSettingsPromise(settingz, _settingsKey, fss.destination)
    if (tranferOtherData) {
      transferOther(fss, pvKeys, callback)
    } else {
      callback()
    }
  }

  if (settings) {
    _cb(settings)
  } else {
    getSourceSettings(fss.source, pvKeys.source, clientSettingsOnly, _cb)
  }
}

const transferOther = (fss, pvKeys, callback) => {

  const _cb2 = async (sourceDataCollections) => {
    const _refPathEvent_destination = createRefPath_client(pvKeys.destination, ['events', pvKeys.destination.events])
    if (sourceDataCollections) {
      await updateDestinationEventCollectionsPromise(_refPathEvent_destination, fss.destination, sourceDataCollections, pvKeys.destination, callback)
    }
  }
  const _cb = async () => {
    const viewItemKeys = ['_globals', '_notifications', '_zones']
    // const viewItemKeys = ['_notifications']
    const dataCollections = await getDataCollections(fss.source, pvKeys.source, viewItemKeys)
    _cb2(dataCollections)
  }

  _cb()

}

/**
 * Transfers data from one source to another
 * @param {object} fss 
 * @param {object} pvKeys 
 * @param {function} callback 
 */
const transferData = (fss, pvKeys, callback, altCollections) => {

  const callback_data = async (sourceDataCollections) => {
    const _refPathEvent_destination = createRefPath_client(pvKeys.destination, ['events', pvKeys.destination.events])
    if (sourceDataCollections) {
      console.log('sourceDataCollections', sourceDataCollections)
      updateDestinationEventCollectionsPromise(_refPathEvent_destination, fss.destination, sourceDataCollections, pvKeys.destination, callback)
    }
  }

  // callback - get event and dataCollections from the source
  const callback_settings = async (settings) => {
    const { viewItems } = settings
    let viewItemKeys = []
    if (altCollections) {
      if (altCollections.normal) {
        Object.keys(altCollections.normal).forEach(nk => {
          viewItemKeys.push(nk)
        })
      }
      if (altCollections.seasonal) {
        Object.keys(altCollections.seasonal).forEach(sk => {
          viewItemKeys.push('_' + sk)
        })
      }
    } else {
      viewItemKeys = Object.keys(viewItems)
    }

    const dataCollections = await getDataCollections(fss.source, pvKeys.source, viewItemKeys)
    callback_data(dataCollections)
  }

  // get the settings from the source
  getSourceSettings(fss.source, pvKeys.source, false, callback_settings)

}

/**
 * Transfers data from one source to another
 * @param {object} fss 
 * @param {object} pvKeys 
 * @param {function} callback 
 */
const transferAppSettings = (fss, callback) => {

  const callback_data = async (res) => {
    const _appSettings = res[0] ? res[0] : {}
    const _appForms = res[1] ? res[1] : {}
    transferAppSettingsPromise(_appSettings, _appForms, fss).then(res => {
      callback()
    })
  }

  getAppSettingsPromise(fss).then(res => {
    callback_data(res)
  })

}

/**
 * 
 * @param {object} appSettings 
 * @param {object} appForms 
 * @param {object} fss  
 * @returns a promise after the _appSettings and _appForms have been updated
 */
const transferAppSettingsPromise = async (appSettings, appForms, fss) => {
  const promises = []
  if (appSettings) {
    Object.keys(appSettings).forEach(ask => {
      const appSetting = appSettings[ask]
      const _refPath1 = createRefPath([_appSettingsCollectionName, 'app', 'settings', ask])
      promises.push(fs_set_doc(_refPath1, appSetting, false, null, null, fss.destination))
    })
  }

  if (appForms) {
    const _refPath2 = createRefPath([_appFormsCollectionName, 'appForms'])
    promises.push(fs_set_doc(_refPath2, appForms, false, null, null, fss.destination))
  }
  return Promise.all(promises)
}

// const getSourceAppSettings = (fsSource, callback) => {
//   const _refPath = createRefPath([_appSettingsCollectionName, 'app'])
//   fs_get_data({ refPath: _refPath, fs: fsSource }).then(res_status => {
//     callback(res_status)
//   })
// }

/**
 * 
 * @param {object} fss 
 * @returns a promise with data for the _appSettings and _appForms
 */
const getAppSettingsPromise = async (fss) => {
  const promises = []
  const _refPath1 = createRefPath([_appSettingsCollectionName, 'app', 'settings'])
  promises.push(fs_get_data({ refPath: _refPath1, fs: fss.source }))
  const _refPath2 = createRefPath([_appFormsCollectionName, 'appForms'])
  promises.push(fs_get_data({ refPath: _refPath2, fs: fss.source }))
  return Promise.all(promises)
}

/**
 * Returns (via callback) the settings (global, views,viewItems) for the _settingsKey
 * @param {object} fsSource 
 * @param {object} pvKeys 
 * @param {boolean} clientSettingsOnly 
 * @param {function} callback 
 */
const getSourceSettings = (fsSource, pvKeys, clientSettingsOnly, callback) => {

  const { events: eventKey, clients: clientKey } = pvKeys ? pvKeys : {}
  const _eventKey = fixKey(eventKey)

  const _settingsKey = clientSettingsOnly ? clientKey : _eventKey

  const _refPath = createRefPath([_settingsFs.root, _settingsKey])

  fs_get_data({ refPath: _refPath, fs: fsSource, opts: { returnFirstObject: true } }).then(res_status => {
    if (res_status) {
      const docRef_source_2 = createRefPath([_settingsFs.collection], _refPath)
      fs_get_data({ refPath: docRef_source_2, fs: fsSource, opts: { ignoreIds: true } }).then(res_gvvi => {
        if (res_gvvi) {
          const { global, views, viewItems } = res_gvvi
          if (global) { removeFromObject(global, ['id', '_itemKey']) }
          if (views) { removeFromObject(views, ['id', '_itemKey']) }
          if (viewItems) { removeFromObject(viewItems, ['id', '_itemKey']) }
          callback({
            status: res_status,
            global,
            views,
            viewItems,
          })
        }
      })
    }
  })
}

/**
 * 
 * @param {object} fs_source 
 * @param {object} pvKeys_source 
 * @param {object} viewItemKeys 
 * @returns data for each of the pvKeys_source
 */
const getDataCollections = async (fs_source, pvKeys_source, viewItemKeys) => {
  const _dcs = {}
  const dcs = await getEventCollectionsPromise(fs_source, pvKeys_source, viewItemKeys)
  if (dcs) {
    viewItemKeys.forEach((vik, index) => {
      if (dcs[index] && Object.keys(dcs[index]).length > 0) {
        _dcs[vik] = dcs[index]
      }
    })
  }
  return _dcs
}

const getEventCollectionsPromise = async (fs_source, pvKeys_source, viewItemKeys) => {
  const { clients: clientKey, events: eventKey } = pvKeys_source ? pvKeys_source : {}
  const _clientKey = fixKey(clientKey)
  const _eventKey = fixKey(eventKey)
  const promises = []
  if (viewItemKeys) {
    viewItemKeys.forEach(vik => {
      const _refPath = createRefPath(['clients', _clientKey, 'events', _eventKey, vik])
      promises.push(fs_get_data({ refPath: _refPath, fs: fs_source }))
    })
  }
  return Promise.all(promises)
}

/**
 * LejUlcRAnUAnCASg7DXo
 * @param {string} refPathEvent_destination 
 * @param {object} fs_destination 
 * @param {object} sourceDataCollections 
 * @returns A Promise containing all the fs_set_doc for each item in the collection of collections
 */
const updateDestinationEventCollectionsPromise = async (refPathEvent_destination, fs_destination, sourceDataCollections, pvKeys_destination, callback) => {

  const allowAll = true

  // const promises = []
  const checkDone = (updates) => {
    let done = true
    Object.keys(updates).forEach(key => {
      const update = updates[key]
      if (update.applied && !update.updated) {
        done = false
      }
    })
    return done
  }

  if (sourceDataCollections) {

    const dataCollectionKeys = Object.keys(sourceDataCollections)
    const dcUpdates = {}

    dataCollectionKeys.forEach((k, index) => {
      let _allow;
      if (allowAll) {
        _allow = true
      } else {
        _allow = index === 0 ? true : false
      }
      if (_allow) {
        dcUpdates[k] = { updating: false, updated: false, applied: true }
      }
    })

    // loop the collections in the sourceDataCollections
    Object.keys(sourceDataCollections).forEach((collectionKey, index) => {

      let _allow;
      if (allowAll) {
        _allow = true
      } else {
        _allow = index === 0 ? true : false
      }

      if (_allow) {
        dcUpdates[collectionKey].updating = true
        callback && callback(dcUpdates)

        const dataCollection = sourceDataCollections[collectionKey]

        if (dataCollection) {
          updateCollectionPromise(collectionKey, dataCollection, refPathEvent_destination, fs_destination, pvKeys_destination).then(res => {
            dcUpdates[collectionKey].updating = false
            dcUpdates[collectionKey].updated = true
            callback && callback(dcUpdates, checkDone(dcUpdates))
          }).catch(error => {
            dcUpdates[collectionKey].updating = false
            dcUpdates[collectionKey].updated = false
            dcUpdates[collectionKey].error = error
            callback && callback(dcUpdates, checkDone(dcUpdates))
          })
        } else {
          dcUpdates[collectionKey].updating = false
          dcUpdates[collectionKey].updated = true
          callback && callback(dcUpdates, checkDone(dcUpdates))
        }
      }
    })
  }
}

/**
 * Sets (updates) each item in the dataCollection
 * @param {string} collectionKey 
 * @param {object} dataCollection 
 * @param {string} refPathEvent_destination 
 * @param {object} fs_destination 
 * @returns 
 */
const updateCollectionPromise = async (collectionKey, dataCollection, refPathEvent_destination, fs_destination, pvKeys_destination) => {

  if (_allowTransfer.collections) {
    // await fsfn_deleteEventCollection(pvKeys_destination, collectionKey, 100)
  }

  const collectionsLogs = []
  const promises = []

  Object.keys(dataCollection).forEach(dcKey => {
    const itemData = dataCollection[dcKey]
    const _itemRef = createRefPath([collectionKey, dcKey], refPathEvent_destination)
    removeFromObject(itemData, ['id', '_itemKey', '_new'])
    if (_allowTransfer.collections) {
      collectionsLogs.push({ dcKey, _itemRef, itemData })
      promises.push(fs_set_doc(_itemRef, itemData, null, null, null, fs_destination))
    } else {
      collectionsLogs.push({ dcKey, _itemRef, itemData })
    }
  })

  console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' - ' + collectionKey)
  console.log('collectionsLogs', collectionsLogs)

  return Promise.all(promises)
}

const updateDestinationSettingsPromise = async (settings, settingsKey, fs_destination) => {
  const promises = []
  console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' Settings')
  Object.keys(settings).forEach(sk => {
    const setting = settings[sk]
    switch (sk) {
      case 'status':
        const ref_s = createRefPath([_settingsFs.root, settingsKey])
        if (_allowTransfer.settings) {
          fs_set_doc(ref_s, setting, null, null, null, fs_destination)
        } else {
          console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' - ' + settingsKey)
          console.log('ref_s', ref_s, setting)
        }
        break;
      default:
        const ref_o = createRefPath([_settingsFs.root, settingsKey, _settingsFs.collection, sk])
        if (_allowTransfer.settings) {
          fs_set_doc(ref_o, setting, null, null, null, fs_destination)
        } else {
          console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' - ' + settingsKey)
          console.log('ref_o', ref_o, setting)
        }
    }
  })
  return Promise.all(promises)
}

const fixKey = (key) => key ? key.replace(/%20/g, ' ') : ''