import { useEffect, useRef, useState } from 'react';

export const useElementOnScreen = (options) => {

  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callback = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const o = new IntersectionObserver(callback, options)
    if (containerRef.current) o.observe(containerRef.current)
    return () => {
      // eslint-disable-next-line
      if (containerRef.current) o.unobserve(containerRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  return [containerRef, isVisible]

}