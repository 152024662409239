import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Menu, Select } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { getAuthIcon } from '../auth/authPermissions';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { uniqueKey } from '../common/keys';
import { gEnums } from '../enums/globalEnums';

const ProfileSelect = (props) => {

  const {
    asDropdownButton,
    profileSelectType,
    selectOnly,
  } = props

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states, handlers } = parentContext ? parentContext : {}
  const { appUser_state, paps_state } = states ? states : {}
  const { appUser_handlers, appSettings_handlers } = handlers ? handlers : {}

  // appUserContext
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { accessLevel: appUserAccessLevel } = appUserAccess ? appUserAccess : {}
  const currentIcon = getAuthIcon(appUserAccessLevel)

  // papsContext
  const { pathViews } = paps_state

  const selectValue = appUserAccessLevel

  const [optsList, setOptsList] = useState()

  let menuPlaceholder = 'maskProfile'

  if (profileSelectType) {
    switch (profileSelectType) {
      case gEnums.profileSelectTypes.profiles:
      case gEnums.profileSelectTypes.appProfiles:
        menuPlaceholder = 'accessLevel'
        break;
      default:
      // nothing
    }
  }

  const handleChange = (e, data) => {
    switch (profileSelectType) {
      case gEnums.profileSelectTypes.accessLevels:
        appUser_handlers.handleTempAccessLevel(data.value, pathViews)
        appSettings_handlers.handleSettingsOff()
        break;
      default:
    }
  }

  const userProfileOptions = () => {

    let ol;

    if (profileSelectType) {
      switch (profileSelectType) {
        case gEnums.profileSelectTypes.appProfiles:
          ol = gEnums.appAccessLevels
          break;

        default:
          ol = gEnums.accessLevels
      }
    }

    if (!ol) { ol = gEnums.accessLevels }

    const opts = []

    Object.keys(ol).forEach(pl => {
      const x = ol[pl]
      if (x.value <= appUserAccessLevel) {
        const icon = getAuthIcon(x.value)
        opts.push({ key: pl, value: x.value, text: _.startCase(pl), icon: { name: icon.name, color: icon.color } })
      }
    })
    setOptsList(opts)
  }

  useEffect(() => {
    userProfileOptions()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const profileSelectLevelMenuItem = () => {
    return <React.Fragment>
      <Menu.Header>{_.startCase(menuPlaceholder)}</Menu.Header>
      <Menu.Item
        className={'menu-item-select'}
        style={{ padding: 4 }}
        key={uniqueKey('ps')} >
        <Select
          placeholder={menuPlaceholder}
          value={appUserAccessLevel}
          options={optsList}
          onChange={handleChange}
        />
      </Menu.Item>
    </React.Fragment>
  }

  if (!optsList) {
    return <div></div>
  } else {
    if (asDropdownButton) {
      const x = _.find(gEnums.accessLevels, { value: selectValue })
      const text = x ? x.caption : null
      return <Dropdown
        button
        className='icon thin profile-select'
        icon={{ name: currentIcon.name, color: currentIcon.color }}
        labeled
        options={optsList}
        placeholder={_.startCase(menuPlaceholder)}
        scrolling
        onChange={handleChange}
        text={text}
      />
    } else {
      if (selectOnly) {
        return <Select placeholder={menuPlaceholder} value={selectValue} options={optsList} onChange={handleChange} />
      } else {
        return (
          profileSelectLevelMenuItem()
        )
      }
    }
  }
}

export default ProfileSelect