import { initFirestore } from 'paoke-firebase';
import React, { useContext, useEffect, useReducer } from 'react';
import { AppComponentContext } from '../../../../global/cnr/contexts/AppComponentContext';
import { PageContext } from '../../../../global/cnr/contexts/PageContext';
import { PapsContext } from '../../../../global/cnr/contexts/PapsContext';
import { createAppStaticViews } from '../../../../global/firestoreData/staticViews/updateStaticViews';
import { getFbConfig } from '../../../../project/fbConfiguration';
import { golfTournamentHandlers, golfTournamentInitialState, golfTournamentReducer } from '../reducers/GolfTournamentReducer';

export const GolfTournamentContext = React.createContext()

/**
 * @provides teams 
 * @provides golfCourse  
 * @provides holes 
 * @provides golfMatchData  
 * @provides roundsStatus 
 * @provides golfTournament_handlers 
 * @provides golfTeam1
 * @provides golfTeam2
 * @provides golfTeam1Style
 * @provides golfTeam2Style
 */

const GolfTournamentProvider = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext
  const { pathViews, viewKey } = paps_state ? paps_state : {}

  // pageContext
  const pageContext = useContext(PageContext)
  const { page_fns, page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { pushSimple } = page_fns ? page_fns : {}
  const { aps_viewItems } = pageSettings ? pageSettings : {}

  // let viParentKey;
  let viSubKeys;

  if (aps_viewItems) {
    Object.keys(aps_viewItems).forEach(viKey => {
      const { dataConnection } = aps_viewItems[viKey]
      const { startParentData, startDataPages } = dataConnection ? dataConnection : {}
      if (startParentData) {
        // viParentKey = viKey
        viSubKeys = startDataPages
      }
    })
  }

  // golfEventContext
  const golfEventContext = useContext(AppComponentContext)
  const { golfEvent_state } = golfEventContext ? golfEventContext : {}

  // golfTournamentReducer 
  const init_state = { pathViews, pushSimple, viewKey, golfEvent_state }
  const [golfTournament_state, golfTournament_dispatch] = useReducer(golfTournamentReducer, init_state, golfTournamentInitialState)
  const golfTournament_handlers = golfTournamentHandlers(golfTournament_dispatch, golfTournament_state)
  const { golfTournament, golfCourse, matches, details, roundsStatus } = golfTournament_state
  const { rounds: rounds_details, teams: teams_details, lineups: lineups_details, golfers } = details ? details : {}

  console.log('details', details)

  useEffect(
    () => {
      const trueConfig = getFbConfig()
      initFirestore(trueConfig)
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []
  )

  useEffect(() => {
    if (pathViews.golfTournaments) {
      golfTournament_handlers.handleGet_golfTournamentData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    // && golfCourse
    if (golfTournament && rounds_details && teams_details) {
      golfTournament_handlers.setGolfInit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [golfTournament, matches, rounds_details, golfCourse, teams_details, pathViews.golfTournaments]);

  useEffect(
    () => {
      // if (updateStuff) { golfTournament_handlers.handleFix_golfTournament() }
      // if (updateStuff2) { golfTournament_handlers.handleFix_golfersTournamentHandicaps() }
      // if (updateStuff3) { golfTournament_handlers.handleFix_golfersTournament() } 
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [golfers]
  )

  useEffect(
    () => {
      // if (updateStuff3) { golfTournament_handlers.handleFix_golfTournamentFromMatches(matches) }
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [matches]
  )

  //  golfCourse && 
  if (golfTournament && rounds_details && teams_details && lineups_details && roundsStatus) {
    const appStaticViews = createAppStaticViews(golfTournament_state, viSubKeys, aps_viewItems)
    return <GolfTournamentContext.Provider value={{ golfTournament_handlers, golfTournament_state, appStaticViews }}>
      {props.children}
    </GolfTournamentContext.Provider>
  } else {
    return <GolfTournamentContext.Provider value={{ golfTournament_handlers }}>
      {props.children}
    </GolfTournamentContext.Provider>
  }
};

export default GolfTournamentProvider

