import _ from 'lodash'
import { getStaticName } from '../../common/convert'
import { createKeys } from '../../common/creating'
import { gEnums } from "../../enums/globalEnums"
import { arrayLinkingPreview } from '../../firestoreData/updates/arrays/arrayUpdatePreview'
import { arrayUpdateToDatabase } from '../../firestoreData/updates/arrays/arrayUpdates'
import { dataModificationTypes } from '../../viewSettings/enums/itemActionTypes'
import { handleAnyPaste } from '../../viewSettings/importing/pasteHelpers'
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths'
import { fs_get_collection, fs_update_doc } from '../../firestoreData/appData/fsData'
import { fs_get_data } from '../../firestoreData/appData/fsAppData'
import { copyObj } from '../../common_web/copy'

const rts = {
  initializeDataLinking: 'initializeDataLinking',
  handleSelectLeft: 'handleSelectLeft',
  handleSelectRight: 'handleSelectRight',
  handleSelectAll: 'handleSelectAll',
  handleShowPaste: 'handleShowPaste',
  handleShowPreview: 'handleShowPreview',
  handleShowSelected: 'handleShowSelected',
  handleQuantity: 'handleQuantity',
  handleUpdateLinkItem: 'handleUpdateLinkItem',
  handleUpdateLinkItems: 'handleUpdateLinkItems',
  handleUpdateLinksOnly: 'handleUpdateLinksOnly',
  handleLeftItemChange: 'handleLeftItemChange',
  handlePaste: 'handlePaste',
  handleRightItemChange: 'handleRightItemChange',
  handleLinkItemChange: 'handleLinkItemChange',
  handleLinkTableLinks: 'handleLinkTableLinks',
  handleSetLinkTableLinks: 'handleSetLinkTableLinks',
  ...grts
}

const modifiedSv = (sv, sortProp) => {
  createKeys(sv)
  return _.sortBy(sv, [sortProp ? sortProp : 'name'])
}

export const dataLinkingReducer = (state, action) => {

  const {
    _uiData,
    appStaticViews,
    connectWith,
    dataLists,
    dataModificationsContext,
    globalCollection,
    globals,
    item_state,
    linkDataList,
    linkedItems,
    collectionItems,
    page_fns,
    paps_state,
    selectedKeyLeft,
    sortProp,
    staticViews,
    useGlobalData,
    view,
    viewItems_page,
    viewKey,
  } = state

  const _collectionItems = { ...collectionItems }
  const _dataLists = { ...dataLists }
  const _linkedItems = { ...linkedItems }

  const { type, dispatch } = action

  const { handleSetLinkTableLinks } = dataLinkingHandlers(dispatch)

  const { dataModifications_handlers } = dataModificationsContext ? dataModificationsContext : {}

  console.log('action', action)

  switch (type) {

    case rts.initializeDataLinking:

      const lps = {
        dataModificationsContext,
        item_state,
        staticViews,
        view,
        viewKey
      }

      const _linkProps = getLinkProps(lps)

      const gdp = { _uiData, viewKey, linkProps: _linkProps, viewItems_page }
      const dataLinkingProps = getDataProps(gdp)
      let _selectedKeyLeft;

      let oks = [];
      let pr;

      const svProps = {
        appStaticViews,
        connectWith,
        globalCollection,
        globals,
        sortProp,
        staticViews,
        useGlobalData,
      }

      dataLists.left = getStaticList(svProps, collectionItems.left)
      dataLists.right = getStaticList(svProps, collectionItems.right)

      if (dataModificationsContext) {
        _linkedItems.right = getInitRightLinkedItems(_uiData, collectionItems, viewKey)
        const ss = { ...state, linkedItems: _linkedItems, selectedKeyLeft }
        pr = collectionItems.right ? arrayLinkingPreview(ss) : null
        _selectedKeyLeft = viewKey
      }

      if (viewKey) {
        _linkedItems.left = [viewKey]
      }

      return {
        ...state,
        selectedKeyLeft: _selectedKeyLeft,
        originalKeys: oks,
        linkedItems: _linkedItems,
        dataLinkingProps,
        linkProps: _linkProps,
        ...pr
      }

    case rts.handleSelectAll:
      const checkAll = action.item.checked
      if (_dataLists && _dataLists.right) {
        _dataLists.right.forEach(item => {
          const { key: itemKey } = item
          _linkedItems.right[selectedKeyLeft][itemKey] = { selected: checkAll }
        })
      }
      return { ...state, linkedItems: _linkedItems }

    case rts.handleSelectLeft:
      const { item } = action
      const { key: skl, checked } = item ? item : {}
      _linkedItems.left = {
        [skl]: { selected: checked }
      }
      return { ...state, selectedKeyLeft: checked ? skl : null, linkedItems: _linkedItems }

    case rts.handleSelectRight:
      let rightCount = 0
      if (selectedKeyLeft) {
        const rs = linkedItems.right[selectedKeyLeft] ? linkedItems.right[selectedKeyLeft] : []
        const _skr = getSelectedKeys(dataLists.right, rs, action.item, true, action.all, state.allChecked)
        _linkedItems.right[selectedKeyLeft] = _skr
        const _selected = _.filter(_skr, { selected: true })
        rightCount = _selected.length
      }
      const s = { ...state, linkedItems: _linkedItems, selectedKeyLeft }
      return { ...state, linkedItems: _linkedItems, ...arrayLinkingPreview(s), allChecked: !state.allChecked, rightCount }

    case rts.handleQuantity:
      const { menuListItem } = action
      const { key: key_menuListItem } = menuListItem ? menuListItem : {}
      const _itemQ = _.find(linkDataList, { key: key_menuListItem })
      return { ...state, itemQ: _itemQ }

    case rts.handleShowPaste:
      return { ...state, showPaste: !state.showPaste }

    case rts.handleShowPreview:
      return { ...state, showPreview: !state.showPreview }

    case rts.handleShowSelected:
      return { ...state, showSelected: !state.showSelected }

    case rts.handleUpdateLinkItem:
      dataModifications_handlers.handleUpdateLinkItem(state.actionItem)
      return { ...state }

    case rts.handleUpdateLinksOnly:

      const cbx = () => {
        console.log('DONE')
      }

      const linkedData = state.preview
      const linkedItemz = getLinkedItems(linkedData, state)

      if (linkedItemz) {
        Object.keys(linkedItemz).forEach(liKey => {
          const _refPath = createRefPath_event(paps_state.pathViews, ['_dataLinks', liKey])
          fs_update_doc(_refPath, linkedItemz[liKey], cbx)
        })
      }

      return { ...state }

    case rts.handleUpdateLinkItems:
      const _preview = action.selectedOnly ? state.previewLeft : state.preview
      const uProps = {
        dispatch,
        dataActionType: gEnums.itemLinkTypes.linkAllItems,
        logging: state.logging,
        page_fns,
        paps_state,
        preview: _preview,
      }
      arrayUpdateToDatabase(uProps)
      return { ...state }

    case rts.handleLeftItemChange:
      if (staticViews && staticViews[action.item]) {
        _dataLists.left = modifiedSv(staticViews[action.item], sortProp)
        const useNames = _dataLists && _dataLists.left && _dataLists.left[0] && _dataLists.left[0].lastName ? true : false
        const orders = useNames ? ['lastName'] : ['name']
        _dataLists.left = _.orderBy(dataLists.left, orders)
      }
      _collectionItems.left = action.item
      return { ...state, collectionItems: _collectionItems, dataLists: _dataLists }

    case rts.handleRightItemChange:

      if (staticViews && staticViews[action.item]) {
        _dataLists.right = modifiedSv(staticViews[action.item], sortProp)
        const useNames = _dataLists && _dataLists.right && _dataLists.right[0] && _dataLists.right[0].lastName ? true : false
        const orders = useNames ? ['lastName'] : ['name']
        _dataLists.right = _.orderBy(_dataLists.right, orders)
      }

      const _item_right = action.item
      _collectionItems.right = _item_right
      _linkedItems.right = {}
      getInitRightFromData(_uiData, _collectionItems, _linkedItems)

      const sss = { ...state, collectionItems: _collectionItems, linkedItems: _linkedItems, selectedKeyLeft }
      return { ...state, dataLists: _dataLists, collectionItems: _collectionItems, linkedItems: _linkedItems, ...arrayLinkingPreview(sss) }

    case rts.handleLinkItemChange:
      return { ...state, linkItem: action.linkItem }


    case rts.handleLinkTableLinks:
      getLinkTableLinks(state, action.linkCollection, handleSetLinkTableLinks)
      return { ...state }

    case rts.handleSetLinkTableLinks:
      const ss = { ...state, linkedItems: action.linkedItems, selectedKeyLeft }
      return { ...state, linkedItems: action.linkedItems, ...arrayLinkingPreview(ss) }
    // return { ...state, preview: action.preview, previewLeft: action.preview, linkedItems: action.linkedItems }


    case rts.handlePaste:
      const parsed = handleAnyPaste(action.item)
      const { left: item_left, right: item_right } = _collectionItems

      const sv_left = staticViews && staticViews[item_left]
      const { itemData } = parsed ? parsed : {}
      const sv_right = staticViews && staticViews[item_right]

      const lks = { left: [], right: {} }

      // const xxx = {
      //   [item_left]: {},
      //   [item_right]: {},
      // }

      if (itemData && sv_left && sv_right) {
        Object.keys(itemData).forEach(key => {
          const item = itemData[key]
          const { lastName, firstName } = item
          const key_left = _.findKey(sv_left, function (svi) {
            return svi.lastName === lastName && svi.firstName === firstName;
          })
          if (key_left) {
            Object.keys(item).forEach(propKey => {
              const key_right = _.findKey(sv_right, function (svi) {
                return _.camelCase(svi.name) === propKey;
              })
              if (key_right) {
                if (!lks.right[key_left]) { lks.right[key_left] = {} }
                lks.right[key_left][key_right] = { selected: true }

                const dll = state._uiData
                const dlls = dll[key_left]
                if (dlls) {
                  const { guests } = dlls
                  if (guests) {
                    guests.forEach(g => {
                      if (!lks.right[g]) { lks.right[g] = {} }
                      lks.right[g][key_right] = { selected: true }
                    })
                  }
                }
              }
            })
          }
        })
      }

      const aaa = { ...state, collectionItems: _collectionItems, linkedItems_right: lks.right, selectedKeyLeft }
      return { ...state, showPaste: false, linkedItems: lks, ...arrayLinkingPreview(aaa) }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })


    default:
      return { ...state }
  }
}

export const dataLinkingInitialState = (initState) => {
  return { ...initState, preview: null, dataLists: { left: null, right: null }, linkedItems: { left: null, right: null }, }
};

export const dataLinkingHandlers = (dispatch) => {
  return {
    initializeDataLinking: () => { dispatch({ type: rts.initializeDataLinking, dispatch }) },
    handleSelectLeft: (item) => { dispatch({ type: rts.handleSelectLeft, dispatch, item }) },
    handleSelectRight: (item, all) => { dispatch({ type: rts.handleSelectRight, dispatch, item, all }) },
    handleSelectAll: (e, item, checked) => { dispatch({ type: rts.handleSelectAll, dispatch, item, checked }) },
    handleShowPaste: () => { dispatch({ type: rts.handleShowPaste, dispatch }) },
    handleShowPreview: () => { dispatch({ type: rts.handleShowPreview, dispatch }) },
    handleShowSelected: () => { dispatch({ type: rts.handleShowSelected, dispatch }) },
    handleQuantity: (menuListItem, value) => { dispatch({ type: rts.handleQuantity, dispatch, menuListItem, value }) },
    handleUpdateLinkItem: () => { dispatch({ type: rts.handleUpdateLinkItem, dispatch }) },
    handleUpdateLinkItems: (selectedOnly) => { dispatch({ type: rts.handleUpdateLinkItems, dispatch, selectedOnly }) },
    handleLeftItemChange: (item) => { dispatch({ type: rts.handleLeftItemChange, dispatch, item }) },
    handlePaste: (item) => { dispatch({ type: rts.handlePaste, dispatch, item }) },
    handleRightItemChange: (item) => { dispatch({ type: rts.handleRightItemChange, dispatch, item }) },
    handleLinkItemChange: (linkItem) => { dispatch({ type: rts.handleLinkItemChange, dispatch, linkItem }) },
    handleUpdateLinksOnly: (item) => { dispatch({ type: rts.handleUpdateLinksOnly, dispatch, item }) },
    handleLinkTableLinks: (linkCollection) => { dispatch({ type: rts.handleLinkTableLinks, dispatch, linkCollection }) },
    handleSetLinkTableLinks: (preview, linkedItems) => { dispatch({ type: rts.handleSetLinkTableLinks, dispatch, preview, linkedItems }) },
    ...responseHandlers(dispatch)
  }
}

// const getActionItem = (state, selected_items, selectedKey, linkValue) => {
//   const { _dataLinking, view, viewKey, viewDataItem, allowMultiSelect, dataLinkType, dataModificationType, fromData, fromPage, originalKeys } = state
//   const _viewItemKeys = getViewKeys(state, selected_items)
//   const actionProps = {
//     allowMultiSelect,
//     dataLinkType,
//     deleteKeys: getDeleteKeys(state, _viewItemKeys),
//     linkedData: fromData,
//     linkValue,
//     dataModificationType,
//     originalKeys,
//     linkData: {
//       _view: {
//         name: view,
//         key: viewKey
//       },
//       _viewItem: {
//         name: fromPage,
//         keys: _viewItemKeys
//       },
//     },
//     viewDataItem,
//     viewItemKey: selectedKey,
//     vl: _dataLinking,
//   }
//   const actionItem = {
//     actionProps,
//     dataActionType: gEnums.itemLinkTypes.collectionItems
//   }
//   return actionItem
// }

// const getViewKeys = (state, selected_items) => selected_items

// const getDeleteKeys = (state, viewItemKeys) => {
//   const { originalKeys } = state
//   const deleteKeys = []
//   if (originalKeys) {
//     originalKeys.forEach(oKey => {
//       if (viewItemKeys) {
//         if (!viewItemKeys.includes(oKey)) {
//           deleteKeys.push(oKey)
//         }
//       }
//     })
//   }
//   return deleteKeys
// }

const getLinkedItems = (linkedData, state) => {
  const linkedItems = {}
  const linkedKeys = Object.keys(linkedData)
  Object.keys(linkedData).forEach(lKey => {
    // linkedItems[attendees]
    linkedItems[lKey] = {}
    const _linkedItems = state.preview[lKey] // attendees  
    let targetLinkItem;
    linkedKeys.forEach(lk => {
      if (lk !== lKey) {
        targetLinkItem = lk
      }
    })
    if (_linkedItems) {
      Object.keys(_linkedItems).forEach(liKey => {
        const linkItem = _linkedItems[liKey]
        const linkItemSub = linkItem[targetLinkItem]
        if (linkItemSub) {
          const linkSubs = Object.keys(linkItemSub)
          if (!linkedItems[lKey][liKey]) { linkedItems[lKey][liKey] = {} }
          if (!linkedItems[lKey][liKey][targetLinkItem]) { linkedItems[lKey][liKey][targetLinkItem] = [] }
          linkedItems[lKey][liKey][targetLinkItem] = linkSubs
        }
      })
    }
  })
  return linkedItems
}

const getLinkProps = (props) => {

  const {
    dataModificationsContext,
    item_state,
    staticViews,
    view,
    viewKey,
  } = props

  let allowMultiSelect;

  const { viewItem, linkFrom } = item_state ? item_state : {}
  const { key: key_viewItem, dataModifications } = viewItem ? viewItem : {}

  const { connections, dataLinking } = dataModifications ? dataModifications : {}
  const { connectWith, pageLinkProps: pageLinkProps_connect } = connections ? connections : {}
  const { pageLinkProps, allowQuantity, maxQuantity } = dataLinking ? dataLinking : {}

  const { dataModifications_state } = dataModificationsContext ? dataModificationsContext : {}
  const { dataModificationType } = dataModifications_state ? dataModifications_state : {}

  let _linking;

  switch (dataModificationType) {
    case dataModificationTypes.dataLinking:
      _linking = { to: view, toKey: viewKey, from: linkFrom, pageLinkProp: pageLinkProps ? pageLinkProps : ['name'], allowQuantity, maxQuantity, }
      allowMultiSelect = true
      break;
    case dataModificationTypes.connections:
      _linking = { to: view, toKey: viewKey, from: connectWith, pageLinkProp: pageLinkProps_connect ? pageLinkProps_connect : ['name'], }
      allowMultiSelect = true
      break;
    default:
      _linking = { to: null, toKey: null, from: null, pageLinkProp: null, }
    // nothing
  }

  const linkProps = {
    fromPage: _linking.from ? _linking.from : key_viewItem,
    toPage: _linking.to,
    toKey: _linking.toKey,
    toKeyCaption: _linking.toKey,
    allowQuantity,
    maxQuantity,
    allowMultiSelect,
  }

  if (staticViews && staticViews[linkProps.toPage] && staticViews[linkProps.toPage][linkProps.toKey]) {
    const sv = getStaticName(staticViews, linkProps.toPage, linkProps.toKey)
    linkProps.toKeyCaption = sv
  }

  return linkProps

}

const getDataProps = (props) => {

  const { _uiData, viewKey, linkProps, viewItems_page } = props ? props : {}
  const { fromPage } = linkProps ? linkProps : {}

  const cd = _uiData && viewKey && _uiData[viewKey] ? _uiData[viewKey] : {}
  const fromData = cd && fromPage && cd[fromPage] ? cd[fromPage] : []
  const viewItem_from = viewItems_page && viewItems_page[fromPage] ? viewItems_page[fromPage] : {}
  const { dataSource, display, dataConstraints } = viewItem_from ? viewItem_from : {}
  const _dataConstraints = dataConstraints ? dataConstraints : dataSource
  // dataLinkType not is nuse
  const { dataLinkType, pageConstraintType } = _dataConstraints ? _dataConstraints : {}
  const { sortProp } = display ? display : {}

  return {
    dataLinkType,
    fromData,
    pageConstraintType,
    sortProp,
  }

}

const getSelectedKeys = (list, selectedKeys, item, allowMultiSelect, all, itemChecked) => {

  const { key: itemKey } = item ? item : {}
  const _item = itemKey ? _.find(list, { key: itemKey }) : null
  let _selectedKeys = { ...selectedKeys }

  if (_item) {
    const selectedKey = _item.key
    if (allowMultiSelect) {
      if (_selectedKeys[selectedKey]) {
        _selectedKeys[selectedKey].selected = !_selectedKeys[selectedKey].selected
      } else {
        if (!_selectedKeys[selectedKey]) { _selectedKeys[selectedKey] = {} }
        _selectedKeys[selectedKey].selected = true
      }
    } else {
      _selectedKeys = selectedKey
    }
    return _selectedKeys
  } else {
    if (all) {
      if (list) {
        list.forEach(item => {
          const { key } = item
          if (!_selectedKeys[key]) { _selectedKeys[key] = {} }
          _selectedKeys[key].selected = !itemChecked
        })
      }
      return _selectedKeys
    }
    return {}
  }
}

const getStaticList = (props, svKey) => {

  let list;

  const {
    appStaticViews,
    connectWith,
    globalCollection,
    globals,
    sortProp,
    staticViews,
    useGlobalData,
  } = props

  if (connectWith) {
    list = modifiedSv(staticViews[connectWith], sortProp)
  } else {
    if (useGlobalData && globalCollection && globals[globalCollection]) {
      list = globals
    } else {
      if (appStaticViews && appStaticViews[svKey]) {
        list = modifiedSv(appStaticViews[svKey], sortProp)
      } else if (staticViews && staticViews[svKey]) {
        list = modifiedSv(staticViews[svKey], sortProp)
      }
    }
  }

  const useNames = list && list[0] && list[0].lastName ? true : false
  const orders = useNames ? ['lastName'] : ['name']

  return list ? _.orderBy(list, orders) : []

}

const getInitRightLinkedItems = (uiData, collectionItems, viewKey) => {

  const { left } = collectionItems

  const _linkedItems = {}
  const _skss = {}

  if (uiData && left) {
    Object.keys(uiData).forEach(key => {
      const itemData = uiData[key]
      if (itemData[left] && _.isArray(itemData[left])) {
        if (itemData[left].includes(viewKey)) {
          _skss[key] = { selected: true }
        }
      }
    })
    _linkedItems[viewKey] = _skss
  }

  return _linkedItems
}

const getInitRightFromData = (uiData, collectionItems, linkedItems) => {
  const { right: itemRight } = collectionItems
  if (uiData) {
    Object.keys(uiData).forEach(rightKey => {
      const ud = uiData[rightKey]
      if (ud[itemRight] && _.isArray(ud[itemRight])) {
        ud[itemRight].forEach(leftKey => {
          if (!linkedItems.right[rightKey]) { linkedItems.right[rightKey] = {} }
          linkedItems.right[rightKey][leftKey] = { selected: true }
        })
      }
    })
  }
}

const getLinkTableLinks = (state, linkCollection, callback) => {
  const { collectionItems, pathViews } = state
  const { left, right } = collectionItems ? collectionItems : {}

  const datas = {}

  const cb = (d, opts) => {
    const { collectionName } = opts
    datas[collectionName] = d

    if (datas['left'] && datas['right'] && datas['link']) {
      const { _preview, _linkedItems } = convertMatchedColumnNames(datas, state, callback)
      callback(_preview, _linkedItems)
    }
  }

  if (left && right && linkCollection) { // activities

    const _refPath_left = createRefPath_event(pathViews, [left])
    const _refPath_right = createRefPath_event(pathViews, [right])
    const _refPath_link = createRefPath_event(pathViews, [linkCollection])

    fs_get_data({ refPath: _refPath_left, callback: cb, opts: { cbProps: { collectionName: 'left' } } })
    fs_get_data({ refPath: _refPath_right, callback: cb, opts: { cbProps: { collectionName: 'right' } } })
    fs_get_data({ refPath: _refPath_link, callback: cb, opts: { cbProps: { collectionName: 'link' } } })

  }
}

const convertMatchedColumnNames = (datas, state) => {

  const { preview, linkedItems, collectionItems } = state
  const { left, right } = collectionItems ? collectionItems : {}

  const _preview = copyObj(preview)
  const _linkedItems = copyObj(linkedItems)

  const { left: leftItems, right: rightItems, link: linkItems } = datas

  Object.keys(linkItems).forEach(linkKey => {

    const linkItem = linkItems[linkKey]
    const { email } = linkItem ? linkItem : {}

    const _rightItem = _.find(rightItems, function (ri) { return (ri.email === email) || (ri.eMail === email) })
    const _rightItemKey = _.findKey(rightItems, function (ri) { return (ri.email === email) || (ri.eMail === email) })

    if (_rightItem && _rightItemKey) {
      Object.keys(linkItem).forEach(linkProp => {

        const _linkProp = _.camelCase(linkProp).toLowerCase()

        const _linkItemValue = linkItem[linkProp]
        if (_linkItemValue === '1' || _linkItemValue === true) {
          Object.keys(leftItems).forEach(liKey => {
            const _leftItem = leftItems[liKey]
            const { name } = _leftItem

            const _name = _.camelCase(name).toLowerCase()

            if (_name === _linkProp) {

              if (!_linkedItems.right) { _linkedItems.right = [] }
              if (!_linkedItems.right[liKey]) { _linkedItems.right[liKey] = {} }
              _linkedItems.right[liKey][_rightItemKey] = { selected: true }

              if (!_preview[left][liKey]) { _preview[left][liKey] = {} }
              if (!_preview[left][liKey][right]) { _preview[left][liKey][right] = {} }
              _preview[left][liKey][right][_rightItemKey] = { selected: true }


              if (!_preview[right][_rightItemKey]) { _preview[right][_rightItemKey] = {} }
              if (!_preview[right][_rightItemKey][left]) { _preview[right][_rightItemKey][left] = {} }
              _preview[right][_rightItemKey][left][liKey] = { selected: true }

            }
          })
        }
      })
    }
  })

  return { _preview, _linkedItems }

}


// const getAllItems = (data) => {
//   const all = {}
//   Object.keys(data).forEach(key => {
//     const item = data[key]
//     const realKey = item.id ? item.id : key
//     all[realKey] = {}
//     Object.keys(item).forEach(itemKey => {
//       if (item[itemKey] && itemKey !== 'id') {
//         all[realKey][_.camelCase(itemKey)] = item[itemKey]
//       }
//     })
//   })
//   return all
// }