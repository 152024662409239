import React, { useContext } from 'react'
import { Dimmer, Icon, Message, Modal, Segment } from 'semantic-ui-react'
import { AppSettingsContext } from '../cnr/contexts/AppSettingsContext'
import { FrameworkContext } from '../cnr/contexts/FrameworkContent'
import { cnnAdd, cnPlus, cn_s, cn_snp } from '../common/cns'
import { uniqueKey } from '../common/keys'
import { DimmerWrap } from '../components/alerts/DimmerWrap'
import { appIconTypes } from '../enums/appIconTypes'
import { gEnums } from '../enums/globalEnums'
import GenericIcon from '../genericControls/GenericIcon'
import MotionComponent from '../motions/MotionComponent'
import { H2Topper } from './TopperWrappers'
import TouchWrapper from './TouchWrapper'
import NotificationMessage from '../components/auth/signInComponents/NotificationMessage'

export const wrapperTypes = {
  linking: 'linking',
  noContent: 'noContent',
  padded: 'padded',
  paddedContent: 'paddedContent',
  paddedFooter: 'paddedFooter',
  paddedHeader: 'paddedHeader',
  paddedHeaderAndFooter: 'paddedHeaderAndFooter',
  pending: 'pending',
  split: 'split',
  splitLR: 'splitLR',
  splitHorz: 'splitHorz',
  splitVert: 'splitVert',
  upload: 'upload',
}

const Wrapper = (props) => {

  // const paokeWrapper = () => <PaokeWrapper
  //   {...props}
  // />

  // return paokeWrapper()

  const {
    asSegment,
    bottom,
    cnAlt,
    confirmation,
    confirmationNoModal,
    content,
    css,
    dt,
    footer,
    footer2,
    fullMode,
    fullWebPage,
    halfModal,
    handleBack,
    handleBackCaption,
    handleSwipedRight,
    header,
    inverted,
    manifest,
    modal,
    navigation,
    notification,
    notFull,
    path,
    popup,
    progress,
    containerRef,
    style,
    top,
    topper,
    updating,
    updatingCaption,
    uploader,
    transition_fpw,
    usePlaceholder,
    wrapper,
    wrapperCss,
    wrapperType,
  } = props

  const _css = wrapperCss ? wrapperCss : css

  const {
    container: css_container,
    header: css_header,
    navigation: css_navigation,
    notification: css_notification,
    content: css_content,
    footer: css_footer,
    footer2: css_footer2
  } = _css ? _css : {}

  const frameworkContext = useContext(FrameworkContext); const { framework_state } = frameworkContext ? frameworkContext : {}
  const { appDimensions, isMobileDevice, mediaDeviceType } = framework_state ? framework_state : {}
  let { width, height } = appDimensions ? appDimensions : {}

  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ? appSettingsContext : {}
  const { currentDevice } = appSettings_state ? appSettings_state : {}
  const { msettings } = currentDevice ? currentDevice : {}

  const buttonProps_cancel = { color: 'black', inverted: true }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />
  const cancelButton = () => gi('btn-cancel', appIconTypes.delete, handleBack, null, { ...buttonProps_cancel, nb: false, nf: true })

  const getHeader = () => {
    if (header) {
      return header
    } else if (handleBack) {
      return <H2Topper
        leftContent={cancelButton()}
        rightContent={handleBackCaption ? handleBackCaption : 'Edit'}
      ></H2Topper>
    } else {
      return null
    }
  }

  if (msettings && msettings.w) { width = msettings.w }
  if (msettings && msettings.h) { height = msettings.h }

  if (halfModal && height) { height = height / 2 }

  const modalStyle = isMobileDevice ? { height: ' 100%', width: ' 100%' } : { height, width }

  let cssT = cnPlus(css_container)
  let cssH = cnPlus(css_header)
  let cssO = cnPlus(css_notification)
  let cssN = cnPlus(css_navigation)
  let cssC = cnPlus(css_content)
  let cssF = cnPlus(css_footer)
  let cssF2 = cnPlus(css_footer2)

  cssT = cnnAdd(cssT, wrapperType)

  let prefix = ' ';

  const _header = getHeader()

  if (topper) { prefix += 't' }
  if (_header) { prefix += 'h' }
  if (navigation) { prefix += 'n' }
  if (notification) { prefix += 'o' }
  if (path) { prefix += 'p' }
  if (uploader) { prefix += 'u' }
  if (progress) { prefix += 'r' }
  if (top) { prefix += 't' }
  if (content) { prefix += 'c' }
  if (manifest) { prefix += 'm' }
  if (bottom) { prefix += 'b' }
  if (footer) { prefix += 'f' }
  if (footer2) { prefix += 'f' }

  let cn = dt ? 'dt-container' : 'vert-container'
  if (notFull) { cn += ' nf' }

  if (asSegment) {
    cssT += ' ui segments'
    cssH += cn_s(inverted)
    cssN += cn_s(inverted)
    cssO += cn_s(inverted)
    cssC += cn_snp(inverted)
    cssF += cn_s(inverted)
    cssF2 += cn_s(inverted)
  }

  const notificationDiv = (n) => <NotificationMessage notification={notification} />

  const main = <React.Fragment>
    {topper && <div key={uniqueKey('wr', 't')} className={'topper' + cssH}>{topper}</div>}
    {_header && <div key={uniqueKey('wr', 'h')} className={'header' + cssH} >{_header}</div>}
    {notification && <div key={uniqueKey('wr', 'o')} className={'notification' + cssO}>{notificationDiv(notification)}</div>}
    {navigation && <div key={uniqueKey('wr', 'n')} className={'navigation' + cssN}>{navigation}</div>}
    {path && <div key={uniqueKey('wr', 'p')} className={'path' + cssN}>{path}</div>}
    {uploader && <div key={uniqueKey('wr', 'u')} className={'uploader' + cssN}>{uploader}</div>}
    {progress && <div key={uniqueKey('wr', 'r')} className={'progress' + cssN}>{progress}</div>}
    {top && <div key={uniqueKey('wr', 'o')} className={'top' + cssC}>{top}</div>}
    {content && <div key={uniqueKey('wr', 'c')} className={'content' + cssC}>{content}</div>}
    {bottom && <div key={uniqueKey('wr', 'bt')} className={'bottom' + cssC}>{bottom}</div>}
    {footer && <div key={uniqueKey('wr', 'f')} className={'footer' + cssF}>{footer}</div>}
    {footer2 && <div key={uniqueKey('wr', 'ff')} className={'footer2' + cssF2}>{footer2}</div>}
  </React.Fragment>

  let sty = style ? style : {}
  let cn_modal = 'modal-full'

  switch (mediaDeviceType) {
    case gEnums.mediaDeviceTypes.desktop:
      if (fullMode) { cn_modal += ' modal-wide' }
      break;
    default:
    // nothing
  }

  if (fullWebPage) { cn_modal += ' full-web-page' }

  if (cnAlt) { cn += ' ' + cnAlt }

  let _updating = updating

  const dimmerWrap = () => <DimmerWrap confirmation={confirmation} confirmationNoModal={confirmationNoModal} caption={updatingCaption} />

  const modalModal = () => <Modal
    style={modalStyle}
    open={true}
    className={cn_modal}
  >
    {transition_fpw && transition_fpw.showTransition ?
      <MotionComponent transition={transition_fpw}>
        {_updating && dimmerWrap()}
        {main}
        {popup && popup}
      </MotionComponent>
      :
      <div className={cn + prefix + cssT} style={sty}>
        {_updating && dimmerWrap()}
        {main}
        {popup && popup}
      </div>
    }
  </Modal>

  const wrapperWrapper = () => <div className={cn + prefix + cssT} style={sty}>
    <div className={wrapper} ref={containerRef}>
      {_updating && dimmerWrap()}
      {main}
      {popup && popup}
    </div>
  </div>

  const noWrapper = () => <Dimmer.Dimmable className={cn + prefix + cssT} style={sty}>
    {_updating && dimmerWrap()}
    {main}
    {popup && popup}
  </Dimmer.Dimmable>

  const wrapperPlaceholder = (content) => <Segment placeholder basic>
    {content}
  </Segment>

  const wrapperContent = () => {
    if (modal) {
      if (handleSwipedRight) {
        return <TouchWrapper handleSwipedRight={handleSwipedRight}>
          {modalModal()}
        </TouchWrapper>
      } else {
        return modalModal()
      }
    } else {
      if (wrapper) {
        return wrapperWrapper()
      } else {
        if (usePlaceholder) {
          return wrapperPlaceholder(noWrapper())
        } else {
          return noWrapper()
        }
      }
    }
  }

  return wrapperContent()

}

export default Wrapper