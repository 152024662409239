import { getDatabase, onValue, ref } from "firebase/database";
import { ammendDataWithStaticViewValues } from "../../common/convert";
import { gEnums } from '../../enums/globalEnums';
import { getRefProps } from "../helpers/getRefProps";
import { pageDataValues } from "../helpers/pageDataValues";
import { fs_get_data } from './fsAppData';

/**
 * Gets the data for the page
 * @param {object} pageDataProps 
 * @param {function} callback 
 */
export const getPageData = async (pageDataProps, callback) => {

  const {
    appUserAccess,
    data_state,
    googleData_state,
    isPageData,
    page_state,
    paps_state,
    page_fns,
    staticViews,
    view,
    viewKey,
  } = pageDataProps

  const { pageSettings } = page_state
  const { aps_page, aps_global, aps_viewItems } = pageSettings
  const { menu: menu_page, viewItems: viewItems_page } = aps_page ? aps_page : {}
  const { captionProps, caption: caption_page, subCaptionProps } = menu_page ? menu_page : {}

  const { googleDataOn, googleSheetsData } = googleData_state ? googleData_state : {}

  let viewItem_global;
  let viewItem_page = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  if (aps_viewItems && aps_viewItems[view]) { viewItem_global = aps_viewItems[view] }

  const { nonLandingView, pathViews } = paps_state ? paps_state : {}
  const { dataOptions, googleSheets } = aps_global ? aps_global : {}
  const { dataLimit, limitDevelopmentData, hideInactiveItems: hideInactiveItems_do } = dataOptions ? dataOptions : {}
  const { googleSheetsId } = googleSheets ? googleSheets : {}

  const grpp = {
    stateProps: {
      appUserAccess,
      aps_viewItems,
      dataLimit,
      googleSheetsId,
      hideInactiveItems_do,
      limitDevelopmentData,
      nonLandingView,
      pathViews,
      staticViews,
    },
    page_fns,
    uivi: view,
    view,
    viewItem_global,
    viewItem: viewItem_page,
    viewKey,
  }

  const refProps = getRefProps(grpp)

  // firestoreRefs
  let { refPath, refPaths, wheres } = refProps
  const { dataConnection } = viewItem_global ? viewItem_global : {}

  const { firebaseType, useGoogleSheetRealtime, documentDependencies } = dataConnection ? dataConnection : {}
  const trueDbType = useGoogleSheetRealtime ? gEnums.firebaseTypes.realtimeDatabase : firebaseType

  const _dataResultProps = { isPageData, view, viewItem_page, data_state, captionProps, subCaptionProps, caption_page, staticViews }

  const dataCallback = (res, a, fsr) => {
    ammendDataWithStaticViewValues(staticViews, res)
    callback(pageDataValues(res, _dataResultProps))
  }

  switch (view) {
    case 'home':
      callback(pageDataValues({}, _dataResultProps))
      break;

    default:
      switch (trueDbType) {
        case gEnums.firebaseTypes.realtimeDatabase:
          const db = getDatabase();
          const _ref = ref(db, refPath);
          onValue(_ref, (snapshot) => {
            let resultData = snapshot
            callback(pageDataValues(resultData, _dataResultProps))
          })
          break;
        default:
          if (googleDataOn && googleSheetsData && googleSheetsData[view] && googleSheetsData[view][viewKey]) {
            const d = googleSheetsData[view][viewKey]
            callback(d, _dataResultProps)
          } else {
            if (documentDependencies && documentDependencies.length > 0) {
              callback(pageDataValues(data_state[documentDependencies[0]], _dataResultProps))
            } else {
              fs_get_data({ refPath: refPaths, wheres, callback: dataCallback, opts: { listen: true } })
            }
          }
      }
  }
}
