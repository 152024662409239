import React from 'react'
import { Segment } from 'semantic-ui-react'
import { AddToHomeScreen, installAppTypes } from '../../AddToHomeScreen'
import { AllowNotifications } from '../../AllowNotifications'
import { Disclaimers } from '../../Disclaimers'
import { cn_s, cn_snp, g_cns } from '../common/cns'
import { gEnums } from '../enums/globalEnums'
import AddToHomeScreenTest from '../../AddToHomeScreenTest'

/** Wraps app-container */
const AppWrapper = (props) => {

  const { useAppDarkMode, inverted, showHomeScreenPrompt, showHomeScreenHelp, forceInstall, appInstallation, appSignIn, eventKey, wrapper, banner, banners, menu, content, navigation, footer, css, asSegment, desktopMobileOn, bannersLocation, homePageResponded, appNotifications } = props
  const { container: css_container, header: css_banner, menu: css_menu, content: css_content, navigation: css_navigation, footer: css_footer } = css ? css : {}

  const { allowNotifications, allowPushNotifications } = appNotifications ? appNotifications : {}
  const { allowAppInstallation, allowAutoPromptForAppIntallation } = appInstallation ? appInstallation : {}

  const addToHomeScreenItem = eventKey ? 'appInstallation-' + eventKey : 'appInstallation'
  const homeScreenAsked = window.localStorage.getItem(addToHomeScreenItem)

  let cssT = css_container ? ' ' + css_container : ''
  let cssB = css_banner ? ' ' + css_banner : ''
  let cssM = css_menu ? ' ' + css_menu : ''
  let cssC = css_content ? ' ' + css_content : ''
  let cssN = css_navigation ? ' ' + css_navigation : ''
  let cssF = css_footer ? ' ' + css_footer : ''

  let prefix = ' ';

  if (banner) { prefix += 'b' }
  if (banners) { prefix += 'n' }
  if (menu) { prefix += 'm' }
  if (content) { prefix += 'c' }
  if (navigation) { prefix += 'n' }
  if (footer) { prefix += 'f' }

  if (bannersLocation === gEnums.bannerLocationTypes.top) {
    prefix += ' blt'
  }

  if (cssM) { cssT += ' ' + cssM }

  const mainCn = 'app'
  let cn = g_cns.app_container

  if (desktopMobileOn) { cn += ' dtm' }

  if (asSegment) {
    cssT += ' ui segments'
    cssM += cn_s(inverted)
    cssB += cn_s(inverted)
    cssC += cn_snp(inverted)
    cssN += cn_s(inverted)
    cssF += cn_s(inverted)
  }

  if (useAppDarkMode) {
    cn += ' app-dark-mode'
  } else {
    cn += ' app-light-mode'
  }

  const showAths_auto = allowAppInstallation && allowAutoPromptForAppIntallation && !homePageResponded && !homeScreenAsked
  const showAths = ((allowAppInstallation && showHomeScreenPrompt) || forceInstall)

  const aths = () => <React.Fragment>
    {/* <AddToHomeScreenTest installAppType={installAppTypes.auto} /> */}
    {showAths_auto && <AddToHomeScreenTest installAppType={installAppTypes.auto} />}
    {showAths && <AddToHomeScreenTest installAppType={installAppTypes.manual} forceInstall={forceInstall} />}
    {showHomeScreenHelp && <AddToHomeScreenTest installAppType={installAppTypes.help} />}
  </React.Fragment>

  const main = <React.Fragment>
    {banner && <div className={mainCn + '-banner' + cssB}>{banner}</div>}
    {banners && <div className={mainCn + '-banners' + cssB}>{banners}</div>}
    {menu && <div className={mainCn + '-menu' + cssM}>{menu}</div>}
    {content && <div className={mainCn + '-content' + cssC}>{content}</div>}
    {navigation && <div className={mainCn + '-navigation' + cssN}>{navigation}</div>}
    {footer && <div className={mainCn + '-footer' + cssF}>{footer}</div>}
    {aths()}
    {allowNotifications && allowPushNotifications && <AllowNotifications />}
    {/* <AddToHomeScreenTest /> */}
  </React.Fragment>

  if (wrapper) {
    return <div className={mainCn + 'container' + prefix + cssT}>
      <div className={wrapper}>
        {main}
      </div>
      {main}
    </div>
  } else {
    return <Segment basic className={cn + prefix + cssT} inverted={useAppDarkMode}>
      {main}
    </Segment>
  }
}

export default AppWrapper