import React, { useContext } from 'react';
import { List, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { uniqueKey } from '../../common/keys';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';

const UserEvents = () => {

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { appUser_state, paps_state, eventInfo_state } = states
  const { clientEvents } = eventInfo_state
  const { page_fns } = fns

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { displayName, events: events_appUser, clientAccessKey, activeEvents_appUser } = appUserAccess ? appUserAccess : {}

  // papsContext 
  const { pathViews } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}

  // pageContext 
  const { pushSimple } = page_fns ? page_fns : {}

  let noEventsCaption = !pathViews.clients ? 'You do not have any events. The Client is unknown.' : 'You do not have any events.'
  if (!events_appUser) { noEventsCaption = 'Searching Events' }

  const handleGoToEvent = (e, key) => {
    // clickedItem, itemKey, modifyMode, opts
    if (clientAccessKey) {
      const opts = { directPath: '/clients/' + clientAccessKey + '/events/' + key }
      if (eventKey !== key) {
        pushSimple({ key: 'events' }, key, null, opts)
      }
    } else {
      if (eventKey !== key) {
        pushSimple({ key: 'events' }, key)
      }
    }
  }

  const eventList = () => {
    const lis = []
    if (events_appUser && Object.keys(events_appUser).length > 0) {
      Object.keys(events_appUser).forEach((key, index) => {
        const event = events_appUser[key]
        let { name } = event
        if (!name && clientEvents && clientEvents[key]) {
          name = clientEvents[key].name
        }
        lis.push(
          <List.Item key={uniqueKey('ue', 'li', index)} onClick={(e) => { handleGoToEvent(e, key) }}>
            <List.Icon name='calendar check outline' size='large' verticalAlign='middle' color={eventKey === key ? 'green' : 'grey'} />
            <List.Content>
              <List.Header >{name ? name : key}</List.Header>
              <List.Description as='a'>{event.startDate}</List.Description>
            </List.Content>
          </List.Item>
        )
      })
      return <div key={uniqueKey('ue', 'd')} className={'c-container'} >
        <div className={'c-content'} >
          < List divided relaxed >
            {lis}
          </List ></div>
      </div>
    } else {
      return <div key={uniqueKey('ue', 'd')} className={'c-container'} >
        <div className={'c-content padd'}>{noEventsCaption}</div></div>
    }
  }

  const header = () => displayName ? 'Events for ' + displayName : 'Events'

  return <Wrapper
    header={!eventKey && header()}
    content={<Segment basic>{eventList()}</Segment>}
    wrapperType={wrapperTypes.padded}
  />

}

export default UserEvents