import React, { useContext } from 'react';
import { Image, Menu, Message, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { gEnums } from '../../enums/globalEnums';
import GenericIcon from '../../genericControls/GenericIcon';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';

const ListGrid = (props) => {

  const { selectedItems } = props

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { page_state, transition_state } = states
  const { page_fns, storage_fns } = fns

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_styles, aps_page, aps_viewItems } = pageSettings ? pageSettings : {}
  const { pushSimple, syncViewItems } = page_fns ? page_fns : {}

  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.component] : null
  const { showTransition } = transition ? transition : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}

  const segProps = {}

  const defaultSnC = aps_styles ? aps_styles[gEnums.projectStyles.listItems] : {}

  if (syncViewItems) { syncViewItems(aps_global, aps_viewItems, aps_page) }

  const grids = []

  const handleLabelClick = (viewItem) => pushSimple(viewItem, selectedItems ? null : viewItem.itemKey)

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const itemIcon = (viewItem, segProps) => {
    const { icon, iconImageUrl } = segProps
    if (iconImageUrl) {
      return <Image style={{ margin: 'auto' }} vit={viewItem.key} name={icon} src={iconImageUrl} />
    } else {
      return gi('lg-icon-', icon, null, null, { color: 'blue', nb: true })
    }
  }

  const menuItems = () => {
    return Object.keys(selectedItems).map((key) => {
      const itemData = selectedItems[key]
      const segProps = page_fns.getGVIProps(key, null, storage_fns, desktopMode)
      return <Menu.Item key={uniqueKey('lg', key)}
        onClick={() => { handleLabelClick(itemData) }}
      >
        {itemIcon(itemData, segProps)}
        {segProps.caption}
      </Menu.Item>
    })
  }

  const menu = () => <Menu vertical fluid>
    {menuItems()}
  </Menu>

  const noMains = () => {
    return <Message
      negative={true}
      header={'Settings'}
      content={"The layout type for this view is 'List', but no view items have been set as 'show'. Please check the settings."}
    />
  }

  if (selectedItems && Object.keys(selectedItems).length > 0) {
    grids.push(menu())
  } else {
    grids.push(<Segment {...segProps}>{noMains()}</Segment>)
  }

  const listContent = () => <div {...defaultSnC} >
    {grids}
  </div>

  if (showTransition) {
    return <MotionComponent transition={transition}>
      {listContent()}
    </MotionComponent>
  } else {
    return listContent()
  }

}

export default ListGrid