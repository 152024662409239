import React from 'react';
import { renderHtml } from '../../html/renderHtml';
import Checker from './Checker';

export const checkbox = (fip, captionQuestion) => {
  const { key, label, value } = fip
  const checked = value ? value : false
  delete fip.value

  const handleClick = (e) => {
    const data = { ...fip, value: checked, checked: !checked, type: 'checkbox' }
    fip.onChange(e, data)
  }

  if (captionQuestion) {
    // , border: 'solid 1px lightgrey'
    return <React.Fragment>
      <div style={{ marginBottom: '.5em', padding: '.5em' }}>{renderHtml(captionQuestion)}</div>
      <Checker key={key} item={fip} label={label} handleClick={handleClick} checked={checked} wrapperType={'inline'} />
    </React.Fragment>
  } else {
    return <Checker key={key} item={fip} label={label} handleClick={handleClick} checked={checked} wrapperType={'inline'} />
  }
}

