import _ from 'lodash';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { ammendUiValue, getSplitStaticName } from '../../common/convert';
import { createKeys, createObject } from '../../common/creating';
import { filterObject } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { sortObject } from '../../common/sorting';
import { gEnums } from '../../enums/globalEnums';
import { getThemeColorDirect } from '../../styles/formatting';
import { _settingsFs } from '../actions/getSettings';

export const isMajorView = (appArea) => {
  switch (appArea) {
    case gEnums.appAreaTypes.client:
    case gEnums.appAreaTypes.club:
    case gEnums.appAreaTypes.event:
    case gEnums.appAreaTypes.league:
    case gEnums.appAreaTypes.school:
      return true
    default:
      return false
  }
}

/**
 * 
 * @param {object} props (
 * _keyy,
    appUserSession,
    aps_global,
    forAppManagement,
    itemData,
    propGroups,
    propsSections,
    staticViews
  )
 * @returns `nameElements`, `metaElements`, `propSections` from the `propGroups`
 */
export const groupTaggedPropSectionElements = (props) => {

  const {
    _keyy,
    appUserSession,
    aps_global,
    forAppManagement,
    itemData,
    propGroups,
    propsSections,
    staticViews,
  } = props

  // header, meta, pin, propArrays, schedule, show, tagged
  const { header, show, tagged } = propGroups ? propGroups : {}

  const { props: props_header } = header ? header : {}
  const { props: props_show } = show ? show : {}
  const { props: props_tagged } = tagged ? tagged : {}

  const nameElements = []
  const metaElements = []

  const _propSections = _.sortBy(propsSections, 'position')

  const item_value = (pv) => {
    if (_.isArray(pv)) {
      const _pv = pv.sort()
      const v = []
      _pv.forEach(pvv => {
        v.push(<div>{pvv}</div>)
      })
      return <div>{v}</div>
    } else {
      return pv
    }
  }

  /**
   * 
   * @param {object} propItem 
   * @param {number} itemIndex 
   * @param {string} sectionKey 
   */
  const item_element = (propItem, itemIndex, sectionKey) => {

    const { display, key: propKey } = propItem ? propItem : {}
    const { caption, icon, iconColor, defaultIconColor, showAlwaysCaption, useSuffix, suffix } = display ? display : {}
    const itemValue = itemData[propKey]

    const icon_color = iconColor && iconColor.apply ? iconColor : defaultIconColor

    const _itemValue = ammendUiValue(itemValue, propItem.key)

    const _icon = icon

    let isGrouped;
    let prop_value;
    let _key = uniqueKey('gspsi', _keyy, itemIndex)
    let _iconColor;
    let _iconColor_display

    _iconColor_display = icon_color ? getThemeColorDirect(aps_global, icon_color) : null

    if (!_.isArray(_itemValue) && !_.isObject(_itemValue)) {

      const hasItem = appUserSession && appUserSession[propItem.key] && appUserSession[propItem.key].includes(itemValue);

      // this could be an array
      prop_value = getSplitStaticName(staticViews, propItem.key, _itemValue)

      _iconColor = hasItem ? 'blue' : 'grey'

      if (showAlwaysCaption && (_.isBoolean(prop_value) || prop_value === 'TRUE' || prop_value === 'FALSE' || _.isBoolean(prop_value))) {
        prop_value = caption
        _iconColor = 'green'
      }

      if (useSuffix && suffix) { prop_value += ' ' + suffix }
    } else {
      if (_.isArray(_itemValue)) {
        prop_value = getSplitStaticName(staticViews, propItem.key, _itemValue)
        isGrouped = true
      } else {
        prop_value = _itemValue
      }
    }

    if (prop_value) {
      switch (sectionKey) {
        case 'header':
          nameElements.push(<div key={uniqueKey(_key, 'n')} className={'prc'}>{item_value(prop_value)}</div>)
          break;
        default:
          metaElements.push(_icon ?
            <div className={isGrouped ? 'icn-grp' : 'icn-ngrp'}>{<div><Icon name={_icon} color={!_iconColor_display ? _iconColor : null} style={{ color: _iconColor_display ? _iconColor_display : null }}></Icon></div>}{item_value(prop_value)}</div>
            :
            <div className={isGrouped ? 'icn-ngrpp' : null}>{item_value(prop_value)}</div>)
      }
    }
  }

  let itemIndex = 0

  if (forAppManagement) {
    if (props_show) {
      const ps = _.sortBy(props_show, 'position')
      ps.forEach(propItem => {
        item_element(propItem, itemIndex, 'header')
        itemIndex++
      })
    }
  } else {
    if (props_header && props_header.length > 0) {
      filterName(props_header)
      // filter where name AND firstName and lastName all exist  
      const ph = _.sortBy(props_header, 'position')
      ph.forEach(propItem => {
        item_element(propItem, itemIndex, 'header')
        itemIndex++
      })
    }

    if (props_tagged) {
      const pt = _.sortBy(props_tagged, 'position')
      pt.forEach(propItem => {
        item_element(propItem, itemIndex, 'tagged')
        itemIndex++
      })
    }

  }

  return { nameElements, metaElements, propSections: _propSections }

}

const filterName = (props) => {
  let hasN;
  let nameIndex;
  let hasFn;
  let hasLn;
  props.forEach((propItem, index) => {
    if (propItem.key === 'name') {
      hasN = true
      nameIndex = index
    }
    if (propItem.key === 'firstName') { hasFn = true }
    if (propItem.key === 'lastName') { hasLn = true }
  })
  if (hasN && hasFn && hasLn) {
    props.splice(nameIndex, 1)
  }
}

export const createPropsFromList = (itemList) => {
  const ips = {}
  Object.keys(itemList).forEach(key => {
    const itemData = itemList[key]
    switch (key) {
      case 'key':
      case 'id':
      case 'uid':
        break;
      default:
        let formItemType = gEnums.formItemTypes.text
        if (_.isObject(itemData)) {
          if (_.isArray(itemData)) {
            formItemType = gEnums.formItemTypes.dropdown
          } else {
            formItemType = gEnums.formItemTypes.select
          }
        }
        ips[key] = {
          key: key,
          data: { formItemType: formItemType },
          propSection: gEnums.sectionHeaderNames.details,
          ui: {
            caption: _.startCase(key),
            elemPropType: gEnums.elemPropTypes.normal
          }
        }
    }
  })
  delete ips.id
  return ips
}

export const getAppFormProps = (vit, appForms) => {

  let vProps;

  switch (vit) {
    case gEnums.profileSelectTypes.appProfiles:
      vProps = appForms.appUser
      break;
    case gEnums.profileSelectTypes.profiles:
      vProps = appForms.profile
      break;
    case gEnums.profileSelectTypes.clientProfiles:
      vProps = appForms.clientProfile
      break;
    case 'notifications':
      vProps = appForms.notification
      break;
    default:
    // console.log('no fixed props vit', vit)
  }

  return vProps

}

export const createPropsFromData = (viewListData) => {
  const props = {}
  Object.keys(viewListData).forEach(key => {
    const item = viewListData[key]
    createPropsFromItem(item, props)
  })
  return props
}

export const createPropsFromItem = (item, x, optionsList, swipedItem, showOnly) => {

  if (swipedItem && swipedItem.key) {
    switch (swipedItem.key) {
      case 'profiles':
        if (!item.accessLevel) {
          item['accessLevel'] = 0
        }
        break;
      default:
      // nothing
    }
  }

  Object.keys(item).forEach(diKey => {

    if (!x[diKey]) {

      let show = true

      switch (diKey) {
        case 'accessLevel':
          if (optionsList['accessLevels']) {
            // formItemType = gEnums.formItemTypes.select
            // listType = 'accessLevels'
          }
          break;
        case 'id':
          // disabled = true
          break;
        default:
        // nothing
      }


      if (show) {
        x[diKey] = {

          key: diKey,
          data: { formItemType: "text" },
          propSection: gEnums.sectionHeaderNames.details,
          ui: {
            caption: _.startCase(diKey),
            elemPropType: gEnums.elemPropTypes.normal
          }
        }
        switch (diKey) {
          case 'name':
          case 'firstName':
          case 'lastName':
            x[diKey].elemPropType = gEnums.elemPropTypes.header
            break;
          default:
          // nothing
        }
      }
    }
  })
}

/** Retuns the settingKey needed for the view */
export const getViewSettingsBaseName = (view, pathViews) => {

  let name = 'home'

  if (pathViews && pathViews.events) {
    name = pathViews.events
  } else if (pathViews && pathViews.clients) {
    name = pathViews.clients
  } else if (view === 'events') {
    name = view
  } else if (view === 'clients') {
    name = view
  } else if (view === 'home') {
    name = 'home'
  }

  return name

}

export const getViewSettingsKeys = (view, pathViews) => {

  const sdks = {}

  sdks['home'] = 'home'
  sdks['clients'] = 'clients'

  if ((pathViews && pathViews.events)) {
    sdks['event_key'] = pathViews.events
    sdks['client_key'] = pathViews.clients
  } else if (pathViews && pathViews.clients) {
    sdks['client_key'] = pathViews.clients
  }

  return sdks
}

export const getSettingsProps = (view, pathViews, useOld) => {

  let baseSettingsName = getViewSettingsBaseName(view, pathViews)

  let settingsCollectionName = null
  let settingsDocKey = null
  let isAll = false

  switch (baseSettingsName) {
    case 'home':
      settingsCollectionName = 'homeSettings'
      settingsDocKey = 'home'
      break;
    case 'clients':
      settingsCollectionName = 'clientSettings'
      settingsDocKey = pathViews.clients
      break;
    case 'events':
      settingsCollectionName = 'appSettings'
      settingsDocKey = pathViews.events
      break;
    default:
    // nothing
  }

  if (!useOld) {
    settingsCollectionName = _settingsFs.root
    baseSettingsName = 'main'
  }

  if (!settingsDocKey) {
    settingsDocKey = baseSettingsName //+ 'All'
    isAll = true
  }

  return {
    baseSettingsName: baseSettingsName,
    settingsCollectionName: settingsCollectionName,
    settingsDocKey: settingsDocKey,
    isAll: isAll
  }
}

export const getViewItemHeaders = (viewItem) => {
  const _props = []
  const _groupedItems = getPropSectionsGrouped(viewItem.props, viewItem.propSections, true)
  if (_groupedItems) {
    Object.keys(_groupedItems).forEach(key => {
      const gi = _groupedItems[key]
      if (gi && gi.props) {
        const pp = _.sortBy(gi.props, 'position')
        pp.forEach(i => {
          _props.push(i.key)
        })
      }
    })
  }
  return _props
}

/** get the props for each section */
export const getPropSectionsGrouped = (props_viewItem, propSections, showAll, desktopMode, display_global, propGroups, viewItem, aps_appUserSettings) => {

  let semPropSections = {}

  const _showProp = desktopMode ? gEnums.showTypes.showDesktop : gEnums.showTypes.show
  const { pin } = propGroups ? propGroups : {}
  const { propArray: propsToSeparate } = pin ? pin : {}

  const { propsToSeparate: propsToSeparate_global } = display_global ? display_global : {}

  let _propsToSeparate = [];

  if (propsToSeparate && propsToSeparate_global) {
    _propsToSeparate = [...propsToSeparate, ...propsToSeparate_global]
  } else if (propsToSeparate) {
    _propsToSeparate = propsToSeparate
  } else if (propsToSeparate_global) {
    _propsToSeparate = propsToSeparate_global
  }

  if (_propsToSeparate) {
    _propsToSeparate.forEach(pts => {
      if (props_viewItem && props_viewItem[pts]) {
        if (semPropSections && !semPropSections[pts]) {
          props_viewItem[pts].propSection = pts
          createPropSection(propSections, pts, gEnums.sectionDisplayTypes.separate)

          const { showOverview, appUserCollections, appUserOverviewPages } = aps_appUserSettings ? aps_appUserSettings : {}
          if (showOverview && appUserOverviewPages && appUserCollections && appUserCollections.includes(viewItem.key)) {
            appUserOverviewPages.forEach(appUserOverviewPage => {

              const _section = _.camelCase(appUserOverviewPage.replace('appUser', ''))

              switch (appUserOverviewPage) {
                case gEnums.appUserProfilePageTypes.appUserAboutMe:
                case gEnums.appUserProfilePageTypes.appUserBio:
                  props_viewItem[_.camelCase(_section)] = newPropEditItem(_section, gEnums.formItemTypes.text, null, _section)
                  createPropSection(propSections, _section, gEnums.sectionDisplayTypes.separate)
                  break;

                case gEnums.appUserProfilePageTypes.appUserGallery:
                  props_viewItem[_.camelCase(_section)] = newPropEditItem(_section, gEnums.formItemTypes.text, null, _section)
                  createPropSection(propSections, _section, gEnums.sectionDisplayTypes.gallery)
              }
            })
          }
        }
      }
    })
  }

  if (propSections) {
    Object.keys(propSections).forEach(psKey => {
      if (!propSections[psKey].position) { propSections[psKey].position = 0 }
    })
    semPropSections = sortObject(propSections, 'position')
  }
  if (semPropSections && !showAll) { semPropSections = filterObject(semPropSections, _showProp) }

  createKeys(props_viewItem)

  // groupBy propSection
  let propSectionGroups = _.groupBy(props_viewItem, 'propSection')

  Object.keys(semPropSections).forEach(sectionKey => {
    if (propSectionGroups[sectionKey]) {
      semPropSections[sectionKey].props = createObject(propSectionGroups[sectionKey], 'key')
    }
  })

  return semPropSections

}

export const createPropSection = (propSections, sectionName, sectionDisplayType) => {
  propSections[sectionName] = {
    key: sectionName,
    position: Object.keys(propSections).length,
    show: true,
    sectionBody: {
      bodyDisplayType: gEnums.sectionBodyTypes.noCaptions
    },
    sectionHeader: {
      headerDisplayType: gEnums.sectionHeaderTypes.divider
    },
    sectionDisplay: {
      sectionDisplayType: sectionDisplayType,
      showHeader: true,
      sectionHeaderCaption: _.startCase(sectionName)
    }
  }
}

export const getPropsFromSections = (sectionProps) => {

  const ps = _.sortBy(sectionProps, 'position')
  const allProps = []

  ps.forEach(aaa => {
    let poz = 0
    if (aaa.props) {
      const a = _.filter(aaa.props, { allowProp: 'allow' })
      const b = _.filter(a, { cssed: true })
      const c = _.sortBy(b, 'position')
      c.forEach(ci => {
        ci.poz = poz
        allProps.push(ci)
        poz++
      })
    }
  })

  const allPropsO = createObject(allProps, 'key')
  const propsSorted = _.sortBy(allPropsO, 'poz')

  return propsSorted
}

export const newPropEditItem = (key, formItemType, listType, propSection, bb) => {
  const npei = {
    data: { formItemType: formItemType, required: false, listType },
    key: key,
    position: 0,
    show: true,
    propSection: propSection ? propSection : 'other',
    ui: {
      caption: _.startCase(key)
    },
    _allowProp: true
  }
  return npei
}

const getVal = (obj, settings) => {
  let r = null
  try {
    settings.forEach(s => {
      if (!r) {
        r = obj[s]
      } else {
        r = r[s]
      }
    })
  } catch (error) {
    console.error(error)
  }
  return r ? r : {}
}

export const getSvs = (isGlobal, pageContext, settings, groups) => {

  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_page } = pageSettings ? pageSettings : {}

  let result = null
  if (isGlobal) {
    if (groups) {
      result = {}
      groups.forEach(g => {
        if (g) {
          const gs = g.split('/')
          const p = gs[gs.length - 1]
          const r = getVal(aps_global, gs)
          result[p] = r ? r : {}
        }
      })
    } else {
      result = getVal(aps_global, settings)
    }
  } else {
    if (groups) {
      result = {}
      groups.forEach(g => {
        if (g) {
          const gs = g.split('/')
          const p = gs[gs.length - 1]
          const r = getVal(aps_page, gs)
          result[p] = r ? r : {}
        }
      })
    } else {
      result = getVal(aps_page, settings)
    }
  }
  return result
}

export const addViPropsFromAdditional = (aps_viewItems, aps_views, vit, itemProps) => {

  const _aps_viewItems = { ...aps_viewItems }
  const _aps_views = { ...aps_views }

  if (_aps_viewItems[vit]) { addViPropsToViewItems(_aps_viewItems, vit, itemProps) }

  addViPropsToViews(_aps_views, vit, false, itemProps)
  addViPropsToViews(_aps_views, vit, true, itemProps)

  return {
    viewKey: vit,
    viewItem: _aps_viewItems[vit] ? _aps_viewItems[vit] : null,
    view: _aps_views[vit] ? _aps_views[vit] : null,
    viewList: _aps_views[vit + '_list'] ? _aps_views[vit + '_list'] : null
  }
}

const addViPropsToViewItems = (aps_viewItems, vit, itemProps) => {
  if (aps_viewItems[vit]) {
    const aps_viewItem = aps_viewItems[vit]
    const { props: props_vi } = aps_viewItem
    if (props_vi && itemProps) {
      Object.keys(itemProps).forEach(propKey => {
        if (!props_vi[propKey]) {
          props_vi[propKey] = itemProps[propKey]
        }
      })
      delete props_vi._itemKey
      delete props_vi._gsId
    }
  }
}

const addViPropsToViews = (aps_views, vit, forList, itemProps) => {
  if (aps_views[vit]) {
    const view = forList ? aps_views[vit + '_list'] : aps_views[vit]
    const { viewItems: viewItems_view } = view
    if (viewItems_view && viewItems_view[vit]) {
      const viewItem_view = viewItems_view[vit]
      const { props: props_vi } = viewItem_view
      if (props_vi && itemProps) {
        Object.keys(itemProps).forEach(propKey => {
          if (!props_vi[propKey]) {
            props_vi[propKey] = itemProps[propKey]
          }
        })
        delete props_vi._itemKey
        delete props_vi._gsId
      }
    }
  }
}