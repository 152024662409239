import { _appVersion } from '../../cnr/contexts/AppSettingsContext';
import { createModes } from '../../cnr/reducers/CreateReducer';
import { dispatchProps, grts } from '../../cnr/reducers/reducerHelpers/dispatchProps';
import { getNow } from '../../common/dateFormatting';
import { convertSettingsUpdate } from '../../firestoreData/settings/convertSettings';
import { updateSettingsDocsToDatabase } from '../../firestoreData/settings/updateSettings';
import { addDataItemsToCollectionDatabase } from '../../firestoreData/updates/subUpdates/addDataItemsToCollection';
import { deleteStorageBucket } from '../../functions/fbBucketFunctions';
import { fsfn_createEventInDatabase } from '../../functions/fbCreate';
import { fsfn_deleteEventCollection } from '../../functions/fbDelete';
import { fsfn_backupSettings, fsfn_updateAppSettings, fsfn_updateAppSettingsByType } from '../../functions/fbSettingsFunctions';
import { fsfn_transferDataToProd, fsfn_transferEventToProd, fsfn_transferSettingsToProd } from '../../functions/fbTransfer';
import { copyViewItemSettingsFromDatabase } from '../create/appSettingsToViews';
import { settingsActionTypes } from '../enums/itemActionTypes';
import { sendConfirmationToMe } from '../fetching/fetching';
import { globalPageItems_update } from './globalData';
import { printDoc } from './printDoc';
import { updateDataLists } from './updateDataLists';

const allowCommitAction = true

/** Commits the action. Called from ViewSettignsReducer
 * @update backupAllSettings
 * @update backupSettings
 * @update commitActiveSettings
 * @update copyViewItemSettingsFromDatabase
 * @update copyViewItemSettingsToViews
 * @update createHelpDocument
 * @update deleteEvent
 * @update fixAllSettings
 * @update fixSettings 
 * @update pushEventToProduction
 * @update restoreSettingsFromBackup
 * @update restoreSettingsFromPaste
 * @update restoreSettingsFromRegistration
 * @update updateAllPageItems 
 * @update updateDataLists 
 * @update updateEventStatus
 * @update updateSettingsByType
  */
export const commitViewSettingsAction = (dispatch, firestore_handlers, commitProps, logging) => {

  const {
    actionName,
    appUser,
    aps_global,
    aps_viewItems,
    aps_views,
    dataKey,
    dataToUpdate,
    databaseProjectId,
    opts,
    page_fns,
    paps_state,
    previewInfo,
    replaceData,
    swipedItem,
    updateProps,
    updateType,
    optz,
  } = commitProps

  // LOGGING
  const { productionSettings } = aps_global ? aps_global : {}

  if (!allowCommitAction) {
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  const { pathViews, settingsDocName, storageRootPath } = paps_state

  switch (actionName) {

    case settingsActionTypes.transferEventToProduction:
      const createInfoP = {
        mode: createModes.create,
        projectData: {
          clientData: { clientMobileKey: pathViews.clients },
          eventData: { id: pathViews.events, name: aps_global.topMenu.mainFixedCaption },
        },
        projectSettings: {
          global: aps_global,
          viewItems: aps_viewItems,
          views: aps_views
        },
        collections: Object.keys(aps_viewItems)
      }
      fsfn_transferEventToProd(createInfoP, pathViews, productionSettings, optz, logging).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        console.error(error)
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.transferDataToProduction:
      fsfn_transferDataToProd(pathViews, ['sponsorTypes'], productionSettings, null, null, false, logging).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        console.error(error)
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.transferSettingsToProduction:
      fsfn_transferSettingsToProd(settingsDocName, logging).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        console.error(error)
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.deleteBucket:
      // let p = paps_state.storageRootPath
      // p += '/manifest'
      deleteStorageBucket(storageRootPath, dispatch)
      break;

    case settingsActionTypes.createClient:
      console.log('actionName', actionName)
      console.log('commitProps', commitProps)
      break;

    case settingsActionTypes.commitActiveSettings:
      dispatch({ type: grts.updateSuccess })
      break;

    case settingsActionTypes.backupSettings:
      const _previewInfo = {
        settings: { ...previewInfo },
        info: {
          appVersion: _appVersion,
          lastUpdate: getNow(),
          uid: appUser.uid,
          displayName: appUser.displayName
        }
      }
      fsfn_backupSettings(settingsDocName, true, _previewInfo).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        console.error(error)
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.backupAllSettings:
      fsfn_backupSettings(null, true, previewInfo).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        console.error(error)
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.createHelpDocument:
      printDoc('helper')
      break;

    case settingsActionTypes.copyViewItemSettingsFromDatabase:
      copyViewItemSettingsFromDatabase(dispatch, paps_state, aps_global, aps_views, swipedItem, updateProps, page_fns)
      break;

    case settingsActionTypes.copyViewItemSettingsToViews:
      const { settings_temp: globalV, views: rViewsV } = previewInfo ? previewInfo : {}
      const fullDbSettingsV = { global: globalV, views: rViewsV }
      firestore_handlers.updateFirestoreSettings_globalAndViewsDb(dispatch, paps_state, true, fullDbSettingsV)
      break;

    case settingsActionTypes.fixSettings:
    case settingsActionTypes.fixAllSettings:
      updateSettingsDocsToDatabase(previewInfo, dispatch)
      break;

    case settingsActionTypes.convertSettingsToSeparate:
      convertSettingsUpdate(settingsDocName, previewInfo, appUser, optz).then(res => {
        dispatch({ type: dispatchProps.success })
      }).catch(error => {
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.restoreSettingsFromRegistration:
      const createInfo = {
        mode: createModes.update,
        projectData: previewInfo.projectData,
        projectSettings: previewInfo.projectSettings
      }
      if (createInfo.projectData.clientData && createInfo.projectData.clientData.client_mobile_key) {
        createInfo.projectData.clientData.clientMobileKey = createInfo.projectData.clientData.client_mobile_key
      }
      fsfn_createEventInDatabase(createInfo, false, null, pathViews, databaseProjectId).then(resp => {
        dispatch({ type: resp.success ? dispatchProps.success : dispatchProps.error })
      }).catch(err => {
        // handleCreateResponse(fn_response, createInfo)
      })
      break;

    case settingsActionTypes.updateDataByType:
      if (dataKey) {
        if (replaceData) {
          fsfn_deleteEventCollection(pathViews, dataKey, 100, logging).then(response_data => {
            const { success, error } = response_data ? response_data : {}
            if (success) {
              addDataItemsToCollectionDatabase(pathViews, dataKey, dataToUpdate, false, dispatch)
            } else {
              dispatch && dispatch({ type: dispatchProps.error, error })
            }
          }).catch(error => {
            dispatch({ type: dispatchProps.error, error })
          })
        } else {
          addDataItemsToCollectionDatabase(pathViews, dataKey, dataToUpdate, false, dispatch)
        }
      }
      break;

    // IMPORTANT: UPDATE DATA
    case settingsActionTypes.updateDataFromGoogleSheets:
    case settingsActionTypes.updateDataFromGoogleData:
      if (replaceData) {
        dispatch({ type: dispatchProps.success })
      } else {

      }
      // fsfn_deleteCollection(pathViews, 'test', 100)
      break;

    case settingsActionTypes.updateSettingsFromGoogleSheets:
    case settingsActionTypes.restoreSettingsFromBackup:
      fsfn_updateAppSettings(settingsDocName, previewInfo.projectSettings, logging).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.restoreSettingsFromPaste:
      fsfn_updateAppSettings(settingsDocName, previewInfo, logging).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        console.error(error);
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    // updates partial data to settings
    case settingsActionTypes.updateSettingsByType:
      fsfn_updateAppSettingsByType(settingsDocName, previewInfo, updateType).then(response_data => {
        const { success, error } = response_data ? response_data : {}
        dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error })
      }).catch(error => {
        dispatch({ type: dispatchProps.error, error })
      })
      break;

    case settingsActionTypes.updateEventStatus:
      const { meEventKey, deleteItem } = opts ? opts : {}
      if (meEventKey) {
        sendConfirmationToMe(meEventKey, true, deleteItem).then(res => {
          dispatch({ type: grts.updateSuccess })
        })
      }
      break;

    case settingsActionTypes.updateAllPageItems:
      globalPageItems_update(dispatch, previewInfo)
      break;

    case settingsActionTypes.updateDataLists:
      updateDataLists(dispatch, paps_state)
      break;

    default:
    // nothing
  }
}