
const rts = {
  handleDateMatchResults: 'handleDateMatchResults',
  handleGoToMatch: 'handleGoToMatch',
  handleMatchClick: 'handleMatchClick',
  handleLevelDateGroup: 'handleLevelDateGroup',
  handleLevelGroupMatches: 'handleLevelGroupMatches',
  handleMatchesInit: 'handleMatchesInit',
  handlePlayoffMatchesInit: 'handlePlayoffMatchesInit',
  handleMatchFilter: 'handleMatchFilter',
  handleNoData: 'handleNoData',
  handleSwiper: 'handleSwiper',
  handleTeamMatches: 'handleTeamMatches',
}

const dataItems = {
  schools: { collectionItems: ['schools'] },
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sportsSeasonMatches season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const seasonMatchesReducer = (state, action) => {

  const {
    _teamKey,
    commonMatches,
    matchesInfo,
    nextMatchOnly,
    previewMatches,
    scheduleTypes,
    sportsSeason_fns,
    sortedMatches,
    pushSimple,
  } = state

  const { type, dispatch } = action

  const {
    matchesAll,
    matchesTeams,
    matchesRetrieved,
  } = matchesInfo ? matchesInfo : {}

  const { handleSetDataResults, handleSetGlobals, handleSetSports } = seasonMatchesHandlers(dispatch)

  switch (type) {

    case rts.handleMatchesInit:
      let _matches = matchesAll
      if (previewMatches) { _matches = previewMatches }
      if (commonMatches) { _matches = commonMatches }
      const { sortedMatches: sms, noData } = getSortedMatches(state, _matches)
      return { ...state, scheduledMatches: _matches, sortedMatches: sms, noData: noData }

    case rts.handlePlayoffMatchesInit:
      console.log('action', action)
      return { ...state, pools: action.pools, poolMatches: action.poolMatches }

    case rts.handleDateMatchResults:
      return { ...state, dateMatchResults: action.dmr }

    case rts.handleLevelDateGroup:
      return { ...state, levelDateGroup: action.dlg, levelDateKey: action.dlk }

    case rts.handleLevelGroupMatches:
      return { ...state, levelGroupMatches: action.lgms }

    case rts.handleMatchFilter:
      return { ...state, matchFilter: action.mf }

    case rts.handleNoData:
      return { ...state, noData: action.nd }

    case rts.handleTeamMatches:
      let _teamMatches;
      if (sortedMatches) {
        if (scheduleTypes.team || scheduleTypes.match) {
          if (nextMatchOnly) {
            _teamMatches = sortedMatches
          } else {
            _teamMatches = sportsSeason_fns.getFilteredMatches(sortedMatches, _teamKey, action.selectedLocation, action.selectedResult, action.selectedSection)
          }
        }
      }
      return { ...state, teamMatches: _teamMatches }

    case rts.handleSwiper:
      return { ...state, swiper: action.swiper }

    case rts.handleMatchClick:
      pushSimple(action.clickedItem, action.itemKey, action.modifyMode, action.opts, action.ddn, action.ddProps, action.ddGroupIndex)
      return { ...state, match_current: matchesAll ? matchesAll[action.itemKey] : null }

    case rts.handleGoToMatch:
      pushSimple(action.clickedItem, action.itemKey, action.modifyMode, action.opts, action.ddn, action.ddProps, action.ddGroupIndex)
      return { ...state, match_current: matchesAll ? matchesAll[action.itemKey] : null }

    default:
      return { ...state }
  }
}

export const seasonMatchesHandlers = (dispatch) => {
  return {
    handlePlayoffMatchesInit: (pools, poolMatches) => { dispatch({ type: rts.handlePlayoffMatchesInit, dispatch, pools, poolMatches }) },
    handleDateMatchResults: (dmr) => { dispatch({ type: rts.handleDateMatchResults, dispatch, dmr }) },
    handleGoToMatch: (pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => { dispatch({ type: rts.handleGoToMatch, dispatch, pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex }) },
    handleMatchClick: (pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => { dispatch({ type: rts.handleMatchClick, dispatch, pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex }) },
    handleLevelDateGroup: (dlg, dlk) => { dispatch({ type: rts.handleLevelDateGroup, dispatch, dlg, dlk }) },
    handleLevelGroupMatches: (lgms) => { dispatch({ type: rts.handleLevelGroupMatches, dispatch, lgms }) },
    handleMatchesInit: (matchesAll) => { dispatch({ type: rts.handleMatchesInit, dispatch, matchesAll }) },
    handleMatchFilter: (mf) => { dispatch({ type: rts.handleMatchFilter, dispatch, mf }) },
    handleNoData: (nd) => { dispatch({ type: rts.handleNoData, dispatch, nd }) },
    handleSwiper: (swiper) => { dispatch({ type: rts.handleSwiper, dispatch, swiper }) },
    handleTeamMatches: (selectedLocation, selectedResult, selectedSection) => { dispatch({ type: rts.handleTeamMatches, dispatch, selectedLocation, selectedResult, selectedSection }) },
  }
}

export const seasonMatchesInitialState = (init_state) => {
  return { ...init_state, matchFilter: {}, teamMatches: {} }
};

const getSortedMatches = (state, scheduledMatches) => {

  const {
    _teamKey,
    commonMatches,
    latestMatchesOnly,
    latestMatchesCount,
    matchesTeams,
    matchesRetrieved,
    nextMatchOnly,
    pathViews,
    scheduleTypes,
    sportsSeason_fns,
  } = state

  let sortedMatches;
  let noData;

  if (scheduledMatches && Object.keys(scheduledMatches).length > 0) {
    if (scheduleTypes.common) {
      sortedMatches = commonMatches
    } else {
      if (scheduleTypes.team) {
        if (matchesTeams) {
          if (nextMatchOnly) {
            sortedMatches = matchesTeams && matchesTeams[_teamKey].next ? matchesTeams[_teamKey].next : 'No Matches'
          } else if (latestMatchesOnly) {
            sortedMatches = matchesTeams && matchesTeams[_teamKey].latest ? matchesTeams[_teamKey].latest : 'No Matches'
          } else {
            sortedMatches = matchesTeams && matchesTeams[_teamKey].all
          }
        } else {
          const tm = sportsSeason_fns.getMatchesByTeam(scheduledMatches, _teamKey, false, nextMatchOnly, latestMatchesOnly, latestMatchesCount)
          sortedMatches = tm
        }
      } else if (scheduleTypes.match) {
        const mm = sportsSeason_fns.getMatch(scheduledMatches, pathViews.matches)
        sortedMatches = mm
      }
    }
    noData = false
  } else {
    noData = matchesRetrieved && scheduledMatches ? true : false
    // handleNoData(matchesRetrieved && matchesAll ? true : false)
  }

  return { sortedMatches, noData }
}