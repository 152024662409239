import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Menu } from 'semantic-ui-react';
import { SettingParentContext } from '../../../cnr/contexts/SettingParentContext';
import { SettingsAreaContext } from '../../../cnr/contexts/SettingsAreaContext';
import SettingsMenuProvider, { SettingsMenuContext } from '../../../cnr/contexts/SettingsMenuContext';
import { gEnums } from '../../../enums/globalEnums';
import { iconColorTypes } from '../../../enums/settingsIconTypes';
import Wrapper from '../../../wrappers/Wrapper';
import SettingsSearch from '../../SettingsSearch';
import SettingsAreaGroupMenu from './SettingsAreaGroupMenu';
import SettingsAreaGroupsMenu from './SettingsAreaGroupsMenu';
import SettingsAreaMenuBottom from './SettingsAreaMenuBottom';
import SettingsAreaMenuHeader from './SettingsAreaMenuHeader';

// const firstItems = ['client', 'event', 'quickLinks']

// IMPORTANT: Settings - SettingsAreaMenu
/** The side settings menu for the desktop view 
 @description This is ONLY a list of menu items. The swiper takes effect after the first click
*/

// IMPORTANT: Settings - SettingsAreaMenu
/**
 * This is the SICE settings menu for the desktop view. It's menu items are ONLY the major groups
 * The swiper (`ViewSettings`) takes effect after the first click.
 * the menus are divided into 4 parts
 * SettingsAreaMenuHeader
 * SettingsAreaGroupsMenu - these are the main settings
 * SettingsAreaMenuBottom
 * SettingsAreaGroupMenu
 * 
 */
const SettingsAreaMenu = () => {

  // settingsParentContext
  const settingsParentContext = useContext(SettingParentContext)
  const { states, fns } = settingsParentContext ? settingsParentContext : {}
  const { paps_state, page_state } = states ? states : {}
  const { appUser_fns, page_fns } = fns ? fns : {}
  const { appArea, view, viewKey, otherView, pathViews, settingsDocName, trueView } = paps_state ? paps_state : {}

  // pageViewContext 
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { pushSimple } = page_fns ? page_fns : {}
  const { sideMenu } = aps_global ? aps_global : {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext);
  const { settingsArea_state } = settingsAreaContext ? settingsAreaContext : {}
  const {
    isGlobal,
    isSolo,
    menuAuthGroups,
    settingsGroupsByGroupType,
    settingsOptions,
    settingsOriginType,
  } = settingsArea_state ? settingsArea_state : {}

  // settingsMenuContext
  const settingsMenuContext = useContext(SettingsMenuContext)
  const { settingsMenu_state, settingsMenu_handlers, settingsMenu_fns } = settingsMenuContext ? settingsMenuContext : {}
  const {
    activeAll,
    activeMenus,
    allowGroupMenuSort, // set by handleAllowGroupMenuSort
    selectedGroup,
    selectedGroupItem,
    showSearch,
  } = settingsMenu_state ? settingsMenu_state : {}

  const { useCollapsibleMenus, useDarkMode, allowSearch } = settingsOptions ? settingsOptions : {}

  const isEmpty = otherView ? false : false
  let globalName = _.startCase(appArea)

  const topCaption = isGlobal ? globalName + ' Settings' : 'Page Settings'
  const subCaption = isGlobal ? settingsDocName : trueView

  const viewPermissionType = appUser_fns.validate_settingsMenuAuth()

  let lsName = 'activeMenus_'
  lsName += isGlobal ? 'global' : 'view'
  let initActive = localStorage.getItem(lsName)

  if (!initActive) {
    initActive = { app: false, data: false, content: true, permissions: false, styling: true, view: true }
  } else {
    initActive = JSON.parse(initActive)
  }

  // run when selectedGroupItem has a value
  useEffect(() => {
    if (selectedGroup && selectedGroupItem) {
      settingsMenu_fns.handleGroupItemClick(selectedGroupItem, selectedGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedGroup, selectedGroupItem])

  // return <Segment inverted>{'isGlobal'}{':'}{isGlobal.toString()}{'/'}{appArea_sa}</Segment>

  const handleUpdateAll = () => settingsMenu_handlers.handleUpdateAll()
  const handleAllowGroupMenuSort = () => settingsMenu_handlers.handleAllowGroupMenuSort()

  const rightMenuItemProps = {
    icon: activeAll ? 'expand' : 'window maximize outline',
    handleUpdateAll,
    handleAllowGroupMenuSort,
    show: useCollapsibleMenus,
    allowGroupMenuSort
  }

  /**
   * 
   * @returns SettingsAreaMenuHeader
   */
  const settingsAreaMenu_header = () => {
    const x = { sideMenu, iconLeft: 'settings', iconLeftColor: iconColorTypes.menuItem, topCaption, subCaption, rightMenuItemProps }
    return <SettingsAreaMenuHeader {...x} />
  }

  /**
   * 
   * @returns SettingsAreaMenuBottom
   */
  const settingsAreaMenu_bottom = () => {
    const mbProps = { activeAll, isGlobal, isSolo, pathViews, pushSimple, handleUpdateAll, useCollapsibleMenus, useDarkMode, view, viewKey, viewPermissionType }
    return <SettingsAreaMenuBottom mbProps={mbProps} />
  }

  /**
   * 
   * @returns SettingsAreaGroupMenu
   */
  const settingsAreaMenu_footer = () => {
    const group = { key: 'search', iconColor: 'green', allowed: true, groupItems: {} }
    const _grpSettings = { caption: 'Search', icon: 'search' }
    addGroupItem(group, 'search', 'search')
    const _menuGroup = <SettingsAreaGroupMenu group={group} grpSettings={_grpSettings} inverse={true} />
    if (Object.keys(group.groupItems).length > 0) {
      return <Menu borderless inverted={useDarkMode} vertical fluid className={'msmi three adm'}>{_menuGroup}</Menu>
    }
  }

  const addGroupItem = (grp, key, goto) => {
    grp.groupItems[key] = { ui: { caption: _.startCase(key) }, gotoKey: goto }
  }

  /**
   * THIS IS THE MAIN MENU
   * @returns a `Menu` element with `MenuItemsGroups` inside
   */
  const settingsAreaMenu_groups = () => <SettingsAreaGroupsMenu />

  /**
   * 
   * @param {string} wcn 
   * @param {string} ccn 
   * @returns a `Wrapper` with `Menus`
   */
  const wrapper = (wcn, ccn) => <Wrapper
    header={!fromApp ? settingsAreaMenu_header() : null}
    content={menuAuthGroups && settingsAreaMenu_groups()}
    footer={allowSearch ? settingsAreaMenu_footer() : settingsAreaMenu_bottom()}
    footer2={allowSearch && settingsAreaMenu_bottom()}
    css={{ container: wcn, content: ccn }}
  />

  const menuContent = (wcn, ccn) => activeMenus && wrapper(wcn, ccn)

  const fromApp = settingsOriginType === gEnums.settingsOriginTypes.app

  let scn = 'padd0' // settings-menu-container'
  if (useDarkMode) { scn += ' dark-mode' }

  if (isEmpty) {
    return (
      <div className={scn + ' empty'} >???</div>
    )
  } else {
    let wcn = 'setz sm'
    if (useDarkMode) { wcn += ' dark-mode' }
    if (isSolo) { wcn += ' app' }
    const ccn = 'ct-vs'
    return showSearch ? <SettingsSearch settingsGroupsByGroupType={settingsGroupsByGroupType} /> : menuContent(wcn, ccn)
  }
}

export default SettingsAreaMenu

export const SettingsAreaMenuWithProvider = (props) => {
  return <SettingsMenuProvider>
    <SettingsAreaMenu {...props} />
  </SettingsMenuProvider>
}