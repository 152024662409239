import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Icon, Image } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { UploadContext } from '../../cnr/contexts/UploadContext';
import { imageEditHandlers, imageEditInitialState, imageEditReducer } from '../../cnr/reducers/ImageEditReducer';
import NumberPicker from '../forms/elements/NumberPicker';

/** Returns the AvatarEditor. There is NO uploading from here */
const ImageEditor = (props) => {

  const { imageEditProps, imageFile, imageImage, isOriginal, useAvatar } = props

  const uploadContext = useContext(UploadContext)
  const { upload_handlers } = uploadContext ? uploadContext : {}

  const [imageEdit_state, imageEditDispatch] = useReducer(imageEditReducer, imageEditInitialState(imageEditProps));
  const imageEdit_handlers = imageEditHandlers(imageEditDispatch, imageEdit_state)
  const { setUseMapProps, setZoom, setRotate, setHeight, setWidth } = imageEdit_handlers
  const { useMapProps, height, width, zoom, rotate } = imageEdit_state

  const setZ = (e, data) => setZoom(avatarEditor, data.value)
  const setW = (e, data) => setWidth(avatarEditor, data.value)
  const setH = (e, data) => setHeight(avatarEditor, data.value)
  const setR = (e, data) => setRotate(avatarEditor, data.value)

  const avatarEditor = useRef(null);

  const [currentImageFile, setCurrentImageFile] = useState()
  const [currentImageBlob, setCurrentImageBlob] = useState()

  const getSource = (f) => {
    if (currentImageBlob) {
      // URL.revokeObjectURL()
    }
    const img = URL.createObjectURL(f)
    // const splits = img.split("/")
    // const scr_item = splits[splits.length - 1]
    // img.onload = () => {
    //   console.log('img', img)
    //   URL.revokeObjectURL(img.src);
    // }
    return img
  }

  // update the local state
  useEffect(() => {
    if (imageFile) {
      setCurrentImageFile(imageFile)
      if (imageImage && 1 === 3) {
        setCurrentImageBlob(imageImage)
      } else {
        const src = getSource(imageFile)
        setCurrentImageBlob(src)
      }
    }
    // eslint-disable-next-line
  }, [imageFile]);

  useEffect(() => {
    if (avatarEditor && upload_handlers) {
      upload_handlers.handleSet_avatorEditor(avatarEditor.current)
    }
    // eslint-disable-next-line
  }, [avatarEditor.current]);

  // eslint-disable-next-line
  const avatarDetails = avatarEditor && avatarEditor.current && avatarEditor.current.props && avatarEditor.current.props.imageEdit ? 'Image Size: ' + avatarEditor.current.props.imageEdit.size : 'Image Size: '

  const co = isOriginal ? 'anonymous' : null

  const allow = {
    width: useAvatar,
    height: useAvatar,
    zoom: useAvatar,
    rotate: false,
    rectangle: false,
    positioning: useAvatar
  }

  const _showEditor = (allow.width || allow.height || allow.rotate || allow.zoom)

  // eslint-disable-next-line
  const handleOnCheck = (e, data) => {
    setUseMapProps(!useMapProps)
  }

  const setPosition = () => {
    if (!allow.positioning) { return false }
  }

  const drawRectangle = () => {

    if (!allow.rectangle) { return false }

    const coords = {
      x: 90,
      y: 90,
      w: 100,
      h: 100
    }

    const canvas = avatarEditor.current.canvas
    const context = canvas.getContext('2d');
    context.beginPath();
    context.rect(coords.x, coords.y, coords.w, coords.h);
    context.lineWidth = 2;
    context.strokeStyle = 'yellow';
    context.stroke();
  }

  const ave = () => <AvatarEditor
    className={'avatar-content'}
    border={0}
    borderRadius={10}
    color={[255, 255, 255, 0.7]} // RGBA
    crossOrigin={co}
    height={height}
    imageEdit={currentImageFile}
    onMouseUp={drawRectangle}
    onPositionChange={setPosition}
    ref={avatarEditor}
    rotate={rotate}
    scale={zoom}
    width={width}
  />

  const ae = () => (
    <React.Fragment>
      <div className={'avatar-container avatar-imageEdit'}>
        {/* <div className={'avatar-header'}>{avatarDetails}</div> */}
        <AvatarEditor
          className={'avatar-content'}
          border={0}
          borderRadius={10}
          color={[255, 255, 255, 0.7]} // RGBA
          crossOrigin={co}
          height={height}
          imageEdit={imageFile}
          onMouseUp={drawRectangle}
          onPositionChange={setPosition}
          ref={avatarEditor}
          rotate={rotate}
          scale={zoom}
          width={width}
        />
      </div>
    </React.Fragment>
  )

  const aep = () => (
    <React.Fragment>
      <div className={'avatar-container avatar-imageEdit'}>
        {/* <div className={'avatar-header'}>{avatarDetails}</div> */}
        {ave()}
      </div>
    </React.Fragment>
  )



  const normalImage = () => {
    if (currentImageBlob) {
      if (currentImageFile.name.indexOf('.pdf') >= 0) {
        return <Icon size={'massive'} name='file pdf outline' />
      } else {
        return <Image className={'modal-imageEdit'} key={uniqueKey('ie', 'i')} src={currentImageBlob} alt={'imageEdit'}></Image>
      }
    } else {
      return <Icon size={'massive'} name='image' />
    }
  }

  const widthSlider = () => {
    const sliderProp = { data: { start: 100, end: 300, step: 10, initialValue: 240 } }
    const fip = { onChange: setW, value: width, propname: 'width' }
    return <NumberPicker fip={fip} itemProp={sliderProp} />
    // return < HorizontalSlider itemProp={sliderProp} fip={fip} />
  }

  const heightSlider = () => {
    const sliderProp = { data: { start: 100, end: 300, step: 10, initialValue: 240 } }
    const fip = { onChange: setH, value: height, propname: 'height' }
    return <NumberPicker fip={fip} itemProp={sliderProp} />
    // return < HorizontalSlider itemProp={sliderProp} fip={fip} />
  }

  const zoomSlider = () => {
    const sliderProp = { data: { start: .2, end: 10, step: .2, initialValue: 1 } }
    const fip = { onChange: setZ, value: zoom, propname: 'zoom' }
    return <NumberPicker fip={fip} itemProp={sliderProp} />
    // return < HorizontalSlider itemProp={sliderProp} fip={fip} />
  }

  const rotateSlider = () => {
    const sliderProp = { data: { start: 0, end: 270, step: 90, initialValue: 0 } }
    const fip = { onChange: setR, value: rotate, propname: 'rotate' }
    return <NumberPicker fip={fip} itemProp={sliderProp} />
    // return < HorizontalSlider itemProp={sliderProp} fip={fip} />
  }

  const editContent = () => {
    const items = []
    // items.push(<Input fluid propname='description' onChange={upload_handlers.handleChange} />)
    // if (allow.positioning) {items.push(positioning())}
    if (allow.width) { items.push(widthSlider()) }
    if (allow.height) { items.push(heightSlider()) }
    if (allow.zoom) { items.push(zoomSlider()) }
    if (allow.rotate) { items.push(rotateSlider()) }
    return items
  }

  const imageEditContent = () => {
    if (useAvatar) {
      if (useMapProps) {
        return aep()
      } else {
        return ae()
      }
    } else {
      return normalImage()
    }
  }

  const content = () => {
    return <div className={'imageEdit-editor-container'}>
      <div className={'imageEdit-editor-content'}>
        {imageEditContent()}
      </div>
      {_showEditor && <div className={'imageEdit-editor-footer'}>
        {editContent()}
      </div>}
    </div>
  }

  return content()
}

export default ImageEditor