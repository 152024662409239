import React, { createContext, useContext } from 'react';
import { SettingsViewerContext } from '../../components/viewers/FullSettingsViewer';
import { DataContext } from './DataContext';
import { FilterContext } from './FilterContext';
import { FrameworkContext } from './FrameworkContent';
import { HeaderContext } from './HeaderContext';
import { PageAreaContext } from './PageAreaContext';
import { ParentContext } from './ParentContext';

export const PageAreaParentContext = createContext()

const PageAreaParentProvider = (props) => {

  // frameworkContext
  const parentContext = useContext(ParentContext);
  const dataContext = useContext(DataContext);
  const filterContext = useContext(FilterContext);
  const frameworkContext = useContext(FrameworkContext);
  const headerContext = useContext(HeaderContext);
  const pageAreaContext = useContext(PageAreaContext)
  const settingsViewerContext = useContext(SettingsViewerContext)

  const { states, handlers, fns } = parentContext ? parentContext : {}

  const { data_state, data_handlers } = dataContext ? dataContext : {}
  const { uiFilter_state, uiFilter_handlers } = filterContext ? filterContext : {}
  const { framework_state, framework_handlers } = frameworkContext ? frameworkContext : {}
  const { header_state, header_handlers } = headerContext ? headerContext : {}
  const { pageArea_state, pageArea_handlers } = pageAreaContext ? pageAreaContext : {}
  const { settingsViewer_state, settingsViewer_handlers } = settingsViewerContext ? settingsViewerContext : {}

  const _parent_states = {
    data_state,
    uiFilter_state,
    framework_state,
    header_state,
    pageArea_state,
    settingsViewer_state,
    ...states
  }

  const _parent_handlers = {
    data_handlers,
    uiFilter_handlers,
    framework_handlers,
    header_handlers,
    pageArea_handlers,
    settingsViewer_handlers,
    ...handlers
  }

  const _parent_fns = {
    ...fns
  }

  return <PageAreaParentContext.Provider value={{ states: _parent_states, handlers: _parent_handlers, fns: _parent_fns }}>
    {props.children}
  </PageAreaParentContext.Provider>

};

export default PageAreaParentProvider