import React, { useEffect, useState } from 'react';

export const AllowNotifications = () => {

  const [notificationRequest, setNotificationRequest] = useState({ requested: false, requestResult: null })

  useEffect(() => {
    if (notificationRequest.requested) {
      try {
        Notification.requestPermission().then(function (result) {
          setNotificationRequest({ requested: true, requestResult: result })
        });
      } catch (error) {
        // notifications not allowed
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>
}