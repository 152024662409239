import _ from 'lodash';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { uniqueKey } from "../../common/keys";
import { gEnums } from '../../enums/globalEnums';
import { appIconTypes } from '../../enums/appIconTypes';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import GenericDdi from '../../genericControls/GenericDdi';

export const getHddItems = ({
  _modifyData,
  action_handlers,
  clickOption,
  hdd_handlers,
  menuItem,
  opts,
  showDirectDataLinking,
  showGroupDataLinking,
  swipedItem,
  viewDataSwiper_handlers,
  vit,
  viType,
}) => {

  const _restoreDefaultsCaption = _.startCase('restoreDefaults')
  const _setPermissionsCaption = _.startCase('setPermissions')

  const {
    getAuthUser,
    handleCopySettingsItem,
    handlePasteSettingsItem,
    handleDelete,
    handleModifyAccess,
    handleModifyImage,
    handleRestoreDefaults,
    handleShowPermissions,
    handleShowModifyPropData,
    handleSwipeTo,
    handleSortItems,
    handleUpdateNewProps,
    handleXXX,
    haq,
    openFirebaseDb,
  } = hdd_handlers

  const a_key = 'hdd'

  const colors = { options: 'blue' }


  const gddi = (key, iconName, onClick, clickOptions, opts) => <GenericDdi key={uniqueKey('gddi', key)} gdiProps={{ key, iconName, onClick, clickOptions, opts }} />
  const addDd = (key, icon, oc, viType, color) => gddi(uniqueKey(a_key, key), icon, oc, { key: key, viType }, { title: _.startCase(key), color: color })

  return {
    add: (addType) => gddi(uniqueKey(a_key, 'add'), 'add', handleSwipeTo, { key: 'add', viType: swipedItem.key }, { title: _.startCase(addType) }),
    addSection: () => gddi(uniqueKey(a_key, 'addSection'), 'add square', handleSwipeTo, { key: 'add', viType: 'propSections' }, { title: _.startCase('addSection'), color: gEnums.accessLevels.appAdmin.color }),
    alarm: () => gddi(uniqueKey(a_key, 'alarm'), 'key', getAuthUser, null, { title: _.startCase('getAuth') }),
    copySettings: () => addDd('copySettings', 'copy', handleCopySettingsItem, viType),
    delete: () => gddi(uniqueKey(a_key, 'delete'), 'delete', handleDelete, null, { title: _.startCase('remove'), color: 'red' }),
    files: () => gddi(uniqueKey(a_key, 'files'), appIconTypes.externalAlternate, openFirebaseDb, null, { title: _.startCase('openDatabase'), color: gEnums.accessLevels.admin.color }),
    imageModify: () => gddi(uniqueKey(a_key, 'imageModify'), 'picture', handleModifyImage, null, { title: _.startCase('modifyImage'), float: 'right', imageUrl: _modifyData.imageUrl }),
    manageSections: (psKey) => gddi(uniqueKey(a_key, 'manageSections'), 'grid layout', handleSwipeTo, { key: 'propSections', viType: 'propSections', psKey }, { title: _.startCase(psKey ? 'manageSection' : 'manageSections'), color: gEnums.accessLevels.appAdmin.color }),
    menuSettingsItem: (item) => gddi(uniqueKey(a_key, item.key), item.icon, clickOption, { menuItem, allowClick: true, clickOptionType: item.key }, { title: item.title ? _.startCase(item.title) : _.startCase(item.key), color: item.color }),
    modifyAccess: () => gddi(uniqueKey(a_key, 'modifyAccess'), 'key', handleModifyAccess, null, { title: _.startCase('modifyAccess'), float: 'right' }),
    modifyImage: () => gddi(uniqueKey(a_key, 'modifyImage'), 'picture', handleModifyImage, null, { title: _.startCase('modifyImage'), float: 'right', imageUrl: _modifyData.imageUrl }),
    modifyPropData: () => gddi(uniqueKey(a_key, 'modify-prop'), 'edit', handleShowModifyPropData, viType, { title: _.startCase('modifyPropData'), color: gEnums.accessLevels.admin.color }),
    notification: () => gddi(uniqueKey(a_key, 'notification'), 'notification', 'send', action_handlers.gotoPage, null, { title: 'send' }),
    pasteSettings: () => addDd('pasteSettings', 'copy', handlePasteSettingsItem, viType),
    restoreDefaults: () => gddi(uniqueKey(a_key, 'reset'), 'undo', handleRestoreDefaults, viType, { title: _restoreDefaultsCaption, color: gEnums.accessLevels.admin.color }),
    showGroup: () => gddi(uniqueKey(a_key, 'showGroup'), 'cart', viewDataSwiper_handlers.handleShowDocGroups, { currentVit: vit }, { title: _.startCase('linkToPage'), color: showGroupDataLinking ? 'green' : colors.options }),
    showHide: (key, sah) => gddi(uniqueKey(a_key, '' + key), sah[key].icon, haq, { actionType: key }, { title: sah[key].title ? sah[key].title : _.startCase(key), color: 'green' }),
    showLink: () => gddi(uniqueKey(a_key, 'showLink'), 'linkify', viewDataSwiper_handlers.handleShowDocLinks, { vit }, { title: _.startCase('showDirectLinks'), color: showDirectDataLinking ? 'green' : colors.options }),
    showPermissions: () => gddi(uniqueKey(a_key, 'showPermissions'), 'key', handleShowPermissions, viType, { title: _setPermissionsCaption, color: gEnums.accessLevels.admin.color }),
    sortItems: () => gddi(uniqueKey(a_key, 'sortItems'), 'sort', handleSortItems, { vit }, { title: _.startCase('sortItems'), color: 'green' }),
    swipeTo: (addType) => gddi(uniqueKey(a_key, 'swipeTo'), 'add', handleSwipeTo, { key: 'add', viType: swipedItem.key, viSection: opts.groupKey }, { title: _.startCase(addType) }),
    testItem: () => gddi(uniqueKey('testItem', 'add'), 'add', handleXXX, { key: 'add', viType: swipedItem.key }, { title: 'XXX' }),
    trigger: (key, dba) => gddi(uniqueKey(a_key, '' + key), dba[key].icon, dba[key].trigger, { actionType: key }, { title: _.startCase(key), color: iconColorTypes.external }),
    updateProps: () => gddi(uniqueKey(a_key, 'updateProps'), 'save', handleUpdateNewProps, null, { title: _.startCase('updateNewProps'), color: colors.options }),
  }
}

export const ddDivider = (index) => <Dropdown.Divider key={uniqueKey('hdd', 'd', index)} />