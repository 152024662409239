import React, { useContext } from 'react';
import { default as ReactSidebar } from 'react-sidebar';
import { SettingParentContext } from '../cnr/contexts/SettingParentContext';
import { SettingsAreaContext } from '../cnr/contexts/SettingsAreaContext';
import { g_cns } from '../common/cns';
import { uniqueKey } from '../common/keys';
import { SettingsAreaMenuWithProvider } from './components/menus/SettingsAreaMenu';
import ViewSettings from './ViewSettings';

/** This is the desktop settings sidebar using react-sidebar */
const SettingsAreaSidebar = () => {

  // settingsParentContext 
  const settingsParentContext = useContext(SettingParentContext)
  const { states } = settingsParentContext ? settingsParentContext : {}
  const { page_state } = states ? states : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { sideMenu } = aps_global ? aps_global : {}
  const { allowSwipeOpen } = sideMenu ? sideMenu : {}

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext)
  const { settingsArea_state, settingsArea_handlers } = settingsAreaContext ? settingsAreaContext : {}
  const { isGlobal, selectedGroupItem } = settingsArea_state ? settingsArea_state : {}
  const { handleCloseGroupItem } = settingsArea_handlers ? settingsArea_handlers : {}

  const pullRight = isGlobal ? false : true

  let sidebarCn = g_cns.sbrc

  return (
    <ReactSidebar
      sidebar={selectedGroupItem ? <ViewSettings key={uniqueKey('vss', 'vs')} /> : <div></div>}
      open={selectedGroupItem ? true : false}
      sidebarClassName={sidebarCn}
      contentClassName='noOverflowY'
      pullRight={pullRight}
      touch={allowSwipeOpen ? true : false}
      transitions={true}
      onSetOpen={() => { handleCloseGroupItem() }}
      styles={{ sidebar: { background: "black" } }}
    >
      <SettingsAreaMenuWithProvider />
    </ReactSidebar >
  )
}

export default SettingsAreaSidebar