import React from 'react';
import { Sidebar } from 'semantic-ui-react';
import { formatTypes } from '../../common/dateFormatting';
import { uniqueKey } from '../../common/keys';
import { menuStyles } from '../menus/menuSlider';
import { TriMenu } from '../menus/TriMenu';

export const CalendarMonths = (props) => {

  const { calProps } = props
  const { months, year, isVisible, handleItemClick, handleYearClick } = calProps

  const gc = (month, index) => (
    <div key={month} onClick={e => handleItemClick(index)}>
      {month}
    </div>
  )

  const gcs = () => months.map((month, i) => gc(month, i))
  const content = () => <div key={uniqueKey('msc')} className={'month-select-container'}> {gcs()}</div>

  const opts = { formatType: formatTypes.monthYear }

  return <Sidebar key={uniqueKey('ms', 'mcb')} animation='overlay' direction='top' visible={isVisible}>
    <TriMenu menuProps={{ value: year, handleMenuClick: handleYearClick, style: menuStyles.menuSliderWrapCal, opts }} />
    {content()}
  </Sidebar>

}
