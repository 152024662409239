import React, { createContext } from 'react';

/** 
* @returns data_current (settings)
* @returns getColors 
* @returns items[]
* @returns otherData 
* @returns setSortedReady (fn) 
* @returns sort_state (fn) 
* @returns swipedItem {key: ---} 
* @returns type
* @returns updateProps {}
*/
export const ViewSettingsContext = createContext();

/** 
 * @returns data_current (settings)
 * @returns getColors 
 * @returns items[]
 * @returns otherData 
 * @returns setSortedReady (fn) 
 * @returns sort_state (fn) 
 * @returns swipedItem {key: ---} 
 * @returns type
 * @returns updateProps {}
 */
const ViewSettingsProvider = (props) => {
  return (
    <ViewSettingsContext.Provider value={{ ...props }}>
      {props.children}
    </ViewSettingsContext.Provider>
  )
}

export default ViewSettingsProvider
