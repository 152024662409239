import React from 'react';
import { Header, Icon, Label, Menu } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { iconColorTypes } from '../../../enums/settingsIconTypes';
import GenericIcon from '../../../genericControls/GenericIcon';

const SettingsMenuSubHeader = (props) => {

  const { iconLeft, iconLeftColor, ocLeft, title, subTitle, labelTitle, rbs } = props

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon key={uniqueKey('gi', 'sh', key)} giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const rightButtons = []

  if (rbs) {
    rbs.forEach(rb => {
      rightButtons.push(gi(rb.keyy, rb.icon, rb.onClick, null, { inverted: true, disabled: !rb.ready, color: rb.color, title: rb.title, cn: rb.className, loading: rb.loading, nb: rb.loading, circular: rb.loading }))
    })
  }

  const menuHeader = () => {
    return <Menu borderless inverted className={'set-top'}>
      <Menu.Item onClick={ocLeft}>
        <Icon name={iconLeft} size={'large'} color={iconLeftColor} />
      </Menu.Item>
      <Menu.Item>
        <Header inverted as={'h4'}>
          <Header.Content>
            {title}
            <Header.Subheader>
              <Label size={'mini'} color={'blue'} style={{ margin: 0, marginTop: 6, marginRight: 6 }}>
                {labelTitle}
              </Label>
              {subTitle && <Label size={'mini'} color={iconColorTypes.default} style={{ margin: 0, marginTop: 6 }}>
                {subTitle}
              </Label>}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item >
          {rightButtons}
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  }

  return menuHeader()
}

export default SettingsMenuSubHeader