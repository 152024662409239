import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { gEnums } from '../../enums/globalEnums';
import { fs_get_data_all } from '../../firestoreData/appData/fsAppData';
import { googleDataHandlers, googleDataInitialState, googleDataReducer } from '../reducers/GoogleDataReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

export const _googleSheetsCollectionDataName = '_googleSheetsData'

/** AppContext */
export const GoogleDataContext = createContext();

/** Global OtherDatas for the Event */
const GoogleDataProvider = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ? papsContext : {}
  const { pathViews } = paps_state ? paps_state : {}

  // pageContext 
  const pageContext = useContext(PageContext)
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { googleSheets, appDataSource, logging } = aps_global ? aps_global : {}
  const { appDataSourceType } = appDataSource ? appDataSource : {}
  const { googleSheetsId } = googleSheets ? googleSheets : {}

  const googleData_initState = { pathViews, logging }
  const [googleData_state, googleData_dispatch] = useReducer(googleDataReducer, googleDataInitialState(googleData_initState));
  const googleData_handlers = googleDataHandlers(googleData_dispatch, googleData_state)
  const { googleDataOn, googleSheetsData, refreshThis } = googleData_state ? googleData_state : {}

  useEffect(() => {
    if (googleSheetsId) {

      let getGoogleData;

      switch (appDataSourceType) {
        case gEnums.dataSourceTypes.googleSpreadSheet:
          getGoogleData = true
          break;

        default:
          if (googleDataOn) {
            getGoogleData = true
          }
        // nothing
      }
      if (getGoogleData) {
        fs_get_data_all(pathViews, _googleSheetsCollectionDataName, googleData_handlers.handleGoogleSheetsDataResponse, { listen: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [refreshThis, googleDataOn]);

  const providerContext = () => <GoogleDataContext.Provider
    value={{ googleData_handlers, googleData_state }}>
    {props.children}
  </GoogleDataContext.Provider>

  switch (appDataSourceType) {
    case gEnums.dataSourceTypes.googleSpreadSheet:
    case gEnums.dataSourceTypes.googleSheetsData:
      return googleSheetsData ? providerContext() : providerContext()
    default:
      if (googleDataOn) {
        return googleSheetsData ? providerContext() : providerContext()
      } else {
        return providerContext()
      }
  }
}

export default GoogleDataProvider