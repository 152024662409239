import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { isObjectFull } from '../../common/sorting';
import { FirestoreContext } from '../../cnr/contexts/FirestoreContext';
import { PageContext } from '../../cnr/contexts/PageContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { dynListTypes, gEnums } from '../../enums/globalEnums';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { getUfCopyProps, getUfPushProps } from '../../cnr/reducers/FirestoreReducer';
import { NewPageContent, SelectContent } from '../create/createSubs/InputContent';

const _subActionTypes = {
  copyDataTo: 'copyDataTo',
  copySettingsFrom: 'copySettingsFrom',
  duplicateView: 'duplicateView',
}

export const HeaderActions = (props) => {

  const { dataHeaderType, viType } = props ? props : {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_viewItems } = pageSettings ? pageSettings : {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ? papsContext : {}

  // viewSettingsSwipeContext 
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state, viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}
  const { isGlobal, showCopyViewSettings, showDuplicateView, showCopyData, showPushData, view: view_dup } = viewSettings_state ? viewSettings_state : {}

  // viewSettingsComponentContext 
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { swipedItem } = vsc_state ? vsc_state : {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext);
  const { firestore_handlers } = firestoreContext ? firestoreContext : {}

  const vit = null

  const [actionValues, setActionValues] = useState()

  const handleLocalPropChange = (data) => setActionValues(data)

  /** Cancels the header action request */
  const handleCancel = () => {
    // if (showCreateSheetsDbLink) {
    // viewSettings_handlers.handleShowSheetsDbLink()
    if (showDuplicateView) {
      viewSettings_handlers.handleDuplicateView()
    } else if (showCopyViewSettings) {
      viewSettings_handlers.handleShowCopyViewSettings()
    } else if (showCopyData) {
      viewSettings_handlers.handleShowCopyData()
    } else if (showPushData) {
      viewSettings_handlers.handleShowPushData()
    }
  }

  const handleCopy = () => {
    const { name, page } = actionValues ? actionValues : {}
    if (showCopyViewSettings) {
      if (isGlobal) {
        viewSettings_handlers.handleCopyView(viType, view_dup, actionValues)
      } else {
        viewSettings_handlers.handleCopyViewItem(viType, view_dup, actionValues)
      }
    } else if (showDuplicateView) {
      if (isGlobal) {
        if (page && aps_viewItems && aps_viewItems[page]) {
          const cvi = { ...aps_viewItems[page] }
          aps_viewItems[name] = cvi
          aps_viewItems[name].key = name
          aps_viewItems[name].display = { caption: _.startCase(name) }
          viewSettings_handlers.handleCopyView(aps_viewItems)
        }
      } else {
        if (page && aps_viewItems && aps_viewItems[page]) {
          const cvi = { ...aps_viewItems[page] }
          aps_viewItems[name] = cvi
          aps_viewItems[name].key = name
          aps_viewItems[name].display = { caption: _.startCase(name) }
          viewSettings_handlers.handleCopyViewItem(aps_viewItems)
        }
      }
    } else if (showCopyData) {
      const ufProps = getUfCopyProps(name, vit)
      firestore_handlers.copyFirestoreData(paps_state, ufProps)
    } else if (showPushData) {
      const ufProps = getUfPushProps(vit, name)
      firestore_handlers.pushFirestoreData(paps_state, ufProps)
    }
  }

  const saveButtons = (selectType, oc) => {
    return <UiSaveButtons
      save={{ oc, caption: _.startCase(selectType), disabled: !isObjectFull(actionValues) }}
      cancel={{ oc: handleCancel }}
    />
  }

  const copySub = () => {

    const hds = { handleFormDataChange_local: handleLocalPropChange }

    const subDataSelect = (selectType, selectProps) => {
      switch (selectType) {
        case _subActionTypes.duplicateView:
          return <div className={'copy-container'} style={{ width: '100%' }}>
            <div><NewPageContent selectProps={selectProps} /></div>
            <div>{saveButtons(selectType, handleCopy)} </div>
          </div>
        default:
          return <div className={'copy-container'} style={{ width: '100%' }}>
            <div><SelectContent selectProps={selectProps} /></div>
            <div>{saveButtons(selectType, handleCopy)} </div>
          </div>
      }
    }

    switch (dataHeaderType) {
      case gEnums.dataHeaderTypes.viewData:
        if (showCopyData) {
          const copyDataProps = { view_dup, listType: dynListTypes.activeViList, hds }
          return subDataSelect(_subActionTypes.copyDataTo, copyDataProps)
        }
        break;

      case gEnums.dataHeaderTypes.itemHeader:
        const copySettingsProps = { view_dup, listType: dynListTypes.activeViList, hds }
        if (showCopyViewSettings) {
          if (swipedItem && swipedItem.vit) {
            return subDataSelect(_subActionTypes.copySettingsFrom, copySettingsProps)
          }
        } else if (showDuplicateView) {
          if (swipedItem && swipedItem.vit) {
            return subDataSelect(_subActionTypes.duplicateView, copySettingsProps)
          }
        }
        // if (showCreateSheetsDbLink) {
        //   return confirmation('Save')
        // }
        break;
      default:
        return null
    }

    return <div></div>
  }

  return copySub()

}