import _ from 'lodash'
import { playoffBracketTypes } from '../elements/matches/PlayoffBracket'

export const alterLevelName = (level) => {
  if (level === 'six' || level === 6) { return '6A' }
  if (level === 'five' || level === 5) { return '5A' }
  if (level === 'four' || level === 4) { return '4A' }
  if (level === 'three' || level === 3) { return 'AAA' }
  if (level === 'two' || level === 2) { return 'AA' }
  if (level === 'one' || level === 1) { return 'A' }
  return level
}

export const getTrueLevel = (level) => {
  if (level === 'six' || level === '6A') { return 6 }
  if (level === 'five' || level === '5A') { return 5 }
  if (level === 'four' || level === '4A') { return 4 }
  if (level === 'three' || level === 'AAA') { return 3 }
  if (level === 'two' || level === 'AA') { return 2 }
  if (level === 'one' || level === 'A') { return 1 }
  return level
}

export const getPlayoffMatches = (round, matches) => {

  const roundMatches = []
  let _roundMatchCount = 1
  let name = 'Final'
  let poolKey = 'finals'

  switch (round) {
    case 32:
      _roundMatchCount = 16
      name = 'Round of 32'
      poolKey = playoffBracketTypes.roundOf32
      break;
    case 16:
      _roundMatchCount = 8
      name = 'Round of 16'
      poolKey = playoffBracketTypes.roundOf16
      break;
    case 8:
      _roundMatchCount = 4
      name = 'Quarter-Finals'
      poolKey = playoffBracketTypes.quarterFinals
      break;
    case 4:
      _roundMatchCount = 2
      name = 'Semi-Finals'
      poolKey = playoffBracketTypes.semiFinals
      break;
    case 2:
      _roundMatchCount = 1
      name = 'Finals'
      poolKey = playoffBracketTypes.finals
      break;
    default:
    // nothing
  }

  for (var r = 1; r <= _roundMatchCount; r++) {
    const match = matches[name + '-' + r]
    match.poolKey = poolKey
    match.isPlayoff = true
    if (match) { roundMatches.push(match) }
  }

  return {
    matches: roundMatches,
    name: name
  }
}

export const groupMatchesByDate = (matchData) => {

  const matchDates = {}
  const startDate = new Date(2018, 0, 1)
  const endDate = new Date(2018, 10, 1)

  for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {

    const td = d //format(d, 'MM/DD/YYYY')
    // const dd = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear()
    const dKey = (d.getMonth() + 1) + '_' + d.getDate() + '_' + d.getFullYear()

    // group by date 
    Object.keys(matchData).forEach(key => {
      if (td === matchData[key].date) {
        if (!matchDates[dKey]) { matchDates[dKey] = [] }
        matchDates[dKey].push(matchData[key])
      }
    })
  }
  return matchDates
}

// export const groupMatchesByLevelAndSection = (matches, favs) => {

//   const groups = {
//     favs: [],
//     all: [],
//     noScore: [],
//     playoffs: []
//   }

//   const checkFav = (match) => {
//     let isFav = false
//     Object.keys(favs).forEach(key => {
//       if (key === match.home || key === match.away) {
//         isFav = true
//       }
//     })
//     return isFav
//   }

//   Object.keys(matches).forEach(key => {

//     const match = matches[key]
//     let groupKey = match.level

//     if (match.isPlayoff) {
//       if (!groups.playoffs[match.level]) { groups.playoffs[match.level] = [] }
//       groups.playoffs[match.level].push(match)
//     } else {
//       if (match.section) { groupKey = groupKey + '_' + _.startCase(match.section) }
//       if (!groups.all[groupKey]) { groups.all[groupKey] = [] }

//       groups.all[groupKey].push(match)
//     }

//     if (match.isPast && (!match.score && !match.scores)) {
//       groups.noScore.push(match)
//     }

//     if (favs) {
//       if (checkFav(match)) {
//         groups.favs.push(match)
//       }
//     }
//   })

//   return groups
// }

// export const groupTeamsBySection = (byType, teams) => {

//   const levels = {}
//   const levelGroups = []

//   Object.keys(teams).forEach(teamKey => {

//     const team = teams[teamKey]

//     let levelKey = team.level
//     let sectionKey = team.section

//     // create the level
//     if (!levels[levelKey]) {
//       levels[levelKey] = { sections: {} }
//       levelGroups.push(levelKey)
//     }

//     const level = levels[levelKey]

//     if (!level.sections[sectionKey]) { level.sections[sectionKey] = {} }
//     const section = level.sections[sectionKey]
//     section[teamKey] = team
//     section[teamKey].itemKey = teamKey
//   })

//   Object.keys(levels).forEach(levelKey => {
//     const level = levels[levelKey]
//     const sectionsSorted = sortAndBackChild(byType, level, 'sections')
//     level.sections = sectionsSorted
//   })

//   return {
//     levels: levels,
//     levelGroups: levelGroups
//   }
// }

// export const groupTeamsByLevelThenSection = (byType, teams) => {

//   const levels = {}
//   const levelGroups = []

//   teams.forEach(team => {

//     const { key: teamKey } = team

//     // const team = teams[teamKey]

//     if (team.levels) {
//       let levelKey = team.levels
//       let sectionKey = team.sections

//       // create the level
//       if (!levels[levelKey]) {
//         if (byType === 'sections') {
//           levels[levelKey] = { sections: {} }
//         } else {
//           levels[levelKey] = {}
//         }
//         levelGroups.push(levelKey)
//       }

//       const level = levels[levelKey]

//       if (byType === 'sections') {
//         if (!level.sections[sectionKey]) { level.sections[sectionKey] = {} }
//         const section = level.sections[sectionKey]
//         section[teamKey] = team
//         // section[teamKey].itemKey = teamKey
//       } else {
//         level[teamKey] = team
//         // level[teamKey].itemKey = teamKey
//       }
//     }

//   })

//   // sort by record
//   if (byType === 'sections') {
//     Object.keys(levels).forEach(levelKey => {
//       const level = levels[levelKey]
//       const sections = {}
//       const sectionsSorted = sortAndBackChild(byType, level, 'sections')
//       Object.keys(sectionsSorted).forEach(sectionKey => {
//         sections[sectionKey] = sortDB.sortSectionStandings(sectionsSorted[sectionKey])
//       })
//       level.sections = sections
//     })
//   } else {
//     Object.keys(levels).forEach(levelKey => {
//       let level = levels[levelKey]
//       const levelsSorted = sortAndBackChild(byType, level)
//       levels[levelKey] = sortDB.sortSectionStandings(levelsSorted)
//     })
//   }

//   return {
//     levels: levels,
//     levelGroups: levelGroups
//   }
// }

// export const groupTeamsByLevel = (teams, favs, desc) => {

//   const groups = {
//     favs: [],
//     all: [],
//   }

//   const checkFav = (match) => {
//     let isFav = false
//     Object.keys(favs).forEach(key => {
//       if (key === match.home || key === match.away) {
//         isFav = true
//       }
//     })
//     return isFav
//   }

//   Object.keys(teams).forEach(key => {

//     const team = teams[key]

//     if (team.level) {

//       const groupKey = team.level // alterLevelName(team.level)

//       team.itemKey = key
//       if (!groups.all[groupKey]) {
//         groups.all[groupKey] = {}
//         groups.all[groupKey].altName = alterLevelName(team.level)
//       }

//       if (desc) {
//         groups.all[groupKey][key] = team
//       } else {
//         groups.all[groupKey][key] = team
//       }



//       if (favs) {
//         if (checkFav(team)) {
//           groups.favs.push(team)
//         }
//       }
//     }
//   })

//   return groups
// }

// export const getLevels = (teams) => {

//   const groups = []

//   Object.keys(teams).forEach(key => {
//     const team = teams[key]
//     const groupKey = team.level
//     if (groups.includes(groupKey) === false) {
//       groups.push(groupKey)
//     }
//   })

//   return groups
// }

export const sortDB = {
  sortBy(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => (
      b[prop] - a[prop]
    ))
  },
  sortByDesc(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    const x = _ar.sort((a, b) => (
      b[prop] - a[prop]
    ))
    return x.reverse()
  },
  sortSWP(ar) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => {
      if (a.record && b.record) {
        return b.record.swp - a.record.swp || b.record.sw - a.record.sw || a.record.sl - b.record.sl
      }
    })
    // return ar.sort((a, b) => (
    //   b.record.swp - a.record.swp || b.record.sw - a.record.sw || a.record.sl - b.record.sl
    // ))
  },
  sortBy2(ar, prop1, prop2) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => (
      b[prop1] - a[prop1] || b[prop2] - a[prop2]
    ))
  },
  sortByNumber(ar, prop) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => {
      const propA = parseInt(a[prop], 10)
      const propB = parseInt(b[prop], 10)
      if (propA > propB) { return -1 }
      if (propA < propB) { return 1 }
      return 0
    })
  },
  sortByTeamMWP(ar, prop1, prop2) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return _ar.sort((a, b) => {

      const prop1A = a[prop1]
      const prop1B = b[prop1]

      const prop2A = a[prop2]
      const prop2B = b[prop2]

      return prop1B - prop1A || prop2B - prop2A
    })
  },

  sortByTeamMWPW(ar, prop1, prop2, prop3) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return ar.sort((a, b, c) => {

      const prop1A = a[prop1]
      const prop1B = b[prop1]
      const prop1C = c[prop3]

      const prop2A = a[prop2]
      const prop2B = b[prop2]
      const prop2C = c[prop3]

      return prop1C - prop1B - prop1A || prop2C - prop2B - prop2A
    })
  },

  sortByProp(ar, prop1) {
    const _ar = Array.isArray(ar) ? ar : Object.values(ar)
    return ar.sort((a, b) => {

      const prop1A = a[prop1]
      const prop1B = b[prop1]

      if (prop1A > prop1B) { return -1 }
      if (prop1A < prop1B) { return 1 }

      return 0
    })
  },

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  sort: function () {
    const a = []
    // convert to array
    Object.keys(this.teams).forEach(key => {
      const team = this.teams[key]
      team.mwp = (team.matchWins / (team.matchWins + team.matchLosses)).toFixed(3)
      team.swp = (team.setWins / (team.setWins + team.setLosses)).toFixed(3)
      team.name = key
      a.push(team)
    })
  },

  sortSectionStandings: function (teams, playoffTeamsPerSection) {

    const sectionPlayoffTeams = playoffTeamsPerSection ? playoffTeamsPerSection : 4
    const teamArray = []
    const _teams = {}
    let lastPlayoffTeam = null

    // convert to array
    teams.forEach(team => {
      const { record } = team
      if (record) {
        record.mwp = .500
        record.swp = .500
        if (record.w + record.l > 0) { record.mwp = (record.w / (record.w + record.l)) }
        if (record.sw + record.sl > 0) { record.swp = (record.sw / (record.sw + record.sl)) }
        record.display = '(' + record.sw + '-' + record.sl + ') (' + record.w + '-' + record.l + ')'
      }
      teamArray.push(team)
    })

    const sortedTeams = this.sortSWP(teamArray)

    sortedTeams.forEach((sortedTeam, index) => {

      _teams[sortedTeam.name] = sortedTeam

      const { record } = sortedTeam

      if (record) {
        record.sectionRank = index + 1

        switch (record.sectionRank) {
          case 1:
            record.sectionRankCaption = record.sectionRank + 'st'
            break;
          case 2:
            record.sectionRankCaption = record.sectionRank + 'nd'
            break;
          case 3:
            record.sectionRankCaption = record.sectionRank + 'rd'
            break;
          default:
            record.sectionRankCaption = record.sectionRank + 'th'
        }

        if ((index + 1) <= sectionPlayoffTeams) {
          record.playoffTeam = true
          lastPlayoffTeam = sortedTeam
          lastPlayoffTeam.record.LMax = lastPlayoffTeam.record.l + (lastPlayoffTeam.record.mt - lastPlayoffTeam.record.mp)
        } else {
          record.WMax = record.w + (record.mt - record.mp)

          lastPlayoffTeam.record.mwp = (lastPlayoffTeam.record.w / (lastPlayoffTeam.record.w + lastPlayoffTeam.record.LMax)).toFixed(3)
          record.mwp = (record.WMax / (record.WMax + record.l)).toFixed(3)

          if (record.swp < lastPlayoffTeam.record.swp) {
            record.eliminated = true
          } else if (record.swp === lastPlayoffTeam.record.swp) {
            record.playoffTeam = true
            record.tied = true
          }
        }
      }
    })

    return _teams
  },

  sortLevelStandings: function (teams) {

    const teamArray = []

    // convert to array
    teams.forEach(team => {
      const { record } = team
      if (record) {
        record.mwp = .500
        record.swp = .500
        if (record.w + record.l > 0) { record.mwp = (record.w / (record.w + record.l)) }
        if (record.sw + record.sl > 0) { record.swp = (record.sw / (record.sw + record.sl)) }
        record.display = '(' + record.sw + '-' + record.sl + ') (' + record.w + '-' + record.l + ')'
      }
      teamArray.push(team)
    })

    const sortedTeams = this.sortSWP(teamArray)

    sortedTeams.forEach((st, index) => {
      st.playoffSeed = index
    })

    return sortedTeams
  }
}

const sortAndBackChild = (byType, list, child) => {
  const sortedItems = list
  const items = {}
  sortedItems.forEach((key, index) => {
    items[key] = (byType === 'section') ? list[child][key] : list[key]
  })
  return items
}