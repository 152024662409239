import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { default as ReactSidebar } from 'react-sidebar';
import { Ref } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import PageAreaContent from '../cnr/contexts/PageAreaContent';
import PageComponentProvider from '../cnr/contexts/PageComponentContext';
import { PageContext } from '../cnr/contexts/PageContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import SplashContextProvider from '../cnr/contexts/SplashContext';
import { startTypes } from '../cnr/reducers/StartReducer';
import PendingWait from '../components/alerts/pendings/PendingWait';
import Banner from '../components/sem/SemBanner';
import Banners from '../components/sem/SemBanners';
import AppSidebar from '../components/sidebars/AppSidebar';
import Desktop from "../desktop/Desktop";
import DesktopFooter from '../desktop/DesktopFooter';
import { allPageTypes } from "../enums/allPageTypes";
import { gEnums } from '../enums/globalEnums';
import AppWrapper from '../wrappers/AppWrapper';
import DesktopWrapper from '../wrappers/DesktopWrapper';
import Wrapper from "../wrappers/Wrapper";
import AppContentAlt from './AppContentAlt';
import PageNavigation from './PageNavigation';
import PageTopHeader from './PageTopHeader';
import SettingsAppTopMenu from './SettingsAppTopMenu';
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';

const AppAccessPage = React.lazy(() => import("../components/alerts/AppAccessPage"));
const RestrictedPage = React.lazy(() => import("../components/alerts/RestrictedPage"));
const QrCode = React.lazy(() => import("../pageItem/modification/pageItemActions/QrCode"));
const UnderMaintenancePage = React.lazy(() => import("../components/alerts/UnderMaintenancePage"));

/**
 * This is the Main App Content
 * @returns 
 */
const AppContent = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings, navigate } = parentContext ? parentContext : {}
  const { appSettings_state, appUser_state, page_state, paps_state } = states
  const { appUser_fns } = fns
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global, aps_page, aps_styles, aps_appUserSettings } = pageSettings ? pageSettings : {}
  const { collectProfileInformation } = aps_appUserSettings ? aps_appUserSettings : {}
  const { clientSettings } = settings ? settings : {}
  const { global: global_client } = clientSettings ? clientSettings : {}
  const { appSignIn: appSignIn_client } = global_client ? global_client : {}
  const { redirectToCurrentEvent } = appSignIn_client ? appSignIn_client : {}

  // frameworkContext - not in parent
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ? frameworkContext : {}
  const { cnp, desktopMode, mediaDeviceType, addSettings, standalone, isMobileDevice, showAppQrCode, useShortLink, showSignIn } = framework_state ? framework_state : {}

  // pageContext
  const pageContext = useContext(PageContext);

  // authContext 
  const { appUser, homePageResponded, accessConfirmed } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { loggedIn: loggedIn_au, accessLevel } = appUserAccess ? appUserAccess : {}

  // appSettingsContext 
  const { themeSelected } = appSettings_state ? appSettings_state : {}
  let { desktopOn, desktopMobileOn, showHomeScreenPrompt, showHomeScreenHelp } = appSettings_state ? appSettings_state : {}

  // papsContext 
  const { otherView, landingView, view, pathViews, rootPaths } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}

  // pageContext 
  const { topMenu, navigation, appStatus, banners: app_banners, appNotifications, appInstallation, projectOptions, appSignIn, appAccess } = aps_global ? aps_global : {}
  const { appAccessType, passcode } = appAccess ? appAccess : {}
  const { showBannersPages, bannersLocation } = app_banners ? app_banners : {}
  const { forceInstall } = appSignIn ? appSignIn : {}

  const _forceInstall = forceInstall && !standalone && isMobileDevice

  let { useAppDarkMode } = projectOptions ? projectOptions : {}
  let { menuLocation } = topMenu ? topMenu : {}
  let { showNavigation } = navigation ? navigation : {}
  let { eventStatusType } = appStatus ? appStatus : {}

  const settingsPermissionType = appUser_fns.validate_trueSettingsAuth()
  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.pageItemDimmer] : {}

  let allowBannersOnPage = otherView ? false : true
  const isLanding = view === landingView

  // local state
  const [pageAccess, setPageAccess] = useState({ pending: true })
  const [allowAppView, setAllowAppView] = useState()
  const [showAppAccess, setShowAppAccess] = useState(false)
  // const [appContentStatus, setAppContentStatus] = useState()

  const ammendAccess = () => {

    if (aps_page && aps_global) {
      // aps_page, paps_state, aps_global, aps_appUserSettings
      const { allow: _allowPageView } = appUser_fns.validateAccess_pageView(aps_page, paps_state, aps_global, aps_appUserSettings)

      let _allowed;
      switch (_allowPageView) {
        case gEnums.viewPermissionTypes.allow:
        case gEnums.viewPermissionTypes.allow_clientLimited:
          _allowed = true
          break;
        default:
          _allowed = false
      }

      const x = { allowed: _allowed, allowPageView: _allowPageView, pending: false }
      setPageAccess(x)

      const _allowAppView = appStatus ? appUser_fns.validateAccess_app(appUser, appStatus) : true
      setAllowAppView(_allowAppView)

      // setAppContentStatus({
      //   allowAppView: _allowAppView,
      //   pageAccess: { allowed: _allowed, allowPageView: _allowPageView },
      //   eventStatusType: eventStatusType ? eventStatusType : gEnums.eventStatusTypes.active
      // })
    }
  }

  useEffect(() => {
    ammendAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, aps_page, aps_global, appUserAccess]);

  useEffect(() => {
    if (aps_page && aps_global && appUser && collectProfileInformation) {
      if (appUser && appUser.profileData && !appUser.profileData.profileUpdated) {
        // setShowProfileWizard(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess]);

  useEffect(() => {
    if (appAccessType) {
      switch (appAccessType) {
        case gEnums.siteAuthorizationTypes.passcode:
          if (accessConfirmed === passcode) {
            setShowAppAccess(false)
          } else {
            setShowAppAccess(true)
          }
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [accessConfirmed]);

  useEffect(() => {
    // redirect the appUser to the current event
    if (loggedIn_au && redirectToCurrentEvent && !pathViews.events && appUserAccess.activeEvents_appUser && accessLevel < gEnums.authLevels.appDataAdmin.value) {
      if (appUserAccess.activeEvents_appUser.length > 0) {
        const event_appUser = appUserAccess.activeEvents_appUser[0]
        const _cp = rootPaths.clients + 'events/' + event_appUser + '/landing'
        console.log('_cp', _cp)
        navigate(_cp)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToCurrentEvent]);

  const contextRef = useRef()

  const banner = () => isLanding && <Banner />
  const banners = () => {
    let allow = showBannersPages && showBannersPages.includes(view)
    if (isLanding || allow) { return <Banners /> }
  }

  /** This is the top */
  const pageTopHeader = () => <PageTopHeader />

  /**
   * 
   * @returns PageAreaContent wrapped in PageComponentProvider
   */
  const pageAreaContent = () => <PageComponentProvider>
    <PageAreaContent />
  </PageComponentProvider>

  const desktopPage = () =>
    <Ref innerRef={contextRef}>
      <DesktopWrapper
        banner={allowBannersOnPage && banner()}
        banners={allowBannersOnPage && banners()}
        menu={menuLocation === gEnums.menuLocations.top && pageTopHeader()}
        content={pageAreaContent()}
        navigation={showNavigation && !otherView && <PageNavigation />}
        footer={desktopOn && < DesktopFooter />}
        css={{ container: themeSelected ? 'theme-on theme-on-' + themeSelected : null, menu: menuLocation }}
        desktopMode={desktopOn}
        innerRef={contextRef}
        bannersLocation={bannersLocation}
      ></DesktopWrapper>
    </Ref>

  const appPage = () => <AppWrapper
    appInstallation={appInstallation}
    appNotifications={appNotifications}
    appSignIn={appSignIn}
    banner={allowBannersOnPage && banner()}
    banners={allowBannersOnPage && banners()}
    bannersLocation={bannersLocation}
    content={pageAreaContent()}
    css={{ container: themeSelected ? 'theme-on theme-on-' + themeSelected : null, menu: menuLocation }}
    desktopMobileOn={desktopMobileOn}
    desktopMode={desktopOn}
    eventKey={eventKey}
    footer={desktopOn && <DesktopFooter />}
    forceInstall={_forceInstall}
    homePageResponded={homePageResponded}
    menu={menuLocation === gEnums.menuLocations.top && pageTopHeader()}
    navigation={showNavigation && !otherView && <PageNavigation />}
    showHomeScreenPrompt={showHomeScreenPrompt && !homePageResponded}
    showHomeScreenHelp={showHomeScreenHelp}
    useAppDarkMode={useAppDarkMode}
  ></AppWrapper>

  /**
   * 
   * @returns appPage OR desktopPage depending on desktopOn
   */
  const normalContent = () => desktopOn ? desktopPage() : appPage()

  const otherContent = () => {

    let oc;

    switch (otherView) {
      case allPageTypes.appProfile:
      case allPageTypes.appUserInfo:
      case allPageTypes.help:
      case allPageTypes.signIn:
        let cn;
        if (cnp) { cn += ' ' + cnp }
        oc = <Wrapper
          content={<PageAreaContent />}
          css={{ container: cn, header: 'padd5' }}
        />
        break;
      default:
        oc = <PageAreaContent />
    }

    if (desktopOn || desktopMode) {
      return <Ref innerRef={contextRef}>
        <Desktop
          headerContent={pageTopHeader(true)}
          appContent={oc}
        />
      </Ref>
    } else {
      return appPage()
    }
  }

  const mainContent = () => <SplashContextProvider>
    <ReactSidebar {...AppSidebar({ frameworkContext, pageContext })}>
      {mediaDeviceType !== gEnums.mediaDeviceTypes.mobile && settingsPermissionType === gEnums.viewPermissionTypes.allow && addSettings && <SettingsAppTopMenu />}
      {!otherView && normalContent()}
      {otherView && otherContent()}
    </ReactSidebar>
  </SplashContextProvider >

  const appContentOther = () => <Suspense fallback={<SuspenseDimmer origin={'App Content'} />}>
    <QrCode qrCodeType={gEnums.qrCodeTypes.appLink} fromSidebar={true} useShortLink={useShortLink} />
  </Suspense>

  const appContent = () => {
    if (showAppQrCode) {
      return appContentOther()
    } else if (showSignIn && !loggedIn_au) {
      // show the sign in page
      return <AppContentAlt view={view} pageAccess={pageAccess} appUser={appUser} loggedIn={loggedIn_au} fromDirect={true} />
    } else if (pageAccess.allowed) {
      return mainContent()
    } else {
      return <AppContentAlt view={view} pageAccess={pageAccess} appUser={appUser} loggedIn={loggedIn_au} />
    }
  }

  // check to see if something is not allowed  
  if (showAppAccess) {
    return <AppAccessPage />
  } else {
    switch (allowAppView) {
      case gEnums.eventStatusTypes.restricted:
        return <RestrictedPage />
      default:
        switch (eventStatusType) {
          case gEnums.eventStatusTypes.underMaintenance:
            return <UnderMaintenancePage />
          default:
            if (pageAccess.pending) {
              return <PendingWait inverted={true} styleAndClass={styleAndClass} startType={startTypes.pageItem} />
            } else {
              return appContent()
            }
        }
    }
  }
}

export default AppContent