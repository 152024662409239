export const printDoc = (id) => {
  var mywindow = window.open('', 'PRINT', 'height=400,width=600');
  mywindow.document.write('<html><head><title>' + document.title + '</title>');
  mywindow.document.write('</head><body >');
  mywindow.document.write('<h1>Help Document</h1>');
  mywindow.document.write(document.getElementById(id).innerHTML);
  mywindow.document.write('</body></html>');
  mywindow.document.close(); // necessary for IE >= 10 

  mywindow.document.head.appendChild(loadFile('%PUBLIC_URL%/src/AppA.css', 'css'))
  mywindow.focus(); // necessary for IE >= 10*/ 
  mywindow.print();
  mywindow.close();
  return true;
}

const loadFile = (file, type) => {
  let fileref;
  if (type === "js") {
    fileref = document.createElement("script")
    fileref.setAttribute("type", "text/javascript")
    fileref.setAttribute("src", file)
  } else if (type === "css") {
    fileref = document.createElement("link")
    fileref.setAttribute("rel", "stylesheet")
    fileref.setAttribute("type", "text/css")
    fileref.setAttribute("href", file)
  }
  return fileref
}