import _ from 'lodash';
import { gEnums } from '../../enums/globalEnums';
import { googlePreviewTypes } from '../../cnr/reducers/GoogleSheetsReducer';
import { setStyleAndClass } from '../../styles/formatting';

const _appUserStuff = [
  'appUserDataSettings',
  'appUserImageOptions',
  'appUserOptions',
  'appUserPagePermissions',
  'appUserPermissions',
  'appUserProfileInfo',
  'appUserSettings',
  'appUserUiOptions',
]

/** Get the settings for the page */
export const getPageSettingsProps = (props) => {

  const { pathViews, lastView, hceStates } = props

  const { homeSettings_state, clientSettings_state, eventSettings_state } = hceStates ? hceStates : {}

  let area_settings;

  // eslint-disable-next-line
  let settingsFor;

  if (pathViews) {
    if (pathViews.events || lastView === 'events') {
      area_settings = eventSettings_state ? eventSettings_state.eventSettings : {}
      settingsFor = 'events'
    } else if (pathViews.clients || lastView === 'clients') {
      area_settings = clientSettings_state ? clientSettings_state.clientSettings : {}
      settingsFor = 'clients'
    } else {
      area_settings = homeSettings_state ? homeSettings_state.homeSettings : {}
      settingsFor = 'home'
    }
  }

  return {
    area_settings,
    time_stamp: area_settings ? area_settings.time_stamp : null,
    tss: Date.now(),
    settingsFor: settingsFor
  }
}

const ammendPageViewItems = (viewItems) => {
  if (viewItems) {
    Object.keys(viewItems).forEach(viKey => {
      if (!viewItems[viKey].key) {
        if (_.isObject(viewItems[viKey])) {
          viewItems[viKey].key = viKey
        }
      }
    })
  }
  return viewItems
}

const getLists = (viewItems) => {

  const viewItemsList = []
  const allPropsList = []

  if (viewItems) {
    Object.keys(viewItems).forEach(viKey => {
      if (!viewItemsList.includes(viKey)) { viewItemsList.push(viKey) }
      const gvi = viewItems[viKey]
      if (!gvi.key) { gvi.key = viKey }
      if (gvi.props) {
        Object.keys(gvi.props).forEach(prKey => {
          if (!allPropsList.includes(prKey)) { allPropsList.push(prKey) }
        })
      }
    })
  }

  return {
    viewItemsList,
    allPropsList
  }
}

/**
 * Returns 
 * @param {object} props 
 * @returns the current settings for the page
 */
export const getAreaSettingsProps = (props) => {

  const { view, pageKey, pss, trueView, settingsPreviewOn, preview_state, setPageSettings, googleSheets_state } = props
  const { settingsPreviewHasChanged } = preview_state ? preview_state : {}

  const { area_settings } = pss
  const { global, viewItems: pageItems, views, settingsDocName, isGlobalStatic, isViewStatic, missingSettings } = area_settings ? area_settings : {}
  const pageView = views ? views[trueView] : {}
  const { viewItems: viewItems_page } = pageView ? pageView : {}
  const { appSettings: appSettings_google, googlePreviewType } = googleSheets_state ? googleSheets_state : {}

  const _aps_appUserSettings = {}

  if (global) {
    Object.keys(global).forEach(k => {
      if (_appUserStuff.includes(k)) {
        const _appUserPrefixed = global[k]
        if (_appUserPrefixed) {
          Object.keys(_appUserPrefixed).forEach(ko => {
            _aps_appUserSettings[ko] = _appUserPrefixed[ko]
          })
        }
      }
    })
  }

  ammendPageViewItems(viewItems_page)

  let globalSettings_temp;
  let viewItems_temp;
  let pageView_temp;

  let aps_page = pageView_temp ? pageView_temp : pageView

  if (aps_page && aps_page.viewItems) {
    Object.keys(aps_page.viewItems).forEach(k => {
      if (_.isObject(aps_page.viewItems[k])) {
        aps_page.viewItems[k].viewPermissionType = 'allow'
      }
    })
  }

  // set the caption if none exists
  if (aps_page && !aps_page.menu) {
    const isList = trueView && trueView.indexOf('_list') >= 0
    const _props = !isList && aps_page && aps_page.viewItems && aps_page.viewItems[view] && aps_page.viewItems[view].props
    const { firstName, lastName } = _props ? _props : {}
    const _captionProps = firstName && lastName ? ['firstName', 'lastName'] : ['name']
    aps_page.menu = {
      captionType: isList ? gEnums.captionTypes.fixed : gEnums.captionTypes.fromData,
      caption: _.startCase(view),
      captionProps: !isList ? _captionProps : null
    }
  }

  const appSettings = {
    aps_global: globalSettings_temp ? globalSettings_temp : global,
    aps_viewItems: viewItems_temp ? viewItems_temp : pageItems,
    aps_views: views,
    aps_page: aps_page,
    aps_appUserSettings: _aps_appUserSettings
  }

  if (appSettings.aps_global) {
    const globalStyles = []
    Object.keys(gEnums.projectStyles).forEach(psKey => {
      globalStyles[psKey] = setStyleAndClass(appSettings.aps_global, psKey)
    })
    appSettings.aps_styles = globalStyles
  }

  if (settingsPreviewOn && settingsPreviewHasChanged) {
    const { settings_preview } = preview_state ? preview_state : {}
    if (settings_preview) {
      const { gvvi, page } = settings_preview ? settings_preview : {}
      const { global, viewItems, views } = gvvi ? gvvi : {}
      appSettings.aps_global = global
      appSettings.aps_viewItems = viewItems
      appSettings.aps_views = views
      appSettings.aps_page = page
    }
  }

  let _appSettings_google;

  if (appSettings_google && googlePreviewType !== googlePreviewTypes.dataOnly) {
    const { aps_views } = appSettings_google ? appSettings_google : {}
    // const { views: view_g } = projectSettings ? projectSettings : {}
    // const { global: global_g, viewItems: viewItems_g, views: view_g } = projectSettings ? projectSettings : {}
    // const pageView_google = aps_views ? aps_views[trueView] : {}
    _appSettings_google.aps_page = aps_views ? aps_views[trueView] : {}
    // appSettings_google = {
    // aps_global: global_g,
    // aps_viewItems: viewItems_g,
    // aps_views: view_g,
    //   aps_page: pageView_google,
    // }
  }

  const _appSettings = appSettings_google ? appSettings_google : appSettings

  const ps = {
    settingsDocName,
    ..._appSettings,
    ...getLists(pageItems),
    isGlobalStatic,
    isViewStatic,
    pageSettingsKey: pageKey,
    missingSettings,
  }

  if (setPageSettings) {
    setPageSettings(ps)
  } else {
    return ps
  }
}

// export const ammendAreaSettingsProps = (googleAppSettings) => {

//   let appSettings_google;

//   if (googleAppSettings) {
//     const { projectSettings } = googleAppSettings ? googleAppSettings : {}
//     const { global: global_g, viewItems: viewItems_g, views: view_g } = projectSettings ? projectSettings : {}
//     appSettings_google = {
//       aps_global: global_g,
//       aps_viewItems: viewItems_g,
//       aps_views: view_g,
//     }
//   }

//   const ps = { ...appSettings_google }
//   return ps
// }