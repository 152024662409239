import _ from 'lodash';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { subAppTypes } from '../../../AppSub';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { filterObject } from '../../common/filtering';
import { uniqueKey } from '../../common/keys';
import { sortObject } from '../../common/sorting';
import PageError, { pageErrorReasonTypes } from '../../components/alerts/PageError';
import SuspenseDimmer from '../../components/alerts/SuspenseDimmer';
import ImageMap from '../../components/swipe/ImageMap';
import { UiSwiperWithProvider } from '../../components/swipers/UiSwiper';
import { userProfileItems } from '../../components/user/userProfileItems';
import UiPaokeSwiperGeneric from '../../components_npm/UiPaokeSwiper';
import { allPageTypes } from '../../enums/allPageTypes';
import { gEnums } from '../../enums/globalEnums';
import PageContent from '../../page/PageContent';
import PageItem from '../../page/PageItem';
import UiWrapper from '../../wrappers/UiWrapper';
import { swipePropNew } from '../reducers/reducerHelpers/swipeHelpers';
import { startTypes } from '../reducers/StartReducer';
import { FrameworkContext } from './FrameworkContent';
import PageAreaProvider from './PageAreaContext';
import PageAreaParentProvider from './PageAreaParentContext';
import PageDataProvider from './PageDataContext';
import { ParentContext } from './ParentContext';
import { QuestionsAndAnswersContext } from './QuestionsAndAnswersContext';
import ErrorBoundary from '../../components/errorHandling/ErrorBoundery';

// lazy these. They are not normally used
// const { ImageMap, imageMapTypes } = React.lazy(() => import("../../components/swipe/ImageMap"));

// GOOD TO GO
const AppUserProfilePagesWithProvider = React.lazy(() => import("../../components/user/AppUserProfileItems"));
const DirectTicket = React.lazy(() => import("../../../projectSpecific/ticketing/components/DirectTicket"));
const EmailSignInConfirmation = React.lazy(() => import("../../validation/EmailSignInConfirmation"));
const GolfEventWrapper = React.lazy(() => import("../../../projectSpecific/golf/pages/GolfEventWrapper"));
const GolfTournamentWrapper = React.lazy(() => import("../../../projectSpecific/golf/pages/GolfTournamentWrapper"));
const HomePage = React.lazy(() => import("../../page/HomePage"));
const NotificationForeground = React.lazy(() => import("../../components/alerts/NotificationForeground"));
const PageAreaContentAlt = React.lazy(() => import("./PageAreaContentAlt"));
const SignIn = React.lazy(() => import("../../components/auth/SignIn"));
const Waiver = React.lazy(() => import("../../../globalAlt/page/Waiver"));

/**
 * 
 * @returns The main content of the app.
 * @description Mostly returns `PageContent`, but depending on certain 'views', it will return something else.
 */
const PageAreaContent = () => {

  const _apits = gEnums.additionalPageItemTypes

  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ? parentContext : {}
  const { appNotifications_state, appUser_state, page_state, paps_state, start_state } = states
  const { start_handlers } = handlers
  const { appUser_fns } = fns
  const { pageSettings } = page_state ? page_state : {}
  const { startType } = start_state ? start_state : {}
  const { aps_global, aps_page, aps_appUserSettings, isGlobalStatic, pageSettingsKey, missingSettings } = pageSettings ? pageSettings : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { accessLevel, email: appUserEmail, loggedIn } = appUserAccess ? appUserAccess : {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ? frameworkContext : {}
  const { desktopMode } = framework_state ? framework_state : {}
  const { handleShowSignIn } = framework_handlers ? framework_handlers : {}

  // questionsAndAnswersContext
  const questionsAndAnswersContext = useContext(QuestionsAndAnswersContext)
  const { questionsAndAnswers_state } = questionsAndAnswersContext ? questionsAndAnswersContext : {}
  const { questionsAndAnswers } = questionsAndAnswers_state ? questionsAndAnswers_state : {}

  // papsContext 
  const { ddGroupIndex, fullSettingsKey, nonLandingView, otherView, pageKey, pathViews, permissionType, view, viewKey } = paps_state ? paps_state : {}
  const { events: eventKey } = pathViews ? pathViews : {}

  const _questionsAndAnswers = questionsAndAnswers && viewKey ? questionsAndAnswers[viewKey] : null

  // pageContext  
  const { desktop, homePage, waiver, projectOptions, projectModules, appGallery, appNotifications } = aps_global ? aps_global : {}
  const { allowGallery, galleryPages } = appGallery ? appGallery : {}
  const { appUserCollection, showAppUserPersonalPages, showAppUserProfilePages, showAppUserPrivacyPages, groupAppUserPages, groupCaption, showProfileSettingsAsPublic } = aps_appUserSettings ? aps_appUserSettings : {}
  const { showHomePage, homePageText } = homePage ? homePage : {}
  const { showWaiver, waiverText } = waiver ? waiver : {}
  const { useDefaultLayoutType, defaultLayoutType } = desktop ? desktop : {}
  const { useComponentSwiperPackage } = projectOptions ? projectOptions : {}
  const { showNotificationsOnForeground } = appNotifications ? appNotifications : {}

  const _showAppUserPages = showAppUserPersonalPages || showAppUserProfilePages || showAppUserPrivacyPages
  const { newPayload, notifications_appUser } = appNotifications_state ? appNotifications_state : {}
  const { hasNew } = notifications_appUser ? notifications_appUser : {}

  // same source 
  const { displayType, viewType, viewItems: viewItems_page, layout: layout_page, desktopLayout: desktopLayout_page } = aps_page ? aps_page : {}
  const { feeds: feeds_asp, geoList: geoList_asp, geoLocation: geoLocation_asp, imageLinks: imageLinks_asp, questionsAndAnswers: questionsAndAnswers_asp, twitter: twitter_asp, videoLinks: videoLinks_asp, voting: voting_asp } = aps_page ? aps_page : {}

  const layout = desktopMode && desktopLayout_page ? desktopLayout_page : layout_page
  const { layoutType, pageIcons: uiSelectedIcons, selectedItems: uiSelectedItems, fixFirstViewItem, gridTemplateRows } = layout ? layout : {}

  const _layoutType = desktopMode && useDefaultLayoutType && defaultLayoutType ? defaultLayoutType : layoutType

  const _seenItem_hp = eventKey ? 'homePageSeen-' + eventKey : 'homePageSeen'
  const _homePageSeen = localStorage.getItem(_seenItem_hp)

  const seenItem_w = eventKey ? 'waiverSeen-' + eventKey : 'waiverSeen'
  const _waiverSeen = localStorage.getItem(seenItem_w)

  useEffect(() => {
    if (startType !== startTypes.finish) {
      start_handlers.handleUpdateStart(startTypes.finish)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [startType, appUserAccess]);

  /** returns the viewItems/pageItems for the page where `show` is set to true */
  const getShownPageItems = () => {

    // get only viewItems marked with show = true
    const showProp = desktopMode ? gEnums.showTypes.showDesktop : gEnums.showTypes.show

    // list of pageItems with their props filter to shown only
    let pageItems_adjusted = filterObject(viewItems_page, showProp)

    appUser_fns.validateAccess_items(pageItems_adjusted)

    let splitItems;

    if (pageItems_adjusted) {
      Object.keys(pageItems_adjusted).forEach(key => {
        const pageItemAdjusted = pageItems_adjusted[key]
        const { combine } = pageItemAdjusted ? pageItemAdjusted : {}
        const { useCombine, combineWith } = combine ? combine : {}

        // remove combined pageItems
        if (useCombine && combineWith && combineWith.length > 0) {
          pageItemAdjusted.combinedGroups = {}
          combineWith.forEach(key => {
            pageItemAdjusted.combinedGroups[key] = pageItems_adjusted[key]
            delete pageItems_adjusted[key]
          })
        }
      })
      pageItems_adjusted = sortObject(pageItems_adjusted, 'position')
    }

    if (splitItems) {
      Object.keys(splitItems).forEach(k => {
        pageItems_adjusted[k] = splitItems[k]
      })
    }
    return pageItems_adjusted
  }

  const appUserSwipeItems = (pis) => {
    const keyy = groupCaption ? _.camelCase(groupCaption) : 'profile'
    const x = <AppUserProfilePagesWithProvider key={uniqueKey('aupwp')} />
    pis[keyy] = swipePropNew(keyy, x, false, true)
  }

  /** returns the profile items for the page, if the view/page === appUserCollection */
  const getAppUserProfileItems = () => {
    let pis = {};
    if (appUserCollection === view && (_showAppUserPages)) {
      const aaua = appUser_fns.validateAccess_appUser({ pathViews, appUserCollection, view, viewKey, appUserAccess })
      if (showProfileSettingsAsPublic || aaua) {
        if (groupAppUserPages) {
          appUserSwipeItems(pis)
          const pis2 = userProfileItems(aps_global, aps_appUserSettings, appUser, true)
          // const keyy = groupCaption ? _.camelCase(groupCaption) : 'profile'
          // const x = <AppUserProfileWithProvider key={uniqueKey('aupwp')} />
          // pis[keyy] = swipePropNew(keyy, x, false, true)
        } else {
          pis = userProfileItems(aps_global, aps_appUserSettings, appUser, true)
        }
      }
    } else if (view === 'clients' && loggedIn) {
      // appUserSwipeItems(pis)
    }
    return pis
  }

  /** returns an other items for the page */
  const addOtherPageItem = (displayType, componentProps, existingCount, caption) => {
    return {
      key: displayType,
      position: existingCount ? existingCount : 2,
      show: true,
      display: {
        caption: caption ? caption : _.startCase(displayType),
        displayType: displayType,
      },
      viewPermissionType: gEnums.viewPermissionTypes.allow,
      componentProps
    }
  }

  /** Adds additional pageItems
   * @add feeds, imageLinks, twitter, videoLinks, photos
   */
  const addOtherPageItems = (vpias) => {

    const { allowQuestionsAndAnswers, allowVoting } = projectModules ? projectModules : {}
    const { allowGeoList, allowGeoLocation, allowFeeds, allowImageLinks, allowTwitter, allowVideoLinks } = projectOptions ? projectOptions : {}

    const count = vpias ? Object.keys(vpias).length : 0

    if (vpias) {
      if (allowQuestionsAndAnswers && _questionsAndAnswers && _questionsAndAnswers.questionActive) { vpias[_apits.questionsAndAnswers] = addOtherPageItem(gEnums.displayTypes.questionsAndAnswers, questionsAndAnswers_asp, count, 'Q and A') }

      if (allowQuestionsAndAnswers && questionsAndAnswers_asp && questionsAndAnswers_asp.allowQuestionsAndAnswers) { vpias[_apits.questionsAndAnswers] = addOtherPageItem(gEnums.displayTypes.questionsAndAnswers, questionsAndAnswers_asp, count, 'Q and A') }
      if (allowVoting && voting_asp && voting_asp.allowVoting) { vpias[_apits.voting] = addOtherPageItem(gEnums.displayTypes.questionsAndAnswers, questionsAndAnswers_asp, count, 'Q and A') }

      if (allowFeeds && feeds_asp && feeds_asp.showFeed) { vpias[_apits.feeds] = addOtherPageItem(gEnums.displayTypes.feeds, feeds_asp, count, 'News') }
      if (allowGeoList && geoList_asp && geoList_asp.showGeoList && _apits && vpias) { vpias[_apits.geoList] = addOtherPageItem(gEnums.displayTypes.geoList, imageLinks_asp, count, geoList_asp.geoListCaption ? geoList_asp.geoListCaption : null) }
      if (allowGeoLocation && geoLocation_asp && geoLocation_asp.showGeoLocation && _apits && vpias) { vpias[_apits.geoLocation] = addOtherPageItem(gEnums.displayTypes.geoLocation, imageLinks_asp, count) }
      if (allowImageLinks && imageLinks_asp && imageLinks_asp.showImageLinks) { vpias[_apits.imageLinks] = addOtherPageItem(gEnums.displayTypes.imageLinks, imageLinks_asp, count) }
      if (allowTwitter && twitter_asp && twitter_asp.showTwitter) { vpias[_apits.twitter] = addOtherPageItem(gEnums.displayTypes.twitter, twitter_asp, count) }
      if (allowVideoLinks && videoLinks_asp && videoLinks_asp.showVideoLinks) { vpias[_apits.videoLinks] = addOtherPageItem(gEnums.displayTypes.videoLinks, videoLinks_asp, count) }
    }

    if (allowGallery && galleryPages && galleryPages.includes(view) && _apits && viewKey) {
      if (!vpias) { vpias = {} }
      vpias[_apits.photos] = addOtherPageItem(gEnums.displayTypes.gallery, appGallery, count)
    }
  }

  // local state
  const [profileItems, setProfileItems] = useState()
  const [pageItemsShown, setViewPageItemsShown] = useState()

  const viCount = pageItemsShown ? Object.keys(pageItemsShown).length : 0

  useEffect(() => {

    const _shownPageItems = getShownPageItems()
    const _profileItems = getAppUserProfileItems()

    setProfileItems(_profileItems)

    let _viewItems_page_shown;

    if (otherView && viewItems_page) {
      switch (otherView) {
        case 'add':
          _viewItems_page_shown = { [view]: viewItems_page[view] }
          break;
        case 'edit':
          _viewItems_page_shown = { [view]: viewItems_page[view] }
          break;
        default:
        // nothing
      }
    } else {
      switch (_layoutType) {
        default:
          _viewItems_page_shown = _shownPageItems
      }
    }

    addOtherPageItems(_viewItems_page_shown)
    setViewPageItemsShown(_viewItems_page_shown)
    // return () => window.removeEventListener("transitionend", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullSettingsKey, pathViews, viewItems_page, appUserEmail, accessLevel]);

  const getPageItem = (key, tabIndex) => {
    return { loaded: true, content: <PageItem key={uniqueKey('sipi', key)} uivi={key} tabIndex={tabIndex} from={'swipeItem_pageItem'} /> }
  }

  const pageAreaSwiper = () => <UiSwiperWithProvider
    // key={'pacsp'}
    swipeContentType={gEnums.swipeContentTypes.pageAreaContent}
    view={view}
    fn_getContent={getPageItem}
    fromAppUserProfile={false}
  />

  const paoke_swiper = () => <UiPaokeSwiperGeneric
    _key={'pacsp'}
    swipeContentType={gEnums.swipeContentTypes.pageAreaContent}
    view={view}
    fn_getContent={getPageItem}
  />

  const swiper = () => useComponentSwiperPackage ? paoke_swiper() : pageAreaSwiper()

  const pageContent = () => {

    const isTicketing = pathViews.ticketing ? true : false
    const isImageMapping = view === allPageTypes.imageMapping ? true : false

    if (nonLandingView === subAppTypes.emailSignInConfirmation) {
      return <EmailSignInConfirmation />
    } else if (isTicketing) {
      return <DirectTicket />
    } else if (isImageMapping) {
      return <ImageMap imageMapType={gEnums.imageMapTypes.mapping} />
    } else {
      if (!_homePageSeen && showHomePage && homePageText) {
        return <HomePage />
      } else if (!_waiverSeen && showWaiver && waiverText) {
        return <Waiver />
      } else {
        switch (view) {
          case allPageTypes.appProfile:
          case allPageTypes.appUserInfo:
          case allPageTypes.appUserRequests:
          case allPageTypes.appUsersManager:
          case allPageTypes.conversations:
          case allPageTypes.credentialScan:
          case allPageTypes.favorites:
          case allPageTypes.gallery:
          case allPageTypes.notifications:
          case allPageTypes.notificationsManager:
          case allPageTypes.photos:
          case allPageTypes.ticketingManager:
          case allPageTypes.ticketingScan:
          case allPageTypes.topicsManager:
          case allPageTypes.voting:
            return <PageAreaContentAlt view={view} />

          default:
            switch (viewType) {
              default:
                switch (displayType) {
                  case gEnums.viewDisplayTypes.signIn:
                    return <SignIn handleCancel={handleShowSignIn} />

                  case gEnums.viewDisplayTypes.appProfile:
                  case allPageTypes.appUserInfo:
                    return UiWrapper(<AppUserProfilePagesWithProvider />)

                  default:
                    switch (permissionType) {
                      default:
                        switch (_layoutType) {
                          case gEnums.layoutTypes.swipeable:
                            if (viCount > 1 && !ddGroupIndex) {
                              return swiper()
                            } else {
                              return <PageContent />
                            }

                          case gEnums.layoutTypes.horizontalSliders:
                          case gEnums.layoutTypes.icons:
                          case gEnums.layoutTypes.pageIcons:
                          case gEnums.layoutTypes.pageList:
                          case gEnums.layoutTypes.singlePage:
                            return <PageContent />

                          case gEnums.layoutTypes.golfEvent:
                            return <GolfEventWrapper />

                          case gEnums.layoutTypes.golfTournament:
                            return <GolfTournamentWrapper />

                          default:
                            return <PageError reason={pageErrorReasonTypes.noLayoutType} />
                        }
                    }
                }
            }
        }
        // }
      }
    }
  }

  // IMPORTANT: Wait for the pageSetting to be correct
  if (pageItemsShown && pageSettingsKey === pageKey) {
    return <ErrorBoundary origin={'pageAreaContent'}>
      <PageDataProvider>
        <PageAreaProvider
          fixFirstViewItem={fixFirstViewItem}
          gridTemplateRows={gridTemplateRows}
          profileItems={profileItems}
          uiGroupedItems={uiSelectedIcons}
          uiSelectedItems={uiSelectedItems}
          pageItemsShown={pageItemsShown}
          pageViewItemKeys={pageItemsShown ? Object.keys(pageItemsShown) : []}
        >
          <PageAreaParentProvider>
            <Suspense fallback={<SuspenseDimmer origin={'Page Area Content'} />}>
              {pageContent()}
              {(isGlobalStatic || missingSettings) && <PageError reason={pageErrorReasonTypes.noSettings} />}
              {(showNotificationsOnForeground && (newPayload || hasNew)) && <NotificationForeground />}
            </Suspense>
          </PageAreaParentProvider>

        </PageAreaProvider>
      </PageDataProvider>
    </ErrorBoundary>
  } else {
    return <div></div>
  }
}

export default PageAreaContent