import { arrayRemove, arrayUnion } from "firebase/firestore";
import _ from "lodash";
import { createRefPath_event } from '../../appData/appRefPaths';
import { fs_get_data } from "../../appData/fsAppData";
import { fs_set_doc } from '../../appData/fsData';

export const updateRelatedCollections = (pathViews, vit, existingItem, itemData, itemKey, staticViewKeys) => {
  if (itemData) {
    if (existingItem) {
      // loop the itemData
      Object.keys(itemData).forEach(propKey => {
        // is the propKey a staticView?
        if (staticViewKeys && staticViewKeys.includes(propKey)) {
          // get the value
          const itemPropValue_existing = existingItem ? existingItem[propKey] : null
          const itemPropValue = itemData[propKey]
          // is the value an array?
          if (_.isArray(itemPropValue)) {

            const removeItems = []

            if (itemPropValue_existing) {
              if (_.isArray(itemPropValue_existing)) {
                itemPropValue_existing.forEach(existingItemArrayValue => {
                  if (!itemPropValue.includes(existingItemArrayValue)) {
                    removeItems.push(existingItemArrayValue)
                  }
                })
              }
            }

            updateRelatedItem(pathViews, vit, itemKey, propKey, itemPropValue)
            removeRelatedItem(pathViews, vit, itemKey, propKey, removeItems)
          }
        }
      })
    }
  }
}

const updateRelatedItem = (pathViews, vit, itemKey, propKey, arrayValues) => {
  // loop the values of the itemData's propValue
  arrayValues.forEach(arrayItemValue => {
    // get the propValue's related document
    const _refPath_related = createRefPath_event(pathViews, [propKey, arrayItemValue])
    fs_get_data({ refPath: _refPath_related, opts: { returnFirstObject: true } }).then(relatedItem => {
      // does the relatedItem have a value for the document name of the itemData
      if (relatedItem && relatedItem[vit]) {
        // get the value for the vit in the related itme
        const relatedPropValue = relatedItem[vit]
        // is the value of the related items vit prop an array
        if (_.isArray(relatedPropValue)) {
          // does the value already exist
          if (!relatedPropValue.includes(itemKey)) {
            const relatedDataUpdate = { [vit]: arrayUnion(itemKey) }
            // console.log('_refPath', _refPath_related, relatedDataUpdate)
            // console.log('The ' + vit + ' value for the (' + itemKey + ') is NOT in ' + propKey + '.' + arrayItemValue + '.' + vit)
            fs_set_doc(_refPath_related, relatedDataUpdate, true)
          }
        }
      }
    })
  })
}

const removeRelatedItem = (pathViews, vit, itemey, propKey, removeItems) => {
  // console.log('removeItems', removeItems)
  if (removeItems.length > 0) {
    // loop the values of the itemData's propValue
    removeItems.forEach(removeItemValue => {
      // get the propValue's related document
      const _refPath_related = createRefPath_event(pathViews, [propKey, removeItemValue])
      fs_get_data({ refPath: _refPath_related, opts: { returnFirstObject: true } }).then(relatedItem => {
        // does the relatedItem have a value for the document name of the itemData
        if (relatedItem && relatedItem[vit]) {
          const relatedDataUpdate = { [vit]: arrayRemove(itemey) }
          // console.log('_refPath', _refPath_related, relatedDataUpdate)
          // console.log('The ' + vit + ' value for the (' + itemey + ') is NOT in ' + propKey + '.' + removeItemValue + '.' + vit)
          fs_set_doc(_refPath_related, relatedDataUpdate, true)
        }
      })
    })
  }
}