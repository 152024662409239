import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { Header, Icon, Menu, Message } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import { ActionContext } from '../../cnr/contexts/ActionContext';
import { DataModifyContext } from '../../cnr/contexts/DataModifyContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { SettingsAreaContext } from '../../cnr/contexts/SettingsAreaContext';
import { ViewSettingsComponentContext } from '../../cnr/contexts/ViewSettingsComponentContext';
import { ViewSettingsContext } from '../../cnr/contexts/ViewSettingsContext';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import GenericIcon from '../../genericControls/GenericIcon';
import { HeaderActions } from './HeaderActions';
import HeaderDropdown from './HeaderDropdown';
import { ParentBreadcrumbs } from './ParentBreadcrumbs';

const AllHeaders = (props) => {

  // props
  // UPDATE-TRIGGER
  // sis,
  const { dataHeaderType, isDirect, headerProps } = props
  const { viType, buttonPermissions, stateProps, restrictedAccess, caption: caption_header } = headerProps ? headerProps : {}
  const { sortOk } = stateProps ? stateProps : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ? parentContext : {}
  const { appUser_state, page_state, paps_state } = states ? states : {}
  const { navigate } = handlers ? handlers : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_viewItems } = pageSettings ? pageSettings : {}

  // authContext 
  const { appUser: authAppUser } = appUser_state ? appUser_state : {}
  const { appUserAccess } = authAppUser ? authAppUser : {}

  // pageContext 
  const gvisKeys = aps_viewItems ? Object.keys(aps_viewItems) : []

  // settingsAreaContext
  const settingsAreaContext = useContext(SettingsAreaContext);
  const { settingsArea_state, settingsArea_handlers } = settingsAreaContext ? settingsAreaContext : {}
  const { settingsOriginType, selectedGroupItem } = settingsArea_state ? settingsArea_state : {}
  const { handleCloseGroupItem } = settingsArea_handlers ? settingsArea_handlers : {}

  // viewSettingsContext 
  const viewSettingsContext = useContext(ViewSettingsContext);
  const { viewSettings_state, viewSettings_handlers } = viewSettingsContext ? viewSettingsContext : {}
  const { showViewLinking } = viewSettings_handlers ? viewSettings_handlers : {}
  const { showCopyViewSettings, showDuplicateView, showCreateSheetsDbLink, showCopyData, showPushData, isGlobal } = viewSettings_state ? viewSettings_state : {}

  // viewSettingsComponentContext  
  const viewSettingsComponentContext = useContext(ViewSettingsComponentContext);
  const { vsc_state, vsc_handlers } = viewSettingsComponentContext ? viewSettingsComponentContext : {}
  const { updateProps } = vsc_state ? vsc_state : {}
  let { swipedItem, swipedItems } = vsc_state ? vsc_state : {}
  const { swipeToSelected } = vsc_handlers ? vsc_handlers : {}

  const { homeSettings } = settings ? settings : {}
  const { global: global_home } = homeSettings ? homeSettings : {}
  const { settingsOptions: settingsOptions_home } = global_home ? global_home : {}

  const { useDarkMode } = settingsOptions_home ? settingsOptions_home : {}

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_state, item_handlers } = dataModifyContext ? dataModifyContext : {}
  const { data_modified, isList, confirmation: originalItemData } = dataModify_state ? dataModify_state : {}

  // actionContext
  const actionContext = useContext(ActionContext);
  const { action_handlers } = actionContext ? actionContext : {}

  const allowViewLinking = viType === 'views' ? false : false

  const vit = null

  switch (vit) {
    case 'profiles':
    case 'attendees':
    case 'golfers':
      // showAddAuth = data_modified && (data_modified.email && data_modified.firstName && data_modified.lastName) ? true : false
      break;
    default:
    // nothing
  }

  // local state   
  const [showSort, setSort] = useState()

  const iconOptions = { inverted: useDarkMode, circular: true }

  const handleShowHelp = (a) => viewSettings_handlers.handleShowHelp(a)

  const updateSort = () => {
    viewSettings_handlers.updateSort(swipedItem, updateProps)
    setSort(false)
  }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon key={uniqueKey('ah', 'gi', key)} giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const helpButton = () => gi('help', 'help', handleShowHelp, viType, { ...iconOptions, title: 'Show Help', color: iconColorTypes.help })
  const stopButton = () => gi('stop', appIconTypes.delete, setSort, false)
  const sortButton = () => gi('sortOk', 'check', updateSort, null, { disabled: !sortOk })

  const adminDropdown = (opts) => <HeaderDropdown
    key={uniqueKey('ah', 'gc', 'hdd')}
    buttonPermissions={buttonPermissions}
    dataHeaderType={dataHeaderType}
    headerProps={headerProps}
    isGlobal={isGlobal}
    opts={opts}
    viType={viType}
  />

  const backIcon = () => {

    let oc;

    switch (settingsOriginType) {
      case gEnums.settingsOriginTypes.app:
      case gEnums.settingsOriginTypes.page:
        return gi('back', appIconTypes.arrowLeft, swipeToSelected, null, { fl: true })
      default:
        switch (dataHeaderType) {

          case gEnums.dataHeaderTypes.dataModify:
            if (item_handlers) {
              oc = item_handlers.handleModifyAction
              return gi('back', appIconTypes.delete, oc, null, { fl: true })
            } else {
              if (isDirect) {
                oc = navigate && navigate(-1)
              }
              return gi('back', appIconTypes.arrowLeft, oc, null, { fl: true })
            }

          case gEnums.dataHeaderTypes.itemHeader:
            if (swipedItems && swipedItems.length > 1) {
              return gi('back', appIconTypes.arrowLeft, swipeToSelected, null, { fl: true })
            } else {
              return gi('back', appIconTypes.arrowLeft, handleCloseGroupItem, { open: false, selectedItem: null }, { fl: true })
            }

          case gEnums.dataHeaderTypes.viewData:
            break;
          default:
          // nothing
        }
    }
  }


  const viewDataButtons = () => {
    const bts = []
    if (gvisKeys.includes(vit)) {
      if (appUserAccess && appUserAccess.isSuperAdmin) {
        const hOpts = adminDropdown()
        if (hOpts) { bts.push(hOpts) }
      }
    }
    // }
    return bts.length > 0 ? bts : null
  }

  const itemHeaderButtons = () => { // swipeToSelected

    const { allowFavorite, allowHelp, allowSort, formActions } = buttonPermissions ? buttonPermissions : {}
    const favIcon = viType && authAppUser && authAppUser.settingsFavs && authAppUser.settingsFavs.includes(viType) ? 'star' : 'star outline'

    const bts = []

    allowHelp && !showSort && viType && bts.push(helpButton())
    allowSort && showSort && viType && bts.push(stopButton())
    allowSort && showSort && viType && bts.push(sortButton())

    // headerAlerts
    allowFavorite && bts.push(gi('start', favIcon, action_handlers.setFavorite, { swipedItem }, { title: _.startCase('addFavorite'), ...iconOptions }))
    allowViewLinking && appUserAccess && appUserAccess.isSuperAdmin && bts.push(gi('ib-showLink', 'linkify', viewSettings_handlers.handleShowViewLinking, null, { title: _.startCase('showLinkedData'), color: showViewLinking ? 'green' : 'blue' }))

    const hdd = adminDropdown({ formActions })

    if (hdd) { bts.push(hdd) }

    return bts.length > 0 ? bts : null
  }

  const sectionHeaderButtons = () => {
    const bts = []
    const hdd = adminDropdown()
    if (hdd) { bts.push(hdd) }
    return bts
  }

  const headerCaption = () => {
    switch (dataHeaderType) {
      case gEnums.dataHeaderTypes.dataModify:
        if (isList && originalItemData && originalItemData.key) {
          return _.startCase(originalItemData.key)
        } else {
          const { name, firstName, lastName, displayName } = data_modified ? data_modified : {}
          if (name) {
            return data_modified.name
          } else if (firstName && lastName) {
            return firstName + ' ' + lastName
          } else if (displayName) {
            return displayName
          }
        }
        break;

      case gEnums.dataHeaderTypes.itemHeader:
        const { caption } = selectedGroupItem ? selectedGroupItem : {}
        let _caption;
        if (!caption) {
          console.log('NO Caption')
        } else {
          _caption = caption
        }
        if (caption_header) { _caption = caption_header }
        let headerCaption = swipedItem && swipedItem.breadCaption ? swipedItem.breadCaption : _caption
        return headerCaption

      default:
        return null
    }
  }

  /** Returns a back icon based on the dataHeaderType */
  const leftButtons = () => {
    switch (dataHeaderType) {
      case gEnums.dataHeaderTypes.dataModify:
      case gEnums.dataHeaderTypes.dataSubHeader:
      case gEnums.dataHeaderTypes.itemHeader:
        return backIcon()
      default:
        return null
    }
  }

  /** Returns a group of buttons/dropdown based on the dataHeaderType */
  const rightButtons = () => {
    switch (dataHeaderType) {
      case gEnums.dataHeaderTypes.itemHeader:
        return itemHeaderButtons()
      case gEnums.dataHeaderTypes.sectionHeader:
        return sectionHeaderButtons()
      case gEnums.dataHeaderTypes.viewData:
        return viewDataButtons()
      default:
        return null
    }
  }

  const headerItems = {
    left: leftButtons(),
    caption: headerCaption(),
    right: rightButtons(),
  }

  let emptyHi = true

  Object.keys(headerItems).forEach(hiKey => {
    if (headerItems[hiKey]) {
      emptyHi = false
    }
  })

  const menu = <Menu borderless inverted={useDarkMode} className={'all-headers-menu'} >
    <Menu.Item>
      {headerItems.left}
      {headerItems.search}
    </Menu.Item>
    <Menu.Item>
      <Header as='h4' inverted={useDarkMode}>
        <Header.Content>
          {headerItems.caption}
        </Header.Content>
      </Header>
    </Menu.Item>
    <Menu.Menu position='right'>
      <Menu.Item>
        {headerItems.right}
      </Menu.Item>
    </Menu.Menu>
  </Menu >

  const content = () => {
    return <React.Fragment>
      {dataHeaderType === gEnums.dataHeaderTypes.itemHeader && <div className={'pbc'}><ParentBreadcrumbs /></div>}
      {menu}
      {(showCopyViewSettings || showDuplicateView || showCreateSheetsDbLink || showCopyData || showPushData) &&
        <HeaderActions dataHeaderType={dataHeaderType} viType={viType} />}
      {restrictedAccess && <Message icon inverted size={'mini'} style={{ margin: '1em' }}>
        <Icon name='alarm' color={'blue'} />
        <Message.Content>{restrictedAccess}</Message.Content>
      </Message>}
    </React.Fragment>
  }
  return emptyHi ? null : content()
}

export default AllHeaders