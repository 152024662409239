import _ from 'lodash';

/**
 * 
 * @param {array} items 
 * @param {string} keyProp 
 * @param {boolean} removeIds 
 * @param {boolean} removeKeys 
 * @returns 
 */
export const createObject = (items, keyProp, removeIds, removeKeys) => {
  const fullO = {}
  if (_.isArray(items)) {
    items.forEach(item => {
      const oKey = item[keyProp]
      fullO[oKey] = item
      if (fullO[oKey]) {
        if (removeIds) { delete fullO[oKey].id }
        if (removeKeys) { delete fullO[oKey].key }
      }
    })
  } else {
    if (items) {
      Object.keys(items).forEach(item => { fullO[items[item][keyProp]] = items[item] })
    }
  }
  return fullO
}

/**
 * 
 * @param {object} items 
 * @param {array} removes 
 * @param {*} altKey 
 * @returns a new object with the key of the item.key as the key 
 */
export const createKeyedObjectFromKey = (items, removes, altKey) => {
  const fullO = {}
  const _trueKey = altKey ? altKey : 'key'
  Object.keys(items).forEach(key => {
    const item = items[key]
    fullO[item[_trueKey]] = item
    if (removes) {
      removes.forEach(r => {
        delete fullO[items[key][_trueKey]][r]
      })
    }
  })
  // delete fullO.undefined
  return fullO
}

/**
 * 
 * @param {object} items 
 * @param {string} keyProp 
 * @returns an `array` from `items`
 */
export const createArray = (items, keyProp) => {
  const fullO = {}
  Object.keys(items).forEach(key => {
    fullO[key] = {
      ...items[key],
      id: key
    }
    if (keyProp) { fullO[key][keyProp] = key }
  })
  return _.filter(fullO)
}

export const createItemArray = (items, keyProp) => {
  const fullO = {}
  Object.keys(items).forEach(key => {
    fullO[key] = {
      key,
      id: key,
      ...items[key],
    }
  })
  return _.filter(fullO)
}

/**
 * 
 * @param {*} root 
 * @param {*} prop 
 * @deprecated
 * @returns 
 */
export const createObjectBundle = (root, prop) => {
  if (!root[prop]) { root[prop] = {} }
  return root[prop]
}

/**
 * 
 * @param {object} items 
 * @returns adds a key for each item
 */
export const createKeys = (items) => {
  if (items && _.isObject(items)) {
    Object.keys(items).forEach(key => {
      if (items[key] && !items[key].key && (key !== 'key')) {
        try {
          items[key].key = key
        } catch (error) {
          console.error('createKeys', items, key, error)
        }
      }
    })
  }
  return items
}