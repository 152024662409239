import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Label } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';

/** Returns a color container */

/**
 * 
 * @param {object} props (fip)
 * @returns A label that allows a color to be selected using `SketchPicker` from `react-color`
 */
export const ColorPicker = (props) => {

  const { fip } = props
  const { propname } = fip
  const [background, setBackground] = useState(fip.value ? fip.value : 'grey')
  const [showColor, setShowColor] = useState(false)

  const handleChange = (color) => {
    setBackground(color.hex)
    const data = { propname: fip.propname, value: color.hex }
    fip.onChange(null, data)
  }

  const handleShowColor = () => { setShowColor(!showColor) }

  const colorLabel = () => {
    return <Label
      key={uniqueKey('cp', 'l', propname)}
      className={'lbl-color'}
      style={{ backgroundColor: background, width: '100%', marginLeft: 0, marginBottom: 10 }}
      size='large'
      onClick={(e) => handleShowColor()}
    >
      <Label.Detail key={uniqueKey('cp', 'ld1', propname)} >{propname}</Label.Detail>
      <Label.Detail key={uniqueKey('cp', 'ld2', propname)}>{background}</Label.Detail>
    </Label>
  }

  return (
    <div className={'color-container'}>
      <div className={'color-header'}>{colorLabel()}</div>
      {showColor && <div className={'color-content'}>
        <SketchPicker
          key={uniqueKey('cp', 'sp')}
          color={background}
          onChange={handleChange}
        />
      </div>}
    </div>
  )
}