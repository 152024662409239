import React, { useContext, useEffect, useState } from 'react';
import { AppUserManagerContext } from '../../cnr/contexts/AppUserManagerContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { fs_get_doc } from '../../firestoreData/appData/fsData';
import { appFormTypes } from '../../enums/appFormTypes';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import AppForm from '../forms/appForms/AppForm';

const UserProfile = (props) => {

  const profileType = gEnums.profileTypes.clientProfiles

  const { forProfile } = props ? props : {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ? parentContext : {}
  const { paps_state } = states ? states : {}
  const { appUser_handlers } = handlers ? handlers : {}
  const { pathViews } = paps_state ? paps_state : {}

  // appUserManagerContext
  const appUserManagerContext = useContext(AppUserManagerContext)
  const { appUserManager_state, appUserManager_handlers } = appUserManagerContext ? appUserManagerContext : {}
  const { selectedAppUser } = appUserManager_state ? appUserManager_state : {}
  const { email } = selectedAppUser ? selectedAppUser : {}

  // local state 
  const [profileData, setProfileData] = useState()
  const [updating, setUpdating] = useState()

  const cb = (pd) => { setProfileData(pd) }

  useEffect(() => {
    const _refPath = createRefPath_client(pathViews, ['profiles', email])
    fs_get_doc(_refPath, cb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelled = () => {
    setUpdating()
    appUserManager_handlers.handleCancel()
  }

  const handleUpdate = () => {
    setUpdating(true)
    appUser_handlers.handleUpdateAppUserProfile(profileData, profileType, handleCancelled)
  }

  const handleUpdateProfileData = (d) => setProfileData(d)

  console.log('aaaaaaaaaaaaaa')

  const appUserForm = () => <AppForm
    appFormType={appFormTypes.appUserProfile}
    parentData={profileData}
    handleUpdateParentData={handleUpdateProfileData}
    handleSave={handleUpdate}
    updating={updating}
  />

  const fpw = () => forProfile ? appUserForm() : <FullPageWrapper
    topperCaption={'Profile'}
    topperCaption2={email}
    content={profileData && appUserForm()}
    handleCancel={appUserManager_handlers.handleCancel && handleCancelled}>
  </FullPageWrapper>

  return fpw()

}

export default UserProfile