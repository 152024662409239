/**
 * 
 * @param {object} cns 
 * @param {object} divs 
 */
export const getClassNames = (cns, divs) => {
  if (divs) {
    Object.keys(divs).forEach(k => {
      const d = divs[k]
      const { className } = d ? d : {}
      if (className) {
        const classNameS = className.split(' ')
        if (classNameS) {
          classNameS.forEach(cn => {
            const { single, multi } = cns
            if (cn.indexOf('-') >= 0) {
              if (!multi.includes(cn)) {
                multi.push(cn)
              }
            } else {
              if (!single.includes(cn)) {
                single.push(cn)
              }
            }
          })
        }
      }
    })
  }
}

export function getClassesAndIds(document, multi) {
  const data = {
    used: [],
    unused: []
  }
  if (document.styleSheets) {
    // loop css
    Object.values(document.styleSheets).forEach(v => {
      try {
        const { cssRules, ownerNode } = v
        if (cssRules) {
          const { baseURI } = ownerNode
          // only local css
          if (baseURI.indexOf('localhost:3000') >= 0) {
            Object.keys(cssRules).forEach(k => {
              const cssRule = cssRules[k]
              const { selectorText } = cssRule
              if (selectorText) {
                const xxxx = selectorText.split('.')
                xxxx.forEach(x => {
                  let y = x.replace('>', '')
                  y = y.trim()
                  if (y) {
                    if (!multi[y]) {
                      console.log('y', y)
                    }
                  }
                })
              }
              multi.forEach(m => {
                // check to see if the 
                if (selectorText.indexOf(m) >= 0) {
                  if (!data.used.includes(m)) {
                    data.used.push(m)
                  }
                } else {
                  if (!data.unused.includes(m)) {
                    data.unused.push(m)
                  }
                }
              })
            })
          }
        }
      } catch (error) {

      }

    })
  }
}