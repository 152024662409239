import { getFirestore, runTransaction } from 'firebase/firestore';
import _ from 'lodash';
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { fs_get_data } from '../../appData/fsAppData';
import { createRefPath } from '../../appData/appRefPaths';

export const updateRating = (props) => {

  const { baseRef, ufProps, dispatch, appUser } = props

  const { refPath, directCollection } = baseRef
  const { uid } = appUser ? appUser : {}
  let { vit, itemData, swipedItem, viewKey, rating, dataRestrictions } = ufProps ? ufProps : {}

  if (!viewKey && itemData && itemData.id) { viewKey = itemData.id }
  if (!viewKey && swipedItem && swipedItem.key) { viewKey = swipedItem.key }

  if (viewKey && uid) {

    const coll = directCollection ? directCollection : vit

    const _docRef = createRefPath([coll, viewKey], refPath)
    const _ratingsRef = createRefPath(['ratings', uid], _docRef)

    if (dataRestrictions && (dataRestrictions.all || dataRestrictions.updateRating)) {
      dispatch({ type: dispatchProps.successAlt, dispatch })
      return false
    }

    fs_get_data({ refPath: _ratingsRef }).then(existingRating => {
      if (!_.existing(existingRating)) {
        updateRatingCollection(_docRef, _ratingsRef, rating, dispatch)
      } else {
        updateRatingCollection(_docRef, _ratingsRef, rating, dispatch, existingRating, true)
      }
    })
  } else {
    alert('Item not rated. No viewItemKey')
  }
}

const updateRatingCollection = async (docRef, ratingsRef, rating, dispatch, existingRating, existing) => {

  const fs = getFirestore()

  try {
    await runTransaction(fs, async (transaction) => {
      const res = await transaction.get(docRef);
      if (!res.exists()) { dispatch({ type: dispatchProps.error }) }

      const itemData = res.data()
      const { ratingData } = itemData
      const { numRatings, avgRating, totalRating } = ratingData ? ratingData : {}

      let rating_count, rating_old, rating_average, rating_total;

      if (!existing) {
        rating_count = numRatings ? numRatings + 1 : 1;
        rating_old = avgRating ? avgRating * numRatings : 0;
        rating_average = (rating_old + rating) / rating_count;
        rating_total = totalRating ? totalRating + rating : rating;
      } else {
        const newTotal = totalRating - existingRating + rating
        const newAverage = newTotal / numRatings
        rating_count = numRatings // stays the same 
        rating_total = newTotal
        rating_average = newAverage;
      }

      // Commit to itemData with the new ratings
      transaction.update(docRef, {
        ratingData: {
          avgRating: rating_average,
          numRatings: rating_count,
          totalRating: rating_total,
        }
      });

      // update the ratings collection
      transaction.set(ratingsRef, { rating: rating });
    });
    dispatch({ type: dispatchProps.success, dispatch })
  } catch (err) {
    dispatch({ type: dispatchProps, err, dispatch })
  }

}