import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { uniqueKey } from '../common/keys'

const GenericIconButton = (button, index, clickOptions) => {
  return <Button
    basic
    className={button.className}
    color={button.color}
    disabled={!button.ready}
    icon
    key={uniqueKey('cnt', 'gib', index)}
    labelPosition={button.showCaption ? 'right' : null}
    primary={!button.color && button.primary}
    title={button.title}
    inverted={true}
    onClick={() => { button.onClick(clickOptions) }}>{button.showCaption ? button.caption : null}
    <Icon name={button.icon}
    />
  </Button>
}

export default GenericIconButton