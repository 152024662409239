import { createRefPath } from "../../appData/appRefPaths";
import { fs_add_doc } from "../../appData/fsData";

export const createList = (props) => {
  const { baseRef, ufProps, dispatch } = props
  const { refPath } = baseRef
  const { createListProps } = ufProps ? ufProps : {}
  const { createListAs, createListData } = createListProps ? createListProps : {}
  const _addRef = createRefPath([createListAs], refPath)
  fs_add_doc(_addRef, createListData, dispatch)
}