import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';

const MatchFilter = (props) => {

  const { setMatchFilter } = props

  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedResult, setSelectedResult] = useState()
  const [selectedSection, setSelectedSection] = useState()

  const handleSelectedLocation = (location) => setSelectedLocation(selectedLocation === location ? null : location)
  const handleSelectedResult = (result) => setSelectedResult(selectedResult === result ? null : result)
  const handleSelectedSection = (section) => setSelectedSection(selectedSection === section ? null : section)

  useEffect(() => {
    setMatchFilter({ selectedLocation, selectedResult, selectedSection })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedLocation, selectedResult, selectedSection]);

  const button = (key, selected, text, oc) => <Button compact className={selected ? 'btn-sel' : null} color={selected ? 'blue' : 'black'} onClick={(e) => { oc(key) }}>{text}</Button>

  const buttons_location = () => <div><Button.Group size={'mini'}>
    {button('away', (selectedLocation === 'away' ? true : false), 'Away', handleSelectedLocation)}
    {button('home', (selectedLocation === 'home' ? true : false), 'Home', handleSelectedLocation)}
  </Button.Group></div>

  const buttons_result = () => <div><Button.Group size={'mini'}>
    {button('win', (selectedResult === 'win' ? true : false), 'Wins', handleSelectedResult)}
    {button('loss', (selectedResult === 'loss' ? true : false), 'Losses', handleSelectedResult)}
  </Button.Group></div>

  const buttons_section = () => <div><Button.Group size={'mini'}>
    {button('section', (selectedSection === 'section' ? true : false), 'Section', handleSelectedSection)}
    {button('non', (selectedSection === 'non' ? true : false), 'Non', handleSelectedSection)}
    {/* {button('pl', (selectedSection === 'pl' ? true : false), 'PL', handleSelectedSection)} */}
  </Button.Group></div>

  const buttonHeader = () => <div className={'match-buttons'}>
    {buttons_location()}
    {buttons_result()}
    {buttons_section()}
  </div>

  return buttonHeader()
}

export default MatchFilter