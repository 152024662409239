export const formItemProps = (fieldProps, handleFormItemChange, req) => {

  const { caption, icon, id, key, propname, parents, placeholder, required, value, vit, subkey, parentkey, type, otype, disabled, readOnly, formItemType } = fieldProps
  const label = caption
  const vlps = { icon, id, label, parents, placeholder, required, value, vit, subkey, parentkey, disabled, readOnly }

  vlps.placeholder = placeholder
  vlps.propname = propname
  vlps.className = (required) ? 'required' : ''
  vlps.value = (vlps.value === undefined) ? '' : vlps.value
  vlps.onChange = handleFormItemChange
  vlps.key = key
  vlps.formItemType = formItemType
  vlps.label = req ? caption + ' *' : caption

  if (type) { vlps.type = type }
  if (otype) { vlps.otype = otype }

  return vlps

}