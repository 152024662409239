import _ from 'lodash';
import React from 'react';
import { Icon, Label, Menu, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import Checker from '../../../components/forms/elements/Checker';
import { gEnums } from '../../../enums/globalEnums';
import HeaderDropdown from '../../headers/HeaderDropdown';
import { settingsIconTypes } from '../../../enums/settingsIconTypes';

export const gridRowCheckbox = (item, saci, grProps) => {

  // subValue
  const { name, key, cla, bcl, cl, settingKey } = item

  const {
    clicks,
    allowColor,
    allowCss,
    allowTagged,
    allowRemove,
    allowShow,
    aps_viewItems,
    forSelect,
    isGlobal,
    menuType,
    desktopMode,
  } = grProps ? grProps : {}

  const { clickCheck, clickOption, clickTo } = clicks ? clicks : {}

  let { icon } = item
  let itemCaption = name ? name : _.startCase(settingKey)
  if (!name && !settingKey && key) { itemCaption = _.startCase(key) }

  if (!icon) { icon = settingsIconTypes[key] }
  if (!icon && aps_viewItems && aps_viewItems[key] && aps_viewItems[key].display && aps_viewItems[key].display.defaultIcon) { icon = aps_viewItems[key].display.defaultIcon }

  const widths = {
    tag: 2,
    name: 10,
    check: 4,

    remove: 2,
    link: 2,

    miniName: 12,
    select: 4,
  }

  const allowSelectClick = forSelect
  const allowCheckClick = allowShow && allowShow(menuType)
  const allowCssClick = allowCss ? allowCss(menuType, isGlobal) : false
  const allowTagClick = allowTagged ? allowTagged(menuType, isGlobal) : false
  const allowColorClick = allowColor ? allowColor(menuType) : false

  let allowRemoveClick = allowRemove && allowRemove(menuType)

  if (forSelect) { allowRemoveClick = false }

  if (allowRemoveClick) { widths.name = widths.name - widths.remove }
  if (!allowCheckClick) { widths.name = widths.name + widths.check }

  const lblSnC = {
    style: {
      backgroundColor: bcl ? bcl : 'grey',
      color: cl ? cl : 'black',
      borderLeftColor: cla ? bcl : 'grey',
      borderRightColor: cla ? bcl : 'grey',
    },
    className: cla ? 'applied' : null
  }


  if (allowColorClick) {
    widths.tag++
    widths.name--
  }

  const tagColumn = (cc) => {
    if (allowColorClick) {
      return <Label key={uniqueKey('grcb', 'lbl')} {...lblSnC}>Color</Label>
    } else {
      return <Icon key={uniqueKey('grcb', 'ico')} name={'unordered list'} color={'grey'} inverted circular />
    }
  }

  const grid = () => {
    const cc = clickCheck
    const leftElems = []
    leftElems.push(tagColumn(cc))

    const allows = {
      check: allowCheckClick && !allowColorClick,
      tag: allowTagClick,
      css: allowCssClick,
      remove: allowRemoveClick,
      color: allowColorClick,
      select: allowSelectClick,
    }

    const hdd = <HeaderDropdown key={uniqueKey('grcb', 'gc', 'hdd')} dataHeaderType={gEnums.dataHeaderTypes.menuHeader} clickOption={clickOption} item={item} allows={allows} />

    const x = {
      item,
      ocHeader: clickTo,
      iconLeft: 'unordered list',
      ocIconLeft: cc,
      caption: itemCaption,
      leftElems,
      hdd,
      allows,
      desktopMode,
    }

    return <SettingsMenuItem key={uniqueKey('grcb', 'sm', key)} {...x} keyy={key} />

  }

  const miniGrid = () => {
    return <div>MG</div>
  }

  if (grProps) {
    return grid(item)
  } else {
    return <Segment key={uniqueKey('grdc', 's')} {...saci}>
      {miniGrid()}
    </Segment>
  }
}

const SettingsMenuItem = (props) => {

  const { item, ocHeader, iconLeft, ocIconLeft, caption, leftElems, allows, keyy } = props

  const selectCb = <Checker item={item} handleClick={ocIconLeft} />

  return <Menu.Item key={uniqueKey('smi', keyy)} >
    <Menu.Header onClick={(e) => ocHeader && ocHeader(item, true)} >
      {leftElems && leftElems}
      {!leftElems && <Icon color={'blue'} name={iconLeft} circular inverted onClick={(e) => ocIconLeft(e, item)} />}
      <div key={uniqueKey('smid', keyy)}>{caption}</div>
      {allows.select && selectCb}
    </Menu.Header>
  </Menu.Item>
}
