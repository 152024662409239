import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { questionsAndAnswersHandlers, questionsAndAnswersInitialState, questionsAndAnswersReducer } from '../reducers/QuestionsAndAnswersReducer';
import { ParentContext } from './ParentContext';

/** Global VideoConferences (Icon, Image, PDF) for the Event taken from the database */
export const QuestionsAndAnswersContext = createContext();

/** This will get the homeSettings and clientSettings */
const QuestionsAndAnswersProvider = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ? parentContext : {}
  const { page_state, paps_state } = states
  const { page_fns } = fns
  const { settingsDocName, pathViews } = paps_state ? paps_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { appQuestionsAndAnswers } = aps_global ? aps_global : {}
  const { allowQuestionsAndAnswers } = appQuestionsAndAnswers ? appQuestionsAndAnswers : {}

  // local state
  const init_state = { settingsDocName, pathViews, page_fns, allowQuestionsAndAnswers }
  const [questionsAndAnswers_state, questionsAndAnswersDispatch] = useReducer(questionsAndAnswersReducer, questionsAndAnswersInitialState(init_state));
  const questionsAndAnswers_handlers = questionsAndAnswersHandlers(questionsAndAnswersDispatch, questionsAndAnswers_state)
  const { questionsAndAnswers } = questionsAndAnswers_state ? questionsAndAnswers_state : {}

  useEffect(
    () => {
      // only get videoConferences in the events collection  
      questionsAndAnswers_handlers.handleGetQuestionsAndAnswers()
    },
    // eslint-disable-next-line
    [settingsDocName] // does not re-rednder unless uivi has changed
  )

  const providerContext = () => {
    return <QuestionsAndAnswersContext.Provider value={{ questionsAndAnswers_state, questionsAndAnswers_handlers }}>
      {props.children}
    </QuestionsAndAnswersContext.Provider>
  }

  if (questionsAndAnswers) { return providerContext() }

  return <div></div>

}

export default QuestionsAndAnswersProvider