import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps"
import { mergePromise } from "../../promises/mergePromise"

export const updateSort = (props) => {

  const { baseRef, ufProps, dispatch } = props

  const { refPath, directCollection } = baseRef
  let { vit, itemData, dataRestrictions } = ufProps ? ufProps : {}

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.sortData)) {
    console.log('refPath', refPath);
    console.log('ufProps', ufProps);
    dispatch({ type: dispatchProps.successAlt, dispatch })
    return false
  }

  mergePromise(refPath, vit, directCollection, itemData).then(() => {
    dispatch({ type: dispatchProps.success, dispatch })
  }).catch(error => {
    dispatch({ type: dispatchProps.error, error, dispatch })
  })
}