import _ from 'lodash';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';

export const groupActionButton = (actionName, handlers, confirmation, buttonClicked, cn, ready, actionStarted, buttonCaption, actionData) => {

  const { handleButtonClicked, handleButtonConfirm } = handlers

  let divCn = 'abc'
  let groupCn = 'btn-confirm'
  if (cn) divCn += ' ' + cn

  const caption = buttonCaption ? buttonCaption : actionName

  if (confirmation && (buttonClicked === actionName)) {
    const bc = confirmation.success ? 'green' : 'red'
    let bi = confirmation.success ? 'check' : 'delete'
    let loading = confirmation.success ? false : true
    return <Button.Group icon fluid key={uniqueKey('sab', 'bg')} className={groupCn}>
      <Button color={bc} onClick={e => handleButtonClicked(null)}><Icon name={bi} /></Button>
      <Button color={bc} onClick={e => handleButtonClicked(null)}>{confirmation.title}</Button>
      <Button color={bc} onClick={e => handleButtonConfirm(null)}><Icon name={bi} loading={loading} /></Button>
    </Button.Group>
  } else {
    const bii = actionStarted ? 'spinner' : 'check'
    if (buttonClicked === actionName) {
      return <Button.Group icon fluid key={uniqueKey('sab', 'bg')} className={groupCn} disabled={true}>
        <Button onClick={e => handleButtonClicked(null)}><Icon name='delete' /></Button>
        <Button onClick={e => handleButtonClicked(null)}>{_.startCase(caption) + '?'}</Button>
        <Button color='green' onClick={e => handleButtonConfirm(actionName)}><Icon name={bii} loading={actionStarted ? true : false} /></Button>
      </Button.Group>
    } else {
      return <Button className={divCn} fluid color='blue' key={uniqueKey('sab', 'btn')} disabled={!ready} onClick={e => handleButtonClicked(actionName, actionData)}>{_.startCase(caption)}</Button>
    }
  }
}