import React, { useContext, useEffect, useReducer } from 'react';
import { AppFormsContext } from '../../../cnr/contexts/AppFormsContext';
import { appFormHandlers, appFormInitialState, appFormReducer } from '../../../cnr/reducers/AppFormReducer';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import { isItemReady } from '../../../validation/validation';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import NoData from '../../alerts/NoData';
import FormForm from '../../forms/FormForm';
import { AppFormWizard } from './AppFormWizard';

/**
 * 
 * @param {object} props 
 * @param {enum} - formType: the type of form from the `appFormTypes` enum
 * @param {function} - handleUpdateParentData: function to update the parent component
 * @param {object} - parentData: data from the parent component
 * @param {array} - propIgnores: list of props to ingnore, if any
 * @param {boolean} - useWizard: whether the form should be separated into a wizard
 * @returns Form or Wizard
 */
const AppForm = (props) => {

  const {
    _formAttributes,
    _header,
    appFormType,
    confirmation,
    handleCancel,
    handleDelete,
    handlePreview,
    handleSaveCaption,
    handleUpdateParentData,
    ignoreItemStatus,
    keepParent,
    parentData, // this is data coming from the parent component
    previewCaption,
    propIgnores,
    topperCaption,
    topperCaption2,
    updating,
    useFullPageWrapper,
    useWizard,
    vit,
    viewOnly,
  } = props

  // appFormsContext
  const appFormsContext = useContext(AppFormsContext);
  const { appForms_state } = appFormsContext ? appFormsContext : {}
  const { appForms } = appForms_state ? appForms_state : {}

  // appForms
  const init_state = { parentData, appFormType, propIgnores, vit }
  const [appForm_state, appForm_dispatch] = useReducer(appFormReducer, appFormInitialState(init_state));
  const appForm_handlers = appFormHandlers(appForm_dispatch)
  const { currentAppForms, formProps, viewProps, noProps, data_form } = appForm_state ? appForm_state : {}

  const form_handlers = props.form_handlers ? props.form_handlers : { handleFormDataChange_local: appForm_handlers.handleLocalPropChange }

  const vpCount = viewProps ? Object.keys(viewProps).length : 0

  const handleSave = () => props.handleSave(data_form)

  // simply set the appForms to the reducer
  useEffect(() => {
    appForm_handlers.handleAppForms(appForms)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (currentAppForms) {
      if (keepParent && parentData) {
        let _parentData = {};
        switch (appFormType) {
          default:
            _parentData = parentData
        }
        appForm_handlers.handleFormInit(_parentData)
      } else {
        appForm_handlers.handleFormInit(parentData)
      }
    }
    // LOOK parentData
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentAppForms, appFormType, viewProps]);

  /** updates the parent component when the data_form changes */
  useEffect(() => {
    if (viewProps && data_form) {
      if (keepParent) {
        const dfi = { [appFormType]: data_form }
        handleUpdateParentData && handleUpdateParentData(dfi, isItemReady(viewProps, dfi), vpCount)
      } else {
        handleUpdateParentData && handleUpdateParentData(data_form, isItemReady(viewProps, data_form))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [data_form]);

  const form = () => <FormForm
    form_handlers={form_handlers}
    formAttributes={_formAttributes}
    formProps={formProps}
    viewProps={viewProps}
    noSort={true}
    viewOnly={viewOnly}
    ignoreItemStatus={ignoreItemStatus}
  />

  const footer = () => <UiSaveButtons
    delete={handleDelete ? { oc: handleDelete } : null}
    preview={handlePreview ? { oc: handlePreview, caption: previewCaption ? previewCaption : null } : null}
    save={{ oc: handleSave, caption: handleSaveCaption ? handleSaveCaption : null, disabled: !data_form }}
  ></UiSaveButtons>

  const formWrapper = () => <Wrapper
    header={_header && _header}
    content={form()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
    confirmation={confirmation}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={formWrapper()}
    handleCancel={handleCancel}
    topperCaption={topperCaption}
    topperCaption2={topperCaption2}
  />

  if (formProps && viewProps) {
    if (useWizard) {
      return <AppFormWizard
        form_handlers={form_handlers}
        formData={data_form}
        handleCancel={handleCancel}
        topperCaption={topperCaption}
        topperCaption2={topperCaption2}
        viewProps={viewProps}
        handlePreview={props.handlePreview}
        handleSave={props.handleSave}
      />
    } else {
      if (props.handleSave) {
        if (useFullPageWrapper) {
          return fullPageWrapper()
        } else {
          return formWrapper()
        }
      } else {
        return form()
      }
    }
  }

  if (noProps) { return <NoData fullCaption={"No form props have been set for `" + appFormType + "`. Please update the `AppForms`."}></NoData> }

  return <div></div>

}

export default AppForm