import _ from 'lodash';

export const postDataConstraits = (fsr, vld) => {

  let _vld = vld
  const { whereOpts } = fsr ? fsr : {}
  const { posts: wherez } = whereOpts ? whereOpts : {}

  let allData = vld // _.sortBy(vld)

  // add the data items
  const { hasConstraint: hasConstraintI, allItems: _allItems } = dataIncluded(wherez, allData)

  // remove the data items
  const { hasConstraint: hasConstraintE, allItems, excludedData } = dataExcluded(wherez, allData, _allItems)

  return hasConstraintI || hasConstraintE ? { postData: allItems, excludedData } : { postData: _vld }

}

export const postKeyConstraits = (fsr, vld) => {

  let _vld = vld
  const { whereOpts } = fsr ? fsr : {}
  const { dataKeys } = whereOpts ? whereOpts : {}

  if (dataKeys && dataKeys.length > 0 && _vld) {
    Object.keys(_vld).forEach(k => {
      if (!dataKeys.includes(k)) {
        delete _vld[k]
      }
    })
  }

  return _vld

}

/**
 * 
 * @param {array} wherez 
 * @param {object} allData 
 * @returns 
 */
const dataIncluded = (wherez, allData) => {

  let hasConstraint;
  let _allItems = {};

  wherez.forEach(wz => {

    // const { Hc: constraintType, Jc: itemValue, zc } = wz ? wz : {}
    // const { wc: constraintType, mc: itemValue, _c } = wz ? wz : {}
    // const { da: constraintType, _a: itemValue, fa: segHolder } = wz ? wz : {}
    const { _op: constraintType, _value: itemValue, _field: segHolder } = wz ? wz : {}
    const { segments } = segHolder ? segHolder : {}
    const itemProp = segments ? segments[0] : null

    let _itemValue = itemValue

    if (!_.isArray(itemValue)) {
      if (itemValue && itemValue.toLowerCase() === 'true') {
        _itemValue = true
      } else if (itemValue.toLowerCase() === 'false') {
        _itemValue = true
      }
    }

    switch (constraintType) {
      // case gEnums.queryTypes.arrayContains:
      case 'array-contains':
        // IMPORTANT: Data - Filter array-contains 
        if (itemProp) {
          const _allData1 = _.filter(allData, function (item) {
            const ivv = item[itemProp]
            if (_.isArray(_itemValue)) {
              return ivv && _.isArray(ivv) && ivv.some(r => _itemValue.includes(r))
            } else {
              return ivv && _.isArray(ivv) && ivv.includes(_itemValue);
            }
          })
          hasConstraint = true
          _allData1.forEach(a => { _allItems[a._itemKey] = a })
        }
        break;
      // case gEnums.queryTypes.arrayContainsAny:
      case 'array-contains-any':
        const _allData2 = _.filter(allData, function (item) {
          const iv = item[itemProp]
          return iv && _.isArray(iv) && iv.some(r => _itemValue.includes(r))
          // return iv && _.isArray(iv) && iv.some(r => _itemValue.indexOf(r) >= 0)
          // return iv && _.isArray(iv) && _.some(iv, _itemValue);
          // return item[itemProp] && _.isArray(item[itemProp]) && item[itemProp].some(ai => _itemValue.includes(ai));
        })
        hasConstraint = true
        _allData2.forEach(a => { _allItems[a._itemKey] = a })
        break;

      case '==':
        if (itemProp) {
          const _allData3 = _.filter(allData, function (item) {
            return (item[itemProp] && item[itemProp] === _itemValue);
          })
          hasConstraint = true
          _allData3.forEach(a => { _allItems[a._itemKey] = a })
        }
        break;
      // case gEnums.queryTypes.greaterThan:
      case '>':
        break;
      // case gEnums.queryTypes.greaterThanOrEqualTo:
      case '>=':
        break;
      // case gEnums.queryTypes.in:
      case 'in':
        break;
      // case gEnums.queryTypes.lessThan:
      case '<':
        break;
      // case gEnums.queryTypes.lessThanOrEqualTo:
      case '<=>':
        break;
      // case gEnums.queryTypes.notEqualTo: 
      // case gEnums.queryTypes.notIn:
      // case '!=':
      // case 'not-in':
      //   if (itemProp) {
      //     const rd = []
      //     allData.forEach(item => {
      //       if (_.isArray(itemValue)) {
      //         itemValue.forEach(iv => {
      //           if (item[itemProp] && item[itemProp].includes(iv)) {
      //             rd.push(item._itemKey)
      //           }
      //         })
      //       } else {
      //         if (item[itemProp] && !item[itemProp].includes(itemValue)) {
      //           rd.push(item._itemKey)
      //         }
      //       }
      //     })
      //     if (rd) {
      //       rd.forEach(_itemKey => {
      //         delete allItems[_itemKey]
      //       })
      //     }
      //     hasConstraint = true
      //   }
      //   break;
      default:
    }
  })

  if (!hasConstraint) {
    return { allItems: allData, hasConstraint }
  } else {
    return { allItems: _allItems, hasConstraint }
  }

}

const dataExcluded = (wherez, allData, _allItems) => {

  let hasConstraint;
  const _excludedData = []

  wherez.forEach(wz => {

    // const { Hc: constraintType, Jc: itemValue, zc } = wz ? wz : {}
    // const { wc: constraintType, mc: itemValue, _c } = wz ? wz : {}
    // const { da: constraintType, _a: itemValue, fa: segHolder } = wz ? wz : {} 
    const { _op: constraintType, _value: itemValue, _field: segHolder } = wz ? wz : {}
    const { segments } = segHolder ? segHolder : {}
    const itemProp = segments ? segments[0] : null

    switch (constraintType) {
      // case gEnums.queryTypes.greaterThan:
      case '>':
        break;
      // case gEnums.queryTypes.greaterThanOrEqualTo:
      case '>=':
        break;
      // case gEnums.queryTypes.in:
      case 'in':
        break;
      // case gEnums.queryTypes.lessThan:
      case '<':
        break;
      // case gEnums.queryTypes.lessThanOrEqualTo:
      case '<=>':
        break;
      // case gEnums.queryTypes.notEqualTo: 
      // case gEnums.queryTypes.notIn:
      case '!=':
        if (itemProp) {
          Object.keys(allData).forEach((key, index) => {
            const item = allData[key]
            if (item[itemProp]) {
              const pv = item[itemProp]
              if (_.isArray(itemValue)) {
                if (itemValue.includes(pv)) {
                  _excludedData.push(item._itemKey)
                }
              } else {
                if (pv === itemValue) {
                  _excludedData.push(item._itemKey)
                }
              }
            }
            if (_excludedData) {
              _excludedData.forEach(k => {
                delete _allItems[k]
              })
            }
            hasConstraint = true
          })
        }
        break;

      case 'not-in':
        if (itemProp) {
          Object.keys(allData).forEach((key, index) => {
            const item = allData[key]
            if (_.isArray(itemValue)) {
              itemValue.forEach(iv => {
                if (item[itemProp] && item[itemProp].includes(iv)) {
                  _excludedData.push(item._itemKey)
                }
              })
            } else {
              if (item[itemProp] && item[itemProp].includes(itemValue)) {
                _excludedData.push(item._itemKey)
              }
            }
          })
          if (_excludedData) {
            _excludedData.forEach(k => {
              delete _allItems[k]
            })
          }
          hasConstraint = true
        }
        break;
      default:
    }
  })

  return { hasConstraint, allItems: _allItems, excludedData: _excludedData }

}

