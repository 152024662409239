import { ddDivider } from "../../helpers/hddItems"

export const getItemHeaderItems = (props) => {

  const ddis = []

  const {
    addType,
    buttonPermissions,
    hddButtons,
    viType
  } = props

  const { allowAdd, allowAddSection, allowFiles, allowHomeSettings, allowManageSection, allowNotification, allowModifyPropData } = buttonPermissions ? buttonPermissions : {}

  allowAdd && viType && ddis.push(hddButtons.add(addType ? addType : 'add'))

  if (viType && (allowAddSection || allowManageSection)) {
    ddis.push(ddDivider(7))
    allowAddSection && ddis.push(hddButtons.addSection())
    allowManageSection && ddis.push(hddButtons.manageSections())
    allowManageSection && ddis.push(hddButtons.sortItems())
  }

  allowNotification && viType && ddis.push(hddButtons.notification())
  allowFiles && ddis.push(hddButtons.files())

  // ddis.push(hddButtons.testItem())

  if (allowHomeSettings) {
    ddis.push(ddDivider(8))
    ddis.push(hddButtons.copySettings())
    ddis.push(hddButtons.pasteSettings())
    ddis.push(hddButtons.showPermissions())
    ddis.push(hddButtons.restoreDefaults())
  }

  if (allowModifyPropData) {
    ddis.push(hddButtons.modifyPropData())
  }

  return ddis

}
