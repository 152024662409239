export const dataDataActionTypes = {
  ammendPropData: 'ammendPropData',
  backupItemData: 'backupItemData',
  bulkImageUpload: 'bulkImageUpload',
  convertToDocument: 'convertToDocument',
  convertToParentKeys: 'convertToParentKeys',
  copyData: 'copyData',
  createCollectionData: 'createCollectionData',
  createGlobalData: 'createGlobalData',
  createFilter: 'createFilter',
  createNotification: 'createNotification',
  fixPropData: 'fixPropData',
  fixPropNames: 'fixPropNames',
  googleSheetsData: 'googleSheetsData',
  imageMapping: 'imageMapping',
  linkData: 'linkData',
  moveData: 'moveData',
  productionData: 'productionData',
  registrationData: 'registrationData',
  secretData: 'secretData',
  updateDataRelationships: 'updateDataRelationships',
  updateParentKeys: 'updateParentKeys',
  updateStaticViews: 'updateStaticViews',
}

export const dataManagementTypes = {
  copyViewSettingsFrom: 'copyViewSettingsFrom',
  createFilter: 'createFilter',
  createNotification: 'createNotification',
  createTopic: 'createTopic',
  duplicateView: 'duplicateView',
  googleDataUpdate: 'googleDataUpdate',
  googleSheetLink: 'googleSheetLink',
  googleSheetUpdate: 'googleSheetUpdate',
  imageLinks: 'imageLinks',
  imageMapping: 'imageMapping',
  manageData: 'manageData',
  openDatabase: 'openDatabase',
  ...dataDataActionTypes,
  // scheduleItems: 'scheduleItems',
  // 
}

export const dataModificationTypes = {
  activate: 'activate',
  add: 'add',
  bulkImageUpload: 'bulkImageUpload',
  clipboard: 'clipboard',
  connections: 'connections',
  createList: 'createList',
  credentialing: 'credentialing',
  dataLinking: 'dataLinking',
  dataManagement: 'dataManagement',
  dataModifications: 'dataModifications',
  delete: 'delete',
  edit: 'edit',
  filters: 'filters',
  images: 'images',
  modifyList: 'modifyList',
  pdfs: 'pdfs',
  settingsActions: 'settingsActions',
  sorting: 'sorting',
  status: 'status',
  subAction: 'subAction',
}

export const pageItemActionTypes = {
  qAndAManager: 'qAndAManager',
  questionsAndAnswers: 'questionsAndAnswers',
  qrCode: 'qrCode',
  ticketing: 'ticketing',
  videoConferencing: 'videoConferencing',
}

export const appUserPermissionTypes = {
  imageUpload: 'imageUpload',
  itemLinking: 'itemLinking',
  requestAccess: 'requestAccess',
  rating: 'rating',
  userEmailSignInLinks: 'userEmailSignInLinks',
}

export const settingsActionTypes = {
  backupAllSettings: 'backupAllSettings',
  backupSettings: 'backupSettings',
  baseForms: 'baseForms',
  baseSettings: 'baseSettings',
  commitActiveSettings: 'commitActiveSettings',
  convertSettingsToSeparate: 'convertSettings',
  copyViewItemSettingsFromDatabase: 'copyViewItemSettingsFromDatabase',
  copyViewItemSettingsToViews: 'copyViewItemSettingsToViews',
  createClient: 'createClient',
  createEvent: 'createEvent',
  createHelpDocument: 'createHelpDocument',
  currentSettings: 'currentSettings',
  deleteBucket: 'deleteBucket',
  deleteClient: 'deleteClient',
  deleteEvent: 'deleteEvent',
  duplicateGoogleSheets: 'duplicateGoogleSheets',
  fixAllSettings: 'fixAllSettings',
  fixData: 'fixData',
  fixSettings: 'fixSettings',
  getThemesFromParent: 'getThemesFromParent',
  lockData: 'lockData',
  printHelp: 'printHelp',
  pushClientToProduction: 'pushClientToProduction',
  pushEventToProduction: 'pushEventToProduction',
  recreateEvent: 'recreateEvent',
  replaceExistingAndUpdateDataFromGoogleSheets: 'replaceExistingAndUpdateDataFromGoogleSheets',
  restoreSettingsFromBackup: 'restoreSettingsFromBackup',
  restoreSettingsFromGoogleSheets: 'restoreSettingsFromGoogleSheets',
  restoreSettingsFromPaste: 'restoreSettingsFromPaste',
  restoreSettingsFromRegistration: 'restoreSettingsFromRegistration',
  transferDataToProduction: 'transferDataToProduction',
  transferEventToProduction: 'transferEventToProduction',
  transferSettingsToProduction: 'transferSettingsToProduction',
  updateAllPageItems: 'updateAllPageItems',
  updateDataByType: 'updateDataByType',
  updateDataFromGoogleData: 'updateDataFromGoogleData',
  updateDataFromGoogleSheets: 'updateDataFromGoogleSheets',
  updateDataLists: 'updateDataLists',
  updateEventStatus: 'updateEventStatus',
  updateSettings: 'updateSettings',
  updateSettingsByType: 'updateSettingsByType',
  updateSettingsFromGoogleSheets: 'updateSettingsFromGoogleSheets',
}

export const itemActionTypes = {
  // add: 'add', 
  assignPageItemAccess: 'assignPageItemAccess',
  appUserInfo: 'appUserInfo',
  connectToAppUser: 'connectToAppUser',
  emailAuth: 'emailAuth',
  geoMapList: 'geoMapList',
  notifications: 'notifications',
  openDatabase: 'openDatabase',
  phoneAuth: 'phoneAuth',
  save: 'save',
  search: 'search',
  showItemCount: 'showItemCount',
  signInAs: 'signInAs',
  signInDirectEmail: 'signInDirectEmail',
  signInDirectPhone: 'signInDirectPhone',
  userNotification: 'userNotification',
  viewAccessRequests: 'viewAccessRequests',
  viewAppUserAuthorizations: 'viewAppUserAuthorizations',
  voiceSearch: 'voiceSearch',
  ...dataManagementTypes,
  ...dataModificationTypes,
  ...pageItemActionTypes,
  ...appUserPermissionTypes,
}

export const itemActionIcons = {
  activate: 'bolt',
  add: 'add',
  assignPageItemAccess: 'users',
  backupData: 'save',
  clipboard: 'clipboard outline',
  convertArrays: 'code',
  convertToDocument: 'wizard',
  convertToParentKeys: 'database',
  connections: 'linkify',
  connectToAppUser: 'linkify',
  createGlobalData: 'wizard',
  createFilter: 'filter',
  createTopic: 'target',
  createList: 'list',
  googleMap: 'map',
  dataModifications: 'unordered list',
  dataManagement: 'unordered list',
  delete: 'remove circle',
  dataOptions: 'options',
  edit: 'edit',
  emailAuth: 'mail',
  // favorite: 'star',
  googleSheetLink: 'google',
  googleSheetUpdate: 'google',
  imageMapping: 'map outline',
  images: 'image outline',
  linking: 'linkify',
  lockData: 'lock',
  notifications: 'alarm',
  notificationSettings: 'alarm',
  phoneAuth: 'mail',
  questionsAndAnswers: 'question',
  qrCode: 'qrcode',
  rating: 'star outline',
  requestAccess: 'handshake outline',
  search: 'search',
  signInAs: 'clone outline',
  signInDirectEmail: 'sign in',
  signInDirectPhone: 'sign in',
  sorting: 'sort numeric down',
  subAction: 'bolt',
  ticketing: 'ticket alternate',
  updateDataRelationships: 'handshake',
  updateParentKeys: 'key',
  userEmailSignInLinks: 'mail',
  userNotification: 'bell',
  videoConferencing: 'video',
  viewAccessRequest: 'handshake outline',
} 