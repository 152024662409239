export const projectStyles = () => {
  return {
    active: { style: {}, className: 'item-active' },
    alphabet: { style: {}, className: 'menu-alphabet' },
    appBanner: { style: {}, className: 'sem-banner' },
    appBanners: { style: {}, className: 'sem-carosel' },
    appInstallation: { style: {}, className: 'app-homepage' },
    background: { style: {}, className: 'app-content' },
    bottomMenu: { style: {}, className: 'navigation bc-sec' },
    content: { style: {}, },
    dateMenus: { style: {}, className: 'bc-main' },
    desktop: { style: {}, className: 'bc-main' },
    filters: { style: {}, className: 'menu-filter padd5 tar bc-bk' },
    gridIcons: { style: {}, className: 'grid-icons-content bc-sec' },
    gridSettingsIcons: { style: {}, className: 'grid-settings-icon bc-settings' },
    gridViewItemIcons: { style: {}, className: 'bc-settings' },
    listItems: { style: {}, className: 'grid-list-container bc-settings' },
    manifest: { style: {}, className: 'splash-container' },
    menu: { style: {}, },
    menuHorizontal: { style: {}, className: 'bc-main' },
    menuHorizontalBottom: { style: {}, className: 'menu-horz-tab-bottom bc-main' },
    menuItemActive: { style: {} },
    menuItemBottom: { style: {} },
    pageIconButtonText: { style: {}, className: 'seg-icon-text' },
    pageIconViewItem: { style: {}, className: 'seg-icon-view-item' },
    pageItemDimmer: { style: {}, className: 'page-item-dimmer' },
    sideMenu: { style: {}, className: 'hcf-container app bc-sec' }, //settings-menu-container
    splashScreen: { style: {}, className: 'splash-container' },
    subMenu: { className: 'sub-menu', style: {}, },
    tabItems: { className: 'bc-sec', },
    tabs: { style: {}, className: 'swipe-tab bc-main' },
    topMenu: { style: {}, className: 'top-menu' },
    topToggleMenu: { style: {}, className: 'top-toggle-menu' },
  }
}