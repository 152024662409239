import { serverTimestamp } from 'firebase/firestore';
import _ from 'lodash';
import { createKeys } from '../../common/creating';
import { _find } from '../../common/filtering';
import { copyObj } from '../../common_web/copy';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes, settingsIconTypes } from '../../enums/settingsIconTypes';
import { createRefPath } from "../../firestoreData/appData/appRefPaths";
import { fs_get_collection, fs_set_doc } from "../../firestoreData/appData/fsData";
import { fsfn_getAppSettings } from '../../functions/fbSettingsFunctions';
import { _settingsFs } from '../../viewSettings/actions/getSettings';
import { checkBasePermissions } from '../contexts/contextHelpers/baseSettings';
import { dispatchProps, grts } from './reducerHelpers/dispatchProps';

export const _appSettingsCollectionName = '_appSettings'
export const _appFormsCollectionName = '_appForms'

const rts = {
  handleGetAppSettingsFromFunctions: 'handleGetAppSettingsFromFunctions',
  handleGetBaseAppAreaSettings: 'handleGetBaseAppAreaSettings',
  handleGetBaseSettings: 'handleGetBaseSettings',
  handleSetBaseSettings: 'handleSetBaseSettings',
  handleSetFunctionsAppSettings: 'handleSetFunctionsAppSettings',
  handleUpdateAdjustedPermissions: 'handleUpdateAdjustedPermissions',
  handleUpdateAppSettings: 'handleUpdateAppSettings',
  // handleUpdateVwApp: 'handleUpdateVwApp',
  ...grts
}

const _allowAll = false
const _ammendedTypes = ['_position', 'dividedText', 'hideFromMenu', 'triggerUrl', 'allowSettingsUpdate', 'settingsUpdateHandler', 'settingsUpdateHandlerFunction', 'settingsUpdateAutomatic', 'settingsUpdateDescription']

export const baseSettingsReducer = (state, action) => {

  const { accessLevel, baseSettings, baseSettings_combined, pathViews, settingsConsole_home_global, aua } = state
  const { adjustedPermissions: adjustedPermissions_existing } = baseSettings ? baseSettings : {}
  const { dispatch, type, appArea } = action

  const { handleSetFunctionsAppSettings, handleSetBaseSettings } = baseSettingsHandlers(dispatch)

  switch (type) {

    // rhw0102@gmail.com
    // 3556happ

    case rts.handleGetAppSettingsFromFunctions:
      const vvProps = { appUserAccess: action.appUserAccess }
      // get the base settings from firebase functions
      const y = fsfn_getAppSettings(vvProps, action.logging)
      y.then(settings => { handleSetFunctionsAppSettings(settings) })
      return { ...state }

    case rts.handleSetFunctionsAppSettings:
      const { app: baseSettings_functions } = action.settings
      const adjustedPermissions_functions = checkBasePermissions(baseSettings_functions, settingsConsole_home_global, adjustedPermissions_existing)
      return { ...state, baseSettings_functions: { ...baseSettings_functions, adjustedPermissions: adjustedPermissions_functions } }

    // on init
    case rts.handleGetBaseSettings:
      if (accessLevel >= gEnums.accessLevels.appSubAdmin.value) {
        getHcceSetting_app(handleSetBaseSettings)
      }
      return { ...state }

    // on init
    // occurs once then on appUserChange
    case rts.handleSetBaseSettings:
      const { hcceSettings: _hcceSettings } = action
      const { app: _baseSettings } = _hcceSettings

      const c = copyObj(_baseSettings)
      const cs = getCombinedSettings(c)

      return {
        ...state,
        baseSettings: c,
        baseSettings_combined: cs,
      }

    // on init after baseSettings is set
    case rts.handleGetBaseAppAreaSettings:
      const { baseSettings_ammended, saSplits, childMenuItems } = getAmmendedBaseSettings(baseSettings, baseSettings_combined, appArea, accessLevel, pathViews)
      // console.log('saSplits', saSplits)
      return { ...state, appArea, baseSettings_combined, baseSettings: baseSettings_ammended, saSplits, childMenuItems }

    case rts.handleUpdateAppSettings:
      saveBaseSettingsToAppSettings(action.dataToUpdate, aua, action.callback)
      return { ...state }

    case rts.handleUpdateAdjustedPermissions:
      updateAdjustedPermission(action.consoleKey, action.consoleGroup, action.callback)
      return { ...state }


    default:
      return { ...state }
  }
}

export const baseSettingsInitialState = (initState) => {
  return { ...initState }
};

export const baseSettingsHandlers = (dispatch) => {
  return {
    handleGetAppSettingsFromFunctions: (appUserAccess, logging) => { dispatch({ type: rts.handleGetAppSettingsFromFunctions, dispatch, appUserAccess, logging }) },
    handleGetBaseAppAreaSettings: (appArea) => { dispatch({ type: rts.handleGetBaseAppAreaSettings, dispatch, appArea }) },
    handleGetBaseSettings: () => { dispatch({ type: rts.handleGetBaseSettings, dispatch }) },
    handleSetBaseSettings: (hcceSettings) => { dispatch({ type: rts.handleSetBaseSettings, dispatch, hcceSettings }) },
    handleSetFunctionsAppSettings: (settings) => { dispatch({ type: rts.handleSetFunctionsAppSettings, dispatch, settings }) },
    handleUpdateAdjustedPermissions: (consoleKey, consoleGroup, callback) => { dispatch({ type: rts.handleUpdateAdjustedPermissions, dispatch, consoleKey, consoleGroup, callback }) },
    handleUpdateAppSettings: (dataToUpdate, callback) => { dispatch({ type: rts.handleUpdateAppSettings, dispatch, dataToUpdate, callback }) },
    // handleUpdateVwApp: (dataToUpdate, callback) => { dispatch({ type: rts.handleUpdateVwApp, dispatch, dataToUpdate, callback }) },
  }
}


/**
 * Updates the `consoleKey` field in the `adjustedPermissions` object in _appSettings/app/settings/
 * @param {string} consoleKey 
 * @param {object} consoleGroup 
 * @param {function} callback 
 */
const updateAdjustedPermission = (consoleKey, consoleGroup, callback) => {
  const _refVw = createRefPath([_appSettingsCollectionName, 'app', _settingsFs.collection, 'adjustedPermissions'])
  const dataToUpdate = {
    [consoleKey]: consoleGroup
  }
  fs_set_doc(_refVw, dataToUpdate, true, callback)
}

/** Get the settings framework from the database collection _appSettings  */
const getHcceSetting_app = (callback) => {
  const _cb = (appSettings => callback(appSettings))
  getHcceSettings_a(_cb)
}

/**
 * 
 * @param {function} callback 
 */
const getHcceSettings_a = (callback) => {
  // const allS = ['app']

  const _refPath = createRefPath([_appSettingsCollectionName, 'app', _settingsFs.collection])
  fs_get_collection(_refPath).then(res => {
    const settings = {}
    Object.keys(res).forEach(sk => {
      settings[sk] = res[sk]
      delete settings[sk].id
      delete settings[sk]._itemKey
    })
    callback({ app: { ...settings } })
  })
}

const getCombinedSettings = (baseSettings) => {

  const useCombined = true

  const normalItems = ['global', 'other', 'page', 'pageItem', 'prop', 'propSection']

  const { componentPermissions, adjustedPermissions } = baseSettings
  const _permissions = adjustedPermissions ? adjustedPermissions : componentPermissions
  const { group } = _permissions ? _permissions : {}
  const _combined = {}

  Object.keys(baseSettings).forEach(key => {
    if (normalItems.includes(key)) {
      _combined[key] = {}
      const baseSetting = copyObj(baseSettings[key])
      const permissionSetting = _permissions[key]
      Object.keys(baseSetting).forEach(bsKey => {
        const baseSettingItem = baseSetting[bsKey]
        const permissionSettingItem = permissionSetting ? permissionSetting[bsKey] : null
        const _cbm = { ...baseSettingItem, ...permissionSettingItem }
        const combined = useCombined ? _cbm : baseSettingItem
        _combined[key][bsKey] = combined
      })
    }
  })

  _combined['group'] = {}
  Object.keys(group).forEach(groupKey => {
    _combined['group'][groupKey] = group[groupKey]
  })

  return _combined
}

const getAmmendedBaseSettings = (baseSettings, baseSettings_combined, appArea, accessLevel, pathViews, adjustedPermissions_s) => {

  const { componentPermissions, adjustedPermissions } = baseSettings
  const _adjustedPermissions = adjustedPermissions_s ? adjustedPermissions_s : adjustedPermissions

  // _permissions are the current permissions for the entire app
  const _permissions = _adjustedPermissions ? _adjustedPermissions : componentPermissions

  const allChildItems = []

  // const saSplits = {
  //   authGroups: {
  //     global: {
  //       6: {
  //         groupedItems: {}
  //       }
  //     },
  //     page: {}
  //   },
  //   global: {
  //     global: {},
  //     page: {}
  //   },
  //   group: {
  //     global: {},
  //     page: {}
  //   },
  //   page: {
  //     global: {},
  //     page: {}
  //   },
  //   pageItem: {
  //     global: {},
  //     page: {}
  //   },
  //   prop: {
  //     global: {},
  //     page: {}
  //   },
  //   propSection: {
  //     global: {},
  //     page: {}
  //   },
  // }

  const saSplits = ammendPageItemBaseSplits(baseSettings_combined, appArea, accessLevel, pathViews)

  // add the groupItems to each of the groups
  ammendAllowedGroupItemsForGroups(saSplits.group, baseSettings_combined, appArea, accessLevel, pathViews)

  const sci = {}
  allChildItems.sort().forEach(ci => {
    sci[ci] = { caption: _.startCase(ci) }
  })

  // baseSettings_ammended, saSplits, childMenuItems
  return { baseSettings_ammended: baseSettings, saSplits, childMenuItems: sci }

}

// const ammendAllowedGroupItemsForGroups = (group, baseSettings_combined, permissions, baseSettings, appArea, accessLevel, pathViews) => {
const ammendAllowedGroupItemsForGroups = (group, baseSettings_combined, appArea, accessLevel, pathViews) => {

  const _groupSortProp = 'settingsMenuGroupType'

  if (group) {

    Object.keys(group).forEach(groupKey => {

      const groupItem = group[groupKey]

      const combinedSetting = baseSettings_combined[groupKey]

      const isGlobal = groupKey === 'global' ? true : false

      if (groupItem) {
        Object.keys(groupItem).forEach(groupItemKey => {

          const _groupIteme = _.pickBy(combinedSetting, function (n) {
            if (n) { return (n[_groupSortProp] === groupItemKey) }
          })

          const _groupItems_allowed = filterAllowedGroupItems(groupItemKey, _groupIteme, appArea, isGlobal, accessLevel, pathViews)


          // ammend the dividedText and triggerUrl
          Object.keys(_groupItems_allowed).forEach(k => {

            const _groupItemee = _groupIteme[k]

            _ammendedTypes.forEach(ats => {
              if (_groupItemee[ats]) {
                _groupItems_allowed[k][ats] = _groupItemee[ats]
              }
            })

          })

          Object.keys(_groupItems_allowed).forEach(k => {
            const _groupItem = _groupIteme[k]
            _ammendedTypes.forEach(ats => {
              if (_groupItem[ats]) {
                _groupItems_allowed[k][ats] = _groupItem[ats]
              }
            })
          })

          Object.keys(_groupItems_allowed).forEach(k => {
            ammendCaptionAndIcon(_groupItems_allowed[k], appArea, k)
          })

          groupItem[groupItemKey].groupItems = _groupItems_allowed
        })
      }
    })
  }
}

const ammendCaptionAndIcon = (item, appArea, k) => {
  const { key, caption, icon, appAs, appAsNull, appUserAsNull } = item ? item : {}
  const _key = key ? key : k
  if (!caption) { item.caption = _.startCase(_key) }
  if (!icon) { item.icon = settingsIconTypes[_key] ? settingsIconTypes[_key] : 'cog' }

  const _appUserAsNull = k ? item.caption.startsWith('App User ') : false
  const _appAsNull = k && !_appUserAsNull ? item.caption.startsWith('App ') : false

  if (appUserAsNull || _appUserAsNull) {
    const _caption = _key.replace('appUser', '')
    item.caption = _.startCase(_caption).trim()
  }

  if (appAsNull || _appAsNull) {
    const _caption = _key.replace('app', '')
    item.caption = _.startCase(_caption).trim()
  }

  if (appAs) {
    const _caption = _key.replace('app', _.startCase(appArea)).trim()
    item.caption = _.startCase(_caption).trim()
  }
}


/**
 * 
 * @param {object} baseSettings 
 * @returns an ammended baseSettings
 * this will add a pageItemSplits object
 * {pageItem: {global: {}, page: {}}}
 * {prop: {global: {}, page: {}}}
 * {propSection: {global: {}, page: {}}}
 */
const ammendPageItemBaseSplits = (baseSettings_combined, appArea, accessLevel, pathViews) => {

  // these are the core settings with all the attibutes. This has nothing to do with any reduced/filtered items
  const { group, global, page, pageItem, prop, propSection } = baseSettings_combined ? baseSettings_combined : {}

  // create the splits object
  const saSplits = {
    group: { global: {}, page: {} },
    page: { global: {}, page: {} },
    global: { global: {}, page: {} },
    pageItem: { global: {}, page: {} },
    prop: { global: {}, page: {} },
    propSection: { global: {}, page: {} },
  }

  // filter the following by appArea, accessLevel and pathViews, NOT pageArea

  const allowed = {
    group: filterAllowed_aaapv(group, appArea, accessLevel, pathViews),
    global: filterAllowed_aaapv(global, appArea, accessLevel, pathViews),
    page: filterAllowed_aaapv(page, appArea, accessLevel, pathViews),
    pageItem: filterAllowed_aaapv(pageItem, appArea, accessLevel, pathViews),
    prop: filterAllowed_aaapv(prop, appArea, accessLevel, pathViews),
    propSection: filterAllowed_aaapv(propSection, appArea, accessLevel, pathViews),
  }

  // allowed does NOT contain groupItems at this point 

  Object.keys(allowed).forEach(allowedKey => {
    splitItemByPageAreaType(saSplits[allowedKey], allowed[allowedKey])
  })

  const _authGroups = getAuthGroups(saSplits.group, appArea)

  getGroupItemsForSplits(saSplits, appArea)

  return {
    ...saSplits,
    authGroups: _authGroups,
  }

}

const getGroupItemsForSplits = (saSplits, appArea) => {
  Object.keys(saSplits).forEach(spKey => {
    switch (spKey) {
      case 'pageItem':
      case 'prop':
      case 'propSection':
        const split = saSplits[spKey]
        Object.keys(split).forEach(spiKey => { // global or page
          const spItem = split[spiKey]
          Object.keys(spItem).forEach(spItemKey => { // item
            const spItemItem = spItem[spItemKey]
            const { groupItems } = spItemItem
            if (groupItems) {
              const _groupItems = ammendGroupItems(groupItems, spiKey === 'global', appArea, spItemKey)
              spItemItem.groupItems = _groupItems
            }
          })
        })
    }
  })
}

/**
 * Splits the item into global and page
 * @param {object} splitItem 
 * @param {object} items 
 */
const splitItemByPageAreaType = (splitItem, items) => {
  const pa = { global: {}, page: {} }
  Object.keys(items).forEach(k => {
    const miItem = items[k]
    const { componentAreas } = miItem
    const { pageAreaTypes } = componentAreas ? componentAreas : {}
    // loop the pa
    Object.keys(pa).forEach(paKey => {
      if (pageAreaTypes && pageAreaTypes.length > 0) {
        if (_allowAll || pageAreaTypes.includes('all') || pageAreaTypes.includes(paKey)) {
          switch (paKey) {
            case 'global':
              splitItem.global[k] = copyObj(miItem)
              break;
            case 'page':
              splitItem.page[k] = copyObj(miItem)
              break;
            default:
            // nothing
          }
        }
      } else {
        splitItem.global[k] = copyObj(miItem)
        splitItem.page[k] = copyObj(miItem)
      }
    })
  })
}

const getAuthGroups = (split_group, appArea) => {

  const _authGroups = { global: {}, page: {} }

  if (split_group) {
    Object.keys(split_group).forEach(groupKey => {
      const group = split_group[groupKey]
      createKeys(group)
      const grps = _.groupBy(group, 'settingsAuthLevel')
      Object.keys(grps).forEach(key => {
        const grp = grps[key]
        const grpo = {}
        grp.forEach(gr => {
          ammendCaptionAndIcon(gr, appArea)
          grpo[gr.key] = gr
        })
        const authLevel = _find(gEnums.authLevels, 'value', parseInt(key))
        const iconColor = authLevel ? authLevel.color : iconColorTypes.menuItem
        _authGroups[groupKey][key] = {
          groupedItems: grpo,
          iconColor: iconColor
        }
      })
    })
  }

  return _authGroups

}

/**
 * 
 * @param {object} items 
 * @param {string} appArea 
 * @param {number} accessLevel 
 * @param {object} pathViews_current 
 * @returns a list of allowed items
 * @description looks at the appAreaTypes, settingsAuthLevel and pathViews of each of the items componentAreas to see if the item is allowed.
 */
const filterAllowed_aaapv = (items, appArea, accessLevel, pathViews_current) => {
  const _allowedItems = {}
  const _nonAllowedItems = {}
  const _disallowedKeys = ['none']
  if (items) {
    Object.keys(items).forEach(key => {
      const item = items[key]
      if (isAllowed_aaapv(item, appArea, accessLevel, pathViews_current) && !_disallowedKeys.includes(key)) {
        _allowedItems[key] = item
      } else {
        _nonAllowedItems[key] = item
      }
    })
  }
  return _allowedItems
}

/**
 * 
 * @param {object} item 
 * @param {string} appArea 
 * @param {number} authLevel 
 * @param {object} pathViews_current 
 * @returns true if the item is allowed
 * @description looks at the appAreaTypes, settingsAuthLevel and pathViews of the componentAreas to see if the item is allowed
 */
const isAllowed_aaapv = (item, appArea, authLevel, pathViews_current) => {

  let allowed = true

  const { componentAreas, settingsAuthLevel, eventAreaKey, eventOnlyKeys, settingsMenuGroupType } = item ? item : {}
  const { appAreaTypes, pathViews } = componentAreas ? componentAreas : {}

  if (settingsAuthLevel) {
    if (authLevel < settingsAuthLevel) {
      allowed = false
    }
  }

  if (eventAreaKey) {
    if (pathViews_current.events) {
      allowed = eventOnlyKeys && eventOnlyKeys.includes(pathViews_current) ? true : false
    } else {
      allowed = false
    }
  }

  if (appAreaTypes && appAreaTypes.length > 0) {
    if (!appAreaTypes.includes('all')) {
      if (!appAreaTypes.includes(appArea)) {
        allowed = false
      }
    }
  }

  if (pathViews) {
    if (!pathViews.includes('all')) {
      if (!pathViews_current || !pathViews_current[pathViews]) {
        allowed = false
      }
    }
  }

  return _allowAll ? true : allowed
}


/**
 * Update the _appSettingsCollectionName.app.settings documents
 * @param {object} settings 
 * @param {function} callback 
 */
const saveBaseSettingsToAppSettings = (settings, aua, callback) => {
  if (settings) {
    appSettingsPromise(settings, aua).then(res => {
      console.log('saveBaseSettingsToAppSettings DONE')
      callback({ type: dispatchProps.success })
    }).catch(error => {
      console.log('error!!!!!!!!!!!!!!!!', error)
    })
  }
}

const appSettingsPromise = async (settings, aua) => {

  const promises = []
  const _ref = createRefPath([_appSettingsCollectionName, 'app'])
  const appData = {
    _updateDate: serverTimestamp(),
    ...aua,
  }
  promises.push(fs_set_doc(_ref, appData, true))
  Object.keys(settings).forEach(sk => {
    const setting = settings[sk]
    const _docRef = createRefPath([_appSettingsCollectionName, 'app', _settingsFs.collection, sk])
    promises.push(fs_set_doc(_docRef, setting, false))
  })
  return Promise.all(promises)
}

const filterAllowedGroupItems = (groupKey, items, appArea, isGlobal, authLevel, pathViews_current) => {
  const _disallowedKeys = ['none']
  const _allowedItems = {}
  const _nonAllowedItems = {}
  if (items) {
    Object.keys(items).forEach(key => {
      const item = items[key]
      if (key === 'sportSettings') {
        console.log('item', item)
      }
      if (isAllowedAll(item, appArea, isGlobal, authLevel, pathViews_current) && !_disallowedKeys.includes(key)) {
        _allowedItems[key] = item
      } else {
        _nonAllowedItems[key] = item
      }
    })
  }
  return _allowedItems
}

const isAllowedAll = (item, appArea, isGlobal, authLevel, pathViews_current) => {

  let allowed = true

  const { componentAreas, settingsAuthLevel, eventOnlyKeys, eventAreaKey } = item ? item : {}
  const { appAreaTypes, pageAreaTypes, pathViews } = componentAreas ? componentAreas : {}

  if (settingsAuthLevel) {
    if (authLevel < settingsAuthLevel) {
      allowed = false
    }
  }

  if (eventOnlyKeys) {
    if (pathViews_current.events) {
      allowed = eventOnlyKeys.includes(pathViews_current)
    } else {
      allowed = false
    }
  }

  if (eventAreaKey) {
    if (pathViews_current.events) {
      allowed = eventAreaKey === pathViews_current.events
    } else {
      allowed = false
    }
  }

  if (appAreaTypes && appAreaTypes.length > 0) {
    if (!appAreaTypes.includes('all')) {
      if (!appAreaTypes.includes(appArea)) {
        allowed = false
      }
    }
  }

  if (pageAreaTypes && pageAreaTypes.length > 0) {
    if (!pageAreaTypes.includes('all')) {
      if (isGlobal) {
        if (!pageAreaTypes.includes('global')) {
          allowed = false
        }
      } else {
        if (!pageAreaTypes.includes('page')) {
          allowed = false
        }
      }
    }
  }

  if (pathViews) {
    if (!pathViews.includes('all')) {
      if (!pathViews_current || !pathViews_current[pathViews]) {
        allowed = false
      }
    }
  }

  return allowed
}

const ammendGroupItems = (groupItems, isGlobal, appArea, spItemKey) => {
  const _allowed = {}
  const _excluded = {}
  if (groupItems) {
    Object.keys(groupItems).forEach(k => {
      const groupItem = groupItems[k]
      const { componentAreas } = groupItem ? groupItem : {}
      const { appAreaTypes, pageAreaTypes } = componentAreas ? componentAreas : {}
      let allowed = true

      if (appAreaTypes && appAreaTypes.length > 0) {
        if (!appAreaTypes.includes('all')) {
          if (!appAreaTypes.includes(appArea)) {
            allowed = false
          }
        }
      }
      if (pageAreaTypes && pageAreaTypes.length > 0) {
        if (!pageAreaTypes.includes('all')) {
          if (isGlobal) {
            if (!pageAreaTypes.includes('global')) {
              allowed = false
            }
          } else {
            if (!pageAreaTypes.includes('page')) {
              allowed = false
            }
          }
        }
      }
      if (!allowed) {
        _excluded[k] = groupItems[k]
      } else {
        _allowed[k] = groupItems[k]
      }
    })
  }

  return _allowed

}