// serviceworker.js

export let _deferredPrompt = null

// Register the service worker
export function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered: ', registration);
        window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();
          _deferredPrompt = e
        });
      })
      .catch(error => {
        console.log('Service Worker registration failed: ', error);
      });
  }
}

// Unregister the service worker
export function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.log('Service Worker unregistration failed: ', error);
      });
  }
}

// Listen for service worker updates
export function listenForServiceWorkerUpdates(onUpdate) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      onUpdate();
    });
  }
}