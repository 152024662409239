import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Segment } from 'semantic-ui-react';
import NoData from '../alerts/NoData';
import { AppAccessContext } from '../../cnr/contexts/AppAccessContext';
import { PapsContext } from '../../cnr/contexts/PapsContext';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import { fs_update_dataCollection } from '../../firestoreData/appData/fsData';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import GenericButton from '../../genericControls/GenericButton';
import { UiItemContext } from '../../cnr/contexts/UiItemContext';

const collectionName = '_accessRequests'

const AppAccessRequests = (props) => {

  const { viewRequest } = props ? props : {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers, item_state } = uiItemContext ? uiItemContext : {}
  const { itemRating } = item_state ? item_state : {}
  const { handleShowItemSidebar, handle_fsSaveDataItemRating } = item_handlers ? item_handlers : {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { navigate, paps_state } = papsContext ? papsContext : {}
  const { pathViews, view, viewKey } = paps_state ? paps_state : {}

  // appAccessContext
  const appAccessContext = useContext(AppAccessContext)
  const { appAccess_state, appAccess_fns } = appAccessContext ? appAccessContext : {}
  const { accessRequests } = appAccess_state ? appAccess_state : {}

  // local state
  const [access_requests, setAccessRequests] = useState()

  useEffect(() => {
    if (viewRequest) {
      const ar = appAccess_fns.filterAccessRequests(view, viewKey)
      setAccessRequests(ar)
    } else {
      setAccessRequests(accessRequests)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [accessRequests]);

  const callback = () => { } // fs_get_data_all(pathViews, collectionName, handleAccessRequests)

  const handleCancel = () => {
    if (handleShowItemSidebar) {
      handleShowItemSidebar()
    } else if (props.handleCancel) {
      props.handleCancel()
    }
  }

  const handleView = (item) => navigate.push(item.pathName)

  const handleChange = (e, data) => {
    const { value, item } = data
    switch (value) {
      case gEnums.accessStatusTypes.approved:
        handleApprove(item)
        break;
      case gEnums.accessStatusTypes.denied:
        handleDeny(item)
        break;
      default:
        handlePending(item)
        break;
    }
  }

  const handlePending = (item) => {
    const { _pathName, _pathKey } = item
    const itemUpdate = { [_pathName + '_' + _pathKey]: { ...item, status: gEnums.accessStatusTypes.pending } }
    fs_update_dataCollection(pathViews, collectionName, item.appUserEmail, itemUpdate, callback)
  }

  const handleApprove = (item) => {
    const { _pathName, _pathKey } = item
    const itemUpdate = { [_pathName + '_' + _pathKey]: { ...item, status: gEnums.accessStatusTypes.approved } }
    fs_update_dataCollection(pathViews, collectionName, item.appUserEmail, itemUpdate, callback)
  }

  const handleDeny = (item) => {
    const { _pathName, _pathKey } = item
    const itemUpdate = { [_pathName + '_' + _pathKey]: { ...item, status: gEnums.accessStatusTypes.denied } }
    fs_update_dataCollection(pathViews, collectionName, item.appUserEmail, itemUpdate, callback)
  }

  const optionsList = () => {
    const opts = []
    Object.keys(gEnums.accessStatusTypes).forEach(key => {
      opts.push({ key: key, value: key, text: _.startCase(key), icon: { ...icon(key) } })
    })
    return opts
  }

  const icon = (status) => {
    switch (status) {
      case gEnums.accessStatusTypes.approved:
        return { color: 'green', name: 'check' }
      case gEnums.accessStatusTypes.denied:
        return { color: 'red', name: 'delete' }
      default:
        return { color: 'grey', name: 'question' }
    }
  }

  const dropdown = (item) => {
    return <Dropdown
      button
      size={'mini'}
      className='icon thin profile-select access'
      icon={{ ...icon(item.status) }}
      labeled
      options={optionsList()}
      item={{ ...item }}
      scrolling
      onChange={handleChange}
      text={_.startCase(item.status)}
      style={{ float: 'right', color: 'black' }}
    />
  }

  const gb = (key, caption, icon, onClick, clickOptions, opts, noE) => <GenericButton gbProps={{ key, caption, icon, onClick, clickOptions, opts, noE }} />

  const card = (item) => {
    const { appUserName, appUserEmail, _pathName, _pathCaption } = item
    return <Card fluid className={'card-access'}>
      <Card.Content>
        <Card.Header>{appUserName}</Card.Header>
        <Card.Meta>{appUserEmail}</Card.Meta>
        <Card.Description className={'ip-url'} >
          {_pathName}{': '}{_pathCaption}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        {gb('btn-view', 'View', 'find', handleView, item, { color: iconColorTypes.menuItem, fl: true })}
        {dropdown(item)}
      </Card.Content>
    </Card>
  }

  const cards = () => Object.keys(access_requests).map(key => {
    const item = access_requests[key]
    const requestItem = { ...item }
    delete requestItem.id
    const sorted = _.sortBy(requestItem, '_pathCaption')
    return sorted.map(sortedItem => (card(sortedItem)))
  })

  const content = () => {
    if (access_requests && Object.keys(access_requests).length > 0) {
      return <Segment inverted>
        {cards()}
      </Segment>
    } else {
      return handleCancel ? <NoData altCaption={'Access Requests'} /> : <Segment inverted>{'No Access Requests'}</Segment>
    }
  }

  const wrapper = () => <Wrapper
    content={content()}
    wrapperType={wrapperTypes.padded}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={'Access Requests'}
  />

  return handleCancel ? fullPageWrapper() : content()

}

export default AppAccessRequests