// import differenceInSeconds from 'date-fns/difference_in_seconds'

import { formatItem, formatTypes } from "../../../global/common/dateFormatting"
import _ from 'lodash'

/**
 * 
 * @param {object} matches 
 * @param {object} updatedMatch 
 */
export const calcAllVballMatches = (matches, updatedMatch) => {
  Object.keys(matches).forEach((key, i) => {
    const match = matches[key]
    // only calc the matches where the team is involved
    if (updatedMatch) {
      if (updatedMatch.home === match.home || updatedMatch.home === match.away || updatedMatch.away === match.home || updatedMatch.away === match.away) {
        calcVballMatch(match, updatedMatch)
      }
    } else {
      calcVballMatch(match)
    }
  })
}

/**
 * 
 * @param {object} match 
 * @param {object} updatedMatch 
 */
export const calcVballMatch = (match, updatedMatch) => {

  let { results, results_current, results_realtime } = match

  let _results = results_current ? results_current : results
  if (results_realtime) { _results = results_realtime }

  const { tempScores, bestOf, setCount, sections, levels, isPlayoff, poolKey } = match
  const thisMatch = updatedMatch ? updatedMatch : match

  if (!match.roundInfo && thisMatch.roundInfo) { match.roundInfo = thisMatch.roundInfo }

  if (sections === 'Non') {
    match.ls = sections + '-Section'
  } else if (sections && levels) {
    match.ls = levels + ' - ' + _.startCase(sections)
  } else if (isPlayoff) {
    match.ls = levels + ' - ' + _.startCase(poolKey)
  }

  if (match && match.startDate) { match.startDate = formatItem(formatTypes.shortestDate, match.startDate) }

  match.setsToWin = bestOf ? Math.round(setCount / 2) : setCount

  if (!_results) { _results = {} }

  _results.setsCompleted = 0
  _results.scoresCompleted = 0
  _results.complete = false
  _results.winner = null
  _results.loser = null
  _results.scoreDisplay = '---'
  _results.setWinners = {}
  _results.tempScoreCount = (tempScores) ? Object.keys(tempScores).length : 0

  ammendMatchDate(match)
  ammendScores(match)
  ammendResults(match)

}

/**
 * Ammends the match
 * @param {object} match 
 */
const ammendMatchDate = (match) => {
  // update match date and time
  if (match.startDate && match.startTime) {

    const matchDate = new Date(match.startDate);
    const todaysDate = new Date()
    const matchTime = parseInt(match.startTime.substring(0, 1), 10) + 12

    const realMatchDate = new Date(matchDate.getFullYear(), matchDate.getMonth(), matchDate.getDate())
    const realTodaysDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate())

    const realMatchDateAndTime = new Date(matchDate.getFullYear(), matchDate.getMonth(), matchDate.getDate(), matchTime, 0, 0)
    const realTodaysDateAndTime = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds())

    match.startDate = formatItem(formatTypes.shortestDate, matchDate)

    match.startTimeUTC = realMatchDateAndTime
    match.startDateUTC = realMatchDate

    if (realMatchDateAndTime <= realTodaysDateAndTime) {
      match.isPast = true
    }

    if (realMatchDate <= realTodaysDate) {
      match.isToday = true
    }

    if (match.home && match.away) {
      match.matchName = match.away.name + ' @ ' + match.home.name
    }

    if (match.away && match.away.name === 'BYE') {
      match.isBye = true
    }
  } else if (match.startDateUTC && match.startTimeUTC) {
    match.startDate = formatItem(formatTypes.shortestDate, match.startDateUTC)
  }
}

/**
 * 
 * @param {object} match 
 */
const ammendResults = (match) => {

  let { results, results_current } = match
  let _results = results_current ? results_current : results
  const { bestOf, setCount } = match
  const stw = match.setsToWin ? match.setsToWin : 3
  let { score } = _results ? _results : {}

  // do we have a score?
  if (score) {

    let winnerDeclared = false

    // did a team win?
    if (bestOf) {
      if ((score.home === stw || score.away === stw) && (score.home >= 0 && score.away >= 0)) {
        winnerDeclared = true
      }
    } else {
      if ((score.home + score.away === setCount)) {
        winnerDeclared = true
      }
    }

    if (winnerDeclared) {
      _results.complete = true
      _results.inProgress = false
      // which team won
      if (score.home === stw) {
        _results.winner = match.home
        _results.loser = match.away
      } else {
        _results.winner = match.away
        _results.loser = match.home
      }
    }

    // _results.inProgress = true

    if (match.away && match.home && score) {
      if ((score.home === stw || score.away === stw) && (score.home >= 0 && score.away >= 0)) {
        _results.complete = true
        delete _results.inProgress
        if (score.home === stw) {
          _results.winner = match.home
          _results.loser = match.away
        } else if (score.away === stw) {
          _results.winner = match.away
          _results.loser = match.home
        }
      }
      _results.setsCompleted = score.home + score.away
      _results.scoreDisplay = score.away + ' - ' + score.home
      _results.scoreDisplayHome = score.home + ' - ' + score.away
      _results.scoreDisplayFull = match.away.name + ' ' + score.away + ' - ' + score.home + ' ' + match.home.name
      _results.officialResult = true
    }
  }
}

/**
 * ammends the scores
 * @param {object} match 
 */
const ammendScores = (match) => {

  let { results, results_current } = match
  let _results = results_current ? results_current : results
  let { score, scores, setScorez } = _results ? _results : {}
  const stw = match.setsToWin ? match.setsToWin : 3

  if (scores) {
    if (!score) {
      score = {
        home: 0,
        away: 0
      }
    }

    score.home = 0
    score.away = 0

    const maxPoints = 25
    const maxPoints5 = 15

    Object.keys(scores).forEach((key, setIndex) => {

      let set_number = setIndex + 1

      const _score = scores[key]

      if (set_number <= 4) {
        if (_score.home > maxPoints && _score.away < _score.home && _score.away !== 0) { _score.away = _score.home - 2 }
        if (_score.away > maxPoints && _score.home < _score.away && _score.home !== 0) { _score.home = _score.away - 2 }
      } else {
        if (_score.home > maxPoints5 && _score.away < _score.home && _score.away !== 0) { _score.away = _score.home - 2 }
        if (_score.away > maxPoints && _score.home < _score.away && _score.home !== 0) { _score.home = _score.away - 2 }
      }

    })

    Object.keys(scores).forEach((key, setIndex) => {

      let set_number = setIndex + 1

      const sett = scores[key]
      const h = parseInt(sett.home, 10)
      const a = parseInt(sett.away, 10)

      if ((h > 0) || (a > 0)) { set_number = setIndex + 1 }

      // a team has more than 25 points and the diff > 2
      if (set_number <= 4) {
        if (((h >= maxPoints) || (a >= maxPoints)) && Math.abs(h - a)) {
          if (h > a) { score.home++ }
          if (h < a) { score.away++ }
          _results.setWinners[set_number] = (h > a) ? 'home' : 'away'
        }
      } else {
        if (((h >= maxPoints5) || (a >= maxPoints5)) && Math.abs(h - a)) {
          if (h > a) { score.home++ }
          if (h < a) { score.away++ }
          _results.setWinners[set_number] = (h > a) ? 'home' : 'away'
        }
      }

      _results.setsCompleted = score.home + score.away
      _results.scoreDisplay = score.away + ' - ' + score.home
      _results.scoreDisplayHome = score.home + ' - ' + score.away

      _results.setScore = scores && scores[set_number] ? scores[set_number] : { home: 0, away: 0 }
      if (_results.setScore) {
        _results.setScoreDisplay = _results.setScore.away + ' - ' + _results.setScore.home
      }

      _results.score = score
      _results.hasScores = true
      _results.setNumber = set_number
      _results.scoresCompleted = Object.keys(scores).length

      if (setScorez) { _results.setScorez = setScorez }

      _results.complete_possible = (_results.score.home >= stw) || (_results.score.away >= stw) ? true : false

      if (_results.inProgress && !_results.complete) { _results.scoreDisplay = _results.setScoreDisplay }

    })

    _results.officialResult = true

  }
}

// export const matchCountdown = (match) => {

//   const matchTime = match.realDateAndTime
//   const todaysDate = new Date()
//   const realTodaysDateAndTime = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds())

//   let diff = differenceInSeconds(matchTime, realTodaysDateAndTime)
//   diff = Math.floor(diff);

//   let days = Math.floor(diff / (24 * 60 * 60))
//   let leftSec = diff - days * 24 * 60 * 60

//   let hrs = Math.floor(leftSec / (60 * 60));
//   leftSec = leftSec - hrs * 60 * 60;

//   let min = Math.floor(leftSec / (60));
//   leftSec = leftSec - min * 60;

//   if (hrs < 10) { hrs = '0' + hrs }
//   if (min < 10) { min = '0' + min }
//   if (leftSec < 10) { leftSec = '0' + leftSec }

//   return hrs + ':' + min + ':' + leftSec

// }

