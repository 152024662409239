import _ from 'lodash'
import { updateGlobal } from "../../../projectSpecific/sports/dbActions/globals"
import { gEnums } from "../../enums/globalEnums"
import { fs_update_dataCollection } from '../../firestoreData/appData/fsData'
import { sidebarMenuTypes } from "./SidebarReducer"

const imageMapperTypes = {
  getFloormapImageFromStorage: 'getFloormapImageFromStorage',
  handleFloormapUrlChange: 'handleFloormapUrlChange',
  handleLocation: 'handleLocation',
  handleLocationName: 'handleLocationName',
  handleNewZone: 'handleNewZone',
  handleUpdated: 'handleUpdated',
  setAllZones: 'setAllZones',
  setCurrentMapFieldData: 'setCurrentMapFieldData',
  handleFloormapUrlResponse: 'handleFloormapUrlResponse',
  setCurrentZone: 'setCurrentZone',
  setSvgElements: 'setSvgElements',
  updateFieldData: 'updateFieldData',
  updateFieldDataToDb: 'updateFieldDataToDb',
  updateZonesToDb: 'updateZonesToDb',
}

export const imageMapReducer = (state, action) => {

  const { setInit, _allZones, floormapSelectedItem, mapFieldName } = state
  const { type, value, img, imageMapType, newItem, dispatch } = action

  const { handleFloormapUrlResponse, handleUpdated } = imageMapHandlers(dispatch)

  switch (type) {

    case imageMapperTypes.handleLocationName:
      return { ...state, locationName: action.locationName }

    case imageMapperTypes.handleUpdated:
      return { ...state, updating: false }

    case imageMapperTypes.setAllZones:
      return { ...state, _allZones: value }

    case imageMapperTypes.setCurrentMapFieldData:
      if (mapFieldName && value && value[mapFieldName]) {
        return { ...state, mapFieldData: value[mapFieldName] }
      } else {
        return { ...state, mapFieldData: value }
      }

    case imageMapperTypes.setCurrentZone:
      return { ...state, currentZone: value }

    case imageMapperTypes.setSvgElements:
      return { ...state, svgElements: value }

    case imageMapperTypes.getFloormapImageFromStorage:
      // get the floormap url from the selected floormap item
      // sets the floormapSelectedItem  
      // fileName, fullImage, storageType   
      state.storage_fns.getImageUrl(action.floormapSelectedItem, true, gEnums.storageTypes.floormap, handleFloormapUrlResponse)
      return { ...state, floormapSelectedItem: action.floormapSelectedItem }

    case imageMapperTypes.handleFloormapUrlResponse:
      // callback for from getFloormapImageFromStorage
      // returns the url of the floormap
      // sets the floormapUrl and nulls the currentZone  
      const { url } = img ? img : {}
      // , currentZone: null
      return { ...state, floormapUrl: url }

    case imageMapperTypes.handleFloormapUrlChange:

      // this triggers when the floormapUrl has changed 
      let nzs;
      let _svge = []

      if (_allZones && floormapSelectedItem && _allZones[floormapSelectedItem]) {
        const x = _allZones[floormapSelectedItem]
        const { zones } = x ? x : {}
        if (zones) {
          const { namedZones, combined } = getSvgCombined(zones)
          // update the nzones
          nzs = namedZones
          _svge = combined
        }
      }

      setInit({ smt: sidebarMenuTypes.two, items: nzs ? Object.keys(nzs) : [], as: 'zones', useStartCase: true })
      return { ...state, svgElements: _svge, zones: nzs }

    // newItem
    case imageMapperTypes.handleNewZone:

      const zz = { ...state.zones }
      const zi = Object.keys(zz).length + 1
      const key = 'zone ' + zi

      newItem.color = 'green'
      newItem.text = zi // 'Zone ' + zi
      newItem.zone = 'zone ' + zi

      zz[key] = newItem

      const svgs = [...state.svgElements]
      svgs.push(newItem)

      setInit({ smt: sidebarMenuTypes.two, items: Object.keys(zz), as: 'zones', useStartCase: true })
      return { ...state, svgElements: svgs, zones: zz }

    case imageMapperTypes.handleLocation:

      switch (imageMapType) {

        case gEnums.imageMapTypes.location:

          let currZone;

          if (state._allZones && state.mapFieldData && state.itemLocation) {
            // const _itemKey = _.findKey(state.mapFieldData, { name: state.itemLocation })
            const _itemKey = state.locationName ? _.findKey(state.mapFieldData, { name: state.locationName }) : _.findKey(state.mapFieldData, { name: state.itemLocation })

            if (state.mapFieldData[_itemKey]) {
              const _location = state.mapFieldData[_itemKey]
              const { zone } = _location ? _location : {}
              const { imageKey, zoneIndex } = zone ? zone : {}
              const item = state._allZones[imageKey]
              const { zones } = item ? item : {}
              if (zones) { currZone = zones[zoneIndex] }
              return { ...state, currentZone: currZone }
            }
          }
          break;
        default:
        // nothing
      }

      return { ...state }

    // database
    case imageMapperTypes.updateZonesToDb:
      const svge = [...state.svgElements]
      fs_update_dataCollection(state.pathViews, state.collectionName, action.docId, { zones: svge }, handleUpdated)
      return { ...state, updating: true }

    case imageMapperTypes.updateFieldDataToDb:
      const { fieldItem, floormapSelectedItem: fmi, zoneIndex } = action.updateProps
      if (state.mapFieldData && fieldItem) {
        const _itemKey = _.findKey(state.mapFieldData, { name: fieldItem })
        if (state.mapFieldData[_itemKey]) {
          state.mapFieldData[_itemKey].zone = {
            imageKey: fmi,
            zoneIndex: zoneIndex
          }
          updateGlobal(state.pathViews, state.mapFieldName, state.mapFieldData, handleUpdated)
        }
      }
      return { ...state, updating: true }

    case imageMapperTypes.updateFieldData:
      const _mapFieldData = { ...state.mapFieldData }
      const { fieldItem: fi, floormapSelectedItem: fmsi, zoneIndex: zid } = action.updateProps
      if (_mapFieldData && fi) {
        const _itemKey = _.findKey(_mapFieldData, { name: fi })
        if (_mapFieldData[_itemKey]) {
          _mapFieldData[_itemKey].zone = {
            imageKey: fmsi,
            zoneIndex: zid
          }
        }
      }
      return { ...state, mapFieldData: _mapFieldData }

    default:
      return state
  }
}

export const imageMapInitialState = ({ storage_fns, mapFieldName, collectionName, itemLocation, setInit, pathViews }) => {
  return {
    collectionName,
    itemLocation,
    mapFieldData: {},
    mapFieldName,
    pathViews,
    setInit,
    storage_fns,
    svgElements: [],
    zones: [],
  }
};

export const imageMapHandlers = (dispatch) => {
  return {
    /** Toggles `showModalSort` */
    setAllZones: (value) => { dispatch({ type: imageMapperTypes.setAllZones, value }) },
    setCurrentMapFieldData: (value) => { dispatch({ type: imageMapperTypes.setCurrentMapFieldData, value }) },
    setCurrentZone: (value) => { dispatch({ type: imageMapperTypes.setCurrentZone, value }) },
    setSvgElements: (value) => { dispatch({ type: imageMapperTypes.setSvgElements, value }) },

    /** Gets the url image from the selected floormap */
    getFloormapImageFromStorage: (floormapSelectedItem) => { dispatch({ type: imageMapperTypes.getFloormapImageFromStorage, floormapSelectedItem, dispatch }) },

    handleFloormapUrlChange: () => { dispatch({ type: imageMapperTypes.handleFloormapUrlChange, dispatch }) },
    handleFloormapUrlResponse: (img, floormapSelectedItem) => { dispatch({ type: imageMapperTypes.handleFloormapUrlResponse, img, floormapSelectedItem }) },
    handleLocation: (imageMapType) => { dispatch({ type: imageMapperTypes.handleLocation, imageMapType, dispatch }) },
    handleLocationName: (locationName) => { dispatch({ type: imageMapperTypes.handleLocationName, locationName, dispatch }) },
    handleNewZone: (newItem) => { dispatch({ type: imageMapperTypes.handleNewZone, newItem }) },

    updateZonesToDb: (docId) => { dispatch({ type: imageMapperTypes.updateZonesToDb, docId, dispatch }) },
    updateFieldData: (updateProps) => { dispatch({ type: imageMapperTypes.updateFieldData, dispatch, updateProps }) },
    updateFieldDataToDb: (updateProps) => { dispatch({ type: imageMapperTypes.updateFieldDataToDb, dispatch, updateProps }) },
    handleUpdated: () => { dispatch({ type: imageMapperTypes.handleUpdated, dispatch }) },
  }
}

const getSvgCombined = (_allZones) => {
  const be = []
  const elems = []
  // create the zones
  _allZones.forEach((zone, index) => {
    const i = index + 1
    zone.text = i // 'Zone ' + i
    zone.zone = 'zone ' + i
    elems.push(zone)
  })
  // create the namedZones
  const combined = [...be, ...elems]
  const namedZones = {}
  if (elems) {
    elems.forEach((elem, index) => {
      const i = index + 1
      const key = 'zone ' + i
      namedZones[key] = elem
    })
  }
  return { namedZones, combined }
}