import { where } from 'firebase/firestore';
import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { createItemKeys } from '../../../../global/common/convert';
import { formatItem, formatTypes } from '../../../../global/common/dateFormatting';
import { getLastItem, getLastItemSortedByKey } from '../../../../global/common/filtering';
import { copyObj } from '../../../../global/common_web/copy';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { createFsDocKey, fs_get_data, fs_get_data_all } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_set_doc } from '../../../../global/firestoreData/appData/fsData';
import { fsfn_getGoogleSheetRange } from '../../../../global/functions/fbSheetsFuntions';
import { getRealtimeScores } from '../../dbActions/realtimeScoring';
import { sortDB } from '../../helpers/league';
import { calcAllVballMatches, calcVballMatch } from '../../helpers/match';
import { ammendTeamName, fixTeamNames, matchNeedsUpdated } from './SportsMatchReducer';
import { getData_parentKeyCollections } from './reducerHelpers/seasonSportsData';
import { getPks, getSports_collectionItem, seasonalUpdateTypes, updateSeasonalOrGlobal } from './reducerHelpers/seasonalSportsData';

function weeksBetween(d1, d2) {
  const weeks = Math.round((new Date(d2) - new Date(d1)) / (7 * 24 * 60 * 60 * 1000))
  const totalWeeks = weeks + 2
  return totalWeeks
}

export const sportsDbCollections = {
  seasonal: {
    allStartNominations: 'allStartNominations',
    allStarTeams: 'allStarTeams',
    districts: 'districts',
    globals: 'globals',
    googleLinks: 'googleLinks',
    history: 'history',
    matches: 'matches',
    playoffs: 'playoffs',
    rankings: 'rankings',
    rosters: 'rosters',
    scheduleDates: 'rosters',
    schools: 'rosters',
    seasonals: 'seasonals',
    teams: 'teams',
  },
  normal: {
    athletes: 'athletes',
    districts: 'districts',
    globals: 'globals',
    organizations: 'organizations',
    schools: 'schools',
    teams: 'teams',
  }
}

export const matchUpdateTypes = {
  addConsolationMatch: 'addConsolationMatch',
  addToHistory: 'addToHistory',
  cancelMatch: 'cancelMatch',
  confirmMatchDate: 'confirmMatchDate',
  deleteMatch: 'deleteMatch',
  deleteRealtimeMatch: 'deleteRealtimeMatch',
  deleteScore: 'deleteScore',
  notifyMatch: 'notifyMatch',
  postponeMatch: 'postponeMatch',
  resetByeMatch: 'resetByeMatch',
  updateMatchDate: 'updateMatchDate',
  updateMatchListResults: 'updateMatchListResults',
  updateMatchListResultsFromGs: 'updateMatchListResultsFromGs',
  updateMatchLocation: 'updateMatchLocation',
  updateResults: 'updateResults',
  updateScore: 'updateScore',
}

const pojoSports = {
  _matches: {
    docKey: {
      parentKeys: { keys: 'odsy' },
      matches: {
        docKey: {
          away: {},
          home: {},
          data: {}
        }
      }
    }
  },
  _teams: {
    docKey: {
      parentKeys: { keys: 'odsy' },
      teams: {
        docKey: {
          data: {}
        }
      }
    }
  },
  _scheduleDates: {
    docKey: {
      parentKeys: { keys: 'odsy' },
      scheduleDates: {
        '6': [],
        '7': [],
      }
    }
  },
  _rankings: {
    docKey: {
      parentKeys: { keys: 'odsy' },
      rankings: {
        docKey: {
          data: {}
        }
      }
    }
  },
  _playoffs: {
    docKey: {
      parentKeys: { keys: 'odsy' },
      playoffInfo: {},
      playoffs: {
        docKey: {
          data: {}
        }
      }
    }
  },
  _history: {
    docKey: {
      parentKeys: { keys: 'ods' },
      history: {
        docKey: {
          '2A': {
            '2008': {
              winner: 'Team1',
              runnerUp: 'Team2',
            }
          },
          '3A': {}
        }
      }
    }
  },
  _rosters: {
    docKeys: {
      parentKeys: { keys: 'odsyt' },
      athletes: {
        docKey: {
          data: {}
        }
      }
    }
  },
}

const rts = {
  // handleSetPlayoffMatches: 'handleSetPlayoffMatches',
  handleBackupSeasonMatches: 'handleBackupSeasonMatches',
  handleCurrentHistory: 'handleCurrentHistory',
  handleGetParentKeyCollectionItems: 'handleGetParentKeyCollectionItems',
  handleGetSeasonMatches: 'handleGetSeasonMatches',
  handleGetSportsSeasonInfo: 'handleGetSportsSeasonInfo',
  handleGetSportsSeasonInit: 'handleGetSportsSeasonInit',
  handleGoToMatch: 'handleGoToMatch',
  handleGoToTeam: 'handleGoToTeam',
  handleSetDataResults: 'handleSetDataResults',
  handleSetHistory: 'handleSetHistory',
  handleSetMatches: 'handleSetMatches',
  handleSetParentKeyCollectionItems: 'handleSetParentKeyCollectionItems',
  handleSetRankings: 'handleSetRankings',
  handleSetRealtimeResults: 'handleSetRealtimeResults',
  handleSetSeasonMatches: 'handleSetSeasonMatches',
  handleSetSidebar: 'handleSetSidebar',
  handleSetState: 'handleSetState',
  handleSetTeams: 'handleSetTeams',
  handleSportsMode: 'handleSportsMode',
  handleSportsTeamsResults: 'handleSportsTeamsResults',
  handleUpdateSportsSeasonData: 'handleUpdateSportsSeasonData',
  handleUpdateSportsSeasonTs: 'handleUpdateSportsSeasonTs',
  setSportSeasonInit: 'setSportSeasonInit',
  setSportsSeasonData: 'setSportsSeasonData',
  updateSportsSeasonSubData: 'updateSportsSeasonSubData',
  handleMatchesData: 'handleMatchesData',
  handleGetGsMatches: 'handleGetGsMatches',
  handleGetGsRoster: 'handleGetGsRoster',
  handleSetGsMatches: 'handleSetGsMatches',
  handleSetGsRoster: 'handleSetGsRoster',
  handleAmmendGsMatches: 'handleAmmendGsMatches',
  ...grts,
}

const dataItems_init = {
  sports: { parentKeys: ['organizations', 'districts', 'name'], returnFirstObject: true },
}

const dataItems_sportsSeason = {
  history: { collectionItems: ['history'], noYear: true },
  matches: { collectionItems: ['matches'], inCollection: 'matches' },
  matchesNonSection: { collectionItems: ['matchesNonSection'] },
  playoffs: { collectionItems: ['playoffMatches'], collectionDoc: 'playoffMatches' },
  rankings: { collectionItems: ['rankings'] },
  rosters: { collectionItems: ['rosters'], allDocs: true },
  scheduleDates: { collectionItems: ['scheduleDates'] },
  seasonals: { collectionItems: ['seasonals'], collectionDoc: 'details' },
  staffs: { collectionItems: ['staffs'], noYear: true, allDocs: true },
  teams: { collectionItems: ['teams'] },
  gls_teams: { collectionItems: ['gls_teams'], noYear: true },
  gls_scores: { collectionItems: ['gls_scores'], noYear: true },
}

const dataItems_sportsSeasonSchedule = {
  matches: { collectionItems: ['matches'], inCollection: 'matches' },
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsSeasonReducer = (state, action) => {

  const {
    currents,
    currentSidebars,
    dataParents,
    googleLinks_info,
    latestSeason,
    matches_info,
    pathViews,
    teams_info,
  } = state

  const { gls_scores } = googleLinks_info ? googleLinks_info : {}
  const { matches } = matches_info ? matches_info : {}

  const { type, dispatch } = action

  // const state = {
  //   history: {
  //     '2A': []
  //   },
  //   matches: {
  //     'matchKey1': { matchData: {} },
  //     'matchKey2': { matchData: {} },
  //   },
  //   playoffMatches: {
  //     'matchKey1': { matchData: {isPlayoff: true} },
  //     'matchKey2': { matchData: {isPlayoff: true} },
  //   },
  //   rankings: {
  //     'week1': {
  //       '2A': ['team1', 'team2'],
  //       '3A': ['team1', 'team2']
  //     }
  //   },
  //   scheduleDates: {
  //     '6': ['date1', 'date2'],
  //     '7': ['date1', 'date2'],
  //   },
  //   teams: {
  //     'teamKey1': {},
  //     'teamKey2': {}
  //   }
  // }

  const sportsSeason_handlers = sportsSeasonHandlers(dispatch)
  const { handleGetSportsSeasonInfo, handleSetDataResults, handleSetSeasonMatches, handleSetRealtimeResults, handleSetParentKeyCollectionItems, handleSetGsMatches, handleSetGsRoster, handleAmmendGsMatches } = sportsSeason_handlers

  switch (type) {

    case rts.handleSportsMode:
      return { ...state, _sportsMode: action.sportsMode, _sportsModeKey: action.sportsModeKey }

    case rts.handleGetSportsSeasonInit:

      Object.keys(dataItems_init).forEach(key => {
        const dataItemDirect = dataItems_init[key]
        const { returnFirstObject } = dataItemDirect
        const refPath_sports = createRefPath_event(pathViews, [key])
        const { wheres } = getWheres_sports(pathViews, dataItemDirect)

        fs_get_data({ refPath: refPath_sports, wheres, opts: { returnFirstObject } }).then(returnedData => {
          switch (key) {
            case 'sports':
              const { latestSeason: latest_season, _itemKey } = returnedData ? returnedData : {}
              const _latestSeason = action.selectedSeason ? action.selectedSeason : latest_season
              // trigger to get the data for the sports season
              handleGetSportsSeasonInfo(_itemKey, _latestSeason)
            default:
          }
        })
      })

      return { ...state }

    case rts.handleGetSportsSeasonInfo:

      const { sportsKey: sports_key, selectedSeason } = action
      const additionalDataParents = ['sports', 'sportsYear']
      // get data from history, matches, matchesNonSection, playoffs, rankings, rosters, scheduleDates, teams
      getData_parentKeyCollections(pathViews, dataItems_sportsSeason, dataParents, additionalDataParents, sports_key, selectedSeason, handleSetDataResults)
      getRealtimeScores(pathViews, latestSeason, handleSetRealtimeResults)

      return { ...state, sportsKey: sports_key }

    // handles the result when all the data from the parentKeys are collected
    case rts.handleSetDataResults:

      const { dataResult, selectedSeason: _selectedSeason, matchDateGroups, parentDataKeys, sportsKey, levelMatches, levelDateGroups, levelLatestDates } = action

      const drs = {}

      Object.keys(dataItems_sportsSeason).forEach(dataItemKey => {
        const dataItem = dataItems_sportsSeason[dataItemKey]
        const { collectionDoc } = dataItem ? dataItem : {}
        if (collectionDoc) {
          drs[collectionDoc] = dataResult[collectionDoc]
        } else {
          drs[dataItemKey] = dataResult[dataItemKey]
        }
      })

      const {
        scheduleDates,
      } = dataResult ? dataResult : {}

      const ssi = drs.teams && drs.matches ? ammendTeams(drs.teams, drs.matches, drs.rankings) : null

      const amm = ammendMatches(drs.matches)
      const { sportLevels, teams } = ssi
      const { matchDateKeys, matchDateKeys_count } = getMatchDateKeys(matchDateGroups)

      const _sections = teams ? _.groupBy(teams, 'sections') : null

      const matchesTeams = {}

      if (amm.matches && teams) {
        Object.keys(teams).forEach(tk => {
          const tms = _.filter(amm.matches, function (m) { return m.teams && m.teams.includes(tk); })
          const today = new Date();
          const _next = _.takeRight(_.filter(_.orderBy(tms, ['startDate'], ['desc']), item => new Date(item.startDate).setHours(state._startHour, 0, 0, 0) >= today), 1);
          const _latest = _.takeRight(_.filter(_.orderBy(tms, ['startDate'], ['asc']), item => (item.results && item.results.complete === true) && new Date(item.startDate).setHours(state._startHour, 0, 0, 0) < today), 5);
          matchesTeams[tk] = {
            all: tms,
            next: _next,
            latest: _latest
          }
        })
      }

      return {
        ...state,
        latestSeason: _selectedSeason,
        latestSeason_full: _selectedSeason ? (_selectedSeason - 1) + '-' + _selectedSeason : _selectedSeason,
        history_info: {
          history: drs.history,
          teams: getTeamHistories(drs.teams, drs.history)
        },
        matches_info: {
          matchDateGroups,
          matchDateKeys_count,
          matchDateKeys,
          // matchesTeams,
          levelMatches,
          levelDateGroups,
          levelLatestDates,
          matches: amm.matches,
          matchesNonSection: drs.matchesNonSection,
          playoffMatches: drs.playoffMatches,
          matchesAll: drs.playoffMatches ? { ...amm.matches, ...drs.playoffMatches } : amm.matches,
          matchesRetrieved: true
        },
        playoff_info: {
          playoffTournaments: action.pts ? action.pts : null,
        },
        teams_info: {
          teams: teams,
          rosters: drs.rosters,
          staffs: drs.staffs,
          playoffTeams: getPlayoffTeams(teams),
          sectionKeys: _sections ? Object.keys(_sections).sort() : []
        },
        rankings_info: {
          rankings: drs.rankings,
          lastestRanking: getLastestRankings(drs.rankings)
        },
        seasonal_info: {
          scheduleDates,
          sportLevels,
          totalWeeks: amm.totalWeeks,
          seasonReady: true,
        },
        details_info: {
          details: drs.details,
        },
        googleLinks_info: {
          gls_scores: drs.gls_scores,
          gls_teams: drs.gls_teams
        },
        parentDataKeys,
        // sportsKey,
      }

    case rts.handleGetSeasonMatches:
      const additionalDataParents_sm = ['sports', 'sportsYear']
      getData_parentKeyCollections(pathViews, dataItems_sportsSeasonSchedule, dataParents, additionalDataParents_sm, state.sportsKey, action.selectedSeason, handleSetSeasonMatches, handleSetRealtimeResults)
      return { ...state }

    case rts.handleSetSeasonMatches:
      const { dataResult: dataResult_sm, matchDateGroups: matchDateGroups_sm } = action
      const { matches: matches_season } = dataResult_sm ? dataResult_sm : {}

      console.log('matchDateKeys_sm', matchDateKeys_sm)

      const { matchDateKeys: matchDateKeys_sm, matchDateKeys_count: matchDateKeys_count_sm } = getMatchDateKeys(matchDateGroups_sm)
      calcAllVballMatches(matches_season)

      const amm_sm = ammendMatches(matches_season)

      return {
        ...state,
        seasonMatches_info: {
          matchDateGroups: matchDateGroups_sm,
          matchDateKeys_count: matchDateKeys_count_sm,
          matchDateKeys: matchDateKeys_sm,
          matches: amm_sm.matches,
        }
      }

    case rts.handleBackupSeasonMatches:
      const { matchesToBackup, currentYear, callback } = action
      Object.keys(matchesToBackup).forEach(cmk => {
        if (!matchesToBackup[cmk].setsToWin) { matchesToBackup[cmk].setsToWin = 3 }
        if (!matchesToBackup[cmk].setCount) { matchesToBackup[cmk].setCount = 5 }
        delete matchesToBackup[cmk]._itemKey
      })
      const pks = getPks(pathViews, currentYear, state.sportsKey)
      updateSeasonalOrGlobal(seasonalUpdateTypes.backupMatches, pathViews, pks, 'backup_matches', 'matches', matchesToBackup, callback)
      callback()
      return { ...state }

    case rts.handleSportsTeamsResults:
      const { results, cbProps } = action
      const { dataKey } = cbProps ? cbProps : {}
      return { ...state, [dataKey]: results }


    case rts.handleCurrentHistory:
      return { ...state, selectedHistory: action.selectedHistory }

    case rts.handleSetState:
      if (action.groupProp) {
        const _groupProp = state[action.groupProp] ? state[action.groupProp] : {}
        _groupProp[action.stateProp] = action.value
        return { ...state, [action.groupProp]: _groupProp }
      } else {
        return { ...state, [action.stateProp]: action.value }
      }

    case rts.setSportsSeasonData:
      if (action && action.ssData) {
      }
      return { ...state }

    case rts.handleSetRealtimeResults:

      const { matches: _seasonMatches, playoffMatches: _playoffMatches } = matches_info ? matches_info : {}
      const { realtimeResults: rtr } = action

      if (rtr) {
        Object.keys(rtr).forEach(mKey => {
          const rtMatch = rtr[mKey]
          Object.keys(rtMatch).forEach(matchUserKey => {
            const rtUserMatch = rtMatch[matchUserKey]
            const { results } = rtUserMatch
            const { scores } = results
            if (scores && _.isArray(scores)) {
              let _scores = {}
              scores.forEach((score, setIndex) => {
                _scores[setIndex] = score
              })
              results.scores = _scores
            }
            const m = { results }
            calcVballMatch(m)
          })
        })
      }

      if (matches_info) {
        // matches_info.matches = _seasonMatches
        // matches_info.playoffMatches = _playoffMatches
        // matches_info.realtimeMatches_playoff = rtr ? rtr : null
        matches_info.matches_realtime = rtr ? rtr : null
        return { ...state, matches_info: matches_info }
      } else {
        return { ...state }
      }

    case rts.handleUpdateSportsSeasonTs:
      return { ...state, timestamp: new Date() }

    case rts.handleUpdateSportsSeasonData:
      const { dataToUpdate } = action
      const _refPath = createRefPath_event(pathViews, ['sports', state.sportsKey])
      // fs_update_doc(_refPath, dataToUpdate, action.callback)
      return { ...state }

    case rts.updateSportsSeasonSubData:
      return { ...state, [action.subDataType]: action.subData }

    case rts.handleSetSidebar:
      const crts = { ...currents }
      const crtsbrs = { ...currentSidebars }
      crts[action.clickType] = action.selected
      crtsbrs[action.sbItemType] = action.selected
      return { ...state, currents: crts, currentSidebars: crtsbrs }

    case rts.handleSortedLevels:
      return { ...state, sortedLevels: action.sortedLevels }

    case rts.handleGoToTeam:
      action.pushSimple({ key: 'teams' }, action.team.id)
      return { ...state, currentTeam: action.team }

    case rts.handleGoToMatch:
      action.pushSimple(action.clickedItem, action.itemKey, action.modifyMode, action.opts, action.ddn, action.ddProps, action.ddGroupIndex)
      return { ...state, match_current: matches ? matches[action.itemKey] : null }

    case rts.handleGetParentKeyCollectionItems:
      getAllParentKeyCollectionItems(pathViews, action.collectionName, state.sportsKey, { collectionName: action.collectionName }, handleSetParentKeyCollectionItems)
      return { ...state }

    case rts.handleSetParentKeyCollectionItems:
      const { collectionName, sportsItem } = action.d
      return { ...state, [collectionName]: sportsItem }

    case rts.handleMatchesData:
      return { ...state, matchesData: getMatchesData(matches_info.matches) }

    case rts.handleGetGsMatches:
      fsfn_getGoogleSheetRange(gls_scores, handleSetGsMatches)
      return { ...state }

    case rts.handleSetGsMatches:
      ammendMatchesFromGs(action.gsData, state.matchesData, teams_info.teams, matches_info.matches, handleAmmendGsMatches)
      return { ...state }

    case rts.handleAmmendGsMatches:
      return { ...state, updating: false, matchUpdates: action.matchUpdates, matchesChanged: action.matchesChanged, matchesNew: action.matchesNew }

    case rts.handleGetGsRoster:
      if (action.gsData) {
        const rosterData = {
          sheetName: action.gsData.rosterSheet,
          googleSheetsId: action.gsData.googleSheetsId
        }
        fsfn_getGoogleSheetRange(rosterData, handleSetGsRoster, null, true, action.team)
      }
      return { ...state, roster_gs: null }

    case rts.handleSetGsRoster:
      if (action.team) {
        console.log('first', action.team, action.gsData)
        return { ...state, ['roster_gs_' + action.team]: action.gsData }
      } else {
        return { ...state, roster_gs: action.gsData }
      }

    case rts.handleStartUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })


    default:
      return { ...state }
  }
}

export const sportsSeasonHandlers = (dispatch) => {
  return {
    handleMatchesData: () => { dispatch({ type: rts.handleMatchesData, dispatch }) },
    handleGetGsMatches: () => { dispatch({ type: rts.handleGetGsMatches, dispatch }) },
    handleGetGsRoster: (gsData, team) => { dispatch({ type: rts.handleGetGsRoster, dispatch, gsData, team }) },
    handleSetGsMatches: (gsData) => { dispatch({ type: rts.handleSetGsMatches, dispatch, gsData }) },
    handleSetGsRoster: (gsData, team) => { dispatch({ type: rts.handleSetGsRoster, dispatch, gsData, team }) },
    handleAmmendGsMatches: (matchesData, matchUpdates, matchesNew, matchesChanged) => { dispatch({ type: rts.handleAmmendGsMatches, dispatch, matchesData, matchUpdates, matchesNew, matchesChanged }) },

    handleBackupSeasonMatches: (currentYear, matchesToBackup, callback) => { dispatch({ type: rts.handleBackupSeasonMatches, dispatch, currentYear, matchesToBackup, callback }) },
    handleCurrentHistory: (selectedHistory) => { dispatch({ type: rts.handleCurrentHistory, dispatch, selectedHistory }) },
    handleGetParentKeyCollectionItems: (collectionName) => { dispatch({ type: rts.handleGetParentKeyCollectionItems, dispatch, collectionName }) },
    handleGetSeasonMatches: (selectedSeason) => { dispatch({ type: rts.handleGetSeasonMatches, dispatch, selectedSeason }) },
    handleGetSportsSeasonInfo: (sportsKey, selectedSeason) => { dispatch({ type: rts.handleGetSportsSeasonInfo, dispatch, sportsKey, selectedSeason }) },
    handleGetSportsSeasonInit: (selectedSeason) => { dispatch({ type: rts.handleGetSportsSeasonInit, dispatch, selectedSeason }) },
    handleGoToMatch: (pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => { dispatch({ type: rts.handleGoToMatch, dispatch, pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex }) },
    handleGoToTeam: (pushSimple, team) => { dispatch({ type: rts.handleGoToTeam, dispatch, pushSimple, team }) },
    handleSetDataResults: (dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys, sportsKey, levelMatches, levelDateGroups, levelLatestDates) => { dispatch({ type: rts.handleSetDataResults, dispatch, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys, sportsKey, levelMatches, levelDateGroups, levelLatestDates }) },
    handleSetHistory: (history) => { dispatch({ type: rts.handleSetHistory, history }) },
    handleSetMatches: (matches) => { dispatch({ type: rts.handleSetMatches, matches }) },
    handleSetParentKeyCollectionItems: (d) => { dispatch({ type: rts.handleSetParentKeyCollectionItems, dispatch, d }) },
    handleSetRankings: (rankings) => { dispatch({ type: rts.handleSetRankings, rankings }) },
    handleSetRealtimeResults: (realtimeResults) => { dispatch({ type: rts.handleSetRealtimeResults, realtimeResults }) },
    handleSetSeasonMatches: (dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys, sportsKey) => { dispatch({ type: rts.handleSetSeasonMatches, dispatch, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys, sportsKey }) },
    handleSetSidebar: (clickType, selected, sidebarType, sbItemType) => { dispatch({ type: rts.handleSetSidebar, clickType, selected, sidebarType, sbItemType }) },
    handleSetState: (stateProp, value, groupProp) => { dispatch({ type: rts.handleSetState, stateProp, value, groupProp }) },
    handleSetTeams: (teams) => { dispatch({ type: rts.handleSetTeams, teams }) },
    handleSportsMode: (sportsMode, sportsModeKey) => { dispatch({ type: rts.handleSportsMode, dispatch, sportsMode, sportsModeKey }) },
    handleSportsTeamsResults: (results, cbProps) => { dispatch({ type: rts.handleSportsTeamsResults, dispatch, results, cbProps }) },
    handleUpdateSportsSeasonData: (dataToUpdate, callback) => { dispatch({ type: rts.handleUpdateSportsSeasonData, dataToUpdate, callback }) },
    handleUpdateSportsSeasonTs: () => { dispatch({ type: rts.handleUpdateSportsSeasonTs }) },
    setSportSeasonInit: () => { dispatch({ type: rts.setSportSeasonInit, dispatch }) },
    setSportsSeasonData: (ssData) => { dispatch({ type: rts.setSportsSeasonData, ssData }) },
    updateSportsSeasonSubData: (subDataType, subData) => { dispatch({ type: rts.updateSportsSeasonSubData, subDataType, subData }) },
    ...responseHandlers(dispatch),
  }
}

export const sportsSeasonInitialState = (init_state) => {
  return { ...init_state, currents: {}, currentSidebars: {} }
};

const getWheres_sports = (pathViews, dataItemDirect, sportsKey) => {

  const { parentKeys } = dataItemDirect ? dataItemDirect : {}

  // get the sport
  const wheres = []
  const _wheres = []

  parentKeys.forEach(parentKey => {
    switch (parentKey) {
      case 'name':
        wheres.push(where("name", "==", _.startCase(pathViews.sports)))
        _wheres.push({ name: _.startCase(pathViews.sports) })
        break;
      default:
        if (pathViews[parentKey]) {
          switch (parentKey) {
            case 'sports':
              wheres.push(where('parentKeys.' + parentKey, '==', sportsKey))
              _wheres.push({ [parentKey]: sportsKey })
              break;
            default:
              wheres.push(where('parentKeys.' + parentKey, '==', pathViews[parentKey]))
              _wheres.push({ [parentKey]: pathViews[parentKey] })
          }
        }
    }
  })

  return { wheres, _wheres }

}

const getWheres_sportsTeams = (pathViews) => {

  const dps = ['organizations', 'districts', 'sports']

  // get the sport
  const wheres = []
  const _wheres = []

  dps.forEach(dp => {
    if (pathViews[dp]) {
      wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
      _wheres.push({ [dp]: pathViews[dp] })
    }
  })

  return { wheres, _wheres }

}

const getLastestRankings = (rankings) => {
  if (rankings) {
    const _rs = getLastItemSortedByKey(rankings)
    return _rs
  }

}

const getPlayoffTeams = (teams) => {
  const _playoffTeams = []
  Object.keys(teams).forEach(k => {
    const team = teams[k]
    if (team && team.record && team.record.playoffTeam) {
      _playoffTeams.push(team)
    }
  })
  return _playoffTeams
}

const getTeamHistories = (teams, history) => {

  const teamHistory = {}

  const getYears = (th, items, hr, winner) => {
    items.forEach(item => {
      if (winner) { th.winners.push({ year: item._itemKey, level: hr }) }
      if (!winner) { th.runnerUps.push({ year: item._itemKey, level: hr }) }
    })
  }

  if (teams && history) {
    Object.keys(teams).forEach(tk => {
      const team = teams[tk]
      teamHistory[tk] = {
        winners: [],
        runnerUps: []
      }
      const { name } = team
      Object.keys(history).forEach(hk => {
        const history_level = history[hk]
        createItemKeys(history_level)
        const winners = _.filter(history_level, { winner: name })
        const runnerUps = _.filter(history_level, { runnerUp: name })
        if (winners && winners.length > 0) { getYears(teamHistory[tk], winners, hk, true) }
        if (runnerUps && runnerUps.length > 0) { getYears(teamHistory[tk], runnerUps, hk) }
      })
    })
  }

  return teamHistory

}
// *****************************************

const updateTeams = (teams_season, pathViews) => {

  const handleAllTeams = (teams) => {

    const handleAllSchools = (schools) => {

      const _newTeams = {}

      Object.keys(teams_season).forEach(tk => {
        if (!teams[tk]) {
          const team = teams_season[tk]
          const { name } = team ? team : {}
          const _school = _.findKey(schools, { name: name })
          _newTeams[tk] = {
            name: name,
            parentKeys: {
              organizations: pathViews.organizations,
              districts: pathViews.districts,
              schools: _school,
              sports: pathViews.sports,
            }
          }
        }
      })

      Object.keys(_newTeams).forEach((tky, index) => {
        const _newTeam = _newTeams[tky]
        const _ref = createRefPath_event(pathViews, ['teams', tky])
        if (index === 0) {
          fs_set_doc(_ref, _newTeam, true)
        }
      })
      // }
    }

    // get the schools
    // fs_get_data_all(pathViews, 'schools', handleAllSchools, { dataParents: ['organizations', 'districts'] })
  }

  // get the teams
  fs_get_data_all(pathViews, 'teams', handleAllTeams, { dataParents: ['organizations', 'districts', 'sports'] })

}

const getAllParentKeyCollectionItems = (pathViews, collectionName, sportsKey, cbProps, callback) => {
  const _pks = getPks(pathViews, null, sportsKey)
  getSports_collectionItem(pathViews, _pks, collectionName, false, cbProps, callback)
}

const isSectionMatch = (m) => {
  if (m && m.home && m.away && (m.home.sections === m.away.sections) && (m.home.levels === m.away.levels)) {
    return true
  }
}

/**
 * 
 * @param {object} teams
 * @param {object} matches 
 */
const updateTeamData = (teams, matches, currentRankings) => {
  // const allTeamScores = {}
  // loop the teams
  if (teams) {
    Object.keys(teams).forEach(teamKey => {
      const team = teams[teamKey]
      if (team) {
        // set the base record
        team.record = { mt: 0, mp: 0, w: 0, l: 0, sw: 0, sl: 0, nsmt: 0, nsmp: 0, nsw: 0, nsl: 0, nssw: 0, nssl: 0 }
        getTeamRank(team, currentRankings)

        if (matches) {
          team.record = getTeamRecord(matches, teamKey)
        }
      }
    })
  }
}

const getTeamRank = (team, currentRankings) => {
  const { name } = team ? team : {}
  if (currentRankings) {
    Object.keys(currentRankings).forEach(crKey => {
      const cr = currentRankings[crKey]
      if (cr && cr.includes(name)) {
        team.currentRank = cr.indexOf(name) + 1
      }
    })
  }
}
/**
 * 
 * @param {object} matches 
 * @param {string} teamKey 
 * @returns the record (w, sw, l, sl) for the team
 */
export const getTeamRecord = (matches, teamKey) => {

  const record = {
    wins: null,
    sectionWins: null,
    losses: null,
    sectionLoses: null,
  }

  record.wins = _.pickBy(matches, function (m) {
    if (m && m.results && m.results.winner) { return m.results.winner && m.results.winner.id === teamKey }
  })

  if (record.wins) {
    record.sectionWins = _.pickBy(record.wins, function (m) {
      if (m && m.results && m.results.winner) { return m.results.winner && m.results.winner.id === teamKey && isSectionMatch(m) }
    })
  }

  record.losses = _.pickBy(matches, function (m) {
    if (m && m.results && m.results.loser) { return m.results.loser && m.results.loser.id === teamKey }
  })

  if (record.losses) {
    record.sectionLoses = _.pickBy(record.losses, function (m) {
      if (m && m.results && m.results.loser) { return m.results.winner && m.results.loser.id === teamKey && isSectionMatch(m) }
    })
  }

  return {
    w: Object.keys(record.wins).length,
    sw: Object.keys(record.sectionWins).length,
    l: Object.keys(record.losses).length,
    sl: Object.keys(record.sectionLoses).length,
  }

}

const updateTeamSectionInfo = (levels) => {
  if (levels) {
    Object.keys(levels).forEach(levelKey => {
      const level = levels[levelKey]
      const sections = _.groupBy(level, 'sections')
      if (sections) {
        Object.keys(sections).forEach(sectionKey => {
          const section = sections[sectionKey]
          sortDB.sortSectionStandings(section)
        })
      }
    })
  }
}

const getMatchDateKeys = (matchDateGroups) => {
  const today = new Date()
  const _today = formatItem(formatTypes.shortestDate, today)
  let _todaysMatchCount;
  const matchDateKeys = []
  const matchDateKeys_count = {}
  if (matchDateGroups) {
    matchDateGroups.forEach(group => {
      matchDateKeys.push(group.matchDate)
      matchDateKeys_count[group.matchDate] = Object.keys(group.matches).length
      if (group.matchDate === _today) {
        _todaysMatchCount = Object.keys(group.matches).length
      }
    })
  }
  // if (!matchDateGroups.includes(_today)) {
  //   matchDateKeys.push(_today)
  //   matchDateKeys_count[_today] = _todaysMatchCount
  // } 
  return { matchDateKeys, matchDateKeys_count }
}


/**
 * 
 * @param {object} teams 
 * @param {object} matches 
 * @returns teams, sportDistricts, sportLevels
 */
const ammendTeams = (teams, matches, rankings) => {
  let sportLevels = []
  const currentRankings = getLastItem(rankings)
  updateTeamData(teams, matches, currentRankings)
  // updateTeamBests(action.ssData)
  // const districts = _.groupBy(teams, 'districts')
  // console.log('ammendTeams', 'districts', districts)pRank
  // const dks = Object.keys(districts)
  // sportDistricts = dks 
  const levels = _.groupBy(teams, 'levels')
  updateTeamSectionInfo(levels)
  const lks = Object.keys(levels).sort().reverse()
  sportLevels = lks
  // sportLevels.push('Non')
  return { teams, sportLevels }
}

/**
 * 
 * @param {object} matches 
 * @param {function} callback 
 * @returns 
 */
const ammendMatches = (matches, callback) => {
  let totalWeeks = 1;
  const dm = _.groupBy(matches, 'startDate')
  const dms = Object.keys(dm)
  const dmsk = _.sortBy(dms, function (value) { return new Date(value); })
  totalWeeks = weeksBetween(dmsk[0], dmsk[dmsk.length - 1])
  return { matches, totalWeeks }
}

// eslint-disable-next-line 
const getSportsSeasonPromise = async (baseRef, docId, matches) => {
  const promises = []
  if (matches) {
    Object.keys(matches).forEach(mzKey => {
      const m = matches[mzKey]
      const matchRef = baseRef.doc(docId).collection('matches').doc(mzKey)
      promises.push(matchRef.set(m, { merge: true }))
    })
  }
  return Promise.all(promises)
}

const getMatchesData = (matches_season) => {
  const _matchesSeason = {}
  Object.keys(matches_season).forEach(mk => {
    const ms = matches_season[mk]
    const { away, home, startDate, sections, levels, results, isPlayoff } = ms
    const { score } = results ? results : {}
    const { home: score_home, away: score_away } = score ? score : {}
    if (!isPlayoff) {
      _matchesSeason[mk] = {
        home: home ? home.name : '?',
        homeScore: score_home,
        away: away ? away.name : '?',
        awayScore: score_away,
        section: sections,
        level: levels,
        startDate,
        results
      }
    }
  })
  return _matchesSeason
}

const ammendMatchesFromGs = (gsData, matchesData, teams, matches_season, callback) => {

  if (gsData && matchesData) {

    const _matchesData = copyObj(matchesData)

    Object.keys(gsData).forEach(dk => {

      const match_gs = gsData[dk]

      let { home: home_gs, away: away_gs } = match_gs ? match_gs : {}

      const _homeName = ammendTeamName(home_gs)
      const _awayName = ammendTeamName(away_gs)

      const match_found = _.find(_matchesData, { home: _homeName, away: _awayName })
      const match_found_key = _.findKey(_matchesData, { home: _homeName, away: _awayName })

      if (_matchesData[match_found_key]) { delete _matchesData[match_found_key]._changed }

      const matchScores = {
        gs: {
          home: match_gs.homeScore || _.isNumber(match_gs.homeScore) ? parseInt(match_gs.homeScore) : null,
          away: match_gs.awayScore || _.isNumber(match_gs.awayScore) ? parseInt(match_gs.awayScore) : null,
        },
        found: match_found ? {
          home: match_found.homeScore || _.isNumber(match_found.homeScore) ? parseInt(match_found.homeScore) : null,
          away: match_found.awayScore || _.isNumber(match_found.awayScore) ? parseInt(match_found.awayScore) : null,
        } : {}
      }

      if (match_found_key && match_found) {
        if (_.isNumber(matchScores.gs.home) && _.isNumber(matchScores.gs.away)) {
          if (_.isNumber(matchScores.found.home) && _.isNumber(matchScores.found.away)) {
            if ((matchScores.gs.home !== matchScores.found.home) || (matchScores.gs.away !== matchScores.found.away)) {
              _matchesData[match_found_key].awayScore = matchScores.gs.away
              _matchesData[match_found_key].homeScore = matchScores.gs.home
              _matchesData[match_found_key]._changed = true
            }
          } else {
            if (_.isNumber(matchScores.gs.away) && _.isNumber(matchScores.gs.home)) {
              _matchesData[match_found_key].awayScore = matchScores.gs.away
              _matchesData[match_found_key].homeScore = matchScores.gs.home
              _matchesData[match_found_key]._changed = true
            }
          }
        }
      } else {
        // new match 
        const _teamHome = _.findKey(teams, { name: _homeName })
        const _teamAway = _.findKey(teams, { name: _awayName })
        if (_teamHome && _teamAway) {
          match_gs._new = true
          _matchesData[createFsDocKey('updateMatch')] = match_gs
        }
      }
    })
    const matchUpdates = createMatchesFromList({ itemData: _matchesData, teams, matches_season })

    const _matchesChanged = _.filter(_matchesData, { _changed: true })
    const _matchesNew = _.filter(_matchesData, { _new: true })

    callback(_matchesData, matchUpdates, _matchesChanged, _matchesNew)
  }
}

const createMatchesFromList = (props) => {

  const { itemData: matchListData, teams, matches_season } = props

  const matches_updated = {
    section: {},
    nonSection: {},
  }

  const matches_created = {
    section: {},
    nonSection: {},
    nonUpdated: [],
    nonTeam: [],
    city: [],
    matchesToUpdate: null,
  }

  fixTeamNames(matchListData)

  // loop the matches_season in the matchData (list)
  if (matchListData) {

    Object.keys(matchListData).forEach(key => {

      // get the match from the list
      const match_item = matchListData[key]
      const { away, home, awayScore, homeScore, startDate, newStartDate, _new, _changed } = match_item

      // if there is a home and awau team
      if (home && away) {

        const _homeName = ammendTeamName(home)
        const _awayName = ammendTeamName(away)

        const _home = _.find(teams, { name: _homeName })
        const _away = _.find(teams, { name: _awayName })

        const { id: id_home, sections: section_home, levels: level_home } = _home ? _home : {}
        const { id: id_away, sections: section_away, levels: level_away } = _away ? _away : {}

        const isSectionMatch = (level_home === level_away) && (section_home === section_away)
        const isLevelMatch = (level_home === level_away)

        let _homeScore;
        let _awayScore;

        try {
          _homeScore = parseInt(homeScore)
          _awayScore = parseInt(awayScore)
          _homeScore = _.isNumber(_homeScore) && !isNaN(_homeScore) ? _homeScore : null
          _awayScore = _.isNumber(_awayScore) && !isNaN(_awayScore) ? _awayScore : null
        } catch (error) {

        }

        // find the match from the existing matches_season list
        const _matchSeasonFound = _.find(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const _matchSeasonFoundKey = _.findKey(matches_season, function (m) {
          return ((m.home && m.home.id === id_home) && (m.away && m.away.id === id_away));
        })

        const isValidScore = _.isNumber(_homeScore) && !isNaN(_homeScore) && _.isNumber(_awayScore) && !isNaN(_awayScore) ? true : false


        // if (_new) {
        //   console.log('new', match_item, _matchSeasonFoundKey)
        // }

        // if (_changed) {
        //   console.log('_changed', match_item, _matchSeasonFoundKey)
        // }


        // if match is found, update the score
        // if the match is not found, it is most likely a non-section match
        if (_matchSeasonFound && _matchSeasonFoundKey) {

          const results = {}

          let matchNeedsUpdating = false

          // check for a valid score

          if (isValidScore) {
            results.score = {
              home: parseInt(homeScore),
              away: parseInt(awayScore),
            }

            if (matchNeedsUpdated(_matchSeasonFound.results, results)) {
              matchNeedsUpdating = true
            }

            if (!_matchSeasonFound.results || matchNeedsUpdating) {
              // add the match to the updated object
              const _matchUpdate = { results: results }
              if (_matchSeasonFound.startDate !== startDate) {
                _matchUpdate.startDate = startDate
              }
              if (newStartDate) { _matchUpdate.startDate = newStartDate }

              if (_matchSeasonFound.sections === 'Non') {
                matches_updated.nonSection[_matchSeasonFoundKey] = _matchUpdate
              } else {
                matches_updated.section[_matchSeasonFoundKey] = _matchUpdate
              }
            }

          } else {
            if ((_matchSeasonFound.startDate !== startDate) || (_matchSeasonFound.startDate !== newStartDate)) {
              const _matchUpdate = { startDate: startDate }
              if (newStartDate) { _matchUpdate.startDate = newStartDate }
              if (_matchSeasonFound.sections === 'Non') {
                matches_updated.nonSection[_matchSeasonFoundKey] = _matchUpdate
              } else {
                matches_updated.section[_matchSeasonFoundKey] = _matchUpdate
              }
            }
          }
        } else {
          // if the match is NOT found, create it.
          if (_home && _away) {
            switch (match_item.section) {
              case 'City League':
                matches_created.city.push(match_item)
                break;
              default:
                // if (!isSectionMatch) {
                const _nsMatch = {
                  bestOf: true,
                  setCount: 5,
                  startDate: formatItem(formatTypes.fullDate, startDate),
                  startTime: '7:00 PM',
                  sections: isSectionMatch ? level_home : 'Non',
                  levels: isLevelMatch ? level_home : 'Non',
                  home: createTeam(_home),
                  away: createTeam(_away),
                  teams: [_home._itemKey, _away._itemKey]
                }
                _nsMatch.results = {}
                if (isValidScore) {
                  _nsMatch.results.score = {
                    home: parseInt(homeScore),
                    away: parseInt(awayScore),
                  }
                } else {
                  _nsMatch.results = {}
                }
                // create a new key for the match
                if (isSectionMatch) {
                  matches_created.section[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                } else {
                  matches_created.nonSection[createFsDocKey('nonSectionMatch')] = { ..._nsMatch }
                }
              // }
            }
          } else {
            matches_created.nonTeam.push(match_item)
          }
        }
      }
    })

    // add the matches that have been found (matchesCreated.updated) to the matchesToUpdate array
    const matchUpdates = {
      toCreate: {
        section: {},
        nonSection: {},
        all: {},
      },
      toUpdate: {
        section: {},
        nonSection: {},
        all: {},
      }
    }

    Object.keys(matches_updated.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_updated.section[key]
      const { results, startDate } = m ? m : {}
      if (results) { matchUpdates.toUpdate.section[_mk + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.section[_mk + '.startDate'] = startDate };
      if (results) { matchUpdates.toUpdate.all[_mk + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.all[_mk + '.startDate'] = startDate };
    })

    Object.keys(matches_updated.nonSection).forEach(key => {
      const _mk = 'matchesNonSection.' + key
      const _mk2 = 'matches.' + key
      const m = matches_updated.nonSection[key]
      const { results, startDate } = m ? m : {}
      if (results) { matchUpdates.toUpdate.nonSection[_mk + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.nonSection[_mk + '.startDate'] = startDate };
      if (results) { matchUpdates.toUpdate.all[_mk2 + '.results'] = results };
      if (startDate) { matchUpdates.toUpdate.all[_mk2 + '.startDate'] = startDate };
    })

    Object.keys(matches_created.section).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.section[key]
      const { results } = m ? m : {}
      m.results = results
      matchUpdates.toCreate.section[key] = m;
      matchUpdates.toCreate.all[_mk] = m;
    })

    Object.keys(matches_created.nonSection).forEach(key => {
      const _mk = 'matches.' + key
      const m = matches_created.nonSection[key]
      const { results } = m ? m : {}
      m.results = results
      matchUpdates.toCreate.nonSection[key] = m;
      matchUpdates.toCreate.all[_mk] = m;
    })

    return matchUpdates

  }
}

const createTeam = (team) => {
  return {
    id: team._itemKey,
    levels: team.levels,
    sections: team.sections,
    name: team.name
  }
}