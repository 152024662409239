export const pushPromise = (baseFromRef, pushFrom, pushTo, selectedDataItems) => {
  const promises = []
  Object.keys(selectedDataItems).forEach(itemKey => {
    const item = selectedDataItems[itemKey]
    const pushId = item[pushTo]
    if (pushTo) {
      let addRef = baseFromRef.collection(pushTo).doc(pushId).collection(pushFrom).doc(itemKey)
      promises.push(addRef.set(item))
    }
  })
  return Promise.all(promises)
}