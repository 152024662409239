import _ from 'lodash';
import React from 'react';
import { Segment } from 'semantic-ui-react';
import { gridRowCheckbox } from '../../components/grids/gridRowCheckbox';
import { gEnums } from '../../../enums/globalEnums';

export const SelectExistingContent = (props) => {

  const { selectExistingProps } = props
  const { selectedOptions, selectedItems, grProps, createOrigin } = selectExistingProps

  grProps.forSelect = true

  const items = []

  selectedOptions.forEach(opt => {
    let item;
    switch (createOrigin) {
      case gEnums.createOriginTypes.createDataModify:
        item = {
          name: opt.firstName ? opt.firstName + ' ' + opt.lastName : opt.name,
          key: opt.key,
          show: selectedItems[opt.key] ? true : false,
          icon: 'tag'
        }
        break;
      default:
        item = {
          name: opt.text,
          key: opt.key,
          show: selectedItems[opt.key] ? true : false,
          icon: 'tag'
        }
    }
    items.push(gridRowCheckbox(item, {}, grProps))
  })

  return <Segment basic style={{ margin: 0 }} inverted className={'grid-check-container adding se msmi three'}>
    {items.length > 0 && items}
    {items.length === 0 && <Segment basic inverted>No Items Available</Segment>}
  </Segment>
}

export const GridItemSelectContent = (props) => {

  const { gridSelectProps } = props
  const { selectedOptions, selectedItems, grProps } = gridSelectProps

  grProps.forSelect = true

  const items = []

  selectedOptions.forEach(key => {
    const item = {
      caption: _.startCase(key),
      key: key,
      show: selectedItems[key] ? true : false,
      icon: 'tag'
    }
    items.push(gridRowCheckbox(item, {}, grProps))
  })

  return <Segment basic style={{ margin: 0 }} className={'grid-check-container adding sc'}>
    {items}
  </Segment>
}

export const GridDataSelectContent = (props) => {

  const { gridSelectProps } = props
  const { selectedOptions, selectedItems, grProps } = gridSelectProps

  grProps.forSelect = true

  const items = []

  Object.keys(selectedOptions).forEach(key => {
    const option = selectedOptions[key]
    const item = {
      caption: option.firstName ? option.firstName + ' ' + option.lastName : option.name,
      key: key,
      show: selectedItems[key] ? true : false,
      icon: 'tag'
    }
    items.push(gridRowCheckbox(item, {}, grProps))
  })

  return <Segment basic style={{ margin: 0 }} className={'grid-check-container adding gd'}>
    {items}
  </Segment>
}