import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Icon, List, Menu, Segment } from 'semantic-ui-react';
import { DisplayContext } from '../../../global/cnr/contexts/DisplayContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../global/cnr/reducers/SidebarReducer';
import { uniqueKey } from '../../../global/common/keys';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import { Popuper } from '../../../global/components/popups/Popuper';
import { SimpleDnD } from '../../../global/dragNDrops/SimpleDnD';
import { appIconTypes } from '../../../global/enums/appIconTypes';
import GenericIcon from '../../../global/genericControls/GenericIcon';
import MenuSidebars from '../../../global/sidebars/MenuSidebars';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import { H3Wrapper } from '../../../global/wrappers/TopperWrappers';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { SportsMatchContext } from '../cnr/contexts/SportsMatchContext';
import { SportsRtMatchContext } from '../cnr/contexts/SportsRtMatchContext';
import { SportsSeasonContext } from '../cnr/contexts/SportsSeasonContext';
import { matchUpdateTypes } from '../cnr/reducers/SportsSeasonReducer';
import SportsAthletes from '../elements/athletes/SportsAthletes';
import MatchItemScoring from '../elements/matches/MatchItemScoring';
import MatchListUpdate from '../elements/matches/MatchListUpdate';
import MatchPendingUpdate from '../elements/matches/MatchPendingUpdate';
import { scoreTypes } from '../elements/matches/MatchRealtimeScoring';
import MatchScoreTable from '../elements/matches/MatchScoreTable';
import { calcVballMatch } from '../helpers/match';
import MatchHeader from './MatchHeader';

const sidebarType = 'createMatchResult'
const _useScorePicker = true

const stepTypes = {
  resultType: 'resultType',
  enterScores: 'enterScores',
  pickTopPlayers: 'pickTopPlayers',
  sortTopPlayers: 'sortTopPlayers',
  confirmResult: 'confirmResult',
}

const _scoreTypes = {
  matchScore: 'matchScore',
  setScores: 'setScores',
}

const CreateMatchResult = (props) => {

  const { realTimeOnly, includePlayers } = props ? props : {}

  // displayContext
  const displayContext = useContext(DisplayContext);

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_current, matchPendingUpdate, sortedAthletes, popupOpen } = sportsMatch_state ? sportsMatch_state : {}
  const { handleShowScore, handleShowMatchPending, setPopupOpen } = sportsMatch_handlers ? sportsMatch_handlers : {}
  const { matchUpdateType } = matchPendingUpdate ? matchPendingUpdate : {}

  // sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state } = sportsRtMatchContext ? sportsRtMatchContext : {}
  const { match_rt } = sportsRtMatch_state ? sportsRtMatch_state : {}

  const componentContexts = { displayContext }

  // local state  
  const steps = includePlayers ?
    [stepTypes.resultType, stepTypes.enterScores, stepTypes.pickTopPlayers, stepTypes.sortTopPlayers, stepTypes.confirmResult]
    :
    [stepTypes.resultType, stepTypes.enterScores, stepTypes.confirmResult]

  const [currentScoreType, setCurrentScoreType] = useState()
  const [step, setStep] = useState({ index: 0, name: steps[0] })

  const stepCount = steps.length

  const handlePopupOpen = () => setPopupOpen(!popupOpen)

  const { complete_possible } = match_rt ? match_rt : {}
  const { results_current, results } = match_current ? match_current : {}
  const _results = results_current ? results_current : results
  const { complete } = _results ? _results : {}

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ? sidebar_state : {}
  const { setInit } = sidebar_handlers ? sidebar_handlers : {}

  const { ms_scoreTypes } = sidebar_items ? sidebar_items : {}

  useEffect(() => {
    if (!_useScorePicker) {
      setInit({ smt: sidebarMenuTypes.one, items: [_scoreTypes.matchScore, _scoreTypes.setScores], currents, as: 'scoreTypes', useStartCase: true, sidebarSliderType: sidebarSliderTypes.labeled })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (props.match_current) {
      const cm = { ...props.match_current }
      if (cm) {
        calcVballMatch(cm)
        sportsMatch_handlers.handleAmmend_currentMatch(cm)
      }
    } else if (props.matchKey && sportsSeason_state && sportsSeason_state.matches && sportsSeason_state && sportsSeason_state.matches[props.matchKey]) {
      const ckm = sportsSeason_state.matches[props.matchKey]
      if (ckm) {
        calcVballMatch(ckm)
        sportsMatch_handlers.handleAmmend_currentMatch(ckm)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes.resultType:
        return currentScoreType ? true : false
      case stepTypes.enterScores:
        return complete || complete_possible
      case stepTypes.pickTopPlayers:
        return true
      case stepTypes.sortTopPlayers:
        return true
      case 3:
      case stepTypes.confirmResult:
      default:
        break;
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const handleUpdatedList = (l) => sportsMatch_handlers.handleSelectAthletes(l.data)
  const handleCancel = () => handleShowScore()

  const footer = () => <UiSaveButtons
    save={{ oc: sportsMatch_handlers.handleUpdate_score, caption: 'Update Match', icon: appIconTypes.check, color: 'green' }}
  ></UiSaveButtons>


  const playerItem = (player) => {
    const { lastName, firstName, teams, uniformNumber, playerPosition } = player
    return <List.Item>
      <List.Content>
        <List.Header>{firstName}{' '}{lastName}</List.Header>
        <List.Description>{'#'}{uniformNumber}{' - '}{teams}</List.Description>
        <List.Description>{playerPosition}</List.Description>
      </List.Content>
    </List.Item>
  }

  const playerList = () => {
    if (sortedAthletes) {
      const list = sortedAthletes.map(sa => (playerItem(sa)))
      return <List divided relaxed ordered>
        {list}
      </List>
    } else {
      return 'None'
    }
  }

  const confirmContent = () => <React.Fragment>
    <Segment.Group>
      <Segment color={'blue'}>{'Result'}</Segment>
      <Segment><MatchScoreTable match_current={match_current} /></Segment>
    </Segment.Group>
    <Segment.Group>
      <Segment color={'blue'}>{'Top Players'}</Segment>
      <Segment>{playerList()}</Segment>
    </Segment.Group>
  </React.Fragment>

  const match_score = () => <MatchItemScoring key={uniqueKey('crm', 'ms', match_current.key)} />
  const match_scores = () => <MatchItemScoring key={uniqueKey('crm', 'mss', match_current.key)} setScoring={true} />
  const match_players = () => <SportsAthletes componentContexts={componentContexts} />
  const match_sortPlayers = () => sortedAthletes ? <SimpleDnD dataItems={sortedAthletes} handleUpdatedList={handleUpdatedList} /> : <Segment basic>{'No players selected'}</Segment>

  const match_confirm = () => <Wrapper
    content={confirmContent()}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
  />

  const scoringContainer = () => {
    if (currentScoreType === _scoreTypes.setScores) {
      return match_scores(scoreTypes.sets)
    } else {
      return match_score()
    }
  }

  const handleStep = (co) => {
    const { count } = co
    let newStep = step.index + count
    if (newStep < 0) { newStep = 0 }
    if (newStep > (stepCount - 1)) { newStep = stepCount - 1 }
    const name = steps[newStep];
    setStep({ index: newStep, name })
  }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const buttonProps = { color: 'black', inverted: true }

  const leftButton = () => gi('btn-back', appIconTypes.arrowLeft, handleStep, { count: -1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowBack })
  const rightButton = () => gi('btn-forward', appIconTypes.arrowRight, handleStep, { count: 1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowForward })

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={scoringContainer()}
    dimmed={dimmed}
  />

  const scoreTypeButtons = () => <Segment basic style={{ padding: '3em', textAlign: 'center' }}><Button.Group>
    <Button color={currentScoreType === _scoreTypes.matchScore ? 'blue' : null} onClick={() => { setCurrentScoreType(_scoreTypes.matchScore) }} >{'Match Score'}</Button>
    <Button.Or />
    <Button color={currentScoreType === _scoreTypes.setScores ? 'blue' : null} onClick={() => { setCurrentScoreType(_scoreTypes.setScores) }} >{'Set Scores'}</Button>
  </Button.Group></Segment>


  const wizardHeader = () => <H3Wrapper
    leftContent={leftButton()}
    middleContent={_.startCase(step.name)}
    rightContent={rightButton()}
    widths={['22%', '56%', '22%']}
  />

  const scoresContent = () => {
    switch (step.name) {
      case stepTypes.resultType:
        return scoreTypeButtons()
      case stepTypes.enterScores:
        return _useScorePicker ? scoringContainer() : menuSidebars()
      case stepTypes.pickTopPlayers:
        return match_players()
      case stepTypes.sortTopPlayers:
        return match_sortPlayers()
      case stepTypes.confirmResult:
        return match_confirm()
      default:
        break;
    }
  }

  const wizard = () => <Wrapper
    header={wizardHeader()}
    content={scoresContent()}
  />

  const content = () => {
    if (matchPendingUpdate) {
      switch (matchUpdateType) {
        case matchUpdateTypes.updateMatchListResults:
        case matchUpdateTypes.updateMatchListResultsFromGs:
          return <MatchListUpdate handleCancel={handleShowMatchPending} matchUpdateType={matchUpdateType} />
        default:
          return <MatchPendingUpdate handleCancel={handleShowMatchPending} />
      }
    } else if (realTimeOnly) {
      return match_scores(scoreTypes.realtime)
    } else {
      return wizard()
    }
  }

  const menuItem = (matchUpdateType, iconName, disabled) => <Menu.Item
    disabled={disabled}
    onClick={() => { handleShowMatchPending({ matchUpdateType }) }} >
    <div><Icon color="blue" name={iconName} /></div>
    <div>{_.startCase(matchUpdateType)}</div>
  </Menu.Item>

  const menuItem_rt = (matchUpdateType, iconName, disabled) => <Menu.Item
    disabled={disabled}
    onClick={() => {
      handlePopupOpen()
    }} >
    <div><Icon color="blue" name={iconName} /></div>
    <div>{_.startCase(matchUpdateType)}</div>
  </Menu.Item>

  const menuItems_popup = () => {
    const mis = []
    mis.push(menuItem(matchUpdateTypes.addToHistory, 'history'))
    mis.push(menuItem(matchUpdateTypes.addConsolationMatch, 'add'))
    mis.push(menuItem(matchUpdateTypes.cancelMatch, 'delete calendar'))
    mis.push(menuItem(matchUpdateTypes.deleteMatch, 'delete',))
    mis.push(menuItem(matchUpdateTypes.deleteRealtimeMatch, 'delete',))
    mis.push(menuItem(matchUpdateTypes.deleteScore, 'ban',))
    mis.push(menuItem(matchUpdateTypes.notifyMatch, 'bell outline'))
    mis.push(menuItem(matchUpdateTypes.postponeMatch, 'calendar minus outline'))
    mis.push(menuItem(matchUpdateTypes.resetByeMatch, 'edit'))
    mis.push(menuItem(matchUpdateTypes.updateMatchDate, 'calendar check outline'))
    mis.push(menuItem(matchUpdateTypes.updateMatchLocation, 'location arrow',))
    mis.push(menuItem(matchUpdateTypes.updateMatchListResults, 'add',))
    mis.push(menuItem(matchUpdateTypes.updateMatchListResultsFromGs, 'add',))
    // mis.push(menuItem_rt('Show Realtime Results', 'view',))
    return mis
  }

  const popuper = () => <Popuper
    cn={'mu-pop'}
    content={content()}
    handlePopupOpen={handlePopupOpen}
    menuItems={menuItems_popup()}
    showPopupIcon={true}
    sidebarHandler={setPopupOpen}
  />

  const header = () => <MatchHeader />

  const wrapper = () => <Wrapper
    content={step.index === 0 ? popuper() : content()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={header()}
    handleCancel={handleCancel}
  />

  if (!_useScorePicker) {
    return step && match_current && ms_scoreTypes && ms_scoreTypes.items ? fullPageWrapper() : <div></div>
  } else {
    return step && match_current ? fullPageWrapper() : <div></div>
  }

}

export default CreateMatchResult