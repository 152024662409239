import React, { useEffect, useState } from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';
import { GenericField } from './GenericField';

const TimeInput = (props) => {

  const { fip } = props

  const [time, setTime] = useState({ hour: 7, minute: 0, ampm: 'PM' })

  useEffect(() => {
    let _time = fip ? fip.value : '19:00'
    let pm;
    if ((_time && _time.indexOf('PM') >= 0) || (_time && _time.indexOf('AM') >= 0)) {
      if (_time.indexOf('PM') >= 0) { pm = true }
      _time = _time.replace('AM', '')
      _time = _time.replace('PM', '')
    }
    let valSplit;
    try {
      valSplit = _time ? _time.split(':') : [1, 0]
    } catch (error) {
      valSplit = _time ? [_time, 0] : [1, 0]
    }
    let hours = parseInt(valSplit[0])
    let minutes = parseInt(valSplit[1])
    if (pm) { hours += 12 }
    if (hours > 12) { hours = hours - 12 }
    minutes = minutes - 0
    const t = { hour: hours, minute: minutes, ampm: 'PM' }
    setTime(t)
    // eslint-disable-next-line
  }, []);

  const handleTrueTime = (t) => {
    const tt = { hour: t.hour, minute: t.minute }
    if (tt.hour >= 24) { tt.hour = 0 }
    const data = { ...fip }
    let dataHour = tt.hour
    let dataMinute = tt.minute
    if (t.ampm === 'PM') { dataHour += 12 }
    if (dataHour < 10) { dataHour = '0' + tt.hour }
    if (dataHour === 12 && t.ampm === 'AM') { dataHour = '00' }
    if (dataMinute < 10) { dataMinute = '0' + tt.minute }
    data.value = dataHour + ':' + dataMinute
    setTime(tt)
    fip.onChange(null, data)
  }

  const handleTimeChange = (e, data) => {
    const t = { ...time }
    t[data.proptype] = data.value
    handleTrueTime(t)
  }

  const opts = (type) => {
    const l = []
    switch (type) {
      case 'hour':
        for (var h = 0; h <= 12; h++) {
          const hh = h < 10 ? '0' + h : h
          l.push({ key: h, value: h, text: hh })
        }
        break;
      case 'minute':
        for (var m = 0; m <= 60; m++) {
          const mm = m < 10 ? '0' + m : m
          l.push({ key: m, value: m, text: mm })
        }
        break;
      case 'ampm':
        l.push({ key: 'AM', value: 'AM', text: 'AM' })
        l.push({ key: 'PM', value: 'PM', text: 'PM' })
        break;
      default:
      // nothing
    }
    return l
  }

  const sel = (type) => <Dropdown
    label={type}
    search
    scrolling
    selection
    options={opts(type)}
    proptype={type}
    value={time[type]}
    onChange={handleTimeChange}
  />

  const xx = () => {
    return <div className={'time-container'}>
      <Grid columns='equal'  >
        <Grid.Column>{sel('hour')}</Grid.Column>
        <Grid.Column>{sel('minute')}</Grid.Column>
        <Grid.Column>{sel('ampm')}</Grid.Column>
      </Grid>
    </div>
  }

  const np = () => {
    return <div className={'npicker-container'}>
      {xx()}
    </div>
  }

  const labeledItem = () => <GenericField
    fip={fip}
    content={np()}
  />

  if (time) { return labeledItem() }

  return <div></div>

}

export default TimeInput