// import { getMessaging, isSupported, usePublicVapidKey } from 'firebase/messaging';
import { fbAppConfigs, googleConfigs } from './appConfigs';
import { appKeys } from './appKeys';

export const appNames = {
  meetingevolution: 'meetingevolution',
  thumbstat: 'thumbstat',
  cardnudge: 'cardnudge'
}

// THIS IS THE CURRENT APP IN LOCALHOST!!!
export const currentLocalHostAppName = appNames.cardnudge
export const useProdDb = true

const forceProd = false


// 520 10th St.
// 15139

export const appNamesP = {
  meetingevolution: 'meetingevolution',
  thumbstat: 'thumbstat',
  cardnudge: 'cardnudge'
}

export const envTypes = {
  development: 'development',
  production: 'production'
}

// upMC@2040_!go


// https://console.firebase.google.com/u/1/project/me-mobile-4410b/settings/cloudmessaging/web:OTM0NzY2ZGQtNTk3YS00NTBjLWJhNDItYjkwNzhhOWQ2MmI2

// export const fbConfig_env = () => {
//   return {
//     meetingevolution: {
//       dev: process.env.REACT_APP_FS_ME_DEV ? JSON.parse(process.env.REACT_APP_FS_ME_DEV) : null,
//       prod: process.env.REACT_APP_FS_ME_PROD ? JSON.parse(process.env.REACT_APP_FS_ME_PROD) : null
//     }
//   }
// }

/**
 * 
 * @returns true if process.env.NODE_ENV = envTypes.production
 */
export const isMeProd = () => {
  // return true
  switch (process.env.NODE_ENV) {
    case envTypes.production:
      if (document.location.host.indexOf('mobiledev') >= 0) {
        return false
      } else if (document.location.host.indexOf('mobile') >= 0) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}

export const isLocalhost = () => {
  if (document.location.host.indexOf('localhost') >= 0) {
    return true
  }
}

export const allAppKeys = () => {
  switch (process.env.NODE_ENV) {
    case envTypes.production:
      if (document.location.host.indexOf('mobiledev') >= 0) {
        return appKeys.meetingevolution.dev
      } else if (document.location.host.indexOf('mobile') >= 0) {
        return useProdDb ? appKeys.meetingevolution.prod : appKeys.meetingevolution.dev
      } else {
        return appKeys.meetingevolution.dev
      }
    default:
      return appKeys.meetingevolution.dev
  }
}

/**
 * 
 * @returns the firebase configuration based on the current project
 */
export const getFbConfig = () => {
  const appName = getAppName()
  const env = getEnvironment()
  const _appConfigs = fbAppConfigs[appName] ? fbAppConfigs[appName] : {}
  const _ac = _appConfigs[env]
  return _ac

}

export const getGoogleMapsConfig = () => {
  const appName = getAppName()
  const env = getEnvironment()
  const _googleConfigs = googleConfigs[appName] ? googleConfigs[appName] : {}
  const _gc = _googleConfigs[env]
  return _gc

}

const getAppName = () => {

  const { location } = document
  const { host } = location ? location : ''
  const isLocal = isLocalhost()

  let appName;

  if (isLocal) {
    appName = currentLocalHostAppName ? currentLocalHostAppName : {}
  } else {
    Object.keys(appNames).forEach(key => {
      if (host.indexOf(key) >= 0) {
        appName = key
      }
    })
  }

  return appName

}

const getEnvironment = () => {
  switch (process.env.NODE_ENV) {
    case envTypes.production:
      if (document.location.host.indexOf('mobiledev') >= 0) {
        return 'dev'
      } else if (document.location.host.indexOf('mobile') >= 0) {
        return useProdDb ? 'prod' : 'dev'
      } else {
        return 'dev'
      }

    default:
      if (forceProd) {
        return 'prod'
      } else {
        return 'dev'
      }
  }
}