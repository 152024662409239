import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { uniqueKey } from '../../../common/keys'
import { appIconTypes } from '../../../enums/appIconTypes'
import { iconColorTypes } from '../../../enums/settingsIconTypes'

export const confirmationTypes = {
  delete: 'delete'
}

const buttonStates = {
  active: 'active',
  confirm: 'confirm',
  pending: 'pending',
  question: 'question',
}

const WAIT_INTERVAL = 2000

/** Returns a confirmation button */
const ConfirmationButton = (props) => {

  const { buttonClicked, caption, confirmation, confirmationType, dataCollectionKey, disabled, opts } = props

  const _caption = caption ? caption : _.startCase(buttonClicked)

  const [buttonState, setButtonState] = useState()
  const [pendingCaption, setPendingCaption] = useState()

  const cn = 'cbc-container confirmation-button confirm'

  useEffect(() => {
    setButtonState(buttonStates.question)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (confirmationType) {
      switch (confirmationType) {
        case confirmationTypes:
          setPendingCaption('Deleting')
          break;
        default:
          setPendingCaption('Working')
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [confirmationType]);

  useEffect(() => {
    if (confirmation) {
      setButtonState(buttonStates.confirm)
      setTimeout(() => setButtonState(buttonStates.question), WAIT_INTERVAL);
      // return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [confirmation]);

  const handleButtonClick = (bs) => {
    switch (bs) {
      case buttonStates.active:
        setButtonState(bs)
        return props.handleConfirm(buttonClicked, dataCollectionKey, opts)
      default:
        break;
    }
    setButtonState(bs)
  }

  const buttons_confirm = () => <Button.Group icon key={uniqueKey('bc')} size='small' className={cn + ' bgcc'}>
    <Button key={uniqueKey('bc', 1)} color={iconColorTypes.default} ><Icon name={appIconTypes.check} /></Button>
    <Button key={uniqueKey('bc', 2)} color={iconColorTypes.default}>{confirmation ? confirmation.title : 'Confirmed'} </Button>
    <Button key={uniqueKey('bc', 3)} color={iconColorTypes.fetching} ><Icon name={appIconTypes.check} /></Button>
  </Button.Group>

  const buttons_active = () => <Button.Group icon key={uniqueKey('ba')} size='small' className={cn + ' bgcc'}>
    <Button key={uniqueKey('ba', 1)} color={iconColorTypes.default} ><Icon name={appIconTypes.settings} /></Button>
    <Button key={uniqueKey('ba', 2)} color={iconColorTypes.default} >{pendingCaption} </Button>
    <Button key={uniqueKey('ba', 3)} color={iconColorTypes.fetching} ><Icon loading name={appIconTypes.cog} /></Button>
  </Button.Group>

  /**Retuns a button group with the confirmation question
   * The first 2 buttons will cancel the question
   * The 3rd button will confirm
   */
  const buttons_pending = () => <Button.Group icon key={uniqueKey('bp')} size='small' className={cn + ' bgcc'}>
    <Button key={uniqueKey('bp', 1)} color={iconColorTypes.default} onClick={() => handleButtonClick(buttonStates.question)}><Icon name='delete' /></Button>
    <Button key={uniqueKey('bp', 2)} color={iconColorTypes.default} onClick={() => handleButtonClick(buttonStates.question)}>{_caption}{'?'}</Button>
    <Button key={uniqueKey('bp', 3)} color={iconColorTypes.save} onClick={() => handleButtonClick(buttonStates.active)}><Icon name={appIconTypes.check} /></Button>
  </Button.Group>

  /**Retuns a button group with the primary question */
  const buttons_question = (disabled) => {
    return <Button.Group icon key={uniqueKey('bq')} size='small' className={cn + ' bgc'} onClick={() => handleButtonClick(buttonStates.pending)}>
      <Button key={uniqueKey('bq', 1)} disabled={disabled} color={iconColorTypes.active}><Icon name={appIconTypes.settings} /></Button>
      <Button key={uniqueKey('bq', 2)} disabled={disabled} color={iconColorTypes.active}>{_caption} </Button>
      <Button key={uniqueKey('bq', 3)} disabled={disabled} color={iconColorTypes.active}><Icon name={appIconTypes.arrowRight} /></Button>
    </Button.Group>
  }

  if (disabled) {
    return buttons_question(true)
  } else {
    switch (buttonState) {
      case buttonStates.question:
        return buttons_question()
      case buttonStates.pending:
        return buttons_pending()
      case buttonStates.active:
        return buttons_active()
      case buttonStates.confirm:
        return buttons_confirm()
      default:
        return <div>---</div>
    }
  }
}

export default ConfirmationButton