import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';

const MatchSetFilter = (props) => {

  const { setSetFilter } = props

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ? sportsMatchContext : {}
  const { match_current } = sportsMatch_state ? sportsMatch_state : {}

  const { results, results_current } = match_current ? match_current : {}
  const _results = results_current ? results_current : results
  const { setNumber, setsCompleted } = _results ? _results : {}

  const [selectedSet, setSelectedSet] = useState(setNumber)

  const handleSelectedSet = (sn) => setSelectedSet(selectedSet === sn ? null : sn)

  useEffect(() => {
    sportsMatch_handlers.handleChange_set(selectedSet)
    setSetFilter({ selectedSet })
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedSet]);

  const button = (sn, basic, text, oc) => {
    if ((sn <= setsCompleted + 1) || setNumber === sn) {
      const color = sn <= setsCompleted ? 'green' : 'blue'
      return <Button compact basic={basic} color={color} onClick={(e) => { oc(sn) }}>{sn <= setsCompleted && <Icon name='check' />}{text}</Button>
    } else {
      return <Button compact basic={basic} color={'grey'}>{text}</Button>
    }
  }

  const buttons = (min, max) => {
    const btns = []
    for (var i = min; i <= max; ++i) {
      btns.push(button(i, (selectedSet === i ? false : true), 'Set ' + i, handleSelectedSet))
    }
    return btns
  }
  const buttons_set = () => {
    return <div><Button.Group size={'mini'}>
      {buttons(1, 5)}
    </Button.Group></div>
  }

  const buttonHeader = () => {
    return <div className={'set-buttons'}>
      {buttons_set()}
    </div>
  }

  return buttonHeader()
}

export default MatchSetFilter