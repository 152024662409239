export const rts = {
  handleAppSidebar: 'handleAppSidebar',
  handleModal: 'handleModal',
  handlePropMode: 'handlePropMode',
  handleSettingSoloSidebar: 'handleSettingSoloSidebar',
  handleShowAppQrCode: 'handleShowAppQrCode',
  handleShowSignIn: 'handleShowSignIn',
  handleUiItem: 'handleUiItem',
  handleShowDashboard: 'handleShowDashboard',
}

export const framworkReducer = (state, action) => {

  switch (action.type) {

    case rts.handleUiItem:
      const { results } = action
      const { uivi, viewKey, _uiItem } = results ? results : {}
      const _uiItems = { ...state.uiItems }
      const _pageItems = { ...state.pageItems }
      if (viewKey) {
        if (!_uiItems[uivi]) { _uiItems[uivi] = {} }
        _uiItems[uivi][viewKey] = _uiItem
      } else {
        _pageItems[uivi] = _uiItem
      }
      return { ...state, uiItems: _uiItems, pageItems: _pageItems }

    case rts.handleAppSidebar:
      return {
        ...state,
        appSidebarOpen: !state.appSidebarOpen,
        settingsSidebarSoloOpen: false
      }

    case rts.handleShowSignIn:
      return {
        ...state,
        appSidebarOpen: false,
        settingsSidebarSoloOpen: false,
        showSignIn: !state.showSignIn
      }

    case rts.handleSettingSoloSidebar:
      return {
        ...state,
        settingsSidebarSoloOpen: !state.settingsSidebarSoloOpen,
        soloPermissionType: action.soloPermissionType
      }

    case rts.handleModal:
      return {
        ...state,
        modalContent: action.modalContent,
      }

    case rts.handlePropMode:
      return {
        ...state,
        propMode: !state.propMode,
      }

    case rts.handleShowAppQrCode:
      if (state.showAppQrCode) {
        return {
          ...state,
          appSidebarOpen: false,
          showAppQrCode: false,
        }
      } else {
        return {
          ...state,
          appSidebarOpen: false,
          showAppQrCode: true,
          useShortLink: action.useShortLink
        }
      }

    case rts.handleShowDashboard:
      return { ...state, dashboardType: action.dashboardType }

    default:
      return { ...state }
  }
}

export const framworkHandlers = (dispatch, state) => {
  return {
    handleAppSidebar: () => { dispatch({ type: rts.handleAppSidebar }) },
    handleModal: (modalContent) => { dispatch({ type: rts.handleModal, modalContent }) },
    handlePropMode: () => { dispatch({ type: rts.handlePropMode }) },
    handleSettingSoloSidebar: (soloPermissionType) => { dispatch({ type: rts.handleSettingSoloSidebar, soloPermissionType }) },
    handleShowAppQrCode: (useShortLink) => { dispatch({ type: rts.handleShowAppQrCode, useShortLink }) },
    handleShowSignIn: () => { dispatch({ type: rts.handleShowSignIn }) },
    handleUiItem: (results) => { dispatch({ type: rts.handleUiItem, results }) },
    handleShowDashboard: (dashboardType) => { dispatch({ type: rts.handleShowDashboard, dashboardType }) },
  }
}

export const framworkReducerInitialState = (initState) => {
  const { addSettings, mediaDeviceType, isMobileDevice, view, pathViews } = initState
  return {
    addSettings,
    appSidebarOpen: false,
    mediaDeviceType,
    isMobileDevice,
    settingsSidebarSoloOpen: false,
    appDimensions: {
      width: 360,
      height: 640
    },
    view,
    pathViews,
    standalone: navigator.standalone,
    pageItems: {},
    uiItems: {},
  }
};
