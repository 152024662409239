import React, { createContext, useContext, useEffect, useState } from 'react';
import PageDevice from '../../page/PageDevice';
import { getRoutes } from '../../routes/getRoutes';
import { PageContext } from './PageContext';

export const RoutesContext = createContext();

const RoutesProvider = (props) => {

  // papsContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ? pageContext : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_views } = pageSettings ? pageSettings : {}

  // local state
  const [viewRoutes, setViewRoutes] = useState()
  const [genericRoutes, setGenericRoutes] = useState()

  const routes_state = { viewRoutes, genericRoutes }

  // IMPORTANT: Page Settings- get the settings for the page
  useEffect(() => {
    const { viewRoutes, genericRoutes } = getRoutes(<PageDevice />, Object.keys(aps_views).sort())
    setViewRoutes(viewRoutes)
    setGenericRoutes(genericRoutes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routesProvider = () => {
    return <RoutesContext.Provider value={{ routes_state }}>
      {props.children}
    </RoutesContext.Provider>
  }

  return genericRoutes && viewRoutes ? routesProvider() : <div></div>
}

export default RoutesProvider