import React, { useContext, useState } from 'react';
import { Icon, Segment, TextArea } from 'semantic-ui-react';
import { FormContext } from '../../../cnr/contexts/FormContext';
import { uniqueKey } from '../../../common/keys';
import { renderHtml } from '../../html/renderHtml';

export const TextAreaToggle = (props) => {

  const { fip } = props
  const { key } = fip
  const className = (fip.required) ? 'field required' : 'field'
  const c = fip.label

  const formContext = useContext(FormContext)
  const { form_handlers, useDarkMode } = formContext ? formContext : {}
  const { handleEditor } = form_handlers ? form_handlers : {}

  const [showHtml, handleHtml] = useState(true)

  const html = (value) => {

    let content;

    try {
      content = renderHtml(value)
    } catch (error) {
      content = value
    }

    return <Segment inverted={useDarkMode} basic className={'is-html'}>
      {content}
    </Segment>
  }

  return <div key={uniqueKey('tat', key)} className={className} >
    <div className='field'><label style={{ float: 'left' }}>{c}</label></div>
    <Icon key={uniqueKey('tat', key, 1)} name='html5' style={{ marginBottom: 2 }} onClick={(e) => handleHtml(!showHtml)} />
    <Icon key={uniqueKey('tat', key, 2)} name='edit outline' style={{ float: 'right', marginBottom: 2 }} onClick={(e) => handleEditor(fip)} />
    {showHtml && html(fip.value)}
    {!showHtml && <TextArea {...fip} rows={5} />}
  </div>
}