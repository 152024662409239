import React, { useState } from 'react'
import { Icon, Label } from 'semantic-ui-react'
import { UIDictaphoneIcon } from '../../../../global/pageItem/modification/dataModifications/UIDictaphone'

export const Keypad = (props) => {

  const { setScoresOn, maxValues, maxDigits } = props

  const maxLength = (maxValues * maxDigits) + 1

  const [pickedPad, setPickedPad] = useState()
  const [selectedPad, setSelectedPad] = useState('away')
  const [padValue, setPadValue] = useState('')
  const [padValue1, setPadValue1] = useState('')
  const [padValue2, setPadValue2] = useState('')

  const handleResult = (value) => props.handleUpdate(value)
  const handleUpdate = () => props.handleUpdate(padValue)

  const handleButtonClick = (value) => {

    if (pickedPad) {
      switch (pickedPad) {
        case 'away':
          let av = padValue1
          av += value
          if (av.length > maxDigits) { return false }
          setPadValue1(av)
          break;
        case 'home':
          let hv = padValue2
          hv += value
          if (hv.length > maxDigits) { return false }
          setPadValue2(hv)
          break;
        default:
          break;
      }
    } else {
      let pv = padValue
      pv += value
      if (pv.length > maxLength) { return false }
      if (pv.length === 2) { pv += '-' }
      const pvs = pv.split('-')

      setPadValue1(pvs[0])
      if (pvs.length === 2) { setPadValue2(pvs[1]) }

      setPadValue(pv)
      switch (pv.length) {
        case 0:
        case 1:
          setSelectedPad('away')
          break;
        default:
          setSelectedPad('home')
          break;
      }
    }
  }

  const handlePick = (value) => {
    setPickedPad(value)
    setSelectedPad(value)
  }

  const handleClose = (value) => setScoresOn()
  const handleClear = (value) => {

    if (pickedPad) {
      switch (pickedPad) {
        case 'away':
          setPadValue1('')
          break;
        case 'home':
          setPadValue2('')
          break;
        default:
          break;
      }
    } else {
      setPadValue('')
      setPadValue1('')
      setPadValue2('')
    }
  }

  const pad = (value, oc) => <div color={'blue'} onClick={() => { oc(value) }}>{value}</div>

  const scoreRow = (divs, start, end) => {
    for (var i = start; i <= end; i++) {
      divs.push(pad(i, handleButtonClick))
    }
  }

  const scoreGrid = () => {
    const divs = []
    scoreRow(divs, 1, 3)
    scoreRow(divs, 4, 6)
    scoreRow(divs, 7, 9)
    divs.push(pad('X', handleClear))
    divs.push(pad(0, handleButtonClick))
    divs.push(pad('<', handleClear))

    return <div className={'scorepad-container'}>
      {divs}
    </div>
  }

  const header = () => <div className={'scorepad-header'}>
    <div><Icon name='delete' bordered color={'blue'} onClick={() => { handleClose() }} /></div>
    <div><Label color={selectedPad === 'away' ? 'green' : 'grey'} size={'big'} className={pickedPad === 'away' ? 'selected' : null} onClick={() => { handlePick('away') }}>{padValue1}</Label></div>
    <div><Label color={selectedPad === 'home' ? 'green' : 'grey'} size={'big'} className={pickedPad === 'home' ? 'selected' : null} onClick={() => { handlePick('home') }}>{padValue2}</Label></div>
    <div><Icon name='check' bordered color={'blue'} onClick={() => { handleUpdate() }} /></div>
  </div>

  const content = () => <React.Fragment>
    {header()}
    {scoreGrid()}
    <UIDictaphoneIcon handleResult={handleResult} oneTimeOnly={true} />
  </React.Fragment>

  return content()

}
