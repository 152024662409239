
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { List, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { updateNewViewToViewsSettingsDB } from '../../firestoreData/settings/updateSettings';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import UserEvents from '../user/UserEvents';

export const pageErrorReasonTypes = {
  noLayoutType: 'noLayoutType',
  noPage: 'noPage',
  noSettings: 'noSettings',
}

const PageError = (props) => {

  const { reason } = props

  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ? parentContext : {}
  const { appUser_state, start_state, paps_state } = states
  const { navigate } = handlers
  const { page_fns } = fns

  // startContext
  const { dimmerOn } = start_state ? start_state : {}

  // authContext 
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { loggedIn } = appUserAccess ? appUserAccess : {}

  // papsContext 
  const { pathViews, view } = paps_state ? paps_state : {}

  // pageContext 
  const { pushSimple } = page_fns ? page_fns : {}

  const [updating, setUpdating] = useState()
  const [newViews, setNewViews] = useState()

  const handleUpdateSettings = () => updateNewViewToViewsSettingsDB(view, newViews, pathViews)
  const handleDismissSettings = () => setNewViews()

  const goHome = () => {
    if (pathViews.events) {
      pushSimple({ key: 'landing' })
    } else if (pathViews.clients) {
      pushSimple({ key: 'client' })
    } else {
      pushSimple({ key: 'home' })
    }
  }

  const goBack = () => navigate(-1)

  useEffect(() => {
    switch (reason) {
      case pageErrorReasonTypes.noLayoutType:
        if (view) {
          // setUpdating(true)
          // const _appSettings_views = { ...aps_views }
          // cv(aps_viewItems, _appSettings_views, view).then(res => {
          //   updateNewViewToViewsSettingsDB(view, _appSettings_views, pathViews)
          // })
        }
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dimmerOn]);

  const list_layout = () => <List.Item>{'The page does not have a layout type (settings).'}.</List.Item>
  const list_noPage = () => <List.Item>{'The page does not exist.'}.</List.Item>
  const list_noSettings = () => <List.Item>{'The page does not have any settings.'}.</List.Item>
  const list_unKnown = () => <List.Item>{'Unknown error'}.</List.Item>

  const content = () => {
    let listItems;
    switch (reason) {
      case pageErrorReasonTypes.noLayoutType:
        listItems = list_layout()
        break;
      case pageErrorReasonTypes.noPage:
        listItems = list_noPage()
        break;
      case pageErrorReasonTypes.noSettings:
        listItems = list_noSettings()
        break;
      default:
        listItems = list_unKnown()
    }
    return <Segment basic>
      <List bulleted>
        {listItems}
        <List.Item>{'You may lost have internet connection'}.</List.Item>
      </List>
      {loggedIn && <UserEvents />}
    </Segment>
  }

  const footer = () => <UiSaveButtons delete={{ oc: goBack, caption: 'Go Back', color: 'blue', icon: 'arrow left' }} save={{ caption: 'Go Home', oc: goHome, icon: 'home' }} />
  const footerNewView = () => <UiSaveButtons delete={{ oc: handleDismissSettings, caption: 'Dismiss', color: 'blue' }} save={{ caption: 'Update', oc: handleUpdateSettings }} />

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
  />

  const newViewsWrapper = () => <Wrapper
    content={'New Page detected. Would you like to update the settings?'}
    footer={footerNewView()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={newViews ? newViewsWrapper() : wrapper()}
    topperCaption={_.startCase('thereIsAnIssueWithThisPage')}
    topperCaption2={_.startCase(reason)}
  />

  return wrapper()
}

export default PageError 