import _ from 'lodash';
import { createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { fs_delete_doc, fs_set_doc } from '../../firestoreData/appData/fsData';
import { getAllClientProfiles } from '../../firestoreData/helpers/appProfileData';
import { _profileCollectionName } from '../../firestoreData/profiles/getProfile';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

// export const _appUserCollections = {
//   profiles: 'profiles',
//   clientProfiles: 'profiles',
//   appUserProfiles: 'appUserProfiles',
// }

export const appUserManagementInitialState = (initState) => {
  return { ...initState }
};

export const rts = {
  handleAppManagementType: 'handleAppManagementType',
  handleClients: 'handleClients',
  handleCloseAppUser: 'handleCloseAppUser',
  handleFilteredItems: 'handleFilteredItems',
  handleGetAllClientProfiles: 'handleGetAllClientProfiles',
  handleProfileSearchChange: 'handleProfileSearchChange',
  handleSearchChange: 'handleSearchChange',
  handleSelectAppUser: 'handleSelectAppUser',
  handleSetAllClientProfiles: 'handleSetAllClientProfiles',
  handleSetAppUserTypes: 'handleSetAppUserTypes',
  handleSetProfileItems: 'handleSetProfileItems',
  handleSetProfileType: 'handleSetProfileType',
  handleUpdateClientProfiles: 'handleUpdateClientProfiles',
  handlSendGcs: 'handlSendGcs',
  ...grts
}

export const showAppManagementTypes = {
  add: 'add',
  auth: 'auth',
  getClientProfiles: 'getClientProfiles',
  updateAppUserProfiles: 'updateAppUserProfiles',
  updateClientProfiles: 'updateClientProfiles',
  gcNotifications: 'gcNotifications',
  email: 'email',
  topics: 'topics',
}

export const userMenuItems = {
  add: 'add',
  checkAuthorizations: 'checkAuthorizations',
  createTopics: 'createTopics',
  getClientProfiles: 'getClientProfiles',
  search: 'search',
  sendGCNotifications: 'sendGCNotifications',
  sendSignInEmails: 'sendSignInEmails',
  updateAppUserProfiles: 'updateAppUserProfiles',
  updateClientProfiles: 'updateClientProfiles',
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * @rts (...rts)
 */
export const appUserManagementReducer = (state, action) => {

  const { selectedItems, appProfileData, pathViews, appUserCollection, allClientProfiles } = state
  const { dispatch } = action

  const { handleSetAllClientProfiles } = appUserManagementHandlers(dispatch)

  switch (action.type) {

    case rts.handleAppManagementType:
      const { item } = action
      let _appMangementType;
      switch (item) {
        case userMenuItems.add:
          _appMangementType = showAppManagementTypes.add
          break;
        case userMenuItems.createTopics:
          _appMangementType = showAppManagementTypes.topics
          break;
        case userMenuItems.checkAuthorizations:
          _appMangementType = showAppManagementTypes.auth
          break;
        case userMenuItems.sendSignInEmails:
          _appMangementType = showAppManagementTypes.email
          break;
        case userMenuItems.getClientProfiles:
          _appMangementType = showAppManagementTypes.getClientProfiles
          break;
        case userMenuItems.updateAppUserProfiles:
          _appMangementType = showAppManagementTypes.updateAppUserProfiles
          break;
        case userMenuItems.updateClientProfiles:
          _appMangementType = showAppManagementTypes.updateClientProfiles
          break;
        case userMenuItems.sendGCNotifications:
          _appMangementType = showAppManagementTypes.gcNotifications
          break;
        default:
          break;
      }
      return { ...state, appManagementType: _appMangementType }

    case rts.handleGetAllClientProfiles:
      getAllClientProfiles(pathViews, pathViews.events, appUserCollection, handleSetAllClientProfiles)
      return { ...state }

    case rts.handleSetAllClientProfiles:
      const { profiles_combined } = action.results ? action.results : {}
      const _clientProfiles = profiles_combined ? profiles_combined : null
      return { ...state, updating: false, allClientProfiles: action.results, selectedItems: _clientProfiles, showFooter: true }

    case rts.handleSearchChange:
      if (action.searchValue) {
        const f = _.filter(selectedItems, function (i) {
          return (i.lastName && i.lastName.toLowerCase().startsWith(action.searchValue)) ||
            (i.firstName && i.firstName.toLowerCase().startsWith(action.searchValue));
        });
        return {
          ...state,
          filteredItems: f,
        }
      } else {
        return {
          ...state,
          filteredItems: null,
          selectedItems: selectedItems
        }
      }

    case rts.handleProfileSearchChange:
      if (action.searchValue) {
        const f = _.filter(appProfileData, function (i) {
          return (i.lastName && i.lastName.toLowerCase().startsWith(action.searchValue)) ||
            (i.firstName && i.firstName.toLowerCase().startsWith(action.searchValue));
        });
        return {
          ...state,
          filteredItems: f,
        }
      } else {
        return {
          ...state,
          filteredItems: null,
        }
      }

    case rts.handleSelectAppUser:
      if (action.appUserKey) {
        const appUser = selectedItems && selectedItems[action.appUserKey] ? selectedItems[action.appUserKey] : action.appUser
        return {
          ...state,
          selectedAppUserKey: action.appUserKey,
          selectedAppUser: appUser
        }
      } else {
        return {
          ...state,
          selectedAppUserKey: null,
          selectedAppUser: null,
        }
      }

    case rts.handleCloseAppUser:
      return { ...state, selectedAppUserKey: null, selectedAppUser: null, }

    case rts.handleFilteredItems:
      return { ...state, filteredItems: action.filteredItems }

    case rts.handleSetProfileType:
      const _selectedItems = state.vld ? state.vld : appProfileData[action.profileType]
      return { ...state, profileType: action.profileType, selectedItems: _selectedItems }

    case rts.handleSetAppUserTypes:
      return { ...state, appUserTypes: action.appUserTypes }

    case rts.handleUpdateClientProfiles:
      if (allClientProfiles) {
        const { merge, remove } = action
        const { profiles_combined, profiles_delete } = allClientProfiles
        if (remove) {
          removeClientProfiles(profiles_delete, pathViews, merge).then(res => {
            dispatch && dispatch({ type: rts.updateSuccess, dispatch })
          }).catch(error => {
            dispatch && dispatch({ type: rts.updateError, dispatch })
          })
        } else {
          updateClientProfiles(profiles_combined, pathViews, merge).then(res => {
            dispatch && dispatch({ type: rts.updateSuccess, dispatch })
          }).catch(error => {
            dispatch && dispatch({ type: rts.updateError, dispatch })
          })
        }

      }
      return { ...state }

    case rts.handlSendGcs:
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const appUserManagementHandlers = (dispatch) => {
  return {
    handleAppManagementType: (item) => dispatch({ type: rts.handleAppManagementType, dispatch, item }),
    handleClients: (clients) => dispatch({ type: rts.handleClients, dispatch, clients }),
    handleCloseAppUser: () => dispatch({ type: rts.handleCloseAppUser, dispatch }),
    handleFilteredItems: (filteredItems) => dispatch({ type: rts.handleFilteredItems, dispatch, filteredItems }),
    handleGetAllClientProfiles: (eventKey) => dispatch({ type: rts.handleGetAllClientProfiles, dispatch, eventKey }),
    handleProfileSearchChange: (searchValue) => dispatch({ type: rts.handleProfileSearchChange, dispatch, searchValue }),
    handleSearchChange: (searchValue) => dispatch({ type: rts.handleSearchChange, dispatch, searchValue }),
    handleSelectAppUser: (appUser, appUserKey) => dispatch({ type: rts.handleSelectAppUser, dispatch, appUser, appUserKey }),
    handleSetAllClientProfiles: (results) => dispatch({ type: rts.handleSetAllClientProfiles, dispatch, results }),
    handleSetAppUserTypes: (appUserTypes) => dispatch({ type: rts.handleSetAppUserTypes, dispatch, appUserTypes }),
    handleSetProfileType: (profileType) => dispatch({ type: rts.handleSetProfileType, dispatch, profileType }),
    handleUpdateClientProfiles: (merge, remove) => dispatch({ type: rts.handleUpdateClientProfiles, dispatch, merge, remove }),
    ...responseHandlers(dispatch)
  }
}

/**
 * Updates (merge) all of the clientProfiles
 * @param {object} clientProfiles 
 * @param {object} pathViews 
 * @returns a promise of all the fs_set_doc
 */
const updateClientProfiles = async (clientProfiles, pathViews, merge) => {

  const useTemp = false
  const collectionName = useTemp ? '_' + _profileCollectionName : _profileCollectionName

  const promises = []
  Object.keys(clientProfiles).forEach((key, index) => {
    const cp = clientProfiles[key]
    const { email } = cp ? cp : {}
    delete cp._itemKey
    delete cp.intials
    const _refPath = createRefPath_client(pathViews, [collectionName, email])
    promises.push(fs_set_doc(_refPath, cp, merge, null, true))
  })
  return Promise.all(promises)
}

/**
 * Deletes a document for each of the clientProfiles
 * @param {object} clientProfiles 
 * @param {object} pathViews  
 * @returns 
 */
const removeClientProfiles = async (clientProfiles, pathViews) => {

  const useTemp = false
  const collectionName = useTemp ? '_' + _profileCollectionName : _profileCollectionName

  const promises = []
  Object.keys(clientProfiles).forEach((key, index) => {
    const cp = clientProfiles[key]
    delete cp._itemKey
    delete cp.intials
    const _refPath = createRefPath_client(pathViews, [collectionName, key])
    console.log('_repPath', _refPath)
    promises.push(fs_delete_doc(_refPath, null, true))
  })
  return Promise.all(promises)
}
