import React, { useContext } from 'react';
import { Header, Icon, Label, Menu } from 'semantic-ui-react';
import { AppSettingsContext } from '../../../cnr/contexts/AppSettingsContext';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';
import { EventSettingsContext } from '../../../cnr/contexts/SettingsDataContext';
import { _settingsFs, rootSettings_collection_sandbox } from '../../actions/getSettings';
import { iconColorTypes } from '../../../enums/settingsIconTypes';

/**
 * 
 * @param {object} props (sideMenu, iconLeft, iconLeftColor, title, rightMenuItemProps)
 * @returns The `Menu` for the header of the `SettingsArea`
 */
const SettingsAreaMenuHeader = (props) => {

  const { sideMenu, iconLeft, iconLeftColor, title, subTitle, rightMenuItemProps } = props

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state } = appSettingsContext ? appSettingsContext : {}
  const { sandboxOn } = appSettings_state ? appSettings_state : {}

  // eventSettingsContext
  const eventSettingsContext = useContext(EventSettingsContext);
  const { eventSettings_state } = eventSettingsContext ? eventSettingsContext : {}
  const { eventSettings } = eventSettings_state ? eventSettings_state : {}
  const { status } = eventSettings ? eventSettings : {}
  const { displayName, lastUpdate } = status ? status : {}

  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ? frameworkContext : {}
  const { handleAppSidebar } = framework_handlers ? framework_handlers : {}

  const { icon: iconRight, handleUpdateAll, handleAllowGroupMenuSort, show: showRight, allowGroupMenuSort } = rightMenuItemProps ? rightMenuItemProps : {}
  const { fullMobileWidth } = sideMenu ? sideMenu : {}

  const rsc = sandboxOn ? rootSettings_collection_sandbox : _settingsFs.root
  const lblColor = sandboxOn ? iconColorTypes.settingsUser : iconColorTypes.settingsLive

  const subHeader = () => <Header.Subheader>
    <Label size={'mini'} color={lblColor} style={{ margin: 0, marginTop: 6 }}>
      {rsc + ': ' + subTitle}
    </Label>
    <Label size={'mini'} color={'blue'} style={{ margin: 0, marginTop: 6 }}>
      {displayName}{': '}{lastUpdate}
    </Label>
  </Header.Subheader>

  const menuItem_right = () => <Menu.Menu position='right'>
    {fullMobileWidth && <Menu.Item onClick={handleAppSidebar}>
      <Icon name={'delete'} />
    </Menu.Item>}
    {showRight && <Menu.Item onClick={handleUpdateAll}>
      <Icon name={iconRight} />
    </Menu.Item>}
  </Menu.Menu>

  const menuItem_edit = () => <Menu.Menu className={'samhe'} position='right'>
    <Menu.Item onClick={handleAllowGroupMenuSort}>
      <Icon name={'sort'} color={allowGroupMenuSort ? 'blue' : null} />
    </Menu.Item>
  </Menu.Menu>

  return <Menu borderless inverted className={'samh'}>
    <Menu.Item>
      <Icon name={iconLeft} size={'large'} color={iconLeftColor} />
    </Menu.Item>
    <Menu.Item>
      <Header inverted>
        <Header.Content>
          {title}
          {subHeader()}
        </Header.Content>
      </Header>
    </Menu.Item>
    {(showRight || fullMobileWidth) && menuItem_right()}
    {menuItem_edit()}
  </Menu>
}

export default SettingsAreaMenuHeader