
import React from "react";
import { motion } from "framer-motion";

// https://codesandbox.io/s/cfk8n?file=/src/three-dots-wave.js

// const containerStyle = {
//   position: "relative",
//   width: "3rem",
//   height: "3rem",
//   boxSizing: "border-box",
//   margin: 'auto',
// };

// const circleStyle = {
//   display: "block",
//   width: "2.5rem",
//   height: "2.5rem",
//   border: "0.4rem solid darkgrey",
//   borderTop: "0.4rem solid blue",
//   borderRadius: "50%",
//   position: "absolute",
//   boxSizing: "border-box",
//   top: 0,
//   left: 0
// };

export default function CircleLoader(props) {
  const { startType } = props ? props : {}
  const cn_div = 'start-circle-container ' + startType
  const cn_span = 'start-circle ' + startType
  return (
    <div className={cn_div}
    >
      <motion.span
        className={cn_span}
        animate={{ rotate: 360 }}
        transition={{ ease: "linear", duration: 2, repeat: Infinity }}
      />
    </div>
  );
}

