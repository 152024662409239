import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Icon, Label } from 'semantic-ui-react';
import { PageAreaParentContext } from '../../../cnr/contexts/PageAreaParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { uniqueKey } from '../../../common/keys';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';
import { _animateTypes } from '../../../motions/AnimateComponent';
import MotionComponent from '../../../motions/MotionComponent';
import QrCode from '../../../pageItem/modification/pageItemActions/QrCode';
import { addToClass } from '../../../styles/formatting';
import { getPropSectionsGrouped, groupTaggedPropSectionElements } from '../../../viewSettings/helpers/settingsHelpers';
import UiImage from '../../imaging/UiImage';
import { propsPicker } from '../../props/propsPicker';
import CardAuthIcon from './CardAuthIcon';
import CardIndicator from './CardIndicator';

const PropSorter = React.lazy(() => import('../../../dragNDrops/PropSorter'));

const CardProfile = (props) => {

  const { viewItem: viewItem_direct, itemData, handleClick, forAppManagement, imageOptions } = props
  const { email, fcmTokens, phoneNumber } = itemData ? itemData : {}

  // parentContext
  const parentContext = useContext(PageAreaParentContext)
  const { states, handlers } = parentContext ? parentContext : {}
  const { appSettings_state, eventInfo_state, page_state, framework_state, transition_state } = states
  const { settingsViewer_handlers } = handlers
  const { pagePropsSettingsOn } = appSettings_state ? appSettings_state : {}
  const { staticViews, staticViews_gs } = eventInfo_state ? eventInfo_state : {}
  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { display: display_global } = aps_global ? aps_global : {}
  const { authOn } = appSettings_state ? appSettings_state : {}

  const _staticViews = staticViews_gs ? staticViews_gs : staticViews

  const { propMode } = framework_state ? framework_state : {}

  // transitionContext  
  const { transitions } = transition_state ? transition_state : {}
  const transition = transitions ? transitions[_animateTypes.pageItemElement] : null
  const { showTransition } = transition ? transition : {}

  // uiItemContext 
  const uiItemContext = useContext(UiItemContext)
  const { item_state } = uiItemContext ? uiItemContext : {}
  const { _display, viewItem, propSections_allowed, propGroups } = item_state ? item_state : {}

  // _viewItem depending whether viewItem_direct exists
  const _viewItem = viewItem_direct ? viewItem_direct : viewItem
  const { display: display_direct } = viewItem_direct ? viewItem_direct : {}
  const display_final = display_direct ? display_direct : _display

  const { showCaptionInitial, sortProp } = display_final ? display_final : {}
  let { cardDisplayType } = display_final ? display_final : {}
  const { images, qrCodes, props: props_viewItem, key: key_viewItem, propSections: propSections_viewItem } = _viewItem ? _viewItem : {}
  const { showItemImage, imageLocation, imageSize } = images ? images : {}
  const { showQrCode } = qrCodes ? qrCodes : {}
  const { _itemKey } = itemData ? itemData : {}

  const _cardKeyy = uniqueKey('cpr', _itemKey)

  let imageInContent = true

  switch (imageLocation) {
    case gEnums.imageLocations.top:
      imageInContent = false
      break;
    default:
    // nothing
  }
  // const _indicatorLocation = indicatorLocation ? indicatorLocation : gEnums.bannerLocationTypes.bottom

  const [propSections, setPropSections] = useState()
  const [nameElements, setNameElements] = useState()
  const [metaElements, setMetaElements] = useState()
  const [propIndicators, setPropIndicators] = useState()

  useEffect(() => {
    const _semPropsSections = forAppManagement ? getPropSectionsGrouped(props_viewItem, propSections_viewItem, false, null, display_global, propGroups) : propSections_allowed
    const { nameElements: _nameElements, metaElements: _metaElements, propSections: s } = groupTaggedPropSectionElements({ _keyy: _cardKeyy, viewItemKey: key_viewItem, itemData, propsSections: _semPropsSections, forAppManagement, staticViews: _staticViews, display: _display, aps_global, propGroups })

    if (showCaptionInitial && sortProp && itemData[sortProp]) {
      _nameElements.unshift(<Label key={uniqueKey('prcr', 'l', 'ci')} circular color='blue'>{itemData[sortProp].substring(0, 1)}</Label>)
    }

    if (authOn) {
      _metaElements.push(<CardAuthIcon email={email} />)
    }

    const _propIndicators = []
    const _indicators = _.filter(props_viewItem, i => i.indicator && i.indicator.showIndicator);

    _indicators.forEach(ind => {
      if (itemData && ind.indicator && itemData[ind.key]) {
        _propIndicators.push({ ...ind.indicator, indicatorProp: ind.key })
      }
    })

    setPropIndicators(_propIndicators)

    if (settingsViewer_handlers && propMode) {
      setNameElements(propsPicker({ items: _nameElements, settingsViewer_handlers }))
      setMetaElements(propsPicker({ items: _metaElements, settingsViewer_handlers }))
      setPropSections(propsPicker({ items: s, settingsViewer_handlers }))
    } else {
      setNameElements(_nameElements)
      setMetaElements(_metaElements)
      setPropSections(s)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [itemData, props_viewItem, propMode]);


  const handleItemClick = () => handleClick && handleClick(_viewItem, _itemKey, null, { itemData: itemData })

  let cn = 'card-profile'
  let showFullImage = false

  if (imageLocation && showItemImage) {
    cn = addToClass(cn, 'card-img')
    cn = addToClass(cn, imageLocation, 'img')
  }

  if (showQrCode) { cn += ' card-qr' }

  if (propIndicators && propIndicators.length > 0) {
    cn += ' indic'
  }

  switch (cardDisplayType) {
    case gEnums.cardDisplayTypes.profilePlus:
    case gEnums.cardDisplayTypes.schedulePlus:
      cn += ' plus'
      showFullImage = true
      break;
    default:
    // nothing
  }

  const cardImage_normal = () => <UiImage
    viewItem={_viewItem}
    itemData={itemData}
    showFullImage={showFullImage}
    imageSize={imageSize}
    imageOptions={imageOptions}
  />

  const cardQr = () => <QrCode
    key={uniqueKey('cp', 'qr', _itemKey)}
    itemData={_viewItem}
    viewItem={_viewItem}
    qrCodeType={gEnums.qrCodeTypes.itemData}
  />

  const cardIcons = () => <div key={uniqueKey('cp', 'd', _cardKeyy)} >
    <Icon key={uniqueKey('cp', 'i', _cardKeyy, 1)} bordered name={appIconTypes.mail} color={email ? 'blue' : 'grey'} />
    <Icon key={uniqueKey('cp', 'i', _cardKeyy, 2)} bordered name={appIconTypes.notifications} color={fcmTokens ? 'blue' : 'grey'} />
    <Icon key={uniqueKey('cp', 'i', _cardKeyy, 3)} bordered name={appIconTypes.phone} color={phoneNumber ? 'blue' : 'grey'} />
  </div>

  const cardIndicator = (ind) => <CardIndicator indicator={ind} cardKey={_cardKeyy} itemData={itemData} aps_global={aps_global} staticViews={_staticViews} />

  const cardIndicators = () => <div className='card-indics'>
    {propIndicators.map(ind => (cardIndicator(ind)))}
  </div>

  const cardContent = () => <React.Fragment key={uniqueKey('cc', 'rf')}>
    {!imageInContent && cardImage_normal()}
    <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy)} className='card-content'>
      <Card.Header key={uniqueKey('cp', 'crd', 'h', _cardKeyy)}>
        {showItemImage && imageInContent && (imageLocation === gEnums.imageLocations.inline) && cardImage_normal()}
        {nameElements}
      </Card.Header>
      <Card.Meta key={uniqueKey('cp', 'mta', _cardKeyy)}>{metaElements}</Card.Meta>
    </Card.Content>
    {showItemImage && (imageInContent && imageLocation !== gEnums.imageLocations.inline) && <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy, 1)} className={'card-image'}>{cardImage_normal()}</Card.Content>}
    {showQrCode && <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy, 2)} className={'card-qr'}>{cardQr()}</Card.Content>}
    {forAppManagement && <Card.Content key={uniqueKey('cp', 'crd', _cardKeyy, 3)} extra className={'card-icons'}>{cardIcons()}</Card.Content>}
    {propIndicators && propIndicators.length > 0 && cardIndicators()}

  </React.Fragment>

  const cardProfile = (contentOnly) => {
    if (contentOnly) {
      return cardContent()
    } else {
      return <Card fluid key={uniqueKey('cp', _cardKeyy)} as='div' className={cn} onClick={() => { handleItemClick() }}>
        {cardContent()}
      </Card>
    }
  }

  const normalContent = () => {
    if (showTransition) {
      return <MotionComponent
        transition={transition}
        cnRoot={'ui fluid card ' + cn}
        oc={handleItemClick}
      >
        {cardProfile(true)}
      </MotionComponent>
    } else {
      return cardProfile()
    }
  }

  return pagePropsSettingsOn
    ?
    <PropSorter propSections={propSections} viewItem={_viewItem} />
    :
    normalContent()
}

export default CardProfile