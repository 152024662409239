import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Dropdown, Icon, Input, Label, Ref, Table } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { ammendUiSvValue, ammendUiValue, createItemKeys, getSplitStaticName } from '../../common/convert';
import { GoogleSheetsContext } from '../../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { labelListHeader } from '../../lists/lists';
import { dvLabelColors } from '../../cnr/reducers/DataManagmentReducer';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import CellIconLabels from './CellIconLabels';
import DropdownLabel from '../forms/elements/DropdownLabel';
import { getHeaders, getHeadersFromPropSections } from '../headers/getHeaders';
import { DataManagementContext } from './DataManagementViewer';
import { dataManagementTypes } from '../../viewSettings/enums/itemActionTypes';

/**
 * Returns a table
 * @param {object} props (keyy, vlProps, itemData, vit, showButtons, inApp, fullView, saveButton, fullScreen, noFullHeight, ignoreSortKeys)
 * @returns a Table
 */
const TableViewer = (props) => {

  const {
    allowEdit,
    columnHeaders,
    dataFieldName,
    forCopy,
    handleEditData,
    handleRowClick,
    handleRowCheck,
    handleRowSelect,
    handleUpdateNewData,
    ignoreItemKeys,
    isAppData,
    jsonData,
    propIcons,
    selectedKey,
    showItemKey,
    tableKey,
    tableRef,
    viewItem
  } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { eventInfo_state, page_state } = states ? states : {}
  const { staticViews: staticViews_app } = eventInfo_state ? eventInfo_state : {}

  const { pageSettings } = page_state ? page_state : {}
  const { aps_global } = pageSettings ? pageSettings : {}
  const { projectOptions, dataOptions } = aps_global ? aps_global : {}
  const { allowGuests } = projectOptions ? projectOptions : {}
  const { dateFormat } = dataOptions ? dataOptions : {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext)
  const { googleSheets_state } = googleSheetsContext ? googleSheetsContext : {}
  const { previewInfo } = googleSheets_state ? googleSheets_state : {}
  const { projectData } = previewInfo ? previewInfo : {}
  const { staticViews: staticViews_gs } = projectData ? projectData : {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state, dataManagement_handlers } = dataManagementContext ? dataManagementContext : {}
  const { dataManagementType, propAdjustmentTypes } = dataManagement_state ? dataManagement_state : {}

  const { key: viewItemKey } = viewItem ? viewItem : {}

  let _staticViews;

  if (isAppData) {
    _staticViews = staticViews_app
  } else {
    _staticViews = staticViews_gs ? staticViews_gs : staticViews_app
  }

  const [headers, setHeaders] = useState()
  const [editData, setEditData] = useState(jsonData)

  const handleIssues = (propKey, nfs) => {
    console.log('nfs', viewItemKey, propKey, nfs)
  }

  const handleHeaderClick = (option) => {
    console.log('option', option)
    dataManagement_handlers.handleConvertProp(option, dateFormat, jsonData, staticViews_app)
  }

  useEffect(() => {
    if (props.headers) {
      setHeaders(props.headers)
    } else {
      let _headers = getHeaders(jsonData, columnHeaders)
      // const { propSections } = viewItem ? viewItem : {}
      // if (propSections) {
      //   _headers = getHeadersFromPropSections(viewItem)
      // }
      if (showItemKey) {
        _headers.unshift('_itemKey')
      }
      setHeaders(_headers)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [jsonData, viewItem]);

  useEffect(() => {
    switch (dataManagementType) {
      case dataManagementTypes.fixPropData:
        dataManagement_handlers.handleGetPropAdjustments()
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleChange = (e, d, cb) => {
    const _editData = { ...editData }
    const { jsonitem, value } = d
    const { _itemKey } = jsonitem ? jsonitem : {}
    if (_editData[_itemKey]) {
      if (cb) {
        _editData[_itemKey][dataFieldName] = !value
      } else {
        _editData[_itemKey][dataFieldName] = value
      }
    }
    setEditData(_editData)
    handleEditData(_editData)
  }

  if (!ignoreItemKeys) {
    createItemKeys(jsonData)
  }

  const checkbox = (value, jsonItem, key) => <Checkbox
    label={_.startCase(key)}
    name={key}
    value='this'
    checked={value}
    onClick={(e) => { handleChange(e, { jsonitem: jsonItem, value }, true) }}
  />

  const _jsonData = _.orderBy(allowEdit && editData ? editData : jsonData, ['lastName', 'firstName', 'name'])

  const cell_boolean = (value, jsonItem, key) => {
    if (allowEdit) {
      return checkbox(value, jsonItem, key)
    } else {
      return value === true ? 'true' : 'false'
    }
  }

  const tableHeaders = () => headers.map((header, index) => {
    const thKey = tableKey ? uniqueKey('tv', 'fdv', 'h', index) : uniqueKey('fdv', 'h', index)
    switch (dataManagementType) {
      case dataManagementTypes.fixPropData:
        return <Table.HeaderCell key={thKey} textAlign='center'>{headerDropdown(index, header)}</Table.HeaderCell>
      default:
        return <Table.HeaderCell key={thKey} textAlign='center'>{_.startCase(header)}</Table.HeaderCell>
    }
  })

  const labelValue = (value, key, cvKey, jsonItem) => {
    const xxx = getSplitStaticName(_staticViews, key, value)
    const _value = ammendUiValue(xxx)
    if (allowEdit && key !== '_itemKey') {
      return <Input placeholder={key} fluid value={value} onChange={handleChange} propkey={key} jsonitem={jsonItem} />
    }
    if (_value === value) {
      return value
    } else {
      return <Label key={uniqueKey('lv', cvKey)} size={'mini'} color={dvLabelColors.value}>{_value}</Label>
    }
  }

  const headerDropdown = (keyy, key, color) => {
    const ddKey = uniqueKey('tv', 'dd', keyy)
    const ddOpts = labelListHeader(propAdjustmentTypes, { propKey: key, handleItemClick: handleHeaderClick })
    let cn = 'icon hdd tv'
    return <Dropdown
      key={ddKey}
      className={cn}
      options={ddOpts}
      button
      labeled
      selection
      text={_.startCase(key)}
      icon='setting'
      style={{ color: color }}
    />
  }

  const dropdown = (keyy, arrayValue, key, color) => <DropdownLabel
    allowGuests={allowGuests}
    arrayValue={arrayValue}
    color={color}
    isAppData={isAppData}
    propKey={key}
    keyy={keyy}
    staticViews_gs={staticViews_gs}
    staticViews_app={staticViews_app}
    viewItemKey={viewItemKey}
    handleIssues={handleIssues}
  />

  const cellIconLabels = (jsonItem, value, cvKey, key, color, existing) => <CellIconLabels
    allowGuests={allowGuests}
    color={color}
    cvKey={cvKey}
    existing={existing}
    handleUpdateNewData={handleUpdateNewData}
    isAppData={isAppData}
    jsonItem={jsonItem}
    propKey={key}
    staticViews_gs={staticViews_gs}
    value={value}
  ></CellIconLabels>

  const arrayText = (a) => {
    let t = ''
    a.forEach((av, index) => {
      t += av
      if (index < a.length - 1) {
        t += ' | '
      }
    })
    return t
  }

  const cellValue = (ri, ci, jsonItem, key, icon) => {

    const value = jsonItem[key]

    const { existingValue, newValue } = value ? value : {}
    let cvKey = ri + '-' + ci + '-' + key

    if (existingValue || newValue) {
      let els = []
      let nls = []
      if (existingValue) { els = cellIconLabels(jsonItem, existingValue, cvKey, key, iconColorTypes.semBlue, true) }
      if (newValue) { nls = cellIconLabels(jsonItem, newValue, cvKey, key, iconColorTypes.semOrange) }
      return <div className={'trcl'}>{els}{nls}</div>
    } else {
      try {
        if (_.isBoolean(value)) {
          if (icon) {
            return <Icon name={icon} color={value === true ? 'blue' : 'green'} />
          } else {
            return cell_boolean(value, jsonItem, key)
          }
        }
        if (_.isEmpty(value)) { return }
        if (_.isObject(value) || _.isArray(value)) {
          if (_.isArray(value)) {
            const dd = forCopy ? arrayText(value) : dropdown(cvKey, value, key, iconColorTypes.semBlack)
            return dd
          } else {
            let els = []
            let nls = []
            return <div className={'trcl'}>{[...els, ...nls]}</div>
          }
        } else {
          if (existingValue || newValue) {
            let els = []
            let nls = []
            return <div className={'trcl'}>{[...els, ...nls]}</div>
          } else {
            let _value = ammendUiSvValue(jsonItem, key)
            return labelValue(_value, key, cvKey, jsonItem)
          }
        }
      } catch (error) {
        console.error(error);
        return 'err'
      }
    }
  }

  const tableCells = (jsonItem, rowIndex) => headers.map((key, cellIndex) => {
    const tcKey = tableKey ?
      uniqueKey('tcs', tableKey, rowIndex, cellIndex)
      :
      uniqueKey('tcs', rowIndex, cellIndex)

    if (propIcons && propIcons[key]) {
      return <Table.Cell key={tcKey} style={{ textAlign: propIcons[key] ? 'center' : 'left' }} >{cellValue(rowIndex, cellIndex, jsonItem, key, propIcons[key])}</Table.Cell>
    } else {
      return <Table.Cell key={tcKey} >{cellValue(rowIndex, cellIndex, jsonItem, key)}</Table.Cell>
    }
  })

  const editHeaderCell = () => <Table.HeaderCell key={uniqueKey('fdv', 'the')}>{'Edit'}</Table.HeaderCell>
  const editCell = (jsonItem, rowIndex) => <Table.Cell key={uniqueKey('fdv', 'tce', rowIndex)} textAlign='center' onClick={() => { handleRowSelect(jsonItem) }}><Icon name={'edit'} /></Table.Cell>
  const checkHeaderCell = () => <Table.HeaderCell key={uniqueKey('fdv', 'the')}>{'Select'}</Table.HeaderCell>
  const checkCell = (jsonItem, rowIndex) => <Table.Cell key={uniqueKey('fdv', 'tce', rowIndex)} textAlign='center' onClick={() => { handleRowCheck(jsonItem) }}><Icon name={jsonItem.selected ? 'check square outline' : 'square outline'} /></Table.Cell>

  const tableRows = () => _jsonData.map((jsonItem, rowIndex) => {
    let allowRow;
    if (selectedKey) {
      if (jsonItem._itemKey === selectedKey) {
        allowRow = true
      }
    } else {
      allowRow = true
    }
    if (allowRow) {
      return <Table.Row className={jsonItem._new ? 'tv-tr-new' : 'tv-tr'} key={uniqueKey('fdv', 'tr', rowIndex)} onClick={() => { handleRowClick && handleRowClick(jsonItem, rowIndex) }}>
        {handleRowCheck && checkCell(jsonItem, rowIndex)}
        {handleRowSelect && editCell(jsonItem, rowIndex)}
        {tableCells(jsonItem, rowIndex)}</Table.Row>
    }
    return null
  })

  const table = () => <div className={'table-wrapper'}>
    <Ref innerRef={tableRef}>
      <Table singleLine celled unstackable striped id='table' key={tableKey ? uniqueKey('tv', tableKey) : uniqueKey('tv')}>
        <Table.Header>
          <Table.Row>
            {handleRowCheck && checkHeaderCell()}
            {handleRowSelect && editHeaderCell()}
            {headers && tableHeaders()}
          </Table.Row>
        </Table.Header>
        <Table.Body>{headers && tableRows()}</Table.Body>
      </Table >
    </Ref>
  </div>

  return table()
}

export default TableViewer