import React, { createContext } from 'react';

export const UploadContext = createContext();

const UploadProvider = (props) => {

  return <UploadContext.Provider value={props}>
    {props.children}
  </UploadContext.Provider>
};

export default UploadProvider