import React, { useContext, useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../../global/auth/appUserAccessPermissions';
import NoData from '../../../global/components/alerts/NoData';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import TicketingProvider, { TicketingContext } from '../cnr/contexts/TicketingContext';
import { ticketTypes } from './SingleTicket';
import Tickets from './Tickets';

export const AppUserTicketing = (props) => {

  const { willCallTickets } = props ? props : {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ? parentContext : {}
  const { paps_state, appUser_state } = states ? states : {}
  const { appUser } = appUser_state ? appUser_state : {}
  const appUserAccess = getAppUserAccess(appUser)
  const { appUserSessionKey, email, phoneNumber } = appUserAccess ? appUserAccess : {}
  const { viewKey } = paps_state ? paps_state : {}

  const _viewKey = viewKey ? viewKey : appUserSessionKey

  // ticketingContext
  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state, ticketing_handlers } = ticketingContext ? ticketingContext : {}
  const { tickets, assignedTickets, currentAppUserTickets, appTicketing } = ticketing_state ? ticketing_state : {}
  const { ticketingCaption } = appTicketing ? appTicketing : {}
  const _ticketCaption = ticketingCaption ? ticketingCaption : 'Tickets'

  const [showMessage, setShowMessage] = useState()

  useEffect(() => {
    if (!willCallTickets && assignedTickets) {
      ticketing_handlers.getCurrentAppUserTickets(_viewKey, email, phoneNumber, appUserAccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [assignedTickets]);

  useEffect(() => {
    if (willCallTickets) {
      ticketing_handlers.setCurrentAppUserTickets(willCallTickets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [willCallTickets]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage()
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);

  const ticketGroups = () => <Tickets tickets={currentAppUserTickets} ticketType={willCallTickets ? ticketTypes.willCall : ticketTypes.single} />

  if (tickets && currentAppUserTickets) {
    return ticketGroups()
  } else {
    return <Segment style={{ height: '100%' }} basic inverted={willCallTickets ? true : false}>
      <NoData altCaption={_ticketCaption ? _ticketCaption : 'No Tickets'} />
    </Segment>
  }
}

export const AppUserTicketingWithProvider = (props) => <TicketingProvider>
  <AppUserTicketing {...props} />
</TicketingProvider>