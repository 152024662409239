import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from "semantic-ui-react";
import { gEnums } from '../../../enums/globalEnums';

/**
 * 
 * @param {object} props (fip, selectMulti, itemProp, optionList)
 * @returns Form.Dropdonw
 */
const DropdownInput = (props) => {

  const { fip, selectMulti, itemProp, optionList } = props
  const { data } = itemProp ? itemProp : {}
  const { formItemType, fullRow } = data ? data : {}

  if (!fip.value || fip.value === '' || fip.value === undefined || fip.value === 'undefined') { delete fip.value }

  if (_.isArray(fip.value)) {
    const nvs = []
    fip.value.forEach(fv => {
      const vn = parseInt(fip.value)
      if (vn && _.isNumber(vn)) {
        nvs.push(vn)
      } else {
        nvs.push(fip.value)
      }
    })
  }

  const [ddOptions, setDdOptions] = useState([])

  useEffect(() => {
    switch (formItemType) {
      case gEnums.formItemTypes.dropdownAdd:
        setDdOptions(fip.value ? fip.value : [])
        break;
      default:
        setDdOptions(optionList ? optionList : [])
        break;
    }
    // eslint-disable-next-line
  }, [optionList]);

  const handleAddition = (e, { value }) => {
    const opts = [...ddOptions]
    const newOpts = [{ text: value, value }, ...opts]
    setDdOptions(newOpts)
    const x = {
      propname: fip.propname,
      value: newOpts,
    }
    fip.onChange(e, x)
  }

  if (fullRow) { fip.className += ' full-row' }

  const _multiple = selectMulti || formItemType === gEnums.formItemTypes.dropdownAdd

  if (_multiple && fip.value && !_.isArray(fip.value)) { fip.value = [fip.value] }

  return <Form.Dropdown {...fip}
    options={ddOptions}
    fluid
    search
    selection
    multiple={_multiple}
    allowAdditions={formItemType === gEnums.formItemTypes.dropdownAdd ? true : false}
    onAddItem={formItemType === gEnums.formItemTypes.dropdownAdd ? handleAddition : null}
  />
}

export const dropdown = (fip, options, selectMulti, itemProp) => {

  if (!options) { options = [] }
  if (!fip.value || fip.value === '' || fip.value === undefined || fip.value === 'undefined') { delete fip.value }

  if (_.isArray(fip.value)) {
    const nvs = []
    fip.value.forEach(fv => {
      const vn = parseInt(fip.value)
      if (vn && _.isNumber(vn)) {
        nvs.push(vn)
      } else {
        nvs.push(fip.value)
      }
    })
  }

  const { data, formItemType } = itemProp ? itemProp : {}

  if (data && data.fullRow) { fip.className += ' full-row' }

  if (selectMulti && fip.value && !_.isArray(fip.value)) { fip.value = [fip.value] }

  return <Form.Dropdown {...fip}
    options={options}
    fluid
    search
    selection
    multiple={selectMulti}
    allowAdditions={formItemType === gEnums.formItemTypes.dropdownAdd}
  />
}

export default DropdownInput